<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Add Duit Lebih</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 aria-label="Close" aria-label="Close"
            (click)="reset()"></button>
    </div>

    <div class="modal-body">

        <div class="container-fluid" style="padding: 2%;">
            <div class="row">
                <div class="col">
                    <div class="row">
                        <div class="col">
                            <div class="table-responsive table-borderless m-1">
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div class="group mt-3">
                                                    <input type="number" required class="textInputLong" name="amount"
                                                        [(ngModel)]="amount" min=0
                                                        (keypress)="sharedService.isNumberKeyAllowNegative($event)">
                                                    <span class="highlight"></span>
                                                    <span class="bar barLong"></span>
                                                    <label>Duit Lebih Amount</label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <mat-form-field appearance="fill">
                                                    <mat-label>SubType</mat-label>
                                                    <mat-select [(ngModel)]="selectedSubType" name="selectedSubType">
                                                        <mat-option *ngFor="let type of subtypeList" [value]="type.ID">
                                                            {{type.Name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col d-flex justify-content-end" style="padding: 0;margin-bottom: 2%;">
                            <button class="btn btn-success" type="button" (click)="submit()">Add</button>
                            <button class="btn btn-danger ms-2" type="button" (click)="reset()">Cancel</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>


</form>