<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h2>Add EPF Loan Request</h2>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>



    <div class="modal-body">
        <div class="row p-1 m-1" *ngIf="isEPF">
            <div class="col-12  p-1 dangerCard">
                <mat-slide-toggle class="mat-slide-toggle-lg" [hideIcon]="true" color="primary" [(ngModel)]="isShared" name="isShared"
                    (change)="isManualKeyIn=isShared"
                    [disabled]="this.selectedProfile!=undefined && selectedProfile.SharedProfileId!=undefined">
                    Share Customer
                </mat-slide-toggle>
            </div>

            <div class="col-12  p-1 dangerCard">
                <mat-slide-toggle class="mat-slide-toggle-lg" [hideIcon]="true" color="primary" [(ngModel)]="isManualElevate" name="isManualElevate"
                    (change)="elevateConfirmation()">
                    Send Request to BOSS
                </mat-slide-toggle>
            </div>
            <div class="col-12  p-1 dangerCard" *ngIf="isManualElevate==true">
                <mat-slide-toggle class="mat-slide-toggle-lg" [hideIcon]="true" color="primary" [(ngModel)]="isManualKeyIn" name="isManualKeyIn"
                    (change)="manualKeyInConfirmation()" [disabled]="isShared==true">
                    Manual Key In
                </mat-slide-toggle>
            </div>
        </div>


        <ng-container *ngIf="getEPFDate()!=undefined && getEPFAmount()!=undefined && checkIfEPFDateOver()==false">
            <app-epf-limit-information [previousEPFLoans]="previousEPFLoans" [isEPFExtra]="isEPFExtra"
                [epfDate]="getEPFDate()" [epfAmount]="getEPFAmount()" [epfLimitRuleSet]="enums.epfLimitList">
            </app-epf-limit-information>
        </ng-container>

        <mat-expansion-panel class="shadow-lg" (opened)="epfDetailsExpansionIsOpen= true"
            (closed)="epfDetailsExpansionIsOpen= false" [expanded]="epfDetailsExpansionIsOpen">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h4>EPF Customer Details</h4>
                </mat-panel-title>
                <mat-panel-description class="d-flex justify-content-end align-items-end">

                    <h2 class="text-success"><i class="fas fa-check-circle"
                            *ngIf="isEPFDetailStepCompleted()==true"></i></h2>


                </mat-panel-description>
            </mat-expansion-panel-header>
            <form [formGroup]="epfFormGroup">
                <ng-template matStepLabel>EPF Details</ng-template>

                <div class="row">
                    <div class="col-12 d-flex justify-content-end align-items-right">
                        <div class="dropdown d-inline ms-1"  data-bs-toggle="tooltip" data-placement="top"
                            title="Upload Customer EPF Docs">
                            <button class="btn btn-dark dropdown-toggle" type="button" id="dropdownMenuButton"
                                 data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fas fa-upload"></i> </button>
                            <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" (click)="openUploadEPFSupportingDoc(enums.EPF_STATEMENT)">
                                    <i class="fas fa-chart-pie"></i> EPF Statement</a>
                                <a class="dropdown-item" (click)="openUploadEPFSupportingDoc(enums.EPF_PROOF)">
                                    <i class="fas fa-video"></i> EPF Proof</a>
                                <a class="dropdown-item" (click)="openUploadEPFSupportingDoc(enums.ACKNOWLEDGEMENT)">
                                    <i class="fas fa-clipboard-check"></i> Acknowledgement</a>

                            </div>
                        </div>


                    </div>
                    <div class="col-12 col-md-6">
                        <mat-form-field class="full-width-formField bar">
                            <input matInput class="textInputLong" [matDatepicker]="myDatepicker"
                                formControlName="epfDate" placeholder="EPF Settlement Date"
                                [min]="sharedService.getTodayDate()" [max]="sharedService.getMaxDateForEPF()"
                                onkeydown="return false" disabled>
                            <mat-datepicker-toggle matSuffix [for]="myDatepicker">
                            </mat-datepicker-toggle>
                            <mat-datepicker #myDatepicker name="date"></mat-datepicker>
                        </mat-form-field>
                        <span class="badge text-bg-warning" *ngIf="checkIfManualEPFDateIsValid()==false">Please
                            check
                            EPF date
                        </span>
                    </div>
                    <div class="col-12 col-md-6">
                        <mat-form-field class="full-width-formField bar">
                            <mat-label>EPF Amount (RM)</mat-label>
                            <input matInput type="number" required class="textInputLong" placeholder="EPF Amount"
                                formControlName="epfAmount" min=0 (keypress)="sharedService.isNumberKey($event)"
                                [readonly]="epfDetails.Amount!=undefined">
                        </mat-form-field>
                    </div>

                    <div class="col-12 mb-2">
                        <mat-slide-toggle class="mat-slide-toggle-lg" [hideIcon]="true" color="primary" formControlName="hasiAccount">
                            Has iAccount
                        </mat-slide-toggle>
                    </div>
                    <div class="col-12 col-md-6" *ngIf="getFormValue('hasiAccount')==true">
                        <mat-form-field class="full-width-formField bar">
                            <mat-label>iAccount Username</mat-label>
                            <input matInput type="text" class="textInputLong" placeholder="iAccount Username"
                                formControlName="iAccountUsername">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-6" *ngIf="getFormValue('hasiAccount')==true">
                        <mat-form-field class="full-width-formField bar">
                            <mat-label>iAccount Password</mat-label>
                            <input matInput type="text" class="textInputLong" placeholder="iAccount Password"
                                formControlName="iAccountPassword">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-6">
                        <mat-form-field class="full-width-formField bar">
                            <mat-label>Other ID (eg: Old IC )</mat-label>
                            <input matInput type="text" class="textInputLong" placeholder="OtherID"
                                formControlName="otherId">
                        </mat-form-field>
                    </div>



                    <div class="col-12 col-md-6" *ngIf="this.selectedCustomer.Gender==''">
                        <div><span>Gender</span></div>
                        <select class="browser-default form-select" formControlName="gender">
                            <option *ngFor="let gender of enums.genderOptions" [value]="gender">
                                {{gender}}</option>
                        </select>
                    </div>
                    <div class="col-12 col-md-6" *ngIf="this.selectedCustomer.Race==''">
                        <div><span>Race</span></div>
                        <select class="browser-default form-select" formControlName="race">
                            <option *ngFor="let race of enums.raceOptions" [value]="race">{{race}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="d-flex justify-content-end aligh-items-right">
                    <hr />
                    <button mat-button (click)="validateEPFDetails()">
                        Submit</button>
                </div>
            </form>
        </mat-expansion-panel>


        <mat-expansion-panel class="my-2 c" (opened)="guarantorExpansionIsOpen = true"
            (closed)="guarantorExpansionIsOpen = false" [expanded]="guarantorExpansionIsOpen"
            *ngIf="epfDetailsExpansionIsOpen==false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h4>Gurantor Details</h4>
                </mat-panel-title>
                <mat-panel-description class="d-flex justify-content-end align-items-end">

                    <h2 class="text-success"><i class="fas fa-check-circle"
                            *ngIf="isEPFGurantorStepCompleted()==true"></i></h2>



                </mat-panel-description>
            </mat-expansion-panel-header>
            <form [formGroup]="contactFormGroup">
                <ng-template matStepLabel>Guarantor Details</ng-template>

                <p *ngIf="contactList.length==0|| contactList==undefined">You do not have any
                    contact can be chosen now, please add contact if necessary
                </p>
                <div class="d-flex justify-content-end">
                    <button class="btn btn-info" (click)="addContact()">Add</button>
                </div>

                <hr />
                <ng-container *ngFor="let contact of contactList;let i=index">
                    <div class="card mb-1" [ngClass]="getContactClass(contact.IsSelected)"
                        (click)="changeIsContactSelected(i)">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-10">
                                    <h5>Contact # {{i+1}}</h5>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col col-2">
                                    <span style="font-size: 1.5rem;color: purple;" *ngIf="contact.IsSelected==true"><i
                                            class="fas fa-check"></i></span>
                                </div>
                                <div class="col col-10">
                                    <div class="row">
                                        <div class="col-6">
                                            <span class="badge text-bg-success" *ngIf="contact.PhotoExisted==true">With
                                                Photos</span>
                                        </div>
                                        <div class="col-6 d-flex justify-content-end align-items-end">
                                            <div class="dropdown d-inline ms-1"  data-bs-toggle="tooltip"
                                                data-placement="top" title="Upload Contact files">
                                                <button class="btn btn-dark dropdown-toggle" type="button"
                                                    id="dropdownMenuButton"  data-bs-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false"
                                                    *ngIf="sharedService.isAdmin()==false && sharedService.isBossAccount()==false && sharedService.isAuditor()==false">
                                                    <i class="fas fa-upload"></i> </button>
                                                <div class="dropdown-menu dropdown-menu-end"
                                                    aria-labelledby="dropdownMenuButton">
                                                    <a class="dropdown-item"
                                                        (click)="openContactUploadFileDialog(contact,this.enums.CONTACT_GUARANTOR_FORM,i)">
                                                        <i class="fas fa-signature"></i> Guarantor
                                                        Form</a>
                                                    <a class="dropdown-item"
                                                        (click)="openContactUploadFileDialog(contact,this.enums.CONTACT_PROOF_OF_RELATION,i)">
                                                        <i class="far fa-heart"></i> Proof Of
                                                        Relation</a>
                                                    <a class="dropdown-item"
                                                        (click)="openContactUploadFileDialog(contact,this.enums.CONTACT_ACKNOWLEDGEMENT,i)">
                                                        <i class="fas fa-clipboard-check"></i>
                                                        Acknowledgement</a>
                                                    <a class="dropdown-item"
                                                        (click)="openContactUploadFileDialog(contact,this.enums.CONTACT_PHOTO,i)">
                                                        <i class="fas fa-photo-video"></i> Other
                                                        Docs</a>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            Name: {{contact.Name}}
                                        </div>
                                        <div class="col-6">
                                            IC: {{contact.ICNumber}}
                                        </div>
                                        <div class="col-6">
                                            Contact Number : {{contact.ContactNumber}}
                                        </div>
                                        <div class="col-6">
                                            Relation : {{contact.Relation}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>





                <div class="d-flex justify-content-end align-items-right">
                    <hr />
                    <button mat-button (click)="validateCheckGurantorValidity()">Next</button>
                </div>
            </form>
        </mat-expansion-panel>



        <div *ngIf="epfDetailsExpansionIsOpen==false && isEPFGurantorStepCompleted()==true">
            <button class="btn btn-primary" (click)="openAddLoanModal(true)" *ngIf="mainLoanRequest==undefined"> Add
                main loan</button>
            <button class="btn btn-primary" (click)="createNewRequestObject()"
                *ngIf="isShared==true && mainLoanRequest!=undefined"> Add share
                loan</button>

            <ng-container *ngIf="mainLoanRequest!=undefined">
                <div>
                    <span class="text-danger" *ngIf="checkHandlingChargeEligibility()==false"> <b>Cannot get HANDLING
                            CHARGE</b></span>
                </div>
                <div class="m-1 p-1" style="background-color: lightyellow">
                    <span class="d-flex justify-content-end align-items-right" style=" cursor: pointer;color:gray"
                        (click)="deleteMainLoan()"> <i class="fas fa-times"></i></span>
                    <p>{{mainLoanRequest.companyName}} | Loan: RM
                        {{mainLoanRequest.request.LoanInfo.Principal|number:'1.0-2'}} |
                        Settlement:RM {{
                        this.sharedService.calculateEPFSettlementAmountWithCharges(mainLoanRequest.request.LoanInfo)
                        |number:'1.0-2'}} |
                        On Hand: RM {{this.sharedService.calculateTotalOnHand(mainLoanRequest.request.LoanInfo) |
                        number:'1.0-2'}}
                    </p>
                    <button class="btn btn-info" (click)="openLoanDetailsModal(mainLoanRequest.request)">View
                        Details</button>

                </div>
            </ng-container>



            <ng-container *ngFor="let addedRequest of requestList; let i = index"
                [hidden]="requestList==0 || requestList ==undefined">
                <ng-container *ngIf="addedRequest.request!=undefined; else doANewRequest">
                    <div class="m-1 p-1" style="background-color: lightsteelblue">
                        <span class="d-flex justify-content-end align-items-right" style=" cursor: pointer;color:gray"
                            (click)="deleteNewLoan(i)"> <i class="fas fa-times"></i></span>
                        <p>{{addedRequest.companyName}} | Loan: RM
                            {{addedRequest.request.LoanInfo.Principal|number:'1.0-2'}} |
                            Settlement:RM {{
                            this.sharedService.calculateEPFSettlementAmountWithCharges(addedRequest.request.LoanInfo)|number:'1.0-2'}}
                            | On Hand: RM {{this.sharedService.calculateTotalOnHand(addedRequest.request.LoanInfo) |
                            number:'1.0-2'}}
                        </p>
                        <button class="btn btn-info" (click)="openLoanDetailsModal(addedRequest.request)">View
                            Details</button>

                    </div>
                </ng-container>
                <ng-template #doANewRequest>
                    <div class="col-12 pt-3">
                        <span class="d-flex justify-content-end align-items-right" style=" cursor: pointer;color:gray"
                            (click)="deleteNewLoan(i)"> <i class="fas fa-times"></i></span>
                        <app-search-company-by-uid (passEntry)="getSelectedCompany($event,i)">
                        </app-search-company-by-uid>

                        <div class="card" *ngIf="requestList[i]!=undefined && requestList[i].companyName!=undefined">
                            <div class="card-body">
                                <p><b>Share with
                                        {{requestList[i].companyName}}
                                    </b></p>
                                <button class="btn btn-primary" (click)="openAddLoanModal(false,i)">Add Loan</button>
                            </div>
                        </div>

                    </div>
                </ng-template>


            </ng-container>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-warning" (click)="submitSharedRequests()">Submit</button>
    </div>

</form>