<form>
    <!-- Modal Header -->
    <div class="modal-header" *ngIf="isDialog==true">
        <h4 class="modal-title" *ngIf="contactToUpdate==undefined">Add Contact</h4>
        <h4 class="modal-title" *ngIf="contactToUpdate!=undefined">Edit Contact</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body">

        <div class="my-0 ">
            <div class="group">
                <input type="text" required class="textInputLong" name="name" [(ngModel)]="name">
                <span class="highlight"></span>
                <span class="bar barLong"></span>
                <label>Name</label>
            </div>
        </div>
        <div class="my-0 d-flex">
            <div class="group">
                <input type="text" required class="textInputLong" name="icNumber" [(ngModel)]="icNumber"
                    (keypress)="sharedService.isNumberKey($event)">
                <span class="highlight"></span>
                <span class="bar barLong"></span>
                <label>IC Number</label>
                <p class="text-danger my-1 mx-0 p-0" *ngIf="this.sharedService.checkIfICValid(this.icNumber) == false"> IC Number format invalid </p>
            </div>
          
        </div>
        <div class="my-0 d-flex">
            <div class="group">
                <input type="text" required class="textInputLong" name="contactNum" [(ngModel)]="contactNum"
                    (keypress)="sharedService.isNumberKey($event)">
                <span class="highlight"></span>
                <span class="bar barLong"></span>
                <label>Contact Number</label>
            </div>
        </div>
        <div class="my-0 ">
            <div class="group">
                <input type="text" required class="textInputLong" name="relation" [(ngModel)]="relation">
                <span class="highlight"></span>
                <span class="bar barLong"></span>
                <label>Relation</label>
            </div>
        </div>


        <div class="my-0">
            <div class="group">
                <p>Address</p>
                <textarea placeholder="Please fill in address" rows="3" name="address" [(ngModel)]="address"></textarea>
                <span class="highlight"></span>
                <span class="bar barLong"></span>
                <!-- <label>Address</label> -->
            </div>
        </div>

        <div class="my-0">
            <div class="group">
                <p>Remark</p>
                <textarea placeholder="Remark" rows="3" name="remark" [(ngModel)]="remark"></textarea>
                <span class="highlight"></span>
                <span class="bar barLong"></span>
                <!-- <label>Address</label> -->
            </div>
        </div>
    </div>




    <div class="modal-footer" *ngIf="isDialog==true">
        <button class="btn btn-outline-success" type="button" (click)="submit()"
            *ngIf="contactToUpdate != undefined">Edit Contact</button>
        <button class="btn btn-outline-success" type="button" (click)="submit()" *ngIf="contactToUpdate == undefined"
            [disabled]="disableSubmit">Add Contact</button>
        <button class="btn btn-outline-danger" type="button" (click)="reset()">Cancel</button>
    </div>

</form>