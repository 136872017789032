<div class="row m-2">
    <div class="col-9">
        <h4>Form J History</h4>
    </div>
    <div class="col-3 d-flex justify-content-end align-items-right"
        *ngIf="sharedService.isAdmin()==false && sharedService.isBossAccount()==false && sharedService.isAuditor()==false">
        <button class="btn btn-info" (click)="openFormJModal()"> <i class="fas fa-plus"></i> New Form J</button>
    </div>
</div>
<ng-container *ngIf="formJList.length==0">
    <p class="ms-2">You do not have any FORM J record for this customer.</p>
</ng-container>
<div class="row" *ngIf="sharedService.checkFormJExpired(viewCustomer.LastFormJDate)==true">
    <div class="col-12 bg-danger">
        <p style="color: white;font-weight: bold;text-align: center;">You have not generate Form J of this customer for
            more than 9 months. Please generate
            Form J</p>
    </div>
</div>
<ng-container *ngIf="formJList.length>0">
    <table class="table table-bordered">
        <thead>
            <tr>
                <th *ngIf="sharedService.isBossAccount()"></th>
                <th>Generated Date</th>
                <th>Principal Amount</th>
                <th>Extra Information</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let formJ of formJList">
                <td *ngIf="sharedService.isBossAccount()"> <button class="btn btn-danger"
                        (click)="openDeleteFormJDialog(formJ)">
                        <i class="fas fa-trash-alt"></i>
                    </button></td>
                <td> {{formJ.DateCreated |date:'dd-MM-yyyy'}}</td>
                <td>{{formJ.Principal}}</td>
                <td>
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Months</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let schedule of formJ.PaymentSchedule">
                                <td>{{schedule.monthsCount}}</td>
                                <td>{{schedule.amount}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>Terms : {{formJ.Term}}</p>
                    <p>Last Payment: RM {{formJ.LastPayment}}</p>
                    <p>Receipt Number: {{formJ.ReceiptNumber}}</p>
                    <p>Form J Stamping Date: {{formJ.StampDate | date:'dd-MM-yyyy'}}</p>
                    <p>Version: {{formJ.Version}}</p>
                </td>
                <td>
                    <button class="btn btn-outline-dark" (click)="reprintFormJ(formJ.ID)">Reprint</button>
                </td>
            </tr>
        </tbody>
    </table>
</ng-container>