<ng-container>
    <div class="row m-2">
        <div class="col-12">
            <mat-form-field>
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Enter searching criteria">
            </mat-form-field>
        </div>
        <div class="col-4">
            <div class="card shadow-lg mb-1">
                <div class="card-body" style="background-color: lightgoldenrodyellow;">
                    <i class="fas fa-hand-holding-usd"></i> Amount: RM {{calculateTotalOfficeAmount()}}
                </div>
            </div>

        </div>
        <div class="col-2">
            <div class="card shadow-lg mb-1" style="background-color: lightsteelblue;">
                <div class="card-body"><i class="fas fa-phone-square-alt"></i> PENDING: {{countStatusTotal('PENDING')}}
                </div>
            </div>
        </div>
        <div class="col-2">
            <div class="card shadow-lg mb-1" style="background-color: rgba(219, 248, 219, 0.76);">
                <div class="card-body"><i class="fas fa-check-circle"></i> SETTLED: {{countStatusTotal('SETTLED')}}
                </div>
            </div>
        </div>
        <div class="col-2">
            <div class="card shadow-lg mb-1" style="background-color: lightsalmon;">
                <div class="card-body"><i class="fas fa-pause-circle"></i> HOLD: {{countStatusTotal('HOLD')}}</div>
            </div>
        </div>
        <div class="col-2">
            <div class="card shadow-lg mb-1" style="background-color: lightgray;">
                <div class="card-body"><i class="fas fa-stop-circle"></i> STOP: {{countStatusTotal('STOP')}}</div>
            </div>
        </div>


        <div class="col-12">
            <table mat-table [dataSource]="dataSource" matSort>

                <!-- customerCode Column -->
                <ng-container matColumnDef="CustomerCode">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> CustomerCode </th>
                    <td mat-cell *matCellDef="let customer" (click)="openCPDetailsModal(customer.CustomerID)"
                        [ngClass]="customer.CaseStatus.toLowerCase()">
                        {{customer.CustomerCode}} </td>
                </ng-container>
                <!-- customerName Column -->
                <ng-container matColumnDef="CustomerName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> CustomerName </th>
                    <td mat-cell *matCellDef="let customer" (click)="openCPDetailsModal(customer.CustomerID)"
                        [ngClass]="customer.CaseStatus.toLowerCase()">
                        {{customer.CustomerName}} </td>
                </ng-container>
                <!-- customerIC Column -->
                <ng-container matColumnDef="CustomerIC">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> CustomerIC </th>
                    <td mat-cell *matCellDef="let customer" (click)="openCPDetailsModal(customer.CustomerID)"
                        [ngClass]="customer.CaseStatus.toLowerCase()">
                        {{customer.CustomerIC}} </td>
                </ng-container>
                <!-- custOccupation Column -->
                <ng-container matColumnDef="CustOccupation">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> CustomerOccupation </th>
                    <td mat-cell *matCellDef="let customer" (click)="openCPDetailsModal(customer.CustomerID)"
                        [ngClass]="customer.CaseStatus.toLowerCase()">
                        {{customer.CustOccupation}} </td>
                </ng-container>
                <!-- caseStatus Column -->
                <ng-container matColumnDef="CaseStatus">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> CaseStatus </th>
                    <td mat-cell *matCellDef="let customer " [ngClass]="customer.CaseStatus.toLowerCase()">
                        {{customer.CaseStatus}} </td>
                </ng-container>

                <!-- officeAmount Column -->
                <ng-container matColumnDef="OfficeAmount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> OfficeAmount </th>
                    <td mat-cell *matCellDef="let customer" [ngClass]="customer.CaseStatus.toLowerCase()">
                        {{customer.OfficeAmount}} </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[100,250,300]" [pageSize]="200" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</ng-container>