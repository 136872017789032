import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { GlobalCustomer } from 'src/model/globalCustomer.model';
import { CompareCpPaymentsComponent } from '../CP/compare-cp-payments/compare-cp-payments.component';
import { ConfirmationDialogComponent } from '../Shared/confirmation-dialog/confirmation-dialog.component';
import { Router } from '@angular/router';



export interface CPTransaction {
  CustomerCode: string,
  CustomerName: string,
  CustomerIC: string,
  CustOccupation: string,
  Remarks: string,
  TransactionDate: Date,
  Amount: number,
  ConfirmedDate: Date

}
@Component({
  selector: 'app-cp-payment-sheet',
  templateUrl: './cp-payment-sheet.component.html',
  styleUrls: ['./cp-payment-sheet.component.css'],
  providers: [DatePipe]
})
export class CpPaymentSheetComponent implements OnInit, OnChanges {
  @Input() companyId: number;
  @Input() isConfirmed = true;
  displayedColumns: string[];
  dataSource = new MatTableDataSource;

  transactionList: CPTransaction[] = new Array;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  startDate: Date
  endDate: Date
  year: number
  simpanBDBalance: number

  searchedCustomer: GlobalCustomer;


  constructor(private restapi: RestApiService, private constructApi: ConstructAPI,
    public sharedService: SharedService, public enums: Enums, private datepipe: DatePipe, private router: Router) { }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.companyId) {
      this.setDefaultDate();
      this.getCompanyCPTransactions();

    }

  }

  ngOnInit(): void {

    if (this.sharedService.isBranchAccountHandler()) {
      this.displayedColumns = ['Index', 'CustomerCode', 'CustomerName', 'CustomerIC', 'CustOccupation', 'Remarks', 'TransactionDate', 'Amount', 'Action'];
    } else
      this.displayedColumns = ['Index', 'CustomerCode', 'CustomerName', 'CustomerIC', 'CustOccupation', 'Remarks', 'TransactionDate', 'Amount'];


  }

  setDefaultDate() {
    //initially set this month's payment sheet
    //start date = 1st day of this month
    //end date = last date of this month

    if (this.isConfirmed == true) {
      this.startDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      this.endDate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    }
    else {
      this.startDate = undefined;
      this.endDate = undefined;
    }
  }


  setIntoThisMonth() {
    this.year = undefined;
    this.setDefaultDate();
    this.getCompanyCPTransactions();


    this.displayedColumns = ['Index', 'CustomerCode', 'CustomerName', 'CustomerIC', 'CustOccupation', 'Remarks', 'TransactionDate', 'Amount', 'Action'];
  }

  setDateIntoThisYear() {
    this.startDate = undefined;
    this.endDate = undefined;

    if (this.isConfirmed == true) {
      this.year = new Date().getFullYear();
    }

    this.getCompanyCPTransactions();

    this.displayedColumns = ['Index', 'CustomerCode', 'CustomerName', 'CustomerIC', 'CustOccupation', 'Remarks', 'TransactionDate', 'Amount'];
  }

  setDateIntoLastYear() {
    this.startDate = undefined;
    this.endDate = undefined;

    if (this.isConfirmed == true) {
      this.year = new Date().getFullYear() - 1;
    }
    this.getCompanyCPTransactions();

    this.displayedColumns = ['Index', 'CustomerCode', 'CustomerName', 'CustomerIC', 'CustOccupation', 'Remarks', 'TransactionDate', 'Amount'];
  }

  getCompanyCPTransactions() {
    this.transactionList = [];
    //convert statt date and end date into 'dd-MM-yyyy'

    var startDateStr = (this.startDate != undefined) ? this.startDate.toISOString() : undefined;
    var endDateStr = (this.endDate != undefined) ? this.endDate.toISOString() : undefined;
    this.year = (this.startDate != undefined) ? undefined : this.year;


    var xhttp = this.restapi.getRequest(this.constructApi.getCompanyAllCPTransactions(this.companyId, this.isConfirmed, startDateStr, endDateStr, this.year))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let transaction = {
            CustomerCode: json.customerCode, CustomerName: json.customerName, CustomerIC: json.customerIC,
            CustOccupation: json.custOccupation, Remarks: json.transactionRemarks, TransactionDate: json.transactionDate,
            Amount: json.transactionAmount, ConfirmedDate: json.confirmDate
          }
          this.transactionList.push(transaction);

        }
        );


        this.refreshDataSource();
      }
    };
    this.getSimpanBDBalance();
  }


  getSimpanBDBalance() {
    this.transactionList = [];
    //convert statt date and end date into 'dd-MM-yyyy'

    // var startDateStr = (this.startDate != undefined) ? this.startDate.toISOString() : undefined;
    // var endDateStr = (this.endDate != undefined) ? this.endDate.toISOString() : undefined;
    // this.year = (this.startDate != undefined) ? undefined : this.year;

    var xhttp = this.restapi.getRequest(this.constructApi.getCompanyTransactionBalances(this.companyId, undefined, this.enums.BAD_DEBT))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          this.simpanBDBalance = json.balance;

        }
        );


      }
    };

  }


  calculateTotalAmount() {
    var total = 0;

    for (let i = 0; i < this.transactionList.length; i++) {
      total += this.transactionList[i].Amount;
    }

    return total;
  }


  refreshDataSource() {
    this.dataSource = new MatTableDataSource<CPTransaction>(this.transactionList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  addIntoSimpanBDModal(transaction: CPTransaction) {
    this.findCustomer(transaction);
  }


  findCustomer(transaction: CPTransaction) {
    this.searchedCustomer = undefined;
    if (transaction.CustomerIC != undefined)
      var icNumberFormat = this.sharedService.sanitizeICNumInput(transaction.CustomerIC);
    var xhr;

    xhr = this.restapi.getRequest(this.constructApi.getCompanyProfileSearch(this.companyId, undefined, icNumberFormat, undefined));

    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {

        if (xhr.status == 200) {
          var jsonArray = JSON.parse((xhr.responseText));

          if (jsonArray.length == 0) {
            this.sharedService.openAlert("Customer not found!", this.enums.DANGER_ALERT)
          }
          else {

            jsonArray.forEach(json => {
              if (json.status != undefined)
                var status = this.sharedService.processStatusCode(json.status);



              this.searchedCustomer = new GlobalCustomer(json.name, json.icNumber, json.companyName, json.customerId, json.status, json.occupation,
                json.id, json.customerCode, json.isLead);


              if (Number(status) == Number(this.enums.CP_CUST)) {
                //open compare cp payment modal
                var modalRef = this.sharedService.openModal(CompareCpPaymentsComponent, "regularModal");
                modalRef.componentInstance.selectedCustomerName = this.searchedCustomer.Name;
                modalRef.componentInstance.selectedCustomerId = this.searchedCustomer.CustomerID;
                modalRef.componentInstance.selectedProfileId = this.searchedCustomer.ProfileID;
                modalRef.componentInstance.transaction = transaction;
              }
              else {
                this.confirmBeforeRedirect(this.searchedCustomer.CustomerID, this.searchedCustomer.ProfileID);
              }

            }
            );



          }

        }
        else {

          this.sharedService.openAlert("Error! " + JSON.parse((xhr.responseText)).error, this.enums.DANGER_ALERT)

        }



      }
    }

  }


  confirmBeforeRedirect(customerId: number, profileId: number) {

    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "This customer does not have any CP profile in system, would you like to check this customer?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something

      if (confirmation == true)
        this.router.navigate(['/manageCustomer', customerId, profileId]);
    });
  }

  goToSimpanBDBalanceReport() {
    this.router.navigate(['/simpleReport', this.companyId, this.enums.SIMPAN_BD_REPORT]);
  }
}
