import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { RestApiService } from 'src/app/API/restapi';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { Account } from 'src/model/account.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddAccountComponent } from 'src/app/AddThings/add-account/add-account.component';
import { AddIncomeComponent } from 'src/app/AddThings/add-income/add-income.component';
import { AddExpensesComponent } from 'src/app/AddThings/add-expenses/add-expenses.component';
import { Transaction } from 'src/model/transaction.model';
import { AddAssetComponent } from 'src/app/AddThings/add-asset/add-asset.component';
import { TransferFundComponent } from 'src/app/AddThings/transfer-fund/transfer-fund.component';
import { TransactionHistoryComponent } from '../transaction-history/transaction-history.component';
import { AddCapitalComponent } from 'src/app/AddThings/add-capital/add-capital.component';
import { AddSavingComponent } from 'src/app/AddThings/add-saving/add-saving.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { AddTotingComponent } from 'src/app/AddThings/add-toting/add-toting.component';

@Component({
  selector: 'app-account-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.css']
})
export class AccountListComponent implements OnInit {
  companyBankAccountList: Account[] = new Array;
  cashAccountList: Account[] = new Array;

  // extrasTransactionList: Transaction[] = new Array;
  duitLebihBalance: number;

  loggedInRole: number;
  constructor(private cdr: ChangeDetectorRef, private restApi: RestApiService, private constructApi: ConstructAPI,
    private modalService: NgbModal, public enums: Enums, public sharedService: SharedService) {

  }

  ngOnInit(): void {

    var companyId = Number(sessionStorage.getItem('companyId'));
    this.loggedInRole = Number(sessionStorage.getItem('roleId'));

    this.getAllAccountsOfCompany(companyId);

    if (this.loggedInRole == this.enums.BRANCH_MANAGER)
      this.getExtraBalance(companyId);
  }


  private getAllAccountsOfCompany(companyId: number) {

    var xhttp = this.restApi.getRequest(this.constructApi.getAccountsOfCompany(companyId));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {

          var amount = (json.balance == undefined) ? 0 : json.balance;
          let account = new Account(json.id, json.number, json.name, json.holderName, amount, json.companyId, json.username);

          if (account.Username != undefined)
            this.cashAccountList.push(account)
          else {
            this.companyBankAccountList.push(account);
          }


        }
        );

      }
    };
  }

  getExtraBalance(companyId: number) {

    var reduceASecDate = new Date(Number(new Date()) - 1);
    var endDateStr = reduceASecDate.toISOString();

    var xhttp = this.restApi.getRequest(this.constructApi.getCompanyTransactionBalances(companyId, endDateStr, this.enums.EXTRAS));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {


        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {

          var balance = Number(json.balance);
          this.duitLebihBalance = balance;
        }
        );


      }
    };
  }
  // getExtraBalance(companyId: number) {
  //   var xhttp = this.restApi.getRequest(this.constructApi.getExtraBalance(companyId));
  //   xhttp.onreadystatechange = () => {
  //     if (xhttp.readyState == 4 && xhttp.status == 200) {

  //       var json = JSON.parse((xhttp.responseText));
  //       // console.log(jsonArray);

  //       this.duitLebihBalance = json.balance;
  //     }
  //   };

  // }

  // calculateExtrasTotal() {
  //   var total = 0;
  //   for (let i = 0; i < this.extrasTransactionList.length; i++) {
  //     total += this.extrasTransactionList[i].Amount;
  //   }
  //   return total;
  // }


  openTransactionHistoryModal(accountId: number) {
    const modalRef = this.sharedService.openModal(TransactionHistoryComponent, "largeModal");
    modalRef.componentInstance.accountId = accountId;
  }

  // openDuitLebihTransactionModal() {
  //   const modalRef = this.sharedService.openModal(TransactionHistoryComponent, { windowClass: "largeModal" });
  //   modalRef.componentInstance.transactionList = this.extrasTransactionList;
  // }

  openAddAccountDialog() {
    const modalRef = this.sharedService.openModal(AddAccountComponent);
    //get account that was created
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var account = entry;

      //append added account to account list
      this.companyBankAccountList.push(account);
    });

    modalRef.result.then(() => {
      this.cdr.detectChanges();
    })

  }




}
