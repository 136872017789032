<div class="row" *ngIf="checkIfAllowApproval()">
    <div class="col">
        <div><span>Remark</span></div>
        <div><textarea class="form-control" rows="5" required [(ngModel)]="remark" name="remark"></textarea>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="confirmBeforeAction('approve')"
            [disabled]="disabledSubmit">Approve</button>
        <button class="btn btn-outline-danger" type="button" data-dismiss="modal" (click)="confirmBeforeAction('deny')"
            [disabled]="disabledSubmit">Deny</button>
    </div>
</div>