<form>
  <!-- Modal Header -->
  <div class="modal-header">
    <h4 class="modal-title">Add Transaction</h4>
    <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()" aria-label="Close"></button>
  </div>

  <div class="modal-body">

    <div class="container-fluid" style="padding: 2%;">
      <div class="card">
    
        <div class="card-body">
          <div class="d-flex align-items-xl-center" style="margin-bottom: 2%;">
            <select class="d-flex" style="font-family: 'Alegreya Sans SC', sans-serif;font-size: 20px;width: 95%;">
              <optgroup label="This is a group">
                <option value="12" selected="">Bank Account 1</option>
                <option value="13">Bank Account 2</option>
                <option value="14">Bank Account 3</option>
              </optgroup>
            </select>
            <button class="btn btn-primary" type="button" style="margin-left: 2%;background-color: #f0ad4e;"><i
                class="fa fa-plus"></i></button>
          </div>
          <div class="row">
            <div class="col-12 align-items-center">
              <input type="date" style="margin-bottom: 2%;">
              <select class="browser-default form-select">
                <!-- <optgroup label="This is a group"> -->
                  <option value="12" selected="">In</option>
                  <option value="13">Out</option>
                <!-- </optgroup> -->
              </select>
              <div><input type="number" placeholder="Amount"></div>
            </div>
            <div class="col-12">
              <p style="font-family: 'Alegreya Sans SC', sans-serif;margin: 0;"><strong>Remarks:</strong></p><textarea
                style="width: 100%;" rows="2"></textarea>
            </div>
          </div>
          <div class="d-flex justify-content-end"><button class="btn btn-outline-success" type="button"
              style="margin-right: 2%;">Add</button><button class="btn btn-outline-danger" type="button">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>