<div class="container-fluid" style="padding: 0;">
    <div class="row">
        <div class="col-12"
            style="background-image: url('../../assets/image/loginBackground.jpg');background-repeat: no-repeat;background-size: contain;background-position: center;">
            <div></div>
        </div>
        <div class="col-12 login-clean" style="height: 100vh;/*background-color: #9e00ff;*/">
            <form method="post" style="background-color: rgba(20,20,20,0.66);">
                <h2 class="sr-only">Login Form</h2>
                <div class="illustration"><img src="../../assets/image/logo.png" width="150px" height="150px"></div>
                <div class="mb-3"><input class="form-control" type="text" placeholder="Username" autofocus=""
                        required="" name="username" [(ngModel)]="username" (ngModelChange)="checkEnableSubmit()"></div>
                <div><input class="form-control" type="password" name="password"
                        placeholder="Password" required="" [(ngModel)]="password" (ngModelChange)="checkEnableSubmit()"></div>
                <div class="d-grid mb-3"><button class="btn btn-primary" (click)="login()"
                        [disabled]="disabledSubmit">Log In</button></div>
                <!-- <a class="forgot">Register</a> -->
            </form>
        </div>
    </div>
</div>