import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditTransactionComponent } from 'src/app/Account/edit-transaction/edit-transaction.component';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { LoanCardComponent } from 'src/app/Loans/loan-card/loan-card.component';
import { LoanDetailsComponent } from 'src/app/Loans/loan-details/loan-details.component';
import { AlertDialogComponent } from 'src/app/Shared/alert-dialog/alert-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Loan } from 'src/model/loan.model';


interface CashBookReport {
  loanId: number,
  customerId: number,
  customerName: string,
  customerCode: string,
  date: Date,
  receiptNumber: string,
  totalIn: number,
  totalOut: number,
  scheme: string,
  outstanding?: number,
  cashBookList: CashBook[],
  collapsed: boolean,
  balance: number,
  interestRate?: number,
  includeInterest: boolean,
  includePrincipal: boolean
}

interface CashBook {
  transactionId: number,
  type: string,
  remark: string,
  amount: number,
}


@Component({
  selector: 'app-cash-book-report',
  templateUrl: './cash-book-report.component.html',
  styleUrls: ['./cash-book-report.component.css']
})
export class CashBookReportComponent implements OnInit, OnDestroy {
  loggedInRole: number;

  constructor(private activatedRoute: ActivatedRoute, public enums: Enums, private modalService: NgbModal, private router: Router,
    private restapi: RestApiService, private constructApi: ConstructAPI, private sharedService: SharedService) {
    this.loggedInRole = Number(sessionStorage.getItem("roleId"));
  }

  cashBookList: CashBook[] = new Array;
  cashBookBeforeList: CashBook[] = new Array;
  cashBookReportList: CashBookReport[] = new Array;
  loanDetailsIds: Number[] = new Array;

  @Input() selectedCompany: number;
  @Input() startDate: Date;
  @Input() endDate: Date;

  isCollapsed: boolean = false;
  carryFowardBalance: number = 0;
  currentBalance: number = 0;
  routeSubscription: any;


  ngOnInit(): void {

    this.routeSubscription = this.activatedRoute.paramMap.subscribe(params => {
      this.selectedCompany = + params.get('companyId');
      this.startDate = (params.get('startDate') == undefined) ? undefined : new Date(params.get('startDate'));
      this.endDate = (params.get('endDate') == undefined) ? undefined : new Date(params.get('endDate'));


      this.cashBookBeforeList.splice(0, this.cashBookBeforeList.length);
      this.cashBookReportList.splice(0, this.cashBookReportList.length);
      this.getCarryForwardBalance();
    });

  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }



  getCarryForwardBalance() {
    // var total = 0;
    // for (let i = 0; i < this.cashBookBeforeList.length; i++) {
    //   total += this.cashBookBeforeList[i].amount;
    // }
    // return total;

    if (this.startDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var reduceASecDate = new Date(Number(this.startDate) - 1);
    var startDate = (this.startDate == undefined) ? undefined : reduceASecDate.toISOString();

    var xhttp = this.restapi.getRequest(this.constructApi.getCompanyCashBookBalance(this.selectedCompany, startDate))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var json = JSON.parse((xhttp.responseText));
        this.carryFowardBalance = Number(json.balance);


        this.getCashBookReport();
      }
      else if (xhttp.readyState == 4 && xhttp.status == 404) {
        this.sharedService.openAlert("Report cannot be generated because do not have any amount before this date", this.enums.DANGER_ALERT)

      }
    };

  }



  getCashBookReport() {

    var startDateStr = (this.startDate == undefined) ? undefined : this.startDate.toISOString();

    if (this.endDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var addADayDate = new Date(Number(this.endDate) + (3600 * 1000 * 24) - 1);

    var endDateStr = (this.endDate == undefined) ? undefined : addADayDate.toISOString();

    var includedTypes = [this.enums.PRINCIPAL, this.enums.REPAYMENT, this.enums.PROCESSING_CHARGE, this.enums.ARREAR_CHARGES, this.enums.DISCOUNT, this.enums.MONTHLY_INTEREST, this.enums.EXTRA_INTEREST];
    var xhttp = this.restapi.getRequest(this.constructApi.getCompanyAllLoansTransactions(this.selectedCompany, startDateStr, endDateStr, null, includedTypes))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          let cashList = { transactionId: json.id, type: json.type, remark: json.remark, amount: json.amount }
          // if (new Date(json.date) < new Date(this.startDate)) {
          //   this.cashBookBeforeList.push(cashList);
          // }
          // else {

          //find same receipt in cashbookreport list to group 
          var index = (this.cashBookReportList.length > 0) ? this.cashBookReportList.findIndex(loan => loan.receiptNumber === json.receiptNumber && loan.date === json.date) : -1;

          //if found
          if (index != -1) {

            //add the amount into total in / total out
            if (json.amount < 0) {
              this.cashBookReportList[index].totalOut += json.amount;
            }
            else {
              this.cashBookReportList[index].totalIn += json.amount;
            }


            //mark whether this group include PRINCIPAL & INTEREST RELATED type

            if (json.type == this.enums.EXTRA_INTEREST || json.type == this.enums.MONTHLY_INTEREST || json.type == this.enums.ARREAR_CHARGES || json.type == this.enums.PROCESSING_CHARGE || json.type== this.enums.BOUNTY)
              this.cashBookReportList[index].includeInterest = true;

            else if (json.type == this.enums.PRINCIPAL)
              this.cashBookReportList[index].includePrincipal = true;


            this.cashBookReportList[index].cashBookList.push(cashList);
          }

          //if not found
          else {
            var cashReport;
            var cashBookList: CashBook[] = new Array;
            cashBookList.push(cashList);

            //mark whether this group include PRINCIPAL & INTEREST RELATED type
            if (json.type == this.enums.EXTRA_INTEREST || json.type == this.enums.MONTHLY_INTEREST || json.type == this.enums.ARREAR_CHARGES || json.type == this.enums.PROCESSING_CHARGE || json.type== this.enums.BOUNTY)
              var includeInterest = true;
            else if (json.type == this.enums.PRINCIPAL)
              var includePrincipal = true;
            else {
              includePrincipal = false;
              includeInterest = false;
            }


            // Create new CASHBOOK REPORT LIST according to the amount
            if (json.amount < 0) {
              cashReport = {
                loanId: json.loanId, customerId: json.customerId, customerName: json.customerName, customerCode: json.customerCode, date: json.date,
                receiptNumber: json.receiptNumber, totalIn: 0, totalOut: json.amount, scheme: json.scheme, outstanding: 0, cashBookList: cashBookList, collapsed: true, interestRate: json.interestRate, includeInterest: includeInterest,
                includePrincipal: includePrincipal
              }
            } else {
              cashReport = {
                loanId: json.loanId, customerId: json.customerId, customerName: json.customerName, customerCode: json.customerCode, date: json.date,
                receiptNumber: json.receiptNumber, totalIn: json.amount, totalOut: 0, scheme: json.scheme, outstanding: 0, cashBookList: cashBookList, collapsed: true, interestRate: json.interestRate, includeInterest: includeInterest,
                includePrincipal: includePrincipal
              }
            }


            this.cashBookReportList.push(cashReport);
          }


          // this.getLoanDetails(json.loanId, json.date);
          // }
          this.loanDetailsIds.push(Number(json.loanId));
        }
        );
        this.getLoanDetails(this.loanDetailsIds);
        this.getAllExpenses();
      }
    };

  }

  changeCollapseDown(cashBookReport: CashBookReport) {

    var index = (this.cashBookReportList.length > 0) ? this.cashBookReportList.findIndex(loan => loan.receiptNumber === cashBookReport.receiptNumber && loan.date === cashBookReport.date) : -1;
    if (index != -1)
      this.cashBookReportList[index].collapsed = false;
  }

  changeCollapseUp(cashBookReport: CashBookReport) {

    var index = (this.cashBookReportList.length > 0) ? this.cashBookReportList.findIndex(loan => loan.receiptNumber === cashBookReport.receiptNumber && loan.date === cashBookReport.date) : -1;
    if (index != -1)
      this.cashBookReportList[index].collapsed = true;
  }


  getAmountStyle(amount: number) {
    if (amount == 0)
      return 'normalText';
    else if (amount > 0)
      return 'positiveText';
    else
      return 'negativeText'
  }

  getAllExpenses() {

    var startDateStr = (this.startDate == undefined) ? undefined : this.startDate.toISOString();

    if (this.endDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var addADayDate = new Date(Number(this.endDate) + (3600 * 1000 * 24) - 1);
    var endDateStr = (this.endDate == undefined) ? undefined : addADayDate.toISOString();

    var xhttp = this.restapi.getRequest(this.constructApi.getAllTransactions(this.selectedCompany, [this.enums.EXPENSE, this.enums.SAVING, this.enums.CAPITAL, this.enums.TOTING,this.enums.BOUNTY], startDateStr, endDateStr))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          // let cashList = { date: json.date, receiptNumber: json.receiptNumber, type: json.type, remark: json.remark, principal: undefined, rate: undefined, amount: json.amount, scheme: undefined, customerId: undefined, loanId: json.loanId }
          let cashList = { transactionId: json.id, type: json.type, remark: json.remark, amount: json.amount }

          var index = (this.cashBookReportList.length > 0) ? this.cashBookReportList.findIndex(report => report.receiptNumber == "EXPENSE" && (new Date(report.date)).toDateString() === (new Date(json.date)).toDateString()) : -1;
          if (index != -1) {
            this.cashBookReportList[index].totalOut += json.amount;
            this.cashBookReportList[index].cashBookList.push(cashList);
          } else {
            var cashReport;
            var cashBookList: CashBook[] = new Array;
            cashBookList.push(cashList);

            cashReport = {
              loanId: json.loanId, customerId: json.customerId, customerName: "EXPENSE & Others", date: new Date(json.date),
              receiptNumber: "", totalIn: 0, totalOut: json.amount, scheme: json.scheme, outstanding: 0, cashBookList: cashBookList, collapsed: true
            }
            this.cashBookReportList.push(cashReport);
          }

        }
        );
        this.sortList();
        // this.currentBalance = this.carryFowardBalance;
        this.calculateEachBalance();
      }
    };
  }



  getRowColor(customerName: string) {
    if (customerName == "EXPENSE")
      return "expenseCol"
    else
      return "normalCol"

  }

  goToCustomer(customerId: number) {
    this.router.navigate(['/manageCustomer', customerId]);
  }

  sortList() {
    this.cashBookReportList.sort((a, b) => (new Date(a.date) < new Date(b.date) ? -1 : 1));
  }

  sortByCriteria(criteria: string) {
    switch (criteria) {
      case 'Receipt':
        this.cashBookReportList.sort((a, b) => (a.receiptNumber < b.receiptNumber ? -1 : 1));
        break;
      case 'CustomerName':
        this.cashBookReportList.sort((a, b) => (a.customerName < b.customerName ? -1 : 1));
        break;
      case 'Code':
        this.cashBookReportList.sort((a, b) => (a.customerCode < b.customerCode ? -1 : 1));
        break;
      case 'Outstanding':
        this.cashBookReportList.sort((a, b) => (Number(a.outstanding) < Number(b.outstanding) ? -1 : 1));
        break;
      case 'In':
        this.cashBookReportList.sort((a, b) => (Number(a.totalIn) < Number(b.totalIn) ? -1 : 1));
        break;
      case 'Out':
        this.cashBookReportList.sort((a, b) => (Number(a.totalOut) < Number(b.totalOut) ? -1 : 1));
        break;

    }

  }

  getLoanDetails(ids: Number[]) {
    var xhttp = this.restapi.getRequest(this.constructApi.getPaidLoanDetail(undefined, ids))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          var index = (this.cashBookReportList.length > 0) ? this.cashBookReportList.findIndex(loan => loan.loanId === json.id) : -1;
          if (index != -1) {
            if (this.cashBookReportList[index].scheme == "A") {
              this.cashBookReportList[index].outstanding = json.principal - json.principalPaid;
            }
            else
              this.cashBookReportList[index].outstanding = ((json.principal * (json.interestRate / 100) * json.term) + json.principal) - json.repayment;
          }

        }
        );
      }
    };
  }

  calculateEachBalance() {
    var currentBalance = this.carryFowardBalance;
    for (let i = 0; i < this.cashBookReportList.length; i++) {
      currentBalance += this.cashBookReportList[i].totalIn + this.cashBookReportList[i].totalOut;
      this.cashBookReportList[i].balance = currentBalance;
    }
  }

  openLoanCard(id: number) {
    const modalRef = this.sharedService.openModal(LoanDetailsComponent, 'regularModal');
    var loan = new Loan(id, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
    modalRef.componentInstance.selectedLoan = loan;
  }

  openEditTransactionDialog(transactionId: number, amount: number, remark: string) {
    const modalRef = this.sharedService.openModal(EditTransactionComponent);
    modalRef.componentInstance.transactionId = transactionId;
    modalRef.componentInstance.amount = amount;
    modalRef.componentInstance.remark = remark;
    //get account that was created
    modalRef.componentInstance.passEntry.subscribe((entry) => {

      window.location.reload();
    });


  }

}
