<form (keyup.arrowright)="changeNextImage()" (keyup.arrowleft)="changePreviousImage()">
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">{{fileName}}</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body" style="height:60vh !important">
        <div class="card shadow-lg" *ngIf="customer!=undefined">
            <div class="card-body p-1">
                <p class="p-0"><strong>{{customer.Name}}</strong></p>
                <p class="p-0"><strong>{{ sharedService.processICNum(customer.ICNumber) }} | {{customer.DOB |
                        date:'dd-MM-yyyy'}}</strong></p>
            </div>
        </div>
        <div style="position: absolute; top:0%; bottom: 0; left: 0; right: 0;">

            <pan-zoom [config]="panZoomConfig">

                <div style="position: relative;">

                    <img [src]="image" id="enlargedImage">

                </div>

            </pan-zoom>
        </div>
    </div>
    <div class="modal-footer">
        <div class="d-flex align-items-left justify-content-start" *ngIf="fileList.length>1 && fileList!=undefined">
            <button class="btn btn-dark " (click)="changePreviousImage()"><i class="fas fa-caret-left"></i></button>
            <button class="btn btn-dark ms-1" (click)="changeNextImage()"><i class="fas fa-caret-right"></i></button>
        </div>


        <button class="btn btn-danger" *ngIf="checkCanDelete()" (click)="confirmDeletePicture()"><i
                class="fas fa-trash-alt"></i> Delete</button>

        <button class="btn btn-dark" (click)="rotate()"><i class="fas fa-undo"></i> Rotate</button>
        <p class="text-danger fw-bold"> Scroll/Pinch to zoom </p>
    </div>

</form>