<div class="container-fluid p-3">
    <div class="d-flex justify-content-end align-items-end">
        <button class="btn btn-info" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseExample">
            <i class="fas fa-chevron-circle-down" *ngIf="isCollapsed"></i>
            <i class="fas fa-chevron-circle-up" *ngIf="isCollapsed==false"></i>
        </button>
    </div>



    <div class="collapse" id="collapseExample" [ngbCollapse]="isCollapsed">
        <app-report-generator></app-report-generator>
    </div>

    <h3 class="text-start" id="pageTitle" style="margin: 15px;">Customer Report</h3>
    <div class="col p-2" style="background-color:lavender ;">
        <mat-slide-toggle class="mat-slide-toggle-lg" [hideIcon]="true" color="primary" [(ngModel)]="hideSettled" name="hideSettled"
            (change)=" hideSettledLoan()">
            Hide Settled
        </mat-slide-toggle>
    </div>
    <div class="content m-3">

        <div class="mat-elevation-z8">
            <div class="table-container">
                <table mat-table [dataSource]="dataSource" matSort>

                    <!-- Code Column -->
                    <ng-container matColumnDef="customerCode">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="position:sticky"> Code
                        </th>
                        <td mat-cell *matCellDef="let report" (click)="goToCustomer(report.customer.ID)"
                            style="cursor:pointer;" class="customerCol">
                            {{report.customerCode }}

                        </td>
                        <td mat-footer-cell *matFooterCellDef style="position:sticky"></td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="customerName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="position:sticky"> Customer Name
                        </th>
                        <td mat-cell *matCellDef="let report" (click)="goToCustomer(report.customer.ID)"
                            style="cursor:pointer;" class="customerCol">
                            <ng-template #tooltipContent>
                                <p class="text-start"> {{report.customer.Name }}
                                </p>
                                <p class="text-start"> {{sharedService.processICNum(report.customer.ICNumber) }} =
                                    {{report.customer.Occupation }}
                                </p>
                            </ng-template>
                            <i class="fas fa-info-circle" placement="top" [ngbTooltip]="tooltipContent"
                                style="cursor:pointer;"></i>

                            {{report.customerName }}


                        </td>
                        <th mat-footer-cell *matFooterCellDef style="position:sticky">
                            Count: {{reportList.length}}
                        </th>
                    </ng-container>

                    <!-- IC Column -->
                    <!-- <ng-container matColumnDef="icNumber">
                    <th mat-header-cell class="text-center" *matHeaderCellDef mat-sort-header> ICNumber </th>
                    <td mat-cell *matCellDef="let report" class="text-center" (click)="goToCustomer(report.CustomerId)">
                        {{sharedService.processICNum(report.customer.ICNumber)}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container> -->



                    <!-- Supposed Column -->
                    <ng-container matColumnDef="supposed">
                        <th mat-header-cell class="text-center" *matHeaderCellDef mat-sort-header
                            style="position:sticky">
                            Supposed </th>
                        <td mat-cell *matCellDef="let report">

                            <ng-template #tooltipContent>
                                <ng-container *ngFor="let loan of report.loans">
                                    <p *ngIf="loan.Scheme=='A' && loan.Principal>0" class="text-start">RM
                                        {{loan.Principal
                                        *(loan.InterestRate/100)| number:'1.0-2'}} =
                                        ({{loan.Scheme}} -{{loan.InterestRate |
                                        number:'1.0-0'}}%) RM{{loan.Principal| number:'1.0-2'}}
                                    </p>

                                    <p *ngIf="loan.Scheme!='A' && loan.TermLeft>1" class="text-start">RM
                                        {{loan.MonthlyPayment|number:'1.0-2'}}
                                        =({{loan.Scheme}}) RM{{loan.MonthlyPayment| number:'1.0-2'}} x {{loan.Term}})
                                    </p>

                                </ng-container>
                            </ng-template>
                            {{report.supposed |number:'1.2-2'}}
                            <i class=" far fa-question-circle ms-2" placement="right" [ngbTooltip]="tooltipContent"
                                style="cursor:pointer;" *ngIf="report.supposed>0"></i>
                        </td>
                        <th mat-footer-cell *matFooterCellDef class="text-center">{{
                            calculateTotalSupposed()|number:'1.2-2'}}</th>
                    </ng-container>

                    <!-- Actual Column -->
                    <ng-container matColumnDef="actual">
                        <th mat-header-cell class="text-center" *matHeaderCellDef mat-sort-header
                            style="position:sticky">
                            Actual </th>
                        <td mat-cell *matCellDef="let report" class="text-center"
                            [ngClass]="{'danger-col':report.actual < report.supposed && report.supposed!=0,'ok-col':report.actual>=report.supposed&& report.supposed!=0}">
                            {{report.actual}}
                        </td>
                        <th mat-footer-cell *matFooterCellDef class="text-center">
                            {{calculateTotalActual() | number:'1.1-1'}}
                        </th>
                    </ng-container>

                    <!-- Processing Column -->
                    <ng-container matColumnDef="processing">
                        <th mat-header-cell class="text-center" *matHeaderCellDef mat-sort-header
                            style="position:sticky">
                            Processing </th>
                        <td mat-cell *matCellDef="let report" class="text-center">
                            {{report.processing | number:'1.1-1'}}
                        </td>
                        <th mat-footer-cell *matFooterCellDef class="text-center">{{calculateTotalProcessing() |
                            number:'1.1-1'}}</th>
                    </ng-container>

                    <!-- Principal In Column -->
                    <ng-container matColumnDef="principalIn">
                        <th mat-header-cell class="text-center" *matHeaderCellDef mat-sort-header
                            style="position:sticky">
                            Principal In </th>
                        <td mat-cell *matCellDef="let report" class="text-center"
                            [ngClass]="{'text-success':report.principalIn>0}">
                            {{report.principalIn | number:'1.1-1'}}
                        </td>
                        <th mat-footer-cell *matFooterCellDef class="text-center"> {{calculateTotalPrincipalIn() |
                            number:'1.1-1'}}</th>
                    </ng-container>

                    <!-- Principal Out Column -->
                    <ng-container matColumnDef="principalOut">
                        <th mat-header-cell class="text-center" *matHeaderCellDef mat-sort-header
                            style="position:sticky">
                            Principal Out </th>
                        <td mat-cell *matCellDef="let report" class="text-center"
                            [ngClass]="{'text-danger':report.principalOut>0}">
                            {{report.principalOut | number:'1.1-1'}}
                        </td>
                        <th mat-footer-cell *matFooterCellDef class="text-center"> {{calculateTotalPrincipalOut() |
                            number:'1.1-1'}}</th>
                    </ng-container>

                    <!-- Salary Column -->
                    <ng-container matColumnDef="salary">
                        <th mat-header-cell class="text-center" *matHeaderCellDef mat-sort-header
                            style="position:sticky">
                            Salary </th>
                        <td mat-cell *matCellDef="let report">
                            {{report.customer.Salary | number:'1.0-0'}}
                        </td>

                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <!-- PrincipalBalance Column -->
                    <ng-container matColumnDef="principalBalance">
                        <th mat-header-cell class="text-center" *matHeaderCellDef mat-sort-header
                            style="position:sticky">
                            Principal Balance </th>
                        <td mat-cell *matCellDef="let report" class="text-center">
                            {{report.principalBalance | number:'1.0-1'}}
                        </td>
                        <th mat-footer-cell *matFooterCellDef class="text-center"> {{calculateTotalPrincipalBalance() |
                            number:'1.1-1'}}</th>
                    </ng-container>

                    <!-- Ratio Column -->
                    <ng-container matColumnDef="ratio">
                        <th mat-header-cell class="text-center" *matHeaderCellDef mat-sort-header
                            style="position:sticky">
                            Ratio </th>
                        <td mat-cell *matCellDef="let report" class="text-center">
                            {{report.ratio | number:'1.2-2'}}
                        </td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <!-- SalaryUsage Column -->
                    <ng-container matColumnDef="salaryUsage">
                        <th mat-header-cell class="text-center" *matHeaderCellDef mat-sort-header
                            style="position:sticky">
                            Salary Usage</th>
                        <td mat-cell *matCellDef="let report" class="text-center">
                            {{report.salaryUsage*100 | number:'1.0-0'}}%
                        </td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <!-- NextSalaryUsage Column -->
                    <ng-container matColumnDef="nextSalaryUsage">
                        <th mat-header-cell class="text-center" *matHeaderCellDef mat-sort-header
                            style="position:sticky">
                            Next Salary Usage</th>
                        <td mat-cell *matCellDef="let report" class="text-center">
                            {{report.nextSalaryUsage*100 | number:'1.0-0'}}%
                        </td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>


                    <!-- EPFAmount Column -->
                    <ng-container matColumnDef="epfAmount">
                        <th mat-header-cell class="text-center " *matHeaderCellDef mat-sort-header
                            style="position:sticky">
                            EPF Amount</th>
                        <td mat-cell *matCellDef="let report" class="text-center "
                            [ngClass]="{'epfDividerCol':report.epfBalance>0}">
                            <ng-container *ngIf="containsEPFLoan(report)==true">{{report.epfAmount |
                                number:'1.2-2'}}</ng-container>

                        </td>
                        <th mat-footer-cell *matFooterCellDef class="text-center">
                            <!-- {{ calculateTotalEPFAmount() |number:'1.2-2'}} -->
                        </th>
                    </ng-container>

                    <!-- EPFBalance Column -->
                    <ng-container matColumnDef="epfBalance">
                        <th mat-header-cell class="text-center " *matHeaderCellDef mat-sort-header
                            style="position:sticky">
                            EPF Balance</th>
                        <td mat-cell *matCellDef="let report" class="text-center "
                            [ngClass]="{'epfDividerCol':report.epfBalance>0}">
                            {{report.epfBalance | number:'1.2-2'}}

                            <ng-template #tooltipContent>
                                <ng-container *ngFor="let loan of report.loans">
                                    <p class="text-start fw-bold">*LEGACY EPF* </p>
                                    <p> {{loan.Scheme}}- RM{{loan.Principal}}</p>
                                </ng-container>
                            </ng-template>
                            <span class="text-danger" *ngIf="report.includeLegacyEPF" placement="top"
                                [ngbTooltip]="tooltipContent" style="cursor:pointer;"><i
                                    class="fas fa-exclamation-circle"></i></span>
                        </td>
                        <th mat-footer-cell *matFooterCellDef class="text-center">{{
                            calculateTotalEPFBalance()|number:'1.2-2'}}</th>
                    </ng-container>

                    <!-- EPF LOAN RATIO Column -->
                    <ng-container matColumnDef="epfLoanRatio">
                        <th mat-header-cell class="text-center " *matHeaderCellDef mat-sort-header
                            style="position:sticky">
                            EPF Ratio</th>
                        <td mat-cell *matCellDef="let report" class="text-center "
                            [ngClass]="{'epfDividerCol':report.epfBalance>0,'danger-col':report.epfLoanRatio >23}">
                            {{report.epfLoanRatio | number:'1.2-2'}} %
                        </td>
                        <th mat-footer-cell *matFooterCellDef class="text-center"></th>
                    </ng-container>

                    <!-- EPFDate Column -->
                    <ng-container matColumnDef="epfDate">
                        <th mat-header-cell class="text-center" *matHeaderCellDef mat-sort-header
                            style="position:sticky">
                            EPF Date</th>
                        <td mat-cell *matCellDef="let report" class="text-center"
                            [ngClass]="{'epfDividerCol':report.epfBalance>0}">
                            <ng-container *ngIf="containsEPFLoan(report)==true">{{report.epfDate |
                                date:'dd-MM-yyyy'}}</ng-container>
                        </td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <!-- LastPayment Column -->
                    <ng-container matColumnDef="lastPaymentDate">
                        <th mat-header-cell class="text-center" *matHeaderCellDef mat-sort-header
                            style="position:sticky">
                            Last Payment</th>
                        <td mat-cell *matCellDef="let report" class="text-center">
                            {{report.lastPaymentDate | date:'dd-MM-yyyy'}}
                        </td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>


                    <!-- P&L Column -->
                    <ng-container matColumnDef="pnl">
                        <th mat-header-cell class="text-center" *matHeaderCellDef mat-sort-header
                            style="position:sticky">
                            P&L</th>
                        <td mat-cell *matCellDef="let report" class="text-center"
                            [ngClass]="{'danger-col':report.pnl<0,'ok-col':report.pnl>=0}">
                            {{report.pnl | number:'1.2-2'}}
                        </td>
                        <th mat-footer-cell *matFooterCellDef class="text-center">{{calculateTotalPnl()|number:'1.2-2'}}
                        </th>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef=" displayedColumns;sticky: true">
                    </tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns;sticky: true"></tr>
                </table>
            </div>


            <!-- <mat-paginator [pageSizeOptions]="[25,50, 100]" showFirstLastButtons></mat-paginator> -->
        </div>
    </div>
</div>