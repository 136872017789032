import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { CheckInHistoryComponent } from '../check-in-history/check-in-history.component';


interface Usage {
  CompanyCode: string,
  CompanyId: number,
  LastInsertedDate: Date,
  LatestDate: Date,
  LastManagerCheckedDate?: Date,
  LastAdminCheckedDate?: Date,
}

@Component({
  selector: 'app-usage-report',
  templateUrl: './usage-report.component.html',
  styleUrls: ['./usage-report.component.css']
})
export class UsageReportComponent implements OnInit {

  usageList: Usage[] = new Array;

  displayedColumns: string[];
  dataSource = new MatTableDataSource;

  innerWidth: any;
  showRemark: string;
  selectedStatus: number;
  isCollapsed = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;


  constructor(private restapi: RestApiService, private constructAPI: ConstructAPI, private router: Router, private sharedService: SharedService, public enums: Enums) { }

  ngOnInit(): void {

    this.displayedColumns = ['CompanyCode', "LastInsertedDate", "LatestDate", "LastManagerCheckedDate", "LastAdminCheckedDate"];

    this.getAllUsageStats(false);
  }


  getWarningStatus(lastInsertedDate: Date) {
    if (lastInsertedDate != undefined) {
      var diff = Math.abs(new Date().getTime() - new Date(lastInsertedDate).getTime());
      var diffDays = Math.ceil(diff / (1000 * 3600 * 24));
      if (diffDays > 4)
        return 'salmon';
      else if (diffDays > 2)
        return 'lightyellow';
      else
        return 'white';
    } else {
      return 'salmon'
    }

  }



  refresh() {
    this.usageList = this.usageList.splice(0, this.usageList.length);

    this.displayedColumns = ['CompanyCode', "LastInsertedDate", "LatestDate", "LastManagerCheckedDate", "LastAdminCheckedDate"];

    this.getAllUsageStats(true);

  }

  toDailyReport(companyId: number, date: Date) {

    var newDate = new Date(new Date(date).setHours(0, 0, 0));
    var dateStr = newDate.toISOString();
    this.router.navigate(['/dailyReport', companyId, dateStr]);
  }

  getAllUsageStats(refresh: boolean) {
    var xhttp = this.restapi.getRequest(this.constructAPI.getAllUsageStats(refresh))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // Typical action to be performed when the document is ready:

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          let usage = { CompanyCode: json.companyCode, CompanyId: json.companyId, LastInsertedDate: json.lastInserted, LatestDate: json.latestDate };
          this.usageList.push(usage);
        }
        );

        this.getCompaniesLatestManagerCheckIn();
      }
    };

  }

  getCompaniesLatestManagerCheckIn() {
    var xhttp = this.restapi.getRequest(this.constructAPI.getCompaniesLatestCheckIns(undefined, undefined, this.enums.BRANCH_MANAGER))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // Typical action to be performed when the document is ready:

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {

          var index = this.usageList.findIndex(usage => Number(usage.CompanyId) == Number(json.companyId));

          if (index != -1)
            this.usageList[index].LastManagerCheckedDate = json.date;
        }
        );

        this.getCompaniesLatestAdminCheckIn();
      }
    };

  }

  getCompaniesLatestAdminCheckIn() {
    var xhttp = this.restapi.getRequest(this.constructAPI.getCompaniesLatestCheckIns(undefined, undefined, this.enums.ADMIN))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // Typical action to be performed when the document is ready:

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {

          var index = this.usageList.findIndex(usage => Number(usage.CompanyId) == Number(json.companyId));

          if (index != -1)
            this.usageList[index].LastAdminCheckedDate = json.date;
        }
        );

        //make customer list as the datasource
        this.dataSource = new MatTableDataSource<Usage>(this.usageList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

      }
    };

  }

  openCheckInHistoryModal(selectedCompanyId: number, companyCode: string, roleId: number) {

    var modalRef = this.sharedService.openModal(CheckInHistoryComponent, "largeModal");

    modalRef.componentInstance.selectedCompanyId = selectedCompanyId;
    modalRef.componentInstance.selectedCompanyCode = companyCode;
    modalRef.componentInstance.selectedRole = roleId;

  }

}
