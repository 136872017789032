<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title" *ngIf="isLoanDocument==false && isReserveGroupDocument==false">Upload File / Image</h4>
        <h4 class="modal-title" *ngIf="isLoanDocument==true">Upload Loan Document</h4>
        <h4 class="modal-title" *ngIf="isReserveGroupDocument==true">Upload Simpan Group Document</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body">
        <ng-container *ngIf="isSingleType==true;else multipleType">

            <input id="file-input" type="file" accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.mp4,.mkv,.webm,.flv"
                (change)="handleFileInput($event.target.files)" />

            <p class="fw-bold text-danger" *ngIf="disableSubmit">Uploading file, please wait patiently...</p>
            <p class="fw-bold">MAX FILE SIZE: 50 MB</p>

            <div class="modal-footer">
                <button class="btn btn-outline-success" type="button" (click)="addImage()"
                    [disabled]="disableSubmit">Add
                    File</button>
                <button class=" btn btn-outline-danger" type="button">Cancel</button>
            </div>

        </ng-container>


        <ng-template #multipleType>
            <h4 style="background-color: lightblue;">Check File Status
                <i class="far fa-question-circle ms-2" [ngbPopover]="popContent" style="cursor:pointer;"></i>

                <ng-template #popContent>
                    <!-- <div *ngIf="isLoanListReady==false;else loansList">
                                <mat-spinner></mat-spinner>
                        </div> -->
                    <app-check-customer-necessary-files [customerId]="selectedCustomerId" [companyId]="companyId">
                    </app-check-customer-necessary-files>
                </ng-template>
            </h4>

            <table class="table table-bordered table-responsive">
                <tbody>
                    <tr>
                        <td>Application Form</td>
                        <td> <input id="file-input" type="file"
                                accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.mp4,.mkv,.webm,.flv"
                                (change)="handleFileInput($event.target.files)" />
                            <p class="fw-bold text-danger" *ngIf="disableSubmit &&  this.file!=undefined">Uploading
                                file, please wait
                                patiently...</p>
                            <p class="fw-bold">MAX FILE SIZE: 50 MB</p>
                        </td>
                        <td>
                            <button class="btn btn-outline-success" type="button"
                                (click)="addImage(this.enums.APPLICATION_FORM)" [disabled]="disableSubmit">Add
                                File
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td>Reference Form</td>
                        <td> <input id="file-input" type="file"
                                accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.mp4,.mkv,.webm,.flv"
                                (change)="handleFileInput($event.target.files)" />
                            <p class="fw-bold text-danger" *ngIf="disableSubmit &&  this.file!=undefined">Uploading
                                file, please wait
                                patiently...</p>
                            <p class="fw-bold">MAX FILE SIZE: 50 MB</p>
                        </td>
                        <td>
                            <button class="btn btn-outline-success" type="button"
                                (click)="addImage(this.enums.REFERENCE_FORM)" [disabled]="disableSubmit">Add
                                File
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td>IC Front</td>
                        <td> <input id="file-input" type="file"
                                accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.mp4,.mkv,.webm,.flv"
                                (change)="handleFileInput($event.target.files)" />
                            <p class="fw-bold text-danger" *ngIf="disableSubmit &&  this.file!=undefined">Uploading
                                file, please wait
                                patiently...</p>
                            <p class="fw-bold">MAX FILE SIZE: 50 MB</p>
                        </td>
                        <td>
                            <button class="btn btn-outline-success" type="button" (click)="addImage(this.enums.IC_FRONT)"
                                [disabled]="disableSubmit">Add
                                File
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td>IC Back</td>
                        <td> <input id="file-input" type="file"
                                accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.mp4,.mkv,.webm,.flv"
                                (change)="handleFileInput($event.target.files)" />
                            <p class="fw-bold text-danger" *ngIf="disableSubmit &&  this.file!=undefined">Uploading
                                file, please wait
                                patiently...</p>
                            <p class="fw-bold">MAX FILE SIZE: 50 MB</p>
                        </td>
                        <td>
                            <button class="btn btn-outline-success" type="button" (click)="addImage(this.enums.IC_BACK)"
                                [disabled]="disableSubmit">Add
                                File
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td>Consent Form</td>
                        <td> <input id="file-input" type="file"
                                accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.mp4,.mkv,.webm,.flv"
                                (change)="handleFileInput($event.target.files)" />
                            <p class="fw-bold text-danger" *ngIf="disableSubmit &&  this.file!=undefined">Uploading
                                file, please wait
                                patiently...</p>
                            <p class="fw-bold">MAX FILE SIZE: 50 MB</p>
                        </td>
                        <td>
                            <button class="btn btn-outline-success" type="button"
                                (click)="addImage(this.enums.CONSENT_FORM)" [disabled]="disableSubmit">Add
                                File
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td>Payslip</td>
                        <td> <input id="file-input" type="file"
                                accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.mp4,.mkv,.webm,.flv"
                                (change)="handleFileInput($event.target.files)" />
                            <p class="fw-bold text-danger" *ngIf="disableSubmit &&  this.file!=undefined">Uploading
                                file, please wait
                                patiently...</p>
                            <p class="fw-bold">MAX FILE SIZE: 50 MB</p>
                        </td>
                        <td>
                            <button class="btn btn-outline-success" type="button" (click)="addImage(this.enums.PAYSLIP)"
                                [disabled]="disableSubmit">Add
                                File
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td>Bank Statement</td>
                        <td> <input id="file-input" type="file"
                                accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.mp4,.mkv,.webm,.flv"
                                (change)="handleFileInput($event.target.files)" />
                            <p class="fw-bold text-danger" *ngIf="disableSubmit &&  this.file!=undefined">Uploading
                                file, please wait
                                patiently...</p>
                            <p class="fw-bold">MAX FILE SIZE: 50 MB</p>
                        </td>
                        <td>
                            <button class="btn btn-outline-success" type="button"
                                (click)="addImage(this.enums.BANK_STATEMENT)" [disabled]="disableSubmit">Add
                                File
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td>ATM Card</td>
                        <td> <input id="file-input" type="file"
                                accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.mp4,.mkv,.webm,.flv"
                                (change)="handleFileInput($event.target.files)" />
                            <p class="fw-bold text-danger" *ngIf="disableSubmit &&  this.file!=undefined">Uploading
                                file, please wait
                                patiently...</p>
                            <p class="fw-bold">MAX FILE SIZE: 50 MB</p>
                        </td>
                        <td>
                            <button class="btn btn-outline-success" type="button" (click)="addImage(this.enums.ATM_CARD)"
                                [disabled]="disableSubmit">Add
                                File
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td>EPF Proof</td>
                        <td> <input id="file-input" type="file"
                                accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.mp4,.mkv,.webm,.flv"
                                (change)="handleFileInput($event.target.files)" />
                            <p class="fw-bold text-danger" *ngIf="disableSubmit &&  this.file!=undefined">Uploading
                                file, please wait
                                patiently...</p>
                            <p class="fw-bold">MAX FILE SIZE: 50 MB</p>
                        </td>
                        <td>
                            <button class="btn btn-outline-success" type="button" (click)="addImage(this.enums.EPF_PROOF)"
                                [disabled]="disableSubmit">Add
                                File
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td>Acknowledgement</td>
                        <td> <input id="file-input" type="file"
                                accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.mp4,.mkv,.webm,.flv"
                                (change)="handleFileInput($event.target.files)" />
                            <p class="fw-bold text-danger" *ngIf="disableSubmit &&  this.file!=undefined">Uploading
                                file, please wait
                                patiently...</p>
                            <p class="fw-bold">MAX FILE SIZE: 50 MB</p>
                        </td>
                        <td>
                            <button class="btn btn-outline-success" type="button"
                                (click)="addImage(this.enums.ACKNOWLEDGEMENT)" [disabled]="disableSubmit">Add
                                File
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td>Other Documents</td>
                        <td> <input id="file-input" type="file"
                                accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.mp4,.mkv,.webm,.flv"
                                (change)="handleFileInput($event.target.files)" />
                            <p class="fw-bold text-danger" *ngIf="disableSubmit &&  this.file!=undefined">Uploading
                                file, please wait
                                patiently...</p>
                            <p class="fw-bold">MAX FILE SIZE: 50 MB</p>
                        </td>
                        <td>
                            <button class="btn btn-outline-success" type="button" (click)="addImage()"
                                [disabled]="disableSubmit">Add
                                File
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-template>

    </div>


</form>