import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { AlertDialogComponent } from 'src/app/Shared/alert-dialog/alert-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Account } from 'src/model/account.model';

@Component({
  selector: 'app-transfer-fund',
  templateUrl: './transfer-fund.component.html',
  styleUrls: ['./transfer-fund.component.css']
})
export class TransferFundComponent implements OnInit {

  @Input() companyBankAccountList: Account[] = new Array;
  @Input() cashAccountList: Account[] = new Array;

  selectedAccountId: number;
  selectedTargetAccountId: number;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  //2-WAY BINDING INFO
  transferDate: Date;
  remark: string;
  amount: number = 0;
  fee: number = 0;

  disabledSubmit = false;

  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private restapi: RestApiService, private constructApi: ConstructAPI, 
    public sharedService: SharedService,private enums:Enums) {

    this.transferDate = new Date();
  }

  ngOnInit(): void {
  }


  setSelectedAccount(accountId: number) {

    if (accountId === this.selectedAccountId)
      return true
    else
      return false;
  }

  setSelectedTargetAccount(accountId: number) {
    if (accountId === this.selectedTargetAccountId)
      return true
    else
      return false;
  }

  isFieldNull() {
    var paymentNull = (this.amount == undefined || this.amount == 0);
    var remarkNull = (this.remark == undefined || this.remark == "");
    var selectedAccountIdNull = (this.selectedAccountId == undefined)
    var selectedTargetAccountIdNull = (this.selectedTargetAccountId == undefined)
    var feeNull = (this.fee == undefined);
    var paymentValid = isNaN(+this.amount);
    var feeValid = isNaN(+this.fee);

    return paymentNull || remarkNull || selectedAccountIdNull || selectedTargetAccountIdNull || feeNull || paymentValid || feeValid
  }

  addTransfer() {
    this.disabledSubmit = true;
    var isoTaskDate = (new Date(Number(this.transferDate))).toISOString();

    var data = {
      "amount": this.amount,
      "accountId": Number(this.selectedTargetAccountId),
      "date": isoTaskDate,
      "remark": this.remark
    };


    var xhr = this.restapi.postRequest(this.constructApi.getPostFundTransfer(this.selectedAccountId), data);
    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        if (xhr.status == 200) {
          var json = JSON.parse(xhr.responseText);

          let account = new Account(this.selectedAccountId, null, null, null, null, null, null, this.amount)
          this.passEntry.emit(account)
          this.reset();
          this.sharedService.openAlert("Successfully added transfer!",this.enums.SUCCESS_ALERT);
          window.location.reload();

          if (this.fee > 0) {
            this.addTransferFeeExpense();
          }
        }
        else
          this.sharedService.openErrorMessage(xhr);
      }

    }
  }

  addTransferFeeExpense() {
    var isoTaskDate = (new Date(Number(this.transferDate))).toISOString();

    var data = {
      "amount": this.fee,
      "accountId": Number(this.selectedAccountId),
      "date": isoTaskDate,
      "remark": this.remark + "- Transfer Fee"
    };


    var xhr = this.restapi.postRequest(this.constructApi.getAddCompanyExpense(), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        var json = JSON.parse(xhr.responseText);

        // let account = new Account(this.selectedAccountId, null, null, null, null, null, null, this.amount)
        // this.passEntry.emit(account)
        // this.reset();
        this.sharedService.openAlert("Successfully added transfer fee expense!",this.enums.SUCCESS_ALERT);

      }
    }
  }

  isFromAccountDisable(balance: number) {
    if (balance != undefined && balance > 0)
      return false
    else
      return true
  }

  isCheckTargetAccountDisable(accountID: number, balance: number) {
    if (this.selectedAccountId != undefined && accountID == this.selectedAccountId)
      return true
    else
      return false
  }

  submit() {
    (this.isFieldNull()) ? this.sharedService.openAlert('Please fill in all the fields',this.enums.DANGER_ALERT) : this.addTransfer();
  }
  reset() {
    this.activeModal.close();
  }



}
