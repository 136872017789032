import { Component, OnInit, Input, SimpleChange, OnChanges, SimpleChanges } from '@angular/core';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Customer } from 'src/model/customer.model';
import { EPF } from 'src/model/epf.model';
import { Profile } from 'src/model/profile.model';
import { EditEpfDetailsComponent } from '../edit-epf-details/edit-epf-details.component';
import { UpdateProfileInfoComponent } from '../update-profile-info/update-profile-info.component';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.css']
})
export class CustomerDetailsComponent implements OnInit, OnChanges {

  constructor(public sharedService: SharedService) { }
  // private profileID = -1;
  @Input() viewCustomer: Customer;
  // @Input()
  // set selectedProfileID(newProfileId: any) {
  //   this.profileID = newProfileId;
  //   var index = this.viewCustomer.Profiles.findIndex(profile => Number(profile.ID) === Number(this.profileID));
  //   this.selectedProfile = this.viewCustomer.Profiles[index];
  // }
  @Input() selectedProfileID;

  @Input() selectedProfile: Profile;

  @Input() epfDetails: EPF;

  ngOnChanges() {
  }

  ngOnInit(): void {

  }


  openUpdateProfileModal() {
    const modalRef = this.sharedService.openModal(UpdateProfileInfoComponent, "largeModal");
    this.selectedProfile.CustomerID = this.viewCustomer.ID;
    modalRef.componentInstance.profileToUpdate = this.selectedProfile;

    if (modalRef.componentInstance.passEntry != undefined) {
      modalRef.componentInstance.passEntry.subscribe((entry) => {
        var profile: Profile = entry;
        this.selectedProfile.CustomerCode = profile.CustomerCode;
        this.selectedProfile.Occupation = profile.Occupation;
        this.selectedProfile.Salary = profile.Salary;
        this.selectedProfile.SalaryDay = profile.SalaryDay;
        this.selectedProfile.Remark = profile.Remark;

      });

      modalRef.result.then(() => {
      })
    }
  }


  openEditEPFDetailsModal() {
    var modalRef = this.sharedService.openModal(EditEpfDetailsComponent, 'largeModal');
    modalRef.componentInstance.customerId = this.viewCustomer.ID;
  }

}
