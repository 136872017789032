import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddBadDebtWithdrawalComponent } from 'src/app/AddThings/add-bad-debt-withdrawal/add-bad-debt-withdrawal.component';
import { AddWithdrawalComponent } from 'src/app/AddThings/add-withdrawal/add-withdrawal.component';
import { BadDebtSimpanExpenseComponent } from 'src/app/AddThings/bad-debt-simpan-expense/bad-debt-simpan-expense.component';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { TransactionSubType } from 'src/model/transactionSubType.model';

interface Report {
  date?: Date,
  remark?: string,
  amount: number,
  customerName: string,
  customerId: number,
  receiptNumber?: string,
  assignee?: string,
  rate?: number,
  principal?: number
  subtype?: number,
  profileId: number
}

@Component({
  selector: 'app-simple-report',
  templateUrl: './simple-report.component.html',
  styleUrls: ['./simple-report.component.css'],
  providers: [DatePipe]
})
export class SimpleReportComponent implements OnInit, OnDestroy {


  @Input() selectedCompany: number;
  @Input() selectedReport: number;
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() reportName: string;

  reportList: Report[] = new Array;

  transactionBalance: number = 0;

  isCollapsed: boolean = false;

  routeSubscription: any;

  isBranchManager: boolean;
  transactionSubtypeList: TransactionSubType[] = new Array;

  includeDLExpense = true;
  withoutDLExpenseList: Report[] = new Array;
  withDLExpenseList: Report[] = new Array;

  constructor(private modalService: NgbModal, public enums: Enums, private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe, private router: Router, private restapi: RestApiService, private constructApi: ConstructAPI, private sharedService: SharedService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    // this.refresh();
    this.routeSubscription = this.activatedRoute.paramMap.subscribe(params => {
      this.selectedCompany = + params.get('companyId');
      this.startDate = (params.get('startDate') == undefined) ? undefined : new Date(params.get('startDate'));
      this.endDate = (params.get('endDate') == undefined) ? undefined : new Date(params.get('endDate'));
      this.selectedReport = + params.get('reportId');

      //clear transactionBalance
      this.transactionBalance = 0;

      if (this.startDate != undefined && this.selectedReport != undefined && this.selectedReport != this.enums.DISCOUNT_REPORT && this.selectedReport != this.enums.EXPENSE_BY_EXTRAS_REPORT && this.selectedReport != this.enums.COMMISSION_REPORT && this.selectedReport != this.enums.SIMPAN_BALANCE_REPORT && this.selectedReport != this.enums.HUTANG_BALANCE_REPORT && this.selectedReport != this.enums.SIMPAN_BD_REPORT)
        this.getTransactionBalance(this.getTypes(this.selectedReport))

      this.getReport(this.getTypes(this.selectedReport));

    });
    this.isBranchManager = (Number(sessionStorage.getItem("roleId")) == this.enums.BRANCH_MANAGER) ? true : false;
  }


  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }

  getTypes(selectedReport: number) {
    var types: number;
    switch (selectedReport) {
      case this.enums.STAMP_REPORT:
        types = this.enums.STAMP;
        break;
      case this.enums.SC_REPORT:
        types = this.enums.SERVICE_CHARGE;
        break;
      case this.enums.ASSET_REPORT:
        types = this.enums.ASSET;
        break;
      case this.enums.SIMPAN_REPORT:
        types = this.enums.RESERVE;
        break;
      case this.enums.EXPENSE_REPORT:
        types = this.enums.EXPENSE;
        break;
      case this.enums.EXPENSE_BY_EXTRAS_REPORT:
        types = this.enums.EXTRAS;
        break;
      case this.enums.CAPITAL_REPORT:
        types = this.enums.CAPITAL;
        break;
      case this.enums.SIMPAN_BD_REPORT:
        types = this.enums.BAD_DEBT;
        break;
      case this.enums.SAVING_REPORT:
        types = this.enums.SAVING;
        break;
      case this.enums.TOTING_REPORT:
        types = this.enums.TOTING;
        break;
      case this.enums.DISCOUNT_REPORT:
        types = this.enums.DISCOUNT;
        break;
      case this.enums.HUTANG_REPORT:
        types = this.enums.HUTANG;
        break;
      case this.enums.SIMPAN_BD_TRANSACTION_REPORT:
        types = this.enums.BAD_DEBT;
        break;

      case this.enums.ADVANCE_REPORT:
        types = this.enums.ADVANCE;
        break;
      case this.enums.HANDLING_REPORT:
        types = this.enums.HANDLING_CHARGE;
        break;
    }

    return types;
  }

  goToCustomer(customerId: number, profileId?: number) {
    if (profileId == undefined)
      this.router.navigate(['/manageCustomer', customerId]);
    else
      this.router.navigate(['/manageCustomer', customerId, profileId]);
  }


  resetList() {
    this.reportList.splice(0, this.reportList.length);
  }

  getTransactionBalance(type: number) {
    //resetBalanceAmount
    this.transactionBalance = 0;
    if (this.startDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var reduceASecDate = new Date(Number(this.startDate) - 1);
    var startDateStr = (this.startDate == undefined) ? undefined : reduceASecDate.toISOString();

    var xhttp = this.restapi.getRequest(this.constructApi.getCompanyTransactionBalances(this.selectedCompany, startDateStr, type));

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          this.transactionBalance = json.balance;
        }
        );
      }
    };

  }

  getReport(types: number) {
    this.resetList();
    var startDateStr = (this.startDate == undefined) ? undefined : this.startDate.toISOString();

    if (this.endDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var addADayDate = new Date(Number(this.endDate) + (3600 * 1000 * 24) - 1);


    var endDateStr = (this.endDate == undefined) ? undefined : addADayDate.toISOString();

    var xhttp;
    switch (this.selectedReport) {
      case this.enums.HUTANG_BALANCE_REPORT:
        // xhttp = this.restapi.getRequest(this.constructApi.getCompanyAllLoansTransactions(this.selectedCompany, startDateStr, endDateStr, "HUTANG"));
        xhttp = this.restapi.getRequest(this.constructApi.getCompanyAllLoans(this.selectedCompany, "HUTANG", [this.enums.ACTIVE_LOAN, this.enums.WARNING_LOAN, this.enums.BAD_DEBT_LOAN]));
        break;
      case this.enums.COMMISSION_REPORT:
        xhttp = this.restapi.getRequest(this.constructApi.getCompanyPaidCommission(this.selectedCompany, startDateStr, endDateStr));
        break;
      case this.enums.SIMPAN_BD_REPORT:
        xhttp = this.restapi.getRequest(this.constructApi.getCompanyUnpaidBadDebts(this.selectedCompany));
        break;
      case this.enums.STAMP_REPORT:
      case this.enums.SC_REPORT:
      case this.enums.HUTANG_REPORT:
      case this.enums.HANDLING_REPORT:
        // case this.enums.SIMPAN_REPORT:
        xhttp = this.restapi.getRequest(this.constructApi.getCompanyAllLoansTransactions(this.selectedCompany, startDateStr, endDateStr, undefined, [types]));
        break;
      case this.enums.SIMPAN_BALANCE_REPORT:
        xhttp = this.restapi.getRequest(this.constructApi.getCompanyActiveReserve(this.selectedCompany, endDateStr, 1));
        break;

      case this.enums.SIMPAN_REPORT:
        xhttp = this.restapi.getRequest(this.constructApi.getProfilesReserveReceipts(startDateStr, endDateStr, this.selectedCompany));
        break;
      case this.enums.SIMPAN_BD_TRANSACTION_REPORT:
        xhttp = this.restapi.getRequest(this.constructApi.getCompanyBDReserveReceipts(this.selectedCompany, startDateStr, endDateStr));
        break;
      case this.enums.EXPENSE_BY_EXTRAS_REPORT:
        xhttp = this.restapi.getRequest(this.constructApi.getAllTransactions(this.selectedCompany, [types], startDateStr, endDateStr, 0));
        break;
      case this.enums.DISCOUNT_REPORT:
        xhttp = this.restapi.getRequest(this.constructApi.getCompanyAllLoansTransactions(this.selectedCompany, startDateStr, endDateStr, undefined, [types], undefined, undefined, undefined, undefined, 0));
        break;

      default:
        xhttp = this.restapi.getRequest(this.constructApi.getAllTransactions(this.selectedCompany, [types], startDateStr, endDateStr));
        break;
    }

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          var report;

          switch (this.selectedReport) {
            case this.enums.COMMISSION_REPORT:

              var rate = (Number(json.amount) / Number(json.principal)) * 100;
              report = {
                date: json.dateCreated, remark: json.remark, amount: json.amount, customerName: json.customerName,
                customerId: json.customerId, receiptNumber: json.receiptNumber, assignee: json.assignee, rate: rate, principal: json.principal, profileId: json.profileId
              };
              this.reportList.push(report);
              break;
            case this.enums.SIMPAN_BD_REPORT:
              if (json.total > 0) {
                report = { customerName: json.name, customerId: json.customerId, amount: json.total, profileId: json.profileId }
                this.reportList.push(report);
              }

              break;

            case this.enums.SIMPAN_BALANCE_REPORT:
              // report = this.processSimpanData(json);
              report = { customerName: json.customerName, customerId: json.customerId, amount: json.balance, remark: json.occupation, profileId: json.profileId }
              this.reportList.push(report);
              break;
            case this.enums.SIMPAN_REPORT:
              report = this.processSimpanData(json);
              break;
            case this.enums.SIMPAN_BD_TRANSACTION_REPORT:
              report = this.processSimpanData(json);
              break;
            case this.enums.HUTANG_BALANCE_REPORT:
              report = { customerName: json.customerName, customerId: json.customerId, date: json.dateCreated, amount: -json.balance, receiptNumber: json.receiptNumber }
              this.reportList.push(report);
              break;
            default:
              report = {
                date: json.date, remark: json.remark, amount: json.amount, customerName: json.customerName,
                customerId: json.customerId, receiptNumber: json.receiptNumber, assignee: json.assignee, rate: json.rate, principal: json.principal, subtype: json.subtype, profileId: json.profileId
              };
              this.reportList.push(report);

              //if is expense report need to record both with DL expense and without DL expense list
              if (this.selectedReport == this.enums.EXPENSE_REPORT) {
                this.withDLExpenseList.push(report);
                this.withoutDLExpenseList.push(report);
              }

              break;
          }


        }
        );

        //sort list
        this.reportList.sort(function (a, b) {
          if (new Date(a.date) === new Date(b.date)) {
            return a.receiptNumber > b.receiptNumber ? 1 : -1;
          }
          return new Date(a.date) > new Date(b.date) ? 1 : -1;
        });

      }
    };


    if (this.selectedReport == this.enums.EXPENSE_REPORT) {
      this.getSavingAndCapital();
    }


    if (this.selectedReport == this.enums.STAMP_REPORT)
      this.getWithdrawal(this.enums.STAMP);
    else if (this.selectedReport == this.enums.SC_REPORT)
      this.getWithdrawal(this.enums.SERVICE_CHARGE);
    else if (this.selectedReport == this.enums.HANDLING_REPORT)
      this.getWithdrawal(this.enums.HANDLING_CHARGE);
  }

  processSimpanData(json: any) {
    var report;
    json.transactions.forEach(transaction => {

      report = {
        date: transaction.date, remark: transaction.remark, amount: transaction.amount, customerName: json.name,
        customerId: json.customerId, profileId: json.profileId
      };

      this.reportList.push(report);
    }
    );

  }

  getSavingAndCapital() {
    var startDateStr = (this.startDate == undefined) ? undefined : this.startDate.toISOString();

    if (this.endDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var addADayDate = new Date(Number(this.endDate) + (3600 * 1000 * 24) - 1);
    var endDateStr = (this.endDate == undefined) ? undefined : addADayDate.toISOString();

    var xhttp = this.restapi.getRequest(this.constructApi.getAllTransactions(this.selectedCompany, [this.enums.SAVING, this.enums.CAPITAL, this.enums.TOTING], startDateStr, endDateStr));

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          var report = {
            date: json.date, remark: json.remark, amount: json.amount, customerName: json.customerName,
            customerId: json.customerId, receiptNumber: json.receiptNumber, assignee: json.assignee, rate: json.rate, principal: json.principal, profileId: json.profileId
          };
          this.reportList.push(report);
          this.withDLExpenseList.push(report);
          this.withoutDLExpenseList.push(report);
        }
        );


        //sort list
        this.reportList.sort((a, b) => (new Date(a.date) > new Date(b.date)) ? 1 : -1);


        if (this.includeDLExpense == true)
          this.getIncludeExpenseByDL();
      }
    };

  }


  getIncludeExpenseByDL() {

    var startDateStr = (this.startDate == undefined) ? undefined : this.startDate.toISOString();

    if (this.endDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var addADayDate = new Date(Number(this.endDate) + (3600 * 1000 * 24) - 1);
    var endDateStr = (this.endDate == undefined) ? undefined : addADayDate.toISOString();

    var xhttp = xhttp = this.restapi.getRequest(this.constructApi.getAllTransactions(this.selectedCompany, [this.enums.EXTRAS], startDateStr, endDateStr, 0));

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          var report = {
            date: json.date, remark: "DL EXPENSE=" +
              json.remark, amount: json.amount, customerName: json.customerName,
            customerId: json.customerId, receiptNumber: json.receiptNumber, assignee: json.assignee, rate: json.rate, principal: json.principal, profileId: json.profileId
          };
          this.reportList.push(report);
          this.withDLExpenseList.push(report);

        }
        );

        //sort list
        this.reportList.sort((a, b) => (new Date(a.date) > new Date(b.date)) ? 1 : -1);
      }
    };
  }


  includeOrExcludeDLExpense() {

    if (this.includeDLExpense == true)
      this.reportList = this.withDLExpenseList;
    else
      this.reportList = this.withoutDLExpenseList;



    this.cdr.detectChanges();
  }

  getWithdrawal(type: number) {
    var startDateStr = (this.startDate == undefined) ? undefined : this.startDate.toISOString();

    if (this.endDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var addADayDate = new Date(Number(this.endDate) + (3600 * 1000 * 24) - 1);
    var endDateStr = (this.endDate == undefined) ? undefined : addADayDate.toISOString();

    var xhttp = this.restapi.getRequest(this.constructApi.getAllTransactions(this.selectedCompany, [type], startDateStr, endDateStr, 0));

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          var report = {
            date: json.date, remark: json.remark, amount: json.amount, customerName: json.customerName,
            customerId: json.customerId, receiptNumber: json.receiptNumber, assignee: json.assignee, rate: json.rate, principal: json.principal, profileId: json.profileId
          };
          this.reportList.push(report);
        }
        );
        //sort list
        this.reportList.sort((a, b) => (new Date(a.date) > new Date(b.date)) ? 1 : -1);
      }
    };

  }

  isAccountReport() {
    if (this.selectedReport == this.enums.EXPENSE_REPORT
      || this.selectedReport == this.enums.EXPENSE_BY_EXTRAS_REPORT || this.selectedReport == this.enums.CAPITAL_REPORT || this.selectedReport == this.enums.SAVING_REPORT)
      return true;
    else return false;
  }
  isOrdinaryReport() {
    if (this.selectedReport == this.enums.STAMP_REPORT || this.selectedReport == this.enums.SC_REPORT || this.selectedReport == this.enums.HANDLING_REPORT ||
      this.selectedReport == this.enums.SIMPAN_REPORT || this.selectedReport == this.enums.SIMPAN_BD_TRANSACTION_REPORT || this.selectedReport == this.enums.HUTANG_BALANCE_REPORT || this.selectedReport == this.enums.HUTANG_REPORT || this.selectedReport == this.enums.ASSET_REPORT ||
      this.selectedReport == this.enums.EXPENSE_REPORT || this.selectedReport == this.enums.EXPENSE_BY_EXTRAS_REPORT
      || this.selectedReport == this.enums.SAVING_REPORT || this.selectedReport == this.enums.CAPITAL_REPORT || this.selectedReport == this.enums.TOTING_REPORT || this.selectedReport == this.enums.DISCOUNT_REPORT || this.selectedReport == this.enums.ADVANCE_REPORT)
      return true;
    else return false;
  }

  getStyle(reportAmount: number) {
    if (reportAmount < 0)
      return "text-danger";
    else
      return "text-success";
  }


  calculateTotal() {
    var total = 0;
    for (let i = 0; i < this.reportList.length; i++) {
      total += Number(this.reportList[i].amount);
    }
    return total
  }

  calculateTotalIn() {
    var totalIn = 0;
    for (let i = 0; i < this.reportList.length; i++) {
      if (this.reportList[i].amount > 0)
        totalIn += Number(this.reportList[i].amount);
    }
    return totalIn
  }

  calculateTotalOut() {
    var totalOut = 0;
    for (let i = 0; i < this.reportList.length; i++) {
      if (this.reportList[i].amount < 0)
        totalOut += Number(this.reportList[i].amount);
    }
    return totalOut
  }

  openWithdrawBDModal(profileId: number, amount: number) {
    const modalRef = this.sharedService.openModal(AddBadDebtWithdrawalComponent);
    modalRef.componentInstance.profileId = profileId;
    modalRef.componentInstance.maxAmount = amount;

    modalRef.componentInstance.passEntry.subscribe((entry) => {
      window.location.reload();
    });

    modalRef.result.then(() => {
      window.location.reload();
    })
  }
  openWithdrawalModal() {
    const modalRef = this.sharedService.openModal(AddWithdrawalComponent);

    if (this.selectedReport == this.enums.STAMP_REPORT)
      modalRef.componentInstance.withdrawStamp = true;
    else if (this.selectedReport == this.enums.SC_REPORT)
      modalRef.componentInstance.withdrawServiceCharge = true;
    else if (this.selectedReport == this.enums.HANDLING_REPORT)
      modalRef.componentInstance.withdrawHandling = true;
    modalRef.componentInstance.availableWithdrawalAmount = this.calculateTotal() + this.transactionBalance;


    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var done = entry;
      if (done == true)
        window.location.reload();
    });

    // modalRef.result.then(() => {
    //   // window.location.reload();
    // })
  }

  openBadDebtModal() {
    const modalRef = this.sharedService.openModal(BadDebtSimpanExpenseComponent, 'largeModal');
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var successStr = entry;
      window.location.reload();
    });

    modalRef.result.then(() => {
      window.location.reload();
    })

  }

  sortByCriteria(criteria: string) {
    switch (criteria) {
      case 'Receipt':
        this.reportList.sort((a, b) => (a.receiptNumber < b.receiptNumber ? -1 : 1));
        break;
      case 'Date':
        this.reportList.sort((a, b) => (a.date < b.date ? -1 : 1));
        break;
      case 'CustomerName':
        this.reportList.sort((a, b) => (a.customerName < b.customerName ? -1 : 1));
        break;
      case 'Principal':
        this.reportList.sort((a, b) => (a.principal < b.principal ? -1 : 1));
        break;
      case 'Rate':
        this.reportList.sort((a, b) => (Number(a.rate) < Number(b.rate) ? -1 : 1));
        break;
      case 'Assignee':
        this.reportList.sort((a, b) => (Number(a.assignee) < Number(b.assignee) ? -1 : 1));
        break;

      case 'Amount':
        this.reportList.sort((a, b) => (a.amount < b.amount ? -1 : 1));
        break;

      case 'Remark':
        this.reportList.sort((a, b) => (a.remark < b.remark ? -1 : 1));
        break;


    }

  }

  calculateCurrentBalance(index: number) {
    var total = this.transactionBalance;

    for (let i = 0; i <= index; i++) {
      total += this.reportList[i].amount;
    }

    return total;
  }

}
