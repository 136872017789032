export class EPF {
    constructor(private _amount: number, private _customerId: number, private _date: Date, private _hasIAccount: boolean, private _iAccountPassword: string, private _iAccountUsername: string, private _remark: string) { }



    set Amount(amount: number) { this._amount = amount }
    get Amount(): number { return this._amount }

    set CustomerId(customerId: number) { this._customerId = customerId }
    get CustomerId(): number { return this._customerId }

    set Date(date: Date) { this._date = date }
    get Date(): Date { return this._date }

    set HasIAccount(hasIAccount: boolean) { this._hasIAccount = hasIAccount }
    get HasIAccount(): boolean { return this._hasIAccount }

    set IAccountPassword(iAccountPassword: string) { this._iAccountPassword = iAccountPassword }
    get IAccountPassword(): string { return this._iAccountPassword }

    set IAccountUsername(iAccountUsername: string) { this._iAccountUsername = iAccountUsername }
    get IAccountUsername(): string { return this._iAccountUsername }

    set Remark(remark: string) { this._remark = remark }
    get Remark(): string { return this._remark }
}