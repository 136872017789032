<div class="card shadow-sm m-2" style="background-color: aliceblue;">
    <div class="card-body">
        <div class="row">
            <div class="col-2"> <span class="fw-bold">#{{loan.ReceiptNumber}}</span></div>
            <div class="col-4"> <span class="text-primary fw-bold"> <i class="fas fa-hand-holding-usd"></i>
                    Loan
                    : RM
                    {{loan.Principal}}</span></div>
            <div class="col-5"> <span class="text-success fw-bold">
                    <i class="fas fa-money-check-alt"></i> Settlement : RM
                    {{calculateSettlement()|number:'1.0-2'}} ({{calculateRatio()|number:'1.0-2'}}%)</span></div>
        </div>
    </div>
</div>