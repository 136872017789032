import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Loan } from 'src/model/loan.model';
import { RestApiService } from 'src/app/API/restapi';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { Enums } from 'src/app/Shared/enums';
import { Task } from 'src/model/task.model';
import { AlertDialogComponent } from 'src/app/Shared/alert-dialog/alert-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/Shared/shared-service.service';

@Component({
  selector: 'app-task-details',
  templateUrl: './task-details.component.html',
  styleUrls: ['./task-details.component.css']
})
export class TaskDetailsComponent implements OnInit, OnDestroy {

  selectedTask: Task;
  nextTaskDesc: string = "";
  followUpRemark: string = "";
  taskDate: Date;
  taskStatus: string = "";

  //for datepicker
  minDate: Date;
  maxDate: Date;

  followUpRemarkNull: boolean;
  nextTaskDescNull: boolean = false;
  statusNull: boolean;

  createNextTask: boolean = false;

  routeSubscription: any;

  constructor(private modalService: NgbModal, public activatedRoute: ActivatedRoute, private router: Router, 
    private restapi: RestApiService, private constructApi: ConstructAPI, public enums: Enums,private sharedService:SharedService) {

    // if (this.router.getCurrentNavigation().extras.state.id) {

    //   this.selectedCustomerID = this.router.getCurrentNavigation().extras.state.id;
    //   // this.sharedService.openAlert("extras.id:" + this.selectedCustomerID);
    // }
    // if (this.router.getCurrentNavigation().extras.state.taskId) {

    //   this.selectedTaskID = this.router.getCurrentNavigation().extras.state.taskId;
    //   this.selectedTaskDesc = this.router.getCurrentNavigation().extras.state.taskDesc;
    //   this.selectedTaskDate = this.router.getCurrentNavigation().extras.state.taskDate;
    //   this.selectedTaskStatus = this.router.getCurrentNavigation().extras.state.taskStatus;

    // }

    this.routeSubscription = this.activatedRoute.paramMap.subscribe(params => {
      const taskId = + params.get('taskId');
      this.getSelectedTask(taskId);
    })
    // const taskId = + this.activatedRoute.snapshot.paramMap.get('id');
    // this.getSelectedTask(taskId);
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }

  getSelectedTask(taskId: number) {
    var xhttp = this.restapi.getRequest(this.constructApi.getTaskDetails(taskId));

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var json = JSON.parse(xhttp.responseText);

        this.selectedTask = new Task(json.id, json.customerId,
          json.description, json.followUpRemark, json.date, json.customerName, json.assignee, json.dateDone);
      }
    };


  }

  ngOnInit(): void {

    //set min follow up date = next day of today
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate() + 1)

    //get last day of next month
    var lastDayOfNextMonth = new Date(this.minDate.getFullYear(), this.minDate.getMonth() + 1, 0)

    //set max date to next month end
    this.maxDate = new Date(this.minDate.getFullYear(), this.minDate.getMonth() + 1, lastDayOfNextMonth.getDate());

    // this.taskDate = this.minDate;
  }

  updateTask() {

    //if staff do works today, no matter the task is from where, should mark done on today
    // const selectedTaskDateFormat = new Date();

    //convert  date to ISOString
    //get timezone offset in milliseconds
    // var tzoffset = selectedTaskDateFormat.getTimezoneOffset() * 60000;

    //deduct timezone offset from the task date then only convert to ISO STRING
    // var isoTaskDate = (new Date(Number(selectedTaskDateFormat) - tzoffset)).toISOString();

    // var isoTaskDate = new Date(Number(selectedTaskDateFormat)).toISOString();
    // var data = {
    //   "id": this.selectedTaskID,
    //   "customerId": this.selectedCustomerID,
    //   "username": sessionStorage.getItem("username"),
    //   "description": this.selectedTaskDesc,
    //   "followUpRemark": this.followUpRemark,
    //   "date": isoTaskDate,
    //   "status": Number(this.taskStatus)
    // };

    var data = {
      "followUpRemark": this.followUpRemark,
      "date": new Date(),
    };


    // var xhr = new XMLHttpRequest();
    // var url = "http://localhost:10080/api/v1/task/" + this.selectedTaskID;
    // xhr.open("PUT", url);
    // xhr.setRequestHeader("Content-type", "application/json");
    // xhr.withCredentials = true;

    var xhr = this.restapi.putRequest(this.constructApi.getUpdateDoneTask(this.selectedTask.ID), data);

    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        var json = JSON.parse(xhr.responseText);
        var loan = new Loan(json.id, json.customerId, json.principal, json.stampAmount, json.receiptNumber,
          json.status, json.dateCreated, json.companyId, json.companyName,
          json.scheme, json.interestRate, json.term, json.customerName,
          json.icNumber, json.interestCharge, json.issuer, json.loanCode, json.arrear, json.principalPaid,
          json.repayment, json.reserve)

        this.sharedService.openAlert("Successfully updated loan!",this.enums.SUCCESS_ALERT);


        if (this.createNextTask)
          this.createTask();

      }
    }



    // xhr.send(data);
  }

  createTask() {
    const taskDateFormat = new Date(this.taskDate);

    //convert  date to ISOString
    //get timezone offset in milliseconds
    // var tzoffset = taskDateFormat.getTimezoneOffset() * 60000;

    //deduct timezone offset from the task date then only convert to ISO STRING
    // var isoTaskDate = (new Date(Number(taskDateFormat) - tzoffset)).toISOString();
    var isoTaskDate = (new Date(Number(taskDateFormat))).toISOString();

    var data = {
      "customerId": this.selectedTask.CustomerId,
      "username": sessionStorage.getItem("username"),
      "description": this.nextTaskDesc,
      "date": isoTaskDate
    };


    // var xhr = new XMLHttpRequest();
    // var url = "http://localhost:10080/api/v1/task";
    // xhr.open("POST", url, true);
    // xhr.setRequestHeader("Content-type", "application/json");
    // xhr.withCredentials = true;

    var xhr = this.restapi.postRequest(this.constructApi.getAddTask(), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {

        var json = JSON.parse(xhr.responseText);
        this.sharedService.openAlert("Successfully created task!",this.enums.SUCCESS_ALERT);
      }
      else if (xhr.readyState == 4 && xhr.status != 200) {
        this.sharedService.openAlert("Oops! Some error occurs when creating task",this.enums.DANGER_ALERT)
      }
    }


  }

  validField(field: string) {
    switch (field) {

      case 'followUpRemark': this.followUpRemarkNull = (this.followUpRemark === ''); break;
      case 'nextTaskDesc': this.nextTaskDescNull = (this.nextTaskDesc === ''); break;
      // case 'status': this.statusNull = (this.taskStatus === ''); break;

    }
  }

  isFieldNull() {


    this.followUpRemarkNull = (this.followUpRemark === '');

    if (this.createNextTask)
      this.nextTaskDescNull = (this.nextTaskDesc === '');
    // this.statusNull = (this.taskStatus === '');

    // this.sharedService.openAlert(this.followUpRemark + "|" + this.nextTaskDesc + "|" + this.taskStatus)
    // this.sharedService.openAlert(this.followUpRemarkNull || this.nextTaskDescNull || this.statusNull)
    return (this.followUpRemarkNull || this.nextTaskDescNull)
  }

  setPresetRemark(preset: string) {
    this.followUpRemark = preset;
  }
  submit() {
    this.isFieldNull() ? this.sharedService.openAlert('Please fill in all the fields!',this.enums.DANGER_ALERT) : this.updateAndCreateTask();
  }
  updateAndCreateTask() {
    this.updateTask();



    this.router.navigateByUrl('/dashboard-calendar');
  }

  backToDashboard() {
    this.router.navigateByUrl('/dashboard-calendar');
  }


}
