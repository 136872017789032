<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Customer EPF Change history</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body">

        <p *ngIf="historyList.length==0">No change record</p>
        <table class="table table-bordered" *ngIf="historyList.length>0">
            <thead>
                <tr>
                    <th>Modified By</th>
                    <th>Modified on</th>
                    <th>Old Value</th>
                    <th>New Value</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let history of historyList">
                    <td> {{history.modified_by}}</td>
                    <td> {{history.modified_on | date:'dd-MM-yyyy'}}</td>
                    <td>
                        <!-- {{history.old_value}} -->
                        <ng-container *ngTemplateOutlet="epfDetails;context:{details:history.old_value}">
                        </ng-container>
                    </td>
                    <td>
                        <!-- {{history.new_value}} -->
                        <ng-container *ngTemplateOutlet="epfDetails;context:{details:history.new_value}">
                        </ng-container>
                    </td>
                    <ng-template #epfDetails let-details="details">
                        <table class="table table-bordered">
                            <tbody>
                                <tr>
                                    <th>Amount:</th>
                                    <td>RM {{details.Amount}}</td>
                                </tr>
                                <tr>
                                    <th>Date:</th>
                                    <td> {{details.Date | date:'dd-MM-yyyy'}}</td>
                                </tr>
                                <tr>
                                    <th>Has i-Account</th>
                                    <td><span *ngIf="details.HasIAccount==true">YES</span> <span
                                            *ngIf="details.HasIAccount==false">NO</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>i-Account Username</th>
                                    <td> {{details.IAccountUsername}}</td>
                                </tr>
                                <tr>
                                    <th>i-Account Password</th>
                                    <td> {{details.IAccountPassword}}</td>
                                </tr>
                                <tr>
                                    <th>Remark</th>
                                    <td> {{epfDetails.Remark}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-template>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="modal-footer">
    </div>

</form>