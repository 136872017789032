import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { AlertDialogComponent } from 'src/app/Shared/alert-dialog/alert-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { GlobalCustomer } from 'src/model/globalCustomer.model';

@Component({
  selector: 'app-search-by-receipt',
  templateUrl: './search-by-receipt.component.html',
  styleUrls: ['./search-by-receipt.component.css']
})
export class SearchByReceiptComponent implements OnInit {
  customerList: GlobalCustomer[] = new Array;

  displayedColumns: string[];
  dataSource = new MatTableDataSource;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  searchReceiptNum: string;
  constructor(private modalService: NgbModal, private router: Router, private constructApi: ConstructAPI,
    private restApi: RestApiService, private activeModal: NgbActiveModal, public enums: Enums, private sharedService: SharedService) { }

  ngOnInit(): void {
    this.displayedColumns = ['CustomerName', 'CustomerIC', 'Status'];
  }


  findCustomer(receiptNum: string) {
    if (this.searchReceiptNum != undefined && this.searchReceiptNum != '') {

      this.customerList.splice(0, this.customerList.length);

      var companyId = Number(sessionStorage.getItem('companyId'));
      var xhr = this.restApi.getRequest(this.constructApi.getSearchCustomerByReceipt(companyId, receiptNum));

      xhr.onreadystatechange = () => {
        if (xhr.readyState == 4) {

          if (xhr.status == 200) {
            var json = JSON.parse((xhr.responseText));
            let customer = new GlobalCustomer(json.customerName, json.icNumber, undefined, json.customerId, json.status)

            this.customerList.push(customer);
            //make task list as the datasource
            this.dataSource = new MatTableDataSource<GlobalCustomer>(this.customerList);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
          else {
            this.sharedService.openAlert("Error! " + JSON.parse((xhr.responseText)).error,this.enums.DANGER_ALERT)
          }

        }
      }
    } else
      this.sharedService.openAlert("Please fill in full receipt number",this.enums.DANGER_ALERT);

  }

  goToCustomer(customerId: number) {
    this.router.navigate(['/manageCustomer', customerId]);
    this.reset();
  }

  reset() {
    this.activeModal.close();
    this.searchReceiptNum = "";
  }

}
