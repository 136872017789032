export class Commission {

    constructor(private _id: number, private _loanId: number,
        private _amount: number, private _assignee: string,
        private _assignor: string, private _transactionId: number,
        private _dateCreated: Date, private _customerId?: number,
        private _customerName?: string, private _principal?: number,
        private _assigned?: boolean, private _paymentAmount?: number, private _paid?: number,
        private _customerCode?: string, private _companyId?: number, private _companyCode?: string, private _scheme?: string) {

    }


    set ID(id: number) { this._id = id }
    get ID(): number { return this._id }

    set LoanId(loanId: number) { this._loanId = loanId }
    get LoanId(): number { return this._loanId }

    set Amount(amount: number) { this._amount = amount }
    get Amount(): number { return this._amount }

    set TransactionId(transactionId: number) { this._transactionId = transactionId }
    get TransactionId(): number { return this._transactionId }


    set CustomerId(customerId: number) { this._customerId = customerId }
    get CustomerId(): number { return this._customerId }

    set Assignee(assignee: string) { this._assignee = assignee }
    get Assignee(): string { return this._assignee }

    set Assignor(assignor: string) { this._assignor = assignor }
    get Assignor(): string { return this._assignor }

    set DateCreated(dateCreated: Date) { this._dateCreated = dateCreated }
    get DateCreated(): Date { return this._dateCreated }

    set CustomerName(customerName: string) { this._customerName = customerName }
    get CustomerName(): string { return this._customerName }

    set Principal(principal: number) { this._principal = principal }
    get Principal(): number { return this._principal }

    set Assigned(assigned: boolean) { this._assigned = assigned }
    get Assigned(): boolean { return this._assigned }

    set PaymentAmount(paymentAmount: number) { this._paymentAmount = paymentAmount }
    get PaymentAmount(): number { return this._paymentAmount }

    set Paid(paid: number) { this._paid = paid }
    get Paid(): number { return this._paid }

    set CustomerCode(customerCode: string) { this._customerCode = customerCode }
    get CustomerCode(): string { return this._customerCode }

    get Scheme(): string {
        return this._scheme
    }
    set Scheme(value: string) {
        this._scheme = value
    }
    get CompanyCode(): string {
        return this._companyCode
    }
    set CompanyCode(value: string) {
        this._companyCode = value
    }
    get CompanyId(): number {
        return this._companyId
    }
    set CompanyId(value: number) {
        this._companyId = value
    }

}