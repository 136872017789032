<div class="container-fluid">
    <h3 class="m-2">Welcome back, <span style="color: blueviolet;">{{this.loggedInUsername | uppercase}}</span></h3>



    <div class="row g-0">
        <div class="col-12 col-md-4">
            <div class="card shadow-lg statusCard">
                <div class="card-body">
                    <div class="row">
                        <div class="col-10">
                            <h4 class="card-title" style="color: rgb(255,255,255);">&nbsp;Customers</h4>
                        </div>
                        <div class="col-2 d-flex justify-content-end align">
                            <button class="btn btn-warning" (click)="openAddCustomerModal()"><i class="fas fa-plus"></i>
                            </button>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-6" style="border-end: 2px solid rgba(238, 134, 221, 0.452);">
                            <p style="margin: 0;color: rgb(255, 255, 255);"><i class="fas fa-hand-holding-usd"></i>
                                Amount
                            </p>
                            <h2 class="text-start" style="color: white;">{{totalLoanAmount ||0}}</h2>
                        </div>
                        <div class="col-6">
                            <p style="margin: 0;color: rgb(255,255,255);"><i class="fa fa-user"></i> Count</p>
                            <h2 class="text-start" style="color: white;">{{totalLoanCount ||0}}</h2>
                        </div>
                    </div>


                </div>
            </div>
        </div>
        <div class="col-12 col-md-4" *ngIf="sharedService.isStaff()==true">
            <div class="card shadow-lg statusCard">
                <div class="card-body">
                    <div class="row">
                        <div class="col-10">
                            <h4 class="card-title" style="color: rgb(255,255,255);">&nbsp;Tasks</h4>
                        </div>
                        <div class="col-2  d-flex justify-content-end align">
                            <button class="btn btn-warning" (click)="openAddTaskModal()"><i class="fas fa-plus"></i>
                            </button>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-6" style="border-end: 2px solid rgba(238, 134, 221, 0.452);">
                            <p style="margin: 0;color: rgb(255,255,255);"><i class="fa fa-calendar"></i> Total</p>
                            <h2 class="text-start" style="color: white;">{{todayTotalTask}}</h2>
                        </div>
                        <div class="col-6">
                            <p style="margin: 0;color: rgb(255,255,255);"><i class="fas fa-thumbtack"></i> Pending</p>
                            <h2 class="text-start" style="color: white;">{{todayPendingTask}}</h2>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="col-12 col-md-4" *ngIf="sharedService.isStaff()==false|| this.sharedService.isBookkeepingStaff()">
            <div class="card shadow-lg statusCard">
                <div class="card-body">
                    <div class="row">
                        <div class="col-10">
                            <h4 class="card-title" style="color: rgb(255,255,255);">&nbsp;You've Spent</h4>
                        </div>
                        <div class="col-2  d-flex justify-content-end align">
                            <button class="btn btn-warning" (click)="goToExpenseReport()"><i
                                    class="fas fa-info"></i></button>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-sm-12">
                            <p style="margin: 0;color: rgb(255,255,255);"><i class="fas fa-dollar-sign"></i> Total</p>
                            <h3 class="text-start" style="color: white;">RM {{expenseOutTotal+dlExpenseOutTotal |
                                number:'1.0-2'}}</h3>
                        </div>
                        <div class="col-4 d-md-none d-lg-inline"
                            style="border-start: 2px solid rgba(238, 134, 221, 0.452);border-end: 2px solid rgba(238, 134, 221, 0.452);">
                            <p style="margin: 0;color: rgb(255,255,255);"><i class="fas fa-money-bill-wave"></i> Expense
                            <h3 class="text-start" style="color: white;">RM {{expenseOutTotal|
                                number:'1.0-2'}}</h3>
                        </div>
                        <div class="col-4 d-md-none d-lg-inline">
                            <p style="margin: 0;color: rgb(255,255,255);"><i class="fas fa-coins"></i> DL</p>
                            <h3 class="text-start" style="color: white;">RM {{dlExpenseOutTotal |
                                number:'1.0-2'}}</h3>
                        </div>

                    </div>

                </div>
            </div>
        </div>
        <div class="col-12 col-md-4">
            <div class="card shadow-lg statusCard">
                <div class="card-body">
                    <div class="row">
                        <div class="col-10">
                            <h4 class="card-title" style="color: rgb(255,255,255);">&nbsp;Cash</h4>
                        </div>
                        <div class="col-2  d-flex justify-content-end align">
                            <button class="btn btn-warning" (click)="openTransactionHistoryModal()"><i
                                    class="fas fa-history"></i> </button>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col">
                            <p style="margin: 0;color: rgb(255, 255, 255);"><i class="fas fa-hand-holding-usd"></i>
                                Amount
                            </p>
                            <h2 class="text-start" style="color: white;" *ngIf="userCashAccount">RM
                                {{userCashAccount.Balance ||0}}</h2>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <mat-tab-group mat-stretch-tabs="false">

        <mat-tab label="DUE EPF">
            <div class="row">
                <div class="col-12 col-md-6" *ngIf="sharedService.isStaff()==false">
                    <div class="card shadow-lg" style="min-width: 40vw;min-height: 100vh;">
                        <div class="card-body">
                            <ng-container *ngIf="transactionRequestList!=undefined && transactionRequestList.length>0">
                                <h2> <i class="fas fa-exclamation-triangle"></i> HQ Assigned Transaction</h2>
                                <div class="row">
                                    <div class="col-12" *ngFor="let request of transactionRequestList">
                                        <app-assigned-transaction-card
                                            [transactionRequest]="request"></app-assigned-transaction-card>
                                    </div>
                                </div>
                            </ng-container>
                            <hr />
                            <app-due-epf-list></app-due-epf-list>
                        </div>

                    </div>

                </div>
                <div class="col-12 col-md-6">

                    <div class="card shadow-lg" style="min-width: 40vw;min-height: 100vh;">
                        <div class="card-body">
                            <ul class="nav nav-tabs">
                                <li class="nav-item"><a class="nav-link active" role="tab"  data-bs-toggle="tab"
                                        href="#tabPending"><strong><i class="fab fa-readme"></i> PENDING</strong></a>
                                </li>
                                <li class="nav-item" *ngIf="sharedService.isStaff()==false"><a class="nav-link"
                                        role="tab"  data-bs-toggle="tab" href="#tabApproved"><strong><i
                                                class="fas fa-thumbs-up"></i>
                                            APPROVED</strong></a></li>
                            </ul>


                            <div class="tab-content">
                                <div class="tab-pane active" role="tabpanel" id="tabPending">
                                    <ng-container *ngFor="let request of sharedLoanRequestList">
                                        <app-shared-loan-agreement
                                            [sharedLoanRequest]="request"></app-shared-loan-agreement>
                                    </ng-container>

                                    <ng-container *ngFor="let request of loanRequestList">
                                        <div class="mb-2 pt-2" *ngIf="request.Approved==undefined">


                                            <app-all-loan-request-card [request]="request"></app-all-loan-request-card>
                                        </div>

                                    </ng-container>
                                </div>
                                <div class="tab-pane" role="tabpanel" id="tabApproved">
                                    <ng-container *ngFor="let request of loanRequestList">
                                        <div class="mb-2" *ngIf="request.Approved==true">
                                            <app-loan-request-details [request]="request" [isExpanded]="true"
                                                [showPrevious]="true">
                                            </app-loan-request-details>
                                        </div>

                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

        </mat-tab>




        <mat-tab label="TASKS">
            <div class="row g-0">
                <div class="col-12 col-sm-5">
                    <igx-calendar [(ngModel)]="calendarDate" (selected)="filterListByDate()" #calendar>
                    </igx-calendar>
                    <!-- <igx-date-picker></igx-date-picker> -->
                </div>

                <div class="col-12 col-sm-7">
                    <div class="row m-0" style="background-color: black;">
                        <div class="col m-0 my-2">
                            <button class="btn btn-primary me-1" (click)="openTaskCollectionModal()">
                                <i class="fas fa-money-check"></i>
                            </button>
                            <button class="btn btn-info me-1" (click)="loadTaskList()" *ngIf="showTask==false">
                                <i class="fas fa-eye"></i>
                                <!-- <i class="fas fa-eye-slash"></i> -->
                            </button>

                            <button class="btn btn-danger me-1" (click)="hideTaskList()" *ngIf="showTask==true">
                                <i class="fas fa-eye-slash"></i>
                            </button>
                        </div>
                        <div class="col m-0 d-flex justify-content-end  my-2">


                            <input type="text" class="form-control" placeholder="Search" aria-label="Search"
                                name="searchText" [(ngModel)]="searchText" (keyup)="filterList()">

                            <div class="dropdown show ms-2">
                                <a class="btn btn-warning dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                     data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Sort
                                </a>

                                <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink">
                                    <a class="dropdown-item" (click)="sortList('status')">Status</a>
                                    <a class="dropdown-item" (click)="sortList('name')">Customer Name</a>
                                    <a class="dropdown-item" (click)="sortList('desc')">Description</a>
                                    <a class="dropdown-item" (click)="sortList('date')">Date</a>
                                </div>
                            </div>


                        </div>
                    </div>

                    <ng-container *ngFor="let task of taskList;">
                        <div class="row g-0">
                            <div class="col">
                                <div class="card taskCard shadow-lg"
                                    [style.border-end-color]="getStatusColor(task.IsDone)"
                                    [ngClass]="addTaskStyle(task.Date,task.IsDone)"
                                    (click)="viewTaskDetails(task.ID,task.CustomerId,task.ProfileId)">
                                    <div class="card-body">
                                        <h6 class="card-title"><i class="fas fa-star" style="color: purple;"
                                                *ngIf="notOwnTask(task.Assignee)"></i>
                                            {{task.CustomerName | uppercase }} </h6>
                                        <p><strong>{{task.Description}}</strong></p>
                                        <ng-container *ngIf="task.FollowUpRemark!=''">
                                            <p style="color: slategrey;">
                                                <strong>{{task.FollowUpRemark| uppercase}}</strong>
                                            </p>
                                        </ng-container>

                                        <p class="text-end"><span class="text-success" *ngIf="task.IsDone==true">Done
                                                On:
                                                {{task.DateDone | date: 'dd-MM-yyyy'}} |</span>
                                            {{task.Date | date: 'dd-MM-yyyy'}}</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>

            </div>
        </mat-tab>
        <mat-tab label="LEADS ({{leadList.length}})">
            <h1> PENDING CUSTOMER LIST</h1>
            <mat-form-field>
                <mat-label>Filter</mat-label>
                <input class="textInputLong" matInput (keyup)="applyFilter($event)"
                    placeholder="Enter searching criteria">
            </mat-form-field>

            <ng-container *ngFor="let lead of leadList">
                <div class="card leadCard shadow-lg">
                    <div class="card-body">
                        <p>Name: {{lead.Name}}</p>
                        <p>IC Number:{{sharedService.processICNum(lead.ICNumber)}}</p>
                        <p>Phone:{{lead.ContactNo}}</p>
                        <p>Job:{{lead.Profiles[0].Occupation}}</p>
                        <p>Salary: {{lead.Profiles[0].Salary}}</p>
                        <p>Remarks:{{lead.Profiles[0].Remark}}</p>

                        <button mat-fab color="primary" aria-label="Pay" style="float: right;"
                            (click)="openAddLoanModal(lead)">
                            <i class="fas fa-handshake"></i>
                        </button>

                        <button mat-fab color="accent" aria-label="Pay" style="float: right;"
                            (click)="goToCustomer(lead)">
                            <i class="fas fa-id-badge"></i>
                        </button>
                    </div>
                </div>
            </ng-container>

        </mat-tab>
    </mat-tab-group>


</div>