import { Component, OnInit, Input, ChangeDetectorRef, OnChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadImageComponent } from 'src/app/Images/upload-image/upload-image.component';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Customer } from 'src/model/customer.model';
import { EnlargeImageComponent } from 'src/app/Images/enlarge-image/enlarge-image.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { FileInterface } from 'src/model/file.interface';


@Component({
  selector: 'app-customer-album',
  templateUrl: './customer-album.component.html',
  styleUrls: ['./customer-album.component.css']
})





export class CustomerAlbumComponent implements OnInit, OnChanges {

  normalFileArr: FileInterface[] = new Array;
  supportingDocArr: FileInterface[] = new Array;
  contactDocsArr: FileInterface[] = new Array;
  loanDocsArr: FileInterface[] = new Array;
  @Input() viewCustomer: Customer;

  isContactCollapsed: boolean = true;
  isLoanCollapsed: boolean = true;

  constructor(private modalService: NgbModal, private restApi: RestApiService, private constructApi: ConstructAPI,
    private cdr: ChangeDetectorRef, public enums: Enums, public sharedService: SharedService) {
  }




  ngOnInit() {

    // this.getAllPictures();
  }

  ngOnChanges() {
    if (this.normalFileArr.length > 0)
      this.normalFileArr.splice(0, this.normalFileArr.length);
    if (this.supportingDocArr.length > 0)
      this.supportingDocArr.splice(0, this.supportingDocArr.length);
    if (this.contactDocsArr.length > 0)
      this.contactDocsArr.splice(0, this.contactDocsArr.length);
    if (this.loanDocsArr.length > 0)
      this.loanDocsArr.splice(0, this.loanDocsArr.length);

    this.getAllFiles();
  }




  private getAllFiles() {

    var xhttp = this.restApi.getRequest(this.constructApi.getAllFiles(this.viewCustomer.ID));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {

          var lastModifiedDate = (json["Last-Modified"] == undefined) ? json["Last-Modified"] : new Date(json["Last-Modified"]);
          let file = { label: json.Name, url: json.Data, contentType: json["Content-Type"], lastModified: lastModifiedDate, uploadedBy: json.uploadedBy }

          // if (file.contentType.includes("image/") == false) {
          //   this.fileArr.push(file)
          // }
          // else {
          if (file.label.includes("contact"))
            this.contactDocsArr.push(file);

          else if (file.label.includes("application-form") || file.label.includes("epf-statement") ||
            file.label.includes("formj") || file.label.includes("consent-form")
            || file.label.includes("reference-form") || file.label.includes("ic/front/")
            || file.label.includes("ic/back") || file.label.includes("payslip") || file.label.includes("bank-statement") || file.label.includes("atm-card") || file.label.includes("epf-proof") || file.label.includes("acknowledgement"))
            this.supportingDocArr.push(file);

          else if (file.label.includes("loan"))
            this.loanDocsArr.push(file);
          else
            this.normalFileArr.push(file);
          // }

        }
        );


        this.supportingDocArr.sort((a, b) => (a.contentType > b.contentType && a.lastModified > b.lastModified) ? -1 : 1);
        this.contactDocsArr.sort((a, b) => (a.contentType > b.contentType && a.lastModified > b.lastModified) ? -1 : 1);
        this.normalFileArr.sort((a, b) => (a.contentType > b.contentType && a.lastModified > b.lastModified) ? -1 : 1);
        this.loanDocsArr.sort((a, b) => (a.contentType > b.contentType && a.lastModified > b.lastModified) ? -1 : 1);
      }
    };

  }



  openImageModal(supportingDocType: string) {
    const modalRef = this.sharedService.openModal(UploadImageComponent);

    modalRef.componentInstance.selectedCustomerId = this.viewCustomer.ID;
    modalRef.componentInstance.supportingDocType = supportingDocType;
    //get added loan
    modalRef.componentInstance.passEntry.subscribe((entry) => {

      this.normalFileArr.splice(0, this.normalFileArr.length)
      this.supportingDocArr.splice(0, this.supportingDocArr.length)
      this.contactDocsArr.splice(0, this.contactDocsArr.length)
      this.loanDocsArr.splice(0, this.loanDocsArr.length)
      this.getAllFiles();
    });

    modalRef.result.then(() => {
      //detect changes
      this.cdr.detectChanges();
    })
  }


}
