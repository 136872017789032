<div class="container-fluid">
    <div class="d-flex justify-content-end align-items-end">
        <button class="btn btn-info" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseExample">
            <i class="fas fa-chevron-circle-down" *ngIf="isCollapsed"></i>
            <i class="fas fa-chevron-circle-up" *ngIf="isCollapsed==false"></i>
        </button>
    </div>



    <div class="collapse" id="collapseExample" [ngbCollapse]="isCollapsed">
        <app-report-generator [selectedCompany]="selectedCompany"></app-report-generator>
    </div>
    <!-- <h2> List Hutang( {{startDate |date:'yyyy-MM-dd'}} - {{endDate |date:'yyyy-MM-dd'}}) </h2> -->

    <div class="row mt-2">
        <div class="col-12 col-md-5">
            <h2>List Hutang ({{endDate |date:'yyyy-MM-dd'}})</h2>
        </div>
        <div class="col-6 col-md-4 d-flex justify-content-center align-items-center"
            *ngIf="isAdminVerified!=undefined && isVerified!=undefined">
            <h2 class="badge rounded-pill text-bg-success" *ngIf="isAdminVerified==false && isVerified==true">Verified By
                Manager - {{verifiedUser}} on {{verifiedDate | date:'dd-MM-yyyy'}}
                <button type="button" class="btn btn-dark btn-circle btn-md"
                    *ngIf="sharedService.isBossAccount()==true"><i class="fas fa-trash-alt"
                        (click)="openDeleteReasonDialog()"></i></button>
            </h2>
            <h2 class="badge rounded-pill text-bg-info ms-2" *ngIf="isAdminVerified==true">Verified By Admin on
                {{verifiedDate | date:'dd-MM-yyyy'}}
                <button type="button" class="btn btn-dark btn-circle btn-md"
                    *ngIf="sharedService.isBossAccount()==true"><i class="fas fa-trash-alt"
                        (click)="openDeleteReasonDialog()"></i></button>
            </h2>
            <h2 class="badge rounded-pill text-bg-danger" *ngIf="isVerified==false">Unverified</h2>
        </div>
        <div class="col-6 col-md-3"
            *ngIf="sharedService.isBossAccount()==true|| sharedService.isAdmin()|| sharedService.isBranchAccountHandler()">
            <button class="btn btn-warning" (click)="openVerifyModal()" *ngIf="checkCanVerify()">Verify</button>
        </div>
    </div>

    <div class="row">
        <div class="col-6">
            <h4 class="text-success fw-bold">Total: {{calculateListHutangTotal() |number:'1.2-2'}}</h4>
        </div>
        <div class="col-6">
            <h4 class="text-primary fw-bold">CashBook: {{cashBookAmount |number:'1.2-2'}}</h4>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-md-6">
            <div class="card">
                <div class="card-header bg-secondary">
                    <h4><strong>Credit</strong></h4>
                </div>
                <div class="card-body">
                    <div class="cashCard card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col d-flex justify-content-end align-items-end"
                                    [hidden]="showBalanceCollapse==false" (click)="showBalanceCollapse=false"
                                    style="cursor: pointer;">
                                    <i class="fas fa-chevron-down"></i>
                                </div>

                                <div class="col d-flex justify-content-end align-items-end"
                                    [hidden]="showBalanceCollapse==true" (click)="showBalanceCollapse=true"
                                    style="cursor: pointer;">
                                    <i class="fas fa-chevron-up"></i>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-6">Cash Balance:</div>
                                <div class="col-6">{{cashAccountBalance|number:'1.2-2'}}</div>
                                <div class="col-6">Bank Balance:</div>
                                <div class="col-6">{{bankAccountBalance|number:'1.2-2'}}</div>
                                <div class="col-6 px-4"
                                    style="border-top:1px solid black;border-bottom:1px solid black">
                                    <strong>Total:</strong>
                                </div>
                                <div class="col-6" style="border-top:1px solid black;border-bottom:1px solid black">
                                    <strong>{{roundTo2Dec(cashAccountBalance+bankAccountBalance) }}</strong>
                                </div>
                            </div>

                            <div class="collapse" id="collapseCashList" [ngbCollapse]="showBalanceCollapse">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr class="bg-secondary">
                                            <th colspan="2" class="text-center ">Cash Account</th>
                                        </tr>
                                        <tr>
                                            <th>Account</th>
                                            <th>Balance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let cashAccount of cashAccountList">
                                            <td class="accountCol"
                                                (click)="openTransactionHistoryModal(cashAccount.ID)">
                                                {{cashAccount.Name}}</td>
                                            <td> <button class="btn btn-warning me-1"
                                                    (click)="openEditAccountModal(cashAccount)"
                                                    *ngIf="sharedService.isBossAccount()==true">
                                                    <i class="fas fa-pencil-alt"></i></button> {{cashAccount.Balance}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr class="bg-info">
                                            <th colspan="2" class="text-center">Bank Account</th>
                                        </tr>
                                        <tr>
                                            <th>Account</th>
                                            <th>Balance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let bankAccount of companyBankAccountList">
                                            <td class="accountCol"
                                                (click)="openTransactionHistoryModal(bankAccount.ID)">
                                                {{bankAccount.Name}}</td>
                                            <td><button class="btn btn-warning me-1"
                                                    (click)="openEditAccountModal(bankAccount)"
                                                    *ngIf="sharedService.isBossAccount()==true">
                                                    <i class="fas fa-pencil-alt"></i></button> {{bankAccount.Balance}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>


                    <div class="mt-2">

                        <div class="card report-card" (click)="toAdvanceList()">
                            <div class="card-body">
                                <h5>Advances: {{advanceTotal}}</h5>
                            </div>
                        </div>
                    </div>

                    <div class="card report-card mt-3" (click)="toReport(enums.HUTANG_REPORT)">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-6">HUTANG:</div>
                                <div class="col-6">{{hutangTotal}}</div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
        <div class="col-12  col-md-6">
            <div class="card">
                <div class="card-header bg-secondary">
                    <h4><strong>Debit</strong></h4>
                </div>
                <div class="card-body">
                    <div class="card report-card mt-3" (click)="toReport(enums.ASSET_REPORT)">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-6">Asset:</div>
                                <div class="col-6">{{assetTotal}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-3 ">
                        <div class="card-header bg-warning">
                            <h5><strong>CHARGES</strong></h5>
                        </div>
                        <div class="card-body">
                            <div class="card report-card mt-3" (click)="toReport(enums.EXTRA_REPORT)">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-6">Duit Lebih:</div>
                                        <div class="col-6">{{duitLebihBalance||0}}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="card report-card mt-3" (click)="toReport(enums.STAMP_REPORT)">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-6">Stamp:</div>
                                        <div class="col-6">{{stampTotal}}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="card report-card mt-3" (click)="toReport(enums.SC_REPORT)">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-6">S/C:</div>
                                        <div class="col-6">{{scTotal}}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="card report-card mt-3" (click)="toReport(enums.HANDLING_REPORT)">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-6">Handling:</div>
                                        <div class="col-6">{{handlingTotal}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-3">
                        <div class="card-header bg-secondary">
                            <h5><strong>SIMPAN</strong></h5>
                        </div>
                        <div class="card-body">
                            <div class="card report-card mt-3" (click)="toReport(enums.SIMPAN_REPORT)">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-6">Simpan:</div>
                                        <div class="col-6">{{simpanTotal}}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="card mt-3">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col d-flex justify-content-end align-items-end"
                                            [hidden]="showCommissionCollapse==false"
                                            (click)="showCommissionCollapse=false" style="cursor: pointer;">
                                            <i class="fas fa-chevron-down"></i>
                                        </div>

                                        <div class="col d-flex justify-content-end align-items-end"
                                            [hidden]="showCommissionCollapse==true"
                                            (click)="showCommissionCollapse=true" style="cursor: pointer;">
                                            <i class="fas fa-chevron-up"></i>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">Simpan Commission:</div>
                                        <div class="col-6">{{commissionTotal}}</div>
                                        <div class="collapse" id="collapseCommission"
                                            [ngbCollapse]="showCommissionCollapse">
                                            <table class="table table-bordered mt-2" *ngIf="commissionTotal>0">
                                                <thead>
                                                    <tr>
                                                        <th>Staff</th>
                                                        <th>Customer</th>
                                                        <th>Amount (Rate )</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let commission of commissionList">
                                                        <td *ngIf="sharedService.isBossAccount()"> <button
                                                                class="btn btn-danger"
                                                                (click)="confirmBeforeDeletingCommission(commission)">
                                                                <i class="fas fa-trash-alt"></i>
                                                            </button></td>
                                                        <td>{{commission.Assignee}}</td>
                                                        <td>{{commission.CustomerName}}</td>
                                                        <td>{{commission.Amount}}
                                                            ({{(commission.Amount/commission.Principal)*100
                                                            |number:'1.1-2'}}%)
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="card report-card mt-3" (click)="toReport(enums.SIMPAN_BD_REPORT)">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-6">Simpan BD:</div>
                                        <div class="col-6">{{bdTotal}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</div>