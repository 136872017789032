import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-apply-asset',
  templateUrl: './apply-asset.component.html',
  styleUrls: ['./apply-asset.component.css']
})
export class ApplyAssetComponent implements OnInit {

  constructor() { }


  loanId: number;
  requesterId: number;
  approverId: number;
  amount: number;
  remark: string;
  approved: boolean;


  ngOnInit() {
  }

  createAssetRecord() {
    var xhr = new XMLHttpRequest();
    var url = "http://localhost:10080/api/v1/asset";
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-type", "application/json");
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200) {
      }
    }
    var data = JSON.stringify({
      "loanId": this.loanId,
      "requesterId": this.requesterId,
      "approverId": this.approverId,
      "amount": this.amount,
      "remark": this.remark,
      "approved": this.approved
    });
    xhr.send(data);

  }


  getAllAssets() {

    var xhr = new XMLHttpRequest();
    var url = "http://localhost:10080/api/v1/assets";
    xhr.open("GET", url, true);
    xhr.setRequestHeader("Content-type", "application/json");
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200) {
        var json = JSON.parse(xhr.responseText);
        // console.log(this.responseText);
      }
    }
    xhr.send();

  }
  reset() {

  }
}
