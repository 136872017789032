export class Advance {
    constructor(private _id: number, private _username: string, private _date: Date, private _amount: number,private _remark: string) {

    }

    set ID(id: number) { this._id = id }
    get ID(): number { return this._id }


    set Username(username: string) { this._username = username }
    get Username(): string { return this._username }

    set Date(date: Date) { this._date = date }
    get Date(): Date { return this._date }
    
    set Amount(amount: number) { this._amount = amount }
    get Amount(): number { return this._amount }

    set Remark(remark: string) { this._remark = remark }
    get Remark(): string { return this._remark }

}