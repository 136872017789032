import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { report } from 'process';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';

interface Report {
  companyName: string,
  cashBalance: number,
  bankBalance: number,
  companyId: number,
  latestCheckin?: Date,
  checkinBy?: string
}


@Component({
  selector: 'app-cash-balance-report',
  templateUrl: './cash-balance-report.component.html',
  styleUrls: ['./cash-balance-report.component.css'],
  providers: [DatePipe]
})
export class CashBalanceReportComponent implements OnInit, OnDestroy, OnChanges {
  endDate: Date;
  companyGroup: number;

  reportList: Report[] = new Array;
  isCollapsed: boolean = false;
  routeSubscription: any;
  warningAmount: Number = 20000;
  filterWarning = false;

  fullReportList: Report[] = new Array;

  constructor(public enums: Enums, public sharedService: SharedService, private activatedRoute: ActivatedRoute, private datePipe: DatePipe, private restapi: RestApiService,
    private constructApi: ConstructAPI) { }

  ngOnInit(): void {
    this.routeSubscription = this.activatedRoute.paramMap.subscribe(params => {
      this.endDate = (params.get('endDate') == undefined) ? undefined : new Date(params.get('endDate'));
      this.companyGroup = (params.get('companyGroup') == undefined) ? undefined : Number(params.get('companyGroup'));

      this.getCashAndBankBalance();
    });
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes.companyGroup) {
      this.getCashAndBankBalance();
    }


  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }


  getCashAndBankBalance() {

    this.reportList = [];
    this.fullReportList = [];

    if (this.endDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var addADayDate = new Date(Number(this.endDate) + (3600 * 1000 * 24) - 1);
    var endDateStr = (this.endDate == undefined) ? undefined : addADayDate.toISOString();

    var xhttp = (this.companyGroup == 0)
      ? this.restapi.getRequest(this.constructApi.getCompaniesCashBalance(endDateStr, 500))
      : this.restapi.getRequest(this.constructApi.getCompanyGroupCompaniesCashBalance(this.companyGroup, endDateStr, 500));

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {

          var cashBalance = (json.cash == undefined) ? 0 : json.cash;
          var bankBalance = (json.bank == undefined) ? 0 : json.bank;

          var report = { companyName: json.companyCode, cashBalance: cashBalance, bankBalance: bankBalance, companyId: json.id }
          this.fullReportList.push(report);

        }
        );

        this.fullReportList.sort((a, b) => (a.companyName < b.companyName ? -1 : 1));
        this.getAllCompaniesLatestCheckins();
      }
    };
  }

  calculateTotalBalance() {
    var total = 0;
    for (let i = 0; i < this.reportList.length; i++) {
      total += this.reportList[i].bankBalance + this.reportList[i].cashBalance;
    }

    return total;
  }


  getAllCompaniesLatestCheckins() {
    var xhttp = this.restapi.getRequest(this.constructApi.getCompaniesLatestCheckIns())



    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {

          var index = this.fullReportList.findIndex(report => report.companyId === json.companyId);

          if (index != -1) {
            this.fullReportList[index].latestCheckin = new Date(json.date);
            this.fullReportList[index].checkinBy = json.username;
          }


        }
        );

        this.filterWarningCompanies();
      }
    };
  }

  filterWarningCompanies() {
    

    if (this.filterWarning == true) {
      this.reportList = [];
      this.reportList = this.fullReportList.filter(company => { return Number(company.bankBalance + company.cashBalance) > Number(this.warningAmount) });
    }
    else
      this.reportList = this.fullReportList;
  }
}
