<div class="bg-danger-subtle p-2 text-center sticky-div">
    <h3 class="text-danger fw-bold mb-1">Draft version for new calculation!</h3>
    <h5 class="text-danger-emphasis fw-bold">This calculation will be rolled out on {{newVersionDate}}!</h5>
</div>
<div class="px-5 py-2 my-3 container-md" style="background-color: whitesmoke;">
    <app-epf-request-calculator [epfLimitRuleSet]="enums.draftEPFLimitList"></app-epf-request-calculator>
</div>=
<div class="bg-light vh-100 w-100" id="watermark" data-watermark="Draft version"></div>
<h1 id="watermark" data-watermark="Draft version" class="text-center opacity-50 text-uppercase ms-4">Draft version</h1>
<div class="tag-wrap">
    <div id="tag" class="text-bg-danger">
        <h4 id="tag-text" class="fw-bold">Draft</h4>
    </div>
</div>