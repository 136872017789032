<form>
    <!-- Modal Header -->
    <div class="modal-header" style="background-color: lightsalmon;">
        <h4 class="modal-title">{{setWithdrawalTitle()}}</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body">

        <div class="card shadow">
            <div class="card-body">
                <h5 class="text-primary fw-bold">Available amount: RM {{availableWithdrawalAmount}}</h5>
            </div>
        </div>


        <!--Amount-->

        <div class="card mt-2">
            <div class="card-header">Withdrawal Details</div>
            <div class="card-body">
                <div class="group">
                    <input type="number" required class="textInputLong" name="amount" [(ngModel)]="amount" min=0
                        (keypress)="sharedService.isNumberKey($event)" (keyup)="selectedAccountId=undefined">
                    <span class="highlight"></span>
                    <span class="bar barLong"></span>
                    <label>Amount</label>
                </div>

                <!--Date-->
                <mat-form-field class="full-width-formField bar">
                    <input matInput class="textInputLong" [matDatepicker]="myDatepicker" name="withdrawalDate"
                        [(ngModel)]="withdrawalDate" placeholder="Withdrawal Date" [max]="sharedService.getTodayDate()"
                        onkeydown="return false">
                    <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                    <mat-datepicker #myDatepicker name="withdrawalDate"></mat-datepicker>
                </mat-form-field>

                <!--Account Number-->
                <div class="row p-3 d-flex justify-content-center align-items-center">

                    <div class="col-12">
                        <p>From Account</p>
                    </div>
                    <div class="col-12">
                        <select class="browser-default form-select" name="selectedAccount"
                            [(ngModel)]="selectedAccountId">
                            <optgroup label="Company Account">
                                <option *ngFor="let account of companyBankAccountList" [value]="account.ID"
                                    [selected]="setSelectedAccount(account.ID)"
                                    [disabled]="isBalanceInsufficient(account.Balance)">
                                    {{account.Name}} (RM {{account.Balance ||0}})
                                </option>
                            </optgroup>

                            <optgroup label="Cash Account">
                                <option *ngFor="let account of cashAccountList" [value]="account.ID"
                                    [selected]="setSelectedAccount(account.ID)"
                                    [disabled]="isBalanceInsufficient(account.Balance)">
                                    {{account.Name}} (RM {{account.Balance ||0}})
                                </option>
                            </optgroup>

                        </select>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="submit()" [disabled]="disabledSubmit">Add
            Withdrawal</button>
        <button class="btn btn-outline-danger" type="button" (click)="reset()">Cancel</button>
    </div>

</form>