import r from "./svgs/bottom-records.svg.js";
import a from "./svgs/bottom-percentile-records.svg.js";
import s from "./svgs/case-sensitive.svg.js";
import n from "./svgs/check.svg.js";
import c from "./svgs/contains.svg.js";
import i from "./svgs/custom-filter.svg.js";
import m from "./svgs/does-not-contain.svg.js";
import l from "./svgs/does-not-end-with.svg.js";
import d from "./svgs/does-not-start-with.svg.js";
import u from "./svgs/end-expression.svg.js";
import p from "./svgs/ends-with.svg.js";
import h from "./svgs/equals.svg.js";
import f from "./svgs/exact-match.svg.js";
import g from "./svgs/greater-than.svg.js";
import y from "./svgs/greater-than-or-equal.svg.js";
import v from "./svgs/is-after.svg.js";
import w from "./svgs/is-before.svg.js";
import k from "./svgs/is-empty.svg.js";
import I from "./svgs/is-false.svg.js";
import q from "./svgs/is-not-null.svg.js";
import x from "./svgs/is-null.svg.js";
import b from "./svgs/is-true.svg.js";
import E from "./svgs/last-month.svg.js";
import T from "./svgs/last-year.svg.js";
import W from "./svgs/less-than.svg.js";
import N from "./svgs/less-than-or-equal.svg.js";
import M from "./svgs/last-quarter.svg.js";
import Q from "./svgs/last-week.svg.js";
import R from "./svgs/match.svg.js";
import F from "./svgs/month.svg.js";
import O from "./svgs/next-month.svg.js";
import Y from "./svgs/next-year.svg.js";
import A from "./svgs/next-week.svg.js";
import P from "./svgs/not-empty.svg.js";
import S from "./svgs/not-equal.svg.js";
import z from "./svgs/next-quarter.svg.js";
import B from "./svgs/previous-quarter.svg.js";
import C from "./svgs/previous-week.svg.js";
import D from "./svgs/quarter-four.svg.js";
import j from "./svgs/quarter-one.svg.js";
import G from "./svgs/quarter-two.svg.js";
import H from "./svgs/quarter-three.svg.js";
import J from "./svgs/all.svg.js";
import K from "./svgs/start-expression.svg.js";
import L from "./svgs/starts-with.svg.js";
import U from "./svgs/this-month.svg.js";
import V from "./svgs/this-year.svg.js";
import X from "./svgs/this-quarter.svg.js";
import Z from "./svgs/this-week.svg.js";
import _ from "./svgs/today.svg.js";
import $ from "./svgs/top-records.svg.js";
import ee from "./svgs/top-percentile-records.svg.js";
import oe from "./svgs/tomorrow.svg.js";
import te from "./svgs/ungroup.svg.js";
import re from "./svgs/yesterday.svg.js";
import ae from "./svgs/year.svg.js";
import se from "./svgs/year-to-date.svg.js";
const ne = {
  name: "bottom-percentile-records",
  value: a,
  categories: ["editor"]
}, ce = {
  name: "bottom-records",
  value: r,
  categories: ["editor"]
}, ie = {
  name: "case-sensitive",
  value: s,
  categories: ["editor"],
  keywords: [
    "case",
    "sensitive",
    "character",
    "text",
    "mode",
    "sentence",
    "style",
    "change",
    "compare",
    "filter"
  ]
}, me = {
  name: "check",
  value: n,
  categories: ["editor"],
  keywords: ["check", "checked", "mark", "tick", "ok", "success"]
}, le = {
  name: "contains",
  value: c,
  categories: ["editor"],
  keywords: ["contains", "letter", "contain", "compare", "filter"]
}, de = {
  name: "custom-filter",
  value: i,
  categories: ["editor"],
  keywords: ["custom", "filter"]
}, ue = {
  name: "does-not-contain",
  value: m,
  categories: ["editor"],
  keywords: ["contains", "character", "contain", "compare", "filter"]
}, pe = {
  name: "does-not-end-with",
  value: l,
  categories: ["editor"]
}, he = {
  name: "does-not-start-with",
  value: d,
  categories: ["editor"]
}, fe = {
  name: "end-expression",
  value: u,
  categories: ["editor"],
  keywords: ["end", "expression", "arrow", "right"]
}, e = {
  name: "ends-with",
  value: p,
  categories: ["editor"],
  keywords: ["end", "character", "compare", "filter"]
}, o = {
  name: "equals",
  value: h,
  categories: ["editor"],
  keywords: [
    "equal",
    "equals",
    "compare",
    "filter",
    "equality",
    "calculation",
    "math"
  ]
}, ge = {
  name: "exact-match",
  value: f,
  categories: ["editor"],
  keywords: [
    "exact",
    "match",
    "arrow",
    "target",
    "goal",
    "aim",
    "business",
    "dart",
    "bullseye"
  ]
}, ye = {
  name: "greater-than",
  value: g,
  categories: ["editor"],
  keywords: [
    "greater",
    "more",
    "compare",
    "next",
    "forward",
    "chevron",
    "math",
    "inequalty",
    "arrow"
  ]
}, ve = {
  name: "greater-than-or-equal",
  value: y,
  categories: ["editor"],
  keywords: ["greater", "more", "equal", "equals", "compare", "math"]
}, we = {
  name: "is-after",
  value: v,
  categories: ["editor"],
  keywords: [
    "after",
    "comparison",
    "before",
    "convert",
    "decode",
    "render",
    "scan"
  ]
}, ke = {
  name: "is-before",
  value: w,
  categories: ["editor"],
  keywords: [
    "after",
    "comparison",
    "before",
    "convert",
    "decode",
    "render",
    "scan"
  ]
}, t = {
  name: "is-empty",
  value: k,
  categories: ["editor"],
  keywords: ["empty", "box", "nothing"]
}, Ie = {
  name: "is-false",
  value: I,
  categories: ["editor"],
  keywords: [
    "false",
    "true",
    "choice",
    "options",
    "yes",
    "no",
    "check",
    "tick",
    "cross"
  ]
}, qe = {
  name: "is-not-null",
  value: q,
  categories: ["editor"],
  keywords: [
    "empty",
    "null",
    "void",
    "problem",
    "number",
    "invalid",
    "inoperative",
    "none",
    "nothing"
  ]
}, xe = {
  name: "is-null",
  value: x,
  categories: ["editor"],
  keywords: ["null", "circle", "zero", "o"]
}, be = {
  name: "is-true",
  value: b,
  categories: ["editor"],
  keywords: [
    "true",
    "false",
    "choice",
    "options",
    "yes",
    "no",
    "check",
    "tick",
    "cross"
  ]
}, Ee = {
  name: "last-month",
  value: E,
  categories: ["editor"],
  keywords: [
    "last",
    "calendar",
    "month",
    "date",
    "day",
    "previous",
    "schedule",
    "arrow",
    "left",
    "page"
  ]
}, Te = {
  name: "last-quarter",
  value: M,
  categories: ["editor"]
}, We = {
  name: "last-week",
  value: Q,
  categories: ["editor"]
}, Ne = {
  name: "last-year",
  value: T,
  categories: ["editor"],
  keywords: [
    "last",
    "calendar",
    "month",
    "date",
    "previous",
    "schedule",
    "arrow",
    "left"
  ]
}, Me = {
  name: "less-than",
  value: W,
  categories: ["editor"],
  keywords: [
    "less",
    "smaller",
    "compare",
    "previous",
    "back",
    "chevron",
    "math",
    "inequalty",
    "arrow"
  ]
}, Qe = {
  name: "less-than-or-equal",
  value: N,
  categories: ["editor"],
  keywords: ["less", "smaller", "equal", "equals", "compare", "math"]
}, Re = {
  name: "match",
  value: R,
  categories: ["editor"],
  keywords: [
    "match",
    "fit",
    "puzzle",
    "pieces",
    "jigsaw",
    "combination",
    "play",
    "game",
    "toy"
  ]
}, Fe = {
  name: "month",
  value: F,
  categories: ["editor"]
}, Oe = {
  name: "next-month",
  value: O,
  categories: ["editor"],
  keywords: [
    "next",
    "calendar",
    "month",
    "date",
    "day",
    "following",
    "schedule",
    "arrow",
    "right",
    "page"
  ]
}, Ye = {
  name: "next-quarter",
  value: z,
  categories: ["editor"]
}, Ae = {
  name: "next-week",
  value: A,
  categories: ["editor"]
}, Pe = {
  name: "next-year",
  value: Y,
  categories: ["editor"],
  keywords: [
    "next",
    "calendar",
    "month",
    "date",
    "following",
    "schedule",
    "arrow",
    "right"
  ]
}, Se = {
  name: "not-empty",
  value: P,
  categories: ["editor"],
  keywords: ["empty", "full"]
}, ze = {
  name: "not-equal",
  value: S,
  categories: ["editor"],
  keywords: [
    "equal",
    "equals",
    "different",
    "compare",
    "filter",
    "equality",
    "calculation",
    "math"
  ]
}, Be = {
  name: "previous-quarter",
  value: B,
  categories: ["editor"]
}, Ce = {
  name: "previous-week",
  value: C,
  categories: ["editor"]
}, De = {
  name: "quarter-four",
  value: D,
  categories: ["editor"]
}, je = {
  name: "quarter-one",
  value: j,
  categories: ["editor"]
}, Ge = {
  name: "quarter-three",
  value: H,
  categories: ["editor"]
}, He = {
  name: "quarter-two",
  value: G,
  categories: ["editor"]
}, Je = {
  name: "select-all",
  value: J,
  categories: ["editor"],
  keywords: ["all", "select", "check", "multiple"]
}, Ke = {
  name: "start-expression",
  value: K,
  categories: ["editor"],
  keywords: ["start", "expression", "arrow", "left"]
}, Le = {
  name: "starts-with",
  value: L,
  categories: ["editor"],
  keywords: ["start", "character", "compare", "filter"]
}, Ue = {
  name: "this-month",
  value: U,
  categories: ["editor"],
  keywords: [
    "this",
    "calendar",
    "month",
    "date",
    "day",
    "current",
    "schedule",
    "check",
    "page",
    "now"
  ]
}, Ve = {
  name: "this-quarter",
  value: X,
  categories: ["editor"]
}, Xe = {
  name: "this-week",
  value: Z,
  categories: ["editor"]
}, Ze = {
  name: "this-year",
  value: V,
  categories: ["editor"],
  keywords: [
    "this",
    "calendar",
    "month",
    "date",
    "current",
    "schedule",
    "check",
    "now"
  ]
}, _e = {
  name: "today",
  value: _,
  categories: ["editor"],
  keywords: [
    "today",
    "now",
    "check",
    "date",
    "day",
    "calendar",
    "current",
    "schedule",
    "event"
  ]
}, $e = {
  name: "top-records",
  value: $,
  categories: ["editor"]
}, eo = {
  name: "top-percentile-records",
  value: ee,
  categories: ["editor"]
}, oo = {
  name: "ungroup",
  value: te,
  categories: ["editor"],
  keywords: ["ungroup", "exclude", "extract"]
}, to = {
  name: "year",
  value: ae,
  categories: ["editor"]
}, ro = {
  name: "year-to-date",
  value: se,
  categories: ["editor"]
}, ao = {
  name: "yesterday",
  value: re,
  categories: ["editor"],
  keywords: [
    "yesterday",
    "date",
    "calendar",
    "left",
    "arrow",
    "past",
    "previous",
    "day"
  ]
}, so = {
  name: "tomorrow",
  value: oe,
  categories: ["editor"],
  keywords: [
    "tomorrow",
    "date",
    "calendar",
    "right",
    "arrow",
    "future",
    "next",
    "day"
  ]
}, lt = [
  ne,
  ce,
  ie,
  me,
  le,
  de,
  ue,
  pe,
  he,
  fe,
  e,
  o,
  ge,
  ye,
  ve,
  we,
  ke,
  t,
  Ie,
  qe,
  xe,
  be,
  Ee,
  Te,
  We,
  Ne,
  Me,
  Qe,
  Re,
  Fe,
  Oe,
  Ye,
  Ae,
  Pe,
  Se,
  ze,
  Be,
  Ce,
  De,
  je,
  Ge,
  He,
  e,
  o,
  t,
  Je,
  Ke,
  Le,
  Ue,
  Ve,
  Xe,
  Ze,
  _e,
  eo,
  $e,
  oo,
  ro,
  to,
  ao,
  so
];
export {
  ne as bottomPercentileRecords,
  ce as bottomRecords,
  ie as caseSensitive,
  me as check,
  le as contains,
  de as customFilter,
  ue as doesNotContain,
  pe as doesNotEndWith,
  he as doesNotStartWith,
  lt as editor,
  fe as endExpression,
  e as endsWith,
  o as equals,
  ge as exactMatch,
  ye as greaterThan,
  ve as greaterThanOrEqual,
  we as isAfter,
  ke as isBefore,
  t as isEmpty,
  Ie as isFalse,
  qe as isNotNull,
  xe as isNull,
  be as isTrue,
  Ee as lastMonth,
  Te as lastQuarter,
  We as lastWeek,
  Ne as lastYear,
  Me as lessThan,
  Qe as lessThanOrEqual,
  Re as match,
  Fe as month,
  Oe as nextMonth,
  Ye as nextQuarter,
  Ae as nextWeek,
  Pe as nextYear,
  Se as notEmpty,
  ze as notEqual,
  Be as previousQuarter,
  Ce as previousWeek,
  De as quarterFour,
  je as quarterOne,
  Ge as quarterThree,
  He as quarterTwo,
  Je as selectAll,
  Ke as startExpression,
  Le as startsWith,
  Ue as thisMonth,
  Ve as thisQuarter,
  Xe as thisWeek,
  Ze as thisYear,
  _e as today,
  so as tomorrow,
  eo as topPercentileRecords,
  $e as topRecords,
  oo as ungroup,
  to as year,
  ro as yearToDate,
  ao as yesterday
};
