import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { AlertDialogComponent } from 'src/app/Shared/alert-dialog/alert-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { User } from 'src/model/user.model';

@Component({
  selector: 'app-edit-commission',
  templateUrl: './edit-commission.component.html',
  styleUrls: ['./edit-commission.component.css']
})
export class EditCommissionComponent implements OnInit {

  @Input() amount: number;
  @Input() assignee: string;
  @Input() loanId: number;
  @Input() commissionId: number;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  userList: string[] = new Array;
  constructor(private activeModal: NgbActiveModal, private modalService: NgbModal,
    private restapi: RestApiService, private constructApi: ConstructAPI, public enums: Enums, public sharedService: SharedService) { }

  ngOnInit(): void {

    this.getAllUser();
  }

  getAllUser() {
    var xhttp = this.restapi.getRequest(this.constructApi.getAllUsers())
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // Typical action to be performed when the document is ready:

        var jsonArray = JSON.parse((xhttp.responseText));
        // console.log(jsonArray);

        jsonArray.forEach(json => {

          let user = json.username;
          this.userList.push(user);
        }
        );
      }
    };
  }


  updateCommission() {

    var data = {
      "amount": this.amount,
      "assignee": this.assignee,
      "dateCreated": new Date().toISOString(),
      "id": Number(this.commissionId)
    };


    var xhr = this.restapi.patchRequest(this.constructApi.getEditCommission(Number(this.loanId)), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {

        //if success emit
        this.passEntry.emit();
        this.reset();
        this.sharedService.openAlert("Successfully updated commission",this.enums.SUCCESS_ALERT)
      }

    }
  }

  confirmBeforeUpdate() {
    var proceed = false;
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Confirm to update commission = RM " + this.amount + " to " + this.assignee + "?";
    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something\

      if (confirmation == true)
        this.updateCommission();
    });

    return proceed;
  }


  checkFieldNull() {
    var amountNull = this.amount == undefined;
    var amountValid = isNaN(+this.amount);
    var assigneeNull = this.assignee == undefined;

    return amountNull || amountValid || assigneeNull;
  }

  reset() {
    this.activeModal.close();
  }

}
