import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';


interface Asset {
  id: number,
  name: string,
  size: number,
  content_type: string;
}

interface Release {
  tagName: string,
  publishedAt: Date,
  body: string,
  assets: Asset[];
}

@Component({
  selector: 'app-deploy-webapp',
  templateUrl: './deploy-webapp.component.html',
  styleUrls: ['./deploy-webapp.component.css']
})
export class DeployWebappComponent implements OnInit {


  releaseList: Release[] = new Array;
  constructor(private activeModal: NgbActiveModal, private restApi: RestApiService, private constructApi: ConstructAPI, private sharedService: SharedService, private enums: Enums) { }

  ngOnInit(): void {
    this.getServerRelease();
  }



  getServerRelease() {
    var xhttp = this.restApi.getRequest(this.constructApi.getWebsiteReleases());
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {


        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {

          var assets = this.processReleaseAsset(json.assets);
          var release = { tagName: json.tag_name, publishedAt: new Date(json.published_at), body: json.body, assets: assets };
          // console.log(release.publishedAt)
          this.releaseList.push(release);


        }
        );

        this.releaseList.sort((a, b) => ((a.publishedAt > b.publishedAt)) ? -1 : 1);
      }
    };
  }

  processReleaseAsset(jsonArr: any) {
    var assetArr: Asset[] = new Array;
    if (jsonArr != undefined) {
      jsonArr.forEach(json => {

        var asset = { id: json.id, name: json.name, size: json.size, content_type: json.content_type }
        assetArr.push(asset);
      }
      );

      return assetArr;
    }


  }

  pushThisRelease(tag: string, filename: string) {
    var xhr = this.restApi.postRequest(this.constructApi.getUpdateWebAppRelease(tag, filename));
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {

        this.sharedService.openAlert("Successfully pushed to " + tag + "!", this.enums.SUCCESS_ALERT);

      }
    }
  }

  reset() {
    this.activeModal.close();
  }
}
