<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Add Plan</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 aria-label="Close" aria-label="Close"
            (click)="reset()"></button>
    </div>

    <div class="modal-body">

        <div class="container-fluid" style="padding: 2%;">
            <div class="row">
                <div class="col">
                    <div class="row">
                        <div class="col">
                            <div class="table-responsive table-borderless m-1">
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td><strong>Name</strong></td>
                                            <td><input type="text" [(ngModel)]="loanSchemeName" name="loanSchemeName">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><strong>Interest (%)</strong></td>
                                            <td><input type="number" [(ngModel)]="loanSchemeInterest"
                                                    name="loanSchemeInterest"
                                                    (keypress)="sharedService.isNumberKey($event)"></td>
                                        </tr>
                                        <tr>
                                            <td><strong>Term (Month)</strong></td>
                                            <td><input type="number" [(ngModel)]="loanSchmemeTerm"
                                                    name="loanSchmemeTerm"
                                                    (keypress)="sharedService.isNumberKey($event)"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col d-flex justify-content-end" style="padding: 0;margin-bottom: 2%;"
                            (click)="submit()"><button class="btn btn-success" type="button">Add</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <h1 style="font-family: Aldrich, sans-serif;"></h1>
                </div>
            </div>
            <div class="row" style="margin: 0;">
                <div class="col p-0">
                    <div class="card shadow-lg">
                        <div class="card-body">
                            <h4 class="card-title">Current Plans<br></h4>
                        </div>
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="text-center">Skim</th>
                                        <th class="text-center">Interest Rate (%)</th>
                                        <th class="text-center">Term</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let scheme of loanSchemeList">
                                        <td class="text-center">{{scheme.Name}}</td>
                                        <td class="text-center">{{scheme.Interest}}</td>
                                        <td class="text-center">{{scheme.Term}} Month</td>
                                        <td class="text-center">
                                            <i class="fas fa-times" style="cursor: pointer;"
                                                (click)="openDeleteConfirmationDialog(scheme)"></i>
                                        </td>
                                    </tr>
                                    <!-- <tr>
                                        <td class="text-center">Skim F</td>
                                        <td class="text-center">6%</td>
                                        <td class="text-center">6 Months</td>
                                    </tr> -->
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</form>