<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Pay Bad Debt</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body">
        <div class="container-fluid p-0">
            <div class="mat-elevation-z8">

                <div class="row m-2">
                    <div class="col">
                        <mat-form-field>
                            <mat-label>Search Filter</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="Enter searching criteria">
                        </mat-form-field>
                    </div>
                </div>

                <table mat-table [dataSource]="dataSource" matSort>

                    <!-- Checked Column -->
                    <ng-container matColumnDef="Check">
                        <th mat-header-cell *matHeaderCellDef>
                            <input type="checkbox" class="form-check-input" name="check" [(ngModel)]="isChecked"
                                [checked]="isCheckedAll()" (change)="checkAll()" [hidden]="filterEmpty==false">
                        </th>
                        <td mat-cell *matCellDef="let bd">
                            <input type="checkbox" class="form-check-input" name="check" [(ngModel)]="bd.checked"
                                (change)="calculateTotal()">
                        </td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="Name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                        <td mat-cell *matCellDef="let bd">
                            <span class="bdName" (click)="showCustomerSimpanBD(bd.profileId,bd.name)">{{bd.name}}</span>
                        </td>
                    </ng-container>

                    <!-- ICNumber Column -->
                    <ng-container matColumnDef="ICNumber">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> IC Number </th>
                        <td mat-cell *matCellDef="let bd">
                            {{sharedService.processICNum(bd.icNumber)}} </td>
                    </ng-container>

                    <!-- Total Column -->
                    <ng-container matColumnDef="Total">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Total </th>
                        <td mat-cell *matCellDef="let bd">{{bd.total}} </td>
                    </ng-container>
                    <!-- Total Column -->
                    <ng-container matColumnDef="Amount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Amount </th>
                        <td mat-cell *matCellDef="let bd">
                            <input type="number" [(ngModel)]="bd.amount" name="amount" (keyup)="calculateTotal()">
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <mat-paginator [pageSize]="200" [pageSizeOptions]="[25,50, 100,200]" showFirstLastButtons>
                </mat-paginator>
            </div>

            <h4 class="mt-2 fw-bold text-info">Total Payment: RM {{totalAmount}}</h4>
            <hr />
            <!--Account Number-->
            <div class="row p-3 d-flex justify-content-center align-items-center">

                <div class="col-12">
                    <p>From Account</p>
                </div>
                <div class="col-12">
                    <select class="browser-default form-select" name="selectedAccount"
                        [(ngModel)]="selectedAccountId">
                        <optgroup label="Company Account">
                            <option *ngFor="let account of companyBankAccountList" [value]="account.ID"
                                [selected]="setSelectedAccount(account.ID)"
                                [disabled]="isBalanceInsufficient(account.Balance)">{{account.Name}} (RM
                                {{account.Balance}})</option>
                        </optgroup>

                        <optgroup label="Cash Account">
                            <option *ngFor="let account of cashAccountList" [value]="account.ID"
                                [selected]="setSelectedAccount(account.ID)"
                                [disabled]="isBalanceInsufficient(account.Balance)">{{account.Name}}(RM
                                {{account.Balance}})
                            </option>
                        </optgroup>

                    </select>
                </div>

            </div>

            <!--Date-->
            <mat-form-field class="full-width-formField bar">
                <input matInput class="textInputLong" [matDatepicker]="myDatepicker" name="paymentDate"
                    [(ngModel)]="paymentDate" placeholder="Payment Date" [max]="sharedService.getTodayDate()"
                    onkeydown="return false">
                <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #myDatepicker name="paymentDate"></mat-datepicker>
            </mat-form-field>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="submit()" [disabled]="disabledSubmit">Pay
            to Bad Debt</button>
        <button class="btn btn-outline-danger" type="button" (click)="reset()">Cancel</button>
    </div>
</form>