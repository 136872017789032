import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class APIAddress {

  CHANGE_PASSWORD = "changePassword";
  ALL_ROLES = "roles";
  ALL_LEADS = "leads";
  ALL_LOANS = "loans";
  ALL_TASKS = "tasks";
  ALL_COMPANIES = "companies";
  ALL_ACCOUNTS = "accounts";
  ALL_LOANSCHEMES = "loanSchemes";
  ALL_CUSTOMERS = "customers";
  ALL_CONTACTS = "contacts";
  ALL_USERS = "users";
  ALL_ADVANCES = "advances";
  ALL_COMMISSION = "commissions";
  ALL_TRANSACTIONS = "transactions";
  ALL_TRANSACTION_BALANCES = "transactionBalances";
  ALL_TRANSCACTION_TOTAL = "transactionTotals"
  ALL_EXPENSES = "expenses";
  ALL_ASSETS = "assets";
  ALL_SUBTYPES = "transactionSubtypes";
  TRANSACTION_TYPES = "transactionTypes";
  All_FORMJ = "formJs";

  ALL_STATS = "stats";

  ALL_RESERVES = "reserves";
  ALL_BADDEBTS = "badDebts"

  ALL_COMPANY_GROUPS = "companyGroups";
  ALL_PROFILES = "profiles";
  ALL_LOAN_APPROVAL_REQUEST = "loanApprovalRequests";
  ALL_SETS = "sets";
  ALL_TRANSACTION_REQUEST = "transactionsRequests";
  ALL_BLACKLIST = "blacklists";


  ALL_SHARED_LOAN_APPROVAL_AGREEMENT = "sharedLoanApprovalRequestAgreements";

  SHARED_LOAN_APPROVAL_REQUEST = "sharedLoanApprovalRequest";

  SHARED_PROFILE = "sharedProfile";
  RESERVE_GROUP = "reserveGroup";


  ALL_FILES = "files";

  REFRESH_TOKEN = "refresh";
  SERVER = "server";

  WEBAPP_RELEASES = "server/website/releases";
  SIGNED_IN_USERS = "signedInUsers";

  LOAN_APPROVAL_REQUEST = "loanApprovalRequest";

  ALL_TRANASACTION_BALANCES_DETAILS = "transactionBalances/details";
  MERGE_PROFILES = "mergeProfiles";

  SIGN_IN = "signin";
  SIGN_UP = "signup";

  CHCECK_CUSTOMER_CODE = "customerCode";

  TRANSACTION = "transaction";
  COMPANY = "company";
  LOAN = "loan";
  TASK = "task";
  LOANSCHEME = "loanScheme";
  USER = "user";
  CONTACT = "contact";
  CUSTOMER = "customer";
  ACCOUNT = "account";
  INCOME = "income";
  EXPENSE = "expense";
  STAT_LOANS = "stats/loans";
  PROFILE = "profile";
  LEAD = "lead";
  TRANSACTION_TYPE = "transactionType";
  EXPENSES = "expenses";
  EXPENSES_EXTRAS = "extras/expenses";
  MAINTENANCE_ON = "server/maintenance/on";
  MAINTENANCE_OFF = "server/maintenance/off";
  SET = "set";
  BLACKLIST = "blacklist";

  REALLOCATION = "accounts/reallocate";
  ADVANCE = "advance";
  ARREAR = "arrear";
  COMMISSION = "commission";
  SAVING = "saving";
  CAPITAL = "capital";
  TOTING = "toting";
  STAMP = "stamp";
  SERVICE_CHARGE = "serviceCharge";
  COMPANY_GROUP = "companyGroup";
  TRANSACTION_BALANCE = "transactionBalance";
  INCOME_EXTRAS = "extras/income"
  EXPENSE_EXTRAS = "extras/expense";
  HANDLING_CHARGE = "handlingCharge";



  USER_CUSTOMERS = "customers?handler=";
  USER_TASK = "tasks?username="
  USER_TRANSACTIONS = "transactions?handledBy=";
  ACCOUNT_TRANSACTIONS = "transactions?accountId=";
  COMPANY_USERS = "users?companyId=";
  LOAN_STATS = "stats/loan";
  LOAN_TRANSACTIONS = "transactions?loanId=";
  CUSTOMER_DETAILS = "customer/";
  SUBTYPE = "transactionSubtype";
  FORMJ = "formJ";
  TRANSACTIONS_REQUEST = "transactionsRequest";
  LOAN_GROUP = "loanGroup";


  ADD_APPROVEDASSET = "transactions/approvedAsset";
  ADD_LOAN = "loan";
  ADD_ASSET = "asset";
  ADD_CUSTOMER = "customer";
  ADD_ACCOUNT = "account";
  ADD_COMPANY = "company";



  UPDATE_COMPANY = "company/";
  UPDATE_CUSTOMER = "customer/";

  GLOBAL_SEARCH = "customers/search?";

  CP_SEARCH = "cp/search";
  CP_CUSTOMER = "cp/customer";
  CP_COMPANY = "cp/company"


  SETTLED_LOAN_SETTLEMENT_INFOS = "settledLoansSettlementInfos";

  SETTLED_LOAN_SETTLEMENT_INFO = "settledLoansSettlementInfo";

}

