import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { AlertDialogComponent } from 'src/app/Shared/alert-dialog/alert-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Account } from 'src/model/account.model';

@Component({
  selector: 'app-add-bad-debt-withdrawal',
  templateUrl: './add-bad-debt-withdrawal.component.html',
  styleUrls: ['./add-bad-debt-withdrawal.component.css']
})
export class AddBadDebtWithdrawalComponent implements OnInit {
  companyBankAccountList: Account[] = new Array;
  cashAccountList: Account[] = new Array;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  selectedAccountId: number;
  selectedAccountBalance: number;

  @Input() profileId: number;
  @Input() maxAmount: number;
  //2-WAY BINDING INFO
  withdrawalDate: Date;
  remark: string;
  amount: number = 0;
  disabledSubmit = false;

  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private restapi: RestApiService, private constructApi: ConstructAPI, 
    public sharedService: SharedService,private enums:Enums) { }

  ngOnInit(): void {

    this.withdrawalDate = new Date();

    var loggedInCompany = Number(sessionStorage.getItem('companyId'));
    this.getAllAccountsOfCompany(loggedInCompany);
  }



  setSelectedAccount(accountId: number) {

    if (accountId === this.selectedAccountId)
      return true
    else
      return false;

  }
  isFieldNull() {
    var paymentNull = (this.amount == undefined || this.amount == 0);
    var remarkNull = (this.remark == undefined || this.remark == "");
    var selectedAccountIdNull = (this.selectedAccountId == undefined);
    var paymentValid = isNaN(+this.amount)

    return paymentNull || remarkNull || selectedAccountIdNull || paymentValid
  }


  private getAllAccountsOfCompany(companyId: number) {

    var xhttp = this.restapi.getRequest(this.constructApi.getAccountsOfCompany(companyId));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {

          var amount = (json.balance == undefined) ? 0 : json.balance;
          let account = new Account(json.id, json.number, json.name, json.holderName, amount, json.companyId, json.username);

          if (account.Username != undefined)
            this.cashAccountList.push(account)
          else {
            this.companyBankAccountList.push(account);
          }

        }
        );

      }
    };
  }

  withdrawSimpanBD() {
    this.disabledSubmit = true;
    var isoTaskDate = (new Date(Number(this.withdrawalDate))).toISOString();

    var data = {
      "amount": this.amount,
      "accountId": Number(this.selectedAccountId),
      "date": isoTaskDate,
      "remark": "Simpan BD Withdrawal - " + this.remark
    };


    var xhr = this.restapi.postRequest(this.constructApi.getWithdrawBadDebtsToSimpan(this.profileId), data);
    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        if (xhr.status == 200) {
          this.disabledSubmit = false;

          this.passEntry.emit()
          this.reset();
          this.sharedService.openAlert("Successfully withdraw simpan BD!",this.enums.SUCCESS_ALERT);
        }
        else {
          this.disabledSubmit = false;
          this.sharedService.openErrorMessage(xhr);
        }
      }

    }
  }

  isBalanceInsufficient(balance: number) {

    // this.sharedService.openAlert(balance);
    if (balance > this.amount)
      return false;
    else
      return true;
  }


  confirmBeforePayBD() {
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "You are withdrawing simpan BD of RM " + this.amount + " to simpan, are you sure?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something

      if (confirmation == true) {
        this.withdrawSimpanBD();
      }
    });
  }

  submit() {
    if (this.isFieldNull()) {
      this.sharedService.openAlert('Please fill in all the fields',this.enums.DANGER_ALERT)
    }
    else if (this.amount > this.maxAmount) {
      this.sharedService.openAlert('You can only withdraw maximum of RM ' + this.maxAmount + "!",this.enums.DANGER_ALERT);
    }
    else {
      this.confirmBeforePayBD();
    }
  }

  reset() {
    this.activeModal.close();
  }

}
