import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Set } from 'src/model/set.model';
import { ReceiptDetailsComponent } from '../receipt-details/receipt-details.component';
import { TransactionSubType } from 'src/model/transactionSubType.model';

@Component({
  selector: 'app-receipt-sets-listview',
  templateUrl: './receipt-sets-listview.component.html',
  styleUrls: ['./receipt-sets-listview.component.css']
})
export class ReceiptSetsListviewComponent implements OnInit, OnChanges {

  constructor(private router: Router, private modalService: NgbModal, private cdr: ChangeDetectorRef, public enums: Enums, private restapi: RestApiService,
    private constructApi: ConstructAPI, public sharedService: SharedService) {




  }
  displayedColumns: string[] = new Array;
  dataSource = new MatTableDataSource;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input() setList: Set[];
  @Input() expenseSubtypeList: TransactionSubType[];
  @Input() dlSubtypeList: TransactionSubType[];

  ngOnInit(): void {
    // console.log(this.setList)
    this.displayedColumns = ['CompanyCode', 'ID', 'Name', 'Remark', 'SetRemark', 'Status', 'Total', 'Transactions'];
    this.dataSource.data = this.setList;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dataSource.data = this.setList
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;


  }


  calculateTotal(set: Set) {
    var sum = 0;
    for (let i = 0; i < set.TransactionList.length; i++) {
      sum += set.TransactionList[i].Amount;
    }
    return sum;
  }

  openSetDetails(set: Set) {
    var modalRef = this.sharedService.openModal(ReceiptDetailsComponent, "regularModal");
    modalRef.componentInstance.receipt = set;
    modalRef.componentInstance.isModal = true;
    modalRef.componentInstance.expenseSubtypeList = this.expenseSubtypeList;
    modalRef.componentInstance.dlSubtypeList = this.dlSubtypeList;
  }


  dataSourceSort() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'Status':
          // if status is empty
          if (item[property] == undefined)
            return ""
          //if contains status code
          else
            return item[property][0].StatusCode;
      }
    };
  }

}
