import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Account } from 'src/model/account.model';
import { Advance } from 'src/model/advance.modal';
import { User } from 'src/model/user.model';

@Component({
  selector: 'app-pay-advance',
  templateUrl: './pay-advance.component.html',
  styleUrls: ['./pay-advance.component.css']
})
export class PayAdvanceComponent implements OnInit {
  companyAccountList: Account[] = new Array;
  @Input() advanceObj: Advance;
  @Input() toCreateNew = false;

  selectedAccountId: number;
  loggedInCompanyId: number;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  //2-WAY BINDING INFO
  date: Date;
  remark: string;
  amount: number = 0;
  userList: User[] = new Array;

  newAmount = 0;

  disabledSubmit = false;
  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private restapi: RestApiService,
    private constructApi: ConstructAPI, private enums: Enums, public sharedService: SharedService) { }

  ngOnInit(): void {

    this.date = new Date();
    this.loggedInCompanyId = Number(sessionStorage.getItem("companyId"));

    this.getAllAccountsOfCompany(this.loggedInCompanyId);
  }

  private getAllAccountsOfCompany(companyId: number) {

    var xhttp = this.restapi.getRequest(this.constructApi.getAccountsOfCompany(companyId));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let account = new Account(json.id, json.number, json.name, json.holderName, json.balance, json.companyId, json.username);

          var loggedInUsername = sessionStorage.getItem("username");
          if (json.username == loggedInUsername || json.username == undefined)
            this.companyAccountList.push(account);

        }
        );

      }
    };

  }

  setSelectedAccount(accountId: number) {

    if (accountId === this.selectedAccountId)
      return true
    else
      return false;
  }

  isFieldNull() {
    var paymentNull = (this.amount == undefined || this.amount == 0);
    var remarkNull = (this.remark == undefined || this.remark == "");
    var selectedAccountIdNull = (this.selectedAccountId == undefined);
    var paymentValid = isNaN(+this.amount)


    return paymentNull || remarkNull || selectedAccountIdNull || paymentValid;
  }


  calculateNewAmount(amount: number) {
    this.newAmount = 0;

    if (this.toCreateNew == true) {
      if (this.advanceObj.Amount >= 0)
        this.newAmount = this.advanceObj.Amount + amount;
      else
        this.newAmount = this.advanceObj.Amount - amount;
    }
    else {
      if (this.advanceObj.Amount >= 0)
        this.newAmount = this.advanceObj.Amount - amount;
      else
        this.newAmount = this.advanceObj.Amount + amount;
    }


    return this.newAmount;
  }



  addAdvancePayment() {
    this.disabledSubmit = true;


    var addADayDate = new Date(Number(new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate())) + (3600 * 1000 * 24) - 2);
    var isoTaskDate = (this.sharedService.checkIfDateIsToday(new Date(this.date))) ? this.date.toISOString() : addADayDate.toISOString();

    // var isoTaskDate = (new Date(Number(this.date))).toISOString();

    // if (this.toCreateNew == true)
    //   var submitAmount = -this.advanceObj.Amount;
    // else
    //   var submitAmount = (this.advanceObj.Amount > 0) ? -this.amount : this.amount;
    var data = {
      "amount": this.amount,
      "accountId": Number(this.selectedAccountId),
      "dateCreated": isoTaskDate,
      "remark": this.remark + " (" + this.advanceObj.Remark + " - " + this.advanceObj.Username + ")"
    };


    var xhr;

    //if is out money =advance
    if (this.advanceObj.Amount < 0) {

      //BORROW more
      if (this.toCreateNew == true)
        xhr = this.restapi.postRequest(this.constructApi.getAddAdvanceBorrowMore(this.advanceObj.ID), data);
      else
        xhr = this.restapi.postRequest(this.constructApi.getAddAdvanceReturn(this.advanceObj.ID), data);

    } else {
      //if its is IN money= deposit
      //DEPOSIT more
      if (this.toCreateNew == true)
        xhr = this.restapi.postRequest(this.constructApi.getAddAdvanceDepositMore(this.advanceObj.ID), data);
      else
        xhr = this.restapi.postRequest(this.constructApi.getAddAdvanceWithdraw(this.advanceObj.ID), data);
    }

    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        if (xhr.status == 200) {
          window.location.reload();
          // this.sharedService.openAlert("Successfully paid advance!", this.enums.SUCCESS_ALERT);


        } else
          this.sharedService.openErrorMessage(xhr);

      }

    }
  }


  // addNewAdvance() {
  //   var isoTaskDate = (new Date(Number(this.date))).toISOString();
  //   // var newAmount = (this.advanceObj.Amount >= 0) ? this.advanceObj.Amount + this.amount : this.advanceObj.Amount - this.amount;
  //   var data = {
  //     "amount": -this.newAmount,
  //     "accountId": Number(this.selectedAccountId),
  //     "dateCreated": isoTaskDate,
  //     "remark": this.advanceObj.Remark,
  //     "username": this.advanceObj.Username
  //   };


  //   var xhr = this.restapi.postRequest(this.constructApi.getAddAdvance(), data);
  //   xhr.onreadystatechange = () => {
  //     if (xhr.readyState == 4) {
  //       if (xhr.status == 200) {
  //         this.disabledSubmit = false;

  //         let advance = new Advance(null, this.advanceObj.Username, this.date, -this.amount, this.remark)
  //         this.passEntry.emit(advance);
  //         this.reset();
  //         this.sharedService.openAlert("Successfully added advance!", this.enums.SUCCESS_ALERT);

  //       }
  //       else {
  //         this.disabledSubmit = false;
  //         this.sharedService.openAlert("Opps,some error occured. Please try again later", this.enums.DANGER_ALERT);
  //       }
  //     }

  //   }
  // }

  isBalanceInsufficient(balance: number) {

    // alert(this.advanceObj.Amount)
    //if is advance more
    if (this.advanceObj.Amount < 0) {
      if (this.toCreateNew == true) {
        if (balance >= this.amount)
          return false;
        else
          return true;
      }

    }
    //if is out simpan
    else {
      if (this.toCreateNew == false) {
        if (balance >= this.amount)
          return false;
        else
          return true;
      }
    }
  }

  checkAmountInvalid() {
    if (Math.abs(this.advanceObj.Amount) > 0 && this.toCreateNew == false && this.amount > Math.abs(this.advanceObj.Amount))
      return true
    else if (Math.abs(this.advanceObj.Amount) < 0 && this.toCreateNew == false && this.amount > Math.abs(this.advanceObj.Amount))
      return true
    else
      return false
  }


  submit() {
    if (this.isFieldNull())
      this.sharedService.openAlert('Please fill in all the fields', this.enums.DANGER_ALERT);
    else if (this.checkAmountInvalid())
      this.sharedService.openAlert("You can only enter lesser or exact amount of advance. Please do not overpay", this.enums.DANGER_ALERT);
    else this.addAdvancePayment();
  }

  reset() {
    this.activeModal.close();
  }



}
