<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Web App Version List</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body">
        <div class="container-fluid p-0">

            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Tag</th>
                        <th>Published At</th>
                        <th>Body</th>
                        <th>Asset</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let release of releaseList">
                        <td>{{release.tagName}}</td>
                        <td>{{release.publishedAt |date:'dd-MM-yyyy'}}</td>
                        <td>{{release.body}}</td>
                        <td>
                            <ng-container *ngFor="let asset of release.assets">
                                <button class="btn btn-dark m-1" (click)="pushThisRelease(release.tagName,asset.name)">{{asset.name}}</button>
                            </ng-container>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</form>