import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Customer } from 'src/model/customer.model';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Task } from 'src/model/task.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RestApiService } from 'src/app/API/restapi';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { Enums } from 'src/app/Shared/enums';
import { AlertDialogComponent } from 'src/app/Shared/alert-dialog/alert-dialog.component';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Profile } from 'src/model/profile.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-create-new-task',
  templateUrl: './create-new-task.component.html',
  styleUrls: ['./create-new-task.component.css'],
  providers: [DatePipe]
})
export class CreateNewTaskComponent implements OnInit {

  profileList: Profile[] = new Array;
  filteredProfiles: Observable<Profile[]>;
  loggedInUserId: string;

  customerCtrl = new UntypedFormControl();

  //get selected customer ID
  selectedProfileID: number;
  selectedCustomerName: string;
  selectedTaskType: number = 0;

  //if create task directly from customer profile, set to true
  isSpecificCustomer: boolean = false;


  //for datepicker
  minDate: Date;
  maxDate: Date;

  taskDate: Date;
  taskDescription: string;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  createdTask: Task;


  constructor(private modalService: NgbModal, public activeModal: NgbActiveModal, private restapi: RestApiService,
    private constructApi: ConstructAPI, public enums: Enums, private sharedService: SharedService, private datepipe: DatePipe) {

    this.getAllProfiles();
    this.filteredProfiles = this.customerCtrl.valueChanges.pipe(
      startWith(''),
      map(customer => customer ? this._filterProfile(customer) : this.profileList.slice())
    );

    this.minDate = new Date();
    var lastDayOfNextMonth = new Date(this.minDate.getFullYear(), this.minDate.getMonth() + 2, 0)
    this.maxDate = new Date(this.minDate.getFullYear(), this.minDate.getMonth() + 1, lastDayOfNextMonth.getDate());

    this.taskDate = new Date();
  }

  ngOnInit(): void {

    // change to get loggedInUserId later


  }


  setSelectedTaskType(type: number) {
    this.selectedTaskType = type;
  }

  getAllProfiles() {
    var xhttp = this.restapi.getRequest(this.constructApi.getAllCustomersProfiles());

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // Typical action to be performed when the document is ready:

        var jsonArray = JSON.parse((xhttp.responseText));
        // console.log(jsonArray);

        jsonArray.forEach(json => {

          // create customer instance with all response data
          let profile = new Profile(json.id, json.occupation, json.salary, json.salaryDay, json.remark, undefined, json.dateCreated, json.id, json.customerCode, undefined, undefined, json.name)
          //add customer instance into customer list
          this.profileList.push(profile);
        }
        );
      }
    };
    // xhttp.open("GET", url, true);
    // xhttp.send();

  }

  private _filterProfile(value: string): Profile[] {
    const filterValue = value.toLowerCase();

    var filtered = this.profileList.filter(profile => { return profile.CustomerName.toLowerCase().indexOf(filterValue) === 0 || profile.Occupation.toLowerCase().indexOf(filterValue) === 0 });
    return filtered;
  }

  setSelectedProfile(ID: number, name: string) {

    this.selectedProfileID = ID;
    this.selectedCustomerName = name;

  }




  createTask() {
    //deduct timezone offset from the task date then only convert to ISO STRING
    // var isoTaskDate = (new Date(Number(this.taskDate) - tzoffset)).toISOString();


    var data = {
      "profileId": this.selectedProfileID, "assignee": this.loggedInUserId, "description": this.taskDescription,
      "date": this.datepipe.transform(this.taskDate, 'yyyy-MM-dd'),
      "type": Number(this.selectedTaskType)
    };

    //post data 
    var xhr = this.restapi.postRequest(this.constructApi.getAddTask(), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {


        var json = JSON.parse(xhr.responseText);
        // console.log(json.ID + ", " + json.CustomerId + "," + json.Username+ "," + json.Description+ "," + json.Date+ "," + json.Status)
        // console.log(json)
        this.createdTask = new Task(json.id, json.customerId, json.description, json.followUpRemark, json.date,
          this.selectedCustomerName, json.assignee, json.dateDone);
        // console.log(this.createdTask)


        this.taskSuccess();

        this.sharedService.openAlert("Successfully created task!", this.enums.SUCCESS_ALERT);


      }
      else if (xhr.readyState == 4 && xhr.status != 200) {
        this.sharedService.openAlert("Oops! Some error occurs", this.enums.DANGER_ALERT)

      }
    }


    // xhr.send(data);
  }



  taskSuccess() {

    this.passEntry.emit(this.createdTask);
    this.activeModal.close(this.createdTask);

    this.selectedProfileID = 0;
    this.taskDescription = "";
    this.taskDate = new Date();
  }

  reset() {

    this.activeModal.close();
    this.selectedProfileID = 0;
    this.taskDescription = "";
    this.taskDate = new Date();
  }


}
