import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Loan } from 'src/model/loan.model';
import { Transaction } from 'src/model/transaction.model';
import { RestApiService } from 'src/app/API/restapi';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { Receipt } from 'src/model/loanReceipt.model';
import { DatePipe } from '@angular/common';
import { TransactionType } from 'src/model/transactionType.model';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { DeleteReasonRemarkComponent } from 'src/app/Account/delete-reason-remark/delete-reason-remark.component';

@Component({
  selector: 'app-loan-details',
  templateUrl: './loan-details.component.html',
  styleUrls: ['./loan-details.component.css'],
  providers: [DatePipe]
})
export class LoanDetailsComponent implements OnInit {

  isDirty: boolean = false;

  @Input() selectedLoan: Loan;
  receiptList: Receipt[] = new Array;
  transactionTypeList: TransactionType[] = new Array;

  @Input() isDeletable = true;

  constructor(private activeModal: NgbActiveModal, private restApi: RestApiService, private constructApi: ConstructAPI, public enums: Enums,
    public sharedService: SharedService, private cdr: ChangeDetectorRef) { }


  ngOnInit(): void {
    this.getAllTransactionsOfLoan()
  }


  confirmDeleteTranscation(transaction: Transaction) {
    var timeDiff = new Date().getTime() - new Date(transaction.Date).getTime();
    var dateDiff = timeDiff / (1000 * 3600 * 24);
    if (dateDiff < 90 || this.sharedService.isBossAccount() == true) {
      const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
      modalRef.componentInstance.message = "Deleting loan transaction might cause deleting receipt, loan and other related entity. Are you sure to do so?";
      //get confirmation
      modalRef.componentInstance.passEntry.subscribe((entry) => {
        var confirmation = entry;
        if (confirmation == true) {


          const modalRef = this.sharedService.openModal(DeleteReasonRemarkComponent);
          modalRef.componentInstance.transaction = transaction;
          modalRef.componentInstance.passEntry.subscribe((entry) => {
            var remark = entry;

            //append added account to account list
            this.deleteTransaction(remark, transaction.ID);
          });

        }

      });
    } else
      this.sharedService.openAlert("This transaction is too old, please contact your admin to delete", this.enums.DANGER_ALERT);


  }

  deleteTransaction(remark: string, transactionID: number) {
    var data =
    {
      "reason": remark
    };


    var xhr = this.restApi.deleteRequest(this.constructApi.getDeleteLoanTransaction(transactionID), data);
    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {

        if (xhr.status == 200) {
          this.removeTransctionFromList(transactionID);
          this.isDirty = true;
        }
        else {
          // this.sharedService.openAlert("Opps, some errors occured. Please try again later");
          this.sharedService.openErrorMessage(xhr);
        }


      }

    }

  }


  removeTransctionFromList(transactionId: number) {

    for (let i = 0; i < this.receiptList.length; i++) {
      for (let j = 0; j < this.receiptList[i].Transactions.length; j++) {
        if (Number(this.receiptList[i].Transactions[j].ID) == transactionId) {
          this.receiptList[i].Transactions.splice(j, 1);
          this.cdr.detectChanges();

          if (this.receiptList.length == 1 && this.receiptList[0].Transactions.length == 0)
            window.location.reload();
        }
      }
    }
    return -1
  }

  getAllTransactionsOfLoan() {

    var xhttp = this.restApi.getRequest(this.constructApi.getLoanReceipts(this.selectedLoan.ID));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var receiptArray = JSON.parse((xhttp.responseText));

        var loanTransactionList: Transaction[] = new Array;
        receiptArray.forEach(receipt => {

          var loanTransactionList: Transaction[] = new Array;
          var transactionArray = JSON.parse(JSON.stringify(receipt.transactions));

          transactionArray.forEach(transaction => {
            var record = new Transaction(transaction.id, transaction.remark, transaction.amount,
              transaction.closingBalance, transaction.accountId, transaction.date, transaction.type, transaction.handleBy, undefined, undefined, undefined, undefined, undefined, transaction.subtype)

            loanTransactionList.push(record);
          });

          var receiptObj = new Receipt(receipt.id, receipt.number, loanTransactionList);
          this.receiptList.push(receiptObj);
        }
        );
      }
    };

  }

  // calculateTransactionAmount() {
  //   var sum = 0;

  //   for (let i = 0; i < this.receiptList.length; i++) {
  //     for (let j = 0; j < this.receiptList[i].Transactions.length; j++) {
  //       sum += this.receiptList[i].Transactions[j].Amount;
  //     }
  //   }
  //   return sum;
  // }

  checkAmountInOut(amount: number) {
    if (amount < 0)
      return 'negative';
    else
      return 'positive';
  }

  reset() {

    if (this.isDirty == true) {
      window.location.reload();
    }
    else
      this.activeModal.close();

  }
}
