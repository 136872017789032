<form>

  <!-- Modal Header -->
  <div class="modal-header">
    <h4 class="modal-title">Add Customer</h4>
    <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()" aria-label="Close"></button>
  </div>

  <div class="modal-body">
    <div class="container-fluid p-0">

      <div class="mt-2">
        <div class="card">
          <div class="card-header">
            <h5 class="mb-0">Core Information</h5>
          </div>
          <div class="card-body">

            <div class="row">
              <div class="col-12" *ngIf="sharedService.isBossAccount()">
                <div class="card p-2" style="background-color:lightgoldenrodyellow ;">
                  <mat-slide-toggle class="mat-slide-toggle-lg" [hideIcon]="true" color="primary" [(ngModel)]="isCPCustomer"
                    name="isCPCustomer ">
                    CP Settled Customer
                  </mat-slide-toggle>
                </div>
              </div>

              <div class="col-12 pt-3" *ngIf="isCPCustomer==true">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Company</mat-label>
                  <input class="companyInput" type="text" placeholder="Pick one" aria-label="Company" matInput
                    [formControl]="myControl" [matAutocomplete]="auto" [(ngModel)]="selectedCompanyCode">
                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option *ngFor="let company of filteredCompany |async" [value]="company.CompanyCode"
                      (onSelectionChange)="setSelectedCompany(company.CompanyCode)">
                      {{company.CompanyCode}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>

              </div>
              <div class="col-12" *ngIf="isCPCustomer==true">
                <select class="browser-default form-select" name="selectedUsername" [(ngModel)]="selectedUsername">
                  <option *ngFor="let user of userList" [value]="user.Username">
                    {{user.Username}}
                  </option>
                </select>
              </div>

              <div class="col col-12">
                <div><span>Name*</span></div>
                <div><input type="text" [(ngModel)]="customerName" name="customerName"
                    (focusout)="validField('customerName')"></div>
              </div>
              <div class="col-12 col-md-6">
                <div><span>IC Number (without '-')*</span></div>
                <div><input type="text" [(ngModel)]="icNumber" name="icNumber" required
                    (focusout)="validField('icNumber')" (keypress)="sharedService.isNumberKey($event)">
                </div>
                <span class="text-danger" *ngIf="sharedService.checkIfICValid(icNumber)==false">IC number format
                  incorrect</span>
              </div>
              <div class="col-12 col-md-6">
                <div><span>Customer Code*</span></div>
                <div><input type="text" [(ngModel)]="customerCode" name="customerCode" required>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <mat-form-field class="full-width-formField bar">
                  <input matInput class="textInputLong" [matDatepicker]="myDatepicker" name="dob" [(ngModel)]="dob"
                    placeholder="Date of Birth" onkeydown="return false" style="border:0px"
                    [max]="sharedService.getTodayDate()">
                  <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                  <mat-datepicker #myDatepicker name="dob"></mat-datepicker>
                </mat-form-field>

                <span class="text-danger"
                  *ngIf="dob==undefined || sharedService.checkInvalidCustomerAge(dob)==true">Please make sure
                  customer age is
                  valid (> {{enums.VALID_CUSTOMER_AGE}} years old)</span>
              </div>

              <div class="col-12 col-md-6">
                <div><span>Other ID (Old IC, Passport etc)</span></div>
                <div><input type="text" [(ngModel)]="otherId" name="otherId" required>
                </div>
              </div>
            </div>


            <div class="row" *ngIf="isCPCustomer==false">
              <div class="col col-12 col-md-6">
                <div><span>Phone number*</span></div>
                <div><input type="text" required [(ngModel)]="contactNo" name="contactNo"
                    (focusout)="validField('contactNo')" (keypress)="sharedService.isNumberKey($event)"></div>
              </div>
              <div class="col col-12 col-md-6">
                <div><span>Company Name*</span></div>
                <div><input type="text" required [(ngModel)]="custOccupation" name="custOccupation"
                    (focusout)="validField('custOccupation')">
                </div>
              </div>
            </div>
            <div class="row" *ngIf="isCPCustomer==false">
              <div class="col col-12 col-md-6">
                <div><span>Salary*</span></div>
                <div><input type="number" required [(ngModel)]="salaryAmount" name="salaryAmount"
                    (focusout)="validField('salaryAmount')" min=1000 (keypress)="sharedService.isNumberKey($event)">
                </div>
              </div>
              <div class="col col-12 col-md-6">
                <div><span>Salary Day*</span></div>
                <div><input type="number" required [(ngModel)]="salaryDay" name="salaryDay" min=1 max=31
                    (keypress)="sharedService.isNumberKey($event)"></div>
                <span class="text-danger" *ngIf="checkIfSalaryDateInvalid()==true">Between (1-31). If more than 1, fill
                  another at Description</span>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div><span>Gender</span></div>
                <select class="browser-default form-select" name="gender" [(ngModel)]="gender">
                  <option *ngFor="let gender of enums.genderOptions" [value]="gender">{{gender}}</option>
                </select>
              </div>
              <div class="col-12">
                <div><span>Race</span></div>
                <select class="browser-default form-select" name="race" [(ngModel)]="race">
                  <option *ngFor="let race of enums.raceOptions" [value]="race">{{race}}</option>
                </select>
              </div>
              <div class="col-12">
                <div><span>Description</span></div>
                <div><textarea class="form-control-lg" required [(ngModel)]="remark" name="remark"
                    (focusout)="validField('remark')"></textarea></div>
              </div>
              <div class="col-12" *ngIf="isCPCustomer==false">
                <div><span>Address*</span></div>
                <div><textarea class="form-control-lg " required [(ngModel)]="address" name="address"
                    (focusout)="validField('address')"></textarea></div>
              </div>

              <div class="col-12" *ngIf="isCPCustomer==false">
                <div><span>Address 2</span></div>
                <div><textarea class="form-control-lg " required [(ngModel)]="address2" name="address2"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="mt-3"> -->
      <!-- <div class="card">
          <div class="card-header">
            <h5 class="mb-0">Loan Information</h5>
          </div>
          <div class="card-body">
            <div class="mt-2">
              <div class="row">
                <div class="col col-12  col-lg-6">
                  <div><span>Loan Amount</span></div>
                  <div><input type="text"></div>
                </div>
                <div class="col col-12  col-lg-6">
                  <div><span>Loan Date</span><i class="far fa-calendar-alt ms-2"></i></div>
                  <div><input class="dateInput" type="date" [value]="getToday()"></div>
                </div>
              </div>
              <div class="row">
                <div class="col col-12  col-lg-6">
                  <div><span>Skim</span></div>
                  <div>
                    <div class="dropdown">
                      <button class="btn btn-outline-dark dropdown-toggle ms-0"  data-bs-toggle="dropdown"
                        aria-expanded="false" type="button">Select a skim</button>
                      <div class="dropdown-menu  dropdown-menu-end" role="menu"><a class="dropdown-item"
                          role="presentation" href="#">First
                          Item</a><a class="dropdown-item" role="presentation" href="#">Second Item</a><a
                          class="dropdown-item" role="presentation" href="#">Third Item</a></div>
                    </div>
                  </div>
                </div>
                <div class="col col-12  col-lg-6">
                  <ng-template #tooltipContent>
                    <div>
                      <span>Loan Term and interest rate will varies based on the Skim selected</span>
                    </div>

                  </ng-template>
                  <div><span>Loan Term</span><i class="far fa-question-circle ms-2" placement="top"
                      [ngbTooltip]="tooltipContent" style="cursor:pointer;"></i></div>
                  <div><input type="number" placeholder="Year" value="1600.00" disabled></div>
                </div>

              </div>
              <div class="row">
                <div class="col col-12  col-lg-6">
                  <div><span>Interest Rate</span><i class="far fa-question-circle ms-2" placement="top"
                      [ngbTooltip]="tooltipContent" style="cursor:pointer;"></i></div>
                  <div><input type="number" placeholder="%" max="100" disabled></div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      <!-- </div> -->
    </div>

  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-success" type="button" (click)="submit()" [disabled]="disabledSubmit">Add
      Customer</button>
    <button class="btn btn-outline-danger" type="button" data-dismiss="modal" (click)="reset()">Cancel</button>
  </div>
</form>