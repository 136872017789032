import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { ShowWhatsappMessageClipboardComponent } from 'src/app/Shared/show-whatsapp-message-clipboard/show-whatsapp-message-clipboard.component';
import { Contact } from 'src/model/contact.model';
import { DuitLebih } from 'src/model/duitLebih.model';
import { Loan } from 'src/model/loan.model';
import { LoanRequest } from 'src/model/loanRequest.model';

@Component({
  selector: 'app-edit-loan-request-info',
  templateUrl: './edit-loan-request-info.component.html',
  styleUrls: ['./edit-loan-request-info.component.css'],
  providers: [DatePipe]
})
export class EditLoanRequestInfoComponent implements OnInit {

  @Input() request: LoanRequest;


  disabledSubmit = false

  @Input() settlementAmount: number;
  constructor(private activeModal: NgbActiveModal, public sharedService: SharedService, private enums: Enums, private datepipe: DatePipe, private restApi: RestApiService, private constructApi: ConstructAPI) { }

  ngOnInit(): void {
  }


  calculateInterestRate() {
    this.request.LoanInfo.InterestRate = ((((this.settlementAmount - this.request.LoanInfo.Principal) / this.request.LoanInfo.Principal) / this.request.LoanInfo.Term)) * 100;
  }

  calculateTotalSettlementRatio() {
    var settlement = this.calculateEPFSettlementIncludeCharges();
    return (settlement / this.request.EpfAmount) * 100;
  }
  calculateEPFSettlementIncludeCharges() {
    return this.settlementAmount + this.request.LoanInfo.SettlementExtra + this.request.LoanInfo.SettlementServiceCharge + this.request.LoanInfo.SettlementStamp;
  }

  getDuitLebihData(duitLebihList: DuitLebih[]) {
    this.request.LoanInfo.Extras = duitLebihList;
  }

  calculateTotalOnHand() {
    var total = 0;
    if (this.request.LoanInfo != undefined) {
      var duitLebihTotal = 0;
      if (this.request.LoanInfo.Extras.length > 0) {
        for (let j = 0; j < this.request.LoanInfo.Extras.length; j++)
          duitLebihTotal += this.request.LoanInfo.Extras[j].Amount;
      }
      var processingCharge = this.request.LoanInfo.Principal * (this.request.LoanInfo.ProcessingRate / 100)

      total += this.request.LoanInfo.Principal + (this.request.LoanInfo.Reserve || 0) - duitLebihTotal - processingCharge - (this.request.LoanInfo.StampAmount || 0) -
        (this.request.LoanInfo.ServiceCharge || 0)
    }

    return total;
  }
  calculateTotalDuitLebih() {
    var total = 0;
    if (this.request.LoanInfo.Extras.length > 0) {
      for (let i = 0; i < this.request.LoanInfo.Extras.length; i++) {
        total += this.request.LoanInfo.Extras[i].Amount;
      }
    }

    return total;
  }


  checkValidity() {
    var error = "";

    if (this.request.LoanInfo.Principal == undefined || this.request.LoanInfo.Principal == 0)
      error += "- Principal";
    if (this.request.LoanInfo.SettlementStamp == undefined || this.request.LoanInfo.SettlementStamp == 0)
      error += "- Settlement Stamp";
    if (this.settlementAmount == undefined || this.settlementAmount == 0)
      error += "- Settlement Amount";
    if (this.request.LoanInfo.InterestRate == undefined || this.request.LoanInfo.InterestRate < 0.05)
      error += "- Interest Rate < 5%";

    if (this.request.LoanInfo.SettlementExtra == undefined)
      error += "- Settlement DL";

    if (this.request.LoanInfo.SettlementServiceCharge == undefined)
      error += "- Settlement SC";

    if (this.request.LoanInfo.StampAmount == undefined)
      error += "-Stamp";

    if (this.request.LoanInfo.ServiceCharge == undefined)
      error += "-Service Charge";



    if (error != "")
      error = "Please key in correct information: \n" + error;
    return error
  }
  submit() {
    var error = this.checkValidity()
    if (error != "")
      this.sharedService.openAlert(error, this.enums.DANGER_ALERT);
    else {
      this.updateLoanRequestDetails();
    }

  }


  updateLoanRequestDetails() {
    var settlementInfo = {
      "stamp": this.request.LoanInfo.SettlementStamp,
      "serviceCharge": this.request.LoanInfo.SettlementServiceCharge,
      "extras": this.request.LoanInfo.SettlementExtra
    }

    var loanInfoData = {
      "principal": this.request.LoanInfo.Principal,
      "scheme": this.request.LoanInfo.Scheme,
      "term": this.request.LoanInfo.Term,
      "interestRate": this.request.LoanInfo.InterestRate,
      "stamp": this.request.LoanInfo.StampAmount,
      "serviceCharge": this.request.LoanInfo.ServiceCharge,
      "extras": this.request.LoanInfo.Extras,
      "processingRate": this.request.LoanInfo.ProcessingRate,
      "reserve": -this.request.LoanInfo.Reserve,
      "settlementInfo": settlementInfo
    }

    var guarantorIdData: Number[] = new Array;
    for (let i = 0; i < this.request.Guarantors.length; i++) {
      guarantorIdData.push(this.request.Guarantors[i].ID);
    }

    var data = {
      "profileId": this.request.ProfileId,
      "loanInfo": loanInfoData,
      "remark": this.request.Remark,
      "requestOn": new Date().toISOString(),
      "guarantorIds": guarantorIdData
    }

    var xhr = this.restApi.putRequest(this.constructApi.getUpdateEPFLoanRequest(this.request.ID), data)
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        //if success , then proceed
        if (xhr.status == 200) {

          var json = JSON.parse(xhr.responseText);
          this.request.ID = Number(json.id);


          if (this.sharedService.isBranchManager()) {
            var modalRef = this.sharedService.openModal(ShowWhatsappMessageClipboardComponent);
            modalRef.componentInstance.request = this.request;
            modalRef.componentInstance.isDirty = true;

            this.reset();
          }
          else
            window.location.reload();

        }
        else {
          //show error message
          this.sharedService.openAlert(JSON.parse(xhr.responseText).error, this.enums.DANGER_ALERT)

        }
      }
    }
  }


  reset() {
    this.activeModal.close();
  }

}
