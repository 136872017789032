<form>
    <!-- Modal Header -->
    <div class="modal-header" style="background-color:aquamarine;">
        <h4 class="modal-title" [hidden]="paidByDuitLebih==true">Add Income</h4>
        <h4 class="modal-title" [hidden]="paidByDuitLebih==false">Add Income of Duit Lebih</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body">

        <!--Account Number-->
        <div class="row p-3 d-flex justify-content-center align-items-center">

            <div class="col-12">
                <p>To Account</p>
            </div>
            <div class="col-12">
                <select class="browser-default form-select" name="selectedAccount" [(ngModel)]="selectedAccountId">
                    <optgroup label="Company Account">
                        <option *ngFor="let account of companyBankAccountList" [value]="account.ID"
                            [selected]="setSelectedAccount(account.ID)">{{account.Name}}</option>
                    </optgroup>

                    <optgroup label="Cash Account">
                        <option *ngFor="let account of cashAccountList" [value]="account.ID"
                            [selected]="setSelectedAccount(account.ID)">{{account.Name}}</option>
                    </optgroup>

                </select>
            </div>

        </div>


        <!--Amount-->

        <div class="card">
            <div class="card-header">Income Amount</div>
            <div class="card-body">
                <div class="group">
                    <input type="number" required class="textInputLong" name="amount" [(ngModel)]="amount" min=0
                        (keypress)="sharedService.isNumberKey($event)">
                    <span class="highlight"></span>
                    <span class="bar barLong"></span>
                    <label>Amount</label>
                </div>
            </div>
        </div>


        <!--Date-->
        <div class="col-12">
            <mat-form-field class="full-width-formField bar">
                <input matInput class="textInputLong" [matDatepicker]="myDatepicker" name="incomeDate"
                    [(ngModel)]="incomeDate" placeholder="Income Date">
                <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #myDatepicker name="incomeDate"></mat-datepicker>
            </mat-form-field>
        </div>

        <!--SubType-->
        <div class="col-12" [hidden]="paidByDuitLebih==false">
            <p>SubType</p>
        </div>
        <div class="col-12">
            <select class="browser-default form-select" name="selectedSubTypeId" [(ngModel)]="selectedSubTypeId"
                [hidden]="paidByDuitLebih==false">
                <option value="undefined"></option>
                <option *ngFor="let type of subtypeList" [value]="type.ID" [selected]="setSelectedSubType(type.ID)">
                    {{type.Name}}
                </option>
            </select>
        </div>

        <!--Remark-->
        <div class="col-12">
            <div class="group">
                <input type="text" required class="textInputLong" name="remark" [(ngModel)]="remark">
                <span class="highlight"></span>
                <span class="bar barLong"></span>
                <label> Remark</label>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="submit()" [disabled]="disabledSubmit">Add Income</button>
        <button class="btn btn-outline-danger" type="button" (click)="reset()">Cancel</button>
    </div>

</form>