import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { User } from 'src/model/user.model';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {

  @Input() amount: number;
  @Input() username: string;
  @Input() companyId: number;

  @Input() selectedRoleId: number;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();


  newPassword: string;
  confirmPassword: string;

  newRoleId: number;

  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private api: RestApiService,
    private constructApi: ConstructAPI, public sharedService: SharedService, public enums: Enums) { }

  ngOnInit(): void {
    this.newRoleId = this.selectedRoleId;
  }


  submit() {
    this.amount == undefined ? this.sharedService.openAlert('Please fill in all the fields!', this.enums.DANGER_ALERT) : this.editBasicSalary();
  }

  editBasicSalary() {

    var data = {
      "salary": this.amount
    };

    var xhr = this.api.putRequest(this.constructApi.getUpdateUserSalary(this.username), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {


        var user = new User(null, null, null, null, null, this.username, null, this.amount);
        this.passEntry.emit(user);

        this.reset();
        this.sharedService.openAlert("Successfully updated user salary!", this.enums.SUCCESS_ALERT);
      }
    }

  }

  changeUserPassword() {
    var error = this.validatePassword();
    if (error != "")
      this.sharedService.openAlert(error, this.enums.DANGER_ALERT)
    else
      this.updateUserPassword();
  }



  validatePassword() {
    var errorMessage: string = "";

    if (this.newPassword == undefined || this.confirmPassword == "" || this.newPassword == "" || this.confirmPassword == "") {
      errorMessage += "\n❌Don't leave password empty"
    }
    else {
      if (this.newPassword != this.confirmPassword) {
        errorMessage += "\n❌Password doesn't match"
      }

      if (this.newPassword.length < 10) {
        errorMessage += "\n❌Password length must be more than 10 characters"
      }
    }
    return errorMessage;

  }

  updateUserPassword() {
    var data = {
      "username": this.username,
      "password": this.newPassword
    };

    var xhr = this.api.putRequest(this.constructApi.getChangePassword(), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {

        this.reset();
        this.sharedService.openAlert("Successfully changed password!", this.enums.SUCCESS_ALERT);

      }
    }
  }

  updateCompanyUserRole() {

    if (Number(this.selectedRoleId) == Number(this.newRoleId)) {
      this.sharedService.openAlert("No changes", this.enums.DANGER_ALERT)
    }
    else {
      var data = {

        "roleId": Number(this.newRoleId),
      };

      var xhr = this.api.putRequest(this.constructApi.getChangeCompanyUserRole(this.companyId, this.username), data);
      xhr.onreadystatechange = () => {
        if (xhr.readyState == 4) {
          if (xhr.status == 200) {
            this.reset();
            this.sharedService.openAlert("Successfully changed role!", this.enums.SUCCESS_ALERT);
            window.location.reload();
          }
          else
            this.sharedService.openErrorMessage(xhr)

        }
      }
    }

  }

  reset() {
    this.activeModal.close();
    this.username = "";
    this.amount = 0;
  }
}
