<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Add record into SIMPAN BD - {{selectedCustomerName}}</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 aria-label="Close" aria-label="Close"
            (click)="reset()"></button>
    </div>
    <div class="modal-body">
        <div class="m-3">
            <h4><strong>Previous SIMPAN BD Records</strong></h4>
            <app-customer-bd-history [selectedProfileID]="selectedProfileId"
                [selectedCustomerName]="selectedCustomerName" [isModal]="false"></app-customer-bd-history>

            <hr />
            <div class="card">
                <div class="card-body">
                    <h4><strong>CP Record</strong></h4>
                    <table class="table table-bordered">
                        <tbody>
                            <tr>
                                <th>Transaction Date:</th>
                                <td>{{transaction.TransactionDate | date:'dd-MM-yyyy'}}</td>
                            </tr>
                            <tr>
                                <th>Amount: </th>
                                <td>RM {{transaction.Amount}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <hr />
                    <h4 class="text-primary"><strong>Add Simpan BD</strong></h4>
                    <app-add-cp-payment [selectedProfileId]="selectedProfileId" [isModal]="false"></app-add-cp-payment>
                </div>


            </div>

        </div>
    </div>
</form>