import { Component, Input, OnInit } from '@angular/core';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { SharedService } from 'src/app/Shared/shared-service.service';


interface Usage {
  CompanyCode: string,
  CompanyId: number,
  LastInsertedDate: Date,
  LatestDate: Date,
  Group: number,
  LastManagerCheckedDate?: Date,
  LastAdminCheckedDate?: Date,

}

interface CompanyGroup {
  id: number,
  name: string
}


@Component({
  selector: 'app-usage-report-card',
  templateUrl: './usage-report-card.component.html',
  styleUrls: ['./usage-report-card.component.css']
})


export class UsageReportCardComponent implements OnInit {

  @Input() usageList: Usage[] = new Array;
  companyGroupList: CompanyGroup[] = new Array;
  constructor(public sharedService: SharedService, private restApi: RestApiService, private constructApi: ConstructAPI) { }

  ngOnInit(): void {
    this.getAllGroups();
  }

  getWarningStatus(lastInsertedDate: Date) {
    if (lastInsertedDate != undefined) {
      var diff = Math.abs(new Date().getTime() - new Date(lastInsertedDate).getTime());
      var diffDays = Math.ceil(diff / (1000 * 3600 * 24));
      if (diffDays > 4)
        return 'salmon';
      else if (diffDays > 2)
        return 'lightyellow';
      else
        return 'white';
    }
    else
      return 'salmon';

  }

  findGroupName(id: number) {
    for (let i = 0; i < this.companyGroupList.length; i++) {
      if (Number(this.companyGroupList[i].id) == Number(id)) {
        return this.companyGroupList[i].name;
      }
    }
  }

  getAllGroups() {

    var xhttp = this.restApi.getRequest(this.constructApi.getAllCompanyGroups())
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let group = { id: json.id, name: json.name }
          this.companyGroupList.push(group);
        }
        );
      }
    };

  }


}
