import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';

interface StockReport {
  schemeName: string,
  activeOutstanding: number,
  bdOutstanding: number,
  cpOutstanding: number,
  stock: number,
  stockReceived: number
}

@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.css']
})




export class VerifyAccountComponent implements OnInit {
  @Input() selectedCompany: number;
  @Input() endDate: Date;
  needStatusOutstanding: boolean = false;

  cashbookBalanceInput: number;
  remarkInput: string;

  actualCashbook: number = 0;
  disabledSubmit = false;

  constructor(private activeModal: NgbActiveModal, public sharedService: SharedService, private restApi: RestApiService, private constructApi: ConstructAPI, private enums: Enums) { }

  ngOnInit(): void {
  }

  reset() {
    this.activeModal.close();
  }

  getActualCashbook(cashbookBalance: number) {
    this.actualCashbook = cashbookBalance;
  }

  verifyInput() {
    if (this.cashbookBalanceInput == undefined) {
      this.sharedService.openAlert("Please key in Cashbook Balance", this.enums.DANGER_ALERT);
      return false;
    }

    else if (this.cashbookBalanceInput != this.actualCashbook) {
      this.sharedService.openAlert("Please key in CORRECT Cashbook Balance", this.enums.DANGER_ALERT);
      return false;
    }

    else return true
  }
  submit() {

    if (this.verifyInput() == true) {
      this.checkIn();
    }
  }

  checkIn() {
    this.disabledSubmit = true;


    //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
    // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
    var addADayDate = new Date(Number(this.endDate) + (3600 * 1000 * 24) - 1);

    var data = {
      "date": addADayDate.toISOString(),
      "remark": this.remarkInput
    }

    var xhr = this.restApi.postRequest(this.constructApi.getAddCompanyCheckIn(this.selectedCompany), data)
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        //if success , then proceed
        if (xhr.status == 200) {

          this.sharedService.openAlert("Successfully verified", this.enums.SUCCESS_ALERT);
          window.location.reload();
          this.reset();
        }
        else {
          //show error message
          this.sharedService.openAlert(JSON.parse(xhr.responseText).error, this.enums.DANGER_ALERT)

        }
      }
    }
  }
}
