<form>
    <!-- Modal Header -->
    <div class="modal-header" style="background-color:lightblue;">
        <h4 class="modal-title">Verify Account</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body">
        <h5 class="fw-bold">Before proceed, please check info below: </h5>
        <div class="card">
            <div class="card-body">
                <app-stock-component [selectedCompany]="selectedCompany" [endDate]="endDate"
                    [needStatusOutstanding]="needStatusOutstanding"   (passActualCashbookBalance)="getActualCashbook($event)"></app-stock-component>
            </div>
        </div>

        <div class="p-3">
            <p>I agree that the :</p>

            <ul class="fw-bold ps-5" style="list-style: square;">
                <li> STOCK AMOUNT</li>
                <li> CASH BOOK BALANCE</li>
                <li> ALL VALUES in LIST HUTANG</li>
            </ul>

            <p>is correct on <strong>{{endDate | date:'dd-MM-yyyy'}}</strong></p>
        </div>

        <div class="row p-3">
            <div class="col-12">
                <p>If confirm, please key in: </p>
            </div>
            <div class="col-12">
                <div class="group">
                    <input type="number" required class="textInputLong" name="amount" [(ngModel)]="cashbookBalanceInput"
                        min=0 (keypress)="sharedService.isNumberKeyAllowNegative($event)">
                    <span class="highlight"></span>
                    <span class="bar barLong"></span>
                    <label>Cashbook Balance</label>
                </div>
            </div>
            <div class="col-12">
                <div class="group">
                    <input type="text" required class="textInputLong" name="remarkInput" [(ngModel)]="remarkInput">
                    <span class="highlight"></span>
                    <span class="bar barLong"></span>
                    <label>Remark(Optional)</label>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12   modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="submit()" [disabled]="disabledSubmit">Confirm</button>
        <button class="btn btn-outline-danger" type="button" data-dismiss="modal" (click)="reset()">Cancel</button>
    </div>

</form>