<form>
    <!-- Modal Header -->
    <div class="modal-header" style="background-color:lightskyblue;" *ngIf="isModal==true">
        <h4 class="modal-title">Add Simpan</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>
    <div class="modal-body">
        <!--Account Number-->
        <div class="row p-3 d-flex justify-content-center align-items-center">
            <div class="col-12">
                <p>To Account</p>
            </div>
            <div class="col-12">
                <select class="browser-default form-select" name="selectedAccount" [(ngModel)]="selectedAccountId">
                    <optgroup label="Company Account">
                        <option *ngFor="let account of companyBankAccountList" [value]="account.ID">
                            {{account.Name}}
                        </option>
                    </optgroup>

                    <optgroup label="Cash Account">
                        <option *ngFor="let account of cashAccountList" [value]="account.ID">
                            {{account.Name}}
                        </option>
                    </optgroup>

                </select>
            </div>

        </div>


        <!--Amount-->
        <div class="group">
            <input type="number" required class="textInputLong" name="amount" [(ngModel)]="amount" min=0
                (keypress)="sharedService.isNumberKey($event)">
            <span class="highlight"></span>
            <span class="bar barLong"></span>
            <label>Simpan Amount</label>
        </div>



        <!--Date-->
        <mat-form-field class="full-width-formField bar">
            <input matInput class="textInputLong" [matDatepicker]="myDatepicker" name="simpanDate"
                [(ngModel)]="simpanDate" placeholder="Simpan Date" [max]="sharedService.getTodayDate()"
                onkeydown="return false">
            <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #myDatepicker name="simpanDate"></mat-datepicker>
        </mat-form-field>

        <!--Remark-->
        <div class="group">
            <input type="text" required class="textInputLong" name="remark" [(ngModel)]="remark">
            <span class="highlight"></span>
            <span class="bar barLong"></span>
            <label> Remark</label>
        </div>



        <div class="col-12 d-md-flex d-lg-flex justify-content-end align-items-end">
            <button class="btn btn-outline-success" type="button" (click)="submit()" [disabled]="disabledSubmit">Add
                Simpan</button>
            <button class="btn btn-outline-danger ms-2" type="button" (click)="reset()">Cancel</button>
        </div>
    </div>

</form>