<div class="card shadow-sm m-2">
    <div class="card-body">
        <div class="row">
            <div class="col-6 fw-bold">
                Requested On : {{request.RequestOn | date:'dd-MM-yyyy'}} | {{request.RequestBy }}
            </div>
            <div class="col-6 fw-bold d-flex justify-content-end align-items-right">
                <h3 class="badge text-bg-success" style="font-size: 1.5rem;"
                    *ngIf="request.Approved!=undefined && request.Approved==true">APPROVED
                    <i class="fas fa-check" *ngIf="request.LoanID!=undefined"></i> |
                    {{request.ProcessedOn | date:'dd-MM-yyyy'}}
                </h3>
                <h3 class="badge text-bg-danger" style="font-size: 1.5rem;"
                    *ngIf="request.Approved!=undefined && request.Approved==false">DENIED |
                    {{request.ProcessedOn | date:'dd-MM-yyyy'}}</h3>
                <h3 class="badge text-bg-primary" style="font-size: 1.5rem;" *ngIf="request.Approved==undefined">PENDING
                </h3>
            </div>
            <div class="col-12 fw-bold" *ngIf="request.ProcessedOn!=undefined">
                Processed Remark : {{request.ProcessedRemark}} | {{request.ProcessedBy}}
            </div>
            <div class="col-12">
                <app-loan-request-details [request]="request" [isExpanded]="true" [editable]="false"
                    [showPrevious]="request.Approved==undefined">
                </app-loan-request-details>
            </div>
        </div>
    </div>
</div>