import { Component } from '@angular/core';
import { ngbPositioning } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { Enums } from 'src/app/Shared/enums';

@Component({
  selector: 'app-draft-calculator',
  templateUrl: './draft-calculator.component.html',
  styleUrl: './draft-calculator.component.css'
})
export class DraftCalculatorComponent {

  newVersionDate: string;


  constructor(public enums: Enums) { }

  ngOnInit(): void {

    this.newVersionDate = "26 August 2024";

    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    document.querySelectorAll('#watermark').forEach(function (el) {
      if (el instanceof HTMLElement)
        el.dataset.watermark = (el.dataset.watermark + '\x09\x02\x09\x02\x09\x02\x09\x02\x09\x02\x09\x02').repeat(300);
    });
  }
}
