import { Component, Input, OnInit } from '@angular/core';
import { AddAssetComponent } from 'src/app/AddThings/add-asset/add-asset.component';
import { AddCapitalComponent } from 'src/app/AddThings/add-capital/add-capital.component';
import { AddExpensesComponent } from 'src/app/AddThings/add-expenses/add-expenses.component';
import { AddIncomeComponent } from 'src/app/AddThings/add-income/add-income.component';
import { AddSavingComponent } from 'src/app/AddThings/add-saving/add-saving.component';
import { AddTotingComponent } from 'src/app/AddThings/add-toting/add-toting.component';
import { TransferFundComponent } from 'src/app/AddThings/transfer-fund/transfer-fund.component';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Account } from 'src/model/account.model';

@Component({
  selector: 'app-add-other-transactions-menu',
  templateUrl: './add-other-transactions-menu.component.html',
  styleUrls: ['./add-other-transactions-menu.component.css']
})
export class AddOtherTransactionsMenuComponent implements OnInit {


  @Input() companyBankAccountList:Account[];
  @Input() cashAccountList:Account[];
  constructor(private sharedService: SharedService) {

  }


  ngOnInit(): void {

  }

  openIncomeModal(isExtras: boolean) {
    const modalRef = this.sharedService.openModal(AddIncomeComponent);

    modalRef.componentInstance.companyBankAccountList = this.companyBankAccountList;
    modalRef.componentInstance.cashAccountList = this.cashAccountList;
    modalRef.componentInstance.paidByDuitLebih = isExtras;

  }

  openExpenseModal() {
    const modalRef = this.sharedService.openModal(AddExpensesComponent);

    modalRef.componentInstance.companyBankAccountList = this.companyBankAccountList;
    modalRef.componentInstance.cashAccountList = this.cashAccountList;
    modalRef.componentInstance.paidByDuitLebih = false;

  }

  openCapitalModal() {
    const modalRef = this.sharedService.openModal(AddCapitalComponent);

    modalRef.componentInstance.companyBankAccountList = this.companyBankAccountList;
    modalRef.componentInstance.cashAccountList = this.cashAccountList;

  }

  openTotingModal() {
    const modalRef = this.sharedService.openModal(AddTotingComponent);

    modalRef.componentInstance.companyBankAccountList = this.companyBankAccountList;
    modalRef.componentInstance.cashAccountList = this.cashAccountList;


  }

  openSavingModal() {
    const modalRef = this.sharedService.openModal(AddSavingComponent);

    modalRef.componentInstance.companyBankAccountList = this.companyBankAccountList;
    modalRef.componentInstance.cashAccountList = this.cashAccountList;

  }

  openAssetModal() {
    const modalRef = this.sharedService.openModal(AddAssetComponent);

    modalRef.componentInstance.companyBankAccountList = this.companyBankAccountList;
    modalRef.componentInstance.cashAccountList = this.cashAccountList;

  }

  openExpenseByExtraModal() {
    const modalRef = this.sharedService.openModal(AddExpensesComponent);

    modalRef.componentInstance.companyBankAccountList = this.companyBankAccountList;
    modalRef.componentInstance.cashAccountList = this.cashAccountList;
    modalRef.componentInstance.paidByDuitLebih = true;

  }


  openTransferModal() {
    const modalRef = this.sharedService.openModal(TransferFundComponent);

    modalRef.componentInstance.companyBankAccountList = this.companyBankAccountList;
    modalRef.componentInstance.cashAccountList = this.cashAccountList;
  }


  findInList(list: Account[], accountId: number) {

    for (let i = 0; i < list.length; i++) {
      if (Number(list[i].ID) == accountId) {
        return i;

      }

    }

    return -1
  }

}
