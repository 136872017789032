<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title"><strong>Task Collection List ({{showDate}})</strong></h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body">

        <div *ngIf="taskCollectionList.length==0;else taskCollectionContent">
            <p>You do not have any task on this date</p>
        </div>

        <ng-template #taskCollectionContent>
            <div *ngFor="let taskCollection of taskCollectionList" class="m-3">

                <div class="card task-card">
                    <div class="card-body">
                        <h4 style="color: blueviolet;"><strong>Customer: {{taskCollection.Name}}</strong></h4>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Receipt Number</th>
                                    <th scope="col">Monthly</th>
                                    <th scope="col">Balance</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let collection of taskCollection.LoanCollection">
                                    <!-- <th scope="row">1</th> -->
                                    <td>{{collection.ReceiptNumber}}
                                    </td>
                                    <td>{{collection.MonthlyPayment}}</td>
                                    <td>{{collection.Balance}}</td>
                                </tr>

                            </tbody>
                            <tfoot>
                                <tr>
                                    <td><b>Total:</b></td>
                                    <td> <b>{{getTotalMonthlyPayment(taskCollection)}}</b></td>
                                    <td> <b>{{getTotalBalance(taskCollection)}}</b></td>
                                </tr>
                            </tfoot>

                        </table>

                    </div>
                </div>

            </div>

        </ng-template>
    </div>





</form>