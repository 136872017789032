import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { AlertDialogComponent } from 'src/app/Shared/alert-dialog/alert-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Account } from 'src/model/account.model';

@Component({
  selector: 'app-add-withdrawal',
  templateUrl: './add-withdrawal.component.html',
  styleUrls: ['./add-withdrawal.component.css']
})
export class AddWithdrawalComponent implements OnInit {


  companyBankAccountList: Account[] = new Array;
  cashAccountList: Account[] = new Array;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  selectedAccountId: number;
  selectedAccountBalance: number;

  @Input() withdrawStamp: boolean = false;
  @Input() withdrawServiceCharge: boolean = false;
  @Input() withdrawHandling: boolean = false;

  @Input() availableWithdrawalAmount: number = 0;

  //2-WAY BINDING INFO
  withdrawalDate: Date;
  amount: number = 0;
  disabledSubmit = false;

  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private restapi: RestApiService, private constructApi: ConstructAPI,
    public sharedService: SharedService, private enums: Enums) { }

  ngOnInit(): void {

    this.withdrawalDate = new Date();

    var companyId = Number(sessionStorage.getItem("companyId"));
    this.getAllAccountsOfCompany(companyId);
  }

  private getAllAccountsOfCompany(companyId: number) {

    var xhttp = this.restapi.getRequest(this.constructApi.getAccountsOfCompany(companyId));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {

          var amount = (json.balance == undefined) ? 0 : json.balance;
          let account = new Account(json.id, json.number, json.name, json.holderName, amount, json.companyId, json.username);

          if (account.Username != undefined)
            this.cashAccountList.push(account)
          else {
            this.companyBankAccountList.push(account);
          }
        }
        );

      }
    };
  }

  setSelectedAccount(accountId: number) {

    if (accountId === this.selectedAccountId)
      return true
    else
      return false;

  }

  setWithdrawalTitle() {
    if (this.withdrawServiceCharge == true)
      return "Service Charge Withdrawal";
    else if (this.withdrawStamp == true)
      return "Stamp Withdrawal";
    else
      return "Handling Withdrawal";
  }

  isFieldNull() {
    var paymentNull = (this.amount == undefined || this.amount == 0);
    var selectedAccountIdNull = (this.selectedAccountId == undefined);
    var paymentValid = isNaN(+this.amount)

    return paymentNull || selectedAccountIdNull || paymentValid
  }




  addStampWithdrawal() {
    this.disabledSubmit = true;
    var isoTaskDate = (new Date(Number(this.withdrawalDate))).toISOString();

    var data = {
      "amount": this.amount,
      "accountId": Number(this.selectedAccountId),
      "date": isoTaskDate,
      "remark": "Stamp Withdrawal"
    };


    var xhr = this.restapi.postRequest(this.constructApi.getWithdrawStamp(), data);
    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        if (xhr.status == 200) {
          let account = new Account(this.selectedAccountId, null, null, null, null, null, null, -this.amount)
          this.passEntry.emit(true)
          this.reset();
          this.sharedService.openAlert("Successfully withdraw stamp!", this.enums.SUCCESS_ALERT);

        } else
          this.sharedService.openErrorMessage(xhr);
      }

    }
  }

  addSCWithdrawal() {
    this.disabledSubmit = true;
    var isoTaskDate = (new Date(Number(this.withdrawalDate))).toISOString();

    var data = {
      "amount": this.amount,
      "accountId": Number(this.selectedAccountId),
      "date": isoTaskDate,
      "remark": "Service Charge Withdrawal"
    };


    var xhr = this.restapi.postRequest(this.constructApi.getWithdrawServiceCharge(), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        if (xhr.status == 200) {

          let account = new Account(this.selectedAccountId, null, null, null, null, null, null, -this.amount)
          this.passEntry.emit(true)
          this.reset();
          this.sharedService.openAlert("Successfully withdraw service charge!", this.enums.SUCCESS_ALERT);

        } else
          this.sharedService.openErrorMessage(xhr);
      }

    }
  }


  addHandlingWithdrawal() {
    this.disabledSubmit = true;
    var isoTaskDate = (new Date(Number(this.withdrawalDate))).toISOString();

    var data = {
      "amount": this.amount,
      "accountId": Number(this.selectedAccountId),
      "date": isoTaskDate,
      "remark": "Handling Charge Withdrawal"
    };


    var xhr = this.restapi.postRequest(this.constructApi.getWithdrawHandling(), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        if (xhr.status == 200) {

          let account = new Account(this.selectedAccountId, null, null, null, null, null, null, -this.amount)
          this.passEntry.emit(true)
          this.reset();
          this.sharedService.openAlert("Successfully withdraw handling charge!", this.enums.SUCCESS_ALERT);

        } else
          this.sharedService.openErrorMessage(xhr);
      }

    }
  }

  getAccountBalance() {

    var account = this.companyBankAccountList.find(account => account.ID === Number(this.selectedAccountId));
    if (account == undefined)
      account = this.cashAccountList.find(account => account.ID === Number(this.selectedAccountId));

    return account.Balance;
  }


  isBalanceInsufficient(balance: number) {
    if (balance >= this.amount)
      return false;
    else
      return true;
  }


  submit() {

    if (this.isFieldNull()) {
      this.sharedService.openAlert('Please fill in all the fields', this.enums.DANGER_ALERT)
    }
    else if (this.amount > this.availableWithdrawalAmount) {
      this.sharedService.openAlert('The withdrawal amount is more than available amount!', this.enums.DANGER_ALERT)
    }
    else {

      if (this.withdrawStamp)
        this.addStampWithdrawal();
      else if (this.withdrawServiceCharge)
        this.addSCWithdrawal();
      else if (this.withdrawHandling)
        this.addHandlingWithdrawal();
    }

  }

  reset() {
    this.activeModal.close();
  }
}
