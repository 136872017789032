export class DuitLebih {
  constructor(
    private amount: number,
    private subtype?: number

  ) { }


  set Amount(_amount: number) { this.amount = _amount }
  get Amount(): number { return this.amount }

  set Subtype(_subtype: number) { this.subtype = _subtype }
  get Subtype(): number { return this.subtype }

}