import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-idle-timeout',
  templateUrl: './idle-timeout.component.html',
  styleUrls: ['./idle-timeout.component.css']
})
export class IdleTimeoutComponent implements OnInit {
  @Output() idleResult: EventEmitter<any> = new EventEmitter();

  @Input() message: string;
  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.activeModal.close();
  }

  passResultToParent(result: boolean) {
    this.closeModal();
    this.idleResult.emit(result);
  }

}
