<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Transaction History</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 aria-label="Close" aria-label="Close"
            (click)="closeModal()" *ngIf="accountId!=undefined"></button>
    </div>

    <div class="modal-body">

        <div class="container-fluid" style="padding: 2%;">
            <div class="row">
                <div class="col-12 col-lg-3">
                    <div class="row">
                        <div class="col-12">
                            <p>Start Date</p>
                        </div>
                        <div class="col-12">
                            <mat-form-field class="full-width-formField p-0">
                                <input matInput readonly [matDatepicker]="startDatePicker" name="startDate"
                                    [(ngModel)]="startDate" required>
                                <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #startDatePicker name="startDate"></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>

                </div>

                <div class="col-12 col-lg-3">
                    <div class="row">
                        <div class="col-12">
                            <p>End Date</p>
                        </div>
                        <div class="col-12">
                            <mat-form-field class="full-width-formField p-0">
                                <input matInput readonly [matDatepicker]="endDatePicker" name="endDate" [(ngModel)]="endDate"
                                    [min]="startDate" required>
                                <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #endDatePicker name="endDate"></mat-datepicker>
                            </mat-form-field>
                        </div>

                    </div>
                </div>

                <div class="col-12 col-lg-3" [hidden]="loggedInRole==enums.STAFF">
                    <div class="row">
                        <div class="col-12">
                            <p>User</p>
                        </div>
                        <div class="col-12">
                            <select class="browser-default form-select" name="selectedUser"
                                [(ngModel)]="selectedUsername">
                                <option [value]=""></option>
                                <option *ngFor="let user of userList" [value]="user.Username"
                                    [selected]="setSelectedUser(user.Username)">{{user.Username}}</option>
                            </select>
                        </div>

                    </div>
                </div>
                <div class="col-12 col-md-2 d-flex justify-content-left align-items-center">
                    <button class="btn btn-primary" (click)="getAllTransactions()">Go</button>
                </div>
            </div>
            <hr />
            <ng-container
                *ngIf="(!transactionList || transactionList.length===0)&& carryForwardAmount==0;else alltransactions">
                <p>There's no transaction record for this account.</p>
            </ng-container>

            <ng-template #alltransactions>
                <!-- <ng-container *ngFor="let transaction of transactionList">
                    <div class="card transaction-card shadow-lg" [ngClass]="getTransactionColor(transaction.Amount)">
                        <div class="card-body">
                            <div class="row g-0">
                                <div class="col-3">
                                    <div class="p-2"
                                        style="background-color: whitesmoke;border-radius: 30px;text-align: center;">
                                        <h2>{{transaction.Date | date:'dd'}}</h2>
                                        <h4>{{transaction.Date | date:'MMM'}}</h4>
                                    </div>
                                </div>
                                <div class="col-9 p-2">
                                    <h5>Amount:RM {{transaction.Amount}} </h5>
                                    <p>Remakrs:{{transaction.Remark}}</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </ng-container> -->


                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th *ngIf="sharedService.isAdmin() ||sharedService.isBossAccount()"></th>
                            <th>Date</th>
                            <th>Remark</th>
                            <th>Amount</th>
                            <th>Type</th>
                            <!-- <th>Balance</th> -->
                            <!-- <th>Closing Balance</th> -->
                            <th class="d-none d-lg-table-cell">Customer Name</th>
                            <th class="d-none d-lg-table-cell">Customer IC</th>
                            <th class="d-none d-lg-table-cell">Occupation</th>
                            <th>HandleBy</th>
                        </tr>

                    </thead>
                    <tr class="bg-info">
                        <td *ngIf="sharedService.isAdmin() ||sharedService.isBossAccount()"></td>
                        <td></td>
                        <td> Carry Forward Amount</td>
                        <td> {{carryForwardAmount}}</td>
                        <td></td>
                        <td class="d-none d-lg-table-cell"></td>
                        <td class="d-none d-lg-table-cell"></td>
                        <td class="d-none d-lg-table-cell"></td>
                        <td></td>
                    </tr>
                    <tr *ngFor="let transaction of transactionList">
                        <td *ngIf="sharedService.isAdmin() ||sharedService.isBossAccount()">
                            <button class="btn btn-warning"
                                (click)="openEditTransactionDialog(transaction.ID,transaction.Amount,transaction.Remark)">
                                <i class="fas fa-pencil-alt"></i>
                            </button>

                            <button class="btn btn-danger" (click)="openDeleteTransactionDialog(transaction)">
                                <i class="fas fa-trash-alt"></i>
                            </button>
                        </td>
                        <td>
                            {{transaction.Date | date:'dd-MM-yyyy'}}
                        </td>
                        <td>
                            {{transaction.Remark}}
                        </td>

                        <td [ngClass]="checkAmountInOut(transaction.Amount)">
                            {{transaction.Amount}}
                        </td>

                        <td>
                            {{enums.transactionTypeString[transaction.Type-1]
                            }} <span
                                *ngIf="transaction.Subtype!=undefined">({{getSubTypeName(transaction.Subtype)}})</span>
                        </td>
                        <!-- <td>
                            {{transaction.ClosingBalance}}
                        </td> -->
                        <td class="customerText d-none d-lg-table-cell" (click)="goToCustomer(transaction.CustomerID)">
                            {{transaction.CustomerName}}
                        </td>
                        <td class="customerText d-none d-lg-table-cell" (click)="goToCustomer(transaction.CustomerID)">
                            {{transaction.CustomerIC}}
                        </td>
                        <td class="customerText d-none d-lg-table-cell" (click)="goToCustomer(transaction.CustomerID)">
                            {{transaction.CustomerOccupation}}
                        </td>
                        <td>
                            {{transaction.HandleBy}}
                        </td>
                    </tr>
                    <tfoot>
                        <tr>
                            <td [hidden]="loggedInRole!=enums.ADMIN"></td>
                            <td></td>
                            <td><strong>Total:</strong></td>
                            <td><strong>{{calculateTotalTransaction(transactionList) |number:'1.2-2'}}</strong></td>
                            <td class="text-success"><strong>Total In: RM {{calculateTotalIn(transactionList) |number:'1.2-2'}}</strong></td>
                            <td class="text-danger"><strong>Total Out: RM {{calculateTotalOut(transactionList) |number:'1.2-2'}}</strong></td>
                        </tr>
                    </tfoot>
                </table>
            </ng-template>
        </div>
    </div>


</form>