import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AddCompanyComponent } from 'src/app/AddThings/add-company/add-company.component';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Company } from 'src/model/company.model';
import { TransactionType } from 'src/model/transactionType.model';
import { User } from 'src/model/user.model';


interface ImportFile {
  type: string,
  file: File,
  done?: boolean
}

interface ImportBalance {
  type: number,
  balance: number,
  date: Date,
  done?: boolean
}

@Component({
  selector: 'app-import-excel',
  templateUrl: './import-excel.component.html',
  styleUrls: ['./import-excel.component.css']
})
export class ImportExcelComponent implements OnInit {
  @ViewChild('excelFile') excelFile: ElementRef;

  selectedCompanyID: number;
  selectedUsername: string;

  isLegacy: boolean;
  isDry: boolean;
  isIncludedSettle: boolean;

  companyList: Company[] = new Array;
  importFileList: ImportFile[] = new Array;
  importBalanceList: ImportBalance[] = new Array;
  userList: User[] = new Array;
  transactionTypeList: TransactionType[] = new Array;

  selectedFile: File;

  selectedImportType: string;
  selectedBalanceType: number;
  balanceAmount: number;
  balanceDate: Date;

  disabledSubmit = false;

  importFileTypes = ["A", "D", "F", "P", "RESERVE", "ADVANCE", "TRANSACTIONS", "DEBT", "BD","B","C","E","EPF"];
  constructor(private router: Router, private restapi: RestApiService, private constructAPI: ConstructAPI,
    public enums: Enums, public sharedService: SharedService, private cdr: ChangeDetectorRef) {

    this.getAllCompanies();
    this.getTransactionType();
  }

  ngOnInit(): void {
  }


  handleFileInput(files: FileList) {
    this.selectedFile = files.item(0);
  }

  private getAllCompanies() {
    var xhttp = this.restapi.getRequest(this.constructAPI.getAllCompanies());

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          // create company instance with all response data
          let company = new Company(json.id, json.name, json.address, json.companyCode, json.contactNo);
          //add company instance into company list
          this.companyList.push(company);
        }
        );

      }
    };
  }

  addSelectedFile() {
    if (this.selectedImportType != undefined && this.selectedFile != undefined) {
      let importExcel = { type: this.selectedImportType, file: this.selectedFile };
      this.importFileList.push(importExcel);

      this.selectedImportType = undefined;
      this.selectedFile = undefined;
      this.excelFile.nativeElement.value = null;
    }
    else
      this.sharedService.openAlert("Done added selected excel into queue",this.enums.SUCCESS_ALERT);

  }

  removeExcel(i) {
    this.importFileList.splice(i, 1);
    this.cdr.detectChanges();
  }

  removeBalance(i) {
    this.importBalanceList.splice(i, 1);
    this.cdr.detectChanges();
  }


  addSelectedBalance() {
    if (this.selectedBalanceType != undefined && this.balanceAmount != undefined && this.balanceDate != undefined) {
      let importBalance = { type: this.selectedBalanceType, balance: this.balanceAmount, date: this.balanceDate };
      this.importBalanceList.push(importBalance);

      this.selectedBalanceType = undefined;
      this.balanceAmount = undefined;
      this.balanceDate = undefined;

      this.sharedService.openAlert("Done added selected balance into queue",this.enums.SUCCESS_ALERT);
    }
    else
      this.sharedService.openAlert("Please key in details for import balance",this.enums.DANGER_ALERT);

  }

  getAllManagerUsers(companyId: number) {
    this.userList.splice(0, this.userList.length);

    var xhttp = this.restapi.getRequest(this.constructAPI.getAllUsers(companyId, this.enums.BRANCH_MANAGER));

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          let user = new User(json.id, json.companyId, undefined, undefined, undefined, json.username, undefined, undefined);
          this.userList.push(user);
        }
        );
      }
    };
  }

  getTransactionType() {
    var xhttp = this.restapi.getRequest(this.constructAPI.getAllTransactionTypes());
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          var type = new TransactionType(json.id, json.name);

          this.transactionTypeList.push(type);
        });

        this.transactionTypeList.sort((a, b) => ((a.ID > b.ID)) ? 1 : -1);
      }
    };

  }

  viewCompanyList() {
    this.router.navigate(['/companyList']);
  }

  goToRegister() {
    this.router.navigate(['/register']);
  }

  submit() {
    if (this.importFileList.length > 0) {
      var index = this.getNextImportIndex(0);

      if (index != -1)
        this.addDryImport(this.importFileList[index], index);
      else
        this.addImportBalance();
    }
    else if (this.importBalanceList.length > 0) {
      this.addImportBalance();
    }
    else
      this.sharedService.openAlert("Please at least fill in 1 file / balance you would like to import",this.enums.DANGER_ALERT)
  }

  checkDone() {
    for (let i = 0; i < this.importFileList.length; i++) {
      if (this.importFileList[i].done != true)
        return false;
    }

    return true;
  }

  addDryImport(importFile: ImportFile, index: number) {

    this.disabledSubmit = true;
    var formData = new FormData();
    formData.append("file", importFile.file, importFile.file.name);
    var data = {
      type: importFile.type,
      username: this.selectedUsername,
      dry: true,
      legacy: true,
      inclSettled:this.isIncludedSettle
    }
    var prop;
    for (prop in data) {
      if (typeof (data[prop]) != "string")
        formData.append(prop, JSON.stringify(data[prop]));
      else
        formData.append(prop, data[prop])
    }

    var xhr = this.restapi.postFormDataRequest(this.constructAPI.getAddImportFile(this.selectedCompanyID), formData);
    xhr.onreadystatechange = () => {

      if (xhr.readyState === 4) {
        if (xhr.status == 200) {

          if (index <= this.importFileList.length - 1) {
            if (this.importFileList[index].done != true)
              this.addImport(importFile, index);
          }
        }
        else {
          var json = JSON.parse((xhr.responseText));
          this.sharedService.openAlert("Error when importing File " + importFile.type + " : " + json.error,this.enums.DANGER_ALERT);
          this.importFileList[index].done = false;
          this.disabledSubmit = false;
        }
      }

    };
  }

  addImport(importFile: ImportFile, index: number) {
    var formData = new FormData();
    formData.append("file", importFile.file, importFile.file.name);
    var data = {
      type: importFile.type,
      username: this.selectedUsername,
      dry: false,
      legacy: true,
      inclSettled:this.isIncludedSettle
    }
    var prop;
    for (prop in data) {
      if (typeof (data[prop]) != "string")
        formData.append(prop, JSON.stringify(data[prop]));
      else
        formData.append(prop, data[prop])
    }

    var xhr = this.restapi.postFormDataRequest(this.constructAPI.getAddImportFile(this.selectedCompanyID), formData);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status == 200) {

        this.importFileList[index].done = true;

        if (index < this.importFileList.length - 1) {
          var nextIndex = this.getNextImportIndex(++index)
          this.addDryImport(this.importFileList[nextIndex], nextIndex);
        }
        else if (index == this.importFileList.length - 1 && this.importBalanceList.length > 0)
          this.addImportBalance();


        this.isIncludedSettle = false;
      } else
        this.disabledSubmit = false;
    };
  }

  getNextImportIndex(index: number) {
    for (let i = index; i < this.importFileList.length; i++) {
      if (this.importFileList[i].done != true)
        return i;
      else
        continue;
    }
    return -1;
  }

  addImportBalance() {
    var processBalanceList: JSON[] = new Array;
    for (let i = 0; i < this.importBalanceList.length; i++) {
      if (this.importBalanceList[i].done != true) {


        if (this.importBalanceList[i].date != undefined)
          //in order to get the correct result, end date need to add 1 day and minus 2 millisecond 
          // 3600*1000*24 is 1 day in millisecond, then reduced by 2 millisecond
          var reduceASecDate = new Date(Number(this.importBalanceList[i].date) - 2);

        let balance = { "type": Number(this.importBalanceList[i].type), "balance": Number(this.importBalanceList[i].balance), "date": reduceASecDate.toISOString() }
        processBalanceList.push(JSON.parse(JSON.stringify(balance)));
      }
    }
    var xhr = this.restapi.postRequest(this.constructAPI.getAddImportBalance(this.selectedCompanyID), processBalanceList);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status == 200) {

          for (let i = 0; i < this.importBalanceList.length; i++) {
            this.importBalanceList[i].done = true;
          }

          this.sharedService.openAlert("Done importing balances",this.enums.SUCCESS_ALERT);
        }
        else {
          for (let i = 0; i < this.importBalanceList.length; i++) {
            this.importBalanceList[i].done = false;
          }
          this.sharedService.openAlert("Error occurred when importing balances. Please try again later",this.enums.DANGER_ALERT);

        }

        this.disabledSubmit = false;
      }
    };
  }

}
