import e from "./svgs/atm-dollar.svg.js";
import n from "./svgs/atm-euro.svg.js";
import a from "./svgs/atm-pound.svg.js";
import o from "./svgs/atm.svg.js";
import r from "./svgs/atm-yen.svg.js";
import c from "./svgs/auction.svg.js";
import i from "./svgs/award.svg.js";
import t from "./svgs/bank-safe.svg.js";
import s from "./svgs/barcode.svg.js";
import m from "./svgs/bear-market.svg.js";
import d from "./svgs/bill-paid.svg.js";
import l from "./svgs/budget-spending.svg.js";
import u from "./svgs/bull-market.svg.js";
import g from "./svgs/business-development.svg.js";
import p from "./svgs/calculator.svg.js";
import f from "./svgs/cash-1.svg.js";
import y from "./svgs/cash-2.svg.js";
import v from "./svgs/cash-only.svg.js";
import k from "./svgs/certificate.svg.js";
import b from "./svgs/commodities.svg.js";
import w from "./svgs/credit-cards.svg.js";
import h from "./svgs/credit-control.svg.js";
import I from "./svgs/crowdfunding.svg.js";
import C from "./svgs/debt.svg.js";
import j from "./svgs/delivery.svg.js";
import x from "./svgs/diamond-circled.svg.js";
import D from "./svgs/diamond.svg.js";
import S from "./svgs/discount.svg.js";
import A from "./svgs/dividents.svg.js";
import B from "./svgs/dollar-circled.svg.js";
import E from "./svgs/drop-shipping.svg.js";
import M from "./svgs/euro-circled.svg.js";
import P from "./svgs/face-id.svg.js";
import q from "./svgs/financial-insurance.svg.js";
import G from "./svgs/foreign-exchange.svg.js";
import O from "./svgs/gift.svg.js";
import R from "./svgs/gold.svg.js";
import T from "./svgs/idea-generation.svg.js";
import Y from "./svgs/invoice.svg.js";
import z from "./svgs/legal-document.svg.js";
import F from "./svgs/loan.svg.js";
import H from "./svgs/money-bag.svg.js";
import J from "./svgs/oil.svg.js";
import K from "./svgs/pay-date.svg.js";
import L from "./svgs/piggy-bank.svg.js";
import N from "./svgs/pound-circled.svg.js";
import Q from "./svgs/project-revenue.svg.js";
import U from "./svgs/sale.svg.js";
import V from "./svgs/security-1.svg.js";
import W from "./svgs/security-2.svg.js";
import X from "./svgs/strategy.svg.js";
import Z from "./svgs/wire-transfer.svg.js";
import _ from "./svgs/yen-circled.svg.js";
const $ = {
  name: "atm-dollar",
  value: e,
  categories: ["finance"],
  keywords: [
    "atm",
    "usd",
    "dollar",
    "bank",
    "cash",
    "money",
    "payment",
    "deposit",
    "withdraw",
    "machine"
  ]
}, ee = {
  name: "atm-euro",
  value: n,
  categories: ["finance"],
  keywords: [
    "atm",
    "eur",
    "euro",
    "bank",
    "cash",
    "money",
    "payment",
    "deposit",
    "withdraw",
    "machine"
  ]
}, ne = {
  name: "atm-pound",
  value: a,
  categories: ["finance"],
  keywords: [
    "atm",
    "gbp",
    "pound",
    "sterling",
    "bank",
    "cash",
    "money",
    "payment",
    "deposit",
    "withdraw",
    "machine"
  ]
}, ae = {
  name: "atm",
  value: o,
  categories: ["finance"],
  keywords: [
    "atm",
    "bank",
    "cash",
    "money",
    "payment",
    "deposit",
    "withdraw",
    "machine"
  ]
}, oe = {
  name: "atm-yen",
  value: r,
  categories: ["finance"],
  keywords: [
    "atm",
    "jpy",
    "yen",
    "bank",
    "cash",
    "money",
    "payment",
    "deposit",
    "withdraw",
    "machine"
  ]
}, re = {
  name: "auction",
  value: c,
  categories: ["finance"],
  keywords: ["auction", "sale", "gavel", "law", "judge", "hammer"]
}, ce = {
  name: "award",
  value: i,
  categories: ["finance"],
  keywords: ["award", "medal", "winner", "first", "gold", "sport"]
}, ie = {
  name: "bank-safe",
  value: t,
  categories: ["finance"],
  keywords: [
    "bank",
    "safe",
    "savings",
    "secure",
    "vault",
    "deposit",
    "locker",
    "safe box"
  ]
}, te = {
  name: "barcode",
  value: s,
  categories: ["finance"],
  keywords: ["barcode", "code", "scan", "marketing", "commerce"]
}, se = {
  name: "bear-market",
  value: m,
  categories: ["finance"],
  keywords: [
    "bear",
    "market",
    "decreasing",
    "financial",
    "economy",
    "animal",
    "stock"
  ]
}, me = {
  name: "bill-paid",
  value: d,
  categories: ["finance"],
  keywords: [
    "bill",
    "paid",
    "check",
    "invoice",
    "payment",
    "purchase",
    "accounting",
    "billing"
  ]
}, de = {
  name: "budget-spending",
  value: l,
  categories: ["finance"],
  keywords: [
    "budget",
    "spending",
    "usd",
    "dollar",
    "accounting",
    "expense",
    "investment",
    "management",
    "ballance"
  ]
}, le = {
  name: "bull-market",
  value: u,
  categories: ["finance"],
  keywords: [
    "bull",
    "market",
    "increasing",
    "financial",
    "economy",
    "animal",
    "stock"
  ]
}, ue = {
  name: "business-development",
  value: g,
  categories: ["finance"],
  keywords: [
    "business",
    "development",
    "global",
    "worldwide",
    "solutions",
    "organisation",
    "innovation",
    "management"
  ]
}, ge = {
  name: "calculator",
  value: p,
  categories: ["finance"],
  keywords: ["calculator", "math", "school", "business", "accounting"]
}, pe = {
  name: "cash",
  value: f,
  categories: ["finance"],
  keywords: [
    "cash",
    "money",
    "notes",
    "bank",
    "pay",
    "payment",
    "banknote",
    "currency"
  ]
}, fe = {
  name: "cash-alt",
  value: y,
  categories: ["finance"],
  keywords: [
    "cash",
    "money",
    "notes",
    "bank",
    "pay",
    "payment",
    "banknote",
    "currency",
    "usd",
    "dollar"
  ]
}, ye = {
  name: "cash-only",
  value: v,
  categories: ["finance"],
  keywords: ["cash", "no card", "payment", "money", "pay", "cash only"]
}, ve = {
  name: "certificate",
  value: k,
  categories: ["finance"],
  keywords: [
    "certificate",
    "medal",
    "badge",
    "seal",
    "prize",
    "award",
    "guarantee",
    "quality",
    "stamp",
    "warranty",
    "collection"
  ]
}, ke = {
  name: "commodities",
  value: b,
  categories: ["finance"],
  keywords: [
    "commodities",
    "oil",
    "gold",
    "trade",
    "commodity",
    "crude",
    "market",
    "price",
    "invest"
  ]
}, be = {
  name: "credit-cards",
  value: w,
  categories: ["finance"],
  keywords: [
    "credit",
    "debit",
    "cards",
    "payment",
    "shopping",
    "business",
    "money",
    "cash"
  ]
}, we = {
  name: "credit-control",
  value: h,
  categories: ["finance"],
  keywords: ["credit", "control", "watch", "eye"]
}, he = {
  name: "crowdfunding",
  value: I,
  categories: ["finance"],
  keywords: [
    "crowdfunding",
    "mutual",
    "fund",
    "contribution",
    "donation",
    "money",
    "usd",
    "dollar",
    "revenue",
    "budget",
    "fundraising"
  ]
}, Ie = {
  name: "debt",
  value: C,
  categories: ["finance"],
  keywords: ["debt", "financial", "usd", "dollar", "money", "request"]
}, Ce = {
  name: "delivery",
  value: j,
  categories: ["finance"],
  keywords: [
    "delivery",
    "truck",
    "logistics",
    "van",
    "courier",
    "shipping",
    "service",
    "vehicle"
  ]
}, je = {
  name: "diamond-circled",
  value: x,
  categories: ["finance"],
  keywords: [
    "diamond",
    "gem",
    "precious",
    "jewellery",
    "circled",
    "jewel",
    "gemstone"
  ]
}, xe = {
  name: "diamond",
  value: D,
  categories: ["finance"],
  keywords: ["diamond", "gem", "precious", "jewellery", "jewel", "gemstone"]
}, De = {
  name: "discount",
  value: S,
  categories: ["finance"],
  keywords: [
    "discount",
    "percent",
    "percentage",
    "sale",
    "badge",
    "off",
    "%",
    "rebate",
    "subsidy"
  ]
}, Se = {
  name: "dividents",
  value: A,
  categories: ["finance"],
  keywords: [
    "dividents",
    "%",
    "percent",
    "percentage",
    "income",
    "rate",
    "cut",
    "intrest",
    "investment"
  ]
}, Ae = {
  name: "dollar-circled",
  value: B,
  categories: ["finance"],
  keywords: ["usd", "dollar", "circled", "currency"]
}, Be = {
  name: "drop-shipping",
  value: E,
  categories: ["finance"],
  keywords: [
    "drop",
    "shipping",
    "dropshipping",
    "box",
    "parachute",
    "business",
    "logistics",
    "crate",
    "air",
    "supply",
    "airdrop",
    "fortnite",
    "pubg",
    "battle royale",
    "delivery",
    "package"
  ]
}, Ee = {
  name: "euro-circled",
  value: M,
  categories: ["finance"],
  keywords: ["eur", "euro", "circled", "currency", "eu"]
}, Me = {
  name: "face-id",
  value: P,
  categories: ["finance"],
  keywords: ["faceid", "biometric", "identity", "security", "face"]
}, Pe = {
  name: "financial-insurance",
  value: q,
  categories: ["finance"],
  keywords: [
    "financial",
    "insurance",
    "business",
    "money",
    "protection",
    "security"
  ]
}, qe = {
  name: "foreign-exchange",
  value: G,
  categories: ["finance"],
  keywords: [
    "foreign",
    "exchange",
    "currency",
    "forex",
    "swap",
    "trade",
    "bank",
    "dollar",
    "usd",
    "euro",
    "eur",
    "money",
    "rate"
  ]
}, Ge = {
  name: "gift",
  value: O,
  categories: ["finance"],
  keywords: ["gift", "present", "box", "christmas", "bonus", "give"]
}, Oe = {
  name: "gold",
  value: R,
  categories: ["finance"],
  keywords: [""]
}, Re = {
  name: "idea-generation",
  value: T,
  categories: ["finance"],
  keywords: [
    "gold",
    "business",
    "currency",
    "finance",
    "gold bars",
    "treasure",
    "gold blocks",
    "gold cubes",
    "gold bricks",
    "gold ingots",
    "gold biscuits"
  ]
}, Te = {
  name: "invoice",
  value: Y,
  categories: ["finance"],
  keywords: [
    "invoice",
    "accounting",
    "bill",
    "business",
    "document",
    "file",
    "billing",
    "payment",
    "purchase"
  ]
}, Ye = {
  name: "legal-document",
  value: z,
  categories: ["finance"],
  keywords: ["legal", "document", "file", "contract", "paper", "law", "court"]
}, ze = {
  name: "loan",
  value: F,
  categories: ["finance"],
  keywords: [
    "loan",
    "collateral",
    "convinience loan",
    "mortgage",
    "personal loan",
    "lending",
    "money",
    "dollar",
    "usd"
  ]
}, Fe = {
  name: "money-bag",
  value: H,
  categories: ["finance"],
  keywords: [
    "money",
    "bag",
    "cash",
    "coin",
    "currency",
    "dollar",
    "usd",
    "investment"
  ]
}, He = {
  name: "oil",
  value: J,
  categories: ["finance"],
  keywords: [
    "oil",
    "crude",
    "commodities",
    "barrel",
    "oil container",
    "petroleum"
  ]
}, Je = {
  name: "pay-date",
  value: K,
  categories: ["finance"],
  keywords: [
    "pay date",
    "calendar",
    "salary",
    "card",
    "payday",
    "payment",
    "finance",
    "schedule",
    "plan",
    "direct debit"
  ]
}, Ke = {
  name: "piggy-bank",
  value: L,
  categories: ["finance"],
  keywords: [
    "piggy bank",
    "money",
    "save",
    "savings",
    "piggybank",
    "business",
    "cash",
    "pig",
    "coin",
    "coins",
    "bank",
    "deposit",
    "piglet"
  ]
}, Le = {
  name: "pound-circled",
  value: N,
  categories: ["finance"],
  keywords: ["gbp", "pound", "sterling", "circled", "currency"]
}, Ne = {
  name: "project-revenue",
  value: Q,
  categories: ["finance"],
  keywords: [
    "project",
    "revenue",
    "turnover",
    "income",
    "dollar",
    "usd",
    "money",
    "capital",
    "earnings",
    "profit",
    "automation",
    "make money"
  ]
}, Qe = {
  name: "sale",
  value: U,
  categories: ["finance"],
  keywords: [
    "sale",
    "circled",
    "rebate",
    "discount",
    "deal",
    "commerce",
    "shopping",
    "promo",
    "flash",
    "flashsale"
  ]
}, Ue = {
  name: "security",
  value: V,
  categories: ["finance"],
  keywords: [
    "security",
    "briefcase",
    "case",
    "guard",
    "insurance",
    "protection",
    "shield",
    "bag",
    "luggage",
    "document",
    "save"
  ]
}, Ve = {
  name: "security-alt",
  value: W,
  categories: ["finance"],
  keywords: [
    "security",
    "briefcase",
    "usd",
    "dollar",
    "case",
    "guard",
    "insurance",
    "protection",
    "bag",
    "luggage",
    "document",
    "save"
  ]
}, We = {
  name: "strategy",
  value: X,
  categories: ["finance"],
  keywords: [
    "strategy",
    "business",
    "corporate",
    "strategic",
    "management",
    "plan",
    "planning",
    "chess",
    "game",
    "play"
  ]
}, Xe = {
  name: "wire-transfer",
  value: Z,
  categories: ["finance"],
  keywords: [
    "wire",
    "money",
    "transfer",
    "currency",
    "exchange",
    "euro",
    "eur",
    "dollar",
    "usd",
    "bank",
    "payment",
    "trade",
    "transaction"
  ]
}, Ze = {
  name: "yen-circled",
  value: _,
  categories: ["finance"],
  keywords: ["jpy", "yen", "circled", "currency"]
}, _n = [
  ae,
  $,
  ee,
  ne,
  oe,
  re,
  ce,
  ie,
  te,
  se,
  me,
  de,
  le,
  ue,
  ge,
  pe,
  fe,
  ye,
  ve,
  ke,
  be,
  we,
  he,
  Ie,
  Ce,
  xe,
  je,
  De,
  Se,
  Ae,
  Be,
  Ee,
  Me,
  Pe,
  qe,
  Ge,
  Oe,
  Re,
  Te,
  Ye,
  ze,
  Fe,
  He,
  Je,
  Ke,
  Le,
  Ne,
  Qe,
  Ue,
  Ve,
  We,
  Xe,
  Ze
];
export {
  ae as atm,
  $ as atmDollar,
  ee as atmEuro,
  ne as atmPound,
  oe as atmYen,
  re as auction,
  ce as award,
  ie as bankSafe,
  te as barcode,
  se as bearMarket,
  me as billPaid,
  de as budgetSpending,
  le as bullMarket,
  ue as businessDevelopment,
  ge as calculator,
  pe as cash,
  fe as cashAlt,
  ye as cashOnly,
  ve as certificate,
  ke as commodities,
  be as creditCards,
  we as creditControl,
  he as crowdfunding,
  Ie as debt,
  Ce as delivery,
  xe as diamond,
  je as diamondCircled,
  De as discount,
  Se as dividents,
  Ae as dollarCircled,
  Be as dropShipping,
  Ee as euroCircled,
  Me as faceId,
  _n as finance,
  Pe as financialInsurance,
  qe as foreignExchange,
  Ge as gift,
  Oe as gold,
  Re as ideaGeneration,
  Te as invoice,
  Ye as legalDocument,
  ze as loan,
  Fe as moneyBag,
  He as oil,
  Je as payDate,
  Ke as piggyBank,
  Le as poundCircled,
  Ne as projectRevenue,
  Qe as sale,
  Ue as security,
  Ve as securityAlt,
  We as strategy,
  Xe as wireTransfer,
  Ze as yenCircled
};
