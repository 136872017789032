import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Account } from 'src/model/account.model';
import { TransactionSubType } from 'src/model/transactionSubType.model';


interface TransactionInterface {
  remark: string,
  amount: number,
  date: Date,
  subtype?: number
}

@Component({
  selector: 'app-add-expenses',
  templateUrl: './add-expenses.component.html',
  styleUrls: ['./add-expenses.component.css']
})
export class AddExpensesComponent implements OnInit {


  @Input() companyBankAccountList: Account[] = new Array;
  @Input() cashAccountList: Account[] = new Array;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  selectedAccountId: number;
  selectedAccountBalance: number;
  selectedSubTypeId: number;

  @Input() paidByDuitLebih: boolean = false;

  //2-WAY BINDING INFO
  @Input() remark: string;
  @Input() amount: number = 0;
  subtypeList: TransactionSubType[] = new Array;

  disabledSubmit = false;
  transactionList: TransactionInterface[] = new Array;

  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private restapi: RestApiService,
    private constructApi: ConstructAPI, public sharedService: SharedService, private enums: Enums) { }

  ngOnInit(): void {
  }

  setSelectedAccount(accountId: number) {

    if (accountId === this.selectedAccountId)
      return true
    else
      return false;

  }

  setSelectedSubType(subTypeID: number) {

    if (subTypeID === this.selectedSubTypeId)
      return true
    else
      return false;

  }

  setExpenseTitle() {
    if (this.paidByDuitLebih == true)
      return "Expenses by Duit Lebih";
    else
      return "Expenses";
  }

  isFieldNull() {
    // var paymentNull = (this.amount == undefined || this.amount == 0);
    // var remarkNull = (this.remark == undefined || this.remark == "");
    var transactionNull = (this.transactionList==undefined || this.transactionList.length==0)
    var selectedAccountIdNull = (this.selectedAccountId == undefined);
    // var paymentValid = isNaN(+this.amount)

    return transactionNull || selectedAccountIdNull
  }


  getTransactionData(data: TransactionInterface[]) {
    this.transactionList = data;
  }



  addExpenses() {
    this.disabledSubmit = true;

    var data = {
      "accountId": Number(this.selectedAccountId),
      "transactions": this.transactionList

    };


    var xhr = this.restapi.postRequest(this.constructApi.getAddExpenses(), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        if (xhr.status == 200) {

          window.location.reload();

        }
        else
          this.sharedService.openErrorMessage(xhr);
      }

    }
  }

  getAccountBalance() {

    var account = this.companyBankAccountList.find(account => account.ID === Number(this.selectedAccountId));
    if (account == undefined)
      account = this.cashAccountList.find(account => account.ID === Number(this.selectedAccountId));

    return account.Balance;
  }

  isBalanceInsufficient(balance: number) {
    var expenseTotal = 0;

    for (let i = 0; i < this.transactionList.length; i++) {
      expenseTotal += this.transactionList[i].amount;
    }

    if (balance >= expenseTotal)
      return false;
    else
      return true;
  }

  addExpensesByDuitLebih() {
    this.disabledSubmit = true;

    if (this.selectedSubTypeId == undefined) {
      var data = {
        "accountId": Number(this.selectedAccountId),
        "transactions": this.transactionList
      };

    }



    var xhr = this.restapi.postRequest(this.constructApi.getAddExtraExpenses(), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        if (xhr.status == 200) {
          window.location.reload();


        } else
          this.sharedService.openErrorMessage(xhr);
      }

    }
  }

  submit() {

    if (this.isFieldNull()) {
      this.sharedService.openAlert('Please fill in all the fields',this.enums.DANGER_ALERT)
    }
    else {
      if (this.paidByDuitLebih == true)
        this.addExpensesByDuitLebih();
      else {

        if (this.getAccountBalance() < this.amount)
          this.sharedService.openAlert("Expense amount is more than account balance",this.enums.DANGER_ALERT);
        else
          this.addExpenses();
      }
    }

  }

  reset() {
    this.activeModal.close();
  }
}
