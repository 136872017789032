<ng-container>
    <div class="row m-1 pe-2" *ngIf="serverStatus!=undefined">
        <div class="col-6"><strong>Version: {{serverStatus.version}}</strong></div>
        <div class="col-6"><strong>Website Version: {{serverStatus.websiteVersion}} <span
                    class=" text-secondary " style="font-size: 0.6rem;">{{versionStatus.clientVersion}}</span> </strong>
        </div>
        <div class="col m-0 p-0 d-flex justify-content-end align-items-right">
            <button class="btn btn-dark" (click)="refreshEverything()"><i class="fas fa-sync"></i></button>
            <button class="btn btn-info" (click)="openDeployWebAppModal()"><i
                    class="fas fa-cloud-upload-alt"></i></button>
        </div>
    </div>
    <div class="row p-2 m-1" *ngIf="serverStatus!=undefined">
        <div class="col-6 col-lg-2">
            <h5 class="fw-bold">
                <div class="card shadow-lg" style="background-color: rgb(225, 242, 248);">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-5">
                                <h2> <i class="fas fa-database"></i></h2>
                                <p>DB</p>
                            </div>
                            <div class="col-7 text-end">
                                <h5 class="text-success fw-bold" *ngIf="serverStatus.databaseConnected==true">
                                    CONNECTED
                                </h5>
                                <h5 class="text-danger fw-bold" *ngIf="serverStatus.databaseConnected==false">
                                    DISCONNECTED
                                </h5>
                                <span style="color: gray;">PING : {{serverStatus.databasePing}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </h5>
        </div>
        <div class="col-6 col-lg-2">
            <h5 class="fw-bold">
                <div class="card shadow-lg" style="background-color: rgb(225, 242, 248);">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-5">
                                <h2> <i class="fas fa-database"></i></h2>
                                <p>CP DB</p>
                            </div>
                            <div class="col-7 text-end">
                                <h5 class="text-success fw-bold" *ngIf="serverStatus.cpdbConnected==true">
                                    CONNECTED
                                </h5>
                                <h5 class="text-danger fw-bold" *ngIf="serverStatus.cpdbConnected==false">
                                    DISCONNECTED
                                </h5>
                                <span style="color: gray;">PING : {{serverStatus.cpdbPing}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </h5>
        </div>
        <div class="col-6 col-lg-2">
            <h5 class="fw-bold">
                <div class="card shadow-lg" style="background-color: rgb(241, 226, 224);">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-5">
                                <h2><i class="fas fa-key"></i> </h2>
                                <p>Redis</p>
                            </div>

                            <div class="col-7 text-end">
                                <h5 class="text-success fw-bold" *ngIf="serverStatus.redisConnected==true">
                                    CONNECTED
                                </h5>
                                <h5 class="text-danger fw-bold" *ngIf="serverStatus.redisConnected==false">
                                    DISCONNECTED
                                </h5>
                                <p style="color: gray;">PING : {{serverStatus.redisPing}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </h5>
        </div>
        <div class="col-6 col-lg-3">
            <h5 class="fw-bold">
                <div class="card shadow-lg" style="background-color: rgb(250, 250, 214);">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-5">
                                <h2>
                                    <i class="fas fa-archive"></i>
                                </h2>
                                <p>Storage</p>
                            </div>
                            <div class="col-7 text-end">
                                <h5 class="text-success fw-bold" *ngIf="serverStatus.storageConnected==true">
                                    CONNECTED
                                </h5>
                                <h5 class="text-danger fw-bold" *ngIf="serverStatus.storageConnected==false">
                                    DISCONNECTED
                                </h5>
                                <p style="color: gray;">PING : {{serverStatus.storagePing}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </h5>
        </div>
        <div class="col-6 col-lg-3">
            <h5 class="fw-bold">
                <div class="card shadow-lg">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div class="">
                                <h2>
                                    <i class="fas fa-server"></i>
                                </h2>
                                <p>Server Status</p>
                            </div>
                            <div class="text-end">
                                <mat-button-toggle-group class="server-status-toggle" [(ngModel)]="serverStatus.maintenanceMode" (change)="openMaintenaceModeModal($event.value)">
                                    <mat-button-toggle [value]="true" class="text-uppercase status-toggle-danger">Maintainance</mat-button-toggle>
                                    <mat-button-toggle [value]="false" class="text-uppercase status-toggle-success">Operational</mat-button-toggle>
                                </mat-button-toggle-group>
                            </div>
                        </div>
                    </div>
                </div>
            </h5>
        </div>
    </div>
    <hr />

    <div class="row p-2 m-1">
        <div class=" col-12 col-lg-10" *ngIf="errorLogList !=undefined">
            <div class="d-flex m-1 p-1" style="background-color: black; height:100%;">

                <table class="table table-borderless ">
                    <tr *ngFor="let error of errorLogList" style="border: 0px;">
                        <td style="color:greenyellow" class="p-1">{{error}}</td>
                    </tr>
                </table>


            </div>


        </div>
        <div class="col-12 col-lg-2" *ngIf="signedInUserList!=undefined">

            <div class=" card shadow-lg" style="height:100%;">
                <div class="card-body">
                    <h5 class="fw-bold"><i class="fas fa-user"></i> Signed In Users</h5>
                    <table class="table">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Username</th>
                            </tr>    
                        </thead>
                        <tbody>
                            <tr *ngFor="let username of signedInUserList;let i=index">
                                <td> {{i+1}}</td>
                                <td> {{username}}</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>



        </div>
    </div>

</ng-container>