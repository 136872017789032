<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Edit Customer EPF Details</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()" aria-label="Close"></button>
    </div>

    <div class="modal-body">
        <div class="container-fluid p-0" *ngIf="epfDetails!=undefined && epfDetails!=undefined">
            <div class="row">
                <div class="col-6">
                    <mat-form-field class="full-width-formField bar">
                        <input matInput class="textInputLong" [matDatepicker]="myDatepicker"
                            [(ngModel)]="epfDetails.Date" [ngModelOptions]="{standalone: true}"
                            placeholder="EPF Settlement Date" [min]="sharedService.getTodayDate()"
                            [max]="sharedService.getMaxDateForEPF()" onkeydown="return false"
                            [disabled]="epfDetails.Date!=undefined">
                        <mat-datepicker-toggle matSuffix [for]="myDatepicker">
                        </mat-datepicker-toggle>
                        <mat-datepicker #myDatepicker name="date"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field class="full-width-formField bar">
                        <mat-label>EPF Amount (RM)</mat-label>
                        <input matInput type="number" required class="textInputLong" placeholder="EPF Amount"
                            [(ngModel)]="epfDetails.Amount" [ngModelOptions]="{standalone: true}" min=0
                            (keypress)="sharedService.isNumberKey($event)" [readonly]="epfDetails.Amount!=undefined">
                    </mat-form-field>
                </div>

                <div class="col-12 mb-2">
                    <mat-slide-toggle class="mat-slide-toggle-lg" [hideIcon]="true" color="primary" [(ngModel)]="epfDetails.HasIAccount"
                        [ngModelOptions]="{standalone: true}">
                        Has iAccount
                    </mat-slide-toggle>
                </div>
                <div class="col-6">
                    <mat-form-field class="full-width-formField bar">
                        <mat-label>iAccount Username</mat-label>
                        <input matInput type="text" class="textInputLong" placeholder="iAccount Username"
                            [(ngModel)]="epfDetails.IAccountUsername" [ngModelOptions]="{standalone: true}">
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field class="full-width-formField bar">
                        <mat-label>iAccount Password</mat-label>
                        <input matInput type="text" class="textInputLong" placeholder="iAccount Password"
                            [(ngModel)]="epfDetails.IAccountPassword" [ngModelOptions]="{standalone: true}">
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <mat-form-field class="full-width-formField bar">
                        <mat-label>Remark</mat-label>
                        <input matInput type="text" class="textInputLong" placeholder="Remark"
                            [(ngModel)]="epfDetails.Remark" [ngModelOptions]="{standalone: true}">
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-success" (click)="updateEPFDetails()" [disabled]="disableSubmit">Edit</button>
    </div>
</form>