<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Add Company</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()" aria-label="Close"></button>
    </div>

    <div class="modal-body">
        <div class="container-fluid p-0">
            <div class="mt-2">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <!--company code-->
                            <div class="col col-12 mt-4 d-flex justify-content-center align-items-center">
                                <div class="group">
                                    <input type="text" required class="textInputLong" [(ngModel)]="companyCode"
                                        name="companyCode" (focusout)="validField('companyCode')">
                                    <span class="highlight"></span>
                                    <span class="bar barLong"></span>
                                    <label>Company Code</label>
                                </div>
                            </div>

                            <!--company group-->
                            <div class="col col-12 mt-4 d-flex justify-content-center align-items-center">
                                <div class="row">
                                    <div class="col-12">
                                        <p class="p-0"> Company Group</p>
                                    </div>
                                    <div class="col-12">
                                        <select class="browser-default form-select" name="selectedGroupId"
                                            [(ngModel)]="selectedGroupId">
                                            <option *ngFor="let group of companyGroupList" [value]="group.id"
                                                [selected]="setSelectedGroup(group.id)">
                                                {{group.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <!--initial cash book balance-->
                            <div class="col col-12 mt-4 d-flex justify-content-center align-items-center">
                                <div class="group">
                                    <input type="text" required class="textInputLong"
                                        [(ngModel)]="initialCashBookBalance" name="initialCashBookBalance"
                                        (focusout)="validField('initialCashBookBalance')"
                                        (keypress)="sharedService.isNumberKeyAllowNegative($event)">
                                    <span class="highlight"></span>
                                    <span class="bar barLong"></span>
                                    <label>Initial Cash Book Balance (RM) </label>
                                </div>
                            </div>


                            <!--initial cash book date-->
                            <div class="col col-12 mt-4 d-flex justify-content-center align-items-center">
                                <mat-form-field class="full-width-formField bar">
                                    <input matInput class="textInputLong" [matDatepicker]="myDatepicker"
                                        name="initialCashBookDate" [(ngModel)]="initialCashBookDate"
                                        placeholder="Initial Cash Book Date (Next day of latest account)">
                                    <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                                    <mat-datepicker #myDatepicker name="date"></mat-datepicker>
                                </mat-form-field>
                            </div>

                            <!--date operating-->
                            <div class="col col-12 mt-4 d-flex justify-content-center align-items-center">
                                <mat-form-field class="full-width-formField bar">
                                    <input matInput class="textInputLong" [matDatepicker]="dateOperatingDatePicker"
                                        name="dateOperating" [(ngModel)]="dateOperating" placeholder="Date Operating">
                                    <mat-datepicker-toggle matSuffix
                                        [for]="dateOperatingDatePicker"></mat-datepicker-toggle>
                                    <mat-datepicker #dateOperatingDatePicker name="date"></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-12 mb-4">
                                <mat-slide-toggle class="mat-slide-toggle-lg" [hideIcon]="true" color="warn" [(ngModel)]="requireConsentForm"
                                    name="requireConsentForm">Require Consent Form
                                </mat-slide-toggle>

                            </div>


                            <div class="card ms-4 p-4" style="border: 1px solid teal;">
                                <h4 class="text-center text-primary fw-bold">For Form J Usage Only</h4>

                                <div class="row">
                                    <div class="col-12 mb-4 d-flex justify-content-center align-items-center">
                                        <mat-slide-toggle class="mat-slide-toggle-lg" [hideIcon]="true" color="accent"
                                            [(ngModel)]="isCopyDetailsFromMainBranch" name="isCopyDetailsFromMainBranch">Use
                                            Main Branch Details
                                        </mat-slide-toggle>

                                    </div>

                                    <!--togglr copy details from main branch-->
                                    <div class="col col-12 mt-4 d-flex justify-content-center align-items-center"
                                        *ngIf="isCopyDetailsFromMainBranch==true">
                                        <div class="row">
                                            <div class="col-12">
                                                <p class="p-0"> Main Company</p>
                                            </div>
                                            <div class="col-12"
                                                *ngIf="allCompanyList!=undefined && allCompanyList.length>0">
                                                <select class="browser-default form-select"
                                                    name="selectedSourceCompany" [(ngModel)]="selectedSourceCompanyID" (ngModelChange)="getCompanyDetails()">
                                                    <option *ngFor="let company of allCompanyList"
                                                        [ngValue]="company.ID">
                                                        {{company.CompanyCode}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>





                                <div class="row">
                                    <!--company name-->
                                    <div class="col col-12 mt-4 d-flex justify-content-center align-items-center">
                                        <div class="group">
                                            <input type="text" required class="textInputLong" name="companyName"
                                                [(ngModel)]="companyName" (focusout)="validField('companyName')"
                                                [readonly]="isCopyDetailsFromMainBranch==true">
                                            <span class="highlight"></span>
                                            <span class="bar barLong"></span>
                                            <label>Form J Company Name</label>
                                        </div>
                                    </div>

                                    <!--phone number-->
                                    <div class="col col-12 mt-4 d-flex justify-content-center align-items-center">
                                        <div class="group">
                                            <input type="text" required class="textInputLong" [(ngModel)]="phoneNumber"
                                                name="phoneNumber" (focusout)="validField('phoneNumber')"
                                                [readonly]="isCopyDetailsFromMainBranch==true">
                                            <span class="highlight"></span>
                                            <span class="bar barLong"></span>
                                            <label>Form J Company Phone Number</label>
                                        </div>
                                    </div>
                                    <!--company reg num-->
                                    <div class="col col-12 mt-4 d-flex justify-content-center align-items-center">
                                        <div class="group">
                                            <input type="text" required class="textInputLong" name="regNum"
                                                [(ngModel)]="regNum" (focusout)="validField('regNum')"
                                                [readonly]="isCopyDetailsFromMainBranch==true">
                                            <span class="highlight"></span>
                                            <span class="bar barLong"></span>
                                            <label>Form J Company Registration Number</label>
                                        </div>
                                    </div>
                                    <!--Address-->
                                    <div class="row">
                                        <div class="col">
                                            <div class="ms-4"><span>Form J Address</span></div>
                                            <div class="ms-4">
                                                <textarea cols="40" rows="4" required [(ngModel)]="address"
                                                    name="address" (focusout)="validField('address')"
                                                    [readonly]="isCopyDetailsFromMainBranch==true"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    
    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="submit()">Add Company</button>
        <button class="btn btn-outline-danger" type="button" data-dismiss="modal"
            (click)="reset()">Cancel</button>
    </div>
</form>