import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IgxListModule, IgxAvatarModule, IgxCalendarModule, IgxIconModule, IgxDatePickerModule, IgxGridModule } from 'igniteui-angular';

import { AgGridModule } from 'ag-grid-angular';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatStepperModule } from '@angular/material/stepper';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidebarComponent } from './Others/sidebar/sidebar.component';
import { ApplyAssetComponent } from './AddThings/apply-asset/apply-asset.component';
import { AddTransactionComponent } from './AddThings/add-transaction/add-transaction.component';
import { AddCustomerComponent } from './AddThings/add-customer/add-customer.component';
import { ContactListComponent } from './Others/contact-list/contact-list.component';
import { ContactCardComponent } from './Others/contact-card/contact-card.component';
import { AddPlanComponent } from './AddThings/add-plan/add-plan.component';
import { DashboardCalendarComponent } from './Dashboards/dashboard-calendar/dashboard-calendar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ManageCustomerComponent } from './Customer/manage-customer/manage-customer.component';
import { CustomerContactListComponent } from './Customer/customer-contact-list/customer-contact-list.component';
import { CustomerTaskListComponent } from './Customer/customer-task-list/customer-task-list.component';
import { CustomerAlbumComponent } from './Customer/customer-album/customer-album.component';
import { CompanyCardComponent } from './Customer/company-card/company-card.component';
import { CustomerLoanCardComponent } from './Customer/customer-loan-card/customer-loan-card.component';
import { DashboardAdminComponent } from './Dashboards/dashboard-admin/dashboard-admin.component';
import { CustomerListComponent } from './Customer/customer-list/customer-list.component';
import { AddAccountComponent } from './AddThings/add-account/add-account.component';
import { from } from 'rxjs';
import { CustomerDetailsComponent } from './Customer/customer-details/customer-details.component';
import { AddLoanComponent } from './AddThings/add-loan/add-loan.component';
import { LoginPageComponent } from './Others/login-page/login-page.component';
import { TaskDetailsComponent } from './Others/task-details/task-details.component';
import { CreateNewTaskComponent } from './AddThings/create-new-task/create-new-task.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { RegisterUserComponent } from './Others/register-user/register-user.component';
import { NavbarComponent } from './Others/navbar/navbar.component';
import { AuthService } from './auth/auth.service';
import { AuthGuard } from './auth/auth.guard';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { NgxPanZoomModule } from 'ngx-panzoom';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MatRadioModule } from '@angular/material/radio';



import { MatTabsModule } from '@angular/material/tabs';
import { CompanyListComponent } from './Company/company-list/company-list.component';
import { CompanyDetailCardComponent } from './Company/company-detail-card/company-detail-card.component';
import { LoanListComponent } from './Loans/loan-list/loan-list.component';
import { AddPaymentComponent } from './LoanPayment/add-payment/add-payment.component';
import { LoanDetailsComponent } from './Loans/loan-details/loan-details.component';
import { LoanCardComponent } from './Loans/loan-card/loan-card.component';
import { AddContactComponent } from './AddThings/add-contact/add-contact.component';
import { UpdateCustomerComponent } from './Customer/update-customer/update-customer.component';
import { AddCompanyComponent } from './AddThings/add-company/add-company.component';
import { ConfirmationDialogComponent } from './Shared/confirmation-dialog/confirmation-dialog.component';
import { RestApiService } from './API/restapi';
import { APIAddress } from './API/apiAddresses';
import { UpdateCompanyComponent } from './Company/update-company/update-company.component';
import { GlobalSearchCustomerComponent } from './Others/global-search-customer/global-search-customer.component';
import { LoanReserveComponent } from './Loans/loan-reserve/loan-reserve.component';
import { TaskCollectionListComponent } from './Others/task-collection-list/task-collection-list.component';
import { ReserveHistoryComponent } from './Reserve/reserve-history/reserve-history.component';
import { LoanArrearComponent } from './Loans/loan-arrear/loan-arrear.component';
import { AddIncomeComponent } from './AddThings/add-income/add-income.component';
import { StockReportComponent } from './Report/stock-report/stock-report.component';
import { AddExpensesComponent } from './AddThings/add-expenses/add-expenses.component';
import { ReportMenuComponent } from './Report/report-menu/report-menu.component';
import { AddPrincipalComponent } from './AddThings/add-principal/add-principal.component';
import { AddAssetComponent } from './AddThings/add-asset/add-asset.component';
import { AddAdvanceComponent } from './AddThings/add-advance/add-advance.component';
import { AdvanceListComponent } from './Advance/advance-list/advance-list.component';
import { PayAdvanceComponent } from './Advance/pay-advance/pay-advance.component';
import { UploadImageComponent } from './Images/upload-image/upload-image.component';
import { EnlargeImageComponent } from './Images/enlarge-image/enlarge-image.component';
import { NoRightClickDirective } from './Shared/no-right-click.directive';
import { AddCpPaymentComponent } from './AddThings/add-cp-payment/add-cp-payment.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SearchTaskComponent } from './Others/search-task/search-task.component';
import { AlertDialogComponent } from './Shared/alert-dialog/alert-dialog.component';
import { ComissionListComponent } from './Comission/comission-list/comission-list.component';
import { AddCommissionPaymentComponent } from './Comission/add-commission-payment/add-commission-payment.component';
import { SimpleReportComponent } from './Report/simple-report/simple-report.component';
import { ReportGeneratorComponent } from './Report/report-generator/report-generator.component';
import { CashBookReportComponent } from './Report/cash-book-report/cash-book-report.component';
import { ListHutangReportComponent } from './Report/list-hutang-report/list-hutang-report.component';
import { LoanSchemeReportComponent } from './Report/loan-scheme-report/loan-scheme-report.component';
import { TransferFundComponent } from './AddThings/transfer-fund/transfer-fund.component';
import { TransactionHistoryComponent } from './Account/transaction-history/transaction-history.component';
import { UserListComponent } from './User/user-list/user-list.component';
import { FundReallocationComponent } from './Account/fund-reallocation/fund-reallocation.component';
import { EditUserComponent } from './User/edit-user/edit-user.component';
import { EditProfileComponent } from './User/edit-profile/edit-profile.component';
import { AccountListComponent } from './Account/account-list/account-list.component';
import { AddCapitalComponent } from './AddThings/add-capital/add-capital.component';
import { AddSavingComponent } from './AddThings/add-saving/add-saving.component';
import { AddWithdrawalComponent } from './AddThings/add-withdrawal/add-withdrawal.component';
import { AssetSummaryReportComponent } from './Report/asset-summary-report/asset-summary-report.component';
import { SearchByReceiptComponent } from './Others/search-by-receipt/search-by-receipt.component';
import { BadDebtSimpanExpenseComponent } from './AddThings/bad-debt-simpan-expense/bad-debt-simpan-expense.component';
import { AddCommissionsComponent } from './Comission/add-commissions/add-commissions.component';
import { AssignCommissionComponent } from './Comission/assign-commission/assign-commission.component';
import { EditTransactionComponent } from './Account/edit-transaction/edit-transaction.component';
import { AddLoanDetailsComponent } from './Loans/add-loan-details/add-loan-details.component';
import { EditCommissionComponent } from './Comission/edit-commission/edit-commission.component';
import { AddBadDebtWithdrawalComponent } from './AddThings/add-bad-debt-withdrawal/add-bad-debt-withdrawal.component';
import { AddCommissionComponent } from './Comission/add-commission/add-commission.component';
import { AddSubtypeComponent } from './AddThings/add-subtype/add-subtype.component';
import { AddDuitLebihComponent } from './AddThings/add-duit-lebih/add-duit-lebih.component';
import { AddedDuitLebihListComponent } from './AddThings/added-duit-lebih-list/added-duit-lebih-list.component';
import { DuitLebihReportComponent } from './Report/duit-lebih-report/duit-lebih-report.component';
import { AddSimpanComponent } from './Reserve/add-simpan/add-simpan.component';
import { WithdrawSimpanComponent } from './Reserve/withdraw-simpan/withdraw-simpan.component';
import { BatchPaymentComponent } from './LoanPayment/batch-payment/batch-payment.component';
import { AddPaymentModalComponent } from './AddThings/add-payment-modal/add-payment-modal.component';
import { ImportNewCompanyComponent } from './Import/import-new-company/import-new-company.component';
import { ImportExcelComponent } from './Import/import-excel/import-excel.component';
import { CustomerTransactionsComponent } from './Customer/customer-transactions/customer-transactions.component';
import { FormJComponent } from './FormJ/form-j/form-j.component';
import { FormJScheduleComponent } from './FormJ/form-j-schedule/form-j-schedule.component';
import { FormJHistoryComponent } from './FormJ/form-j-history/form-j-history.component';
import { GenerateFormjComponent } from './FormJ/generate-formj/generate-formj.component';
import { PaymentVoucherComponent } from './FormJ/payment-voucher/payment-voucher.component';
import { FormjReceiptLetterComponent } from './FormJ/formj-receipt-letter/formj-receipt-letter.component';
import { UpdateProfileInfoComponent } from './Customer/update-profile-info/update-profile-info.component';
import { AddProfileComponent } from './AddThings/add-profile/add-profile.component';
import { CustomerBdHistoryComponent } from './Customer/customer-bd-history/customer-bd-history.component';
import { CashBalanceReportComponent } from './Report/cash-balance-report/cash-balance-report.component';
import { CustomerLeadListComponent } from './Customer/customer-lead-list/customer-lead-list.component';
import { CustomerAdminRemarkComponent } from './Customer/customer-admin-remark/customer-admin-remark.component';
import { AddAdminRemarkComponent } from './AddThings/add-admin-remark/add-admin-remark.component';
import { ViewCustomerRemarkComponent } from './Customer/view-customer-remark/view-customer-remark.component';
import { DeleteReasonRemarkComponent } from './Account/delete-reason-remark/delete-reason-remark.component';
import { MidMonthReportComponent } from './Report/mid-month-report/mid-month-report.component';
import { UsageReportComponent } from './Report/usage-report/usage-report.component';
import { TransferSimpanComponent } from './Reserve/transfer-simpan/transfer-simpan.component';
import { DailyReportComponent } from './Report/daily-report/daily-report.component';
import { FormJReportComponent } from './Report/form-j-report/form-j-report.component';
import { CompanySummaryReportComponent } from './Report/company-summary-report/company-summary-report.component';
import { VerifyAccountComponent } from './Report/verify-account/verify-account.component';
import { StockComponentComponent } from './Report/stock-component/stock-component.component';
import { CheckInHistoryComponent } from './Report/check-in-history/check-in-history.component';
import { IdleTimeoutComponent } from './Others/idle-timeout/idle-timeout.component';
import { ChangeLoanStatusComponent } from './Loans/change-loan-status/change-loan-status.component';
import { MergeProfileComponent } from './Customer/merge-profile/merge-profile.component';
import { ExpenseSimpanComponent } from './Reserve/expense-simpan/expense-simpan.component';
import { NoscrollDirective } from './noscroll.directive';
import { AddTotingComponent } from './AddThings/add-toting/add-toting.component';
import { DeleteReasonComponent } from './Others/delete-reason/delete-reason.component';
import { AddOtherTransactionsMenuComponent } from './Account/add-other-transactions-menu/add-other-transactions-menu.component';
import { AddMultipleTransactionComponent } from './AddThings/add-multiple-transaction/add-multiple-transaction.component';
import { MaintenanceModeToggleComponent } from './Others/maintenance-mode-toggle/maintenance-mode-toggle.component';
import { DueEpfListComponent } from './Dashboards/due-epf-list/due-epf-list.component';
import { ShowWhatsappMessageClipboardComponent } from './Shared/show-whatsapp-message-clipboard/show-whatsapp-message-clipboard.component';
import { LoanRequestListComponent } from './LoanRequest/loan-request-list/loan-request-list.component';
import { AddApprovedLoanComponent } from './AddThings/add-approved-loan/add-approved-loan.component';
import { LoanRequestDetailsComponent } from './LoanRequest/loan-request-details/loan-request-details.component';
import { AllLoanRequestCardComponent } from './LoanRequest/all-loan-request-card/all-loan-request-card.component';
import { ReviewLoanRequestComponent } from './LoanRequest/review-loan-request/review-loan-request.component';
import { UsageReportCardComponent } from './Dashboards/usage-report-card/usage-report-card.component';
import { NextTaskComponent } from './Dashboards/follow-next-task/follow-next-task.component';
import { PreviousApprovedRequestCardComponent } from './LoanRequest/previous-approved-request-card/previous-approved-request-card.component';
import { MarkLegacyEpfsComponent } from './Loans/mark-legacy-epfs/mark-legacy-epfs.component';
import { EpfRequestCalculatorComponent } from './LoanRequest/epf-request-calculator/epf-request-calculator.component';
import { CustomerLoanRequestHistoryComponent } from './Customer/customer-loan-request-history/customer-loan-request-history.component';
import { EditEpfDetailsComponent } from './Customer/edit-epf-details/edit-epf-details.component';
import { EditLoanRequestInfoComponent } from './LoanRequest/edit-loan-request-info/edit-loan-request-info.component';
import { OverlapComponentComponent } from './LoanPayment/overlap-component/overlap-component.component';
import { CustomerRecordReportComponent } from './Report/customer-record-report/customer-record-report.component';
import { AddLoanRequestGuarantorComponent } from './LoanRequest/add-loan-request-guarantor/add-loan-request-guarantor.component';
import { ServerStatusPageComponent } from './Others/server-status-page/server-status-page.component';
import { OverdueEpfReportComponent } from './Report/overdue-epf-report/overdue-epf-report.component';
import { AuthorizeLoanRequestComponent } from './LoanRequest/authorize-loan-request/authorize-loan-request.component';
import { LegacyEpfSettlementComponent } from './Loans/legacy-epf-settlement/legacy-epf-settlement.component';
import { EpfLimitInformationComponent } from './LoanRequest/epf-limit-information/epf-limit-information.component';
import { GuarantorFormComponent } from './Customer/guarantor-form/guarantor-form.component';
import { ViewContactThumbnailsComponent } from './Images/view-contact-thumbnails/view-contact-thumbnails.component';
import { AddHutangRequestComponent } from './AddThings/add-hutang-request/add-hutang-request.component';
import { ElevateConfirmationComponent } from './LoanRequest/elevate-confirmation/elevate-confirmation.component';
import { AddReceiptSetComponent } from './Sets/add-receipt-set/add-receipt-set.component';
import { ReceiptSetsListComponent } from './Sets/receipt-sets-list/receipt-sets-list.component';
import { ReceiptDetailsComponent } from './Sets/receipt-details/receipt-details.component';
import { ViewThumbnailsComponent } from './Images/view-thumbnails/view-thumbnails.component';
import { MoveLoanComponent } from './Loans/move-loan/move-loan.component';
import { ShowUnprocessedSetTransactionComponent } from './Sets/show-unprocessed-set-transaction/show-unprocessed-set-transaction.component';
import { EditLoanRequestRemarkComponent } from './LoanRequest/edit-loan-request-remark/edit-loan-request-remark.component';
import { VerifyCustomerComponent } from './Customer/verify-customer/verify-customer.component';
import { CheckCustomerNecessaryFilesComponent } from './Customer/check-customer-necessary-files/check-customer-necessary-files.component';
import { AlbumComponentComponent } from './Images/album-component/album-component.component';
import { DeployWebappComponent } from './Others/deploy-webapp/deploy-webapp.component';
import { ManageCompanyAccessComponent } from './Company/manage-company-access/manage-company-access.component';
import { CompareSettlementInfoListComponent } from './Others/compare-settlement-info-list/compare-settlement-info-list.component';
import { SharedAddRemarkComponent } from './Shared/shared-add-remark/shared-add-remark.component';
import { ReceiptSetsListviewComponent } from './Sets/receipt-sets-listview/receipt-sets-listview.component';
import { ReceiptAddExpenseAdminRemarkComponent } from './Sets/receipt-add-expense-admin-remark/receipt-add-expense-admin-remark.component';
import { ManageTransactionSubtypeComponent } from './AddThings/manage-transaction-subtype/manage-transaction-subtype.component';
import { ScrollToTopComponent } from './Shared/scroll-to-top/scroll-to-top.component';
import { ViewCpDetailsComponent } from './CP/view-cp-details/view-cp-details.component';
import { CpCustomerListComponent } from './CP/cp-customer-list/cp-customer-list.component';
import { CpOverviewComponent } from './CP/cp-overview/cp-overview.component';
import { CpPaymentSheetComponent } from './cp-payment-sheet/cp-payment-sheet.component';
import { CompareCpPaymentsComponent } from './CP/compare-cp-payments/compare-cp-payments.component';
import { AddTransactionRequestComponent } from './AddThings/add-transaction-request/add-transaction-request.component';
import { AssignedTransactionCardComponent } from './Dashboards/assigned-transaction-card/assigned-transaction-card.component';
import { AddAssignedTransactionIntoAccountComponent } from './AddThings/add-assigned-transaction-into-account/add-assigned-transaction-into-account.component';
import { ConflictedCustomerListComponent } from './Others/conflicted-customer-list/conflicted-customer-list.component';
import { ViewEpfChangeHistoryComponent } from './Customer/view-epf-change-history/view-epf-change-history.component';
import { IcChangeHistoryComponent } from './Customer/ic-change-history/ic-change-history.component';
import { SelectAllComponent } from './Shared/select-all/select-all.component';
import { LoanRequestApprovalComponent } from './LoanRequest/loan-request-approval/loan-request-approval.component';
import { AddPulledCustomerRecordComponent } from './AddThings/add-pulled-customer-record/add-pulled-customer-record.component';
import { AddBlacklistCustomerComponent } from './Blacklist/add-blacklist-customer/add-blacklist-customer.component';
import { BlacklistCustomerListComponent } from './Blacklist/blacklist-customer-list/blacklist-customer-list.component';
import { ValidateEpfStatementComponent } from './Others/validate-epf-statement/validate-epf-statement.component';
import { DatePipe } from '@angular/common';

import { SharedLoanComponent } from './SharedLoan/shared-loan/shared-loan.component';
import { AddLoanRequestDetailsComponent } from './LoanRequest/add-loan-request-details/add-loan-request-details.component';
import { SharedLoanAgreementComponent } from './SharedLoan/shared-loan-agreement/shared-loan-agreement.component';
import { AddReserveGroupComponent } from './Reserve/add-reserve-group/add-reserve-group.component';
import { ViewReserveGroupsComponent } from './Reserve/view-reserve-groups/view-reserve-groups.component';
import { SearchCompanyByUidComponent } from './Others/search-company-by-uid/search-company-by-uid.component';
import { SharedLoanRequestCardComponent } from './SharedLoan/shared-loan-request-card/shared-loan-request-card.component';

import { SearchTicketComponent } from './LoanRequest/search-ticket/search-ticket.component';
import { ShareProfileToCompanyComponent } from './Customer/share-profile-to-company/share-profile-to-company.component';
import { SharedLoanListComponent } from './SharedLoan/shared-loan-list/shared-loan-list.component';
import { BookmarkComponent } from './Others/bookmark/bookmark.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BookmarkItemComponent } from "./Others/bookmark-item/bookmark-item.component";
import { DraftCalculatorComponent } from './LoanRequest/draft-calculator/draft-calculator.component';
import { EpfSettlementBreakdownLetterComponent } from './Loans/epf-settlement-breakdown-letter/epf-settlement-breakdown-letter.component';

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    ApplyAssetComponent,
    AddTransactionComponent,
    EditProfileComponent,
    AddCustomerComponent,
    ContactListComponent,
    ContactCardComponent,
    AddPlanComponent,
    DashboardCalendarComponent,
    ManageCustomerComponent,
    CustomerContactListComponent,
    CustomerTaskListComponent,
    CustomerAlbumComponent,
    CompanyCardComponent,
    CustomerLoanCardComponent,
    DashboardAdminComponent,
    CustomerListComponent,
    AddAccountComponent,
    CustomerDetailsComponent,
    AddLoanComponent,
    LoginPageComponent,
    TaskDetailsComponent,
    CreateNewTaskComponent,
    RegisterUserComponent,
    NavbarComponent,
    CompanyListComponent,
    CompanyDetailCardComponent,
    LoanListComponent,
    TransactionHistoryComponent,
    AddPaymentComponent,
    LoanDetailsComponent,
    LoanCardComponent,
    AddContactComponent,
    UpdateCustomerComponent,
    AddCompanyComponent,
    ConfirmationDialogComponent,
    UserListComponent,
    UpdateCompanyComponent,
    GlobalSearchCustomerComponent,
    LoanReserveComponent,
    FundReallocationComponent,
    TaskCollectionListComponent,
    AccountListComponent,
    ReserveHistoryComponent,
    LoanArrearComponent,
    AddIncomeComponent,
    StockReportComponent,
    AddExpensesComponent,
    ReportMenuComponent,
    AddPrincipalComponent,
    AddAssetComponent,
    AddAdvanceComponent,
    AdvanceListComponent,
    PayAdvanceComponent,
    UploadImageComponent,
    EnlargeImageComponent,
    NoRightClickDirective,
    AddCpPaymentComponent,
    SearchTaskComponent,
    AlertDialogComponent,
    ComissionListComponent,
    AddCommissionPaymentComponent,
    SimpleReportComponent,
    ReportGeneratorComponent,
    CashBookReportComponent,
    ListHutangReportComponent,
    LoanSchemeReportComponent,
    TransferFundComponent,
    EditUserComponent,
    AddCapitalComponent,
    AddSavingComponent,
    AddWithdrawalComponent,
    AssetSummaryReportComponent,
    SearchByReceiptComponent,
    BadDebtSimpanExpenseComponent,
    AddCommissionsComponent,
    AssignCommissionComponent,
    EditTransactionComponent,
    AddLoanDetailsComponent,
    EditCommissionComponent,
    AddBadDebtWithdrawalComponent,
    AddCommissionComponent,
    AddSubtypeComponent,
    AddDuitLebihComponent,
    AddedDuitLebihListComponent,
    DuitLebihReportComponent,
    AddSimpanComponent,
    WithdrawSimpanComponent,
    BatchPaymentComponent,
    AddPaymentModalComponent,
    ImportNewCompanyComponent,
    ImportExcelComponent,
    CustomerTransactionsComponent,
    FormJComponent,
    FormJScheduleComponent,
    FormJHistoryComponent,
    GenerateFormjComponent,
    PaymentVoucherComponent,
    FormjReceiptLetterComponent,
    UpdateProfileInfoComponent,
    AddProfileComponent,
    CustomerBdHistoryComponent,
    CashBalanceReportComponent,
    CustomerLeadListComponent,
    CustomerAdminRemarkComponent,
    AddAdminRemarkComponent,
    ViewCustomerRemarkComponent,
    DeleteReasonRemarkComponent,
    MidMonthReportComponent,
    UsageReportComponent,
    TransferSimpanComponent,
    DailyReportComponent,
    FormJReportComponent,
    CompanySummaryReportComponent,
    VerifyAccountComponent,
    StockComponentComponent,
    CheckInHistoryComponent,
    IdleTimeoutComponent,
    ChangeLoanStatusComponent,
    MergeProfileComponent,
    ExpenseSimpanComponent,
    NoscrollDirective,
    AddTotingComponent,
    DeleteReasonComponent,
    AddOtherTransactionsMenuComponent,
    AddMultipleTransactionComponent,
    MaintenanceModeToggleComponent,
    DueEpfListComponent,
    ShowWhatsappMessageClipboardComponent,
    LoanRequestListComponent,
    AddApprovedLoanComponent,
    LoanRequestDetailsComponent,
    AllLoanRequestCardComponent,
    ReviewLoanRequestComponent,
    UsageReportCardComponent,
    NextTaskComponent,
    PreviousApprovedRequestCardComponent,
    MarkLegacyEpfsComponent,
    EpfRequestCalculatorComponent,
    CustomerLoanRequestHistoryComponent,
    EditEpfDetailsComponent,
    EditLoanRequestInfoComponent,
    OverlapComponentComponent,
    CustomerRecordReportComponent,
    AddLoanRequestGuarantorComponent,
    ServerStatusPageComponent,
    OverdueEpfReportComponent,
    AuthorizeLoanRequestComponent,
    LegacyEpfSettlementComponent,
    EpfLimitInformationComponent,
    GuarantorFormComponent,
    ViewContactThumbnailsComponent,
    AddHutangRequestComponent,
    ElevateConfirmationComponent,
    AddReceiptSetComponent,
    ReceiptSetsListComponent,
    ReceiptDetailsComponent,
    ViewThumbnailsComponent,
    MoveLoanComponent,
    ShowUnprocessedSetTransactionComponent,
    EditLoanRequestRemarkComponent,
    VerifyCustomerComponent,
    CheckCustomerNecessaryFilesComponent,
    AlbumComponentComponent,
    DeployWebappComponent,
    ManageCompanyAccessComponent,
    CompareSettlementInfoListComponent,
    SharedAddRemarkComponent,
    ReceiptSetsListviewComponent,
    ReceiptAddExpenseAdminRemarkComponent,
    ManageTransactionSubtypeComponent,
    ScrollToTopComponent,
    ViewCpDetailsComponent,
    CpCustomerListComponent,
    CpOverviewComponent,
    CpPaymentSheetComponent,
    CompareCpPaymentsComponent,
    AddTransactionRequestComponent,
    AssignedTransactionCardComponent,
    AddAssignedTransactionIntoAccountComponent,
    ConflictedCustomerListComponent,
    ViewEpfChangeHistoryComponent,
    IcChangeHistoryComponent,
    SelectAllComponent,
    LoanRequestApprovalComponent,
    AddPulledCustomerRecordComponent,
    AddBlacklistCustomerComponent,
    BlacklistCustomerListComponent,
    ValidateEpfStatementComponent,

    SharedLoanComponent,
    AddLoanRequestDetailsComponent,
    SharedLoanAgreementComponent,
    AddReserveGroupComponent,
    ViewReserveGroupsComponent,
    SearchCompanyByUidComponent,
    SharedLoanRequestCardComponent,

    SearchTicketComponent,

    ShareProfileToCompanyComponent,

    SharedLoanListComponent,
    BookmarkComponent,
    DraftCalculatorComponent,
    EpfSettlementBreakdownLetterComponent

  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    IgxCalendarModule,
    IgxListModule,
    IgxAvatarModule,
    IgxIconModule,
    IgxDatePickerModule,
    IgxGridModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    TextFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatTooltipModule,
    NgxPanZoomModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatSnackBarModule,
    ClipboardModule,
    MatRadioModule,
    AgGridModule,
    NgIdleKeepaliveModule.forRoot(),
    BookmarkItemComponent
  ],
  exports: [EpfRequestCalculatorComponent] ,
  providers: [AuthService, CookieService, AuthGuard, NgbActiveModal, RestApiService, APIAddress, DatePipe, {provide: MAT_DATE_LOCALE, useValue: 'en-GB'}],
  bootstrap: [AppComponent]
})
export class AppModule {
}
