import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Customer } from 'src/model/customer.model';
import { AddLoanComponent } from '../../AddThings/add-loan/add-loan.component';
import { UpdateCustomerComponent } from '../update-customer/update-customer.component';
import { RestApiService } from 'src/app/API/restapi';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { Enums } from 'src/app/Shared/enums';
import { ReserveHistoryComponent } from 'src/app/Reserve/reserve-history/reserve-history.component';
import { AlertDialogComponent } from 'src/app/Shared/alert-dialog/alert-dialog.component';
import { Commission } from 'src/model/commission.model';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { CustomerTransactionsComponent } from '../customer-transactions/customer-transactions.component';
import { Profile } from 'src/model/profile.model';
import { DatePipe, Location } from '@angular/common';
import { CustomerBdHistoryComponent } from '../customer-bd-history/customer-bd-history.component';
import { AddProfileComponent } from 'src/app/AddThings/add-profile/add-profile.component';
import { MergeProfileComponent } from '../merge-profile/merge-profile.component';
import { LoanRequest } from 'src/model/loanRequest.model';
import { Loan } from 'src/model/loan.model';
import { Contact } from 'src/model/contact.model';
import { LoanRequestListComponent } from 'src/app/LoanRequest/loan-request-list/loan-request-list.component';
import { EPF } from 'src/model/epf.model';
import { DuitLebih } from 'src/model/duitLebih.model';
import { ViewEpfChangeHistoryComponent } from '../view-epf-change-history/view-epf-change-history.component';
import { IcChangeHistoryComponent } from '../ic-change-history/ic-change-history.component';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { SettlementCharges } from 'src/model/settlementCharges.model';
import { AddPulledCustomerRecordComponent } from 'src/app/AddThings/add-pulled-customer-record/add-pulled-customer-record.component';
import { ShareProfileToCompanyComponent } from '../share-profile-to-company/share-profile-to-company.component';

@Component({
  selector: 'app-manage-customer',
  templateUrl: './manage-customer.component.html',
  styleUrls: ['./manage-customer.component.css']
})
export class ManageCustomerComponent implements OnInit {


  viewCustomer: Customer;
  @Input() allowUpdateTask: boolean = true;
  @Input() selectedCustomerID: number;
  viewCustCode: number;
  customerEpfDetails: EPF;

  commissionList: Commission[] = new Array;
  routeSubscription: any;
  selectedProfile: Profile;
  selectedProfileID: number;

  loanRequestList: LoanRequest[] = new Array;
  sharedProfiles: Profile[] = new Array;


  constructor(public activatedRoute: ActivatedRoute, public router: Router, private restapi: RestApiService,
    private constructApi: ConstructAPI, public enums: Enums, public sharedService: SharedService, private location: Location, private cdr: ChangeDetectorRef, private datepipe: DatePipe) {
    this.routeSubscription = this.activatedRoute.paramMap.subscribe(params => {
      this.selectedCustomerID = +params.get('customerId');
      if (params.get('profileId') != undefined)
        this.selectedProfileID = +params.get('profileId');
      else
        this.selectedProfileID = undefined

      //reset epf details every time
      this.customerEpfDetails = undefined;
      this.getCustomerInfos();

    })
  }


  ngOnInit() {
  }

  openAddLoanModal() {

    const modalRef = this.sharedService.openModal(AddLoanComponent, "largeModal");
    modalRef.componentInstance.selectedCustomerId = this.viewCustomer.ID;
    modalRef.componentInstance.selectedCustomerName = this.viewCustomer.Name;
  }


  getCustomerStatus(statusCode: number) {
    return this.enums.customerStatusString[statusCode - 1];
  }
  getStatusColor(status: number) {

    var color: string;

    switch (status) {
      case this.enums.ACTIVE_CUST:
        color = "blue";
        break;
      case this.enums.WARNING_CUST:
        color = "orange";
        break;
      case this.enums.BAD_DEBT_CUST:
        color = "black";
        break;
      case this.enums.CP_CUST:
        color = "#17a2b8";
        break;
      case this.enums.LBD_CUST:
        color = "darkgray";
        break;
      default:
        color = "green";
        break;
    }

    return color;
  }

  getStatusPanelColor(statusCode: number) {
    switch (statusCode) {
      case this.enums.ACTIVE_CUST:
        return "activePanel";
      case this.enums.BAD_DEBT_CUST:
        return "badDebtPanel";
      case this.enums.WARNING_CUST:
        return "warningPanel";
      case this.enums.CP_CUST:
        return "cpPanel";
      case this.enums.LBD_CUST:
        return "legalPanel";
      default:
        return "activePanel";
    }

  }

  openAddProfileModal() {

    const modalRef = this.sharedService.openModal(AddProfileComponent, "largeModal");
    modalRef.componentInstance.addProfileCustomerId = this.viewCustomer.ID;

    if (modalRef.componentInstance.passEntry != undefined) {
      modalRef.componentInstance.passEntry.subscribe((entry) => {
        var profile: Profile = entry;
        this.viewCustomer.Profiles.push(profile);
      });

      modalRef.result.then(() => {
      })
    }

  }

  openEditProfileModal() {

    const modalRef = this.sharedService.openModal(UpdateCustomerComponent);
    modalRef.componentInstance.customerToUpdate = this.viewCustomer;
    modalRef.componentInstance.epfDetails = this.customerEpfDetails;

    if (modalRef.componentInstance.passEntry != undefined) {
      modalRef.componentInstance.passEntry.subscribe((entry) => {
        if (entry == true)
          this.getCustomerInfos();
      });

      modalRef.result.then(() => {
      })
    }

  }
  openCustomerTransactionModal() {
    const modalRef = this.sharedService.openModal(CustomerTransactionsComponent, "largeModal");
    modalRef.componentInstance.profileId = this.selectedProfile.ID;
    modalRef.componentInstance.companyId = this.viewCustomer.CompanyId;
  }

  openReserveHistory() {
    const modalRef = this.sharedService.openModal(ReserveHistoryComponent, "largeModal");
    modalRef.componentInstance.selectedProfileID = this.selectedProfileID;
    modalRef.componentInstance.selectedProfile = this.selectedProfile;

    if (this.selectedProfile.SharedProfileId != undefined)
      modalRef.componentInstance.sharedProfileId = this.selectedProfile.SharedProfileId;

    var index = this.viewCustomer.Profiles.findIndex(profile => Number(profile.ID) == Number(this.selectedProfileID));
    modalRef.componentInstance.totalReserveAmount = this.viewCustomer.Profiles[index].Reserve;
    modalRef.componentInstance.viewCustomer = this.viewCustomer;

    modalRef.componentInstance.profileList = this.viewCustomer.Profiles;

    if (modalRef.componentInstance.passEntry != undefined) {
      modalRef.componentInstance.passEntry.subscribe((amount) => {
        var addedSimpan = amount;

        //append added account to account list
        this.viewCustomer.Reserve += addedSimpan;
      });

      modalRef.result.then(() => {
      })
    }

  }

  openBadDebtHistory() {
    const modalRef = this.sharedService.openModal(CustomerBdHistoryComponent, "largeModal");
    modalRef.componentInstance.selectedProfileID = this.selectedProfileID;
    modalRef.componentInstance.selectedCustomerName = this.viewCustomer.Name;
  }

  openLoanRequestList() {
    const modalRef = this.sharedService.openModal(LoanRequestListComponent, "largeModal");
    modalRef.componentInstance.loanRequestList = this.loanRequestList;
  }


  isStaff() {
    var roleId = Number(sessionStorage.getItem('roleId'));

    if (roleId == this.enums.STAFF)
      return true;
    else
      return false;
  }



  getCustomerInfos() {
    // this.viewCustomer == undefined;
    var xhttp = this.restapi.getRequest(this.constructApi.getCustomerDetails(this.selectedCustomerID));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var json = JSON.parse((xhttp.responseText));
        var reserveAmount = (json.reserve == undefined) ? 0 : json.reserve;
        var verified = (json.verifiedOn == undefined) ? false : true;

        this.viewCustomer = new Customer(json.id, json.name, json.occupation,
          json.salary, json.salaryDay, json.icNumber, json.contactNo, json.remark, json.ctosNumber, json.ctosStatus,
          json.status, json.dateCreated, json.handler, json.companyId, json.customerCode, json.companyCode, reserveAmount, json.badDebt, json.address, undefined,
          json.lastFormJDate, undefined, json.epfDate, json.address2, verified, json.dob, json.verifiedBy, json.otherId, json.race, json.gender, json.verifiedOn);
        this.viewCustCode = this.viewCustomer.ID;


        if (this.viewCustomer.EPFDate != undefined)
          this.getCustomerEPF();
        this.getProfileInfo(json.profileIds);
      }
    };

  }

  getCustomerEPF() {

    var xhttp = this.restapi.getRequest(this.constructApi.getCustomerEPF(this.selectedCustomerID));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var json = JSON.parse((xhttp.responseText));
        this.customerEpfDetails = new EPF(json.amount, json.customerId, json.date, json.hasIAccount, json.iAccountPassword, json.iAccountUsername, json.remark);
      }
    };
  }

  getProfileLoanRequest(profileId: number, withLoan: boolean) {
    this.loanRequestList = [];
    var xhttp = this.restapi.getRequest(this.constructApi.getProfileLoanApprovalRequest(profileId, undefined, withLoan));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));


        jsonArray.forEach(json => {

          var loanInfoJson = json.loanInfo

          var stampAmount = (loanInfoJson.stamp == undefined) ? 0 : loanInfoJson.stamp;
          var serviceChargeAmount = (loanInfoJson.serviceCharge == undefined) ? 0 : loanInfoJson.serviceCharge;
          var reserve = (loanInfoJson.reserve == undefined) ? 0 : loanInfoJson.reserve;

          var extras = (loanInfoJson.extras == undefined) ? undefined : this.processDuitLebihJsonArr(loanInfoJson.extras);

          var settleStamp = (loanInfoJson.settlementInfo == undefined || loanInfoJson.settlementInfo.stamp == undefined) ? 0 : loanInfoJson.settlementInfo.stamp;
          var settleSc = (loanInfoJson.settlementInfo == undefined || loanInfoJson.settlementInfo.serviceCharge == undefined) ? 0 : loanInfoJson.settlementInfo.serviceCharge;
          var settleExtra = (loanInfoJson.settlementInfo == undefined || loanInfoJson.settlementInfo.extras == undefined) ? 0 : loanInfoJson.settlementInfo.extras;


          var settlementCharges = (loanInfoJson.settlementCharges == undefined) ? undefined : new SettlementCharges(loanInfoJson.settlementCharges.serviceCharge, loanInfoJson.settlementCharges.stamp, loanInfoJson.settlementCharges.extras, loanInfoJson.settlementCharges.handlingCharge);


          var loanInfo = new Loan(undefined, loanInfoJson.profileId, loanInfoJson.principal, stampAmount,
            undefined, undefined, undefined, undefined, undefined, loanInfoJson.scheme, loanInfoJson.interestRate,
            loanInfoJson.term, undefined, undefined, undefined, undefined, undefined, undefined, undefined,
            undefined, reserve, undefined, undefined, undefined, serviceChargeAmount, extras, undefined, undefined,
            undefined, loanInfoJson.processingRate, undefined, undefined, undefined,
            settleStamp, settleSc, settleExtra, undefined, undefined, undefined, undefined, undefined, undefined, settlementCharges);



          if ( Array(json.guarantors).length > 0 )
            var guarantorsInfo = this.processGuarantorJsonArr(json.guarantors);
          var loanRequest = new LoanRequest(json.customerId, json.epfAmount, json.epfDate, guarantorsInfo, json.hasIAccount,
            json.id, loanInfo, json.processedRemark, json.profileId, json.remark, json.requestBy, json.requestOn,
            json.updatedOn, json.approved, json.loanId, json.companyCode, json.icNumber, json.name, json.companyId, json.processedBy, json.processedOn,
            undefined, undefined, undefined, undefined, undefined, json.dob, json.otherId, undefined, undefined, undefined, this.selectedProfile.SharedProfileId, json.loanGroupId)

          //if manager only get approved and pending request
          if (this.sharedService.isBranchAccountHandler()) {
            if (loanRequest.Approved == undefined || loanRequest.Approved == true)
              this.loanRequestList.push(loanRequest);

          }
          //if admin / boss account can get all
          else
            this.loanRequestList.push(loanRequest);

          this.cdr.detectChanges();

        }
        );

      }
    };
  }

  processGuarantorJsonArr(jsonArray: any) {
    var guarantorsInfo: Contact[] = new Array;
    jsonArray.forEach(json => {
      var guarantors = new Contact(json.id, json.name, json.icNumber, json.number, json.address, json.customerId, json.relation, json.remark)
      guarantorsInfo.push(guarantors);
    });

    return guarantorsInfo;
  }

  processDuitLebihJsonArr(jsonArray: any) {
    var duitLebihList: DuitLebih[] = new Array;
    jsonArray.forEach(json => {
      var extra = new DuitLebih(json.amount, json.subtype)
      duitLebihList.push(extra);
    });

    return duitLebihList;
  }

  getProfileInfo(profileIds: Number[]) {
    this.viewCustomer.Profiles = new Array;
    var xhttp = this.restapi.getRequest(this.constructApi.getAllProfiles(profileIds));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          var viewProfile = new Profile(json.id, json.occupation, json.salary, json.salaryDay,
            json.remark, json.status, json.dateCreated, json.customerID, json.customerCode, json.reserve, json.badDebt, json.customerName, json.sharedProfileId)

          this.viewCustomer.Profiles.push(viewProfile);
        }
        );

        if (this.selectedProfileID != undefined)
          this.checkSelectedProfile();
        else {
          this.selectedProfile = this.viewCustomer.Profiles[0];
          this.selectedProfileID = this.selectedProfile.ID;
          this.getSelectedProfileDetails(this.selectedProfile.ID)
        }



      }
    };
  }




  getSelectedProfileDetails(id: number) {
    var xhttp = this.restapi.getRequest(this.constructApi.getProfile(id));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var json = JSON.parse((xhttp.responseText));
        var reserveAmount = (json.reserve == undefined) ? 0 : json.reserve;
        this.selectedProfile.Reserve = reserveAmount;
        this.selectedProfile.BadDebt = json.badDebt;


        if (this.sharedService.isBranchAccountHandler() == true || this.sharedService.isStaff())
          this.getProfileLoanRequest(id, false);
        else if (this.sharedService.isAdmin() || this.sharedService.isBossAccount() || this.sharedService.isFinance())
          this.getProfileLoanRequest(id, undefined)


      }
    };
  }

  checkSelectedProfile() {
    var index = this.viewCustomer.Profiles.findIndex(profile => Number(profile.ID) === Number(this.selectedProfileID));
    this.selectedProfile = this.viewCustomer.Profiles[index];
    this.getSelectedProfileDetails(this.selectedProfile.ID);

    //construct activated route url -> append selctedProfileID behind
    var urls = window.location.pathname.split("/")
    if (window.location.pathname.includes('manageCustomer'))
      this.location.replaceState(urls[1] + "/" + urls[2] + "/" + this.selectedProfileID);
    else
      this.location.replaceState(urls[1] + "/" + urls[2] + "/" + urls[3] + "/" + this.selectedProfileID);
  }

  openAlert(message: string) {
    const modalRef = this.sharedService.openModal(AlertDialogComponent);
    modalRef.componentInstance.message = message;
  }

  openMergeProfileModal() {
    var modalRef = this.sharedService.openModal(MergeProfileComponent, 'largeModal');
    modalRef.componentInstance.profileList = this.viewCustomer.Profiles;
    modalRef.componentInstance.selectedProfile = this.selectedProfile;
    modalRef.componentInstance.selectedCustomer = this.viewCustomer;

  }


  openShareProfileModal() {
    var modalRef = this.sharedService.openModal(ShareProfileToCompanyComponent, 'largeModal');
    modalRef.componentInstance.currentProfile = this.selectedProfile;
  }

  confirmBeforeUnshare() {
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Are you sure you want to UNSHARE this profile ?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something
      if (confirmation == true) {
        this.unshareProfile();
      }
    });
  }


  confirmBeforeMarkAsMain() {
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Are you sure you want to MARK this profile AS MAIN SHARED PROFILE?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something
      if (confirmation == true) {
        this.markProfileAsShare();
      }
    });
  }


  markProfileAsShare() {

    var xhr = this.restapi.putRequest(this.constructApi.getMarkProfileAsMainShared(Number(this.viewCustomer.ID), Number(this.selectedProfile.ID)));
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        if (xhr.status == 200) {
          //if success emit
          // this.passEntry.emit();
          window.location.reload();
          this.sharedService.openAlert("Successfully mark as MAIN profile!", this.enums.SUCCESS_ALERT)
        }
        else {
          this.sharedService.openErrorMessage(xhr);
        }

      }

    }
  }
  unshareProfile() {

    var xhr = this.restapi.patchRequest(this.constructApi.getUnshareProfile(Number(this.selectedProfile.ID)));
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        if (xhr.status == 200) {
          //if success emit
          // this.passEntry.emit();
          window.location.reload();
          this.sharedService.openAlert("Successfully unshared profile!", this.enums.SUCCESS_ALERT)
        }
        else {
          this.sharedService.openErrorMessage(xhr);
        }

      }

    }
  }

  viewCustomerEPFHistory(viewCustomer: Customer) {

    var modalRef = this.sharedService.openModal(ViewEpfChangeHistoryComponent, 'largeModal');
    modalRef.componentInstance.customerId = viewCustomer.ID;

  }

  viewCustomerICHistory(viewCustomer: Customer) {

    var modalRef = this.sharedService.openModal(IcChangeHistoryComponent, 'regularModal');
    modalRef.componentInstance.customerId = viewCustomer.ID;

  }

  confirmBeforeVerifyCustomer(isVerify: boolean, customerID: number) {
    var errorMessage = "";
    if (this.customerEpfDetails.Date != undefined && isVerify == true) {
      var isDOBValidWithIC = this.sharedService.isDOBValidWithIC(this.viewCustomer.ICNumber, new Date(this.viewCustomer.DOB));
      var isEPFDateValid = this.sharedService.isEPFDateValid(new Date(this.viewCustomer.DOB), new Date(this.customerEpfDetails.Date));

      if (isDOBValidWithIC == false)
        errorMessage += "Date Of Birth (" + this.datepipe.transform(this.viewCustomer.DOB, "dd-MM-yyyy") + ") not same with IC (" + this.sharedService.processICNum(this.viewCustomer.ICNumber) + ") \n\n";

      if (isEPFDateValid == false) {
        var calculatedEPFDate = new Date(this.sharedService.calculateEPFDate(new Date(this.viewCustomer.DOB)));
        errorMessage += "Calculated EPF Date (" + this.datepipe.transform(calculatedEPFDate, "dd-MM-yyyy") + ") not same with User defined EPF Date (" + this.datepipe.transform(this.customerEpfDetails.Date, "dd-MM-yyyy") + ")  \n\n";
      }

    }

    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    var verifyMessage = (isVerify == true) ? "VERIFY" : "UNVERIFY";
    modalRef.componentInstance.message = (errorMessage == "") ? "Confirm to " + verifyMessage + " this customer?" : "There are some error(s):\n" + errorMessage + "Confirm to " + verifyMessage + " this customer?";
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something\

      if (confirmation == true) {
        if (isVerify == true) {
          this.verifyCutsomer(customerID);
        }
        else {
          this.unverifyCutsomer(customerID);
        }
      }
    });

  }


  verifyCutsomer(customerID: number) {

    var xhr = this.restapi.postRequest(this.constructApi.getVerifyCustomer(customerID));
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        // var json = JSON.parse(xhr.responseText);

        if (xhr.status == 200) {
          this.viewCustomer.Verified = true;
          this.viewCustomer.VerifiedBy = sessionStorage.getItem("username");
          this.viewCustomer.VerifiedOn = new Date();
        } else
          this.sharedService.openErrorMessage(xhr);

      }
    }
  }


  unverifyCutsomer(customerID: number) {

    var xhr = this.restapi.postRequest(this.constructApi.getUnverifyCustomer(customerID));
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        // var json = JSON.parse(xhr.responseText);

        if (xhr.status == 200) {
          this.viewCustomer.Verified = false;
        } else
          this.sharedService.openErrorMessage(xhr);

      }
    }
  }

  openPullCustomerModal() {
    var modalRef = this.sharedService.openModal(AddPulledCustomerRecordComponent, 'largeModal');
    modalRef.componentInstance.selectedCustomer = this.viewCustomer;
  }
}
