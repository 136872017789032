<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Manage Transaction Subtype </h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 aria-label="Close" aria-label="Close"
            (click)="closeModal()"></button>
    </div>

    <div class="modal-body">
        <table class="table table-bordered">
            <tbody>
                <tr>
                    <th>Date</th>
                    <td>{{transaction.Date |date:'dd-MM-yyyy'}}</td>
                </tr>
                <tr>
                    <th>Remark</th>
                    <td>{{transaction.Remark}}</td>
                </tr>
                <tr>
                    <th>Amount (RM)</th>
                    <td>{{transaction.Amount | number:'1.0-2'}}</td>
                </tr>
            </tbody>
        </table>
        <hr />
        <p class="fw-bold">Subtype</p>
        <select class="browser-default form-select" name="currentSubtype" [(ngModel)]="currentSubtype">
            <option *ngFor="let subtype of subtypeList" [value]="subtype.ID">
                {{subtype.Name}}</option>
        </select>
    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="submit()"
            [disabled]="disabledSubmit">Submit</button>
        <button class="btn btn-outline-danger" type="button" (click)="closeModal()">Cancel</button>
    </div>

</form>