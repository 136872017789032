import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Contact } from 'src/model/contact.model';
import { FileInterface } from 'src/model/file.interface';
import { LoanRequest } from 'src/model/loanRequest.model';


@Component({
  selector: 'app-view-contact-thumbnails',
  templateUrl: './view-contact-thumbnails.component.html',
  styleUrls: ['./view-contact-thumbnails.component.css']
})
export class ViewContactThumbnailsComponent implements OnInit {


  @Input() request: LoanRequest;
  @Input() contact: Contact;

  imageArr: FileInterface[] = new Array;
  fileArr: FileInterface[] = new Array;
  constructor(private activeModal: NgbActiveModal, public sharedService: SharedService, public enums: Enums, private restApi: RestApiService, private constructApi: ConstructAPI) { }

  ngOnInit(): void {
    this.getContactFile();
  }


  reset() {
    this.activeModal.close();
  }


  private getContactFile() {

    var xhttp = this.restApi.getRequest(this.constructApi.getAllContactPhotos(this.request.CustomerId, this.contact.ID));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          var lastModifiedDate = (json["Last-Modified"] == undefined) ? json["Last-Modified"] : new Date(json["Last-Modified"]);
          let file = { label: json.Name, url: json.Data, contentType: json["Content-Type"], lastModified: lastModifiedDate, uploadedBy: json.uploadedBy }

          if (file.contentType.includes("image/") == false) {
            this.fileArr.push(file)
          }
          else
            this.imageArr.push(file)

        }
        );

      }
    };

  }



  downloadFile(filename: string, customerId: number) {
    var encoded = encodeURIComponent(filename);
    var xhr = this.restApi.getRequest(this.constructApi.getFullFile(customerId, encoded));
    xhr.responseType = "blob";
    xhr.onreadystatechange = () => {

      if (xhr.readyState === 4) {
        if (xhr.status == 200) {
          this.sharedService.generateDownloadLink(xhr, filename);
        }
        else {
          this.sharedService.openAlert("Some error occurred. Please try again later", this.enums.DANGER_ALERT);
        }
      }

    };
  }




  previewFile(customerId: number, filename: string) {
    // var modalRef = this.sharedService.openModal(PreviewPdfComponent, "largeModal");
    // modalRef.componentInstance.url = this.restApi.apiUrl + this.constructApi.getFullFile(customerId, filename);
    // modalRef.componentInstance.filename = filename;
    var encoded = encodeURIComponent(filename);
    window.open(this.restApi.apiUrl + this.constructApi.getFullFile(customerId, encoded));
  }

}
