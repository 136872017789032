import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Company } from 'src/model/company.model';
import { User } from 'src/model/user.model';
import { Customer } from 'src/model/customer.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RestApiService } from 'src/app/API/restapi';
import { APIAddress } from 'src/app/API/apiAddresses';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { Enums } from 'src/app/Shared/enums';
import { Account } from 'src/model/account.model';
import { SharedService } from 'src/app/Shared/shared-service.service';
@Component({
  selector: 'app-add-account',
  templateUrl: './add-account.component.html',
  styleUrls: ['./add-account.component.css']
})
export class AddAccountComponent implements OnInit {
  selectedCompany: string = "COMPANY";
  selectedCompanyID: number;


  selectedUsername: string = "";

  @Input() accountNo: string;
  @Input() holderName: string;
  @Input() accountName: string;
  @Input() initialAmount: number = 0;
  @Input() initialAccountDate: Date;
  @Input() accountId: number;
  @Input() isCashAccount = false;

  companyList: Company[] = new Array;
  userList: User[] = new Array;


  accountNoNull = false;
  holderNameNull = false;
  accountNameNull = false;
  selectedCompanyNull = false;
  selectedUsernameNull = false;

  disabledSubmit: boolean = false;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private restapi: RestApiService,
    private constructApiAddress: ConstructAPI, private enums: Enums, public sharedService: SharedService) { }

  ngOnInit(): void {

    var isStaff = this.checkIsStaff();

    // if (isStaff) {

    //   //push STAFF COMPANY
    //   var companyId = Number(sessionStorage.getItem('companyId'));
    //   var companyName = sessionStorage.getItem('companyName');

    //   this.companyList.push(new Company(companyId, companyName, null, null, null));

    //   this.selectedCompanyID = companyId;

    //   //push STAFF USERNAME
    //   var username = sessionStorage.getItem('username');
    //   this.userList.push(new User(null, companyId, companyName, null, null, username, null));
    //   this.selectedUsername = username;

    // }
    // else {
    var companyId = Number(sessionStorage.getItem('companyId'));

    if (this.isBranchManager())
      this.selectedCompanyID = companyId;
    else
      this.getAllCompanies();
    // this.getAllUsersInCompany(companyId);
    // }
  }



  private getAllCompanies() {

    // var xhttp = new XMLHttpRequest();
    // var url = "http://localhost:10080/api/v1/companies";
    // xhttp.withCredentials = true;

    var xhttp = this.restapi.getRequest(this.constructApiAddress.getAllCompanies());

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // Typical action to be performed when the document is ready:

        var jsonArray = JSON.parse((xhttp.responseText));
        // console.log(jsonArray);

        jsonArray.forEach(json => {

          // create company instance with all response data
          let company = new Company(json.id, json.name, json.address, json.companyCode, json.contactNo);


          //add company instance into company list
          this.companyList.push(company);
        }
        );

      }
    };
    // xhttp.open("GET", url, true);
    // xhttp.send();

  }

  private getAllUsersInCompany(companyId: number) {

    // var xhttp = new XMLHttpRequest();
    // var url = "http://localhost:10080/api/v1/users?company=" + companyId;
    // xhttp.withCredentials = true;

    var xhttp = this.restapi.getRequest(this.constructApiAddress.getUsersInCompany(companyId));

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let user = new User(json.id, json.companyid, json.companyname, json.roleid, json.rolename, json.username, json.password);
          this.userList.push(user);
        }
        );

      }
    };
    // xhttp.open("GET", url, true);
    // xhttp.send();

  }

  checkIsStaff() {
    var roleID = Number(sessionStorage.getItem('roleId'));
    if (roleID === this.enums.STAFF)
      return true
    else
      return false
  }


  setSelectedCompany(companyID: number) {
    if (companyID === this.selectedCompanyID)
      return true
    else
      return false;
  }

  setSelectedUser(username: string) {

    if (username === this.selectedUsername)
      return true
    else
      return false;
  }

  validField(field: string) {
    switch (field) {

      case 'accountNo': this.accountNoNull = (this.accountNo === ''); break;
      case 'holderName': this.holderNameNull = (this.holderName === ''); break;
      case 'accountName': this.accountNameNull = (this.accountName === ''); break;
      case 'selectedCompany': this.selectedCompanyNull = (this.selectedCompany === ''); break;
      case 'selectedUsername': this.selectedUsernameNull = (this.selectedUsername === ''); break;
    }
  }

  fieldIncomplete() {

    this.accountNoNull = ((this.accountNo == undefined || this.accountNo === ''));
    this.holderNameNull = (this.holderName == undefined || this.holderName === '');
    this.accountNameNull = (this.accountName == undefined || this.accountName === '');
    this.selectedCompanyNull = (!this.selectedCompany || this.selectedCompany === '');
    this.selectedUsernameNull = (!this.selectedUsername || this.selectedUsername === '');
    var amountInvalid = (isNaN(+this.initialAmount))
    var initialAmountNull = (this.initialAmount == undefined);
    var initialAccountDateNull = (this.initialAccountDate == undefined)

    if (this.accountId == undefined)
      return (this.accountNoNull || this.holderNameNull || this.accountNameNull ||
        this.selectedCompanyNull || initialAmountNull || initialAccountDateNull || amountInvalid);

    else {
      if (this.isCashAccount == true)

        return (this.accountNameNull);
      else
        return (this.accountNoNull || this.holderNameNull || this.accountNameNull);
    }


  }

  addNewAccount() {
    // var xhr = new XMLHttpRequest();
    // var url = "http://localhost:10080/api/v1/account";
    // xhr.open("POST", url, true);
    // xhr.setRequestHeader("Content-type", "application/json");
    // xhr.withCredentials = true;
    this.disabledSubmit = true;

    if (this.initialAccountDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var reduceASecDate = new Date(Number(this.initialAccountDate) - 1);

    var initialCashBookDateStr = (this.initialAccountDate == undefined) ? undefined : reduceASecDate.toISOString();

    var data = {
      "number": this.accountNo,
      "name": this.accountName,
      "companyId": Number(this.selectedCompanyID),
      "holderName": this.holderName,
      "initialAccountBalance": Number(this.initialAmount),
      "initialAccountDate": initialCashBookDateStr,
      "username": this.selectedUsername
    };

    var xhr = (this.accountId == undefined)
      ? this.restapi.postRequest(this.constructApiAddress.getAddAcccount(), data)
      : this.restapi.putRequest(this.constructApiAddress.getAccount(this.accountId), data)
    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        if (xhr.status == 200) {

          this.disabledSubmit = false;
          var json = JSON.parse(xhr.responseText);

          var account = new Account(json.id, json.number, json.name, json.holderName, this.initialAmount, json.companyId, null);
          this.reset();

          if (this.accountId == undefined) {
            this.passEntry.emit(account);
            this.sharedService.openAlert("Successfully added account!", this.enums.SUCCESS_ALERT);
          }
          else {
            window.location.reload();
          }


        } else {
          this.disabledSubmit = false;
          this.sharedService.openAlert("Some error occurs, please try again later", this.enums.DANGER_ALERT);
        }
      }

    }

  }


  isBranchManager() {

    var role = Number(sessionStorage.getItem('roleId'));
    if (role == this.enums.BRANCH_MANAGER)
      return true;

    else return false;

  }

  submit() { this.fieldIncomplete() ? this.sharedService.openAlert('Please fill in all the fields!', this.enums.DANGER_ALERT) : this.addNewAccount(); }

  reset() {
    this.activeModal.close();
    this.accountNo = "";
    this.accountName = "";
    this.holderName = "";
    this.companyList.splice(0, this.companyList.length);
    this.userList.splice(0, this.userList.length);
    this.selectedCompanyID = 0;
    this.selectedUsername = "";
  }

}
