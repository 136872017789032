<div class="container-fluid p-3">
    <div class="d-flex justify-content-end align-items-end">
        <button class="btn btn-info" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseExample">
            <i class="fas fa-chevron-circle-down" *ngIf="isCollapsed"></i>
            <i class="fas fa-chevron-circle-up" *ngIf="isCollapsed==false"></i>
        </button>
    </div>

    <div class="collapse" id="collapseExample" [ngbCollapse]="isCollapsed">
        <app-report-generator></app-report-generator>
    </div>
    <h2> Cash Balance Report (Up to {{endDate |date:'yyyy-MM-dd'}} )
    </h2>

    <p [hidden]="reportList.length>0"> Do not have any record within this date range</p>

    <div class="group mt-3" [hidden]="reportList.length<=0">
        <input type="number" required class="textInputLong" name="amount" [(ngModel)]="warningAmount" min=0
            (keypress)="sharedService.isNumberKey($event)"  (ngModelChange)="filterWarningCompanies()">
        <span class="highlight"></span>
        <span class="bar barLong"></span>
        <label>Warning Amount</label>
    </div>

    <div class="col-12 mb-2" [hidden]="reportList.length==0">
        <mat-slide-toggle class="mat-slide-toggle-lg" [hideIcon]="true" color="warn" [(ngModel)]="filterWarning" name="filterWarning"
            (ngModelChange)="filterWarningCompanies()">Show Warning Company Only
        </mat-slide-toggle>
    </div>

    <table class="table table-striped" [hidden]="reportList.length==0">
        <thead>
            <tr>
                <th>Company Name</th>
                <th>Latest CheckIn</th>
                <th>CheckIn By</th>
                <th>Cash Balance</th>
                <th>Bank Balance</th>
                <th>Total</th>

            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let report of reportList">
                <td [ngClass]="{'warningCol':(report.bankBalance+ report.cashBalance) > warningAmount}">
                    {{report.companyName}}</td>
                <td [ngClass]="{'warningCol':(report.bankBalance+ report.cashBalance) > warningAmount}">
                    {{report.latestCheckin | date:'dd-MM-yyyy'}}</td>
                <td [ngClass]="{'warningCol':(report.bankBalance+ report.cashBalance) > warningAmount}">
                    {{report.checkinBy}}</td>
                <td [ngClass]="{'warningCol':(report.bankBalance+ report.cashBalance) > warningAmount}">RM
                    {{report.cashBalance | number:'1.2-2'}}</td>
                <td [ngClass]="{'warningCol':(report.bankBalance+ report.cashBalance) > warningAmount}">RM
                    {{report.bankBalance | number:'1.2-2'}}</td>
                <th [ngClass]="{'warningCol':(report.bankBalance+ report.cashBalance) > warningAmount}">RM
                    {{report.bankBalance+ report.cashBalance | number:'1.2-2'}}</th>

            </tr>
        </tbody>
        <tfoot>
            <tr style="border-top: 2px solid black;border-bottom: 2px solid black">
                <th>Count: {{reportList.length}}</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th>{{calculateTotalBalance() |number:'1.2-2'}}</th>
            </tr>
        </tfoot>
    </table>
</div>