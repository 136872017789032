<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Pay with SIMPAN?</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 aria-label="Close" (click)="reset()"></button>
    </div>

    <div class="modal-body">

        <div class="container-fluid" style="padding: 2%;">


            <div style="margin: 2%;">
                <ul class="nav nav-tabs">
                    <li class="nav-item " (click)="setIsPayPrincipal(false)"
                        [hidden]="selectedLoan.Status==enums.SETTLED_LOAN || selectedLoan.Scheme=='HUTANG'">
                        <a class="nav-link " [ngClass]="setActive('Monthly')" role="tab"  data-bs-toggle="tab"
                            href="#tabMonthly">
                            <p>Monthly</p>
                        </a>
                    </li>

                    <li class="nav-item" (click)="setIsPayPrincipal(true)"
                        *ngIf="isSchemeA()|| selectedLoan.Scheme=='HUTANG'"
                        [hidden]="selectedLoan.Status==enums.SETTLED_LOAN">
                        <a class=" nav-link" [ngClass]="setActive('Principal')" role="tab"  data-bs-toggle="tab"
                            href="#tabPrincipal">
                            <p>Principal</p>
                        </a>
                    </li>
                    <li class="nav-item" (click)="changeReserveOption(enums.WITHDRAW_INTEREST_CHARGE)"
                        [hidden]="selectedLoan.Scheme=='HUTANG' && isCP(selectedLoan.Status) == false">
                        <a class=" nav-link" [ngClass]="setActive('InterestCharge')" role="tab"  data-bs-toggle="tab"
                            href="#tabWithdrawInterestChargeAmount">
                            <p><i class="fas fa-exchange-alt"></i> Interest Charge</p>
                        </a>
                    </li>

                </ul>
                <div class="tab-content p-2">

                    <!--tabMonthly-->
                    <div class="tab-pane" [ngClass]="setActive('Monthly')" role="tabpanel" id="tabMonthly">

                        <table style="border:none;">
                            <tr>
                                <div class="row">
                                    <div class="col-7">
                                        <h4 class="fw-bold" style="color: green;">Simpan: RM
                                            {{selectedLoan.Reserve}}
                                        </h4>
                                    </div>
                                    <div class="col-4">
                                        <button class="btn btn-outline-dark" (click)="topUpSimpan()">Top Up</button>
                                    </div>
                                </div>

                            </tr>
                            <tr>
                                <h5 class="fw-bold" style="color: red;">Monthly Amount : RM {{paymentAmount}}
                                </h5>
                            </tr>
                            <tr>
                                <div class="row">
                                    <div class="col  pe-0">
                                        <!-- <h5 class="fw-bold" style="color: purple;">Stamp Amount: RM
                                            {{stampAmount}}</h5> -->
                                        <h5 class="fw-bold" style="color: purple;">Stamp Amount: RM </h5>
                                    </div>
                                    <div class="col p-0 m-0">
                                        <input type="number" class="stampSCInput p-0 m-0" required name="stampAmount"
                                            [(ngModel)]="stampAmount" min=0 placeholder="Stamp"
                                            (keypress)="sharedService.isNumberKey($event)">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col  pe-0">
                                        <!-- <h5 class="fw-bold" style="color: purple;">Service Charge: RM
                                            {{scAmount}}</h5> -->
                                        <h5 class="fw-bold" style="color: purple;">Service Charge: RM </h5>
                                    </div>
                                    <div class="col p-0 m-0">
                                        <input type="number" class="stampSCInput p-0 m-0" required name="scAmount"
                                            [(ngModel)]="scAmount" min=0 placeholder="Service Charge"
                                            (keypress)="sharedService.isNumberKey($event)">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col  pe-0">
                                        <!-- <h5 class="fw-bold" style="color: purple;">Service Charge: RM
                                            {{scAmount}}</h5> -->
                                        <h5 class="fw-bold" style="color: purple;">Duit Lebih: RM </h5>
                                    </div>
                                    <div class="col p-0 m-0">
                                        <input type="number" class="stampSCInput p-0 m-0" required
                                            name="duitLebihAmount" [(ngModel)]="duitLebihAmount" min=0
                                            placeholder="Duit Lebih" (keypress)="sharedService.isNumberKey($event)">
                                    </div>
                                </div>


                            </tr>
                            <tr style="border-top: 2px solid black;border-bottom:2px solid black">
                                <h5 class="fw-bold" style="color: blue;">Simpan left:RM
                                    {{calculateReserveLeft(paymentAmount) |number:'1.1-2'}}
                                </h5>
                            </tr>
                        </table>
                    </div>

                    <!--tab principal-->
                    <div class="tab-pane " [ngClass]="setActive('Principal')" role="tabpanel" id="tabPrincipal">

                        <table style="border:none;width: 85%;">
                            <tr>
                                <div class="row">
                                    <div class="col-7">
                                        <h4 class="fw-bold" style="color: green;">Simpan: RM
                                            {{selectedLoan.Reserve}}
                                        </h4>
                                    </div>
                                    <div class="col-4">
                                        <button class="btn btn-outline-dark" (click)="topUpSimpan()">Top Up</button>
                                    </div>
                                </div>

                            </tr>
                            <tr>
                                <!-- <h5 class="fw-bold" style="color: red;">Principal Amount : RM {{paymentAmount}}
                                </h5> -->
                                <div class="row">
                                    <div class="col  pe-0">
                                        <h5 class="fw-bold" style="color: red;">Principal: RM</h5>
                                    </div>
                                    <!-- <div class="col p-0 m-0">
                                        <input type="number" class="principalInput p-0 m-0" required
                                            name="principalAmount" [(ngModel)]="principalAmount" min=0
                                            placeholder="Principal" (keypress)="sharedService.isNumberKey($event)"
                                            (change)="calculateStampAndServiceCharge(principalAmount)">
                                    </div> -->
                                    <div class="col p-0 m-0">
                                        <input type="number" class="principalInput p-0 m-0" required
                                            name="principalAmount" [(ngModel)]="principalAmount" min=0
                                            placeholder="Principal" (keypress)="sharedService.isNumberKey($event)"
                                            (keyup)="checkPrincipalEmpty()">
                                    </div>
                                </div>
                                <div class="mb-1">
                                    <h5 style="display:inline"
                                        (click)="assignPrincipalAndCharges(selectedLoan.MonthlyPayment)"
                                        [hidden]="selectedLoan.Scheme=='HUTANG'">
                                        <span class="badge rounded-pill text-bg-primary badgeAmount">
                                            Monthly: RM {{selectedLoan.MonthlyPayment}}
                                        </span>
                                    </h5>
                                    <h5 style="display:inline"
                                        (click)="assignPrincipalAndCharges(selectedLoan.Balance)">
                                        <span class="badge rounded-pill text-bg-success badgeAmount">
                                            Settle: RM {{selectedLoan.Balance}}
                                        </span>
                                    </h5>
                                </div>

                            </tr>
                            <tr [hidden]="selectedLoan.Scheme=='HUTANG'">
                                <div class="row">
                                    <div class="col  pe-0">
                                        <!-- <h5 class="fw-bold" style="color: purple;">Stamp Amount: RM
                                            {{stampAmount}}</h5> -->
                                        <h5 class="fw-bold" style="color: purple;">Stamp Amount: RM </h5>
                                    </div>
                                    <div class="col p-0 m-0">
                                        <input type="number" class="stampSCInput p-0 m-0" required name="stampAmount"
                                            [(ngModel)]="stampAmount" min=0 placeholder="Stamp"
                                            (keypress)="sharedService.isNumberKey($event)">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col  pe-0">
                                        <!-- <h5 class="fw-bold" style="color: purple;">Service Charge: RM
                                            {{scAmount}}</h5> -->
                                        <h5 class="fw-bold" style="color: purple;">Service Charge: RM </h5>
                                    </div>
                                    <div class="col p-0 m-0">
                                        <input type="number" class="stampSCInput p-0 m-0" required name="scAmount"
                                            [(ngModel)]="scAmount" min=0 placeholder="Service Charge"
                                            (keypress)="sharedService.isNumberKey($event)">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col  pe-0">
                                        <!-- <h5 class="fw-bold" style="color: purple;">Service Charge: RM
                                            {{scAmount}}</h5> -->
                                        <h5 class="fw-bold" style="color: purple;">Duit Lebih: RM </h5>
                                    </div>
                                    <div class="col p-0 m-0">
                                        <input type="number" class="stampSCInput p-0 m-0" required
                                            name="duitLebihAmount" [(ngModel)]="duitLebihAmount" min=0
                                            placeholder="Duit Lebih" (keypress)="sharedService.isNumberKey($event)">
                                    </div>
                                </div>
                            </tr>
                            <tr style="border-top: 2px solid black;border-bottom:2px solid black;">
                                <h5 class="fw-bold" style="color: blue;">Simpan left:RM
                                    {{calculateReserveLeft(principalAmount) |number:'1.1-2'}}
                                </h5>
                            </tr>
                        </table>
                    </div>



                    <!--tab Withdraw Amount-->
                    <div class="tab-pane " [ngClass]="setActive('InterestCharge')" role="tabpanel"
                        id="tabWithdrawInterestChargeAmount">

                        <table style="border:none;">
                            <tr>
                                <div class="row">
                                    <div class="col-7">
                                        <h4 class="fw-bold" style="color: green;">Simpan: RM
                                            {{selectedLoan.Reserve}}
                                        </h4>
                                    </div>
                                    <div class="col-4">
                                        <button class="btn btn-outline-dark" (click)="topUpSimpan()">Top Up</button>
                                    </div>
                                </div>

                            </tr>
                            <tr>
                                <div class="row">
                                    <div class="col  pe-0">
                                        <h5 class="fw-bold">Interest Charge: RM</h5>
                                    </div>
                                    <div class="col p-0 m-0">
                                        <input type="number" class="arrearInput p-0 m-0" required
                                            name="interestChargeAmount" [(ngModel)]="interestChargeAmount" min=0
                                            placeholder="InterestCharge" (keypress)="sharedService.isNumberKey($event)"
                                            (keyup)="checkInterstChargeEmpty()">
                                    </div>
                                </div>
                            </tr>
                            <!--only for non-EPF payment allow to key in stamp and sc when doing extra interest payment-->
                            <!--because all EPF loans only can pay extra interst after customer had settled.-->
                            <!-- all epf loans have a preset value of extra charges during settlement, so when extra interest no longer allow stamp and sc-->
                            <tr *ngIf="selectedLoan.Scheme!='EPF' && selectedLoan.IsEPF==false">
                                <div class="row">
                                    <div class="col  pe-0">
                                        <!-- <h5 class="fw-bold" style="color: purple;">Stamp Amount: RM
                                            {{stampAmount}}</h5> -->
                                        <h5 class="fw-bold" style="color: purple;">Stamp Amount: RM </h5>
                                    </div>
                                    <div class="col p-0 m-0">
                                        <input type="number" class="stampSCInput p-0 m-0" required name="stampAmount"
                                            [(ngModel)]="stampAmount" min=0 placeholder="Stamp"
                                            (keypress)="sharedService.isNumberKey($event)">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col  pe-0">
                                        <!-- <h5 class="fw-bold" style="color: purple;">Service Charge: RM
                                            {{scAmount}}</h5> -->
                                        <h5 class="fw-bold" style="color: purple;">Service Charge: RM </h5>
                                    </div>
                                    <div class="col p-0 m-0">
                                        <input type="number" class="stampSCInput p-0 m-0" required name="scAmount"
                                            [(ngModel)]="scAmount" min=0 placeholder="Service Charge"
                                            (keypress)="sharedService.isNumberKey($event)">
                                    </div>
                                </div>
                            </tr>
                            <tr style="border-top: 2px solid black;border-bottom:2px solid black">
                                <h5 class="fw-bold" style="color: blue;">Simpan left:RM
                                    {{calculateReserveLeft(interestChargeAmount) |number:'1.1-2'}}
                                </h5>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <hr />
            <ng-container *ngIf="checkSimpanLeftEnough()==true;else notEnough">
                <!--Account Number-->
                <div class="row p-3 d-flex justify-content-center align-items-center">

                    <div class="col-12">
                        <p>To Account</p>
                    </div>
                    <div class="col-12">
                        <select class="browser-default form-select" name="selectedAccount"
                            [(ngModel)]="selectedAccountId">
                            <option *ngFor="let account of companyAccountList" [value]="account.ID"
                                [selected]="setSelectedAccount(account.ID)">{{account.Name}}</option>
                        </select>
                    </div>

                </div>

                <div class=" d-flex justify-content-start align-items-left">
                    <mat-form-field class="full-width-formField bar ">
                        <input matInput class="textInputLong initialDatePicker" [matDatepicker]="myDatepicker"
                            name="paymentDate" [(ngModel)]="paymentDate" placeholder="Payment Date"
                            [max]="sharedService.getTodayDate()" onkeydown="return false">
                        <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #myDatepicker name="date"></mat-datepicker>
                    </mat-form-field>
                </div>

                <p class="text-center">Are you sure you want to make payment by using SIMPAN?</p>
                <div class="row">
                    <div class="col d-flex justify-content-center align-items-center">
                        <button class="btn btn-success" (click)="submit()" [disabled]="disabledSubmit">
                            Yes
                        </button>
                        <button class="btn btn-danger ms-2" [disabled]="disabledSubmit" (click)="reset()">
                            No
                        </button>
                    </div>
                </div>
            </ng-container>
            <ng-template #notEnough>
                <p class="text-danger fw-bold bg-warning"> Cannot make payment, not enough simpan!</p>
            </ng-template>
        </div>
    </div>


</form>