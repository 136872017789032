<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Edit User</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body">
        <div class="container-fluid p-0">
            <div class="mt-2">
                <h5 class="text-primary fw-bold">Username: {{username}}</h5>

                <div class="card">
                    <div class="card-body">
                        <div class="table table-borderless">
                            <tr>
                                <td>
                                    <mat-form-field class="full-width-formField bar">
                                        <input matInput placeholder="Basic Salary" [value]="amount" name="allocation"
                                            [(ngModel)]="amount" (keypress)="sharedService.isNumberKey($event)"
                                            maxlength="6">
                                    </mat-form-field>
                                </td>
                                <td>
                                    <button class="btn btn-outline-success" type="button" (click)="submit()">Update User
                                        Salary</button>
                                </td>
                            </tr>

                        </div>
                    </div>
                </div>

                <div class="card mt-1">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="mt-4 d-flex justify-content-center align-items-center">
                                    <div class="group">
                                        <input type="password" required class="textInputLong" name="newPassword"
                                            [(ngModel)]="newPassword">
                                        <span class="highlight"></span>
                                        <span class="bar barLong"></span>
                                        <label>New Password</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="mt-4 d-flex justify-content-center align-items-center">
                                    <div class="group">
                                        <input type="password" required class="textInputLong" name="confirmPassword"
                                            [(ngModel)]="confirmPassword">
                                        <span class="highlight"></span>
                                        <span class="bar barLong"></span>
                                        <label>Confirm Password</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 d-flex justify-content-end align-items-end">
                                <button class="btn btn-outline-success" type="button"
                                    (click)="changeUserPassword()">Update Password</button>
                            </div>
                        </div>



                    </div>
                </div>

                <div class="card mt-1" *ngIf="companyId!=undefined">
                    <div class="card-body">
                        <select class="browser-default form-select" name="newRoleId" [(ngModel)]="newRoleId">
                            <option *ngFor="let role of enums.roleString; let i=index" [value]="i+1">{{role}}
                            </option>
                        </select>

                        <div class="col-12 my-1 d-flex justify-content-end align-items-end">
                            <button class="btn btn-outline-success" type="button"
                                (click)="updateCompanyUserRole()">Update
                                User Role</button>
                        </div>

                    </div>
                </div>
            </div>


        </div>
    </div>
</form>