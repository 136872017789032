import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { stringEmpty } from 'igniteui-angular-core';
import { Enums } from 'src/app/Shared/enums';


@Component({
  selector: 'app-mid-month-report',
  templateUrl: './mid-month-report.component.html',
  styleUrls: ['./mid-month-report.component.css']
})
export class MidMonthReportComponent implements OnInit, OnDestroy {

  disabledSubmit: boolean = false;
  startDate: Date;
  endDate: Date;
  selectedFile: File;
  @Input() selectedCompanyID: number;
  routeSubscription: any;
  isCollapsed = true;

  willOmitSchemeA = false;
  useVersion1 = false;

  allowGeneration = true;

  constructor(private activatedRoute: ActivatedRoute, private restApi: RestApiService, private constructAPI: ConstructAPI, public sharedService: SharedService, 
    private activeModal: NgbActiveModal,private enums:Enums) { }

  ngOnInit(): void {

    this.routeSubscription = this.activatedRoute.paramMap.subscribe(params => {
      this.selectedCompanyID = + params.get('companyId');

    });
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }

  handleFileInput(files: FileList) {
    this.selectedFile = files.item(0);
  }

  isFieldInvalid() {
    if (this.startDate != undefined && this.endDate != undefined) {
      return false;
    } else
      return true;
  }
  submit() {
    if (this.isFieldInvalid() == false) {

      this.getLastestCheckInDateBeforeMidMonthStartDate(this.selectedCompanyID);

    } else
      this.sharedService.openAlert("Please fill in all neccessary fields!",this.enums.DANGER_ALERT);
  }




  generateMidMonth() {
    //default the start data always the beginning of the month
    // var startDateStr = (this.startDate == undefined) ? undefined : new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate()).toISOString();
    var startDateStr = (this.startDate == undefined) ? undefined : this.sharedService.createLocalISODateEncodedString(this.startDate);

    if (this.endDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var addADayDate = new Date(Number(this.endDate) + (3600 * 1000 * 24) - 1);

    var endDateStr = (this.endDate == undefined) ? undefined : this.sharedService.createLocalISODateEncodedString(addADayDate);
    // var endDateStr = (this.endDate == undefined) ? undefined : addADayDate.toISOString();
    this.disabledSubmit = true;
    // var formData = new FormData();
    // formData.append("cashbook", this.selectedFile, this.selectedFile.name);



    var xhr;

    if (this.useVersion1 == true)
      xhr = this.restApi.getRequest(this.constructAPI.getMidMonthV1(this.selectedCompanyID, startDateStr, endDateStr, this.willOmitSchemeA));
    else
      xhr = this.restApi.getRequest(this.constructAPI.getMidMonth(this.selectedCompanyID, startDateStr, endDateStr, this.willOmitSchemeA));
    xhr.responseType = "blob";
    xhr.onreadystatechange = () => {

      if (xhr.readyState === 4) {
        if (xhr.status == 200) {

          this.disabledSubmit = false;

          var disposition = xhr.getResponseHeader("content-disposition");
          // var contentType = xhr.getResponseHeader("content-type");
          // console.log(disposition)
          var filename = disposition.split(';')[1].split('filename')[1].split('=')[1].trim().replace("\"", stringEmpty).replace("\"", stringEmpty);
          // console.log(filename);

          this.sharedService.generateDownloadLink(xhr, filename);
          this.sharedService.openAlert("Successfully generated MidMonth!",this.enums.SUCCESS_ALERT);
        }
        else {
          this.disabledSubmit = false;

          this.sharedService.openAlert("Some error occurred. Please try again later",this.enums.DANGER_ALERT);

        }
      }

    };
  }


  getLastestCheckInDateBeforeMidMonthEndDate(companyId: number) {
    // var midMonthEndDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate() + 1, 0, 0, 0, 0);
    // var midMonthEndDateStr = midMonthEndDate.toISOString();

    var midMonthEndDateStr = (new Date(Number(this.endDate) + (3600 * 1000 * 24) - 1)).toISOString();
    var xhttp = this.restApi.getRequest(this.constructAPI.getCompaniesLatestCheckIns(companyId, midMonthEndDateStr));

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // Typical action to be performed when the document is ready:

        // Typical action to be performed when the document is ready:

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {

          var lastDateISOString = (new Date(json.date)).toISOString();
          var lastSecEndDateISOString = (new Date(Number(this.endDate) + (3600 * 1000 * 24) - 1)).toISOString();

          if (lastDateISOString == lastSecEndDateISOString)
            this.generateMidMonth();
          else {
            this.sharedService.openAlert("Please verify your account on END DATE (" + this.endDate.getFullYear() + "/" + (this.endDate.getMonth() + 1) + "/" + this.endDate.getDate() + ")",this.enums.DANGER_ALERT);
            this.allowGeneration = false;
          }

        }
        );
      }
    };
  }


  getLastestCheckInDateBeforeMidMonthStartDate(companyId: number) {
    // var midMonthStartDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate() + 1, 0, 0, 0, 0);
    // var midMonthStartDateStr = midMonthStartDate.toISOString();

    var midMonthStartDateStr = (new Date(Number(this.startDate) + (3600 * 1000 * 24) - 1)).toISOString();
    var xhttp = this.restApi.getRequest(this.constructAPI.getCompaniesLatestCheckIns(companyId, midMonthStartDateStr));

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // Typical action to be performed when the document is ready:


        var jsonArray = JSON.parse((xhttp.responseText));

        if (jsonArray.length > 0) {
          jsonArray.forEach(json => {

            var lastDateISOString = (new Date(json.date)).toISOString();
            var lastSecStartDateISOString = (new Date(Number(this.startDate) + (3600 * 1000 * 24) - 1)).toISOString();

            if (lastDateISOString == lastSecStartDateISOString)
              this.getLastestCheckInDateBeforeMidMonthEndDate(companyId);
            else {
              this.sharedService.openAlert("Please verify your account on START DATE (" + this.startDate.getFullYear() + "/" + (this.startDate.getMonth() + 1) + "/" + this.startDate.getDate() + ")",this.enums.DANGER_ALERT);
              this.allowGeneration = false;
            }

          }
          );
        }
        else {
          this.sharedService.openAlert("Please verify your account on both START DATE and END DATE",this.enums.SUCCESS_ALERT);
          this.allowGeneration = false;
        }

      }
    };
  }

  reset() {
    this.activeModal.close();
  }

}
