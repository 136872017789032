<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">View Reserve Group # {{reserveGroupId}}</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 aria-label="Close" aria-label="Close"
            (click)="reset()"></button>
    </div>

    <div class="modal-body" *ngIf="reserveGroup!=undefined" [ngClass]="{'unsubmitCard':reserveGroup.Valid==false}">
        <p>Created By: {{reserveGroup.RequestedBy}} on {{reserveGroup.RequestedOn |date:'dd-MM-yyyy'}}</p>
        <div class="col m-1 d-flex justify-content-end align-items-right">
            <button class="btn btn-primary " (click)="viewReserveGroupImages(reserveGroup.ID)" #tooltip="matTooltip"
                matTooltip="View Files" matTooltipPosition="above" matTooltipHideDelay="100"
                matTooltipClass="tooltip-black">
                <i class="fas fa-image"></i>
            </button>

            <button class="btn btn-info ms-1" (click)="openUploadReserveGroupDocModal(reserveGroup.ID)"
                #tooltip="matTooltip" matTooltip="Add Files" matTooltipPosition="above" matTooltipHideDelay="100"
                matTooltipClass="tooltip-black">
                <i class="fas fa-plus"></i></button>
        </div>

        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>Company</th>
                    <th>Distribution</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let distribution of reserveGroup.Distribution">
                    <td>{{distribution.CompanyCode}} <span class="badge text-bg-success"
                            *ngIf="distribution.AcceptedOn!=undefined">Accepted</span></td>
                    <td>{{distribution.Amount}}</td>
                </tr>
            </tbody>
        </table>

        <ng-container *ngIf="loggedInUserAccepted==false && sharedService.isOperatingUser()">

            <div>
                <mat-form-field class="full-width-formField bar">
                    <input matInput class="textInputLong" [matDatepicker]="myDatepicker" name="simpanDate"
                        [(ngModel)]="simpanDate" placeholder="Simpan Date" [max]="sharedService.getTodayDate()"
                        onkeydown="return false">
                    <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                    <mat-datepicker #myDatepicker name="simpanDate"></mat-datepicker>
                </mat-form-field>
            </div>

            <!--Account Number-->
            <div class="row p-3 d-flex justify-content-center align-items-center">
                <div class="col-12">
                    <p>From Account</p>
                </div>
                <div class="col-12">
                    <select class="browser-default form-select" name="selectedAccount" [(ngModel)]="selectedAccountId">
                        <optgroup label="Company Account">
                            <option *ngFor="let account of companyBankAccountList" [value]="account.ID">
                                {{account.Name}}
                            </option>
                        </optgroup>

                        <optgroup label="Cash Account">
                            <option *ngFor="let account of cashAccountList" [value]="account.ID">
                                {{account.Name}}
                            </option>
                        </optgroup>

                    </select>
                </div>

            </div>

            <textarea [(ngModel)]="remark" name="remark"></textarea>
            <div class="d-flex justify-content-end align-items-right">
                <button class="btn btn-outline-success" (click)="acceptReserveGroup()">Accept</button>
            </div>

        </ng-container>
        <div class="d-flex justify-content-end align-items-right"
            *ngIf="reserveGroup.Valid==false && sharedService.isOperatingUser()">
            <button class="btn btn-outline-success" (click)="submitReserveGroup()">Submit</button>
        </div>

    </div>

</form>