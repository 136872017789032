<h3 class="text-start" id="pageTitle" style="margin: 15px;">Loan Scheme Report ({{schemeName}})</h3>
<div class="content m-3">

    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>
            <!-- Date Created Column -->
            <ng-container matColumnDef="DateCreated">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> DateCreated </th>
                <td mat-cell *matCellDef="let report" (click)="goToCustomer(report.CustomerId)">
                    {{report.DateCreated |date:'dd-MM-yyyy'}}
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- ReceiptNumber Column -->
            <ng-container matColumnDef="ReceiptNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ReceiptNumber </th>
                <td mat-cell *matCellDef="let report" (click)="goToCustomer(report.CustomerId)">
                    {{report.ReceiptNumber}}
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- CustomerName Column -->
            <ng-container matColumnDef="CustomerName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> CustomerName </th>
                <td mat-cell *matCellDef="let report" (click)="goToCustomer(report.CustomerId)">
                    {{report.CustomerName}} </td>
                <th mat-footer-cell *matFooterCellDef>Total:</th>
            </ng-container>

            <!-- Principal Column -->
            <ng-container matColumnDef="Principal">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Principal </th>
                <td mat-cell *matCellDef="let report" (click)="goToCustomer(report.CustomerId)"> {{report.Principal}}
                </td>
                <th mat-footer-cell *matFooterCellDef>{{calculateTotalPrincipal()}}</th>
            </ng-container>

            <!-- Outstanding Column -->
            <ng-container matColumnDef="Outstanding">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Outstanding (Term)</th>
                <td mat-cell *matCellDef="let report" (click)="goToCustomer(report.CustomerId)">
                    {{report.Outstanding}}
                </td>
                <th mat-footer-cell *matFooterCellDef>{{calculateTotalOutstanding()}} </th>
            </ng-container>

            <!-- RemainingTerm Column -->
            <ng-container matColumnDef="RemainingTerm">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Remaining Term </th>
                <td mat-cell *matCellDef="let report" (click)="goToCustomer(report.CustomerId)">
                    {{report.RemainingTerm | number:'1.0-0'}}
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="Status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Status </th>
                <td mat-cell *matCellDef="let report" (click)="goToCustomer(report.CustomerId)">
                    <h5> <span class="badge text-bg-primary"
                            [ngClass]="getStatusColor(report.Status)">{{convertStatusString(report.Status)}}
                        </span>
                    </h5>

                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef=" displayedColumns">
            </tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
        </table>

        <!-- <mat-paginator [pageSizeOptions]="[25,50, 100]" showFirstLastButtons></mat-paginator> -->
    </div>
</div>