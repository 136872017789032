import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-customer-remark',
  templateUrl: './view-customer-remark.component.html',
  styleUrls: ['./view-customer-remark.component.css']
})
export class ViewCustomerRemarkComponent implements OnInit {

  constructor(private activeModal: NgbActiveModal) { }
  @Input() viewCustomerId: number;
  @Input() viewCustomerName: string;
  ngOnInit(): void {
  }


  reset() {
    this.activeModal.close();
  }
}
