<form>
    <!-- Modal Header -->
    <div class="modal-header" style="background-color:lightyellow;">
        <h4 class="modal-title">Batch Loan Payment</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body">
        <div class="col-12">
            <mat-form-field class="full-width-formField p-0">
                <input matInput readonly [matDatepicker]="endDatePicker" name="paymentDate" [(ngModel)]="paymentDate"
                    required>
                <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #endDatePicker name="paymentDate"></mat-datepicker>
            </mat-form-field>
        </div>

        <ng-container *ngIf="paymentList.length>0">
            <div *ngFor="let payment of paymentList;let i=index">
                <ng-container *ngIf="payment.isOverlap==false">
                    <mat-expansion-panel (opened)="payment.panelOpenState = true"
                        (closed)="payment.panelOpenState = false" [expanded]="payment.panelOpenState">
                        <mat-expansion-panel-header [ngClass]="getStatusColor(payment.loan.Status)">
                            <mat-panel-title>
                                <button class="btn btn-info me-2" (click)="setOverlap(payment)"
                                    [hidden]="payment.loan.Scheme=='HUTANG' || needRestrictedCustomer==true"><i
                                        class="fas fa-clone"></i></button>
                                {{payment.loan.ReceiptNumber}} (Code: {{payment.loan.ID}}) -
                                <span class="text-danger fw-bold"> Balance : RM {{payment.loan.Balance
                                    |number:'1.0-2'}} |
                                    Monthly:
                                    RM {{payment.loan.MonthlyPayment|number:'1.0-2'}}</span>
                            </mat-panel-title>
                            <mat-panel-description *ngIf="paymentList.length>0">
                                <!-- Currently I am {{panelOpenState ? 'open' : 'closed'}} -->
                                <h6 class="fw-bold" [ngClass]="getPaymentColor(payment.totalPayment)"> Paid :
                                    RM
                                    {{payment.totalPayment ||0|number:'1.0-2'}}</h6>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <!-- <p>I'm visible because I am open</p> -->
                        <app-add-payment [selectedLoan]="payment.loan"
                            (passLoanPaymentDetails)="getLoanPaymentData($event,i)" [batchDate]="paymentDate">
                        </app-add-payment>
                    </mat-expansion-panel>
                </ng-container>
            </div>
        </ng-container>

        <ng-container *ngIf="paymentList.length>0">
            <hr />
            <div *ngFor="let payment of paymentList;let i=index">
                <ng-container *ngIf="payment.isOverlap==true">
                    <app-overlap-component class="m-2" [payment]="payment" [selectedProfile]="selectedProfile"
                        [selectedCustomer]="selectedCustomer" [payWithSimpan]="isPayBySimpan"
                        (passOverlapDetails)="getOverlapData($event,i)" [batchDate]="paymentDate">
                    </app-overlap-component>
                </ng-container>
            </div>
        </ng-container>


    </div>

    <div class="card m-3" style="background-color: lavender;">
        <div class="card-body p-1">
            <h3 class="fw-bold ms-3" style="color: rgb(192, 36, 192);">
                <span style="color: purple;" *ngIf="isPayBySimpan==true">Actual: RM{{addedSimpan +
                    calculateExtraOverlapAmount()}} |</span>
                <span *ngIf="isPayBySimpan==false"> Payment: RM {{calculateTotalCustomerPayment()
                    |number:'1.2-2'}}</span>
                <span *ngIf="isPayBySimpan==true"> Simpan Out: RM {{calculateTotalSimpanOut() |number:'1.2-2'}}</span>
            </h3>

        </div>
    </div>
    <div class="card m-3" style="background-color: #ffe6eb;">
        <div class="card-body">
            <div class="row">
                <div class="col">
                    <mat-slide-toggle class="mat-slide-toggle-lg" [hideIcon]="true" color="primary" [(ngModel)]="isPayBySimpan"
                        name="isPayBySimpan" [disabled]="sharedProfileId!=undefined">
                        Pay By Simpan
                    </mat-slide-toggle>
                </div>
                <div class="col" *ngIf="this.isPayBySimpan==true">
                    <div class="row">
                        <div class="col-6 d-flex justify-content-end align-items-end">
                            <h5 class="fw-bold" style="color: indigo;">Simpan: RM
                                {{selectedProfile.Reserve}}
                                <span class="small fw-bold" style="color:rgb(204, 59, 204)"
                                    *ngIf="this.addedSimpan>0">(+
                                    RM{{this.addedSimpan}})</span>
                            </h5>
                        </div>
                        <div class="col-6 d-flex justify-content-start align-items-start">
                            <button class="btn btn-outline-dark" (click)="checkIfNeedDoReserveGroup()"
                                *ngIf="this.selectedProfile.SharedProfileId == undefined || sharedService.checkIsMainShareProfile(selectedProfile)">Top
                                Up</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <hr />




    <div [hidden]="isPayBySimpan==true && this.selectedProfile.Reserve < calculateTotalSimpanOut()">
        <!--Account Number-->
        <div class=" row p-3 d-flex justify-content-center align-items-center">

            <div class="col-12">
                <p *ngIf="this.isPayBySimpan==false">To Account</p>
                <p *ngIf="this.isPayBySimpan==true">From Account</p>
            </div>
            <div class="col-12">
                <select class="browser-default form-select" name="selectedAccount" [(ngModel)]="selectedAccountId">
                    <option *ngFor="let account of companyAccountList" [value]="account.ID">{{account.Name }}
                    </option>
                </select>
            </div>

        </div>
        <div class="modal-footer">
            <button class="btn btn-outline-success" type="button" (click)="submit()" [disabled]="disabledSubmit">Pay
                Loans</button>
            <button class="btn btn-outline-danger" type="button" (click)="reset()">Cancel</button>
        </div>
    </div>
    <div class="m-3" *ngIf="isPayBySimpan==true && this.selectedProfile.Reserve < calculateTotalSimpanOut()">
        <h6 class="fw-bold text-danger"> You do not have enough simpan to pay, please top up first!</h6>
    </div>
</form>