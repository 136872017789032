import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddAdvanceComponent } from 'src/app/AddThings/add-advance/add-advance.component';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Advance } from 'src/model/advance.modal';
import { PayAdvanceComponent } from '../pay-advance/pay-advance.component';

@Component({
  selector: 'app-advance-list',
  templateUrl: './advance-list.component.html',
  styleUrls: ['./advance-list.component.css']
})
export class AdvanceListComponent implements OnInit {
  advanceList: Advance[] = new Array;

  displayedColumns: string[] = ['Date', 'Username', 'Amount', 'Remark', 'Actions'];
  dataSource = new MatTableDataSource;
  routeSubscription: any;

  selectedCompanyID: number;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(private activatedRoute: ActivatedRoute, private cdr: ChangeDetectorRef, public enums: Enums, private restapi: RestApiService,
    private constructApi: ConstructAPI, private sharedService: SharedService) {

    this.routeSubscription = this.activatedRoute.paramMap.subscribe(params => {
      this.selectedCompanyID = + params.get('companyId');
    })
  }

  ngOnInit(): void {

    this.getAllAdvances();
  }

  getAllAdvances() {
    var xhttp = this.restapi.getRequest(this.constructApi.getAllAdvancesOfCompany(this.selectedCompanyID, false))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // Typical action to be performed when the document is ready:

        var jsonArray = JSON.parse((xhttp.responseText));
        // console.log(jsonArray);

        jsonArray.forEach(json => {

          // create advance instance with all response data
          let advance = new Advance(json.id, json.username, json.dateCreated, json.balance, json.remark);

          //add advance instance into advance list
          this.advanceList.push(advance);
        }
        );

        this.advanceList.sort((a, b) => ((a.Amount > b.Amount)) ? 1 : -1);

        //make advance list as the datasource
        this.dataSource = new MatTableDataSource<Advance>(this.advanceList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

      }
    };

  }

  addStatusBadge(advanceStatus: number) {
    if (advanceStatus == this.enums.ACTIVE_LOAN)
      return "bg-primary";
    else if (advanceStatus == this.enums.SETTLED_LOAN)
      return "bg-success";
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  refreshDataSource() {
    this.dataSource.data = this.advanceList;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  openAddAdvanceModal() {

    const modalRef = this.sharedService.openModal(AddAdvanceComponent, "largeModal");

    //get company that was created
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var advance = entry;

      //append added customer to customer list
      this.advanceList.push(advance);
    });

    modalRef.result.then(() => {
      this.refreshDataSource();
    })
  }


  findAdvanceInList(advanceId: number) {
    for (let i = 0; i < this.advanceList.length; i++) {
      if (this.advanceList[i].ID == advanceId)
        return i
    }
  }

  calculateTotalAmount() {
    var total = 0;
    for (let i = 0; i < this.advanceList.length; i++) {
      total += this.advanceList[i].Amount;
    }

    return total;
  }

  getBackgroundColor(amount: number) {
    if (amount > 0)
      return 'postiveBackground';
  }

  openPayAdvanceModal(advance: Advance, toCreateNew: boolean) {

    const modalRef = this.sharedService.openModal(PayAdvanceComponent, "largeModal");
    modalRef.componentInstance.advanceObj = advance;
    modalRef.componentInstance.toCreateNew = toCreateNew;

    //get company that was created
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var advance = entry;

      //update amount
      // var index = this.findAdvanceInList(advance.ID);

      // this.advanceList[index].Amount += advance.Amount;

      this.advanceList.splice(0, this.advanceList.length);
      this.getAllAdvances();
    });

    modalRef.result.then(() => {
      this.refreshDataSource();
    })
  }

}
