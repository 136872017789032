<div class="card m-1" style="background-color: aliceblue;border-radius: 20px;">
    <div class="card-body">
        <div class="col-12 d-flex justify-content-end align-items-right">
            <span class="text-info fw-bold me-4" style="cursor: pointer;" placement="bottom"
                [ngbTooltip]="previousLoanTooltip"><i class="fas fa-info-circle"></i></span>
            <ng-template #previousLoanTooltip>
                <div *ngIf="message.length>0">
                    <ng-container *ngFor=" let rule of message">
                        <p>{{rule}}</p>
                    </ng-container>
                </div>
            </ng-template>
        </div>
        <ng-container *ngIf="isEPFExtra==false && epfDate!=undefined && epfAmount!=undefined && epfLimitRuleSet[sharedService.checkEPFLimitCategory(epfDate,epfAmount,epfLimitRuleSet)]!=undefined &&
        epfLimitRuleSet[sharedService.checkEPFLimitCategory(epfDate,epfAmount,epfLimitRuleSet)].FirstLoanRate!=undefined">
            <h5>
                Maximum First Loan = RM
                {{sharedService.getEPFLimitFirstLoan(epfDate,epfAmount,epfLimitRuleSet)|number:'1.2-2'}}
            </h5>

            <h5>
                Maximum On Hand = RM
                {{sharedService.getEPFLimitCashOnHand(epfDate,epfAmount,this.sharedService.getEPFLimitFirstLoan(epfDate,epfAmount,epfLimitRuleSet))|number:'1.2-2'}}
            </h5>
        </ng-container>

        <ng-container *ngIf="epfDate!=undefined && epfAmount!=undefined && epfLimitRuleSet[sharedService.checkEPFLimitCategory(epfDate,epfAmount,epfLimitRuleSet)]!=undefined &&
        epfLimitRuleSet[sharedService.checkEPFLimitCategory(epfDate,epfAmount,epfLimitRuleSet)].OverallLoanRate!=undefined">
            <h5>
                Maximum Total Loan = RM
                {{sharedService.getEPFLimitPrincipal(epfDate,epfAmount,epfLimitRuleSet)|number:'1.2-2'}}
            </h5>
        </ng-container>

        <ng-container *ngIf="isEPFExtra==true">
            <h5>Previous Loan = RM
                {{sharedService.calculatePreviousEPFLoanPrincipal(previousEPFLoans)|number:'1.2-2'}}
                <span class="badge text-bg-primary" *ngIf="includeSharedLoans()">Include Shared Loans </span>
            </h5>
            <p class="text-danger fw-bold"
                *ngIf="epfLimitRuleSet[sharedService.checkEPFLimitCategory(epfDate,epfAmount,epfLimitRuleSet)]!=undefined && epfLimitRuleSet[sharedService.checkEPFLimitCategory(epfDate,epfAmount,epfLimitRuleSet)].OverallLoanRate!=undefined">
                Maximum principal left : RM
                {{sharedService.getEPFLimitPrincipal(epfDate,
                epfAmount,epfLimitRuleSet) -
                sharedService.calculatePreviousEPFLoanPrincipal(previousEPFLoans)|number:'1.2-2'}}

                <span class="badge text-bg-danger" *ngIf="sharedService.getEPFLimitPrincipal(epfDate, epfAmount,epfLimitRuleSet)
                -sharedService.calculatePreviousEPFLoanPrincipal(previousEPFLoans)<0"> Cannot extra anymore!
                </span>
            </p>
            <hr />
            <h5
                *ngIf="epfLimitRuleSet[sharedService.checkEPFLimitCategory(epfDate,epfAmount,epfLimitRuleSet)]!=undefined && (epfLimitRuleSet[sharedService.checkEPFLimitCategory(epfDate,epfAmount,epfLimitRuleSet)].MaximumSettlementRate !=undefined|| epfLimitRuleSet[sharedService.checkEPFLimitCategory(epfDate,epfAmount,epfLimitRuleSet)].ElevatedSettlementRate !=undefined)">
                Previous Settlement =
                RM {{
                sharedService.calculatePreviousEPFLoanSettlement(previousEPFLoans)|number:'1.2-2'}}
                ( {{sharedService.calculateSettlementRatio(
                sharedService.calculatePreviousEPFLoanSettlement(previousEPFLoans),this.epfAmount)|number:'1.2-2'}}%)

            </h5>

            <p class=" text-info fw-bold"
                *ngIf="epfLimitRuleSet[sharedService.checkEPFLimitCategory(epfDate,epfAmount,epfLimitRuleSet)]!=undefined && epfLimitRuleSet[sharedService.checkEPFLimitCategory(epfDate,epfAmount,epfLimitRuleSet)].MaximumSettlementRate !=undefined">
                Maximum settlement left :
                RM {{sharedService.getEPFLimitSettlement(epfDate, epfAmount,epfLimitRuleSet)
                - sharedService.calculatePreviousEPFLoanSettlement(previousEPFLoans) |number:'1.2-2'}}
                ({{((epfLimitRuleSet[sharedService.checkEPFLimitCategory(epfDate,epfAmount,epfLimitRuleSet)].MaximumSettlementRate)*100)
                -
                sharedService.calculateSettlementRatio(sharedService.calculatePreviousEPFLoanSettlement(previousEPFLoans),epfAmount)
                |number:'1.2-2'}}%)


                <span class="badge text-bg-danger" *ngIf="sharedService.getEPFLimitSettlement(epfDate, epfAmount,epfLimitRuleSet)
                - sharedService.calculatePreviousEPFLoanSettlement(previousEPFLoans)<0"> Cannot extra anymore!
                </span>

            </p>

            <p class=" text-info fw-bold"
                *ngIf="epfLimitRuleSet[sharedService.checkEPFLimitCategory(epfDate,epfAmount,epfLimitRuleSet)]!=undefined && epfLimitRuleSet[sharedService.checkEPFLimitCategory(epfDate,epfAmount,epfLimitRuleSet)].ElevatedSettlementRate !=undefined">
                Suggested settlement left :
                RM {{sharedService.getEPFElevateSettlement(epfDate, epfAmount,epfLimitRuleSet)
                - sharedService.calculatePreviousEPFLoanSettlement(previousEPFLoans) |number:'1.2-2'}}
                ({{((epfLimitRuleSet[sharedService.checkEPFLimitCategory(epfDate,epfAmount,epfLimitRuleSet)].ElevatedSettlementRate)*100)
                -
                sharedService.calculateSettlementRatio(sharedService.calculatePreviousEPFLoanSettlement(previousEPFLoans),epfAmount)
                |number:'1.2-2'}}%)

            </p>
        </ng-container>
    </div>
</div>