<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Arrears</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body">

        <div class="container-fluid" style="padding: 2%;">

            <div style="margin: 2%;">
                <ul class="nav nav-tabs">
                    <li class="nav-item" [hidden]="selectedLoan.Status != enums.SETTLED_LOAN">
                        <a class="nav-link" [ngClass]="setActive('Payment')" role="tab"  data-bs-toggle="tab"
                            href="#tabPayArrear">
                            <p>Pay Arrear</p>

                        </a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" [ngClass]="setActive('History')" role="tab"  data-bs-toggle="tab"
                            href="#tabArrearHistory">
                            <p>Arrear History</p>
                        </a>
                    </li>

                </ul>
                <div class="tab-content">
                    <!-- <div class="tab-pane active" role="tabpanel" id="tabPayArrear">
                        <h5 class="mt-1 fw-bold text-danger">Unpaid Arrears : {{unpaidArrearList.length}}
                            time(s)
                        </h5>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Remark</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let arrear of unpaidArrearList">
                                <tr [style.background-color]="setSelectedStyle(arrear.ID)">
                                    <td>
                                        {{arrear.Date | date:'dd-MM-yyyy'}}
                                    </td>
                                    <td>
                                        {{arrear.Remark}}
                                    </td>
                                </tr>
                            </tbody>

                        </table>


                        <div class="card card-body">
                            <!--Account Number-->
                    <!-- <div class="row p-3">
                                <div class="col-12">
                                    <p>To Account</p>
                                </div>
                                <div class="col-12">
                                    <select class="browser-default form-select" name="selectedAccount"
                                        [(ngModel)]="selectedAccountId">
                                        <option *ngFor="let account of companyAccountList" [value]="account.ID"
                                            [selected]="setSelectedAccount(account.ID)">{{account.Name}} (RM
                                            {{account.Balance}})</option>
                                    </select>
                                </div>

                            </div> -->
                    <!--Amount-->
                    <!-- <div class="group">
                                <input type="number" required class="textInputLong" name="amount" [(ngModel)]="amount"
                                    min=0 (keypress)="sharedService.isNumberKey($event)">
                                <span class="highlight"></span>
                                <span class="bar barLong"></span>
                                <label>Amount</label>
                            </div> -->


                    <!--Date-->
                    <!-- <div class="d-flex justify-content-center align-items-center"> -->
                    <!-- <mat-form-field class="full-width-formField bar">
                                <input matInput class="textInputLong" [max]="maxDate" [matDatepicker]="myDatepicker"
                                    name="payArrearDate" [(ngModel)]="payArrearDate" placeholder="Payment Date">
                                <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                                <mat-datepicker #myDatepicker name="payArrearDate"></mat-datepicker>
                            </mat-form-field> -->

                    <!-- Remark -->
                    <!-- <div class="group">
                                <input type="text" required class="textInputLong" name="remark" [(ngModel)]="remark">
                                <span class="highlight"></span>
                                <span class="bar barLong"></span>
                                <label>Remark</label>
                            </div> -->

                    <!--buttons-->
                    <!-- <div class="modal-footer">
                                <button class="btn btn-outline-success" type="button" (click)="submit()" [disabled]="disabledSubmit">Pay
                                    Arrear</button>
                                <button class="btn btn-outline-danger" type="button" (click)="reset()">Cancel</button>
                            </div>
                        </div>
                    </div>  -->
                    <div class="tab-pane" [ngClass]="setActive('Payment')" role="tabpanel" id="tabPayArrear">
                        <h5 class="mt-1 fw-bold text-danger">Arrears : {{unpaidArrearList.length}}
                            time(s)
                        </h5>
                        <div class="card card-body">
                            <!--Account Number-->
                            <div class="row p-3">
                                <div class="col-12">
                                    <p>To Account</p>
                                </div>
                                <div class="col-12">
                                    <select class="browser-default form-select" name="selectedAccount"
                                        [(ngModel)]="selectedAccountId">
                                        <option *ngFor="let account of companyAccountList" [value]="account.ID"
                                            [selected]="setSelectedAccount(account.ID)">{{account.Name}} </option>
                                    </select>
                                </div>

                            </div>
                            <!--Amount-->
                            <div class="group">
                                <input type="number" required class="textInputLong" name="amount" [(ngModel)]="amount"
                                    min=0 (keypress)="sharedService.isNumberKey($event)">
                                <span class="highlight"></span>
                                <span class="bar barLong"></span>
                                <label>Amount</label>
                            </div>


                            <!--Date-->
                            <!-- <div class="d-flex justify-content-center align-items-center"> -->
                            <mat-form-field class="full-width-formField bar">
                                <input matInput class="textInputLong" [max]="maxDate" [matDatepicker]="myDatepicker"
                                    name="payArrearDate" [(ngModel)]="payArrearDate" placeholder="Payment Date">
                                <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                                <mat-datepicker #myDatepicker name="payArrearDate"></mat-datepicker>
                            </mat-form-field>

                            <!-- Remark -->
                            <!-- <div class="group">
                                <input type="text" required class="textInputLong" name="remark" [(ngModel)]="remark">
                                <span class="highlight"></span>
                                <span class="bar barLong"></span>
                                <label>Remark</label>
                            </div> -->

                            <!--buttons-->
                            <div class="modal-footer">
                                <button class="btn btn-outline-success" type="button" (click)="submit()"
                                    [disabled]="disabledSubmit">Pay
                                    Arrear</button>
                                <button class="btn btn-outline-danger" type="button" (click)="reset()">Cancel</button>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane " [ngClass]="setActive('History')" role="tabpanel" id="tabArrearHistory">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th *ngIf="sharedService.isBossAccount()"></th>
                                    <th>Date</th>
                                    <th>Remark</th>
                                    <!-- <th>Amount</th> -->
                                    <th>Date Paid</th>
                                </tr>

                            </thead>
                            <tr *ngFor="let arrear of arrearList">
                                <td *ngIf="sharedService.isBossAccount()">
                                    <button class="btn btn-danger"><i class="fas fa-trash-alt"
                                            (click)="confirmDeleteArrear(arrear.ID)"></i></button>
                                </td>
                                <td>
                                    {{arrear.Date | date:'dd-MM-yyyy'}}
                                </td>
                                <td>
                                    {{arrear.Remark}}
                                </td>

                                <!-- <td [ngClass]="addPaidStyle(arrear.DatePaid)">
                                    {{arrear.Amount}}
                                </td> -->

                                <td class="fw-bold" [ngClass]="addPaidStyle(arrear.DatePaid)">
                                    {{arrear.DatePaid | date:'dd-MM-yyyy'}}
                                </td>
                            </tr>
                            <tfoot>
                                <tr>
                                    <td *ngIf="sharedService.isBossAccount()"></td>
                                    <td></td>
                                    <td><strong>Total Unpaid:</strong></td>
                                    <td><strong>{{calculateTotalArrear(arrearList)}}</strong></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>




        </div>
    </div>


</form>