import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { AddCommissionsComponent } from 'src/app/Comission/add-commissions/add-commissions.component';
import { AddReserveGroupComponent } from 'src/app/Reserve/add-reserve-group/add-reserve-group.component';
import { AddSimpanComponent } from 'src/app/Reserve/add-simpan/add-simpan.component';
import { ReceiptDetailsComponent } from 'src/app/Sets/receipt-details/receipt-details.component';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Account } from 'src/model/account.model';
import { BatchPaymentInterface } from 'src/model/batchPayment.interface';
import { Customer } from 'src/model/customer.model';
import { Loan } from 'src/model/loan.model';
import { Overlap } from 'src/model/overlap.model';
import { Payment } from 'src/model/payment.model';
import { Profile } from 'src/model/profile.model';



@Component({
  selector: 'app-batch-payment',
  templateUrl: './batch-payment.component.html',
  styleUrls: ['./batch-payment.component.css']
})
export class BatchPaymentComponent implements OnInit {

  @Input() loanList: Loan[] = new Array;
  @Input() selectedCustomer: Customer;
  @Input() selectedProfile: Profile;

  @Input() sharedProfileId: number;

  addedDiscountSetId: number;
  addedEPFPaymentSetId: number;

  paymentList: BatchPaymentInterface[] = new Array;
  companyAccountList: Account[] = new Array;
  selectedAccountId: number;

  disabledSubmit = false;

  panelOpenState = false;

  isPayBySimpan = false;

  addedSimpan = 0;

  paymentDate: Date = new Date();

  containsPayIntAfterPrincipal = false;

  containsAddLoan = false;
  containsDiscountInPayment = false;
  containsEPFPayment = false;
  needRestrictedCustomer = false;

  constructor(private enums: Enums, private activeModal: NgbActiveModal, private restapi: RestApiService, private constructApi: ConstructAPI, public sharedService: SharedService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.generatePaymentObjects();
    var companyId = Number(sessionStorage.getItem("companyId"));
    this.getAllAccountsOfCompany(companyId);

    //if shared customer only can use simpan for payment
    if (this.sharedProfileId != undefined) {
      this.isPayBySimpan = true;
      this.cdr.detectChanges();
    }

    else {
      if (this.selectedProfile.Reserve > 0) {
        this.openPayBySimpanConfirmation();
      }
    }



    this.needRestrictedCustomer = this.sharedService.checkIsSeniorRestrictedCustomer(new Date(this.selectedCustomer.DOB));


  }

  openPayBySimpanConfirmation() {
    var proceed = false;
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "This customer has RM " + this.selectedProfile.Reserve + " SIMPAN. You want to PAY BY SIMPAN?";
    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something\

      if (confirmation == true)
        this.isPayBySimpan = true;
    });

  }

  generatePaymentObjects() {
    for (let i = 0; i < this.loanList.length; i++) {
      var addedLoan = this.loanList[i];
      var payment = { loan: addedLoan, panelOpenState: false, isOverlap: false }
      this.paymentList.push(payment);
    }
  }


  private getAllAccountsOfCompany(companyId: number) {

    var xhttp = this.restapi.getRequest(this.constructApi.getAccountsOfCompany(companyId));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let account = new Account(json.id, json.number, json.name, json.holderName, json.balance, json.companyId, json.username);

          var loggedInUser = sessionStorage.getItem("username");
          this.companyAccountList.push(account);
        }
        );
      }
    };

  }

  getStatusColor(status: number) {
    if (status == this.enums.ACTIVE_LOAN)
      return 'activeLoan';
    else if (status == this.enums.BAD_DEBT_LOAN)
      return 'bdLoan'
    else if (status == this.enums.WARNING_LOAN)
      return 'warningLoan'
  }

  getLoanPaymentData(details: Payment, index: number) {
    this.paymentList[index].paymentDetails = details;
    this.paymentList[index].panelOpenState = false;
    this.paymentList[index].totalPayment = this.calculateTotalLoanPayment(details)
  }


  getOverlapData(details: BatchPaymentInterface, index: number) {
    this.paymentList[index].paymentDetails = details.paymentDetails;
    this.paymentList[index].totalPayment = this.calculateTotalLoanPayment(details.paymentDetails);
    this.paymentList[index].overlapAddedLoan = details.overlapAddedLoan;
    if (details.overlapAddedLoan != undefined)
      this.containsAddLoan = true;
  }

  setOverlap(payment: BatchPaymentInterface) {
    payment.panelOpenState = true;
    payment.isOverlap = true;

  }

  calculateTotalLoanPayment(payment: Payment) {
    if (payment != undefined) {
      var extrasTotal = 0;
      for (let i = 0; i < payment.Extras.length; i++) {
        extrasTotal += payment.Extras[i].Amount;
      }

      if (payment.PayInterestAfterPrincipal == true) {
        return payment.Stamp + payment.ServiceCharge - payment.Discount + extrasTotal + payment.Arrear + payment.TermsAmount + payment.Reserve + payment.Principal + payment.NewInterestAmount + (payment.HandlingCharge || 0);
      }

      else {

        if (payment.ActualDiscountAmount != undefined)
          return payment.Stamp + payment.ServiceCharge - payment.ActualDiscountAmount + extrasTotal + payment.Arrear + payment.TermsAmount + payment.Reserve + payment.Principal + (payment.HandlingCharge || 0);
        else
          return payment.Stamp + payment.ServiceCharge - payment.Discount + extrasTotal + payment.Arrear + payment.TermsAmount + payment.Reserve + payment.Principal + (payment.HandlingCharge || 0);
      }

    }
    else
      return 0
  }

  getPaymentColor(totalPayment: number) {
    if (totalPayment == undefined || totalPayment == 0)
      return 'text-primary'
    else
      return 'text-success';
  }

  calculateTotalCustomerPayment() {
    var total = 0;

    for (let i = 0; i < this.paymentList.length; i++) {
      if (this.paymentList[i].totalPayment != undefined)
        total += this.paymentList[i].totalPayment;

      //add added loan into total payment

      if (this.paymentList[i].overlapAddedLoan != undefined && this.paymentList[i].overlapAddedLoan.length > 0) {
        for (let j = 0; j < this.paymentList[i].overlapAddedLoan.length; j++) {
          total -= this.sharedService.calculateTotalOnHand(this.paymentList[i].overlapAddedLoan[j]);
        }
      }
    }


    return total;
  }


  //if pay by simpan, the actual payment is the TOPPED UP SIMPAN + OVERLAP AMOUNT DIFFERENCE
  //EG: Original loan settled 300, Overlap loan 500, need give customer 200
  //Total payment still need customer top up 150 simpan
  //So ACTUAL PAYMENT =  150 -200 = -50 , MEANS only GIVE CUSTOMER 50
  calculateExtraOverlapAmount() {
    var total = 0;

    for (let i = 0; i < this.paymentList.length; i++) {
      if (this.paymentList[i].paymentDetails != undefined) {
        if (this.paymentList[i].totalPayment != undefined && this.paymentList[i].isOverlap == true) {
          total += this.paymentList[i].totalPayment;

          if (this.paymentList[i].paymentDetails.PayInterestAfterPrincipal == true) {
            total -= this.paymentList[i].paymentDetails.NewInterestAmount + this.paymentList[i].paymentDetails.Stamp + this.paymentList[i].paymentDetails.ServiceCharge;
          }
        }

      }


      //add added loan principal into total payment

      if (this.paymentList[i].overlapAddedLoan != undefined && this.paymentList[i].overlapAddedLoan.length > 0) {
        for (let j = 0; j < this.paymentList[i].overlapAddedLoan.length; j++) {
          total -= this.paymentList[i].overlapAddedLoan[j].Principal;
        }
      }
    }

    return total;
  }

  calculateTotalSimpanOut() {
    var total = 0;

    for (let i = 0; i < this.paymentList.length; i++) {
      if (this.paymentList[i].paymentDetails != undefined && this.paymentList[i].totalPayment != undefined) {

        if (this.paymentList[i].isOverlap == false)
          total += this.paymentList[i].totalPayment;
        else {
          if (this.paymentList[i].paymentDetails.PayInterestAfterPrincipal == true)
            total += this.paymentList[i].paymentDetails.NewInterestAmount + this.paymentList[i].paymentDetails.Stamp + this.paymentList[i].paymentDetails.ServiceCharge + (this.paymentList[i].paymentDetails.HandlingCharge || 0);
        }
      }



      //add added loan into total payment
      if (this.paymentList[i].overlapAddedLoan != undefined && this.paymentList[i].overlapAddedLoan.length > 0) {
        for (let j = 0; j < this.paymentList[i].overlapAddedLoan.length; j++) {
          total += this.paymentList[i].overlapAddedLoan[j].Reserve;
        }
      }
    }


    return this.sharedService.roundTo2Dec(total);
  }


  submit() {
    if (this.calculateTotalCustomerPayment() == 0 || this.selectedAccountId == undefined)
      this.sharedService.openAlert("Please fill in customer payment details and issue account!", this.enums.DANGER_ALERT);
    else {
      // var i = this.getNextPayingLoanPayment();


      //if is pay by simpan and contains overlap, need to do the overlap payment without using simpan first
      //after this add loan
      if (this.isPayBySimpan == true) {
        for (let i = 0; i < this.paymentList.length; i++) {
          if (this.paymentList[i].paymentDetails != undefined && this.paymentList[i].isOverlap == true) {
            this.addPayment(i);

            if (this.paymentList[i].paymentDetails.PayInterestAfterPrincipal == false)
              this.paymentList[i].paymentDetails = undefined;
            else {
              this.paymentList[i].paymentDetails.Principal = 0;
            }
          }

        }
      }

      //for others just add batch payment like normal
      this.addBatchPayment();

    }
  }


  checkIfNeedDoReserveGroup() {
    var activeEPFLoans = this.loanList.filter((loan: Loan) => (loan.Status != this.enums.SETTLED_LOAN && loan.Scheme == "EPF"));
    // console.log(activeEPFLoans)

    //if this is an EPF shared profile's loan
    if (activeEPFLoans.length > 0 && this.selectedProfile.SharedProfileId != undefined)
      this.openAddReserveGroup();
    else
      this.topUpSimpan();

  }

  openAddReserveGroup() {
    const modalRef = this.sharedService.openModal(AddReserveGroupComponent, "largeModal");
    modalRef.componentInstance.sharedProfileId = this.selectedProfile.SharedProfileId;
    modalRef.componentInstance.selectedProfileId = this.selectedProfile.ID;
    var activeLoan = this.loanList.filter((loan: Loan) => (loan.Status != this.enums.SETTLED_LOAN));
    modalRef.componentInstance.loanList = activeLoan;
    modalRef.componentInstance.selectedCustomer = this.selectedCustomer;
    modalRef.componentInstance.selectedProfile = this.selectedProfile;


    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var addedSimpan = entry;
      this.addedSimpan += addedSimpan;
      this.selectedProfile.Reserve += addedSimpan;

    });

  }

  topUpSimpan() {
    var modalRef = this.sharedService.openModal(AddSimpanComponent);
    modalRef.componentInstance.isModal = true;
    modalRef.componentInstance.selectedProfileID = this.selectedProfile.ID;
    modalRef.componentInstance.selectedProfile = this.selectedProfile;
    modalRef.componentInstance.selectedCustomer = this.selectedCustomer;

    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var addedSimpan = entry;

      this.addedSimpan += addedSimpan;
      this.selectedProfile.Reserve += addedSimpan;
    });
  }

  createPaymentWithSimpanArray() {
    var data;
    var paymentArray = [];
    for (let i = 0; i < this.paymentList.length; i++) {
      if (this.paymentList[i].paymentDetails != undefined) {
        var duitLebihAmount = this.sharedService.calculateDuitLebihAmount(this.paymentList[i].paymentDetails.Extras);


        // if contains principal payment
        if (this.paymentList[i].paymentDetails.Principal != 0) {
          if (this.paymentList[i].paymentDetails.PayInterestAfterPrincipal == true) {
            this.containsPayIntAfterPrincipal = true;
            data = {
              "loanId": this.paymentList[i].loan.ID,
              "principal": this.paymentList[i].paymentDetails.Principal,
              "accountId": Number(this.selectedAccountId),
              "extras": this.paymentList[i].paymentDetails.Extras,
              "dateCreated": this.paymentDate,
              "remark": "Pay Principal with Simpan=Loan " + this.paymentList[i].loan.LoanCode + "=" + this.paymentList[i].paymentDetails.Remark,
              "arrear": this.paymentList[i].paymentDetails.Arrear,
              "discount": this.paymentList[i].paymentDetails.Discount,
              "reserve": -this.paymentList[i].paymentDetails.Principal - duitLebihAmount -
                this.paymentList[i].paymentDetails.Arrear + this.paymentList[i].paymentDetails.Discount -
                this.paymentList[i].paymentDetails.HandlingCharge,
              "handlingCharge": this.paymentList[i].paymentDetails.HandlingCharge
            };
          }
          else {
            data = {
              "loanId": this.paymentList[i].loan.ID,
              "principal": this.paymentList[i].paymentDetails.Principal,
              "accountId": Number(this.selectedAccountId),
              "extras": this.paymentList[i].paymentDetails.Extras,
              "stamp": this.paymentList[i].paymentDetails.Stamp,
              "serviceCharge": this.paymentList[i].paymentDetails.ServiceCharge,
              "dateCreated": this.paymentDate,
              "remark": "Pay Principal with Simpan=Loan " + this.paymentList[i].loan.LoanCode + "=" + this.paymentList[i].paymentDetails.Remark,
              "arrear": this.paymentList[i].paymentDetails.Arrear,
              "discount": this.paymentList[i].paymentDetails.Discount,
              "reserve": -this.paymentList[i].paymentDetails.Principal - this.paymentList[i].paymentDetails.Stamp -
                this.paymentList[i].paymentDetails.ServiceCharge - duitLebihAmount - this.paymentList[i].paymentDetails.Arrear + this.paymentList[i].paymentDetails.Discount -
                this.paymentList[i].paymentDetails.HandlingCharge,

              "handlingCharge": this.paymentList[i].paymentDetails.HandlingCharge
            };
          }
        }

        //if did not contain principal payment, mainly interest / other schemes
        else {

          if (this.paymentList[i].paymentDetails.PayInterestAfterPrincipal == true) {
            this.paymentList[i].paymentDetails.Term = 1;
            data = {
              "loanId": this.paymentList[i].loan.ID,
              "term": Number(this.paymentList[i].paymentDetails.Term),
              "stamp": this.paymentList[i].paymentDetails.Stamp,
              "serviceCharge": this.paymentList[i].paymentDetails.ServiceCharge,
              "accountId": Number(this.selectedAccountId),
              "dateCreated": this.paymentDate,
              "reserve": -this.paymentList[i].paymentDetails.TermsAmount,
              "remark": "Part and Interest with Simpan =Loan " + this.paymentList[i].loan.LoanCode + "=" + this.paymentList[i].paymentDetails.Remark,
              "handlingCharge": this.paymentList[i].paymentDetails.HandlingCharge
            };

            this.paymentList[i].paymentDetails.Term = 0;
            this.paymentList[i].paymentDetails.PayInterestAfterPrincipal = false;

            if (this.paymentList[i].isOverlap == false)
              this.containsPayIntAfterPrincipal = false;
          }
          else {
            data = {
              "loanId": this.paymentList[i].loan.ID,
              "term": Number(this.paymentList[i].paymentDetails.Term),
              "accountId": Number(this.selectedAccountId),
              "dateCreated": this.paymentDate,
              "arrear": this.paymentList[i].paymentDetails.Arrear,
              "reserve": -this.paymentList[i].paymentDetails.TermsAmount - this.paymentList[i].paymentDetails.Stamp - this.paymentList[i].paymentDetails.ServiceCharge - this.paymentList[i].paymentDetails.Arrear - duitLebihAmount + this.paymentList[i].paymentDetails.Discount -
                this.paymentList[i].paymentDetails.HandlingCharge,
              "stamp": this.paymentList[i].paymentDetails.Stamp,
              "extras": this.paymentList[i].paymentDetails.Extras,
              "serviceCharge": this.paymentList[i].paymentDetails.ServiceCharge,
              "discount": this.paymentList[i].paymentDetails.Discount,
              "remark": "Pay with Simpan =Loan " + this.paymentList[i].loan.LoanCode + "=" + this.paymentList[i].paymentDetails.Remark,
              "handlingCharge": this.paymentList[i].paymentDetails.HandlingCharge

            };
          }

        }

        //switch contains discount boolean to true, after it is set to true then can ignore the rest of the payments
        if (this.containsDiscountInPayment == false && this.paymentList[i].paymentDetails.Discount != undefined || this.paymentList[i].paymentDetails.Discount != 0)
          this.containsDiscountInPayment = true;

        //switch contains epf payment boolean to true, after it is set to true then can ignore the rest of the payments since only need to know whether the whole group contain any epf payment
        if (this.containsEPFPayment == false && (this.paymentList[i].loan.Scheme == 'EPF' || this.paymentList[i].loan.IsEPF == true))
          this.containsEPFPayment = true;

        paymentArray.push(data);
      }

    }

    return paymentArray;
  }


  createPaymentArray() {
    var data;
    var paymentArray = [];
    for (let i = 0; i < this.paymentList.length; i++) {

      if (this.paymentList[i].paymentDetails != undefined) {
        if (this.paymentList[i].paymentDetails.Principal != 0) {

          if (this.paymentList[i].loan.Scheme == 'A') {

            if (this.paymentList[i].paymentDetails.PayInterestAfterPrincipal == true) {
              data = {
                "loanId": this.paymentList[i].loan.ID,
                "principal": this.paymentList[i].paymentDetails.Principal,
                "accountId": Number(this.selectedAccountId),
                "dateCreated": this.paymentDate,
                "remark": this.paymentList[i].paymentDetails.Remark,
              };
            } else {
              data = {
                "loanId": this.paymentList[i].loan.ID,
                "principal": this.paymentList[i].paymentDetails.Principal,
                "accountId": Number(this.selectedAccountId),
                "stamp": this.paymentList[i].paymentDetails.Stamp,
                "extras": this.paymentList[i].paymentDetails.Extras,
                "serviceCharge": this.paymentList[i].paymentDetails.ServiceCharge,
                "arrear": this.paymentList[i].paymentDetails.Arrear,
                "dateCreated": this.paymentDate,
                "remark": this.paymentList[i].paymentDetails.Remark,
                "discount": this.paymentList[i].paymentDetails.Discount,
                "reserve": this.paymentList[i].paymentDetails.Reserve,
                "handlingCharge": this.paymentList[i].paymentDetails.HandlingCharge
              };
            }

          }
          else {

            var term = this.paymentList[i].paymentDetails.Principal / this.paymentList[i].loan.MonthlyPayment;
            data = {
              "loanId": this.paymentList[i].loan.ID,
              "term": term,
              "accountId": Number(this.selectedAccountId),
              "stamp": this.paymentList[i].paymentDetails.Stamp,
              "extras": this.paymentList[i].paymentDetails.Extras,
              "serviceCharge": this.paymentList[i].paymentDetails.ServiceCharge,
              "arrear": this.paymentList[i].paymentDetails.Arrear,
              "dateCreated": this.paymentDate,
              "remark": this.paymentList[i].paymentDetails.Remark,
              "discount": this.paymentList[i].paymentDetails.Discount,
              "reserve": this.paymentList[i].paymentDetails.Reserve,
              "handlingCharge": this.paymentList[i].paymentDetails.HandlingCharge
            };
          }


          if (this.paymentList[i].paymentDetails.PayInterestAfterPrincipal == true) {
            this.containsPayIntAfterPrincipal = true;
          }

        }

        //if principal =0
        else {
          data = {
            "loanId": this.paymentList[i].loan.ID,
            "term": Number(this.paymentList[i].paymentDetails.Term),
            "accountId": Number(this.selectedAccountId),
            "stamp": this.paymentList[i].paymentDetails.Stamp,
            "extras": this.paymentList[i].paymentDetails.Extras,
            "serviceCharge": this.paymentList[i].paymentDetails.ServiceCharge,
            "arrear": this.paymentList[i].paymentDetails.Arrear,
            "dateCreated": this.paymentDate,
            "remark": this.paymentList[i].paymentDetails.Remark,
            "discount": this.paymentList[i].paymentDetails.Discount,
            "reserve": this.paymentList[i].paymentDetails.Reserve,
            "handlingCharge": this.paymentList[i].paymentDetails.HandlingCharge
          };

          if (this.paymentList[i].paymentDetails.PayInterestAfterPrincipal == true) {
            this.paymentList[i].paymentDetails.Term = 0;
            this.paymentList[i].paymentDetails.PayInterestAfterPrincipal = false;
            this.containsPayIntAfterPrincipal = false;
          }
        }


        //switch contains discount boolean to true, after it is set to true then can ignore the rest of the payments
        if (this.containsDiscountInPayment == false && this.paymentList[i].paymentDetails.Discount != undefined || this.paymentList[i].paymentDetails.Discount != 0)
          this.containsDiscountInPayment = true;

        //switch contains epf payment boolean to true, after it is set to true then can ignore the rest of the payments since only need to know whether the whole group contain any epf payment
        if (this.containsEPFPayment == false && (this.paymentList[i].loan.Scheme == 'EPF' || this.paymentList[i].loan.IsEPF == true))
          this.containsEPFPayment = true;

        paymentArray.push(data);
      }

    }

    return paymentArray;
  }


  addPayment(i: number) {
    this.disabledSubmit = true;
    var data;
    if (this.paymentList[i].paymentDetails.Principal != 0) {

      if (this.paymentList[i].loan.Scheme == 'A') {
        data = {
          "principal": this.paymentList[i].paymentDetails.Principal,
          "accountId": Number(this.selectedAccountId),
          "dateCreated": this.paymentDate,
          "remark": this.paymentList[i].paymentDetails.Remark,
        };
      }
      else {
        data = {
          "term": this.paymentList[i].paymentDetails.Principal / this.paymentList[i].loan.MonthlyPayment,
          "accountId": Number(this.selectedAccountId),
          "dateCreated": this.paymentDate,
          "remark": this.paymentList[i].paymentDetails.Remark,
        };
      }


    }
    else {

      if (this.paymentList[i].paymentDetails.PayInterestAfterPrincipal == true) {
        data = {
          "term": Number(this.paymentList[i].paymentDetails.Term),
          "accountId": Number(this.selectedAccountId),
          "dateCreated": this.paymentList[i].paymentDetails.DateCreated,
          "remark": "Part and Interest:" + this.paymentList[i].paymentDetails.Remark
        };
        this.paymentList[i].paymentDetails.PayInterestAfterPrincipal = false;
      }
    }
    var xhr = this.restapi.postRequest(this.constructApi.getAddPayment(this.paymentList[i].loan.ID), data);
    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        if (xhr.status == 200) {
        }
        else
          this.sharedService.openErrorMessage(xhr);
      }

    }
  }






  addOverlapLoansOrReload() {
    if (this.containsAddLoan == true) {
      for (let i = 0; i < this.paymentList.length; i++) {
        if (this.paymentList[i].overlapAddedLoan != undefined && this.paymentList[i].overlapAddedLoan.length > 0) {
          //if CONTAINS NEW LOAN, add them
          this.addLoans(this.paymentList[i].overlapAddedLoan);
        }
      }
    }
    else {
      this.resetAndReload();
    }
  }

  addBatchPayment() {
    this.disabledSubmit = true;
    var data;
    var xhr;


    data = (this.isPayBySimpan == true) ? this.createPaymentWithSimpanArray() : this.createPaymentArray();

    if (data != undefined && data.length > 0) {
      xhr = (this.isPayBySimpan == true) ? this.restapi.postRequest(this.constructApi.getAddBatchSimpanPayment(), data) : this.restapi.postRequest(this.constructApi.getAddBatchPayment(), data);


      xhr.onreadystatechange = () => {
        if (xhr.readyState == 4) {
          this.disabledSubmit = false;
          if (xhr.status == 200) {

            // if the payment contains any epf payment, add all the transactions EXCEPT SIMPAN OUT into set
            if (this.containsEPFPayment == true) {
              var allTransactionIds: number[] = new Array;
              var jsonArray = JSON.parse((xhr.responseText));


              // only non-share profile need to upload epf transactions set
              // because share profile will need to upload the receipt when adding reserve
              // if share profile require to upload receipts again when making payment will have duplicate receipts
              if (this.selectedProfile.SharedProfileId == undefined) {
                jsonArray.forEach(json => {
                  if ((json.type != this.enums.RESERVE && json.amount > 0) || json.type == this.enums.DISCOUNT)
                    allTransactionIds.push(Number(json.id));
                }
                );
              }



              if (allTransactionIds.length > 0) {
                this.addEPFSetAndContinuePayment(allTransactionIds, this.selectedCustomer)
              } else
                this.continuePayment();
            }


            //if other than EPF payment but contains discount, add discount transaction into set
            else if (this.containsDiscountInPayment == true) {
              var discountTransactionIds: number[] = new Array;
              var jsonArray = JSON.parse((xhr.responseText));

              jsonArray.forEach(json => {
                if (Number(json.type) == this.enums.DISCOUNT)
                  discountTransactionIds.push(Number(json.id));
              }
              );

              if (discountTransactionIds.length > 0) {
                this.addDiscountSetAndContinuePayment(discountTransactionIds, this.selectedCustomer)
              } else
                this.continuePayment();

            }

            //if just normal payment, continue payment
            else {
              this.continuePayment();
            }

          }
          else
            this.sharedService.openErrorMessage(xhr);
        }

      }
    } else {
      this.addOverlapLoansOrReload();
    }

  }

  addDiscountSetAndContinuePayment(idsToAdd: number[], customer: Customer) {
    this.disabledSubmit = true;

    var companyId = Number(sessionStorage.getItem("companyId"))
    var data = {
      "transactionIds": idsToAdd,
      "remark": customer.ID + "=" + customer.Name + "(" + customer.ICNumber + ")",
      "name": "DISCOUNT FOR " + customer.Name
    }


    var xhr = this.restapi.postRequest(this.constructApi.getAddNewCompanySet(companyId), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;

        if (xhr.status == 200) {
          var json = JSON.parse(xhr.responseText);
          this.addedDiscountSetId = Number(json.id);

          this.continuePayment();
        }
        else {
          var json = JSON.parse(xhr.responseText);
          this.sharedService.openAlert(json.error, this.enums.DANGER_ALERT)
        }

      }

    }
  }

  addEPFSetAndContinuePayment(idsToAdd: number[], customer: Customer) {
    this.disabledSubmit = true;

    var companyId = Number(sessionStorage.getItem("companyId"))
    var data = {
      "transactionIds": idsToAdd,
      "remark": customer.ID + "=" + customer.Name + "(" + customer.ICNumber + ")",
      "name": "EPF PAYMENT FOR " + customer.Name
    }


    var xhr = this.restapi.postRequest(this.constructApi.getAddNewCompanySet(companyId), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;

        if (xhr.status == 200) {

          var json = JSON.parse(xhr.responseText);
          this.addedEPFPaymentSetId = Number(json.id);

          this.continuePayment();
        }
        else {
          var json = JSON.parse(xhr.responseText);
          this.sharedService.openAlert(json.error, this.enums.DANGER_ALERT)
        }

      }

    }
  }

  continuePayment() {
    if (this.containsPayIntAfterPrincipal == true) {
      for (let i = 0; i < this.paymentList.length; i++) {
        if (this.paymentList[i].paymentDetails != undefined && this.paymentList[i].paymentDetails.PayInterestAfterPrincipal == true) {
          this.paymentList[i].paymentDetails.Principal = 0;
          this.paymentList[i].paymentDetails.Term = 1;
        }
        else
          this.paymentList[i].paymentDetails = undefined;
      }
      this.addBatchPayment();
    }
    else {
      this.addOverlapLoansOrReload();
    }
  }

  resetAndReload() {
    this.reset();
    this.sharedService.openAlert("Successfully added payment!", this.enums.SUCCESS_ALERT);

    if (this.containsAddLoan == false) {
      if (this.addedEPFPaymentSetId != undefined) {
        var modalRef = this.sharedService.openModal(ReceiptDetailsComponent, "largeModal");
        modalRef.componentInstance.receiptId = Number(this.addedEPFPaymentSetId);
        modalRef.componentInstance.isModal = true;
      }
      else if (this.addedDiscountSetId != undefined) {
        var modalRef = this.sharedService.openModal(ReceiptDetailsComponent, "largeModal");
        modalRef.componentInstance.receiptId = Number(this.addedDiscountSetId);
        modalRef.componentInstance.isModal = true;
      }
      else {
        window.location.reload();
      }
    }

  }


  addLoans(loans: Loan[]) {
    this.disabledSubmit = true;
    var dataArr = new Array;
    this.containsAddLoan == true;

    for (let i = 0; i < loans.length; i++) {
      var data = {
        "loanCode": loans[i].LoanCode,
        "principal": loans[i].Principal,
        "profileID": this.selectedProfile.ID,
        "dateCreated": this.paymentDate.toISOString(),
        "scheme": loans[i].Scheme,
        "term": loans[i].Term,
        "interestRate": loans[i].InterestRate,
        "stamp": loans[i].StampAmount,
        "serviceCharge": loans[i].ServiceCharge,
        "extras": loans[i].Extras,
        "processingRate": loans[i].ProcessingRate,
        "accountId": Number(this.selectedAccountId),
        "reserve": -loans[i].Reserve
      }
      dataArr.push(data)
    }

    var xhr = this.restapi.postRequest(this.constructApi.getAddLoans(), dataArr)
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        //if success , then proceed
        if (xhr.status == 200) {
          var jsonArray = JSON.parse(xhr.responseText);

          jsonArray.forEach(json => {
            var loan = new Loan(json.id, json.profileId, json.principal, json.stampAmount, json.receiptNumber,
              json.status, json.dateted, json.companyId, json.companyName,
              json.scheme, json.interestRate, json.term, json.customerName,
              json.icNumber, json.interestCharge, json.issuer, json.loanCode, json.arrear, json.principalPaid,
              json.repayment, json.reserve)

            //if successfully added loan, need to assign commission
            if (this.sharedService.isBranchAccountHandler())
              this.openAssignCommissionModal(loan);

          }
          );
          this.reset();
        }
        else {
          //show error message
          this.sharedService.openAlert(JSON.parse(xhr.responseText).error, this.enums.DANGER_ALERT)

        }
      }
    }
  }


  openAssignCommissionModal(loan: Loan) {
    const modalRef = this.sharedService.openModal(AddCommissionsComponent);
    modalRef.componentInstance.selectedLoan = loan;
  }


  reset() {
    this.activeModal.close();
  }
}
