<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Add Admin Remark</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()" aria-label="Close"></button>
    </div>

    <div class="modal-body">
        <div class="container-fluid p-0">
            <div class="mt-2">
                <div class="col">
                    <div><span>Remark</span></div>
                    <div><textarea class="form-control" rows="10" required [(ngModel)]="adminRemark"
                            name="adminRemark"></textarea></div>
                </div>

                <div class="row">
                    <div class="col-12 py-2 mb-4">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="checkboxAttention"
                                [(ngModel)]="needAttention" [value]="needAttention" name="isEpf">
                            <label class=" form-check-label checkBoxLabel text-info" for="checkboxAttention">Need
                                Attention</label>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="submit()" [disabled]="disabledSubmit">Add
            Remark</button>
        <button class="btn btn-outline-danger" type="button" data-dismiss="modal" (click)="reset()">Cancel</button>
    </div>
</form>