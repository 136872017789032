import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class VersionStatus {

    clientVersion = "v0.41.8";

}