import { Component, OnInit } from '@angular/core';
import { stringify } from 'querystring';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/auth/auth.service';
import { Role } from 'src/model/role.model';
import { Company } from 'src/model/company.model';
import { RestApiService } from 'src/app/API/restapi';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { AlertDialogComponent } from 'src/app/Shared/alert-dialog/alert-dialog.component';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Enums } from 'src/app/Shared/enums';


@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {
  imgURL: any;
  imageUrls: Array<string>;

  newPassword: string;
  confirmPassword: string;

  loggedInUsername: string;
  loggedInUserRole: string;


  roleList: Role[] = new Array;
  selectCompanyList: Company[] = new Array;


  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private _auth: AuthService,
    private restapi: RestApiService, private constructApi: ConstructAPI, private sharedService: SharedService, private enums: Enums) {

  }

  ngOnInit() {
    this.loggedInUsername = sessionStorage.getItem('username');
    // this.getAllRoles();
    // this.getCompanyList();
    // this.getCompanyList();
  }

  handleFileInput(fileList: FileList) {
    // const preview = document.getElementById('preview');
    this.imageUrls = new Array();

    Array.from(fileList).forEach((file: File) => {
      const reader = new FileReader();
      reader.onload = () => {
        var result = String(reader.result);
        this.imageUrls.push(result);
      }
      reader.readAsDataURL(file);
    });
  }

  getSelectedRole(roleID: number) {

    var loggedInRoleID = Number(sessionStorage.getItem("roleId"));
    if (roleID == loggedInRoleID)
      return true;
    else
      return false;

  }



  getSelectedCompany(companyId: number) {

    var loggedInCompanyID = Number(sessionStorage.getItem("companyId"));
    if (companyId == loggedInCompanyID)
      return true;
    else
      return false;

  }


  updateProfile() {
    var error = this.validatePassword();

    if (error != "")
      this.sharedService.openAlert("Oops! Please fix the error(s) before proceed!" + error, this.enums.DANGER_ALERT);
    else {
      this.update();
    }

  }

  update() {
    // var xhr = new XMLHttpRequest();
    // var url = "http://localhost:10080/api/v1/changePassword";
    // xhr.open("PUT", url, true);
    // xhr.setRequestHeader("Content-type", "application/json");
    // xhr.withCredentials = true;

    var data = { "password": this.newPassword };

    var xhr = this.restapi.putRequest(this.constructApi.getChangePassword(), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {

        this.reset();
        this.sharedService.openAlert("Successfully changed password!", this.enums.SUCCESS_ALERT);
        this._auth.logout();

      }
    }

  }


  reset() {
    this.activeModal.close();
    this.newPassword = "";
    this.confirmPassword = "";
  }

  private getAllRoles() {
    // var xhttp = new XMLHttpRequest();
    // var url = "http://localhost:10080/api/v1/roles";
    // xhttp.withCredentials = true;

    var xhttp = this.restapi.getRequest(this.constructApi.getAllRoles())
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // Typical action to be performed when the document is ready:

        var jsonArray = JSON.parse((xhttp.responseText));
        // console.log(jsonArray);

        jsonArray.forEach(json => {

          // create role instance with all response data
          let role = new Role(json.id, json.name);

          //add role instance into role list
          this.roleList.push(role);
        }
        );

      }
    };
    // xhttp.open("GET", url, true);
    // xhttp.send();

  }


  private getCompanyList() {
    // var xhttp = new XMLHttpRequest();
    // var url = "http://localhost:10080/api/v1/companies";
    // xhttp.withCredentials = true;

    var xhttp = this.restapi.getRequest(this.constructApi.getAllCompanies())
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // Typical action to be performed when the document is ready:

        var jsonArray = JSON.parse((xhttp.responseText));
        // console.log(jsonArray);

        jsonArray.forEach(json => {

          // create role instance with all response data
          let company = new Company(json.id, json.name, json.address, json.companyCode, json.contactNo)

          //add role instance into role list
          this.selectCompanyList.push(company);
        }
        );

      }
    };
    // xhttp.open("GET", url, true);
    // xhttp.send();

  }

  validatePassword() {
    var errorMessage: string = "";

    if (this.newPassword == "" || this.confirmPassword == "") {
      errorMessage += "\n❌Don't leave password empty"
    }
    else {
      if (this.newPassword != this.confirmPassword) {
        errorMessage += "\n❌Password doesn't match"
      }

      if (this.newPassword.length < 10) {
        errorMessage += "\n❌Password length must be more than 10 characters"
      }
    }
    return errorMessage;

  }



}
