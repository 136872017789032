import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { ReceiptDetailsComponent } from 'src/app/Sets/receipt-details/receipt-details.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Account } from 'src/model/account.model';
import { Customer } from 'src/model/customer.model';
import { Loan } from 'src/model/loan.model';
import { Payment } from 'src/model/payment.model';
import { Set } from 'src/model/set.model';

@Component({
  selector: 'app-add-payment-modal',
  templateUrl: './add-payment-modal.component.html',
  styleUrls: ['./add-payment-modal.component.css']
})
export class AddPaymentModalComponent implements OnInit {

  constructor(private enums: Enums, private activeModal: NgbActiveModal, private restapi: RestApiService, private constructApi: ConstructAPI, private sharedService: SharedService) { }
  @Input() selectedLoan: Loan;
  @Input() selectedCustomer: Customer;

  companyAccountList: Account[] = new Array;
  selectedAccountId: number;

  payment: Payment;
  disabledSubmit = false;

  addedDiscountSetId: number;
  ngOnInit(): void {
    var companyId = Number(sessionStorage.getItem("companyId"));
    this.getAllAccountsOfCompany(companyId);
  }

  private getAllAccountsOfCompany(companyId: number) {

    var xhttp = this.restapi.getRequest(this.constructApi.getAccountsOfCompany(companyId));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let account = new Account(json.id, json.number, json.name, json.holderName, json.balance, json.companyId, json.username);

          var loggedInUser = sessionStorage.getItem("username");
          this.companyAccountList.push(account);
        }
        );
      }
    };

  }
  getLoanPaymentData(details: Payment) {
    this.payment = details;
  }

  submit() {
    if (this.payment == undefined || this.selectedAccountId == undefined)
      this.sharedService.openAlert("Please fill in customer payment details and issue account!", this.enums.DANGER_ALERT);
    else {
      this.addPayment();
    }
  }


  addPayment() {

    this.disabledSubmit = true;
    var data;

    if (this.payment.Principal != 0) {

      //If need pay interest after principal, only pay principal at this transaction
      //when next payment doing interest only insert all the other charges :simpan, stamp, sc, dl
      if (this.payment.PayInterestAfterPrincipal == true) {
        data = {
          "principal": this.payment.Principal,
          "accountId": Number(this.selectedAccountId),
          "dateCreated": this.payment.DateCreated,
          "remark": this.payment.Remark,
        };

      }
      else {
        data = {
          "principal": this.payment.Principal,
          "accountId": Number(this.selectedAccountId),
          "stamp": this.payment.Stamp,
          "extras": this.payment.Extras,
          "serviceCharge": this.payment.ServiceCharge,
          "arrear": this.payment.Arrear,
          "dateCreated": this.payment.DateCreated,
          "remark": this.payment.Remark,
          "discount": this.payment.Discount,
          "reserve": this.payment.Reserve
        };
      }


    }
    else {

      data = {
        "term": Number(this.payment.Term),
        "accountId": Number(this.selectedAccountId),
        "stamp": this.payment.Stamp,
        "extras": this.payment.Extras,
        "serviceCharge": this.payment.ServiceCharge,
        "arrear": this.payment.Arrear,
        "dateCreated": this.payment.DateCreated,
        "remark": this.payment.Remark,
        "discount": this.payment.Discount,
        "reserve": this.payment.Reserve
      };

      if (this.payment.PayInterestAfterPrincipal == true)
        this.payment.PayInterestAfterPrincipal = false;

    }
    var xhr = this.restapi.postRequest(this.constructApi.getAddPayment(this.selectedLoan.ID), data);
    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        if (xhr.status == 200) {
          this.closeModal();


          //first: check if the selected loan is EPF loan, if yes, add all the transactions into set
          if (this.selectedLoan.Scheme == 'EPF' || this.selectedLoan.IsEPF == true) {
            var jsonArray = JSON.parse((xhr.responseText));

            var allTransactionIds: number[] = new Array;
            jsonArray.forEach(json => {
              allTransactionIds.push(Number(json.id));
            }
            );

            if (allTransactionIds != undefined && allTransactionIds.length > 0)
              this.addEPFSetAndContinuePayment(allTransactionIds, this.selectedLoan);
          }

          // if it is normal loan with discount, add DISCOUNT transaction into set
          else if (this.payment.Discount != 0 && this.payment.Discount != undefined) {

            var jsonArray = JSON.parse((xhr.responseText));

            jsonArray.forEach(json => {

              if (Number(json.type) == this.enums.DISCOUNT)
                this.addDiscountSetAndContinuePayment([Number(json.id)], this.selectedLoan);
            }
            );
          }

          // if is just normal payment, continue to pay
          else
            this.continueOrCompletePayment();

        } else
          this.sharedService.openErrorMessage(xhr);
      }

    }
  }

  continueOrCompletePayment() {
    if (this.payment.Principal != 0 && this.payment.PayInterestAfterPrincipal == true) {
      this.payment.Principal = 0;
      this.payment.Term = 1;
      this.addPayment();

    } else {
      this.sharedService.openAlert("Successfully added payment!", this.enums.SUCCESS_ALERT);
      // window.location.reload();
      if (this.addedDiscountSetId != undefined) {
        var modalRef = this.sharedService.openModal(ReceiptDetailsComponent, "largeModal");
        modalRef.componentInstance.receiptId = Number(this.addedDiscountSetId);
        modalRef.componentInstance.isModal = true;
      } else {
        window.location.reload();
      }

    }
  }



  addEPFSetAndContinuePayment(idsToAdd: number[], loan: Loan) {
    this.disabledSubmit = true;

    var companyId = Number(sessionStorage.getItem("companyId"))
    var data = {
      "transactionIds": idsToAdd,
      "remark": this.selectedCustomer.ID + "=" + this.selectedCustomer.Name + "(" + this.selectedCustomer.ICNumber + ")",
      "name": "EPF PAYMENT OF " + this.selectedCustomer.Name + "(" + loan.ID + ")"
    }


    var xhr = this.restapi.postRequest(this.constructApi.getAddNewCompanySet(companyId), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;

        if (xhr.status == 200) {

          var json = JSON.parse(xhr.responseText);
          this.addedDiscountSetId = Number(json.id);

          this.continueOrCompletePayment();
        }
        else {
          var json = JSON.parse(xhr.responseText);
          this.sharedService.openAlert(json.error, this.enums.DANGER_ALERT)
        }

      }

    }
  }

  addDiscountSetAndContinuePayment(idsToAdd: number[], loan: Loan) {
    this.disabledSubmit = true;

    var companyId = Number(sessionStorage.getItem("companyId"))
    var data = {
      "transactionIds": idsToAdd,
      "remark": this.selectedCustomer.ID + "=" + this.selectedCustomer.Name + "(" + this.selectedCustomer.ICNumber + ")",
      "name": "DISCOUNT FOR " + loan.LoanCode + "(" + loan.ID + ")"
    }


    var xhr = this.restapi.postRequest(this.constructApi.getAddNewCompanySet(companyId), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;

        if (xhr.status == 200) {

          var json = JSON.parse(xhr.responseText);
          this.addedDiscountSetId = Number(json.id);

          this.continueOrCompletePayment();
        }
        else {
          var json = JSON.parse(xhr.responseText);
          this.sharedService.openAlert(json.error, this.enums.DANGER_ALERT)
        }

      }

    }
  }

  closeModal() {
    this.activeModal.close();
  }
}
