import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { AlertDialogComponent } from 'src/app/Shared/alert-dialog/alert-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.css']
})
export class UploadImageComponent implements OnInit {


  @Input() selectedCustomerId: number;
  @Input() supportingDocType: string;
  @Input() contactId: number;
  @Input() contactDocType: string;
  @Input() isSetDocument: boolean;
  @Input() setId: number;
  @Input() loanId: number;
  @Input() isLoanDocument: boolean = false;
  @Input() isReserveGroupDocument: boolean = false;
  @Input() reserveGroupId: number;



  @Input() isSingleType: boolean = true;

  disableSubmit = true;

  companyId: number;

  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private restApi: RestApiService, private constructApi: ConstructAPI,
    private sharedService: SharedService, public enums: Enums) { }
  image: string;
  file: File;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  ngOnInit(): void {

    this.companyId = Number(sessionStorage.getItem("companyId"));
  }

  handleFileInput(files: FileList) {
    this.disableSubmit = false;
    this.file = files.item(0);

  }

  addImage(uploadType?: string) {

    if (this.file == undefined) {
      this.disableSubmit = true;
      this.sharedService.openAlert("Please select 1 file before proceed!", this.enums.DANGER_ALERT);

    } else {
      this.disableSubmit = true;

      // var companyId = Number(sessionStorage.getItem("companyId"))
      var xhr;
      //use different endpoint if different picture to upload

      if (uploadType != undefined)
        xhr = this.restApi.postFileRequest(this.constructApi.getUploadApplicationForm(this.selectedCustomerId, uploadType), this.file);

      else if (this.supportingDocType != undefined)
        xhr = this.restApi.postFileRequest(this.constructApi.getUploadApplicationForm(this.selectedCustomerId, this.supportingDocType), this.file);

      else if (this.contactId != undefined) {
        if (this.contactDocType != undefined)
          xhr = this.restApi.postFileRequest(this.constructApi.getUploadContactTypedFile(this.selectedCustomerId, this.contactId, this.contactDocType), this.file);
        else
          xhr = this.restApi.postFileRequest(this.constructApi.getUploadContactGuarantorForm(this.selectedCustomerId, this.contactId), this.file);
      }
      else if (this.isSetDocument) {
        xhr = this.restApi.postFileRequest(this.constructApi.getUploadSetFile(this.setId), this.file);
      }
      else if (this.isLoanDocument) {
        xhr = this.restApi.postFileRequest(this.constructApi.getUploadLoanTypedFile(this.selectedCustomerId, this.loanId, this.enums.PAYOUT_SLIP), this.file);
      }

      else if (this.isReserveGroupDocument) {
        xhr = this.restApi.postFileRequest(this.constructApi.getUploadReserveGroupFile(this.reserveGroupId), this.file);
      }
      else
        xhr = this.restApi.postFileRequest(this.constructApi.getUploadFile(this.selectedCustomerId), this.file);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {

          if (xhr.status == 200) {
            this.disableSubmit = false;


            if (this.loanId != undefined)
              this.passEntry.emit(this.loanId);
            else
              this.passEntry.emit(this.selectedCustomerId);

            this.sharedService.openAlert("Done Upload", this.enums.SUCCESS_ALERT);
            this.file = undefined;

            if (this.isSingleType == true) {
              this.reset();
            }



          }
          else {
            this.disableSubmit = false;
            var json = JSON.parse(xhr.responseText);
            this.sharedService.openAlert(json.error, this.enums.DANGER_ALERT);
          }

        }
      };
    }


  }

  reset() {
    this.file = undefined;
    this.activeModal.close();
  }

}
