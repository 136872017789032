export class AdminRemark {
    constructor(
        private id: number, private author: string, private customerId: number, private date: Date, private remark: string, private needAttention: boolean) { }


    set ID(_id: number) { this.id = _id }
    get ID(): number { return this.id }

    set Author(_author: string) { this.author = _author }
    get Author(): string { return this.author }

    set Date(_date: Date) { this.date = _date }
    get Date(): Date { return this.date }


    set CustomerId(_customerId: number) { this.customerId = _customerId }
    get CustomerId(): number { return this.customerId }

    set Remark(_remark: string) { this.remark = _remark }
    get Remark(): string { return this.remark }

    set NeedAttention(_needAttention: boolean) { this.needAttention = _needAttention }
    get NeedAttention(): boolean { return this.needAttention }
}