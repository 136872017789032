<div class="modal-header" style="background-color:lightgray;">
    <h4 class="modal-title">Expense Admin Remark</h4>
    <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
</div>

<div class="m-3">
    <!--Remark-->
    <div class="group">
        <input type="text" required class="textInputLong" name="remark" [(ngModel)]="remark">
        <span class="highlight"></span>
        <span class="bar barLong"></span>
        <label> Remark</label>
    </div>
    <div class="group">
        <p> Tags:</p>
        <textarea col="100" row="100" placeholder="Separate with commas(,)" [(ngModel)]="tagName"></textarea>

    </div>
    <!-- <hr /> -->
    <!-- <div class="table-responsive table-borderless m-1">
        <table class="table table-bordered">
            <tbody>
                <tr>
                    <td><strong>Tag Name</strong></td>
                    <td><input type="text" [(ngModel)]="tagName" name="loanSchemeName">
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="row">
        <div class="col d-flex justify-content-end" style="padding: 0;margin-bottom: 2%;" (click)="addTagIntoList()">
            <button class="btn btn-success" type="button">Add</button>
        </div>
    </div>

    <div class="row" style="margin: 0;">
        <div class="col p-0">
            <div class="card shadow-lg">
                <div class="card-body">
                    <h4 class="card-title">Added Tag<br></h4>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="text-center">Tag</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let tag of tagList;let i = index">
                                <td class="text-center">{{tag}}</td>
                                <td class="text-center">
                                    <i class="fas fa-times" style="cursor: pointer;"
                                        (click)="confirmBeforeRemove(tag,i)"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div> -->
</div>

<div class="modal-footer">
    <button class="btn btn-outline-success" type="button" (click)="submit()" [disabled]="disabledSubmit">Save</button>
    <button class="btn btn-outline-danger" type="button" data-dismiss="modal" (click)="reset()">Cancel</button>
</div>