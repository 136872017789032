import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { GlobalSearchCustomerComponent } from 'src/app/Others/global-search-customer/global-search-customer.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { EPFLimit } from 'src/model/epfLimit.model';
import { GlobalCustomer } from 'src/model/globalCustomer.model';
import { Loan } from 'src/model/loan.model';
import { SettlementCharges } from 'src/model/settlementCharges.model';

@Component({
  selector: 'app-epf-request-calculator',
  templateUrl: './epf-request-calculator.component.html',
  styleUrls: ['./epf-request-calculator.component.css'],
  providers: [DatePipe]
})
export class EpfRequestCalculatorComponent implements OnInit {

  constructor(private activeModal: NgbActiveModal, public sharedService: SharedService, public enums: Enums,
    private restApi: RestApiService, private constructAPI: ConstructAPI, private datepipe: DatePipe) { }


  @Input() epfLimitRuleSet: EPFLimit[];

  epfDate: Date;
  epfAmount: number;


  loanTerm: number = 0;
  loanAmount: number = 0;
  settlementAmount: number = 0;
  interestRate: number = 0;

  processStamp = 0;
  processSc = 0;
  processDL = 0;

  isCollapsed = false;

  isExtra = false;
  MINIMUM_INTEREST = 0.05

  // previousPrincipal = 0;
  previousEPFLoans: Loan[] = new Array;
  customer: GlobalCustomer;

  cashOnHand: number;

  extraChargesCollected = 0;
  settlementCharges: SettlementCharges;
  totalDeductions = 0;

  settlementStamp: number = 0;
  settlementSC: number = 0;
  settlementExtras: number = 0;



  ngOnInit(): void {

  }


  resetExtra() {
    // this.isExtra = !this.isExtra;
    this.customer = undefined;
    this.epfAmount = undefined;
    this.epfDate = undefined;
    this.loanTerm = undefined;
    this.previousEPFLoans = new Array;
    this.settlementCharges = undefined;
    this.settlementStamp = undefined;
    this.settlementSC = undefined;
    this.settlementExtras = undefined;
    this.totalDeductions = undefined;
    this.extraChargesCollected = undefined;

  }

  getCustomerLoanInfo(customer: GlobalCustomer) {

    var status = [this.enums.ACTIVE_LOAN];
    var schemes = ["EPF"]
    this.previousEPFLoans = new Array;

    // var xhttp = this.restApi.getRequest(this.constructAPI.getLoanOfCustomer(customer.CustomerID, status, schemes, true));

    var companyId = (this.sharedService.isOperatingUser()) ? Number(sessionStorage.getItem("companyId")) : customer.CompanyId;
    // var xhttp = this.restApi.getRequest(this.constructAPI.getCompanyLoans(companyId, status, schemes, true, customer.SharedProfileId, true));
    var xhttp = this.restApi.getRequest(this.constructAPI.getLoanOfCustomer(customer.CustomerID, status, schemes, true, customer.SharedProfileId, true));
    // var xhttp = this.restApi.getRequest(this.constructAPI.getAllProfileLoans(customer.ProfileID, "EPF", status, true,true))


    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));
        if (jsonArray.length == 0) {
          this.sharedService.openAlert("This profile do not have active EPF loan(s)", this.enums.DANGER_ALERT);
          this.resetExtra();
        }

        else {
          jsonArray.forEach(json => {


            var settlementCharges = (json.settlementCharges.serviceCharge == undefined || json.settlementCharges.stamp == undefined) ? undefined : new SettlementCharges(json.settlementCharges.serviceCharge, json.settlementCharges.stamp, json.settlementCharges.extras, json.settlementCharges.handlingCharge)

            var monthlyPayment = this.sharedService.calculateLoanMonthlyPayment(json.scheme, json.principal, json.principalPaid, json.term, json.interestRate);



            let loan = new Loan(json.id, json.profileId, json.principal, json.stampAmount, json.receiptNumber,
              json.status, json.dateCreated, json.companyId, json.companyName,
              json.scheme, json.interestRate, json.term, json.customerName,
              json.icNumber, json.extraInterest, json.issuer, json.loanCode, json.arrearCount,
              json.principalPaid, json.repayment, json.reserve, json.balance, monthlyPayment, json.arrearPaid, undefined, undefined, undefined,
              json.monthlyInterest, json.discount, undefined, json.processingCharge, false, json.epf,
              json.settlementStamp, json.settlementServiceCharge, json.settlementExtras, undefined, undefined, undefined, undefined,
              json.legacySettlement, undefined, settlementCharges, json.sharedCompanies)


            //add customer instance into customer list
            this.previousEPFLoans.push(loan);
          }
          );

          this.getCutsomerEPFInfo(customer.CustomerID);
        }

      }
    };

  }


  checkIfHittingRatioLimit(epfDate: Date, epfAmount: number) {
    if (this.epfDate != undefined && epfAmount != undefined) {
      var currentSettlement = this.sharedService.roundToDec((this.calculateTotalSettlement() + this.calculateHandlingCharge() + this.calculateTotalCharges()), 2);
      var limitMaximumSettlement = this.sharedService.roundToDec(this.sharedService.getEPFLimitSettlement(epfDate, epfAmount, this.epfLimitRuleSet), 2);


      if (currentSettlement > limitMaximumSettlement)
        return true;
    }
    return false;
  }



  getCutsomerEPFInfo(customerID: number) {
    var xhttp = this.restApi.getRequest(this.constructAPI.getCustomerEPF(customerID));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var json = JSON.parse((xhttp.responseText));
        this.epfDate = new Date(json.date);
        this.epfAmount = Number(json.amount);


        if (this.epfDate < new Date()) {
          var formattedDate = this.datepipe.transform(this.epfDate, "dd-MM-yyyy");
          this.sharedService.openAlert("EPF Date already over! EPF DATE:  " + formattedDate, this.enums.DANGER_ALERT)
          this.resetExtra();
        }
        else
          this.calculateLoanTerm();
      }
    };
  }

  changeInterestRate(reset: boolean) {


    if (this.isExtra == true) {
      if (reset == true)
        this.resetExtra();
      else
        this.MINIMUM_INTEREST = this.enums.MINIMUM_EXTRA_EPF_RATE;
    }

    else {
      if (reset == true)
        this.resetExtra();
      if (this.loanTerm <= 3)
        this.MINIMUM_INTEREST = this.enums.MINIMUM_EXTRA_EPF_RATE;
      else
        this.MINIMUM_INTEREST = this.enums.MINIMUM_NEW_EPF_RATE;
    }

  }

  calculateLoanTerm() {
    // var difference_time = new Date(this.epfDate).getTime() - new Date().getTime();
    // var difference_month = (difference_time / (1000 * 3600 * 24)) / 30;


    // //loan Term need to deduct 1 month
    // this.loanTerm = (Math.round(difference_month) == 0) ? 1 : Math.round(difference_month);

    this.loanTerm = this.sharedService.getEPFTerm(this.epfDate);
    this.changeInterestRate(false)

  }


  calculateTotalSettlement() {
    var settlement = (this.MINIMUM_INTEREST * this.loanAmount * this.loanTerm) + this.loanAmount;
    return settlement
  }

  calculateHandlingCharge() {
    if (this.isExtra == true)
      return Math.floor(this.enums.MININUM_HANDLING_RATE * this.loanAmount * this.loanTerm);
    else
      return 0;
  }

  calculateTotalCharges() {
    return this.settlementStamp + this.settlementSC + this.settlementExtras;
  }


  searchCustomer() {
    const modalRef = this.sharedService.openModal(GlobalSearchCustomerComponent, "largeModal")
    modalRef.componentInstance.isGlobal = this.sharedService.isAdmin() || this.sharedService.isBossAccount();
    modalRef.componentInstance.needRedirect = false;

    modalRef.componentInstance.passEntry.subscribe((entry) => {
      this.customer = entry;
      this.getCustomerLoanInfo(this.customer);


    });
  }


  reset() {
    this.activeModal.close();
  }

  getSettlementCharges() {
    if (this.isExtra == true)
      this.settlementCharges = this.sharedService.calculateAndReturnSettlementCharges(this.loanAmount, this.extraChargesCollected, this.loanTerm);
    else
      this.settlementCharges = this.sharedService.calculateEPFFirstLoanSettlementCharges(this.extraChargesCollected, this.loanTerm);

    this.settlementStamp = this.settlementCharges.Stamp;
    this.settlementSC = this.settlementCharges.ServiceCharge;
    this.settlementExtras = (this.settlementCharges.Extras == undefined) ? 0 : this.settlementCharges.Extras;

    if (this.isExtra == true)
      this.calculateProcessDeductions();


  }

  calculateProcessDeductions() {
    this.processStamp = this.enums.MINIMUM_STAMP;
    this.processSc = this.enums.MINIMUM_SC;
  }

}
