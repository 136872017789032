import { Component, OnInit, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Company } from 'src/model/company.model';
import { RestApiService } from 'src/app/API/restapi';
import { DatePipe } from '@angular/common';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Enums } from 'src/app/Shared/enums';
import { ManageCompanyAccessComponent } from 'src/app/Company/manage-company-access/manage-company-access.component';

interface CompanyGroupInterface {
  id: number,
  name: string
}

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.css'],
  providers: [DatePipe]
})
export class AddCompanyComponent implements OnInit {

  companyName: string;
  phoneNumber: string;
  companyCode: string;
  address: string;
  regNum: string;
  initialCashBookBalance: number;
  initialCashBookDate: Date;
  dateOperating: Date;

  companyNameNull = false;
  phoneNumberNull = false;
  companyCodeNull = false;
  addressNull = false;
  initialCashBookBalanceNull = false;
  initialCashBookDateNull = false;
  regNumNull = false;

  companyGroupList: CompanyGroupInterface[] = new Array;
  selectedGroupId: number;

  disabledSubmit = false;

  requireConsentForm = false;

  isCopyDetailsFromMainBranch = false;
  allCompanyList: Company[] = new Array;
  selectedSourceCompanyID: number;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();


  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private api: RestApiService, private constructAPI: ConstructAPI,
    public sharedService: SharedService, private enums: Enums, private datepipe: DatePipe, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.getAllGroups();
    this.loadAllCompanyList();
  }


  getToday() {
    var date = new Date().toISOString().substr(0, 10);
    return date;
  }

  validField(field: string) {
    switch (field) {
      case 'companyName': this.companyNameNull = (this.companyName === ''); break;
      case 'phoneNumber': this.phoneNumberNull = (this.phoneNumber === ''); break;
      case 'companyCode': this.companyCodeNull = (this.companyCode === ''); break;
      case 'address': this.addressNull = (this.address === ''); break;
      case 'regNum': this.regNumNull = (this.regNum === ''); break;
    }
  }

  validFields() {
    this.companyNameNull = (this.companyName == undefined || this.companyName === '');
    this.phoneNumberNull = (this.phoneNumber == undefined || this.phoneNumber === '');
    this.companyCodeNull = (this.companyCode == undefined || this.companyCode === '');
    this.addressNull = (this.address == undefined || this.address === '');
    this.initialCashBookBalanceNull = (this.initialCashBookBalance == undefined);
    var initialCashBookBalanceInvalid = (isNaN(+this.initialCashBookBalance))
    this.initialCashBookDateNull = (this.initialCashBookDate == undefined);
    var groupNull = (this.selectedGroupId == undefined);
    this.regNumNull = (this.regNum == undefined || this.regNum === '');
    var dateOperatingNull = (this.dateOperating == undefined)

    return (this.companyNameNull || this.phoneNumberNull || this.companyCodeNull || this.addressNull || this.initialCashBookBalanceNull || this.initialCashBookDateNull || groupNull || initialCashBookBalanceInvalid || this.regNumNull || dateOperatingNull);
  }

  getAllGroups() {

    var xhttp = this.api.getRequest(this.constructAPI.getAllCompanyGroups())
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let group = { id: json.id, name: json.name }
          this.companyGroupList.push(group);
        }
        );
      }
    };


  }



  loadAllCompanyList() {
    var xhttp = this.api.getRequest(this.constructAPI.getAllCompanies())
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let company = new Company(json.id, json.name, json.address, json.companyCode, json.contactNo, json.regNo);
          this.allCompanyList.push(company);

        }
        );

        this.allCompanyList.sort((a, b) => (a.CompanyCode < b.CompanyCode ? -1 : 1));
      }
    };

  }

  getCompanyDetails() {
    var xhttp = this.api.getRequest(this.constructAPI.getCompanyDetails(this.selectedSourceCompanyID))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var json = JSON.parse((xhttp.responseText));

        var company = new Company(json.id, json.name, json.address, json.companyCode, json.contactNo, json.regNo, json.group, undefined, undefined, json.requireConsentForm);

        this.copyDetailsFromSource(company);


      }
    };
  }




  copyDetailsFromSource(company: Company) {

    this.address = company.Address;
    this.companyName = company.Name;
    this.regNum = company.RegNum;
    this.phoneNumber = company.ContactNo;
    this.requireConsentForm = company.RequireConsentForm;

    this.cdr.detectChanges();

  }

  setSelectedGroup(groupId: number) {

    if (groupId === this.selectedGroupId)
      return true
    else
      return false;
  }


  submit() {
    this.validFields() ? this.sharedService.openAlert('Please fill in all the fields!', this.enums.DANGER_ALERT) : this.addCompany();
  }

  addCompany() {
    this.disabledSubmit = true;

    if (this.initialCashBookDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var reduceASecDate = new Date(Number(this.initialCashBookDate) - 1);

    var initialCashBookDateStr = (this.initialCashBookDate == undefined) ? undefined : reduceASecDate.toISOString();

    var dateOperatingStr = this.datepipe.transform(this.dateOperating, 'yyyy-MM-dd');

    var data = {
      "name": this.companyName,
      "address": this.address,
      "companyCode": this.companyCode,
      "group": Number(this.selectedGroupId),
      "contactno": this.phoneNumber,
      "regNo": this.regNum,
      "dateOperating": dateOperatingStr,
      "initialCashBookBalance": Number(this.initialCashBookBalance),
      "initialCashBookDate": initialCashBookDateStr,
      "requireConsentForm": this.requireConsentForm
    };

    var xhr = this.api.postRequest(this.constructAPI.getAddCompany(), data);
    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        if (xhr.status == 200) {
          var json = JSON.parse(xhr.responseText);
          // console.log(json);

          var company = new Company(json.id, json.name, json.address, json.companyCode, json.contactNo)
          this.passEntry.emit(company);

          this.reset();
          this.sharedService.openAlert("Successfully added company!", this.enums.SUCCESS_ALERT);
          this.openCompanyAccessModal(company.ID, company.CompanyCode);
        } else
          this.sharedService.openErrorMessage(xhr);
      }

    }

  }


  openCompanyAccessModal(companyID: number, companyCode: string) {
    var modalRef = this.sharedService.openModal(ManageCompanyAccessComponent, "largeModal");
    modalRef.componentInstance.selectedCompany = companyID;
    modalRef.componentInstance.selectedCompanyCode = companyCode;
  }

  reset() {
    this.activeModal.close();
    this.companyName = "";
    this.address = "";
    this.phoneNumber = "";

  }
}
