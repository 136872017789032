<h3 class="text-start" id="pageTitle" style="margin: 15px;">Loan List</h3>
<div class="content m-3">
    <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Enter searching criteria">
    </mat-form-field>

    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>

            <!-- ReceiptNumber Column -->
            <ng-container matColumnDef="receiptNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Receipt Number </th>
                <td mat-cell *matCellDef="let loan"> {{loan.ReceiptNumber}} </td>
            </ng-container>

            <!-- CustomerName Column -->
            <ng-container matColumnDef="customerName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:max-content;"> Customer Name </th>
                <td mat-cell *matCellDef="let loan" style="cursor: pointer;" (click)="openLoanDetailsModal(loan)">
                    {{loan.CustomerName}} </td>
            </ng-container>

            <!-- Amount Column -->
            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Amount </th>
                <td mat-cell *matCellDef="let loan"> {{loan.Amount}} </td>
            </ng-container>

            <!-- Term Column -->
            <ng-container matColumnDef="term">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Term(Mths) </th>
                <td mat-cell *matCellDef="let loan"> {{loan.Term}} </td>
            </ng-container>



            <!-- DateCreated Column -->
            <ng-container matColumnDef="dateCreated">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Created </th>
                <td mat-cell *matCellDef="let loan"> {{loan.DateCreated | date:'dd/MM/yyyy'}} </td>
            </ng-container>

            <!-- Company Name Column -->
            <ng-container matColumnDef="companyName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Company Name</th>
                <td mat-cell *matCellDef="let loan"> {{loan.CompanyName}} </td>
            </ng-container>

            <!-- Company IC Column -->
            <ng-container matColumnDef="icNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> IC Number</th>
                <td mat-cell *matCellDef="let loan"> {{loan.ICNumber}} </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                <td mat-cell *matCellDef="let loan"> {{loan.Status}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[25,50, 100]" showFirstLastButtons></mat-paginator>
    </div>
</div>