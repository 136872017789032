<div class="container-fluid p-3">
    <div class="mt-2">
        <ng-container class="m-2">

            <div class="d-flex justify-content-end align-items-end">
                <button class="btn btn-info" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
                    aria-controls="collapseExample">
                    <i class="fas fa-chevron-circle-down" *ngIf="isCollapsed"></i>
                    <i class="fas fa-chevron-circle-up" *ngIf="isCollapsed==false"></i>
                </button>
            </div>



            <div class="collapse" id="collapseExample" [ngbCollapse]="isCollapsed">
                <app-report-generator [selectedCompany]="selectedCompanyID"></app-report-generator>
            </div>
            <div class="mt-2">
                <div class="card">
                    <div class="card-header fw-bold bg-info">Generate Mid Month</div>

                    <div class="card-body">
                        <span class="text-info fw-bold">Please select mid month date range</span>
                        <div class="table-responsive  m-1 mt-5">
                            <table class="table table-bordered">
                                <tbody>
                                    <tr>
                                        <td>
                                            <div>Start Date</div>
                                            <mat-form-field class="full-width-formField p-0">
                                                <input matInput readonly [matDatepicker]="startDatePicker" name="startDate"
                                                    [(ngModel)]="startDate" required>
                                                <mat-datepicker-toggle matSuffix [for]="startDatePicker">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #startDatePicker name="startDate">
                                                </mat-datepicker>
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>End Date</div>
                                            <mat-form-field class="full-width-formField p-0">
                                                <input matInput readonly [matDatepicker]="endDatePicker" name="startDate"
                                                    [(ngModel)]="endDate" required [min]="startDate">
                                                <mat-datepicker-toggle matSuffix [for]="endDatePicker">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #endDatePicker name="endDate"></mat-datepicker>
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="row m-0 p-0">
                                                <div class="col-12">
                                                    <mat-slide-toggle class="mat-slide-toggle-lg" [hideIcon]="true" color="primary" [(ngModel)]="willOmitSchemeA"
                                                        name="willOmitSchemeA">
                                                        Hide Scheme A
                                                    </mat-slide-toggle>
                                                </div>
                                                <div class="col-12" *ngIf="sharedService.isBossAccount()==true">
                                                    <mat-slide-toggle class="mat-slide-toggle-lg" [hideIcon]="true" color="primary" [(ngModel)]="useVersion1"
                                                        name="useVersion1">
                                                        Show accurate interest with VERSION 1
                                                    </mat-slide-toggle>
                                                </div>

                                            </div>

                                        </td>
                                    </tr>
                                    <!-- <tr>
                                        <td> <input #excelFile id="file-input" type="file"
                                                (change)="handleFileInput($event.target.files)" />
                                        </td>
                                    </tr> -->
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col d-flex justify-content-end align-items-right">
                        <button class="btn btn-success" (click)="submit()" [disabled]="disabledSubmit">Generate</button>
                        <button class="btn btn-danger ms-2">Cancel</button>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>