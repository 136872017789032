import { Component, Input, OnInit } from '@angular/core';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { AddAssignedTransactionIntoAccountComponent } from 'src/app/AddThings/add-assigned-transaction-into-account/add-assigned-transaction-into-account.component';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { TransactionRequest } from 'src/model/transactionRequest.model';

@Component({
  selector: 'app-assigned-transaction-card',
  templateUrl: './assigned-transaction-card.component.html',
  styleUrls: ['./assigned-transaction-card.component.css']
})
export class AssignedTransactionCardComponent implements OnInit {

  constructor(public enums: Enums, public sharedService: SharedService, private restApi: RestApiService, private constructApi: ConstructAPI) { }

  @Input() transactionRequest: TransactionRequest;
  ngOnInit(): void {
  }

  openAddTrasactionIntoAccountModal() {
    var modalRef = this.sharedService.openModal(AddAssignedTransactionIntoAccountComponent, "largeModal");

    modalRef.componentInstance.transactionRequest = this.transactionRequest;
  }


  confirmBeforeDelete(request: TransactionRequest) {
    var proceed = false;
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Confirm to delete this request to  " + request.CompanyCode + " that costs RM ?";
    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something\

      if (confirmation == true)
        this.deleteRequest(this.transactionRequest.ID);
    });

    return proceed;
  }


  deleteRequest(requestId: number) {

    var xhr = this.restApi.deleteRequest(this.constructApi.getDeleteTransactionRequest(requestId));

    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        if (xhr.readyState == 4 && xhr.status == 200) {

          if (xhr.status == 200) {
            window.location.reload();
          }
          else {
            this.sharedService.openAlert("Opps, some errors occured. Please try again later", this.enums.DANGER_ALERT);
          }

        }
      }

    }
  }

  calculateTotal() {
    var sum = 0;

    for (let i = 0; i < this.transactionRequest.TransactionItems.length; i++) {
      sum += this.transactionRequest.TransactionItems[i].amount;
    }
    return sum
  }

}
