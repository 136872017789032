<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Add HUTANG request</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col col-12  col-lg-6">
                <div><span>Customer Name</span></div>
                <div><input type="text" disabled [value]="selectedCustomer.Name"></div>
            </div>
        </div>
        <div class="row">
            <div class="col col-12  col-lg-6">
                <div><span>Handler</span></div>
                <!-- <div><input type="text" disabled [value]="loggedInUser"></div> -->
            </div>
            <div class="col col-12  col-lg-6">
                <div><span>Company</span></div>
                <div><input type="text" disabled [value]="selectedProfile.Occupation"></div>
            </div>
        </div>
        <div class="row">
            <div class="col col-12  col-lg-6">
                <div><span>Salary</span></div>
                <div class="row">
                    <div class="col">
                        <input type="number" [(ngModel)]="selectedProfile.Salary" name="salary"
                            (keypress)="sharedService.isNumberKey($event)">
                    </div>
                    <ng-container>
                        <div class="col-12 col-lg-6">
                            <p class="text-danger" *ngIf="selectedProfile.Salary==0">*Please update
                                salary if neccessary.*</p>
                            <p class="text-danger" *ngIf="selectedProfile.Salary==undefined">*Cannot leave
                                empty*</p>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="col col-12  col-lg-6">
                <div><span>Salary Day</span></div>
                <div class="row">
                    <div class="col">
                        <input type="number" [(ngModel)]="selectedProfile.SalaryDay" name="salaryDay"
                            (keypress)="sharedService.isNumberKey($event)">
                    </div>
                    <ng-container *ngIf="selectedProfile.SalaryDay==0">
                        <div class="col-12 col-lg-6">
                            <p class="text-danger">*cannot be 0*</p>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col d-flex justify-content-end align-items-end">

                <button class="btn-success btn" (click)="updateSalaryDetailsAndTaskDate()"><i class="fas fa-check"></i>
                    Update
                    Salary Details</button>
                <button class="btn btn-warning ms-1" (click)="openUploadApplicationFormModal()"> <i
                        class="fas fa-file-alt"></i> Add Application
                    Form</button>
            </div>
        </div>

        <hr />
        <div class="row">
            <div class="col col-12  col-lg-6">
                <div><span>HUTANG Amount (RM)</span></div>
                <div><input type="number" name="loanAmount" [(ngModel)]="loanAmount" min="0"
                        (keypress)="sharedService.isNumberKey($event)"></div>
            </div>

        </div>
        <div class="row">
            <div class="col col-12  col-lg-6">
                <div><span>HUTANG REASON</span></div>
                <div><input type="text" name="remark" [(ngModel)]="remark"></div>
            </div>

        </div>


    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="submit()" [disabled]="disabledSubmit">Send
            Request</button>
        <button class="btn btn-outline-danger" type="button" (click)="reset()">Cancel</button>
    </div>

</form>