<form>
    <!-- Modal Header -->
    <div class="modal-header" style="background-color:lightcoral;">
        <h4 class="modal-title">Add Commission</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="confirmBeforeLeaving()"></button>
    </div>

    <div class="modal-body">
        <h4 class="fw-bold text-success">Successfully added loan! Let's assign commission!</h4>

        <div class="card shadow">
            <div class="card-body">
                <h4 class="fw-bold">Loan Summary</h4>
                <table>
                    <tbody>
                        <tr>
                            <th>Loan Principal:</th>
                            <td> RM {{selectedLoan.Principal}}</td>
                        </tr>
                        <tr>
                            <th>Loan Scheme:</th>
                            <td>{{selectedLoan.Scheme}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>


        <div class="card">
            <div class="card-header">Staff Commission</div>
            <div class="card-body">
                <!--Username-->
                <div class="row p-3 d-flex justify-content-center align-items-center">
                    <div class="col-12">
                        <p>Staff Name</p>
                    </div>
                    <div class="col-12">
                        <select class="browser-default form-select" name="selectedUser" [(ngModel)]="selectedUser">
                            <option *ngFor="let user of userList" [value]="user.Username"
                                [selected]="setSelectedUser(user.Username)">{{user.Username}}</option>
                        </select>
                    </div>
                </div>

                <!--Amount-->
                <div class="group">
                    <input type="number" required class="textInputLong" name="staffAmount" [(ngModel)]="staffAmount"
                        min=0 (keypress)="sharedService.isNumberKey($event)">
                    <span class="highlight"></span>
                    <span class="bar barLong"></span>
                    <label>Staff Amount</label>
                    <span class="fw-bold"
                        [ngClass]="{'text-danger':sharedService.checkCommissionOverpay(selectedLoan,staffAmount)==true , 'text-info': sharedService.checkCommissionOverpay(selectedLoan,staffAmount)==false  }">{{(staffAmount/selectedLoan.Principal)*100
                        | number: '1.0-2'}}%
                        of
                        Principal</span>
                </div>


            </div>
        </div>

        <div class="card mt-2">
            <div class="card-header">Other Commission</div>
            <div class="card-body">
                <!--Account Number-->
                <div class="row p-3 d-flex justify-content-center align-items-center">

                    <div class="col-12">
                        <p>From Account</p>
                    </div>
                    <div class="col-12">
                        <select class="browser-default form-select" name="selectedAccount"
                            [(ngModel)]="selectedAccountId">
                            <option *ngFor="let account of companyAccountList" [value]="account.ID"
                                [selected]="setSelectedAccount(account.ID)"
                                [disabled]="isBalanceInsufficient(account.Balance)">
                                {{account.Name}} (RM {{account.Balance||0}})</option>
                        </select>
                    </div>
                </div>
                <!--Amount-->

                <div class="group">
                    <input type="number" required class="textInputLong" name="otherAmount" [(ngModel)]="otherAmount"
                        min=0 (keypress)="sharedService.isNumberKey($event)">
                    <span class="highlight"></span>
                    <span class="bar barLong"></span>
                    <label>Amount</label>
                    <span class="text-danger fw-bold">{{(otherAmount/selectedLoan.Principal)*100 | number:
                        '1.0-2'}}%
                        of
                        Principal</span>

                </div>

                <!--Remark-->
                <div class="group">
                    <input type="text" required class="textInputLong" name="remark" [(ngModel)]="remark">
                    <span class="highlight"></span>
                    <span class="bar barLong"></span>
                    <label> Remark</label>
                </div>
            </div>
        </div>

        <h4 class="text-info fw-bold">Total Amount: {{staffAmount + otherAmount}}
            ({{((staffAmount + otherAmount)/selectedLoan.Principal)*100 | number: '1.0-2'}}%)</h4>
        <hr />
        <!--Date-->
        <mat-form-field class="full-width-formField bar">
            <input matInput class="textInputLong" [matDatepicker]="myDatepicker" name="date" [(ngModel)]="date"
                placeholder="Issue Date">
            <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #myDatepicker name="date"></mat-datepicker>
        </mat-form-field>
    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="submit()" [disabled]="disabledSubmit">Add
            Commissions</button>
        <button class=" btn btn-outline-danger" type="button" (click)="confirmBeforeLeaving()">Cancel</button>
    </div>

</form>