import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { LoanRequest } from 'src/model/loanRequest.model';

@Component({
  selector: 'app-loan-request-list',
  templateUrl: './loan-request-list.component.html',
  styleUrls: ['./loan-request-list.component.css']
})
export class LoanRequestListComponent implements OnInit {

  @Input() loanRequestList: LoanRequest[];

  isExpanded = false;
  currentMenu = 'Pending';
  constructor(private activeModal: NgbActiveModal, public sharedService: SharedService,) { }

  ngOnInit(): void {

    if (this.loanRequestList.length > 0 && this.containsApproved())
      this.currentMenu = 'Approved';

  }



  containsPending() {
    var filtered = this.loanRequestList.filter((request: LoanRequest) => (request.Approved == undefined));

    return filtered.length > 0

  }


  checkActive(menu: string) {
    if (menu == "Pending") {
      if (this.loanRequestList.length > 0 && this.containsPending()) return "active";
    }
    else if (menu == "Approved")
      if (this.loanRequestList.length > 0 && this.containsApproved()) return "active";

  }
  containsApproved() {
    var filtered = this.loanRequestList.filter((request: LoanRequest) => (request.Approved == true));

    return filtered.length > 0

  }

  setSelectedMenu(menu: string) {

    this.currentMenu = menu;
  }


  checkMenuSelected(menu: string) {
    if (this.currentMenu == menu)
      return true;
  }



  closeModal() {
    this.activeModal.close();
  }
}
