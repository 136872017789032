<div class="card shadow-lg">
    <div class="">
        <ul class="nav nav-tabs">
            <li class="nav-item"><a class="nav-link active" role="tab"  data-bs-toggle="tab" href="#tabGroup1"><strong>Group
                        {{findGroupName(1)}}</strong></a></li>
            <li class="nav-item"><a class="nav-link" role="tab"  data-bs-toggle="tab" href="#tabGroup2"><strong>Group
                        {{findGroupName(2)}}</strong></a></li>
        </ul>

        <div class="tab-content">
            <div class="tab-pane active" role="tabpanel" id="tabGroup1">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Company</th>
                            <th>Last Inserted Date</th>
                            <th>Latest Account Date</th>
                        </tr>
                    </thead>
                    <ng-container *ngFor="let usage of usageList">
                        <tbody>
                            <tr *ngIf="usage.Group==1">
                                <td [style.background-color]="getWarningStatus(usage.LatestDate)"> {{usage.CompanyCode}}
                                </td>
                                <td [style.background-color]="getWarningStatus(usage.LatestDate)"> {{usage.LastInsertedDate
                                    | date:'dd-MM-yyyy'}}
                                    <span class="badge text-bg-danger" *ngIf="usage.LatestDate==undefined">>= 1 MONTH NO
                                        ACCOUNT</span>
                                </td>
                                <td [style.background-color]="getWarningStatus(usage.LatestDate)"> {{usage.LatestDate|
                                    date:'dd-MM-yyyy'}}</td>
                            </tr>
                        </tbody>
                    </ng-container>

                </table>
            </div>
            <div class="tab-pane" role="tabpanel" id="tabGroup2">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Company</th>
                            <th>Last Inserted Date</th>
                            <th>Latest Account Date</th>
                        </tr>
                    </thead>
                    <ng-container *ngFor="let usage of usageList">
                        <tbody>
                            <tr *ngIf="usage.Group==2">
                                <td [style.background-color]="getWarningStatus(usage.LatestDate)"> {{usage.CompanyCode}}
                                </td>
                                <td [style.background-color]="getWarningStatus(usage.LatestDate)"> {{usage.LastInsertedDate
                                    | date:'dd-MM-yyyy'}}
                                    <span class="badge text-bg-danger" *ngIf="usage.LatestDate==undefined">>= 1 MONTH NO
                                        ACCOUNT</span>
                                </td>
                                <td [style.background-color]="getWarningStatus(usage.LatestDate)"> {{usage.LatestDate|
                                    date:'dd-MM-yyyy'}}</td>
                            </tr>
                        </tbody>
                    </ng-container>

                </table>
            </div>
        </div>
    </div>
</div>