<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Add Transaction Request</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body">
        <!--Company-->
        <div class="row">
            <div class="col-12 ms-3">Company</div>
            <div class="col-12">
                <div class="p-3 d-flex justify-content-center align-items-center">

                    <select class="browser-default form-select" name="selectedCompany"
                        [(ngModel)]="transactionRequest.CompanyId">
                        <option *ngFor="let company of companyList" [value]="company.ID"
                            [selected]="setSelectedCompany(company.ID)">{{company.CompanyCode}}</option>
                    </select>
                </div>

            </div>
        </div>

        <!--Amount-->
        <div class="card">
            <div class="card-header">Transaction Details</div>
            <div class="card-body">
                <div class="group">
                    <input type="number" required class="textInputLong" name="amount" [(ngModel)]="amount" min=0
                        (keypress)="sharedService.isNumberKeyAllowNegative($event)">

                    <span class="highlight"></span>
                    <span class="bar barLong"></span>
                    <label>Amount</label>
                </div>


                <!--Type-->
                <div class="col-12">
                    <mat-form-field appearance="fill">
                        <mat-label>Type</mat-label>
                        <mat-select [(ngModel)]="type" name="type" (ngModelChange)="getTransactionSubTypes(type)">
                            <mat-option *ngFor="let type of transactionTypeList" [value]="type.ID">
                                {{type.Name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>


                <!--Subtype-->
                <div class="col-12">
                    <mat-form-field appearance="fill" *ngIf="type!=undefined && transactionSubtypeList.length>0">
                        <mat-label>SubType</mat-label>
                        <mat-select [(ngModel)]="subtype" name="subtype">
                            <mat-option *ngFor="let subtype of transactionSubtypeList" [value]="subtype.ID">
                                {{subtype.Name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>
            <div class="card-footer">
                <div class="col-12 d-flex justify-content-end align-items-end">
                    <button class="btn btn-dark" (click)="addTransactionItem()">Add</button>
                </div>

            </div>
        </div>

        <div class="row m-0 my-1" *ngIf="transactionRequestItemList.length>0">
            <div class="col p-0">
                <div class="card shadow-lg">
                    <div class="card-body">
                        <h4 class="card-title">Added Items<br></h4>
                    </div>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="text-center">Amount</th>
                                    <th class="text-center">Type</th>
                                    <th class="text-center">Subtype</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of transactionRequestItemList">
                                    <td class="text-center">{{item.amount}}</td>
                                    <td class="text-center">{{enums.transactionTypeString[item.type-1]}}</td>
                                    <td class="text-center">{{transactionSubtypeList[item.subtype-1]}} </td>
                                    <td class="text-center">
                                        <i class="fas fa-times" style="cursor: pointer;"
                                            (click)="openDeleteConfirmationDialog(scheme)"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <!--Remark-->
        <div class="group">
            <input type="text" required class="textInputLong" name="remark" [(ngModel)]="transactionRequest.Remark">
            <span class="highlight"></span>
            <span class="bar barLong"></span>
            <label> Remark</label>
        </div>

    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="addTransactionRequest()"
            [disabled]="disabledSubmit">Add
            Transacton Request</button>
        <button class="btn btn-outline-danger" type="button" (click)="reset()">Cancel</button>
    </div>

</form>