import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { EPF } from 'src/model/epf.model';

@Component({
  selector: 'app-edit-epf-details',
  templateUrl: './edit-epf-details.component.html',
  styleUrls: ['./edit-epf-details.component.css'],
  providers: [DatePipe]
})
export class EditEpfDetailsComponent implements OnInit {

  @Input() customerId: number;
  @Input() epfDetails: EPF;
  withEPFDate = false;

  disableSubmit = false;
  constructor(private activeModal: NgbActiveModal, public sharedService: SharedService, private restApi: RestApiService, private constructApi: ConstructAPI,
    private enums: Enums, private datepipe: DatePipe) { }

  ngOnInit(): void {

    this.getCustomerEPF();
  }


  getCustomerEPF() {

    var xhttp = this.restApi.getRequest(this.constructApi.getCustomerEPF(this.customerId));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4) {

        if (xhttp.status == 200) {
          var json = JSON.parse((xhttp.responseText));
          this.epfDetails = new EPF(json.amount, json.customerId, json.date, json.hasIAccount, json.iAccountPassword, json.iAccountUsername, json.remark);
          this.withEPFDate = true;
        }
        else {
          this.epfDetails = new EPF(undefined, undefined, undefined, undefined, undefined, undefined, undefined);
          this.withEPFDate = false;
        }

      }
    };
  }

  updateEPFDetails() {

    var data = {
      "hasIAccount": this.epfDetails.HasIAccount,
      "iAccountUsername": this.epfDetails.IAccountUsername,
      "iAccountPassword": this.epfDetails.IAccountPassword,
      "remark": this.epfDetails.Remark
    }
    var xhr = this.restApi.putRequest(this.constructApi.getUpdateEPFDetails(this.customerId), data);

    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disableSubmit = false;

        if (xhr.status == 200) {
          this.sharedService.openAlert("Successfully updated customer details", this.enums.SUCCESS_ALERT)
          this.reset();
          window.location.reload();
        }
        else {
          this.sharedService.openErrorMessage(xhr);
        }

      }
    }
  }
  reset() {
    this.activeModal.close();
  }
}
