import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-company-card',
  templateUrl: './company-card.component.html',
  styleUrls: ['./company-card.component.css']
})
export class CompanyCardComponent implements OnInit {


  public companies = [{
    companyCode: 'ABC',
    companyName: 'ABC SDN BHD',
    address: 'ABC,TAMAN GEMBIRA, JALAN GEMBIRA, SELANGOR,MALAYSIA',
    phone: '03-3333333333'
  },
  {
    companyCode: 'ABC',
    companyName: 'ABC SDN BHD',
    address: 'ABC,TAMAN GEMBIRA, JALAN GEMBIRA, SELANGOR,MALAYSIA',
    phone: '03-3333333333'
  },
  {
    companyCode: 'ABC',
    companyName: 'ABC SDN BHD',
    address: 'ABC,TAMAN GEMBIRA, JALAN GEMBIRA, SELANGOR,MALAYSIA',
    phone: '03-3333333333'
  },
  ];
  constructor() { }

  ngOnInit() {
  }

}
