<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Update Company</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body">
        <div class="container-fluid p-0">
            <div class="mt-2">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col col-12 mt-4 d-flex justify-content-center align-items-center">
                                <div class="group">
                                    <input type="text" required class="textInputLong" [(ngModel)]="companyCode"
                                        name="companyCode" (focusout)="validField('companyCode')">
                                    <span class="highlight"></span>
                                    <span class="bar barLong"></span>
                                    <label>Company Code</label>
                                </div>
                            </div>
                            <!--date operating-->
                            <div class="col col-12 mt-4 d-flex justify-content-center align-items-center">
                                <mat-form-field class="full-width-formField bar">
                                    <input matInput class="textInputLong" [matDatepicker]="myDatepicker"
                                        name="dateOperating" [(ngModel)]="dateOperating" placeholder="Date Operating">
                                    <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                                    <mat-datepicker #myDatepicker name="date"></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col col-12 mt-4 d-flex justify-content-center align-items-center">
                                <div class="group">
                                    <input type="text" required class="textInputLong" name="companyName"
                                        [(ngModel)]="companyName" (focusout)="validField('companyName')">
                                    <span class="highlight"></span>
                                    <span class="bar barLong"></span>
                                    <label>Form J Company Name</label>
                                </div>
                            </div>

                            <div class="col col-12 mt-4 d-flex justify-content-center align-items-center">
                                <div class="group">
                                    <input type="text" required class="textInputLong" [(ngModel)]="phoneNumber"
                                        name="phoneNumber" (focusout)="validField('phoneNumber')">
                                    <span class="highlight"></span>
                                    <span class="bar barLong"></span>
                                    <label>Form J Phone Number</label>
                                </div>
                            </div>
                            <div class="col col-12 mt-4 d-flex justify-content-center align-items-center">
                                <div class="group">
                                    <input type="text" required class="textInputLong" [(ngModel)]="regNum" name="regNum"
                                        (focusout)="validField('regNum')">
                                    <span class="highlight"></span>
                                    <span class="bar barLong"></span>
                                    <label>Form J Registration Number</label>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="ms-4"><span>Form J Address</span></div>
                                    <div class="ms-4">
                                        <textarea cols="40" rows="4" required [(ngModel)]="address" name="address"
                                            (focusout)="validField('address')"></textarea>
                                    </div>
                                </div>
                                <div class="col-6 ps-4" *ngIf="sharedService.isBossAccount()">
                                    <mat-slide-toggle class="mat-slide-toggle-lg" [hideIcon]="true" color="warn"
                                        [(ngModel)]="requireConsentForm" name="requireConsentForm">Require Consent Form
                                    </mat-slide-toggle>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="submit()">Update Company</button>
        <button class="btn btn-outline-danger" type="button" (click)="reset()">Cancel</button>
    </div>
</form>