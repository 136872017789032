<div class="container-fluid  p-5">
    <!-- <div id="pivotgrid-demo">
        <dx-chart id="sales-chart">
            <dxo-common-series-settings type="bar"></dxo-common-series-settings>
            <dxo-size [height]="200"></dxo-size>
            <dxo-adaptive-layout [width]="450"></dxo-adaptive-layout>
            <dxo-tooltip [enabled]="true" format="currency" [customizeTooltip]="customizeTooltip"></dxo-tooltip>
        </dx-chart>

        <dx-pivot-grid id="pivotgrid" [allowSortingBySummary]="true" [allowFiltering]="true" [showBorders]="true"
            [allowSortingBySummary]="true" [showColumnGrandTotals]="false" [showRowGrandTotals]="false"
            [showRowTotals]="false" [showColumnTotals]="false" [dataSource]="pivotGridDataSource">
            <dxo-field-chooser [enabled]="true" [height]="400"></dxo-field-chooser>
        </dx-pivot-grid>
    </div> -->

    <div class="d-flex justify-content-end align-items-end">
        <button class="btn btn-info" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseExample">
            <i class="fas fa-chevron-circle-down" *ngIf="isCollapsed"></i>
            <i class="fas fa-chevron-circle-up" *ngIf="isCollapsed==false"></i>
        </button>
    </div>



    <div class="collapse" id="collapseExample" [ngbCollapse]="isCollapsed">
        <app-report-generator [selectedCompany]="selectedCompany"></app-report-generator>
    </div>
    <h2> Stock Report ( Up to {{endDate |date:'yyyy-MM-dd'}} )
    </h2>

    <app-stock-component [selectedCompany]="selectedCompany" [endDate]="endDate" [needStatusOutstanding]="needStatusOutstanding"></app-stock-component>
</div>