import { Transaction } from "./transaction.model"

export class Set {
    constructor(private _id: number, private _name: string, private _remark: string, private _companyId: number, private _transactions: Transaction[],
        private _createdBy: string, private _createdOn: Date, private _checkerRemark: string, private _valid: boolean, private _checkedBy: string,
        private _transactionIdList?: number[], private _companyCode?: string, private _saved?: boolean, private _setRemark?: string, private _tags?: string[]) {

    }

    set ID(id: number) { this._id = id }
    get ID(): number { return this._id }


    set Name(name: string) { this._name = name }
    get Name(): string { return this._name }

    set CreatedOn(createdOn: Date) { this._createdOn = createdOn }
    get CreatedOn(): Date { return this._createdOn }

    set TransactionList(transactions: Transaction[]) { this._transactions = transactions }
    get TransactionList(): Transaction[] { return this._transactions }

    set TransactionIdList(transactionIdList: number[]) { this._transactionIdList = transactionIdList }
    get TransactionIdList(): number[] { return this._transactionIdList }


    set CreatedBy(createdBy: string) { this._createdBy = createdBy }
    get CreatedBy(): string { return this._createdBy }


    set Remark(remark: string) { this._remark = remark }
    get Remark(): string { return this._remark }

    set CheckerRemark(checkerRemark: string) { this._checkerRemark = checkerRemark }
    get CheckerRemark(): string { return this._checkerRemark }

    set CompanyId(companyId: number) { this._companyId = companyId }
    get CompanyId(): number { return this._companyId }

    set Valid(valid: boolean) { this._valid = valid }
    get Valid(): boolean { return this._valid }

    set CheckedBy(checkedBy: string) { this._checkedBy = checkedBy }
    get CheckedBy(): string { return this._checkedBy }


    set CompanyCode(companyCode: string) { this._companyCode = companyCode }
    get CompanyCode(): string { return this._companyCode }

    set Saved(saved: boolean) { this._saved = saved }
    get Saved(): boolean { return this._saved }

    set SetRemark(setRemark: string) { this._setRemark = setRemark }
    get SetRemark(): string { return this._setRemark }

    set Tags(tags: string[]) { this._tags = tags }
    get Tags(): string[] { return this._tags }

}