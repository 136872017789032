<form>
  <!-- Modal Header -->
  <div class="modal-header">
    <h4 class="modal-title">Apply Asset</h4>
    <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()" aria-label="Close"></button>
  </div>

  <div class="modal-body">

    <div class="container-fluid" style="padding: 3%;">
      <div>
        <ul class="nav nav-tabs">
          <li class="nav-item"><a class="nav-link active" role="tab"  data-bs-toggle="tab"
              href="#tab-1"><strong>Apply</strong></a></li>
          <li class="nav-item"><a class="nav-link" role="tab"  data-bs-toggle="tab"
              href="#tab-2"><strong>Return</strong></a></li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane active" role="tabpanel" id="tab-1">
            <div class="row">
              <div class="col-12 d-flex d-md-flex d-xl-flex justify-content-start align-items-start"
                style="padding: 3%;">
                <p><strong>Amount (RM) :</strong>&nbsp;</p><input type="text" class="ms-2">
              </div>
            </div>
            <div class="row">
              <div class="col-12 d-flex d-md-flex d-xl-flex justify-content-start align-items-start"
                style="padding: 3%;">
                <p><strong>Description &nbsp; &nbsp; :</strong></p><input type="text" class="ms-2">
              </div>
            </div>
            <div class="row">
              <div class="col-12 d-flex d-md-flex d-xl-flex justify-content-start align-items-start"
                style="padding: 3%;">
                <p><strong>Link Customer:</strong></p><input type="text" class="ms-2">
              </div>
            </div>
            <div class="row">
              <div class="col-12 d-flex d-md-flex d-xl-flex justify-content-start align-items-start"
                style="padding: 3%;">
                <p><strong>Attachment &nbsp; &nbsp; :</strong></p><input type="file" class="ms-2">
              </div>
            </div>
          </div>
          <div class="tab-pane" role="tabpanel" id="tab-2">
            <div class="row">
              <div class="col-12 d-flex d-md-flex d-xl-flex justify-content-start align-items-start"
                style="padding: 3%;">
                <p><strong>Amount (RM) :</strong>&nbsp;</p><input type="text" class="ms-2">
              </div>
            </div>
            <div class="row">
              <div class="col-12 d-flex d-md-flex d-xl-flex justify-content-start align-items-start"
                style="padding: 3%;">
                <p><strong>Description &nbsp; &nbsp; :</strong></p><input type="text" class="ms-2">
              </div>
            </div>
            <div class="row">
              <div class="col-12 d-flex d-md-flex d-xl-flex justify-content-start align-items-start"
                style="padding: 3%;">
                <p><strong>Attachment &nbsp; &nbsp; :</strong></p><input type="file" class="ms-2">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>