<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Update Customer</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()" aria-label="Close"></button>
    </div>

    <div class="modal-body">
        <div class="container-fluid p-0">

            <div class="mt-2">
                <div class="card">
                    <!-- <div class="card-header">
                        <h5 class="mb-0">Core Information</h5>
                    </div> -->
                    <div class="card-body">
                        <div class="row"
                            *ngIf="sharedService.isBranchAccountHandler()==true || sharedService.isBossAccount()  ">
                            <div class="col col-12">
                                <div><span>Name</span></div>
                                <div><input type="text" [(ngModel)]="customerName" name="customerName"
                                        (focusout)="validField('customerName')"></div>
                            </div>
                            <div class="col col-12"
                                *ngIf="sharedService.isBranchAccountHandler()==true|| sharedService.isBossAccount() ">
                                <div><span>IC Number</span></div>
                                <div><input type="text" [(ngModel)]="icNumber" name="icNumber" required
                                        (focusout)="validField('icNumber')"
                                        (keypress)="sharedService.isNumberKey($event)">
                                    <span class="text-danger" *ngIf="sharedService.checkIfICValid(icNumber)==false">IC
                                        number format incorrect</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-12 col-md-6"
                                *ngIf="sharedService.isBranchAccountHandler()==true|| sharedService.isBossAccount() ">
                                <div><span>Phone number</span></div>
                                <div><input type="text" required [(ngModel)]="contactNo" name="contactNo"
                                        (focusout)="validField('contactNo')">
                                </div>
                            </div>

                        </div>

                        <div class="row">
                            <div class="col-12"
                                *ngIf="sharedService.isBranchAccountHandler()==true|| sharedService.isBossAccount() ">
                                <div><span>Gender</span></div>
                                <select class="browser-default form-select" name="gender" [(ngModel)]="gender">
                                    <option *ngFor="let gender of enums.genderOptions" [value]="gender">{{gender}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-12"
                                *ngIf="sharedService.isBossAccount() || (sharedService.isBranchAccountHandler() && oldRace=='')">
                                <div><span>Race</span></div>
                                <select class="browser-default form-select" name="race" [(ngModel)]="race">
                                    <option *ngFor="let race of enums.raceOptions" [value]="race">{{race}}</option>
                                </select>
                            </div>

                            <div class="col-12"
                                *ngIf="sharedService.isOperatingUser() || sharedService.isBossAccount()==true">
                                <div><span>Address</span></div>
                                <div><textarea class="form-control-lg cols='30'" required [(ngModel)]="address"
                                        name="address" (focusout)="validField('address')"></textarea></div>
                            </div>

                            <div class="col-12"
                                *ngIf="sharedService.isOperatingUser()==true  || sharedService.isBossAccount()==true">
                                <div><span>Address 2</span></div>
                                <div><textarea class="form-control-lg cols='30'" required [(ngModel)]="address2"
                                        name="address2" (focusout)="validField('address2')"></textarea></div>
                            </div>

                            <div class="col col-12">
                                <div><span>Other Id</span></div>
                                <div><input type="text" [(ngModel)]="otherId" name="otherId"
                                        (focusout)="validField('otherId')"></div>
                            </div>
                        </div>

                        <div class="card mt-2" *ngIf="(sharedService.isBossAccount()||sharedService.isAdmin())">
                            <div class="card-body">
                                <ng-container>
                                    <div class="col-6">
                                        <mat-form-field class="full-width-formField bar">
                                            <input matInput class="textInputLong" [matDatepicker]="dobDatepicker"
                                                name="dob" [(ngModel)]="dob" placeholder="Date of Birth"
                                                onkeydown="return false" style="border:0px"
                                                [max]="sharedService.getTodayDate()">
                                            <mat-datepicker-toggle matSuffix
                                                [for]="dobDatepicker"></mat-datepicker-toggle>
                                            <mat-datepicker #dobDatepicker name="dob"></mat-datepicker>
                                        </mat-form-field>

                                    </div>
                                    <div class="col-6">
                                        <button class="btn btn-warning" (click)="updateDOB()">Update DOB</button>
                                    </div>
                                    <div class="col col-12  col-lg-6 mt-1">
                                        <!--EPF Date-->
                                        <mat-form-field class="full-width-formField bar">
                                            <input matInput class="textInputLong" [matDatepicker]="myDatepicker"
                                                name="date" [(ngModel)]="epfDetails.Date"
                                                placeholder="EPF Settlement Date" [min]="sharedService.getTodayDate()"
                                                [max]="sharedService.getMaxDateForEPF()">
                                            <mat-datepicker-toggle matSuffix
                                                [for]="myDatepicker"></mat-datepicker-toggle>
                                            <mat-datepicker #myDatepicker name="date"></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div class="col col-12 col-md-6">
                                        <div><span>EPF Amount (RM)</span></div>
                                        <div><input type="number" required [(ngModel)]="epfDetails.Amount"
                                                name="epfAmount" (keypress)="sharedService.isNumberKey($event)">
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <button class="btn btn-warning" (click)="updateEPFDetails()">Update EPF
                                            Details</button>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="submit()" [disabled]="disableSubmit">Update
            Customer</button>
        <button class="btn btn-outline-danger" type="button" data-dismiss="modal"
            onclick="form.reset()">Clear</button>
    </div>
</form>