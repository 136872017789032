<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Loan Request Message</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-12" *ngIf="sharedService.isAdmin()|| sharedService.isBossAccount()">
                <div class="block">
                    <h1 class="badge mt-1" style="font-size: 1.5rem;"
                        [ngClass]="{'bg-success':compareResult()==true,'bg-danger':compareResult()==false,'bg-secondary':compareString==''}"
                        *ngIf="value!=undefined|| value !=''">
                        <span *ngIf="compareString!='' && compareResult()==true">MATCHED</span>
                        <span *ngIf="compareString!=''&& compareResult()==false">NOT MATCH</span>
                        <span *ngIf="compareString==''">NOTHING TO COMPARE</span>
                    </h1>

                </div>
            </div>

            <ng-container *ngIf="(sharedService.isAdmin()|| sharedService.isBossAccount())">
                <ng-container *ngFor="let request of requestList">
                    <div class="col-12" *ngIf="request.VerifiedOn!=undefined" style="background-color: #5cb85c">
                        <span class="p-0 m-0" style="color: white;"><strong>Verified by {{request.VerifiedBy}} on
                                {{request.VerifiedOn |
                                date:'dd-MM-yyyy'}}</strong></span>
                        <button class="btn btn-info ms-1" (click)="openReviewLoanRequestModal(request)"><i
                                class="fas fa-file-alt"></i>Review
                        </button>
                    </div>

                    <div class="col-12" *ngIf="request.VerifiedOn==undefined" style="background-color: black;">
                        <span class="p-0 m-0" style="color: white;"> <strong>Please Verify Customer</strong> </span>
                        <button class="btn btn-info ms-1" (click)="openReviewLoanRequestModal(request)"><i
                                class="fas fa-file-alt"></i>Review
                        </button>
                    </div>

                </ng-container>

                <div class="col-12" *ngIf="compareResult()==true||sharedService.isBossAccount()">
                    <app-loan-request-approval [requestList]="requestList"
                        [loanGroupId]="loanGroupId"></app-loan-request-approval>
                </div>
            </ng-container>



            <div [ngClass]="needSplitScreen()" *ngIf=" compareResult()!=true">
                <h2 *ngIf="sharedService.isAdmin()|| sharedService.isBossAccount()">Original</h2>
                <textarea id="clipboard-example-textarea" cols="50" rows="30" [(ngModel)]="value" name="value"
                    [ngModelOptions]="{standalone: true}" readonly></textarea>
            </div>
            <div *ngIf="(sharedService.isAdmin()|| sharedService.isBossAccount()) && compareResult()!=true"
                class="col-12 col-md-6">
                <h3>Compare</h3>
                <textarea id="clipboard-example-textarea" cols="50" rows="30" [(ngModel)]="compareString"
                    compareString="compareString" [ngModelOptions]="{standalone: true}"
                    style="background-color: lightyellow;"></textarea>
            </div>
        </div>


        <ng-container *ngIf="compareResult()!=true">
            <button [cdkCopyToClipboard]="value" class="btn btn-info me-2"><i class="far fa-clipboard"></i> Copy to
                clipboard</button>
            <button class="btn btn-success" (click)="generateWhatsappLink()"> <i
                    class="fab fa-whatsapp"></i>Whatsapp</button>
        </ng-container>

    </div>
</form>