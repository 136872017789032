export class User {

    constructor(private _id: number, private _companyId: number,
        private _companyName: string,
        private _roleId: number, private _roleName: string,
        private _username: string, private _password: string, private _salary?: number, private _active?: boolean) {
    }


    set ID(id: number) { this._id = id }
    get ID(): number { return this._id }


    set CompanyId(companyId: number) { this._companyId = companyId }
    get CompanyId(): number { return this._companyId }

    set CompanyName(companyName: string) { this._companyName = companyName }
    get CompanyName(): string { return this._companyName }

    set RoleId(roleId: number) { this._roleId = roleId }
    get RoleId(): number { return this._roleId }

    set RoleName(roleName: string) { this._roleName = roleName }
    get RoleName(): string { return this._roleName }

    set Username(username: string) { this._username = username }
    get Username(): string { return this._username }

    set Password(password: string) { this._password = password }
    get Password(): string { return this._password }

    set Salary(salary: number) { this._salary = salary }
    get Salary(): number { return this._salary }

    get Active(): boolean { return this._active }
    set Active(value: boolean) { this._active = value }

}