<div class="container-fluid">
    <!-- <div class="card stock-card" (click)="goToStockReport()">
        <div class="card-body">
            <p>Stock Report</p>
        </div>
    </div>

    <div class="card stock-card">
        <div class="card-body">
            <p>Stamp Report</p>
        </div>
    </div> -->

    <div class="d-flex justify-content-end align-items-end">
        <button class="btn btn-info" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseExample">
            <i class="fas fa-chevron-circle-down" *ngIf="isCollapsed"></i>
            <i class="fas fa-chevron-circle-up" *ngIf="isCollapsed==false"></i>
        </button>
    </div>



    <div class="collapse" id="collapseExample" [ngbCollapse]="isCollapsed">
        <app-report-generator></app-report-generator>
    </div>

    <!-- <app-simple-report class="p-3" [selectedCompany]="selectedCompany" [startDate]="startDate" [endDate]="endDate"
        [reportName]="selectedReport" [hidden]="isReady==false">
    </app-simple-report> -->

</div>