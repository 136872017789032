import { Component, Input, OnInit } from '@angular/core';
import { LoanRequest } from 'src/model/loanRequest.model';

@Component({
  selector: 'app-customer-loan-request-history',
  templateUrl: './customer-loan-request-history.component.html',
  styleUrls: ['./customer-loan-request-history.component.css']
})
export class CustomerLoanRequestHistoryComponent implements OnInit {

  @Input() request:LoanRequest;
  constructor() { }

  ngOnInit(): void {
  }

}
