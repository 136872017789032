import { Component, Input, OnInit } from '@angular/core';
import { ReviewLoanRequestComponent } from 'src/app/LoanRequest/review-loan-request/review-loan-request.component';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { LoanGroupRequest } from 'src/model/sharedLoan.model';

@Component({
  selector: 'app-shared-loan-request-card',
  templateUrl: './shared-loan-request-card.component.html',
  styleUrls: ['./shared-loan-request-card.component.css']
})
export class SharedLoanRequestCardComponent implements OnInit {


  @Input() loanGroupRequest: LoanGroupRequest;
  constructor(private sharedService: SharedService) { }

  ngOnInit(): void {
  }


  openReviewLoanRequestModal() {
    var modalRef = this.sharedService.openModal(ReviewLoanRequestComponent, 'largeModal');
    modalRef.componentInstance.requestList = this.loanGroupRequest.requestList;
    modalRef.componentInstance.loanGroupId = this.loanGroupRequest.loanGroup;
  }

}
