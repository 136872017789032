<ng-container *ngIf="epfDate!=undefined">
    <app-add-loan-details (getLoanData)="getLoan1Data($event)" [availableReserve]="selectedProfile.Reserve"
        [isEPF]="isEPF" [epfDate]="getFormValue('epfDate')" [isEPFExtra]="isEPFExtra"
        [previousPrincipal]="previousPrincipal" [epfAmount]="epfAmount" [isManualKeyIn]="isManualKeyIn"
        required>

    </app-add-loan-details>
</ng-container>

<h3 class="mt-2" style="font-weight: bold;color: brown;background-color: yellow;"
    *ngIf="loan!=undefined && (loan.Principal!=undefined && loan.Principal!=0)">
    Total
    On Hand: RM
    {{calculateTotalOnHand() ||0}} </h3>