import { Component, OnInit, EventEmitter, Output, Input, ViewChild, ViewContainerRef, ComponentFactoryResolver, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogItem } from 'src/app/dialog-item';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {

  message: string;
  confirmation: boolean;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() dialogItem: DialogItem;
  @ViewChild('anchor', { read: ViewContainerRef }) anchor: ViewContainerRef;

  constructor(private activeModal: NgbActiveModal, private cdr: ChangeDetectorRef, private componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnInit(): void {
  }

  setConfirmation(confirm: boolean) {
    this.confirmation = confirm;

    this.passEntry.emit(this.confirmation);
    this.activeModal.close();
  }

  loadComponent() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.dialogItem.component);
    // this.anchor.clear();
    const componentRef = this.anchor.createComponent<any>(componentFactory);
    componentRef.instance.data = this.dialogItem.data;
  }

  ngAfterViewInit() {
    if (this.dialogItem != undefined)
      this.loadComponent();
    this.cdr.detectChanges();
  }

}
