<h3 class="text-start" id="pageTitle" style="margin: 15px;">Company List</h3>
<div class="content m-3">

    <div *ngIf="sharedService.isAdmin()||sharedService.isBossAccount()">
        <button class="btn btn-primary float-end" (click)="openAddCompanyDialog()"><i class="fas fa-plus"></i> Add
            Company</button>
    </div>
    <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Enter searching criteria">
    </mat-form-field>

    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>

            <!-- Name Column -->
            <!-- <ng-container matColumnDef="Name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let company"> {{company.Name}} </td>
            </ng-container> -->

            <!-- Address Column -->
            <!-- <ng-container matColumnDef="Address">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Address </th>
                <td mat-cell *matCellDef="let company" style="cursor: pointer;">
                    {{company.Address}} </td>
            </ng-container> -->

            <!-- Contact No Column -->
            <!-- <ng-container matColumnDef="ContactNo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Contact Number </th>
                <td mat-cell *matCellDef="let company"> {{company.ContactNo}} </td>
            </ng-container> -->

            <!-- Company Code Column -->
            <ng-container matColumnDef="CompanyCode">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Company Code </th>
                <td mat-cell *matCellDef="let company"> {{company.CompanyCode}} </td>
            </ng-container>

            <!-- UnverifiedCounts Column -->
            <ng-container matColumnDef="UnverifiedCounts"
                *ngIf="sharedService.isAdmin()==true|| sharedService.isBossAccount()==true">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Unverified Customer </th>
                <td mat-cell *matCellDef="let company">
                    <h5><span class="badge text-bg-dark"
                            *ngIf="company.UnverifiedCutsomerCount!=undefined">{{company.UnverifiedCutsomerCount}}
                            Unverified
                        </span></h5>
                </td>
            </ng-container>

            <!-- Action Column -->
            <ng-container matColumnDef="Actions"
                *ngIf="sharedService.isAdmin()==true|| sharedService.isBossAccount()==true|| sharedService.isAuditor()==true || sharedService.isFinance()">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
                <td mat-cell *matCellDef="let company">
                    <button class="btn btn-info" (click)="viewCustomerList(company.ID)" #tooltip="matTooltip"
                        matTooltip="View Customers" matTooltipPosition="above" matTooltipHideDelay="100"
                        matTooltipClass="tooltip-black"><i class="fas fa-eye"></i></button>
                    <button class="btn btn-warning ms-2" (click)="openUpdateCompanyModal(company)" #tooltip="matTooltip"
                        matTooltip="Edit Company Info" matTooltipPosition="above" matTooltipHideDelay="100"
                        matTooltipClass="tooltip-black" [hidden]="sharedService.isAuditor()==true || sharedService.isFinance()"><i class="fas fa-pencil-alt" ></i></button>

                    <button class="btn btn-primary ms-2" #tooltip="matTooltip" matTooltip="Edit Company Access"
                        matTooltipPosition="above" matTooltipHideDelay="100" matTooltipClass="tooltip-black"
                        (click)="openCompanyAccessModal(company.ID,company.CompanyCode)" *ngIf="sharedService.isBossAccount()==true"><i
                            class="fas fa-users"></i></button>

                    <button class="btn btn-dark ms-2" (click)="viewUnverifiedCustomerList(company.ID)"
                        *ngIf="company.UnverifiedCutsomerCount!=undefined" [hidden]="sharedService.isAuditor()==true || sharedService.isFinance()"><i class="fas fa-tasks"></i>
                        {{company.UnverifiedCutsomerCount}}
                        Unverified</button>


                </td>
            </ng-container>





            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[25,50, 100]" showFirstLastButtons [pageSize]="100"></mat-paginator>
    </div>
</div>