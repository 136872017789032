<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">View Customer Remark = {{viewCustomerName}}</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body">
        <app-customer-admin-remark [viewCustomerId]="viewCustomerId"></app-customer-admin-remark>
    </div>

</form>