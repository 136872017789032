import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { SharedService } from 'src/app/Shared/shared-service.service';

interface ICChangeHistory {
  changed_by: string,
  customerId: number,
  date: Date,
  newIC: string,
  oldIC: string
}

@Component({
  selector: 'app-ic-change-history',
  templateUrl: './ic-change-history.component.html',
  styleUrls: ['./ic-change-history.component.css']
})
export class IcChangeHistoryComponent implements OnInit {


  @Input() customerId: number;
  historyList: ICChangeHistory[] = new Array;
  constructor(private activeModal: NgbActiveModal, private restApi: RestApiService, private constructApi: ConstructAPI,public sharedService:SharedService) { }

  ngOnInit(): void {
    this.getCustomerEpfChangeHistory(this.customerId)
  }

  getCustomerEpfChangeHistory(customerId: number) {
    var xhttp = this.restApi.getRequest(this.constructApi.getCustomerICChangeHistory(this.customerId))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse(xhttp.responseText);
        jsonArray.forEach(json => {

          let history = { customerId: json.customerId, changed_by: json.changed_by, date: json.date, newIC: json.newIC, oldIC: json.oldIC };
        
          this.historyList.push(history);
        }
        );
      }
    };
  }


  reset() {
    this.activeModal.close();
  }
}
