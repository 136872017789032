import { Component, OnInit, EventEmitter, Output, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from '../../app.component';
import { Enums } from 'src/app/Shared/enums';
import { AuthService } from 'src/app/auth/auth.service';
import { SharedService } from 'src/app/Shared/shared-service.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  @Output() sendStatusEvent = new EventEmitter<boolean>();
  constructor(private router: Router, private appComponent: AppComponent, private _auth: AuthService, public enums: Enums, public sharedService: SharedService) {

  }


  ngOnInit() {
  }

  getLoggedInRolename() {
    return sessionStorage.getItem("roleName");
  }

  getLoggedInRoleID() {
    return Number(sessionStorage.getItem("roleId"));
  }

  getAdvanceLink() {
    var loggedInCompanyId = sessionStorage.getItem("companyId");

    return "/advanceList/" + Number(loggedInCompanyId);
  }

  getCustomerListLink() {
    var loggedInCompanyId = sessionStorage.getItem("companyId");

    return "/customerList/" + Number(loggedInCompanyId);
  }

  checkDashboard(loggedInRoleID: number) {    
    if (loggedInRoleID != this.enums.ADMIN && loggedInRoleID != this.enums.BOSS && loggedInRoleID != this.enums.FINANCE)
      return "/dashboard-calendar"
    else
      return "/dashboard-admin"
  }

  getStyle(routeUrl: string) {

    return (this.router.url == routeUrl) ? "active" : "inactive";

  }

  getSubmenuStyle() {

    return (this.router.url == '/assetList' || this.router.url == '/assetGroupList' || this.router.url == '/vendorList') ? "submenuActive" : "inactive";

  }

  toggleSidebar() {
    this.appComponent.toggleSidebar();

  }

  getReceiptMenu() {

    if (this.sharedService.isAdmin())
      this.router.navigate(['/receiptList']);
    else {
      // var companyId = Number(sessionStorage.getItem("companyId"))
      // var addADayDate = new Date(Number(new Date()) + (3600 * 1000 * 24) - 1);

      // this.router.navigate(['/receiptList', companyId, new Date().toISOString(), addADayDate.toISOString(), false]);
      this.router.navigate(['/receiptList']);
    }

    this.toggleSidebar();
  }

  logout() {
    this._auth.logout();
  }

}
