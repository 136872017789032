import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Company } from 'src/model/company.model';
import { Customer } from 'src/model/customer.model';
import { FormJ } from 'src/model/formJ.model';

@Component({
  selector: 'app-payment-voucher',
  templateUrl: './payment-voucher.component.html',
  styleUrls: ['./payment-voucher.component.css']
})
export class PaymentVoucherComponent implements OnInit {

  @Input() selectedFormJ: FormJ;
  @Input() selectedCompany: Company;
  @Input() selectedCustomer: Customer;

  constructor(public sharedService:SharedService) { }

  ngOnInit(): void {
  }

}
