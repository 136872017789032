<form>
    <!-- Modal Header -->
    <div class="modal-header"
        [ngClass]="{'addLoanTitle':needRestrictedRequest ==false,'requestTitle':needRestrictedRequest ==true}">
        <h4 class="modal-title" *ngIf="needRestrictedRequest==false">Add New Loan</h4>
        <h4 class="modal-title" *ngIf="needRestrictedRequest==true">Add Loan Request</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()" aria-label="Close"></button>
    </div>

    <div class="modal-body">
        <div class="container-fluid p-0">
            <div class="mt-3">
                <div class="mt-2">


                    <div class="row">
                        <div class="col col-12  col-lg-6">
                            <div><span>Customer Name</span></div>
                            <div><input type="text" disabled [value]="selectedCustomer.Name"></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-12  col-lg-6">
                            <div><span>Handler</span></div>
                            <div><input type="text" disabled [value]="loggedInUser"></div>
                        </div>
                        <div class="col col-12  col-lg-6">
                            <div><span>Company</span></div>
                            <div><input type="text" disabled [value]="selectedProfile.Occupation"></div>
                        </div>

                        <div class="col col-12  col-lg-6">
                            <div><span>DOB</span></div>
                            <div> <mat-form-field class="full-width-formField p-0">
                                    <input matInput readonly [matDatepicker]="dobDatePicker" name="dob"
                                        [(ngModel)]="selectedCustomer.DOB" disabled>
                                    <mat-datepicker-toggle matSuffix [for]="dobDatePicker"></mat-datepicker-toggle>
                                    <mat-datepicker #dobDatePicker name="dob"></mat-datepicker>
                                </mat-form-field></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-12  col-lg-6">
                            <div><span>Salary</span></div>
                            <div class="row">
                                <div class="col">
                                    <input type="number" [(ngModel)]="selectedProfile.Salary" name="salary"
                                        (keypress)="sharedService.isNumberKey($event)">
                                </div>
                                <ng-container>
                                    <div class="col-12 col-lg-6">
                                        <p class="text-danger" *ngIf="selectedProfile.Salary==0">*Please update
                                            salary if neccessary.*</p>
                                        <p class="text-danger" *ngIf="selectedProfile.Salary==undefined">*Cannot leave
                                            empty*</p>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col col-12  col-lg-6">
                            <div><span>Salary Day</span></div>
                            <div class="row">
                                <div class="col">
                                    <input type="number" [(ngModel)]="selectedProfile.SalaryDay" name="salaryDay"
                                        (keypress)="sharedService.isNumberKey($event)">
                                </div>
                                <ng-container *ngIf="selectedProfile.SalaryDay==0">
                                    <div class="col-12 col-lg-6">
                                        <p class="text-danger">*cannot be 0*</p>
                                    </div>
                                </ng-container>
                            </div>
                        </div>



                    </div>
                    <div class="row">

                        <div class="col d-flex justify-content-end align-items-end">

                            <button class="btn-success btn" (click)="updateSalaryDetailsAndTaskDate()"><i
                                    class="fas fa-check"></i> Update
                                Salary Details</button>
                            <button class="btn btn-warning ms-1" (click)="openUploadApplicationFormModal()"> <i
                                    class="fas fa-file-alt"></i> Add Supporting Docs</button>
                        </div>
                    </div>

                    <hr />

                    <div class="row">
                        <div class="col-12 col-md-6" *ngIf="isOverlap==false && (sharedService.isOperatingUser())">
                            
                            <!--temporarily off this option,because loan request so far only can epf-->
                            <!-- <div class="card mb-1 epfCard" *ngIf="allowEPF==true" [visible]>
                                <div class=" card-body">
                                    <mat-slide-toggle class="mat-slide-toggle-lg" [hideIcon]="true" color="primary" [(ngModel)]="isEPF" name="isEPF"
                                        (change)="loadEPFInfos()">
                                        <span style="color:purple">EPF Customer</span>
                                    </mat-slide-toggle>
                                </div>
                            </div> -->
                            <div class="card  epfCard" *ngIf="isEPF==false  && needRestrictedRequest==false">
                                <div class="card-body">
                                    <mat-slide-toggle class="mat-slide-toggle-lg" [hideIcon]="true" color="primary" [(ngModel)]="withAnotherLoan"
                                        name="withAnotherLoan" (change)="addNewLoanObj()">
                                        <span class="text-danger">Create Another Loan</span>
                                    </mat-slide-toggle>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6" *ngIf="selectedProfile.Reserve>0">
                            <div class="card mb-5 simpanCard" *ngIf="sharedService.isBranchAccountHandler()">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12 py-2 mb-4">
                                            <div class="col col-12  col-lg-6 mt-1">
                                                <div class="row">
                                                    <div class="col-12 m-0 p-0">
                                                        <span class="fw-bold text-primary"> Available Simpan:
                                                            RM{{selectedProfile.Reserve}}
                                                        </span>
                                                    </div>
                                                    <div class="col-12">
                                                        <mat-slide-toggle class="mat-slide-toggle-lg" [hideIcon]="true" color="primary" [(ngModel)]="payWithSimpan"
                                                            name="payWithSimpan" [disabled]="isOverlap==true">
                                                            Pay With Simpan
                                                        </mat-slide-toggle>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--test-->

                    <div *ngIf="isEPF">
                        <div>

                            <ng-template matStepLabel>Loan Details</ng-template>
                            <ng-container *ngIf="getFormValue('epfDate')!=undefined">
                                <app-add-loan-details (getLoanData)="getLoan1Data($event)"
                                    [availableReserve]="selectedProfile.Reserve" [isEPF]="isEPF"
                                    [epfDate]="getFormValue('epfDate')" [isEPFExtra]="isEPFExtra"
                                    [previousPrincipal]="previousPrincipal" [epfAmount]="getFormValue('epfAmount')"
                                    [isManualKeyIn]="isManualKeyIn" required>

                                </app-add-loan-details>

                                <div class="col-12 ">
                                    <div><mat-label>Remark</mat-label>
                                    </div>
                                    <div><textarea class=" cols='100' row='3'" placeholder="Ticket Remark"
                                            [(ngModel)]="requestRemark" name="requestRemark"></textarea></div>
                                </div>
                            </ng-container>

                            <h3 class="mt-2" style="font-weight: bold;color: brown;background-color: yellow;"
                                *ngIf="this.loanList[0]!=undefined && (this.loanList[0].Principal!=undefined && this.loanList[0].Principal!=0)">
                                Total
                                On Hand: RM
                                {{calculateTotalOnHand() ||0}} </h3>
                            <div class="d-flex justify-content-end aligh-items-right">
                                <hr />
                                <!-- <button mat-button matStepperPrevious (click)="loanList=[]">Back</button> -->
                                <button class="btn btn-info" (click)="checkEPFLoanBeforeSubmitting()">
                                    Submit EPF Request</button>
                            </div>
                        </div>

                        <!-- <mat-step>
                            <ng-template matStepLabel>Summary</ng-template>

                            <ng-container *ngIf="request!=undefined">
                                <app-loan-request-details [request]="request" [isExpanded]="true" [editable]="false"
                                    [showPrevious]="true" [previousEPFLoanList]="previousEPFLoans">
                                </app-loan-request-details>
                            </ng-container>

                            <div class="d-flex justify-content-end align-items-right">
                                <hr />
                                <button mat-button matStepperPrevious>Back</button>
                                <button mat-button [disabled]="disabledSubmit" (click)="checkIfRequireElevate()">Submit
                                    Request</button>
                            </div>
                        </mat-step> -->
                    </div>
                    <!--end-->

                    <div *ngIf="isEPF==false">
                        <h2 class="fw-bold" [hidden]="withAnotherLoan==false">Loan 1</h2>
                        <app-add-loan-details (getLoanData)="getLoan1Data($event)"
                            [availableReserve]="selectedProfile.Reserve" [isEPF]="isEPF"
                            [isPayWithSimpan]="payWithSimpan" [batchDate]="batchDate"></app-add-loan-details>

                        <div [hidden]="withAnotherLoan==false">
                            <hr />
                            <h2 class="fw-bold">Loan 2</h2>
                            <app-add-loan-details (getLoanData)="getLoan2Data($event)"
                                [availableReserve]="selectedProfile.Reserve" [isEPF]="isEPF"
                                [isPayWithSimpan]="payWithSimpan" [batchDate]="batchDate"></app-add-loan-details>
                        </div>
                    </div>
                </div>
            </div>


            <div *ngIf="isEPF==false">
                <h3 class="mt-2" style="font-weight: bold;color: brown;background-color: yellow;"> Total On Hand: RM
                    {{calculateTotalOnHand() ||0 |number:'1.0-2'}} </h3>

                <div class="card shadow-lg" style="background-color: lightgoldenrodyellow;">
                    <div class="card-body">
                        <table class="table-borderless">
                            <tr>
                                <td>Total Processing</td>
                                <td class="px-2"> : </td>
                                <td class="fw-bold"> RM {{calculateTotalProcessing() ||0}}</td>
                            </tr>
                            <tr>
                                <td>Total Stamp & SC</td>
                                <td class="px-2"> : </td>
                                <td class="fw-bold"> RM {{calculateTotalStampAndSC() ||0}}</td>
                            </tr>
                            <tr>
                                <td>Total Duit Lebih</td>
                                <td class="px-2"> : </td>
                                <td class="fw-bold"> RM {{calculateTotalDuitLebih() ||0}}</td>
                            </tr>
                            <tfoot style="border-top:1px solid black;border-bottom:1px solid black;">
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th> RM {{calculateTotalProcessing() +calculateTotalStampAndSC()
                                        +calculateTotalDuitLebih() ||0}}</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>


                <div class="row" [hidden]="isOverlap==true || needRestrictedRequest==true">
                    <div class="col-12">
                        <h5>Issued by account:</h5>
                    </div>
                    <div class="col-12">
                        <select class="browser-default form-select" name="selectedAccount"
                            [(ngModel)]="selectedAccountId">
                            <option *ngFor="let account of companyAccountList" [value]="account.ID"
                                [selected]="setSelectedAccount(account.ID)"
                                [disabled]="isBalanceInsufficient(account.Balance)">
                                {{account.Name}} (RM
                                {{account.Balance || 0}} )
                            </option>
                        </select>
                    </div>
                </div>
                <h5 style="color: crimson;font: bold;" *ngIf="selectedProfile.SalaryDay!=undefined">A follow up task
                    will be
                    added on :
                    {{ nextTaskDate | date: 'dd-MM-yyyy'}}</h5>

                <div class="row" *ngIf="needRestrictedRequest==true">
                    <div class="col-12 col-md-6">
                        <input type="text" class="textInputLong" placeholder="Remark"
                            [(ngModel)]="restrictedReqeustRemark" name="restrictedReqeustRemark">
                    </div>
                    <div class="col-12">
                        <span class="badge text-bg-warning"> *Please upload PAYSLIP, BANK STATEMENTS and ATM CARD for
                            reference</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer" *ngIf="isEPF==false ">
        <button class="btn btn-outline-success" type="button" (click)="submit()" [disabled]="disabledSubmit"
            *ngIf="needRestrictedRequest==false  || isOverlap==true">Add
            Loan</button>

        <button class="btn btn-warning" type="button" (click)="openUploadSupportingDocModal()"
            [disabled]="disabledSubmit" *ngIf="needRestrictedRequest==true">Upload Supporting Docs</button>
        <button class="btn btn-dark" type="button" (click)="addRestrictedLoanRequest()" [disabled]="disabledSubmit"
            *ngIf="needRestrictedRequest==true">Send Request</button>
        <button class="btn btn-outline-danger" type="button" (click)="reset()">Cancel</button>
    </div>
</form>