<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Validate EPF Statement</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()" aria-label="Close"></button>
    </div>

    <div class="modal-body">
        <ng-container>

            <input id="file-input" type="file" accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.mp4,.mkv,.webm,.flv"
                (change)="handleFileInput($event.target.files)" />

            <p class="fw-bold text-danger" *ngIf="disableSubmit">Uploading file, please wait patiently...</p>
            <p class="fw-bold">MAX FILE SIZE: 10 MB</p>

        </ng-container>
    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="validateEPFImage()"
            [disabled]="disableSubmit">Check File</button>
        <button class=" btn btn-outline-danger" type="button">Cancel</button>
    </div>
</form>