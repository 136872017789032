<div class="container-fluid p-3">
    <div class="d-flex justify-content-end align-items-end">
        <button class="btn btn-info" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseExample">
            <i class="fas fa-chevron-circle-down" *ngIf="isCollapsed"></i>
            <i class="fas fa-chevron-circle-up" *ngIf="isCollapsed==false"></i>
        </button>
    </div>



    <div class="collapse" id="collapseExample" [ngbCollapse]="isCollapsed">
        <app-report-generator [selectedCompany]="selectedCompany"></app-report-generator>
    </div>
    <h2> {{enums.reportString[selectedReport-1]}} ( {{startDate |date:'yyyy-MM-dd'}} - {{endDate |date:'yyyy-MM-dd'}} )
    </h2>
    <div class="col-12 my-1">
        <mat-slide-toggle class="mat-slide-toggle-lg" [hideIcon]="true" color="primary" [(ngModel)]="includeDLExpense" name="withDL"
            (ngModelChange)="includeOrExcludeDLExpense()" *ngIf="selectedReport==enums.EXPENSE_REPORT">
            Include Duit Lebih Expenses
        </mat-slide-toggle>
    </div>

    <p *ngIf="reportList.length==0 && transactionBalance==0"> Do not have any record within this date range</p>

    <div class="d-flex float-end" [hidden]="(reportList.length==0 && transactionBalance==0)|| isBranchManager==false">
        <button class="btn btn-primary" (click)="openWithdrawalModal()"
            [hidden]="selectedReport!= enums.STAMP_REPORT && selectedReport!= enums.SC_REPORT && selectedReport!= enums.HANDLING_REPORT">
            <i class="fas fa-donate"></i>
            Withdraw
        </button>
        <button class="btn btn-primary" (click)="openBadDebtModal()" [hidden]="selectedReport!=enums.SIMPAN_BD_REPORT">
            <i class="fas fa-file-invoice"></i>
            Pay Bad Debt
        </button>
    </div>
    <table class="table table-striped" [hidden]="reportList.length==0 && transactionBalance==0">
        <thead>
            <tr>
                <th [hidden]="selectedReport!=enums.SIMPAN_BD_REPORT || isBranchManager==false">
                </th>
                <th [hidden]="selectedReport==enums.SIMPAN_BD_REPORT || selectedReport==enums.SIMPAN_BALANCE_REPORT"
                    (click)="sortByCriteria('Date')" class="sortHeader">
                    Date
                </th>
                <th [hidden]="isAccountReport()" (click)="sortByCriteria('CustomerName')" class="sortHeader">Customer
                </th>
                <th [hidden]="isOrdinaryReport()==false ||  selectedReport==enums.SIMPAN_BALANCE_REPORT"
                    (click)="sortByCriteria('Receipt')" class="sortHeader">Receipt</th>
                <th [hidden]="isOrdinaryReport()==false" (click)="sortByCriteria('Remark')" class="sortHeader">Remark
                </th>
                <th [hidden]="selectedReport!=enums.COMMISSION_REPORT" (click)="sortByCriteria('Principal')"
                    class="sortHeader">Principal</th>
                <th [hidden]="selectedReport!=enums.COMMISSION_REPORT" (click)="sortByCriteria('Rate')"
                    class="sortHeader">Rate</th>
                <th (click)="sortByCriteria('Amount')" class="sortHeader">Amount</th>

                <th [hidden]="selectedReport!=enums.ASSET_REPORT" class="sortHeader">Balance</th>
                <th [hidden]="selectedReport!=enums.COMMISSION_REPORT" (click)="sortByCriteria('Assignee')"
                    class="sortHeader">Assignee</th>

            </tr>
        </thead>
        <tbody>
            <tr style="background-color: lightskyblue;"
                [hidden]="selectedReport==enums.EXPENSE_BY_EXTRAS_REPORT ||selectedReport==enums.DISCOUNT_REPORT||selectedReport==enums.COMMISSION_REPORT||selectedReport==enums.SIMPAN_BALANCE_REPORT||selectedReport==enums.HUTANG_BALANCE_REPORT||selectedReport==enums.SIMPAN_BD_REPORT">
                <td [hidden]="selectedReport!=enums.SIMPAN_BD_REPORT || isBranchManager==false"></td>
                <td [hidden]="selectedReport==enums.SIMPAN_BD_REPORT"></td>
                <td [hidden]="isAccountReport()"></td>
                <td [hidden]="isOrdinaryReport()==false">Carry Forward Balance</td>
                <td [hidden]="isOrdinaryReport()==false"></td>
                <td [hidden]="selectedReport!=enums.COMMISSION_REPORT"></td>
                <td [hidden]="selectedReport!=enums.COMMISSION_REPORT"></td>
                <td [hidden]="selectedReport!=enums.ASSET_REPORT"></td>
                <td>{{transactionBalance}}</td>
                <td [hidden]="selectedReport!=enums.COMMISSION_REPORT"></td>
            </tr>
            <tr *ngFor="let report of reportList; let i=index">
                <td [hidden]="selectedReport!=enums.SIMPAN_BD_REPORT || isBranchManager==false"
                    (click)="openWithdrawBDModal(report.profileId,report.amount)">
                    <button class="btn btn-info"><i class="fas fa-piggy-bank"></i> </button>
                </td>
                <td [hidden]="selectedReport==enums.SIMPAN_BD_REPORT || selectedReport == enums.SIMPAN_BALANCE_REPORT">
                    {{report.date | date:'yyyy-MM-dd'}}</td>
                <td [hidden]=" isAccountReport()" (click)="goToCustomer(report.customerId,report.profileId)"
                    class="customerCol">
                    {{report.customerName}}</td>
                <td [hidden]="isOrdinaryReport()==false || selectedReport==enums.SIMPAN_BALANCE_REPORT">
                    {{report.receiptNumber}}</td>
                <td [hidden]="isOrdinaryReport()==false"> {{report.remark}}</td>
                <td [hidden]="selectedReport!=enums.COMMISSION_REPORT">RM {{report.principal}}</td>
                <td [hidden]="selectedReport!=enums.COMMISSION_REPORT">{{report.rate |number:'1.2-2'}}%</td>
                <td [ngClass]="getStyle(report.amount)">RM {{report.amount}}</td>
                <td [hidden]="selectedReport!=enums.ASSET_REPORT">RM {{calculateCurrentBalance(i)|number:'1.2-2'}}</td>
                <td [hidden]="selectedReport!=enums.COMMISSION_REPORT">{{report.assignee}}</td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <td
                    [hidden]="selectedReport==enums.SIMPAN_BD_REPORT||isAccountReport() || selectedReport==enums.SIMPAN_BALANCE_REPORT">
                </td>
                <td [hidden]="selectedReport==enums.SIMPAN_BD_REPORT"></td>
                <td [hidden]=" selectedReport==enums.SIMPAN_BALANCE_REPORT">
                </td>
                <th>
                    <div class="row">
                        <div class="col-12"> Sum : RM {{calculateTotal()| number:'1.2-2'}} </div>
                        <div class="col-12 col-md-6 text-success">(In : RM {{calculateTotalIn()| number:'1.2-2'}})</div>
                        <div class="col-12 col-md-6 text-danger">(Out : RM {{calculateTotalOut()| number:'1.2-2'}})
                        </div>
                    </div>
                </th>
                <th>
                    <div class="row">
                        <div class="col-12"> Total: RM {{calculateTotal() + transactionBalance| number:'1.2-2'}}</div>
                        <div class="col-12 text-info"> Count: {{reportList.length}}</div>
                    </div>
                </th>
            </tr>
        </tfoot>
    </table>
</div>