<form>
    <!-- Modal Header -->
    <div class="modal-header" style="background-color: cornflowerblue;">
        <h4 class="modal-title">Add Receipts</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body">


        <!--Name-->
        <div class="group" *ngIf="setId==undefined">
            <input type="text" required class="textInputLong" name="name" [(ngModel)]="name">
            <span class="highlight"></span>
            <span class="bar barLong"></span>
            <label> Receipt Title</label>
        </div>

        <!--Remark-->
        <div class="group" *ngIf="setId==undefined">
            <input type="text" required class="textInputLong" name="remark" [(ngModel)]="remark">
            <span class="highlight"></span>
            <span class="bar barLong"></span>
            <label> Remark</label>
        </div>

        <div class="row mt-1">
            <div class="col-12" *ngIf="transactionIDList.length==0">
                <div class="col-4 col-md-2">
                    <div class="row">
                        <div class="col-12">
                            <p>Start Date</p>
                        </div>
                        <div class="col-12">
                            <mat-form-field class="full-width-formField p-0">
                                <input matInput readonly [matDatepicker]="startDatePicker" name="startDate"
                                    [(ngModel)]="startDate" required [max]="sharedService.getTodayDate()">
                                <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #startDatePicker name="startDate"></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>

                </div>

                <div class="col-4 col-md-2">
                    <div class="row">
                        <div class="col-12">
                            <p>End Date</p>
                        </div>
                        <div class="col-12">
                            <mat-form-field class="full-width-formField p-0">
                                <input matInput readonly [matDatepicker]="endDatePicker" name="endDate" [(ngModel)]="endDate"
                                    [min]="startDate" required [max]="sharedService.getTodayDate()">
                                <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #endDatePicker name="endDate"></mat-datepicker>
                            </mat-form-field>
                        </div>

                    </div>
                </div>
                <div class="col-3 d-flex justify-content-end align-items-end">
                    <button class="btn btn-dark" (click)="getUnprocessedTransactions()">Apply</button>
                </div>
            </div>
            <div class="col-12 m-1" *ngIf="transactionIDList.length==0">
                <h3>Unprocessed Transactions</h3>
                <table class="table ">
                    <thead>
                        <tr>
                            <th> <input type="checkbox" class="form-check-input" name="check" [(ngModel)]="isChecked"
                                    [checked]="isCheckedAll()" (change)="checkAll()"></th>
                            <th>Date</th>
                            <th>Remark</th>
                            <th>Customer</th>
                            <th>Type</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let st of transactionList">
                            <td><input type="checkbox" class="form-check-input" name="check" [(ngModel)]="st.isChecked">
                            </td>
                            <td>{{st.transaction.Date | date:'dd-MM-yyyy'}}</td>
                            <td>{{st.transaction.Remark}}</td>
                            <td> <span class="customerLink" *ngIf="st.transaction.CustomerName!=undefined"
                                    (click)="goToCustomer(st.transaction.CustomerID)">{{st.transaction.CustomerName}}
                                    ({{sharedService.processICNum(st.transaction.CustomerIC)}})</span>
                            </td>
                            <td>{{enums.transactionTypeString[st.transaction.Type-1]}}</td>
                            <td>{{st.transaction.Amount}}</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>

            </div>

            <div class="col-12" *ngIf="transactionIDList.length>0">
                <hr />
                <p class="fw-bold text-secondary">OR add into existing receipts</p>
                <div class="card m-1" *ngFor="let set of availableSetList; let i=index">
                    <div class="card-body setCard" [ngClass]="{'selectedCard':set.isChecked==true}"
                        (click)="setSelectedSet(i,set.set.ID)">
                        <p>{{set.set.Name}}</p>
                    </div>
                </div>
            </div>

        </div>

    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="submit()" [disabled]="disabledSubmit"
            *ngIf="setId==undefined">Add
            Receipt</button>

        <button class="btn btn-outline-success" type="button" [disabled]="disabledSubmit" *ngIf="setId!=undefined"
            (click)="confirmBeforeAddingTransactionsIntoSet()">Add into receipt</button>
        <button class="btn btn-outline-danger" type="button" (click)="reset()">Cancel</button>
    </div>

</form>