<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Turn Maintenance Mode - {{turn_on === true ? 'ON' : 'OFF'}}</h4>

        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 aria-label="Close" (click)="reset()"></button>
    </div>


    <div class="modal-body">

        <p class="d-flex justify-content-center align-items-center"> You are about to TURN {{turn_on === true ? 'ON' : 'OFF'}} maintenance mode, please
            key in your password to confirm this action:</p>
        <div class="col-12 d-flex justify-content-center align-items-center">
            <input type="password" class="confirmationPassword p-0 m-0" required name="confirmationPassword"
                [(ngModel)]="confirmationPassword" placeholder="Password">
        </div>


    </div>
    <div class="modal-footer">
        <button class="btn btn-outline-success" type="submit" (click)="submit()"
            [disabled]="disabledSubmit">Submit</button>
        <button class="btn btn-outline-danger" type="button" data-dismiss="modal" (click)="reset()">Cancel</button>
    </div>
</form>