import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';

@Component({
  selector: 'app-validate-epf-statement',
  templateUrl: './validate-epf-statement.component.html',
  styleUrls: ['./validate-epf-statement.component.css']
})
export class ValidateEpfStatementComponent implements OnInit {

  disableSubmit = false;
  file: File;
  constructor(private activeModal: NgbActiveModal, private sharedService: SharedService, private enums: Enums, private restApi: RestApiService, private constructApi: ConstructAPI) { }

  ngOnInit(): void {
  }

  validateEPFImage() {
    var xhr = this.restApi.postFileRequest(this.constructApi.getValidateEPFStatement(), this.file);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {

        if (xhr.status == 200) {
          this.disableSubmit = false;

          this.sharedService.openAlert("This EPF STATEMENT is a VALID statement", this.enums.SUCCESS_ALERT);
          this.file = undefined;


          this.reset();

        }
        else {
          this.disableSubmit = false;
          var json = JSON.parse(xhr.responseText);
          this.sharedService.openAlert(json.error, this.enums.DANGER_ALERT);
        }

      }
    };
  }


  handleFileInput(files: FileList) {
    this.disableSubmit = false;
    this.file = files.item(0);

  }

  reset() {
    this.activeModal.close();
  }
}
