import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Customer } from 'src/model/customer.model';
import { Profile } from 'src/model/profile.model';

@Component({
  selector: 'app-add-hutang-request',
  templateUrl: './add-hutang-request.component.html',
  styleUrls: ['./add-hutang-request.component.css']
})
export class AddHutangRequestComponent implements OnInit {

  constructor(private activeModal: NgbActiveModal, public sharedService: SharedService, private enums: Enums,
    private restApi: RestApiService, private constructApi: ConstructAPI) { }

  disabledSubmit = false;
  selectedCustomer: Customer;
  selectedProfile: Profile;
  loanAmount: number;
  remark: string;

  ngOnInit(): void {
  }

  updateSalaryDetailsAndTaskDate() { }

  openUploadApplicationFormModal() { }

  submit() {

    if (this.loanAmount == undefined || this.loanAmount == 0)
      this.sharedService.openAlert("Please fill in HUTANG AMOUNT", this.enums.DANGER_ALERT)
    else if (this.remark == "" || this.remark == undefined)
      this.sharedService.openAlert("Please fill in HUTANG REASON", this.enums.DANGER_ALERT)
    else {
      this.addHutangLoanRequest();
    }

  }

  addHutangLoanRequest() {
    var loanData = {
      "principal": this.loanAmount,
      "scheme": "HUTANG",
      "term": 1
    }

    var data = {
      "profileId": this.selectedProfile.ID,
      "loanInfo": loanData,
      "remark": this.remark,
      "requestOn": new Date().toISOString(),
    }

    var xhr = this.restApi.postRequest(this.constructApi.getAddLoanRequest(), data)
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        //if success , then proceed
        if (xhr.status == 200) {
          window.location.reload();

        }
        else {
          this.disabledSubmit = false;
          //show error message
          this.sharedService.openAlert(JSON.parse(xhr.responseText).error, this.enums.DANGER_ALERT)

        }
      }
    }
  }
  reset() {
    this.activeModal.close();
  }
}
