<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Generate Form J</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col p-2" style="background-color:lavender ;">
                <mat-slide-toggle class="mat-slide-toggle-lg" [hideIcon]="true" color="primary" [(ngModel)]="isEPF" name="isEPF">
                    EPF LOAN
                </mat-slide-toggle>
            </div>
        </div>
        <div class="bg-light m-5" *ngIf="isEPF==true">
            <div class="row">
                <div class="col-6 d-flex justify-content-center align-items-center">
                    <p>Months:</p>
                    <input type="text" required class="textInputLong" name="selectedMonths" [(ngModel)]="selectedMonths"
                        (keypress)="sharedService.isNumberKey($event)">
                </div>
                <div class="col-6 d-flex justify-content-center align-items-center">
                    <div class="row">
                        <div class="col-6">
                            <!-- <p>Payment Amount:</p> -->
                            <!-- <input type="text" required class="textInputLong" name="desiredTotal"
                                [(ngModel)]="desiredTotal" (keypress)="sharedService.isNumberKey($event)"> -->
                            <p>On Hand Amount:</p>
                            <input type="text" required class="textInputLong" name="onHandAmount"
                                [(ngModel)]="onHandAmount" (keypress)="sharedService.isNumberKey($event)">
                        </div>
                        <div class="col-12 d-flex align-items-right justify-content-end">
                            <button class="btn btn-info" (click)="checkEPFValidity()" [disabled]="disabledSubmit">
                                Calculate</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="isEPF==false">
            <table class="table">
                <tbody>
                    <tr>
                        <th class="text-center">Months</th>
                        <th class="text-center">Amount (RM)</th>
                    </tr>
                    <tr *ngFor="let schedule of paymentScheduleList;let i=index">
                        <td class="text-center"> {{schedule.monthsCount}}</td>
                        <td class="text-center"> {{schedule.amount}}
                            <h4 class="text-danger d-inline ms-1" style="cursor: pointer;" (click)="removeSchedule(i)">
                                <i class="fas fa-times"></i>
                            </h4>
                        </td>
                    </tr>
                </tbody>
                <tfoot *ngIf="paymentScheduleList.length>0">
                    <tr>
                        <th class="text-center">
                            {{selectedMonths}} months </th>
                        <th class="text-center">
                            RM {{desiredTotal}}</th>
                    </tr>
                </tfoot>
            </table>
            <div class="bg-light m-5">
                <div class="row">
                    <div class="col-6 d-flex justify-content-center align-items-center">
                        <p>Months:</p>
                        <input type="text" required class="textInputLong" name="monthsCount" [(ngModel)]="monthsCount"
                            (keypress)="sharedService.isNumberKey($event)">
                    </div>
                    <div class="col-6 d-flex justify-content-center align-items-center">
                        <div class="row">
                            <div class="col-6">
                                <p>Amount:</p>
                                <input type="text" required class="textInputLong" name="amount" [(ngModel)]="amount"
                                    (keypress)="sharedService.isNumberKey($event)">
                            </div>
                            <div class="col-6">
                                <button class="btn btn-primary" (click)="checkValidity()" [disabled]="disabledSubmit">
                                    Add Schedule</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="m-5">
        <div class="card">
            <div class="card-body">
                <h4>Pinjaman (Pokok): RM {{principal}}</h4>
                <!-- <h4 *ngIf="selectedMonths>1">Payment : RM {{payment}}</h4>
                <h4 *ngIf="selectedMonths>1">Total Payment : RM {{totalPayment}}</h4> -->
                <h4 class="text-primary" *ngIf="isEPF==true && selectedMonths-1>0">Monthly Payment : RM {{epfMonthly}} x
                    {{(selectedMonths-1)}} months</h4>
                <h4>Last Payment Amount : RM {{lastPayment}}</h4>

            </div>
        </div>

        <br>
        <div class="row">
            <div class="col-12">
                <p>Form J Date</p>
            </div>
            <div class="col-12">
                <mat-form-field class="full-width-formField p-0">
                    <input matInput readonly [matDatepicker]="stampDatePicker" name="stampDate" [(ngModel)]="stampDate"
                        [min]="stampDate" required>
                    <mat-datepicker-toggle matSuffix [for]="stampDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #stampDatePicker name="stampDate"></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="group">
                <input type="text" required class="textInputLong" name="receiptNumber" [(ngModel)]="receiptNumber">
                <span class="highlight"></span>
                <span class="bar barLong"></span>
                <label>Receipt Number</label>
            </div>
        </div>
        <div class="d-flex justify-content-end align-itmes-right">
            <button class="btn btn-primary" (click)="checkGenerateValidity()" [disabled]="disabledSubmit"> Generate
            </button>
        </div>
    </div>




</form>