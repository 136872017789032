import { Contact } from "./contact.model";
import { LoanRequest } from "./loanRequest.model";

export class SharedLoanCompany {


    constructor(private _agreed: boolean, private _companyId: number, private _companyCode: string,
        private _groupId: number) { }

    public get Agreed(): boolean {
        return this._agreed;
    }
    public set Agreed(value: boolean) {
        this._agreed = value;
    }
    public get GroupId(): number {
        return this._groupId;
    }
    public set GroupId(value: number) {
        this._groupId = value;
    }
    public get CompanyCode(): string {
        return this._companyCode;
    }
    public set CompanyCode(value: string) {
        this._companyCode = value;
    }
    public get CompanyId(): number {
        return this._companyId;
    }
    public set CompanyId(value: number) {
        this._companyId = value;
    }
}


export interface LoanGroupRequest {
    loanGroup: number,
    requestList: LoanRequest[]
  
  }

export class SharedLoanRequest {

    constructor(private _authorizedBy: string, private _authorizedOn: Date, private _companies: SharedLoanCompany[], private _guarantors: Contact[],
        private _loanGroupId: number, private _loansInfo: LoanRequest[], private _processorRoleRequired: number,
        private _requestBy: string, private _requestOn: Date, private _sharedProfileId: number, private _customerName: string) { }

    public get SharedProfileId(): number {
        return this._sharedProfileId;
    }
    public set SharedProfileId(value: number) {
        this._sharedProfileId = value;
    }


    public get RequestBy(): string {
        return this._requestBy;
    }
    public set RequestBy(value: string) {
        this._requestBy = value;
    }
    public get ProcessorRoleRequired(): number {
        return this._processorRoleRequired;
    }
    public set ProcessorRoleRequired(value: number) {
        this._processorRoleRequired = value;
    }
    public get LoanRequestsInfo(): LoanRequest[] {
        return this._loansInfo;
    }
    public set LoanRequestsInfo(value: LoanRequest[]) {
        this._loansInfo = value;
    }
    public get LoanGroupId(): number {
        return this._loanGroupId;
    }
    public set LoanGroupId(value: number) {
        this._loanGroupId = value;
    }
    public get RequestOn(): Date {
        return this._requestOn;
    }
    public set RequestOn(value: Date) {
        this._requestOn = value;
    }
    public get Guarantors(): Contact[] {
        return this._guarantors;
    }
    public set Guarantors(value: Contact[]) {
        this._guarantors = value;
    }
    public get Companies(): SharedLoanCompany[] {
        return this._companies;
    }
    public set Companies(value: SharedLoanCompany[]) {
        this._companies = value;
    }
    public get AuthorizedOn(): Date {
        return this._authorizedOn;
    }
    public set AuthorizedOn(value: Date) {
        this._authorizedOn = value;
    }
    public get AuthorizedBy(): string {
        return this._authorizedBy;
    }
    public set AuthorizedBy(value: string) {
        this._authorizedBy = value;
    }

    public get CustomerName(): string {
        return this._customerName;
    }
    public set CustomerName(value: string) {
        this._customerName = value;
    }


}