<!-- <form> -->
    <!-- Modal Header -->
    <div class="modal-header" [class]="checkType()">
        <h4 class="modal-title" *ngIf="alertType==enums.DANGER_ALERT">WARNING!</h4>
        <h4 class="modal-title" *ngIf="alertType==enums.SUCCESS_ALERT">SUCCESS!</h4>
        <!-- <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="closeDialog()"></button> -->
    </div>

    <div class="modal-body">
        <div class="container-fluid">
            <div class="p-3 messageText">
                {{message}}
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="closeModal()">Okay</button>
    </div>
<!-- </form> -->