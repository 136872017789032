<div class="content m-3">

  <ng-container>
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link active" role="tab"  data-bs-toggle="tab" href="#tabCustomer">
          <p class="d-block">Customer</p>
        </a>
      </li>
      <li class="nav-item " *ngIf="selectedCompany!=undefined">
        <a class="nav-link " role="tab"  data-bs-toggle="tab" href="#tabLeads">
          <p class="d-block">Leads</p>
        </a>
      </li>
    </ul>

    <div class="tab-content">
      <div class="tab-pane active" role="tabpanel" id="tabCustomer">
        <h3 class="text-start" id="pageTitle" style="margin: 15px;">Customer List</h3>
        <div *ngIf="!sharedService.isFinance()">
          <button class="btn btn-primary float-end" (click)="openAddCustomerDialog()"><i class="fas fa-plus"></i> Add
            Customer</button>
        </div>

        <div class="row">
          <div class="col">
            <mat-form-field>
              <mat-label>Filter</mat-label>
              <input matInput (keyup)="applyFilter($event)" placeholder="Enter searching criteria">
            </mat-form-field>
          </div>
          <div class="col">
            <select class="browser-default form-select" [(ngModel)]="selectedStatus" (change)="filterByStatus()">
              <option></option>
              <ng-container *ngFor="let status of enums.customerStatusString;let i=index">
                <option [value]="i+1">
                  {{status}}
                </option>
              </ng-container>

            </select>
          </div>
        </div>


        <div class="mat-elevation-z8">
          <table mat-table [dataSource]="dataSource" matSort>

            <!-- Name Column -->
            <ng-container matColumnDef="Name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
              <td mat-cell *matCellDef="let customer" (click)="goToCustomer(customer.ID)"> <span
                  class="badge text-bg-success" *ngIf="checkNewCustomer(customer)==true">NEW </span> {{customer.Name}}
              </td>
            </ng-container>

            <!-- Occupation Column -->
            <!-- <ng-container matColumnDef="Occupation">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Occupation </th>
              <td mat-cell *matCellDef="let customer" (click)="goToCustomer(customer.ID)">
                {{customer.Occupation}} </td>
            </ng-container> -->

            <!-- ICNumber Column -->
            <ng-container matColumnDef="ICNumber">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> IC Number </th>
              <td mat-cell *matCellDef="let customer" (click)="goToCustomer(customer.ID)">
                {{sharedService.processICNum(customer.ICNumber)}} </td>
            </ng-container>

            <!-- Remark Column -->
            <!-- <ng-container matColumnDef="Remark">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Remark </th>
              <td mat-cell *matCellDef="let customer" (click)="goToCustomer(customer.ID)"> {{customer.Remark}} </td>
            </ng-container> -->

            <!-- Status Column -->
            <ng-container matColumnDef="Status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
              <td mat-cell *matCellDef="let customer">
                <ng-container *ngFor="let status of customer.Status">
                  <h5 class="d-inline ms-1">

                    <!--POPOVER CUSTOMER LOAN LIST FILTER BY STATUS-->
                    <ng-template #popContent>
                      <div *ngIf="isLoanListReady==false;else loansList">
                        <mat-spinner></mat-spinner>
                      </div>

                      <ng-template #loansList>
                        <div class="card mb-2">
                          <div class="card-body">
                            <b>{{customer.Name}} ({{sharedService.processICNum(customer.ICNumber) }})</b>
                          </div>
                        </div>
                        <ng-container *ngFor="let loan of customerLoansList">
                          <app-loan-card [loan]="loan" [isSimplifiedView]="true"></app-loan-card>
                        </ng-container>
                      </ng-template>

                    </ng-template>


                    <span class="badge text-bg-primary" [ngbPopover]="popContent"
                      [ngClass]="getStatusColor(status.StatusCode)"
                      (click)="getCustomerLoans(status.StatusCode,customer.ID)"
                      container="body">{{convertStatusString(status.StatusCode)}}
                      x
                      {{status.Occurrence}}
                    </span>
                  </h5>
                </ng-container>

              </td>
            </ng-container>

            <!-- Remark Column -->
            <ng-container matColumnDef="Remark">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Remark </th>
              <td mat-cell *matCellDef="let customer;let i=index">

                <h3 class="d-inline ms-1" *ngIf="customer.NeedAttention==undefined"
                  (click)="addCustomerRemark(customer.ID,i)">
                  <span class="badge text-bg-primary" style="cursor:pointer;">
                    <i class="fas fa-comment-medical"></i>
                  </span>
                </h3>

                <h3 class="d-inline ms-1" *ngIf="customer.NeedAttention==false"
                  (click)="viewCustomerRemark(customer.ID,customer.Name)">
                  <span class="badge text-bg-warning" style="cursor:pointer;">
                    <i class="far fa-comment"></i>
                  </span>
                </h3>

                <h3 class="d-inline ms-1" *ngIf="customer.NeedAttention==true"
                  (click)="viewCustomerRemark(customer.ID,customer.Name)">
                  <span class="badge text-bg-danger" style="cursor:pointer;">
                    <i class="fas fa-exclamation-circle"></i>
                  </span>
                </h3>

              </td>
            </ng-container>

            <!-- Action Column -->
            <ng-container matColumnDef="Action">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
              <td mat-cell *matCellDef="let customer">
                <button class="btn btn-info" (click)="viewCustomerImages(customer.ID)" #tooltip="matTooltip"
                  matTooltip="View Customer Docs" matTooltipPosition="above" matTooltipHideDelay="100"
                  matTooltipClass="tooltip-black"><i class="fas fa-images"></i></button>


                <button class="btn btn-success ms-1" *ngIf="customer.Verified==false && !sharedService.isFinance()"
                  (click)="confirmBeforeVerifyCustomer(true,customer.ID)" #tooltip="matTooltip"
                  matTooltip="Verify Customer" matTooltipPosition="above" matTooltipHideDelay="100"
                  matTooltipClass="tooltip-black" [hidden]="sharedService.isAuditor()==true">
                  <i class="fas fa-user-check"></i>
                </button>

                <button class="btn btn-dark ms-1" *ngIf="customer.Verified==true && !sharedService.isFinance()"
                  (click)="confirmBeforeVerifyCustomer(false,customer.ID)" #tooltip="matTooltip"
                  matTooltip="Unverify Customer" matTooltipPosition="above" matTooltipHideDelay="100"
                  matTooltipClass="tooltip-black" [hidden]="sharedService.isAuditor()==true">
                  <i class="fas fa-user-times"></i>
                </button>
                <span class="badge text-bg-success ms-1" style="font-size:0.9rem" *ngIf="customer.Verified==true && !sharedService.isFinance()">Verified
                  by
                  {{customer.VerifiedBy || '-'}}
                </span>


              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>

          <mat-paginator [pageSizeOptions]="[25,50, 100]" showFirstLastButtons></mat-paginator>
        </div>
      </div>
      <div class="tab-pane" role="tabpanel" id="tabLeads" *ngIf="selectedCompany!=undefined">
        <app-customer-lead-list></app-customer-lead-list>
      </div>
    </div>
  </ng-container>

</div>