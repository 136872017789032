import { Injectable } from '@angular/core';
import { EPFLimit } from 'src/model/epfLimit.model';

@Injectable({
  providedIn: 'root'
})

export class Enums {

  //loanStatus 
  ACTIVE_LOAN = 1;
  WARNING_LOAN = 2;
  BAD_DEBT_LOAN = 3;
  CP_LOAN = 4;
  SETTLED_LOAN = 5;
  LBD_LOAN = 6;

  //Role Name
  STAFF = 1;
  BRANCH_MANAGER = 2;
  ADMIN = 3;
  BRANCH_MANAGER_HEAD = 4;
  BOSS = 5;
  BOOKKEEPING_STAFF = 6;
  EXPENSE_ADMIN = 7;
  AUDITOR = 8;
  FINANCE = 9;

  //customerStatus
  ACTIVE_CUST = 1;
  WARNING_CUST = 2;
  BAD_DEBT_CUST = 3;
  CP_CUST = 4;
  SETTLED_CUST = 5;
  LBD_CUST = 6;


  //PayByReserveOptions
  PAY_MONTHLY = 1;
  PAY_PRINCIPAL = 2;
  WITHDRAW_INTEREST_CHARGE = 3;

  // REPORT
  CASH_BOOK = 1;
  LIST_HUTANG = 2;
  STOCK = 3;
  DAILY_REPORT = 4;
  CUSTOMER_RECORD_REPORT = 5;

  STAMP_REPORT = 6;
  SC_REPORT = 7;
  EXTRA_REPORT = 8;
  HANDLING_REPORT = 9;
  ASSET_REPORT = 10
  HUTANG_REPORT = 11;
  SIMPAN_REPORT = 12;
  COMMISSION_REPORT = 13;
  SIMPAN_BD_REPORT = 14;

  // INCOME_REPORT = 12;
  EXPENSE_REPORT = 15;
  EXPENSE_BY_EXTRAS_REPORT = 16;
  DISCOUNT_REPORT = 17;
  ADVANCE_REPORT = 18;
  SIMPAN_BALANCE_REPORT = 19;
  SIMPAN_BD_TRANSACTION_REPORT = 20;
  HUTANG_BALANCE_REPORT = 21;
  MIDMONTH_REPORT = 22;
  FORMJ_REPORT = 23;
  OVERDUE_EPF_REPORT = 24;
  USAGE_REPORT = 25;
  CAPITAL_REPORT = 26;
  SAVING_REPORT = 27;
  TOTING_REPORT = 28;

  ASSET_SUMMARY_REPORT = 29;
  COMPANY_SUMMARY_REPORT = 30;
  OFFICE_CASH_REPORT = 31;






  //TRANSACTION_TYPE
  PRINCIPAL = 1;
  MONTHLY_INTEREST = 2;
  REPAYMENT = 3;
  STAMP = 4;
  SERVICE_CHARGE = 5;
  ARREAR_CHARGES = 6;
  EXTRAS = 7;
  ASSET = 8;
  BAD_DEBT = 9;
  RESERVE = 10;
  ADVANCE = 11;
  TRANSFER = 12;
  REALLOCATION = 13;
  INCOME = 14;
  EXPENSE = 15;
  CAPITAL = 16;
  SAVING = 17;
  HUTANG = 18;
  PROCESSING_CHARGE = 19;
  DISCOUNT = 20;
  TOTING = 21;
  EXTRA_INTEREST = 22;
  BOUNTY = 23;
  HANDLING_CHARGE = 24;


  //alert dialog type
  SUCCESS_ALERT = 1;
  DANGER_ALERT = 2;

  //taskType
  SALARYT_TASK = 1;
  EPF_TASK = 2;
  FORM_J_TASK = 3;
  OTHER_TASK = 4;


  //fixedSkim processing rate 
  FIXED_SCHEME_PROCESSING_RATE = 10;

  //skim A processing rate
  SKIM_A_PROCESSING_RATES = [8, 9, 10, 11, 12];





  //minimum ads rate for duit lebih = 2%
  MINIMUM_ADS_RATE = 0.02;

  // minimum interest rate for epf customer
  MINIMUM_NEW_EPF_RATE = 0.05;
  MINIMUM_EXTRA_EPF_RATE = 0.08;
  //if it is EXTRA SHARE customer, need to add handling charge rate on top of basic epf settlement interest rate
  MININUM_HANDLING_RATE = 0.01;


  MINIMUM_SETTLEMENT_STAMP_PERMONTH = 20;
  MINIMUM_SETTLEMENT_SC_PERMONTH = 6;

  MINIMUM_STAMP = 20;
  MINIMUM_SC = 6;


  MINIMUM_SHARED_LOAN_PORTION = 0.8;

  //NEED TO SET TO MAXIMUM STAMP AND SC FOR NORMAL SCHEME, NOW HOLD FIRST 
  MAXIMUM_STAMP_NORMAL_SCHEME = 999999;
  MAXIMUM_SC_NORMAL_SCHEME = 999999;

  MINIMUM_SENIOR_RESTRICTED_AGE = 50;
  MAXIMUM_SENIOR_RESTRICTED_AGE = 99; // if follow new SOP, all customer above 50 years old need to have loan request (Aug2024)


  EPF_TIER_1 = 50;
  EPF_TIER_2 = 55;
  EPF_TIER_3 = 60;

  RESTRICTED_AGE_BUFFER_DAY = 30; //{?} days after maximum restricted age still consider restricted

  VALID_CUSTOMER_AGE = 10; // temporary set customer need to have at least 10 years old


  // MAXIMUM_EPF_LIMIT_TERM = 17;
  // MAXIMUM_EPF_PRIINCIPAL_LIMIT_RATE = 0.07;


  //
  epfLimitList: EPFLimit[] =

    [
      // new EPFLimit(36, 25, 0.06, undefined, 0.06, 0.25),

      //UPPER LIMIT, LOWER LIMIT, FIRST LOAN, MAXIMUM SETTLEMNT RATE, OVERALL LOAN RATE, ELEVATED RATIO,MINIMUM EPF AMOUNT, MINIMUM INTERST RATE

      // new EPFLimit(24, 18, 0.06, 0.18, 0.06, undefined, 150000, undefined, this.MINIMUM_NEW_EPF_RATE, false),//add maximum settlement ratio into 0.18, orginally does not have this, and remove elevated ratio 0.25
      new EPFLimit(24, 18, 0.04, 0.18, 0.04, undefined, 100000, undefined, this.MINIMUM_NEW_EPF_RATE, true),//add maximum settlement ratio into 0.18, orginally does not have this, and remove elevated ratio 0.25
      new EPFLimit(17, 17, 0.06, 0.18, 0.06, undefined, 30000, undefined, this.MINIMUM_NEW_EPF_RATE, true),//add maximum settlement ratio into 0.18, orginally does not have this, and remove elevated ratio 0.25
      new EPFLimit(16, 15, 0.08, 0.18, 0.08, undefined, 30000, undefined, this.MINIMUM_NEW_EPF_RATE, true), //originally from here not worry about first loan limit, just leave empty
      new EPFLimit(14, 12, 0.10, 0.18, 0.10, undefined, 30000, undefined, this.MINIMUM_NEW_EPF_RATE, true),
      new EPFLimit(11, 11, 0.116, 0.18, undefined, undefined, undefined, undefined, this.MINIMUM_EXTRA_EPF_RATE, true),
      new EPFLimit(10, 10, 0.12, 0.19, undefined, undefined, undefined, undefined, this.MINIMUM_EXTRA_EPF_RATE, true),
      new EPFLimit(9, 9, 0.12, 0.20, undefined, undefined, undefined, undefined, this.MINIMUM_EXTRA_EPF_RATE, true),
      new EPFLimit(8, 8, 0.12, 0.21, undefined, undefined, undefined, undefined, this.MINIMUM_EXTRA_EPF_RATE, true),
      new EPFLimit(7, 7, 0.12, 0.22, undefined, undefined, undefined, undefined, this.MINIMUM_EXTRA_EPF_RATE, true),
      new EPFLimit(6, 6, 0.12, 0.23, undefined, undefined, undefined, undefined, this.MINIMUM_EXTRA_EPF_RATE, true),
      new EPFLimit(5, 5, 0.12, 0.24, undefined, undefined, undefined, undefined, this.MINIMUM_EXTRA_EPF_RATE, true),
      new EPFLimit(4, 1, 0.12, 0.25, undefined, undefined, undefined, undefined, this.MINIMUM_EXTRA_EPF_RATE, true),
    ]
  // [
  //   // new EPFLimit(36, 25, 0.06, undefined, 0.06, 0.25),

  //   //UPPER LIMIT, LOWER LIMIT, FIRST LOAN, MAXIMUM SETTLEMNT RATE, OVERALL LOAN RATE, ELEVATED RATIO,MINIMUM EPF AMOUNT, MINIMUM INTERST RATE

  //   // new EPFLimit(24, 18, 0.06, 0.18, 0.06, undefined, 150000, undefined, this.MINIMUM_NEW_EPF_RATE, false),//add maximum settlement ratio into 0.18, orginally does not have this, and remove elevated ratio 0.25
  //   new EPFLimit(24, 18, 0.04, 0.18, 0.04, undefined, 100000, undefined, this.MINIMUM_NEW_EPF_RATE, true),//add maximum settlement ratio into 0.18, orginally does not have this, and remove elevated ratio 0.25
  //   new EPFLimit(17, 17, 0.06, 0.18, 0.06, undefined, 30000, undefined, this.MINIMUM_NEW_EPF_RATE, true),//add maximum settlement ratio into 0.18, orginally does not have this, and remove elevated ratio 0.25
  //   new EPFLimit(16, 15, 0.08, 0.18, 0.08, undefined, 30000, undefined, this.MINIMUM_NEW_EPF_RATE, true), //originally from here not worry about first loan limit, just leave empty
  //   new EPFLimit(14, 12, 0.08, 0.18, undefined, undefined, 30000, undefined, this.MINIMUM_NEW_EPF_RATE, true),
  //   new EPFLimit(11, 4, 0.12, undefined, undefined, 0.25, undefined, undefined, this.MINIMUM_NEW_EPF_RATE, true),
  //   new EPFLimit(3, 1, 0.12, undefined, undefined, 0.25, undefined, undefined, this.MINIMUM_EXTRA_EPF_RATE, true),
  // ]

  draftEPFLimitList: EPFLimit[];

  //will maintain another set of epf rule for upcoming epf limit changes
  // draftEPFLimitList: EPFLimit[] =
  //   [
  //     // new EPFLimit(36, 25, 0.06, undefined, 0.06, 0.25),

  //     //UPPER LIMIT, LOWER LIMIT, FIRST LOAN, MAXIMUM SETTLEMNT RATE, OVERALL LOAN RATE, ELEVATED RATIO,MINIMUM EPF AMOUNT, MINIMUM INTERST RATE

  //     // new EPFLimit(24, 18, 0.06, 0.18, 0.06, undefined, 150000, undefined, this.MINIMUM_NEW_EPF_RATE, false),//add maximum settlement ratio into 0.18, orginally does not have this, and remove elevated ratio 0.25
  //     new EPFLimit(24, 18, 0.04, 0.18, 0.04, undefined, 100000, undefined, this.MINIMUM_NEW_EPF_RATE, true),//add maximum settlement ratio into 0.18, orginally does not have this, and remove elevated ratio 0.25
  //     new EPFLimit(17, 17, 0.06, 0.18, 0.06, undefined, 30000, undefined, this.MINIMUM_NEW_EPF_RATE, true),//add maximum settlement ratio into 0.18, orginally does not have this, and remove elevated ratio 0.25
  //     new EPFLimit(16, 15, 0.08, 0.18, 0.08, undefined, 30000, undefined, this.MINIMUM_NEW_EPF_RATE, true), //originally from here not worry about first loan limit, just leave empty
  //     new EPFLimit(14, 12, 0.08, 0.18, undefined, undefined, 30000, undefined, this.MINIMUM_NEW_EPF_RATE, true),
  //     new EPFLimit(11, 11, 0.12, 0.18, undefined, undefined, undefined, undefined, this.MINIMUM_EXTRA_EPF_RATE, true),
  //     new EPFLimit(10, 10, 0.12, 0.19, undefined, undefined, undefined, undefined, this.MINIMUM_EXTRA_EPF_RATE, true),
  //     new EPFLimit(9, 9, 0.12, 0.20, undefined, undefined, undefined, undefined, this.MINIMUM_EXTRA_EPF_RATE, true),
  //     new EPFLimit(8, 8, 0.12, 0.21, undefined, undefined, undefined, undefined, this.MINIMUM_EXTRA_EPF_RATE, true),
  //     new EPFLimit(7, 7, 0.12, 0.22, undefined, undefined, undefined, undefined, this.MINIMUM_EXTRA_EPF_RATE, true),
  //     new EPFLimit(6, 6, 0.12, 0.23, undefined, undefined, undefined, undefined, this.MINIMUM_EXTRA_EPF_RATE, true),
  //     new EPFLimit(5, 5, 0.12, 0.24, undefined, undefined, undefined, undefined, this.MINIMUM_EXTRA_EPF_RATE, true),
  //     new EPFLimit(4, 1, 0.12, 0.25, undefined, undefined, undefined, undefined, this.MINIMUM_EXTRA_EPF_RATE, true),
  //   ]


  //To make sure the epf customer within this term range have minimum amount of epf amount before proceed to check other epf limits
  epfAmountCategory: EPFLimit[] = [
    new EPFLimit(24, 18, undefined, undefined, undefined, undefined, 100000, undefined, undefined),
    new EPFLimit(17, 12, undefined, undefined, undefined, undefined, 30000, undefined, undefined),
    new EPFLimit(11, 1, undefined, undefined, undefined, undefined, 0, undefined, undefined),
  ]


  //EPF EXTRA CHARGES LIMIT CONST

  // * SSC = stamp + service charge
  MIN_SSC_PER_TERM = 26; // minimum SSC
  MIN_SSC_STARTING_PRINCIPAL = 500; // any principal less than this will have a minimum SSC 
  SSC_PER_TERM_PER_STEP = 26; // for every principal step after starting principal, SSC increased by this amount

  PRINCIPAL_STEP = 1000;
  MAX_SSC_OVERCOLLETED = 0.6; // maximum SSC over collected
  STAMP_PER_SSC = 10.0 / 13;// Stamp portion of SSC (floor)
  SC_PER_SSC = 3.0 / 13;// Service charge portion of SSC (ceiling)







  //COMMISSION LIMIT
  EPF_COMMISSION_MAX_RATE = 0.06;
  OTHER_COMMISSION_MAX_RATE = 0.04;


  // DUIT LEBIH SUBTYPE
  ADS_SUBTYPE_ID = 10;
  BORROW_ADV_SUBTYPE_ID = 111;
  LOAN_EXPENSE_SUBTYPE_ID = 7;


  //RESERVE SUBTYPE
  RESERVE_EXPENSE_SUBTYPE_ID = 1;

  //SUPPORTING DOCUMENTS
  APPLICATION_FORM = "application";
  FORM_J = "formj";
  EPF_STATEMENT = "epfStatement";
  PHOTO = "photo";
  CONSENT_FORM = "consentForm";
  IC_FRONT = "icFront";
  IC_BACK = "icBack";
  REFERENCE_FORM = "referenceForm";
  PAYSLIP = "payslip";
  BANK_STATEMENT = "bankStatement";
  ATM_CARD = "atmCard";
  EPF_PROOF = "epfProof";
  ACKNOWLEDGEMENT = "acknowledgement";

  //LOAN DOCUMENTS TYPE
  PAYOUT_SLIP = "payOutSlip";


  //CONTACT DOCUMENTS
  CONTACT_GUARANTOR_FORM = "guarantorForm";
  CONTACT_PROOF_OF_RELATION = "proofOfRelation";
  CONTACT_ACKNOWLEDGEMENT = "acknowledgement";
  CONTACT_PHOTO = "photo";


  //PRIORITY MANAGEMENT
  FIRST_PRIORITY = 100;
  SECOND_PRIORITY = 200;
  THIRD_PRIORITY = 300;
  HIGHEST_ADMIN_PRIORITY = 500;
  AUDITOR_PRIORITY = 800;
  CP_PRIORITY = 880;

  //taskTypeString
  taskTypeString = ["SALARY", "EPF", "FORM J", "OTHERS"];

  customerNecessaryDocs = [this.APPLICATION_FORM, this.IC_FRONT, this.IC_BACK, this.REFERENCE_FORM, this.CONSENT_FORM];
  customerNecessaryDocsString = ["Application Form", "IC Front", "IC Back", "Reference Form", "Consent Form"];

  customerOptionalDocs = [this.EPF_STATEMENT, this.PAYSLIP, this.BANK_STATEMENT, this.ATM_CARD, this.ACKNOWLEDGEMENT, this.EPF_PROOF];
  customerOptionalDocsString = ["EPF Statement", "Payslip", "Bank Statement", "ATM Card", "Acknowledgement Video", "EPF Proof Video"];

  reportString = ["Cash Book", "List Hutang", "Stock", "Daily Report", "Customer Report", "Stamp Report", "S/C Report", "Duit Lebih Report", "Handling Report", "Asset Report", "Hutang Report", "Simpan Report",
    "Paid Commission Report", "Simpan Bad Debt Report", "Expense Report", "Expense By Duit Lebih Report", "Discount Report", "Advance Report", "Simpan Balance Report", "Simpan BD Transaction List", "Hutang Balance Report", "MidMonth Report",
    "Overdue FormJ Report", "Overdue EPF Report", "Usage Report", "Capital Report",
    "Saving Report", "Toting Report", "Asset Summary Report", "Companies Summary Report", "Office Cash Report"];

  //customerStatusString
  customerStatusString = ["ACTIVE", "WARNING", "BAD DEBT", "CP", "SETTLED", "LEGAL"];

  //loanStatusString
  loanStatusString = ["ACTIVE", "WARNING", "BAD DEBT", "CP", "SETTLED", "LEGAL"];

  transactionTypeString = ["Principal", "Monthly Interest", "Repayment", "Stamp", "Service Charge", "Arrear Charges",
    "Extras", "Asset", "BadDebt", "Reserve", "Advance", "Transfer", "Reallocation", "Income", "Expense", "Capital", "Saving", "Hutang",
    "Processing Charge", "Discount", "Toting", "Extra Interest", "Bounty", "Handling Charge"];


  roleString = ["Staff", "Branch Manager", "Admin", "Branch Manager Head", "Boss", "Bookkeeping Staff", "Expense Admin", "Auditor"];

  generalDeleteReasonString = ["wrong acct", "wrong date", "should pay by simpan", "wrong amount", "wrong %", "duplicate entry", "wrong skim", "wrong customer", "customer change of mind"]

  genderOptions = ['Male', 'Female', 'Other']
  raceOptions = ['Indian', 'Malay', 'Chinese', 'Other']

}

