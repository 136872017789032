<div class="container-fluid  ">
    <h4 class="text-primary fw-bold text-end">CashBook Balance: {{cashBookAmount}}</h4>
    <table class="table table-striped px-5">
        <thead>
            <tr>
                <th class="text-center">Scheme</th>
                <th class="text-end">Stock</th>
                <th class="text-end">Total Outstanding</th>
                <th class="text-end" *ngIf="needStatusOutstanding==true">Active Outstanding</th>
                <th class="text-end" *ngIf="needStatusOutstanding==true">Bad Debt Outstanding</th>
                <th class="text-end" *ngIf="needStatusOutstanding==true">CP Outstanding</th>
                <th class="text-end" *ngIf="needStatusOutstanding==true">LBD Outstanding</th>

            </tr>
        </thead>
        <tbody *ngFor="let stock of stockList">
            <tr>
                <td class="text-center stockCol" (click)="goToLoanSchemeReport(stock.schemeName)">{{stock.schemeName}}
                </td>

                <td class="text-end">{{stock.stock - stock.stockReceived | number:'1.2-2'}}</td>
                <td class="text-end">{{stock.activeOutstanding + stock.bdOutstanding + stock.cpOutstanding +
                    stock.lbdOutstanding|
                    number:'1.2-2'}}</td>
                <td class="text-end" *ngIf="needStatusOutstanding==true">{{stock.activeOutstanding| number:'1.2-2'}}
                </td>
                <td class="text-end" *ngIf="needStatusOutstanding==true">{{stock.bdOutstanding| number:'1.2-2'}}</td>
                <td class="text-end" *ngIf="needStatusOutstanding==true">{{stock.cpOutstanding| number:'1.2-2'}}</td>
                <td class="text-end" *ngIf="needStatusOutstanding==true">{{stock.lbdOutstanding| number:'1.2-2'}}</td>


            </tr>
        </tbody>
        <tfoot style="border-top:2px solid black">
            <tr>
                <th class="text-end">Total:</th>
                <th class="text-end">{{calculateTotalStock()| number:'1.2-2'}}</th>
                <th class="text-end">{{calculateTotalOutstanding()| number:'1.2-2'}}</th>
                <th class="text-end" *ngIf="needStatusOutstanding==true">{{calculateTotalActiveOutstanding()|
                    number:'1.2-2'}}</th>
                <th class="text-end" *ngIf="needStatusOutstanding==true">{{calculateTotalBDOutstanding()|
                    number:'1.2-2'}}</th>
                <th class="text-end" *ngIf="needStatusOutstanding==true">{{calculateTotalCPOutstanding()|
                    number:'1.2-2'}}</th>
                <th class="text-end" *ngIf="needStatusOutstanding==true">{{calculateTotalLBDOutstanding()|
                    number:'1.2-2'}}</th>
            </tr>
        </tfoot>
    </table>
</div>