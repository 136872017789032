import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { LoanRequest } from 'src/model/loanRequest.model';

@Component({
  selector: 'app-authorize-loan-request',
  templateUrl: './authorize-loan-request.component.html',
  styleUrls: ['./authorize-loan-request.component.css']
})
export class AuthorizeLoanRequestComponent implements OnInit {

  @Input() request: LoanRequest;
  @Input() onlyViewLoan = false;

  @Input() requestList: LoanRequest[] = new Array;

  disabledSubmit = false;

  remark = "";

  constructor(private activeModal: NgbActiveModal, private restApi: RestApiService, private constructApi: ConstructAPI,
    public sharedService: SharedService, public enums: Enums) { }

  ngOnInit(): void {

  }


  confirmBeforeAction() {

    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Are you confirm to authorize loan TICKET #" + this.request.ID + "?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something

      if (confirmation == true) {

        this.authorize();
      }
    });


  }


  authorize() {
    var xhr = this.restApi.putRequest(this.constructApi.getAuthorizeLoanApprovalRequest(this.request.ID));
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        window.location.reload();
      }
    }
  }


  reset() {
    this.activeModal.close();
  }
}
