<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">{{task.CustomerName}} on {{task.Date | date:'dd-MM-yyyy'}}</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()" aria-label="Close"></button>
    </div>

    <div class="modal-body">
        <div class="container-fluid p-0">
            <div class="card shadow-lg m-2" *ngIf="epfDetails!=undefined">
                <div class="card-body">
                    <table class="table table-borderless">
                        <tbody>
                            <tr>
                                <th>EPF Date:</th>
                                <td>{{epfDetails.Date | date:'dd-MM-yyyy'}}</td>
                            </tr>
                            <tr>
                                <th>EPF Amount:</th>
                                <td>RM {{epfDetails.Amount}}</td>
                            </tr>
                            <tr>
                                <th>Has i-Account :</th>
                                <td><span *ngIf="epfDetails.HasIAccount==true">YES</span><span
                                    *ngIf="epfDetails.HasIAccount==false">NO</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p>Customer says:</p>
                    <textarea class="form-control" rows="5" required [(ngModel)]="task.FollowUpRemark"
                        name="FollowUpRemark"></textarea>
                </div>
                <div class="col-12">
                    <p>Continue Follow On:</p>
                    <mat-form-field class="full-width-formField p-0">
                        <input matInput readonly [matDatepicker]="datePicker" name="date" [(ngModel)]="newDate" required>
                        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                        <mat-datepicker #datePicker name="date"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <p>Customer promise:</p>
                    <textarea class="form-control" rows="2" required [(ngModel)]="newDescription"
                        name="newDescription"></textarea>
                </div>

            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="submit()">Follow Up</button>
        <button class="btn btn-outline-danger" type="button" data-dismiss="modal"
            (click)="reset()">Cancel</button>
    </div>
</form>