import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Thickness } from 'igniteui-angular-core';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { AlertDialogComponent } from 'src/app/Shared/alert-dialog/alert-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Account } from 'src/model/account.model';
import { Commission } from 'src/model/commission.model';

interface UnpaidStaffCommissionInterface {
  StaffUsername: string;
  UnpaidComissionList: Commission[];
}

@Component({
  selector: 'app-add-commission-payment',
  templateUrl: './add-commission-payment.component.html',
  styleUrls: ['./add-commission-payment.component.css']
})
export class AddCommissionPaymentComponent implements OnInit {
  companyBankAccountList: Account[] = new Array;
  cashAccountList: Account[] = new Array;

  selectedAccountId: number;
  @Input() selectedUsername: string;
  selectedCommissionAmount: number = 0;
  @Input() unpaidCommission: UnpaidStaffCommissionInterface;
  @Input() selectedBasicAmount: number;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  //2-WAY BINDING INFO
  paymentDate: Date = new Date();

  disabledSubmit = false;
  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private restapi: RestApiService, private constructApi: ConstructAPI,
    public sharedService: SharedService, private enums: Enums) { }

  ngOnInit(): void {
    var loggedInCompanyId = Number(sessionStorage.getItem('companyId'));
    this.getAllAccountsOfCompany(loggedInCompanyId);
    this.calculateTotalAmount();
  }

  private getAllAccountsOfCompany(companyId: number) {

    // var xhttp = new XMLHttpRequest();
    // var url = "http://localhost:10080/api/v1/company/"+companyId+"/accounts";
    // xhttp.withCredentials = true;
    var loggedInUsername = sessionStorage.getItem("username");
    var xhttp = this.restapi.getRequest(this.constructApi.getAccountsOfCompany(companyId));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let account = new Account(json.id, json.number, json.name, json.holderName, json.balance, json.companyId, json.username);

          if (account.Username == undefined)
            this.companyBankAccountList.push(account);
          else if (account.Username == loggedInUsername)
            this.cashAccountList.push(account);

        }
        );

      }
    };

  }

  setSelectedAccount(accountId: number) {

    if (accountId === this.selectedAccountId)
      return true
    else
      return false;
  }
  calculateTotalAmount() {
    for (let i = 0; i < this.unpaidCommission.UnpaidComissionList.length; i++) {
      this.selectedCommissionAmount += this.unpaidCommission.UnpaidComissionList[i].PaymentAmount;
    }
  }


  confirmBeforePayCommission() {
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Are you sure you want to pay these commissions to " + this.unpaidCommission.StaffUsername + "?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something

      if (confirmation == true) {
        this.addPaymentForCommission();
      }
    });

  }

  addPaymentForCommission() {
    this.disabledSubmit = true;

    var isoTaskDate = (new Date(Number(this.paymentDate))).toISOString();
    var commissionList: JSON[] = new Array;
    for (let i = 0; i < this.unpaidCommission.UnpaidComissionList.length; i++) {
      if (this.unpaidCommission.UnpaidComissionList[i].PaymentAmount > 0) {
        var commission = { "id": this.unpaidCommission.UnpaidComissionList[i].ID, "amount": this.unpaidCommission.UnpaidComissionList[i].PaymentAmount };
        commissionList.push(JSON.parse(JSON.stringify(commission)));
      }
    }

    var data = {
      "accountId": Number(this.selectedAccountId),
      "date": isoTaskDate,
      "commissions": commissionList
    };

    var xhr = this.restapi.postRequest(this.constructApi.getAddCommissionPayout(), data);
    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        this.disabledSubmit = false;

        if (xhr.status == 200) {
          // var json = JSON.parse(xhr.responseText);

          this.passEntry.emit()
          this.reset();
          this.sharedService.openAlert("Successfully added payment for " + this.unpaidCommission.StaffUsername + "'s commission!", this.enums.SUCCESS_ALERT);

          if (this.selectedBasicAmount != undefined)
            this.addSalaryPayOutExpense();

        }
        else
          this.sharedService.openErrorMessage(xhr);
      }

    }
  }

  addSalaryPayOutExpense() {
    var isoTaskDate = (new Date(Number(this.paymentDate))).toISOString();

    var data = {
      "amount": this.selectedBasicAmount,
      "accountId": Number(this.selectedAccountId),
      "date": isoTaskDate,
      "remark": "SALARY - " + this.selectedUsername
    };


    var xhr = this.restapi.postRequest(this.constructApi.getAddCompanyExpense(), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        // var json = JSON.parse(xhr.responseText);

        let account = new Account(this.selectedAccountId, null, null, null, null, null, null, -this.selectedBasicAmount)
        this.passEntry.emit(account)
        this.reset();
        this.sharedService.openAlert("Successfully added salary expense!", this.enums.SUCCESS_ALERT);

      }
    }
  }

  isBalanceInsufficient(balance: number) {

    var total = (this.selectedBasicAmount == undefined) ? this.selectedCommissionAmount : this.selectedBasicAmount + this.selectedCommissionAmount;
    if (balance >= total)
      return false;
    else
      return true;
  }

  submit() {
    if (this.selectedAccountId == undefined)
      this.sharedService.openAlert('Please select account!', this.enums.DANGER_ALERT);
    else
      this.confirmBeforePayCommission();
  }

  reset() {
    this.activeModal.close();
  }


}
