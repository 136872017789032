import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { AlertDialogComponent } from 'src/app/Shared/alert-dialog/alert-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Loan } from 'src/model/loan.model';

interface LoanCommissionUser {
  companyCode: string,
  usernameList: string[]
}

@Component({
  selector: 'app-add-commission',
  templateUrl: './add-commission.component.html',
  styleUrls: ['./add-commission.component.css']
})


export class AddCommissionComponent implements OnInit {




  amount: number;
  assignee: string;
  @Input() loan: Loan;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  // usernameList: string[] = new Array;

  loanCommissionUserList: LoanCommissionUser[] = new Array;
  disabledSubmit = false;

  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private restapi: RestApiService, private constructAPI: ConstructAPI,
    public sharedService: SharedService, private enums: Enums) { }

  ngOnInit(): void {
    this.getAllUser();
  }

  getAllUser() {
    var xhttp = this.restapi.getRequest(this.constructAPI.getLoanCommissionUsers(this.loan.ID))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var json = JSON.parse((xhttp.responseText))
        var userArray = json.users;
        userArray.forEach(json => {
          let user = json.username;


          var index = this.loanCommissionUserList.findIndex(commissionUser => commissionUser.companyCode == json.companyCode)

          if (index != -1)
            this.loanCommissionUserList[index].usernameList.push(user);
          else {
            var loanCommissionUser = { companyCode: json.companyCode, usernameList: [json.username] };
            this.loanCommissionUserList.push(loanCommissionUser);
          }

          // this.usernameList.push(user);
        }
        );
      }
    };
  }

  checkNull() {
    return (this.amount == undefined) || (this.assignee == undefined) || isNaN(+this.amount)
  }

  submit() {
    if (this.checkNull() == false) {
      this.assignCommission();
    }
    else
      this.sharedService.openAlert("Please fill in amount and assignee!", this.enums.DANGER_ALERT)
  }

  assignCommission() {
    this.disabledSubmit = true;

    var data = {
      "amount": this.amount,
      "loanId": this.loan.ID,
      "assignee": this.assignee,
      "dateCreated": new Date().toISOString()
    };


    var xhr = this.restapi.postRequest(this.constructAPI.getAssignCommission(), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;

        if (xhr.status == 200) {
          this.passEntry.emit();
          this.reset();
          this.sharedService.openAlert("Successfully added commission!", this.enums.SUCCESS_ALERT);

        }
        else {
          this.sharedService.openErrorMessage(xhr);
        }
      }

    }
  }

  reset() {
    this.activeModal.close();
  }
}
