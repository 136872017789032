<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Check In History ({{selectedCompanyCode}} - {{enums.roleString[selectedRole-1]}})</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 aria-label="Close" aria-label="Close"
            (click)="closeModal()"></button>
    </div>

    <div class="modal-body">

        <div class="container-fluid" style="padding: 2%;">
            <ul class="nav nav-tabs">
                <li class="nav-item ">
                    <a class="nav-link active" role="tab"  data-bs-toggle="tab" href="#tabHistory">
                        <p> <i class="fas fa-history"></i> History</p>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link " role="tab"  data-bs-toggle="tab" href="#tabDeletedHistory">
                        <p> <i class="fas fa-folder-minus"></i> All Deleted CheckIns</p>
                    </a>
                </li>
            </ul>
            <hr />


            <div class="tab-content">

                <!--tabHistory-->
                <div class="tab-pane active" role="tabpanel" id="tabHistory">
                    <ng-container *ngIf="checkInList== undefined || checkInList.length==0;else allHistory">
                        <p>There's no check in history record for this company.</p>
                    </ng-container>

                    <ng-template #allHistory>

                        <table class="table table-bordered">                            
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Date</th>
                                    <th>Remark</th>
                                    <th>Username</th>
                                    <th>Checked On</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let checkIn of checkInList;let i =index">
                                    <td><button class="btn btn-warning"
                                            (click)="confirmBeforeDeleteCheckIn(checkIn.companyId,checkIn.id,i)"><i
                                                class="fas fa-trash-alt"></i></button>
                                        <button class="btn btn-danger ms-1"
                                            (click)="confirmDeleteSinceDateCheckIn(checkIn.companyId,checkIn.date)"><i
                                                class="fas fa-calendar-times"></i></button>
                                    </td>
                                    <td class="reportCol" (click)="goToListHutang(checkIn.date)"> {{checkIn.date |
                                        date:'dd-MM-yyyy'}}</td>
                                    <td>{{checkIn.remark}}</td>
                                    <td>{{checkIn.username}}</td>
                                    <td>{{checkIn.dateCreated| date:'dd-MM-yyyy'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-template>
                </div>

                <!--tabDeletedHistory-->
                <div class="tab-pane" role="tabpanel" id="tabDeletedHistory">

                    <ng-container
                        *ngIf="deletedCheckInList== undefined || deletedCheckInList.length==0;else allDeletedHistory">
                        <p>There's no deleted check in history record for this company.</p>
                    </ng-container>

                    <ng-template #allDeletedHistory>
                        <div class="row">
                            <div class="col d-flex justify-content-end align-items-right">
                                <button class="btn btn-info" (click)="isCollapsed = !isCollapsed"
                                    [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">Show
                                    Details</button>
                            </div>
                        </div>
                        <ng-container *ngFor="let deleted of deletedCheckInList">
                            <div class="card my-2 shadow">
                                <div class="card-body">
                                    <ng-container>
                                        <div class="row">
                                            <div class="col">
                                                <p class="fw-bold text-danger"> DELETED ON {{deleted.deletedOn
                                                    |date:'yyyy-MM-dd'}} </p>
                                            </div>

                                        </div>

                                        <div id="collapseExample" [ngbCollapse]="isCollapsed">
                                            <div class="card mt-2" style="border:1px purple solid">
                                                <div class="card-body">
                                                    <table class="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>CheckIn Date</th>
                                                                <th>Checked In By</th>
                                                                <th>Delete Reason</th>
                                                                <th>Deleted On</th>
                                                                <th>Deleted By</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr *ngFor="let checkIn of deleted.deletedList">
                                                                <td> {{checkIn.date |
                                                                    date:'dd-MM-yyyy'}}</td>
                                                                <td>{{checkIn.checkInCreatedBy}}</td>
                                                                <td>{{checkIn.deleteReason}}</td>
                                                                <td>{{checkIn.deletedOn| date:'dd-MM-yyyy'}}</td>
                                                                <td>{{checkIn.deleteBy}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </ng-template>

                </div>

            </div>


        </div>
    </div>


</form>