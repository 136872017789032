import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { DuitLebih } from 'src/model/duitLebih.model';
import { SettlementCharges } from 'src/model/settlementCharges.model';
import { Loan } from 'src/model/loan.model';
import { LoanScheme } from 'src/model/loanScheme.model';

@Component({
  selector: 'app-add-loan-details',
  templateUrl: './add-loan-details.component.html',
  styleUrls: ['./add-loan-details.component.css']
})
export class AddLoanDetailsComponent implements OnInit, OnChanges {

  today: string;
  selectedOption: number;
  selectedSkim: LoanScheme;

  loanTerm: number = 0;
  interestRate: number = 0;
  processingRate: number = 0;

  loggedInUser: string;
  loggedInCompanyName: string;
  loggedInCompanyId: number;


  loanSchemeList: LoanScheme[] = new Array;
  allLoanSchemeList: LoanScheme[] = new Array;

  loanAmount: number = 0;
  receiptNo: string;
  loanDate: Date = new Date();
  stampAmount: number = 0;
  extras: number = 0;
  serviceCharge: number = 0;
  interestCharge: number = 0;

  monthlyAmount: number = 0;
  reserve: number = 0;


  flexiMonthlyAmount: number = 0;

  loanCode: string;
  duitLebihList: DuitLebih[] = new Array;

  epfSettlementAmount = 0;
  settlementStamp: number;
  settlementServiceCharge: number;
  settlementExtras: number;

  handlingCharge: number;

  extraChargesCollected = 0;
  settlementCharges: SettlementCharges;
  totalDeductionCharge = 0; // if EPF EXTRA, user cannot key in CHAGES (STAMP SC DL) themselves, system will distribute,they only can key in total

  @Input() availableReserve: number = 0;
  @Input() isEPF: boolean = false;

  @Input() epfDate: Date;
  @Input() epfAmount = 0;

  @Input() isPayWithSimpan = false;
  @Input() batchDate: Date;
  @Input() isEPFExtra = false;
  @Input() previousPrincipal = 0;
  @Output() getLoanData: EventEmitter<any> = new EventEmitter();

  @Input() isManualKeyIn = false;


  adsDuitLebih: number;
  cashOnHand: number;

  constructor(private restApi: RestApiService, private constructApi: ConstructAPI, public enums: Enums, public sharedService: SharedService) { }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isEPF) {
      if (this.allLoanSchemeList.length == 0) {
        this.getAllLoanSchemes();
      }
      else
        this.changeEPFScheme(this.isEPF);




    }

    if (changes.epfDate && this.isEPF == true) {

      //difference in month, IGNORE DATE
      // var months = (new Date(this.epfDate).getFullYear() - new Date().getFullYear()) * 12;
      // months -= new Date().getMonth();
      // months += new Date(this.epfDate).getMonth();

      // this.loanTerm = (months <= 0) ? 0 : months;

      //difference in month, a month = 30days
      // var difference_time = new Date(this.epfDate).getTime() - new Date().getTime();
      // var difference_month = (difference_time / (1000 * 3600 * 24)) / 30;


      //loan Term need to deduct 1 month
      // this.loanTerm = (Math.round(difference_month) == 0) ? 1 : Math.round(difference_month);

      this.loanTerm = this.sharedService.getEPFTerm(new Date(this.epfDate));

      if (this.isEPF == true) {
        this.stampAmount = this.enums.MINIMUM_STAMP;
        this.serviceCharge = this.enums.MINIMUM_SC;
      }
      else {
        this.stampAmount = 0;
        this.serviceCharge = 0;
      }
    }

    if (changes.batchDate) {
      if (this.batchDate != undefined)
        this.loanDate = this.batchDate;
      else
        this.loanDate = new Date();
    }

    if (changes.isManualKeyIn) {
      this.extraChargesCollected = undefined;
      this.settlementExtras = undefined;
      this.settlementServiceCharge = undefined;
      this.settlementStamp = undefined;
      this.settlementCharges = undefined
      this.outputToParent();
    }

  }


  changeEPFScheme(isEPF: boolean) {
    if (this.isEPF == true) {
      this.loanSchemeList = [];
      this.loanSchemeList = Array.from(this.allLoanSchemeList);
      this.selectedSkim = this.loanSchemeList[this.loanSchemeList.findIndex(skim => skim.Name == "EPF")];
      this.selectedOption = this.selectedSkim.ID;

    } else {
      var epfIndex = this.loanSchemeList.findIndex(skim => skim.Name == "EPF");
      if (epfIndex != -1)
        this.loanSchemeList.splice(epfIndex, 1);
    }
  }

  ngOnInit(): void {

    this.loggedInCompanyName = sessionStorage.getItem('companyName');
    this.loggedInCompanyId = Number(sessionStorage.getItem('companyId'));
    this.loggedInUser = sessionStorage.getItem('username');

  }


  calculateMonthlyAmount(interest: number, principal: number, term: number) {
    if (this.selectedSkim !== undefined && this.selectedSkim.Name == 'A') {
      this.monthlyAmount = this.roundTo2Dec(this.interestRate / 100 * principal);
    }
    else {
      this.monthlyAmount = this.roundTo2Dec(this.interestRate / 100 * principal + (principal / term));
    }

    return this.monthlyAmount;
  }


  roundTo2Dec(num: number) {
    return Math.round(num * 100) / 100
  }


  outputToParent() {
    var loanDateFormat = new Date(this.loanDate);
    var interestRate = (this.selectedSkim !== undefined && this.selectedSkim.Name != 'P' && this.selectedSkim.Name != 'EPF') ? this.selectedSkim.Interest : this.interestRate;
    var term = (this.selectedSkim !== undefined && this.selectedSkim.Name != 'P' && this.selectedSkim.Name != 'EPF') ? this.selectedSkim.Term : this.loanTerm;
    var schemeName = (this.selectedSkim == undefined) ? undefined : this.selectedSkim.Name;

    if (this.isPayWithSimpan)
      this.reserve = this.interestCharge + this.stampAmount + this.serviceCharge + this.calculateTotalDuitLebih();

    //add handling charge into settlement charges object
    if (this.handlingCharge != undefined && this.settlementCharges != undefined)
      this.settlementCharges.HandlingCharge = this.handlingCharge;

    var loan = new Loan(undefined, undefined, this.loanAmount, this.stampAmount, this.receiptNo, undefined, loanDateFormat, undefined, undefined,
      schemeName, interestRate,
      term, undefined, undefined, this.interestCharge, undefined, this.loanCode, undefined, undefined, undefined, this.reserve,
      undefined, undefined, undefined, this.serviceCharge, this.duitLebihList, undefined, undefined, undefined, this.processingRate, undefined, undefined, true,
      this.settlementStamp, this.settlementServiceCharge, this.settlementExtras, undefined, undefined, undefined, undefined, undefined, undefined, this.settlementCharges);
    this.getLoanData.emit(loan);
  }

  getDuitLebihData(duitLebihList: DuitLebih[]) {
    this.duitLebihList = duitLebihList;
    this.outputToParent();
  }

  calculateStampAndServiceCharge() {

    if (this.loanAmount > 2000) {
      this.stampAmount = 20;
      this.serviceCharge = 6
    }
    else if (this.loanAmount > 500) {
      this.stampAmount = 10;
      this.serviceCharge = 3;
    } else if (this.loanAmount >= 50) {
      this.stampAmount = 3;
      this.serviceCharge = 5;
    }
    else {
      this.stampAmount = 0;
      this.serviceCharge = 0;
    }

  }

  calculateTotalDuitLebih() {
    var total = 0;
    if (this.duitLebihList.length > 0) {
      for (let i = 0; i < this.duitLebihList.length; i++) {
        total += this.duitLebihList[i].Amount;
      }
    }

    return total;
  }

  getInterestCharge() {
    //if Skim A & HUTANG dont default interest charge
    //else fix interest charge to 10%

    if (this.selectedSkim != undefined) {
      if (this.selectedSkim.Name == 'A' || this.selectedSkim.Name == 'HUTANG') {
        this.interestCharge = 0;
        this.processingRate = 0;
      }

      else {
        this.interestCharge = (this.loanAmount * (this.enums.FIXED_SCHEME_PROCESSING_RATE/100));
        this.processingRate = this.enums.FIXED_SCHEME_PROCESSING_RATE;

        if (this.isEPF == true)
          this.calculateEPFMonthly();

      }

    }

    this.outputToParent();
  }

  calculateInterestCharge(interest: number) {
    this.interestCharge = this.loanAmount * (interest / 100)
    this.processingRate = interest;
    this.outputToParent();
  }

  findSchemeInfo(selectedOption: number) {

    this.loanSchemeList.forEach(loanScheme => {
      if (loanScheme.ID == selectedOption) {
        this.selectedSkim = loanScheme;

        if (this.isNotSkimP() == true)
          this.loanTerm = loanScheme.Term;
        else
          this.loanTerm = 0;
        this.interestRate = loanScheme.Interest;
      }
    });


    this.getInterestCharge();
  }

  isNotSkimP() {
    if (this.selectedSkim != undefined && this.selectedSkim.Name != 'P')
      return true;
    else return false;
  }


  calculateEPFMonthly() {
    this.flexiMonthlyAmount = (this.epfSettlementAmount) / this.loanTerm;
    this.calculateInterestRate(this.flexiMonthlyAmount, this.loanAmount, this.loanTerm);

    //if it's an extra loan, add handling charge
    if (this.isEPFExtra && this.isManualKeyIn == false)
      this.handlingCharge = Math.floor(this.loanAmount * this.enums.MININUM_HANDLING_RATE * this.loanTerm);


    if (this.isManualKeyIn == false)
      this.getSettlementCharges();
    else
      this.manualSetSettlementCharges();
    this.outputToParent();
  }

  calculateInterestRate(monthly: number, principal: number, term: number) {

    var total = monthly * term;

    this.interestRate = (((total - principal) / principal) / term) * 100;
    // this.outputToParent();

    return this.interestRate;


  }

  calculateEPFSettlementIncludeCharges() {

    if (this.isManualKeyIn == false)
      return this.epfSettlementAmount + this.extraChargesCollected + (this.handlingCharge || 0);
    else
      return this.epfSettlementAmount + this.settlementExtras + this.settlementServiceCharge + this.settlementStamp + (this.handlingCharge || 0);
  }

  getAllLoanSchemes() {
    this.loanSchemeList = [];
    var xhttp = this.restApi.getRequest(this.constructApi.getAllLoanSchemes());
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {

          let loanScheme = new LoanScheme(json.id, json.name, json.term, json.interest);

          //make EPF and HUTANG scheme out of selection list
          if (json.name != "EPF" && json.name != "HUTANG")
            this.loanSchemeList.push(loanScheme);


          this.allLoanSchemeList.push(loanScheme);
          this.loanSchemeList.sort((a, b) => (a.Name > b.Name) ? 1 : -1);
        }
        );

        this.changeEPFScheme(this.isEPF);
      }

    };
  }



  getPositiveNegative() {
    if (this.isEPFExtra == false) {
      if (this.roundTo2Dec(this.interestRate) >= (this.enums.MINIMUM_NEW_EPF_RATE * 100))
        return 'lightseagreen'
      else if (this.roundTo2Dec(this.interestRate) > 0 && this.roundTo2Dec(this.interestRate) < (this.enums.MINIMUM_NEW_EPF_RATE * 100))
        return 'orange'
      else
        return 'red';
    }

    //if is EPF EXTRA
    {
      if (this.roundTo2Dec(this.interestRate) >= (this.enums.MINIMUM_EXTRA_EPF_RATE * 100))
        return 'lightseagreen'
      else
        return 'red';
    }
  }

  calculateMinimumSettlementStamp() {
    return this.loanTerm * this.enums.MINIMUM_SETTLEMENT_STAMP_PERMONTH;
  }

  calculateMinimumSettlementSC() {
    return this.loanTerm * this.enums.MINIMUM_SETTLEMENT_SC_PERMONTH;
  }


  checkIfIsEPFFirstLoan() {
    if (this.isEPF == true && this.isEPFExtra == false && this.isManualKeyIn == false)
      return true;
    else
      return false;
  }

  checkIfIsEPFDefaultLoan() {
    if (this.isEPF == true && this.isManualKeyIn == false)
      return true;
    else
      return false;
  }

  fillInAllDefaultForEPFFirstLoan() {
    this.loanAmount = this.sharedService.calculateRoundedPrincipalByOnHand(this.cashOnHand);
    this.adsDuitLebih = this.loanAmount * this.enums.MINIMUM_ADS_RATE;
    this.duitLebihList = [new DuitLebih(this.sharedService.calculateRoundedDLByOnHand(this.cashOnHand)), new DuitLebih(this.adsDuitLebih, this.enums.ADS_SUBTYPE_ID)];
    this.extraChargesCollected = this.roundTo2Dec(this.enums.MIN_SSC_PER_TERM * this.loanTerm)
    this.getInterestCharge();
  }





  getSettlementCharges() {
    this.settlementCharges = undefined;

    //make sure charges collected is more than minimum total ssc and charges over term  less than principal then proceed calculate
    if (this.extraChargesCollected >= (this.enums.MIN_SSC_PER_TERM * this.loanTerm) && (this.extraChargesCollected / this.loanTerm) <= this.loanAmount) {


      this.settlementCharges = this.sharedService.calculateAndReturnSettlementCharges(this.loanAmount, this.extraChargesCollected, this.loanTerm);

      this.outputToParent();
    }
  }

  manualSetSettlementCharges() {
    this.settlementCharges = undefined;
    if (this.settlementServiceCharge != undefined && this.settlementServiceCharge != undefined && this.settlementExtras != undefined)
      this.settlementCharges = new SettlementCharges(this.settlementServiceCharge, this.settlementStamp, this.settlementExtras, this.handlingCharge);
    this.outputToParent();
  }




}
