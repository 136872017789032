import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Company } from 'src/model/company.model';

@Component({
  selector: 'app-search-company-by-uid',
  templateUrl: './search-company-by-uid.component.html',
  styleUrls: ['./search-company-by-uid.component.css']
})
export class SearchCompanyByUidComponent implements OnInit {

  uid: string;
  company: Company;
  disabledSubmit = false;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();


  constructor(public sharedService: SharedService, public enums: Enums, private restApi: RestApiService, private constructApi: ConstructAPI) { }

  ngOnInit(): void {
  }

  checkNull(uid: string) {
    if (uid == undefined)
      this.sharedService.openAlert("Please fill in UID!", this.enums.DANGER_ALERT);
    else
      this.searchCustomerByUid(uid);
  }


  searchCustomerByUid(uid: string) {
    var xhttp = this.restApi.getRequest(this.constructApi.getCompanyByUID(uid));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4) {

        if (xhttp.status == 200) {
          var json = JSON.parse((xhttp.responseText));

          this.company = new Company(json.id, json.name, undefined, json.companyCode, undefined, undefined, undefined, undefined, undefined, undefined, json.uid);
          this.passEntry.emit(this.company);
        }
        else {
          this.sharedService.openErrorMessage(xhttp);
        }

      }
    };
  }

}
