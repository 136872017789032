import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { UploadImageComponent } from 'src/app/Images/upload-image/upload-image.component';
import { ViewThumbnailsComponent } from 'src/app/Images/view-thumbnails/view-thumbnails.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Account } from 'src/model/account.model';
import { ReserveGroup, rgDistribution } from 'src/model/reserveGroup.model';

@Component({
  selector: 'app-view-reserve-groups',
  templateUrl: './view-reserve-groups.component.html',
  styleUrls: ['./view-reserve-groups.component.css']
})
export class ViewReserveGroupsComponent implements OnInit {

  @Input() reserveGroupId: number


  reserveGroup: ReserveGroup;
  remark: string;

  simpanDate: Date;
  loggedInUserAccepted: boolean = false;

  companyBankAccountList: Account[] = new Array;
  cashAccountList: Account[] = new Array;
  selectedAccountId: number;

  constructor(private activeModal: NgbActiveModal, private restApi: RestApiService, private constructApi: ConstructAPI, private sharedService: SharedService, private enums: Enums) { }

  ngOnInit(): void {

    this.getReserveGroupDetails();
    var companyId = Number(sessionStorage.getItem("companyId"));

    if (this.sharedService.isOperatingUser())
      this.getAllAccountsOfCompany(companyId);
  }



  getReserveGroupDetails() {
    var xhttp = this.restApi.getRequest(this.constructApi.getReserveGroupDetails(this.reserveGroupId));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {


        var json = JSON.parse((xhttp.responseText));
        var distributionArr = json.distribution;

        var distributionList: rgDistribution[] = new Array;
        distributionArr.forEach(da => {

          var distribution = new rgDistribution(da.id, da.companyId, da.amount, da.companyCode, da.acceptedOn, da.transactionDate);
          distributionList.push(distribution);

          //check if loggedIn User accepted
          var loggedInCompanyId = Number(sessionStorage.getItem("companyId"));
          if (distribution.CompanyId == loggedInCompanyId && distribution.AcceptedOn != undefined)
            this.loggedInUserAccepted = true;

        });
        this.reserveGroup = new ReserveGroup(distributionList, json.id, json.requestedBy, json.requestedOn, json.sharedProfileId, undefined, undefined, json.valid)

      }
    };


  }

  getAllAccountsOfCompany(companyId: number) {
    var loggedInUsername = sessionStorage.getItem("username");
    var xhttp = this.restApi.getRequest(this.constructApi.getAccountsOfCompany(companyId));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let account = new Account(json.id, json.number, json.name, json.holderName, json.balance, json.companyId, json.username);

          if (account.Username == undefined)
            this.companyBankAccountList.push(account);
          else {
            if (this.sharedService.isStaff()) {
              if (account.Username == loggedInUsername)
                this.cashAccountList.push(account);
            }
            else {
              this.cashAccountList.push(account);
            }

          }


        }
        );

      }
    };

  }

  viewReserveGroupImages(reserveGroupId: number) {
    var modalRef = this.sharedService.openModal(ViewThumbnailsComponent, "largeModal");
    modalRef.componentInstance.reserveGroupID = reserveGroupId;
  }


  openUploadReserveGroupDocModal(reserveGroupId: number) {
    const modalRef = this.sharedService.openModal(UploadImageComponent);

    modalRef.componentInstance.isReserveGroupDocument = true;
    modalRef.componentInstance.reserveGroupId = reserveGroupId;

    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var reserveGroupId = Number(entry);



    });


  }

  acceptReserveGroup() {
    if (this.simpanDate == undefined || this.selectedAccountId == undefined) {
      this.sharedService.openAlert("Please select date and account!", this.enums.DANGER_ALERT)
    }
    else {
      var isoDate = (new Date(Number(this.simpanDate))).toISOString();

      var data = {
        "remark": "ACCEPTED FROM MAIN",
        "accountId": Number(this.selectedAccountId),
        "dateCreated": isoDate
      }


      var xhr = this.restApi.postRequest(this.constructApi.getAcceptReserveGroup(this.reserveGroupId), data);
      xhr.onreadystatechange = () => {

        if (xhr.readyState == 4) {
          if (xhr.status == 200) {
            this.sharedService.openAlert("Successfully added simpan distribution!", this.enums.SUCCESS_ALERT);
            this.activeModal.close();
          }
          else
            this.sharedService.openErrorMessage(xhr);
        }


      }
    }

  }

  submitReserveGroup() {
    var xhr = this.restApi.putRequest(
      this.constructApi.getSubmitReserveGroup(this.reserveGroup.ID)
    );
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        if (xhr.status == 200) {
          this.sharedService.openAlert("Reserve Group submittied", this.enums.SUCCESS_ALERT);
          this.activeModal.close();
        }
        else this.sharedService.openErrorMessage(xhr);
      }
    };
  }

  reset() {
    this.activeModal.close();
  }
}
