<form>
    <!-- Modal Header -->
    <div class="modal-header" style="background-color:lightskyblue;">
        <h4 class="modal-title">Add Commission</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body">

        <div class="card shadow mb-3">
            <div class="card-body">
                <table class="table-borderless">
                    <tbody>
                        <tr *ngIf="loan.CustomerName!=undefined">
                            <td><strong>Customer Name</strong></td>
                            <td>:</td>
                            <td class="ps-2">{{loan.CustomerName}}</td>
                        </tr>
                        <tr>
                        <tr>
                            <td><strong>Receipt Number</strong></td>
                            <td>:</td>
                            <td class="ps-2">{{loan.ReceiptNumber}}</td>
                        </tr>
                        <tr>
                            <td><strong>Principal</strong></td>
                            <td>:</td>
                            <td class="ps-2">RM {{loan.Principal}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!--Assignee-->
        <ng-container *ngIf="loanCommissionUserList.length>0">
            <mat-form-field>
                <mat-label>Assignee</mat-label>
                <select matNativeControl [(ngModel)]="assignee" name="assignee">
                    <ng-container *ngFor="let companyUsers of loanCommissionUserList">
                        <optgroup [label]="companyUsers.companyCode">
                            <option [value]="user" *ngFor="let user of companyUsers.usernameList">
                                {{user}}
                            </option>
                        </optgroup>
                    </ng-container>



                </select>
            </mat-form-field>
        </ng-container>


        <!--Amount-->
        <div class="group  mt-2">
            <input type="number" required class="textInputLong" name="amount" [(ngModel)]="amount" min=0
                (keypress)="sharedService.isNumberKey($event)">
            <span class="highlight"></span>
            <span class="bar barLong"></span>
            <label>Amount</label>
        </div>
        <span class="text-danger"
            [ngClass]="{'text-danger':sharedService.checkCommissionOverpay(loan,amount)==true , 'text-info': sharedService.checkCommissionOverpay(loan,amount)==false   }"
            [hidden]="amount==undefined"><strong>{{(amount/loan.Principal)*100 |number :'1.2-2'}}%
                of
                principal</strong></span>



    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="submit()" [disabled]="disabledSubmit"
            *ngIf="sharedService.checkCommissionOverpay(loan,amount)==false">Assign
            Commission</button>
        <button class="btn btn-outline-danger" type="button" (click)="reset()">Cancel</button>
    </div>

</form>