import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { filter } from 'jszip';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Customer } from 'src/model/customer.model';
import { Profile } from 'src/model/profile.model';

@Component({
  selector: 'app-customer-lead-list',
  templateUrl: './customer-lead-list.component.html',
  styleUrls: ['./customer-lead-list.component.css']
})
export class CustomerLeadListComponent implements OnInit {
  leadProfileList: Customer[] = new Array;
  displayedColumns: string[];
  dataSource = new MatTableDataSource;

  innerWidth: any;
  showRemark: string;
  selectedStatus: number;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  routeSubscription: any;
  selectedCompany: number;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private restapi: RestApiService, private constructApi: ConstructAPI, public enums: Enums, private sharedService: SharedService) { }

  ngOnInit(): void {
    this.routeSubscription = this.activatedRoute.paramMap.subscribe(params => {
      this.selectedCompany = (params.get('companyId') == undefined) ? undefined : +params.get('companyId');
    });

    // get customer
    this.getLeadsProfile();

    this.innerWidth = window.innerWidth;
    if (innerWidth <= 450)
      this.displayedColumns = ['DateCreated', 'Name', 'ICNumber', 'Occupation'];
    else
      this.displayedColumns = ['DateCreated', 'Name', 'ICNumber', 'Occupation', 'SalaryDay', 'Salary'];
  }

  //detect screen size
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = event.innerWidth;

    if (innerWidth <= 450)
      this.displayedColumns = ['DateCreated', 'Name', 'ICNumber', 'Occupation'];
    else
      this.displayedColumns = ['DateCreated', 'Name', 'ICNumber', 'Occupation', 'SalaryDay', 'Salary'];
  }

  goToCustomerProfile(selectedCustomerID: number, selectedProfileId: number) {
    this.router.navigate(['/manageCustomer', selectedCustomerID, selectedProfileId]);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;

    var processFilter = filterValue.replace(/-/g, '');
    this.dataSource.filter = processFilter;
  }


  getLeadsProfile() {
    if (this.selectedCompany == undefined)
      var xhttp = this.restapi.getRequest(this.constructApi.getAllLeadsProfiles())
    else
      var xhttp = this.restapi.getRequest(this.constructApi.getCompanyLeadsProfiles(this.selectedCompany))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // Typical action to be performed when the document is ready:

        var jsonArray = JSON.parse((xhttp.responseText));
        // console.log(jsonArray);

        jsonArray.forEach(json => {
          let leadProfile = new Profile(json.id, json.occupation, json.salary, json.salaryDay, json.remark, json.status, json.dateCreated, json.customerId, json.customerCode, json.reserve)
          var profileList: Profile[] = new Array;
          profileList.push(leadProfile);
          let lead = new Customer(json.customerId, json.name, json.occupation, json.salary, json.salaryDay, json.icNumber, json.contactNo, json.Remark, json.ctosNumber, json.ctosStatus, json.status, json.dateCreated, json.handler, json.companyId, json.customerCode, undefined, undefined, undefined, undefined, profileList);
          this.leadProfileList.push(lead);
        }
        );

        //make customer list as the datasource
        this.dataSource = new MatTableDataSource<Customer>(this.leadProfileList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

      }
    };
  }

}
