<!-- <div class=" m-2 d-flex justify-content-end align-content-right">
    <button class="btn btn-dark doNotPrint" (click)="print()"> <i class="fas fa-print"></i> Print</button>
</div> -->



<div class="text-justify agreement"
    style="font-family: 'Times New Roman', Times, serif;line-height:150%;font-size: 11pt;" id="htmlData" #htmlData
    *ngIf="selectedCustomer!=undefined && selectedCompany!=undefined && selectedFormJ!=undefined">
    <div class="m-3">
        <div>
            <p style="font-size:13pt;" class="fw-bold text-center">SCHEDULE J</p>
            <p style="font-size:13pt;" class="fw-bold text-center">MONEY LENDERS ACT 1951</p>
            <p style="font-size:13pt;" class="fw-bold text-center">MONEYLENDERS (CONTROL AND LICENSING) REGULATIONS 2003</p>
            <p class="text-center">[Subregulation 10(1)]</p>
            <p style="font-size:13pt;" class="fw-bold text-center ">MONEYLENDING AGREEMENT (UNSECURED LOAN)</p>
            <br />
        </div>

        <p><strong>AN AGREEMENT</strong> made the day and year stated in section 1 of the First Schedule hereto
            between the moneylender as specified in section 2 of the First Schedule ( “the lender” ) of the one
            part and the borrower as specified in section 3 of the First schedule ( “the borrower” ) of the
            other
            part.
        </p>

        <p><strong>WHEREAS</strong> the Lender is a licensed moneylender under the Moneylender Act 1951 hereby
            agree to lend the borrower and the borrower agree to borrow from the lender for the purpose of this
            agreement a sum of money as specified in section 4 of the First Schedule (“the Principle Sum”)

        </p>
        <p class="ms-5"><strong>NOW IT IS AGREED</strong> as follows:</p>

        <p style="font-size:13pt;"><strong>Installment Repayment</strong></p>
        <p>
            1. <span class="ms-3">The installment repayments in this Agreement shall be due and payable without
                first of which is to be made on the
                <span class="fw-bold" style="width: 200px;"
                    *ngIf="selectedFormJ.StampDate==undefined">_________________________</span>
                <span class="fw-bold" style="text-decoration: underline;" *ngIf="selectedFormJ.StampDate!=undefined">{{
                    calculateFirstPayment(selectedFormJ.StampDate)
                    |date:'dd-MM-yyyy'}}
                </span>
                (date of the first repayment) and thereafter on the
                <span class="fw-bold" style="width: 200px;"
                    *ngIf="selectedFormJ.StampDate==undefined">_____________</span>
                <span class="fw-bold" style="text-decoration: underline;"
                    *ngIf="selectedFormJ.StampDate!=undefined">{{selectedFormJ.StampDate
                    |date:'dd'}}</span> of each and every subsequent month until the expiration of the said
                <span class="fw-bold" style="text-decoration: underline;">{{selectedFormJ.Term}}</span>
                months
                from the date hereof.
            </span>
        </p>
        <br />
        <p style="font-size:13pt;"><strong>Default</strong></p>
        <p>

            2. <span class="ms-3">(1) If default is made in the repayment upon the due date of any sum of
                installment
                payable
                to the Lender under this Agreement, whether in respect of principal or interest, the Lender
                shall be
                entitled to charge simple interest on the unpaid sum of installment which shall be calculated at
                the
                rate
                of eight per centum per annum from day to day from the date of default in repayment of the sum
                of
                installment until sum of installment is paid, and any interest so charged shall not be reckoned
                for
                the
                purpose of this Agreement as part of the interest charged in respect of the loan.
            </span>
        </p>
        <p class="ms-4"> &nbsp;(2) The interest shall be calculated in accordance with the following formula :
        </p>
        <div class="ms-5">
            <p>R = 8/100 x D/365 x S</p>

            <p>Where,</p>
            <p>R <span class="ms-5">represent sum of the interest to be paid</span></p>
            <p>D <span class="ms-5">represent the number of date in default</span></p>
            <p>S <span class="ms-5">represent the sum of monthly installment which is overdue</span></p>
        </div>


        <p style="font-size:13pt;"><strong>Right of Action</strong></p>
        <p>
            3. <span class="ms-3">(1) If the Borrower –</span>
        </p>
        <div class="ms-5">

            <table class="table table-borderless">
                <tr>
                    <td><i>(a)</i></td>
                    <td> fails to repay any sum of installment payable or any part thereof and any interest
                        payable under section 5 of the First Schedule for any period in excess of twenty
                        eight days after its due date; or
                    </td>
                </tr>
                <tr>
                    <td><i>(b)</i></td>
                    <td> commits an act of bankruptcy or enters into any composition or arrangement with his
                        creditors or, being a company, enters into liquidation, whether compulsory or voluntary,
                    </td>
                </tr>
            </table>
        </div>
        <p>the Lender may terminate this Agreement.</p>

        <p class="ms-4">
            &nbsp;(2) Upon the occurrence of any of the events specified in subclause (1) herein, the Lender
            shall
            give the
            Borrower not less than fourteen days notice in writing to treat this Agreement as having been
            repudiated
            by the
            Borrower and unless in the meanwhile such default alleged is rectified or such unpaid sum of
            instalment
            and
            interest are paid, this Agreement shall at the expiry of the said notice, at the option of the
            Lender be
            deemed
            to be annulled.
        </p>
        <p class="ms-4">
            &nbsp; (3) In the event this Agreement has been terminated or annulled, the Lender may claim the
            balance
            outstanding
            from the Borrower in accordance with the provisions under Order 45 of the Subordinate Court Rules
            1990
            [P.U.(A)
            97/1990] in case the balance outstanding does not exceed two hundred and fifty thousand ringgit or
            Order
            79 of
            the Rules of the High Court 1980 [P.U.(A) 50/1980] in case the balance outstanding is higher than
            two
            hundred
            and fifty thousand ringgit.
        </p>

        <p style="font-size:13pt;"><strong>Compliance with written law</strong></p>
        <p>
            4. <span class="ms-3">The Lender shall, in relation to the moneylending business, conform to the
                provisions and
                requirements of the
                Moneylenders Act 1951 and any written law for the time being in force affecting the
                business.</span>
        </p>

        <p style="font-size:13pt;"><strong>Stamp duties and attestation fees</strong></p>
        <p>
            5. <span class="ms-3">All stamp duties and attestation fees incurred in connection with this
                Agreement
                shall be
                borne by the
                Borrower.</span>
        </p>

        <p style="font-size:13pt;"><strong>Service of documents</strong></p>
        <p>
            6. <span class="ms-3">(1) Any notice, request or demand required to be served by either party hereto
                to
                the
                other under this
                Agreement shall be in writing and shall be deemed to be sufficiently served -</span>
        </p>
        <div class="ms-5">

            <table class="table table-borderless">
                <tr>
                    <td><i>(a)</i></td>
                    <td> if it is sent by the party or his solicitors by A.R. registered post addressed to the
                        other
                        party’s
                        address hereinbefore mentioned and in such case it shall be deemed to have been received
                        upon the
                        expiry of
                        a period of five days of posting of such registered letter; or
                    </td>
                </tr>
                <tr>
                    <td><i>(b)</i></td>
                    <td> if it is given by the party or his solicitors by hand to the other party or his
                        solicitors.
                    </td>
                </tr>
            </table>
        </div>

        <p class="ms-4">
            &nbsp;(2) Any change of address by either party shall be communicated to the other.
        </p>

        <p style="font-size:13pt;"><strong>Schedules</strong></p>
        <p>
            7.<span class="ms-3">The Schedule hereto shall form part of this Agreement and shall be read, taken
                and
                construed as an essential
                part of this Agreement.</span>
        </p>
        <br />
        <p style="font-size:13pt;"><strong>Time essence of Agreement</strong></p>
        <p>
            8.<span class="ms-3">Time shall be the essence of the Agreement in relation to all provisions of
                this
                Agreement.</span>
        </p>

        <p style="font-size:13pt;"><strong>Persons to be bound by Agreement</strong></p>
        <p>
            9. <span class="ms-3">This Agreement shall be binding upon the successors in title and permitted
                assigns of the
                Lender, the heirs,
                personal representatives, successors in title and permitted assigns of the Borrower.</span>
        </p>

        <p>
            <strong>IN WITNESS WHEREOF</strong> the parties hereto have hereunto set their hands the day and
            year
            first
            above written.
        </p>


        <table>
            <tr>
                <td colspan="2"><strong>SIGNED</strong> by the abovenamed</td>
                <td> ) *</td>
                <td style="width: 15%;"> </td>
            </tr>
            <tr>
                <td>Borrower </td>
                <td></td>
                <td> )</td>
                <td style="width: 15%;"> </td>
            </tr>
            <tr>
                <td>Name:</td>
                <th class="p-1" style="text-decoration: underline;" style="width: 50%"> {{selectedCustomer.Name
                    |
                    uppercase}} </th>
                <td> )</td>
                <td style="width: 15%;"> </td>
            </tr>
            <tr class="pt-2">
                <td>I.C./Co. Reg. No.:</td>
                <th class="p-1" style="text-decoration: underline;" style="width: 50%">
                    {{sharedService.processICNum(selectedCustomer.ICNumber)}} </th>
                <td> )</td>
                <td style="width: 15%;"> </td>
            </tr>
            <tr style="height:50px!important">
                <td></td>
                <td></td>
                <td style="width: 15%;"> </td>
            </tr>


            <tr>
                <td colspan="2"><strong>SIGNED</strong> by the abovenamed</td>
                <td> )</td>
                <td style="width: 15%;"> </td>
            </tr>
            <tr>
                <td>Lender </td>
                <td></td>
                <td> )</td>
                <td style="width: 15%;"> </td>
            </tr>
            <tr>
                <td>Name:</td>
                <th class="p-1" style="text-decoration: underline;" style="width: 50%"> {{selectedCompany.Name |
                    uppercase}} </th>
                <td> )</td>
                <td style="width: 15%;"> </td>
            </tr>
            <tr class="pt-2">
                <td>I.C./Co. Reg. No.:</td>
                <th class="p-1" style="text-decoration: underline;" style="width: 50%"> {{selectedCompany.RegNum
                    |
                    uppercase}} </th>
                <td> )</td>
                <td style="width: 15%;"> </td>
            </tr>
        </table>



        <br />
        <br />
        <p>I, do solemnly and sincerely declare that I have explained the terms of this Agreement to the
            Borrower
            and it
            appears to me that the Borrower has understood the nature and consequence of this Agreement.</p>
        <br />
        <br />

        <br />
        <br />
        <div class="text-center" style="margin-top: 100px;">
            <p style="margin:0;padding:0">…………………………….</p>
            <p style="margin:0;padding:0">(Name of the attestor)</p>
            <p style="margin:0;padding:0">(Advocate and Solicitor, officer of the Judicial and</p>
            <p style="margin:0;padding:0">Legal Service, Commissioner for Oaths, District Officer,</p>
            <p style="margin:0;padding:0">Justice of the Peace or person appointed by the Minister)</p>
        </div>

        <app-form-j-schedule [selectedCompany]="selectedCompany" [selectedCustomer]="selectedCustomer"
            [selectedFormJ]="selectedFormJ">
        </app-form-j-schedule>
    </div>
    <div>
        <app-payment-voucher [selectedCompany]="selectedCompany" [selectedCustomer]="selectedCustomer"
            [selectedFormJ]="selectedFormJ"></app-payment-voucher>
    </div>
    <div>
        <app-formj-receipt-letter [selectedCompany]="selectedCompany" [selectedCustomer]="selectedCustomer">
        </app-formj-receipt-letter>
    </div>

</div>