import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RestApiService {

    //Define API
    // apiUrl = 'http://localhost:10080/api/v1/';
    // apiUrl = 'http://206.189.87.13:10080/api/v1/';
    // apiUrl = 'http://188.166.224.170:10080/api/v1/';
    // apiUrl = 'https://credsys.al-fikrah.com/api/v1/';

    apiUrl: string;

    constructor() {

        if (environment.production == true || environment.environment == "local")
            this.apiUrl = environment.apiUrl;

        else {
            var protocol = window.location.protocol;
            var hostname = window.location.host;
            hostname = hostname.replace("app.", "api.");

            this.apiUrl = protocol + "//" + hostname + "/api/v1/";
        }

    }

    postRequest(apiAddress: string, jsonData?: any) {
        var xhr = new XMLHttpRequest();

        xhr.open("POST", this.apiUrl + apiAddress, true);
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.withCredentials = true;
        var data = JSON.stringify(jsonData);
        xhr.send(data);
        return xhr
    }

    getRequest(apiAddress: string) {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", this.apiUrl + apiAddress, true);
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.withCredentials = true;
        xhr.send();
        return xhr
    }

    putRequest(apiAddress: string, jsonData?: any) {
        var xhr = new XMLHttpRequest();
        xhr.open("PUT", this.apiUrl + apiAddress, true);
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.withCredentials = true;
        var data = JSON.stringify(jsonData);
        xhr.send(data);
        return xhr
    }

    patchRequest(apiAddress: string, jsonData?: any) {
        var xhr = new XMLHttpRequest();
        xhr.open("PATCH", this.apiUrl + apiAddress, true);
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.withCredentials = true;
        var data = JSON.stringify(jsonData);
        xhr.send(data);
        return xhr
    }

    deleteRequest(apiAddress: string, jsonData?: any) {
        var xhr = new XMLHttpRequest();
        xhr.open("DELETE", this.apiUrl + apiAddress, true);
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.withCredentials = true;

        if (jsonData != undefined) {
            var data = JSON.stringify(jsonData);
            xhr.send(data);
        }
        else
            xhr.send();

        return xhr
    }



    postFormDataRequest(apiAddress: string, formData: FormData) {
        var xhr = new XMLHttpRequest();
        xhr.open("POST", this.apiUrl + apiAddress, true);
        xhr.withCredentials = true;
        xhr.send(formData);
        return xhr;
    }

    postFileRequest(apiAddress: string, file: File) {
        var xhr = new XMLHttpRequest();

        xhr.open("POST", this.apiUrl + apiAddress, true);
        xhr.withCredentials = true;

        let formData = new FormData();

        var processedFileName = file.name.replace("'", "");
        formData.append("file", file, processedFileName);
        xhr.send(formData);
        return xhr
    }

}