import { Component, Input, OnInit } from '@angular/core';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';

interface NecessaryFileExistence {

  type: string,
  existed: boolean
}
@Component({
  selector: 'app-check-customer-necessary-files',
  templateUrl: './check-customer-necessary-files.component.html',
  styleUrls: ['./check-customer-necessary-files.component.css'],

})
export class CheckCustomerNecessaryFilesComponent implements OnInit {

  neccessaryFileList: NecessaryFileExistence[] = new Array;
  optionalFileList: NecessaryFileExistence[] = new Array;
  @Input() customerId: number;
  @Input() companyId: number
  constructor(private enums: Enums, private restApi: RestApiService, private constructApi: ConstructAPI) {


  }

  ngOnInit(): void {
    for (let i = 0; i < this.enums.customerNecessaryDocs.length; i++) {
      this.getFileExistence(this.enums.customerNecessaryDocs[i], true);
    }

    for (let i = 0; i < this.enums.customerOptionalDocs.length; i++) {
      this.getFileExistence(this.enums.customerOptionalDocs[i], false);
    }

  }


  getFileExistence(type: string, isNeccessary?: boolean) {
    var xhttp = this.restApi.getRequest(this.constructApi.getCompanyCustomerTypedFileExistence(this.customerId, type))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var result = JSON.parse(xhttp.responseText);

        var fileExisted = { type: type, existed: Boolean(result) }
        if (isNeccessary == true)
          this.neccessaryFileList.push(fileExisted);
        else
          this.optionalFileList.push(fileExisted);

        this.neccessaryFileList.sort((a, b) => (a.type > b.type) ? -1 : 1);
        this.optionalFileList.sort((a, b) => (a.type > b.type) ? -1 : 1);
      }
    };
  }
}
