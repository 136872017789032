import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { AlertDialogComponent } from 'src/app/Shared/alert-dialog/alert-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Account } from 'src/model/account.model';
import { DuitLebih } from 'src/model/duitLebih.model';
import { Loan } from 'src/model/loan.model';

@Component({
  selector: 'app-add-principal',
  templateUrl: './add-principal.component.html',
  styleUrls: ['./add-principal.component.css']
})
export class AddPrincipalComponent implements OnInit {
  companyAccountList: Account[] = new Array;
  loanList: Loan[] = new Array;


  loggedInCompanyId: number;

  selectedAccountId: number;
  selectedHandler: string;

  //2-WAY BINDING INFO
  addPrincipalDate: Date;
  remark: string;
  amount: number = 0;
  stampAmount: number = 0;
  scAmount: number = 0;
  interestCharge: number = 0;
  interestChargeRate: number = 0;
  maxDate: Date = new Date();
  reserve: number = 0;
  duitLebihList: DuitLebih[] = new Array;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() selectedLoan: Loan;
  @Input() availableReserve: number = 0;

  disabledSubmit = false;

  constructor(private activeModal: NgbActiveModal, private restapi: RestApiService,
    private constructApi: ConstructAPI, private enums: Enums, private modalService: NgbModal, public sharedService: SharedService) { }

  ngOnInit(): void {
    this.addPrincipalDate = new Date();

    //get all datas
    this.loggedInCompanyId = Number(sessionStorage.getItem('companyId'));
    this.getAllAccountsOfCompany(this.loggedInCompanyId);
  }

  calculateOnHandAmount() {
    var total = 0;
    var duitLebihTotal = 0;
    if (this.duitLebihList != undefined && this.duitLebihList.length > 0) {
      for (let i = 0; i < this.duitLebihList.length; i++)
        duitLebihTotal += this.duitLebihList[i].Amount;
    }
    total = this.amount + this.reserve - duitLebihTotal - this.stampAmount - this.scAmount - this.interestCharge;

    return total;
  }
  getDuitLebihData(duitLebihList: DuitLebih[]) {
    this.duitLebihList = duitLebihList;
  }

  calculateStampAndServiceCharge() {
    if (this.amount > 2000) {
      this.stampAmount = 20;
      this.scAmount = 6
    }
    else if (this.amount > 500) {
      this.stampAmount = 10;
      this.scAmount = 3;
    } else if (this.amount >= 50) {
      this.stampAmount = 3;
      this.scAmount = 5;
    }
    else {
      this.stampAmount = 0;
      this.scAmount = 0;
    }

  }
  calculateDeductions() {
    // this.calculateStampAndServiceCharge();

    //interestCharge
    // this.interestCharge = this.amount * (this.selectedLoan.InterestRate / 100)
    this.interestCharge = 0;
    this.interestChargeRate = 0;


  }

  isFieldNull() {
    var amountNull = (this.amount == undefined || this.amount == 0);
    var remarkNull = (this.remark == undefined);
    var selectedAccountIdNull = (this.selectedAccountId == undefined);
    var amountValid = isNaN(+this.amount);
    var interestChargeRateNull = (this.interestChargeRate == undefined || this.interestChargeRate == 0);

    return amountNull || remarkNull || selectedAccountIdNull || amountValid || interestChargeRateNull
  }



  private getAllAccountsOfCompany(companyId: number) {

    // var xhttp = new XMLHttpRequest();
    // var url = "http://localhost:10080/api/v1/company/"+companyId+"/accounts";
    // xhttp.withCredentials = true;

    var xhttp = this.restapi.getRequest(this.constructApi.getAccountsOfCompany(companyId));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let account = new Account(json.id, json.number, json.name, json.holderName, json.balance, json.companyId, json.username);

          this.companyAccountList.push(account);

        }
        );

      }
    };

  }

  setSelectedAccount(accountId: number) {

    if (accountId === this.selectedAccountId)
      return true
    else
      return false;
  }

  calculateInterestCharge(interest: number) {
    this.interestCharge = this.amount * (interest / 100);
    this.interestChargeRate = interest;
  }

  addPrincipal() {

    this.disabledSubmit = true;

    var data = {
      "principal": this.amount,
      "stamp": this.stampAmount,
      "serviceCharge": this.scAmount,
      "extras": this.duitLebihList,
      "accountId": Number(this.selectedAccountId),
      "dateCreated": this.addPrincipalDate,
      "remark": this.remark,
      "interestChargeRate": this.interestChargeRate,
      "reserve": -this.reserve
    };


    var xhr = this.restapi.postRequest(this.constructApi.getAddTopUp(this.selectedLoan.ID), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        if (xhr.status == 200) {
          this.passEntry.emit(this.selectedLoan.ID)
          this.reset();
          this.sharedService.openAlert("Successfully added principal!", this.enums.SUCCESS_ALERT);
        } else
          this.sharedService.openErrorMessage(xhr);
      }

    }
  }


  getAccountBalance() {
    var account = this.companyAccountList.find(account => Number(account.ID) == Number(this.selectedAccountId));
    return account.Balance;
  }

  isBalanceInsufficient() {

    var extras = 0;
    for (let i = 0; i < this.duitLebihList.length; i++) {
      extras += this.duitLebihList[i].Amount;
    }

    if (this.amount - this.stampAmount - this.scAmount - extras - this.interestCharge + this.reserve > this.getAccountBalance()) {
      return true;
    }
    else
      return false;
  }

  submit() {

    if (this.isFieldNull())
      this.sharedService.openAlert("Please fill in all the fields!", this.enums.DANGER_ALERT);
    else if (this.isBalanceInsufficient()) {
      this.sharedService.openAlert("This account do not have enough fund", this.enums.DANGER_ALERT);
    }
    else if (this.reserve > this.availableReserve)
      this.sharedService.openAlert("You used more than your available simpan!", this.enums.DANGER_ALERT);
    else if ((this.stampAmount == undefined || this.stampAmount > this.enums.MAXIMUM_STAMP_NORMAL_SCHEME)) {
      this.sharedService.openAlert("Please make sure your STAMP not more than RM " + this.enums.MAXIMUM_STAMP_NORMAL_SCHEME, this.enums.DANGER_ALERT);
    }
    else if ((this.scAmount == undefined || this.scAmount > this.enums.MAXIMUM_SC_NORMAL_SCHEME)) {
      this.sharedService.openAlert("Please make sure your SC not more than RM " + this.enums.MAXIMUM_SC_NORMAL_SCHEME, this.enums.DANGER_ALERT);
    }
    else
      this.addPrincipal();
  }

  reset() {
    this.activeModal.close();
    this.selectedAccountId = 0;
    this.selectedHandler = "";
    this.selectedLoan = undefined;
  }



}
