import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-company-detail-card',
  templateUrl: './company-detail-card.component.html',
  styleUrls: ['./company-detail-card.component.css']
})
export class CompanyDetailCardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
