<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Search Task</h4>

        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 aria-label="Close" (click)="reset()"></button>
    </div>


    <div class="modal-body">
        <div class="row">
            <div class="col col-12 col-md-6">
                <div class="position-relative mb-2">
                    <input type="text" required class="textInputLong" name="searchName" [(ngModel)]="searchName">
                    <span class="highlight"></span>
                    <span class="bar barLong"></span>
                    <label>Name</label>
                </div>
            </div>
            <div class="col col-12 col-md-6">
                <div class="position-relative mb-2">
                    <input type="text" required class="textInputLong" name="searchICNum" [(ngModel)]="searchICNum">
                    <span class="highlight"></span>
                    <span class="bar barLong"></span>
                    <label>IC Number</label>
                </div>
            </div>

            <div class="col d-flex justify-content-end mt-0 pt-0">
                <button class="btn btn-primary" (click)="findTask(searchName,searchICNum,false)"><i
                        class="fas fa-search"></i> Search</button>
            </div>

        </div>
        <div class="container-fluid p-0" [hidden]="taskList.length<=0">

            <table mat-table [dataSource]="dataSource" matSort>

                <!-- Date Column -->
                <ng-container matColumnDef="Date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
                    <td mat-cell *matCellDef="let task" [ngClass]="addTaskStatusStyle(task.DateDone)"
                        (click)="goToTask(task.ID,task.CustomerId)"> {{task.Date | date:'dd-MM-yyyy'}}
                    </td>
                </ng-container>


                <!-- Name Column -->
                <ng-container matColumnDef="CustomerName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Name </th>
                    <td mat-cell *matCellDef="let task" [ngClass]="addTaskStatusStyle(task.DateDone)"
                        (click)="goToTask(task.ID,task.CustomerId)"> {{task.CustomerName}} </td>
                </ng-container>


                <!-- Description Column -->
                <ng-container matColumnDef="Description">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
                    <td mat-cell *matCellDef="let task" [ngClass]="addTaskStatusStyle(task.DateDone)"
                        (click)="goToTask(task.ID,task.CustomerId)">
                        {{task.Description}}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="[25,50, 100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</form>