<div class="container-fluid p-3">
    <div class="d-flex justify-content-end align-items-end">
        <button class="btn btn-info" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseExample">
            <i class="fas fa-chevron-circle-down" *ngIf="isCollapsed"></i>
            <i class="fas fa-chevron-circle-up" *ngIf="isCollapsed==false"></i>
        </button>
    </div>



    <div class="collapse" id="collapseExample" [ngbCollapse]="isCollapsed">
        <app-report-generator [selectedCompany]="selectedCompany"></app-report-generator>
    </div>
    <h2> Cash Book ( {{startDate |date:'yyyy-MM-dd'}} - {{endDate |date:'yyyy-MM-dd'}} )
    </h2>

    <table class="table table-stripped" style="border-collapse:collapse;">
        <thead>
            <tr>
                <th></th>
                <th>Date</th>
                <th class="sortHeader" (click)="sortByCriteria('Receipt')">Receipt Number</th>
                <th class="sortHeader" (click)="sortByCriteria('CustomerName')">Customer</th>
                <th class="sortHeader" (click)="sortByCriteria('Code')">Code</th>
                <th class="sortHeader" (click)="sortByCriteria('Outstanding')">Outstanding</th>
                <th class="sortHeader" (click)="sortByCriteria('In')">In</th>
                <th class="sortHeader" (click)="sortByCriteria('Out')">Out</th>
                <th>Balance</th>
            </tr>
        </thead>
        <tbody>
            <tr class="bg-info">
                <td></td>
                <td></td>
                <td></td>
                <td>Carry Forward Balance</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{carryFowardBalance}}</td>
            </tr>
            <ng-container *ngFor="let cashBookReport of cashBookReportList">
                <tr [ngClass]="getRowColor(cashBookReport.customerName)">
                    <td [hidden]="cashBookReport.cashBookList.length>0 && cashBookReport.collapsed==false"
                        (click)="changeCollapseDown(cashBookReport)" style="cursor: pointer;"
                        [attr.aria-expanded]="!cashBookReport.collapsed" aria-controls="collapseExample1"><i
                            class="fas fa-chevron-down"></i></td>
                    <td [hidden]="cashBookReport.cashBookList.length>0 && cashBookReport.collapsed==true"
                        (click)="changeCollapseUp(cashBookReport)" style="cursor: pointer;"
                        [attr.aria-expanded]="!cashBookReport.collapsed" aria-controls="collapseExample1"><i
                            class="fas fa-chevron-up"></i></td>
                    <td>{{cashBookReport.date |date:'dd-MM-yyyy'}}</td>
                    <td class="receiptCol" (click)="openLoanCard(cashBookReport.loanId)">
                        <span *ngIf="cashBookReport.includePrincipal==true"><i
                                class="fas fa-parking text-success"></i></span>
                        <span *ngIf="cashBookReport.includeInterest==true"><i
                                class="fas fa-info-circle text-warning"></i></span>
                        {{cashBookReport.receiptNumber}}
                        <span *ngIf="cashBookReport.scheme=='A'">({{cashBookReport.interestRate}}%)</span>

                    </td>
                    <td class="receiptCol" (click)="goToCustomer(cashBookReport.customerId)">
                        {{cashBookReport.customerName}}
                    </td>
                    <td class="receiptCol" (click)="goToCustomer(cashBookReport.customerId)">
                        {{cashBookReport.customerCode}}
                    </td>
                    <td>{{cashBookReport.outstanding | number : '1.2-2'}}</td>
                    <td [ngClass]="getAmountStyle(cashBookReport.totalIn)" [hidden]="cashBookReport.totalIn==0">
                        {{cashBookReport.totalIn}}
                    </td>
                    <td [ngClass]="getAmountStyle(cashBookReport.totalIn)" [hidden]="cashBookReport.totalIn!=0"></td>
                    <td [ngClass]="getAmountStyle(cashBookReport.totalOut)" [hidden]="cashBookReport.totalOut==0">
                        {{cashBookReport.totalOut}}
                    </td>
                    <td [ngClass]="getAmountStyle(cashBookReport.totalOut)" [hidden]="cashBookReport.totalOut!=0"></td>
                    <td>{{cashBookReport.balance |number:'1.2-2'}}</td>
                </tr>

                <ng-container *ngFor="let cashBook of cashBookReport.cashBookList">
                    <tr class="collapseDetails" id="collapseExample1" [ngbCollapse]="cashBookReport.collapsed">
                        <td [hidden]="loggedInRole==enums.ADMIN"></td>
                        <td [hidden]="loggedInRole!=enums.ADMIN"> <button class="btn btn-warning"
                                (click)="openEditTransactionDialog(cashBook.transactionId,cashBook.amount,cashBook.remark)"><i
                                    class="fas fa-pencil-alt"></i></button>
                        </td>
                        <td>{{cashBookReport.date |date:'dd-MM-yyyy'}}</td>
                        <td>{{enums.transactionTypeString[cashBook.type-1]}}</td>
                        <td>{{cashBook.remark}}</td>
                        <td></td>
                        <td [hidden]="cashBook.amount<0">{{cashBook.amount}}</td>
                        <td [hidden]="cashBook.amount>0">-</td>
                        <td [hidden]="cashBook.amount>0">{{cashBook.amount}}</td>
                        <td [hidden]="cashBook.amount<0">-</td>
                        <td></td>
                        <td></td>
                    </tr>
                </ng-container>

            </ng-container>

        </tbody>
    </table>
    <!-- <table class="table table-striped">
        <thead>
            <tr>
                <th>Date</th>
                <th>Receipt</th>
                <th>Remark</th>
                <th>Rate</th>
                <th>Type</th>
                <th>Outstanding</th>
                <th>In</th>
                <th>Out</th>
                <th>Balance</th>


            </tr>
        </thead>
        <tbody>
            <tr class="bg-info">
                <td></td>
                <td></td>
                <td>Balance Carry Forward</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{carryFowardBalance}}</td>
            </tr>

            <tr *ngFor="let report of cashBookList">
                <td>{{report.date | date:'yyyy-MM-dd'}}</td>
                <td class="receiptCol" (click)="openLoanCard(report.loanId)">{{report.receiptNumber}}</td>
                <td class="remarkCol">{{report.remark}}</td>
                <td [hidden]="report.scheme=='A'"></td>
                <td [hidden]="report.scheme!='A'">{{report.rate}}</td>
                <td>{{report.type}}</td>
                <td>{{report.outstanding}}</td>
                <td [hidden]="report.amount>0" [ngClass]="getAmountStyle(report.amount)">-
                </td>
                <td [hidden]="report.amount<0" [ngClass]="getAmountStyle(report.amount)">
                    {{report.amount}}</td>

                <td [hidden]="report.amount<0" [ngClass]="getAmountStyle(report.amount)">
                    -</td>
                <td [hidden]="report.amount>0" [ngClass]="getAmountStyle(report.amount)">
                    {{report.amount}}</td>

                <td>{{report.principal-amount}}</td>
                <td></td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <th>Total:</th>
                <th>RM </th>
            </tr>
        </tfoot>
    </table> -->
</div>