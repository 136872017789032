<div class="card" style="background-color: lightsteelblue;">
    <div class="card-body">
        <div class="row">
            <div class="col-6">
                <p>Company</p>
                <div class="group mt-3">
                    <input type="text" required class="textInputShort" name="uid" [(ngModel)]="uid">
                    <span class="highlight"></span>
                    <span class="bar barShort"></span>
                    <label>UID</label>
                </div>
            </div>
            <div class="col-6">
                <button class="btn btn-primary" (click)="checkNull(uid)"><i class="fas fa-search"></i>
                    Search</button>
            </div>
        </div>
    </div>
</div>