<form>
    <!-- Modal Header -->
    <div class="modal-header" style="background-color: salmon;">
        <h4 class="modal-title">Elevate Confirmation!!</h4>
        <!-- <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="closeDialog()"></button> -->
    </div>

    <div class="modal-body">
        <div class="container-fluid">
            <div class="p-3">
                <p class="fw-bold messageText">{{message}}</p>
                <p class="fw-bold text-danger">You need to send this ticket to BOSS and wait for approval, are
                    you sure to
                    do so?</p>
                <hr />
                <p class="fw-bold text-danger"> Please key in your username to confirm this action</p>
                <div class="group mt-3">
                    <input type="text" required class="textInputLong" name="username" [(ngModel)]="username">
                    <span class="highlight"></span>
                    <span class="bar barLong"></span>
                    <label>Username</label>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="setConfirmation(true)">Send to
            BOSS</button>
        <button class="btn btn-outline-danger" type="button" (click)="setConfirmation(false)">I will
            adjust</button>
    </div>
</form>