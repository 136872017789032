import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { PaymentSchedule } from 'src/model/paymentSchedule.model';



@Component({
  selector: 'app-generate-formj',
  templateUrl: './generate-formj.component.html',
  styleUrls: ['./generate-formj.component.css']
})
export class GenerateFormjComponent implements OnInit {

  @Input() viewCustomerId: number;
  selectedMonths: number;
  paymentScheduleList: PaymentSchedule[] = new Array;
  desiredTotal = 0;
  onHandAmount = 0;
  principal = 0;
  interestCharge = 0;
  totalPayment = 0;
  lastPayment = 0;
  amount = 0;
  monthsCount = 1;

  stampDate: Date;
  receiptNumber: string;
  formJID: number;

  disabledSubmit: boolean = false;

  isEPF: boolean = false;

  epfMonthly: number;

  constructor(private router: Router, private activeModal: NgbActiveModal, public sharedService: SharedService, private restapi: RestApiService,
    private constructApi: ConstructAPI, private enums: Enums) { }
  ngOnInit(): void {
  }

  addSchedule() {
    var schedule = { monthsCount: Number(this.monthsCount), amount: Number(this.amount) }
    this.paymentScheduleList.push(schedule);

    this.calculateTotal();
    this.calculateTotalMonths();
    this.calculateAllAmount();
  }

  getEPFScheduleAndCalculate() {

    this.paymentScheduleList = [];
    var interestRate = 0.015;


    //2024-01-18 -  epf form J version 2, need to change from calculating principal based on total payment into the on hand amount
    //previous calculation:
    // this.principal = Math.ceil((this.desiredTotal / 50) / (1 + this.selectedMonths * interestRate)) * 50;
    this.principal = Number(this.onHandAmount);
    this.epfMonthly = Math.floor(this.principal * interestRate);
    this.lastPayment = this.principal + (this.principal * interestRate * this.selectedMonths) - (this.epfMonthly * (this.selectedMonths - 1));

    var schedule = { monthsCount: Number(this.selectedMonths), amount: Number(this.epfMonthly) }
    this.paymentScheduleList.push(schedule);

  }


  calculateTotalMonths() {
    this.selectedMonths = 0;
    if (this.paymentScheduleList.length > 0) {
      for (let i = 0; i < this.paymentScheduleList.length; i++) {
        this.selectedMonths += this.paymentScheduleList[i].monthsCount
      }
    }
    return this.selectedMonths;
  }

  clearSchedule() {
    this.paymentScheduleList = undefined;
  }

  checkValidity() {
    if (this.amount == 0 || this.amount == undefined || this.monthsCount == 0 || this.monthsCount == undefined)
      this.sharedService.openAlert("Please key in your amount and months count", this.enums.DANGER_ALERT);
    else
      this.addSchedule();
  }

  checkEPFValidity() {
    if (this.selectedMonths == 0 || this.selectedMonths == undefined || this.onHandAmount == 0 || this.onHandAmount == undefined)
      this.sharedService.openAlert("Please key in your on hand amount and months count", this.enums.DANGER_ALERT);
    else
      this.getEPFScheduleAndCalculate();
  }

  calculateTotal() {
    this.desiredTotal = 0;
    if (this.paymentScheduleList.length > 0) {
      for (let i = 0; i < this.paymentScheduleList.length; i++) {
        this.desiredTotal += this.paymentScheduleList[i].monthsCount * this.paymentScheduleList[i].amount
      }
    }
    return this.desiredTotal;
  }

  calculateAllAmount() {

    var interestRate = 0.015;
    var roundedPrincipal = Math.round((this.desiredTotal * 100 / ((this.selectedMonths * interestRate) + 1))) / 100;
    this.principal = Math.floor(roundedPrincipal / 50) * 50;


    this.interestCharge = this.principal * interestRate * this.selectedMonths;
    this.totalPayment = this.principal + this.interestCharge;

    this.lastPayment = this.totalPayment;
    for (let i = 0; i < this.paymentScheduleList.length; i++) {
      if (i < this.paymentScheduleList.length - 1)
        this.lastPayment -= this.paymentScheduleList[i].monthsCount * this.paymentScheduleList[i].amount;
      else
        this.lastPayment -= (this.paymentScheduleList[i].monthsCount - 1) * this.paymentScheduleList[i].amount;
    }
  }

  removeSchedule(i: number) {
    this.paymentScheduleList.splice(i, 1);
    this.calculateTotal();
    this.calculateTotalMonths();
    this.calculateAllAmount();
  }


  addFormJ() {
    this.disabledSubmit = true;
    var terms = 0;

    for (let i = 0; i < this.paymentScheduleList.length; i++) {
      terms += this.paymentScheduleList[i].monthsCount;
    }
    var scheduleData = {
      "paymentSchedule": this.paymentScheduleList,
      "lastPayment": this.lastPayment,
      "receiptNumber": this.receiptNumber,
      "stampDate": this.stampDate,
      "version":(this.isEPF==true)?"epfVer2": "swastaVer1"
    }
    var data = {
      "principal": this.principal,
      "customerId": this.viewCustomerId,
      "term": terms,
      "dateCreated": new Date(),
      "data": scheduleData
    };

    var xhr = this.restapi.postRequest(this.constructApi.getAddFormJ(), data);
    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        if (xhr.status == 200) {
          this.disabledSubmit = false;
          var json = JSON.parse(xhr.responseText);

          this.formJID = json.id
          this.sharedService.openAlert("Successfully added form J record!", this.enums.SUCCESS_ALERT);
          this.generateFormJ();

        } else {

          this.disabledSubmit = false;
          this.sharedService.openAlert("Some error occurs, please try again later", this.enums.DANGER_ALERT);
        }
      }

    }

  }


  checkGenerateValidity() {
    if (this.paymentScheduleList.length == 0 || this.paymentScheduleList == undefined) {
      this.sharedService.openAlert("Please fill in your payment schedule!", this.enums.DANGER_ALERT)
    }
    else {
      this.addFormJ();
    }
  }
  generateFormJ() {
    var companyId = Number(sessionStorage.getItem('companyId'));
    this.router.navigateByUrl('/formJ/' + companyId + "/" + this.viewCustomerId + "/" + this.formJID);
    this.reset();
  }

  reset() {
    this.activeModal.close();
  }
}
