<h3 class="text-start" id="pageTitle" style="margin: 15px;">Lead List</h3>
<div class="content m-3">
    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Enter searching criteria">
            </mat-form-field>
        </div>
    </div>


    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>

            <!-- DateCreated Column -->
            <ng-container matColumnDef="DateCreated">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Created </th>
                <td mat-cell *matCellDef="let lead" (click)="goToCustomerProfile(lead.ID,lead.Profiles[0].ID)">
                    {{lead.Profiles[0].DateCreated|date:'dd-MM-yyyy'}}
                </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="Name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let lead" (click)="goToCustomerProfile(lead.ID,lead.Profiles[0].ID)">
                    {{lead.Name}}
                </td>
            </ng-container>

            <!-- ICNumber Column -->
            <ng-container matColumnDef="ICNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> IC Number </th>
                <td mat-cell *matCellDef="let lead" (click)="goToCustomerProfile(lead.ID,lead.Profiles[0].ID)">
                    {{sharedService.processICNum(lead.ICNumber)}} </td>
            </ng-container>

            <!-- Occupation Column -->
            <ng-container matColumnDef="Occupation">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Occupation </th>
                <td mat-cell *matCellDef="let lead" (click)="goToCustomerProfile(lead.ID,lead.Profiles[0].ID)">
                    {{lead.Profiles[0].Occupation}} </td>
            </ng-container>

            <!-- Salary Column -->
            <ng-container matColumnDef="Salary">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Salary </th>
                <td mat-cell *matCellDef="let lead" (click)="goToCustomerProfile(lead.ID,lead.Profiles[0].ID)">
                    RM {{lead.Profiles[0].Salary}} </td>
            </ng-container>

            <!-- Salary Day Column -->
            <ng-container matColumnDef="SalaryDay">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Salary Day </th>
                <td mat-cell *matCellDef="let lead" (click)="goToCustomerProfile(lead.ID,lead.Profiles[0].ID)">
                    {{lead.Profiles[0].SalaryDay}} </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[25,50, 100]" showFirstLastButtons></mat-paginator>
    </div>
</div>