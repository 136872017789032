import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Enums } from 'src/app/Shared/enums';
import { Account } from 'src/model/account.model';
import { ConstructAPI } from '../../API/constructAPI';
import { RestApiService } from '../../API/restapi';
import { SharedService } from '../../Shared/shared-service.service';
import { Customer } from 'src/model/customer.model';
import { Profile } from 'src/model/profile.model';
import { ReceiptDetailsComponent } from 'src/app/Sets/receipt-details/receipt-details.component';

@Component({
  selector: 'app-add-simpan',
  templateUrl: './add-simpan.component.html',
  styleUrls: ['./add-simpan.component.css']
})
export class AddSimpanComponent implements OnInit {

  companyBankAccountList: Account[] = new Array;
  cashAccountList: Account[] = new Array;
  selectedAccountId: number;

  amount: number;
  simpanDate: Date = new Date();
  remark: string;

  @Input() isModal = false;
  @Input() selectedProfileID: number;
  @Input() selectedProfile: Profile;
  @Input() selectedCustomer: Customer;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  addedSimpanSetId: number;

  disabledSubmit = false;
  constructor(private activeModal: NgbActiveModal, private restapi: RestApiService, private constructApi: ConstructAPI,
    public sharedService: SharedService, private enums: Enums) { }

  ngOnInit(): void {

    var companyId = Number(sessionStorage.getItem("companyId"));
    this.getAllAccountsOfCompany(companyId);
  }


  fieldIsNull() {
    var amountNull = this.amount == undefined || this.amount == 0;
    var remarkNull = this.remark == undefined;
    var accountNull = this.selectedAccountId == undefined;

    return amountNull || remarkNull || accountNull
  }

  submit() {
    if (this.fieldIsNull() == true) {
      this.sharedService.openAlert("Please fill in all fields correctly", this.enums.DANGER_ALERT);
    } else {
      this.addSimpan();
    }

  }


  reset() {
    this.activeModal.close();
  }

  private getAllAccountsOfCompany(companyId: number) {
    var loggedInUsername = sessionStorage.getItem("username");
    var xhttp = this.restapi.getRequest(this.constructApi.getAccountsOfCompany(companyId));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let account = new Account(json.id, json.number, json.name, json.holderName, json.balance, json.companyId, json.username);

          if (account.Username == undefined)
            this.companyBankAccountList.push(account);
          else {
            if (this.sharedService.isStaff()) {
              if (account.Username == loggedInUsername)
                this.cashAccountList.push(account);
            }
            else {
              this.cashAccountList.push(account);
            }



          }


        }
        );

      }
    };

  }

  addSimpan() {
    this.disabledSubmit = true;
    var isoTaskDate = (new Date(Number(this.simpanDate))).toISOString();

    var data = {
      "reserve": this.amount,
      "accountId": Number(this.selectedAccountId),
      "dateCreated": isoTaskDate,
      "remark": this.remark
    };


    var xhr = this.restapi.postRequest(this.constructApi.getAddReserve(this.selectedProfileID), data);
    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        if (xhr.status == 200) {

          var json = JSON.parse(xhr.responseText);

          //if this is a shared profile and user logged in is main profile

          if (this.selectedProfile != undefined && this.selectedProfile.SharedProfileId == this.selectedProfile.ID) {
            var addedSimpanTransactionId = Number(json.id);
            this.addSimpanIncomeSet([addedSimpanTransactionId]);
          } else {
            this.continueSimpanAction();
          }





        }
        else
          this.sharedService.openErrorMessage(xhr);
      }


    }
  }

  continueSimpanAction() {
    this.sharedService.openAlert("Successfully added simpan!", this.enums.SUCCESS_ALERT);

    if (this.addedSimpanSetId != undefined) {
      this.activeModal.close();
      var modalRef = this.sharedService.openModal(ReceiptDetailsComponent, "largeModal");
      modalRef.componentInstance.receiptId = Number(this.addedSimpanSetId);
      modalRef.componentInstance.isModal = true;
    }
    else {
      if (this.isModal == true) {
        this.passEntry.emit(this.amount);
        this.activeModal.close();
      }
      else
        window.location.reload();
    }

  }

  addSimpanIncomeSet(idsToAdd: number[]) {
    this.disabledSubmit = true;

    var companyId = Number(sessionStorage.getItem("companyId"))
    var data = {
      "transactionIds": idsToAdd,
      "remark": "SIMPAN " + this.selectedCustomer.Name + " (" + this.selectedCustomer.ICNumber + ")",
      "name": "EPF SIMPAN PAYMENT "
    }


    var xhr = this.restapi.postRequest(this.constructApi.getAddNewCompanySet(companyId), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;

        if (xhr.status == 200) {

          var json = JSON.parse(xhr.responseText);
          this.addedSimpanSetId = Number(json.id);

          this.continueSimpanAction();
        }
        else {
          var json = JSON.parse(xhr.responseText);
          this.sharedService.openAlert(json.error, this.enums.DANGER_ALERT)
        }

      }

    }
  }
}
