import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RestApiService } from 'src/app/API/restapi';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { Arrear } from 'src/model/arrear.model';
import { Loan } from 'src/model/loan.model';
import { DatePipe } from '@angular/common';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Account } from 'src/model/account.model';
import { AlertDialogComponent } from 'src/app/Shared/alert-dialog/alert-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-loan-arrear',
  templateUrl: './loan-arrear.component.html',
  styleUrls: ['./loan-arrear.component.css'],
  providers: [DatePipe]
})
export class LoanArrearComponent implements OnInit {

  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private restApi: RestApiService,
    private constructApi: ConstructAPI, public enums: Enums, public sharedService: SharedService) { }

  arrearList: Arrear[] = new Array;
  unpaidArrearList: Arrear[] = new Array;
  companyAccountList: Account[] = new Array;

  @Input() selectedLoan: Loan;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  selectedAccountId: number;

  //2-WAY BINDING INFO
  payArrearDate: Date;
  // remark: string;
  amount: number = 0;
  maxDate: Date = new Date();

  isExpanded: string;
  selectedArrearID: number;

  disabledSubmit = false;

  ngOnInit(): void {

    this.getAllArrearOfLoan();

    if (this.sharedService.isBranchAccountHandler()) {
      var companyID = Number(sessionStorage.getItem("companyId"));
      this.getAllAccountsOfCompany(companyID);

    }

    this.payArrearDate = this.maxDate;

  }

  getAllArrearOfLoan() {

    var xhttp = this.restApi.getRequest(this.constructApi.getLoanArrear(this.selectedLoan.ID));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArr = JSON.parse((xhttp.responseText));

        jsonArr.forEach(json => {

          let arrear = new Arrear(json.amount, json.date, json.id, json.loanId, json.remark, json.datePaid);

          if (arrear.DatePaid == undefined)
            this.unpaidArrearList.push(arrear);

          this.arrearList.push(arrear);
        }
        );
      }
      this.arrearList.sort((a, b) => (a.ID > b.ID) ? 1 : -1);
    };

  }


  setSelectedStyle(arrearID: number) {
    this.selectedArrearID = this.unpaidArrearList[0].ID;

    if (arrearID === Number(this.selectedArrearID))
      return "lightcoral"
  }

  private getAllAccountsOfCompany(companyId: number) {

    // var xhttp = new XMLHttpRequest();
    // var url = "http://localhost:10080/api/v1/company/"+companyId+"/accounts";
    // xhttp.withCredentials = true;

    var xhttp = this.restApi.getRequest(this.constructApi.getAccountsOfCompany(companyId));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let account = new Account(json.id, json.number, json.name, json.holderName, json.balance, json.companyId, json.username);
          var loggedInUser = sessionStorage.getItem("username");

          this.companyAccountList.push(account);

        }
        );

      }
    };

  }

  setSelectedAccount(accountId: number) {

    if (accountId === this.selectedAccountId)
      return true
    else
      return false;
  }


  isFieldNull() {
    var amountNull = (this.amount == undefined || this.amount == 0);
    // var remarkNull = (this.remark == undefined);
    var selectedAccountIdNull = (this.selectedAccountId == undefined)
    var amountValid = isNaN(+this.amount)

    return amountNull || selectedAccountIdNull || amountValid
  }


  setActive(type: string) {

    if (this.selectedLoan.Status == this.enums.SETTLED_LOAN) {
      if (type == 'Payment')
        return 'active';
      else
        return ''
    } else {
      if (type == 'History')
        return 'active';
      else
        return ''
    }
  }


  //THIS PART IS USING PAY ARREAR ENDPOINT TO PAY ARREAR
  // payArrear() {
  //   var data = {
  //     "amount": this.amount,
  //     // "interestCharge": this.amount,
  //     "accountId": Number(this.selectedAccountId),
  //     "datePaid": this.payArrearDate
  //   };


  //   var xhr = this.restApi.postRequest(this.constructApi.getLoanArrearPayment(this.selectedArrearID), data);
  //   xhr.onreadystatechange = () => {
  //     if (xhr.readyState == 4 && xhr.status == 200) {
  //       var json = JSON.parse(xhr.responseText);


  //       this.passEntry.emit(this.selectedLoan.ID)
  //       this.reset();
  //       this.sharedService.openAlert("Successfully paid arrear!");

  //     }
  //   }
  // }


  payArrear() {
    this.disabledSubmit = true;
    var data = {
      "repayment": 0,
      "stamp": 0,
      "serviceCharge": 0,
      "reserve": 0,
      "extras": [{ amount: 0 }],
      "AccountId": Number(this.selectedAccountId),
      "arrear": Number(this.amount),
      "discount": 0,
      "dateCreated": this.payArrearDate,
      "remark": "PAY ARREAR"
    };


    var xhr = this.restApi.postRequest(this.constructApi.getAddPayment(this.selectedLoan.ID), data);
    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        if (xhr.status == 200) {
          this.passEntry.emit(this.selectedLoan.ID)
          this.reset();
          this.sharedService.openAlert("Successfully paid arrear!", this.enums.SUCCESS_ALERT);
        }
        else
          this.sharedService.openErrorMessage(xhr);
      }

    }
  }

  getAccountBalance() {
    var account = this.companyAccountList.find(account => account.ID === Number(this.selectedAccountId));
    return account.Balance;
  }

  submit() {
    if (this.isFieldNull())
      this.sharedService.openAlert("Please fill in all the fields!", this.enums.DANGER_ALERT);
    else
      this.payArrear();
  }

  reset() {
    this.activeModal.close();

  }


  // ARREAR HISTORY PART
  calculateTotalArrear(arrear: Arrear[]) {
    var numberOfArrear: number = 0;

    for (let i = 0; i < arrear.length; i++) {

      if (arrear[i].DatePaid == undefined)
        numberOfArrear++;
    }
    return numberOfArrear;

  }

  addPaidStyle(datePaid: Date) {
    if (datePaid != undefined)
      return 'paid';
  }


  confirmDeleteArrear(arrearID: number) {
    var modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Are you sure you want to delete this arrear?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something

      if (confirmation == true) {
        this.deleteArrear(arrearID);
      }
    });
  }

  deleteArrear(arrearID: number) {
    var xhr = this.restApi.deleteRequest(this.constructApi.getSingleLoanArrear(this.selectedLoan.ID, arrearID));

    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        if (xhr.status == 200) {

          if (xhr.status == 200) {
            window.location.reload();
          }
          else {
            this.sharedService.openAlert("Opps, some errors occured. Please try again later", this.enums.DANGER_ALERT);
          }

        }
      }

    }
  }




}
