import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Company } from 'src/model/company.model';
import { Customer } from 'src/model/customer.model';

@Component({
  selector: 'app-add-pulled-customer-record',
  templateUrl: './add-pulled-customer-record.component.html',
  styleUrls: ['./add-pulled-customer-record.component.css']
})
export class AddPulledCustomerRecordComponent implements OnInit {

  date: Date;
  remark: string;
  fromCompany: number;

  @Input() selectedCustomer: Customer;
  disabledSubmit = false;

  myControl = new UntypedFormControl();
  filteredCompany: Observable<Company[]>;

  companyList: Company[] = new Array;
  selectedCompanyCode: string;


  constructor(private activeModal: NgbActiveModal, private restapi: RestApiService, private constructApi: ConstructAPI, private sharedService: SharedService) {

    //filter company
    this.filteredCompany = this.myControl.valueChanges.pipe(
      startWith(''),
      map(companyCode => this._filterCompanyCodeOptions(companyCode))
    );
  }


  ngOnInit(): void {
    this.getAllCompanies();
  }



  submit() {

    this.disabledSubmit = true;

    var data = {
      "fromCompany": this.fromCompany,
      "remark": this.remark,
      "date": this.date
    };


    var xhr = this.restapi.postRequest(this.constructApi.getAddPulledCustomerRecord(this.selectedCustomer.ID), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        if (xhr.status == 200) {
          window.location.reload();
        } else
          this.sharedService.openErrorMessage(xhr);
      }

    }
  }


  private _filterCompanyCodeOptions(value: string): Company[] {
    const filterValue = value.toLowerCase();


    // return this.companyList.filter(company => company.CompanyCode.toLowerCase().includes(filterValue));
    // var filtered = this.companyList.filter(company => { return company.CompanyCode.toLowerCase().indexOf(filterValue) === 0 });
    var filtered = this.companyList.filter(company => { return company.CompanyCode.toLowerCase().includes(filterValue) });

    return filtered;
  }

  setSelectedCompanyCode(companyId: number) {
    var index = this.companyList.findIndex(company => Number(company.ID) == Number(companyId));
    this.selectedCompanyCode = this.companyList[index].CompanyCode;
  }

  setSelectedCompany(code: string) {

    var index = this.companyList.findIndex(company => company.CompanyCode.toLowerCase() == code.toLowerCase());
    this.fromCompany = this.companyList[index].ID;
  }




  getAllCompanies() {
    //clear company list
    this.companyList.splice(0, this.companyList.length);

    var xhttp = this.restapi.getRequest(this.constructApi.getAllCompanies())
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));


        jsonArray.forEach(json => {
          let company = new Company(json.id, json.name, json.address, json.companyCode, json.contactNo, undefined, json.group, new Date(json.dateOperating));
          this.companyList.push(company);
        }
        );


        //set the selected company code
        if (this.fromCompany != undefined)
          this.setSelectedCompanyCode(this.fromCompany)
      }
    };
  }

  reset() {
    this.activeModal.close();

  }
}
