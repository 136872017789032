import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';

@Component({
  selector: 'app-maintenance-mode-toggle',
  templateUrl: './maintenance-mode-toggle.component.html',
  styleUrls: ['./maintenance-mode-toggle.component.css']
})
export class MaintenanceModeToggleComponent implements OnInit {

  confirmationPassword: string;
  disabledSubmit = false;

  @Input() turn_on: boolean;
  constructor(private activeModal: NgbActiveModal, private restapi: RestApiService, private constructApi: ConstructAPI, private sharedService: SharedService, private enums: Enums) { }

  ngOnInit(): void {


  }


  reset() {
    this.activeModal.close();
  }

  submit() {
    if (this.confirmationPassword != undefined) {
      this.toggleMaintenanceMode();
    }

  }

  toggleMaintenanceMode() {
    this.disabledSubmit = true;
    var data = {
      "password": this.confirmationPassword,
    };


    var xhr = (this.turn_on == true) ? this.restapi.postRequest(this.constructApi.getOnMaintenanceMode(), data) : this.restapi.postRequest(this.constructApi.getOffMaintenanceMode(), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        if (xhr.status == 200) {
          this.disabledSubmit = false;
          if (this.turn_on == true)
            this.sharedService.openAlert("Successfully turned ON maintenance mode", this.enums.SUCCESS_ALERT);
          else
            this.sharedService.openAlert("Successfully turned OFF maintenance mode", this.enums.SUCCESS_ALERT);
          this.activeModal.close();
        }
        else {
          this.disabledSubmit = false;
          this.sharedService.openErrorMessage(xhr);
        }
      }

    }
  }

}
