import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { Loan } from 'src/model/loan.model';
import { LoanScheme } from 'src/model/loanScheme.model';
export class Sale {
  id: number;
  region: string;
  country: string;
  city: string;
  amount: number;
  date: Date;
}

interface StockReport {
  schemeName: string,
  activeOutstanding: number,
  bdOutstanding: number,
  cpOutstanding: number,
  stock: number,
  stockReceived: number
}


@Component({
  selector: 'app-stock-report',
  templateUrl: './stock-report.component.html',
  styleUrls: ['./stock-report.component.css']
})

export class StockReportComponent implements OnInit {
  @Input() selectedCompany: number;
  @Input() endDate: Date;
  needStatusOutstanding: boolean = true;
 

  stockList: StockReport[] = new Array;
  cashBookAmount: number;

  pivotGridDataSource: any;

  routeSubscription: any;
  isCollapsed: boolean = false;

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    private restapi: RestApiService, private constructApi: ConstructAPI, private enums: Enums) {
  }

  ngOnInit(): void {
    this.routeSubscription = this.activatedRoute.paramMap.subscribe(params => {
      this.selectedCompany = + params.get('companyId');
      this.endDate = (params.get('endDate') == undefined) ? undefined : new Date(params.get('endDate'));

    });

  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }
}
