import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ConstructAPI } from '../../API/constructAPI';
import { RestApiService } from '../../API/restapi';
import { Enums } from '../../Shared/enums';
import { SharedService } from '../../Shared/shared-service.service';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';

interface Blacklist {
  name: string,
  icNumber: string,
  otherId: string,
  remark: string,
  status: string,
  createdBy: string,
  createdOn: Date,
  id: number,
  releasedBy: string,
  releasedOn: Date
}

@Component({
  selector: 'app-blacklist-customer-list',
  templateUrl: './blacklist-customer-list.component.html',
  styleUrls: ['./blacklist-customer-list.component.css']
})
export class BlacklistCustomerListComponent implements OnInit {

  constructor(private router: Router, private restApi: RestApiService, private constructApi: ConstructAPI, public sharedService: SharedService, private enums: Enums) { }
  displayedColumns: string[];
  dataSource = new MatTableDataSource;
  innerWidth: any;
  disabledSubmit = false;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  blacklistCustList: Blacklist[] = new Array;

  ngOnInit(): void {
    this.getAllBlacklistRecords();

    if (this.sharedService.isBossAccount() || this.sharedService.isAdmin())
      this.displayedColumns = ['name', 'icNumber', 'otherId', 'remark', 'status', 'createdBy', 'createdOn', 'action'];
    else
      this.displayedColumns = ['name', 'icNumber', 'otherId', 'remark', 'status'];
  }



  getAllBlacklistRecords() {
    var xhttp = this.restApi.getRequest(this.constructApi.getAllBlacklist());



    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          var blacklist = {
            createdBy: json.created_by, createdOn: json.created_on, icNumber: json.icNumber,
            id: json.id, name: json.name, otherId: json.otherId, remark: json.remark, status: json.status, releasedBy: json.releasedBy, releasedOn: json.releasedOn
          }

          this.blacklistCustList.push(blacklist);

        }
        );

        this.blacklistCustList.sort((a, b) => (new Date(a.createdOn) > new Date(b.createdOn)) ? -1 : 1);

        //make customer list as the datasource
        this.dataSource = new MatTableDataSource<Blacklist>(this.blacklistCustList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

      }
    };


  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  confirmBeforeReleaseCustomer(blacklist: Blacklist) {
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Confirm to release this customer: " + blacklist.name + "?";
    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something\

      if (confirmation == true)
        this.releaseCustomer(blacklist.id);
    });

  }

  releaseCustomer(id: number) {
    var xhr = this.restApi.patchRequest(this.constructApi.getPatchReleaseCustomer(id));
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        if (xhr.status == 200) {          //if success emit;
          this.sharedService.openAlert("Successfully release customer", this.enums.SUCCESS_ALERT);
          window.location.reload();
        }
        else
          this.sharedService.openErrorMessage(xhr);
      }

    }
  }
}
