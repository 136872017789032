import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-verify-customer',
  templateUrl: './verify-customer.component.html',
  styleUrls: ['./verify-customer.component.css']
})
export class VerifyCustomerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
