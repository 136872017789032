export interface CPRemark {
    HandlerName: string,
    RemarkDate: Date,
    RemarkDesc: string,
    RemarkID: number
}

export class CPDetails {




    constructor(private _customerID: number, private _additionalInfo: string, private _custAltNumber: string, private _customerCode: string, private _customerIC: string, private _customerName: string,
        private _debtAmount: number, private _debtTerm: string, private _caseStatus: string, private _charges: number, private _custOccupation: string,
        private _custStatus: string, private _defaultPhone: string, private _officeAmount: number, private _remarks?: CPRemark[], private _handlerName?: string) {

    }

    public get HandlerName(): string {
        return this._handlerName;
    }
    public set HandlerName(value: string) {
        this._handlerName = value;
    }

    public get CustomerID(): number {
        return this._customerID;
    }
    public set CustomerID(value: number) {
        this._customerID = value;
    }
    public get Remarks(): CPRemark[] {
        return this._remarks;
    }
    public set Remarks(value: CPRemark[]) {
        this._remarks = value;
    }

    public get AdditionalInfo(): string {
        return this._additionalInfo;
    }
    public set AdditionalInfo(value: string) {
        this._additionalInfo = value;
    }


    public get CustomerIC(): string {
        return this._customerIC;
    }
    public set CustomerIC(value: string) {
        this._customerIC = value;
    }
    public get CustomerCode(): string {
        return this._customerCode;
    }
    public set CustomerCode(value: string) {
        this._customerCode = value;
    }
    public get CustAltNumber(): string {
        return this._custAltNumber;
    }
    public set CustAltNumber(value: string) {
        this._custAltNumber = value;
    }


    public get OfficeAmount(): number {
        return this._officeAmount;
    }
    public set OfficeAmount(value: number) {
        this._officeAmount = value;
    }
    public get DefaultPhone(): string {
        return this._defaultPhone;
    }
    public set DefaultPhone(value: string) {
        this._defaultPhone = value;
    }
    public get CustStatus(): string {
        return this._custStatus;
    }
    public set CustStatus(value: string) {
        this._custStatus = value;
    }
    public get CustOccupation(): string {
        return this._custOccupation;
    }
    public set CustOccupation(value: string) {
        this._custOccupation = value;
    }
    public get Charges(): number {
        return this._charges;
    }
    public set Charges(value: number) {
        this._charges = value;
    }
    public get CaseStatus(): string {
        return this._caseStatus;
    }
    public set CaseStatus(value: string) {
        this._caseStatus = value;
    }
    public get DebtTerm(): string {
        return this._debtTerm;
    }
    public set DebtTerm(value: string) {
        this._debtTerm = value;
    }
    public get DebtAmount(): number {
        return this._debtAmount;
    }
    public set DebtAmount(value: number) {
        this._debtAmount = value;
    }
    public get CustomerName(): string {
        return this._customerName;
    }
    public set CustomerName(value: string) {
        this._customerName = value;
    }


}