import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Profile } from 'src/model/profile.model';
import { Location } from '@angular/common';
import { GlobalCustomer } from 'src/model/globalCustomer.model';
import { Customer } from 'src/model/customer.model';
import { profile } from 'console';
import { Enums } from 'src/app/Shared/enums';




@Component({
  selector: 'app-merge-profile',
  templateUrl: './merge-profile.component.html',
  styleUrls: ['./merge-profile.component.css']
})
export class MergeProfileComponent implements OnInit {

  @Input() profileList: Profile[];
  @Input() selectedCustomer: Customer;


  selectedProfile: Profile;

  disabledSubmit = false;
  mergeSimpan = true;

  searchName: string;
  searchICNum: string;
  searchLeadID: number;

  isForceMove = false;

  customerList: GlobalCustomer[] = new Array;

  constructor(private sharedService: SharedService, private activeModal: NgbActiveModal, private location: Location, private restApi: RestApiService,
     private constructApi: ConstructAPI,private enums:Enums) { }

  ngOnInit(): void {

    for (let i = 0; i < this.profileList.length; i++) {

      if (Number(this.profileList[i].ID) != Number(this.selectedProfile.ID))
        this.getProfileDetails(this.profileList[i].ID);
    }
  }


  getProfileDetails(id: number) {
    var xhttp = this.restApi.getRequest(this.constructApi.getProfile(id));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var json = JSON.parse((xhttp.responseText));
        var reserveAmount = (json.reserve == undefined) ? 0 : json.reserve;

        var index = this.profileList.findIndex(profile => Number(profile.ID) === Number(json.id));
        this.profileList[index].Reserve = reserveAmount;
        this.profileList[index].BadDebt = json.badDebt;
      }
    };
  }

  reset() {
    this.activeModal.close();
  }

  confirmBeforeUpdate(profile: Profile) {
    this.disabledSubmit = true;

    var proceed = false;
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Confirm to merge profile " +
      this.selectedProfile.ID + "-" + this.selectedProfile.Occupation + " to " + profile.ID + "-" + profile.Occupation;

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something\

      if (confirmation == true)
        this.mergeProfile(this.selectedProfile.ID, profile.ID);
      else
        this.disabledSubmit = false;
    });

    return proceed;
  }

  mergeProfile(source: number, target: number) {

    var data = {
      "sourceId": source,
      "targetId": target,
      "mergeReserve": this.mergeSimpan
    };


    var xhr = this.restApi.postRequest(this.constructApi.getMergeProfiles(), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        if (xhr.status == 200) {

          var urls = window.location.pathname.split("/")
          this.location.replaceState(urls[1] + "/" + urls[2]);

          window.location.reload();

        } else
          this.sharedService.openErrorMessage(xhr);
      }

    }
  }

  detectEnableSearch() {
    if (this.searchICNum == "" && this.searchName == "")
      this.disabledSubmit = true;
    else
      this.disabledSubmit = false;

    return this.disabledSubmit;
  }



  findCustomer(name: string, ic: string, leadCustomerID: number) {
    this.disabledSubmit = true;
    this.customerList.splice(0, this.customerList.length);
    if (ic != undefined)
      var icNumberFormat = this.sharedService.sanitizeICNumInput(ic)
    var xhr;
    xhr = this.restApi.getRequest(this.constructApi.getCompanyCustomerSearch(this.selectedCustomer.CompanyId, name, icNumberFormat));

    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;

        if (xhr.status == 200) {

          var jsonArray = JSON.parse((xhr.responseText));

          if (jsonArray.length == 0) {
            this.sharedService.openAlert("Customer not found!",this.enums.DANGER_ALERT)
          }
          else {
            jsonArray.forEach(json => {
              // create customer instance with all response data

              if (Number(json.id) != Number(this.selectedCustomer.ID)) {
                var customer = new GlobalCustomer(json.name, json.icNumber, json.companyCode, json.id, json.status);
                //add customer instance into customer list
                this.customerList.push(customer);
              }

            }
            );

            if (this.customerList.length == 0)
              this.sharedService.openAlert("Do not have any other customer with these search criteria",this.enums.DANGER_ALERT)

          }


        }
        else {
          this.sharedService.openAlert("Error! " + JSON.parse((xhr.responseText)).error,this.enums.DANGER_ALERT)
        }

      }
    }


  }

  getFromLeads() {
    this.disabledSubmit = true;
    this.customerList.splice(0, this.customerList.length);

    var xhr = this.restApi.getRequest(this.constructApi.getCompanyLeads(this.selectedCustomer.CompanyId));

    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;

        if (xhr.status == 200) {

          var jsonArray = JSON.parse((xhr.responseText));

          if (jsonArray.length == 0) {
            this.sharedService.openAlert("Customer not found!",this.enums.DANGER_ALERT)
          }
          else {

            jsonArray.forEach(json => {
              // create customer instance with all response data

              if (Number(json.id) != Number(this.selectedCustomer.ID)) {
                var customer = new GlobalCustomer(json.name, json.icNumber, json.companyCode, json.id, json.status);
                //add customer instance into customer list
                this.customerList.push(customer);
              }

            }
            );

            if (this.customerList.length == 0)
              this.sharedService.openAlert("Do not any leads for this customer",this.enums.DANGER_ALERT)

          }
        }
        else {
          this.sharedService.openAlert("Error! " + JSON.parse((xhr.responseText)).error,this.enums.DANGER_ALERT)
        }

      }
    }
  }

  confirmBeforeMove(customer: GlobalCustomer, isForceMove: boolean) {
    this.disabledSubmit = true;

    var proceed = false;
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Confirm to move profile " +
      this.selectedProfile.ID + "-" + this.selectedProfile.Occupation + " to " + customer.CustomerID + "-" + customer.Name + "(" + customer.ICNumber + ")";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something\

      if (confirmation == true)
        this.moveProfile(this.selectedProfile.ID, customer.CustomerID, isForceMove);
      else
        this.disabledSubmit = false;
    });

    return proceed;
  }

  moveProfile(profileId: number, customerId: number, isForceMove: boolean) {

    var data = {
      "customerId": customerId
    };


    var xhr;
    if (isForceMove == true)
      xhr = this.restApi.putRequest(this.constructApi.getForceMoveProfile(profileId), data);
    else
      xhr = this.restApi.putRequest(this.constructApi.getMoveProfile(profileId), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        if (xhr.status == 200) {

          var urls = window.location.pathname.split("/")
          this.location.replaceState(urls[1] + "/" + customerId);

          window.location.reload();

        } else
          this.sharedService.openErrorMessage(xhr);
      }

    }
  }

  setActive(action: string) {

    if (action == "Merge") {
      if (this.profileList.length > 1) return "active";
    }
    else
      if (this.profileList.length == 1) return "active";
  }
}
