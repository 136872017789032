import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ThumbRangePosition } from 'igniteui-angular-charts';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { CustomerTransactionsComponent } from 'src/app/Customer/customer-transactions/customer-transactions.component';
import { UploadImageComponent } from 'src/app/Images/upload-image/upload-image.component';
import { ViewThumbnailsComponent } from 'src/app/Images/view-thumbnails/view-thumbnails.component';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Customer } from 'src/model/customer.model';
import { Profile } from 'src/model/profile.model';
import { Set } from 'src/model/set.model';
import { Transaction } from 'src/model/transaction.model';
import { AddReceiptSetComponent } from '../add-receipt-set/add-receipt-set.component';
import { ReceiptAddExpenseAdminRemarkComponent } from '../receipt-add-expense-admin-remark/receipt-add-expense-admin-remark.component';
import { TransactionSubType } from 'src/model/transactionSubType.model';
import { ManageTransactionSubtypeComponent } from 'src/app/AddThings/manage-transaction-subtype/manage-transaction-subtype.component';

@Component({
  selector: 'app-receipt-details',
  templateUrl: './receipt-details.component.html',
  styleUrls: ['./receipt-details.component.css']
})
export class ReceiptDetailsComponent implements OnInit {

  @Input() receipt: Set;
  @Input() receiptId: number;
  @Input() isModal = false;
  @Input() expenseSubtypeList: TransactionSubType[];
  @Input() dlSubtypeList: TransactionSubType[];
  checkerRemark: string = "";

  isEditing: boolean = false;
  editedRemark: string = "";

  transactionCustomerIsReady = false;
  transactionCustomer: Customer;


  isDirty: boolean = false;
  constructor(private activeModal: NgbActiveModal, private router: Router, public enums: Enums, public sharedService: SharedService, private restApi: RestApiService, private constructApi: ConstructAPI) { }


  ngOnInit(): void {

    if (this.receiptId != undefined)
      this.getSetDetails();

    if (this.expenseSubtypeList == undefined) {
      this.expenseSubtypeList = new Array;
      this.getTransactionSubTypes(this.enums.EXPENSE);
    }

    if (this.dlSubtypeList == undefined) {
      this.dlSubtypeList = new Array;
      this.getTransactionSubTypes(this.enums.EXTRAS);
    }




  }
  getTransactionSubTypes(type: number) {

    var xhttp = this.restApi.getRequest(this.constructApi.getTransactionSubTypes(type));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let subtype = new TransactionSubType(json.id, json.name, json.type, json.assignable, json.enabled);

          // if (json.assignable == true) {
          if (type == this.enums.EXPENSE)
            this.expenseSubtypeList.push(subtype)
          else if (type == this.enums.EXTRAS)
            this.dlSubtypeList.push(subtype)

          // }

        });

      }
    };



  }

  getSetDetails() {

    var xhttp = this.restApi.getRequest(this.constructApi.getSingleSetDetails(this.receiptId));

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var json = JSON.parse((xhttp.responseText));


        var transactions = this.processSetTransactions(json.transactions);

        this.receipt = new Set(json.id, json.name, json.remark, json.companyId, transactions, json.created_by,
          json.checked_on, json.checker_remark, json.valid, json.checked_by, undefined, json.companyCode, json.saved, json.setRemark, json.tags)


      }
    };
  }

  editRemark(remark: string) {
    this.editedRemark = remark;
    this.isEditing = true;
  }

  processSetTransactions(json: any) {
    var setTransactions: Transaction[] = new Array;

    json.forEach(json => {
      var transcation = new Transaction(json.id, json.remark, json.amount, undefined, undefined, json.date, json.type, json.handleBy,
        json.receiptId, json.customerId, json.customerName, json.customerIC, undefined, json.subtype, json.groupId, json.setId);

      setTransactions.push(transcation);


    }
    );
    return setTransactions;
  }

  reset() {
    this.activeModal.close();
    if (this.isDirty == true)
      window.location.reload();
  }


  findSubtypeName(id: number, type: number) {
    var subtypeList = (type == this.enums.EXTRAS) ? this.dlSubtypeList : this.expenseSubtypeList;

    for (let i = 0; i < subtypeList.length; i++) {
      if (Number(subtypeList[i].ID) == Number(id)) {
        return subtypeList[i].Name;
      }

    }
  }

  loadTransactionCustomer(transactionId: number) {
    this.transactionCustomerIsReady = false;
    var xhttp = this.restApi.getRequest(this.constructApi.getTypedTransaction("loans", transactionId))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        this.transactionCustomerIsReady = true;
        var json = JSON.parse((xhttp.responseText));

        this.transactionCustomer = new Customer(json.customerId, json.customerName, undefined, undefined, undefined, json.icNumber, undefined, undefined, undefined, undefined,
          undefined, undefined, undefined, undefined, undefined, json.companyId, undefined, undefined, undefined, [new Profile(json.profileId, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)])
      }
    };
  }




  viewCustomerTransaction(profileId: number, companyId: number, transaction: Transaction) {
    const modalRef = this.sharedService.openModal(CustomerTransactionsComponent, "largeModal");
    modalRef.componentInstance.profileId = profileId;
    modalRef.componentInstance.companyId = companyId;
    modalRef.componentInstance.startDate = new Date(transaction.Date);
    modalRef.componentInstance.endDate = new Date(transaction.Date);
  }

  goToCustomer(customerId: number) {
    this.activeModal.close();
    this.router.navigate(['/manageCustomer', customerId]);
  }



  calculateTransactionTotal(transactionList: Transaction[]) {
    var total = 0;
    for (let i = 0; i < transactionList.length; i++) {
      total += transactionList[i].Amount;
    }

    return total;

  }


  openAddTransactionToSetModal() {
    var modalRef = this.sharedService.openModal(AddReceiptSetComponent, "largeModal");
    modalRef.componentInstance.setId = this.receipt.ID;

  }

  confirmBeforeDeletingTransaction(transaction: Transaction) {
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Are you sure you want to REMOVE this transaction " + transaction.Remark + "- RM " + transaction.Amount + " from receipt?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;
      if (confirmation == true) {
        this.deleteTransactionsFromSet([transaction.ID])
      }
    });
  }

  confirmBeforeDeletingAllTransactions() {
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Are you sure you want to REMOVE ALL transactions from receipt?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;
      if (confirmation == true) {

        let transactionIdList = this.receipt.TransactionList.map(a => a.ID);
        this.deleteTransactionsFromSet(transactionIdList)
      }
    });
  }

  confirmBeforeValidate() {
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Are you sure you want to VALIDATE this receipt?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;
      if (confirmation == true) {
        this.validateSet();
      }
    });
  }


  confirmBeforeInvalidate() {
    if (this.checkerRemark == "" || this.checkerRemark == undefined) {
      this.sharedService.openAlert("Please fill in remark to explain invalidate reason", this.enums.DANGER_ALERT);
    }
    else {
      const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
      modalRef.componentInstance.message = "Are you sure you want to INVALIDATE this receipt?";

      //get confirmation
      modalRef.componentInstance.passEntry.subscribe((entry) => {
        var confirmation = entry;
        if (confirmation == true) {
          this.invalidateSet();
        }
      });
    }

  }



  deleteTransactionsFromSet(transactionIds: number[]) {
    var data =
    {
      "transactionIds": transactionIds
    };

    var xhr = this.restApi.deleteRequest(this.constructApi.getAddOrRemoveCompanySetTransactions(this.receipt.CompanyId, this.receipt.ID), data);

    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        if (xhr.status == 200) {
          window.location.reload();
        }
        else {
          // this.sharedService.openAlert("Opps, some errors occured. Please try again later");
          this.sharedService.openErrorMessage(xhr);
        }


      }

    }

  }


  submitSet() {

    var xhr = this.restApi.putRequest(this.constructApi.getSubmitSet(this.receipt.CompanyId, this.receipt.ID));

    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        if (xhr.status == 200) {
          window.location.reload();
        }
        else {
          // this.sharedService.openAlert("Opps, some errors occured. Please try again later");
          this.sharedService.openErrorMessage(xhr);
        }


      }

    }

  }

  validateSet() {

    var data = {
      "remark": this.checkerRemark
    };

    var xhr = (this.checkerRemark == undefined || this.checkerRemark == "")
      ? this.restApi.putRequest(this.constructApi.getValidateSet(this.receipt.CompanyId, this.receipt.ID))
      : this.restApi.putRequest(this.constructApi.getValidateSet(this.receipt.CompanyId, this.receipt.ID), data);

    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        if (xhr.status == 200) {
          window.location.reload();

        }
        else {
          // this.sharedService.openAlert("Opps, some errors occured. Please try again later");
          this.sharedService.openErrorMessage(xhr);
        }


      }

    }

  }

  invalidateSet() {
    var data = {
      "remark": this.checkerRemark
    };
    var xhr = this.restApi.putRequest(this.constructApi.getInvalidateSet(this.receipt.CompanyId, this.receipt.ID), data);

    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        if (xhr.status == 200) {
          window.location.reload();
        }
        else {
          // this.sharedService.openAlert("Opps, some errors occured. Please try again later");
          this.sharedService.openErrorMessage(xhr);
        }


      }

    }

  }

  updateRemark() {

    var data = {
      "remark": this.editedRemark
    }

    var xhr = this.restApi.patchRequest(this.constructApi.getEditSet(this.receipt.ID), data);

    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        if (xhr.status == 200) {
          window.location.reload();
        }
        else {
          // this.sharedService.openAlert("Opps, some errors occured. Please try again later");
          this.sharedService.openErrorMessage(xhr);
        }


      }

    }
  }

  viewSetFiles() {

    var modalRef = this.sharedService.openModal(ViewThumbnailsComponent, 'largeModal');
    modalRef.componentInstance.setID = this.receipt.ID;
    modalRef.componentInstance.isValidFile = this.receipt.Valid;

  }
  addEvidenceForSet() {
    var modalRef = this.sharedService.openModal(UploadImageComponent);
    modalRef.componentInstance.isSetDocument = true;
    modalRef.componentInstance.setId = this.receipt.ID;
    this.isDirty = true;

  }

  confirmRemoveBookmark(set: Set) {
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Are you sure you want to REMOVE BOOKMARK for this receipt?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;
      if (confirmation == true) {
        this.removeBookmark(set);
      }
    });
  }

  removeBookmark(set: Set) {
    var data = {
      "saved": false
    }

    var xhttp = this.restApi.putRequest(this.constructApi.getAddSetRemark(set.CompanyId, set.ID), data)


    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        this.reset();
        window.location.reload();


      }
    }
  }


  checkOpenBookmark(set: Set) {
    if (set.Saved == false || set.Saved == undefined) {
      var modalRef = this.sharedService.openModal(ReceiptAddExpenseAdminRemarkComponent);
      modalRef.componentInstance.set = set;

    }
    else {

      this.confirmRemoveBookmark(set);

    }
  }

  openEditSetRemark(set: Set) {
    var modalRef = this.sharedService.openModal(ReceiptAddExpenseAdminRemarkComponent);
    modalRef.componentInstance.set = set;
    modalRef.componentInstance.editMode = true;
  }



  removeSetTag(set: Set, index: number) {

    this.receipt.Tags.splice(index, 1);

    var data = {
      "tags": this.receipt.Tags
    }

    var xhttp = this.restApi.putRequest(this.constructApi.getAddSetRemark(set.CompanyId, set.ID), data)


    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        this.reset();
        window.location.reload();


      }
    }
  }

  confirmRemoveTag(set: Set, tag: string, index: number) {
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Are you sure you want to Tag '" + tag + "' for this receipt?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;
      if (confirmation == true) {
        this.removeSetTag(set, index);
      }
    });
  }

  openManageSubtypeModal(transaction: Transaction) {
    var modalRef = this.sharedService.openModal(ManageTransactionSubtypeComponent);
    modalRef.componentInstance.currentSubtype = transaction.Subtype;
    modalRef.componentInstance.transaction = transaction;
    if (transaction.Type == this.enums.EXPENSE)
      modalRef.componentInstance.subtypeList = this.expenseSubtypeList;
    else
      modalRef.componentInstance.subtypeList = this.dlSubtypeList;
  }
}
