import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Company } from 'src/model/company.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddCompanyComponent } from 'src/app/AddThings/add-company/add-company.component';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { UpdateCompanyComponent } from '../update-company/update-company.component';
import { Enums } from 'src/app/Shared/enums';
import { RestApiService } from 'src/app/API/restapi';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { ManageCompanyAccessComponent } from '../manage-company-access/manage-company-access.component';

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.css']
})
export class CompanyListComponent implements OnInit {
  companyList: Company[] = new Array;

  displayedColumns: string[] = ['CompanyCode'];
  dataSource = new MatTableDataSource;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  loggedInRoleID: number;
  constructor(private router: Router, private modalService: NgbModal, private cdr: ChangeDetectorRef, public enums: Enums, private restapi: RestApiService,
    private constructApi: ConstructAPI, public sharedService: SharedService) { }

  ngOnInit(): void {
    this.getAllCompanies();

    this.loggedInRoleID = Number(sessionStorage.getItem('roleId'));

    //IF LOGGED IN USER IS ADMIN, ADD 'ACTIONS' COLOUMN
    if (this.sharedService.isAdmin() || this.sharedService.isBossAccount() || this.sharedService.isAuditor() || this.sharedService.isFinance()) {
      this.displayedColumns.push("Actions");

    }

  }

  getAllCompanies() {

    // var xhttp = new XMLHttpRequest();
    // var url = "http://localhost:10080/api/v1/companies";
    // xhttp.withCredentials = true;

    var xhttp = this.restapi.getRequest(this.constructApi.getAllCompanies())
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // Typical action to be performed when the document is ready:

        var jsonArray = JSON.parse((xhttp.responseText));
        // console.log(jsonArray);

        jsonArray.forEach(json => {

          // create company instance with all response data
          let company = new Company(json.id, json.name, json.address, json.companyCode, json.contactNo, json.regNo, json.group);

          //add company instance into company list
          this.companyList.push(company);
        }
        );

        this.getUnverifedCustomerCount();
      }
    };

  }


  getUnverifedCustomerCount() {
    var xhttp = this.restapi.getRequest(this.constructApi.getCustomerCount(false))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {

          for (let i = 0; i < this.companyList.length; i++) {
            if (Number(this.companyList[i].ID) == Number(json.companyId))
              this.companyList[i].UnverifiedCutsomerCount = Number(json.count);
          }
        }
        );

        //make company list as the datasource
        this.dataSource = new MatTableDataSource<Company>(this.companyList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSourceSort()

      }
    };
    // xhttp.open("GET", url, true);
    // xhttp.send();

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openAddCompanyDialog() {

    const modalRef = this.sharedService.openModal(AddCompanyComponent);

    //get company that was created
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var company = entry;

      //append added company to company list
      this.companyList.push(company);
      // console.log(JSON.stringify(this.companyList));
    });

    modalRef.result.then(() => {
      // this.cdr.detectChanges();
      this.refreshDataSource();
    })
  }

  confirmDeleteCompany(company: Company) {

    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Are you sure you want to delete this company (" + company.Name + ") ?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something

      if (confirmation == true)
        this.deleteCompany(company);

    });

  }

  deleteCompany(company: Company) {
    // var xhttp = new XMLHttpRequest();
    // var url = "http://localhost:10080/api/v1/company/" + company.ID;
    // xhttp.open("DELETE", url, true);
    // xhttp.withCredentials = true;
    // xhttp.setRequestHeader("Content-type", "application/json");

    var xhttp = this.restapi.deleteRequest(this.constructApi.getDeleteCompany(company.ID))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // Typical action to be performed when the document is ready:
        //  document.getElementById("demo").innerHTML = xhttp.responseText;
        // console.log(this.responseText);


        for (let i in this.companyList) {
          if (this.companyList[i].ID == company.ID) {
            this.companyList.splice(Number(i), 1);
            // this.cdr.detectChanges();

            this.refreshDataSource();

          }
        }


      }
    };

    // xhttp.send();

  }

  openUpdateCompanyModal(company: Company) {
    const modalRef = this.sharedService.openModal(UpdateCompanyComponent)

    //pass value to edit form
    modalRef.componentInstance.companyId = company.ID;
    // modalRef.componentInstance.companyName = company.Name;
    // modalRef.componentInstance.phoneNumber = company.ContactNo;
    // modalRef.componentInstance.address = company.Address;
    // modalRef.componentInstance.companyCode = company.CompanyCode;
    // modalRef.componentInstance.regNum = company.RegNum;

    //get company that was updated
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var updateCompany = entry;
      this.companyList.splice(this.companyList.indexOf(company), 1)
      this.companyList.push(updateCompany)
    });

    modalRef.result.then(() => {
      // console.log("added task")
      // this.cdr.detectChanges();
      this.refreshDataSource();
    })


  }

  refreshDataSource() {
    // this.dataSource = null
    // this.dataSource = new MatTableDataSource<Company>(this.companyList);
    this.dataSource.data = this.companyList;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSourceSort();
  }

  viewCustomerList(companyID: number) {
    this.router.navigate(['/customerList', companyID]);
  }

  viewUnverifiedCustomerList(companyID: number) {
    this.router.navigate(['/unverfiedCustomerList', companyID, false]);
  }


  openCompanyAccessModal(companyID: number,companyCode:string) {
    var modalRef = this.sharedService.openModal(ManageCompanyAccessComponent, "largeModal");
    modalRef.componentInstance.selectedCompany = companyID;
    modalRef.componentInstance.selectedCompanyCode = companyCode;
  }


  dataSourceSort() {

    this.dataSource.sortingDataAccessor = (item, property) => {

      switch (property) {
        case 'Actions':
          //if sort by action column, sort VERIFIED
          if (item["UnverifiedCutsomerCount"] != undefined)
            return item["UnverifiedCutsomerCount"]
          else {
            return 0
          }

        default:
          return item[property];
      }
    };
  }
}
