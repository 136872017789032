import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AddLoanComponent } from 'src/app/AddThings/add-loan/add-loan.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { BatchPaymentInterface } from 'src/model/batchPayment.interface';
import { Customer } from 'src/model/customer.model';
import { Loan } from 'src/model/loan.model';
import { Overlap } from 'src/model/overlap.model';
import { Payment } from 'src/model/payment.model';
import { Profile } from 'src/model/profile.model';

@Component({
  selector: 'app-overlap-component',
  templateUrl: './overlap-component.component.html',
  styleUrls: ['./overlap-component.component.css']
})
export class OverlapComponentComponent implements OnInit {

  @Input() payment: BatchPaymentInterface;
  @Input() availableSimpan: number = 0;
  @Input() selectedCustomer: Customer;
  @Input() selectedProfile: Profile;
  @Input() payWithSimpan: boolean = false;
  @Input() batchDate: Date;

  @Output() passOverlapDetails: EventEmitter<any> = new EventEmitter();

  amount: number = 0;
  stamp: number = 0;
  serviceCharge: number = 0;
  payInterest: boolean = false;
  paymentDate: Date = new Date();


  addedLoanList: Loan[] = new Array;

  constructor(public sharedService: SharedService, private enums: Enums) { }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.batchDate) {
      this.paymentDate = this.batchDate;
    }


  }
  ngOnInit(): void {
  }



  deleteNewLoan(i: number) {
    this.addedLoanList.splice(i, 1);
  }

  openAddLoanDialog() {
    const modalRef = this.sharedService.openModal(AddLoanComponent, "largeModal");
    modalRef.componentInstance.selectedCustomer = this.selectedCustomer;
    modalRef.componentInstance.selectedProfile = this.selectedProfile;
    modalRef.componentInstance.isOverlap = true;
    modalRef.componentInstance.payWithSimpan = this.payWithSimpan;
    modalRef.componentInstance.batchDate = this.batchDate;


    modalRef.componentInstance.passEntry.subscribe((entry) => {

      this.addedLoanList.push(entry[0]);

    });



  }

  resetOverlap(payment: BatchPaymentInterface) {
    payment.isOverlap = false;
    payment.overlapAddedLoan = undefined;
    payment.paymentDetails = undefined;
    payment.totalPayment = 0;
    payment.panelOpenState = false;

  }

  getPaymentColor(totalPayment: number) {
    if (totalPayment == undefined || totalPayment == 0)
      return 'text-primary'
    else
      return 'text-success';
  }


  resetIntAndCharges() {
    this.stamp = 0;
    this.serviceCharge = 0;
    this.payInterest = false;

  }
  calculateTotalPayment() {
    return this.amount + this.stamp + this.serviceCharge + this.calculateIntAfterPart() - this.calculateTotalLoanOnHand() - this.calculateSimpanOut();
  }

  calculateIntAfterPart() {
    var int = 0;
    if (this.payInterest == true)
      int = (this.payment.loan.Balance - this.amount) * (this.payment.loan.InterestRate / 100)

    return int;
  }


  calculateTotalLoanOnHand() {
    var addedLoanOnHand = 0;
    if (this.addedLoanList != undefined && this.addedLoanList.length > 0)
      for (let i = 0; i < this.addedLoanList.length; i++) {
        addedLoanOnHand += this.sharedService.calculateTotalOnHand(this.addedLoanList[i]);
      }

    return addedLoanOnHand;

  }

  calculateSimpanOut() {
    var so = 0;
    if (this.payWithSimpan) {
      so += this.calculateIntAfterPart() + this.stamp + this.serviceCharge;

      for (let i = 0; i < this.addedLoanList.length; i++) {
        so += this.addedLoanList[i].Reserve;
      }
    }

    return so;
  }

  submit() {

    if (this.payment.loan.Balance - this.amount < 0)
      this.sharedService.openAlert("Overlap amount more than the loan amount!", this.enums.DANGER_ALERT);

    else if (this.addedLoanList.length == 0) {
      this.sharedService.openAlert("Please at least open 1 new loan", this.enums.DANGER_ALERT);
    }
    else {

      //INPROGRESS : CALCULATE INTEREST AFTER PRINCIPAL & RESERVE
      var reserve = 0;
      var interestAfterReducePrincipal = this.calculateIntAfterPart();

      this.payment.paymentDetails = new Payment(this.stamp, this.serviceCharge, 0, 0, this.paymentDate, "OVERLAP", [], reserve, 0, this.amount, 0, this.payInterest, interestAfterReducePrincipal);
      this.payment.overlapAddedLoan = this.addedLoanList;
      this.payment.panelOpenState = false;
      // var overlapObj = new Overlap(payment, this.addedLoanList);
      this.passOverlapDetails.emit(this.payment)
      this.sharedService.openAlert("Added OVERLAP payment into queue", this.enums.SUCCESS_ALERT);
    }
  }


}
