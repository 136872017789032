import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Customer } from 'src/model/customer.model';
import { Task } from 'src/model/task.model';
import { NextTaskComponent } from '../follow-next-task/follow-next-task.component';

@Component({
  selector: 'app-due-epf-list',
  templateUrl: './due-epf-list.component.html',
  styleUrls: ['./due-epf-list.component.css'],
  providers: [DatePipe]
})
export class DueEpfListComponent implements OnInit {

  searchText: string;

  sortAsc = true;

  taskList: Task[] = new Array;
  wholeTaskList: Task[] = new Array;


  constructor(private restapi: RestApiService, private constructApi: ConstructAPI, private datepipe: DatePipe, private router: Router, public sharedService: SharedService, private enums: Enums) { }

  ngOnInit(): void {


    // var today = new Date();
    // var endDate = today.setDate(today.getDate() + 30);
    var endDateStr = this.datepipe.transform(new Date(), 'yyyy-MM-dd');

    this.getAllOverdueEpf(endDateStr);
  }



  getAllOverdueEpf(date: string) {
    var xhttp = this.restapi.getRequest(this.constructApi.getTasksForAday(date, this.enums.EPF_TASK))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {

          var task = new Task(json.id, json.customerId, json.description, json.followUpRemark, json.date, json.customerName, undefined, json.dateDone, json.isDone, json.profileId);
          this.taskList.push(task);
          this.wholeTaskList.push(task);
        }

        );
      }
    };
  }


  filterList() {

    if (this.searchText != "") {
      // console.log("search:" + this.searchText);
      this.taskList = this.wholeTaskList.filter((task: Task) => task.CustomerName.toString().toUpperCase().includes(this.searchText.toUpperCase()));
    }

    else
      this.taskList = this.wholeTaskList;
  }


  goCustomerProfile(customerID: number, profileID: number) {
    this.router.navigate(['/manageCustomer', customerID, profileID]);
  }

  openNextTaskModal(selectedTask: Task) {
    var modalRef = this.sharedService.openModal(NextTaskComponent);
    modalRef.componentInstance.task = selectedTask;
    modalRef.componentInstance.isEPF = true;
  }


  addCardStyle(epfDate: Date) {
    var difference_time = new Date(epfDate).getTime() - new Date().getTime();
    var difference_day = difference_time / (1000 * 3600 * 24);

    if (difference_day <= 7) {
      return "urgentEpfCard";
    }

  }

  getStatusColor(epfDate: Date) {
    var difference_time = new Date(epfDate).getTime() - new Date().getTime();
    var difference_day = difference_time / (1000 * 3600 * 24);

    if (difference_day <= 7) {
      return "red";
    }
    else
      return "lightseagreen"

  }

  getColor(epfDate: Date) {
    var difference_time = new Date(epfDate).getTime() - new Date().getTime();
    var difference_day = difference_time / (1000 * 3600 * 24);

    if (difference_day <= 7) {
      return "maroon";
    }
    else
      return "blue"
  }

  calculateDaysLeft(epfDate: Date) {
    var difference_time = new Date(epfDate).getTime() - new Date().getTime();
    var difference_day = difference_time / (1000 * 3600 * 24);
    return difference_day;
  }

}
