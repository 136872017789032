import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TouchHistoryItem } from 'igniteui-angular-core';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { ViewCpDetailsComponent } from 'src/app/CP/view-cp-details/view-cp-details.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { CPDetails } from 'src/model/cpDetails';




@Component({
  selector: 'app-cp-customer-list',
  templateUrl: './cp-customer-list.component.html',
  styleUrls: ['./cp-customer-list.component.css']
})
export class CpCustomerListComponent implements OnInit, OnChanges {

  @Input() companyId: number;

  cpCustomerList: CPDetails[] = new Array;

  displayedColumns: string[];
  dataSource = new MatTableDataSource;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(private restapi: RestApiService, private constructApi: ConstructAPI, private sharedService: SharedService, private enums: Enums) { }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.companyId)
      this.getAllCPCustomer();
  }
  ngOnInit(): void {

    this.displayedColumns = ['CustomerCode', 'CustomerName', 'CustomerIC', 'CustOccupation', 'CaseStatus', 'OfficeAmount'];

  }


  getAllCPCustomer() {
    this.cpCustomerList = [];
    var xhttp = this.restapi.getRequest(this.constructApi.getCompanyAllCPCustomer(this.companyId))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // Typical action to be performed when the document is ready:

        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let customer = new CPDetails(json.customerID, undefined, undefined, json.customerCode, json.customerIC, json.customerName, undefined, undefined, json.caseStatus, undefined,
            json.custOccupation, undefined, undefined, json.officeAmount);

          this.cpCustomerList.push(customer);

        }
        );


        //make customer list as the datasource
        this.dataSource = new MatTableDataSource<CPDetails>(this.cpCustomerList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    };

  }


  calculateTotalOfficeAmount() {
    var total = 0;

    for (let i = 0; i < this.cpCustomerList.length; i++) {
      total += Number(this.cpCustomerList[i].OfficeAmount);
    }

    return total;
  }

  countStatusTotal(status: string) {
    var count = 0;

    for (let i = 0; i < this.cpCustomerList.length; i++) {

      if (this.cpCustomerList[i].CaseStatus == status)
        count++;
    }

    return count;
  }

  openCPDetailsModal(customerId: string) {
    var modalRef = this.sharedService.openModal(ViewCpDetailsComponent, "largeModal");
    modalRef.componentInstance.customerId = customerId;


  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
