<div class="row d-flex justify-content-end align-items-right mt-2">
    <div class="d-flex">
        <h3 class="badge rounded-pill text-bg-warning float-end me-3" style="font-size:1.3rem">
            Receipt: {{selectedLoan.ReceiptNumber}}
        </h3>
    </div>
</div>


<div class="card p-2">
    <div class="card-body">
        <div class="row mb-5">

            <div class="col p-2" style="background-color:lavender ;">
                <mat-slide-toggle class="mat-slide-toggle-lg" [hideIcon]="true" color="primary" [(ngModel)]="isPayForPrincipal"
                    name="isPayForPrincipal " (change)="setPayInterestAfterPrincipal()"
                    [disabled]="selectedLoan.Scheme=='EPF' || selectedLoan.IsEPF==true">
                    <span *ngIf="selectedLoan.Scheme!='EPF'">Pay Principal</span>
                    <span *ngIf="selectedLoan.Scheme=='EPF'">Settlement</span>
                </mat-slide-toggle>
            </div>
        </div>



        <!--If is term payment and there's term left + it's not a HUTANG scheme because Hutang dont have term-->
        <div class="row" *ngIf="isPayForPrincipal==false && maximumTerms.length>0 && selectedLoan.Scheme!='HUTANG'">
            <div class="col-6">
                <div class="card p-1" style="border: 2px solid purple;background-color: plum;">
                    <h4>RM {{paymentAmount |number:'1.0-2'}}</h4>
                </div>
            </div>
            <div class="col-6">
                <select class="browser-default form-select" name="selectedPaymentTerm"
                    [(ngModel)]="selectedPaymentTerm" (change)="calculatePaymentAmount()">
                    <option *ngFor="let term of maximumTerms" [value]="term">
                        {{term}} Terms</option>
                </select>
            </div>
        </div>


        <ng-container
            *ngIf="((selectedLoan.Scheme!='EPF' && selectedLoan.IsEPF!==true )|| (selectedLoan.SettlementStamp!=undefined && selectedLoan.SettlementCharges==undefined)) ;else epfPaymentWithSettlementCharges">

            <!--If user select to pay any amount / the loan is HUTANG loan-->
            <div *ngIf="isPayForPrincipal==true || selectedLoan.Scheme=='HUTANG'">
                <div class="group">
                    <div class="principalInput" *ngIf="selectedLoan.Scheme!='EPF' && selectedLoan.IsEPF!=true">
                        <input type="number" required class="textInputLong" name="amount" [(ngModel)]="principalAmount"
                            min=0 (keypress)="sharedService.isNumberKey($event)">
                        <span class="highlight"></span>
                        <span class="bar barLong"></span>
                        <label>Payment (RM)</label>
                    </div>


                    <h4 *ngIf="selectedLoan.Scheme=='EPF' || selectedLoan.IsEPF==true" style="color:#5264AE"
                        class="p-0 m-0">
                        <strong>Payment = RM
                            {{principalAmount}}</strong>
                    </h4>

                    <h5 style="display:inline"
                        [hidden]="selectedLoan.Scheme=='HUTANG' || selectedLoan.Scheme=='EPF' || selectedLoan.IsEPF==true">
                        <span class="badge rounded-pill text-bg-primary badgeAmount"
                            (click)="updatePaymentAmountAndCalculateStamp()">
                            Monthly: RM {{selectedLoan.MonthlyPayment |number:'1.0-2'}}
                        </span>
                    </h5>
                    <h5 style="display:inline" *ngIf="isPayForPrincipal ==true"
                        [hidden]=" selectedLoan.Scheme=='EPF' || selectedLoan.IsEPF==true">
                        <span class="badge rounded-pill text-bg-success badgeAmount"
                            (click)="principalAmount = selectedLoan.Balance">
                            Settle: RM {{selectedLoan.Balance|number:'1.0-2'}}
                        </span>
                    </h5>
                </div>

                <!--If is A scheme, user can select to pay principal + interest after deducting principal-->
                <div class="row mb-5"
                    *ngIf="principalAmount>0 && selectedLoan.Scheme=='A' && principalAmount<selectedLoan.Balance">
                    <div class="col">
                        <div class="card p-2" style="background-color:lavenderblush ;">
                            <mat-slide-toggle class="mat-slide-toggle-lg" [hideIcon]="true" color="primary"
                                [(ngModel)]="payInterestAfterPrincipal" name="payInterestAfterPrincipal "
                                (change)="calculatePaymentWithInterest()">
                                With Monthly
                            </mat-slide-toggle>
                        </div>

                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #epfPaymentWithSettlementCharges>

            <div class="group" *ngIf="isPayForPrincipal==true && selectedLoan.SettlementCharges!=undefined">
                <input type="number" required class="textInputLong" name="amount" [(ngModel)]="epfLoanCollectedAmount"
                    min=0 (keypress)="sharedService.isNumberKey($event)" (keyup)="calculateAndDistributeCharges()">
                <span class="highlight"></span>
                <span class="bar barLong"></span>
                <label>Collected Amount (RM)</label>
                <h5 style="display:inline">
                    <span class="badge rounded-pill text-bg-success badgeAmount" (click)="settleFullAndDistributeCharges()">
                        Settle: RM
                        {{sharedService.calculateEPFSettlementAmountWithCharges(selectedLoan)|number:'1.0-2'}}
                    </span>
                </h5>
            </div>

            <div class="card mt-1" *ngIf="selectedLoan.SettlementCharges!=undefined">
                <div class="card-body">
                    <table class="table table-borderless">
                        <tbody>
                            <tr class="text-success">
                                <td>Stamp (+) = </td>
                                <td>
                                    <span>RM {{stampAmount| number:'1.0-2'}}</span>
                                </td>
                            </tr>
                            <tr class="text-success">
                                <td>Service Charge (+) = </td>
                                <td>
                                    <span>RM {{scAmount| number:'1.0-2'}}</span>
                            </tr>
                            <tr class="text-success">
                                <td>Duit Lebih (+) = </td>
                                <td>
                                    <span>RM {{extrasAmount | number:'1.0-2'}}</span>
                                </td>
                            </tr>
                            <tr class="text-success" *ngIf="handlingAmount!=0">
                                <td>Handling Charge (+) = </td>
                                <td>
                                    <span>RM {{handlingAmount | number:'1.0-2'}}</span>
                                </td>
                            </tr>
                            <tr class="text-success">
                                <td>Repayment (+) = </td>
                                <td>
                                    <span>RM {{selectedLoan.Balance - discount | number:'1.0-2'}}</span>
                                </td>
                            </tr>
                            <tr *ngIf="discount>0" class="text-danger">
                                <td>Discount (-) = </td>
                                <td>RM {{discount| number:'1.0-2'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


        </ng-template>

        <!--HUTANG cannot have charges other than principal since it's a no interest scheme already-->
        <!--make sure it's not a HUTANG scheme to allow key in extra charges-->
        <div [hidden]="selectedLoan.Scheme=='HUTANG' || selectedLoan.SettlementCharges!=undefined">
            <div class="row mb-4">
                <div class="col-6">
                    <!-- STAMP-->
                    <div><span style="font-size: 14px;color: #5264AE;">Stamp</span></div>
                    <div>
                        <input class="textInputShort" type="number" name="stampAmount" [(ngModel)]="stampAmount" min="0"
                            (keypress)="sharedService.isNumberKey($event)">
                        <span class="text-danger" *ngIf="selectedLoan.Scheme=='EPF'">Settlement
                            Stamp: RM
                            {{selectedLoan.SettlementStamp}}</span>
                    </div>
                </div>

                <div class="col-6">
                    <!-- SERVICE CHARGE-->
                    <div><span style="font-size: 14px;color: #5264AE;">Service Charge</span></div>
                    <div>
                        <input class="textInputShort" type="number" name="scAmount" [(ngModel)]="scAmount" min="0"
                            (keypress)="sharedService.isNumberKey($event)">
                        <span class="text-danger" *ngIf="selectedLoan.Scheme=='EPF'">Settlement
                            SC:
                            RM
                            {{selectedLoan.SettlementServiceCharge}}</span>
                    </div>
                </div>

            </div>

            <div class="row">

                <div class="col-12">
                    <!-- RESERVE-->
                    <div class="group">
                        <input type="number" required class="textInputShort" name="reserve" [(ngModel)]="reserve" min=0
                            (keypress)="sharedService.isNumberKey($event)">
                        <span class="highlight"></span>
                        <span class="bar barShort"></span>
                        <label>Simpan</label>
                    </div>
                </div>
                <div class="col-12 mb-3">
                    <app-added-duit-lebih-list (getDuitLebihData)="getDuitLebihData($event)">
                    </app-added-duit-lebih-list>
                </div>

                <div class="col-12">
                    <div class="card shadow-md ">
                        <div class="card-header"> Other Chagres</div>
                        <div class="card-body">

                            <div class="row">
                                <div class="col-12">
                                    <!-- ARREAR CHARGES-->
                                    <div class="group">
                                        <input type="number" required class="textInputShort" name="otherCharges"
                                            [(ngModel)]="otherCharges" min=0
                                            (keypress)="sharedService.isNumberKey($event)">
                                        <span class="highlight"></span>
                                        <span class="bar barShort"></span>
                                        <label>Arrear Charges</label>
                                    </div>
                                </div>
                                <div class="col-12" *ngIf="selectedLoan.Scheme!='A'">
                                    <!-- DISCOUNT-->
                                    <div class="row">
                                        <div class="col-2 me-5">
                                            <div class="group">
                                                <input type="number" required class="textInputShort" name="discount"
                                                    [(ngModel)]="discount" min=0
                                                    (keypress)="sharedService.isNumberKey($event)"
                                                    (change)="changeDiscountAmount(selectedDiscountType,paymentAmount)">
                                                <span class="highlight"></span>
                                                <span class="bar barShort"></span>
                                                <label>Discount</label>
                                            </div>
                                        </div>
                                        <div class="col-3 ms-5 d-flex justify-content-start align-items-start">
                                            <mat-button-toggle-group name="discountType" aria-label="Discount Type"
                                                [(ngModel)]="selectedDiscountType"
                                                (change)="changeDiscountAmount(selectedDiscountType,paymentAmount)">
                                                <mat-button-toggle value="%">%</mat-button-toggle>
                                                <mat-button-toggle value="RM">RM</mat-button-toggle>
                                            </mat-button-toggle-group>
                                        </div>
                                    </div>
                                    <div>
                                        <span class="text-danger fw-bold"
                                            [hidden]="selectedDiscountType=='RM'">Discounted
                                            Amount: {{discountAmount}}</span>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

            </div>
        </div>





        <!--Total Payment Summary-->
        <div [hidden]="selectedLoan.Scheme=='EPF' &&  selectedLoan.SettlementCharges !=undefined">
            <h3 class="mt-1" style="color: purple;font-weight: bold;">Total: RM
                {{calculatePaidTotal() |number:'1.2-2'}}</h3>
            <h6 style="color:red;font-weight:bold;font:1rem" *ngIf="isPayForPrincipal">* Pay for Principal*</h6>
            <h6 style="color:salmon;font-weight:bold;font:0.5rem" *ngIf="payInterestAfterPrincipal==true">Part: RM
                {{principalAmount}} | Interest : RM {{interestAfterReducePrincipal}} ({{selectedLoan.InterestRate}}%)
            </h6>

            <div>
                <span class="text-info fw-bold" *ngIf="reserve>0">
                    RM {{reserve}} will go into simpan</span>
            </div>
        </div>

    </div>
</div>


<!--Actions-->
<mat-form-field class="full-width-formField bar" *ngIf="batchDate==undefined">
    <input matInput class="textInputLong" [max]="maxDate" [matDatepicker]="myDatepicker" name="paymentDate"
        [(ngModel)]="paymentDate" placeholder="Payment Date" onkeydown="return false">
    <mat-datepicker-toggle matSuffix [for]="myDatepicker" [disabled]="batchDate!=undefined"></mat-datepicker-toggle>
    <mat-datepicker #myDatepicker name="paymentDate"></mat-datepicker>
</mat-form-field>

<div class="group">
    <input type="text" required class="textInputLong" name="paymentRemark" [(ngModel)]="paymentRemark">
    <span class="highlight"></span>
    <span class="bar barLong"></span>
    <label>Payment Remark</label>
</div>

<div class="row">
    <div class="col d-flex justify-content-end align-items-right">
        <button class="btn btn-success" (click)="submit()"> Confirm</button>
        <button class="btn btn-danger ms-2"> Reset</button>
    </div>
</div>