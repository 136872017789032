<form class="rowRedBlue">
  <!-- Modal Header -->
  <!-- <div class="modal-header">
    <h4 class="modal-title">Add Asset</h4>
    <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()" aria-label="Close"></button>
  </div> -->

  <div class="modal-body p-0">
    <div class="row">
      <div class="col rowRedBlue" style="padding: 0;">
        <div class="card curveCard">
          <div class="card-div" style="height: 180px;">
            <div style="margin-left: 7%;">
              <div class="row">
                <div class="col-3 d-flex justify-content-center align-items-center">
                  <figure class="figure"><img class="img-fluid figure-img contactAvatar"
                      src="../../assets/image/man-avatar.png">
                  </figure>
                </div>
                <div class="col">
                  <h4 style="color: rgb(255,255,255);font-family: Aldrich, sans-serif;">TONY STARK</h4>
                  <h6 class="text-secondary mb-2">DIRECTOR OF STARK INDUSTRIES</h6>
                  <div>
                    <a class="btn btnContact" type="button" style="background-color: rgba(255,210,204,0.58);" href="https://wa.me/+60149267965">
                      <i class="fab fa-whatsapp" style="font-size: 29px;" ></i></a>
                    <a class="btn btnContact" type="button" style="background-color: rgba(255,210,204,0.58);" href="tel:+60149267965"> 
                      <i class="fa fa-phone" style="font-size: 29px;"></i></a>
                    <a class="btn btnContact" type="button" style="background-color: rgba(255,210,204,0.58);"  href="mailto:mewindng@gmail.com">
                      <i class="far fa-envelope" style="font-size: 29px;"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>