<div class="content">
    <div class="card shadow-lg my-3" style="background-color: rgb(248, 231, 233);">
        <div class="card-body">
            <div class="group mt-3">
                <input type="number" required class="textInputLong" name="adsDuitLebih" [(ngModel)]="adsDuitLebih" min=0
                    (keypress)="sharedService.isNumberKey($event)" (ngModelChange)="updateAdsDuitLebih()">
                <span class="highlight"></span>
                <span class="bar barLong"></span>
                <label>ADS DUIT LEBIH</label>

                <span class="badge rounded-pill  me-3" style="font-size: 15px;cursor:pointer"
                    [ngClass]="{'bg-danger':adsDuitLebih==undefined || adsDuitLebih<calculateMinimumAds(),'bg-success':adsDuitLebih>=calculateMinimumAds()}"
                    (click)="setMinimumAds()">
                    <span [hidden]="isEPF==false">Minimum Amount:</span><span [hidden]="isEPF==true">Suggested
                        Amount:</span> RM {{ calculateMinimumAds() }}
                </span>
            </div>
        </div>
    </div>
    <div [hidden]="isEPF==true">
        <button class="btn btn-primary float-end" (click)="openAddDuitLebihModal()"><i class="fas fa-plus"></i> Add
            Duit Lebih</button>
    </div>


    <div class="mat-elevation-z8" [hidden]="isEPF==true">
        <table mat-table [dataSource]="dataSource">

            <!-- Amount Column -->
            <ng-container matColumnDef="Amount">
                <th mat-header-cell *matHeaderCellDef> Amount </th>
                <td mat-cell *matCellDef="let duitLebih;let i=index"> RM
                    {{duitLebih.Amount}} </td>
            </ng-container>

            <!-- SubType Column -->
            <ng-container matColumnDef="SubType">
                <th mat-header-cell *matHeaderCellDef> SubType </th>
                <td mat-cell *matCellDef="let duitLebih;let i=index" style="cursor: pointer;">
                    <span *ngIf="duitLebih.Subtype!=undefined"> {{ findTransactionSubTypeName(duitLebih.Subtype)}}
                    </span>
                </td>
            </ng-container>

            <!--Action Column-->
            <ng-container matColumnDef="Action">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let duitLebih;let i=index" style="cursor: pointer;" style="cursor: pointer;">
                    <i class="fas fa-times" style="cursor: pointer;" (click)="removeDuitLebih(i)"></i>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>