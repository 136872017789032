import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';

@Component({
  selector: 'app-elevate-confirmation',
  templateUrl: './elevate-confirmation.component.html',
  styleUrls: ['./elevate-confirmation.component.css']
})
export class ElevateConfirmationComponent implements OnInit {

  constructor(public sharedService: SharedService, private enums: Enums, private activeModal: NgbActiveModal) { }

  @Input() message: string;
  username: string;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {

    if (this.message == undefined)
      this.message = "WARNING! You are going to do something out of limit!"

  }


  compareUsername() {
    if (this.username == sessionStorage.getItem("username"))
      return true;
    else return false;

  }

  setConfirmation(bool: boolean) {
    if (bool == true) {
      if (this.compareUsername() == true) {

        this.passEntry.emit(true);
        this.activeModal.close();
      }
      else
        this.sharedService.openAlert("Wrong username entered!", this.enums.DANGER_ALERT);
    }
    else {
      this.passEntry.emit(false);
      this.activeModal.close();
    }

  }
}
