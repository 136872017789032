<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Add Loan Into Account</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 aria-label="Close" aria-label="Close"
            (click)="closeModal()"></button>
    </div>

    <div class="modal-body">
        <div class="card mb-2 p-2">
            <div class="card-header"><b>Summary</b></div>
            <div class="card-body">
                <span><strong>Remark: {{transactionRequest.Remark}}</strong></span>
                <table class="table table-bordered table-custom p-0 m-0">
                    <thead>
                        <tr>
                            <th> Type </th>
                            <th> Amount</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let item of transactionRequest.TransactionItems">
                            <td> {{ enums.transactionTypeString[item.type-1] }}</td>
                            <td> RM {{item.amount}}</td>
                        </tr>
                    </tbody>

                    <tfoot>
                        <tr>
                            <th></th>
                            <th>RM {{calculateTotal()}}</th>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <hr />

            <div class="row">

                <div class="col-12">
                    <div>
                        <span>Account Date</span><i class="far fa-calendar-alt ms-2"></i>
                    </div>
                    <mat-form-field class="full-width-formField p-0">
                        <input matInput readonly [matDatepicker]="accountDatePicker" name="accountDate" [(ngModel)]="accountDate"
                            required [max]="sharedService.getTodayDate()" onkeydown="return false">
                        <mat-datepicker-toggle matSuffix [for]="accountDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #accountDatePicker name="accountDate"></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="col-12">
                    <h5>Issued by account:</h5>
                </div>
                <div class="col-12">
                    <select class="browser-default form-select" name="selectedAccount"
                        [(ngModel)]="selectedAccountId">
                        <option *ngFor="let account of companyAccountList" [value]="account.ID"
                            [selected]="setSelectedAccount(account.ID)"
                            [disabled]="isBalanceInsufficient(account.Balance)">
                            {{account.Name}} (RM
                            {{account.Balance || 0}} )
                        </option>
                    </select>
                </div>


            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="submit()" [disabled]="disabledSubmit">Add
            Transaction</button>
        <button class="btn btn-outline-danger" type="button" (click)="closeModal()">Cancel</button>
    </div>
</form>