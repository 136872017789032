import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { AlertDialogComponent } from 'src/app/Shared/alert-dialog/alert-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Account } from 'src/model/account.model';
import { Advance } from 'src/model/advance.modal';
import { Loan } from 'src/model/loan.model';
import { User } from 'src/model/user.model';

@Component({
  selector: 'app-add-commissions',
  templateUrl: './add-commissions.component.html',
  styleUrls: ['./add-commissions.component.css']
})
export class AddCommissionsComponent implements OnInit {
  @Input() companyAccountList: Account[] = new Array;

  selectedAccountId: number;
  selectedUser: string;
  loggedInCompanyId: number;

  @Input() selectedLoan: Loan;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  //2-WAY BINDING INFO
  date: Date;
  remark: string;
  staffAmount: number = 0;
  otherAmount: number = 0;
  userList: User[] = new Array;

  disabledSubmit = false;

  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private restapi: RestApiService, private constructApi: ConstructAPI,
    private enums: Enums, public sharedService: SharedService) {
    this.date = new Date();
  }

  ngOnInit(): void {

    this.loggedInCompanyId = Number(sessionStorage.getItem("companyId"));

    this.getAllUsersOfCompany();
    this.getAllAccountsOfCompany(this.loggedInCompanyId);
  }

  private getAllAccountsOfCompany(companyId: number) {

    var xhttp = this.restapi.getRequest(this.constructApi.getAccountsOfCompany(companyId));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let account = new Account(json.id, json.number, json.name, json.holderName, json.balance, json.companyId, json.username);

          var loggedInUsername = sessionStorage.getItem("username");
          if (json.username == loggedInUsername || json.username == undefined)
            this.companyAccountList.push(account);

        }
        );

      }
    };

  }


  setSelectedAccount(accountId: number) {

    if (accountId === this.selectedAccountId)
      return true
    else
      return false;
  }

  setSelectedUser(username: string) {

    if (username === this.selectedUser)
      return true
    else
      return false;
  }

  private getAllUsersOfCompany() {
    var xhttp = this.restapi.getRequest(this.constructApi.getAllUsers(undefined, undefined, true))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {

          var user = new User(null, json.companyId, json.companyName, json.roleId, json.roleName, json.username, null)
          this.userList.push(user);

        }
        );
      }
    };
  }


  isBalanceInsufficient(balance: number) {
    if (balance > this.staffAmount + this.otherAmount)
      return false;
    else
      return true;
  }

  isFieldNull() {
    var staffPaymentNull = (this.staffAmount == undefined || this.staffAmount == 0);
    if (this.otherAmount != 0 && this.otherAmount != undefined) {
      var remarkNull = (this.remark == undefined || this.remark == "");
      var selectedAccountIdNull = (this.selectedAccountId == undefined)
    }
    else {
      var remarkNull = false;
      var selectedAccountIdNull = false;
    }
    var selectedUserNull = (this.selectedUser == undefined);
    var paymentValid = isNaN(+this.staffAmount)

    if (staffPaymentNull == true && this.otherAmount != 0 && this.otherAmount != undefined)
      return remarkNull || selectedAccountIdNull || paymentValid;
    else
      return remarkNull || selectedAccountIdNull || selectedUserNull || staffPaymentNull || paymentValid;
  }

  confirmBeforeAddCommission() {

    var proceed = false;
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);

    if (this.otherAmount == 0 || this.otherAmount == undefined)
      modalRef.componentInstance.message = "You are going to assign RM " + this.staffAmount + " to " + this.selectedUser + "\n.Are you sure?";
    else {
      if (this.staffAmount != 0 && this.staffAmount != undefined)
        modalRef.componentInstance.message = "You are going to assign RM " + this.staffAmount + " to " + this.selectedUser + " and RM " + this.otherAmount + " as " + this.remark + ". \nAre you sure?";
      else
        modalRef.componentInstance.message = "You are going to assign RM " + this.otherAmount + " as " + this.remark + ". \nAre you sure?";
    }

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something

      if (confirmation == true) {
        if (this.staffAmount > 0 && this.staffAmount != undefined)
          this.addStaffCommission();
        else
          this.addOtherCommission();
      }

    });

    return proceed;

  }

  addOtherCommission() {
    var isoTaskDate = (new Date(Number(this.date))).toISOString();

    var data = {
      "amount": this.otherAmount,
      "accountId": Number(this.selectedAccountId),
      "date": isoTaskDate,
      "remark": this.remark
    };


    var xhr = this.restapi.postRequest(this.constructApi.getAddCompanyExpenseByExtras(), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        // var json = JSON.parse(xhr.responseText);

        if (xhr.status == 200) {
          let account = new Account(this.selectedAccountId, null, null, null, null, null, null, -this.otherAmount)
          // this.passEntry.emit(account)
          window.location.reload();

          this.reset();
          this.sharedService.openAlert("Successfully added commission by using duit lebih", this.enums.SUCCESS_ALERT);
        } else
          this.sharedService.openErrorMessage(xhr);

      }
    }
  }

  confirmBeforeLeaving() {

    var proceed = false;
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "You are going to leave without assigning any commission, are you sure?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something

      if (confirmation == true)
        this.reset();
    });

    return proceed;

  }

  addStaffCommission() {

    this.disabledSubmit = true;

    var isoTaskDate = (new Date(Number(this.date))).toISOString();

    var data = {
      "amount": this.staffAmount,
      "dateCreated": isoTaskDate,
      "assignee": this.selectedUser
    };


    var xhr = this.restapi.postRequest(this.constructApi.getAssignLoanCommission(this.selectedLoan.ID), data);
    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        if (xhr.status == 200) {

          //after assigning staff commission, add other commission

          if (this.otherAmount > 0)
            this.addOtherCommission();
          else {
            window.location.reload();
            this.reset();
          }

          this.sharedService.openAlert("Successfully added commission!", this.enums.SUCCESS_ALERT);

        } else
          this.sharedService.openErrorMessage(xhr);
      }

    }
  }

  submit() {

    (this.isFieldNull()) ? this.sharedService.openAlert('Please fill in all the fields', this.enums.DANGER_ALERT) : this.confirmBeforeAddCommission();
  }

  reset() {
    this.activeModal.close();
    window.location.reload();
  }


}
