<div class="container-fluid" style="padding: 2%;">
    <div class="row">
        <div class="col">
            <div class="table table-borderless">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body" style="border: 1.5px solid purple;">
                                <mat-form-field class="full-width-formField bar">
                                    <input matInput class="textInputLong " [matDatepicker]="myDatepicker" name="date"
                                        [(ngModel)]="date" placeholder="Date" [max]="sharedService.getTodayDate()"
                                        onkeydown="return false">
                                    <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                                    <mat-datepicker #myDatepicker name="expenseDate"></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>

                    </div>
                </div>


                <table class="table table-borderless">
                    <tbody>
                        <tr>
                            <td><strong>Remark</strong></td>
                            <td><input type="text" [(ngModel)]="remark" name="remark">
                            </td>
                        </tr>
                        <tr>
                            <td><strong>Amount</strong></td>
                            <td><input type="number" [(ngModel)]="amount" name="amount"
                                    (keypress)="sharedService.isNumberKey($event)"></td>
                        </tr>
                        <tr>
                            <td><strong>Subtype</strong></td>
                            <td>
                                <mat-form-field appearance="fill">
                                    <mat-label>SubType</mat-label>
                                    <mat-select [(ngModel)]="subtype" name="subtype">
                                        <mat-option *ngFor="let type of subtypeList" [value]="type.ID">
                                            {{type.Name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row">
                <div class="col d-flex justify-content-end" style="padding: 0;margin-bottom: 2%;" >
                    <button class="btn btn-success" type="button" (click)="submit()">Add To List</button>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <h1 style="font-family: Aldrich, sans-serif;"></h1>
        </div>
    </div>
    <div class="row" style="margin: 0;" *ngIf="transactionList.length>0">
        <div class="col p-0">
            <div class="card shadow-lg">
                <div class="card-body">
                    <h4 class="card-title">Transaction List<br></h4>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="text-center">Remark</th>
                                <th class="text-center">Amount(RM)</th>
                                <th class="text-center">SubType</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let transaction of transactionList;let i=index">
                                <td class="text-center">{{transaction.remark}}</td>
                                <td class="text-center">{{transaction.amount}}</td>
                                <td class="text-center">{{getSubTypeName(transaction.subtype)}}
                                </td>
                                <td class="text-center">
                                    <i class="fas fa-times" style="cursor: pointer;" (click)="removeTransaction(i)"></i>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th class="text-center">Total: RM</th>
                                <th class="text-center">{{calculateTotalAmount()}}</th>
                                <th class="text-center"></th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>