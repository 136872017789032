<div class="container-fluid" style="padding: 5%;">
  <div class="row mb-3">
      <input type="text" name="search" placeholder="🔍 Search.." style="width:100%">
  </div>
  <div class="row">
    <div class="col rowRedBlue" style="padding: 0;">
      <div class="card curveCard">
        <div class="card-div" style="height: 180px;">
          <div style="margin-left: 7%;">
            <div class="row">
              <div class="col-3 d-flex justify-content-center align-items-center">
                <figure class="figure"><img class="img-fluid figure-img contactAvatar"
                    src="../../assets/image/man-avatar.png">
                </figure>
              </div>
              <div class="col">
                <h4 style="color: rgb(255,255,255);font-family: Aldrich, sans-serif;">TONY STARK</h4>
                <h6 class="text-secondary mb-2">DIRECTOR OF STARK INDUSTRIES</h6>
                <div>
                  <a class="btn btnContact" type="button" style="background-color: rgba(255,210,204,0.58);"
                    href="https://wa.me/+60149267965">
                    <i class="fab fa-whatsapp" style="font-size: 29px;"></i></a>
                  <a class="btn btnContact" type="button" style="background-color: rgba(255,210,204,0.58);"
                    href="tel:+60149267965">
                    <i class="fa fa-phone" style="font-size: 29px;"></i></a>
                  <a class="btn btnContact" type="button" style="background-color: rgba(255,210,204,0.58);"
                    href="mailto:mewindng@gmail.com">
                    <i class="far fa-envelope" style="font-size: 29px;"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col rowRedOrange" style="padding: 0;">
      <div class="card curveCard" style="height: 180px;">
        <div class="card-div">
          <div style="margin-left: 7%;">
            <div class="row">
              <div class="col-3 d-flex justify-content-center align-items-center">
                <figure class="figure"><img class="img-fluid figure-img contactAvatar"
                    src="../../assets/image/woman-avatar.png">
                </figure>
              </div>
              <div class="col">
                <h4 style="color: rgb(255,255,255);font-family: Aldrich, sans-serif;">NATASHA ROMANOFF</h4>
                <h6 class="text-secondary mb-2">BLACK WIDOW</h6>
                <div><button class="btn btnContact" type="button" style="background-color: rgba(255,210,204,0.58);"><i
                      class="fab fa-whatsapp" style="font-size: 29px;"></i></button><button class="btn btnContact"
                    type="button" style="background-color: rgba(255,210,204,0.58);"><i class="fa fa-phone"
                      style="font-size: 29px;"></i></button>
                  <button class="btn btnContact" type="button" style="background-color: rgba(255,210,204,0.58);"><i
                      class="far fa-envelope" style="font-size: 29px;"></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col rowRedBlue" style="padding: 0;">
      <div class="card curveCard" style="height: 180px;">
        <div class="card-div">
          <div style="margin-left: 7%;">
            <div class="row">
              <div class="col-3 d-flex justify-content-center align-items-center" style="height: 70%;">
                <figure class="figure"><img class="img-fluid figure-img contactAvatar"
                    src="../../assets/image/male-avatar2.jpg">
                </figure>
              </div>
              <div class="col" style="height: 100%;">
                <h4 class="d-flex" style="color: rgb(255,255,255);font-family: Aldrich, sans-serif;">STEVE GRANT</h4>
                <h6 class="text-secondary d-flex mb-2">CAPTAIN AMERICA</h6>
                <div><button class="btn btnContact" type="button" style="background-color: rgba(255,210,204,0.58);"><i
                      class="fab fa-whatsapp" style="font-size: 29px;"></i></button><button class="btn btnContact"
                    type="button" style="background-color: rgba(255,210,204,0.58);"><i class="fa fa-phone"
                      style="font-size: 29px;"></i></button>
                  <button class="btn btnContact" type="button" style="background-color: rgba(255,210,204,0.58);"><i
                      class="far fa-envelope" style="font-size: 29px;"></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>