<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Add Profile</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()" aria-label="Close"></button>
    </div>

    <div class="modal-body">
        <div class="container-fluid p-0">
            <div class="mt-2">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <p>Date Created</p>
                            </div>
                            <div class="col-12">
                                <mat-form-field class="full-width-formField p-0">
                                    <input matInput readonly [matDatepicker]="dateCreatedPicker" name="dateCreated"
                                        [(ngModel)]="dateCreated" required>
                                    <mat-datepicker-toggle matSuffix [for]="dateCreatedPicker"></mat-datepicker-toggle>
                                    <mat-datepicker #dateCreatedPicker name="dateCreated"></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col col-12">
                                <div><span>Customer Code</span></div>
                                <div><input type="text" [(ngModel)]="customerCode" name="customerCode" required
                                        (focusout)="validField('customerCode')"></div>
                            </div>
                            <div class="col col-12 col-md-6">
                                <div><span>Company Name</span></div>
                                <div><input type="text" required [(ngModel)]="custOccupation" name="custOccupation"
                                        (focusout)="validField('custOccupation')">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-12 col-md-6">
                                <div><span>Salary</span></div>
                                <div><input type="number" required [(ngModel)]="salaryAmount" name="salaryAmount"
                                        (focusout)="validField('salaryAmount')" min=1000
                                        (keypress)="sharedService.isNumberKey($event)">
                                </div>
                            </div>
                            <div class="col col-12 col-md-6">
                                <div><span>Salary Day</span></div>
                                <div><input type="number" required [(ngModel)]="salaryDay" name="salaryDay" min=1 max=31
                                        (keypress)="sharedService.isNumberKey($event)"></div>
                                <span class="text-danger">Between (1-31). If more than 1, fill another at
                                    Description</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div><span>Description</span></div>
                                <div><textarea class="form-control-lg" required [(ngModel)]="remark" name="remark"
                                        (focusout)="validField('remark')"></textarea></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="submit()" [disabled]="disabledSubmit">Add
            Profile</button>
        <button class="btn btn-outline-danger" type="button" data-dismiss="modal" (click)="reset()">Cancel</button>
    </div>
</form>