import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { DeleteReasonComponent } from 'src/app/Others/delete-reason/delete-reason.component';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';


interface CheckIn {
  companyId: number,
  date: Date,
  id: number,
  remark: string,
  username: string,
  dateCreated: Date
}


interface DeletedCheckInRecord {
  companyId: number,
  date: Date,
  id: number,
  dateCreated: Date,
  deleteBy: string,
  deleteReason: string,
  checkInCreatedBy: string

}

interface DeletedCheckIn {
  deletedOn: Date,
  deletedList: DeletedCheckInRecord[]
}

@Component({
  selector: 'app-check-in-history',
  templateUrl: './check-in-history.component.html',
  styleUrls: ['./check-in-history.component.css']
})
export class CheckInHistoryComponent implements OnInit {

  @Input() selectedCompanyId: number;
  @Input() selectedCompanyCode: number;
  @Input() selectedRole: number;

  isCollapsed = true;
  isDirty = false;

  checkInList: CheckIn[] = new Array;

  deletedCheckInList: DeletedCheckIn[] = new Array;

  constructor(private activeModal: NgbActiveModal, private restApi: RestApiService, private constructApi: ConstructAPI, private router: Router,
    public sharedService: SharedService, public enums: Enums, private cdr: ChangeDetectorRef) {

  }

  ngOnInit(): void {

    this.getCheckInHistory();
    this.getDeletedCheckInHistory();
  }

  getCheckInHistory() {
    var xhttp = this.restApi.getRequest(this.constructApi.getCompanyCheckInHistory(this.selectedCompanyId, this.selectedRole))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // Typical action to be performed when the document is ready:

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {

          var checkIn = { companyId: json.companyId, date: json.date, id: json.id, remark: json.remark, username: json.username, dateCreated: json.dateCreated };

          this.checkInList.push(checkIn);
        }
        );

      }
    };

  }


  getDeletedCheckInHistory() {
    var xhttp = this.restApi.getRequest(this.constructApi.getCompanyDeletedCheckInHistory(this.selectedCompanyId))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // Typical action to be performed when the document is ready:

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          var deletedOn = new Date(json.delete_on);
          var formattedDeleteOn = new Date(deletedOn.getFullYear(), deletedOn.getMonth(), deletedOn.getDate(), 0, 0, 0);
          var deletedRecord = { companyId: json.companyId, date: json.date, id: json.id, dateCreated: json.dateCreated, deleteBy: json.delete_by, deleteReason: json.delete_reason, checkInCreatedBy: json.username };

          var index = this.deletedCheckInList.findIndex(deleted => deleted.deletedOn.getFullYear() == formattedDeleteOn.getFullYear() && deleted.deletedOn.getMonth() == formattedDeleteOn.getMonth() && deleted.deletedOn.getDate() == formattedDeleteOn.getDate());

          if (index == -1) {
            var deletedCheckIn = { deletedOn: formattedDeleteOn, deletedList: [deletedRecord] }
            this.deletedCheckInList.push(deletedCheckIn);

          }
          else {
            this.deletedCheckInList[index].deletedList.push(deletedRecord)
          }
        }
        );

      }
    };

  }

  goToListHutang(date: Date) {
    var dateStr = new Date(date).toISOString();
    this.router.navigate(['/listHutang', this.selectedCompanyId, dateStr]);
    this.closeModal();
  }

  closeModal() {
    if (this.isDirty == true)
      window.location.reload();
    this.activeModal.close();
  }


  confirmDeleteSinceDateCheckIn(companyId: number, checkInDate: Date) {
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Confirm to delete checkin since date " + checkInDate + "?";


    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;
      if (confirmation == true) {
        const modalRef = this.sharedService.openModal(DeleteReasonComponent);
        modalRef.componentInstance.passEntry.subscribe((entry) => {
          var remark = entry;

          //append added account to account list
          this.deleteCheckInSinceDate(remark, companyId, checkInDate);
        });
      }
    });
  }


  confirmBeforeDeleteCheckIn(companyId: number, checkInId: number, indexInList: number) {
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Confirm to delete checkin ?";


    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;
      if (confirmation == true) {
        const modalRef = this.sharedService.openModal(DeleteReasonComponent);
        modalRef.componentInstance.passEntry.subscribe((entry) => {
          var remark = entry;

          //append added account to account list
          this.deleteCheckIn(remark, companyId, checkInId, indexInList);
        });
      }
    });

  }

  deleteCheckIn(remark: string, companyId: number, checkInId: number, indexInList: number) {
    var data =
    {
      "reason": remark
    };

    var xhr = this.restApi.deleteRequest(this.constructApi.getDeleteCheckIn(companyId, checkInId), data);

    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        if (xhr.readyState == 4 && xhr.status == 200) {

          if (xhr.status == 200) {
            this.isDirty = true;
            this.checkInList.splice(Number(indexInList), 1);
            this.cdr.detectChanges();
          }
          else {
            this.sharedService.openAlert("Opps, some errors occured. Please try again later", this.enums.DANGER_ALERT);
          }

        }
      }

    }
  }


  deleteCheckInSinceDate(remark: string, companyId: number, checkInDate: Date) {
    var data =
    {
      "reason": remark
    };

    var xhr = this.restApi.deleteRequest(this.constructApi.getDeleteCheckInSinceDate(companyId, checkInDate), data);

    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        if (xhr.readyState == 4 && xhr.status == 200) {

          if (xhr.status == 200) {
            window.location.reload();
          }
          else {
            this.sharedService.openAlert("Opps, some errors occured. Please try again later", this.enums.DANGER_ALERT);
          }

        }
      }

    }
  }
}
