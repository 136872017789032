import { Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Task } from 'src/model/task.model';
import { IgxCalendarComponent } from 'igniteui-angular';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { CreateNewTaskComponent } from '../../AddThings/create-new-task/create-new-task.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Customer } from 'src/model/customer.model';
import { AddCustomerComponent } from 'src/app/AddThings/add-customer/add-customer.component';
import { Account } from 'src/model/account.model';
import { AddLoanComponent } from 'src/app/AddThings/add-loan/add-loan.component';
import { DataService } from 'src/app/Shared/data.service';
import { RestApiService } from 'src/app/API/restapi';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { TaskCollectionListComponent } from 'src/app/Others/task-collection-list/task-collection-list.component';
import { Enums } from 'src/app/Shared/enums';
import { TransactionHistoryComponent } from 'src/app/Account/transaction-history/transaction-history.component';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Profile } from 'src/model/profile.model';
import { filter } from 'jszip';
import { LoanRequest } from 'src/model/loanRequest.model';
import { Loan } from 'src/model/loan.model';
import { Contact } from 'src/model/contact.model';
import { DuitLebih } from 'src/model/duitLebih.model';
import { EPF } from 'src/model/epf.model';
import { TransactionRequest } from 'src/model/transactionRequest.model';
import { SettlementCharges } from 'src/model/settlementCharges.model';
import { SharedLoanCompany, SharedLoanRequest } from 'src/model/sharedLoan.model';


@Component({
  selector: 'app-dashboard-calendar',
  templateUrl: './dashboard-calendar.component.html',
  styleUrls: ['./dashboard-calendar.component.css'],
  providers: [DatePipe]
})
export class DashboardCalendarComponent implements OnInit, OnDestroy {

  // loggedInUserId: string;
  loggedInUsername: string;

  sortAsc = true;

  searchText: string;
  calendarDate: Date = new Date();

  model: any;


  taskList: Task[] = new Array;
  wholeTaskList: Task[] = new Array;

  leadList: Customer[] = new Array;
  wholeLeadList: Customer[] = new Array;

  transactionRequestList: TransactionRequest[] = new Array;

  todayTotalTask: number = 0;
  todayPendingTask: number = 0;

  userCashAccount: Account;

  totalLoanCount: number;
  totalLoanAmount: number;
  leadSubscription: any;
  showTask: boolean = false;

  loanRequestList: LoanRequest[] = new Array;

  expenseOutTotal = 0;
  dlExpenseOutTotal = 0;

  sharedLoanRequestList: SharedLoanRequest[] = new Array;

  constructor(private cdr: ChangeDetectorRef, public datepipe: DatePipe, private router: Router, private modalService: NgbModal, private dataService: DataService,
    private restapi: RestApiService, private constructAPI: ConstructAPI, private enums: Enums, public sharedService: SharedService) { }

  ngOnInit() {

    this.loggedInUsername = sessionStorage.getItem("username");

    this.getAllLeads();
    this.wholeLeadList = this.leadList;


    //subscribe to new lead data if any new lead is added
    this.leadSubscription = this.dataService.currentLeadList.subscribe(data => {

      //if there is any new lead
      if (data != undefined && data.length > 0) {

        //add the lead into lead list
        // this.leadList.push(data[0]);


        //UI detect changes
        // this.cdr.detectChanges();
      }

    })


    this.getLoanSummary();
    this.getAccountDetails();

    //get all HQ Assigned transactions, only manager and BOOK KEEPING STAFFS can get 
    if (this.sharedService.isStaff() == false)
      this.getAllAssignedTransactionList();

    // if (this.sharedService.isBranchManager())
    this.getAllLoanRequests(new Date().toISOString());

    this.getAllSharedLoanAgreements();


    //if is staff show task details
    //other than staff show expenses details
    if (this.sharedService.isStaff() || this.sharedService.isBookkeepingStaff())
      this.calculateTodayTasks(this.taskList);
    else
      this.getExpensesDetailsOfTheMonth();
  }

  ngOnDestroy() {
    this.leadSubscription.unsubscribe();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;

    if (filterValue != "") {

      var value = filterValue.replace(/-/g, '');
      this.leadList = this.wholeLeadList.filter((lead: Customer) => (lead.Name.toString().toUpperCase().includes(value.toUpperCase()) || lead.ICNumber.toString().includes(value)))
    } else
      this.leadList = this.wholeLeadList;

  }
  loadTaskList() {
    var date = this.datepipe.transform(new Date(), 'yyyy-MM-dd');

    this.getAllTasks(date);
    this.wholeTaskList = this.taskList;
    this.showTask = true;

  }

  hideTaskList() {

    //RESET TASK COUNTS
    this.todayTotalTask = 0;
    this.todayPendingTask = 0;

    this.wholeTaskList = [];
    this.taskList = [];
    this.showTask = false;
  }

  addTaskStyle(taskDate: Date, isDone: boolean) {

    var taskDateFormat = new Date(taskDate);
    // alert(doneDate)
    if (taskDateFormat < this.calendarDate && isDone == false) {
      return "pendingTaskCard";
    }

  }


  getStatusColor(isDone: boolean) {

    // alert(dateDone)
    var color: string;

    switch (isDone) {
      case false:
        color = "red";
        break;

      default:
        color = "green";
        break;
    }
    return color;
  }

  filterListByDate() {

    this.showTask = true;
    //RESET TASK COUNTS
    this.todayTotalTask = 0;
    this.todayPendingTask = 0;
    //clear task list
    this.taskList.splice(0, this.taskList.length)

    if (this.calendarDate != null) {

      var calendar = new Date(this.datepipe.transform(this.calendarDate, 'yyyy-MM-dd'));
      var calendarFormat = this.datepipe.transform(this.calendarDate, 'yyyy-MM-dd');
      // var taskDate = new Date(this.datepipe.transform(this.wholeTaskList[0].Date, 'yyyy-MM-dd'));

      // console.log("Calendar:" + calendar);
      // console.log("taskDate:" + taskDate);

      // console.log("Calendar<taskDate?" + (calendar < taskDate));

      //filter task by date
      // this.taskList = this.wholeTaskList.filter((task: Task) => new Date(this.datepipe.transform(task.Date, 'yyyy-MM-dd')) <= calendar);



      //get task list by date
      this.getAllTasks(calendarFormat);

      this.taskList.sort((a, b) => (a.Date > b.Date) ? 1 : -1);

    }
  }

  calculateTodayTasks(taskList: Task[]) {

    this.todayTotalTask = taskList.length;


    taskList.forEach(task => {
      if (task.IsDone == false)
        this.todayPendingTask++;

    });
  }
  filterList() {

    if (this.searchText != "") {
      // console.log("search:" + this.searchText);
      this.taskList = this.wholeTaskList.filter((task: Task) => (task.Description.toUpperCase().includes(this.searchText.toUpperCase())) || task.CustomerName.toString().toUpperCase().includes(this.searchText.toUpperCase()));
    }

    else
      this.taskList = this.wholeTaskList;
  }
  sortList(sortCriteria: string) {

    // console.log(this.taskList)
    if (this.sortAsc == true) {
      switch (sortCriteria) {

        case 'status':
          this.taskList.sort((a, b) => ((a.IsDone > b.IsDone)) ? 1 : -1);
          break;
        case 'name':
          this.taskList.sort((a, b) => (a.CustomerName > b.CustomerName) ? 1 : -1);
          break;
        case 'desc':
          this.taskList.sort((a, b) => (a.Description > b.Description) ? 1 : -1);
          break;
        case 'date':
          this.taskList.sort((a, b) => (a.Date > b.Date) ? 1 : -1);
          break;

      }

    }

    else {
      switch (sortCriteria) {

        case 'status':
          this.taskList.sort((a, b) => ((a.IsDone < b.IsDone)) ? 1 : -1);
          break;
        case 'name':
          this.taskList.sort((a, b) => (a.CustomerId < b.CustomerId) ? 1 : -1);
          break;
        case 'desc':
          this.taskList.sort((a, b) => (a.Description < b.Description) ? 1 : -1);
          break;
        case 'date':
          this.taskList.sort((a, b) => (a.Date < b.Date) ? 1 : -1);
          break;


      }

    }


    this.sortAsc = !this.sortAsc;

  }


  viewTaskDetails(taskId: number, customerId: number, profileId: number) {

    this.router.navigate(['/taskDetails', taskId, customerId, profileId]);
  }

  notOwnTask(assignee: string) {
    var username = sessionStorage.getItem("username");

    if (assignee != username)
      return true;

    else false;
  }


  getAllSharedLoanAgreements() {
    var xhttp;
    if (this.sharedService.isBranchManager())
      xhttp = this.restapi.getRequest(this.constructAPI.getAllSharedLoanApprovalRequest(undefined, undefined, false))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {


        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          //process all loan requests 
          var loanRequestsInfo: LoanRequest[] = new Array;
          var loanRequestsArray = json.loansInfo;
          loanRequestsArray.forEach(requestJson => {
            var loanRequest = this.sharedService.processLoanRequest(requestJson);
            loanRequest.ProfileId = json.sharedProfileId;
            loanRequestsInfo.push(loanRequest);
          });


          //process all shared loan companies
          var companies = this.processSharedLoanCompany(json.companies);

          //process all shared loan guarantors

          var guarantors = this.sharedService.processGuarantorJsonArr(json.guarantors);

          var sharedLoanRequest = new SharedLoanRequest(json.authorizedBy, json.authorizedOn, companies, guarantors, json.loanGroupId, loanRequestsInfo,
            json.processorRoleRequired, json.requestBy, json.requestOn, json.sharedProfileId, json.name);


          this.sharedLoanRequestList.push(sharedLoanRequest);
          this.cdr.detectChanges();


        }
        );
      }
    };
  }

  processSharedLoanCompany(jsonArr: any) {
    var companies: SharedLoanCompany[] = new Array;
    jsonArr.forEach(json => {
      var company = new SharedLoanCompany(json.agreed, json.companyId, json.companyCode, json.group);
      companies.push(company);
    });

    return companies;
  }



  getAllLoanRequests(endDate: string) {
    var xhttp;
    if (this.sharedService.isBranchManager())
      xhttp = this.restapi.getRequest(this.constructAPI.getAllLoanApprovalRequest(endDate, false))
    else
      xhttp = this.restapi.getRequest(this.constructAPI.getAllLoanApprovalRequest(endDate, false, undefined, undefined, false))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));


        jsonArray.forEach(json => {

          var loanRequest = this.sharedService.processLoanRequest(json);
          this.loanRequestList.push(loanRequest);


          this.loanRequestList.sort((a, b) => ((a.AuthorizedBy > b.AuthorizedBy)) ? -1 : 1);
          this.cdr.detectChanges();


        }
        );
      }
    };
  }


  getAllTasks(date: string) {

    var xhttp = this.restapi.getRequest(this.constructAPI.getTasksForAday(date))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // Typical action to be performed when the document is ready:

        var jsonArray = JSON.parse((xhttp.responseText));
        // console.log(jsonArray);

        jsonArray.forEach(json => {

          var isDone = (json.dateDone == undefined) ? false : true;
          let task = new Task(json.id, json.customerId,
            json.description, json.followUpRemark, json.date, json.customerName, json.assignee, json.dateDone, isDone, json.profileId);


          this.taskList.push(task);
        }

        );

        this.calculateTodayTasks(this.taskList);
      }
    };

  }

  getLoanSummary() {
    var dateNow = new Date();

    var userRole = Number(sessionStorage.getItem("roleId"));
    var apiEndpoint = (userRole == this.enums.BRANCH_MANAGER) ? this.constructAPI.getYearMonthLoanStat(dateNow.getFullYear(), dateNow.getMonth() + 1)
      : this.constructAPI.getUserYearMonthLoanStat(this.loggedInUsername, dateNow.getFullYear(), dateNow.getMonth() + 1);
    var xhttp = this.restapi.getRequest(apiEndpoint)

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // Typical action to be performed when the document is ready:

        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(
          json => {
            this.totalLoanAmount = json.amount;
            this.totalLoanCount = json.count;
          }
        );

      }
    };
  }

  getAccountDetails() {

    var xhttp = this.restapi.getRequest(this.constructAPI.getUserCashAccount(this.loggedInUsername))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // Typical action to be performed when the document is ready:

        var json = JSON.parse((xhttp.responseText));
        // console.log(jsonArray);

        this.userCashAccount = new Account(json.id, json.number, json.name, null, json.balance, json.companyId, json.username, null);

      }
    };

  }

  getAllLeads() {
    var xhttp = this.restapi.getRequest(this.constructAPI.getAllLeadsProfiles())
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        // console.log(jsonArray);

        jsonArray.forEach(json => {

          var companyCode = sessionStorage.getItem("companyName");
          let leadProfile = new Profile(json.id, json.occupation, json.salary, json.salaryDay, json.remark, json.status, json.dateCreated, json.customerId, json.customerCode, json.reserve)
          var profileList: Profile[] = new Array;
          profileList.push(leadProfile);
          let lead = new Customer(json.customerId, json.name, json.occupation, json.salary, json.salaryDay, json.icNumber, json.contactNo, json.Remark, json.ctosNumber, json.ctosStatus, json.status, json.dateCreated, json.handler, json.companyId, json.customerCode, companyCode, undefined, undefined, undefined, profileList, undefined, undefined, json.epfDate, undefined, undefined, json.dob);
          this.leadList.push(lead);
        }
        );
      }
    };
  }

  openAddTaskModal() {
    const modalRef = this.sharedService.openModal(CreateNewTaskComponent);


    //get task that was created
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var task = entry;

      //append added task to task list
      // this.taskList.push(task);
      // console.log(JSON.stringify(this.taskList));

      //REFRESH TASK LIST
      this.taskList.splice(0, this.taskList.length);

      var dateString = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
      this.getAllTasks(dateString);

    });

    modalRef.result.then(() => {
      this.cdr.detectChanges();

      //refresh whole page, temporary solution
      // location.reload();
    })

  }

  openTransactionHistoryModal() {
    const modalRef = this.sharedService.openModal(TransactionHistoryComponent, "largeModal");

    modalRef.componentInstance.accountId = this.userCashAccount.ID;


  }


  openAddCustomerModal() {
    const modalRef = this.sharedService.openModal(AddCustomerComponent, "largeModal");

    //get customer that was created
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      window.location.reload();
    });
  }

  goToCustomer(lead: Customer) {
    this.router.navigate(['/manageCustomer', lead.ID]);
  }

  openAddLoanModal(lead: Customer) {

    const modalRef = this.sharedService.openModal(AddLoanComponent, "largeModal");
    modalRef.componentInstance.selectedCustomer = lead;
    modalRef.componentInstance.selectedProfile = lead.Profiles[0];
    modalRef.componentInstance.isLead = true;
    var epf = new EPF(undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    modalRef.componentInstance.epfDetails = epf;

    modalRef.componentInstance.isEPFExtra = false;
    modalRef.componentInstance.previousEPFLoans = undefined;


    modalRef.componentInstance.needRestrictedRequest = this.sharedService.checkIsSeniorRestrictedCustomer(new Date(lead.DOB));

    // modalRef.componentInstance.allowEPF = this.sharedService.checkIsAllowEPF(lead.DOB, epf);

    //get customerID for lead that deals
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var profileId = entry.ProfileId;

      //remove the customer from lead list
      var index = this.leadList.findIndex(lead => lead.Profiles[0].ID === profileId);
      this.leadList.splice(index, 1);
    });

    modalRef.result.then(() => {

      //detect changes
      this.cdr.detectChanges();
    })
  }


  openTaskCollectionModal() {
    const modalRef = this.sharedService.openModal(TaskCollectionListComponent, 'largeModal');
    modalRef.componentInstance.selectedDate = this.calendarDate;
  }


  getAllAssignedTransactionList() {
    //only get trasactionRequest without transaction
    var xhttp = this.restapi.getRequest(this.constructAPI.getAllTransactionRequests(false))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {

          var itemsArray = json.items;
          var itemList = new Array;
          itemsArray.forEach(itemJson => {
            var item = { "amount": itemJson.amount, "type": itemJson.type, "subtype": itemJson.subtype }

            itemList.push(item);
          }
          );

          var transactionRequest = new TransactionRequest(json.id, json.companyCode, json.companyid, json.remark, json.requestedBy, json.requestedOn, itemList);
          this.transactionRequestList.push(transactionRequest);
        }
        );
      }
    };
  }


  getExpensesDetailsOfTheMonth() {

    var startDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    var endDate = new Date();

    var companyId = Number(sessionStorage.getItem('companyId'));
    //only get trasactionRequest without transaction
    var xhttp = this.restapi.getRequest(this.constructAPI.getCompanyTransactionDetails(companyId, startDate.toISOString(), endDate.toISOString(), [this.enums.EXPENSE]))


    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          this.expenseOutTotal = -Number(json.out);
        }
        );

        this.getExpensesDLDetailsOfTheMonth();
      }
    };
  }

  getExpensesDLDetailsOfTheMonth() {

    var startDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    var endDate = new Date();

    var companyId = Number(sessionStorage.getItem('companyId'));
    //only get trasactionRequest without transaction
    var xhttp = this.restapi.getRequest(this.constructAPI.getCompanyTransactionDetails(companyId, startDate.toISOString(), endDate.toISOString(), [this.enums.EXTRAS]))


    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          this.dlExpenseOutTotal = -Number(json.out);
        }
        );
      }
    };
  }

  goToExpenseReport() {
    var companyId = Number(sessionStorage.getItem("companyId"));

    var startDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    var endDate = new Date();

    this.router.navigate(['/simpleReport', companyId, this.enums.EXPENSE_REPORT, startDate.toISOString(), endDate.toISOString()]);
  }
}