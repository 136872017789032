import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Enums } from '../enums';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.css']
})
export class AlertDialogComponent implements OnInit {

  @Input() message: string;
  @Input() alertType: number;
  constructor(private activeModal: NgbActiveModal, public enums: Enums) { }

  ngOnInit(): void {
  }



  checkType() {
  
    switch (this.alertType) {
      case this.enums.DANGER_ALERT:
        return 'danger';
      case this.enums.SUCCESS_ALERT:
        return 'success';

    }


  }

  closeModal() {
    this.activeModal.close();
  }

}
