import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Customer } from 'src/model/customer.model';

import { Loan } from 'src/model/loan.model';
import { Profile } from 'src/model/profile.model';

@Component({
  selector: 'app-shared-loan-list',
  templateUrl: './shared-loan-list.component.html',
  styleUrls: ['./shared-loan-list.component.css']
})
export class SharedLoanListComponent implements OnInit {

  @Input() sharedLoanList: Loan[] = new Array;
  @Input() viewCustomer: Customer;
  @Input() selectedProfile: Profile;
  constructor(private activeModal: NgbActiveModal) { }


  ngOnInit(): void {
  }

  reset() {
    this.activeModal.close();
  }

}
