import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteReasonRemarkComponent } from 'src/app/Account/delete-reason-remark/delete-reason-remark.component';
import { TransactionHistoryComponent } from 'src/app/Account/transaction-history/transaction-history.component';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Account } from 'src/model/account.model';
import { CheckIn } from 'src/model/checkIn.model';
import { Advance } from 'src/model/advance.modal';
import { Asset } from 'src/model/asset.model';
import { Commission } from 'src/model/commission.model';
import { VerifyAccountComponent } from '../verify-account/verify-account.component';
import { DeleteReasonComponent } from 'src/app/Others/delete-reason/delete-reason.component';
import { AddAccountComponent } from 'src/app/AddThings/add-account/add-account.component';

@Component({
  selector: 'app-list-hutang-report',
  templateUrl: './list-hutang-report.component.html',
  styleUrls: ['./list-hutang-report.component.css']
})
export class ListHutangReportComponent implements OnInit {

  routeSubscription: any;
  selectedCompany: number;
  endDate: Date;

  cashAccountList: Account[] = new Array;
  cashAccountBalance: number = 0;

  companyBankAccountList: Account[] = new Array;
  bankAccountBalance: number = 0;

  duitLebihBalance: number = 0;
  assetList: Asset[] = new Array;
  assetTotal: number = 0;

  advanceList: Advance[] = new Array;
  advanceTotal: number = 0;
  stampTotal: number = 0;
  scTotal: number = 0;
  hutangTotal: number = 0;
  simpanTotal: number = 0;
  commissionTotal: number = 0;
  commissionList: Commission[] = new Array;
  bdTotal: number = 0;
  handlingTotal: number = 0;
  cashBookAmount: number = 0;


  isCollapsed: boolean = false;
  showBalanceCollapse: boolean = true;
  showAdvanceCollapse: boolean = true;
  showCommissionCollapse: boolean = true;

  isVerified: boolean;
  isAdminVerified: boolean;
  verifiedUser: string;
  verifiedDate: Date;
  latestCheckInID: number;

  constructor(private modalService: NgbModal, private activatedRoute: ActivatedRoute, private restApi: RestApiService,
    private constructApi: ConstructAPI, public enums: Enums, private router: Router, public sharedService: SharedService) { }

  ngOnInit(): void {

    this.routeSubscription = this.activatedRoute.paramMap.subscribe(params => {
      this.selectedCompany = + params.get('companyId');
      // this.startDate = (params.get('startDate') == undefined) ? undefined : new Date(params.get('startDate'));
      this.endDate = (params.get('endDate') == undefined) ? undefined : new Date(params.get('endDate'));

      this.resetAllList();
      this.getCompanyTransactionBalances(this.selectedCompany)

      this.getAllAccountsOfCompany(this.selectedCompany);
      this.getAllHutang(this.selectedCompany);
      this.getAllUnpaidCommission(this.selectedCompany);
      // this.getAllUnpaidBadDebt(this.selectedCompany);
      this.getCashBookBalance(this.selectedCompany);
      this.getCheckInLatest(this.selectedCompany)

    });

  }

  resetAllList() {
    this.cashAccountBalance = 0;
    this.bankAccountBalance = 0;
    this.assetTotal = 0;
    this.advanceTotal = 0;
    this.hutangTotal = 0;
    this.simpanTotal = 0;
    this.scTotal = 0;
    this.stampTotal = 0;
    this.commissionTotal = 0;
    this.bdTotal = 0;
    this.cashBookAmount = 0;
    this.duitLebihBalance = 0;
    this.handlingTotal = 0;

    this.cashAccountList.splice(0, this.cashAccountList.length);
    this.companyBankAccountList.splice(0, this.companyBankAccountList.length);
    this.advanceList.splice(0, this.advanceList.length);
    this.assetList.splice(0, this.assetList.length);
    this.commissionList.splice(0, this.commissionList.length);
  }


  getCheckInLatest(companyId: number) {

    var addADayDate = new Date(Number(this.endDate) + (3600 * 1000 * 24) - 1);
    var date = addADayDate.toISOString();
    var xhttp = this.restApi.getRequest(this.constructApi.getCompaniesLatestCheckIns(companyId, date));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        if (jsonArray.length == 0 || jsonArray == undefined) {
          this.isVerified = false;
          this.isAdminVerified = false;
        }

        else {
          jsonArray.forEach(json => {
            var compareDate = new Date(json.date).setHours(0, 0, 0, 0);
            var reportDate = new Date(this.endDate).setHours(0, 0, 0, 0);

            if (compareDate == reportDate) {
              this.isVerified = true;

              if (Number(json.roleId) == Number(this.enums.ADMIN))
                this.isAdminVerified = true;
              else
                this.isAdminVerified = false;

              this.verifiedUser = json.username;
              this.verifiedDate = json.dateCreated;

              this.latestCheckInID = json.id;
            }

            else {
              this.isVerified = false;
              this.isAdminVerified = false;
            }
          }
          );
        }
      }
    };
  }



  openDeleteReasonDialog() {

    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Confirm to delete checkin ?";


    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;
      if (confirmation == true) {
        const modalRef = this.sharedService.openModal(DeleteReasonComponent);
        modalRef.componentInstance.passEntry.subscribe((entry) => {
          var remark = entry;

          //append added account to account list
          this.deleteCheckIn(remark, this.latestCheckInID);
        });
      }
    });


  }

  deleteCheckIn(remark: string, checkInId: number) {
    var data =
    {
      "reason": remark
    };

    var xhr = this.restApi.deleteRequest(this.constructApi.getDeleteCheckIn(this.selectedCompany, this.latestCheckInID), data);

    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        if (xhr.readyState == 4 && xhr.status == 200) {

          if (xhr.status == 200) {
            window.location.reload();
          }
          else {
            this.sharedService.openAlert("Opps, some errors occured. Please try again later", this.enums.DANGER_ALERT);
          }

        }
      }

    }
  }

  checkCanVerify() {
    if (this.sharedService.isAdmin() || this.sharedService.isBossAccount()) {
      if (this.isAdminVerified == true)
        return false;
      else return true;
    }

    else if (this.sharedService.isBranchAccountHandler()) {
      if (this.isVerified == false)
        return true;
      else
        return false;
    }

  }

  toAdvanceList() {
    this.router.navigate(['/advanceList', this.selectedCompany]);
  }

  getAllAccountsOfCompany(companyId: number) {

    if (this.endDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var addADayDate = new Date(Number(this.endDate) + (3600 * 1000 * 24) - 1);
    var endDateStr = (this.endDate == undefined) ? undefined : addADayDate.toISOString();

    var xhttp = this.restApi.getRequest(this.constructApi.getAccountsOfCompany(companyId, endDateStr));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          var amount = (json.balance == undefined) ? 0 : json.balance;
          let account = new Account(json.id, json.number, json.name, json.holderName, amount, json.companyId, json.username);

          if (account.Username != undefined) {
            this.cashAccountList.push(account)

            this.cashAccountBalance += amount;
          }
          else {
            this.companyBankAccountList.push(account);
            this.bankAccountBalance += amount;
          }
        }
        );
      }
    };
  }

  getCashBookBalance(companyId: number) {
    if (this.endDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var addADayDate = new Date(Number(this.endDate) + (3600 * 1000 * 24) - 1);
    var endDateStr = (this.endDate == undefined) ? undefined : addADayDate.toISOString();


    var xhttp = this.restApi.getRequest(this.constructApi.getCompanyCashBookBalance(companyId, endDateStr));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var json = JSON.parse((xhttp.responseText));
        this.cashBookAmount = json.balance;

      }
    };
  }


  getCompanyTransactionBalances(companyId: number) {
    if (this.endDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var addADayDate = new Date(Number(this.endDate) + (3600 * 1000 * 24) - 1);
    var endDateStr = (this.endDate == undefined) ? undefined : addADayDate.toISOString();


    var xhttp = this.restApi.getRequest(this.constructApi.getCompanyTransactionBalances(companyId, endDateStr));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          var type = Number(json.type);
          switch (type) {
            case this.enums.STAMP:
              this.stampTotal += json.balance;
              break;
            case this.enums.SERVICE_CHARGE:
              this.scTotal += json.balance;
              break;
            case this.enums.EXTRAS:
              this.duitLebihBalance += json.balance;
              break;
            case this.enums.ASSET:
              this.assetTotal += json.balance;
              break;
            case this.enums.RESERVE:
              this.simpanTotal += json.balance;
              break;
            case this.enums.ADVANCE:
              this.advanceTotal -= json.balance;
              break;
            case this.enums.BAD_DEBT:
              this.bdTotal += json.balance;
              break;
            case this.enums.HANDLING_CHARGE:
              this.handlingTotal += json.balance;
              break;
          }
        }
        );




      }
    };
  }

  openTransactionHistoryModal(accountId: number) {
    const modalRef = this.sharedService.openModal(TransactionHistoryComponent, "largeModal");
    modalRef.componentInstance.accountId = accountId;
    modalRef.componentInstance.selectedCompany = this.selectedCompany
  }

  toReport(selectedReport) {

    //default the start data always the beginning of the month
    var startDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), 1, 0, 0, 0, 0);
    var startDateStr = startDate.toISOString();


    if (this.endDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var addADayDate = new Date(Number(this.endDate) + (3600 * 1000 * 24) - 1);
    var endDateStr = (this.endDate == undefined) ? undefined : addADayDate.toISOString();
    if (selectedReport != this.enums.EXTRA_REPORT)
      this.router.navigate(['/simpleReport', this.selectedCompany, selectedReport, startDateStr, endDateStr]);
    else
      this.router.navigate(['/duitLebihReport', this.selectedCompany, startDateStr, endDateStr]);
  }

  getAllHutang(companyId: number) {
    if (this.endDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var addADayDate = new Date(Number(this.endDate) + (3600 * 1000 * 24) - 1);
    var endDateStr = (this.endDate == undefined) ? undefined : addADayDate.toISOString();

    var xhttp = this.restApi.getRequest(this.constructApi.getCompanyAllLoansTransactions(companyId, undefined, endDateStr, "HUTANG", [this.enums.HUTANG]));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          this.hutangTotal += json.amount;
        }
        );
      }
    };
  }

  getAllUnpaidCommission(companyId: number) {
    if (this.endDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var addADayDate = new Date(Number(this.endDate) + (3600 * 1000 * 24) - 1);
    var endDateStr = (this.endDate == undefined) ? undefined : addADayDate.toISOString();

    var xhttp = this.restApi.getRequest(this.constructApi.getCompanyUnpaidCommission(companyId, endDateStr));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          var paid = (json.paid == undefined) ? 0 : Number(json.paid)
          this.commissionTotal += json.amount - paid;
          let commission = new Commission(json.id, json.loanId, json.amount, json.assignee, json.assignor, json.transactionId, json.dateCreated, undefined, json.customerName, json.principal);
          this.commissionList.push(commission);
        }
        );
      }
    };
  }

  getAllUnpaidBadDebt(companyId: number) {
    if (this.endDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var addADayDate = new Date(Number(this.endDate) + (3600 * 1000 * 24) - 1);
    var endDateStr = (this.endDate == undefined) ? undefined : addADayDate.toISOString();

    var xhttp = this.restApi.getRequest(this.constructApi.getCompanyUnpaidBadDebts(companyId, endDateStr));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          this.bdTotal += json.total;
        }
        );
      }
    };
  }

  roundTo2Dec(num: number) {
    return Math.round(num * 100) / 100
  }


  calculateListHutangTotal() {
    // return this.cashAccountBalance + this.bankAccountBalance + this.advanceTotal + (-this.hutangTotal) - this.assetTotal - this.duitLebihBalance - this.stampTotal -
    //   this.scTotal - this.simpanTotal - this.bdTotal - this.commissionTotal;
    return this.cashAccountBalance + this.bankAccountBalance + this.advanceTotal + (-this.hutangTotal) - this.assetTotal - this.duitLebihBalance - this.stampTotal -
      this.scTotal - this.simpanTotal - this.bdTotal - this.handlingTotal;
  }

  openVerifyModal() {
    const modalRef = this.sharedService.openModal(VerifyAccountComponent, "largeModal");
    modalRef.componentInstance.selectedCompany = this.selectedCompany;
    modalRef.componentInstance.endDate = this.endDate;
    modalRef.componentInstance.needStatusOutstanding = false;
  }


  openEditAccountModal(account: Account) {
    var modalRef = this.sharedService.openModal(AddAccountComponent);
    modalRef.componentInstance.accountId = account.ID;


    modalRef.componentInstance.accountNo = account.AccountNumber;
    modalRef.componentInstance.holderName = account.HolderName;
    modalRef.componentInstance.accountName = account.Name;
    modalRef.componentInstance.initialAmount = account.Balance;
    modalRef.componentInstance.isCashAccount = (account.Username != undefined);
    modalRef.componentInstance.selectedCompanyID = account.CompanyId;
    modalRef.componentInstance.selectedUsername = account.Username;

  }


  confirmBeforeDeletingCommission(commission: Commission) {
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Are you sure you want to delete the commission of " + commission.Assignee + "- RM " + commission.Amount + "?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something

      if (confirmation == true) {
        this.deleteCommission(commission.LoanId, commission.ID);
      }
    });
  }

  deleteCommission(loanId: number, commissionId: number) {
    var xhttp = this.restApi.deleteRequest(this.constructApi.getDeleteLoanCommission(loanId, commissionId));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        window.location.reload();
      }
    };
  }
}
