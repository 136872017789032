<form>
    <!-- Modal Header -->
    <div class="modal-header" style="background-color:lightyellow;">
        <h4 class="modal-title">Loan Payment</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="closeModal()"></button>
    </div>

    <div class="modal-body">
        <app-add-payment [selectedLoan]="selectedLoan" (passLoanPaymentDetails)="getLoanPaymentData($event)">
        </app-add-payment>
    </div>

    <!--Account Number-->
    <div class="row p-3 d-flex justify-content-center align-items-center">

        <div class="col-12">
            <p>To Account</p>
        </div>
        <div class="col-12">
            <select class="browser-default form-select" name="selectedAccount" [(ngModel)]="selectedAccountId">
                <option *ngFor="let account of companyAccountList" [value]="account.ID">{{account.Name }}</option>
            </select>
        </div>

    </div>
    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="submit()" [disabled]="disabledSubmit">Pay Loans</button>
        <button class="btn btn-outline-danger" type="button" (click)="closeModal()">Cancel</button>
    </div>

</form>