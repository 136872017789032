import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { AlertDialogComponent } from 'src/app/Shared/alert-dialog/alert-dialog.component';
import { Company } from 'src/model/company.model';
import { LoanScheme } from 'src/model/loanScheme.model';
import { SimpleReportComponent } from '../simple-report/simple-report.component';


interface ReportType {
  id: number,
  name: string
}

@Component({
  selector: 'app-report-menu',
  templateUrl: './report-menu.component.html',
  styleUrls: ['./report-menu.component.css']
})
export class ReportMenuComponent implements OnInit {

  @ViewChild(SimpleReportComponent) simpleReport: SimpleReportComponent;

  // loanSchemeList: LoanScheme[] = new Array;
  // companyList: Company[] = new Array;
  // startDate: Date;
  // endDate: Date;


  // primayReportList = ["Cash Book", "List Hutang", "Stock"];
  // otherReportList = ["Stamp Report", "S/C Report", "Duit Lebih Report", "Asset Report", "Hutang", "Simpan Report", "Commission Report"];

  // selectedCompany: number;
  // selectedReport: string;
  isCollapsed: boolean = false;
  isReady: boolean = false;

  constructor(private modalService: NgbModal, private router: Router, private restapi: RestApiService, private constructApi: ConstructAPI) { }

  ngOnInit(): void {
  
  }

  goToStockReport() {
    this.router.navigateByUrl('/stockReport');

  }



}