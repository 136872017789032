import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { AlertDialogComponent } from 'src/app/Shared/alert-dialog/alert-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { DuitLebih } from 'src/model/duitLebih.model';
import { TransactionSubType } from 'src/model/transactionSubType.model';

@Component({
  selector: 'app-add-duit-lebih',
  templateUrl: './add-duit-lebih.component.html',
  styleUrls: ['./add-duit-lebih.component.css']
})
export class AddDuitLebihComponent implements OnInit {
  subtypeList: TransactionSubType[] = new Array;
  amount: number;
  selectedSubType: number;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private restapi: RestApiService, private constructApi: ConstructAPI, public sharedService: SharedService, private enums: Enums) { }

  ngOnInit(): void {
    this.getTransactionSubTypes();
  }


  getTransactionSubTypes() {
    var xhttp = this.restapi.getRequest(this.constructApi.getTransactionSubTypes(this.enums.EXTRAS));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let type = new TransactionSubType(json.id, json.name, json.type, json.assignable, json.enabled);
          if (json.assignable == true)
            this.subtypeList.push(type);
        });
      }
    };
  }


  submit() {
    if (this.amount == undefined) {
      this.sharedService.openAlert("Please key in amount", this.enums.DANGER_ALERT);
    }
    else {
      let duitLebih = new DuitLebih(this.amount, this.selectedSubType)
      this.passEntry.emit(duitLebih);
      this.sharedService.openAlert("Successfully added duit lebih", this.enums.SUCCESS_ALERT);
      this.reset();
    }

  }

  reset() {
    this.activeModal.close();
  }

}
