import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Account } from 'src/model/account.model';
import { Profile } from 'src/model/profile.model';

@Component({
  selector: 'app-transfer-simpan',
  templateUrl: './transfer-simpan.component.html',
  styleUrls: ['./transfer-simpan.component.css']
})
export class TransferSimpanComponent implements OnInit {

  companyBankAccountList: Account[] = new Array;
  cashAccountList: Account[] = new Array;
  selectedAccountId: number;

  amount: number;
  simpanDate: Date = new Date();
  remark: string;
  toProfileId: number;

  @Input() selectedProfileID: number;
  @Input() profileList: Profile[];

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  disabledSubmit = false;
  constructor(private activeModal: NgbActiveModal, private restapi: RestApiService, private constructApi: ConstructAPI,
    public sharedService: SharedService, private enums: Enums) { }

  ngOnInit(): void {
    var companyId = Number(sessionStorage.getItem("companyId"));
    this.getAllAccountsOfCompany(companyId);
  }

  private getAllAccountsOfCompany(companyId: number) {
    var loggedInUsername = sessionStorage.getItem("username");
    var xhttp = this.restapi.getRequest(this.constructApi.getAccountsOfCompany(companyId));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let account = new Account(json.id, json.number, json.name, json.holderName, json.balance, json.companyId, json.username);

          if (account.Username == undefined)
            this.companyBankAccountList.push(account);
          else if (account.Username == loggedInUsername)
            this.cashAccountList.push(account);

        }
        );

      }
    };

  }

  fieldIsNull() {
    var amountNull = this.amount == undefined || this.amount == 0;
    var remarkNull = this.remark == undefined;
    var accountNull = this.selectedAccountId == undefined;
    var profileNull = this.toProfileId == undefined;

    return amountNull || remarkNull || accountNull || profileNull

  }

  submit() {
    if (this.fieldIsNull() == true) {
      this.sharedService.openAlert("Please fill in all fields correctly", this.enums.DANGER_ALERT);
    } else {
      this.transferSimpan();
    }
  }

  transferSimpan() {
    this.disabledSubmit = true;
    var isoDate = (new Date(Number(this.simpanDate))).toISOString();

    var data = {
      "toProfile": Number(this.toProfileId),
      "amount": this.amount,
      "remark": this.remark,
      "date": isoDate,
      "accountId": Number(this.selectedAccountId)
    };


    var xhr = this.restapi.postRequest(this.constructApi.getTransferReserve(this.selectedProfileID), data);
    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        if (xhr.status == 200) {
          this.sharedService.openAlert("Successfully transferred simpan!", this.enums.SUCCESS_ALERT);
          window.location.reload();
        }
        else
          this.sharedService.openErrorMessage(xhr);
      }


    }
  }

  reset() {
    this.activeModal.close();
  }
}
