import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReplaySubject } from 'rxjs';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { EPF } from 'src/model/epf.model';
import { Task } from 'src/model/task.model';

@Component({
  selector: 'app-follow-next-task',
  templateUrl: './follow-next-task.component.html',
  styleUrls: ['./follow-next-task.component.css'],
  providers: [DatePipe]
})
export class NextTaskComponent implements OnInit {

  @Input() task: Task;
  @Input() isEPF = false;
  newDescription: string = "";
  newDate: Date;

  epfDetails: EPF;
  constructor(private activeModal: NgbActiveModal, private sharedService: SharedService, private enums: Enums, private restApi: RestApiService, private constructApi: ConstructAPI, private datepipe: DatePipe) { }

  ngOnInit(): void {
    if (this.isEPF == true)
      this.getEPFDetails(this.task.CustomerId)

      
  }


  getEPFDetails(customerID: number) {
    var xhttp = this.restApi.getRequest(this.constructApi.getCustomerEPF(customerID))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var json = JSON.parse((xhttp.responseText))
        this.epfDetails = new EPF(json.amount, json.customerId, json.date, json.hasIAccount, json.iAccountPassword, json.iAccountUsername, json.remark);

      }
    };
  }

  checkNull() {
    var error = "";
    if (this.task.FollowUpRemark == undefined || this.task.FollowUpRemark == '')
      error += "- Customer Says\n";

    if (this.newDescription == '')
      error += "- Customer Promise\n";

    if (this.newDate == undefined)
      error += "- Continue Follow Date";

    var start = "Please key in:\n";
    if (error != "")
      error = start + error;
    return error;
  }

  submit() {
    var error = this.checkNull()
    if (error != "")
      this.sharedService.openAlert(error, this.enums.DANGER_ALERT);
    else {
      this.updateTask();
    }

  }

  updateTask() {

    var doneDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    var newDateStr = this.datepipe.transform(this.newDate, 'yyyy-MM-dd');
    var data = {
      "followUpRemark": this.task.FollowUpRemark,
      "date": doneDate,
      "newDate": newDateStr,
      "newDescription": this.newDescription
    };


    var xhr = this.restApi.putRequest(this.constructApi.getMarkProfileTaskAsDone(this.task.ProfileId, this.task.ID), data);

    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        window.location.reload();

      }
    }

  }


  reset() {
    this.activeModal.close();
  }
}
