import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { APIAddress } from 'src/app/API/apiAddresses';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Profile } from 'src/model/profile.model';

@Component({
  selector: 'app-update-profile-info',
  templateUrl: './update-profile-info.component.html',
  styleUrls: ['./update-profile-info.component.css']
})
export class UpdateProfileInfoComponent implements OnInit {

  profileToUpdate: Profile;
  customerCode: string;
  custOccupation: string;
  salaryAmount: number;
  salaryDay: number;
  remark: string;

  disableSubmit = false;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  custOccupationNull = false;
  salaryAmountNull = false;
  salaryDayNull = false;
  remarkNull = false;
  customerCodeNull = false;

  constructor(private activeModal: NgbActiveModal, private api: RestApiService, private constructApi: ConstructAPI, private sharedService: SharedService,private enums:Enums) { }

  ngOnInit(): void {
    this.customerCode = this.profileToUpdate.CustomerCode;
    this.custOccupation = this.profileToUpdate.Occupation;
    this.salaryAmount = this.profileToUpdate.Salary;
    this.salaryDay = this.profileToUpdate.SalaryDay;
    this.remark = this.profileToUpdate.Remark;
  }

  validField(field: string) {
    switch (field) {
      case 'custOccupation': this.custOccupationNull = (this.custOccupation === ''); break;
      case 'salaryAmount': this.salaryAmountNull = (this.salaryAmount === 0); break;
      case 'salaryDay': this.salaryDayNull = (this.salaryDay === 0); break;
      case 'remark': this.remarkNull = (this.remark === ''); break;
    }
  }

  validFields() {
    this.customerCodeNull = (this.customerCode === '');
    this.custOccupationNull = (this.custOccupation === '');
    this.salaryAmountNull = this.salaryAmount == undefined;
    this.salaryDayNull = this.salaryDay == undefined;

    return (this.custOccupationNull || this.customerCodeNull || this.salaryAmountNull || this.salaryDayNull)
  }

  updateProfile() {
    this.disableSubmit = true;

    var data = {
      "occupation": this.custOccupation,
      "salary": this.salaryAmount,
      "salaryDay": this.salaryDay,
      "remark": this.remark,
      "customerCode": this.customerCode,
      "customerId": this.profileToUpdate.CustomerID
    }


    var xhr = this.api.putRequest(this.constructApi.getUpdateProfile(this.profileToUpdate.ID), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disableSubmit = false;

        if (xhr.status == 200) {

          var json = JSON.parse(xhr.responseText);
          var profile = new Profile(json.id, json.occupation, json.salary, json.salaryDay, json.remark, undefined, undefined, undefined, undefined)
          this.passEntry.emit(profile);

          this.reset();
          this.sharedService.openAlert("Successfully updated profile",this.enums.SUCCESS_ALERT)
        }
        else {
          var json = JSON.parse(xhr.responseText);
          this.sharedService.openAlert(json.error,this.enums.DANGER_ALERT)
        }

      }

    }
  }

  submit() {
    (this.validFields() == true) ? this.sharedService.openAlert("Please fill in all the fields correctly",this.enums.DANGER_ALERT) : this.updateProfile();

  }

  reset() {
    this.activeModal.close();
    this.profileToUpdate = null;
  }
}
