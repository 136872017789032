import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { ConstructAPI } from '../API/constructAPI';
import { RestApiService } from '../API/restapi';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // private signUpURL = "http://206.189.87.13:10080/api/v1/signup";
  // private signInURL = "http://206.189.87.13:10080/api/v1/signin";

  userLoggedIn = new BehaviorSubject<any>([]);

  constructor(private http: HttpClient, private cookieService: CookieService, private router: Router, private constructApi: ConstructAPI, private restApi: RestApiService) {
    this.userLoggedIn.next(!!sessionStorage.getItem('username'));
  }


  registerUser(userInfo) {
    // return this.http.post<any>(this.signUpURL, userInfo,{withCredentials:true})
    // var xhr = new XMLHttpRequest();

    // xhr.open("POST", this.constructApi.getSignUp(), true);
    // xhr.setRequestHeader("Content-type", "application/json");
    // xhr.withCredentials = true;

    // var data = JSON.stringify(userInfo);
    // xhr.send(data);

    var data = userInfo;
    var xhr = this.restApi.postRequest(this.constructApi.getSignUp(), data);
    return xhr
  }


  login(userInfo) {
    // var xhr = new XMLHttpRequest();

    // xhr.open("POST", this.constructApi.getSignIn(), true);
    // xhr.setRequestHeader("Content-type", "application/json");
    // xhr.withCredentials = true;

    // var data = JSON.stringify(userInfo);
    // xhr.send(data);
    var data = userInfo;
    var xhr = this.restApi.postRequest(this.constructApi.getSignIn(), data);
    return xhr
  }

  refreshToken(){
    var xhr = this.restApi.getRequest(this.constructApi.getTokenRefresh());
    return xhr
  }

  loggedIn() {
    // return !!this.cookieService.get('token');
    return !!sessionStorage.getItem('username');
  }


  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }

  logout() {
    sessionStorage.clear();
    // this.cookieService.delete('token');
    this.userLoggedIn.next(false);
    this.router.navigate(['/loginPage']);

  }
}
