<div class="container">
    <div class="row mt-1" [hidden]="sharedService.isAdmin()==true ||sharedService.isStaff()==true || sharedService.isFinance()">
        <div class="col d-flex justify-content-end align-items-right">
            <button class="btn btn-warning" (click)="openUpdateProfileModal()"> <i class="far fa-edit"></i> Edit
                Profile</button>
        </div>
    </div>
    <div class="row mt-3" *ngIf="sharedService.isBranchAccountHandler()==true">
        <div class="col col-12 col-sm-6">
            <div><strong><span>Phone number</span></strong></div>
            <div>
                <p>{{viewCustomer.ContactNo}}</p>
            </div>
        </div>
        <div class="col">
            <div><strong><span>Company Name / Occupation:</span></strong></div>
            <div>
                <p>{{selectedProfile.Occupation}}</p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col col-12 col-sm-6">
            <div><strong><span>Salary</span></strong></div>
            <div>
                <p>RM {{selectedProfile.Salary}}</p>
            </div>
        </div>
        <div class="col">
            <div><strong><span>Salary Day</span></strong></div>
            <div>
                <p>{{selectedProfile.SalaryDay}}HB</p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col col-12 col-sm-6">
            <div><strong><span>DOB</span></strong></div>
            <div>
                <p>{{viewCustomer.DOB }}</p>
            </div>
        </div>
        <div class="col col-12 col-sm-6">
            <div><strong><span>Gender</span></strong></div>
            <div>
                <p>{{viewCustomer.Gender}}</p>
            </div>
        </div>
        <div class="col col-12 col-sm-6">
            <div><strong><span>Race</span></strong></div>
            <div>
                <p>{{viewCustomer.Race}}</p>
            </div>
        </div>
        <div class="col" *ngIf="sharedService.isStaff()==false">
            <div><strong><span>Description</span></strong></div>
            <div>
                <p>{{selectedProfile.Remark}}</p>
            </div>
        </div>

        <div class="col col-12 col-sm-6">
            <div><strong><span>Address</span></strong></div>
            <div>
                <p>{{viewCustomer.Address}}</p>
            </div>
        </div>
        <div class="col col-12 col-sm-6">
            <div><strong><span>Address 2</span></strong></div>
            <div>
                <p>{{viewCustomer.Address2}}</p>
            </div>
        </div>
        <div class="col col-12 col-sm-6">
            <div><strong><span>OtherId (Old IC, Passport etc)</span></strong></div>
            <div>
                <p>{{viewCustomer.OtherID}}</p>
            </div>
        </div>

    </div>
</div>

<div class="card shadow-sm" *ngIf="epfDetails!=undefined">
    <div class="card-body">
        <div class="d-flex justiy-content-end align-items-right">
            <button class="btn btn-warning" (click)="openEditEPFDetailsModal()"
                *ngIf="sharedService.isStaff()==false && sharedService.isAuditor()==false && !sharedService.isFinance()">
                <i class="far fa-edit"></i>
            </button>
        </div>
        <div class="row">
            <div class="col-6"><strong>EPF Date :</strong> {{epfDetails.Date}}</div>
            <div class="col-6"><strong>EPF Amount :</strong> RM{{epfDetails.Amount}}</div>
            <div class="col-6"><strong>Has IAccount:</strong> <span *ngIf="epfDetails.HasIAccount==true"> YES</span>
                <span *ngIf="epfDetails.HasIAccount==false"> NO</span>
            </div>
            <div class="col-6"><strong>iAccount Username : </strong>{{epfDetails.IAccountUsername}}</div>
            <div class="col-6"><strong>iAccount Password :</strong> {{epfDetails.IAccountPassword}}</div>
            <div class="col-6"><strong>Remark : </strong>{{epfDetails.Remark}}</div>
        </div>
    </div>
</div>