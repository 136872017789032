import { Status } from "./status.model"

export class Profile {



    constructor(private _id: number, private _occupation: string, private _salary: number,
        private _salaryDay: number, private _remark: string, private _status: Status[], private _dateCreated: Date, private _customerId: number,
        private _customerCode: string, private _reserve?: number, private _badDebt?: number, private _customerName?: string,
        private _sharedProfileId?: number, private _companyId?: number, private _companyCode?: string) {

    }


    set ID(id: number) { this._id = id }
    get ID(): number { return this._id }

    set Occupation(occupation: string) { this._occupation = occupation }
    get Occupation(): string { return this._occupation }


    set Salary(salary: number) { this._salary = salary }
    get Salary(): number { return this._salary }

    set SalaryDay(salaryDay: number) { this._salaryDay = salaryDay }
    get SalaryDay(): number { return this._salaryDay }

    set Remark(remark: string) { this._remark = remark }
    get Remark(): string { return this._remark }

    set Status(status: Status[]) { this._status = status }
    get Status(): Status[] { return this._status }

    set DateCreated(dateCreated: Date) { this._dateCreated = dateCreated }
    get DateCreated(): Date { return this._dateCreated }

    set CustomerCode(customerCode: string) { this._customerCode = customerCode }
    get CustomerCode(): string { return this._customerCode }

    set Reserve(reserve: number) { this._reserve = reserve }
    get Reserve(): number { return this._reserve }

    set BadDebt(badDebt: number) { this._badDebt = badDebt }
    get BadDebt(): number { return this._badDebt }

    set CustomerID(customerId: number) { this._customerId = customerId }
    get CustomerID(): number { return this._customerId }

    set CustomerName(customerName: string) { this._customerName = customerName }
    get CustomerName(): string { return this._customerName }

    get SharedProfileId(): number {
        return this._sharedProfileId
    }
    set SharedProfileId(value: number) {
        this._sharedProfileId = value
    }

    get CompanyId(): number {
        return this._companyId
    }
    set CompanyId(value: number) {
        this._companyId = value
    }
    get CompanyCode(): string {
        return this._companyCode
    }
    set CompanyCode(value: string) {
        this._companyCode = value
    }

}