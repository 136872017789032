import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { AlertDialogComponent } from 'src/app/Shared/alert-dialog/alert-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { TransactionSubType } from 'src/model/transactionSubType.model';
import { TransactionType } from 'src/model/transactionType.model';


@Component({
  selector: 'app-add-subtype',
  templateUrl: './add-subtype.component.html',
  styleUrls: ['./add-subtype.component.css']
})

export class AddSubtypeComponent implements OnInit {

  constructor(private cdr: ChangeDetectorRef, private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private restapi: RestApiService, private constructApi: ConstructAPI, private sharedService: SharedService, private enums: Enums) { }

  subtypeList: TransactionSubType[] = new Array;
  transactionTypeList: TransactionType[] = new Array;

  //2 way binding variables
  name: string;
  selectedTransactionType: number;


  ngOnInit(): void {
    this.getTransactionType();
    this.getTransactionSubTypes();
  }

  getTransactionType() {
    var xhttp = this.restapi.getRequest(this.constructApi.getAllTransactionTypes());
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let type = new TransactionType(json.id, json.name);

          this.transactionTypeList.push(type);
        });

        this.transactionTypeList.sort((a, b) => ((a.ID > b.ID)) ? 1 : -1);
      }
    };

  }

  getTransactionSubTypes() {
    var xhttp = this.restapi.getRequest(this.constructApi.getAllTransactionSubTypes());
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let type = new TransactionSubType(json.id, json.name, json.type, json.assignable, json.enabled);

          this.subtypeList.push(type);
        });
      }
    };

  }

  isInvalid() {
    return this.name == undefined || this.selectedTransactionType == undefined;
  }
  submit() {
    this.isInvalid() ? this.sharedService.openAlert('Please fill in all the fields!', this.enums.DANGER_ALERT) : this.addSubType();
  }

  addSubType() {
    var data = {
      "name": this.name,
      "type": this.selectedTransactionType
    };
    var xhr = this.restapi.postRequest(this.constructApi.getAddTransactionSubType(), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        var json = JSON.parse(xhr.responseText);
        let type = new TransactionSubType(json.id, json.name, json.type, json.assignable, json.enabled);

        this.subtypeList = this.subtypeList.concat(type);

        this.resetValues();

      }
    }
  }
  reset() {

    this.activeModal.close();
    this.resetValues();
  }

  resetValues() {
    this.name = undefined;
    this.selectedTransactionType = undefined;
  }

  openDeleteConfirmationDialog(subType: TransactionSubType) {
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Are you sure you want to delete " + subType.Name + " ?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something
      if (confirmation == true)
        this.deleteScheme(subType);

    });

  }

  deleteScheme(subType: TransactionSubType) {
    var xhttp = this.restapi.deleteRequest(this.constructApi.getDeleteTransactionSubType(subType.ID));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        for (let i in this.subtypeList) {
          if (this.subtypeList[i].ID == subType.ID) {
            this.subtypeList.splice(Number(i), 1);
            this.cdr.detectChanges();
          }
        }
      }
    };

  }

}
