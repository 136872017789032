<table style="border:none;">
    <!-- <tr>
        <h4 class="fw-bold" style="color: green;">Simpan: RM {{totalReserveAmount}}
        </h4>
    </tr> -->

    <div class="card m-2">
        <div class="card-body">
            <div class="table table-borderless">
                <tbody>
                    <tr>
                        <th>Customer Name: </th>
                        <td>{{viewCustomer.Name}}</td>
                    </tr>
                    <tr>
                        <th>Customer IC: </th>
                        <td>{{sharedService.processICNum(viewCustomer.ICNumber)}}</td>
                    </tr>
                    <tr>
                        <th>Total Simpan: </th>
                        <td>RM {{totalReserveAmount}}</td>
                    </tr>
                </tbody>
            </div>
        </div>
    </div>

    <hr />
    <tr class="mt-2">
        <div class="row">
            <div class="col  pe-0">
                <h5 class="fw-bold">Return Amount: RM</h5>
            </div>
            <div class="col p-0 m-0">
                <input type="number" class="withdrawAmount p-0 m-0" required name="withdrawAmount"
                    [(ngModel)]="withdrawAmount" min=0 placeholder="Amount"
                    (keypress)="sharedService.isNumberKey($event)">
            </div>
        </div>


    </tr>
    <tr style="border-top: 2px solid black;border-bottom:2px solid black">
        <h5 class="fw-bold" style="color: blue;">Simpan left:RM
            {{calculateReserveLeft()||totalReserveAmount}}
        </h5>
    </tr>
</table>

<ng-container *ngIf="checkSimpanLeftEnough()==true;else notEnough">
    <!--Account Number-->
    <div class="row p-3 d-flex justify-content-center align-items-center">

        <div class="col-12">
            <p>To Account</p>
        </div>
        <div class="col-12">
            <select class="browser-default form-select" name="selectedAccount" [(ngModel)]="selectedAccountId">
                <optgroup label="Company Account">
                    <option *ngFor="let account of companyBankAccountList" [value]="account.ID"
                        [disabled]="isBalanceInsufficient(account.Balance)">
                        {{account.Name}} (RM {{account.Balance}})
                    </option>
                </optgroup>

                <optgroup label="Cash Account">
                    <option *ngFor="let account of cashAccountList" [value]="account.ID"
                        [disabled]="isBalanceInsufficient(account.Balance)">
                        {{account.Name}} (RM {{account.Balance}})
                    </option>
                </optgroup>
            </select>
        </div>

    </div>

    <!--Payment Date-->
    <div class=" col-12 d-flex justify-content-start align-items-left">
        <mat-form-field class="full-width-formField bar ">
            <input matInput class="textInputLong initialDatePicker" [matDatepicker]="myDatepicker" name="paymentDate"
                [(ngModel)]="paymentDate" placeholder="Payment Date">
            <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #myDatepicker name="date"></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="col-12 d-flex justify-content-start align-items-left">
        <div class="group">
            <input type="text" required class="textInputLong" name="remark" [(ngModel)]="remark">
            <span class="highlight"></span>
            <span class="bar barLong"></span>
            <label> Remark</label>
        </div>
    </div>
    <hr />

    <div class="">
        <div class="row ">
            <div class="col-12">
                <p class="text-center text-info fw-bold">If you confirm to return amount back to customer,
                    please key in customer's IC
                    Number without (-). </p>
                <p class="text-center text-info fw-bold">Eg: 999999119999</p>
            </div>
            <div class="col-12 d-flex justify-content-center align-items-center">
                <input type="text" class="confirmationIC p-0 m-0" required name="confirmationIC"
                    [(ngModel)]="confirmationIC" placeholder="IC Number" (keypress)="sharedService.isNumberKey($event)">
            </div>

            <div class="col-12 mt-2">
                <p class="text-center text-secondary fw-bold">Who made this simpan return? Please key in your
                    username: </p>
            </div>
            <div class="col-12 d-flex justify-content-center align-items-center">
                <input type="text" class="confirmationUsername p-0 m-0" required name="confirmationUsername"
                    [(ngModel)]="confirmationUsername" placeholder="Username">
            </div>

            <div class="col d-flex justify-content-center align-items-center mt-2">
                <button class="btn btn-success" (click)="submit()" [disabled]="disabledSubmit">
                    OK
                </button>
                <button class="btn btn-danger ms-2" (click)="close()">
                    Cancel
                </button>
            </div>
        </div>
    </div>

</ng-container>
<ng-template #notEnough>
    <p class="text-danger fw-bold bg-warning"> Cannot make payment, not enough simpan!</p>
</ng-template>