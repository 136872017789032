export class Transaction {
    constructor(
        private _id: number,
        private _remark: string,
        private _amount: number,
        private _closingBalance: number,
        private _accountId: number,
        private _date: Date,
        private _type: number,
        private _handleBy: string,
        private _receiptID?: number,
        private _customerID?: number,
        private _customerName?: string,
        private _customerIC?: string,
        private _customerOccupation?: string,
        private _subtype?: number,
        private _groupId?: number,
        private _setId?: number,

    ) { }


    set ID(id: number) { this._id = id }
    get ID(): number { return this._id }


    set ReceiptID(receiptID: number) { this._receiptID = receiptID }
    get ReceiptID(): number { return this._receiptID }

    set HandleBy(handleBy: string) { this._handleBy = handleBy }
    get HandleBy(): string { return this._handleBy }

    set Type(type: number) { this._type = type }
    get Type(): number { return this._type }

    set Remark(remark: string) { this._remark = remark }
    get Remark(): string { return this._remark }


    set Amount(amount: number) { this._amount = amount }
    get Amount(): number { return this._amount }


    set ClosingBalance(closingBalance: number) { this._closingBalance = closingBalance }
    get ClosingBalance(): number { return this._closingBalance }

    set AccountId(accountId: number) { this._accountId = accountId }
    get AccountId(): number { return this._accountId }

    set Date(date: Date) { this._date = date }
    get Date(): Date { return this._date }

    set CustomerID(customerID: number) { this._customerID = customerID }
    get CustomerID(): number { return this._customerID }

    set CustomerName(customerName: string) { this._customerName = customerName }
    get CustomerName(): string { return this._customerName }

    set CustomerIC(customerIC: string) { this._customerIC = customerIC }
    get CustomerIC(): string { return this._customerIC }

    set CustomerOccupation(customerOccupation: string) { this._customerOccupation = customerOccupation }
    get CustomerOccupation(): string { return this._customerOccupation }

    set Subtype(subtype: number) { this._subtype = subtype }
    get Subtype(): number { return this._subtype }

    set GroupId(groupId: number) { this._groupId = groupId }
    get GroupId(): number { return this._groupId }

    set SetId(setId: number) { this._setId = setId }
    get SetId(): number { return this._setId }
}