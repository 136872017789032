<form *ngIf="requestList!=undefined">
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">{{requestList[0].CustomerName}}
            ({{sharedService.processICNum(requestList[0].CustomerIC)}})</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()" aria-label="Close"></button>
    </div>

    <div class="modal-body">
        <div class="col-12 d-flex justify-content-end align-items-right mb-2" *ngIf="loanGroupId!=undefined">
            <button class="btn btn-success me-2" (click)=" openShowWhatsappClipboardModal()">
                <i class="fab fa-whatsapp"></i> Send Shared Whatsapp
            </button>
        </div>
        <ng-container *ngFor="let request of requestList;let i = index">


            <div class="container-fluid p-0">
                <div class="row" *ngIf="sharedService.isAdmin()|| sharedService.isBossAccount()">
                    <div class="col-12" *ngIf="request.VerifiedOn!=undefined" style="background-color: #5cb85c">
                        <span class="p-0 m-0" style="color: white;"><strong>Verified by {{request.VerifiedBy}} on
                                {{request.VerifiedOn |
                                date:'dd-MM-yyyy'}}</strong></span>

                        <button class="btn btn-dark ms-2" (click)="confirmBeforeVerifyOrUnverify(false,i)"> <i
                                class="fas fa-user-times"></i></button>
                    </div>

                    <div class="col-12" *ngIf="request.VerifiedOn==undefined" style="background-color: black;">
                        <span class="p-0 m-0" style="color: white;"> <strong>Please Verify Customer</strong> </span>
                        <button class="btn btn-success ms-2" (click)="confirmBeforeVerifyOrUnverify(true,i)"> <i
                                class="fas fa-user-check"></i></button>
                    </div>
                </div>

                <app-loan-request-details [request]="request" [isExpanded]="true" [showPrevious]="true"
                    [includeShared]="((requestList.length==1)) && (sharedService.isAdmin()|| sharedService.isBossAccount())">
                </app-loan-request-details>

                <hr />


            </div>
        </ng-container>



        <app-loan-request-approval [requestList]="requestList" [loanGroupId]="loanGroupId"></app-loan-request-approval>


    </div>
</form>