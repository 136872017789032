import { style, animate, animation, keyframes } from '@angular/animations';

const base$3 = [
    /*@__PURE__*/ style({
        opacity: `{{startOpacity}}`
    }),
    /*@__PURE__*/ animate(`{{duration}} {{delay}} {{easing}}`, 
    /*@__PURE__*/ style({
        opacity: `{{endOpacity}}`
    }))
];
const fadeIn = /*@__PURE__*/ animation(base$3, {
    params: {
        delay: '0s',
        duration: '350ms',
        easing: "cubic-bezier(0.390, 0.575, 0.565, 1.000)" /* EaseOut.Sine */,
        endOpacity: 1,
        startOpacity: 0
    }
});
const fadeOut = /*@__PURE__*/ animation(base$3, {
    params: {
        delay: '0s',
        duration: '350ms',
        easing: "cubic-bezier(0.390, 0.575, 0.565, 1.000)" /* EaseOut.Sine */,
        endOpacity: 0,
        startOpacity: 1
    }
});

const baseRecipe$2 = [
    /*@__PURE__*/ style({
        backfaceVisibility: 'hidden',
        transformStyle: 'preserve-3d'
    }),
    /*@__PURE__*/ animate(`{{duration}} {{delay}} {{easing}}`, 
    /*@__PURE__*/ keyframes([
        /*@__PURE__*/ style({
            offset: 0,
            transform: `translateZ({{startDistance}})
                rotate3d({{rotateX}}, {{rotateY}}, {{rotateZ}}, {{startAngle}}deg)`
        }),
        /*@__PURE__*/ style({
            offset: 1,
            transform: `translateZ({{endDistance}})
                rotate3d({{rotateX}}, {{rotateY}}, {{rotateZ}}, {{endAngle}}deg)`
        })
    ]))
];
const flipTop = /*@__PURE__*/ animation(baseRecipe$2, {
    params: {
        delay: '0s',
        duration: '600ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endAngle: 180,
        endDistance: '0px',
        rotateX: 1,
        rotateY: 0,
        rotateZ: 0,
        startAngle: 0,
        startDistance: '0px'
    }
});
const flipBottom = /*@__PURE__*/ animation(baseRecipe$2, {
    params: {
        delay: '0s',
        duration: '600ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endDistance: '0px',
        rotateX: 1,
        rotateY: 0,
        rotateZ: 0,
        startAngle: 0,
        startDistance: '0px',
        endAngle: -180
    }
});
const flipLeft = /*@__PURE__*/ animation(baseRecipe$2, {
    params: {
        delay: '0s',
        duration: '600ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endAngle: 180,
        endDistance: '0px',
        rotateZ: 0,
        startAngle: 0,
        startDistance: '0px',
        rotateX: 0,
        rotateY: 1
    }
});
const flipRight = /*@__PURE__*/ animation(baseRecipe$2, {
    params: {
        delay: '0s',
        duration: '600ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endDistance: '0px',
        rotateZ: 0,
        startAngle: 0,
        startDistance: '0px',
        endAngle: -180,
        rotateX: 0,
        rotateY: 1
    }
});
const flipHorFwd = /*@__PURE__*/ animation(baseRecipe$2, {
    params: {
        delay: '0s',
        duration: '600ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endAngle: 180,
        rotateX: 1,
        rotateY: 0,
        rotateZ: 0,
        startAngle: 0,
        startDistance: '0px',
        endDistance: '170px'
    }
});
const flipHorBck = /*@__PURE__*/ animation(baseRecipe$2, {
    params: {
        delay: '0s',
        duration: '600ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endAngle: 180,
        rotateX: 1,
        rotateY: 0,
        rotateZ: 0,
        startAngle: 0,
        startDistance: '0px',
        endDistance: '-170px'
    }
});
const flipVerFwd = /*@__PURE__*/ animation(baseRecipe$2, {
    params: {
        delay: '0s',
        duration: '600ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endAngle: 180,
        rotateZ: 0,
        startAngle: 0,
        startDistance: '0px',
        endDistance: '170px',
        rotateX: 0,
        rotateY: 1
    }
});
const flipVerBck = /*@__PURE__*/ animation(baseRecipe$2, {
    params: {
        delay: '0s',
        duration: '600ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endAngle: 180,
        rotateZ: 0,
        startAngle: 0,
        startDistance: '0px',
        endDistance: '-170px',
        rotateX: 0,
        rotateY: 1
    }
});

const base$2 = [
    /*@__PURE__*/ style({
        opacity: `{{ startOpacity }}`,
        height: `{{ startHeight }}`,
        paddingBlock: `{{ startPadding }}`
    }),
    /*@__PURE__*/ animate(`{{duration}} {{delay}} {{easing}}`, 
    /*@__PURE__*/ style({
        opacity: `{{ endOpacity }}`,
        height: `{{ endHeight }}`,
        paddingBlock: `{{ endPadding }}`
    }))
];
const growVerIn = /*@__PURE__*/ animation(base$2, {
    params: {
        delay: '0s',
        duration: '350ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        startOpacity: 0,
        endOpacity: 1,
        startHeight: '0px',
        endHeight: '*',
        startPadding: '0px',
        endPadding: '*'
    }
});
const growVerOut = /*@__PURE__*/ animation(base$2, {
    params: {
        delay: '0s',
        duration: '350ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        startOpacity: 1,
        endOpacity: 0,
        startHeight: '*',
        endHeight: '0px',
        startPadding: '*',
        endPadding: '0px'
    }
});

const baseRecipe$1 = [
    /*@__PURE__*/ animate(`{{duration}} {{delay}} {{easing}}`, 
    /*@__PURE__*/ keyframes([
        /*@__PURE__*/ style({
            offset: 0,
            transform: `rotate(0deg) translate{{direction}}(0)`,
            transformOrigin: `{{xPos}} {{yPos}}`
        }),
        /*@__PURE__*/ style({
            offset: 0.1,
            transform: `rotate({{endAngle}}deg) translate{{direction}}(-{{startDistance}})`
        }),
        /*@__PURE__*/ style({
            offset: 0.2,
            transform: `rotate(-{{startAngle}}deg) translate{{direction}}({{startDistance}})`
        }),
        /*@__PURE__*/ style({
            offset: 0.3,
            transform: `rotate({{startAngle}}deg) translate{{direction}}(-{{startDistance}})`
        }),
        /*@__PURE__*/ style({
            offset: 0.4,
            transform: `rotate(-{{startAngle}}deg) translate{{direction}}({{startDistance}})`
        }),
        /*@__PURE__*/ style({
            offset: 0.5,
            transform: `rotate({{startAngle}}deg) translate{{direction}}(-{{startDistance}})`
        }),
        /*@__PURE__*/ style({
            offset: 0.6,
            transform: `rotate(-{{startAngle}}deg) translate{{direction}}({{startDistance}})`
        }),
        /*@__PURE__*/ style({
            offset: 0.7,
            transform: `rotate({{startAngle}}deg) translate{{direction}}(-{{startDistance}})`
        }),
        /*@__PURE__*/ style({
            offset: 0.8,
            transform: `rotate(-{{endAngle}}deg) translate{{direction}}({{endDistance}})`
        }),
        /*@__PURE__*/ style({
            offset: 0.9,
            transform: `rotate({{endAngle}}deg) translate{{direction}}(-{{endDistance}})`
        }),
        /*@__PURE__*/ style({
            offset: 1,
            transform: `rotate(0deg) translate{{direction}}(0)`,
            transformOrigin: `{{xPos}} {{yPos}}`
        })
    ]))
];
const shakeHor = /*@__PURE__*/ animation(baseRecipe$1, {
    params: {
        delay: '0s',
        direction: 'X',
        duration: '800ms',
        easing: "cubic-bezier(0.455, 0.030, 0.515, 0.955)" /* EaseInOut.Quad */,
        endAngle: 0,
        endDistance: '8px',
        startAngle: 0,
        startDistance: '10px',
        xPos: 'center',
        yPos: 'center'
    }
});
const shakeVer = /*@__PURE__*/ animation(baseRecipe$1, {
    params: {
        delay: '0s',
        direction: 'Y',
        duration: '800ms',
        easing: "cubic-bezier(0.455, 0.030, 0.515, 0.955)" /* EaseInOut.Quad */,
        endAngle: 0,
        endDistance: '8px',
        startAngle: 0,
        startDistance: '10px',
        xPos: 'center',
        yPos: 'center'
    }
});
const shakeTop = /*@__PURE__*/ animation(baseRecipe$1, {
    params: {
        delay: '0s',
        direction: 'X',
        duration: '800ms',
        easing: "cubic-bezier(0.455, 0.030, 0.515, 0.955)" /* EaseInOut.Quad */,
        xPos: 'center',
        endAngle: 2,
        endDistance: '0',
        startAngle: 4,
        startDistance: '0',
        yPos: 'top'
    }
});
const shakeBottom = /*@__PURE__*/ animation(baseRecipe$1, {
    params: {
        delay: '0s',
        duration: '800ms',
        easing: "cubic-bezier(0.455, 0.030, 0.515, 0.955)" /* EaseInOut.Quad */,
        xPos: 'center',
        direction: 'Y',
        endAngle: 2,
        endDistance: '0',
        startAngle: 4,
        startDistance: '0',
        yPos: 'bottom'
    }
});
const shakeRight = /*@__PURE__*/ animation(baseRecipe$1, {
    params: {
        delay: '0s',
        duration: '800ms',
        easing: "cubic-bezier(0.455, 0.030, 0.515, 0.955)" /* EaseInOut.Quad */,
        direction: 'Y',
        endAngle: 2,
        endDistance: '0',
        startAngle: 4,
        startDistance: '0',
        xPos: 'right',
        yPos: 'center'
    }
});
const shakeLeft = /*@__PURE__*/ animation(baseRecipe$1, {
    params: {
        delay: '0s',
        duration: '800ms',
        easing: "cubic-bezier(0.455, 0.030, 0.515, 0.955)" /* EaseInOut.Quad */,
        direction: 'Y',
        endAngle: 2,
        endDistance: '0',
        startAngle: 4,
        startDistance: '0',
        xPos: 'left',
        yPos: 'center'
    }
});
const shakeCenter = /*@__PURE__*/ animation(baseRecipe$1, {
    params: {
        delay: '0s',
        duration: '800ms',
        easing: "cubic-bezier(0.455, 0.030, 0.515, 0.955)" /* EaseInOut.Quad */,
        direction: 'Y',
        endAngle: 8,
        endDistance: '0',
        startAngle: 10,
        startDistance: '0',
        xPos: 'center',
        yPos: 'center'
    }
});
const shakeTr = /*@__PURE__*/ animation(baseRecipe$1, {
    params: {
        delay: '0s',
        duration: '800ms',
        easing: "cubic-bezier(0.455, 0.030, 0.515, 0.955)" /* EaseInOut.Quad */,
        direction: 'Y',
        endAngle: 2,
        endDistance: '0',
        startAngle: 4,
        startDistance: '0',
        xPos: 'right',
        yPos: 'top'
    }
});
const shakeBr = /*@__PURE__*/ animation(baseRecipe$1, {
    params: {
        delay: '0s',
        duration: '800ms',
        easing: "cubic-bezier(0.455, 0.030, 0.515, 0.955)" /* EaseInOut.Quad */,
        direction: 'Y',
        endAngle: 2,
        endDistance: '0',
        startAngle: 4,
        startDistance: '0',
        xPos: 'right',
        yPos: 'bottom'
    }
});
const shakeBl = /*@__PURE__*/ animation(baseRecipe$1, {
    params: {
        delay: '0s',
        duration: '800ms',
        easing: "cubic-bezier(0.455, 0.030, 0.515, 0.955)" /* EaseInOut.Quad */,
        direction: 'Y',
        endAngle: 2,
        endDistance: '0',
        startAngle: 4,
        startDistance: '0',
        xPos: 'left',
        yPos: 'bottom'
    }
});
const shakeTl = /*@__PURE__*/ animation(baseRecipe$1, {
    params: {
        delay: '0s',
        duration: '800ms',
        easing: "cubic-bezier(0.455, 0.030, 0.515, 0.955)" /* EaseInOut.Quad */,
        direction: 'Y',
        endAngle: 2,
        endDistance: '0',
        startAngle: 4,
        startDistance: '0',
        xPos: 'left',
        yPos: 'top'
    }
});

const heartbeatBase = [
    /*@__PURE__*/ style({
        animationTimingFunction: `ease-out`,
        transform: `scale(1)`,
        transformOrigin: `center center`
    }),
    /*@__PURE__*/ animate(`{{duration}} {{delay}} {{easing}}`, 
    /*@__PURE__*/ keyframes([
        /*@__PURE__*/ style({
            animationTimingFunction: `ease-in`,
            offset: 0.1,
            transform: `scale(0.91)`
        }),
        /*@__PURE__*/ style({
            animationTimingFunction: `ease-out`,
            offset: 0.17,
            transform: `scale(0.98)`
        }),
        /*@__PURE__*/ style({
            animationTimingFunction: `ease-in`,
            offset: 0.33,
            transform: `scale(0.87)`
        }),
        /*@__PURE__*/ style({
            animationTimingFunction: `ease-out`,
            offset: 0.45,
            transform: `scale(1)`
        })
    ]))
];
const pulsateBase = [
    /*@__PURE__*/ animate(`{{duration}} {{delay}} {{easing}}`, 
    /*@__PURE__*/ keyframes([
        /*@__PURE__*/ style({
            offset: 0,
            transform: `scale({{fromScale}})`
        }),
        /*@__PURE__*/ style({
            offset: 0.5,
            transform: `scale({{toScale}})`
        }),
        /*@__PURE__*/ style({
            offset: 1,
            transform: `scale({{fromScale}})`
        })
    ]))
];
const blinkBase = [
    /*@__PURE__*/ animate(`{{duration}} {{delay}} {{easing}}`, 
    /*@__PURE__*/ keyframes([
        /*@__PURE__*/ style({
            offset: 0,
            opacity: .8,
            transform: `scale({{fromScale}})`
        }),
        /*@__PURE__*/ style({
            offset: 0.8,
            opacity: 0,
            transform: `scale({{midScale}})`
        }),
        /*@__PURE__*/ style({
            offset: 1,
            opacity: 0,
            transform: `scale({{toScale}})`
        })
    ]))
];
const pulsateFwd = /*@__PURE__*/ animation(pulsateBase, {
    params: {
        delay: '0s',
        duration: '.5s',
        easing: 'ease-in-out',
        fromScale: 1,
        toScale: 1.1
    }
});
const pulsateBck = /*@__PURE__*/ animation(pulsateBase, {
    params: {
        delay: '0s',
        duration: '.5s',
        easing: 'ease-in-out',
        fromScale: 1,
        toScale: .9
    }
});
const heartbeat = /*@__PURE__*/ animation(heartbeatBase, {
    params: {
        delay: '0s',
        duration: '1.5s',
        easing: 'ease-in-out'
    }
});
const blink = /*@__PURE__*/ animation(blinkBase, {
    params: {
        delay: '0s',
        duration: '.8s',
        easing: 'ease-in-out',
        fromScale: .2,
        midScale: 1.2,
        toScale: 2.2
    }
});

const baseRecipe = [
    /*@__PURE__*/ style({
        opacity: `{{startOpacity}}`,
        transform: `rotate3d({{rotateX}},{{rotateY}},{{rotateZ}},{{startAngle}}deg)`,
        transformOrigin: `{{xPos}} {{yPos}}`
    }),
    /*@__PURE__*/ animate(`{{duration}} {{delay}} {{easing}}`, 
    /*@__PURE__*/ style({
        offset: 0,
        opacity: `{{endOpacity}}`,
        transform: `rotate3d({{rotateX}},{{rotateY}},{{rotateZ}},{{endAngle}}deg)`,
        transformOrigin: `{{xPos}} {{yPos}}`
    }))
];
const rotateInCenter = /*@__PURE__*/ animation(baseRecipe, {
    params: {
        delay: '0s',
        duration: '600ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endAngle: 0,
        endOpacity: 1,
        rotateX: 0,
        rotateY: 0,
        rotateZ: 1,
        startAngle: -360,
        startOpacity: 0,
        xPos: 'center',
        yPos: 'center'
    }
});
const rotateOutCenter = /*@__PURE__*/ animation(baseRecipe, {
    params: {
        delay: '0s',
        duration: '600ms',
        endAngle: 0,
        rotateX: 0,
        rotateY: 0,
        rotateZ: 1,
        startAngle: -360,
        xPos: 'center',
        yPos: 'center',
        easing: "cubic-bezier(0.550, 0.085, 0.680, 0.530)" /* EaseIn.Quad */,
        endOpacity: 0,
        startOpacity: 1
    }
});
const rotateInTop = /*@__PURE__*/ animation(baseRecipe, {
    params: {
        delay: '0s',
        duration: '600ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endAngle: 0,
        endOpacity: 1,
        rotateX: 0,
        rotateY: 0,
        rotateZ: 1,
        startAngle: -360,
        startOpacity: 0,
        yPos: 'center',
        xPos: 'top'
    }
});
const rotateOutTop = /*@__PURE__*/ animation(baseRecipe, {
    params: {
        delay: '0s',
        duration: '600ms',
        endAngle: 0,
        rotateX: 0,
        rotateY: 0,
        rotateZ: 1,
        startAngle: -360,
        yPos: 'center',
        easing: "cubic-bezier(0.550, 0.085, 0.680, 0.530)" /* EaseIn.Quad */,
        endOpacity: 0,
        startOpacity: 1,
        xPos: 'top'
    }
});
const rotateInRight = /*@__PURE__*/ animation(baseRecipe, {
    params: {
        delay: '0s',
        duration: '600ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endAngle: 0,
        endOpacity: 1,
        rotateX: 0,
        rotateY: 0,
        rotateZ: 1,
        startAngle: -360,
        startOpacity: 0,
        yPos: 'center',
        xPos: 'right'
    }
});
const rotateOutRight = /*@__PURE__*/ animation(baseRecipe, {
    params: {
        delay: '0s',
        duration: '600ms',
        endAngle: 0,
        rotateX: 0,
        rotateY: 0,
        rotateZ: 1,
        startAngle: -360,
        yPos: 'center',
        easing: "cubic-bezier(0.550, 0.085, 0.680, 0.530)" /* EaseIn.Quad */,
        endOpacity: 0,
        startOpacity: 1,
        xPos: 'right'
    }
});
const rotateInBottom = /*@__PURE__*/ animation(baseRecipe, {
    params: {
        delay: '0s',
        duration: '600ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endAngle: 0,
        endOpacity: 1,
        rotateX: 0,
        rotateY: 0,
        rotateZ: 1,
        startAngle: -360,
        startOpacity: 0,
        yPos: 'center',
        xPos: 'bottom'
    }
});
const rotateOutBottom = /*@__PURE__*/ animation(baseRecipe, {
    params: {
        delay: '0s',
        duration: '600ms',
        endAngle: 0,
        rotateX: 0,
        rotateY: 0,
        rotateZ: 1,
        startAngle: -360,
        yPos: 'center',
        easing: "cubic-bezier(0.550, 0.085, 0.680, 0.530)" /* EaseIn.Quad */,
        endOpacity: 0,
        startOpacity: 1,
        xPos: 'bottom'
    }
});
const rotateInLeft = /*@__PURE__*/ animation(baseRecipe, {
    params: {
        delay: '0s',
        duration: '600ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endAngle: 0,
        endOpacity: 1,
        rotateX: 0,
        rotateY: 0,
        rotateZ: 1,
        startAngle: -360,
        startOpacity: 0,
        yPos: 'center',
        xPos: 'left'
    }
});
const rotateOutLeft = /*@__PURE__*/ animation(baseRecipe, {
    params: {
        delay: '0s',
        duration: '600ms',
        endAngle: 0,
        rotateX: 0,
        rotateY: 0,
        rotateZ: 1,
        startAngle: -360,
        yPos: 'center',
        easing: "cubic-bezier(0.550, 0.085, 0.680, 0.530)" /* EaseIn.Quad */,
        endOpacity: 0,
        startOpacity: 1,
        xPos: 'left'
    }
});
const rotateInTr = /*@__PURE__*/ animation(baseRecipe, {
    params: {
        delay: '0s',
        duration: '600ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endAngle: 0,
        endOpacity: 1,
        rotateX: 0,
        rotateY: 0,
        rotateZ: 1,
        startAngle: -360,
        startOpacity: 0,
        xPos: 'right',
        yPos: 'top'
    }
});
const rotateOutTr = /*@__PURE__*/ animation(baseRecipe, {
    params: {
        delay: '0s',
        duration: '600ms',
        endAngle: 0,
        rotateX: 0,
        rotateY: 0,
        rotateZ: 1,
        startAngle: -360,
        easing: "cubic-bezier(0.550, 0.085, 0.680, 0.530)" /* EaseIn.Quad */,
        endOpacity: 0,
        startOpacity: 1,
        xPos: 'right',
        yPos: 'top'
    }
});
const rotateInBr = /*@__PURE__*/ animation(baseRecipe, {
    params: {
        delay: '0s',
        duration: '600ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endAngle: 0,
        endOpacity: 1,
        rotateX: 0,
        rotateY: 0,
        rotateZ: 1,
        startAngle: -360,
        startOpacity: 0,
        xPos: 'right',
        yPos: 'bottom'
    }
});
const rotateOutBr = /*@__PURE__*/ animation(baseRecipe, {
    params: {
        delay: '0s',
        duration: '600ms',
        endAngle: 0,
        rotateX: 0,
        rotateY: 0,
        rotateZ: 1,
        startAngle: -360,
        easing: "cubic-bezier(0.550, 0.085, 0.680, 0.530)" /* EaseIn.Quad */,
        endOpacity: 0,
        startOpacity: 1,
        xPos: 'right',
        yPos: 'bottom'
    }
});
const rotateInBl = /*@__PURE__*/ animation(baseRecipe, {
    params: {
        delay: '0s',
        duration: '600ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endAngle: 0,
        endOpacity: 1,
        rotateX: 0,
        rotateY: 0,
        rotateZ: 1,
        startAngle: -360,
        startOpacity: 0,
        xPos: 'left',
        yPos: 'bottom'
    }
});
const rotateOutBl = /*@__PURE__*/ animation(baseRecipe, {
    params: {
        delay: '0s',
        duration: '600ms',
        endAngle: 0,
        rotateX: 0,
        rotateY: 0,
        rotateZ: 1,
        startAngle: -360,
        easing: "cubic-bezier(0.550, 0.085, 0.680, 0.530)" /* EaseIn.Quad */,
        endOpacity: 0,
        startOpacity: 1,
        xPos: 'left',
        yPos: 'bottom'
    }
});
const rotateInTl = /*@__PURE__*/ animation(baseRecipe, {
    params: {
        delay: '0s',
        duration: '600ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endAngle: 0,
        endOpacity: 1,
        rotateX: 0,
        rotateY: 0,
        rotateZ: 1,
        startAngle: -360,
        startOpacity: 0,
        xPos: 'left',
        yPos: 'top'
    }
});
const rotateOutTl = /*@__PURE__*/ animation(baseRecipe, {
    params: {
        delay: '0s',
        duration: '600ms',
        endAngle: 0,
        rotateX: 0,
        rotateY: 0,
        rotateZ: 1,
        startAngle: -360,
        easing: "cubic-bezier(0.550, 0.085, 0.680, 0.530)" /* EaseIn.Quad */,
        endOpacity: 0,
        startOpacity: 1,
        xPos: 'left',
        yPos: 'top'
    }
});
const rotateInDiagonal1 = /*@__PURE__*/ animation(baseRecipe, {
    params: {
        delay: '0s',
        duration: '600ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endAngle: 0,
        endOpacity: 1,
        startAngle: -360,
        startOpacity: 0,
        xPos: 'center',
        yPos: 'center',
        rotateX: 1,
        rotateY: 1,
        rotateZ: 0
    }
});
const rotateOutDiagonal1 = /*@__PURE__*/ animation(baseRecipe, {
    params: {
        delay: '0s',
        duration: '600ms',
        endAngle: 0,
        startAngle: -360,
        xPos: 'center',
        yPos: 'center',
        easing: "cubic-bezier(0.550, 0.085, 0.680, 0.530)" /* EaseIn.Quad */,
        endOpacity: 0,
        startOpacity: 1,
        rotateX: 1,
        rotateY: 1,
        rotateZ: 0
    }
});
const rotateInDiagonal2 = /*@__PURE__*/ animation(baseRecipe, {
    params: {
        delay: '0s',
        duration: '600ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endAngle: 0,
        endOpacity: 1,
        startAngle: -360,
        startOpacity: 0,
        xPos: 'center',
        yPos: 'center',
        rotateX: -1,
        rotateY: 1,
        rotateZ: 0
    }
});
const rotateOutDiagonal2 = /*@__PURE__*/ animation(baseRecipe, {
    params: {
        delay: '0s',
        duration: '600ms',
        endAngle: 0,
        startAngle: -360,
        xPos: 'center',
        yPos: 'center',
        easing: "cubic-bezier(0.550, 0.085, 0.680, 0.530)" /* EaseIn.Quad */,
        endOpacity: 0,
        startOpacity: 1,
        rotateX: -1,
        rotateY: 1,
        rotateZ: 0
    }
});
const rotateInHor = /*@__PURE__*/ animation(baseRecipe, {
    params: {
        delay: '0s',
        duration: '600ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endAngle: 0,
        endOpacity: 1,
        startAngle: -360,
        startOpacity: 0,
        xPos: 'center',
        yPos: 'center',
        rotateX: 0,
        rotateY: 1,
        rotateZ: 0
    }
});
const rotateOutHor = /*@__PURE__*/ animation(baseRecipe, {
    params: {
        delay: '0s',
        duration: '600ms',
        endAngle: 0,
        startAngle: -360,
        xPos: 'center',
        yPos: 'center',
        easing: "cubic-bezier(0.550, 0.085, 0.680, 0.530)" /* EaseIn.Quad */,
        endOpacity: 0,
        startOpacity: 1,
        rotateX: 0,
        rotateY: 1,
        rotateZ: 0
    }
});
const rotateInVer = /*@__PURE__*/ animation(baseRecipe, {
    params: {
        delay: '0s',
        duration: '600ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endAngle: 0,
        endOpacity: 1,
        startAngle: -360,
        startOpacity: 0,
        xPos: 'center',
        yPos: 'center',
        rotateX: 1,
        rotateY: 0,
        rotateZ: 0
    }
});
const rotateOutVer = /*@__PURE__*/ animation(baseRecipe, {
    params: {
        delay: '0s',
        duration: '600ms',
        endAngle: 0,
        startAngle: -360,
        xPos: 'center',
        yPos: 'center',
        easing: "cubic-bezier(0.550, 0.085, 0.680, 0.530)" /* EaseIn.Quad */,
        endOpacity: 0,
        startOpacity: 1,
        rotateX: 1,
        rotateY: 0,
        rotateZ: 0
    }
});

const base$1 = [
    /*@__PURE__*/ style({
        opacity: `{{startOpacity}}`,
        transform: `scale{{direction}}({{fromScale}})`,
        transformOrigin: `{{xPos}} {{yPos}}`
    }),
    /*@__PURE__*/ animate(`{{duration}} {{delay}} {{easing}}`, 
    /*@__PURE__*/ style({
        opacity: `{{endOpacity}}`,
        transform: `scale{{direction}}({{toScale}})`,
        transformOrigin: `{{xPos}} {{yPos}}`
    }))
];
const scaleInCenter = /*@__PURE__*/ animation(base$1, {
    params: {
        delay: '0s',
        direction: '',
        duration: '350ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endOpacity: 1,
        fromScale: .5,
        startOpacity: 0,
        toScale: 1,
        xPos: '50%',
        yPos: '50%'
    }
});
const scaleInBl = /*@__PURE__*/ animation(base$1, {
    params: {
        delay: '0s',
        direction: '',
        duration: '350ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endOpacity: 1,
        fromScale: .5,
        startOpacity: 0,
        toScale: 1,
        xPos: '0',
        yPos: '100%'
    }
});
const scaleInVerCenter = /*@__PURE__*/ animation(base$1, {
    params: {
        delay: '0s',
        duration: '350ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endOpacity: 1,
        startOpacity: 0,
        toScale: 1,
        xPos: '50%',
        yPos: '50%',
        direction: 'Y',
        fromScale: .4
    }
});
const scaleInTop = /*@__PURE__*/ animation(base$1, {
    params: {
        delay: '0s',
        direction: '',
        duration: '350ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endOpacity: 1,
        fromScale: .5,
        startOpacity: 0,
        toScale: 1,
        xPos: '50%',
        yPos: '0'
    }
});
const scaleInLeft = /*@__PURE__*/ animation(base$1, {
    params: {
        delay: '0s',
        direction: '',
        duration: '350ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endOpacity: 1,
        fromScale: .5,
        startOpacity: 0,
        toScale: 1,
        xPos: '0',
        yPos: '50%'
    }
});
const scaleInVerTop = /*@__PURE__*/ animation(base$1, {
    params: {
        delay: '0s',
        duration: '350ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endOpacity: 1,
        startOpacity: 0,
        toScale: 1,
        direction: 'Y',
        fromScale: .4,
        xPos: '100%',
        yPos: '0'
    }
});
const scaleInTr = /*@__PURE__*/ animation(base$1, {
    params: {
        delay: '0s',
        direction: '',
        duration: '350ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endOpacity: 1,
        fromScale: .5,
        startOpacity: 0,
        toScale: 1,
        xPos: '100%',
        yPos: '0'
    }
});
const scaleInTl = /*@__PURE__*/ animation(base$1, {
    params: {
        delay: '0s',
        direction: '',
        duration: '350ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endOpacity: 1,
        fromScale: .5,
        startOpacity: 0,
        toScale: 1,
        xPos: '0',
        yPos: '0'
    }
});
const scaleInVerBottom = /*@__PURE__*/ animation(base$1, {
    params: {
        delay: '0s',
        duration: '350ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endOpacity: 1,
        startOpacity: 0,
        toScale: 1,
        direction: 'Y',
        fromScale: .4,
        xPos: '0',
        yPos: '100%'
    }
});
const scaleInRight = /*@__PURE__*/ animation(base$1, {
    params: {
        delay: '0s',
        direction: '',
        duration: '350ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endOpacity: 1,
        fromScale: .5,
        startOpacity: 0,
        toScale: 1,
        xPos: '100%',
        yPos: '50%'
    }
});
const scaleInHorCenter = /*@__PURE__*/ animation(base$1, {
    params: {
        delay: '0s',
        duration: '350ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endOpacity: 1,
        startOpacity: 0,
        toScale: 1,
        xPos: '50%',
        yPos: '50%',
        direction: 'X',
        fromScale: .4
    }
});
const scaleInBr = /*@__PURE__*/ animation(base$1, {
    params: {
        delay: '0s',
        direction: '',
        duration: '350ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endOpacity: 1,
        fromScale: .5,
        startOpacity: 0,
        toScale: 1,
        xPos: '100%',
        yPos: '100%'
    }
});
const scaleInHorLeft = /*@__PURE__*/ animation(base$1, {
    params: {
        delay: '0s',
        duration: '350ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endOpacity: 1,
        startOpacity: 0,
        toScale: 1,
        direction: 'X',
        fromScale: .4,
        xPos: '0',
        yPos: '0'
    }
});
const scaleInBottom = /*@__PURE__*/ animation(base$1, {
    params: {
        delay: '0s',
        direction: '',
        duration: '350ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endOpacity: 1,
        fromScale: .5,
        startOpacity: 0,
        toScale: 1,
        xPos: '50%',
        yPos: '100%'
    }
});
const scaleInHorRight = /*@__PURE__*/ animation(base$1, {
    params: {
        delay: '0s',
        duration: '350ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endOpacity: 1,
        startOpacity: 0,
        toScale: 1,
        direction: 'X',
        fromScale: .4,
        xPos: '100%',
        yPos: '100%'
    }
});
const scaleOutCenter = /*@__PURE__*/ animation(base$1, {
    params: {
        delay: '0s',
        direction: '',
        duration: '350ms',
        xPos: '50%',
        yPos: '50%',
        easing: "cubic-bezier(0.390, 0.575, 0.565, 1.000)" /* EaseOut.Sine */,
        endOpacity: 0,
        fromScale: 1,
        startOpacity: 1,
        toScale: .5
    }
});
const scaleOutBl = /*@__PURE__*/ animation(base$1, {
    params: {
        delay: '0s',
        direction: '',
        duration: '350ms',
        easing: "cubic-bezier(0.390, 0.575, 0.565, 1.000)" /* EaseOut.Sine */,
        endOpacity: 0,
        fromScale: 1,
        startOpacity: 1,
        toScale: .5,
        xPos: '0',
        yPos: '100%'
    }
});
const scaleOutBr = /*@__PURE__*/ animation(base$1, {
    params: {
        delay: '0s',
        direction: '',
        duration: '350ms',
        easing: "cubic-bezier(0.390, 0.575, 0.565, 1.000)" /* EaseOut.Sine */,
        endOpacity: 0,
        fromScale: 1,
        startOpacity: 1,
        toScale: .5,
        xPos: '100%',
        yPos: '100%'
    }
});
const scaleOutVerCenter = /*@__PURE__*/ animation(base$1, {
    params: {
        delay: '0s',
        duration: '350ms',
        xPos: '50%',
        yPos: '50%',
        easing: "cubic-bezier(0.390, 0.575, 0.565, 1.000)" /* EaseOut.Sine */,
        endOpacity: 0,
        fromScale: 1,
        startOpacity: 1,
        direction: 'Y',
        toScale: .3
    }
});
const scaleOutVerTop = /*@__PURE__*/ animation(base$1, {
    params: {
        delay: '0s',
        duration: '350ms',
        easing: "cubic-bezier(0.390, 0.575, 0.565, 1.000)" /* EaseOut.Sine */,
        endOpacity: 0,
        fromScale: 1,
        startOpacity: 1,
        direction: 'Y',
        toScale: .3,
        xPos: '100%',
        yPos: '0'
    }
});
const scaleOutVerBottom = /*@__PURE__*/ animation(base$1, {
    params: {
        delay: '0s',
        duration: '350ms',
        easing: "cubic-bezier(0.390, 0.575, 0.565, 1.000)" /* EaseOut.Sine */,
        endOpacity: 0,
        fromScale: 1,
        startOpacity: 1,
        direction: 'Y',
        toScale: .3,
        xPos: '0',
        yPos: '100%'
    }
});
const scaleOutTop = /*@__PURE__*/ animation(base$1, {
    params: {
        delay: '0s',
        direction: '',
        duration: '350ms',
        easing: "cubic-bezier(0.390, 0.575, 0.565, 1.000)" /* EaseOut.Sine */,
        endOpacity: 0,
        fromScale: 1,
        startOpacity: 1,
        toScale: .5,
        xPos: '50%',
        yPos: '0'
    }
});
const scaleOutLeft = /*@__PURE__*/ animation(base$1, {
    params: {
        delay: '0s',
        direction: '',
        duration: '350ms',
        easing: "cubic-bezier(0.390, 0.575, 0.565, 1.000)" /* EaseOut.Sine */,
        endOpacity: 0,
        fromScale: 1,
        startOpacity: 1,
        toScale: .5,
        xPos: '0',
        yPos: '50%'
    }
});
const scaleOutTr = /*@__PURE__*/ animation(base$1, {
    params: {
        delay: '0s',
        direction: '',
        duration: '350ms',
        easing: "cubic-bezier(0.390, 0.575, 0.565, 1.000)" /* EaseOut.Sine */,
        endOpacity: 0,
        fromScale: 1,
        startOpacity: 1,
        toScale: .5,
        xPos: '100%',
        yPos: '0'
    }
});
const scaleOutTl = /*@__PURE__*/ animation(base$1, {
    params: {
        delay: '0s',
        direction: '',
        duration: '350ms',
        easing: "cubic-bezier(0.390, 0.575, 0.565, 1.000)" /* EaseOut.Sine */,
        endOpacity: 0,
        fromScale: 1,
        startOpacity: 1,
        toScale: .5,
        xPos: '0',
        yPos: '0'
    }
});
const scaleOutRight = /*@__PURE__*/ animation(base$1, {
    params: {
        delay: '0s',
        direction: '',
        duration: '350ms',
        easing: "cubic-bezier(0.390, 0.575, 0.565, 1.000)" /* EaseOut.Sine */,
        endOpacity: 0,
        fromScale: 1,
        startOpacity: 1,
        toScale: .5,
        xPos: '100%',
        yPos: '50%'
    }
});
const scaleOutBottom = /*@__PURE__*/ animation(base$1, {
    params: {
        delay: '0s',
        direction: '',
        duration: '350ms',
        easing: "cubic-bezier(0.390, 0.575, 0.565, 1.000)" /* EaseOut.Sine */,
        endOpacity: 0,
        fromScale: 1,
        startOpacity: 1,
        toScale: .5,
        xPos: '50%',
        yPos: '100%'
    }
});
const scaleOutHorCenter = /*@__PURE__*/ animation(base$1, {
    params: {
        delay: '0s',
        duration: '350ms',
        xPos: '50%',
        yPos: '50%',
        easing: "cubic-bezier(0.390, 0.575, 0.565, 1.000)" /* EaseOut.Sine */,
        endOpacity: 0,
        fromScale: 1,
        startOpacity: 1,
        direction: 'X',
        toScale: .3
    }
});
const scaleOutHorLeft = /*@__PURE__*/ animation(base$1, {
    params: {
        delay: '0s',
        duration: '350ms',
        easing: "cubic-bezier(0.390, 0.575, 0.565, 1.000)" /* EaseOut.Sine */,
        endOpacity: 0,
        fromScale: 1,
        startOpacity: 1,
        direction: 'X',
        toScale: .3,
        xPos: '0',
        yPos: '0'
    }
});
const scaleOutHorRight = /*@__PURE__*/ animation(base$1, {
    params: {
        delay: '0s',
        duration: '350ms',
        easing: "cubic-bezier(0.390, 0.575, 0.565, 1.000)" /* EaseOut.Sine */,
        endOpacity: 0,
        fromScale: 1,
        startOpacity: 1,
        direction: 'X',
        toScale: .3,
        xPos: '100%',
        yPos: '100%'
    }
});

const base = [
    /*@__PURE__*/ style({
        opacity: `{{startOpacity}}`,
        transform: `{{fromPosition}}`
    }),
    /*@__PURE__*/ animate(`{{duration}} {{delay}} {{easing}}`, 
    /*@__PURE__*/ style({
        opacity: `{{endOpacity}}`,
        transform: `{{toPosition}}`
    }))
];
const slideInTop = /*@__PURE__*/ animation(base, {
    params: {
        delay: '0s',
        duration: '350ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endOpacity: 1,
        fromPosition: 'translateY(-500px)',
        startOpacity: 0,
        toPosition: 'translateY(0)'
    }
});
const slideInLeft = /*@__PURE__*/ animation(base, {
    params: {
        delay: '0s',
        duration: '350ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endOpacity: 1,
        fromPosition: 'translateX(-500px)',
        startOpacity: 0,
        toPosition: 'translateY(0)'
    }
});
const slideInRight = /*@__PURE__*/ animation(base, {
    params: {
        delay: '0s',
        duration: '350ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endOpacity: 1,
        fromPosition: 'translateX(500px)',
        startOpacity: 0,
        toPosition: 'translateY(0)'
    }
});
const slideInBottom = /*@__PURE__*/ animation(base, {
    params: {
        delay: '0s',
        duration: '350ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endOpacity: 1,
        fromPosition: 'translateY(500px)',
        startOpacity: 0,
        toPosition: 'translateY(0)'
    }
});
const slideInTr = /*@__PURE__*/ animation(base, {
    params: {
        delay: '0s',
        duration: '350ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endOpacity: 1,
        startOpacity: 0,
        fromPosition: 'translateY(-500px) translateX(500px)',
        toPosition: 'translateY(0) translateX(0)'
    }
});
const slideInTl = /*@__PURE__*/ animation(base, {
    params: {
        delay: '0s',
        duration: '350ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endOpacity: 1,
        startOpacity: 0,
        fromPosition: 'translateY(-500px) translateX(-500px)',
        toPosition: 'translateY(0) translateX(0)'
    }
});
const slideInBr = /*@__PURE__*/ animation(base, {
    params: {
        delay: '0s',
        duration: '350ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endOpacity: 1,
        startOpacity: 0,
        fromPosition: 'translateY(500px) translateX(500px)',
        toPosition: 'translateY(0) translateX(0)'
    }
});
const slideInBl = /*@__PURE__*/ animation(base, {
    params: {
        delay: '0s',
        duration: '350ms',
        easing: "cubic-bezier(0.250, 0.460, 0.450, 0.940)" /* EaseOut.Quad */,
        endOpacity: 1,
        startOpacity: 0,
        fromPosition: 'translateY(500px) translateX(-500px)',
        toPosition: 'translateY(0) translateX(0)'
    }
});
const slideOutTop = /*@__PURE__*/ animation(base, {
    params: {
        delay: '0s',
        duration: '350ms',
        easing: "cubic-bezier(0.550, 0.085, 0.680, 0.530)" /* EaseIn.Quad */,
        endOpacity: 0,
        fromPosition: 'translateY(0)',
        startOpacity: 1,
        toPosition: 'translateY(-500px)'
    }
});
const slideOutRight = /*@__PURE__*/ animation(base, {
    params: {
        delay: '0s',
        duration: '350ms',
        easing: "cubic-bezier(0.550, 0.085, 0.680, 0.530)" /* EaseIn.Quad */,
        endOpacity: 0,
        fromPosition: 'translateY(0)',
        startOpacity: 1,
        toPosition: 'translateX(500px)'
    }
});
const slideOutBottom = /*@__PURE__*/ animation(base, {
    params: {
        delay: '0s',
        duration: '350ms',
        easing: "cubic-bezier(0.550, 0.085, 0.680, 0.530)" /* EaseIn.Quad */,
        endOpacity: 0,
        fromPosition: 'translateY(0)',
        startOpacity: 1,
        toPosition: 'translateY(500px)'
    }
});
const slideOutLeft = /*@__PURE__*/ animation(base, {
    params: {
        delay: '0s',
        duration: '350ms',
        easing: "cubic-bezier(0.550, 0.085, 0.680, 0.530)" /* EaseIn.Quad */,
        endOpacity: 0,
        fromPosition: 'translateY(0)',
        startOpacity: 1,
        toPosition: 'translateX(-500px)'
    }
});
const slideOutTr = /*@__PURE__*/ animation(base, {
    params: {
        delay: '0s',
        duration: '350ms',
        easing: "cubic-bezier(0.550, 0.085, 0.680, 0.530)" /* EaseIn.Quad */,
        endOpacity: 0,
        startOpacity: 1,
        fromPosition: 'translateY(0) translateX(0)',
        toPosition: 'translateY(-500px) translateX(500px)'
    }
});
const slideOutBr = /*@__PURE__*/ animation(base, {
    params: {
        delay: '0s',
        duration: '350ms',
        easing: "cubic-bezier(0.550, 0.085, 0.680, 0.530)" /* EaseIn.Quad */,
        endOpacity: 0,
        startOpacity: 1,
        fromPosition: 'translateY(0) translateX(0)',
        toPosition: 'translateY(500px) translateX(500px)'
    }
});
const slideOutBl = /*@__PURE__*/ animation(base, {
    params: {
        delay: '0s',
        duration: '350ms',
        easing: "cubic-bezier(0.550, 0.085, 0.680, 0.530)" /* EaseIn.Quad */,
        endOpacity: 0,
        startOpacity: 1,
        fromPosition: 'translateY(0) translateX(0)',
        toPosition: 'translateY(500px) translateX(-500px)'
    }
});
const slideOutTl = /*@__PURE__*/ animation(base, {
    params: {
        delay: '0s',
        duration: '350ms',
        easing: "cubic-bezier(0.550, 0.085, 0.680, 0.530)" /* EaseIn.Quad */,
        endOpacity: 0,
        startOpacity: 1,
        fromPosition: 'translateY(0) translateX(0)',
        toPosition: 'translateY(-500px) translateX(-500px)'
    }
});

const swingBase = [
    /*@__PURE__*/ style({
        opacity: `{{startOpacity}}`,
        transform: `rotate{{direction}}({{startAngle}}deg)`,
        transformOrigin: `{{xPos}} {{yPos}}`
    }),
    /*@__PURE__*/ animate(`{{duration}} {{delay}} {{easing}}`, 
    /*@__PURE__*/ style({
        opacity: `{{endOpacity}}`,
        transform: `rotate{{direction}}({{endAngle}}deg)`,
        transformOrigin: `{{xPos}} {{yPos}}`
    }))
];
const swingInTopFwd = /*@__PURE__*/ animation(swingBase, {
    params: {
        delay: '0s',
        direction: 'X',
        duration: '.5s',
        easing: "cubic-bezier(0.175, 0.885, 0.320, 1.275)" /* EaseOut.Back */,
        endAngle: 0,
        endOpacity: 1,
        startAngle: -100,
        startOpacity: 0,
        xPos: 'top',
        yPos: 'center'
    }
});
const swingInRightFwd = /*@__PURE__*/ animation(swingBase, {
    params: {
        delay: '0s',
        duration: '.5s',
        easing: "cubic-bezier(0.175, 0.885, 0.320, 1.275)" /* EaseOut.Back */,
        endAngle: 0,
        endOpacity: 1,
        startAngle: -100,
        startOpacity: 0,
        direction: 'Y',
        xPos: 'center',
        yPos: 'right'
    }
});
const swingInBottomFwd = /*@__PURE__*/ animation(swingBase, {
    params: {
        delay: '0s',
        direction: 'X',
        duration: '.5s',
        easing: "cubic-bezier(0.175, 0.885, 0.320, 1.275)" /* EaseOut.Back */,
        endAngle: 0,
        endOpacity: 1,
        startOpacity: 0,
        yPos: 'center',
        startAngle: 100,
        xPos: 'bottom'
    }
});
const swingInLeftFwd = /*@__PURE__*/ animation(swingBase, {
    params: {
        delay: '0s',
        duration: '.5s',
        easing: "cubic-bezier(0.175, 0.885, 0.320, 1.275)" /* EaseOut.Back */,
        endAngle: 0,
        endOpacity: 1,
        startOpacity: 0,
        direction: 'Y',
        startAngle: 100,
        xPos: 'center',
        yPos: 'left'
    }
});
const swingInTopBck = /*@__PURE__*/ animation(swingBase, {
    params: {
        delay: '0s',
        direction: 'X',
        easing: "cubic-bezier(0.175, 0.885, 0.320, 1.275)" /* EaseOut.Back */,
        endAngle: 0,
        endOpacity: 1,
        startOpacity: 0,
        xPos: 'top',
        yPos: 'center',
        duration: '.6s',
        startAngle: 70
    }
});
const swingInRightBck = /*@__PURE__*/ animation(swingBase, {
    params: {
        delay: '0s',
        easing: "cubic-bezier(0.175, 0.885, 0.320, 1.275)" /* EaseOut.Back */,
        endAngle: 0,
        endOpacity: 1,
        startOpacity: 0,
        direction: 'Y',
        duration: '.6s',
        startAngle: 70,
        xPos: 'center',
        yPos: 'right'
    }
});
const swingInBottomBck = /*@__PURE__*/ animation(swingBase, {
    params: {
        delay: '0s',
        direction: 'X',
        easing: "cubic-bezier(0.175, 0.885, 0.320, 1.275)" /* EaseOut.Back */,
        endAngle: 0,
        endOpacity: 1,
        startOpacity: 0,
        yPos: 'center',
        duration: '.6s',
        startAngle: -70,
        xPos: 'bottom'
    }
});
const swingInLeftBck = /*@__PURE__*/ animation(swingBase, {
    params: {
        delay: '0s',
        easing: "cubic-bezier(0.175, 0.885, 0.320, 1.275)" /* EaseOut.Back */,
        endAngle: 0,
        endOpacity: 1,
        startOpacity: 0,
        direction: 'Y',
        duration: '.6s',
        startAngle: -70,
        xPos: 'center',
        yPos: 'left'
    }
});
const swingOutTopFwd = /*@__PURE__*/ animation(swingBase, {
    params: {
        delay: '0s',
        direction: 'X',
        xPos: 'top',
        yPos: 'center',
        duration: '.55s',
        easing: "cubic-bezier(0.600, -0.280, 0.735, 0.045)" /* EaseIn.Back */,
        endAngle: 70,
        endOpacity: 0,
        startAngle: 0,
        startOpacity: 1
    }
});
const swingOutRightFwd = /*@__PURE__*/ animation(swingBase, {
    params: {
        delay: '0s',
        duration: '.55s',
        easing: "cubic-bezier(0.600, -0.280, 0.735, 0.045)" /* EaseIn.Back */,
        endAngle: 70,
        endOpacity: 0,
        startAngle: 0,
        startOpacity: 1,
        direction: 'Y',
        xPos: 'center',
        yPos: 'right'
    }
});
const swingOutBottomFwd = /*@__PURE__*/ animation(swingBase, {
    params: {
        delay: '0s',
        direction: 'X',
        yPos: 'center',
        duration: '.55s',
        easing: "cubic-bezier(0.600, -0.280, 0.735, 0.045)" /* EaseIn.Back */,
        endOpacity: 0,
        startAngle: 0,
        startOpacity: 1,
        endAngle: -70,
        xPos: 'bottom'
    }
});
const swingOutLefttFwd = /*@__PURE__*/ animation(swingBase, {
    params: {
        delay: '0s',
        duration: '.55s',
        easing: "cubic-bezier(0.600, -0.280, 0.735, 0.045)" /* EaseIn.Back */,
        endOpacity: 0,
        startAngle: 0,
        startOpacity: 1,
        direction: 'Y',
        endAngle: -70,
        xPos: 'center',
        yPos: 'left'
    }
});
const swingOutTopBck = /*@__PURE__*/ animation(swingBase, {
    params: {
        delay: '0s',
        direction: 'X',
        xPos: 'top',
        yPos: 'center',
        easing: "cubic-bezier(0.600, -0.280, 0.735, 0.045)" /* EaseIn.Back */,
        endOpacity: 0,
        startAngle: 0,
        startOpacity: 1,
        duration: '.45s',
        endAngle: -100
    }
});
const swingOutRightBck = /*@__PURE__*/ animation(swingBase, {
    params: {
        delay: '0s',
        easing: "cubic-bezier(0.600, -0.280, 0.735, 0.045)" /* EaseIn.Back */,
        endOpacity: 0,
        startAngle: 0,
        startOpacity: 1,
        direction: 'Y',
        duration: '.45s',
        endAngle: -100,
        xPos: 'center',
        yPos: 'right'
    }
});
const swingOutBottomBck = /*@__PURE__*/ animation(swingBase, {
    params: {
        delay: '0s',
        direction: 'X',
        yPos: 'center',
        easing: "cubic-bezier(0.600, -0.280, 0.735, 0.045)" /* EaseIn.Back */,
        endOpacity: 0,
        startAngle: 0,
        startOpacity: 1,
        duration: '.45s',
        endAngle: 100,
        xPos: 'bottom'
    }
});
const swingOutLeftBck = /*@__PURE__*/ animation(swingBase, {
    params: {
        delay: '0s',
        easing: "cubic-bezier(0.600, -0.280, 0.735, 0.045)" /* EaseIn.Back */,
        endOpacity: 0,
        startAngle: 0,
        startOpacity: 1,
        direction: 'Y',
        duration: '.45s',
        endAngle: 100,
        xPos: 'center',
        yPos: 'left'
    }
});

class AnimationUtil {
    constructor() {
        this.oppositeAnimation = new Map([
            [fadeIn, fadeIn],
            [fadeOut, fadeOut],
            [flipTop, flipBottom],
            [flipBottom, flipTop],
            [flipRight, flipLeft],
            [flipLeft, flipRight],
            [flipHorFwd, flipHorBck],
            [flipHorBck, flipHorFwd],
            [flipVerFwd, flipVerBck],
            [flipVerBck, flipVerFwd],
            [growVerIn, growVerIn],
            [growVerOut, growVerOut],
            [heartbeat, heartbeat],
            [pulsateFwd, pulsateBck],
            [pulsateBck, pulsateFwd],
            [blink, blink],
            [shakeHor, shakeHor],
            [shakeVer, shakeVer],
            [shakeTop, shakeTop],
            [shakeBottom, shakeBottom],
            [shakeRight, shakeRight],
            [shakeLeft, shakeLeft],
            [shakeCenter, shakeCenter],
            [shakeTr, shakeTr],
            [shakeBr, shakeBr],
            [shakeBl, shakeBl],
            [shakeTl, shakeTl],
            [rotateInCenter, rotateInCenter],
            [rotateOutCenter, rotateOutCenter],
            [rotateInTop, rotateInBottom],
            [rotateOutTop, rotateOutBottom],
            [rotateInRight, rotateInLeft],
            [rotateOutRight, rotateOutLeft],
            [rotateInLeft, rotateInRight],
            [rotateOutLeft, rotateOutRight],
            [rotateInBottom, rotateInTop],
            [rotateOutBottom, rotateOutTop],
            [rotateInTr, rotateInBl],
            [rotateOutTr, rotateOutBl],
            [rotateInBr, rotateInTl],
            [rotateOutBr, rotateOutTl],
            [rotateInBl, rotateInTr],
            [rotateOutBl, rotateOutTr],
            [rotateInTl, rotateInBr],
            [rotateOutTl, rotateOutBr],
            [rotateInDiagonal1, rotateInDiagonal1],
            [rotateOutDiagonal1, rotateOutDiagonal1],
            [rotateInDiagonal2, rotateInDiagonal2],
            [rotateOutDiagonal2, rotateOutDiagonal2],
            [rotateInHor, rotateInHor],
            [rotateOutHor, rotateOutHor],
            [rotateInVer, rotateInVer],
            [rotateOutVer, rotateOutVer],
            [scaleInTop, scaleInBottom],
            [scaleOutTop, scaleOutBottom],
            [scaleInRight, scaleInLeft],
            [scaleOutRight, scaleOutLeft],
            [scaleInBottom, scaleInTop],
            [scaleOutBottom, scaleOutTop],
            [scaleInLeft, scaleInRight],
            [scaleOutLeft, scaleOutRight],
            [scaleInCenter, scaleInCenter],
            [scaleOutCenter, scaleOutCenter],
            [scaleInTr, scaleInBl],
            [scaleOutTr, scaleOutBl],
            [scaleInBr, scaleInTl],
            [scaleOutBr, scaleOutTl],
            [scaleInBl, scaleInTr],
            [scaleOutBl, scaleOutTr],
            [scaleInTl, scaleInBr],
            [scaleOutTl, scaleOutBr],
            [scaleInVerTop, scaleInVerBottom],
            [scaleOutVerTop, scaleOutVerBottom],
            [scaleInVerBottom, scaleInVerTop],
            [scaleOutVerBottom, scaleOutVerTop],
            [scaleInVerCenter, scaleInVerCenter],
            [scaleOutVerCenter, scaleOutVerCenter],
            [scaleInHorCenter, scaleInHorCenter],
            [scaleOutHorCenter, scaleOutHorCenter],
            [scaleInHorLeft, scaleInHorRight],
            [scaleOutHorLeft, scaleOutHorRight],
            [scaleInHorRight, scaleInHorLeft],
            [scaleOutHorRight, scaleOutHorLeft],
            [slideInTop, slideInBottom],
            [slideOutTop, slideOutBottom],
            [slideInRight, slideInLeft],
            [slideOutRight, slideOutLeft],
            [slideInBottom, slideInTop],
            [slideOutBottom, slideOutTop],
            [slideInLeft, slideInRight],
            [slideOutLeft, slideOutRight],
            [slideInTr, slideInBl],
            [slideOutTr, slideOutBl],
            [slideInBr, slideInTl],
            [slideOutBr, slideOutTl],
            [slideInBl, slideInTr],
            [slideOutBl, slideOutTr],
            [slideInTl, slideInBr],
            [slideOutTl, slideOutBr],
            [swingInTopFwd, swingInBottomFwd],
            [swingOutTopFwd, swingOutBottomFwd],
            [swingInRightFwd, swingInLeftFwd],
            [swingOutRightFwd, swingOutLefttFwd],
            [swingInLeftFwd, swingInRightFwd],
            [swingOutLefttFwd, swingOutRightFwd],
            [swingInBottomFwd, swingInTopFwd],
            [swingOutBottomFwd, swingOutTopFwd],
            [swingInTopBck, swingInBottomBck],
            [swingOutTopBck, swingOutBottomBck],
            [swingInRightBck, swingInLeftBck],
            [swingOutRightBck, swingOutLeftBck],
            [swingInBottomBck, swingInTopBck],
            [swingOutBottomBck, swingOutTopBck],
            [swingInLeftBck, swingInRightBck],
            [swingOutLeftBck, swingOutRightBck],
        ]);
        this.horizontalAnimations = [
            flipRight,
            flipLeft,
            flipVerFwd,
            flipVerBck,
            rotateInRight,
            rotateOutRight,
            rotateInLeft,
            rotateOutLeft,
            rotateInTr,
            rotateOutTr,
            rotateInBr,
            rotateOutBr,
            rotateInBl,
            rotateOutBl,
            rotateInTl,
            rotateOutTl,
            scaleInRight,
            scaleOutRight,
            scaleInLeft,
            scaleOutLeft,
            scaleInTr,
            scaleOutTr,
            scaleInBr,
            scaleOutBr,
            scaleInBl,
            scaleOutBl,
            scaleInTl,
            scaleOutTl,
            scaleInHorLeft,
            scaleOutHorLeft,
            scaleInHorRight,
            scaleOutHorRight,
            slideInRight,
            slideOutRight,
            slideInLeft,
            slideOutLeft,
            slideInTr,
            slideOutTr,
            slideInBr,
            slideOutBr,
            slideInBl,
            slideOutBl,
            slideInTl,
            slideOutTl,
            swingInRightFwd,
            swingOutRightFwd,
            swingInLeftFwd,
            swingOutLefttFwd,
            swingInRightBck,
            swingOutRightBck,
            swingInLeftBck,
            swingOutLeftBck,
        ];
        this.verticalAnimations = [
            flipTop,
            flipBottom,
            flipHorFwd,
            flipHorBck,
            growVerIn,
            growVerOut,
            rotateInTop,
            rotateOutTop,
            rotateInBottom,
            rotateOutBottom,
            rotateInTr,
            rotateOutTr,
            rotateInBr,
            rotateOutBr,
            rotateInBl,
            rotateOutBl,
            rotateInTl,
            rotateOutTl,
            scaleInTop,
            scaleOutTop,
            scaleInBottom,
            scaleOutBottom,
            scaleInTr,
            scaleOutTr,
            scaleInBr,
            scaleOutBr,
            scaleInBl,
            scaleOutBl,
            scaleInTl,
            scaleOutTl,
            scaleInVerTop,
            scaleOutVerTop,
            scaleInVerBottom,
            scaleOutVerBottom,
            slideInTop,
            slideOutTop,
            slideInBottom,
            slideOutBottom,
            slideInTr,
            slideOutTr,
            slideInBr,
            slideOutBr,
            slideInBl,
            slideOutBl,
            slideInTl,
            slideOutTl,
            swingInTopFwd,
            swingOutTopFwd,
            swingInBottomFwd,
            swingOutBottomFwd,
            swingInTopBck,
            swingOutTopBck,
            swingInBottomBck,
            swingOutBottomBck,
        ];
    }
    static instance() {
        return this._instance || (this._instance = new this());
    }
    reverseAnimationResolver(animation) {
        return this.oppositeAnimation.get(animation) ?? animation;
    }
    isHorizontalAnimation(animation) {
        return this.horizontalAnimations.includes(animation);
    }
    isVerticalAnimation(animation) {
        return this.verticalAnimations.includes(animation);
    }
}

/**
 * Generated bundle index. Do not edit.
 */

export { AnimationUtil, blink, fadeIn, fadeOut, flipBottom, flipHorBck, flipHorFwd, flipLeft, flipRight, flipTop, flipVerBck, flipVerFwd, growVerIn, growVerOut, heartbeat, pulsateBck, pulsateFwd, rotateInBl, rotateInBottom, rotateInBr, rotateInCenter, rotateInDiagonal1, rotateInDiagonal2, rotateInHor, rotateInLeft, rotateInRight, rotateInTl, rotateInTop, rotateInTr, rotateInVer, rotateOutBl, rotateOutBottom, rotateOutBr, rotateOutCenter, rotateOutDiagonal1, rotateOutDiagonal2, rotateOutHor, rotateOutLeft, rotateOutRight, rotateOutTl, rotateOutTop, rotateOutTr, rotateOutVer, scaleInBl, scaleInBottom, scaleInBr, scaleInCenter, scaleInHorCenter, scaleInHorLeft, scaleInHorRight, scaleInLeft, scaleInRight, scaleInTl, scaleInTop, scaleInTr, scaleInVerBottom, scaleInVerCenter, scaleInVerTop, scaleOutBl, scaleOutBottom, scaleOutBr, scaleOutCenter, scaleOutHorCenter, scaleOutHorLeft, scaleOutHorRight, scaleOutLeft, scaleOutRight, scaleOutTl, scaleOutTop, scaleOutTr, scaleOutVerBottom, scaleOutVerCenter, scaleOutVerTop, shakeBl, shakeBottom, shakeBr, shakeCenter, shakeHor, shakeLeft, shakeRight, shakeTl, shakeTop, shakeTr, shakeVer, slideInBl, slideInBottom, slideInBr, slideInLeft, slideInRight, slideInTl, slideInTop, slideInTr, slideOutBl, slideOutBottom, slideOutBr, slideOutLeft, slideOutRight, slideOutTl, slideOutTop, slideOutTr, swingInBottomBck, swingInBottomFwd, swingInLeftBck, swingInLeftFwd, swingInRightBck, swingInRightFwd, swingInTopBck, swingInTopFwd, swingOutBottomBck, swingOutBottomFwd, swingOutLeftBck, swingOutLefttFwd, swingOutRightBck, swingOutRightFwd, swingOutTopBck, swingOutTopFwd };

