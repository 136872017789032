import o from "./svgs/brick-wall.svg.js";
import r from "./svgs/brush.svg.js";
import e from "./svgs/bucket.svg.js";
import t from "./svgs/building.svg.js";
import n from "./svgs/bulldozer.svg.js";
import c from "./svgs/cement-mixer.svg.js";
import a from "./svgs/construction-cone.svg.js";
import i from "./svgs/construction-project.svg.js";
import s from "./svgs/construction-trolley.svg.js";
import l from "./svgs/cordless-screwdriver.svg.js";
import m from "./svgs/country-home.svg.js";
import u from "./svgs/crane.svg.js";
import p from "./svgs/dump-truck.svg.js";
import d from "./svgs/estate.svg.js";
import w from "./svgs/forklift-truck.svg.js";
import k from "./svgs/foundation.svg.js";
import g from "./svgs/fountain.svg.js";
import f from "./svgs/hammer.svg.js";
import y from "./svgs/impact-drill.svg.js";
import h from "./svgs/ladder.svg.js";
import v from "./svgs/maintenance.svg.js";
import I from "./svgs/oil-platform.svg.js";
import b from "./svgs/paint-roller.svg.js";
import T from "./svgs/pipes.svg.js";
import q from "./svgs/piping-system.svg.js";
import x from "./svgs/pliers.svg.js";
import H from "./svgs/pneumatic-hammer-drill.svg.js";
import j from "./svgs/radiator.svg.js";
import z from "./svgs/restricted-area.svg.js";
import P from "./svgs/road-roller.svg.js";
import R from "./svgs/rulers.svg.js";
import S from "./svgs/safety-vest.svg.js";
import B from "./svgs/saw.svg.js";
import D from "./svgs/scissor-lift.svg.js";
import W from "./svgs/screwdriver.svg.js";
import A from "./svgs/shovel.svg.js";
import C from "./svgs/skyscrapers.svg.js";
import L from "./svgs/solar-panel.svg.js";
import M from "./svgs/soldering-iron.svg.js";
import V from "./svgs/stairs.svg.js";
import E from "./svgs/stop-hand.svg.js";
import F from "./svgs/table-saw-blade.svg.js";
import G from "./svgs/tap-water.svg.js";
import J from "./svgs/tapeline.svg.js";
import K from "./svgs/tow-truck.svg.js";
import N from "./svgs/towel-rail.svg.js";
import O from "./svgs/tractor.svg.js";
import Q from "./svgs/trowel.svg.js";
import U from "./svgs/water-boiler.svg.js";
import X from "./svgs/water-heater.svg.js";
import Y from "./svgs/water-tap.svg.js";
import Z from "./svgs/worker.svg.js";
import _ from "./svgs/workers.svg.js";
import $ from "./svgs/wrench.svg.js";
const oo = {
  name: "brick-wall",
  value: o,
  categories: ["construction"],
  keywords: [
    "brick",
    "wall",
    "block",
    "pattern",
    "architecture",
    "stone",
    "building",
    "construction",
    "house"
  ]
}, ro = {
  name: "brush",
  value: r,
  categories: ["construction"],
  keywords: [
    "brush",
    "decoration",
    "interior",
    "paint",
    "painting",
    "roller",
    "wall"
  ]
}, eo = {
  name: "bucket",
  value: e,
  categories: ["construction"],
  keywords: [
    "bucket",
    "decoration",
    "interior",
    "paint",
    "water",
    "house",
    "wall",
    "construction"
  ]
}, to = {
  name: "building",
  value: t,
  categories: ["construction"],
  keywords: [
    "building",
    "home",
    "house",
    "apartments",
    "architecture",
    "structure"
  ]
}, no = {
  name: "bulldozer",
  value: n,
  categories: ["construction"],
  keywords: ["bulldozer", "digging", "excavator", "machine"]
}, co = {
  name: "cement-mixer",
  value: c,
  categories: ["construction"],
  keywords: ["cement", "mixer", "concrete", "construction", "machine", "truck"]
}, ao = {
  name: "construction-cone",
  value: a,
  categories: ["construction"],
  keywords: ["construction", "cone", "barrier", "caution", "road", "safety"]
}, io = {
  name: "construction-project",
  value: i,
  categories: ["construction"],
  keywords: ["construction", "project", "blueprint", "plan", "architecture"]
}, so = {
  name: "construction-trolley",
  value: s,
  categories: ["construction"],
  keywords: ["construction", "trolley", "carrier", "cart", "cement", "holder"]
}, lo = {
  name: "cordless-screwdriver",
  value: l,
  categories: ["construction"],
  keywords: ["cordless", "screwdriver", "drill", "driver", "electric", "power"]
}, mo = {
  name: "country-home",
  value: m,
  categories: ["construction"],
  keywords: ["country", "home", "house", "real estate"]
}, uo = {
  name: "crane",
  value: u,
  categories: ["construction"],
  keywords: ["crane", "construction", "hook", "machinery", "tower"]
}, po = {
  name: "dump-truck",
  value: p,
  categories: ["construction"],
  keywords: [
    "dump",
    "truck",
    "dumper",
    "garbage",
    "trash",
    "waste",
    "vehicle",
    "transport"
  ]
}, wo = {
  name: "estate",
  value: d,
  categories: ["construction"],
  keywords: ["estate", "building", "home", "house", "architecture"]
}, ko = {
  name: "forklift-truck",
  value: w,
  categories: ["construction"],
  keywords: [
    "forklift",
    "truck",
    "warehouse",
    "industrial",
    "transport",
    "lift",
    "cargo",
    "logistics",
    "loader",
    "machinery"
  ]
}, go = {
  name: "foundation",
  value: k,
  categories: ["construction"],
  keywords: ["foundation", "wall", "architecture", "construction"]
}, fo = {
  name: "fountain",
  value: g,
  categories: ["construction"],
  keywords: ["fountain", "garden", "splash", "park", "water"]
}, yo = {
  name: "hammer",
  value: f,
  categories: ["construction"],
  keywords: [
    "hammer",
    "construction",
    "equipment",
    "hardware",
    "repair",
    "tool",
    "work",
    "tools"
  ]
}, ho = {
  name: "impact-drill",
  value: y,
  categories: ["construction"],
  keywords: [
    "impact drill",
    "power",
    "tools",
    "driver",
    "construction",
    "tool",
    "work",
    "repair",
    "drill"
  ]
}, vo = {
  name: "ladder",
  value: h,
  categories: ["construction"],
  keywords: [
    "ladder",
    "construction",
    "building",
    "climb",
    "height",
    "tool",
    "work",
    "tools"
  ]
}, Io = {
  name: "maintenance",
  value: v,
  categories: ["construction"],
  keywords: [
    "maintenance",
    "construction",
    "tools",
    "hardware",
    "equipment",
    "wrench",
    "screwdriver",
    "tool kit",
    "tool box"
  ]
}, bo = {
  name: "oil-platform",
  value: I,
  categories: ["construction"],
  keywords: [
    "oil platform",
    "industry",
    "drilling",
    "platform",
    "oil",
    "rig",
    "offshore"
  ]
}, To = {
  name: "paint-roller",
  value: b,
  categories: ["construction"],
  keywords: [
    "paint roller",
    "equipment",
    "house construction",
    "paint",
    "roller",
    "wall paint",
    "tools",
    "tool"
  ]
}, qo = {
  name: "pipes",
  value: T,
  categories: ["construction"],
  keywords: ["pipes", "plumbing", "pipe", "drain", "water"]
}, xo = {
  name: "piping-system",
  value: q,
  categories: ["construction"],
  keywords: [
    "piping system",
    "piping",
    "water",
    "drain",
    "water",
    "industrial"
  ]
}, Ho = {
  name: "pliers",
  value: x,
  categories: ["construction"],
  keywords: ["pliers", "equipment", "tools", "tool", "repairs", "hardware"]
}, jo = {
  name: "pneumatic-hammer-drill",
  value: H,
  categories: ["construction"],
  keywords: [
    "pneumatic hammer drill",
    "drill",
    "hammer",
    "hydraulic",
    "jack",
    "kit",
    "pneumatic",
    "asphalt"
  ]
}, zo = {
  name: "radiator",
  value: j,
  categories: ["construction"],
  keywords: ["radiator", "heat", "warm", "interior", "heater", "heating"]
}, Po = {
  name: "restricted-area",
  value: z,
  categories: ["construction"],
  keywords: [
    "restricted area",
    "zone",
    "no entry",
    "warning sign",
    "caution zone",
    "area",
    "military",
    "restricted"
  ]
}, Ro = {
  name: "road-roller",
  value: P,
  categories: ["construction"],
  keywords: [
    "road roller",
    "construction",
    "road",
    "roller",
    "vehicle",
    "roadwork"
  ]
}, So = {
  name: "rulers",
  value: R,
  categories: ["construction"],
  keywords: [
    "rulers",
    "measure",
    "scale",
    "measurement",
    "centimeter",
    "tools"
  ]
}, Bo = {
  name: "safety-vest",
  value: S,
  categories: ["construction"],
  keywords: [
    "safety vest",
    "construction",
    "engineer",
    "jacket",
    "protect",
    "safety"
  ]
}, Do = {
  name: "saw",
  value: B,
  categories: ["construction"],
  keywords: ["saw", "hand", "tools", "woodworking", "equipment", "tool"]
}, Wo = {
  name: "scissor-lift",
  value: D,
  categories: ["construction"],
  keywords: [
    "scissor lift",
    "lift",
    "scaffolding",
    "scissor",
    "hydraulic",
    "construction",
    "high",
    "factory",
    "industry"
  ]
}, Ao = {
  name: "screwdriver",
  value: W,
  categories: ["construction"],
  keywords: [
    "screwdriver",
    "tools",
    "tool",
    "hardware",
    "screw",
    "fixer",
    "repair"
  ]
}, Co = {
  name: "shovel",
  value: A,
  categories: ["construction"],
  keywords: ["shovel", "digging", "tool", "spade", "equipment"]
}, Lo = {
  name: "skyscrapers",
  value: C,
  categories: ["construction"],
  keywords: [
    "skyscrapers",
    "buildings",
    "city",
    "cityscape",
    "downtown",
    "skyline",
    "architecture"
  ]
}, Mo = {
  name: "solar-panel",
  value: L,
  categories: ["construction"],
  keywords: [
    "solar panel",
    "alternative",
    "energy",
    "electricity",
    "panel",
    "solar"
  ]
}, Vo = {
  name: "soldering-iron",
  value: M,
  categories: ["construction"],
  keywords: [
    "soldering iron",
    "soldering",
    "iron",
    "tool",
    "copper",
    "manufactoring",
    "electronic"
  ]
}, Eo = {
  name: "stairs",
  value: V,
  categories: ["construction"],
  keywords: ["stairs", "building", "climb", "height"]
}, Fo = {
  name: "stop-hand",
  value: E,
  categories: ["construction"],
  keywords: ["stop hand", "stop", "sign", "halt", "adblock"]
}, Go = {
  name: "table-saw-blade",
  value: F,
  categories: ["construction"],
  keywords: ["table saw", "blade", "cut", "saw", "table"]
}, Jo = {
  name: "tap-water",
  value: G,
  categories: ["construction"],
  keywords: ["tap water", "tap", "water", "faucet", "drip", "leak"]
}, Ko = {
  name: "tapeline",
  value: J,
  categories: ["construction"],
  keywords: [
    "tapeline",
    "centimeter",
    "inches tape",
    "measuring tape",
    "meter stick",
    "inches",
    "measure",
    "metre",
    "ruler"
  ]
}, No = {
  name: "tow-truck",
  value: K,
  categories: ["construction"],
  keywords: [
    "tow truck",
    "repair",
    "service",
    "tow",
    "truck",
    "towing",
    "vehicle",
    "transport"
  ]
}, Oo = {
  name: "towel-rail",
  value: N,
  categories: ["construction"],
  keywords: [
    "towel rail",
    "warmer",
    "heated",
    "towel",
    "rail",
    "bathroom",
    "interior",
    "rack"
  ]
}, Qo = {
  name: "tractor",
  value: O,
  categories: ["construction"],
  keywords: ["tractor", "vehicle", "machinery"]
}, Uo = {
  name: "trowel",
  value: Q,
  categories: ["construction"],
  keywords: [
    "trowel",
    "cement",
    "equipment",
    "tool",
    "renovation",
    "maintenance"
  ]
}, Xo = {
  name: "water-boiler",
  value: U,
  categories: ["construction"],
  keywords: [
    "water boiler",
    "boiler",
    "heater",
    "water",
    "heating",
    "appliance"
  ]
}, Yo = {
  name: "water-heater",
  value: X,
  categories: ["construction"],
  keywords: [
    "water heater",
    "heater",
    "water",
    "tank",
    "appliance",
    "electrical",
    "heating"
  ]
}, Zo = {
  name: "water-tap",
  value: Y,
  categories: ["construction"],
  keywords: ["water tap", "tap", "faucet", "water", "plumbing", "valve"]
}, _o = {
  name: "worker",
  value: Z,
  categories: ["construction"],
  keywords: ["worker", "builder", "engineer", "man", "labor"]
}, $o = {
  name: "workers",
  value: _,
  categories: ["construction"],
  keywords: ["workers", "crew", "builders", "engineers", "men", "constructors"]
}, or = {
  name: "wrench",
  value: $,
  categories: ["construction"],
  keywords: [
    "wrench",
    "construction",
    "equipment",
    "hardware",
    "metal",
    "tool"
  ]
}, re = [
  oo,
  ro,
  eo,
  to,
  no,
  co,
  ao,
  io,
  so,
  lo,
  mo,
  uo,
  po,
  wo,
  ko,
  go,
  fo,
  yo,
  ho,
  vo,
  Io,
  bo,
  To,
  qo,
  xo,
  Ho,
  jo,
  zo,
  Po,
  Ro,
  So,
  Bo,
  Do,
  Wo,
  Ao,
  Co,
  Lo,
  Mo,
  Vo,
  Eo,
  Fo,
  Go,
  Jo,
  Ko,
  No,
  Oo,
  Qo,
  Uo,
  Xo,
  Yo,
  Zo,
  _o,
  $o,
  or
];
export {
  oo as brickWall,
  ro as brush,
  eo as bucket,
  to as building,
  no as bulldozer,
  co as cementMixer,
  re as construction,
  ao as constructionCone,
  io as constructionProject,
  so as constructionTrolley,
  lo as cordlessScrewdriver,
  mo as countryHome,
  uo as crane,
  po as dumpTruck,
  wo as estate,
  ko as forkliftTruck,
  go as foundation,
  fo as fountain,
  yo as hammer,
  ho as impactDrill,
  vo as ladder,
  Io as maintenance,
  bo as oilPlatform,
  To as paintRoller,
  qo as pipes,
  xo as pipingSystem,
  Ho as pliers,
  jo as pneumaticHammerDrill,
  zo as radiator,
  Po as restrictedArea,
  Ro as roadRoller,
  So as rulers,
  Bo as safetyVest,
  Do as saw,
  Wo as scissorLift,
  Ao as screwdriver,
  Co as shovel,
  Lo as skyscrapers,
  Mo as solarPanel,
  Vo as solderingIron,
  Eo as stairs,
  Fo as stopHand,
  Go as tableSawBlade,
  Jo as tapWater,
  Ko as tapeline,
  No as towTruck,
  Oo as towelRail,
  Qo as tractor,
  Uo as trowel,
  Xo as waterBoiler,
  Yo as waterHeater,
  Zo as waterTap,
  _o as worker,
  $o as workers,
  or as wrench
};
