import { PaymentSchedule } from "./paymentSchedule.model"

export class FormJ {
    constructor(private _id: number, private _principal: number, private _customerId: number, private _term: number,
        private _dateCreated: Date, private _lastPayment: number, private _paymentSchedule: PaymentSchedule[],
        private _receiptNumber: string, private _stampDate: Date,private _version?: string) {

    }

    set ID(id: number) { this._id = id }
    get ID(): number { return this._id }

    set Principal(principal: number) { this._principal = principal }
    get Principal(): number { return this._principal }

    set CustomerId(customerId: number) { this._customerId = customerId }
    get CustomerId(): number { return this._customerId }

    set Term(term: number) { this._term = term }
    get Term(): number { return this._term }

    set DateCreated(dateCreated: Date) { this._dateCreated = dateCreated }
    get DateCreated(): Date { return this._dateCreated }

    set StampDate(stampDate: Date) { this._stampDate = stampDate }
    get StampDate(): Date { return this._stampDate }

    set LastPayment(lastPayment: number) { this._lastPayment = lastPayment }
    get LastPayment(): number { return this._lastPayment }

    set PaymentSchedule(paymentSchedule: PaymentSchedule[]) { this._paymentSchedule = paymentSchedule }
    get PaymentSchedule(): PaymentSchedule[] { return this._paymentSchedule }

    set ReceiptNumber(receiptNumber: string) { this._receiptNumber = receiptNumber }
    get ReceiptNumber(): string { return this._receiptNumber }
    
    get Version(): string { return this._version }
    set Version(value: string) { this._version = value}

}