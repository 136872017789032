<div class="container-fluid" style="padding: 0;">
    <div class="row">
        <div class="col-12">
        </div>
        <div class="col-12 login-clean" style="height: 100vh;width: 100vw;">
            <form method="post" style="background-color: rgba(20,20,20,0.66);">
                <h2 style="color: white;text-align: center;">REGISTER</h2>
                <div class="illustration"><img src="../../assets/image/logo.png" width="150px" height="150px"></div>
                <div class="mb-3">
                    <input class="form-control" type="text" placeholder="Username" autofocus="" required=""
                        name="username" [(ngModel)]="username">
                </div>
                <div class="mb-3"><input class="form-control" type="password" name="password"
                        placeholder="Password" required="" [(ngModel)]="password">
                </div>

                <div class="mb-3"><input class="form-control" type="password" name="repeatPassword"
                        placeholder="Confirm Password" required="" [(ngModel)]="repeatPassword">
                </div>
                <div class="mb-3"
                    [hidden]="selectedRoleID==undefined || selectedRoleID==enums.ADMIN || selectedRoleID==enums.BOSS || selectedRoleID==enums.FINANCE || selectedRoleID==enums.EXPENSE_ADMIN ||selectedRoleID==enums.AUDITOR">
                    <input class="form-control" type="number" name="initialCashAmount" placeholder="Initial Cash Amount"
                        required="" [(ngModel)]="initialCashAmount">
                </div>
                <div class="mb-3"
                    [hidden]="selectedRoleID==undefined || selectedRoleID==enums.ADMIN || selectedRoleID==enums.BOSS || selectedRoleID==enums.FINANCE || selectedRoleID==enums.EXPENSE_ADMIN ||selectedRoleID==enums.AUDITOR">
                    <mat-form-field class="bar w-100">
                        <input matInput class="textInputLong initialDatePicker" [matDatepicker]="myDatepicker"
                            name="initialAccountDate" [(ngModel)]="initialAccountDate"
                            placeholder="Initial Cash Account Date">
                        <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #myDatepicker name="date"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="btn-group  m-1">
                            <button type="button" class="btn btn-danger dropdown-toggle dropdown-btn"
                                 data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {{selectedRole}}
                            </button>
                            <div class="dropdown-menu">
                                <ng-container *ngFor="let role of roleList">
                                    <a class="dropdown-item" style="cursor: pointer;"
                                        (click)="setSelectedRole(role.ID,role.Name)">{{role.Name}}</a>
                                </ng-container>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="row"
                    [hidden]="selectedRoleID==undefined || selectedRoleID==enums.ADMIN || selectedRoleID==enums.BOSS || selectedRoleID==enums.FINANCE || selectedRoleID==enums.EXPENSE_ADMIN ||selectedRoleID==enums.AUDITOR">

                    <div class="col">
                        <div class="btn-group m-1">
                            <button type="button" class="btn btn-warning dropdown-toggle dropdown-btn"
                                 data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {{selectedCompany}}
                            </button>
                            <div class="dropdown-menu">
                                <ng-container *ngFor="let company of companyList">
                                    <a class="dropdown-item" style="cursor: pointer;"
                                        (click)="setSelectedCompany(company.ID,company.CompanyCode)">{{company.CompanyCode}}</a>
                                </ng-container>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-grid mb-3"><button class="btn btn-primary" (click)="validateField()">SIGN
                        UP</button>
                </div>
            </form>
        </div>
    </div>
</div>