import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { TransactionSubType } from 'src/model/transactionSubType.model';

interface TransactionInterface {
  remark: string,
  amount: number,
  date: Date,
  subtype?: number
}

@Component({
  selector: 'app-add-multiple-transaction',
  templateUrl: './add-multiple-transaction.component.html',
  styleUrls: ['./add-multiple-transaction.component.css']
})


export class AddMultipleTransactionComponent implements OnInit {


  amount: number = 0;
  remark: string;
  subtype: number;
  date: Date = new Date();

  disabledSubmit = false;

  transactionList: TransactionInterface[] = new Array;
  subtypeList: TransactionSubType[] = new Array;

  @Input() paidByDuitLebih: boolean = false;
  @Output() passTransactionDetails: EventEmitter<any> = new EventEmitter();

  constructor(private cdr: ChangeDetectorRef, private activeModal: NgbActiveModal, private modalService: NgbModal,
    private restapi: RestApiService, private constructApi: ConstructAPI, public sharedService: SharedService, public enums: Enums) { }

  ngOnInit(): void {
    this.getTransactionSubTypes();
  }


  getTransactionSubTypes() {


    var xhttp;

    xhttp = (this.paidByDuitLebih == true)
      ? this.restapi.getRequest(this.constructApi.getTransactionSubTypes(this.enums.EXTRAS))
      : this.restapi.getRequest(this.constructApi.getTransactionSubTypes(this.enums.EXPENSE));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let type = new TransactionSubType(json.id, json.name, json.type, json.assignable, json.enabled);

          if (json.assignable == true)
            this.subtypeList.push(type);
        });
      }
    };
  }


  calculateTotalAmount() {
    var sum = 0;

    for (let i = 0; i < this.transactionList.length; i++) {
      sum += this.transactionList[i].amount;
    }

    return sum;
  }

  isInvalid() {
    var remarkNull = (this.remark == undefined || this.remark === '');
    var amountNull = (this.amount == undefined || this.amount == 0);
    var amountValid = isNaN(+this.amount)
    var dateNull = (this.date == undefined)

    return (dateNull || remarkNull || amountNull || amountValid);
  }


  getSubTypeName(id: number) {
    for (let i = 0; i < this.subtypeList.length; i++) {
      if (this.subtypeList[i].ID == id)
        return this.subtypeList[i].Name;
    }
  }
  addToList() {
    var transaction = { remark: this.remark, amount: this.amount, date: this.date, subtype: this.subtype }
    this.transactionList.push(transaction);
    this.cdr.detectChanges();

    this.resetValues();
    this.passTransactionDetails.emit(this.transactionList);
  }

  submit() {

    if (this.isInvalid()) {
      this.sharedService.openAlert('Please fill in all the fields!', this.enums.DANGER_ALERT)
    }
    else if (this.paidByDuitLebih == false && this.subtype == undefined)
      this.sharedService.openAlert('Please select subtype!', this.enums.DANGER_ALERT)
    else
      this.addToList();
  }



  reset() {

    this.activeModal.close();
    this.resetValues();
  }



  removeTransaction(i: number) {
    this.transactionList.splice(Number(i), 1);
    this.cdr.detectChanges();
    this.passTransactionDetails.emit(this.transactionList);

  }
  resetValues() {
    this.remark = undefined;
    this.amount = undefined;
    this.subtype = undefined;
  }

}
