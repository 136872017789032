import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { AlertDialogComponent } from 'src/app/Shared/alert-dialog/alert-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Account } from 'src/model/account.model';

@Component({
  selector: 'app-add-saving',
  templateUrl: './add-saving.component.html',
  styleUrls: ['./add-saving.component.css']
})
export class AddSavingComponent implements OnInit {

  @Input() companyBankAccountList: Account[] = new Array;
  @Input() cashAccountList: Account[] = new Array;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  selectedAccountId: number;
  selectedAccountBalance: number;

  @Input() paidByDuitLebih: boolean;

  //2-WAY BINDING INFO
  savingDate: Date;
  remark: string;
  amount: number = 0;
  disabledSubmit = false;

  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private restapi: RestApiService, private constructApi: ConstructAPI, 
    public sharedService: SharedService,private enums:Enums) { }

  ngOnInit(): void {
    this.savingDate = new Date();
  }

  setSelectedAccount(accountId: number) {

    if (accountId === this.selectedAccountId)
      return true
    else
      return false;

  }
  isFieldNull() {
    var paymentNull = (this.amount == undefined || this.amount == 0);
    var remarkNull = (this.remark == undefined || this.remark == "");
    var selectedAccountIdNull = (this.selectedAccountId == undefined)
    var paymentValid = isNaN(+this.amount);

    return paymentNull || remarkNull || selectedAccountIdNull || paymentValid;
  }

  addSaving() {
    this.disabledSubmit = true;
    var isoTaskDate = (new Date(Number(this.savingDate))).toISOString();

    var data = {
      "amount": this.amount,
      "accountId": Number(this.selectedAccountId),
      "date": isoTaskDate,
      "remark": "SAVING - " + this.amount + "(" + this.remark + ")"
    };


    var xhr = this.restapi.postRequest(this.constructApi.getAddSaving(), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        if (xhr.status == 200) {
          // var json = JSON.parse(xhr.responseText);

          let account = new Account(this.selectedAccountId, null, null, null, null, null, null, -this.amount)
          this.passEntry.emit(account)
          this.reset();
          this.sharedService.openAlert("Successfully added saving!",this.enums.DANGER_ALERT);
          window.location.reload();


        } else
          this.sharedService.openErrorMessage(xhr);
      }

    }
  }

  getAccountBalance() {

    var account = this.companyBankAccountList.find(account => account.ID === Number(this.selectedAccountId));
    if (account == undefined)
      account = this.cashAccountList.find(account => account.ID === Number(this.selectedAccountId));

    return account.Balance;
  }

  isBalanceInsufficient(balance: number) {

    // this.sharedService.openAlert(balance);
    if (balance > 0)
      return false;
    else
      return true;
  }


  submit() {
    if (this.isFieldNull()) {
      this.sharedService.openAlert('Please fill in all the fields',this.enums.DANGER_ALERT)
    }
    else {
      this.addSaving();
    }
  }



  reset() {
    this.activeModal.close();
  }

}
