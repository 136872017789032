<div>
    <h3 class="m-2"><i class="fas fa-stopwatch"></i> EPF CUSTOMER DUE WITHIN 30 DAYS</h3>
    <div class="col-12">
        <div class="row m-0" style="background-color: black;">

            <div class="col m-0 d-flex justify-content-end  my-2">
                <input type="text" class="form-control" placeholder="Search" aria-label="Search" name="searchText"
                    [(ngModel)]="searchText" (keyup)="filterList()">
            </div>
        </div>
        <div class="m-2 p-2" *ngIf="taskList.length==0 || taskList==undefined">
            <h5 class="fw-bold text-secondary">You do not have any EPF task today</h5>
        </div>
        <ng-container *ngFor="let task of taskList;">
            <div class="row g-0">
                <div class="col">
                    <div class="card epfCard shadow-lg">
                        <div class="card-body"
                            [ngClass]="{'overdueEpfCard':calculateDaysLeft(task.Date)<0 && task.IsDone==undefined,'doneEpfCard':task.DateDone!=undefined}">
                            <h6 class="card-title customerLink"
                                (click)="goCustomerProfile(task.CustomerId, task.ProfileId)">
                                {{task.CustomerName | uppercase }} </h6>
                            <p class="text-start fw-bold"
                                [ngClass]="{'text-danger':calculateDaysLeft(task.Date)<0 && task.IsDone==undefined, 'text-secondary':calculateDaysLeft(task.Date)>=0}">
                                Description: {{task.Description}}
                            </p>
                            <p class="text-start fw-bold"
                                [ngClass]="{'text-danger':calculateDaysLeft(task.Date)<0 && task.IsDone==undefined, 'text-secondary':calculateDaysLeft(task.Date)>=0}">
                                Task Date: {{task.Date | date:'dd-MM-yyyy'}}
                            </p>
                            <hr />
                            <div class="d-flex justify-content-end align-items-right" *ngIf="task.DateDone==undefined">
                                <button class="btn btn-warning me-2" (click)="openNextTaskModal(task)"><i
                                        class="fas fa-phone"></i> Follow again</button>
                                <button class="btn btn-success"
                                    (click)="goCustomerProfile(task.CustomerId, task.ProfileId)"><i
                                        class="fas fa-money-bill-wave"></i> Payment</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>