<ng-container *ngIf="!customerTaskList || customerTaskList.length===0;else taskList">
    <p>There's no task record for this customer.</p>
    <p>Add a tasks?</p>
    <button class="btn btn-dark" (click)="openAddTaskModal()">Add Task</button>
</ng-container>


<ng-template #taskList>
    <!-- <div class=" row d-flex align-items-end justify-content-end p-1" *ngIf="allowUpdateTask==true">
        <button class="btn btn-dark" (click)="openAddTaskModal()" *ngIf="sharedService.isAdmin()==false">Add
            Task</button>
    </div> -->
    <igx-list>
        <igx-list-item isHeader="true">
            Tasks
        </igx-list-item>
        <igx-list-item #item *ngFor="let task of customerTaskList;">
            <h4 igxListLineTitle>{{ task.Description }}</h4>

            <p igxListLineSubTitle><i class="far fa-calendar"></i> {{ task.Date | date:'dd-MM-yyyy' }}
                <span class="badge text-bg-primary">{{enums.taskTypeString[task.Type-1]}}</span>
            </p>
            <p igxListLineSubTitle *ngIf="task.DateDone!=undefined">
                <strong>
                    <i class="far fa-comments"></i>
                    {{task.FollowUpRemark}}
                </strong>
            </p>
            <small igxListLine [style.color]="changeStatusColor(task.DateDone)">
                <strong>
                    {{ getTaskStatusString(task.DateDone)}} | {{ task.Assignee }} </strong>
            </small>
            <div class="row">
                <div class="col">
                    <button class="btn btn-warning" *ngIf="task.DateDone==undefined && sharedService.isBranchManager()==true" (click)="openNextTaskModal(task)">FOLLOW AGAIN</button>
                </div>
            </div>
        </igx-list-item>
    </igx-list>
</ng-template>