import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { List$1 } from 'igniteui-angular-core';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Company } from 'src/model/company.model';
import { Customer } from 'src/model/customer.model';
import { FormJ } from 'src/model/formJ.model';
import { PaymentSchedule } from 'src/model/paymentSchedule.model';

@Component({
  selector: 'app-form-j',
  templateUrl: './form-j.component.html',
  styleUrls: ['./form-j.component.css']
})
export class FormJComponent implements OnInit {

  selectedCompany: Company;
  selectedCompanyID: number;
  selectedCustomer: Customer;
  selectedCustomerID: number;
  selectedFormJID: number;
  selectedFormJ: FormJ;
  routeSubscription: any;
  paymentScheduleList: PaymentSchedule[] = new Array;
  principal: number;
  lastPayment: number;

  @ViewChild('htmlData') htmlData: ElementRef;

  constructor(private activatedRoute: ActivatedRoute, private restapi: RestApiService, private constructApi: ConstructAPI, public sharedService: SharedService) {

    this.routeSubscription = this.activatedRoute.paramMap.subscribe(params => {
      this.selectedCustomerID = + params.get('customerId');
      this.selectedCompanyID = + params.get('companyId');
      this.selectedFormJID = +params.get('formJId');

      this.getCompanyInfo();
      this.getCustomerInfo();
      this.getFormJInfo();
    })

  }

  ngOnInit(): void {
  }


  getCompanyInfo() {
    var xhttp = this.restapi.getRequest(this.constructApi.getCompanyDetails(this.selectedCompanyID))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var json = JSON.parse((xhttp.responseText));
        this.selectedCompany = new Company(json.id, json.name, json.address, json.companyCode, json.contactNo, json.regNo)


      }
    };
  }

  getCustomerInfo() {
    var xhttp = this.restapi.getRequest(this.constructApi.getCustomerDetails(this.selectedCustomerID))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var json = JSON.parse((xhttp.responseText));
        this.selectedCustomer = new Customer(json.id, json.name, json.occupation,
          json.salary, json.salaryDay, json.icNumber, json.contactNo, json.remark, json.ctosNumber, json.ctosStatus,
          json.status, json.dateCreated, json.handler, json.companyId, json.customerCode, json.companyName, json.reserve, json.badDebt, json.address);
      }
    };
  }

  getFormJInfo() {
    var xhttp = this.restapi.getRequest(this.constructApi.getFormJDetails(this.selectedFormJID))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var json = JSON.parse((xhttp.responseText));
        var lastPayment = Number(json.data.lastPayment);
        var paymentScheduleList = this.processPaymentSchedule(JSON.parse(JSON.stringify(json.data.paymentSchedule)))
        this.selectedFormJ = new FormJ(json.id, json.principal, json.customerId, json.term, json.dateCreated, lastPayment, paymentScheduleList, json.data.receiptNumber, json.data.stampDate)
      }
    };
  }

  calculateFirstPayment(stampDate: Date) {

    return new Date(new Date(stampDate).getFullYear(), new Date(stampDate).getMonth() + 1, new Date(stampDate).getDate());

  }

  processPaymentSchedule(scheduleList: any) {
    var paymentScheduleList: PaymentSchedule[] = new Array;
    scheduleList.forEach(schedule => {
      var payment = { monthsCount: schedule.monthsCount, amount: schedule.amount }
      paymentScheduleList.push(payment);
    }
    );
    return scheduleList;
  }

  print() {
    window.print();
  }


}
