<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">CP Customer - {{customerCode}}</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="closeModal()"></button>
    </div>

    <div class="modal-body">

        <ul class="nav nav-tabs">
            <li class="nav-item"><a class="nav-link active" role="tab"  data-bs-toggle="tab" href="#tabDetails"><strong><i
                            class="fas fa-info"></i> Details</strong></a>
            </li>
            <li class="nav-item" (click)="getCPRemarks(customerId)">
                <a class="nav-link" role="tab"  data-bs-toggle="tab" href="#tabRemarks"><strong><i
                            class="fas fa-comment-alt"></i>
                        Remarks</strong></a>
            </li>
        </ul>


        <div class="tab-content" *ngIf="cpDetails!=undefined">
            <div class="tab-pane active" role="tabpanel" id="tabDetails">
                <div class="mb-2 pt-2">
                    <table class="table table-responsive">
                        <tbody>
                            <tr>
                                <th>Customer Code</th>
                                <td>{{cpDetails.CustomerCode}}</td>
                            </tr>
                            <tr>
                                <th>Customer Name</th>
                                <td>{{cpDetails.CustomerName}}</td>
                            </tr>
                            <tr>
                                <th>Customer IC</th>
                                <td>{{cpDetails.CustomerIC}}</td>
                            </tr>
                            <tr>
                                <th>Occupation</th>
                                <td>{{cpDetails.CustOccupation}}</td>
                            </tr>
                            <tr>
                                <th>Phone Number</th>
                                <td>{{cpDetails.DefaultPhone}}</td>
                            </tr>
                            <tr>
                                <th>Handling Status</th>
                                <td>{{cpDetails.CustStatus}}</td>
                            </tr>
                            <tr>
                                <th>Addtional</th>
                                <td>{{cpDetails.AdditionalInfo}}</td>
                            </tr>
                            <tr>
                                <th>Case Status</th>
                                <td>{{cpDetails.CaseStatus}}</td>
                            </tr>
                            <tr>
                                <th>Current Handler</th>
                                <td>{{cpDetails.HandlerName}}</td>
                            </tr>
                            <tr>
                                <th>Debt Amount</th>
                                <td>{{cpDetails.DebtAmount}}</td>
                            </tr>
                            <tr>
                                <th>Debt Term</th>
                                <td>{{cpDetails.DebtTerm}}</td>
                            </tr>
                            <tr>
                                <th>Charges</th>
                                <td>{{cpDetails.Charges}}</td>
                            </tr>
                            <tr>
                                <th>Total Amount (Debt + Charges)</th>
                                <td>{{cpDetails.DebtAmount +cpDetails.Charges }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="tab-pane" role="tabpanel" id="tabRemarks">
                <ng-container>
                    <div class="mb-2">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Remarks</th>
                                    <th>Remark Date</th>
                                    <th>Handler Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let remark of customerRemarks">
                                    <td>
                                        {{remark.RemarkDesc}}
                                    </td>
                                    <td>{{remark.RemarkDate |date:'dd-MM-yyyy'}}</td>
                                    <td>{{remark.HandlerName}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </ng-container>
            </div>
        </div>

    </div>


</form>