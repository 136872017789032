import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Customer } from 'src/model/customer.model';
import { EnlargeImageComponent } from '../enlarge-image/enlarge-image.component';
import { FileInterface } from 'src/model/file.interface';



@Component({
  selector: 'app-album-component',
  templateUrl: './album-component.component.html',
  styleUrls: ['./album-component.component.css']
})
export class AlbumComponentComponent implements OnInit {

  constructor(private cdr: ChangeDetectorRef, public sharedService: SharedService, public enums: Enums, private restApi: RestApiService, private constructApi: ConstructAPI) { }

  @Input() docArr: FileInterface[] = new Array;
  @Input() viewCustomer: Customer;
  
  ngOnInit(): void {

    this.docArr.sort((a, b) => (a.lastModified > b.lastModified) ? -1 : 1);
  }


  downloadFile(filename: string, customerId: number) {
    var encoded = encodeURIComponent(filename);
    var xhr = this.restApi.getRequest(this.constructApi.getFullFile(customerId, encoded));
    xhr.responseType = "blob";
    xhr.onreadystatechange = () => {

      if (xhr.readyState === 4) {
        if (xhr.status == 200) {
          this.sharedService.generateDownloadLink(xhr, filename);
        }
        else {
          this.sharedService.openAlert("Some error occurred. Please try again later", this.enums.DANGER_ALERT);

        }
      }

    };
  }

  checkIsImage(contentType: string) {
    if (contentType.includes("image/") == true)
      return true;
    else
      return false;
  }

  checkCanConvertSupportingDoc(filename: string) {
    // if (filename.includes("application-form") || filename.includes("contact") == true && filename.includes("guarantor-form") == false)
    //   return true;
    if (filename.includes("application-form"))
      return true;
    else if (filename.includes("epf-statement") == false &&
      filename.includes("formj") == false && filename.includes("consent-form") == false
      && filename.includes("reference-form") == false && filename.includes("ic/front") == false
      && filename.includes("ic/back") == false && filename.includes("contact") == false &&
      filename.includes("payslip") && filename.includes("bank-statement") && filename.includes("atm-card"))
      return true;
    else
      return false;
  }


  isContactConvertibleDoc(filename: string) {
    if (filename.includes("contact") && filename.includes("guarantor-form") == false)
      return true;
    else
      return false;
  }

  openEnlargeImageModal(fileName: string, index: number) {
    const modalRef = this.sharedService.openModal(EnlargeImageComponent, 'largeModal');
    modalRef.componentInstance.customerId = this.viewCustomer.ID;
    modalRef.componentInstance.fileList = this.docArr;
    modalRef.componentInstance.currentFileIndex = index;
    modalRef.componentInstance.fileName = fileName;

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var isDirty = entry;

      // use the confirmation to do something

      if (isDirty == true) {
        this.docArr.splice(index, 1);
        this.cdr.detectChanges();
      }


    });
  }



  confirmDeleteFile(filename: string, index: number) {

    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Are you sure you want to delete this file (" + filename + ") ?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something

      if (confirmation == true)
        this.deleteFile(this.viewCustomer.ID, filename, index);

    });

  }
  confirmBeforeChangeType(customerID: number, filename: string, targetType: string) {
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Are you sure you want to change this file type to " + targetType.toUpperCase() + "?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something

      if (confirmation == true)
        this.changeFileType(customerID, filename, targetType);

    });
  }


  confirmBeforeContactPhotoChangeType(customerID: number, filename: string) {
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Are you sure you want to change this file type to GUARANTOR FORM?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something

      if (confirmation == true) {
        var contactId = filename.split("/")[1];

        this.changeContactFileType(customerID, Number(contactId), filename, "guarantorForm");
      }


    });
  }


  deleteFile(customerId: number, filename: string, index: number) {
    var xhttp = this.restApi.deleteRequest(this.constructApi.getDeleteFile(customerId, filename))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // Typical action to be performed when the document is ready:
        this.sharedService.openAlert("Successfully deleted picture!", this.enums.SUCCESS_ALERT);
        // window.location.reload();

        this.docArr.splice(index, 1);
        this.cdr.detectChanges();
      }
    };
  }

  changeFileType(customerID: number, filename: string, targetType: string) {

    var xhr = this.restApi.patchRequest(this.constructApi.getChangeFileType(customerID, filename, targetType));
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        if (xhr.status == 200) {          //if success emit;
          this.sharedService.openAlert("Successfully changed file type", this.enums.SUCCESS_ALERT);
          window.location.reload();
        }
        else
          this.sharedService.openErrorMessage(xhr);
      }

    }
  }

  changeContactFileType(customerID: number, contactId: number, filename: string, targetType: string) {

    var xhr = this.restApi.patchRequest(this.constructApi.getChangeContactFileType(customerID, contactId, filename, targetType));
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        if (xhr.status == 200) {          //if success emit;
          this.sharedService.openAlert("Successfully changed file type", this.enums.SUCCESS_ALERT);
          window.location.reload();
        }
        else
          this.sharedService.openErrorMessage(xhr);
      }

    }
  }

  previewFile(filename: string) {
    // var modalRef = this.sharedService.openModal(PreviewPdfComponent, "largeModal");
    // modalRef.componentInstance.url = this.restApi.apiUrl + this.constructApi.getFullFile(this.viewCustomer.ID, filename);
    // modalRef.componentInstance.filename = filename;

    var encoded = encodeURIComponent(filename);
    window.open(this.restApi.apiUrl + this.constructApi.getFullFile(this.viewCustomer.ID, encoded));

  }

}
