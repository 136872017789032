import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Company } from 'src/model/company.model';
import { LoanScheme } from 'src/model/loanScheme.model';

interface CompanyGroupInterface {
  id: number,
  name: string
}

@Component({
  selector: 'app-report-generator',
  templateUrl: './report-generator.component.html',
  styleUrls: ['./report-generator.component.css']
})


export class ReportGeneratorComponent implements OnInit {


  loanSchemeList: LoanScheme[] = new Array;
  companyGroupList: CompanyGroupInterface[] = new Array;
  companyList: Company[] = new Array;
  startDate: Date = new Date();
  endDate: Date = new Date();


  myControl = new UntypedFormControl();
  filteredCompany: Observable<Company[]>;

  @Output() sendCompanyData: EventEmitter<any> = new EventEmitter();


  // primayReportList = ["Cash Book", "List Hutang", "Stock"];
  // otherReportList = ["Stamp Report", "S/C Report", "Duit Lebih Report", "Asset Report", "Hutang", "Simpan Report", "Commission Report"];

  @Input() selectedCompany: number;
  @Input() selectedReport: number;
  @Input() selectedCompanyGroup: number = 0;
  isCollapsed: boolean = false;
  isReady: boolean = false;
  isAll: boolean = false;
  companyDisabled: boolean = false;
  loggedInRole: number;
  loggedInCompany: number;

  selectedCompanyCode: string;

  constructor(public enums: Enums, private modalService: NgbModal, private router: Router, private restapi: RestApiService,
    private constructApi: ConstructAPI, public sharedService: SharedService) {
    this.loggedInRole = Number(sessionStorage.getItem("roleId"));

    //filter company
    this.filteredCompany = this.myControl.valueChanges.pipe(
      startWith(''),
      map(companyCode => this._filterCompanyCodeOptions(companyCode))
    );
  }

  ngOnInit(): void {

    //default the start data always the beginning of the month
    this.startDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate());
    this.endDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate());

    if (this.sharedService.isMonitoringUser()) {
      this.getAllGroups();
      // this.setSelectedCompanyCode(this.selectedCompany);
    }
    else

      this.selectedCompany = Number(sessionStorage.getItem("companyId"));

  }

  private _filterCompanyCodeOptions(value: string): Company[] {
    const filterValue = value.toLowerCase();


    // return this.companyList.filter(company => company.CompanyCode.toLowerCase().includes(filterValue));
    // var filtered = this.companyList.filter(company => { return company.CompanyCode.toLowerCase().indexOf(filterValue) === 0 });
    var filtered = this.companyList.filter(company => { return company.CompanyCode.toLowerCase().includes(filterValue) });

    return filtered;
  }



  setSelectedCompanyCode(companyId: number) {
    var index = this.companyList.findIndex(company => Number(company.ID) == Number(companyId));
    this.selectedCompanyCode = this.companyList[index].CompanyCode;
  }

  setSelectedCompany(code: string) {

    var index = this.companyList.findIndex(company => company.CompanyCode.toLowerCase() == code.toLowerCase());
    this.selectedCompany = this.companyList[index].ID;
  }



  resetReady() {
    this.isReady = false;
    this.isAll = false;

    if (this.selectedReport == this.enums.ASSET_SUMMARY_REPORT) {
      this.selectedCompany = undefined;
      this.companyDisabled = true;
    }
    else
      this.companyDisabled = false;
  }

  // outputToParent() {
  //   this.sendCompanyData.emit(this.companyList);
  // }

  getAllCompanies(companyGroup: number) {
    //clear company list
    this.companyList.splice(0, this.companyList.length);

    var xhttp;
    if (companyGroup == 0 || companyGroup == undefined)
      xhttp = this.restapi.getRequest(this.constructApi.getAllCompanies())
    else
      xhttp = this.restapi.getRequest(this.constructApi.getCompanyGroupCompanies(companyGroup))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));


        jsonArray.forEach(json => {
          let company = new Company(json.id, json.name, json.address, json.companyCode, json.contactNo, undefined, json.group, new Date(json.dateOperating));
          this.companyList.push(company);
        }
        );
        // this.outputToParent();

        //set the selected company code
        if (this.selectedCompany != undefined)
          this.setSelectedCompanyCode(this.selectedCompany)
      }
    };
  }

  getAllLoanSchemes() {

    var xhttp = this.restapi.getRequest(this.constructApi.getAllLoanSchemes())
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        // console.log(jsonArray);

        jsonArray.forEach(json => {

          let loanScheme = new LoanScheme(json.id, json.name, json.term, json.interest);
          this.loanSchemeList.push(loanScheme);
        }
        );
      }
    };

  }

  getAllGroups() {

    var xhttp = this.restapi.getRequest(this.constructApi.getAllCompanyGroups())
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let group = { id: json.id, name: json.name }
          this.companyGroupList.push(group);
        }
        );
      }
    };

    this.getAllCompanies(0);

  }

  allowViewAll() {
    if (this.selectedReport == this.enums.STOCK || this.selectedReport == this.enums.CASH_BOOK || this.selectedReport == this.enums.HUTANG_REPORT ||
      this.selectedReport == this.enums.LIST_HUTANG || this.selectedReport == this.enums.SIMPAN_REPORT)
      return true
    else
      return false;
  }


  checkPass() {

    if (this.selectedReport == this.enums.ASSET_SUMMARY_REPORT) {
      if (this.selectedCompanyGroup == 0) {
        this.sharedService.openAlert("Please select either 1 company group", this.enums.DANGER_ALERT);
        return false;
      }
      return true;
    }
    else if (this.selectedReport == undefined || (this.selectedCompany == undefined && this.selectedReport != this.enums.USAGE_REPORT && this.selectedReport != this.enums.COMPANY_SUMMARY_REPORT && this.selectedReport != this.enums.OFFICE_CASH_REPORT)) {
      this.sharedService.openAlert("Please select report type and company!", this.enums.DANGER_ALERT);
      return false;
    }
    else
      return true;
  }
  generateReport() {
    // if (this.selectedReport == undefined || this.selectedCompany == undefined)
    //   this.sharedService.openAlert("Please select report type and company!");
    if (this.checkPass() == false) { }
    else {

      //IF NOT PRIMARY REPORT
      if (this.selectedReport > 5) {
        if (this.selectedReport == this.enums.ASSET_SUMMARY_REPORT)
          this.router.navigate(['/assetSummary', this.selectedCompanyGroup, this.startDate.toISOString(), this.endDate.toISOString()]);
        else if (this.selectedReport == this.enums.EXTRA_REPORT)
          this.router.navigate(['/duitLebihReport', this.selectedCompany, this.startDate.toISOString(), this.endDate.toISOString()]);
        else if (this.isAll == true)
          this.router.navigate(['/simpleReport', this.selectedCompany, this.selectedReport]);
        else if (this.selectedReport == this.enums.MIDMONTH_REPORT)
          this.router.navigate(['/midMonth', this.selectedCompany])
        else if (this.selectedReport == this.enums.USAGE_REPORT)
          this.router.navigate(['/usageReport'])
        else if (this.selectedReport == this.enums.FORMJ_REPORT)
          this.router.navigate(['/formJReport', this.selectedCompany]);
        else if (this.selectedReport == this.enums.OVERDUE_EPF_REPORT)
          this.router.navigate(['/overdueEPFReport', this.selectedCompany]);
        else if (this.selectedReport == this.enums.COMPANY_SUMMARY_REPORT)
          this.router.navigate(['/companiesSummaryReport', this.selectedCompanyGroup, this.startDate.toISOString()]);
        else if (this.selectedReport == this.enums.OFFICE_CASH_REPORT)
          this.router.navigate(['/officeCashReport', this.selectedCompanyGroup, this.startDate.toISOString()]);



        else
          this.router.navigate(['/simpleReport', this.selectedCompany, this.selectedReport, this.startDate.toISOString(), this.endDate.toISOString()]);
      }

      // PRIMARY REPORTS
      else if (this.selectedReport == this.enums.CASH_BOOK) {
        if (this.isAll == true)
          this.router.navigate(['/cashBookReport', this.selectedCompany]);
        else
          this.router.navigate(['/cashBookReport', this.selectedCompany, this.startDate.toISOString(), this.endDate.toISOString()]);
      }

      else if (this.selectedReport == this.enums.STOCK) {
        if (this.isAll == true)
          this.router.navigate(['/stockReport', this.selectedCompany]);
        else
          this.router.navigate(['/stockReport', this.selectedCompany, this.startDate.toISOString(), this.endDate.toISOString()]);
      }
      else if (this.selectedReport == this.enums.LIST_HUTANG)
        this.router.navigate(['/listHutang', this.selectedCompany, this.endDate.toISOString()]);
      else if (this.selectedReport == this.enums.DAILY_REPORT)
        this.router.navigate(['/dailyReport', this.selectedCompany, this.startDate.toISOString()]);
      else if (this.selectedReport == this.enums.CUSTOMER_RECORD_REPORT)
        this.router.navigate(['/customerRecord', this.selectedCompany]);

    }
    // this.outputToParent();
  }

  getMinDateForEndDate() {
    if (this.selectedReport != this.enums.LIST_HUTANG && this.selectedReport != this.enums.STOCK)
      return this.startDate;
    else
      return null;
  }
}
