import { DialogItem } from '../../dialog-item';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { LoanRequestDetailsComponent } from 'src/app/LoanRequest/loan-request-details/loan-request-details.component';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Contact } from 'src/model/contact.model';
import { DuitLebih } from 'src/model/duitLebih.model';
import { Loan } from 'src/model/loan.model';
import { LoanRequest } from 'src/model/loanRequest.model';
import { SettlementCharges } from 'src/model/settlementCharges.model';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-bookmark',
  templateUrl: './bookmark.component.html',
  styleUrls: ['./bookmark.component.css']
})
export class BookmarkComponent implements OnInit {
  bookmarkStorage: string = 'bookmarks';

  bookmarkedList: LoanRequest[] = new Array();
  filteredBookmarkedList: LoanRequest[] = new Array();
  bookmarks: number[] = new Array();
  deletedTickets: number[] = new Array();
  filteredDeletedTickets: number[] = new Array();
  searchText: string = "";
  searching: boolean = false;

  constructor(private restApi: RestApiService, private constructApi: ConstructAPI, public sharedService: SharedService, private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.bookmarks = (localStorage.bookmarks != undefined) ? JSON.parse(localStorage.bookmarks).filter(x => x !== null) : [];
    this.refresh();
  }

  async applyFilter() {
    if (!this.isSearchTextEmpty()) {
      try {
        this.resetFilter();
        this.filteredBookmarkedList = this.filteredBookmarkedList.filter(
          (r) => r.ID === parseInt(this.searchText, 10)
        )
        this.filteredDeletedTickets = this.deletedTickets.filter(
          (r) => r === parseInt(this.searchText, 10)
        );
        this.searching = true;
      } catch (error) {
        console.error(error);
      }
    } else {
      this.resetFilter();
    }
  }

  resetFilter() {
    this.searching = false;
    this.filteredBookmarkedList = this.bookmarkedList;
    this.filteredDeletedTickets = this.deletedTickets;
  }

  isSearchTextEmpty(): boolean {
    let result = this.searchText.length == 0;
    if (result) {
      this._snackBar.open('Please insert ticket number.', '', {
        duration: 3000,
        verticalPosition: 'top',
        panelClass: ['top-snackbar', 'bg-light', 'text-dark']
      });
    }
    return result;
  }

  addBookmark(confirm: Boolean = true) {
    if (!this.isSearchTextEmpty()) {
      let ticketId = parseInt(this.searchText, 10);

      if (!this.bookmarks.includes(ticketId)) {
        var xhttp = this.restApi.getRequest(this.constructApi.getLoanApprovalRequestById(ticketId));
        xhttp.onreadystatechange = () => {
          if (xhttp.readyState == 4) {

            if (xhttp.status == 200) {
              var json = JSON.parse((xhttp.responseText));
              var r = this.processLoanRequest(json);
              if (confirm) this.openConfirmationModal(r); 
              else this.saveBookmark();
            }
            else {
              this._snackBar.open('Ticket not found.', '', {
                duration: 3000,
                verticalPosition: 'top',
                panelClass: ['top-snackbar', 'bg-light', 'text-dark']
              });
            }
          }

        }
      } else {
        this._snackBar.open('Ticket already bookmarked.', '', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['top-snackbar', 'bg-light', 'text-dark']
        });
      }
    }

  }

  saveBookmark() {
    this.bookmarks.push(parseInt(this.searchText));
    localStorage.setItem(this.bookmarkStorage, JSON.stringify(this.bookmarks));
    this.refresh();
  }

  removeBookmark(id: number | number[]) {
    if (Array.isArray(id)) {
      var l: Set<number> = new Set(id);     
      this.bookmarks = this.bookmarks.filter( (r) => !l.has(r) ) 
    } else this.bookmarks = this.bookmarks.filter( (r) => r !== id )
    localStorage.setItem(this.bookmarkStorage, JSON.stringify(this.bookmarks));
    this.searchText = '';
    this.refresh();
  }

  processDuitLebihJsonArr(jsonArray: any) {
    var duitLebihList: DuitLebih[] = new Array;
    jsonArray.forEach(json => {
      var extra = new DuitLebih(json.amount, json.subtype)
      duitLebihList.push(extra);
    });

    return duitLebihList;
  }

  processGuarantorJsonArr(jsonArray: any) {
    var guarantorsInfo: Contact[] = new Array;
    jsonArray.forEach(json => {
      var guarantors = new Contact(json.id, json.name, json.icNumber, json.number, json.address, json.customerId, json.relation, json.remark)
      guarantorsInfo.push(guarantors);
    });

    guarantorsInfo.sort((a, b) => (a.Name > b.Name) ? 1 : -1);
    return guarantorsInfo;
  }

  openConfirmationModal(req: LoanRequest) {
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent, 'largeModal');
    modalRef.componentInstance.dialogItem = new DialogItem(LoanRequestDetailsComponent, { request: req, viewOnly: true, onlyViewLoan: false, isExpanded: true });
    modalRef.componentInstance.message = 'Are you sure to bookmark this loan request?';
    modalRef.componentInstance.passEntry.subscribe((confirm) => {
      if (confirm == true) this.saveBookmark();
    });
  }

  async refresh() {
    try {
      if (this.bookmarks.length > 0) {
        this.bookmarkedList = await this.getBookmarked(this.bookmarks);
        this.deletedTickets = this.ticketNotExists();
      } else {
        this.bookmarkedList = [];
        this.deletedTickets = [];
      }
      this.resetFilter();
    } catch (error) {
      console.error(error);
    }
  }

  processLoanRequest(json: any): LoanRequest {
    var loanInfoJson = json.loanInfo
    var stampAmount = (loanInfoJson.stamp == undefined) ? 0 : loanInfoJson.stamp;
    var serviceChargeAmount = (loanInfoJson.serviceCharge == undefined) ? 0 : loanInfoJson.serviceCharge;
    var reserve = (loanInfoJson.reserve == undefined) ? 0 : loanInfoJson.reserve;

    var extras = (loanInfoJson.extras == undefined) ? undefined : this.processDuitLebihJsonArr(loanInfoJson.extras);

    var settleStamp = (loanInfoJson.settlementInfo == undefined || loanInfoJson.settlementInfo.stamp == undefined) ? 0 : loanInfoJson.settlementInfo.stamp;
    var settleSc = (loanInfoJson.settlementInfo == undefined || loanInfoJson.settlementInfo.serviceCharge == undefined) ? 0 : loanInfoJson.settlementInfo.serviceCharge;
    var settleExtra = (loanInfoJson.settlementInfo == undefined || loanInfoJson.settlementInfo.extras == undefined) ? 0 : loanInfoJson.settlementInfo.extras;

    var settlementCharges = (loanInfoJson.settlementCharges == undefined) ? undefined : new SettlementCharges(loanInfoJson.settlementCharges.serviceCharge, loanInfoJson.settlementCharges.stamp, loanInfoJson.settlementCharges.extras, loanInfoJson.settlementCharges.handlingCharge);

    var loanInfo = new Loan(undefined, loanInfoJson.profileId, loanInfoJson.principal, stampAmount,
      undefined, undefined, undefined, undefined, undefined, loanInfoJson.scheme, loanInfoJson.interestRate, loanInfoJson.term,
      undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined,
      reserve, undefined, undefined, undefined, serviceChargeAmount, extras, undefined, undefined,
      undefined, loanInfoJson.processingRate, undefined, undefined, undefined,
      settleStamp, settleSc, settleExtra, undefined, undefined, undefined, undefined, undefined, undefined, settlementCharges);

    var guarantorsInfo = this.processGuarantorJsonArr(json.guarantors);
    let a = new LoanRequest(json.customerId, json.epfAmount, json.epfDate,
      guarantorsInfo, json.hasIAccount, json.id, loanInfo, json.processedRemark,
      json.profileId, json.remark, json.requestBy, json.requestOn, json.updatedOn,
      json.approved, json.loanId, json.companyCode, json.icNumber, json.name, json.companyId, undefined, json.processedOn, json.authorizedBy, undefined, undefined, undefined, undefined, json.dob, json.otherId);
    return a;
  }

  getBookmarkListByStatus(approved: boolean[]): LoanRequest[] {
    return this.filteredBookmarkedList.filter(obj => approved.includes(obj.Approved));
  }

  ticketNotExists(): number[] {
    let list = new Set(this.bookmarkedList.map(x => x.ID));
    var result: number[] = result = this.bookmarks.reduce((a, t) => {
      if (!list.has(t)) a.push(t);
      return a;
    }, new Array());
    return result;
  }

  getBookmarked(bookmarks) {
    return new Promise<LoanRequest[]>(resolve => {
      var xhttp = this.restApi.getRequest(this.constructApi.getLoanApprovalRequestByIds(bookmarks));
      xhttp.onreadystatechange = () => {
        if (xhttp.readyState == 4 && xhttp.status == 200) {
          let jsonArray = JSON.parse((xhttp.responseText));
          let result: LoanRequest[] = new Array();
          result = jsonArray.map((r) => this.processLoanRequest(r));       
          resolve(result);
        }
      }
    });
  }
}
