export class Role {
    constructor(private _id:number,private _name:string) {
        
    }


    set ID(id: number) { this._id = id }
    get ID(): number { return this._id }

    set Name(name: string) { this._name = name }
    get Name(): string { return this._name }


}