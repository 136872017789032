<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Loan Transaction</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body">
        <div class="container-fluid p-0">
            <!-- <app-loan-card [loan]="selectedLoan"></app-loan-card>
            <hr /> -->
            <!-- <app-transaction-history [transactionList]="loanTransactionList"></app-transaction-history> -->
            <div *ngFor="let receipt of receiptList">
                <h6><b>{{receipt.Number}}</b></h6>

                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th
                                *ngIf="isDeletable ==true && (this.sharedService.isAdmin()==true|| sharedService.isBossAccount()==true)">
                            </th>
                            <th style="width: 10%">Date</th>
                            <th style="width: 40%;text-align: center;">Remark</th>
                            <th style="width: 15%">Amount</th>
                            <th style="width: 20%">Type</th>
                            <!-- <th>Closing Balance</th> -->
                            <th style="width: 15%">HandleBy</th>
                        </tr>

                    </thead>
                    <tbody>
                        <tr *ngFor="let transaction of receipt.Transactions">
                            <td
                                *ngIf="isDeletable==true &&(this.sharedService.isAdmin()==true||sharedService.isBossAccount()==true)">
                                <button class="btn btn-danger" (click)="confirmDeleteTranscation(transaction)"><i
                                        class="fas fa-trash-alt"></i></button>
                            </td>
                            <td style="cursor: pointer;" [matTooltip]="transaction.Date  |
                        date:'dd-MM-yyyy hh:mm:ss'" matTooltipPosition="above" matTooltipHideDelay="100"
                                matTooltipClass="tooltip-black">
                                {{transaction.Date | date:'dd-MM-yyyy'}}
                            </td>
                            <td class="text-center">
                                {{transaction.Remark}}
                            </td>

                            <td [ngClass]=" checkAmountInOut(transaction.Amount)">
                                {{transaction.Amount}}
                            </td>
                            <td>
                                {{enums.transactionTypeString[transaction.Type-1]}}
                            </td>
                            <!-- <td>
                            {{transaction.ClosingBalance}}
                        </td> -->
                            <td>
                                {{transaction.HandleBy}}
                            </td>
                        </tr>
                    </tbody>

                </table>
            </div>
        </div>
    </div>
</form>