import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';

@Component({
  selector: 'app-delete-reason',
  templateUrl: './delete-reason.component.html',
  styleUrls: ['./delete-reason.component.css']
})
export class DeleteReasonComponent implements OnInit {

  deleteRemark: string;
  disabledSubmit: boolean = false;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  constructor(private activeModal: NgbActiveModal, private sharedService: SharedService, public enums: Enums) { }

  ngOnInit(): void {
  }

  submit() {
    if (this.deleteRemark == undefined || this.deleteRemark == "")
      this.sharedService.openAlert("Please key in your reason to proceed deletion!",this.enums.DANGER_ALERT);
    else
      this.passEntry.emit(this.deleteRemark);

    this.activeModal.close();
  }


  reset() {
    this.deleteRemark = "";
    this.activeModal.close();
  }

}
