import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { RestApiService } from 'src/app/API/restapi';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { Account } from 'src/model/account.model';
import { FundAllocation } from 'src/model/fundAllocation.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertDialogComponent } from 'src/app/Shared/alert-dialog/alert-dialog.component';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Enums } from 'src/app/Shared/enums';

@Component({
  selector: 'app-fund-reallocation',
  templateUrl: './fund-reallocation.component.html',
  styleUrls: ['./fund-reallocation.component.css']
})

export class FundReallocationComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns: string[];
  dataSource = new MatTableDataSource;

  accountList: Account[] = new Array;

  managerAccount: Account;
  loggedInUsername: string;
  totalAdjustment = 0;

  fundAllocationList: FundAllocation[] = new Array;
  date: Date;
  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private restapi: RestApiService, private constructApi: ConstructAPI,
    public sharedService: SharedService, private enums: Enums) {


    // get cashAccounts
    this.getAllCashAccounts();
    this.loggedInUsername = sessionStorage.getItem("username");
  }


  ngOnInit(): void {

    this.displayedColumns = ['Name', 'Current', 'Allocation', 'Adjustment'];


  }

  checkIsOwnAccount(username: string) {

    var loggedInUsername = sessionStorage.getItem["username"];
    if (username == loggedInUsername)
      return true;
    else
      return false;

  }
  calculateAdjustment(account: Account) {

    account.Adjustment = account.Allocation - account.Balance;
    return account.Adjustment;
  }

  getTotalAdjustment() {
    this.totalAdjustment = this.accountList.map(t => t.Adjustment).reduce((acc, value) => acc + value, 0);

    return this.totalAdjustment;
  }

  getAllCashAccounts() {
    var xhttp = this.restapi.getRequest(this.constructApi.getAllAccounts())
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // Typical action to be performed when the document is ready:

        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {

          let account = new Account(json.id, json.accountNumber, json.name, json.holderName, json.balance, json.companyId, json.username, json.balance)

          //if username undefined = cash account
          //because only manager can come into this function, so just check the loggedInUsername to identify manager
          if (account.Username != undefined) {
            if (account.Username != this.loggedInUsername)
              //add account instance into account list
              this.accountList.push(account);
            else
              this.managerAccount = account;
          }

        }
        );

        //make account list as the datasource
        this.dataSource = new MatTableDataSource<Account>(this.accountList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

      }
    };
    // xhttp.open("GET", url, true);
    // xhttp.send();

  }

  calculateManagerAdjustment(managerAccount: Account) {

    this.managerAccount.Adjustment = (managerAccount.Balance - this.totalAdjustment) - managerAccount.Balance;
    return this.managerAccount.Adjustment;
  }

  createFundAllocationJSON() {
    for (let i = 0; i < this.accountList.length; i++) {
      var fund = new FundAllocation(this.accountList[i].Username, this.accountList[i].Adjustment);
      this.fundAllocationList.push(fund);
    }

    var managerFund = new FundAllocation(this.managerAccount.Username, this.managerAccount.Adjustment);
    this.fundAllocationList.push(managerFund);

    return this.fundAllocationList

  }

  postFundAllocation() {

    if (this.date == undefined)
      this.sharedService.openAlert("Please select Reallocation date!", this.enums.DANGER_ALERT);
    else {
      var data = {
        "date": this.date.toISOString(),
        "accounts": this.createFundAllocationJSON()
      }

      var xhr = this.restapi.postRequest(this.constructApi.getPostFundAllocation(), data)
      xhr.onreadystatechange = () => {
        if (xhr.readyState == 4 && xhr.status == 200) {

          this.reset();


          this.sharedService.openAlert("Successfully reallocate fund!", this.enums.SUCCESS_ALERT);


        }
      }
    }

  }

  reset() {
    this.activeModal.close();

  }



}
