import { Transaction } from './transaction.model'

export class Receipt {
    constructor(private _id, private _number: string, private _transactions: Transaction[], private _scheme?:string,private _schemeInterest?: number) { }


    set ID(id: number) { this._id = id }
    get ID(): number { return this._id }

    set Number(number: string) { this._number = number }
    get Number(): string { return this._number }

    set Scheme(scheme: string) { this._scheme = scheme }
    get Scheme(): string { return this._scheme }

    set SchemeInterest(schemeInterest: number) { this._schemeInterest = schemeInterest }
    get SchemeInterest(): number { return this._schemeInterest }

    set Transactions(transactions: Transaction[]) { this._transactions = transactions }
    get Transactions(): Transaction[] { return this._transactions }

}