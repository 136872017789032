<div class="max-width:30vw;">
    <h4>Necessary File Checklist</h4>
    <table class="table table-bordered" *ngIf="neccessaryFileList.length>0 && neccessaryFileList!=undefined">
        <tbody>
            <tr *ngFor="let file of neccessaryFileList">
                <td> {{file.type| uppercase}}</td>
                <td>
                    <h5 class="text-success" *ngIf="file.existed==true"><i class="fas fa-check-circle"></i></h5>
                    <h5 class="text-danger" *ngIf="file.existed==false"> <i class=" fas fa-times-circle"></i></h5>
                </td>
            </tr>
        </tbody>
    </table>

    <hr />
    <h4>Optional File Checklist</h4>
    <table class="table table-bordered" *ngIf="optionalFileList.length>0 && optionalFileList!=undefined">
        <tbody>
            <tr *ngFor="let file of optionalFileList">
                <td> {{file.type| uppercase}}</td>
                <td>
                    <h5 class="text-success" *ngIf="file.existed==true"><i class="fas fa-check-circle"></i></h5>
                    <h5 class="text-danger" *ngIf="file.existed==false"> <i class=" fas fa-times-circle"></i></h5>
                </td>
            </tr>
        </tbody>
    </table>
</div>