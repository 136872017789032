<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Deletion Reason</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()" aria-label="Close"></button>
    </div>

    <div class="modal-body">
        <div class="container-fluid p-0">
            <div class="mt-2">
                <div class="col">
                    <div><span>Reason</span></div>
                    <div><textarea class="form-control" rows="10" required [(ngModel)]="deleteRemark"
                            name="deleteRemark"></textarea></div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="submit()"
            [disabled]="disabledSubmit">Delete</button>
        <button class="btn btn-outline-danger" type="button" data-dismiss="modal" (click)="reset()">Cancel</button>
    </div>
</form>