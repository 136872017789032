import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ArgumentOutOfRangeError } from 'rxjs';
import { DeleteReasonRemarkComponent } from 'src/app/Account/delete-reason-remark/delete-reason-remark.component';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { CustomerTransactionsComponent } from 'src/app/Customer/customer-transactions/customer-transactions.component';
import { AddReceiptSetComponent } from 'src/app/Sets/add-receipt-set/add-receipt-set.component';
import { ReceiptDetailsComponent } from 'src/app/Sets/receipt-details/receipt-details.component';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Account } from 'src/model/account.model';
import { Transaction } from 'src/model/transaction.model';


interface ReportRecord {
  date?: Date,
  remark?: string,
  amount: number,
  receiptNumber?: string,
  receiptId?: number,
  type?: number,
  customerName?: string,
  customerCode?: string,
  customerId?: number,
  profileId?: number,
  transactionId?: number,
  containsExtra?: boolean,
  extrasAmount?: number,
  groupId?: number,
  chargesAmount?: number,
  setId?: number,
  cashbookRelatedAmount?: number,
  nonCashbookRelatedTransactions?: Transaction[]

}



@Component({
  selector: 'app-daily-report',
  templateUrl: './daily-report.component.html',
  styleUrls: ['./daily-report.component.css'],
  providers: [DatePipe]
})
export class DailyReportComponent implements OnInit {
  @Input() selectedCompany: number;
  @Input() selectedReport: number;
  @Input() startDate: Date;

  totalCashBalance = 0;


  reportList: ReportRecord[] = new Array;
  loanReportList: ReportRecord[] = new Array;
  isCollapsed: boolean = false;

  companyBankAccountList: Account[] = new Array;
  cashAccountList: Account[] = new Array;

  routeSubscription: any;


  constructor(public enums: Enums, private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe, private router: Router, private restapi: RestApiService, private constructApi: ConstructAPI, public sharedService: SharedService) { }

  ngOnInit(): void {

    this.routeSubscription = this.activatedRoute.paramMap.subscribe(params => {
      this.selectedCompany = + params.get('companyId');
      this.startDate = (params.get('startDate') == undefined) ? undefined : new Date(params.get('startDate'));
      this.selectedReport = + params.get('reportId');

      if (this.startDate != undefined && this.selectedReport != undefined) {
        this.getLoansTransaction();
        this.getOtherTranscations();

        this.getLastBalance(this.selectedCompany);
      }

      if (this.sharedService.isBranchAccountHandler())
        this.getAllAccountsOfCompany(this.selectedCompany);

    });
  }

  private getAllAccountsOfCompany(companyId: number) {

    var xhttp = this.restapi.getRequest(this.constructApi.getAccountsOfCompany(companyId));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {

          var amount = (json.balance == undefined) ? 0 : json.balance;
          let account = new Account(json.id, json.number, json.name, json.holderName, amount, json.companyId, json.username);

          if (account.Username != undefined)
            this.cashAccountList.push(account)
          else {
            this.companyBankAccountList.push(account);
          }
        }
        );

      }
    };
  }

  getPositiveNegative(amount) {
    if (amount > 0)
      return "text-success";
    else
      return "text-danger"

  }
  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }

  goToCustomerProfile(selectedCustomerID: number, selectedProfileId: number) {
    this.router.navigate(['/manageCustomer', selectedCustomerID, selectedProfileId]);
  }

  goToProfileTransaction(profileId: number) {
    const modalRef = this.sharedService.openModal(CustomerTransactionsComponent, "largeModal");
    modalRef.componentInstance.profileId = profileId;
    modalRef.componentInstance.startDate = this.startDate;
    modalRef.componentInstance.endDate = this.startDate;
    modalRef.componentInstance.companyId = this.selectedCompany;
  }

  calculateTotal() {
    var total = 0;

    for (let i = 0; i < this.loanReportList.length; i++) {
      total += this.loanReportList[i].amount;
    }

    return total;
  }

  calculateTotalCharges() {
    var total = 0;

    for (let i = 0; i < this.loanReportList.length; i++) {
      if (this.loanReportList[i].chargesAmount != undefined)
        total += this.loanReportList[i].chargesAmount;
    }

    return total;
  }

  calculateTotalCashbookRelatedAmount() {
    var total = 0;

    for (let i = 0; i < this.loanReportList.length; i++) {
      if (this.loanReportList[i].cashbookRelatedAmount != undefined)
        total += this.loanReportList[i].cashbookRelatedAmount;
    }

    return total;
  }

  calculateTotalOtherTransaction() {
    var total = 0;

    for (let i = 0; i < this.reportList.length; i++) {
      total += this.reportList[i].amount;
    }

    return total;
  }
  calculateTotalNonRelatedTransactions(index: number) {
    var total = 0;
    if (this.loanReportList[index].nonCashbookRelatedTransactions.length > 0) {
      for (let j = 0; j < this.loanReportList[index].nonCashbookRelatedTransactions.length; j++)
        total += this.loanReportList[index].nonCashbookRelatedTransactions[j].Amount;
    }



    return this.sharedService.roundTo2Dec(total);
  }

  getLastBalance(companyId: number) {

    this.totalCashBalance = 0;
    if (this.startDate != undefined)
      var yesterdayReducedASec = new Date(Number(this.startDate) - 1);
    var yesterdayStr = (this.startDate == undefined) ? undefined : yesterdayReducedASec.toISOString();

    var xhttp = this.restapi.getRequest(this.constructApi.getAccountsOfCompany(companyId, yesterdayStr));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          var amount = (json.balance == undefined) ? 0 : json.balance;

          this.totalCashBalance += amount;
        }
        );
      }
    };
  }

  getLoansTransaction() {
    this.loanReportList.splice(0, this.loanReportList.length);
    var startDateStr = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate()).toISOString();

    if (this.startDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var addADayDate = new Date(Number(this.startDate) + (3600 * 1000 * 24) - 1);
    var endDateStr = addADayDate.toISOString();

    var xhttp = this.restapi.getRequest(this.constructApi.getCompanyAllLoansTransactions(this.selectedCompany, startDateStr, endDateStr))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {

          //check if the transaction type = DUIT LEBIH (EXTRAS), if YES mark whether is positive or negative
          var isExtra = (json.type == this.enums.EXTRAS);
          var isCharges = (json.type == this.enums.STAMP || json.type == this.enums.SERVICE_CHARGE || json.type == this.enums.HANDLING_CHARGE)
          var isCashbookRelated = (json.type == this.enums.REPAYMENT || json.type == this.enums.PRINCIPAL || json.type == this.enums.EXTRA_INTEREST || json.type == this.enums.ARREAR_CHARGES
            || json.type == this.enums.MONTHLY_INTEREST || json.type == this.enums.PROCESSING_CHARGE || json.type == this.enums.DISCOUNT)

          //find if profile existed in list
          var index = (this.loanReportList.length > 0) ? this.loanReportList.findIndex(report => report.profileId === json.profileId) : -1;

          //If existed
          if (index != -1) {
            //if this transcation is EXTRAS, mark YES.
            //if not EXTRAS, dont change anything
            if (isExtra == true) {
              this.loanReportList[index].containsExtra = isExtra;
              this.loanReportList[index].extrasAmount += Number(json.amount)
            }

            this.loanReportList[index].amount += Number(json.amount);

            if (isCharges == true)
              this.loanReportList[index].chargesAmount += Number(json.amount)
            else if (isCashbookRelated == true)
              this.loanReportList[index].cashbookRelatedAmount += Number(json.amount)
            else
              this.loanReportList[index].nonCashbookRelatedTransactions.push(new Transaction(undefined, undefined, json.amount, undefined, undefined, undefined, json.type, undefined))
          }

          //If its new record
          else {
            //add new record
            var extraAmount = (isExtra == true) ? json.amount : 0;
            var chargesAmount = (isCharges == true) ? json.amount : 0;
            var cashbookRelatedAmount = (isCashbookRelated == true) ? json.amount : 0;
            var nonCashbookRelatedTransactions;
            if (isCashbookRelated == false)
              nonCashbookRelatedTransactions = [new Transaction(undefined, undefined, json.amount, undefined, undefined, undefined, json.type, undefined)]
            else
              nonCashbookRelatedTransactions = new Array;

            var loanRecord = {
              date: json.date, remark: json.remark, amount: json.amount, receiptNumber: json.receiptNumber, receiptId: json.receiptId,
              type: json.type, customerName: json.customerName,
              customerCode: json.customerCode, customerId: json.customerId, profileId: json.profileId,
              containsExtra: isExtra, extrasAmount: extraAmount, chargesAmount: chargesAmount, cashbookRelatedAmount: cashbookRelatedAmount, nonCashbookRelatedTransactions: nonCashbookRelatedTransactions
            }
            this.loanReportList.push(loanRecord);
          }
        }
        );

        this.getReserveTransactions();
      }
    };
  }



  getReserveTransactions() {
    var startDateStr = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate()).toISOString();

    if (this.startDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var addADayDate = new Date(Number(this.startDate) + (3600 * 1000 * 24) - 1);
    var endDateStr = (this.startDate == undefined) ? undefined : addADayDate.toISOString();

    var xhttp = this.restapi.getRequest(this.constructApi.getCompanyReserveReceipts(this.selectedCompany, startDateStr, endDateStr))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {

          var index = (this.loanReportList.length > 0) ? this.loanReportList.findIndex(report => report.profileId === json.profileId) : -1;
          if (index != -1) {
            var reserveAmount = this.processReserveData(json.transactions);
            this.loanReportList[index].amount += reserveAmount;
            this.loanReportList[index].nonCashbookRelatedTransactions.push(new Transaction(undefined, undefined, reserveAmount, undefined, undefined, undefined, this.enums.RESERVE, undefined))
          } else {

            var reserveAmount = this.processReserveData(json.transactions);
            var nonCashbookRelatedTransactions = new Transaction(undefined, undefined, reserveAmount, undefined, undefined, undefined, this.enums.RESERVE, undefined)
            var loanRecord = { amount: reserveAmount, customerName: json.name, customerCode: json.customerCode, customerId: json.customerId, profileId: json.profileId, chargesAmount: 0, nonCashbookRelatedTransactions: [nonCashbookRelatedTransactions] }
            this.loanReportList.push(loanRecord);
          }
        }
        );

        this.getBDReserveTransactions();
      }
    };
  }

  getBDReserveTransactions() {
    var startDateStr = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate()).toISOString();

    if (this.startDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var addADayDate = new Date(Number(this.startDate) + (3600 * 1000 * 24) - 1);
    var endDateStr = (this.startDate == undefined) ? undefined : addADayDate.toISOString();

    var xhttp = this.restapi.getRequest(this.constructApi.getCompanyBDReserveReceipts(this.selectedCompany, startDateStr, endDateStr))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {

          var index = (this.loanReportList.length > 0) ? this.loanReportList.findIndex(report => report.profileId === json.profileId) : -1;
          if (index != -1) {
            var reserveAmount = this.processReserveData(json.transactions);
            this.loanReportList[index].amount += reserveAmount;
            this.loanReportList[index].nonCashbookRelatedTransactions.push(new Transaction(undefined, undefined, reserveAmount, undefined, undefined, undefined, this.enums.BAD_DEBT, undefined))
            console.log(this.loanReportList[index].nonCashbookRelatedTransactions)
          } else {

            var reserveAmount = this.processReserveData(json.transactions);
            var nonCashbookRelatedTransactions = new Transaction(undefined, undefined, reserveAmount, undefined, undefined, undefined, this.enums.BAD_DEBT, undefined)
            var loanRecord = { amount: reserveAmount, customerName: json.name, customerCode: json.customerCode, customerId: json.customerId, profileId: json.profileId, nonCashbookRelatedTransactions: [nonCashbookRelatedTransactions] }
            this.loanReportList.push(loanRecord);
          }
        }
        );
      }
    };
  }

  processReserveData(jsonTransactions: any) {
    var amount = 0;
    jsonTransactions.forEach(json => {

      amount += Number(json.amount);

    }
    );
    return amount;
  }

  getOtherTranscations() {
    this.reportList.splice(0, this.reportList.length);
    var startDateStr = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate()).toISOString();

    if (this.startDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var addADayDate = new Date(Number(this.startDate) + (3600 * 1000 * 24) - 1);
    var endDateStr = (this.startDate == undefined) ? undefined : addADayDate.toISOString();

    var types = [this.enums.STAMP, this.enums.SERVICE_CHARGE, this.enums.EXTRAS, this.enums.ASSET, this.enums.ADVANCE, this.enums.TRANSFER, this.enums.REALLOCATION, this.enums.EXPENSE, this.enums.CAPITAL, this.enums.SAVING, this.enums.TOTING, this.enums.BOUNTY, this.enums.HANDLING_CHARGE]
    var xhttp = this.restapi.getRequest(this.constructApi.getAllTransactions(this.selectedCompany, types, startDateStr, endDateStr))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          if ((Number(json.type) == this.enums.SERVICE_CHARGE || json.type == this.enums.STAMP || json.type == this.enums.EXTRAS || json.type == this.enums.HANDLING_CHARGE)) {
            if (Number(json.amount < 0) && json.loanId == undefined) {
              var record = { remark: json.remark, amount: json.amount, type: json.type, transactionId: json.id, date: json.date, groupId: json.groupId, setId: json.setId }
              this.reportList.push(record);
            }
          }
          else {
            var record = { remark: json.remark, amount: json.amount, type: json.type, transactionId: json.id, date: json.date, groupId: json.groupId, setId: json.setId }
            this.reportList.push(record);
          }
        }
        );
        this.reportList.sort((a, b) => (b.type > a.type) ? 1 : -1);
      }
    };
  }
  openSetDialog(setID: number) {
    var modalRef = this.sharedService.openModal(ReceiptDetailsComponent);
    modalRef.componentInstance.receiptId = setID;
    modalRef.componentInstance.isModal = true;

  }
  openDeleteTransactionDialog(transaction: ReportRecord) {

    var timeDiff = new Date().getTime() - new Date(transaction.date).getTime();
    var dateDiff = timeDiff / (1000 * 3600 * 24);
    if (dateDiff < 90 || this.sharedService.isBossAccount() == true) {

      const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
      modalRef.componentInstance.message = "Confirm to delete transaction amount = RM " + transaction.amount + " and remark=" + transaction.remark + "?";


      //get confirmation
      modalRef.componentInstance.passEntry.subscribe((entry) => {
        var confirmation = entry;
        if (confirmation == true) {
          const modalRef = this.sharedService.openModal(DeleteReasonRemarkComponent);
          var transactionObj = new Transaction(transaction.transactionId, transaction.remark, transaction.amount, undefined, undefined,
            transaction.date, transaction.type, undefined, transaction.receiptId, transaction.customerId, undefined, undefined, undefined, undefined, transaction.groupId)

          modalRef.componentInstance.transaction = transactionObj;
          modalRef.componentInstance.companyId = this.selectedCompany;
          modalRef.componentInstance.groupId = transaction.groupId;
          modalRef.componentInstance.passEntry.subscribe((entry) => {
            var remark = entry;

            //append added account to account list

            if (transaction.groupId == undefined)
              this.deleteTransaction(remark, transaction.transactionId, transaction.type);
            else
              this.deleteGroupTransaction(remark, transaction.groupId)
          });
        }
      });
    }
    else
      this.sharedService.openAlert("This transaction is too old, please contact your admin to delete", this.enums.DANGER_ALERT);
  }


  deleteGroupTransaction(remark: string, groupId: number) {
    var data =
    {
      "reason": remark
    };


    var xhr = this.restapi.deleteRequest(this.constructApi.getDeleteGroupTransaction(this.selectedCompany, groupId), data);


    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        if (xhr.status == 200) {
          window.location.reload();
        }
        else {
          // this.sharedService.openAlert("Opps, some errors occured. Please try again later");
          this.sharedService.openErrorMessage(xhr);
        }


      }

    }
  }
  deleteTransaction(remark: string, transactionID: number, transactionType: number) {
    var data =
    {
      "reason": remark
    };

    var xhr;
    switch (transactionType) {
      case this.enums.ADVANCE:
        xhr = this.restapi.deleteRequest(this.constructApi.getDeleteAdvanceTransaction(transactionID), data);
        break;
      case this.enums.ASSET:
        xhr = this.restapi.deleteRequest(this.constructApi.getDeleteAssetTransaction(transactionID), data);
        break;
      default:
        xhr = this.restapi.deleteRequest(this.constructApi.getDeleteTransaction(transactionID), data);
        break;
    }

    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {

        if (xhr.status == 200) {
          window.location.reload();
        }
        else {
          // this.sharedService.openAlert("Opps, some errors occured. Please try again later");
          this.sharedService.openErrorMessage(xhr);
        }


      }

    }

  }

  addThisTransactionIntoNewSet(transaction: ReportRecord) {
    var modalRef = this.sharedService.openModal(AddReceiptSetComponent)
    modalRef.componentInstance.transactionIDList = [transaction.transactionId];
    modalRef.componentInstance.startDate = this.startDate;


  }

}
