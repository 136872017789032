<form>
    <!-- Modal Header -->
    <div class="modal-header" style="background-color: deepskyblue;">
        <h4 class="modal-title">Confirmation Dialog</h4>
        <!-- <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="closeDialog()"></button> -->
    </div>

    <div class="modal-body">
        <div class="container-fluid">
            <div class="p-3">
                <p class="messageText">{{message}}</p>
                <ng-container #anchor></ng-container>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="setConfirmation(true)">Yes</button>
        <button class="btn btn-outline-danger" type="button" (click)="setConfirmation(false)">No</button>
    </div>
</form>