import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { User } from 'src/model/user.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { RestApiService } from 'src/app/API/restapi';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { EditUserComponent } from '../edit-user/edit-user.component';
import { Account } from 'src/model/account.model';
import { AddExpensesComponent } from 'src/app/AddThings/add-expenses/add-expenses.component';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Enums } from 'src/app/Shared/enums';


interface UnpaidStaffCommissionInterface {
  StaffUsername: string;
  StaffBasic: number;
  UnpaidCommissionTotal: number;
}

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  userList: User[] = new Array;
  displayedColumns: string[] = ['Username', 'RoleName', 'CompanyName', 'Salary'];
  dataSource = new MatTableDataSource;

  cashAccountList: Account[] = new Array;
  companyBankAccountList: Account[] = new Array;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  constructor(private modalService: NgbModal, private restapi: RestApiService, private constructApi: ConstructAPI, private cdr: ChangeDetectorRef, public sharedService: SharedService, public enums: Enums) { }

  ngOnInit(): void {
    this.getAllUsers();
    if (this.sharedService.isBossAccount()) {
      this.displayedColumns.push('Actions');
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getAllUsers() {
    var xhttp = this.restapi.getRequest(this.constructApi.getAllUsers());
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // Typical action to be performed when the document is ready:

        var jsonArray = JSON.parse((xhttp.responseText));
        // console.log(jsonArray);

        jsonArray.forEach(json => {

          // create user instance with all response data
          let user = new User(json.id, json.companyId, json.companyCode, json.roleId, json.roleName, json.username, json.password, json.salary, json.active);


          //add user instance into user list
          this.userList.push(user);
        }
        );

        //make user list as the datasource
        this.dataSource = new MatTableDataSource<User>(this.userList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

      }
    };

  }

  confirmDeleteUser(user: User) {

    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Are you sure you want to delete this user (" + user.Username + ") ?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something

      if (confirmation == true)
        this.deleteUser(user);

    });

  }
  deleteUser(user: User) {
    var xhttp = new XMLHttpRequest();
    var url = "http://localhost:10080/api/v1/user/" + user.Username;
    xhttp.open("DELETE", url, true);
    xhttp.withCredentials = true;
    xhttp.setRequestHeader("Content-type", "application/json");


    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // Typical action to be performed when the document is ready:
        //  document.getElementById("demo").innerHTML = xhttp.responseText;
        // console.log(this.responseText);


        for (let i in this.userList) {
          if (this.userList[i].Username == user.Username) {
            this.userList.splice(Number(i), 1);
            // this.cdr.detectChanges();
            this.refreshDataSource();

          }
        }
      }
    };
    xhttp.send();
  }


  refreshDataSource() {
    this.dataSource.data = this.userList;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  openEditUserDialog(user: User) {
    const modalRef = this.sharedService.openModal(EditUserComponent);
    modalRef.componentInstance.username = user.Username;
    modalRef.componentInstance.amount = user.Salary;
    modalRef.componentInstance.selectedRoleId = user.RoleId;
    modalRef.componentInstance.companyId = user.CompanyId;

    //get user that was updated
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var updatedUser = entry;

      var index = this.userList.findIndex(user => user.Username === updatedUser.Username);
      this.userList[index].Salary = updatedUser.Salary;
      this.userList[index].RoleId = updatedUser.RoleId;
      this.userList[index].RoleName = updatedUser.RoleName;
    });

    modalRef.result.then(() => {
      // console.log("added task")
      // this.cdr.detectChanges();
      this.refreshDataSource();
    })
  }


  openAddSalaryExpenseModal(username: string, amount: number) {
    var companyId = Number(sessionStorage.getItem("companyId"));

    this.getAllAccountsOfCompany(companyId);
    var modalRef = this.sharedService.openModal(AddExpensesComponent);
    modalRef.componentInstance.companyBankAccountList = this.companyBankAccountList;
    modalRef.componentInstance.cashAccountList = this.cashAccountList;
    modalRef.componentInstance.remark = "SALARY - " + username;
    modalRef.componentInstance.amount = amount;

    modalRef.componentInstance.passEntry.subscribe((entry) => {
      window.location.reload();

    });

    modalRef.result.then(() => {
      this.cdr.detectChanges();
    })

  }

  private getAllAccountsOfCompany(companyId: number) {

    var xhttp = this.restapi.getRequest(this.constructApi.getAccountsOfCompany(companyId));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {

          var amount = (json.balance == undefined) ? 0 : json.balance;
          let account = new Account(json.id, json.number, json.name, json.holderName, amount, json.companyId, json.username);

          if (account.Username != undefined)
            this.cashAccountList.push(account)
          else {
            this.companyBankAccountList.push(account);
          }


        }
        );

      }
    };
  }


  promoteUser(user: User) {
    var data = {
      "roleId": this.enums.BRANCH_MANAGER
    };

    var xhr = this.restapi.putRequest(this.constructApi.getEditUserRole(user.CompanyId, user.Username), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {

        if (xhr.status == 200) {
          window.location.reload();

          this.sharedService.openAlert("Successfully promoted user!", this.enums.SUCCESS_ALERT);
        }
        else {
          var json = JSON.parse(xhr.responseText);
          this.sharedService.openAlert(json.error, this.enums.DANGER_ALERT);
        }

      }
    }
  }

  demoteUser(user: User) {
    var data = {
      "roleId": this.enums.STAFF
    };

    var xhr = this.restapi.putRequest(this.constructApi.getEditUserRole(user.CompanyId, user.Username), data);
    xhr.onreadystatechange = () => {
      if (xhr.status == 200) {
        window.location.reload();

        this.sharedService.openAlert("Successfully demoted user!", this.enums.SUCCESS_ALERT);
      }
      else {
        var json = JSON.parse(xhr.responseText);
        this.sharedService.openAlert(json.error, this.enums.DANGER_ALERT);
      }
    }
  }

  confirmBeforeDeactive(user: User) {
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Are you sure you want to deactivate this user - " + user.Username + "?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something

      if (confirmation == true) {
        this.deactivateUser(user);
      }
    });
  }


  confirmBeforeDemote(user: User) {
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Are you sure you want to demote this user - " + user.Username + ", make him/her become STAFF?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something

      if (confirmation == true) {
        this.demoteUser(user);
      }
    });
  }

  confirmBeforePromote(user: User) {
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Are you sure you want to promote this user - " + user.Username + ", make him/her become BRANCH MANAGER?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something

      if (confirmation == true) {
        this.promoteUser(user);
      }
    });
  }


  deactivateUser(user: User) {
    var xhr = (user.RoleId == this.enums.ADMIN || user.RoleId == this.enums.AUDITOR || user.RoleId == this.enums.BOSS|| user.RoleId == this.enums.EXPENSE_ADMIN)
      ? this.restapi.putRequest(this.constructApi.getDeactivateUser(user.Username))
      : this.restapi.putRequest(this.constructApi.getComapnyDeactivateUser(user.CompanyId, user.Username));

    xhr.onreadystatechange = () => {
      if (xhr.status == 200) {
        window.location.reload();

        this.sharedService.openAlert("Successfully deactivated user!", this.enums.SUCCESS_ALERT);
      }
      else {
        var json = JSON.parse(xhr.responseText);
        this.sharedService.openAlert(json.error, this.enums.DANGER_ALERT);
      }
    }
  }

}
