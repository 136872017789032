import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { AlertDialogComponent } from 'src/app/Shared/alert-dialog/alert-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Commission } from 'src/model/commission.model';
import { User } from 'src/model/user.model';
import { AddCommissionComponent } from '../add-commission/add-commission.component';
import { Loan } from 'src/model/loan.model';

@Component({
  selector: 'app-assign-commission',
  templateUrl: './assign-commission.component.html',
  styleUrls: ['./assign-commission.component.css']
})
export class AssignCommissionComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  displayedColumns: string[];
  dataSource = new MatTableDataSource;

  loggedInRole: number;
  loggedInUsername: string;

  usernameList: User[] = new Array;
  @Input() commissionList: Commission[];

  @Input() isModal: boolean = false;
  constructor(private router: Router, private activeModal: NgbActiveModal, private modalService: NgbModal,
    private restapi: RestApiService, private constructApi: ConstructAPI, public enums: Enums, private cdr: ChangeDetectorRef, private sharedService: SharedService) { }

  ngOnInit(): void {

    this.loggedInRole = Number(sessionStorage.getItem('roleId'));
    this.loggedInUsername = sessionStorage.getItem('username');


    this.getAllUser();

    // this.displayedColumns = ['CustomerName', 'Principal', 'Amount', 'Assignee', 'Action'];
    this.displayedColumns = ['CustomerName', 'Principal', 'CompanyCode', 'Action'];
    if (this.commissionList != undefined) {
      this.dataSource = new MatTableDataSource<Commission>(this.commissionList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  getAllUser() {

    var xhttp = this.restapi.getRequest(this.constructApi.getAllUsers())
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // Typical action to be performed when the document is ready:

        var jsonArray = JSON.parse((xhttp.responseText));
        // console.log(jsonArray);

        jsonArray.forEach(json => {

          let user = json.username
          this.usernameList.push(user);
        }
        );
      }
    };
  }


  addCommission(commission: Commission, index: number) {
    var modalRef = this.sharedService.openModal(AddCommissionComponent, "regularModal");
    var loan = new Loan(commission.LoanId, undefined, commission.Principal, undefined, undefined, undefined, undefined, commission.CompanyId, commission.CompanyCode, commission.Scheme, undefined, undefined, commission.CustomerName, undefined,
      undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    modalRef.componentInstance.loan = loan;

    modalRef.componentInstance.passEntry.subscribe((entry) => {
      this.commissionList.splice(index, 1);

      this.dataSource = new MatTableDataSource<Commission>(this.commissionList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.cdr.detectChanges();
    });
  }


  confirmBeforeAssignAll() {

    var confirmation = false;
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "You are going to clear all the commissions, are you sure about that?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      confirmation = entry;
      if (confirmation) {

        // confirm to proceed
        this.assignAllCommissions();
      }
    });

  }
  assignAllCommissions() {
    var editIds: Number[] = new Array;
    for (let i = 0; i < this.commissionList.length; i++) {
      editIds.push(Number(this.commissionList[i].LoanId))
    }

    this.batchAssignAmountAndAssignee(this.loggedInUsername, editIds, 0)

  }

  batchAssignAmountAndAssignee(assignee: string, loanIds: Number[], amount: number) {

    var data = {
      "amount": amount,
      "loanIds": loanIds,
      "assignee": assignee,
      "dateCreated": new Date()
    }

    var xhr = this.restapi.postRequest(this.constructApi.getAssignCommission(), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        // var jsonArray = JSON.parse((xhr.responseText));

        this.sharedService.openAlert("Successfully batch assign commission!", this.enums.SUCCESS_ALERT);
        window.location.reload();
      }
    };
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  assignCommission(commission: Commission) {


    // alert(commission.Rate + "|" + commission.Assignee)
    if (commission.Assignee == undefined || commission.Amount == undefined)
      this.sharedService.openAlert("Please key in commission amount and assignee!", this.enums.DANGER_ALERT);
    else
      this.assignRateAndAssignee(commission, true);
  }

  assignRateAndAssignee(commission: Commission, showAlert: boolean) {

    var data = {
      "amount": Number(commission.Amount),
      "loanId": commission.LoanId,
      "assignee": commission.Assignee,
      "dateCreated": new Date()
    }

    var xhr = this.restapi.postRequest(this.constructApi.getAssignCommission(), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        // var jsonArray = JSON.parse((xhr.responseText));

        if (showAlert == true)
          this.sharedService.openAlert("Successfully assign commission!", this.enums.SUCCESS_ALERT);
        commission.Assigned = true;
      }
    };
  }

  goToCustomer(selectedID: number) {
    // console.log("selcted:" + selectedID);
    // this.router.navigateByUrl('/manageCustomer', { state: { id: selectedID } });
    this.router.navigate(['/manageCustomer', selectedID]);
  }

  reset() {
    this.activeModal.close();
  }

}
