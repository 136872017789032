<table style="border:none;">
    <tr>
        <h4 class="fw-bold" style="color: green;">Simpan: RM {{totalReserveAmount}}
        </h4>
    </tr>

    <tr>
        <div class="row">
            <div class="col  pe-0">
                <h5 class="fw-bold">Duit Lebih: RM</h5>
            </div>
            <div class="col p-0 m-0">
                <input type="number" class="withdrawAmount p-0 m-0" required name="withdrawAmount"
                    [(ngModel)]="withdrawAmount" min=0 placeholder="Duit Lebih Amount"
                    (keypress)="sharedService.isNumberKey($event)">
            </div>
        </div>


    </tr>
    <tr style="border-top: 2px solid black;border-bottom:2px solid black">
        <h5 class="fw-bold" style="color: blue;">Simpan left:RM
            {{calculateReserveLeft()||totalReserveAmount}}
        </h5>
    </tr>
</table>

<ng-container *ngIf="checkSimpanLeftEnough()==true;else notEnough">
    <!--Account Number-->
    <div class="row p-3 d-flex justify-content-center align-items-center">

        <div class="col-12">
            <p>To Account</p>
        </div>
        <div class="col-12">
            <select class="browser-default form-select" name="selectedAccount" [(ngModel)]="selectedAccountId">
                <optgroup label="Company Account">
                    <option *ngFor="let account of companyBankAccountList" [value]="account.ID">
                        {{account.Name}}
                    </option>
                </optgroup>

                <optgroup label="Cash Account">
                    <option *ngFor="let account of cashAccountList" [value]="account.ID">
                        {{account.Name}}
                    </option>
                </optgroup>
            </select>
        </div>

    </div>

    <!--Payment Date-->
    <div class=" d-flex justify-content-start align-items-left">
        <mat-form-field class="full-width-formField bar ">
            <input matInput class="textInputLong initialDatePicker" [matDatepicker]="myDatepicker" name="paymentDate"
                [(ngModel)]="paymentDate" placeholder="Payment Date">
            <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #myDatepicker name="date"></mat-datepicker>
        </mat-form-field>
    </div>

    <p class="text-center">Are you sure you want to make payment by using SIMPAN?</p>
    <div class="row">
        <div class="col d-flex justify-content-center align-items-center">
            <button class="btn btn-success" (click)="submit()" [disabled]="disabledSubmit">
                Yes
            </button>
            <button class="btn btn-danger ms-2">
                No
            </button>
        </div>
    </div>
</ng-container>
<ng-template #notEnough>
    <p class="text-danger fw-bold bg-warning"> Cannot make payment, not enough simpan!</p>
</ng-template>