<div class="container-fluid " style="padding: 0;" *ngIf="viewCustomer && selectedProfile">
    <div class="row g-0">
        <div class="col-12 px-1 py-0 text-center"
            [ngClass]="{'verified':viewCustomer.Verified==true,'unverify':viewCustomer.Verified==false}"
            *ngIf="sharedService.isBossAccount() || sharedService.isAdmin()">
            <span style="color:white" *ngIf="viewCustomer.Verified==true"><strong>Verified by
                    {{viewCustomer.VerifiedBy}} on {{viewCustomer.VerifiedOn | date:"dd-MM-yyyy"}}</strong> </span>
            <span style="color:white" *ngIf="viewCustomer.Verified==false"><strong>Not verified, verify now? </strong>
            </span>
            <button class="btn btn-success ms-1" *ngIf="viewCustomer.Verified==false"
                (click)="confirmBeforeVerifyCustomer(true,viewCustomer.ID)" #tooltip="matTooltip"
                matTooltip="Verify Customer" matTooltipPosition="above" matTooltipHideDelay="100"
                matTooltipClass="tooltip-black">
                <i class="fas fa-user-check"></i>
            </button>

            <button class="btn btn-dark ms-1" *ngIf="viewCustomer.Verified==true"
                (click)="confirmBeforeVerifyCustomer(false,viewCustomer.ID)" #tooltip="matTooltip"
                matTooltip="Unverify Customer" matTooltipPosition="above" matTooltipHideDelay="100"
                matTooltipClass="tooltip-black">
                <i class="fas fa-user-times"></i>
            </button>
        </div>
        <div class=" col py-0" id="custRecordPanel"
            [ngClass]="getStatusPanelColor(sharedService.processStatusCode(selectedProfile.Status))">
            <h3 class="text-start" style="font-family: Aldrich, sans-serif;color: rgb(255,255,255);margin: 1%;">
                <strong>Customer Record</strong>
            </h3>
            <div class="card" style="margin: 2%;">
                <div class="card-body">
                    <div class="row d-flex d-lg-flex justify-content-start align-items-right">
                        <div class="col-6">
                            <h2 class="badge text-bg-primary" [hidden]="selectedProfile.Status==null"
                                [style.background-color]="getStatusColor(sharedService.processStatusCode(selectedProfile.Status))"
                                style="font-size:1.2rem">
                                {{getCustomerStatus(sharedService.processStatusCode(selectedProfile.Status))}}
                            </h2>
                        </div>
                        <div class="col-6">
                            <div class="row d-flex justify-content-end">
                                <div class="col-3 d-flex justify-content-center"
                                    *ngIf="sharedService.isBossAccount()==true">
                                    <button class="btn btn-info" (click)="openMergeProfileModal()">
                                        <i class="far fa-clone"></i>
                                        <small class="d-none d-lg-flex" style="font-size: 16px;">Merge/Move
                                            Profile</small></button>
                                </div>

                                <div class="col-3 d-flex justify-content-center"
                                    *ngIf="sharedService.isBossAccount()==true">
                                    <button class="btn btn-primary" (click)="openShareProfileModal()"
                                        *ngIf="selectedProfile.SharedProfileId==undefined || selectedProfile.ID== selectedProfile.SharedProfileId">
                                        <i class="fas fa-handshake"></i> <small class="d-none d-lg-flex"
                                            style="font-size: 16px;">Share
                                            Profile</small></button>
                                    <button class="btn btn-danger" (click)="confirmBeforeUnshare()"
                                        *ngIf="selectedProfile.SharedProfileId!=undefined && selectedProfile.ID!= selectedProfile.SharedProfileId"><i
                                            class="fas fa-heart-broken"></i>
                                        <small class="d-none d-lg-flex" style="font-size: 16px;"> Unshare
                                            Profile </small></button>

                                    <button class="btn btn-warning" (click)="confirmBeforeMarkAsMain()"
                                        *ngIf="selectedProfile.SharedProfileId!=undefined && selectedProfile.ID!= selectedProfile.SharedProfileId">
                                        <i class="fas fa-highlighter"></i>
                                        <small class="d-none d-lg-flex" style="font-size: 16px;"> Mark as Main
                                        </small></button>
                                </div>
                                <div class="col-3 d-flex justify-content-center ">
                                    <button class="btn btn-dark" (click)="openCustomerTransactionModal()">
                                        <i class="fas fa-file-invoice-dollar"></i>
                                        <small class="d-none d-lg-flex" style="font-size: 16px;">View
                                            Transactions</small></button>
                                </div>

                                <div class="col-3 d-flex justify-content-center">
                                    <button class="btn btn-warning " type="button" (click)="openEditProfileModal()"
                                        *ngIf="sharedService.isAuditor()==false && !sharedService.isFinance()">
                                        <i class="far fa-edit"></i>
                                        <small class="d-none d-lg-flex" style="font-size: 16px;">Edit Customer</small>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>

                    <h4 class="card-title">{{viewCustomer.Name | uppercase}}</h4>
                    <h6 class="text-secondary card-subtitle mb-2">{{sharedService.processICNum(viewCustomer.ICNumber)}}
                        <i class="fas fa-history me-1" style="cursor:pointer;"
                            *ngIf="sharedService.isBossAccount() || sharedService.isFinance() || sharedService.isAdmin()"
                            (click)="viewCustomerICHistory(viewCustomer)" placement="top"
                            ngbTooltip="Check IC History"></i>

                        <ng-template #tooltipContent>
                            <p>EPF Amount: RM {{customerEpfDetails.Amount ||0 |number:'1.2-2'}}</p>
                            <p>Has IAccount: {{customerEpfDetails.HasIAccount }}</p>

                        </ng-template>

                        <span class="badge text-bg-primary" style="background-color: lightseagreen;"
                            *ngIf="viewCustomer.EPFDate!=undefined">
                            EPF Date: {{viewCustomer.EPFDate |date:'yyyy-MM-dd' }}
                            <i class="far fa-question-circle ms-2" placement="top" [ngbTooltip]="tooltipContent"
                                style="cursor:pointer;" *ngIf="customerEpfDetails!=undefined"></i>

                            <i class="fas fa-history ms-1" style="cursor:pointer;"
                                *ngIf="sharedService.isBossAccount() || sharedService.isFinance() || sharedService.isAdmin()"
                                (click)="viewCustomerEPFHistory(viewCustomer)" placement="top"
                                ngbTooltip="Check EPF History"></i>
                        </span>
                    </h6>
                    <h6 class="card-subtitle mb-2">Customer Code: {{selectedProfile.CustomerCode | uppercase}}
                        <strong>({{viewCustomer.CompanyCode}})</strong>
                    </h6>
                    <p class="card-text mb-1" style="font-size: 14px;"
                        [style.color]="getStatusColor(sharedService.processStatusCode(selectedProfile.Status))">
                        <strong> REGISTERED ON {{selectedProfile.DateCreated| date:'yyyy-MM-dd' }}</strong>
                    </p>
                    <div class="row" *ngIf="viewCustomer.Profiles!=undefined">
                        <div class="col-5">
                            <select class="browser-default form-select" [(ngModel)]="selectedProfileID"
                                (ngModelChange)="checkSelectedProfile()"
                                *ngIf="viewCustomer.Profiles.length > 1;else singleProfile" style="border:2px solid "
                                [style.border-color]="getStatusColor(sharedService.processStatusCode(selectedProfile.Status))">

                                <ng-container *ngFor="let profile of viewCustomer.Profiles;">
                                    <option [value]="profile.ID">
                                        {{profile.Occupation}} -{{profile.ID}}
                                        <i class="fas fa-handshake"
                                            [ngClass]="{'text-primary':profile.SharedProfileId== profile.ID }"
                                            *ngIf="profile.SharedProfileId!=undefined"></i>
                                    </option>
                                </ng-container>
                            </select>
                            <ng-template #singleProfile>
                                <div class="p-1" style="border: 2px solid rgb(250, 247, 247);">
                                    <span class="h6"> {{selectedProfile.Occupation}}-{{selectedProfile.ID}}
                                        <i class="fas fa-handshake"
                                            [ngClass]="{'text-primary':selectedProfile.SharedProfileId== selectedProfile.ID }"
                                            *ngIf="selectedProfile.SharedProfileId!=undefined"></i></span>
                                </div>

                            </ng-template>

                        </div>
                        <div class="col-3"
                            *ngIf="sharedService.isAdmin()==false && sharedService.isBossAccount()==false && !sharedService.isFinance() && sharedService.isAuditor()==false">
                            <button class="btn btn-info" (click)="openAddProfileModal()"><i
                                    class="fas fa-user-plus"></i> Add Profile</button>
                        </div>
                        <div class="col-3" *ngIf="sharedService.isBossAccount()==true">
                            <button class="btn btn-primary" (click)="openPullCustomerModal()"><i
                                    class="fas fa-people-arrows"></i> Add
                                Pull Customer Record</button>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-4 d-flex justify-content-start align-items-start">
                            <div class="card simpan-card" (click)="openReserveHistory()"
                                *ngIf=" selectedProfile.Reserve!=undefined">
                                <div class="card-body m-0 py-0">
                                    <h6 style="font-size: 1rem;color: white;">
                                        <strong>SIMPAN: RM {{selectedProfile.Reserve ||0}}</strong>
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 d-flex justify-content-start align-items-start"
                            *ngIf="selectedProfile.BadDebt!=undefined">
                            <div class="card badDebt-card" (click)="openBadDebtHistory()">
                                <div class="card-body m-0 py-0">
                                    <h6 style="font-size: 1rem;color: white;">
                                        <strong>BAD DEBT RESERVE: RM {{selectedProfile.BadDebt ||0}}</strong>
                                    </h6>
                                </div>
                            </div>
                        </div>

                        <div class="col-4 d-flex justify-content-start align-items-start"
                            *ngIf="loanRequestList.length>0 && (sharedService.isBranchAccountHandler()==true || sharedService.isStaff()==true)">
                            <div class="card loanRequest-card" (click)="openLoanRequestList()">
                                <div class="card-body m-0 py-0">
                                    <h6 style="font-size: 1rem;color: white;">
                                        <strong>LOAN REQUEST :{{this.loanRequestList.length ||0}}</strong>
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-4 d-flex justify-content-start align-items-start"
                                *ngIf="isStaff()==false && commissionList.length>0">
                                <div class="card commission-card"
                                    (click)="openCustomerCommissionsModal(commissionList,true)">
                                    <div class="card-body m-0 py-0">
                                        <h6 style="font-size: 1rem;color: white;">
                                            <strong>UNASSIGNED COMMISSION: {{commissionList.length ||0}}</strong>
                                        </h6>
                                    </div>
                                </div>
                            </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class=" row" style="margin: 0;">
        <div class="col" style="padding: 0;">
            <div style="margin: 2%;">
                <ul class="nav nav-tabs">
                    <li class="nav-item" *ngIf="sharedService.isAdmin()==false">
                        <a class="nav-link" role="tab" data-bs-toggle="tab" href="#tabDetails">
                            <i class="fa fa-user d-flex justify-content-center" style="font-size: 18px;"></i>
                            <span class="d-none d-lg-block">Details</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link active" role="tab" data-bs-toggle="tab" href="#tabLoans">
                            <i class="fas fa-donate d-flex justify-content-center"></i>
                            <span class="d-none d-lg-block">Loans</span>
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="sharedService.isStaff()==false">
                        <a class="nav-link" role="tab" data-bs-toggle="tab" href="#tabContacts">
                            <i class="far fa-address-card d-flex justify-content-center"></i>
                            <span class="d-none d-lg-block">Contacts</span>
                        </a>
                    </li>
                    <li class="nav-item"
                        *ngIf="sharedService.isBranchAccountHandler()==true || sharedService.isBossAccount()==true">
                        <a class=" nav-link" role="tab" data-bs-toggle="tab" href="#tabTasks">
                            <i class="fa fa-calendar d-flex d-flex justify-content-center"></i>
                            <span class="d-none d-lg-block">Tasks</span>
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="sharedService.isStaff()==false">
                        <a class="nav-link" role="tab" data-bs-toggle="tab" href="#tabImages">
                            <i
                                class="fa fa-image d-flex d-xl-flex justify-content-center justify-content-xl-center"></i>
                            <span class="d-none d-lg-block">Images</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" role="tab" data-bs-toggle="tab" href="#tabFormJ">
                            <i *ngIf="sharedService.checkFormJExpired(viewCustomer.LastFormJDate)==true" class="text-danger fas fa-exclamation-circle d-flex d-xl-flex justify-content-center
                                justify-content-xl-center"> </i>
                            <i *ngIf="sharedService.checkFormJExpired(viewCustomer.LastFormJDate)==false" class="fas fa-file-signature d-flex d-xl-flex justify-content-center
                                        justify-content-xl-center"></i>
                            <span class="d-none d-lg-block">Form J</span>
                        </a>
                    </li>
                    <li class="nav-item"
                        *ngIf="sharedService.isAdmin() ||sharedService.isBossAccount() || sharedService.isFinance()">
                        <a class="nav-link" role="tab" data-bs-toggle="tab" href="#tabCustomerRequestHistory">
                            <i class="far fa-paper-plane d-flex d-flex justify-content-center"></i>
                            <span class="d-none d-lg-block">Requests</span>
                        </a>
                    </li>
                    <li class="nav-item"
                        *ngIf="sharedService.isAdmin() ||sharedService.isBossAccount() || sharedService.isFinance() || sharedService.isAuditor()">
                        <a class="nav-link" role="tab" data-bs-toggle="tab" href="#tabCustomerRemark">
                            <i class="far fa-comments d-flex d-flex justify-content-center"></i>
                            <span class="d-none d-lg-block">Remarks</span>
                        </a>
                    </li>
                </ul>
                <div class="tab-content">
                    <div class="tab-pane" role="tabpanel" id="tabDetails" *ngIf="sharedService.isAdmin()==false">
                        <app-customer-details [viewCustomer]="viewCustomer" [selectedProfile]="selectedProfile"
                            [epfDetails]="customerEpfDetails">
                        </app-customer-details>
                    </div>
                    <div class="tab-pane active" role="tabpanel" id="tabLoans">
                        <app-customer-loan-card [viewCustomer]="viewCustomer" [selectedProfile]="selectedProfile"
                            [epfDetails]="customerEpfDetails">
                        </app-customer-loan-card>
                    </div>
                    <div class="tab-pane" role="tabpanel" id="tabContacts" *ngIf="sharedService.isStaff()==false">
                        <app-customer-contact-list [viewCustomer]="viewCustomer" [selectedProfile]="selectedProfile"
                            [epfDetails]="customerEpfDetails"></app-customer-contact-list>
                    </div>
                    <div class="tab-pane" role="tabpanel" id="tabTasks"
                        *ngIf="(sharedService.isBranchAccountHandler()==true || sharedService.isBossAccount()==true || sharedService.isFinance())">
                        <app-customer-task-list [viewCustomer]="viewCustomer" [selectedProfileId]="selectedProfileID"
                            [allowUpdateTask]="allowUpdateTask">
                        </app-customer-task-list>
                    </div>
                    <div class="tab-pane" role="tabpanel" id="tabImages" *ngIf="sharedService.isStaff()==false">
                        <app-customer-album [viewCustomer]="viewCustomer"></app-customer-album>
                    </div>
                    <div class="tab-pane" role="tabpanel" id="tabFormJ">
                        <app-form-j-history [viewCustomer]="viewCustomer"></app-form-j-history>
                    </div>

                    <div class="tab-pane" role="tabpanel" id="tabCustomerRequestHistory"
                        *ngIf="sharedService.isAdmin()==true || sharedService.isBossAccount() ==true || sharedService.isFinance()">
                        <ng-container *ngIf="loanRequestList.length>0|| loanRequestList!=undefined">
                            <ng-container *ngFor="let request of loanRequestList">
                                <app-customer-loan-request-history [request]="request">
                                </app-customer-loan-request-history>
                            </ng-container>
                        </ng-container>
                        <div *ngIf="loanRequestList.length==0|| loanRequestList==undefined">
                            <p>This customer does not have any loan request history</p>
                        </div>
                    </div>
                    <div class="tab-pane" role="tabpanel" id="tabCustomerRemark"
                        *ngIf="sharedService.isAdmin()==true || sharedService.isBossAccount() ==true || sharedService.isFinance() || sharedService.isAuditor()">
                        <app-customer-admin-remark [viewCustomerId]="viewCustomer.ID"></app-customer-admin-remark>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>