import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteReasonRemarkComponent } from 'src/app/Account/delete-reason-remark/delete-reason-remark.component';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { ReceiptDetailsComponent } from 'src/app/Sets/receipt-details/receipt-details.component';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Receipt } from 'src/model/loanReceipt.model';
import { Transaction } from 'src/model/transaction.model';
import { TransactionType } from 'src/model/transactionType.model';

interface TypeTotal {
  type: number,
  total: number
}

@Component({
  selector: 'app-customer-transactions',
  templateUrl: './customer-transactions.component.html',
  styleUrls: ['./customer-transactions.component.css']
})
export class CustomerTransactionsComponent implements OnInit {



  @Input() startDate: Date;
  @Input() endDate: Date;

  @Input() companyId: number;

  receiptList: Receipt[] = new Array;
  transactionTypeList: TransactionType[] = new Array;

  typeTotalList: TypeTotal[] = new Array;

  @Input() profileId: number;

  totalIn: number = 0;
  totalOut: number = 0;
  totalAmount: number = 0;

  isDirty = false;

  constructor(private activeModal: NgbActiveModal, private restApi: RestApiService, private constructApi: ConstructAPI, public enums: Enums, public sharedService: SharedService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {

    //always show today's transaction

    if (this.startDate == undefined && this.endDate == undefined) {
      this.startDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
      this.endDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    }

    else {
      this.startDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate());
      this.endDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate());
    }

    this.getAllTransactionsOfLoan();
  }

  closeModal() {
    this.activeModal.close();
  }

  checkAmountInOut(amount: number) {
    if (amount < 0)
      return 'negative';
    else
      return 'positive';
  }


  confirmDeleteTranscation(transaction: Transaction) {
    var timeDiff = new Date().getTime() - new Date(transaction.Date).getTime();
    var dateDiff = timeDiff / (1000 * 3600 * 24);


    if (dateDiff < 90 || this.sharedService.isBossAccount() == true) {
      const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
      modalRef.componentInstance.message = "Deleting loan transaction might cause deleting receipt, loan and other related entity. Are you sure to do so?";
      //get confirmation
      modalRef.componentInstance.passEntry.subscribe((entry) => {
        var confirmation = entry;
        if (confirmation == true) {


          const modalRef = this.sharedService.openModal(DeleteReasonRemarkComponent);
          modalRef.componentInstance.transaction = transaction;
          modalRef.componentInstance.groupId = transaction.GroupId

          modalRef.componentInstance.companyId = this.companyId;
          modalRef.componentInstance.passEntry.subscribe((entry) => {
            var remark = entry;

            //append added account to account list

            if (transaction.GroupId != undefined)
              this.deleteGroupTransaction(remark, transaction.GroupId, this.companyId);
            else
              this.deleteTransaction(remark, transaction.ID, transaction.Type);
          });

        }

      });
    } else
      this.sharedService.openAlert("This transaction is too old, please contact your admin to delete", this.enums.DANGER_ALERT);


  }




  deleteGroupTransaction(remark: string, groupId: number, companyId: number) {
    var data =
    {
      "reason": remark
    };


    var xhr = this.restApi.deleteRequest(this.constructApi.getDeleteGroupTransaction(companyId, groupId), data);


    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        if (xhr.status == 200) {
          window.location.reload();
        }
        else {
          // this.sharedService.openAlert("Opps, some errors occured. Please try again later");
          this.sharedService.openErrorMessage(xhr);
        }


      }

    }

  }
  deleteTransaction(remark: string, transactionID: number, transactionType: number) {
    var data =
    {
      "reason": remark
    };


    var xhr;

    switch (transactionType) {
      case this.enums.RESERVE:
        xhr = this.restApi.deleteRequest(this.constructApi.getDeleteReserveTransaction(transactionID), data);
        break;
      case this.enums.BAD_DEBT:
        xhr = this.restApi.deleteRequest(this.constructApi.getDeleteBadDebtTransaction(transactionID), data);
        break;
      default:
        xhr = this.restApi.deleteRequest(this.constructApi.getDeleteLoanTransaction(transactionID), data);
        break;
    }

    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        if (xhr.status == 200) {
          this.removeTransctionFromList(transactionID);
          this.isDirty = true;
        }
        else {
          // this.sharedService.openAlert("Opps, some errors occured. Please try again later");
          this.sharedService.openErrorMessage(xhr);
        }


      }

    }

  }

  removeTransctionFromList(transactionId: number) {

    for (let i = 0; i < this.receiptList.length; i++) {
      for (let j = 0; j < this.receiptList[i].Transactions.length; j++) {
        if (Number(this.receiptList[i].Transactions[j].ID) == transactionId) {
          this.receiptList[i].Transactions.splice(j, 1);
          this.cdr.detectChanges();

          if (this.receiptList.length == 1 && this.receiptList[0].Transactions.length == 0)
            window.location.reload();
        }
      }
    }
    return -1
  }

  getProfileReserveReceipts() {
    var startDateStr = (this.startDate == undefined) ? undefined : this.startDate.toISOString();

    if (this.endDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var addADayDate = new Date(Number(this.endDate) + (3600 * 1000 * 24) - 1);
    var endDateStr = (this.endDate == undefined) ? undefined : addADayDate.toISOString();


    var xhttp = this.restApi.getRequest(this.constructApi.getProfileReserveReceipts(this.profileId, startDateStr, endDateStr));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {

          var transactionArray = json.transactions;
          transactionArray.forEach(transaction => {
            var record = new Transaction(transaction.id, transaction.remark, transaction.amount,
              transaction.closingBalance, transaction.accountId, transaction.date, transaction.type, transaction.handleBy,
               undefined, undefined, undefined, undefined, undefined, transaction.subtype, transaction.groupId,transaction.setId)


            //GROUP TRANSACTIONS WITH RECEIPT
            var index = this.receiptList.findIndex(receipt => receipt.ID === Number(json.id));
            if (index == -1) {
              var reserveTransactionList: Transaction[] = new Array;
              reserveTransactionList.push(record);
              var receiptObj = new Receipt(json.id, "SIMPAN -" + json.number, reserveTransactionList);
              this.receiptList.push(receiptObj);
            }
            else
              this.receiptList[index].Transactions.push(record);


            // GROUP TRANSACTIONS BY TYPE
            var typeIndex = this.typeTotalList.findIndex(totalList => Number(totalList.type) === Number(transaction.type));

            if (typeIndex == -1) {
              var typeTotal = { type: transaction.type, total: Number(transaction.amount) };
              this.typeTotalList.push(typeTotal);

            }
            else {
              this.typeTotalList[typeIndex].total += Number(transaction.amount);
            }
          });
        }
        );

        this.getProfileSimpanBDReceipts();
      }
    };

  }


  getProfileSimpanBDReceipts() {
    var startDateStr = (this.startDate == undefined) ? undefined : this.startDate.toISOString();

    if (this.endDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var addADayDate = new Date(Number(this.endDate) + (3600 * 1000 * 24) - 1);
    var endDateStr = (this.endDate == undefined) ? undefined : addADayDate.toISOString();


    var xhttp = this.restApi.getRequest(this.constructApi.getProfileBDReceipts(this.profileId, startDateStr, endDateStr));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {

          var transactionArray = json.transactions;
          transactionArray.forEach(transaction => {
            var record = new Transaction(transaction.id, transaction.remark, transaction.amount,
              transaction.closingBalance, transaction.accountId, transaction.date, transaction.type, transaction.handleBy, undefined, undefined, undefined, undefined, undefined, transaction.subtype, transaction.groupId)

            //GROUP TRANSACTION BY RECEIPT
            var index = this.receiptList.findIndex(receipt => receipt.ID === Number(json.id));
            if (index == -1) {
              var reserveTransactionList: Transaction[] = new Array;
              reserveTransactionList.push(record);
              var receiptObj = new Receipt(json.id, "SIMPAN BD -" + json.number, reserveTransactionList);
              this.receiptList.push(receiptObj);
            }
            else
              this.receiptList[index].Transactions.push(record);

            // GROUP TRANSACTIONS BY TYPE
            var typeIndex = this.typeTotalList.findIndex(totalList => Number(totalList.type) === Number(transaction.type));

            if (typeIndex == -1) {
              var typeTotal = { type: transaction.type, total: Number(transaction.amount) };
              this.typeTotalList.push(typeTotal);

            }
            else {
              this.typeTotalList[typeIndex].total += Number(transaction.amount);
            }


          });
        }
        );

        this.calculateTotal();

        //SORT TYPE TOTAL SUMMARY LIST
        this.typeTotalList.sort((a, b) => (a.type < b.type) ? -1 : 1);
      }
    };

  }

  getAllTransactionsOfLoan() {
    this.receiptList.splice(0, this.receiptList.length);
    this.typeTotalList.splice(0, this.typeTotalList.length);

    var startDateStr = (this.startDate == undefined) ? undefined : this.startDate.toISOString();

    if (this.endDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var addADayDate = new Date(Number(this.endDate) + (3600 * 1000 * 24) - 1);
    var endDateStr = (this.endDate == undefined) ? undefined : addADayDate.toISOString();


    var xhttp = this.restApi.getRequest(this.constructApi.getProfileAllLoansTransactions(this.profileId, startDateStr, endDateStr));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var transactionArray = JSON.parse((xhttp.responseText));


        
        transactionArray.forEach(json => {
          var record = new Transaction(json.id, json.remark, json.amount,
            json.closingBalance, json.accountId, json.date, json.type, json.handleBy, undefined, undefined, undefined, undefined, undefined, json.subtype, json.groupId,json.setId)

         
          //GROUP TRANSACTIONS BY RECEIPT
          var index = this.receiptList.findIndex(receipt => receipt.ID === Number(json.receiptId));
          if (index == -1) {
            var loanTransactionList: Transaction[] = new Array;
            loanTransactionList.push(record);
            var receiptObj = new Receipt(json.receiptId, json.receiptNumber, loanTransactionList, json.scheme, json.interestRate);
            this.receiptList.push(receiptObj);
          }
          else
            this.receiptList[index].Transactions.push(record);


          // GROUP TRANSACTIONS BY TYPE
          var typeIndex = this.typeTotalList.findIndex(totalList => Number(totalList.type) === Number(json.type));

          if (typeIndex == -1) {
            var typeTotal = { type: json.type, total: Number(json.amount) };
            this.typeTotalList.push(typeTotal);

          }
          else {
            this.typeTotalList[typeIndex].total += Number(json.amount);
          }
        }
        );


        this.getProfileReserveReceipts()
      }
    };

  }

  calculateTotal() {
    this.totalAmount = 0;
    this.totalIn = 0;
    this.totalOut = 0;

    for (let i = 0; i < this.receiptList.length; i++) {
      for (let j = 0; j < this.receiptList[i].Transactions.length; j++) {
        if (this.receiptList[i].Transactions[j].Amount < 0)
          this.totalOut += this.receiptList[i].Transactions[j].Amount;
        else
          this.totalIn += this.receiptList[i].Transactions[j].Amount;

        this.totalAmount += this.receiptList[i].Transactions[j].Amount;
      }
    }
  }


  openSetDialog(setId: number) {
    var modalRef = this.sharedService.openModal(ReceiptDetailsComponent);
    modalRef.componentInstance.receiptId = setId;
    modalRef.componentInstance.isModal = true;

  }
}
