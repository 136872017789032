<div class="doNotPrint">

    <div class="group mt-3">
        <input type="number" required class="textInputLong" name="amount" [(ngModel)]="amount" min=0
            (keypress)="sharedService.isNumberKey($event)">
        <span class="highlight"></span>
        <span class="bar barLong"></span>
        <label>Loan Amount</label>
    </div>

    <div class="col-12">
        <p>Loan Date</p>
    </div>
    <div class="col-12">
        <mat-form-field class="full-width-formField p-0">
            <input matInput readonly [matDatepicker]="loanDatePicker" name="date" [(ngModel)]="date" required>
            <mat-datepicker-toggle matSuffix [for]="loanDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #loanDatePicker name="date"></mat-datepicker>
        </mat-form-field>
        <button class="btn btn-danger ms-1" (click)="date=undefined"><i class="fas fa-times"></i></button>
    </div>
    <div class="col-12">
        <p>Form J Date</p>
    </div>
    <div class="col-12">
        <mat-form-field class="full-width-formField p-0">
            <input matInput readonly [matDatepicker]="formJDatePicker" name="formJDate" [(ngModel)]="formJDate"
                required>
            <mat-datepicker-toggle matSuffix [for]="formJDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #formJDatePicker name="formJDate"></mat-datepicker>
        </mat-form-field>
        <button class="btn btn-danger ms-1" (click)="formJDate=undefined"><i class="fas fa-times"></i></button>
    </div>
    <hr />
</div>

<div class="text-justify guarantorForm " style="font-family: 'Calibri, sans-serif';line-height:150%;font-size: 12pt;"
    id="htmlData" #htmlData *ngIf="selectedCustomer!=undefined && selectedCompany!=undefined">

    <h6 class="text-center"><strong>SURAT JAMINAN PERIBADI</strong></h6>
    <br />
    <div class="row">
        <div class="col-7">
            {{selectedCompany.Name}}
        </div>
        <div class="col-7">
            {{selectedCompany.Address}}
        </div>
    </div>
    <br />

    <p>Tarikh : <strong><span *ngIf="date!=undefined">{{date | date:'dd/MM/yyyy'}}</span>
        </strong></p>

    <p>
        JAMINAN ini adalah antara “PEMBERI PINJAMAN” {{selectedCompany.Name}} dengan “PENJAMIN” atas Perjanjian
        Pinjaman Wang (Form J) bertarikh
        <strong><span *ngIf="formJDate!=undefined"><u>{{formJDate | date:'dd/MM/yyyy'}}</u></span>
            <span *ngIf="formJDate==undefined">_______________________</span>
        </strong>
        yang
        berjumlah <strong> RM <span *ngIf="amount!=0 && amount!=undefined"><u>{{amount | number:'1.2-2'}}</u></span>
            <span *ngIf="amount==undefined">_______________________</span>
        </strong>
        antara Pemberi Pinjaman dengan
        “PEMINJAM”:
    </p>
    <br />
    <table class="fw-bold" style="border: none;">
        <tr>
            <td>NAMA</td>
            <td>:</td>
            <td class="p-2">{{selectedCustomer.Name}}</td>
        </tr>
        <tr>
            <td>NO. I.C.</td>
            <td>:</td>
            <td class="p-2">{{sharedService.processICNum(selectedCustomer.ICNumber)}}</td>
        </tr>
    </table>
    <br />
    <p>
        Sekiranya Peminjam gagal untuk membuat pembayaran penuh semasa umur {{epfSettlementAge}} tahun ataupun mempunyai
        hutang tertunggak
        selepas umur {{epfSettlementAge}} tahun, Penjamin akan mengambil tanggungjawab untuk melangsaikan hutang
        tertunggak sebagaimana
        yang patut dibayar oleh Peminjam.
    </p>
    <p><strong>Nota: Jumlah pembayaran balik adalah muktamad dan sebarang diskaun atas pembayaran balik pinjaman adalah
            tidak dibenarkan.</strong></p>

    <p>
        Pemberi Pinjaman juga diberikan kuasa untuk <strong>menyenarai hitamkan nama Penjamin dalam Sistem CTOS</strong>
        sekiranya
        Peminjam gagal untuk membuat pembayaran penuh ataupun mempunyai hutang tertunggak semasa umur
        {{epfSettlementAge}} tahun.
    </p>
    <p>
        Saya memberi jaminan bahawa Peminjam akan membuat pembayaran pinjaman dalam masa yang sepatutnya dan seperti
        yang dinyatakan di atas Perjanjian Pinjaman Wang.
        (Form J)

    </p>
    <br />
    <table style="border: none;" *ngIf="contact!=undefined">
        <tr>
            <td>Tandatangan PENJAMIN</td>
            <td>:</td>
            <td></td>
        </tr>
        <tr>
            <td>Nama</td>
            <td>:</td>
            <td class="p-2">{{contact.Name}}</td>
        </tr>
        <tr>
            <td>No. K.P.</td>
            <td>:</td>
            <td *ngIf="contact.ICNumber!=undefined" class="p-2">{{sharedService.processICNum(contact.ICNumber)}}</td>
        </tr>
        <tr>
            <td>No. Tel.</td>
            <td>:</td>
            <td class="p-2">{{contact.ContactNumber}}</td>
        </tr>
    </table>
    <br />
    <table style="border: none;">
        <tr>
            <td>Tandatangan PEMINJAM</td>
            <td>:</td>
            <td></td>
        </tr>
        <tr>
            <td>Nama</td>
            <td>:</td>
            <td class="p-2">{{selectedCustomer.Name}}</td>
        </tr>
        <tr>
            <td>No. K.P.</td>
            <td>:</td>
            <td *ngIf="selectedCustomer.ICNumber!=undefined" class="p-2">
                {{sharedService.processICNum(selectedCustomer.ICNumber)}}
            </td>
        </tr>
        <tr>
            <td>No. Tel.</td>
            <td>:</td>
            <td class="p-2">{{selectedCustomer.ContactNo}}</td>
        </tr>
    </table>

</div>