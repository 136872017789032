<div class="card shadow-lg requestCard mb-3">
    <div class="card-body">
        <h2><strong>{{transactionRequest.CompanyCode}}</strong></h2>
        <hr />
        <span><strong>Remark: {{transactionRequest.Remark}}</strong></span>
        <table class="table table-bordered table-custom p-0 m-0">
            <thead>
                <tr>
                    <th> Type </th>
                    <th> Amount</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let item of transactionRequest.TransactionItems">
                    <td> {{ enums.transactionTypeString[item.type-1] }}</td>
                    <td> RM {{item.amount}}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <th></th>
                    <th>RM {{calculateTotal()}}</th>
                </tr>
            </tfoot>
        </table>


        <div class="row">
            <div class="col d-flex justify-content-end align-items-right">

                <button class="btn btn-danger" (click)="confirmBeforeDelete(transactionRequest)"
                    *ngIf="this.sharedService.isBossAccount()"><i class="fas fa-trash-alt"></i>
                    Delete </button>

                <button class="btn btn-success" (click)="openAddTrasactionIntoAccountModal()"
                    *ngIf="this.sharedService.isBranchAccountHandler() "><i class="fas fa-plus"></i>
                    Add Into Account </button>

            </div>
        </div>

    </div>

</div>