<div class="container-fluid p-3">
    <div class="d-flex justify-content-end align-items-end">
        <button class="btn btn-info" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseExample">
            <i class="fas fa-chevron-circle-down" *ngIf="isCollapsed"></i>
            <i class="fas fa-chevron-circle-up" *ngIf="isCollapsed==false"></i>
        </button>
    </div>



    <div class="collapse" id="collapseExample" [ngbCollapse]="isCollapsed">
        <app-report-generator [selectedCompany]="selectedCompany"></app-report-generator>
    </div>
    <h2> Overdue Form J Report
    </h2>

    <p [hidden]="reportList.length>0"> Congratulations! All your Form Js are up to date!</p>

    <div [hidden]="reportList.length==0">

        <table class="table table-striped mt-2">
            <thead>
                <tr>
                    <td>Customer Name</td>
                    <td>Customer IC</td>
                    <td>Latest Form J Date</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let report of reportList">
                    <td class="customerCol" (click)="goToCustomer(report.customerId)"> {{report.customerName}}</td>
                    <td> {{sharedService.processICNum(report.customerIC) }}</td>
                    <td> {{report.lastGeneratedDate | date:'dd-MM-yyyy'}}</td>
                </tr>
            </tbody>

        </table>
    </div>
</div>