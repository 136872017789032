<!-- <mat-form-field> -->
<!-- <mat-label>Filter</mat-label> -->
<!-- <input matInput (keyup)="applyFilter($event)" placeholder="Enter searching criteria"> -->
<!-- </mat-form-field> -->
<ng-container class="container-fluid">
    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>


            <!-- Company Code Column -->
            <ng-container matColumnDef="CompanyCode">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Company Code </th>
                <td mat-cell *matCellDef="let set" [ngClass]="{'savedCol':set.Saved==true}"> {{set.CompanyCode}} </td>
            </ng-container>

            <!-- ID Column -->
            <ng-container matColumnDef="ID">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>No </th>
                <td mat-cell *matCellDef="let set" [ngClass]="{'savedCol':set.Saved==true}"># {{set.ID}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="Name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name </th>
                <td mat-cell *matCellDef="let set" [ngClass]="{'savedCol':set.Saved==true}"> {{set.Name}} </td>
            </ng-container>

            <!-- Remark Column -->
            <ng-container matColumnDef="Remark">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Remark </th>
                <td mat-cell *matCellDef="let set" [ngClass]="{'savedCol':set.Saved==true}"> {{set.Remark}} </td>
            </ng-container>

            <!-- SetRemark Column -->
            <ng-container matColumnDef="SetRemark">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Acc Remark </th>
                <td mat-cell *matCellDef="let set" [ngClass]="{'savedCol':set.Saved==true}"> {{set.SetRemark}}
                    <ng-container *ngFor="let tag of set.Tags; let i=index">
                        <span class="badge text-bg-secondary m-1">{{tag}} </span>
                    </ng-container>
                </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="Status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Status </th>
                <td mat-cell *matCellDef="let set" [ngClass]="{'savedCol':set.Saved==true}">
                    <h4>
                        <span class="badge text-bg-success" *ngIf="set.Valid==true">Valid</span>
                        <span class="badge text-bg-danger"
                            *ngIf="set.Valid==false && set.CheckedBy!=undefined">Invalid</span>
                        <span class="badge text-bg-primary" *ngIf="set.Valid==undefined">Pending</span>
                    </h4>
                </td>
            </ng-container>

            <!-- Total Column -->
            <ng-container matColumnDef="Total">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Total </th>
                <td mat-cell *matCellDef="let set" [ngClass]="{'savedCol':set.Saved==true}"> RM {{calculateTotal(set) |
                    number:'1.0-2'}} </td>
            </ng-container>

            <!-- Transactions Column -->
            <ng-container matColumnDef="Transactions">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Transactions </th>
                <td mat-cell *matCellDef="let set" [ngClass]="{'savedCol':set.Saved==true}">
                    <button class="btn btn-info" (click)="openSetDetails(set)">
                        {{set.TransactionList.length}} Transaction(s)</button>
                </td>
            </ng-container>






            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[25,50, 100]" showFirstLastButtons [pageSize]="100"></mat-paginator>
    </div>
</ng-container>