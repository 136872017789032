import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { GlobalCustomer } from 'src/model/globalCustomer.model';
import { LoanRequest } from 'src/model/loanRequest.model';

@Component({
  selector: 'app-conflicted-customer-list',
  templateUrl: './conflicted-customer-list.component.html',
  styleUrls: ['./conflicted-customer-list.component.css']
})
export class ConflictedCustomerListComponent implements OnInit {


  @Input() customerList: GlobalCustomer[] = new Array;
  displayedColumns: string[];
  dataSource = new MatTableDataSource;

  @Input() guarantorList: GlobalCustomer[] = new Array;
  displayedGuarantorColumns: string[];
  guarantorDataSource = new MatTableDataSource;

  @Input() ticketList: LoanRequest[] = new Array;
  displayedTicketColumns: string[];
  ticketDataSource = new MatTableDataSource;

  constructor(private modal: NgbModal, private activeModal: NgbActiveModal, private router: Router, public sharedService: SharedService, public enums: Enums) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<GlobalCustomer>(this.customerList);
    this.guarantorDataSource = new MatTableDataSource<GlobalCustomer>(this.guarantorList);
    this.ticketDataSource = new MatTableDataSource<LoanRequest>(this.ticketList);

    this.displayedColumns = ['Name', 'ICNumber', 'Status', 'CompanyName'];
    this.displayedGuarantorColumns = ['Name', 'ICNumber', 'CompanyName', 'CustomerName', 'CustomerIC'];
    this.displayedTicketColumns = ['TicketNo', 'CompanyName', 'CustomerName', 'CustomerIC'];
  }


  goToCustomer(customerID: number, profileID: number) {
    this.modal.dismissAll();
    if (profileID == undefined)
      this.router.navigate(['/manageCustomer', customerID]);
    else
      this.router.navigate(['/manageCustomer', customerID, profileID]);

   
  }


  getStatusColor(status: number) {
    var color: string;
    switch (status) {
      case this.enums.ACTIVE_CUST:
        color = "bg-primary";
        break;
      case this.enums.WARNING_CUST:
        color = "bg-warning";
        break;
      case this.enums.BAD_DEBT_CUST:
        color = "bg-dark";
        break;
      case this.enums.CP_CUST:
        color = "bg-info";
        break;
      default:
        color = "bg-success";
        break;
    }
    return color;
  }

  reset() {
    this.activeModal.close();
  }

}
