import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Enum, timeSpanDays } from 'igniteui-angular-core';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';

@Component({
  selector: 'app-add-blacklist-customer',
  templateUrl: './add-blacklist-customer.component.html',
  styleUrls: ['./add-blacklist-customer.component.css']
})
export class AddBlacklistCustomerComponent implements OnInit {

  constructor(private activeModal: NgbActiveModal, public sharedService: SharedService, private enums: Enums, private restApi: RestApiService, private constructApi: ConstructAPI) { }

  name: string;
  icNumber: string;
  remark: string;
  otherId: string;
  status: string;
  disabledSubmit = false;
  ngOnInit(): void {
  }

  reset() {
    this.activeModal.close();
  }

  checkNull() {
    return this.name == undefined || this.name == "" || this.icNumber == undefined || this.icNumber == "" || this.remark == undefined || this.remark == "" ||
      this.status == undefined || this.status == ""
  }

  submit() {
    if (this.checkNull() == true) {
      this.sharedService.openAlert("Please fill in details correctly!", this.enums.DANGER_ALERT)
    } else {
      this.addBlacklistRecord();
    }

  }

  addBlacklistRecord() {

    var processedICNum = this.sharedService.sanitizeICNumInput(this.icNumber)
    var data = {
      "remark": this.remark,
      "icNumber": processedICNum,
      "name": this.name,
      "otherId": this.otherId,
      "status": this.status
    };
    var xhr = this.restApi.postRequest(this.constructApi.getAddBlacklistCustomer(), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {

        this.sharedService.openAlert("Successfully added blacklist record!", this.enums.SUCCESS_ALERT);
        this.reset();
      }
    }
  }

}
