<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Add Principal</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body">

        <div class="row d-flex justify-content-end align-items-right">
            <div class="d-flex">
                <h4>
                    <span class="badge rounded-pill text-bg-warning float-end me-3">
                        Receipt: {{selectedLoan.ReceiptNumber}}
                    </span>
                </h4>

            </div>
        </div>
        <!--Account Number-->
        <div class="row p-3 d-flex justify-content-center align-items-center">

            <div class="col-12">
                <p>From Account</p>
            </div>
            <div class="col-12">
                <select class="browser-default form-select" name="selectedAccount" [(ngModel)]="selectedAccountId">
                    <option *ngFor="let account of companyAccountList" [value]="account.ID"
                        [selected]="setSelectedAccount(account.ID)">{{account.Name}} (RM {{account.Balance}})</option>
                </select>
            </div>

        </div>

        <!--Amount-->

        <div class="card">
            <div class="card-header">Add Principal (EXTRA) Amount</div>
            <div class="card-body">

                <div class="group">
                    <input type="number" required class="textInputLong" name="amount" [(ngModel)]="amount" min=0
                        (keypress)="sharedService.isNumberKey($event)" (change)="calculateDeductions()">
                    <span class="highlight"></span>
                    <span class="bar barLong"></span>
                    <label>Amount</label>
                </div>

                <div class="row">
                    <div class="col-6">
                        <div><span>Stamp Amount</span></div>
                        <div><input type="number" name="stampAmount" [(ngModel)]="stampAmount" min="0"></div>
                    </div>
                    <div class="col-6">
                        <div><span>Service Charge</span></div>
                        <div><input type="number" name="scAmount" [(ngModel)]="scAmount" min="0"></div>
                    </div>
                    <div class="col-6">
                        <div><span>Processing Fee</span></div>
                        <div><input type="number" name="interestCharge" [(ngModel)]="interestCharge" min="0"
                                (keypress)="sharedService.isNumberKey($event)" disabled></div>
                        <ng-container>
                            <button class="btn btn-primary" (click)="calculateInterestCharge(8)">
                                8%</button>
                            <button class="btn btn-dark ms-1" (click)="calculateInterestCharge(9)">
                                9%</button>
                            <button class="btn btn-dark ms-1" (click)="calculateInterestCharge(10)">
                                10%</button>
                            <button class="btn btn-primary ms-1" (click)="calculateInterestCharge(11)">
                                11%</button>
                            <button class="btn btn-info ms-1" (click)="calculateInterestCharge(12)">
                                12%</button>
                        </ng-container>
                    </div>
                    <div class="col-12 my-2">
                        <app-added-duit-lebih-list (getDuitLebihData)="getDuitLebihData($event)">
                        </app-added-duit-lebih-list>
                    </div>
                </div>
                <div class="col-12 mt-2">
                    <h4 class="fw-bold text-info"> Available Simpan: RM {{availableReserve}} </h4>
                    <div><span>Simpan Out</span></div>
                    <div><input type="number" name="reserve" [(ngModel)]="reserve" min="0"></div>
                </div>
                <hr />
                <h3 class="mt-2" style="color: blue;font-weight: bold;">Total On Hand: RM
                    {{calculateOnHandAmount()}}</h3>
            </div>
        </div>


        <!--Date-->
        <mat-form-field class="full-width-formField bar">
            <input matInput class="textInputLong" [max]="maxDate" [matDatepicker]="myDatepicker" name="addPrincipalDate"
                [(ngModel)]="addPrincipalDate" placeholder="Add Principal Date" [max]="sharedService.getTodayDate()"
                onkeydown="return false">
            <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #myDatepicker name="addPrincipalDate"></mat-datepicker>
        </mat-form-field>

        <div class="group">
            <input type="text" required class="textInputLong" name="remark" [(ngModel)]="remark">
            <span class="highlight"></span>
            <span class="bar barLong"></span>
            <label>Remark</label>
        </div>

    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="submit()" [disabled]="disabledSubmit">Add
            Principal</button>
        <button class="btn btn-outline-danger" type="button" (click)="reset()">Cancel</button>
    </div>

</form>