import { Time } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Timestamp } from 'rxjs/internal/operators/timestamp';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Profile } from 'src/model/profile.model';


interface PossibleSharedProfile {
  companyCode: string,
  companyId: number,
  customerId: number,
  customerName: string,
  icNumber: string,
  profileId: number,
  requestApprovalInterval: string;
}

@Component({
  selector: 'app-share-profile-to-company',
  templateUrl: './share-profile-to-company.component.html',
  styleUrls: ['./share-profile-to-company.component.css']
})
export class ShareProfileToCompanyComponent implements OnInit {

  constructor(private activeModal: NgbActiveModal, public sharedService: SharedService, private restApi: RestApiService, private constructApi: ConstructAPI, private enums: Enums) { }


  profileIdToShare: number;
  disabledSubmit = false;
  @Input() currentProfile: Profile;
  possibleSharedProfileList: PossibleSharedProfile[] = new Array;
  manualKeyInSharedId: boolean = false;

  ngOnInit(): void {

    this.getProfilePossibleSharedProfiles();
  }


  changeManualKeyInBoolean() {
    !this.manualKeyInSharedId;
    this.profileIdToShare = undefined;
  }

  validateInput() {
    if (this.profileIdToShare == undefined)
      this.sharedService.openAlert("Please key in profile ID to share!", this.enums.DANGER_ALERT);
    else
      this.confirmBeforeShare();
  }

  sharePossibleProfile(profileId: number) {
    this.profileIdToShare = profileId;
    this.confirmBeforeShare();

  }

  confirmBeforeShare() {
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Are you sure you want to share this profile to " + this.profileIdToShare + "?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something

      if (confirmation == true) {
        this.shareProfile();
      }
    });
  }



  getProfilePossibleSharedProfiles() {
    var xhttp = this.restApi.getRequest(this.constructApi.getProfilePossibleSharedProfile(this.currentProfile.ID));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {

          var possibleProfile = { companyCode: json.companyCode, companyId: json.companyId, customerId: json.customerId, customerName: json.customerName, icNumber: json.icNumber, profileId: json.profileId, requestApprovalInterval: json.requestApprovalInterval }
          this.possibleSharedProfileList.push(possibleProfile)
        }
        );

      }
    };
  }

  shareProfile() {

    var xhr = this.restApi.patchRequest(this.constructApi.getShareProfileToAnotherProfile(Number(this.currentProfile.ID), Number(this.profileIdToShare)));
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        if (xhr.status == 200) {
          //if success emit
          // this.passEntry.emit();
          window.location.reload();
          this.closeModal();
          this.sharedService.openAlert("Successfully shared profile!", this.enums.SUCCESS_ALERT)
        }
        else {
          this.sharedService.openErrorMessage(xhr);
        }


      }

    }
  }


  closeModal() {
    this.activeModal.close();
  }
}
