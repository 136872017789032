<div class="card">
  <div class="card-body" [ngClass]="defineLoanScheme()">
    <div class="row">
      <div class="col p-0 d-flex justify-content-start align-items-left">

        <p class="badge rounded-pill text-bg-secondary" [hidden]="isEditing == true">
          Code: {{ loan.LoanCode }}
        </p>

        <div>
          <input class="textInputShort m-0" type="text" name="loanCode" [(ngModel)]="loan.LoanCode"
            [hidden]="isEditing == false" />
        </div>

        <ng-container *ngIf="
            (sharedService.isAdmin() == false &&
            sharedService.isBossAccount() == false && !sharedService.isFinance() &&
            sharedService.isAuditor() == false) && isSimplifiedView==false
          ">
          <button class="btn btn-info ms-1 d-none d-md-inline" (click)="changeIsEditing(true)"
            [hidden]="isEditing == true ||isSharedLoan()==true">
            <i class="fas fa-pencil-alt"></i>
          </button>
          <button class="btn btn-danger ms-1" (click)="changeIsEditing(false)" [hidden]="isEditing == false">
            <i class="fas fa-times"></i>
          </button>
          <button class="btn btn-success ms-1" [hidden]="isEditing == false" (click)="updateLoan()">
            <i class="fas fa-check"></i>
          </button>
        </ng-container>
      </div>
      <div class="col d-flex justify-content-end align-items-right">
        <button class="btn btn-dark me-2" (click)="openViewLoanDocsModal(loan.ID)" placement="top"
          ngbTooltip="View Loan Documents" *ngIf="loan.LoanDocExisted == true && sharedService.isStaff()==false"><i
            class="fas fa-paperclip"></i></button>

        <p class="badge text-bg-primary" *ngIf="isSharedLoan()==true"> <i class="far fa-handshake"></i>
          {{loan.CompanyCode}}</p>
        <p class="badge  float-end me-2"
          [ngClass]="{'text-bg-dark':loan.Scheme=='EPF','text-bg-info':loan.Scheme!='EPF'}" style="cursor:pointer"
          *ngIf="loan.LoanDocExisted == false && isSimplifiedView==false && (sharedService.isBranchAccountHandler()  || sharedService.isBookkeepingStaff()) && isSharedLoan()==false "
          (click)="openUploadLoanDocModal(loan.ID)">
          <i class="fas fa-file-upload"></i> Add Payout Slip
        </p>

        <p class="badge rounded-pill text-bg-danger float-end me-2" *ngIf="loan.IsPendingCommission == true && isSimplifiedView==false && (sharedService.isAdmin() == false &&
          sharedService.isBossAccount() == false && !sharedService.isFinance() &&
          sharedService.isAuditor() == false) &&  selectedProfile.SharedProfileId == selectedProfile.ID">
          No Commission
        </p>
        <p class="badge rounded-pill text-bg-info float-end" *ngIf="loan.Scheme == 'A'">
          {{ loan.InterestRate }}
        </p>

        <p class="badge rounded-pill text-bg-light float-end">
          {{ loan.ReceiptNumber }} <span *ngIf="loan.Scheme=='EPF'">(EPF)</span>
        </p>

        <div>
          <p class="badge rounded-pill float-end" [ngClass]="'text-'+getStatusColor(loan.Status)">
            {{ getLoanStatus(loan.Status) }}
          </p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6">
        <p>
          <i class="far fa-calendar-minus"></i>
          Registered Date: <br />
          <span class="fw-bold">{{
            loan.DateCreated | date: "dd-MM-yyyy"
            }}</span>
        </p>
      </div>
      <div class="col-12 col-md-6">
        <p>
          <i class="fas fa-money-bill-wave"></i>
          Loan Amount: <br />
          <span class="fw-bold">RM {{ loan.Principal }}</span>
          <span *ngIf="loan.Scheme != 'A'" class="fw-bold text-danger">
            | Total: RM {{ loanTotalIncludeInterest | number:'1.0-2'}}</span>
          <button class="btn btn-primary ms-2" (click)="openAddPrincipalModal(loan)" *ngIf="
              loan.Scheme == 'A' &&
              isCP(loan.Status) == false &&
              isBadDebt(loan.Status) == false &&
              isSettled(loan.Status) == false &&
              sharedService.isAdmin() == false &&
              sharedService.isBossAccount() == false && 
              !sharedService.isFinance() &&
              sharedService.isAuditor() == false &&
              loan.IsEPF ==false &&
              needRestrictionCustomer==false">
            <i class="fas fa-plus"></i>
          </button>
        </p>

      </div>
    </div>
    <div class="row" *ngIf="isSimplifiedView==false || loan.Scheme=='P'">
      <div class="col-6" [hidden]="loan.Scheme == 'HUTANG'">
        <p>
          <i class="fas fa-table"></i>
          Tenure :
          <span class="fw-bold">{{ loan.Term }} month(s)</span>
        </p>
      </div>
      <div class="col-6" [hidden]="loan.Scheme == 'HUTANG'">


        <p>

          <ng-template #settlementDetailsTooltip>
            <div>
              <p>RM {{sharedService.calculateEPFSettlementAmount(loan)| number: "1.0-2"}}</p>
              <p class="p-0 m-0">
                <small *ngIf="loan.SettlementCharges!=undefined">Charges Handling : RM
                  {{loan.SettlementCharges.HandlingCharge |number:'1.0-2'}}</small>
              </p>

              <p class="p-0 m-0">
                <small *ngIf="loan.SettlementCharges==undefined">Settlement DL :RM {{ loan.SettlementExtra | number:
                  "1.0-2"}}</small>
                <small *ngIf="loan.SettlementCharges!=undefined">Charges DL :RM {{
                  loan.SettlementCharges.Extras/loan.Term | number:
                  "1.0-2"}} x {{loan.Term |number:'1.0-2'}} = RM
                  {{loan.SettlementCharges.Extras |number:'1.0-2'}}</small>
              </p>
              <p class="p-0 m-0">
                <small *ngIf="loan.SettlementCharges==undefined">Settlement Stamp :RM {{ loan.SettlementStamp | number:
                  "1.0-2"}}</small>
                <small *ngIf="loan.SettlementCharges!=undefined">Charges Stamp :RM {{
                  loan.SettlementCharges.Stamp/loan.Term |
                  number:
                  "1.0-2"}} x {{loan.Term |number:'1.0-2'}} = RM
                  {{loan.SettlementCharges.Stamp |number:'1.0-2'}}</small>
              </p>
              <p class="p-0 m-0">
                <small *ngIf="loan.SettlementCharges==undefined">Settlement SC :RM {{ loan.SettlementServiceCharge|
                  number:
                  "1.0-2" }}</small>
                <small *ngIf="loan.SettlementCharges!=undefined">Charges SC :RM {{
                  loan.SettlementCharges.ServiceCharge/loan.Term |
                  number:
                  "1.0-2"}} x {{loan.Term |number:'1.0-2'}} = RM
                  {{loan.SettlementCharges.ServiceCharge |number:'1.0-2'}}</small>
              </p>
            </div>
          </ng-template>
          <i class="fas fa-calendar-check"></i>
          Monthly :
          <span class="fw-bold">RM{{ loan.MonthlyPayment | number: "1.2-2" }}</span>
          <span class="fw-bold text-primary" *ngIf="loan.IsEPF == true && loan.LegacySettlement!=undefined">
            | <i class="fas fa-money-check-alt"></i> : RM{{ loan.LegacySettlement | number: "1.0-0"}}
          </span>

          <span class="fw-bold text-primary" *ngIf="loan.Scheme =='EPF'">
            | <i class="fas fa-money-check-alt"></i> : RM{{ sharedService.calculateEPFSettlementAmountWithCharges(loan)|
            number: "1.0-2"}}
            <i class="far fa-question-circle ms-2" placement="top" [ngbTooltip]="settlementDetailsTooltip"
              style="cursor: pointer"></i>
          </span>

          <button class="btn btn-primary ms-1"
            *ngIf=" sharedService.isBossAccount()==true && loan.SettlementCharges!=undefined && (sharedService.checkIsWholeNumber(loan.SettlementCharges.Extras)==false ||sharedService.checkIsWholeNumber(loan.SettlementCharges.ServiceCharge)==false
            || sharedService.checkIsWholeNumber(loan.SettlementCharges.Stamp)==false || sharedService.checkIsWholeNumber(loan.SettlementCharges.HandlingCharge)==false)"
            (click)="confirmBeforeMoveCents()">Cents to Interest</button>

          <button class="btn btn-primary ms-1" *ngIf="loan.IsEPF == true && loan.LegacySettlement==undefined"
            (click)="openAddLegacySettlement(loan)">Add
            Settlement</button>
          <button class="btn btn-danger"
            *ngIf="loan.IsEPF == true && loan.LegacySettlement!=undefined && sharedService.isBossAccount()==true"
            (click)="confirmBeforeDeleteLegacySettlement(loan.ID)"><i class="fas fa-times"></i></button>
        </p>
      </div>
    </div>
    <!-- <div class="row">
            <div class="col-6">
                <p>
                    <i class="fas fa-stamp"></i>
                    Stamp : RM {{loan.StampAmount}}
                </p>
            </div>

        </div> -->

    <hr />
    <div class="row">
      <div class="col-12 col-md-6">
        <p [style.color]="setBalanceColor(loan.Balance)" style="float: left; font-weight: bold">
          BALANCE :RM {{ loan.Balance }}
          <span [hidden]="loan.Scheme == 'A' || loan.Scheme == 'HUTANG'">({{
            loan.Balance / loan.MonthlyPayment | number: "1.0-0"
            }} terms)</span>
        </p>

      </div>
      <div class="col-12 col-md-6">
        <ng-template #tooltipContent>
          <div>
            <p class="p-0 m-0">
              <small>Monthly Interest :RM {{ loan.MonthlyInterest }}</small>
            </p>
            <p class="p-0 m-0">
              <small>Interest Charge :RM {{ loan.InterestCharge }}</small>
            </p>
            <p class="p-0 m-0">
              <small>Arrear Charges :RM {{ loan.ArrearPaid }}</small>
            </p>
            <p class="p-0 m-0">
              <small>Discount :RM {{ loan.Discount }}</small>
            </p>
          </div>
        </ng-template>
        <p style="float: left; color: blue; font-weight: bold" [hidden]="loan.Scheme == 'HUTANG'&&
        isCP(loan.Status) == false">
          INTEREST CHARGED :RM
          {{
          loan.InterestCharge +
          loan.ArrearPaid +
          loan.MonthlyInterest +
          loan.Discount
          }}
          <i class="far fa-question-circle ms-2" placement="top" [ngbTooltip]="tooltipContent"
            style="cursor: pointer"></i>
        </p>
      </div>
      <div class="col-12 col-md-6" *ngIf="isSimplifiedView==false">
        <p style="float: left; color: black; font-weight: bold" [hidden]="loan.Scheme == 'EPF'">
          ARREAR :{{ loan.Arrear }} time(s)
        </p>
      </div>
      <div class="col-12 col-md-6" *ngIf="
          (sharedService.isBranchAccountHandler() == true ||
          sharedService.isBossAccount() == true || sharedService.isFinance()) && isSimplifiedView==false
        ">
        <p class="badge rounded-pill" [ngClass]="getIfEarning(calculateProfitAndLoss(loan))">
          RM {{ calculateProfitAndLoss(loan) | number: "1.2-2" }}
        </p>
      </div>
      <div class="col-12">
        <button class="btn btn-secondary" *ngIf="sharedService.isBossAccount()|| sharedService.isAdmin()"
          (click)="openMoveLoanModal()">
          <i class="fas fa-arrows-alt"></i> Move Loan</button>
      </div>
    </div>

    <!-- <button mat-fab color="warn" aria-label="Pay" style="float: right;" *ngIf="loan.Status!='SETTLED'"
            (click)="openPaymentModal(loan.ID, loan.ReceiptNumber);$event.stopPropagation()">
            <i class="fas fa-dollar-sign"></i>
        </button> -->

    <!--try floating action button-->

    <div class="fixed-action-btn">
      <ng-container *ngIf="isCP(loan.Status) == false && isSimplifiedView==false; else cpPaymentButton">
        <a class="btn-floating btn-large red">
          <i class="fas fa-caret-down"></i>
        </a>
        <ul>
          <li>
            <span class="badge text-bg-dark" style="color: white">{{
              loan.ID
              }}</span>
          </li>
          <li>
            <a class="btn-floating red" (click)="openLoanDetailsModal(loan)" #tooltip="matTooltip"
              matTooltip="Loan Transaction" matTooltipPosition="right" matTooltipHideDelay="1000"
              matTooltipClass="tooltip-black">
              <i class="fas fa-history"></i>
            </a>
          </li>
          <li [hidden]="
              (isSettled(loan.Status) == true && loan.Scheme == 'HUTANG') ||
              sharedService.isAdmin() == true ||
              sharedService.isAuditor() == true ||
              sharedService.isBossAccount() == true || sharedService.isFinance() || selectedProfile.SharedProfileId!=undefined
            ">
            <!-- <li [hidden]="isSettled(loan.Status)==true"> -->
            <a class="btn-floating yellow darken-1" (click)="openPaymentModal(loan)" #tooltip="matTooltip"
              matTooltip="Payment" matTooltipPosition="right" matTooltipHideDelay="1000"
              matTooltipClass="tooltip-black">
              <i class="fas fa-dollar-sign"></i>
            </a>
          </li>

          <!--only show pay by simpan button for operating user-->
          <!--for normal active EPF loan, cannot use pay by simpan-->
          <!--allow to use this function for all SETTLED LOAN because can use simpan to pay interest charge -->
          <li
            *ngIf="
              (sharedService.isAdmin() == false && sharedService.isBossAccount() == false && !sharedService.isFinance()
              && sharedService.isAuditor()== false) && ((loan.Scheme!='EPF' && loan.IsEPF==false)|| isSettled(loan.Status) == true)">

            <a class="btn-floating green" (click)="openPayWithReserveModal(loan, loan.MonthlyPayment)"
              #tooltip="matTooltip" matTooltip="Pay by Simpan" matTooltipPosition="right" matTooltipHideDelay="1000"
              matTooltipClass="tooltip-black"><i class="fas fa-piggy-bank"> </i>
            </a>
          </li>
          <li
            *ngIf="sharedService.isBranchAccountHandler() == true &&  (selectedProfile.SharedProfileId==undefined|| selectedProfile.SharedProfileId == selectedProfile.ID)">
            <a class="btn-floating purple" (click)="openAddCommissionModal(loan)" #tooltip="matTooltip"
              matTooltip="Assign Commission" matTooltipPosition="right" matTooltipHideDelay="1000"
              matTooltipClass="tooltip-black">
              <i class="fas fa-gift"></i>
            </a>
          </li>

          <li>
            <a class="btn-floating blue" (click)="openLoanArrear(loan)" #tooltip="matTooltip" matTooltip="Loan Arrears"
              matTooltipPosition="right" matTooltipHideDelay="1000" matTooltipClass="tooltip-black">
              <i class="fas fa-book-dead"></i>
            </a>
          </li>

          <li [hidden]="sharedService.isBossAccount() == false">
            <a class="btn-floating gray darken-1" (click)="openChangeStatusModal(loan)" #tooltip="matTooltip"
              matTooltip="Change Status" matTooltipPosition="right" matTooltipHideDelay="1000"
              matTooltipClass="tooltip-black">
              <i class="fas fa-user-edit"></i>
            </a>
          </li>
        </ul>
      </ng-container>

      <ng-template #cpPaymentButton>
        <a class="btn-floating btn-large green" *ngIf="isSimplifiedView==false">
          <i class="fas fa-caret-down"></i>
        </a>
        <ul>
          <li>
            <a class="btn-floating red" (click)="openLoanDetailsModal(loan)" #tooltip="matTooltip"
              matTooltip="Loan Transaction" matTooltipPosition="right" matTooltipHideDelay="1000"
              matTooltipClass="tooltip-black">
              <i class="fas fa-history"></i>
            </a>
          </li>
          <li>
            <a class="btn-floating blue" (click)="openPaymentForBadDebt(loan)" #tooltip="matTooltip"
              matTooltip="Simpan Bad Debt" matTooltipPosition="right" matTooltipHideDelay="1000"
              matTooltipClass="tooltip-black">
              <i class="fas fa-hand-holding-usd"></i>
            </a>
          </li>
          <li [hidden]="loan.Scheme=='HUTANG' && loan.Balance==0">
            <a class="btn-floating red darken-1" (click)="openPaymentModal(loan)" #tooltip="matTooltip"
              matTooltip="Payment" matTooltipPosition="right" matTooltipHideDelay="1000"
              matTooltipClass="tooltip-black"><i class="fas fa-dollar-sign"></i>
            </a>
          </li>
          <li *ngIf="sharedService.isBranchAccountHandler() == true">
            <a class="btn-floating green" (click)="openPayWithReserveModal(loan, loan.MonthlyPayment)"
              #tooltip="matTooltip" matTooltip="Pay by Simpan" matTooltipPosition="right" matTooltipHideDelay="1000"
              matTooltipClass="tooltip-black"><i class="fas fa-piggy-bank"> </i>
            </a>
          </li>

          <li *ngIf="
              sharedService.isAdmin() == true ||
              sharedService.isBossAccount() == true
            ">
            <a class="btn-floating purple" (click)="confirmBeforeRemoveCP()" #tooltip="matTooltip"
              matTooltip="Remove From CP" matTooltipPosition="right" matTooltipHideDelay="1000"
              matTooltipClass="tooltip-black">
              <i class="fas fa-user-times"></i>
            </a>
          </li>
        </ul>
      </ng-template>
    </div>


  </div>
</div>