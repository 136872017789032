import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Loan } from 'src/model/loan.model';

@Component({
  selector: 'app-legacy-epf-settlement',
  templateUrl: './legacy-epf-settlement.component.html',
  styleUrls: ['./legacy-epf-settlement.component.css']
})
export class LegacyEpfSettlementComponent implements OnInit {

  @Input() loanList: Loan[] = new Array;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  disabledSubmit = false;
  constructor(private activeModal: NgbActiveModal, private sharedService: SharedService, private enums: Enums, private restApi: RestApiService, private constructApi: ConstructAPI) { }

  ngOnInit(): void {
  }



  containsError() {
    var error = "";
    for (let i = 0; i < this.loanList.length; i++) {
      if (this.loanList[i].LegacySettlement == 0 || this.loanList[i].LegacySettlement == undefined)
        error += "- Please fill in LEGACY SETTLEMENT for loan =" + this.loanList[i].ReceiptNumber;
      else if (this.loanList[i].LegacySettlement <= this.loanList[i].Principal)
        error += "\n- LEGACY SETTLEMENT for loan =" + this.loanList[i].ReceiptNumber + " is less than principal";
    }

    if (error != "")
      return "Please fix all these errors before proceed : \n" + error;
    else
      return error;
  }

  submit() {
    this.disabledSubmit = true;
    var error = this.containsError();
    if (error != "") {
      this.sharedService.openAlert(error, this.enums.DANGER_ALERT);
      this.disabledSubmit = false;
    }
    else {
      for (let i = 0; i < this.loanList.length; i++) {
        this.addLegacyEPFSettlement(this.loanList[i], i);
      }
    }
  }


  addLegacyEPFSettlement(loan: Loan, index: number) {

    var data = {
      "legacySettlement": loan.LegacySettlement
    }
    var xhr = this.restApi.postRequest(this.constructApi.getAddLegacyEPFSettlement(loan.ID), data)
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        //if success , then proceed
        if (xhr.status == 200) {

          //if last object is updated
          if (index == this.loanList.length - 1) {
            this.passEntry.emit(true)
            this.closeModal();
          }

        }
        else {
          //show error message
          this.sharedService.openAlert(JSON.parse(xhr.responseText).error, this.enums.DANGER_ALERT)

        }
      }
    }
  }

  closeModal() {
    this.activeModal.close();
  }

}
