import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Transaction } from 'src/model/transaction.model';
import { TransactionHistoryComponent } from '../transaction-history/transaction-history.component';

@Component({
  selector: 'app-delete-reason-remark',
  templateUrl: './delete-reason-remark.component.html',
  styleUrls: ['./delete-reason-remark.component.css']
})
export class DeleteReasonRemarkComponent implements OnInit {


  deleteRemark: string;
  disabledSubmit: boolean = false;

  generalReason: string;

  constructor(private activeModal: NgbActiveModal, private sharedService: SharedService, public enums: Enums, private restApi: RestApiService, private constructApi: ConstructAPI, private cdr: ChangeDetectorRef) { }
  @Input() deleteTransactionId: number;
  @Input() companyId: number;
  @Input() groupId: number;

  @Input() groupTransactions: Transaction[] = new Array;
  @Input() transaction: Transaction;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();


  ngOnInit(): void {
    if (this.groupId != undefined)
      this.getGroupTransactions(this.groupId)
  }





  calculateTotalPayment() {
    var sum = 0;

    for (let i = 0; i < this.groupTransactions.length; i++) {
      sum += this.groupTransactions[i].Amount;
    }

    return sum;
  }

  submit() {
    if (this.generalReason == undefined || this.generalReason == "")
      this.sharedService.openAlert("Please key in your reason to delete transaction!", this.enums.DANGER_ALERT);
    else {

      //if there's no further desc on reason, then only submit the GENERAL REASON, else append the desc behind GENERAL REASON
      var deleteReason = (this.deleteRemark == undefined || this.deleteRemark == "") ? this.generalReason : this.generalReason + "-" + this.deleteRemark;

      this.passEntry.emit(deleteReason);
      // alert(deleteReason)
    }


    this.activeModal.close();
  }


  getGroupTransactions(groupId: number) {

    var xhttp = this.restApi.getRequest(this.constructApi.getAllTransactionsForTransactionGroup(this.companyId, groupId));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var transactionArray = JSON.parse((xhttp.responseText));

        transactionArray.forEach(json => {
          var transaction = new Transaction(json.id, json.remark, json.amount, undefined, json.accountId, json.created_on, json.type, json.handleBy, json.receiptId, json.customerId, undefined, undefined)
          this.groupTransactions.push(transaction);

          this.cdr.detectChanges();
        }
        );

      }
    };

  }


  reset() {
    this.deleteRemark = "";
    this.activeModal.close();
  }

}
