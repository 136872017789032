import { Component, Input, OnInit } from '@angular/core';
import { Company } from 'src/model/company.model';
import { Customer } from 'src/model/customer.model';
import { SharedService } from '../../Shared/shared-service.service';

@Component({
  selector: 'app-formj-receipt-letter',
  templateUrl: './formj-receipt-letter.component.html',
  styleUrls: ['./formj-receipt-letter.component.css']
})
export class FormjReceiptLetterComponent implements OnInit {

  @Input() selectedCompany: Company;
  @Input() selectedCustomer: Customer;
  constructor(public sharedService:SharedService) { }

  ngOnInit(): void {
  }

}
