export interface TransactionRequestItem {
    amount: number,
    type: number,
    subtype: number
}


export class TransactionRequest {


    constructor(
        private _id: number,
        private _companyCode: string,
        private _companyId: number,
        private _remark: string,
        private _requestedBy: string,
        private _requestedOn: Date,
        private _transactionItems: TransactionRequestItem[]
    ) { }


    public get TransactionItems(): TransactionRequestItem[] {
        return this._transactionItems;
    }
    public set TransactionItems(value: TransactionRequestItem[]) {
        this._transactionItems = value;
    }
    public get RequestedOn(): Date {
        return this._requestedOn;
    }
    public set RequestedOn(value: Date) {
        this._requestedOn = value;
    }
    public get RequestedBy(): string {
        return this._requestedBy;
    }
    public set RequestedBy(value: string) {
        this._requestedBy = value;
    }
    public get Remark(): string {
        return this._remark;
    }
    public set Remark(value: string) {
        this._remark = value;
    }
    public get CompanyId(): number {
        return this._companyId;
    }
    public set CompanyId(value: number) {
        this._companyId = value;
    }
    public get CompanyCode(): string {
        return this._companyCode;
    }
    public set CompanyCode(value: string) {
        this._companyCode = value;
    }
    public get ID(): number {
        return this._id;
    }
    public set ID(value: number) {
        this._id = value;
    }


}