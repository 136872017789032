<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title" *ngIf="request!=undefined">{{request.CustomerName}}
            ({{sharedService.processICNum(request.CustomerIC)}})</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()" aria-label="Close"></button>
    </div>

    <div class="modal-body">
        <div class="container-fluid p-0">
            <ng-container *ngIf="requestList!=undefined">
                <ng-container *ngFor="let request of requestList">
                    <app-loan-request-details [request]="request" [isExpanded]="true" [onlyViewLoan]="true"></app-loan-request-details>
                </ng-container>
            </ng-container>



            <ng-container *ngIf="request!=undefined">
                <app-loan-request-details [request]="request" [isExpanded]="true" [onlyViewLoan]="onlyViewLoan"></app-loan-request-details>
            </ng-container>

        </div>
    </div>

    <div class="modal-footer"
        *ngIf="request!=undefined && request.AuthorizedBy ==undefined && sharedService.isBranchManager()==true ">
        <button class=" btn btn-outline-success" type="button" (click)="confirmBeforeAction()"
            [disabled]="disabledSubmit">Authorize</button>
    </div>
</form>