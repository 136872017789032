<form>
  <!-- Modal Header -->
  <div class="modal-header">
    <h4 class="modal-title">Edit Profile</h4>
    <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
  </div>

  <div class="modal-body">

    <div class="container">
      <div class="d-flex justify-content-center align-items-center">
        <img src="../../assets/image/man-avatar.png" alt="Avatar"
          class="img-thumbnail img-fluid border rounded-circle imageAvatar">
        <div class="overlayIcon">
          <i class="fas fa-camera iconUpload"></i>
        </div>
      </div>
    </div>

    <div class="m-3 text-center">
      <h2>{{loggedInUsername}}</h2>
    </div>

    <div class="mt-4 d-flex justify-content-center align-items-center">
      <div class="group">
        <input type="password" required class="textInputLong" name="newPassword" [(ngModel)]="newPassword">
        <span class="highlight"></span>
        <span class="bar barLong"></span>
        <label>New Password</label>
      </div>
    </div>

    <div class="mt-4 d-flex justify-content-center align-items-center">
      <div class="group">
        <input type="password" required class="textInputLong" name="confirmPassword" [(ngModel)]="confirmPassword">
        <span class="highlight"></span>
        <span class="bar barLong"></span>
        <label>Confirm Password</label>
      </div>
    </div>

    <!-- <div class="p-3 d-flex justify-content-center align-items-center">
      <select class="browser-default form-select" disabled="disabled">

        <ng-container *ngFor="let role of roleList">
          <option [value]="role.ID" [selected]="getSelectedRole(role.ID)">{{role.Name | uppercase}}</option>
        </ng-container>

      </select>


    </div>
    <div class="p-3 d-flex justify-content-center align-items-center">
      <select class="browser-default form-select" disabled="disabled">

        <ng-container *ngFor="let company of selectCompanyList">
          <option [value]="company.ID" [selected]="getSelectedCompany(company.ID)">{{company.Name | uppercase}}</option>
        </ng-container>

      </select>


    </div> -->
  </div>

  <div class="modal-footer">
    <button class="btn btn-outline-success" (click)="updateProfile()">Update</button>
    <button class="btn btn-outline-danger" type="button" data-dismiss="modal">Cancel</button>
  </div>
</form>