<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class=" modal-title">Move Loan to Profile</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body">
        <ul class="nav nav-tabs">
            <li class="nav-item" *ngIf="profileList.length>1">
                <a class="nav-link" [ngClass]="setActive('Merge')" role="tab"  data-bs-toggle="tab" href="#tabMergeProfile">
                    <p>Same Customer Profile</p>

                </a>
            </li>

            <li class="nav-item">
                <a class="nav-link" [ngClass]="setActive('Move')" role="tab"  data-bs-toggle="tab" href="#tabMoveProfile">
                    <p>Move to other customer</p>
                </a>
            </li>

        </ul>

        <div class="tab-content">
            <div class="tab-pane" [ngClass]="setActive('Merge')" role="tabpanel" id="tabMergeProfile"
                *ngIf="profileList.length>1">
                <h4 class="fw-bold">Current Profile</h4>
                <div class="card" style="background-color: lightsteelblue;">
                    <div class="card-body">
                        <table class="table table-custom">
                            <tbody>
                                <tr>
                                    <td>Occupation:</td>
                                    <th>{{selectedProfile.Occupation}}</th>
                                </tr>
                                <tr>
                                    <td>Customer Code:</td>
                                    <th>{{selectedProfile.CustomerCode}}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <h4 class="fw-bold mt-2">Move to profile</h4>
                <table class="table ">
                    <thead>
                        <tr>
                            <th>Occupation</th>
                            <th>Customer Code</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let profile of profileList">
                            <td *ngIf="profile.ID != selectedProfile.ID">{{profile.Occupation}}</td>
                            <td *ngIf="profile.ID != selectedProfile.ID">{{profile.CustomerCode}}</td>
                            <td *ngIf="profile.ID != selectedProfile.ID" class="d-flex align-items-center">
                                <button class="btn btn-info" (click)="confirmBeforeMoveToSameCustomer(profile)"
                                    [disabled]="disabledSubmit" type="button">Select</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="tab-pane" [ngClass]="setActive('Move')" role="tabpanel" id="tabMoveProfile">

                <!-- <div class="row"> -->

                <div class="card mt-2">
                    <div class="card-header">Search Profile</div>
                    <div class="card-body">
                        <div class="col-12  col-lg-4">
                            <div class="position-relative mb-2">
                                <input type="text" required class="textInputLong" name="searchName"
                                    [(ngModel)]="searchName">
                                <span class="highlight"></span>
                                <span class="bar barLong"></span>
                                <label>Name</label>
                            </div>
                        </div>
                        <div class="col-12  col-lg-4">
                            <div class="position-relative mb-2">
                                <input type="text" required class="textInputLong" name="searchICNum"
                                    [(ngModel)]="searchICNum">
                                <span class="highlight"></span>
                                <span class="bar barLong"></span>
                                <label>IC Number</label>
                            </div>
                        </div>
                        <div class="col-12  col-lg-4">
                            <div class="position-relative mb-2">
                                <input type="text" required class="textInputLong" name="searchCustCode"
                                    [(ngModel)]="searchCustCode">
                                <span class="highlight"></span>
                                <span class="bar barLong"></span>
                                <label>Customer Code</label>
                            </div>
                        </div>

                        <div class="col-12 col-lg-4 d-lg-flex justify-content-end mt-0 pt-0">
                            <button class="btn btn-primary"
                                (click)="findCustomer(searchName,searchICNum,searchCustCode)"
                                [disabled]="detectEnableSearch()">
                                <i class="fas fa-search"></i> Search
                            </button>
                        </div>
                    </div>
                </div>

                <!-- </div> -->



                <div class="row mt-3" *ngIf="searchProfileList.length>0">
                    <h3 class="fw-bold ms-2">Results:</h3>
                    <div class="col-12 mb-2" *ngFor=" let customer of searchProfileList">
                        <div class="card" style="background-color: lightskyblue;">
                            <div class="card-body">
                                <table class="table table-custom">
                                    <tbody>
                                        <tr>
                                            <th>Name:</th>
                                            <th>{{customer.Name}}</th>
                                        </tr>
                                        <tr>
                                            <th>IC Number:</th>
                                            <th>{{sharedService.processICNum(customer.ICNumber)}}</th>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="row">
                                    <div class="col-12 d-flex justify-content-end">
                                        <button class="btn btn-dark" (click)="confirmBeforeMove(customer,true)">Force
                                            Move</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>


    <!-- <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="submit()" [disabled]="disabledSubmit">Add
            Saving</button>
        <button class="btn btn-outline-danger" type="button" (click)="reset()">Cancel</button>
    </div> -->

</form>