<form>
    <!-- Modal Header -->
    <div class="modal-header" style="background-color:aquamarine;" [hidden]="isModal==false">
        <h4 class="modal-title">Assign Commission</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div [ngClass]="isModal == false ? 'p-3' : 'modal-body'">
        <div [hidden]="commissionList.length>=0">
            <p>You do not have any unassigned commission.</p>
        </div>

        <div [hidden]="commissionList.length==0">
            <div class="row">
                <div class="col">
                    <mat-form-field>
                        <mat-label>Filter</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Enter searching criteria">
                    </mat-form-field>
                </div>
                <div class="col">
                    <button class="btn btn-danger" [hidden]="loggedInRole!=enums.BRANCH_MANAGER"
                        (click)="confirmBeforeAssignAll()"><i class="fas fa-trash-alt"></i> Clear
                        Commission</button>
                </div>
            </div>


            <div class="mat-elevation-z8">
                <table mat-table [dataSource]="dataSource" matSort>

                    <!-- Customer Name Column -->
                    <ng-container matColumnDef="CustomerName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Name </th>
                        <td mat-cell *matCellDef="let commission" (click)="goToCustomer(commission.CustomerId)">
                            <p class="customerNameText">{{commission.CustomerName}}</p>
                        </td>
                    </ng-container>

                    <!-- Principal Column -->
                    <ng-container matColumnDef="Principal">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Principal </th>
                        <td mat-cell *matCellDef="let commission">
                            {{commission.Principal}} </td>
                    </ng-container>


                    <!-- Company Code Column -->
                    <ng-container matColumnDef="CompanyCode">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> CompanyCode Code </th>
                        <td mat-cell *matCellDef="let commission" (click)="goToCustomer(commission.CustomerId)">
                            <p class="customerNameText">{{commission.CompanyCode}}</p>
                        </td>
                    </ng-container>

                    <!-- Rate Column -->
                    <!-- <ng-container matColumnDef="Rate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Rate </th>
                <td mat-cell *matCellDef="let commission">
                    <mat-form-field appearance="fill">
                        <mat-label>Rate</mat-label>
                        <mat-select [disabled]="commission.Assigned" [(ngModel)]="commission.Rate"
                            name="commissionRate">
                            <mat-option *ngFor="let rate of rates" [value]="rate.value">
                                {{rate.viewValue}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
            </ng-container> -->
                    <!-- <ng-container matColumnDef="Amount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Amount </th>
                        <td mat-cell *matCellDef="let commission">
                            <div class="row">
                                <div class="col-12"> <input type="text" required class="textInputShort" name="Amount"
                                        [(ngModel)]="commission.Amount" placeholder="Amount"
                                        [disabled]="commission.Assigned">
                                </div>
                                <div class="col-12"><span
                                        class="text-danger fw-bold">{{(commission.Amount/commission.Principal)*100
                                        ||0 | number: '1.0-2'}}%</span>
                                </div>
                            </div>


                        </td>
                    </ng-container> -->


                    <!-- Assignee Column -->
                    <!-- <ng-container matColumnDef="Assignee">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Assignee </th>
                        <td mat-cell *matCellDef="let commission">
                            <mat-form-field appearance="fill">
                                <mat-label>Assignee</mat-label>
                                <mat-select [disabled]="commission.Assigned" [(ngModel)]="commission.Assignee"
                                    name="assignee">
                                    <mat-option *ngFor="let user of usernameList" [value]="user">
                                        {{user}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                    </ng-container> -->

                    <!-- Action Column -->
                    <ng-container matColumnDef="Action">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
                        <td mat-cell *matCellDef="let commission;let i= index">
                            <btn class="btn btn-primary" (click)="addCommission(commission,i)"
                                *ngIf="commission.Assigned==false"> Assign</btn>
                        </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[100,200,300]" showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
</form>