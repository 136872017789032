import { Component, OnInit, Input } from '@angular/core';

import { DatePipe } from '@angular/common';
import { ReceiptPayment, TaskCollection } from 'src/model/taskCollection';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-task-collection-list',
  templateUrl: './task-collection-list.component.html',
  styleUrls: ['./task-collection-list.component.css'],
  providers: [DatePipe]
})
export class TaskCollectionListComponent implements OnInit {

  constructor(private activeModal: NgbActiveModal, private restapi: RestApiService, private constructApi: ConstructAPI, public datepipe: DatePipe) { }

  showDate: string;
  loggedInCompanyId: number;

  @Input() selectedDate: Date;
  ngOnInit(): void {
    this.loggedInCompanyId = Number(sessionStorage.getItem("companyId"));
    this.showDate = this.datepipe.transform(this.selectedDate, 'yyyy-MM-dd');

    this.getTaskCollections();
  }

  taskCollectionList: TaskCollection[] = new Array;
  // receiptPaymentList: ReceiptPayment[] = new Array;



  getTaskCollections() {

    var xhttp = this.restapi.getRequest(this.constructApi.getTaskCollection(this.loggedInCompanyId, this.showDate))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // Typical action to be performed when the document is ready:

        var jsonArray = JSON.parse((xhttp.responseText));


        jsonArray.forEach(json => {
          var receiptPaymentList: ReceiptPayment[] = new Array;
          // get the receipts and payment amount in each task
          json.loans.forEach(collection => {

            let receiptPayment = new ReceiptPayment(collection.receiptNumber, collection.monthlyPayment, collection.balance);

            receiptPaymentList.push(receiptPayment);

          });


          // add each taskCollection into list
          let taskCollection = new TaskCollection(json.id, json.name, json.icNumber, receiptPaymentList);
          this.taskCollectionList.push(taskCollection);

        }
        );


      }
    };

  }


  getTotalMonthlyPayment(taskCollection: TaskCollection) {

    var sumOfMonthly: number = 0;
    for (let i = 0; i < taskCollection.LoanCollection.length; i++) {
      sumOfMonthly += taskCollection.LoanCollection[i].MonthlyPayment;
    }
    return sumOfMonthly;
  }

  getTotalBalance(taskCollection: TaskCollection) {

    var sumOfBalance: number = 0;
    for (let i = 0; i < taskCollection.LoanCollection.length; i++) {
      sumOfBalance += taskCollection.LoanCollection[i].Balance;
    }
    return sumOfBalance;
  }

  isNotSchemeA(receiptNumber: string) {

    if (receiptNumber.includes('A'))
      return false;
    else
      return true;

  }

  
  reset() {
    this.activeModal.close();
  }
}
