import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { SharedService } from 'src/app/Shared/shared-service.service';


interface Report {
  customerName: string,
  customerIC: string,
  epfDate: Date,
  customerID: number
}
@Component({
  selector: 'app-overdue-epf-report',
  templateUrl: './overdue-epf-report.component.html',
  styleUrls: ['./overdue-epf-report.component.css']
})
export class OverdueEpfReportComponent implements OnInit {

  reportList: Report[] = new Array;
  @Input() selectedCompany: number;
  @Input() reportName: string;
  routeSubscription: any;

  isCollapsed: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, public sharedService: SharedService, private restApi: RestApiService, private constructApi: ConstructAPI) { }

  ngOnInit(): void {

    this.routeSubscription = this.activatedRoute.paramMap.subscribe(params => {
      this.selectedCompany = + params.get('companyId');

      this.getCompanyOverdueEPF();
    });
  }


  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }

  goToCustomer(customerID: number) {
    this.router.navigate(['/manageCustomer', customerID]);
  }


  getCompanyOverdueEPF() {
    this.reportList.splice(0, this.reportList.length);
    var xhttp = this.restApi.getRequest(this.constructApi.getCompanyOverdueEPFCustomer(this.selectedCompany));

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          var report = {
            customerID: json.id, customerName: json.name, customerIC: json.icNumber, epfDate: json.epfDate
          };
          this.reportList.push(report);
        }
        );
      }
    };
  }

}
