import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Customer } from 'src/model/customer.model';
import { EPF } from 'src/model/epf.model';
import { Loan } from 'src/model/loan.model';

@Component({
  selector: 'app-mark-legacy-epfs',
  templateUrl: './mark-legacy-epfs.component.html',
  styleUrls: ['./mark-legacy-epfs.component.css'],
  providers: [DatePipe]
})
export class MarkLegacyEpfsComponent implements OnInit {


  @Input() epfDetails: EPF;
  @Input() loanList: Loan[] = new Array;
  @Input() selectedCustomer: Customer;
  @Input() selectedProfileId: number;

  displayedColumns: string[];
  dataSource = new MatTableDataSource;
  disabledSubmit = false;
  checkedAll = false;

  epfDate: Date;
  epfAmount: number;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(private activeModal: NgbActiveModal, public sharedService: SharedService, private enums: Enums, private datepipe: DatePipe, private restApi: RestApiService, private constructApi: ConstructAPI) { }

  ngOnInit(): void {

    this.displayedColumns = ['Check', 'DateCreated', 'ReceiptNumber', 'LoanCode', 'Principal', 'Scheme', 'MonthlyPayment'];

    if (this.loanList != undefined) {
      this.dataSource = new MatTableDataSource<Loan>(this.loanList);
      this.dataSource.sort = this.sort;

    }

    if (this.epfDetails == undefined)
      this.epfDetails = new EPF(undefined, undefined, undefined, undefined, undefined, undefined, undefined);
    else {
      this.epfDate = this.epfDetails.Date;
      this.epfAmount = this.epfDetails.Amount;
    }
  }

  checkIsNull() {
    var error = "";

    if (this.epfDate == undefined)
      error += "- EPF Date\n";

    if (this.epfAmount == undefined || this.epfAmount == 0)
      error += "- EPF Amount\n";
    if (this.calculateSelected() == 0)
      error += "- Select loan to mark as EPF"

    if (error != "")
      error = "Please fill in correctly:\n" + error;
    return error;
  }


  calculateSelected() {
    var sum = 0;

    for (let i = 0; i < this.loanList.length; i++) {
      if (this.loanList[i].IsSelected == true)
        sum++
    }

    return sum
  }

  submit() {
    var error = this.checkIsNull();
    if (error != "")
      this.sharedService.openAlert(error, this.enums.DANGER_ALERT);
    else {
      if (this.epfDetails.Date == undefined) {
        this.updateEPFDetails();
      }
      else {
        this.markAsEPFLoan();
      }
    }

  }


  updateEPFDetails() {
    this.disabledSubmit = true;

    var data;
    var epfStr = this.datepipe.transform(this.epfDate, 'yyyy-MM-dd');
    data = {
      "date": epfStr,
      "amount": this.epfAmount
    }
    var xhr = (this.epfDetails == undefined || this.epfDetails.Date == undefined) ? this.restApi.postRequest(this.constructApi.getUpdateEPFDetails(this.selectedCustomer.ID), data) : this.restApi.putRequest(this.constructApi.getUpdateEPFDetails(this.selectedCustomer.ID), data);
    // var xhr = this.restApi.postRequest(this.constructApi.getUpdateEPFDetails(this.selectedCustomer.ID), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        //if success , then proceed
        if (xhr.status == 200) {
          this.disabledSubmit = false;
          this.markAsEPFLoan();
        }
        else {
          this.disabledSubmit = false;
          //show error message
          this.sharedService.openAlert(JSON.parse(xhr.responseText).error, this.enums.DANGER_ALERT)

        }
      }
    }
  }


  consructLoanId() {
    var selectedIds: Number[] = new Array;

    for (let i = 0; i < this.loanList.length; i++) {
      if (this.loanList[i].IsSelected == true)
        selectedIds.push(this.loanList[i].ID)
    }

    return selectedIds;
  }

  markAsEPFLoan() {
    this.disabledSubmit = true;

    var data = this.consructLoanId();

    var xhr = this.restApi.putRequest(this.constructApi.getMarkLegacyEPF(), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        //if success , then proceed
        if (xhr.status == 200) {
          window.location.reload();
        }
        else {
          this.disabledSubmit = false;
          //show error message
          this.sharedService.openAlert(JSON.parse(xhr.responseText).error, this.enums.DANGER_ALERT)

        }
      }
    }
  }

  isCheckedAll() {
    for (let i = 0; i < this.loanList.length; i++) {
      if (this.loanList[i].IsSelected == false)
        return false;
    }
    return true;
  }

  checkAll() {

    if (this.checkedAll == true) {
      for (let i = 0; i < this.loanList.length; i++) {
        this.loanList[i].IsSelected = true;
      }
    }
    else {
      for (let i = 0; i < this.loanList.length; i++) {
        this.loanList[i].IsSelected = false;
      }
    }
  }

  reset() {
    this.activeModal.close();
  }

}
