import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { AlertDialogComponent } from 'src/app/Shared/alert-dialog/alert-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Task } from 'src/model/task.model';

@Component({
  selector: 'app-search-task',
  templateUrl: './search-task.component.html',
  styleUrls: ['./search-task.component.css']
})
export class SearchTaskComponent implements OnInit {
  taskList: Task[] = new Array;

  displayedColumns: string[];
  dataSource = new MatTableDataSource;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input() isGlobal: boolean;

  searchICNum: string;
  searchName: string;
  searchDone: boolean;
  constructor(private modalService: NgbModal, private router: Router, private constructApi: ConstructAPI, 
    private restApi: RestApiService, private activeModal: NgbActiveModal,private sharedService:SharedService,private enums:Enums) { }

  ngOnInit(): void {
    this.displayedColumns = ['Date', 'CustomerName', 'Description'];
  }


  findTask(name: string, ic: string, done: boolean) {

    var isNull = this.checkCriteriaIsNull();

    if (isNull == false) {

      this.taskList.splice(0, this.taskList.length);

      var xhr = this.restApi.getRequest(this.constructApi.getSearchTaskByCriteria(undefined, this.searchDone, undefined, this.searchName, this.searchICNum));

      xhr.onreadystatechange = () => {
        if (xhr.readyState == 4) {

          if (xhr.status == 200) {
            var jsonArray = JSON.parse((xhr.responseText));
            // console.log(jsonArray);

            jsonArray.forEach(json => {

              let task = new Task(json.id, json.customerId, json.description, json.followUpRemark, json.date, json.customerName, json.assignee, json.dateDone)

              this.taskList.push(task);
            }
            );

            //make task list as the datasource
            this.dataSource = new MatTableDataSource<Task>(this.taskList);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
          else {
            this.sharedService.openAlert("Error! " + JSON.parse((xhr.responseText)).error,this.enums.DANGER_ALERT)
          }

        }
      }
    } else
      this.sharedService.openAlert("Please at least fill in 1 criteria",this.enums.DANGER_ALERT);

  }

  goToTask(taskID: number, customerId: number) {
    this.router.navigate(['/taskDetails', taskID, customerId]);
    this.reset();
  }

  checkCriteriaIsNull() {
    if ((this.searchICNum == undefined || this.searchICNum == '') && (this.searchName == undefined || this.searchName == ''))
      return true;

    else return false;

  }

  addTaskStatusStyle(dateDone: Date) {

    if (dateDone != undefined)
      return 'text-success'
    else
      return 'text-danger';

  }
  reset() {
    this.activeModal.close();
    this.searchICNum = "";
    this.searchName = "";
  }



}
