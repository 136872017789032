<div class="container-fluid p-5">
    <div class="row d-flex align-items-end justify-content-end" [hidden]="sharedService.isStaff()">
        <div class="col-5 col-md-3 col-lg-2">
           <!--here-->
           <app-add-other-transactions-menu [companyBankAccountList]="companyBankAccountList" [cashAccountList]="cashAccountList"></app-add-other-transactions-menu>
        </div>
        <div class="col-6 col-md-3 col-lg-2" *ngIf="sharedService.isBranchManager()">
            <button class="btn btn-primary" (click)="openAddAccountDialog()"><i class="fas fa-plus"></i> Add
                Account</button>
        </div>
    </div>

    <div [hidden]="sharedService.isStaff()">
        <h3><strong>Duit Lebih</strong> </h3>
        <h4 style="color:blueviolet"><strong>Balance : {{duitLebihBalance||0}}</strong></h4>
        <hr />
    </div>

    <div [hidden]="sharedService.isStaff()">
        <h3>Company Account</h3>

        <div *ngIf="companyBankAccountList.length==0; else bankAccountCards">
            <p>Currently no bank account for this company</p>
        </div>

        <ng-template #bankAccountCards>
            <div class="row">
                <div class="col-12 col-md-4" *ngFor="let bankAccount of companyBankAccountList">
                    <div class="card  companyAccount-card m-1" (click)="openTransactionHistoryModal(bankAccount.ID)">
                        <div class="card-header"> <strong>{{bankAccount.Name}}</strong></div>
                        <div class="card-body companyAccount-card-body">
                            <p> Number: {{bankAccount.AccountNumber}}</p>
                            <p> Holder: {{bankAccount.HolderName}}</p>
                            <p>Balance : {{bankAccount.Balance||0 | number:'1.2-2'}} </p>
                        </div>
                    </div>
                </div>
            </div>

        </ng-template>

        <hr />
    </div>
    <h3>Cash Account</h3>

    <div *ngIf="cashAccountList.length==0; else cashAccountCards">
        <p>Currently no cash account for this company</p>
    </div>

    <ng-template #cashAccountCards>

        <div class="row">
            <div class="col-12 col-md-4" *ngFor="let cashAccount of cashAccountList">
                <div class="card m-1  cashAccount-card" (click)="openTransactionHistoryModal(cashAccount.ID)">
                    <div class="card-header"> <strong>{{cashAccount.Name}}</strong></div>
                    <div class="card-body cashAccount-card-body">
                        <p> Username: {{cashAccount.Username}}</p>
                        <p>Balance : {{cashAccount.Balance || 0| number:'1.2-2'}}</p>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>


</div>