import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { AdminRemark } from 'src/model/customerAdminRemark';

@Component({
  selector: 'app-add-admin-remark',
  templateUrl: './add-admin-remark.component.html',
  styleUrls: ['./add-admin-remark.component.css']
})
export class AddAdminRemarkComponent implements OnInit {

  constructor(private activeModal: NgbActiveModal, private sharedService: SharedService, private restapi: RestApiService, private constructApi: ConstructAPI, private enums: Enums) { }
  adminRemark: string;
  disabledSubmit: boolean = false;


  needAttention: boolean = false;
  @Input() viewCustomerId: number;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  ngOnInit(): void {
  }



  submit() {

    (this.adminRemark == undefined || this.adminRemark == "")
      ? this.sharedService.openAlert('Please fill in all the fields correctly!', this.enums.DANGER_ALERT)
      : this.addRemark();
  }

  addRemark() {
    var data = {
      "remark": this.adminRemark,
      "date": new Date().toISOString(),
      "needAttention": this.needAttention
    };
    var xhr = this.restapi.postRequest(this.constructApi.getAddAdminRemark(this.viewCustomerId), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        var json = JSON.parse(xhr.responseText);

        var remark = new AdminRemark(json.id, json.author, json.customerId, json.date, json.remark,json.needAttention)
        this.passEntry.emit(remark)
        this.reset();
        this.sharedService.openAlert("Successfully added remark!", this.enums.SUCCESS_ALERT);

      }
    }
  }

  reset() {
    this.adminRemark = "";
    this.activeModal.close();
  }
}
