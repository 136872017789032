export class SettlementCharges {


    constructor(private _serviceCharge: number, private _stamp: number, private _extras: number, private _handlingCharge?: number) { }


    public get Extras(): number {
        return this._extras;
    }
    public set Extras(value: number) {
        this._extras = value;
    }
    public get Stamp(): number {
        return this._stamp;
    }
    public set Stamp(value: number) {
        this._stamp = value;
    }
    public get ServiceCharge(): number {
        return this._serviceCharge;
    }
    public set ServiceCharge(value: number) {
        this._serviceCharge = value;
    }

    public get HandlingCharge(): number {
        return this._handlingCharge;
    }
    public set HandlingCharge(value: number) {
        this._handlingCharge = value;
    }


}