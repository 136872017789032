<div class="container-fluid p-3">
    <div class="d-flex justify-content-end align-items-end">
        <button class="btn btn-info" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseExample">
            <i class="fas fa-chevron-circle-down" *ngIf="isCollapsed"></i>
            <i class="fas fa-chevron-circle-up" *ngIf="isCollapsed==false"></i>
        </button>
    </div>



    <div class="collapse" id="collapseExample" [ngbCollapse]="isCollapsed">
        <app-report-generator [selectedCompany]="selectedCompany"></app-report-generator>
    </div>
    <h2> Daily Report ( {{startDate |date:'yyyy-MM-dd'}} )
    </h2>

    <p [hidden]="reportList.length>0 || loanReportList.length>0"> Do not have any record within this date range</p>
    <div class="card shadow-lg" [hidden]="loanReportList.length==0">
        <h3 class=" p-3 fw-bold">Loan Transactions</h3>
        <div class="card-body">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>No.</th>
                        <th>Customer</th>
                        <th>Code</th>
                        <th>Collected Amount</th>
                        <th>Cashbook Related Amount</th>
                        <th>Stamp + SC</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let transaction of loanReportList;let i=index">
                        <td>{{i+1}}</td>
                        <td class="customerCol"
                            (click)="goToCustomerProfile(transaction.customerId,transaction.profileId)">
                            <i class="fas fa-wallet" style="cursor: pointer;"
                                [ngClass]="getPositiveNegative(transaction.extrasAmount)"
                                *ngIf="transaction.containsExtra==true && sharedService.isBranchAccountHandler()==false"
                                placement="top" [ngbTooltip]="tooltipContent"></i>
                            {{transaction.customerName}}

                            <ng-template #tooltipContent>
                                <div>
                                    <p class="m-0">Duit Lebih:</p>
                                    <span>RM {{transaction.extrasAmount |number:'1.0-2'}}</span>
                                </div>

                            </ng-template>
                        </td>
                        <td class="customerCol"
                            (click)="goToCustomerProfile(transaction.customerId,transaction.profileId)">
                            {{transaction.customerCode}}
                        </td>
                        <td class="amountCol fw-bold" [ngClass]="getPositiveNegative(transaction.amount)"
                            (click)="goToProfileTransaction(transaction.profileId)">RM
                            {{transaction.amount|number:'1.0-2'}}
                            <i class="fas fa-funnel-dollar" style="cursor: pointer;"
                                *ngIf="transaction.nonCashbookRelatedTransactions.length>0 && sharedService.isBranchAccountHandler()==false"
                                placement="top" [ngbTooltip]="transactionsTooltipContent"></i>

                            <ng-template #transactionsTooltipContent>
                                <div>
                                    <table>
                                        <tbody>
                                            <tr *ngFor="let transaction of transaction.nonCashbookRelatedTransactions">
                                                <td>{{ sharedService.roundTo2Dec(transaction.Amount)}}</td>
                                                <td>{{enums.transactionTypeString[transaction.Type-1]}}</td>
                                            </tr>
                                            <tr>
                                                <th>Total:</th>
                                                <th>{{calculateTotalNonRelatedTransactions(i)}}</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </ng-template>


                        </td>
                        <td class="amountCol fw-bold"
                            [ngClass]="getPositiveNegative(transaction.cashbookRelatedAmount)"
                            (click)="goToProfileTransaction(transaction.profileId)">RM
                            {{transaction.cashbookRelatedAmount|number:'1.0-2'}}</td>
                        <td class="amountCol fw-bold" (click)="goToProfileTransaction(transaction.profileId)">
                            {{transaction.chargesAmount|number:'1.0-2'}}
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <th style="border-top:2px solid black;border-bottom:2px solid black"></th>
                        <th style="border-top:2px solid black;border-bottom:2px solid black"></th>
                        <th style="border-top:2px solid black;border-bottom:2px solid black">Total:</th>
                        <th style="border-top:2px solid black;border-bottom:2px solid black">RM
                            {{calculateTotal()|number: '1.2-2'}}</th>
                        <th style="border-top:2px solid black;border-bottom:2px solid black">RM
                            {{calculateTotalCashbookRelatedAmount()|number: '1.2-2'}}</th>
                        <th style="border-top:2px solid black;border-bottom:2px solid black">RM
                            {{calculateTotalCharges()|number: '1.2-2'}}</th>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
    <div class="mt-2 me-5">
        <!--here-->
        <app-add-other-transactions-menu [companyBankAccountList]="companyBankAccountList"
            [cashAccountList]="cashAccountList" *ngIf="sharedService.isBranchAccountHandler()==true">
        </app-add-other-transactions-menu>
    </div>
    <div class="card shadow-lg mt-3" [hidden]="reportList.length==0">
        <h3 class=" p-3 fw-bold">Other Transactions</h3>
        <div class="card-body">

            <table class="table table-striped">
                <thead>
                    <tr>
                        <th
                            *ngIf="this.sharedService.isBranchAccountHandler()==true || sharedService.isBossAccount()==true">
                        </th>
                        <th>No.</th>
                        <th>Remark</th>
                        <th>Type</th>
                        <th>Amount</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let transaction of reportList;let i =index">
                        <td
                            *ngIf="this.sharedService.isBranchAccountHandler()==true || sharedService.isBossAccount()==true">
                            <button class="btn btn-danger" (click)="openDeleteTransactionDialog(transaction)"
                                *ngIf="transaction.setId==undefined">
                                <i class="fas fa-trash-alt"></i>
                            </button>
                        </td>
                        <td>{{i+1}}</td>
                        <td>
                            <span *ngIf="transaction.setId!=undefined && (transaction.type == enums.EXPENSE
                                || transaction.type == enums.EXTRAS || transaction.type == enums.ADVANCE)"
                                class="text-info" style="cursor: pointer;" (click)="openSetDialog(transaction.setId)"><i
                                    class="fas fa-file-invoice-dollar"></i></span>
                            {{transaction.remark}}
                        </td>
                        <td> {{enums.transactionTypeString[transaction.type-1]}}</td>
                        <td class="fw-bold" [ngClass]="getPositiveNegative(transaction.amount)">RM
                            {{transaction.amount|number:'1.0-2'}}</td>
                        <td>
                            <button class="btn btn-dark" *ngIf="transaction.setId==undefined  && (transaction.type == enums.EXPENSE
                                || transaction.type == enums.EXTRAS || transaction.type == enums.ADVANCE)">
                                <i class="fas fa-file-medical"
                                    (click)="addThisTransactionIntoNewSet(transaction)"></i></button>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <th *ngIf="this.sharedService.isBranchAccountHandler()==true || sharedService.isBossAccount()==true"
                            style="border-top:2px solid black;border-bottom:2px solid black">
                        </th>
                        <th style="border-top:2px solid black;border-bottom:2px solid black"></th>
                        <th style=" border-top:2px solid black;border-bottom:2px solid black"></th>
                        <th style="border-top:2px solid black;border-bottom:2px solid black">Total:</th>
                        <th style="border-top:2px solid black;border-bottom:2px solid black">RM
                            {{calculateTotalOtherTransaction()| number: '1.2-2'}}</th>
                        <th style="border-top:2px solid black;border-bottom:2px solid black"></th>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>

    <div class="card mt-3 shadow-lg" [hidden]="reportList.length==0 && loanReportList.length==0">
        <div class="card-body">
            <div class="row">
                <div class="col-12">
                    <h5 class="fw-bold text-info">Last Balance : RM {{totalCashBalance| number: '1.2-2'}}</h5>
                </div>
                <div class="col-12 col-md-6">
                    <h5 class="fw-bold text-primary">Today's total : RM {{calculateTotal() +
                        calculateTotalOtherTransaction()| number:
                        '1.2-2'}}
                    </h5>
                </div>
                <div class="col-12 col-md-6">
                    <h5 class="fw-bold text-success">New Balance : RM {{totalCashBalance + calculateTotal() +
                        calculateTotalOtherTransaction()|
                        number:
                        '1.2-2'}}
                    </h5>
                </div>
            </div>



        </div>
    </div>

</div>