import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Contact } from 'src/model/contact.model';
import { DuitLebih } from 'src/model/duitLebih.model';
import { Loan } from 'src/model/loan.model';
import { LoanRequest } from 'src/model/loanRequest.model';
import { SettlementCharges } from 'src/model/settlementCharges.model';

@Component({
  selector: 'app-search-ticket',
  templateUrl: './search-ticket.component.html',
  styleUrls: ['./search-ticket.component.css']
})
export class SearchTicketComponent implements OnInit {

  ticketNumber: number;
  disabledSubmit = false;

  loanRequest: LoanRequest;
  constructor(private activeModal: NgbActiveModal, public sharedService: SharedService, public enums: Enums, private restApi: RestApiService, private constructApi: ConstructAPI) { }

  ngOnInit(): void {

  }


  detectEnableSearch() {
    if (this.ticketNumber == undefined)
      this.disabledSubmit = true;
    else
      this.disabledSubmit = false;

    return this.disabledSubmit;


  }


  processDuitLebihJsonArr(jsonArray: any) {
    var duitLebihList: DuitLebih[] = new Array;
    jsonArray.forEach(json => {
      var extra = new DuitLebih(json.amount, json.subtype)
      duitLebihList.push(extra);
    });

    return duitLebihList;
  }


  processGuarantorJsonArr(jsonArray: any) {
    var guarantorsInfo: Contact[] = new Array;
    jsonArray.forEach(json => {
      var guarantors = new Contact(json.id, json.name, json.icNumber, json.number, json.address, json.customerId, json.relation, json.remark)
      guarantorsInfo.push(guarantors);



    });

    guarantorsInfo.sort((a, b) => (a.Name > b.Name) ? -1 : 1);
    return guarantorsInfo;
  }

  searchTicket() {
    this.loanRequest = undefined;
    if (this.ticketNumber == undefined)
      this.sharedService.openAlert("Please enter ticket number!", this.enums.DANGER_ALERT);
    else {
      var xhttp = this.restApi.getRequest(this.constructApi.getLoanApprovalRequestById(this.ticketNumber));
      xhttp.onreadystatechange = () => {
        if (xhttp.readyState == 4) {

          if (xhttp.status == 200) {
            var json = JSON.parse((xhttp.responseText));


            var loanInfoJson = json.loanInfo

            var stampAmount = (loanInfoJson.stamp == undefined) ? 0 : loanInfoJson.stamp;
            var serviceChargeAmount = (loanInfoJson.serviceCharge == undefined) ? 0 : loanInfoJson.serviceCharge;
            var reserve = (loanInfoJson.reserve == undefined) ? 0 : loanInfoJson.reserve;

            var extras = (loanInfoJson.extras == undefined) ? undefined : this.processDuitLebihJsonArr(loanInfoJson.extras);

            var settleStamp = (loanInfoJson.settlementInfo == undefined || loanInfoJson.settlementInfo.stamp == undefined) ? 0 : loanInfoJson.settlementInfo.stamp;
            var settleSc = (loanInfoJson.settlementInfo == undefined || loanInfoJson.settlementInfo.serviceCharge == undefined) ? 0 : loanInfoJson.settlementInfo.serviceCharge;
            var settleExtra = (loanInfoJson.settlementInfo == undefined || loanInfoJson.settlementInfo.extras == undefined) ? 0 : loanInfoJson.settlementInfo.extras;

            var settlementCharges = (loanInfoJson.settlementCharges == undefined) ? undefined : new SettlementCharges(loanInfoJson.settlementCharges.serviceCharge, loanInfoJson.settlementCharges.stamp, loanInfoJson.settlementCharges.extras, loanInfoJson.settlementCharges.handlingCharge);


            var loanInfo = new Loan(undefined, loanInfoJson.profileId, loanInfoJson.principal, stampAmount,
              undefined, undefined, undefined, undefined, undefined, loanInfoJson.scheme, loanInfoJson.interestRate, loanInfoJson.term,
              undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined,
              reserve, undefined, undefined, undefined, serviceChargeAmount, extras, undefined, undefined,
              undefined, loanInfoJson.processingRate, undefined, undefined, undefined,
              settleStamp, settleSc, settleExtra, undefined, undefined, undefined, undefined, undefined, undefined, settlementCharges);



            if (Array(json.guarantors).length > 0)
              var guarantorsInfo = this.processGuarantorJsonArr(json.guarantors);


            this.loanRequest = new LoanRequest(json.customerId, json.epfAmount, json.epfDate,
              guarantorsInfo, json.hasIAccount, json.id, loanInfo, json.processedRemark,
              json.profileId, json.remark, json.requestBy, json.requestOn, json.updatedOn,
              json.approved, json.loanId, json.companyCode, json.icNumber, json.name, json.companyId, undefined, json.processedOn, json.authorizedBy, undefined, undefined, undefined, undefined, json.dob, json.otherId)


            // console.log(this.loanRequest.Guarantors)


          }
          else {
            this.sharedService.openErrorMessage(xhttp);
          }


        }
      };
    }

  }


  reset() {
    this.activeModal.close();
  }

}
