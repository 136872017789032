import { Component, Input, OnInit } from '@angular/core';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Loan } from 'src/model/loan.model';
import { LoanRequest } from 'src/model/loanRequest.model';

@Component({
  selector: 'app-previous-approved-request-card',
  templateUrl: './previous-approved-request-card.component.html',
  styleUrls: ['./previous-approved-request-card.component.css']
})
export class PreviousApprovedRequestCardComponent implements OnInit {

  // @Input() request: LoanRequest
  @Input() loan: Loan
  @Input() epfAmount: number


  constructor(private restApi: RestApiService, private constructApi: ConstructAPI, private sharedService: SharedService) { }

  ngOnInit(): void {
  }



  calculateSettlement() {
    var settlement = 0;
    if (this.loan.Scheme == 'EPF') {
      settlement = this.sharedService.calculateEPFSettlementAmountWithCharges(this.loan);
    }
    else
      settlement = this.loan.LegacySettlement;
    return settlement;

  }



  calculateRatio() {
    var settlement = this.calculateSettlement();
    var ratio = (settlement / this.epfAmount) * 100;
    return ratio;

  }
}
