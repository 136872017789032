import { DatePipe } from '@angular/common';
import { Input, OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { report } from 'process';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { TransactionSubType } from 'src/model/transactionSubType.model';

interface Report {
  id: number,
  date: Date,
  remark: string,
  amount: number,
  customerName: string,
  customerId: number,
  receiptNumber?: string,
  subtype: number,
  profileId: number
}
@Component({
  selector: 'app-duit-lebih-report',
  templateUrl: './duit-lebih-report.component.html',
  styleUrls: ['./duit-lebih-report.component.css'],
  providers: [DatePipe]
})
export class DuitLebihReportComponent implements OnInit, OnDestroy {
  @Input() selectedCompany: number;
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() reportName: string;

  reportList: Report[] = new Array;
  wholeReportList: Report[] = new Array;
  transactionBalance: number = 0;

  isCollapsed: boolean = false;
  selectedSubtype: number;

  routeSubscription: any;

  isBranchManager: boolean;
  transactionSubtypeList: TransactionSubType[] = new Array;

  constructor(private modalService: NgbModal, public enums: Enums, private activatedRoute: ActivatedRoute, private datePipe: DatePipe, private router: Router, private restapi: RestApiService, private constructApi: ConstructAPI) { }

  ngOnInit(): void {
    this.routeSubscription = this.activatedRoute.paramMap.subscribe(params => {
      this.selectedCompany = + params.get('companyId');
      this.startDate = (params.get('startDate') == undefined) ? undefined : new Date(params.get('startDate'));
      this.endDate = (params.get('endDate') == undefined) ? undefined : new Date(params.get('endDate'));

      if (this.startDate != undefined)
        this.getTransactionBalance(this.enums.EXTRAS)
      this.getAllTransactionSubtype();
      this.getLoanRelatedExtras(this.enums.EXTRAS);
    });
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }

  filterBySubtype() {

    if (this.selectedSubtype != null)
      this.reportList = this.wholeReportList.filter(report => report.subtype == this.selectedSubtype);
    else
      this.reportList = this.wholeReportList;
  }

  getLoanRelatedExtras(types: number) {
    this.reportList.splice(0, this.reportList.length);

    var startDateStr = (this.startDate == undefined) ? undefined : this.startDate.toISOString();

    if (this.endDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var addADayDate = new Date(Number(this.endDate) + (3600 * 1000 * 24) - 1);


    var endDateStr = (this.endDate == undefined) ? undefined : addADayDate.toISOString();

    var xhttp = this.restapi.getRequest(this.constructApi.getCompanyAllLoansTransactions(this.selectedCompany, startDateStr, endDateStr, undefined, [types]));


    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          var report = {
            id: json.id, date: json.date, remark: json.remark, amount: json.amount, customerName: json.customerName,
            customerId: json.customerId, receiptNumber: json.receiptNumber, assignee: json.assignee, rate: json.rate, principal: json.principal, subtype: json.subtype, profileId: json.profileId
          };
          this.reportList.push(report);
        }
        );
        this.getNonLoanRelatedExtras(types);
      }
    };
  }

  goToCustomer(customerId: number, profileId: number) {
    this.router.navigate(['/manageCustomer', customerId, profileId]);
  }
  getNonLoanRelatedExtras(types: number) {
    var startDateStr = (this.startDate == undefined) ? undefined : this.startDate.toISOString();

    if (this.endDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var addADayDate = new Date(Number(this.endDate) + (3600 * 1000 * 24) - 1);


    var endDateStr = (this.endDate == undefined) ? undefined : addADayDate.toISOString();

    var xhttp = this.restapi.getRequest(this.constructApi.getAllTransactions(this.selectedCompany, [types], startDateStr, endDateStr,));


    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          var report = {
            id: json.id, date: json.date, remark: json.remark, amount: json.amount, customerName: json.customerName,
            customerId: json.customerId, receiptNumber: json.receiptNumber, assignee: json.assignee, rate: json.rate, principal: json.principal, subtype: json.subtype, profileId: json.profileId
          };

          var index = this.reportList.findIndex(transaction => transaction.id === report.id);
          if (index == -1)
            this.reportList.push(report);
        }
        );

        //sort list
        this.reportList.sort((a, b) => (new Date(a.date) > new Date(b.date)) ? 1 : -1);
        this.wholeReportList = this.reportList;
      }
    };
  }

  private getAllTransactionSubtype() {
    var xhttp = this.restapi.getRequest(this.constructApi.getTransactionSubTypes(this.enums.EXTRAS));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          let type = new TransactionSubType(json.id, json.name, json.type, json.assignable, json.enabled)

          this.transactionSubtypeList.push(type);
        }
        );
      }
    };
  }

  getStyle(reportAmount: number) {
    if (reportAmount < 0)
      return "text-danger";
    else
      return "text-success";
  }

  getSubTypeName(subTypeId: number) {
    var index = this.transactionSubtypeList.findIndex(type => type.ID === subTypeId);
    return this.transactionSubtypeList[index].Name;
  }

  getTransactionBalance(type: number) {
    this.transactionBalance = 0;
    if (this.startDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var reduceASecDate = new Date(Number(this.startDate) - 1);
    var startDateStr = (this.startDate == undefined) ? undefined : reduceASecDate.toISOString();

    var xhttp = this.restapi.getRequest(this.constructApi.getCompanyTransactionBalances(this.selectedCompany, startDateStr, type));

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          this.transactionBalance = json.balance;
        }
        );

      }
    };

  }

  calculateTotal() {
    var total = 0;
    for (let i = 0; i < this.reportList.length; i++) {
      total += this.reportList[i].amount;
    }

    return total
  }

  calculateInTotal() {
    var totalIn = 0;
    for (let i = 0; i < this.reportList.length; i++) {
      if (this.reportList[i].amount > 0)
        totalIn += this.reportList[i].amount;
    }

    return totalIn
  }

  calculateOutTotal() {
    var totalOut = 0;
    for (let i = 0; i < this.reportList.length; i++) {
      if (this.reportList[i].amount < 0)
        totalOut += this.reportList[i].amount;
    }

    return totalOut
  }

  sortByCriteria(criteria: string) {
    switch (criteria) {
      case 'Receipt':
        this.reportList.sort((a, b) => (a.receiptNumber < b.receiptNumber ? -1 : 1));
        break;
      case 'Date':
        this.reportList.sort((a, b) => (a.date < b.date ? -1 : 1));
        break;
      case 'CustomerName':
        this.reportList.sort((a, b) => (a.customerName < b.customerName ? -1 : 1));
        break;

      case 'Subtype':
        this.reportList.sort((a, b) => (Number(a.subtype) < Number(b.subtype) ? -1 : 1));
        break;

      case 'Amount':
        this.reportList.sort((a, b) => (a.amount < b.amount ? -1 : 1));
        break;

      case 'Remark':
        this.reportList.sort((a, b) => (a.remark < b.remark ? -1 : 1));
        break;


    }

  }

}
