<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Add Loan Into Account</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 aria-label="Close" aria-label="Close"
            (click)="closeModal()"></button>
    </div>

    <div class="modal-body">
        <div class="row mb-2">
            <div class="col d-flex justify-content-end align-items-end">
                <button class="btn btn-warning ms-1" (click)="openUploadSupportingDocModal()"> <i
                        class="fas fa-file-alt"></i> Add Supporting Doc</button>
                <hr />
            </div>
        </div>
        <div class="card mb-2">
            <div class="card-header"><b>Loan Summary</b></div>
            <div class="card-body">
                <div class="row">
                    <div class="col-4">
                        Loan Amount : RM {{approvedLoan.Principal|number:'1.0-0'}}
                    </div>
                    <div class="col-4" *ngIf="approvedLoan.Scheme!='HUTANG'">
                        Repayment : RM {{settlement|number:'1.0-2'}}
                    </div>
                    <div class="col-4" *ngIf="approvedLoan.Scheme!='HUTANG'">
                        Term : {{approvedLoan.Term|number:'1.0-0'}} months
                    </div>
                </div>
                <hr />
                <ng-container *ngIf="approvedLoan.Scheme!='HUTANG'">
                    <div class="row">
                        <div class="col col-4">
                            Stamp :RM{{ approvedLoan.StampAmount||0|number:'1.0-0'}}
                        </div>
                        <div class="col col-4">
                            S/C :RM{{ approvedLoan.ServiceCharge||0|number:'1.0-0'}}
                        </div>
                        <div class="col col-4">
                            Duit Lebih :RM{{ calculateTotalDuitLebih()||0}}
                        </div>

                        <div class="col col-4">
                            <span>
                                <b>Interest Rate: {{approvedLoan.InterestRate ||0|number:'1.2-2'}}%</b>
                            </span>
                        </div>

                        <div class="col col-4">
                            <span><b>Settlement Ratio:
                                    {{this.ratio||0 |number:'1.3-3'}}% </b>
                            </span>
                        </div>
                        <div class="col col-4">
                            <span><b>Total Settlement:
                                    RM {{settlementWithCharges|number:'1.0-2'}}</b>
                            </span>
                        </div>
                        <div class="col col-12">
                            <h3 class="mt-2" style="font-weight: bold;color: brown;background-color: yellow;"> Total
                                On Hand: RM
                                {{totalOnHand ||0}} </h3>
                        </div>
                    </div>
                </ng-container>

            </div>
        </div>
        <hr />

        <div class="row">

            <div class="col-12">
                <div>
                    <span>Loan Date</span><i class="far fa-calendar-alt ms-2"></i>
                </div>
                <mat-form-field class="full-width-formField p-0">
                    <input matInput readonly [matDatepicker]="loanDatePicker" name="loanDate" [(ngModel)]="loanDate" required
                        [max]="sharedService.getTodayDate()" onkeydown="return false">
                    <mat-datepicker-toggle matSuffix [for]="loanDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #loanDatePicker name="loanDate"></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="col-12">
                <h5>Issued by account:</h5>
            </div>
            <div class="col-12">
                <select class="browser-default form-select" name="selectedAccount" [(ngModel)]="selectedAccountId">
                    <option *ngFor="let account of companyAccountList" [value]="account.ID"
                        [selected]="setSelectedAccount(account.ID)" [disabled]="isBalanceInsufficient(account.Balance)">
                        {{account.Name}} (RM
                        {{account.Balance || 0}} )
                    </option>
                </select>
            </div>


        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="submit()" [disabled]="disabledSubmit">Add
            Loan</button>
        <button class="btn btn-outline-danger" type="button" (click)="closeModal()">Cancel</button>
    </div>

</form>