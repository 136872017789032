import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddContactComponent } from 'src/app/AddThings/add-contact/add-contact.component';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { ShowWhatsappMessageClipboardComponent } from 'src/app/Shared/show-whatsapp-message-clipboard/show-whatsapp-message-clipboard.component';
import { Contact } from 'src/model/contact.model';
import { LoanRequest } from 'src/model/loanRequest.model';

@Component({
  selector: 'app-add-loan-request-guarantor',
  templateUrl: './add-loan-request-guarantor.component.html',
  styleUrls: ['./add-loan-request-guarantor.component.css']
})
export class AddLoanRequestGuarantorComponent implements OnInit {

  contactList: Contact[] = new Array;
  @Input() request: LoanRequest;

  disabledSubmit = false;
  constructor(private restApi: RestApiService, private constructApi: ConstructAPI, private activeModal: NgbActiveModal,
    private sharedService: SharedService, private cdr: ChangeDetectorRef, private enums: Enums) { }

  ngOnInit(): void {
    this.getContactInfo();
  }


  getContactInfo() {
    var xhttp = this.restApi.getRequest(this.constructApi.getCustomerContact(this.request.CustomerId));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {

          let contact = new Contact(json.id, json.name, json.icNumber, json.number, json.address, json.customerId, json.relation, json.remark, false);
          //add customer instance into customer list
          this.contactList.push(contact);

        }
        );
      }
    };

  }

  getContactClass(isSelected: boolean) {
    if (isSelected == true)
      return 'selectedContactCard';
    else
      return 'contactCard'
  }

  changeIsContactSelected(i: number) {
    this.contactList[i].IsSelected = !this.contactList[i].IsSelected;
  }


  addContact() {
    const modalRef = this.sharedService.openModal(AddContactComponent);
    modalRef.componentInstance.viewCustomerId = this.request.CustomerId;

    //get added loan
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var contact = entry;

      //append added loan to loan list
      this.contactList.push(contact);
    });

    modalRef.result.then(() => {

      //detect changes
      this.cdr.detectChanges();
    })
  }

  reset() {
    this.activeModal.close();
  }

  submit() {

    var guarantorIdData: Number[] = new Array;
    for (let i = 0; i < this.contactList.length; i++) {
      if (this.contactList[i].IsSelected == true)
        guarantorIdData.push(this.contactList[i].ID);
    }


    var data = {
      "profileId": this.request.ProfileId,
      "loanInfo": this.request.LoanInfo,
      "remark": this.request.Remark,
      "requestOn": new Date().toISOString(),
      "guarantorIds": guarantorIdData
    }

    var xhr = this.restApi.putRequest(this.constructApi.getUpdateEPFLoanRequest(this.request.ID), data)
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        //if success , then proceed
        if (xhr.status == 200) {
          // window.location.reload();

          var json = JSON.parse(xhr.responseText);
          this.request.ID = Number(json.id);
          var modalRef = this.sharedService.openModal(ShowWhatsappMessageClipboardComponent);
          modalRef.componentInstance.request = this.request;
          modalRef.componentInstance.isDirty = true;

          this.reset();

        }
        else {
          //show error message
          this.sharedService.openAlert(JSON.parse(xhr.responseText).error, this.enums.DANGER_ALERT)

        }
      }
    }
  }

}
