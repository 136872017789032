import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Company } from 'src/model/company.model';

@Component({
  selector: 'app-cp-overview',
  templateUrl: './cp-overview.component.html',
  styleUrls: ['./cp-overview.component.css']
})
export class CpOverviewComponent implements OnInit {
  selectedCompanyCode: string;
  myControl = new UntypedFormControl();
  filteredCompany: Observable<Company[]>;
  companyList: Company[] = new Array;
  selectedCompany: number;

  constructor(private cdr: ChangeDetectorRef, private restapi: RestApiService, private constructApi: ConstructAPI, public sharedService: SharedService, public enums: Enums) {


  }

  ngOnInit(): void {

    if (this.sharedService.isBranchAccountHandler()) {
      this.selectedCompany = Number(sessionStorage.getItem("companyId"));
    }

    else {
      this.getAllCompanies(0);
      //filter company
      this.filteredCompany = this.myControl.valueChanges.pipe(
        startWith(''),
        map(companyCode => this._filterCompanyCodeOptions(companyCode))
      );
    }

  }


  private _filterCompanyCodeOptions(value: string): Company[] {
    const filterValue = value.toLowerCase();
    var filtered = this.companyList.filter(company => { return company.CompanyCode.toLowerCase().includes(filterValue) });

    return filtered;
  }


  getAllCompanies(companyGroup: number) {
    //clear company list
    this.companyList.splice(0, this.companyList.length);

    var xhttp;
    if (companyGroup == 0 || companyGroup == undefined)
      xhttp = this.restapi.getRequest(this.constructApi.getAllCompanies())
    else
      xhttp = this.restapi.getRequest(this.constructApi.getCompanyGroupCompanies(companyGroup))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));


        jsonArray.forEach(json => {
          let company = new Company(json.id, json.name, json.address, json.companyCode, json.contactNo, undefined, json.group, new Date(json.dateOperating));
          this.companyList.push(company);
        }
        );
        // this.outputToParent();

        //set the selected company code
        if (this.selectedCompany != undefined)
          this.setSelectedCompanyCode(this.selectedCompany)
      }
    };
  }

  setSelectedCompanyCode(companyId: number) {
    var index = this.companyList.findIndex(company => Number(company.ID) == Number(companyId));
    this.selectedCompany = companyId;
    this.selectedCompanyCode = this.companyList[index].CompanyCode;
  }

  setSelectedCompany(code: string) {
    var index = this.companyList.findIndex(company => company.CompanyCode.toLowerCase() == code.toLowerCase());
    this.selectedCompany = this.companyList[index].ID;

    this.cdr.detectChanges();
  }
}
