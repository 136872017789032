<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Search Ticket</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()" aria-label="Close"></button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-10">
                <input type="text" required class="textInputLong" name="ticketNumber" [(ngModel)]="ticketNumber"
                    (keypress)="sharedService.isNumberKey($event)">
            </div>
            <div class="col-2">
                <button class="btn btn-info" (click)="searchTicket()" [disabled]="detectEnableSearch()">
                    <i class="fas fa-search"></i>
                </button>
            </div>
        </div>

        <div *ngIf="loanRequest!=undefined">
            <div class=" mt-1 fw-bold d-flex justify-content-end align-items-right gap-3">
                <h3 class="badge text-bg-success" style="font-size: 1.5rem;"
                    *ngIf="loanRequest.Approved!=undefined && loanRequest.Approved==true">APPROVED
                    <i class="fas fa-check" *ngIf="loanRequest.LoanID!=undefined"></i> |
                    {{loanRequest.ProcessedOn | date:'dd-MM-yyyy'}}
                </h3>
                <h3 class="badge text-bg-danger" style="font-size: 1.5rem;"
                    *ngIf="loanRequest.Approved!=undefined && loanRequest.Approved==false">DENIED |
                    {{loanRequest.ProcessedOn | date:'dd-MM-yyyy'}}</h3>
                <h3 class="badge text-bg-primary" style="font-size: 1.5rem;" *ngIf="loanRequest.Approved==undefined">
                    PENDING
                </h3>
                <h3 class="badge text-bg-primary text-uppercase" style="font-size: 1.5rem;" *ngIf="loanRequest.LoanID!=undefined">
                    <i class="fas fa-donate"></i> Has Loan
                </h3>
            </div>

            <app-loan-request-details [request]="loanRequest" [isExpanded]="true" [editable]="false"
                [showPrevious]="false"></app-loan-request-details>
        </div>


    </div>
</form>