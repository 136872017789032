export class EPFLimit {



    constructor(private _upperLimit: number, private _lowerLimit: number, private _firstLoanRate?: number, private _maximumSettlementRate?: number,
        private _overallLoanRate?: number, private _elevatedSettlementRate?: number, private _minimumEPFAmount?: number, private _maximumEPFAmount?: number, private _minimumInterestRate?: number, private _isMinimumInTier?: boolean) {

    }


    set UpperLimit(upperLimit: number) { this._upperLimit = upperLimit }
    get UpperLimit(): number { return this._upperLimit }


    set LowerLimit(lowerLimit: number) { this._lowerLimit = lowerLimit }
    get LowerLimit(): number { return this._lowerLimit }

    set FirstLoanRate(firstLoanRate: number) { this._firstLoanRate = firstLoanRate }
    get FirstLoanRate(): number { return this._firstLoanRate }

    set MaximumSettlementRate(maximumSettlementRate: number) { this._maximumSettlementRate = maximumSettlementRate }
    get MaximumSettlementRate(): number { return this._maximumSettlementRate }


    set OverallLoanRate(overallLoanRate: number) { this._overallLoanRate = overallLoanRate }
    get OverallLoanRate(): number { return this._overallLoanRate }

    set ElevatedSettlementRate(elevatedSettlementRate: number) { this._elevatedSettlementRate = elevatedSettlementRate }
    get ElevatedSettlementRate(): number { return this._elevatedSettlementRate }

    get MinimumInterestRate(): number { return this._minimumInterestRate }
    set MinimumInterestRate(value: number) { this._minimumInterestRate = value }

    get MinimumEPFAmount(): number { return this._minimumEPFAmount }
    set MinimumEPFAmount(value: number) { this._minimumEPFAmount = value }

    get MaximumEPFAmount(): number { return this._maximumEPFAmount }
    set MaximumEPFAmount(value: number) { this._maximumEPFAmount = value }

    get IsMinimumnTier(): boolean { return this._isMinimumInTier }
    set IsMinimumnTier(value: boolean) { this._isMinimumInTier = value }
}