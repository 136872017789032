import o from "./svgs/amazon.svg.js";
import e from "./svgs/american-express.svg.js";
import r from "./svgs/apple-app-store.svg.js";
import s from "./svgs/apple-pay.svg.js";
import a from "./svgs/apple.svg.js";
import t from "./svgs/battlenet.svg.js";
import n from "./svgs/bitcoin.svg.js";
import c from "./svgs/blu-ray.svg.js";
import i from "./svgs/box.svg.js";
import m from "./svgs/chrome.svg.js";
import l from "./svgs/diners-club.svg.js";
import g from "./svgs/discord.svg.js";
import p from "./svgs/dropbox.svg.js";
import d from "./svgs/ebay.svg.js";
import f from "./svgs/ethereum.svg.js";
import u from "./svgs/firefox.svg.js";
import y from "./svgs/google-drive.svg.js";
import v from "./svgs/google-hangouts.svg.js";
import w from "./svgs/google-maps.svg.js";
import k from "./svgs/google-pay.svg.js";
import I from "./svgs/google-play.svg.js";
import b from "./svgs/google.svg.js";
import h from "./svgs/google-translate.svg.js";
import x from "./svgs/internet-explorer.svg.js";
import P from "./svgs/ios.svg.js";
import z from "./svgs/jsfiddle.svg.js";
import E from "./svgs/maestro.svg.js";
import j from "./svgs/mastercard.svg.js";
import C from "./svgs/microsoft.svg.js";
import O from "./svgs/ms-edge.svg.js";
import T from "./svgs/ms-excel.svg.js";
import A from "./svgs/ms-office.svg.js";
import D from "./svgs/ms-powerpoint.svg.js";
import F from "./svgs/ms-teams.svg.js";
import H from "./svgs/ms-outlook.svg.js";
import M from "./svgs/ms-word.svg.js";
import R from "./svgs/onedrive.svg.js";
import S from "./svgs/opera.svg.js";
import W from "./svgs/paypal.svg.js";
import q from "./svgs/safari.svg.js";
import B from "./svgs/sass.svg.js";
import G from "./svgs/skype.svg.js";
import J from "./svgs/slack.svg.js";
import K from "./svgs/soundcloud.svg.js";
import L from "./svgs/spotify.svg.js";
import N from "./svgs/stackoverflow.svg.js";
import Q from "./svgs/steam.svg.js";
import U from "./svgs/stripe.svg.js";
import V from "./svgs/tinder.svg.js";
import X from "./svgs/touch-id.svg.js";
import Y from "./svgs/twitch.svg.js";
import Z from "./svgs/usb.svg.js";
import _ from "./svgs/vimeo.svg.js";
import $ from "./svgs/visa.svg.js";
import oo from "./svgs/vs-code.svg.js";
const eo = {
  name: "amazon",
  value: o,
  categories: ["logos"],
  keywords: ["amazon", "ecommerce", "online", "shopping", "logo", "brand"]
}, ro = {
  name: "american-express",
  value: e,
  categories: ["logos"],
  keywords: [
    "americanexpress",
    "amex",
    "card",
    "payment",
    "finance",
    "logo",
    "brand"
  ]
}, so = {
  name: "apple-app-store",
  value: r,
  categories: ["logos"],
  keywords: ["apple", "appstore", "apps", "logo"]
}, ao = {
  name: "apple-pay",
  value: s,
  categories: ["logos"],
  keywords: ["apple", "pay", "payment", "finance", "logo"]
}, to = {
  name: "apple",
  value: a,
  categories: ["logos"],
  keywords: ["apple", "mac", "iphone", "ipad", "iwatch", "logo", "brand"]
}, no = {
  name: "battlenet",
  value: t,
  categories: ["logos"],
  keywords: ["battlenet", "bnet", "gaming", "blizzard", "logo"]
}, co = {
  name: "bitcoin",
  value: n,
  categories: ["logos", "finance"],
  keywords: ["bitcoin", "crypto", "currency", "coin", "cryptocurrency", "logo"]
}, io = {
  name: "blu-ray",
  value: c,
  categories: ["logos"],
  keywords: ["bluray", "ray", "entertainment", "movie", "logo", "brand"]
}, mo = {
  name: "box",
  value: i,
  categories: ["logos"],
  keywords: ["box", "cloud", "logo"]
}, lo = {
  name: "chrome",
  value: m,
  categories: ["logos"],
  keywords: ["chrome", "browser", "internet", "google", "logo"]
}, go = {
  name: "diners-club",
  value: l,
  categories: ["logos", "finance"],
  keywords: [
    "diners",
    "club",
    "dinersclub",
    "card",
    "payment",
    "finance",
    "logo",
    "brand"
  ]
}, po = {
  name: "discord",
  value: g,
  categories: ["logos"],
  keywords: ["discord", "chat", "social", "logo"]
}, fo = {
  name: "dropbox",
  value: p,
  categories: ["logos"],
  keywords: ["dropbox", "cloud", "logo"]
}, uo = {
  name: "ebay",
  value: d,
  categories: ["logos"],
  keywords: [
    "ebay",
    "ecommerce",
    "online",
    "shopping",
    "marketplace",
    "bidding",
    "logo",
    "brand"
  ]
}, yo = {
  name: "ethereum",
  value: f,
  categories: ["logos"],
  keywords: [
    "ethereum",
    "crypto",
    "currency",
    "coin",
    "cryptocurrency",
    "logo"
  ]
}, vo = {
  name: "firefox",
  value: u,
  categories: ["logos"],
  keywords: ["firefox", "browser", "internet", "mozilla", "logo"]
}, wo = {
  name: "google-drive",
  value: y,
  categories: ["logos"],
  keywords: ["drive", "google", "googledrive", "cloud", "logo"]
}, ko = {
  name: "google-hangouts",
  value: v,
  categories: ["logos"],
  keywords: [
    "hangouts",
    "google",
    "googlehangouts",
    "chat",
    "conferencing",
    "logo"
  ]
}, Io = {
  name: "google-maps",
  value: w,
  categories: ["logos"],
  keywords: ["google", "maps", "navigation", "logo"]
}, bo = {
  name: "google-pay",
  value: k,
  categories: ["logos"],
  keywords: ["google", "pay", "payment", "finance", "logo"]
}, ho = {
  name: "google-play",
  value: I,
  categories: ["logos"],
  keywords: ["google", "play", "store", "playstore", "apps", "logo"]
}, xo = {
  name: "google",
  value: b,
  categories: ["logos"],
  keywords: ["google", "g", "logo", "brand"]
}, Po = {
  name: "google-translate",
  value: h,
  categories: ["logos"],
  keywords: ["google", "translate", "language", "logo"]
}, zo = {
  name: "internet-explorer",
  value: x,
  categories: ["logos"],
  keywords: ["explorer", "browser", "internet", "microsoft", "logo"]
}, Eo = {
  name: "ios",
  value: P,
  categories: ["logos"],
  keywords: ["apple", "ios", "iphone", "ipad", "iwatch", "programming", "logo"]
}, jo = {
  name: "jsfiddle",
  value: z,
  categories: ["logos"],
  keywords: ["jsfiddle", "code", "programming", "editor", "snippet", "logo"]
}, Co = {
  name: "maestro",
  value: E,
  categories: ["logos", "finance"],
  keywords: ["maestro", "card", "payment", "finance", "logo", "brand"]
}, Oo = {
  name: "mastercard",
  value: j,
  categories: ["logos", "finance"],
  keywords: ["mastercard", "card", "payment", "finance", "logo", "brand"]
}, To = {
  name: "microsoft",
  value: C,
  categories: ["logos"],
  keywords: ["microsoft", "windows", "software", "logo", "brand"]
}, Ao = {
  name: "ms-edge",
  value: O,
  categories: ["logos"],
  keywords: ["edge", "browser", "internet", "microsoft", "logo"]
}, Do = {
  name: "ms-excel",
  value: T,
  categories: ["logos"],
  keywords: ["excel", "spreadsheet", "microsoft", "logo"]
}, Fo = {
  name: "ms-office",
  value: A,
  categories: ["logos"],
  keywords: ["office", "365", "microsoft", "logo"]
}, Ho = {
  name: "ms-powerpoint",
  value: D,
  categories: ["logos"],
  keywords: [
    "powerpoint",
    "slides",
    "presentation",
    "deck",
    "microsoft",
    "logo"
  ]
}, Mo = {
  name: "ms-teams",
  value: F,
  categories: ["logos"],
  keywords: [
    "teams",
    "communication",
    "chat",
    "conferencing",
    "meeting",
    "microsoft",
    "logo"
  ]
}, Ro = {
  name: "ms-outlook",
  value: H,
  categories: ["logos"],
  keywords: ["outlook", "email", "communication", "microsoft", "logo"]
}, So = {
  name: "ms-word",
  value: M,
  categories: ["logos"],
  keywords: ["word", "microsoft", "logo"]
}, Wo = {
  name: "onedrive",
  value: R,
  categories: ["logos"],
  keywords: ["onedrive", "cloud", "microsoft", "logo"]
}, qo = {
  name: "opera",
  value: S,
  categories: ["logos"],
  keywords: ["opera", "browser", "internet", "logo", "o", "circle"]
}, Bo = {
  name: "paypal",
  value: W,
  categories: ["logos"],
  keywords: ["paypal", "payment", "finance", "logo"]
}, Go = {
  name: "safari",
  value: q,
  categories: ["logos"],
  keywords: [
    "safari",
    "browser",
    "internet",
    "apple",
    "logo",
    "compass",
    "dirctions",
    "navigation"
  ]
}, Jo = {
  name: "sass",
  value: B,
  categories: ["logos"],
  keywords: ["sass", "logo"]
}, Ko = {
  name: "skype",
  value: G,
  categories: ["logos"],
  keywords: ["skype", "chat", "meeting", "conferencing", "microsoft", "logo"]
}, Lo = {
  name: "slack",
  value: J,
  categories: ["logos"],
  keywords: ["slack", "chat", "communication", "logo"]
}, No = {
  name: "soundcloud",
  value: K,
  categories: ["logos"],
  keywords: ["soundcloud", "music", "social", "media", "network", "logo"]
}, Qo = {
  name: "spotify",
  value: L,
  categories: ["logos"],
  keywords: ["spotify", "music", "network", "logo"]
}, Uo = {
  name: "stackoverflow",
  value: N,
  categories: ["logos"],
  keywords: [
    "stackoverflow",
    "stack",
    "overflow",
    "social",
    "media",
    "programming",
    "logo"
  ]
}, Vo = {
  name: "steam",
  value: Q,
  categories: ["logos"],
  keywords: ["steam", "gaming", "games", "social", "media", "logo"]
}, Xo = {
  name: "stripe",
  value: U,
  categories: ["logos"],
  keywords: ["stripe", "payment", "finance", "logo"]
}, Yo = {
  name: "tinder",
  value: V,
  categories: ["logos"],
  keywords: [
    "tinder",
    "dating",
    "chat",
    "match",
    "social",
    "media",
    "network",
    "logo",
    "flame"
  ]
}, Zo = {
  name: "touch-id",
  value: X,
  categories: ["logos"],
  keywords: [
    "touchid",
    "touch",
    "finger",
    "fingerprint",
    "biometric",
    "identity",
    "security",
    "apple",
    "logo"
  ]
}, _o = {
  name: "twitch",
  value: Y,
  categories: ["logos"],
  keywords: [
    "twitch",
    "social",
    "media",
    "gaming",
    "logo",
    "cartoon",
    "streaming"
  ]
}, $o = {
  name: "usb",
  value: Z,
  categories: ["logos"],
  keywords: ["usb", "flash", "drive", "logo"]
}, oe = {
  name: "vimeo",
  value: _,
  categories: ["logos"],
  keywords: ["vimeo", "video", "social", "media", "network", "logo"]
}, ee = {
  name: "visa",
  value: $,
  categories: ["logos", "finance"],
  keywords: ["visa", "card", "payment", "finance", "logo", "brand"]
}, re = {
  name: "vs-code",
  value: oo,
  categories: ["logos"],
  keywords: ["vscode", "ide", "programming", "microsoft", "logo"]
}, ar = [
  eo,
  ro,
  to,
  so,
  ao,
  no,
  co,
  io,
  mo,
  lo,
  go,
  po,
  fo,
  uo,
  yo,
  vo,
  xo,
  wo,
  ko,
  Io,
  bo,
  ho,
  Po,
  zo,
  Eo,
  jo,
  Co,
  Oo,
  To,
  Ao,
  Do,
  Fo,
  Ro,
  Ho,
  Mo,
  So,
  Wo,
  qo,
  Bo,
  Go,
  Jo,
  Ko,
  Lo,
  No,
  Qo,
  Uo,
  Vo,
  Xo,
  Yo,
  Zo,
  _o,
  $o,
  oe,
  ee,
  re
];
export {
  eo as amazon,
  ro as amex,
  to as apple,
  so as appleAppStore,
  ao as applePay,
  no as battlenet,
  co as bitcoin,
  io as bluRay,
  mo as box,
  lo as chrome,
  go as dinersClub,
  po as discord,
  fo as dropbox,
  uo as ebay,
  yo as ethereum,
  vo as firefox,
  xo as google,
  wo as googleDrive,
  ko as googleHangouts,
  Io as googleMaps,
  bo as googlePay,
  ho as googlePlay,
  Po as googleTranslate,
  zo as internetExplorer,
  Eo as ios,
  jo as jsFiddle,
  ar as logos,
  Co as maestro,
  Oo as mastercard,
  To as microsoft,
  Ao as msEdge,
  Do as msExcel,
  Fo as msOffice,
  Ro as msOutlook,
  Ho as msPowerpoint,
  Mo as msTeams,
  So as msWord,
  Wo as onedrive,
  qo as opera,
  Bo as paypal,
  Go as safari,
  Jo as sass,
  Ko as skype,
  Lo as slack,
  No as soundcloud,
  Qo as spotify,
  Uo as stackoverflow,
  Vo as steam,
  Xo as stripe,
  Yo as tinder,
  Zo as touchId,
  _o as twitch,
  $o as usb,
  oe as vimeo,
  ee as visa,
  re as vsCode
};
