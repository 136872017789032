<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Add Subtype</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 aria-label="Close" aria-label="Close"
            (click)="reset()"></button>
    </div>

    <div class="modal-body">

        <div class="container-fluid" style="padding: 2%;">
            <div class="row">
                <div class="col">
                    <div class="row">
                        <div class="col">
                            <div class="table-responsive table-borderless m-1">
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td><strong>SubType Name</strong></td>
                                            <td><input type="text" [(ngModel)]="name" name="name">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><strong>For Transaction Type</strong></td>
                                            <td>
                                                <mat-form-field appearance="fill">
                                                    <mat-label>TransactionType</mat-label>
                                                    <mat-select [(ngModel)]="selectedTransactionType"
                                                        name="selectedTransactionType">
                                                        <mat-option *ngFor="let type of transactionTypeList"
                                                            [value]="type.ID">
                                                            {{type.Name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col d-flex justify-content-end" style="padding: 0;margin-bottom: 2%;"
                            (click)="submit()"><button class="btn btn-success" type="button">Add</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <h1 style="font-family: Aldrich, sans-serif;"></h1>
                </div>
            </div>
            <div class="row" style="margin: 0;">
                <div class="col p-0">
                    <div class="card shadow-lg">
                        <div class="card-body">
                            <h4 class="card-title">Current SubTypes<br></h4>
                        </div>
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="text-center">Name</th>
                                        <th class="text-center">For Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let type of subtypeList">
                                        <td class="text-center">{{type.Name}}</td>
                                        <td class="text-center">{{transactionTypeList[type.Type-1].Name}}</td>
                                        <td class="text-center">
                                            <i class="fas fa-times" style="cursor: pointer;"
                                                (click)="openDeleteConfirmationDialog(type)"></i>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</form>