export class Arrear {
    constructor(
        private _amount: number,
        private _date: Date,
        private _id: number,
        private _loanId: number,
        private _remark: string,
        private _datePaid?: Date) { }


    set ID(id: number) { this._id = id }
    get ID(): number { return this._id }


    set Amount(amount: number) { this._amount = amount }
    get Amount(): number { return this._amount }

    set LoanId(loanId: number) { this._loanId = loanId }
    get LoanId(): number { return this._loanId }

    set Remark(remark: string) { this._remark = remark }
    get Remark(): string { return this._remark }

    set Date(date: Date) { this._date = date }
    get Date(): Date { return this._date }

    set DatePaid(datePaid: Date) { this._datePaid = datePaid }
    get DatePaid(): Date { return this._datePaid }
}

