import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { AlertDialogComponent } from 'src/app/Shared/alert-dialog/alert-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Account } from 'src/model/account.model';
import { Advance } from 'src/model/advance.modal';
import { User } from 'src/model/user.model';

@Component({
  selector: 'app-add-advance',
  templateUrl: './add-advance.component.html',
  styleUrls: ['./add-advance.component.css']
})
export class AddAdvanceComponent implements OnInit {
  @Input() companyAccountList: Account[] = new Array;

  selectedAccountId: number;
  selectedUser: string;
  loggedInCompanyId: number;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  //2-WAY BINDING INFO
  date: Date;
  remark: string;
  amount: number = 0;
  userList: User[] = new Array;

  disabledSubmit: boolean = false;

  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal,
    private restapi: RestApiService, private constructApi: ConstructAPI, private enums: Enums, public sharedService: SharedService) {
    this.date = new Date();
  }

  ngOnInit(): void {

    this.loggedInCompanyId = Number(sessionStorage.getItem("companyId"));

    this.getAllUsersOfCompany();
    this.getAllAccountsOfCompany(this.loggedInCompanyId);
  }

  private getAllAccountsOfCompany(companyId: number) {

    var xhttp = this.restapi.getRequest(this.constructApi.getAccountsOfCompany(companyId));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let account = new Account(json.id, json.number, json.name, json.holderName, json.balance, json.companyId, json.username);

          var loggedInUsername = sessionStorage.getItem("username");
          if (json.username == loggedInUsername || json.username == undefined)
            this.companyAccountList.push(account);

        }
        );

      }
    };

  }

  setSelectedAccount(accountId: number) {

    if (accountId === this.selectedAccountId)
      return true
    else
      return false;
  }

  setSelectedUser(username: string) {

    if (username === this.selectedUser)
      return true
    else
      return false;
  }

  private getAllUsersOfCompany() {
    var xhttp = this.restapi.getRequest(this.constructApi.getAllUsers())
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {

          var user = new User(null, json.companyId, json.companyName, json.roleId, json.roleName, json.username, null)
          this.userList.push(user);

        }
        );
      }
    };
  }


  isBalanceInsufficient(balance: number) {
    if (balance > this.amount)
      return false;
    else
      return true;
  }

  isFieldNull() {
    var paymentNull = (this.amount == undefined || this.amount == 0);
    var remarkNull = (this.remark == undefined || this.remark == "");
    var selectedAccountIdNull = (this.selectedAccountId == undefined)
    var selectedUserNull = (this.selectedUser == undefined);
    var paymentValid = (isNaN(+this.amount))

    return paymentNull || remarkNull || selectedAccountIdNull || selectedUserNull || paymentValid;
  }

  addAdvance() {

    this.disabledSubmit = true;

    var isoTaskDate = (new Date(Number(this.date))).toISOString();

    var positiveAmount = (this.amount < 0) ? -this.amount : this.amount;
    var data = {
      "amount": positiveAmount,
      "accountId": Number(this.selectedAccountId),
      "dateCreated": isoTaskDate,
      "remark": this.remark,
      "username": this.selectedUser
    };


    var xhr;
    if (this.amount < 0)
      xhr = this.restapi.postRequest(this.constructApi.getAddAdvanceDeposit(), data);
    else
      xhr = this.restapi.postRequest(this.constructApi.getAddAdvanceBorrow(), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        if (xhr.status == 200) {
          this.disabledSubmit = false;
          // var json = JSON.parse(xhr.responseText);

          // let account = new Account(this.selectedAccountId, null, null, null, null, null, null, this.amount)
          // this.passEntry.emit(account)

          let advance = new Advance(null, this.selectedUser, this.date, -this.amount, this.remark)
          this.passEntry.emit(advance);
          this.reset();
          this.sharedService.openAlert("Successfully added advance!", this.enums.SUCCESS_ALERT);

        }
        else {
          this.disabledSubmit = false;
          this.sharedService.openAlert("Opps,some error occured. Please try again later", this.enums.DANGER_ALERT);
        }
      }

    }
  }

  submit() {

    (this.isFieldNull()) ? this.sharedService.openAlert('Please fill in all the fields', this.enums.DANGER_ALERT) : this.addAdvance();
  }
  reset() {
    this.activeModal.close();
  }
}
