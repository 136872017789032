<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title" *ngIf="isGlobal;else customerSearch">Global Search Customer</h4>
        <ng-template #customerSearch>
            <h4 class="modal-title">Search Customer</h4>
        </ng-template>

        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 aria-label="Close" (click)="reset()"></button>
    </div>

    <div class="card m-3 shadow-lg" *ngIf="searchHistoryList!=undefined && searchHistoryList.length>0">
        <div class="card-body">
            <h5><i class="fas fa-history"></i> 3 Recent Searches</h5>
            <div class="row">
                <ng-container *ngFor="let record of searchHistoryList">
                    <div class="col-4">
                        <span class=" badge text-bg-info" style="cursor: pointer;"
                            (click)="findCustomer(record.Name,record.ICNumber,record.CustCode,true)">
                            <span *ngIf="record.Name!=undefined && record.Name!=''">Name: {{record.Name}}</span>
                            <span *ngIf="record.ICNumber!=undefined && record.ICNumber!=''">IC:
                                {{sharedService.processICNum(record.ICNumber)}}</span>
                            <span *ngIf="record.CustCode!=undefined">Code: {{record.CustCode}}</span>
                        </span>
                    </div>
                </ng-container>

            </div>

        </div>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-12 col-md-4  mb-md-4">
                <mat-slide-toggle class="mat-slide-toggle-lg" [hideIcon]="true" color="primary" [(ngModel)]="includeCPSearch" name="searchCP"
                    *ngIf="isGlobal==true &&(this.sharedService.isAdmin()|| this.sharedService.isBossAccount() || this.sharedService.isFinance())"
                    [disabled]="isGlobal==true &&(this.sharedService.isAdmin()|| this.sharedService.isBossAccount() || this.sharedService.isFinance())">
                    CP customer (IC Only)
                </mat-slide-toggle>

            </div>
            <div class="col-12 col-md-4 mb-md-4">
                <mat-slide-toggle class="mat-slide-toggle-lg" [hideIcon]="true" color="primary" [(ngModel)]="includeGuarantorSearch"
                    name="searchGuarantor"
                    *ngIf="isGlobal==true &&(this.sharedService.isAdmin()|| this.sharedService.isBossAccount() || this.sharedService.isFinance())"
                    [disabled]="isGlobal==true &&(this.sharedService.isAdmin()|| this.sharedService.isBossAccount() || this.sharedService.isFinance())">
                    Guarantor (IC Only)
                </mat-slide-toggle>

            </div>
            <div class="col-12 col-md-4 mb-md-4">
                <mat-slide-toggle class="mat-slide-toggle-lg" [hideIcon]="true" color="primary" [(ngModel)]="includeBlacklistSearch"
                    name="searchGuarantor"
                    *ngIf="isGlobal==true &&(this.sharedService.isAdmin()|| this.sharedService.isBossAccount() || this.sharedService.isFinance())"
                    [disabled]="isGlobal==true &&(this.sharedService.isAdmin()|| this.sharedService.isBossAccount() || this.sharedService.isFinance())">
                    Blacklist (IC Only)
                </mat-slide-toggle>

            </div>
            <div class="col-12  col-lg-3">
                <div class="position-relative mb-2">
                    <input type="text" required class="textInputLong" name="searchName" [(ngModel)]="searchName">
                    <span class="highlight"></span>
                    <span class="bar barLong"></span>
                    <label>Name</label>
                </div>
            </div>
            <div class="col-12  col-lg-3">
                <div class="position-relative mb-2">
                    <input type="text" required class="textInputLong" name="searchICNum" [(ngModel)]="searchICNum">
                    <span class="highlight"></span>
                    <span class="bar barLong"></span>
                    <label>IC Number</label>
                </div>
            </div>

            <div class="col-12 col-lg-3" [hidden]="isGlobal==true">
                <div class="position-relative mb-2">
                    <input type="text" required class="textInputLong" name="searchCustCode"
                        [(ngModel)]="searchCustCode">
                    <span class="highlight"></span>
                    <span class="bar barLong"></span>
                    <label>Customer Code</label>
                </div>
            </div>
            <div class="col-12 col-lg-3 d-lg-flex justify-content-end mt-0 pt-0">
                <button class="btn btn-primary" (click)="ifContainsInvalidSymbol(searchName,searchICNum,searchCustCode)"
                    [disabled]="detectEnableSearch()">
                    <i class="fas fa-search"></i> Search
                </button>
            </div>

        </div>
        <div class="container-fluid p-0" [hidden]="customerList.length<=0">
            <p *ngIf="searchICNum==undefined || searchICNum==''" class="text-danger">*Take Note: Name search cannot
                search CP
                and BLACKLIST</p>
            <table mat-table [dataSource]="dataSource" matSort>

                <!-- Name Column -->
                <ng-container matColumnDef="Name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                    <td mat-cell *matCellDef="let customer" (click)="checkRedirectOrReturn(customer)"
                        [ngClass]="checkIsLead(customer)"> {{customer.Name}} </td>
                </ng-container>

                <!-- ICNumber Column -->
                <ng-container matColumnDef="ICNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> IC Number </th>
                    <td mat-cell *matCellDef="let customer" (click)="checkRedirectOrReturn(customer)"
                        [ngClass]="checkIsLead(customer)">
                        {{sharedService.processICNum(customer.ICNumber)}}
                    </td>
                </ng-container>

                <ng-container *ngIf="isGlobal==false">
                    <!-- Customer Code Column -->
                    <ng-container matColumnDef="CustCode">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Code </th>
                        <td mat-cell *matCellDef="let customer" (click)="checkRedirectOrReturn(customer)"
                            [ngClass]="checkIsLead(customer)">
                            {{customer.CustomerCode}}
                        </td>
                    </ng-container>

                    <!-- Occupation Column -->
                    <ng-container matColumnDef="Occupation">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Occupation </th>
                        <td mat-cell *matCellDef="let customer" (click)="checkRedirectOrReturn(customer)"
                            [ngClass]="checkIsLead(customer)">
                            {{customer.Occupation}}
                        </td>
                    </ng-container>
                </ng-container>

                <!-- Status Column -->
                <ng-container matColumnDef="Status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                    <td mat-cell *matCellDef="let customer" (click)="checkRedirectOrReturn(customer)"
                        [ngClass]="checkIsLead(customer)">
                        <h6>
                            <span class="badge"
                                [ngClass]="getStatusColor(sharedService.processStatusCode(customer.Status))"
                                *ngIf="isGlobal==false">
                                {{enums.customerStatusString[sharedService.processStatusCode(customer.Status)-1]}}
                            </span>

                            <span class="badge" [ngClass]="getStatusColor(customer.Status)" *ngIf="isGlobal==true">
                                {{enums.customerStatusString[customer.Status-1]}}
                            </span>

                        </h6>
                    </td>
                </ng-container>

                <!-- CompanyName Column -->
                <ng-container matColumnDef="CompanyName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> CompanyName </th>
                    <td mat-cell *matCellDef="let customer" (click)="checkRedirectOrReturn(customer)">
                        {{customer.CompanyName}}
                    </td>
                </ng-container>

                <!-- DOB Column -->
                <ng-container matColumnDef="DOB">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> DOB </th>
                    <td mat-cell *matCellDef="let customer" (click)="checkRedirectOrReturn(customer)">
                        {{customer.DOB }}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[25,50, 100]" showFirstLastButtons></mat-paginator>
        </div>


        <!--CP CUSTOMER-->
        <div class="col-12"
            *ngIf="isGlobal==true && includeCPSearch==true  && cpCustomerList!=undefined && searchICNum != undefined && searchICNum != ''">
            <hr />
            <h2>CP Customer</h2>
            <p [hidden]=" cpCustomerList.length>0"> No result</p>
            <table mat-table [dataSource]="cpDataSource" matSort [hidden]="cpCustomerList.length<=0">

                <!-- Name Column -->
                <ng-container matColumnDef="CustName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                    <td mat-cell *matCellDef="let customer" (click)="openCPDetailsModal(customer.CustomerID)">
                        {{customer.Name}} </td>
                </ng-container>

                <!-- ICNumber Column -->
                <ng-container matColumnDef="CustIC">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> IC Number </th>
                    <td mat-cell *matCellDef="let customer" (click)="openCPDetailsModal(customer.CustomerID)">
                        {{customer.ICNumber}}
                    </td>
                </ng-container>

                <ng-container *ngIf="isGlobal==true">
                    <!-- Customer Code Column -->
                    <ng-container matColumnDef="CustCode">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Code </th>
                        <td mat-cell *matCellDef="let customer" (click)="openCPDetailsModal(customer.CustomerID)">
                            {{customer.CustomerCode}}
                        </td>
                    </ng-container>

                </ng-container>

                <!-- Status Column -->
                <ng-container matColumnDef="CaseStatus">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                    <td mat-cell *matCellDef="let customer" (click)="openCPDetailsModal(customer.CustomerID)">
                        <h6>

                            {{customer.Status}}

                        </h6>
                    </td>
                </ng-container>

                <!-- EmployeeName Column -->
                <ng-container matColumnDef="EmployeeName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Handler </th>
                    <td mat-cell *matCellDef="let customer" (click)="openCPDetailsModal(customer.CustomerID)">
                        <h6>
                            {{customer.HandlerName}}
                        </h6>
                    </td>
                </ng-container>



                <tr mat-header-row *matHeaderRowDef="displayedCPColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedCPColumns;"></tr>
            </table>
        </div>


        <!--GUARANTOR-->
        <div class="col-12"
            *ngIf="isGlobal==true && (this.sharedService.isAdmin() || this.sharedService.isBossAccount() || this.sharedService.isFinance() ) && guarantorList!=undefined  && searchICNum != undefined && searchICNum != ''">
            <hr />
            <h2>Guarantor</h2>
            <p [hidden]="guarantorList.length>0">No result</p>
            <table mat-table [dataSource]="guarantorDataSource" matSort [hidden]="guarantorList.length<=0">

                <!-- Name Column -->
                <ng-container matColumnDef="GuarantorName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                    <td mat-cell *matCellDef="let guarantor" (click)="checkRedirectOrReturn(guarantor)">
                        {{guarantor.GuarantorName}} </td>
                </ng-container>

                <!-- ICNumber Column -->
                <ng-container matColumnDef="GuarantorICNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> IC Number </th>
                    <td mat-cell *matCellDef="let guarantor" (click)="checkRedirectOrReturn(guarantor)">
                        {{sharedService.processICNum(guarantor.GuarantorIC)}}
                    </td>
                </ng-container>

                <ng-container>
                    <!-- Company Code Column -->
                    <ng-container matColumnDef="GuarantorCompanyCode">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Company Code </th>
                        <td mat-cell *matCellDef="let guarantor" (click)="checkRedirectOrReturn(guarantor)">
                            {{guarantor.CompanyName}}
                        </td>
                    </ng-container>

                </ng-container>


                <!-- Name Column -->
                <ng-container matColumnDef="CustomerName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Name </th>
                    <td mat-cell *matCellDef="let guarantor" (click)="checkRedirectOrReturn(guarantor)">
                        {{guarantor.Name}} </td>
                </ng-container>

                <!-- ICNumber Column -->
                <ng-container matColumnDef="CustomerIC">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer IC </th>
                    <td mat-cell *matCellDef="let guarantor" (click)="checkRedirectOrReturn(guarantor)">
                        {{sharedService.processICNum(guarantor.ICNumber)}}
                    </td>
                </ng-container>

                <!-- HasLoan Column -->
                <ng-container matColumnDef="HasLoan">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Has Loan</th>
                    <td mat-cell *matCellDef="let guarantor" (click)="checkRedirectOrReturn(guarantor)">
                        <span *ngIf="guarantor.HasLoan==true">Yes</span>
                        <span *ngIf="guarantor.HasLoan==false">No</span>
                    </td>
                </ng-container>
                <!-- LastApprovedOn Column -->
                <ng-container matColumnDef="LastApprovedOn">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Approved Ticket</th>
                    <td mat-cell *matCellDef="let guarantor" (click)="checkRedirectOrReturn(guarantor)">
                        {{guarantor.LastApprovedTicketDate | date:'dd-MM-yyyy'}}
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedGuarantorColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedGuarantorColumns;"></tr>
            </table>
        </div>


        <!--BLACKLIST-->
        <div class="col-12"
            *ngIf="isGlobal==true && blacklistRecordList!=undefined && searchICNum != undefined && searchICNum != ''">
            <hr />
            <h2>Blacklist</h2>
            <p [hidden]="blacklistRecordList.length>0">No result</p>
            <table mat-table [dataSource]="blacklistDataSource" matSort [hidden]="blacklistRecordList.length<=0">

                <!-- Name Column -->
                <ng-container matColumnDef="Name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                    <td mat-cell *matCellDef="let record">
                        {{record.Name}} </td>
                </ng-container>

                <!-- ICNumber Column -->
                <ng-container matColumnDef="ICNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> IC Number </th>
                    <td mat-cell *matCellDef="let record">
                        {{sharedService.processICNum(record.ICNumber)}}
                    </td>
                </ng-container>

                <!-- Remark Column -->
                <ng-container matColumnDef="Remark">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Remark </th>
                    <td mat-cell *matCellDef="let record">
                        {{record.Remark}}
                    </td>
                </ng-container>




                <tr mat-header-row *matHeaderRowDef="displayedBlacklistColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedBlacklistColumns;"></tr>
            </table>
        </div>
    </div>
</form>