import { Component, Input, OnInit } from '@angular/core';
import { Loan } from 'src/model/loan.model';

@Component({
  selector: 'app-add-loan-request-details',
  templateUrl: './add-loan-request-details.component.html',
  styleUrls: ['./add-loan-request-details.component.css']
})
export class AddLoanRequestDetailsComponent implements OnInit {


  @Input() epfDate: Date;
  @Input() epfAmount: number;
  @Input() isEPFExtra: boolean;
  @Input() isManualKeyIn: boolean;
  @Input() previousPrincipal: number;
  @Input() isEPF: boolean = true;
  @Input() availableReserve: number;

  loan: Loan;
  constructor() { }

  ngOnInit(): void {
  }


  calculateTotalOnHand() {
    var total = 0;
      if (this.loan != undefined) {
        var duitLebihTotal = 0;
        if (this.loan.Extras.length > 0) {
          for (let j = 0; j < this.loan.Extras.length; j++)
            duitLebihTotal += this.loan.Extras[j].Amount;
        }

        total += this.loan.Principal + this.loan.Reserve - duitLebihTotal - this.loan.InterestCharge - this.loan.StampAmount -
          this.loan.ServiceCharge
      }

    

    return total;
  }
}
