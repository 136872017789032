import { DatePipe } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Company } from 'src/model/company.model';
import { Customer } from 'src/model/customer.model';

@Component({
  selector: 'app-epf-settlement-breakdown-letter',
  templateUrl: './epf-settlement-breakdown-letter.component.html',
  styleUrl: './epf-settlement-breakdown-letter.component.css',
})
export class EpfSettlementBreakdownLetterComponent {

  selectedCompany: Company;
  selectedCustomer: Customer;
  selectedCustomerID: number;
  selectedCompanyID: number;

  loanAmount: number;
  @ViewChild('htmlData') htmlData: ElementRef;

  routeSubscription: any;
  settlement: number;
  simpan: number;
  latestDate: Date;


  constructor(private activatedRoute: ActivatedRoute, private restapi: RestApiService, private constructApi: ConstructAPI, public sharedService: SharedService, private router: Router) {
    this.routeSubscription = this.activatedRoute.paramMap.subscribe(params => {
      this.selectedCustomerID = + params.get('customerId');
      this.selectedCompanyID = + params.get('companyId');

      this.getCompanyInfo();
      this.getCustomerInfo();


      this.settlement = this.router.getCurrentNavigation().extras.state.settlement;
      this.simpan = this.router.getCurrentNavigation().extras.state.simpan;
      this.latestDate = this.router.getCurrentNavigation().extras.state.latestDate;


    })
  }

  ngOnInit(): void {

  }


  getLatestSettlementDate() {

    //latest settlement date = 3 days after epf date
    var epfDate = new Date(this.selectedCustomer.EPFDate);
    return new Date(epfDate.getFullYear(), epfDate.getMonth(), epfDate.getDate() + 3)
  }

  getCompanyInfo() {
    var xhttp = this.restapi.getRequest(this.constructApi.getCompanyDetails(this.selectedCompanyID))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var json = JSON.parse((xhttp.responseText));
        this.selectedCompany = new Company(json.id, json.name, json.address, json.companyCode, json.contactNo, json.regNo)


      }
    };
  }

  getCustomerInfo() {
    var xhttp = this.restapi.getRequest(this.constructApi.getCustomerDetails(this.selectedCustomerID))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var json = JSON.parse((xhttp.responseText));
        this.selectedCustomer = new Customer(json.id, json.name, json.occupation,
          json.salary, json.salaryDay, json.icNumber, json.contactNo, json.remark, json.ctosNumber, json.ctosStatus,
          json.status, json.dateCreated, json.handler, json.companyId, json.customerCode, json.companyName, json.reserve, json.badDebt, json.address, undefined, undefined, undefined, json.epfDate);
      }
    };
  }

  calculateBalance() {

    if (this.settlement != undefined && this.loanAmount != undefined && this.simpan != undefined)
      return this.settlement + this.loanAmount - this.simpan;
    else
      return 0;
  }
}
