<div class="doNotPrint">

    <div class="group mt-3">
        <input type="number" required class="textInputLong" name="loanAmount" [(ngModel)]="loanAmount" min=0
            (keypress)="sharedService.isNumberKey($event)">
        <span class="highlight"></span>
        <span class="bar barLong"></span>
        <label>Loan Amount</label>
    </div>
    <hr />
</div>


<div class="text-justify settlementBreakdownLetter "
    style="font-family: 'Calibri, sans-serif';line-height:120%;font-size: 12pt;" id="htmlData" #htmlData
    *ngIf="selectedCustomer!=undefined && selectedCompany!=undefined && loanAmount!=undefined && settlement!=undefined && simpan!=undefined">

    <h3 class="text-center"><strong> {{selectedCompany.Name}}</strong></h3>
    <p class="text-center m-0 p-0"><strong> ({{selectedCompany.RegNum}})</strong></p>
    <p class="text-center m-0 p-0"><strong> {{selectedCompany.Address}}</strong></p>
    <p class="text-center m-0 p-0"><strong> TEL: {{selectedCompany.ContactNo}}</strong></p>


    <p class="d-flex justify-content-end">Tarikh : {{sharedService.getTodayDate()|date:'dd-MM-yyyy'}}</p>

    <h5 class="text-center text-decoration-underline"><strong> PENYATA PENYELESAIAN PINJAMAN</strong></h5>


    <table class="table table-borderless mx-5">
        <tbody>
            <tr>
                <td><strong>NAMA:</strong></td>
                <td><strong>{{selectedCustomer.Name}}</strong></td>
            </tr>
            <tr>
                <td><strong>NO. KAD PENGENALAN :</strong></td>
                <td><strong> {{sharedService.processICNum(selectedCustomer.ICNumber)}}</strong></td>
            </tr>
            <tr>
                <td><strong>NO RUJUKAN :</strong></td>
                <td><strong></strong></td>
            </tr>
        </tbody>
    </table>


    <p>Untuk makluman tuan/puan, di bawah ini dinyatakan jumlah yang perlu dibayar untuk menjelaskan akaun di atas
        dengan mengikut syarat-syarat yang sudah dibincang dengan pihak <strong>{{selectedCompany.Name}}</strong></p>


    <table class="table table-borderless mb-0 mx-5 breakdownTable">
        <tbody>
            <tr>
                <td class="my-0 py-0">(+)</td>
                <td class="my-0 py-0">Baki pada {{latestDate | date:'dd-MM-yyyy'}} </td>
                <td class="my-0 py-0">:</td>
                <td class="my-0 py-0">RM @if(settlement==0){ {{loanAmount| number:'1.2-2'}} } @else { {{settlement |
                    number:'1.2-2'}} }
                </td>
            </tr>
            <tr>
                <td class="my-0 py-0"></td>
                <td class="my-0 py-0">Ansuran tertunggak </td>
                <td class="my-0 py-0">:</td>
                <td class="my-0 py-0"></td>
            </tr>
            <tr>
                <td class="my-0 py-0">(-)</td>
                <td class="my-0 py-0">Payment</td>
                <td class="my-0 py-0">:</td>
                <td class="my-0 py-0">RM {{simpan | number:'1.2-2'}}</td>
            </tr>
            <tr>
                <td class="my-0 py-0">(+)</td>
                <td class="text-danger my-0 py-0">EXTRA</td>
                <td class="my-0 py-0">:</td>
                <td class="text-danger my-0 py-0">RM @if(settlement==0){ 0.00 } @else { {{loanAmount | number:'1.2-2'}}}
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <td class="my-0 py-0"></td>
                <td class="my-0 py-0">Jumlah Penyelesaian</td>
                <td class="my-0 py-0">:</td>
                <td class="my-0 py-0">RM {{calculateBalance() | number:'1.2-2'}} </td>
            </tr>
        </tfoot>
    </table>
    <br />
    <p>
        Jumlah penyelesaian di atas perlu dibayar sebelum atau pada {{getLatestSettlementDate() | date:'dd-MM-yyyy'}}.
        Sekiranya tuan/puan
        gagal membuat
        demikian, jumlah tersebut akan dibatal dan jumlah baru akan dikira sekiranya tuan/puan bercadang untuk
        menjelaskan pinjaman tersebut selepas tarikh yang dinyatakan di atas. Diskaun semasa penyelesaian loan juga
        adalah tidak dibenarkan.
    </p>

    <br>
    <p>
        <strong>SEMUA BAYARAN HARUS "ONLINE TRANSFER / OVER THE BANK KAUNTER" KE AKAUN BANK SYARIKAT SAHAJA. INI ADALAH
            UNTUK SYARIKAT MENJEJAK BAYARAN BALIK YANG TELAH DIBUAT OLEH ANDA MELALUI TRANSAKSI BANK.</strong>
    </p>
    <p>NOTE : CASH / ATM CASH DEPOSIT ADALAH TIDAK DITERIMA.</p>


    <table class="table table-borderless ">
        <tbody>
            <tr>
                <td class="my-0 py-0"><strong>{{selectedCompany.Name}}</strong></td>
                <td></td>
                <td class="my-0 py-0"><strong>NAMA : {{selectedCustomer.Name}}</strong></td>
            </tr>
            <tr>
                <td class="my-0 py-0"><strong>({{selectedCompany.RegNum}})</strong></td>
                <td></td>
                <td class="my-0 py-0"><strong>NO K/P:
                        {{sharedService.processICNum(selectedCustomer.ICNumber)}}</strong></td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td><strong></strong></td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td><strong></strong></td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td><strong></strong></td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td><strong></strong></td>
            </tr>
            <tr>
                <td>-------------------------------------</td>
                <td></td>
                <td>-------------------------------------</td>
            </tr>
            <tr>
                <td><strong>AUTHORISED BY</strong></td>
                <td></td>
                <td><strong>TANDATANGAN PEMINJAM</strong></td>
            </tr>

        </tbody>
    </table>
</div>