<div class="m-2 voucher">

    <div class="card p-3"
        style="border:black 1px solid; font-family: 'Times New Roman', Times, serif;line-height: 100%;font-size: 11pt;">
        <div class="row">
            <div class="col-12">
                <strong>{{selectedCompany.Name |uppercase}} - {{selectedCompany.RegNum}}</strong>
            </div>
            <div class="col-8">
                <strong>{{selectedCompany.Address}}</strong>
            </div>
            <div class="col-3 d-flex justify-content-end align-items-right">
                <strong>TEL :{{selectedCompany.ContactNo}}</strong>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-12">
                <u><strong>PAYMENT VOUCHER</strong></u>
            </div>
            <div class="col-8">
                <p class="m-0"> A/C : <strong>{{selectedFormJ.ReceiptNumber}}</strong></p>
                <p class="m-0">Pay To: <strong>{{selectedCustomer.Name |uppercase}}</strong></p>
                <p class="m-0">I/C: <strong>{{sharedService.processICNum(selectedCustomer.ICNumber)}}</strong></p>
            </div>
            <div class="col-3 d-flex justify-content-end align-items-right">
                <p class="display-inline">Date:
                    <span><strong>__________________</strong></span>
                </p>

            </div>
        </div>

        <table class="table m-0 p-0">
            <thead>
                <tr>
                    <th class="text-center">PARTICULARS</th>
                    <th class="text-center">AMOUNT (RM) </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <i>PERSONAL LOAN FOR </i>
                        <span class="text-center"> <i>{{selectedFormJ.ReceiptNumber}}</i></span>
                    </td>
                    <th class="text-center">{{selectedFormJ.Principal}}</th>
                </tr>
                <tr>
                    <td></td>
                    <th class="text-center"></th>
                </tr>
                <tr>
                    <td></td>
                    <th class="text-center"></th>
                </tr>
                <tr>
                    <td></td>
                    <th class="text-center"></th>
                </tr>
                <tr>
                    <td> </td>
                    <th class="text-center"></th>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td> <i>(CASH/CHEQUE NO.)</i></td>
                    <td class="text-center" style="border-bottom:double  black;">
                        <strong>{{selectedFormJ.Principal}}</strong>
                    </td>
                </tr>
            </tfoot>
        </table>

        <table class="table mt-5 border-none">
            <tr>
                <td>Authorised by:</td>
                <td style="width:30vw;border-bottom: 2px solid black;"></td>
                <td>Receive by:</td>
                <td style="width:30vw;border-bottom: 2px solid black;"></td>
                <td>*</td>
            </tr>
        </table>
    </div>
</div>