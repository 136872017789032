import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Account } from 'src/model/account.model';
import { TransactionRequest } from 'src/model/transactionRequest.model';

@Component({
  selector: 'app-add-assigned-transaction-into-account',
  templateUrl: './add-assigned-transaction-into-account.component.html',
  styleUrls: ['./add-assigned-transaction-into-account.component.css']
})
export class AddAssignedTransactionIntoAccountComponent implements OnInit {

  constructor(private activeModal: NgbActiveModal, public sharedService: SharedService, public enums: Enums, private restApi: RestApiService, private constructApi: ConstructAPI) { }
  @Input() transactionRequest: TransactionRequest;
  accountDate: Date;
  selectedAccountId: number;

  disabledSubmit = false;

  companyAccountList: Account[] = new Array;
  ngOnInit(): void {
    var companyId = sessionStorage.getItem("companyId");
    this.getAllAccountsOfCompany(Number(companyId));
  }


  private getAllAccountsOfCompany(companyId: number) {

    var xhttp = this.restApi.getRequest(this.constructApi.getAccountsOfCompany(companyId))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let account = new Account(json.id, json.number, json.name, json.holderName, json.balance, json.companyId, json.username);


          var isStaff = (Number(sessionStorage.getItem("roleId")) === Number(this.enums.STAFF)) ? true : false;

          var loggedInUser = sessionStorage.getItem("username");
          if (json.username == undefined) {
            if (!isStaff)
              this.companyAccountList.push(account);
          }
          else
            this.companyAccountList.push(account);
        }
        );

      }
    };
  }


  submit() {
    if (this.selectedAccountId == undefined || this.accountDate == undefined)
      this.sharedService.openAlert("Please fill in all necessary fields: Date and Account", this.enums.DANGER_ALERT);
    else {
      this.disabledSubmit = true;

      var data = {
        "date": this.accountDate.toISOString(),
        "accountId": Number(this.selectedAccountId),
        "remark": this.transactionRequest.Remark
      }
      var xhr = this.restApi.postRequest(this.constructApi.getAddAssignedTransactionToAccount(this.transactionRequest.ID), data)
      xhr.onreadystatechange = () => {
        if (xhr.readyState == 4) {
          this.disabledSubmit = false;
          //if success , then proceed
          if (xhr.status == 200) {
            this.closeModal();
            window.location.reload();
          }
          else {
            //show error message
            this.sharedService.openAlert(JSON.parse(xhr.responseText).error, this.enums.DANGER_ALERT)

          }
        }
      }
    }
  }

  calculateTotalAmount() {
    var sum = 0
    for (let i = 0; i < this.transactionRequest.TransactionItems.length; i++) {
      sum += this.transactionRequest.TransactionItems[i].amount;
    }

    return sum
  }
  isBalanceInsufficient(balance: number) {
    if (this.calculateTotalAmount() > balance) {
      return true;
    }
    else
      return false;
  }

  closeModal() {
    this.activeModal.close();
  }

  setSelectedAccount(accountId: number) {

    if (accountId === this.selectedAccountId)
      return true
    else
      return false;
  }


  calculateTotal() {
    var sum = 0;

    for (let i = 0; i < this.transactionRequest.TransactionItems.length; i++) {
      sum += this.transactionRequest.TransactionItems[i].amount;
    }
    return sum
  }



}
