import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';

interface StockReport {
  schemeName: string,
  activeOutstanding: number,
  bdOutstanding: number,
  cpOutstanding: number,
  lbdOutstanding: number,
  stock: number,
  stockReceived: number,
}


@Component({
  selector: 'app-stock-component',
  templateUrl: './stock-component.component.html',
  styleUrls: ['./stock-component.component.css']
})
export class StockComponentComponent implements OnInit, OnChanges {
  @Input() selectedCompany: number;
  @Input() endDate: Date;

  @Input() needStatusOutstanding: boolean;
  @Output() passActualCashbookBalance: EventEmitter<any> = new EventEmitter();

  stockList: StockReport[] = new Array;
  cashBookAmount: number;

  isCollapsed: boolean = false;

  constructor(private router: Router, private restapi: RestApiService, private constructApi: ConstructAPI, private enums: Enums, private sharedService: SharedService) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if (this.selectedCompany != undefined) {
      this.getSchemeSummary(this.selectedCompany);
      this.getCashBookBalance(this.selectedCompany)
    }
  }

  getCashBookBalance(companyId: number) {
    this.cashBookAmount = 0;
    //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
    // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
    // var reduceASecDate = (this.endDate == undefined) ? new Date(Number(new Date()) - 1) : new Date(Number(this.endDate) - 1);
    var addADayDate = (this.endDate == undefined) ? new Date(Number(new Date()) + (3600 * 1000 * 24) - 1) : new Date(Number(this.endDate) + (3600 * 1000 * 24) - 1);

    var endDateStr = addADayDate.toISOString();


    var xhttp = this.restapi.getRequest(this.constructApi.getCompanyCashBookBalance(companyId, endDateStr));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var json = JSON.parse((xhttp.responseText));
        this.cashBookAmount = json.balance;

        this.passActualCashbookBalance.emit(this.cashBookAmount);

      }
    };
  }


  getSchemeSummary(companyId: number) {
    //clear stock list first
    this.stockList.splice(0, this.stockList.length);
    //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
    // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
    // var reduceASecDate = (this.endDate == undefined) ? new Date(Number(new Date()) - 1) : new Date(Number(this.endDate) - 1);
    var addADayDate = (this.endDate == undefined) ? new Date(Number(new Date()) + (3600 * 1000 * 24) - 1) : new Date(Number(this.endDate) + (3600 * 1000 * 24) - 1);

    var endDateStr = addADayDate.toISOString();


    var xhttp = this.restapi.getRequest(this.constructApi.getSchemeSummaries(companyId, endDateStr));
    xhttp.onreadystatechange = () => {

      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {

          var schemeSummary = {
            schemeName: json.scheme, activeOutstanding: json.activeOutstanding, bdOutstanding: json.bdOutstanding,
            cpOutstanding: json.cpOutstanding, lbdOutstanding: json.lbdOutstanding, stock: json.stock, stockReceived: json.stockReceived
          }
          this.stockList.push(schemeSummary);

          this.stockList.sort((a, b) => (a.schemeName > b.schemeName) ? 1 : -1);
        }
        );

        if (this.sharedService.isOperatingUser()) {
          this.processSchemePAndEPF();
        }
      }

    };
  }

  processSchemePAndEPF() {
    var schemePIndex = this.stockList.findIndex(scheme => scheme.schemeName == 'P');
    var schemeEPFIndex = this.stockList.findIndex(scheme => scheme.schemeName == 'EPF');

    if (schemeEPFIndex != -1) {
      if (schemePIndex != -1) {
        this.stockList[schemePIndex].activeOutstanding += Number(this.stockList[schemeEPFIndex].activeOutstanding);
        this.stockList[schemePIndex].bdOutstanding += Number(this.stockList[schemeEPFIndex].bdOutstanding);
        this.stockList[schemePIndex].cpOutstanding += Number(this.stockList[schemeEPFIndex].cpOutstanding);
        this.stockList[schemePIndex].lbdOutstanding += Number(this.stockList[schemeEPFIndex].lbdOutstanding);
        this.stockList[schemePIndex].stock += Number(this.stockList[schemeEPFIndex].stock);
        this.stockList[schemePIndex].stockReceived += Number(this.stockList[schemeEPFIndex].stockReceived);

        this.stockList.splice(schemeEPFIndex, 1);
      }
      else {
        this.stockList[schemeEPFIndex].schemeName = 'P';
      }

    }
  }

  roundTo2Dec(num: number) {

    return Math.round(num * 100) / 100

  }


  calculateTotalStock() {
    var total = 0;

    for (let i = 0; i < this.stockList.length; i++) {
      total += this.stockList[i].stock - this.stockList[i].stockReceived;
    }

    return total;
  }

  calculateTotalOutstanding() {
    var total = 0;

    for (let i = 0; i < this.stockList.length; i++) {
      total += this.stockList[i].activeOutstanding + this.stockList[i].bdOutstanding + this.stockList[i].cpOutstanding + this.stockList[i].lbdOutstanding;
    }

    return total;
  }

  calculateTotalActiveOutstanding() {
    var total = 0;
    for (let i = 0; i < this.stockList.length; i++) {
      total += this.stockList[i].activeOutstanding;
    }
    return total;
  }

  calculateTotalBDOutstanding() {
    var total = 0;
    for (let i = 0; i < this.stockList.length; i++) {
      total += this.stockList[i].bdOutstanding;
    }
    return total;
  }


  calculateTotalCPOutstanding() {
    var total = 0;
    for (let i = 0; i < this.stockList.length; i++) {
      total += this.stockList[i].cpOutstanding;
    }
    return total;
  }


  calculateTotalLBDOutstanding() {
    var total = 0;
    for (let i = 0; i < this.stockList.length; i++) {
      total += this.stockList[i].lbdOutstanding;
    }
    return total;
  }

  goToLoanSchemeReport(schemeName: string) {
    this.router.navigate(['/loanSchemeReport', this.selectedCompany, schemeName]);
  }

}
