import { Contact } from "./contact.model"
import { Loan } from "./loan.model"
import { GlobalCustomer } from "./globalCustomer.model"

export class LoanRequest {



    constructor(
        private _customerId: number,
        private _epfAmount: number,
        private _epfDate: Date,
        private _guarantors: Contact[],
        private _hasIAccount: boolean,
        private _id: number,
        private _loanInfo: Loan,
        private _processedRemark: string,
        private _profileId: number,
        private _remark: string,
        private _requestBy: string,
        private _requestOn: Date,
        private _updatedOn: Date,
        private _approved: boolean,
        private _loanId: number,
        private _companyCode?: string,
        private _customerIC?: string,
        private _customerName?: string,
        private _companyId?: number,
        private _processedBy?: string,
        private _processedOn?: Date,
        private _authorizedBy?: string,
        private _processRequiredRole?: number,
        private _priority?: number,
        private _verifiedBy?: string,
        private _verifiedOn?: Date,
        private _dob?: Date,
        private _otherId?: string,
        private _conflictedCustomerList?: GlobalCustomer[],
        private _conflictedGuarantorList?: GlobalCustomer[],
        private _conflictedTicketCustomerList?: LoanRequest[],
        private _sharedProfileId?: number,
        private _loanGroupId?: number) {

    }


    set ID(id: number) { this._id = id }
    get ID(): number { return this._id }

    set EpfAmount(epfAmount: number) { this._epfAmount = epfAmount }
    get EpfAmount(): number { return this._epfAmount }

    set CustomerId(customerId: number) { this._customerId = customerId }
    get CustomerId(): number { return this._customerId }

    set EPFDate(epfDate: Date) { this._epfDate = epfDate }
    get EPFDate(): Date { return this._epfDate }

    set Guarantors(guarantors: Contact[]) { this._guarantors = guarantors }
    get Guarantors(): Contact[] { return this._guarantors }


    set HasIAccount(hasIAccount: boolean) { this._hasIAccount = hasIAccount }
    get HasIAccount(): boolean { return this._hasIAccount }

    get LoanInfo(): Loan { return this._loanInfo }
    set LoanInfo(value: Loan) { this._loanInfo = value }

    get ProcessedRemark(): string {
        return this._processedRemark
    }
    set ProcessedRemark(value: string) {
        this._processedRemark = value
    }

    get ProfileId(): number {
        return this._profileId
    }
    set ProfileId(value: number) {
        this._profileId = value
    }

    get Remark(): string {
        return this._remark
    }
    set Remark(value: string) {
        this._remark = value
    }


    get UpdatedOn(): Date {
        return this._updatedOn
    }
    set UpdatedOn(value: Date) {
        this._updatedOn = value
    }
    get RequestOn(): Date {
        return this._requestOn
    }
    set RequestOn(value: Date) {
        this._requestOn = value
    }
    get RequestBy(): string {
        return this._requestBy
    }
    set RequestBy(value: string) {
        this._requestBy = value
    }


    set Approved(approved: boolean) { this._approved = approved }
    get Approved(): boolean { return this._approved }


    set LoanID(loanId: number) { this._loanId = loanId }
    get LoanID(): number { return this._loanId }

    get CompanyCode(): string { return this._companyCode }
    set CompanyCode(value: string) { this._companyCode = value }

    get CustomerIC(): string { return this._customerIC }
    set CustomerIC(value: string) { this._customerIC = value }

    get CustomerName(): string { return this._customerName }
    set CustomerName(value: string) { this._customerName = value }

    get CompanyId(): number { return this._companyId }
    set CompanyId(value: number) { this._companyId = value }


    get ProcessedBy(): string {
        return this._processedBy
    }
    set ProcessedBy(value: string) {
        this._processedBy = value
    }

    set ProcessedOn(processedOn: Date) { this._processedOn = processedOn }
    get ProcessedOn(): Date { return this._processedOn }


    get AuthorizedBy(): string {
        return this._authorizedBy
    }
    set AuthorizedBy(value: string) {
        this._authorizedBy = value
    }


    get ProcessRequiredRole(): number { return this._processRequiredRole }
    set ProcessRequiredRole(value: number) { this._processRequiredRole = value }

    get Priority(): number { return this._priority }
    set Priority(value: number) { this._priority = value }


    set VerifiedOn(verifiedOn: Date) { this._verifiedOn = verifiedOn }
    get VerifiedOn(): Date { return this._verifiedOn }


    get VerifiedBy(): string {
        return this._verifiedBy
    }
    set VerifiedBy(value: string) {
        this._verifiedBy = value
    }



    set DOB(dob: Date) { this._dob = dob }
    get DOB(): Date { return this._dob }


    get OtherId(): string {
        return this._otherId
    }
    set OtherId(value: string) {
        this._otherId = value
    }

    get ConflictedTicketCustomerList(): LoanRequest[] {
        return this._conflictedTicketCustomerList
    }
    set ConflictedTicketCustomerList(value: LoanRequest[]) {
        this._conflictedTicketCustomerList = value
    }
    get ConflictedCustomerList(): GlobalCustomer[] {
        return this._conflictedCustomerList
    }
    set ConflictedCustomerList(value: GlobalCustomer[]) {
        this._conflictedCustomerList = value
    }

    get ConflictedGuarantorList(): GlobalCustomer[] {
        return this._conflictedGuarantorList
    }
    set ConflictedGuarantorList(value: GlobalCustomer[]) {
        this._conflictedGuarantorList = value
    }

    get SharedProfileId(): number {
        return this._sharedProfileId
    }
    set SharedProfileId(value: number) {
        this._sharedProfileId = value
    }

    get LoanGroupId(): number {
        return this._loanGroupId
    }
    set LoanGroupId(value: number) {
        this._loanGroupId = value
    }




}