import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Company } from 'src/model/company.model';
import { TransactionRequest, TransactionRequestItem } from 'src/model/transactionRequest.model';
import { TransactionSubType } from 'src/model/transactionSubType.model';
import { TransactionType } from 'src/model/transactionType.model';

@Component({
  selector: 'app-add-transaction-request',
  templateUrl: './add-transaction-request.component.html',
  styleUrls: ['./add-transaction-request.component.css']
})
export class AddTransactionRequestComponent implements OnInit {

  transactionRequest: TransactionRequest;
  disabledSubmit = false;
  transactionTypeList: TransactionType[] = new Array;
  transactionSubtypeList: TransactionSubType[] = new Array;
  companyList: Company[] = new Array;
  transactionRequestItemList: TransactionRequestItem[] = new Array;

  amount: number;
  type: number;
  subtype: number;

  constructor(public sharedService: SharedService, public enums: Enums, private restApi: RestApiService, private constructApi: ConstructAPI, private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.transactionRequest = new TransactionRequest(undefined, undefined, undefined, undefined, undefined, undefined, undefined);
    this.getTransactionType();
    this.getAllCompanies();
  }


  addTransactionItem() {

    var amountError = this.amount == undefined && this.amount == 0;
    var typeError = this.type == undefined && this.type == 0;

    if (amountError || typeError)
      this.sharedService.openAlert("Please fill in all the fields correctly!", this.enums.DANGER_ALERT);
    else {
      var item = { amount: this.amount, type: this.type, subtype: this.subtype }
      this.transactionRequestItemList.push(item);

      this.amount = undefined;
      this.type = undefined;
    }
  }

  getTransactionType() {
    var xhttp = this.restApi.getRequest(this.constructApi.getAllTransactionTypes());
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          var type = new TransactionType(json.id, json.name);

          this.transactionTypeList.push(type);
        });

        this.transactionTypeList.sort((a, b) => ((a.ID > b.ID)) ? 1 : -1);
      }
    };

  }

  getTransactionSubTypes(type: number) {


    var xhttp = this.restApi.getRequest(this.constructApi.getTransactionSubTypes(type));

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {


        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let type = new TransactionSubType(json.id, json.name, json.type, json.assignable, json.enabled)
          if (type.Assignable == true)
            this.transactionSubtypeList.push(type);
        }
        );



      }
    };
  }


  addTransactionRequest() {

    this.disabledSubmit = true;
    var isValid = this.checkBeforeSendingRequest();
    if (isValid) {
      var data = {
        "remark": this.transactionRequest.Remark,
        "items": this.transactionRequestItemList,
        "companyId": Number(this.transactionRequest.CompanyId)
      };


      var xhr = this.restApi.postRequest(this.constructApi.getAddTransactionRequest(), data);
      xhr.onreadystatechange = () => {

        if (xhr.readyState == 4) {
          this.disabledSubmit = false;

          if (xhr.status == 200) {
            this.sharedService.openAlert("Added transaction request !", this.enums.SUCCESS_ALERT);
            window.location.reload();


          } else {
            this.disabledSubmit = false;
            this.sharedService.openErrorMessage(xhr);
          }
        }

      }

    }

    else {
      this.disabledSubmit = false;
      this.sharedService.openAlert("Please key in all details properly!", this.enums.DANGER_ALERT);
    }


  }

  checkBeforeSendingRequest() {
    var isCompanySelected = this.transactionRequest.CompanyId != undefined;

    var itemExisted = this.transactionRequestItemList.length > 0 && this.transactionRequestItemList != undefined;

    //var isAmountValid = this.transactionRequest.Amount != undefined && this.transactionRequest.Amount != 0;
    //var isTypeSelected = this.transactionRequest.Type != undefined;

    var isRemarkValid = this.transactionRequest.Remark != undefined && this.transactionRequest.Remark != "";

    return isCompanySelected && itemExisted && isRemarkValid;

  }

  private getAllCompanies() {
    var xhttp = this.restApi.getRequest(this.constructApi.getAllCompanies());

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {

          // create company instance with all response data
          let company = new Company(json.id, json.name, json.address, json.companyCode, json.contactNo);


          //add company instance into company list
          this.companyList.push(company);
        }
        );

      }
    };

  }


  setSelectedCompany(companyID: number) {
    if (companyID === this.transactionRequest.CompanyId)
      return true
    else
      return false;
  }



  reset() {
    this.activeModal.close();
  }
}
