<div class="card card-body shadow bg-warning p-2 m-0">
    <h2>Report Generation</h2>
    <h5>Kindly select your report criteria and click go to generate report. </h5>
    <hr />
    <div class="row">
        <div class="col-12 col-md-2">
            <div class="row p-0 d-flex justify-content-start align-items-left">
                <div class="col-6">
                    <p>Report</p>
                </div>
                <div class="col-12">
                    <select class="browser-default form-select" [(ngModel)]="selectedReport" (change)="resetReady()">
                        <optgroup label="Primary Report">
                            <!-- <option *ngFor="let primaryReport of primayReportType" [value]="account.ID"
                            [selected]="setSelectedAccount(account.ID)">{{primaryReport}}</option> -->
                            <ng-container *ngFor="let primaryReport of enums.reportString;let i=index">
                                <option *ngIf="i<5" [value]="i+1">
                                    {{primaryReport}}
                                </option>
                            </ng-container>

                        </optgroup>
                        <optgroup label="Other Report">
                            <ng-container *ngFor="let otherReport of enums.reportString;let i=index">
                                <option *ngIf="i>=5 && i <23" [value]="i+1">
                                    {{otherReport}}
                                </option>
                            </ng-container>
                        </optgroup>
                        <optgroup label="Admin Report"
                            *ngIf="sharedService.isAdmin()|| sharedService.isBossAccount() || sharedService.isFinance()">
                            <ng-container *ngFor="let adminReport of enums.reportString;let i=index">
                                <option *ngIf="i>=24 && i<25" [value]="i+1">
                                    {{adminReport}}
                                </option>
                            </ng-container>
                        </optgroup>
                        <optgroup label="Boss Only Report"
                            *ngIf="sharedService.isBossAccount()|| sharedService.isFinance()">
                            <ng-container *ngFor="let bossReport of enums.reportString;let i=index">
                                <option *ngIf="i>=25" [value]="i+1">
                                    {{bossReport}}
                                </option>
                            </ng-container>
                        </optgroup>
                    </select>
                </div>
            </div>
        </div>

        <div class="col-12 col-md-2" [hidden]="sharedService. isBranchAccountHandler()">
            <div class="row p-0 d-flex justify-content-start align-items-left">
                <div class="col-12">
                    <p>Group</p>
                </div>
                <div class="col-12">
                    <select class="browser-default form-select" [(ngModel)]="selectedCompanyGroup"
                        (change)=" getAllCompanies(selectedCompanyGroup)">
                        <option [value]="0">All</option>
                        <option *ngFor="let group of companyGroupList" [value]="group.id">{{group.name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-3"
            [hidden]="sharedService.isBranchAccountHandler() || this.selectedReport==enums.ASSET_SUMMARY_REPORT|| this.selectedReport==enums.USAGE_REPORT||this.selectedReport == enums.COMPANY_SUMMARY_REPORT||this.selectedReport == enums.OFFICE_CASH_REPORT">
            <div class="row p-0 d-flex justify-content-start align-items-left">
                <div class="col-12">
                </div>
                <!-- <div class="col-12">
                    <select class="browser-default form-select" [(ngModel)]="selectedCompany" (change)="resetReady()"
                        [disabled]="companyDisabled">
                        <option *ngFor="let company of companyList" [value]="company.ID">{{company.CompanyCode}}
                        </option>
                    </select>
                </div> -->
                <div class="col-12 pt-3">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Company</mat-label>
                        <input class="companyInput" type="text" placeholder="Pick one" aria-label="Company" matInput
                            [formControl]="myControl" [matAutocomplete]="auto" [(ngModel)]="selectedCompanyCode">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                            <mat-option *ngFor="let company of filteredCompany |async" [value]="company.CompanyCode"
                                (onSelectionChange)="setSelectedCompany(company.CompanyCode)">
                                {{company.CompanyCode}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                </div>
            </div>
        </div>

        <div class="col-12 col-md-2"
            [hidden]="selectedReport==enums.LIST_HUTANG || selectedReport==enums.STOCK ||selectedReport==enums.MIDMONTH_REPORT || this.selectedReport==enums.USAGE_REPORT||this.selectedReport == enums.FORMJ_REPORT||this.selectedReport == enums.CUSTOMER_RECORD_REPORT">
            <div class="row">
                <div class="col-12">
                    <p>Start Date</p>
                </div>
                <div class="col-12">
                    <mat-form-field class="full-width-formField p-0">
                        <input matInput readonly [matDatepicker]="startDatePicker" name="startDate"
                            [(ngModel)]="startDate" required (change)="resetReady()" [disabled]="isAll"
                            [max]="sharedService.getTodayDate()">
                        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #startDatePicker name="startDate"></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

        </div>

        <div class="col-12 col-md-2"
            [hidden]="selectedReport==enums.DAILY_REPORT ||selectedReport==enums.MIDMONTH_REPORT ||this.selectedReport==enums.USAGE_REPORT||this.selectedReport == enums.FORMJ_REPORT||this.selectedReport == enums.COMPANY_SUMMARY_REPORT||this.selectedReport == enums.CUSTOMER_RECORD_REPORT ||this.selectedReport == enums.OFFICE_CASH_REPORT">
            <div class="row">
                <div class="col-12">
                    <p>End Date</p>
                </div>
                <div class="col-12">
                    <mat-form-field class="full-width-formField p-0">
                        <input matInput readonly [matDatepicker]="endDatePicker" name="endDate" [(ngModel)]="endDate"
                            [min]="getMinDateForEndDate()" [disabled]="isAll" required (change)="resetReady()"
                            [max]="sharedService.getTodayDate()">
                        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #endDatePicker name="endDate"></mat-datepicker>
                    </mat-form-field>
                </div>

            </div>
        </div>
        <!-- <div class="col-12 col-md-3" *ngIf="selectedReport==enums.STOCK">
            <div class="form-check">
                <input type="checkbox" class="form-check-input" id="checkboxSummary" name="isAll"
                    [value]="isAll" [(ngModel)]="isAll">
                <label class="form-check-label" for="checkboxSummary"
                    style="color:red;font-weight:bold;font-size:1.2rem;cursor: pointer;">Summary</label>
            </div>
        </div> -->
        <div class="col-12 col-md-3" *ngIf="allowViewAll()">
            <div class="form-check" style="width: 100%!important;">
                <input type="checkbox" class="form-check-input" id="checkboxSummary" [(ngModel)]="isAll" name="isAll"
                    [value]="isAll" style="position: relative;cursor: pointer;">
                <label class="form-check-label" for="checkboxSummary"
                    style="font-weight: bold;color:red;font-size:1.2rem;top:-10px !important;cursor: pointer;">All</label>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col d-flex justify-content-end align-items-end">
            <button class="btn btn-info" (click)="generateReport()">Go </button>
        </div>
    </div>
</div>