import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Company } from 'src/model/company.model';

interface Report {
  companyId: number,
  companyCode: string,
  dateOperating: Date,
  activeOutstanding: number,
  bdOutstanding: number,
  customerCount: number,
  cpOutstanding: number,
  activeCount: number,
  warningCount: number,
  bdCount: number,
  cpCount: number,
  assetBalance: number,
  savingBalance: number,
  expense: number,
  duitLebihBalance: number,
  dlExpense: number,
  expenseTotal: number,
  stampTotalIn: number,
  scTotalIn: number,
  cashBalance: number,
  bankBalance: number,
  officeBalance: number,
  cashTotalIn: number,
  cashTotalOut: number,
  cashTotalBalance: number,
  stockTotal: number,
  activePercentage: number,
  bdPercentage: number,
  cpPercentage: number
}

@Component({
  selector: 'app-company-summary-report',
  templateUrl: './company-summary-report.component.html',
  styleUrls: ['./company-summary-report.component.css'],
  providers: [DatePipe]
})


export class CompanySummaryReportComponent implements OnInit {
  @Input() endDate: Date;
  // @Input() companyList: Company[] = new Array;
  @Input() companyGroupID: number;



  columnControl = new UntypedFormControl();
  columnList: string[] = ['companyCode', 'dateOperating', 'activeCount', 'activeOutstanding', 'bdOutstanding', 'cpOutstanding', 'stockTotal', 'activePercentage', 'bdPercentage', 'cpPercentage',
    "officeBalance", 'expenseTotal', 'expense', 'dlExpense', 'duitLebihBalance', 'assetBalance', 'savingBalance', "totalIn", "totalOut", "cashTotalBalance", "stampTotalIn", "scTotalIn"];
  selectedColumns = [];
  allSelected = false;

  reportList: Report[] = new Array;
  wholeReportList: Report[] = new Array;

  isCollapsed: boolean = false;

  routeSubscription: any;

  isBranchManager: boolean;

  displayedColumns: string[];
  dataSource = new MatTableDataSource;

  minCount: number;
  maxCount: number;

  allCompanyCodeList: string[] = new Array;
  companyControl = new UntypedFormControl();

  isSelectAll = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(public enums: Enums, private activatedRoute: ActivatedRoute, private datePipe: DatePipe, private router: Router, private restapi: RestApiService, private constructApi: ConstructAPI, public sharedService: SharedService) { }

  ngOnInit(): void {
    this.routeSubscription = this.activatedRoute.paramMap.subscribe(params => {

      this.endDate = (params.get('endDate') == undefined) ? undefined : new Date(params.get('endDate'));
      this.companyGroupID = (params.get('companyGroup') == undefined) ? undefined : Number(params.get('companyGroup'));

      if (this.companyGroupID == 0)
        this.companyGroupID = undefined

      this.displayedColumns = ['companyCode', 'dateOperating', 'activeCount', 'activeOutstanding', 'bdOutstanding', 'cpOutstanding', 'stockTotal',
        "officeBalance", 'expenseTotal', 'expense', 'dlExpense', 'duitLebihBalance', 'assetBalance', 'savingBalance', "cashTotalBalance", "stampTotalIn", "scTotalIn"];
      this.selectedColumns = this.displayedColumns;


      this.getCompaniesByGroup(this.companyGroupID);
      this.getCompaniesDetailsByGroup(this.companyGroupID);


      this.companyControl.setValue(this.allCompanyCodeList);
      this.companyControl.valueChanges.subscribe((companyCodeValue) => {
        this.dataSource.data = this.filterOptions(companyCodeValue);
      });

    });


  }

  onSelect() {
    this.displayedColumns = this.selectedColumns;
  }

  checkAllCompanyCode() {

    if (this.selectedAll)
      this.companyControl.setValue(this.allCompanyCodeList);
    else
      this.companyControl.setValue([]);
    

  }

  selectedAll() {
  
    this.isSelectAll = !this.isSelectAll;

  }

  // getCompanyData(companyList: Company[]) {
  //   this.reportList.splice(0, this.reportList.length);

  //   this.companyList = companyList;

  //   for (let i = 0; i < this.companyList.length; i++) {


  //     var index = this.reportList.findIndex(report => Number(report.companyId) === Number(this.companyList[i].ID));
  //     if (index == -1) {
  //       var report = {
  //         companyId: this.companyList[i].ID, companyCode: this.companyList[i].CompanyCode, dateOperating: this.companyList[i].DateOperating, activeOutstanding: 0, bdOutstanding: 0, customerCount: 0, cpOutstanding: 0, activeCount: 0, warningCount: 0, bdCount: 0, cpCount: 0, assetBalance: 0, savingBalance: 0, expenseBalance: 0, stampTotalIn: 0, scTotalIn: 0
  //       };
  //       this.reportList.push(report);
  //     }
  //     else {
  //       this.reportList[index].companyCode = this.companyList[i].CompanyCode;
  //       this.reportList[index].dateOperating = this.companyList[i].DateOperating;
  //     }

  //   }
  //   // this.getCompanyOutstandingSummaries();

  // }


  getCompaniesByGroup(companyGroup?: number) {

    //clear report list
    this.reportList = [];
    this.dataSource = new MatTableDataSource;

    // this.getCompanyDetails();

    var xhttp = (companyGroup == undefined)
      ? this.restapi.getRequest(this.constructApi.getAllCompanies()) :
      this.restapi.getRequest(this.constructApi.getCompanyGroupCompanies(companyGroup));

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          var company = json.companyCode.toString();

          this.allCompanyCodeList.push(company);

        }
        );
        this.allCompanyCodeList.sort((a, b) => (a < b) ? -1 : 1);
      }
    };
  }

  getCompaniesDetailsByGroup(companyGroup?: number) {

    //clear report list
    this.reportList = [];
    this.dataSource = new MatTableDataSource;

    // this.getCompanyDetails();

    var xhttp = (companyGroup == undefined)
      ? this.restapi.getRequest(this.constructApi.getAllCompanySummaires()) :
      this.restapi.getRequest(this.constructApi.getCompanyGroupSummaries(companyGroup));

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {

          var report = {
            companyId: json.id, companyCode: json.companyCode, dateOperating: json.dateOperating, activeOutstanding: json.activeOutstanding || 0, bdOutstanding: json.bdOutstanding || 0, customerCount: (Number(json.active) + Number(json.warning) + Number(json.baddebt) + Number(json.cp)) || 0,
            cpOutstanding: json.cpOutstanding || 0, activeCount: json.active || 0, warningCount: json.warning || 0, bdCount: json.badDebt || 0,
            cpCount: json.cp, assetBalance: 0, savingBalance: 0, expense: 0, dlExpense: 0, expenseTotal: 0, duitLebihBalance: 0, stampTotalIn: 0, scTotalIn: 0, cashBalance: json.cash || 0, bankBalance: json.bank || 0,
            officeBalance: (Number(json.cash) + Number(json.bank)) || 0, cashTotalIn: 0, cashTotalOut: 0, cashTotalBalance: 0, stockTotal: json.stock || 0,
            activePercentage: ((Number(json.activeOutstanding) / Number(json.stock) * 100)) || 0,
            bdPercentage: ((Number(json.bdOutstanding) / Number(json.stock) * 100)) || 0,
            cpPercentage: ((Number(json.cpOutstanding) / Number(json.stock) * 100)) || 0
          };
          this.reportList.push(report);

        }
        );

        this.getExpenseTotalLastMonth();
      }
    };
  }


  getExpenseTotalLastMonth() {

    var startDate = new Date(this.endDate);
    startDate.setDate(1);
    startDate.setMonth(startDate.getMonth() - 1);
    startDate.setHours(0, 0, 0, 0);

    var endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0, 23, 59, 59, 59);

    var startDateStr = startDate.toISOString();
    var endDateStr = endDate.toISOString();


    //expense total = expense balance + dl expense
    var xhttp = (this.companyGroupID == undefined)
      ? this.restapi.getRequest(this.constructApi.getTransactionDetails(startDateStr, endDateStr, [this.enums.EXPENSE])) :
      this.restapi.getRequest(this.constructApi.getTransactionDetails(startDateStr, endDateStr, [this.enums.EXPENSE], this.companyGroupID));

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          var index = this.reportList.findIndex(report => Number(report.companyId) === Number(json.companyId));

          this.reportList[index].expense = (json.out == undefined) ? 0 : Number(json.out);

        }
        );
        this.getDLExpenseTotalLastMonth();
      }

    };
  }

  getDLExpenseTotalLastMonth() {

    var startDate = new Date(this.endDate);
    startDate.setDate(1);
    startDate.setMonth(startDate.getMonth() - 1);
    startDate.setHours(0, 0, 0, 0);

    var endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0, 23, 59, 59, 59);

    var startDateStr = startDate.toISOString();
    var endDateStr = endDate.toISOString();


    //expense total = expense balance + dl expense
    var xhttp = (this.companyGroupID == undefined)
      ? this.restapi.getRequest(this.constructApi.getTransactionDetails(startDateStr, endDateStr, [this.enums.EXTRAS])) :
      this.restapi.getRequest(this.constructApi.getTransactionDetails(startDateStr, endDateStr, [this.enums.EXTRAS], this.companyGroupID));

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          var index = this.reportList.findIndex(report => Number(report.companyId) === Number(json.companyId));

          this.reportList[index].dlExpense = (json.out == undefined) ? 0 : Number(json.out);
          this.reportList[index].expenseTotal = this.reportList[index].dlExpense + this.reportList[index].expense;

        }
        );
        this.getCompaniesTransactionBalances();
      }

    };
  }






  getLastYearsSavingBalance() {
    if (this.endDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var lastYearDate = new Date(this.endDate.getFullYear(), 0, 1);
    var endDateStr = (this.endDate == undefined) ? undefined : lastYearDate.toISOString();

    var types = [this.enums.SAVING];
    var xhttp = (this.companyGroupID == undefined)
      ? this.restapi.getRequest(this.constructApi.getTransactionBalances(undefined, endDateStr, types))
      : this.restapi.getRequest(this.constructApi.getTransactionBalances(this.companyGroupID, endDateStr, types));

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          var index = this.reportList.findIndex(report => Number(report.companyId) === Number(json.companyId));

          this.reportList[index].savingBalance -= Number(json.balance);

        }
        );

        this.getCompanyTransactionStampTotalDetails();

      }
    };
  }

  getCompaniesTransactionBalances() {
    if (this.endDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var addADayDate = new Date(Number(this.endDate) + (3600 * 1000 * 24) - 1);
    var endDateStr = (this.endDate == undefined) ? undefined : addADayDate.toISOString();

    var types = [this.enums.ASSET, this.enums.SAVING, this.enums.EXTRAS];
    var xhttp = (this.companyGroupID == undefined)
      ? this.restapi.getRequest(this.constructApi.getTransactionBalances(undefined, endDateStr, types))
      : this.restapi.getRequest(this.constructApi.getTransactionBalances(this.companyGroupID, endDateStr, types));

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          var index = this.reportList.findIndex(report => Number(report.companyId) === Number(json.companyId));

          switch (Number(json.type)) {
            case this.enums.SAVING:
              this.reportList[index].savingBalance = Number(json.balance);
              break;
            case this.enums.ASSET:
              this.reportList[index].assetBalance = Number(json.balance);
              break;
            case this.enums.EXTRAS:
              this.reportList[index].duitLebihBalance = Number(json.balance);
              break;

          }
        }
        );

        this.getLastYearsSavingBalance();

      }
    };
  }

  getCompanyTransactionStampTotalDetails() {

    var startDate = new Date(this.endDate);
    startDate.setDate(1);
    startDate.setMonth(startDate.getMonth() - 1);
    startDate.setHours(0, 0, 0, 0);

    var endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0, 23, 59, 59, 59);
    var startDateStr = startDate.toISOString();
    var endDateStr = endDate.toISOString();

    var xhttp = (this.companyGroupID == undefined)
      ? this.restapi.getRequest(this.constructApi.getTransactionDetails(startDateStr, endDateStr, [this.enums.STAMP]))
      : this.restapi.getRequest(this.constructApi.getTransactionDetails(startDateStr, endDateStr, [this.enums.STAMP], this.companyGroupID));

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          var index = this.reportList.findIndex(report => Number(report.companyId) === Number(json.companyId));
          this.reportList[index].stampTotalIn = Number(json.in);

        }
        );
        this.getCompanyTransactionSCTotalDetails();
      }

    };
  }

  getCompanyTransactionSCTotalDetails() {

    var startDate = new Date(this.endDate);
    startDate.setDate(1);
    startDate.setMonth(startDate.getMonth() - 1);
    startDate.setHours(0, 0, 0, 0);

    var endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0, 23, 59, 59, 59);
    var startDateStr = startDate.toISOString();
    var endDateStr = endDate.toISOString();

    var xhttp = (this.companyGroupID == undefined)
      ? this.restapi.getRequest(this.constructApi.getTransactionDetails(startDateStr, endDateStr, [this.enums.SERVICE_CHARGE]))
      : this.restapi.getRequest(this.constructApi.getTransactionDetails(startDateStr, endDateStr, [this.enums.SERVICE_CHARGE], this.companyGroupID));

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          var index = this.reportList.findIndex(report => Number(report.companyId) === Number(json.companyId));
          this.reportList[index].scTotalIn = Number(json.in);

        }
        );


        this.getCompanyTotalInOut();
      }

    };
  }

  getCompanyTotalInOut() {

    var startDate = new Date(this.endDate);
    startDate.setDate(1);
    startDate.setMonth(startDate.getMonth());
    startDate.setHours(0, 0, 0, 0);

    var endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0, 23, 59, 59, 59);
    var startDateStr = startDate.toISOString();
    var endDateStr = endDate.toISOString();

    var xhttp = this.restapi.getRequest(this.constructApi.getTransactionBalanceDetails(undefined, undefined, startDateStr, endDateStr, this.companyGroupID));

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          var index = this.reportList.findIndex(report => Number(report.companyId) === Number(json.companyId));


          if (index != -1) {
            var cashIn = (json.in == undefined) ? 0 : Number(json.in);
            this.reportList[index].cashTotalIn += cashIn;

            var cashOut = (json.out == undefined) ? 0 : Number(json.out);
            this.reportList[index].cashTotalOut += cashOut;

            this.reportList[index].cashTotalBalance += cashIn + cashOut;

          }
        }
        );

        this.setDatasource();

      }

    };
  }


  setDatasource() {
    //make report list as the datasource
    this.dataSource = new MatTableDataSource<Report>(this.reportList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  toReport(selectedReport: number, companyId: number) {

    var startDate;
    var endDate;


    switch (Number(selectedReport)) {
      case Number(this.enums.SAVING_REPORT):
        startDate = new Date(this.endDate.getFullYear(), 0, 1, 0, 0, 0, 0);
        endDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate(), 23, 59, 59, 59);
        break;
      case Number(this.enums.ASSET_REPORT):
        startDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), 1, 0, 0, 0, 0);
        endDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate(), 23, 59, 59, 59);
        break;
      case Number(this.enums.EXTRA_REPORT):
        startDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), 1, 0, 0, 0, 0);
        endDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate(), 23, 59, 59, 59);
        break;
      //FOR EXPENSES, SC AND STAMP REPORT
      default:
        startDate = new Date(this.endDate);
        startDate.setDate(1);
        startDate.setMonth(startDate.getMonth() - 1);
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0, 0, 0, 0, 0);
        break;


    }

    var startDateStr = startDate.toISOString();
    var endDateStr = endDate.toISOString();

    if (selectedReport == this.enums.EXTRA_REPORT)
      this.router.navigate(['/duitLebihReport', companyId, startDateStr, endDateStr]);
    else
      this.router.navigate(['/simpleReport', companyId, selectedReport, startDateStr, endDateStr]);
  }

  toCustomerList(companyId: number) {
    this.router.navigate(['/customerList', companyId]);
  }
  toListHutang(companyId: number) {
    var endDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate(), 23, 59, 59, 59);
    this.router.navigate(['/listHutang', companyId, endDate.toISOString()]);
  }


  calculateTotalActiveCount() {
    // console.log(this.dataSource.data);

    let data: Report[] = <Report[]>this.dataSource.data;
    var sum = 0;
    for (let i = 0; i < data.length; i++) {
      sum += data[i].activeCount;
    }
    return sum;
  }

  calculateTotalActiveOutstanding() {
    let data: Report[] = <Report[]>this.dataSource.data;
    var sum = 0;
    for (let i = 0; i < data.length; i++) {
      sum += data[i].activeOutstanding;
    }
    return sum;
  }

  calculateTotalBDOutstanding() {
    let data: Report[] = <Report[]>this.dataSource.data;
    var sum = 0;
    for (let i = 0; i < data.length; i++) {
      sum += data[i].bdOutstanding;
    }
    return sum;
  }

  calculateTotalCPOutstanding() {
    let data: Report[] = <Report[]>this.dataSource.data;
    var sum = 0;
    for (let i = 0; i < data.length; i++) {
      sum += data[i].cpOutstanding;
    }
    return sum;
  }

  calculateTotalOfficeBalance() {
    let data: Report[] = <Report[]>this.dataSource.data;
    var sum = 0;
    for (let i = 0; i < data.length; i++) {
      sum += data[i].officeBalance;
    }
    return sum;
  }

  calculateTotalExpenses() {
    let data: Report[] = <Report[]>this.dataSource.data;
    var sum = 0;
    for (let i = 0; i < data.length; i++) {
      sum += data[i].expenseTotal;
    }
    return sum;
  }

  calculateTotalAsset() {
    let data: Report[] = <Report[]>this.dataSource.data;
    var sum = 0;
    for (let i = 0; i < data.length; i++) {
      sum += data[i].assetBalance;
    }
    return sum;
  }

  calculateTotalSaving() {
    let data: Report[] = <Report[]>this.dataSource.data;
    var sum = 0;
    for (let i = 0; i < data.length; i++) {
      sum += data[i].savingBalance;
    }
    return sum;
  }

  calculateTotalIn() {
    let data: Report[] = <Report[]>this.dataSource.data;
    var sum = 0;
    for (let i = 0; i < data.length; i++) {
      sum += data[i].cashTotalIn;
    }
    return sum;
  }

  calculateTotalOut() {
    let data: Report[] = <Report[]>this.dataSource.data;
    var sum = 0;
    for (let i = 0; i < data.length; i++) {
      sum += data[i].cashTotalOut;
    }
    return sum;
  }
  calculateTotalStamping() {
    let data: Report[] = <Report[]>this.dataSource.data;
    var sum = 0;
    for (let i = 0; i < data.length; i++) {
      sum += data[i].stampTotalIn;
    }
    return sum;
  }


  calculateTotalSC() {
    let data: Report[] = <Report[]>this.dataSource.data;
    var sum = 0;
    for (let i = 0; i < data.length; i++) {
      sum += data[i].scTotalIn;
    }
    return sum;
  }

  calculateTotalExpensesOnly() {
    let data: Report[] = <Report[]>this.dataSource.data;
    var sum = 0;
    for (let i = 0; i < data.length; i++) {
      sum += data[i].expense;
    }
    return sum;
  }

  calculateTotalDLExpense() {
    let data: Report[] = <Report[]>this.dataSource.data;
    var sum = 0;
    for (let i = 0; i < data.length; i++) {
      sum += data[i].dlExpense;
    }
    return sum;
  }

  calculateTotalDLBalance() {
    let data: Report[] = <Report[]>this.dataSource.data;
    var sum = 0;
    for (let i = 0; i < data.length; i++) {
      sum += data[i].duitLebihBalance;
    }
    return sum;
  }



  getLastMonth() {
    if (this.endDate.getMonth() < 10)
      return "0" + this.endDate.getMonth() + "/" + this.endDate.getFullYear();
    else
      return this.endDate.getMonth() + "/" + this.endDate.getFullYear();
  }





  filterOptions(companyCode: string[]): Report[] {

    if ((!companyCode || companyCode.length === 0)) {
      return this.reportList;
    }
    const filtered = this.reportList.filter((report) => {
      return (companyCode ? companyCode.indexOf(report.companyCode + '') !== -1 : false)
    });
    return filtered;
  }


}
