import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(private _authService: AuthService, private router: Router) { }

  canActivate(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot): boolean {


    // if (this.router.url != "/loginPage") {
    if (this._authService.loggedIn()) {
      return true
    }
    else {
      //if user access page by using an URL and user is not authenticated
      //save the URL as return URL to redirect after login
      if (state != undefined)
        this.router.navigate(['/loginPage'], { queryParams: { returnUrl: state.url } });
      else
        this.router.navigate(['/loginPage'])
      return false
    }

    // } else {
    //   if (!this._authService.loggedIn()) {
    //     return false
    //   }
    //   else {
    //     this.router.navigate(['/loginPage'])
    //     return true
    //   }

    // }

  }


}
