<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Quick Calculate EPF Settlement</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>


    <div class="modal-body">
        <div class="row">
            <div class="col-10 p-2" style="background-color:lavender ;">
                <mat-slide-toggle class="mat-slide-toggle-lg" [hideIcon]="true" color="primary" [(ngModel)]="isExtra"
                    name="isExtra" (ngModelChange)="changeInterestRate(true)">
                    EXTRA
                </mat-slide-toggle>
            </div>
            <div class="col-2">
                <button class="btn btn-dark" (click)="searchCustomer()" *ngIf="isExtra"><i
                        class="fas fa-search"></i></button>
            </div>
        </div>

        <app-epf-limit-information [previousEPFLoans]="previousEPFLoans" [isEPFExtra]="isExtra" [epfDate]="epfDate"
            [epfAmount]="epfAmount"
            *ngIf="epfDate!=undefined && epfAmount!=undefined && epfAmount >= enums.epfAmountCategory[sharedService.checkEPFAmountLimitCategory(epfDate)].MinimumEPFAmount"
            [epfLimitRuleSet]="epfLimitRuleSet"></app-epf-limit-information>


        <div class="p-1 m-1" style="background-color: red;"
            *ngIf="epfDate!=undefined && epfAmount!=undefined &&  
            enums.epfAmountCategory[sharedService.checkEPFAmountLimitCategory(epfDate)].MinimumEPFAmount!=undefined 
            && epfAmount < enums.epfAmountCategory[sharedService.checkEPFAmountLimitCategory(epfDate)].MinimumEPFAmount">
            <span class="fw-bold" style="color:white">
                TERM {{ enums.epfAmountCategory[sharedService.checkEPFAmountLimitCategory(epfDate)].LowerLimit}} - {{
                enums.epfAmountCategory[sharedService.checkEPFAmountLimitCategory(epfDate)].UpperLimit}} months AND EPF
                Amount less than RM {{
                enums.epfAmountCategory[sharedService.checkEPFAmountLimitCategory(epfDate)].MinimumEPFAmount
                |number:'1.0-2'}} is not
                allowed</span>
        </div>



        <div class="row">

            <div class="col-6">
                <mat-form-field class="halfWidth bar">
                    <input matInput class="textInputLong" [matDatepicker]="myDatepicker" [(ngModel)]="epfDate"
                        placeholder="EPF Settlement Date" [min]="sharedService.getTodayDate()" name="date"
                        [max]="sharedService.getMaxDateForEPF()" onkeydown="false" (ngModelChange)="calculateLoanTerm()"
                        [disabled]="isExtra">
                    <mat-datepicker-toggle matSuffix [for]=" myDatepicker">
                    </mat-datepicker-toggle>
                    <mat-datepicker #myDatepicker name="date"></mat-datepicker>

                </mat-form-field>


            </div>
            <div class="col-6">
                <mat-form-field class="halfWidth bar">
                    <mat-label>EPF Amount (RM)</mat-label>
                    <input matInput type="number" required class="textInputLong" placeholder="epfAmount" name="amount"
                        [(ngModel)]="epfAmount" min=0 (keypress)="sharedService.isNumberKey($event)"
                        [disabled]="isExtra">


                </mat-form-field>
            </div>
        </div>
        <div class="row" *ngIf="isExtra==true">
            <div class="col-6">
                <mat-form-field class="halfWidth bar">
                    <mat-label>Loan Amount (RM)</mat-label>
                    <input matInput type="number" required class="textInputLong" placeholder="loanAmount"
                        name="loanAmount" [(ngModel)]="loanAmount" min=0 (keypress)="sharedService.isNumberKey($event)">
                </mat-form-field>
                <span class="badge text-bg-danger" style="font-size:0.8rem"
                    *ngIf="loanAmount!=undefined && loanAmount%50!=0">Make sure PRINCIPAL is multiple of 50</span>
                <span class="badge text-bg-danger ms-1" style="font-size:0.8rem" *ngIf="epfDate!=undefined && epfAmount!=undefined && loanAmount > sharedService.getEPFLimitPrincipal(epfDate,
                    epfAmount,epfLimitRuleSet) -
                    sharedService.calculatePreviousEPFLoanPrincipal(previousEPFLoans)">Principal
                    over limit! </span>

            </div>
            <ng-container *ngIf="loanAmount>0 && loanAmount!=undefined">

                <div class="col-6" *ngIf="loanAmount>0 && loanAmount!=undefined">
                    <mat-form-field class="halfWidth bar">
                        <mat-label>Settlement Extra Charges </mat-label>
                        <input matInput type="number" required class="textInputLong" placeholder="Extra Charges"
                            name="extraChargesCollected" [(ngModel)]="extraChargesCollected" min=0
                            (keypress)="sharedService.isNumberKey($event)" (keyup)="getSettlementCharges()">

                    </mat-form-field>
                    <p style="color: crimson;" *ngIf="extraChargesCollected!=0 && extraChargesCollected >= loanAmount">
                        Extra Charges not reasonable! Cannot be more than principal</p>
                    <p style="color: crimson;"
                        *ngIf="extraChargesCollected!=undefined && extraChargesCollected < (this.enums.MIN_SSC_PER_TERM *loanTerm)">
                        Extra Charges not reasonable! Cannot be less than minimum charges of
                        (RM{{this.enums.MIN_SSC_PER_TERM}} x {{loanTerm}} =RM
                        {{this.enums.MIN_SSC_PER_TERM *loanTerm}})</p>

                    <p class=" text-info"><strong>Suggested Charges : RM
                            {{sharedService.calculateMaxTotalExtraCharges(loanAmount,
                            loanTerm) | number:'1.2-2'}}</strong></p>
                </div>

                <div class="col-12 card "
                    *ngIf="settlementCharges!=undefined && extraChargesCollected >= enums.MIN_SSC_PER_TERM*loanTerm">
                    <div class="card-body shadow-lg">
                        <p>Settlement Stamp : RM {{settlementStamp/loanTerm|number:'1.0-2'}} x {{loanTerm}} = RM
                            {{settlementStamp}}
                        </p>
                        <p>Settlement SC : RM {{settlementSC/loanTerm|number:'1.0-2'}} x {{loanTerm}} = RM
                            {{settlementSC}}</p>
                        <p> Settlement Duit Lebih : RM {{settlementExtras/loanTerm |number:'1.0-2'}} x {{loanTerm}} = RM
                            {{settlementExtras}}
                        </p>
                    </div>
                </div>

            </ng-container>
        </div>
        <hr />

        <div class="card" *ngIf="isExtra==true">
            <div class="card-body shadow-lg">
                <h6 class="fw-bold">Deductions
                    <span>
                        <button class="btn btn-dark" (click)="isCollapsed=true" *ngIf="isCollapsed==false"><i
                                class="fas fa-caret-up"></i>
                        </button>
                        <button class="btn btn-dark" (click)="isCollapsed=false" *ngIf="isCollapsed==true"><i
                                class="fas fa-caret-down"></i>
                        </button>
                    </span>
                </h6>
                <div id="collapseExample" [ngbCollapse]="isCollapsed">
                    <div class="row">

                        <div class="col-12 m-1">
                            <p>Stamp : {{processStamp}}</p>
                            <p>Service Charge : {{processSc}}</p>
                            <p>Ads : {{loanAmount*0.02}}</p>
                            <mat-form-field class="halfWidth bar">
                                <mat-label>Duit Lebih (RM)</mat-label>
                                <input matInput type="number" required class="textInputLong" placeholder="Duit Lebih"
                                    name="processDL" [(ngModel)]="processDL" min=0
                                    (keypress)="sharedService.isNumberKey($event)">

                            </mat-form-field>
                        </div>
                    </div>
                </div>

            </div>
        </div>




        <div class="row" *ngIf="isExtra==true">
            <hr />
            <div class="col-12  mb-2">
                <h4>Loan Term:</h4>
                <div class="card shadow-sm" style="background-color: #CBC3E3;">
                    <div class="card-body">
                        <h3 class="fw-bold"> {{loanTerm}} month(s) | Int: {{MINIMUM_INTEREST*100}}% </h3>
                    </div>
                </div>
            </div>
            <div class="col-12 mb-2">
                <h4>Minimum Settlement:</h4>
                <div class="card shadow-sm" style="background-color: #CBC3E3;">
                    <div class="card-body">
                        <h3 class="fw-bold">RM {{calculateTotalSettlement() + calculateHandlingCharge()+
                            calculateTotalCharges()||0|number:'1.0-2'}} |
                            <span class="fw-bold"
                                [ngClass]="{'text-danger':checkIfHittingRatioLimit(epfDate,epfAmount)==true}">
                                Ratio: {{((calculateTotalSettlement() +calculateHandlingCharge()+
                                calculateTotalCharges())/this.epfAmount)*100||0|number:'1.0-4'}}%
                            </span>
                        </h3>
                        <p class="m-0 p-0 font-italic">(RM{{calculateTotalSettlement()|number:'1.2-2'}} +

                            <span *ngIf="isExtra==true">RM{{calculateHandlingCharge()|number:'1.2-2'}} + </span>
                            RM{{calculateTotalCharges() |number:'1.2-2'}})
                        </p>

                    </div>
                </div>
            </div>


            <div class="col-12  mb-2" *ngIf="loanAmount!=undefined && loanAmount>0">
                <h4>On Hand:</h4>
                <div class=" card shadow-sm" style="background-color:  #a7d4f1 ;">
                    <div class="card-body">
                        <h3 class="fw-bold">
                            RM {{loanAmount - processStamp - processDL - processSc - loanAmount*0.10 -
                            (enums.MINIMUM_ADS_RATE*loanAmount)|number:'1.0-2'}}
                        </h3>
                    </div>
                </div>
            </div>
        </div>


        <div class="row"
            *ngIf="epfDate!=undefined && this.epfAmount!=undefined && isExtra ==false &&  
            enums.epfAmountCategory[sharedService.checkEPFAmountLimitCategory(epfDate)].MinimumEPFAmount!=undefined 
            && epfAmount >= enums.epfAmountCategory[sharedService.checkEPFAmountLimitCategory(epfDate)].MinimumEPFAmount">
            <div class="col-12">
                <span class="text-danger" *ngIf="cashOnHand==undefined|| cashOnHand==0"><strong>Please key in CASH ON
                        HAND</strong></span>
                <mat-form-field class="halfWidth bar">
                    <mat-label>Cash on hand</mat-label>
                    <input matInput type="number" required class="textInputLong" placeholder="loanAmount"
                        name="cashOnHand" [(ngModel)]="cashOnHand" min=0 (keypress)="sharedService.isNumberKey($event)"
                        (ngModelChange)="loanAmount= sharedService.calculateRoundedPrincipalByOnHand(cashOnHand)">
                </mat-form-field>
                <span class="badge text-bg-danger" style="font-size:0.8rem"
                    *ngIf="cashOnHand!=undefined && cashOnHand%100!=0">Make sure CASH ON
                    HAND is multiple of
                    100</span>
                <span class="badge text-bg-danger ms-1" style="font-size:0.8rem"
                    *ngIf="epfDate!=undefined && epfAmount!=undefined && cashOnHand > sharedService.getEPFLimitCashOnHand(epfDate,epfAmount,sharedService.getEPFLimitFirstLoan(epfDate,epfAmount,epfLimitRuleSet))">Cash
                    On Hand over limit!</span>

            </div>

            <hr />
            <div class="col-12  mb-2">
                <h4>Principal:</h4>
                <div class=" card shadow-sm" style="background-color:  #a7d4f1 ;">
                    <div class="card-body">
                        <h4 class="fw-bold">
                            RM {{ this.loanAmount||0|number:'1.0-2'}}
                        </h4>
                    </div>
                </div>
            </div>
            <div class="col-12 mb-2" *ngIf="epfDate!=undefined && epfAmount!=undefined">
                <h4>Minimum Settlement:</h4>
                <div class="card shadow-sm" style="background-color: #dedbe7;">
                    <div class="card-body">
                        <h4><span class="badge text-bg-primary"> {{loanTerm}} month(s) | Int: {{MINIMUM_INTEREST*100}}%
                            </span>
                        </h4>

                        <h3 class="fw-bold">RM {{(calculateTotalSettlement() +
                            calculateTotalCharges())||0|number:'1.0-2'}} |
                            <span class="fw-bold"
                                [ngClass]="{'text-danger':checkIfHittingRatioLimit(epfDate,epfAmount)==true}">
                                Ratio: {{((calculateTotalSettlement() +
                                calculateTotalCharges())/this.epfAmount)*100||0|number:'1.0-4'}}%
                            </span>
                        </h3>
                        <p class="m-0 p-0 font-italic">(RM {{calculateTotalSettlement()|number:'1.2-2'}} +
                            RM{{calculateTotalCharges() |number:'1.2-2'}})</p>
                        <hr />
                        <div class="row mt-1" *ngIf="loanAmount>0 && loanAmount!=undefined">

                            <div class="col-12">
                                <mat-form-field class="halfWidth bar">
                                    <mat-label>Setttlement Extra Charges </mat-label>
                                    <input matInput type="number" name="extraChargesCollected"
                                        [(ngModel)]="extraChargesCollected" min="0"
                                        (keypress)="sharedService.isNumberKey($event)" (keyup)="getSettlementCharges()">
                                </mat-form-field>
                                <p style="color: crimson;"
                                    *ngIf="extraChargesCollected!=0 && extraChargesCollected >= loanAmount">
                                    Extra Charges not reasonable! Cannot be more than principal</p>
                                <p style="color: crimson;"
                                    *ngIf="extraChargesCollected!=undefined && extraChargesCollected < (this.enums.MIN_SSC_PER_TERM *loanTerm)">
                                    Extra Charges not reasonable! Cannot be less than minimum charges of
                                    (RM{{this.enums.MIN_SSC_PER_TERM}} x {{loanTerm}} =RM
                                    {{this.enums.MIN_SSC_PER_TERM *loanTerm}})</p>

                                <p class=" text-info"><strong>Suggested Charges : RM
                                        {{enums.MIN_SSC_PER_TERM * loanTerm| number:'1.0'}}</strong></p>
                            </div>
                            <div class="col-12"
                                *ngIf="settlementCharges!=undefined && extraChargesCollected >= enums.MIN_SSC_PER_TERM *loanTerm">
                                <p>Stamp : RM {{settlementStamp/loanTerm |number:'1.0-2'}} x {{loanTerm}} months= RM
                                    {{settlementStamp|number:'1.2-2'}}</p>
                                <p>SC : RM {{settlementSC/loanTerm |number:'1.0-2'}} x {{loanTerm}} months = RM
                                    {{settlementSC|number:'1.2-2'}}
                                </p>
                                <p>Duit Lebih : RM {{settlementExtras/loanTerm |number:'1.0-2'}} x {{loanTerm}} months =
                                    RM
                                    {{settlementExtras |number:'1.2-2'}}
                                </p>
                            </div>

                        </div>


                    </div>
                </div>
            </div>

            <div class="col-12 m-1">
                <div class="card">
                    <div class="card-body">
                        <h6><strong>Dedeuction Summary</strong></h6>
                        <p>Stamp : {{enums.MINIMUM_STAMP}}</p>
                        <p>Service Charge : {{enums.MINIMUM_SC}}</p>
                        <p>Ads : {{loanAmount*0.02}}</p>
                        <p>Duit Lebih : {{sharedService.calculateRoundedDLByOnHand(cashOnHand)}}</p>
                    </div>
                </div>
            </div>


        </div>

    </div>

</form>