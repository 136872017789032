import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddApprovedLoanComponent } from 'src/app/AddThings/add-approved-loan/add-approved-loan.component';
import { AddContactComponent } from 'src/app/AddThings/add-contact/add-contact.component';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { EditEpfDetailsComponent } from 'src/app/Customer/edit-epf-details/edit-epf-details.component';
import { DeleteReasonComponent } from 'src/app/Others/delete-reason/delete-reason.component';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { ShowWhatsappMessageClipboardComponent } from 'src/app/Shared/show-whatsapp-message-clipboard/show-whatsapp-message-clipboard.component';
import { Contact } from 'src/model/contact.model';
import { DuitLebih } from 'src/model/duitLebih.model';
import { Loan } from 'src/model/loan.model';
import { LoanRequest } from 'src/model/loanRequest.model';
import { AddLoanRequestGuarantorComponent } from '../add-loan-request-guarantor/add-loan-request-guarantor.component';
import { EditLoanRequestInfoComponent } from '../edit-loan-request-info/edit-loan-request-info.component';
import { EditLoanRequestRemarkComponent } from '../edit-loan-request-remark/edit-loan-request-remark.component';
import { ViewContactThumbnailsComponent } from '../../Images/view-contact-thumbnails/view-contact-thumbnails.component';
import { ViewThumbnailsComponent } from 'src/app/Images/view-thumbnails/view-thumbnails.component';
import { GlobalCustomer } from 'src/model/globalCustomer.model';
import { ConflictedCustomerListComponent as ConflictedCustomerListComponent } from 'src/app/Others/conflicted-customer-list/conflicted-customer-list.component';
import { CustomerLoanCardComponent } from 'src/app/Customer/customer-loan-card/customer-loan-card.component';
import { Customer } from 'src/model/customer.model';
import { Profile } from 'src/model/profile.model';
import { SettlementCharges } from 'src/model/settlementCharges.model';

@Component({
  selector: 'app-loan-request-details',
  templateUrl: './loan-request-details.component.html',
  styleUrls: ['./loan-request-details.component.css']
})
export class LoanRequestDetailsComponent implements OnInit {

  @Input() data: any;
  @Input() request: LoanRequest;
  @Input() isExpanded = false;
  @Input() onlyViewLoan = false;

  @Input() viewOnly = false;
  @Input() editable = true;
  @Input() allowAction = false;
  @Input() showPrevious = true;
  @Input() includeShared = false;


  @Input() isModal = false;
  previousSettlement: number = 0;
  previousSettlementRatio: number = 0;

  @Input() previousEPFLoanList: Loan[] = new Array;

  currentSettlementWithoutCharges: number;


  constructor(private activeModal: NgbActiveModal, public sharedService: SharedService, private restApi: RestApiService, private constructApi: ConstructAPI, private enums: Enums, private router: Router) { }

  ngOnInit(): void {
    if (this.data !== undefined) { 
      this.request = this.data.request ?? undefined; 
      this.onlyViewLoan = this.data.onlyViewLoan ?? undefined; 
      this.editable = this.data.editable ?? undefined; 
      this.viewOnly = this.data.viewOnly ?? undefined;
      this.isExpanded = this.data.isExpanded ?? undefined;
      this.onlyViewLoan = this.data.onlyViewLoan ?? undefined;
    }

    if (this.request != undefined) {

      if (this.showPrevious == true) {
        if (this.previousEPFLoanList.length == 0 || this.previousEPFLoanList == undefined) {
          this.getAllEPFLoans();


        }
        else {
          for (let i = 0; i < this.previousEPFLoanList.length; i++) {


            var settlementAmountWithoutCharge = this.previousEPFLoanList[i].Principal + (this.previousEPFLoanList[i].Term * this.previousEPFLoanList[i].Principal * (this.previousEPFLoanList[i].InterestRate / 100));

            if (this.previousEPFLoanList[i].Scheme == 'EPF') {
              if (this.previousEPFLoanList[i].SettlementCharges != undefined)
                this.previousSettlement += settlementAmountWithoutCharge + (this.previousEPFLoanList[i].SettlementCharges.Stamp) + (this.previousEPFLoanList[i].SettlementCharges.ServiceCharge) + (this.previousEPFLoanList[i].SettlementCharges.Extras);
              else
                this.previousSettlement += settlementAmountWithoutCharge + this.previousEPFLoanList[i].SettlementStamp + this.previousEPFLoanList[i].SettlementServiceCharge + this.previousEPFLoanList[i].SettlementExtra;


            }

            else
              this.previousSettlement += this.previousEPFLoanList[i].LegacySettlement;

            this.previousSettlementRatio = ((this.previousSettlement / this.request.EpfAmount) * 100);
          }

        }

      }


      if (this.sharedService.isBossAccount() || this.sharedService.isAdmin()) {

        this.request.ConflictedCustomerList = [];
        this.request.ConflictedGuarantorList = [];
        this.request.ConflictedTicketCustomerList = [];

        //check if ticket customer conflicted
        this.searchConflictedGuarantor(this.request.CustomerIC, -1);
        this.findConflictedCustomer(this.request.CustomerIC, -1);
        this.findConflictedTicketCustomer(this.request.CustomerIC, -1)

        //check if guarantor conflicted
        if (this.request.Guarantors != undefined && this.request.Guarantors.length > 0) {
          for (let i = 0; i < this.request.Guarantors.length; i++) {
            this.searchConflictedGuarantor(this.request.Guarantors[i].ICNumber, i);
            this.findConflictedCustomer(this.request.Guarantors[i].ICNumber, i);
            this.findConflictedTicketCustomer(this.request.Guarantors[i].ICNumber, i)
          }
        }

      }

    }

  }

  closeModal() {
    this.activeModal.close();
  }

  downloadGuarantorForm(contact: Contact) {
    var companyId = this.request.CompanyId;

    this.router.navigateByUrl('/guarantorForm/' + companyId + "/" + this.request.CustomerId + "/" + contact.ID);
  }

  getAllEPFLoans() {

    var includedStatus = [this.enums.ACTIVE_LOAN, this.enums.WARNING_LOAN, this.enums.BAD_DEBT_LOAN]

    var xhttp = this.restApi.getRequest(this.constructApi.getAllProfileLoans(this.request.ProfileId, "EPF", includedStatus, true, this.includeShared))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          var stampAmount = (json.stamp == undefined) ? 0 : json.stamp;
          var serviceChargeAmount = (json.serviceCharge == undefined) ? 0 : json.serviceCharge;
          var reserve = (json.reserve == undefined) ? 0 : json.reserve;
          var extras = (json.extras == undefined) ? undefined : this.processDuitLebihJsonArr(json.extras);

          var settlementCharges = (json.settlementCharges == undefined || json.settlementCharges.stamp == undefined) ? undefined : new SettlementCharges(json.settlementCharges.serviceCharge, json.settlementCharges.stamp, json.settlementCharges.extras,json.settlementCharges.handlingCharge)
          var monthlyPayment = this.sharedService.calculateLoanMonthlyPayment(json.scheme, json.principal, json.principalPaid, json.term, json.interestRate);

          var loan = new Loan(json.id, json.profileId, json.principal, stampAmount,
            json.receiptNumber, json.status, json.dateCreated, json.companyId, json.companyName, json.scheme, json.interestRate, json.term,
            undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined,
            reserve, json.balance, monthlyPayment, json.arrearPaid, serviceChargeAmount, extras, undefined, undefined,
            undefined, json.processingRate, undefined, undefined, undefined,
            json.settlementStamp || 0, json.settlementServiceCharge || 0, json.settlementExtras || 0, undefined, undefined, undefined, undefined, json.legacySettlement,
            undefined, settlementCharges);

          if (loan.ID != this.request.LoanID) {
            this.previousEPFLoanList.push(loan);
          }
        }
        );

      }
    };
  }



  processGuarantorJsonArr(jsonArray: any) {
    var guarantorsInfo: Contact[] = new Array;
    jsonArray.forEach(json => {
      var guarantors = new Contact(json.id, json.name, json.icNumber, json.number, json.address, json.customerId, json.relation, json.remark)
      guarantorsInfo.push(guarantors);
    });

    return guarantorsInfo;
  }

  processDuitLebihJsonArr(jsonArray: any) {
    var duitLebihList: DuitLebih[] = new Array;
    jsonArray.forEach(json => {
      var extra = new DuitLebih(json.amount, json.subtype)
      duitLebihList.push(extra);
    });

    return duitLebihList;
  }

  getTotalSettlement() {
    return this.sharedService.calculateEPFSettlementAmountWithCharges(this.request.LoanInfo) + this.sharedService.calculatePreviousEPFLoanSettlement(this.previousEPFLoanList);
  }

  getTotalSettlementRatio() {

    return (this.getTotalSettlement() / this.request.EpfAmount) * 100
  }

  goToCustomer() {
    this.router.navigate(['/manageCustomer', this.request.CustomerId, this.request.ProfileId]);
  }

  calculateTotalDuitLebih(request: LoanRequest) {
    var amount = 0;

    if (request.LoanInfo.Extras != undefined) {
      for (let i = 0; i < request.LoanInfo.Extras.length; i++) {
        amount += request.LoanInfo.Extras[i].Amount;
      }
    }

    return amount;

  }


  calculateTotalOnHand(request: LoanRequest) {
    var total = 0;

    if (request.LoanInfo != undefined) {
      var duitLebihTotal = this.calculateTotalDuitLebih(request);
      var processingCharge = (request.LoanInfo.ProcessingRate / 100) * request.LoanInfo.Principal;
      total += request.LoanInfo.Principal - request.LoanInfo.Reserve - duitLebihTotal - processingCharge - request.LoanInfo.StampAmount -
        request.LoanInfo.ServiceCharge


    }

    return total;
  }






  assessEPFCase() {

    var points = 0;
    if (this.request.Guarantors.length > 0)
      points++;

    if (this.request.LoanInfo != undefined && this.sharedService.roundToDec((this.request.LoanInfo.InterestRate), 4) >= 5)
      points++;

    if (this.request.LoanInfo != undefined) {
      if (this.getTotalSettlementRatio() < 25)
        points++;

      else
        points--;
    }
    return points;
  }



  confirmBeforeDeleteRequest(request: LoanRequest) {
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Are you sure you want to delete this loan request #" + request.ID;

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;
      if (confirmation == true) {
        const modalRef = this.sharedService.openModal(DeleteReasonComponent);
        modalRef.componentInstance.passEntry.subscribe((entry) => {
          var remark = entry;

          //append added account to account list
          this.deleteLoanRequest(remark, request.ID);
        });
      }
    });
  }

  confirmBeforeDenial(request: LoanRequest) {
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Are you sure you want to DENY this loan request #" + request.ID;

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;
      if (confirmation == true) {
        const modalRef = this.sharedService.openModal(DeleteReasonComponent);
        modalRef.componentInstance.passEntry.subscribe((entry) => {
          var remark = entry;

          //append added account to account list

          var processedRemark = (request.Remark) ? "DENIED: " + remark + "(" + request.Remark + ")" : "DENIED: " + remark;
          this.denyLoanRequest(processedRemark, request);
        });
      }
    });
  }


  deleteLoanRequest(remark: string, requestId: number) {
    var data =
    {
      "reason": remark
    };

    var xhr = this.restApi.deleteRequest(this.constructApi.getLoanApprovalRequestById(Number(requestId)), data);

    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        if (xhr.readyState == 4 && xhr.status == 200) {

          if (xhr.status == 200) {
            window.location.reload();
          }
          else {
            this.sharedService.openAlert("Opps, some errors occured. Please try again later", this.enums.DANGER_ALERT);
          }

        }
      }

    }
  }



  denyLoanRequest(remark: string, request: LoanRequest) {
    var data = {
      "remark": remark
    };


    var xhr = (request.LoanGroupId == undefined)
      ? this.restApi.postRequest(this.constructApi.getDenyLoanApprovalRequest(request.ID), data)
      : this.restApi.postRequest(this.constructApi.getDenyGroupLoanApprovalRequest(request.LoanGroupId), data)
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        window.location.reload();
      }
    }

  }

  openAddApprovedLoanModal(request: LoanRequest) {
    var modalRef = this.sharedService.openModal(AddApprovedLoanComponent, 'largeModal');
    modalRef.componentInstance.approvedLoan = request.LoanInfo;
    modalRef.componentInstance.settlement = this.sharedService.calculateEPFSettlementAmount(request.LoanInfo);
    var settlementWithCharges = this.sharedService.calculateEPFSettlementAmountWithCharges(request.LoanInfo);
    modalRef.componentInstance.settlementWithCharges = settlementWithCharges
    modalRef.componentInstance.ratio = this.sharedService.calculateSettlementRatio(settlementWithCharges, this.request.EpfAmount);
    modalRef.componentInstance.totalOnHand = this.calculateTotalOnHand(request);
    modalRef.componentInstance.approvedRequestId = request.ID;
    modalRef.componentInstance.selectedCustomerID = request.CustomerId;


    //check if this profile is a shared profile from other companies
    var isMainAccount = (this.request.SharedProfileId == this.request.ProfileId) || (this.request.SharedProfileId == undefined);

    modalRef.componentInstance.isMainAccount = isMainAccount;

  }


  openShowWhatsappClipboardModal(requestList: LoanRequest[]) {

    var modalRef = (this.sharedService.isAdmin() || this.sharedService.isBossAccount())
      ? this.sharedService.openModal(ShowWhatsappMessageClipboardComponent, "largeModal")
      : this.sharedService.openModal(ShowWhatsappMessageClipboardComponent);
    modalRef.componentInstance.requestList = requestList;
    modalRef.componentInstance.isEPFRequest = requestList[0].LoanInfo.Scheme == 'EPF';
    modalRef.componentInstance.loanGroupId = requestList[0].LoanGroupId;
    // modalRef.componentInstance.previousEPFLoanList = this.previousEPFLoanList;
  }

  checkOtherLoanRequests(request: LoanRequest) {

    if (request.LoanGroupId != undefined)
      this.getAllSharedReqeusts();
    else
      this.openShowWhatsappClipboardModal([request]);
  }

  getAllSharedReqeusts() {
    var xhttp;
    xhttp = this.restApi.getRequest(this.constructApi.getLoanGroupApprovalRequests(this.request.LoanGroupId))

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4) {

        if (xhttp.status == 200) {
          var jsonArray = JSON.parse((xhttp.responseText));

          var loanRequestList: LoanRequest[] = new Array;
          jsonArray.forEach(json => {

            var loanRequest = this.sharedService.processLoanRequest(json);
            loanRequestList.push(loanRequest);

            loanRequestList.sort((a, b) => ((a.ID > b.ID)) ? -1 : 1);
            this.openShowWhatsappClipboardModal(loanRequestList);
          }
          );
        }
        else
          //if somehow cant get share loan requests, at least need to be able to generate own request
          this.openShowWhatsappClipboardModal([this.request]);

      }
    };
  }


  viewContactImages(contact: Contact) {
    var modalRef = this.sharedService.openModal(ViewContactThumbnailsComponent, 'largeModal');
    modalRef.componentInstance.request = this.request;
    modalRef.componentInstance.contact = contact;
  }

  openEditEPFModal() {
    var modalRef = this.sharedService.openModal(EditEpfDetailsComponent, 'largeModal');
    modalRef.componentInstance.customerId = this.request.CustomerId;
  }

  openEditGuaratorModal(contact: Contact) {
    var modalRef = this.sharedService.openModal(AddContactComponent, 'largeModal');
    modalRef.componentInstance.viewCustomerId = this.request.CustomerId;
    modalRef.componentInstance.contactToUpdate = contact;
  }

  openEditLoanInfoModal(request: LoanRequest) {
    var modalRef = this.sharedService.openModal(EditLoanRequestInfoComponent, 'largeModal');
    modalRef.componentInstance.request = request;

    if (request.LoanInfo.Extras == undefined)
      request.LoanInfo.Extras = new Array();
    modalRef.componentInstance.settlementAmount = request.LoanInfo.Principal + (request.LoanInfo.Term *
      request.LoanInfo.Principal * (request.LoanInfo.InterestRate / 100));
  }

  openAddGuarantorModal(request: LoanRequest) {
    var modalRef = this.sharedService.openModal(AddLoanRequestGuarantorComponent, 'largeModal');
    modalRef.componentInstance.request = request;

  }

  openEditLoanRequestRemarkModal(request: LoanRequest) {
    var modalRef = this.sharedService.openModal(EditLoanRequestRemarkComponent, 'largeModal');
    modalRef.componentInstance.request = request;

  }

  viewCustomerImages(customerID: number) {
    var modalRef = this.sharedService.openModal(ViewThumbnailsComponent, "largeModal");
    modalRef.componentInstance.customerID = customerID;
  }



  searchConflictedGuarantor(ic: string, guarantorIndex: number) {
    if (guarantorIndex != -1)
      this.request.Guarantors[guarantorIndex].ConflictedGuarantorList = [];

    if (ic != undefined)
      var icNumberFormat = this.sharedService.sanitizeICNumInput(ic)
    var xhr = this.restApi.getRequest(this.constructApi.getSearchGuarantor(icNumberFormat, this.request.CustomerId.toString()));

    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        if (xhr.status == 200) {
          var jsonArray = JSON.parse((xhr.responseText));

          if (jsonArray.length > 0) {
            jsonArray.forEach(json => {
              var guarantor;
              // create customer instance with all response data

              guarantor = new GlobalCustomer(json.customerName, json.CustomerIcNumber, json.companyCode, json.customerId, undefined, undefined, undefined, undefined, undefined, json.name, json.icNumber);

              //add customer instance into customer list
              if (guarantorIndex != -1)
                this.request.Guarantors[guarantorIndex].ConflictedGuarantorList.push(guarantor);
              else
                this.request.ConflictedGuarantorList.push(guarantor);

            }
            );

          }
        }
        else {
          if (guarantorIndex != -1)
            this.request.Guarantors[guarantorIndex].ConflictedGuarantorList = undefined;
          else
            this.request.ConflictedGuarantorList = undefined;
        }

      }
    }

  }


  findConflictedTicketCustomer(ic: string, guarantorIndex: number) {
    if (guarantorIndex != -1)
      this.request.Guarantors[guarantorIndex].ConflictedTicketCustomerList = [];


    if (ic != undefined)
      var icNumberFormat = this.sharedService.sanitizeICNumInput(ic)
    var xhr;
    xhr = this.restApi.getRequest(this.constructApi.getSearchConflictedTicketCustomer(icNumberFormat, this.request.CustomerId, true, true, false));


    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {

        if (xhr.status == 200) {
          var jsonArray = JSON.parse((xhr.responseText));

          if (jsonArray.length > 0) {
            jsonArray.forEach(json => {
              var loanRequest = new LoanRequest(json.customerId, json.epfAmount, json.epfDate,
                undefined, json.hasIAccount, json.id, undefined, json.processedRemark,
                json.profileId, json.remark, json.requestBy, json.requestOn, json.updatedOn,
                json.approved, json.loanId, json.companyCode, json.icNumber, json.name, json.companyId, undefined, undefined, json.authorizedBy, undefined, undefined, undefined, undefined, json.dob, json.otherId)

              //add customer instance into customer list
              if (guarantorIndex != -1)
                this.request.Guarantors[guarantorIndex].ConflictedTicketCustomerList.push(loanRequest);
              else
                this.request.ConflictedTicketCustomerList.push(loanRequest)
            }
            );

          }
        }
        else {
          if (guarantorIndex != -1)
            this.request.Guarantors[guarantorIndex].ConflictedCustomerList = undefined;
          else
            this.request.ConflictedTicketCustomerList = undefined;
        }

      }
    }
  }

  findConflictedCustomer(ic: string, guarantorIndex: number) {
    if (guarantorIndex != -1)
      this.request.Guarantors[guarantorIndex].ConflictedCustomerList = [];

    if (ic != undefined)
      var icNumberFormat = this.sharedService.sanitizeICNumInput(ic)
    var xhr;
    xhr = this.restApi.getRequest(this.constructApi.getGlobalSearch("", icNumberFormat));


    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {

        if (xhr.status == 200) {
          var jsonArray = JSON.parse((xhr.responseText));

          if (jsonArray.length > 0) {
            jsonArray.forEach(json => {
              var customer = new GlobalCustomer(json.name, json.icNumber, json.companyCode, json.id, json.status);
              //add customer instance into customer list
              if (guarantorIndex != -1)
                this.request.Guarantors[guarantorIndex].ConflictedCustomerList.push(customer);
              else if (this.request.CustomerId != customer.CustomerID)
                this.request.ConflictedCustomerList.push(customer);
            }
            );

          }
        }
        else {
          if (guarantorIndex != -1)
            this.request.Guarantors[guarantorIndex].ConflictedCustomerList = undefined;
          else
            this.request.ConflictedCustomerList = undefined;
        }

      }
    }
  }

  openConflictedModal(conflictedCustomerList: GlobalCustomer, conflictedGuarantorList: GlobalCustomer, conflictedTicketCustomerList: LoanRequest) {
    var modalRef = this.sharedService.openModal(ConflictedCustomerListComponent, "regularModal");
    modalRef.componentInstance.customerList = conflictedCustomerList;
    modalRef.componentInstance.guarantorList = conflictedGuarantorList;
    modalRef.componentInstance.ticketList = conflictedTicketCustomerList;

    // console.log(conflictedCustomerList)
  }


  viewCustomerProfileLoans(request: LoanRequest) {
    var modalRef = this.sharedService.openModal(CustomerLoanCardComponent, "largeModal");
    modalRef.componentInstance.viewCustomer = new Customer(request.CustomerId, request.CustomerName, undefined, undefined, undefined, request.CustomerIC, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
    modalRef.componentInstance.selectedProfile = new Profile(request.ProfileId, undefined, undefined, undefined, undefined, undefined, undefined, request.CustomerId, undefined);
    modalRef.componentInstance.isModal = true
  }

}
