import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Transaction } from 'src/model/transaction.model';
import { TransactionSubType } from 'src/model/transactionSubType.model';

@Component({
  selector: 'app-manage-transaction-subtype',
  templateUrl: './manage-transaction-subtype.component.html',
  styleUrls: ['./manage-transaction-subtype.component.css']
})
export class ManageTransactionSubtypeComponent implements OnInit {

  @Input() subtypeList: TransactionSubType[] = new Array;
  @Input() currentSubtype: number;
  @Input() transaction: Transaction;



  disabledSubmit = false;
  constructor(private activeModal: NgbActiveModal, private enums: Enums, private sharedService: SharedService, private restApi: RestApiService, private constructApi: ConstructAPI) { }

  ngOnInit(): void {
  }


  submit() {
    var data = {
      "subtype": Number(this.currentSubtype)
    };


    var xhr = this.restApi.patchRequest(this.constructApi.getEditTransactionSubtype(Number(this.transaction.ID)), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        if (xhr.status == 200)
          //if success emit
          window.location.reload();
        else
          this.sharedService.openErrorMessage(xhr)
      }

    }
  }

  closeModal() {

    this.activeModal.close();
  }
}
