<form>
    <!-- Modal Header -->
    <div class="modal-header" style="background-color:aquamarine;" [hidden]="advanceObj.Amount>0">
        <h4 class="modal-title" [hidden]="toCreateNew==false">Advance More</h4>

        <h4 class="modal-title" [hidden]="toCreateNew==true">Return Advance</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <!-- Modal Header -->
    <div class="modal-header" style="background-color:skyblue;" [hidden]="advanceObj.Amount<0">
        <h4 class="modal-title" [hidden]="toCreateNew==false">Simpan More</h4>
        <h4 class="modal-title" [hidden]="toCreateNew==true">Pay Advance</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>


    <div class="modal-body">

        <!--Total Advance amount-->
        <h4 class="fw-bold text-primary">Current Advance: RM {{this.advanceObj.Amount}}</h4>

        <!--Amount-->

        <div class="card">

            <div class="card-body">
                <div class="group">
                    <input type="number" required class="textInputLong" name="amount" [(ngModel)]="amount" min=0
                        (keypress)="sharedService.isNumberKey($event)" (keyup)="selectedAccountId=undefined">
                    <span class="highlight"></span>
                    <span class="bar barLong"></span>
                    <label>Amount</label>
                </div>

                <!--NEW Advance amount-->
                <ng-container >
                    <h3 class="fw-bold text-success">New Advance: RM
                        <span> {{calculateNewAmount(amount)}}</span>
                    </h3>
                </ng-container>

            </div>
        </div>

        <!--Account Number-->
        <div class="row p-3 d-flex justify-content-center align-items-center">

            <div class="col-12">
                <p>From Account</p>
            </div>
            <div class="col-12">
                <select class="browser-default form-select" name="selectedAccount" [(ngModel)]="selectedAccountId">
                    <option *ngFor="let account of companyAccountList" [value]="account.ID"
                        [selected]="setSelectedAccount(account.ID)" [disabled]="isBalanceInsufficient(account.Balance)">
                        {{account.Name}}(RM {{account.Balance}}) </option>
                </select>
            </div>

        </div>

        <!--Date-->
        <mat-form-field class="full-width-formField bar">
            <input matInput class="textInputLong" [matDatepicker]="myDatepicker" name="date" [(ngModel)]="date"
                placeholder="Income Date">
            <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #myDatepicker name="date"></mat-datepicker>
        </mat-form-field>

        <!--Remark-->
        <div class="group">
            <input type="text" required class="textInputLong" name="remark" [(ngModel)]="remark">
            <span class="highlight"></span>
            <span class="bar barLong"></span>
            <label> Remark</label>
        </div>

    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="submit()" [disabled]="disabledSubmit">Submit</button>
        <button class=" btn btn-outline-danger" type="button" (click)="reset()">Cancel</button>
    </div>

</form>