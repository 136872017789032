<form>
    <!-- Modal Header -->
    <div class="modal-header" style="background-color: cornflowerblue;">
        <h4 class="modal-title">Edit Transaction</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body">

        <!--Amount-->
        <div class="group mt-3">
            <input type="number" required class="textInputLong" name="amount" [(ngModel)]="amount" min=0
                (keypress)="sharedService.isNumberKey($event)">
            <span class="highlight"></span>
            <span class="bar barLong"></span>
            <label>Transaction Amount</label>
        </div>
        <!--Remark-->
        <div class="group">
            <input type="text" required class="textInputLong" name="remark" [(ngModel)]="remark">
            <span class="highlight"></span>
            <span class="bar barLong"></span>
            <label> Remark</label>
        </div>

    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="confirmBeforeUpdate()">Edit</button>
        <button class="btn btn-outline-danger" type="button" (click)="reset()">Cancel</button>
    </div>

</form>