<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Mark Old EPF Loans</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-12 m-2 p-1">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <span class="fw-bold text-secondary">Fill in EPF Details: </span>
                            </div>
                            <div class="col-6">
                                <mat-form-field class="full-width-formField bar">
                                    <input matInput class="textInputLong" [matDatepicker]="myDatepicker"
                                        [(ngModel)]="epfDate" placeholder="EPF Settlement Date"
                                        [min]="sharedService.getTodayDate()" name="date"
                                        [max]="sharedService.getMaxDateForEPF()" onkeydown="false"
                                        [disabled]="epfDetails.Date!=undefined">
                                    <mat-datepicker-toggle matSuffix [for]=" myDatepicker">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #myDatepicker name="date"></mat-datepicker>
                                </mat-form-field>


                            </div>
                            <div class="col-6">
                                <mat-form-field class="full-width-formField bar">
                                    <mat-label>EPF Amount (RM)</mat-label>
                                    <input matInput type="number" required class="textInputLong" placeholder="epfAmount"
                                        name="amount" [(ngModel)]="epfAmount" min=0
                                        (keypress)="sharedService.isNumberKey($event)"
                                        [readonly]="epfDetails.Amount!=undefined">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
            </div>
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <span class="fw-bold text-secondary">Select Loan(s) to mark as EPF: </span>
                        <table mat-table [dataSource]="dataSource" matSort>

                            <!-- Checked Column -->
                            <ng-container matColumnDef="Check">
                                <th mat-header-cell *matHeaderCellDef>
                                    <input type="checkbox" class="form-check-input" name="check"
                                        [(ngModel)]="checkedAll" [checked]="isCheckedAll()" (change)="checkAll()">
                                </th>
                                <td mat-cell *matCellDef="let loan">
                                    <input type="checkbox" class="form-check-input" name="check"
                                        [(ngModel)]="loan.IsSelected">
                                </td>
                            </ng-container>


                            <!-- Date Column -->
                            <ng-container matColumnDef="DateCreated">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
                                <td mat-cell *matCellDef="let loan">
                                    <span>{{loan.DateCreated | date:'dd-MM-yyyy'}}</span>
                                </td>
                            </ng-container>

                            <!-- ReceiptNumber Column -->
                            <ng-container matColumnDef="ReceiptNumber">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Receipt Number </th>
                                <td mat-cell *matCellDef="let loan">
                                    <span>{{loan.ReceiptNumber}}</span>
                                </td>
                            </ng-container>

                            <!-- Code Column -->
                            <ng-container matColumnDef="LoanCode">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Code </th>
                                <td mat-cell *matCellDef="let loan">
                                    <span>{{loan.LoanCode}}</span>
                                </td>
                            </ng-container>

                            <!-- Principal Column -->
                            <ng-container matColumnDef="Principal">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Principal </th>
                                <td mat-cell *matCellDef="let loan">
                                    <span>RM {{loan.Principal}}</span>
                                </td>
                            </ng-container>


                            <!-- Skim Column -->
                            <ng-container matColumnDef="Scheme">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Skim </th>
                                <td mat-cell *matCellDef="let loan">
                                    <span>{{loan.Scheme}}</span>
                                </td>
                            </ng-container>

                            <!-- Monthly Column -->
                            <ng-container matColumnDef="MonthlyPayment">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Monthly Payment </th>
                                <td mat-cell *matCellDef="let loan">
                                    <span>RM {{loan.MonthlyPayment}}</span>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>


                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="submit()"
            [disabled]="disabledSubmit">Mark EPF</button>
        <button class="btn btn-outline-danger" type="button" (click)="reset()">Cancel</button>
    </div>
</form>