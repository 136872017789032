import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { AlertDialogComponent } from 'src/app/Shared/alert-dialog/alert-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';

@Component({
  selector: 'app-edit-transaction',
  templateUrl: './edit-transaction.component.html',
  styleUrls: ['./edit-transaction.component.css']
})
export class EditTransactionComponent implements OnInit {
  @Input() amount: number;
  @Input() remark;
  @Input() transactionId: number;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private restapi: RestApiService, private constructApi: ConstructAPI,
    public sharedService:SharedService,private enums:Enums) { }


  ngOnInit(): void {
  }

  updateTransactionAmountAndRemark() {

    var data = {
      "amount": this.amount,
      "remark": this.remark
    };


    var xhr = this.restapi.patchRequest(this.constructApi.getATransactionByID(Number(this.transactionId)), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {

        var json = JSON.parse(xhr.responseText);
        //if success emit
        this.passEntry.emit();
        this.reset();
        this.sharedService.openAlert("Successfully updated transaction",this.enums.SUCCESS_ALERT)
      }

    }
  }

  confirmBeforeUpdate() {
    var proceed = false;
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Confirm to update transaction amount = RM " + this.amount + " and remark=" + this.remark + "?";
    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something\

      if (confirmation == true)
        this.updateTransactionAmountAndRemark();
    });

    return proceed;
  }

  checkFieldNull() {
    var amountNull = this.amount == undefined;
    var amountValid = isNaN(+this.amount);
    var remarkNull = this.remark == undefined;

    return amountNull || amountValid || remarkNull;
  }

  reset() {
    this.activeModal.close();
  }

}
