<div class="row p-2" *ngIf="isModal">
    <div class="col d-flex justify-content-end">
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="closeModal()"></button>
    </div>
</div>
<div class="card my-2" style="border-radius: 20px; border: 3px solid purple;">


    <!--TEMPLATE TO BE USED FOR CONFLICTED CUSTOMER -->
    <ng-template #conflictedCustomer let-request="request">
        <div class="col-6"></div>
        <div class="col-6 d-flex justify-content-end align-items-right">
            <button class="btn btn-danger  m-2"  data-bs-toggle="tooltip" data-placement="top" title="Duplicated Customer"
                (click)="openConflictedModal(request.ConflictedCustomerList,request.ConflictedGuarantorList,request.ConflictedTicketCustomerList)"
                *ngIf="(sharedService.isBossAccount()==true || sharedService.isAdmin()==true) && (request.ConflictedCustomerList.length>0||request.ConflictedGuarantorList.length>0||request.ConflictedTicketCustomerList.length>0)">

                <i class="fas fa-exclamation-triangle"></i>
                {{request.ConflictedCustomerList.length +
                request.ConflictedGuarantorList.length +request.ConflictedTicketCustomerList.length }}
                Conflicted
            </button>
        </div>
    </ng-template>




    <div class="card-header" [ngClass]="{'pending-card': request.Approved ==undefined, 'approved-card' : request.Approved ==true, 'deny-card' : request.Approved ==false,
    'normalLoan-card': request.LoanInfo.Scheme!='EPF' }">
        <div class="row">
            <div class="col-6">
                <h2 *ngIf="request.LoanInfo.Scheme!='HUTANG'">Ticket # {{request.ID}} ({{request.LoanInfo.Scheme}}) -
                    {{request.CompanyCode}}</h2>
                <h2 *ngIf="request.LoanInfo.Scheme=='HUTANG'">HUTANG Request</h2>
            </div>
            <div class="col-6 d-flex justify-content-end align-items-right">
                <button class="btn btn-light me-2"
                    *ngIf="viewOnly==false && (sharedService.isAdmin()==true || sharedService.isBossAccount()==true || sharedService.isFinance())"
                    (click)="viewCustomerProfileLoans(request)">
                    <i class="fas fa-donate"></i>
                </button>
                <button class="btn btn-primary me-2"
                    *ngIf="viewOnly==false && (sharedService.isAdmin()==true || sharedService.isBossAccount()==true || sharedService.isFinance())"
                    (click)="viewCustomerImages(request.CustomerId)">
                    <i class="fas fa-image"></i>
                </button>
                <button class="btn btn-success me-2" #tooltip="matTooltip" matTooltip="Send Whatsapp"
                    matTooltipPosition="above" matTooltipHideDelay="100" matTooltipClass="tooltip-black"
                    *ngIf="viewOnly==false && (request.Approved==undefined && request.ID!=undefined && request.LoanInfo.Scheme!='HUTANG') && sharedService.isStaff()==false && !sharedService.isFinance()"
                    (click)=" checkOtherLoanRequests(request)">
                    <i class="fab fa-whatsapp"></i>
                </button>
                <button class="btn btn-danger me-2" #tooltip="matTooltip" matTooltip="Delete" matTooltipPosition="above"
                    matTooltipHideDelay="100" matTooltipClass="tooltip-black"
                    *ngIf="viewOnly==false && ((editable ==true && request.LoanGroupId==undefined && request.Approved !=true && this.sharedService.isOperatingUser()) || (this.sharedService.isBossAccount()==true && request.Approved==true && request.LoanID==undefined))"
                    (click)=" confirmBeforeDeleteRequest(request)">
                    <i class="fas fa-eraser"></i>
                </button>
                <button class="btn btn-success" #tooltip="matTooltip" matTooltip="Add Approved Loan"
                    matTooltipPosition="above" matTooltipHideDelay="100" matTooltipClass="tooltip-black"
                    *ngIf="viewOnly==false && editable ==true && request.Approved==true && this.sharedService.isOperatingUser()"
                    (click)="openAddApprovedLoanModal(request)">
                    <i class="fas fa-plus-circle"></i>
                </button>

                <button class="btn btn-dark" #tooltip="matTooltip" matTooltip="Deny" matTooltipPosition="above"
                    matTooltipHideDelay="100" matTooltipClass="tooltip-black"
                    *ngIf="viewOnly==false && request.Approved==true && request.LoanID==undefined && (this.sharedService.isAdmin()==true||this.sharedService.isBossAccount()==true)"
                    (click)="confirmBeforeDenial(request)">
                    <i class="fas fa-times"></i>
                </button>
            </div>
        </div>
    </div>


    <!-- EPF SCHEME-->
    <div class="card-body">
        <ng-container *ngIf="request.LoanInfo.Scheme=='EPF';else normalLoanRequest">
            <div id="collapseExample" [ngbCollapse]="!isExpanded" *ngIf="onlyViewLoan==false">
                <div class="card mb-2">
                    <div class="card-header"><b>Customer Summary <span
                                *ngIf="request.SharedProfileId!=undefined &&  request.ProfileId == request.SharedProfileId">🤝</span>
                        </b>
                        <button class="btn btn-warning m-2" *ngIf="editable ==true
                && (this.sharedService.isBranchManager()==true)" (click)="openEditEPFModal()">
                            <i class="fas fa-pencil-alt"></i>
                        </button>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <ng-container *ngTemplateOutlet="conflictedCustomer;context:{request:request}">
                            </ng-container>
                            <div class="col-6">
                                Name : <span class="customerLink" (click)="goToCustomer()">{{request.CustomerName
                                    }}</span>
                            </div>
                            <div class="col-6">
                                IC : <span class="customerLink" (click)="goToCustomer()">{{
                                    sharedService.processICNum(request.CustomerIC) }}</span>
                            </div>

                            <div class="col-6">
                                DOB : <span class="customerLink" (click)="goToCustomer()">{{request.DOB
                                    |date:'yyyy-MM-dd'
                                    }}</span>
                            </div>
                            <div class="col-6">
                                Other ID : <span class="customerLink" (click)="goToCustomer()">{{
                                    request.OtherId }}</span>
                            </div>
                            <div class="col-6">
                                EPF Date : {{request.EPFDate | date:'yyyy-MM-dd'}}
                            </div>
                            <div class="col-6">
                                EPF Amount : RM {{request.EpfAmount |number:'1.0-2'}}
                            </div>
                            <div class="col-6">
                                <span *ngIf="request.HasIAccount==true">I-Account : YES</span>
                                <span *ngIf="request.HasIAccount==false">I-Account : NO</span>
                            </div>

                            <div class="col-6">
                                Remark :{{request.Remark}}
                                <button class="btn btn-warning m-2" *ngIf="editable ==true  
                            && (this.sharedService.isOperatingUser())"
                                    (click)="openEditLoanRequestRemarkModal(request)">
                                    <i class="fas fa-pencil-alt"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mb-2">
                <div class="card-header"><b>Loan Summary</b>
                    <!-- <button class="btn btn-warning m-2" *ngIf="editable ==true  
            && (this.sharedService.isOperatingUser())" (click)="openEditLoanInfoModal(request)">
                        <i class="fas fa-pencil-alt"></i>
                    </button> -->

                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-4">
                            Loan Amount : RM {{request.LoanInfo.Principal|number:'1.0-2'}}
                        </div>
                        <div class="col-4">
                            Settlement : RM {{sharedService.calculateEPFSettlementAmount(this.request.LoanInfo)
                            |number:'1.0-2'}}
                        </div>
                        <div class="col-4">
                            Term : {{request.LoanInfo.Term}} months
                        </div>
                        <div class="col-4">
                            <span *ngIf="request.LoanInfo.SettlementCharges==undefined;">Settlement Stamp : RM
                                {{request.LoanInfo.SettlementStamp |number:'1.0-2'}}</span>
                            <span *ngIf="request.LoanInfo.SettlementCharges!=undefined">SettlementCharge Stamp : RM
                                {{request.LoanInfo.SettlementCharges.Stamp/request.LoanInfo.Term |number:'1.0-2'}} x
                                {{request.LoanInfo.Term}} =RM
                                {{request.LoanInfo.SettlementCharges.Stamp |number:'1.0-2'}} </span>
                        </div>
                        <div class="col-4">
                            <span *ngIf="request.LoanInfo.SettlementCharges==undefined;">Settlement SC : RM
                                {{request.LoanInfo.SettlementServiceCharge |number:'1.0-2'}}</span>
                            <span *ngIf="request.LoanInfo.SettlementCharges!=undefined">SettlementCharge SC : RM
                                {{request.LoanInfo.SettlementCharges.ServiceCharge/request.LoanInfo.Term
                                |number:'1.0-2'}}
                                x {{request.LoanInfo.Term}} =RM
                                {{request.LoanInfo.SettlementCharges.ServiceCharge
                                |number:'1.0-2'}}
                            </span>
                        </div>
                        <div class="col-4">
                            <span *ngIf="request.LoanInfo.SettlementCharges==undefined;">Settlement DL : RM
                                {{request.LoanInfo.SettlementExtra||0 |number:'1.0-2'}}</span>
                            <span *ngIf="request.LoanInfo.SettlementCharges!=undefined">SettlementCharge DL : RM
                                {{request.LoanInfo.SettlementCharges.Extras/request.LoanInfo.Term||0 |number:'1.0-2'}}
                                x {{request.LoanInfo.Term}} =RM
                                {{request.LoanInfo.SettlementCharges.Extras||0 |number:'1.0-2'}}
                            </span>
                        </div>
                        <div class="col-4">
                            <span *ngIf="request.LoanInfo.SettlementCharges!=undefined">Handling Charge : RM
                                {{request.LoanInfo.SettlementCharges.HandlingCharge||0 |number:'1.0-2'}}
                            </span>
                        </div>

                    </div>
                    <hr />
                    <div class="row">
                        <div class="col col-4">
                            Stamp :RM{{ request.LoanInfo.StampAmount||0|number:'1.0-2'}}
                        </div>
                        <div class="col col-4">
                            S/C :RM{{ request.LoanInfo.ServiceCharge||0|number:'1.0-2'}}
                        </div>
                        <div class="col col-4">
                            Duit Lebih :RM{{ calculateTotalDuitLebih(request)||0|number:'1.2-2'}}
                        </div>
                        <div class="col col-4">
                            Simpan Out :-RM{{ -request.LoanInfo.Reserve||0}}
                        </div>
                        <div class="col col-4">
                            Processing Charge :RM{{
                            (request.LoanInfo.ProcessingRate/100)*request.LoanInfo.Principal||0|number:'1.0-2'}}
                        </div>
                        <div class="col col-4">
                            Processing Rate : {{(request.LoanInfo.ProcessingRate)|number:'1.0-2'}}%
                        </div>
                        <div class="col col-4">
                            <b>ON HAND = RM {{calculateTotalOnHand(request) ||0|number:'1.2-2'}}</b>
                        </div>
                        <div class="col col-4">
                            <span
                                [ngClass]="{'text-danger':sharedService.roundToDec(request.LoanInfo.InterestRate,4)<5,'text-success':sharedService.roundToDec(request.LoanInfo.InterestRate,4)>=5}">
                                <b>Interest Rate: {{request.LoanInfo.InterestRate ||0|number:'1.3-3'}}%</b>
                            </span>
                        </div>

                        <div class="col col-4">
                            <span
                                [ngClass]="{'text-danger':this.sharedService.calculateSettlementRatio(this.sharedService.calculateEPFSettlementAmountWithCharges(request.LoanInfo),request.EpfAmount)>23,
                                'text-success':this.sharedService.calculateSettlementRatio(this.sharedService.calculateEPFSettlementAmountWithCharges(request.LoanInfo),request.EpfAmount)<=23}">
                                <b>+ Settlement Ratio:
                                    {{this.sharedService.calculateSettlementRatio(this.sharedService.calculateEPFSettlementAmountWithCharges(request.LoanInfo),request.EpfAmount)||0
                                    |number:'1.3-3'}}% | RM
                                    {{this.sharedService.calculateEPFSettlementAmountWithCharges(request.LoanInfo)|number:'1.0-2'}}</b>
                            </span>
                        </div>
                    </div>
                </div>
            </div>


            <div id="collapseExample" [ngbCollapse]="!isExpanded" *ngIf="onlyViewLoan==false">
                <div class="row">
                    <div class="col-12">
                        <div class="card mb-2">
                            <div class="card-header"><b>Guarantor Summary</b></div>
                            <div class="card-body">
                                <div *ngIf="request.Guarantors.length==0">
                                    <p class="text-danger">NO GUARANTOR</p>
                                </div>
                                <ng-container class="row" *ngIf="request.Guarantors.length>0">
                                    <div *ngFor="let contact of request.Guarantors">
                                        <div class="row">
                                            <div class="col-12 d-flex justify-content-end align-items-right">
                                                <button class="btn btn-warning m-2" *ngIf="editable ==true 
                                        && (this.sharedService.isBranchManager()==true)"
                                                    (click)="openEditGuaratorModal(contact)">
                                                    <i class="fas fa-pencil-alt"></i>
                                                </button>

                                                <button class="btn btn-info  m-2"
                                                    (click)="downloadGuarantorForm(contact)"  data-bs-toggle="tooltip"
                                                    data-placement="top" title="Print Guarantor Form" *ngIf="editable ==true 
                                            && (this.sharedService.isBranchManager()==true)">
                                                    <i class="fas fa-print"></i>
                                                </button>

                                                <button class="btn btn-danger  m-2"  data-bs-toggle="tooltip"
                                                    data-placement="top" title="Duplicated Customer"
                                                    (click)="openConflictedModal(contact.ConflictedCustomerList,contact.ConflictedGuarantorList,contact.ConflictedTicketCustomerList)"
                                                    *ngIf="(sharedService.isBossAccount()==true || sharedService.isAdmin()==true) && (contact.ConflictedCustomerList.length>0||contact.ConflictedGuarantorList.length>0||contact.ConflictedTicketCustomerList.length>0)">

                                                    <i class="fas fa-exclamation-triangle"></i>
                                                    {{contact.ConflictedCustomerList.length +
                                                    contact.ConflictedGuarantorList.length +
                                                    contact.ConflictedTicketCustomerList.length }} Conflicted
                                                </button>

                                                <button class="btn btn-primary  m-2"  data-bs-toggle="tooltip"
                                                    data-placement="top" title="View Guarantor Images"
                                                    (click)="viewContactImages(contact)"
                                                    *ngIf="sharedService.isStaff()==false">
                                                    <i class="fas fa-image"></i>
                                                </button>
                                            </div>
                                            <div class="col-6">
                                                Name: {{contact.Name}}
                                            </div>
                                            <div class="col-6" *ngIf="contact.ICNumber!=undefined">
                                                IC: {{sharedService.processICNum(contact.ICNumber)}}
                                            </div>
                                            <div class="col-6">
                                                Contact Number : {{contact.ContactNumber}}
                                            </div>
                                            <div class="col-6">
                                                Relation : {{contact.Relation}}
                                            </div>
                                        </div>
                                        <ng-container *ngIf="request.Guarantors.length>1">
                                            <hr />
                                        </ng-container>

                                    </div>

                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-2">
                        <div class="card mt-2">
                            <div class="card-body">
                                <div class="mb-2"
                                    *ngIf="previousEPFLoanList!=undefined && previousEPFLoanList.length>0">
                                    <div class="card shadow-lg" style="background-color: rgba(190, 212, 241, 0.425);">
                                        <div class="card-body">
                                            <h5 class="fw-bold">Previous Approved Loan(s)</h5>
                                            <hr />
                                            <ng-container *ngFor="let loan of previousEPFLoanList">
                                                <app-previous-approved-request-card [loan]="loan"
                                                    [epfAmount]="request.EpfAmount">
                                                </app-previous-approved-request-card>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                                <h4 class="fw-bold">Total Settlement =
                                    <span [ngClass]="{'text-danger':getTotalSettlementRatio()>
                                23,'text-success':getTotalSettlementRatio()<=23}">RM
                                        {{getTotalSettlement()|
                                        number:'1.0-2'}} ({{getTotalSettlementRatio() | number:'1.3-3'}}%)
                                    </span>
                                </h4>
                            </div>

                        </div>
                    </div>
                    <div class="col-12 mt-2">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="fw-bold"> Case Assessment :
                                    <span *ngIf="assessEPFCase()>=2" class="text-success"> SAFE</span>
                                    <span *ngIf="assessEPFCase()<2 &&assessEPFCase()>=1.5" class="text-warning">
                                        OK</span>
                                    <span *ngIf="assessEPFCase()<1.5" class="text-danger"> DANGER</span>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </ng-container>


        <!--NORMAL LOAN REQUEST OTHER THAN EPF SCHEME-->
        <ng-template #normalLoanRequest>
            <div class="card mb-2" *ngIf="request.LoanInfo.Scheme=='HUTANG'">
                <div class="card-header"><b>HUTANG Summary</b>
                </div>
                <div class="card-body">
                    <div class="row">
                        <ng-container *ngTemplateOutlet="conflictedCustomer;context:{request:request}">
                        </ng-container>
                        <div class="col-6">
                            Name : <span class="customerLink" (click)="goToCustomer()">{{request.CustomerName }}</span>
                        </div>
                        <div class="col-6">
                            IC : <span class="customerLink" (click)="goToCustomer()">{{
                                sharedService.processICNum(request.CustomerIC) }}</span>
                        </div>

                        <div class="col-6">
                            Remark :{{request.Remark}}
                        </div>

                        <div class="col-6">
                            Hutang Amount :RM {{request.LoanInfo.Principal |number:'1.2-2'}}
                        </div>
                    </div>
                </div>
            </div>


            <div class="card mb-2" *ngIf="request.LoanInfo.Scheme!='HUTANG'">
                <div class="card-header"><b>Loan Summary</b>
                </div>

                <div class="card-body">
                    <div class="row">
                        <ng-container *ngTemplateOutlet="conflictedCustomer;context:{request:request}">
                        </ng-container>
                        <div class="col-4">
                            Name : <span class="customerLink" (click)="goToCustomer()">{{request.CustomerName }}</span>
                        </div>
                        <div class="col-4">
                            IC : <span class="customerLink" (click)="goToCustomer()">{{
                                sharedService.processICNum(request.CustomerIC) }}</span>
                        </div>
                        <div class="col-4">

                        </div>
                        <div class="col-4">
                            Loan Amount : RM {{request.LoanInfo.Principal|number:'1.0-2'}}
                        </div>
                        <div class="col-4">
                            Term : {{request.LoanInfo.Term}} months
                        </div>
                        <div class="col-4">
                            Scheme : {{request.LoanInfo.Scheme}}
                            ({{request.LoanInfo.InterestRate ||0|number:'1.0-2'}}%)
                        </div>
                    </div>
                    <hr />
                    <div class="row">
                        <div class="col col-4">
                            Stamp :RM{{ request.LoanInfo.StampAmount||0|number:'1.0-2'}}
                        </div>
                        <div class="col col-4">
                            S/C :RM{{ request.LoanInfo.ServiceCharge||0|number:'1.0-2'}}
                        </div>
                        <div class="col col-4">
                            Duit Lebih :RM{{ calculateTotalDuitLebih(request)||0|number:'1.2-2'}}
                        </div>
                        <div class="col col-4">
                            Simpan Out :-RM{{ -request.LoanInfo.Reserve||0}}
                        </div>
                        <div class="col col-4">
                            Processing Charge :RM{{
                            (request.LoanInfo.ProcessingRate/100)*request.LoanInfo.Principal||0|number:'1.0-2'}}
                        </div>
                        <div class="col col-4">
                            Processing Rate : {{(request.LoanInfo.ProcessingRate)|number:'1.0-2'}}%
                        </div>
                        <div class="col col-4">
                            <b>ON HAND = RM {{calculateTotalOnHand(request) ||0|number:'1.2-2'}}</b>
                        </div>

                        <div class="col col-4">
                            <b>Remark: {{request.Remark}}</b>
                        </div>

                    </div>
                </div>


            </div>
        </ng-template>

    </div>

</div>