import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { AddCommissionsComponent } from 'src/app/Comission/add-commissions/add-commissions.component';
import { UploadImageComponent } from 'src/app/Images/upload-image/upload-image.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Account } from 'src/model/account.model';
import { Customer } from 'src/model/customer.model';
import { Loan } from 'src/model/loan.model';

@Component({
  selector: 'app-add-approved-loan',
  templateUrl: './add-approved-loan.component.html',
  styleUrls: ['./add-approved-loan.component.css']
})
export class AddApprovedLoanComponent implements OnInit {


  @Input() approvedLoan: Loan;
  @Input() totalOnHand: number;
  @Input() settlement: number;
  @Input() settlementWithCharges: number;
  @Input() ratio: number;
  @Input() approvedRequestId: number;
  @Input() selectedCustomerID: number;
  @Input() isMainAccount: boolean;


  selectedAccountId: number;
  loanDate: Date;

  disabledSubmit = false;

  companyAccountList: Account[] = new Array;
  hasApplicationForm: boolean;

  constructor(private router: Router, private activeModal: NgbActiveModal, public sharedService: SharedService, private restApi: RestApiService, private constructApi: ConstructAPI, private enums: Enums) { }

  ngOnInit(): void {

    var companyId = Number(sessionStorage.getItem('companyId'));
    this.getAllAccountsOfCompany(companyId);

    this.getApplicationFormExistence(companyId);
  }

  calculateTotalDuitLebih() {
    var amount = 0;

    if (this.approvedLoan.Extras != undefined && this.approvedLoan.Extras.length > 0) {
      for (let i = 0; i < this.approvedLoan.Extras.length; i++) {
        amount += this.approvedLoan.Extras[i].Amount;
      }
    }

    return amount;

  }

  setSelectedAccount(accountId: number) {

    if (accountId === this.selectedAccountId)
      return true
    else
      return false;
  }

  private getAllAccountsOfCompany(companyId: number) {

    var xhttp = this.restApi.getRequest(this.constructApi.getAccountsOfCompany(companyId))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let account = new Account(json.id, json.number, json.name, json.holderName, json.balance, json.companyId, json.username);


          var isStaff = (Number(sessionStorage.getItem("roleId")) === Number(this.enums.STAFF)) ? true : false;

          var loggedInUser = sessionStorage.getItem("username");
          if (json.username == undefined) {
            if (!isStaff)
              this.companyAccountList.push(account);
          }
          else
            this.companyAccountList.push(account);
        }
        );

      }
    };
  }


  private getApplicationFormExistence(companyId: number) {
    var xhttp = this.restApi.getRequest(this.constructApi.getCompanyCustomerApplicationFormExistence(companyId, this.selectedCustomerID))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var result = JSON.parse(xhttp.responseText);
        this.hasApplicationForm = Boolean(result);

      }
    };
  }


  openUploadSupportingDocModal() {
    const modalRef = this.sharedService.openModal(UploadImageComponent, "largeModal");

    modalRef.componentInstance.selectedCustomerId = this.selectedCustomerID;
    modalRef.componentInstance.isApplicationForm = true;
    modalRef.componentInstance.isSingleType = false

    this.hasApplicationForm = true;
  }

  openUploadLoanDocModal(loan: Loan) {
    const modalRef = this.sharedService.openModal(UploadImageComponent);

    modalRef.componentInstance.selectedCustomerId = this.selectedCustomerID;
    modalRef.componentInstance.isLoanDocument = true;
    modalRef.componentInstance.loanId = loan.ID;



  }



  isBalanceInsufficient(balance: number) {
    if (this.totalOnHand > balance) {
      return true;
    }
    else
      return false;
  }

  submit() {
    if (this.selectedAccountId == undefined || this.loanDate == undefined)
      this.sharedService.openAlert("Please fill in all necessary fields: Date and Account", this.enums.DANGER_ALERT);
    else if (this.hasApplicationForm == false && this.isMainAccount == true)
      this.sharedService.openAlert("Please upload application form", this.enums.DANGER_ALERT);
    else {
      this.disabledSubmit = true;

      var data = {
        "dateCreated": this.loanDate.toISOString(),
        "accountId": Number(this.selectedAccountId),
        "remark": ''
      }
      var xhr = this.restApi.postRequest(this.constructApi.getAddApprovedLoanToAccount(this.approvedRequestId), data)
      xhr.onreadystatechange = () => {
        if (xhr.readyState == 4) {
          this.disabledSubmit = false;
          //if success , then proceed
          if (xhr.status == 200) {
            this.closeModal();

            var json = JSON.parse(xhr.responseText);
            if (this.sharedService.isBranchAccountHandler() == true) {
              this.approvedLoan.ID = json.loanId;

              if (this.approvedLoan.Scheme != 'HUTANG') {

                if (this.approvedLoan.Scheme == 'EPF')
                  this.openUploadLoanDocModal(this.approvedLoan);


                if (this.isMainAccount == false && this.approvedLoan.Scheme == 'EPF') {

                  this.sharedService.openAlert("Please inform your main company to assign commission!", this.enums.SUCCESS_ALERT);
                  window.location.reload();

                }

                else
                  this.openAssignCommissionModal(this.approvedLoan);
              }

              else
                window.location.reload();
            }
            this.router.navigate(['manageCustomer', this.selectedCustomerID]);
          }
          else {
            //show error message
            this.sharedService.openAlert(JSON.parse(xhr.responseText).error, this.enums.DANGER_ALERT)

          }
        }
      }
    }
  }


  openAssignCommissionModal(loan: Loan) {
    const modalRef = this.sharedService.openModal(AddCommissionsComponent);
    modalRef.componentInstance.selectedLoan = loan;
  }

  closeModal() {
    this.activeModal.close();
  }
}
