<div class="container-fluid p-3">
    <div class="d-flex justify-content-end align-items-end">
        <button class="btn btn-info" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseExample">
            <i class="fas fa-chevron-circle-down" *ngIf="isCollapsed"></i>
            <i class="fas fa-chevron-circle-up" *ngIf="isCollapsed==false"></i>
        </button>
    </div>


    <mat-checkbox [(ngModel)]=" isCollapsed"></mat-checkbox>


    <div class="collapse" id="collapseExample" [ngbCollapse]="isCollapsed">
        <app-report-generator></app-report-generator>
    </div>


    <h2> Company Summary Report ({{endDate | date :'dd-MM-yyyy'}})
    </h2>

    <p [hidden]="reportList.length>0"> Sorry you do not have any record here</p>

    <div [hidden]="reportList.length==0">
        <div class="row">
            <div class="col-6">
                <mat-form-field appearance="fill">
                    <mat-label>Columns</mat-label>
                    <mat-select [formControl]="columnControl" multiple [(ngModel)]="selectedColumns"
                        (selectionChange)="onSelect()">
                        <mat-option *ngFor="let column of columnList" [value]="column">{{column}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-label>Company</mat-label>
                <mat-select [formControl]="companyControl" multiple>
                    <app-select-all [model]="companyControl" [values]="allCompanyCodeList">
                    </app-select-all>
                    <mat-option *ngFor="let code of allCompanyCodeList" [value]="code">{{code}}</mat-option>
                </mat-select>

            </div>
        </div>
        <div class="mat-elevation-z8">
            <div class="table-container">
                <table mat-table [dataSource]="dataSource" matSort>

                    <!-- Company Column -->
                    <ng-container matColumnDef="companyCode">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Company </th>
                        <td mat-cell *matCellDef="let report">
                            {{report.companyCode}}
                        </td>

                        <th mat-footer-cell *matFooterCellDef> Count: {{reportList.length}} </th>
                    </ng-container>

                    <!-- DateOperating Column -->
                    <ng-container matColumnDef="dateOperating">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Operating </th>
                        <td mat-cell *matCellDef="let report">
                            {{report.dateOperating |date:'dd-MM-yyyy'}}
                        </td>
                        <th mat-footer-cell *matFooterCellDef></th>
                    </ng-container>

                    <!-- CustomerCount Column -->

                    <ng-container matColumnDef="activeCount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Active Customer Count </th>
                        <td class="reportCol" mat-cell *matCellDef="let report"
                            (click)="toCustomerList(report.companyId)">
                            <ng-template #tooltipContent>
                                <div>
                                    <p class=" p-0 m-0 text-start"><small>Total: {{report.customerCount}}</small></p>
                                    <p class="p-0 m-0 text-start"><small>Warning: {{report.warningCount}}</small></p>
                                    <p class="p-0 m-0 text-start"><small>BD: {{report.bdCount}}</small></p>
                                    <p class="p-0 m-0 text-start"><small>CP: {{report.cpCount}}</small></p>
                                </div>
                            </ng-template>

                            {{report.activeCount}} <i class="far fa-question-circle ms-2" placement="top"
                                [ngbTooltip]="tooltipContent" style="cursor:pointer;"></i>
                        </td>
                        <th mat-footer-cell *matFooterCellDef> {{calculateTotalActiveCount()|number:'1.0-2'}}</th>
                    </ng-container>


                    <!-- ActiveOutstanding Column -->
                    <ng-container matColumnDef="activeOutstanding">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Active Outstanding </th>
                        <td mat-cell *matCellDef="let report">
                            {{report.activeOutstanding|number:'1.0-2'}}
                        </td>
                        <th mat-footer-cell *matFooterCellDef> {{calculateTotalActiveOutstanding()|number:'1.0-2'}}</th>
                    </ng-container>

                    <!-- BDOutstanding Column -->
                    <ng-container matColumnDef="bdOutstanding">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> BD Outstanding </th>
                        <td mat-cell *matCellDef="let report">
                            {{report.bdOutstanding|number:'1.0-2'}}
                        </td>
                        <th mat-footer-cell *matFooterCellDef> {{calculateTotalBDOutstanding()|number:'1.0-2'}}</th>
                    </ng-container>

                    <!-- CPOutstanding Column -->
                    <ng-container matColumnDef="cpOutstanding">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> CP Outstanding </th>
                        <td mat-cell *matCellDef="let report">
                            {{report.cpOutstanding|number:'1.0-2'}}
                        </td>
                        <th mat-footer-cell *matFooterCellDef>{{calculateTotalCPOutstanding()|number:'1.0-2'}} </th>
                    </ng-container>

                    <!-- Stock Total Column -->
                    <ng-container matColumnDef="stockTotal">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Stock </th>
                        <td mat-cell *matCellDef="let report">
                            {{report.stockTotal|number:'1.0-2'}}
                        </td>
                        <th mat-footer-cell *matFooterCellDef></th>
                    </ng-container>

                    <!-- ActivePercentage Column -->
                    <ng-container matColumnDef="activePercentage">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Active % </th>
                        <td mat-cell *matCellDef="let report">
                            {{report.activePercentage|number:'1.0-2'}} %
                        </td>
                        <th mat-footer-cell *matFooterCellDef></th>
                    </ng-container>

                    <!-- BDPercentage Column -->
                    <ng-container matColumnDef="bdPercentage">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> BD % </th>
                        <td mat-cell *matCellDef="let report">
                            {{report.bdPercentage|number:'1.0-2'}} %
                        </td>
                        <th mat-footer-cell *matFooterCellDef></th>
                    </ng-container>

                    <!-- CPPercentage Column -->
                    <ng-container matColumnDef="cpPercentage">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> CP % </th>
                        <td mat-cell *matCellDef="let report">
                            {{report.cpPercentage|number:'1.0-2'}} %
                        </td>
                        <th mat-footer-cell *matFooterCellDef></th>
                    </ng-container>


                    <!-- OfficeBalance Column -->

                    <ng-container matColumnDef="officeBalance">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Office Balance </th>
                        <td class="reportCol" mat-cell *matCellDef="let report"
                            (click)="toListHutang(report.companyId)">
                            <ng-template #tooltipContent>
                                <div>
                                    <p class=" p-0 m-0 text-start"><small>Bank:
                                            {{report.bankBalance|number:'1.0-2'}}</small>
                                    </p>
                                    <p class="p-0 m-0 text-start"><small>Cash: {{report.cashBalance
                                            |number:'1.0-2'}}</small>
                                    </p>
                                </div>
                            </ng-template>

                            {{report.officeBalance|number:'1.0-2'}} <i class="far fa-question-circle ms-2"
                                placement="top" [ngbTooltip]="tooltipContent" style="cursor:pointer;"></i>
                        </td>
                        <th mat-footer-cell *matFooterCellDef>{{calculateTotalOfficeBalance()|number:'1.0-2'}} </th>
                    </ng-container>

                    <!-- Expense Total Column -->
                    <ng-container matColumnDef="expenseTotal">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Expense + DL ({{getLastMonth()}}) </th>
                        <td class="reportCol" mat-cell *matCellDef="let report"
                            (click)="toReport(enums.EXPENSE_REPORT, report.companyId)">
                            {{report.expenseTotal|number:'1.0-2'}}
                        </td>
                        <th mat-footer-cell *matFooterCellDef> {{calculateTotalExpenses()|number:'1.0-2'}}</th>
                    </ng-container>

                    <!-- Expense Column -->
                    <ng-container matColumnDef="expense">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Expense ({{getLastMonth()}}) </th>
                        <td class="reportCol" mat-cell *matCellDef="let report"
                            (click)="toReport(enums.EXPENSE_REPORT, report.companyId)">
                            {{report.expense |number:'1.0-2'}}
                        </td>
                        <th mat-footer-cell *matFooterCellDef> {{calculateTotalExpensesOnly()|number:'1.0-2'}}</th>

                    </ng-container>

                    <!-- Expense DL Column -->
                    <ng-container matColumnDef="dlExpense">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> DL Expense({{getLastMonth()}}) </th>
                        <td class="reportCol" mat-cell *matCellDef="let report"
                            (click)="toReport(enums.EXPENSE_BY_EXTRAS_REPORT, report.companyId)">
                            {{ report.dlExpense|number:'1.0-2'}}
                        </td>
                        <th mat-footer-cell *matFooterCellDef> {{calculateTotalDLExpense()|number:'1.0-2'}}</th>
                    </ng-container>

                    <!-- DL Balance Column -->
                    <ng-container matColumnDef="duitLebihBalance">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> DL Balance </th>
                        <td class="reportCol" mat-cell *matCellDef="let report"
                            (click)="toReport(enums.EXTRA_REPORT, report.companyId)">
                            {{ report.duitLebihBalance|number:'1.0-2'}}
                        </td>
                        <th mat-footer-cell *matFooterCellDef>{{calculateTotalDLBalance()|number:'1.0-2'}} </th>
                    </ng-container>

                    <!-- Asset Column -->
                    <ng-container matColumnDef="assetBalance">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Asset </th>
                        <td class="reportCol" mat-cell *matCellDef="let report"
                            (click)="toReport(enums.ASSET_REPORT, report.companyId)">
                            {{report.assetBalance|number:'1.0-2'}}
                        </td>
                        <th mat-footer-cell *matFooterCellDef> {{calculateTotalAsset()|number:'1.0-2'}}</th>
                    </ng-container>

                    <!-- Saving Column -->
                    <ng-container matColumnDef="savingBalance">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Saving </th>
                        <td class="reportCol" mat-cell *matCellDef="let report"
                            (click)="toReport(enums.SAVING_REPORT, report.companyId)">
                            {{report.savingBalance |number:'1.0-2'}}
                        </td>
                        <th mat-footer-cell *matFooterCellDef>{{calculateTotalSaving()|number:'1.0-2'}} </th>
                    </ng-container>

                    <!-- TotalIn Column -->

                    <ng-container matColumnDef="totalIn">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Total In </th>
                        <td class="reportCol" mat-cell *matCellDef="let report"
                            (click)="toListHutang(report.companyId)">
                            {{report.cashTotalIn|number:'1.0-2'}}
                        </td>
                        <th mat-footer-cell *matFooterCellDef> {{calculateTotalIn()|number:'1.0-2'}}</th>
                    </ng-container>

                    <!-- TotalOut Column -->

                    <ng-container matColumnDef="totalOut">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Total Out </th>
                        <td class="reportCol" mat-cell *matCellDef="let report"
                            (click)="toListHutang(report.companyId)">

                            {{report.cashTotalOut|number:'1.0-2'}}
                        </td>
                        <th mat-footer-cell *matFooterCellDef> {{calculateTotalOut()|number:'1.0-2'}}</th>
                    </ng-container>

                    <!-- TotalBalance Column -->

                    <ng-container matColumnDef="cashTotalBalance">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Total In - Out </th>
                        <td class="reportCol" mat-cell *matCellDef="let report"
                            (click)="toListHutang(report.companyId)">

                            {{report.cashTotalBalance|number:'1.0-2'}}
                        </td>
                        <th mat-footer-cell *matFooterCellDef> </th>
                    </ng-container>

                    <!-- Stamp Column -->
                    <ng-container matColumnDef="stampTotalIn">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Stamp ({{getLastMonth()}})</th>
                        <td class="reportCol" mat-cell *matCellDef="let report"
                            (click)="toReport(enums.STAMP_REPORT, report.companyId)">
                            {{report.stampTotalIn |number:'1.0-2'}}
                        </td>
                        <th mat-footer-cell *matFooterCellDef>{{calculateTotalStamping()|number:'1.0-2'}} </th>
                    </ng-container>

                    <!-- SC Column -->
                    <ng-container matColumnDef="scTotalIn">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> SC ({{getLastMonth()}})</th>
                        <td class="reportCol" mat-cell *matCellDef="let report"
                            (click)="toReport(enums.SC_REPORT, report.companyId)">
                            {{report.scTotalIn |number:'1.0-2'}}
                        </td>
                        <th mat-footer-cell *matFooterCellDef> {{calculateTotalSC() |number:'1.0-2'}}</th>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
                </table>

                <mat-paginator [pageSize]="500" [pageSizeOptions]="[100,200,300,400,500]" showFirstLastButtons>
                </mat-paginator>
            </div>
        </div>
    </div>
</div>