<h3 class="text-start" id="pageTitle" style="margin: 15px;">Advance List</h3>
<div class="content m-3">

    <div>
        <button class="btn btn-primary float-end" (click)="openAddAdvanceModal()"><i class="fas fa-plus"></i> Add
            Advance</button>
    </div>
    <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Enter searching criteria">
    </mat-form-field>

    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>

            <!-- Date Column -->
            <ng-container matColumnDef="Date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
                <td mat-cell *matCellDef="let advance" [ngClass]="getBackgroundColor(advance.Amount)"> {{advance.Date |
                    date:'dd-MM-yyyy'}}
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Username Column -->
            <ng-container matColumnDef="Username">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Username </th>
                <td mat-cell *matCellDef="let advance" [ngClass]="getBackgroundColor(advance.Amount)">
                    {{advance.Username}} </td>
                <td mat-footer-cell *matFooterCellDef>
                    <h5 class="fw-bold">Total:</h5>
                </td>
            </ng-container>

            <!-- Amount Column -->
            <ng-container matColumnDef="Amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Amount</th>
                <td mat-cell *matCellDef="let advance" [ngClass]="getBackgroundColor(advance.Amount)">
                    {{advance.Amount}}
                </td>
                <td mat-footer-cell *matFooterCellDef>
                    <h5 class="fw-bold">RM {{calculateTotalAmount()}}</h5>
                </td>
            </ng-container>


            <!-- Remark Column -->
            <ng-container matColumnDef="Remark">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Remark </th>
                <td mat-cell *matCellDef="let advance" [ngClass]="getBackgroundColor(advance.Amount)">
                    {{advance.Remark}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Action Column -->
            <ng-container matColumnDef="Actions">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
                <td mat-cell *matCellDef="let advance">

                    <button class="btn btn-dark" (click)="openPayAdvanceModal(advance,true)" [hidden]="advance.Amount>0"
                        #tooltip="matTooltip" matTooltip="Advance More" matTooltipPosition="above"
                        matTooltipHideDelay="100" matTooltipClass="tooltip-black">
                        <i class="fas fa-plus-circle"></i>
                    </button>
                    <button class="btn btn-success ms-2" (click)="openPayAdvanceModal(advance,false)" [hidden]="advance.Amount>0"
                        #tooltip="matTooltip" matTooltip="Return Advance" matTooltipPosition="above"
                        matTooltipHideDelay="100" matTooltipClass="tooltip-black">
                        <i class="fas fa-money-bill-wave"></i>
                    </button>

                    <button class="btn btn-primary" (click)="openPayAdvanceModal(advance,true)" [hidden]="advance.Amount<0">
                        <i class="fas fa-plus-circle" #tooltip="matTooltip" matTooltip="Simpan More"
                            matTooltipPosition="above" matTooltipHideDelay="100" matTooltipClass="tooltip-black"></i>
                    </button>
                    <button class="btn btn-warning ms-2" (click)="openPayAdvanceModal(advance,false)"
                        [hidden]="advance.Amount<0" #tooltip="matTooltip" matTooltip="Payout Simpan"
                        matTooltipPosition="above" matTooltipHideDelay="100" matTooltipClass="tooltip-black">
                        <i class="fas fa-money-bill-wave"></i>
                    </button>


                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>



            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[25,50, 100]" showFirstLastButtons></mat-paginator>
    </div>
</div>