import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Company } from 'src/model/company.model';
import { Customer } from 'src/model/customer.model';
import { FormJ } from 'src/model/formJ.model';
import { PaymentSchedule } from 'src/model/paymentSchedule.model';


interface ProcessedSchedule {
  months: string,
  amount: number
}

@Component({
  selector: 'app-form-j-schedule',
  templateUrl: './form-j-schedule.component.html',
  styleUrls: ['./form-j-schedule.component.css']
})
export class FormJScheduleComponent implements OnInit {
  @Input() selectedCompany: Company;
  @Input() selectedCustomer: Customer;
  @Input() selectedFormJ: FormJ;
  processedScheduleList: ProcessedSchedule[] = new Array;

  repaymentAmount: number = 0;

  constructor(public sharedService: SharedService) { }

  ngOnInit(): void {

    this.getPaymentScheduleMonth();
  }


  getPaymentScheduleMonth() {
    //CASE 1 : if the payment schedule only have 1 section
    if (this.selectedFormJ.PaymentSchedule.length == 1) {
      // if the only section is more than 1 term, then show 1 ~ x-1
      if (this.selectedFormJ.PaymentSchedule[0].monthsCount > 1) {
        var processedSchedule = { months: "1~" + (this.selectedFormJ.PaymentSchedule[0].monthsCount - 1), amount: this.selectedFormJ.PaymentSchedule[0].amount };

        //calculate total repayment amount without last payment
        this.repaymentAmount += (this.selectedFormJ.PaymentSchedule[0].monthsCount - 1) * this.selectedFormJ.PaymentSchedule[0].amount;
      }

      else
      //if the schedule is only 1 term, then empty the month, and amount = LastPayment
      {
        var processedSchedule = { months: "", amount: this.selectedFormJ.LastPayment };
      }


      this.processedScheduleList.push(processedSchedule);
    }

    // CASE 2: if payment schedule is more than 1 section
    else {
      var x = 1;
      //remove last month from schedule if the schedules have above 1 section,means the last month should be deducted
      this.selectedFormJ.PaymentSchedule[this.selectedFormJ.PaymentSchedule.length - 1].monthsCount = this.selectedFormJ.PaymentSchedule[this.selectedFormJ.PaymentSchedule.length - 1].monthsCount - 1;


      for (let i = 0; i < this.selectedFormJ.PaymentSchedule.length; i++) {
        if (this.selectedFormJ.PaymentSchedule[i].monthsCount == 1) {
          var month = x.toString();
        }
        else {
          var endNum = x + ((this.selectedFormJ.PaymentSchedule[i].monthsCount) - 1);
          var month = x + "~" + endNum;
        }
        x = x + this.selectedFormJ.PaymentSchedule[i].monthsCount;

        //if after deducting the last section month count, the month count =0, can ignore because it will show in last payment
        if (this.selectedFormJ.PaymentSchedule[i].monthsCount > 0) {
          var processedSchedule = { months: month, amount: this.selectedFormJ.PaymentSchedule[i].amount };
          this.processedScheduleList.push(processedSchedule);
        }

        //calculate total repayment amount without last payment
        this.repaymentAmount += this.selectedFormJ.PaymentSchedule[i].monthsCount * this.selectedFormJ.PaymentSchedule[i].amount;

      }
    }

    //calculate total repayment amount by adding last payment amount
    this.repaymentAmount += this.selectedFormJ.LastPayment;

  }


}
