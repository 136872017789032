<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">{{request.CustomerName}} ({{sharedService.processICNum(request.CustomerIC)}})</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()" aria-label="Close"></button>
    </div>

    <div class="modal-body">
        <div class="container-fluid p-0">



            <div class="row" appNoRightClick>
                <div class="col-3 m-2 " *ngFor="let img of imageArr">
                    <div class="row">
                        <div class="col-12 imageBox">
                            <span class="badge text-bg-dark dateTag" #tooltip="matTooltip" [matTooltip]="img.lastModified"
                                matTooltipPosition="above" matTooltipHideDelay="100"
                                matTooltipClass="tooltip-black">{{img.lastModified | date:'dd-MM-yyyy'}}</span>
                            <img [src]="img.url" />

                        </div>
                    </div>
                </div>


            </div>


            <div class="row" appNoRightClick>
                <div class="col-5 m-2 imageBox" *ngFor="let file of fileArr">
                    <h1>
                        <i class="fas fa-file-pdf" *ngIf="sharedService.checkIsPDF(file.contentType)"></i>
                        <i class="fas fa-video" *ngIf="sharedService.checkIsVideo(file.contentType,file.label)"></i>
                        <i class="fas fa-file"
                            *ngIf="sharedService.checkIsPDF(file.contentType)==false && sharedService.checkIsVideo(file.contentType,file.label)==false"></i>
                    </h1>
                    <span class="badge text-bg-dark dateTag" #tooltip="matTooltip" [matTooltip]="file.lastModified"
                        matTooltipPosition="above" matTooltipHideDelay="100"
                        matTooltipClass="tooltip-black">{{file.lastModified | date:'dd-MM-yyyy'}}</span>

                    <p class="m-1 downloadLink d-block" (click)="downloadFile(file.label,request.CustomerId)">
                        {{file.label}}
                    </p>
                    <button class="btn btn-info d-flex justify-content-end align-items-end"
                        (click)="previewFile(request.CustomerId,file.label)"
                        *ngIf="sharedService.checkIsPDF(file.contentType)|| sharedService.checkIsVideo(file.contentType,file.label)"><i
                            class="fas fa-eye"></i> </button>

                </div>

            </div>

        </div>
    </div>
</form>