<div class="row" appNoRightClick>
    <div class="col-2 m-1 " *ngFor="let img of docArr; let i = index">
        <div class="row">
            <div *ngIf="checkIsImage(img.contentType)==true ; else sdFileTemplate">
                <div class="col-12 imageBox" (click)="openEnlargeImageModal(img.label,i)">
                    <span class="badge text-bg-dark dateTag" matTooltip="{{img.lastModified}} | By:  {{img.uploadedBy}}"
                        matTooltipPosition="above" matTooltipHideDelay="100" #tooltip="matTooltip"
                        matTooltipClass="tooltip-black">{{img.lastModified | date:'dd-MM-yyyy'}}</span>
                    <img [src]="img.url" *ngIf="checkIsImage(img.contentType)==true" />

                </div>
                <div class="col-12" style="width: 250px;">
                    <p class="downloadLink" (click)="downloadFile(img.label,viewCustomer.ID)">
                        {{img.label}}
                    </p>
                </div>

                <div class="dropdown show ms-1" *ngIf="checkCanConvertSupportingDoc(img.label)==true"
                    #tooltip="matTooltip" matTooltip="Convert Type" matTooltipPosition="above" matTooltipHideDelay="100"
                    matTooltipClass="tooltip-black">
                    <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                         data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fas fa-exchange-alt"></i>
                    </a>

                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <a class="dropdown-item"
                            (click)="confirmBeforeChangeType(viewCustomer.ID,img.label,enums.REFERENCE_FORM)"
                            *ngIf="isContactConvertibleDoc(img.label)==false">Reference
                            Form</a>
                        <a class="dropdown-item"
                            (click)="confirmBeforeChangeType(viewCustomer.ID,img.label,enums.IC_FRONT)"
                            *ngIf="isContactConvertibleDoc(img.label)==false">IC
                            Front</a>
                        <a class="dropdown-item"
                            (click)="confirmBeforeChangeType(viewCustomer.ID,img.label,enums.IC_BACK)"
                            *ngIf="isContactConvertibleDoc(img.label)==false">IC
                            Back</a>
                        <a class="dropdown-item"
                            (click)="confirmBeforeChangeType(viewCustomer.ID,img.label,enums.CONSENT_FORM)"
                            *ngIf="isContactConvertibleDoc(img.label)==false">Consent
                            Form</a>
                        <a class="dropdown-item"
                            (click)="confirmBeforeChangeType(viewCustomer.ID,img.label,enums.EPF_STATEMENT)"
                            *ngIf="isContactConvertibleDoc(img.label)==false">EPF Statement</a>
                        <a class="dropdown-item"
                            (click)="confirmBeforeChangeType(viewCustomer.ID,img.label,enums.FORM_J)"
                            *ngIf="isContactConvertibleDoc(img.label)==false">Signed FormJ</a>
                        <a class="dropdown-item"
                            (click)="confirmBeforeChangeType(viewCustomer.ID,img.label,enums.PAYSLIP)"
                            *ngIf="isContactConvertibleDoc(img.label)==false">Payslip</a>
                        <a class="dropdown-item"
                            (click)="confirmBeforeChangeType(viewCustomer.ID,img.label,enums.BANK_STATEMENT)"
                            *ngIf="isContactConvertibleDoc(img.label)==false">Bank Statement</a>
                        <a class="dropdown-item"
                            (click)="confirmBeforeChangeType(viewCustomer.ID,img.label,enums.ATM_CARD)"
                            *ngIf="isContactConvertibleDoc(img.label)==false">ATM</a>
                        <!-- <a class="dropdown-item"
                            (click)="confirmBeforeContactPhotoChangeType(viewCustomer.ID, img.label)"
                            *ngIf="isContactConvertibleDoc(img.label)==true">Guarantor Form</a> -->
                    </div>
                </div>

            </div>

            <ng-template #sdFileTemplate>
                <div class="col-12 imageBox">
                    <span class="badge text-bg-dark dateTag" #tooltip="matTooltip" [matTooltip]="img.lastModified"
                        matTooltipPosition="above" matTooltipHideDelay="100">{{img.lastModified |
                        date:'dd-MM-yyyy'}}</span>

                    <div class="row">
                        <div class="col-12 d-flex align-items-center justify-content-center">
                            <h1><i class="fas fa-file-pdf" *ngIf="sharedService.checkIsPDF(img.contentType)"></i>
                                <i class="fas fa-video"
                                    *ngIf="sharedService.checkIsVideo(img.contentType,img.label)"></i>
                                <i class="fas fa-file"
                                    *ngIf="sharedService.checkIsPDF(img.contentType)==false && sharedService.checkIsVideo(img.contentType,img.label)==false"></i>
                            </h1>
                        </div>
                        <div class="col-12" style="width: 250px;">
                            <p class="m-1 downloadLink" (click)="downloadFile(img.label,viewCustomer.ID)">
                                {{img.label}}
                            </p>
                        </div>

                    </div>

                </div>

                <div class="col-12">
                    <button class="btn btn-danger" *ngIf="sharedService.isBossAccount() || sharedService.isAdmin()"><i
                            class="fas fa-trash-alt" (click)="confirmDeleteFile(img.label,i)"></i>
                    </button>
                    <button class="btn btn-info " (click)="previewFile(img.label)"
                        *ngIf="sharedService.checkIsPDF(img.contentType) || sharedService.checkIsVideo(img.contentType,img.label)"><i
                            class="fas fa-eye"></i>
                    </button>

                    <div class="dropdown show " *ngIf="checkCanConvertSupportingDoc(img.label)==true"
                        #tooltip="matTooltip" matTooltip="Convert Type" matTooltipPosition="above"
                        matTooltipHideDelay="100" matTooltipClass="tooltip-black">
                        <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                             data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-exchange-alt"></i>
                        </a>

                        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <a class="dropdown-item"
                                (click)="confirmBeforeChangeType(viewCustomer.ID,img.label,enums.REFERENCE_FORM)"
                                *ngIf="isContactConvertibleDoc(img.label)==false">Reference
                                Form</a>
                            <a class="dropdown-item"
                                (click)="confirmBeforeChangeType(viewCustomer.ID,img.label,enums.IC_FRONT)"
                                *ngIf="isContactConvertibleDoc(img.label)==false">IC
                                Front</a>
                            <a class="dropdown-item"
                                (click)="confirmBeforeChangeType(viewCustomer.ID,img.label,enums.IC_BACK)"
                                *ngIf="isContactConvertibleDoc(img.label)==false">IC
                                Back</a>
                            <a class="dropdown-item"
                                (click)="confirmBeforeChangeType(viewCustomer.ID,img.label,enums.CONSENT_FORM)"
                                *ngIf="isContactConvertibleDoc(img.label)==false">Consent
                                Form</a>
                            <a class="dropdown-item"
                                (click)="confirmBeforeChangeType(viewCustomer.ID,img.label,enums.EPF_STATEMENT)"
                                *ngIf="isContactConvertibleDoc(img.label)==false">EPF Statement</a>
                            <a class="dropdown-item"
                                (click)="confirmBeforeChangeType(viewCustomer.ID,img.label,enums.FORM_J)"
                                *ngIf="isContactConvertibleDoc(img.label)==false">Signed FormJ</a>
                            <a class="dropdown-item"
                                (click)="confirmBeforeChangeType(viewCustomer.ID,img.label,enums.PAYSLIP)"
                                *ngIf="isContactConvertibleDoc(img.label)==false">Payslip</a>
                            <a class="dropdown-item"
                                (click)="confirmBeforeChangeType(viewCustomer.ID,img.label,enums.BANK_STATEMENT)"
                                *ngIf="isContactConvertibleDoc(img.label)==false">Bank Statement</a>
                            <a class="dropdown-item"
                                (click)="confirmBeforeChangeType(viewCustomer.ID,img.label,enums.ATM_CARD)"
                                *ngIf="isContactConvertibleDoc(img.label)==false">ATM</a>
                            <!-- <a class="dropdown-item"
                            (click)="confirmBeforeContactPhotoChangeType(viewCustomer.ID, img.label)"
                            *ngIf="isContactConvertibleDoc(img.label)==true">Guarantor Form</a> -->
                        </div>
                    </div>
                </div>
            </ng-template>





        </div>


    </div>
</div>