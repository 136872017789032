<h3 class="text-start" id="pageTitle" style="margin: 15px;">User List</h3>
<div class="content m-3">

    <ul class="nav nav-tabs">
        <li class="nav-item">
            <a class="nav-link active" role="tab"  data-bs-toggle="tab" href="#tabUserList">
                <p>UserList</p>

            </a>
        </li>

        <li class="nav-item">
            <a class="nav-link" role="tab"  data-bs-toggle="tab" href="#tabPayroll">
                <p>Payroll</p>
            </a>
        </li>

    </ul>
    <div class="tab-content">
        <div class="tab-pane active" role="tabpanel" id="tabUserList">
            <mat-form-field>
                <mat-label>Filter</mat-label>
                <input matInput placeholder="Enter searching criteria" (keyup)="applyFilter($event)">
            </mat-form-field>

            <div class="mat-elevation-z8">
                <table mat-table [dataSource]="dataSource" matSort>

                    <!-- Username Column -->
                    <ng-container matColumnDef="Username">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Username </th>
                        <td mat-cell *matCellDef="let user" [ngClass]="{'deactivatedUser':user.Active==false}">
                            {{user.Username}} </td>
                    </ng-container>

                    <!-- Role Column -->
                    <ng-container matColumnDef="RoleName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Role </th>
                        <td mat-cell *matCellDef="let user" style="cursor: pointer;"
                            [ngClass]="{'deactivatedUser':user.Active==false}">
                            {{user.RoleName}} </td>
                    </ng-container>

                    <!-- Company Column -->
                    <ng-container matColumnDef="CompanyName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Company </th>
                        <td mat-cell *matCellDef="let user" [ngClass]="{'deactivatedUser':user.Active==false}">
                            {{user.CompanyName}} </td>
                    </ng-container>

                    <!-- Salary Column -->
                    <ng-container matColumnDef="Salary">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Salary </th>
                        <td mat-cell *matCellDef="let user" [ngClass]="{'deactivatedUser':user.Active==false}"> RM
                            {{user.Salary}} </td>
                    </ng-container>

                    <!-- Action Column -->
                    <ng-container matColumnDef="Actions">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
                        <td mat-cell *matCellDef="let user" [ngClass]="{'deactivatedUser':user.Active==false}">

                            <button class="btn btn-danger" (click)="confirmDeleteUser(user)" #tooltip="matTooltip"
                                matTooltip="Delete User" matTooltipPosition="above" matTooltipHideDelay="300"
                                matTooltipClass="tooltip-black" *ngIf="sharedService.isBossAccount()==true">
                                <i class="fas fa-trash-alt"></i>
                            </button>
                            <button class="btn btn-warning ms-2" (click)="openEditUserDialog(user)"
                                #tooltip="matTooltip" matTooltip="Edit User" matTooltipPosition="above"
                                matTooltipHideDelay="300" matTooltipClass="tooltip-black"
                                *ngIf="sharedService.isBossAccount()==true">
                                <i class="fas fa-pencil-alt"></i>
                            </button>
                            <button class="btn btn-secondary ms-2" (click)="confirmBeforeDeactive(user)"
                                #tooltip="matTooltip" matTooltip="Deactivate User" matTooltipPosition="above"
                                matTooltipHideDelay="300" matTooltipClass="tooltip-black"
                                *ngIf="sharedService.isBossAccount()==true && user.Active==true">
                                <i class="fas fa-times"></i>
                            </button>
                            <button class="btn btn-success ms-2" (click)="confirmBeforePromote(user)"
                                *ngIf="user.RoleId==enums.STAFF && sharedService.isBossAccount()==true"
                                #tooltip="matTooltip" matTooltip="Promote User" matTooltipPosition="above"
                                matTooltipHideDelay="300" matTooltipClass="tooltip-black">
                                <i class="fas fa-arrow-up"></i>
                            </button>
                            <button class="btn btn-info ms-2" (click)="confirmBeforeDemote(user)"
                                *ngIf="user.RoleId==enums.BRANCH_MANAGER && sharedService.isBossAccount()==true"
                                #tooltip="matTooltip" matTooltip="Demote User" matTooltipPosition="above"
                                matTooltipHideDelay="300" matTooltipClass="tooltip-black">
                                <i class="fas fa-arrow-down"></i>
                            </button>

                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[ 100,200,300,500]" showFirstLastButtons></mat-paginator>
            </div>
        </div>


        <div class="tab-pane" role="tabpanel" id="tabPayroll">
            <!-- <div class="row ">
                <div class="col-12 d-flex justify-content-end align-items-end">
                    <button class="btn btn-info ">Pay All</button>
                </div>
            </div> -->
            <ng-container *ngFor="let user of userList">
                <div class="card mt-2">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                {{user.Username}}
                            </div>
                            <div class="col">
                                <span class="text-danger fw-bold"> RM
                                    {{user.Salary }}</span>

                            </div>
                            <div class="col">
                                <button class="btn btn-success"
                                    (click)="openAddSalaryExpenseModal(user.Username,user.Salary)">Pay
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </ng-container>
        </div>
    </div>





</div>