import { GlobalCustomer } from "./globalCustomer.model"
import { LoanRequest } from "./loanRequest.model"

export class Contact {



    constructor(private _id: number, private _name: string, private _icNumber: string, private _contactNumber: string, private _address: string,
        private _customerId: number, private _relation: string, private _remark: string, private _isSelected?: boolean, private _guarantorFilename?: string,
        private _photoExisted?: boolean, private _conflictedCustomerList?: GlobalCustomer[], private _conflictedGuarantorList?: GlobalCustomer[], private _conflictedTicketCustomerList?: LoanRequest[]) {

    }


    set ID(id: number) { this._id = id }
    get ID(): number { return this._id }

    set Name(name: string) { this._name = name }
    get Name(): string { return this._name }

    set ContactNumber(contactNumber: string) { this._contactNumber = contactNumber }
    get ContactNumber(): string { return this._contactNumber }

    set ICNumber(icNumber: string) { this._icNumber = icNumber }
    get ICNumber(): string { return this._icNumber }

    set Address(address: string) { this._address = address }
    get Address(): string { return this._address }

    set CustomerId(customerId: number) { this._customerId = customerId }
    get CustomerId(): number { return this._customerId }

    set Relation(relation: string) { this._relation = relation }
    get Relation(): string { return this._relation }

    set Remark(remark: string) { this._remark = remark }
    get Remark(): string { return this._remark }

    set IsSelected(isSelected: boolean) { this._isSelected = isSelected }
    get IsSelected(): boolean { return this._isSelected }

    set GuarantorFilename(guarantorFilename: string) { this._guarantorFilename = guarantorFilename }
    get GuarantorFilename(): string { return this._guarantorFilename }

    set PhotoExisted(photoExisted: boolean) { this._photoExisted = photoExisted }
    get PhotoExisted(): boolean { return this._photoExisted }

    set ConflictedCustomerList(duplicatedCustomerList: GlobalCustomer[]) { this._conflictedCustomerList = duplicatedCustomerList }
    get ConflictedCustomerList(): GlobalCustomer[] { return this._conflictedCustomerList }

    get ConflictedGuarantorList(): GlobalCustomer[] { return this._conflictedGuarantorList }
    set ConflictedGuarantorList(value: GlobalCustomer[]) { this._conflictedGuarantorList = value }


    get ConflictedTicketCustomerList(): LoanRequest[] { return this._conflictedTicketCustomerList }
    set ConflictedTicketCustomerList(value: LoanRequest[]) { this._conflictedTicketCustomerList = value }

}

