<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Customer IC Change history</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body">
        <p *ngIf="historyList.length==0">No change record</p>
        <table class="table table-bordered" *ngIf="historyList.length>0">
            <thead>
                <tr>
                    <th>Modified By</th>
                    <th>Modified on</th>
                    <th>Old Value</th>
                    <th>New Value</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let history of historyList">
                    <td> {{history.changed_by}}</td>
                    <td> {{history.date | date:'dd-MM-yyyy'}}</td>
                    <td>
                        {{sharedService.processICNum(history.oldIC) }}
                    </td>
                    <td>
                        {{sharedService.processICNum(history.newIC)}}
                    </td>
                </tr>
            </tbody>
        </table>



    </div>

    <div class="modal-footer">
    </div>

</form>