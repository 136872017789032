import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { Company } from 'src/model/company.model';
import { Role } from 'src/model/role.model';
import { Account } from 'src/model/account.model';
import { APIAddress } from 'src/app/API/apiAddresses';
import { RestApiService } from 'src/app/API/restapi';
import { isEmpty } from 'rxjs/operators';
import { isNull } from 'util';
import { Enums } from 'src/app/Shared/enums';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { AlertDialogComponent } from 'src/app/Shared/alert-dialog/alert-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/Shared/shared-service.service';



@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.css']
})
export class RegisterUserComponent implements OnInit {

  username: string;
  password: string;
  repeatPassword: string;

  initialCashAmount: number;
  initialAccountDate: Date;
  selectedCompany: string = "COMPANY";
  selectedCompanyID: number;
  selectedRole: string = "ROLE";
  selectedRoleID: number;

  companyList: Company[] = new Array;
  roleList: Role[] = new Array;

  constructor(private modalService: NgbModal, private _auth: AuthService, private apiAddress: APIAddress,
    private restapi: RestApiService, private constructApi: ConstructAPI, public enums: Enums, public sharedService: SharedService) { }

  ngOnInit(): void {
    this.getAllCompanies();
    this.getAllRoles();
  }

  setSelectedRole(roleID: number, roleName: string) {

    this.selectedRole = roleName;
    this.selectedRoleID = roleID;

  }

  setSelectedCompany(companyId: number, companyCode: string) {

    this.selectedCompany = companyCode;
    this.selectedCompanyID = companyId;

  }


  private getAllRoles() {
    // var xhttp = new XMLHttpRequest();
    // var url = "http://localhost:10080/api/v1/roles";
    // xhttp.withCredentials = true;

    var xhttp = this.restapi.getRequest(this.constructApi.getAllRoles())
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // Typical action to be performed when the document is ready:

        var jsonArray = JSON.parse((xhttp.responseText));
        // console.log(jsonArray);

        jsonArray.forEach(json => {

          // create role instance with all response data
          let role = new Role(json.id, json.name);

          //add role instance into role list
          this.roleList.push(role);
        }
        );

      }
    };
    // xhttp.open("GET", url, true);
    // xhttp.send();

  }

  private getAllCompanies() {

    // var xhttp = new XMLHttpRequest();
    // var url = "http://localhost:10080/api/v1/companies";
    // xhttp.withCredentials = true;

    var xhttp = this.restapi.getRequest(this.constructApi.getAllCompanies())
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // Typical action to be performed when the document is ready:

        var jsonArray = JSON.parse((xhttp.responseText));
        // console.log(jsonArray);

        jsonArray.forEach(json => {

          // create company instance with all response data
          let company = new Company(json.id, json.name, json.address, json.companyCode, json.contactNo);


          //add company instance into company list
          this.companyList.push(company);
        }
        );

      }
    };
    // xhttp.open("GET", url, true);
    // xhttp.send();

  }


  validateField() {
    var errorMsg = "";

    //username
    if (this.username == undefined || this.username.length < 6) {
      errorMsg += "❌ Username must contain more than 6 characters";
    }

    var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;


    //password


    if (this.password == undefined || this.password.length <= 10) {
      errorMsg += "\n❌ Password must at least contain more than 10 characters";
    }
    else if (this.password != this.repeatPassword) {
      errorMsg += "\n❌ Password and confirm password are different!";
    }
    else if (format.test(this.password) == false) {
      errorMsg += "\n❌ Password must contain at least a special character : @!-_?]/&*^%$";
    }

    //check company if not admin
    // this.sharedService.openAlert(this.selectedRoleID +"|"+this.selectedRole)
    if (!this.selectedRoleID)
      errorMsg += "\n❌ Please select your role";

    if ((!this.sharedService.isAdmin() && !this.sharedService.isBossAccount() && !this.sharedService.isFinance() && !this.sharedService.isExpenseAdmin() && !this.sharedService.isAuditor()) && !this.selectedCompanyID) {
      errorMsg += "\n❌ Please select your company before proceed";
    }

    if (!this.sharedService.isAdmin() && !this.sharedService.isBossAccount() && !this.sharedService.isFinance() && !this.sharedService.isExpenseAdmin() && !this.sharedService.isAuditor()) {
      if (this.initialCashAmount == undefined)
        errorMsg += "\n❌ Please fill in your initial cash amount";

      if (this.initialAccountDate == undefined)
        errorMsg += "\n❌ Please fill in your initial cash account date";
    }

    if (errorMsg != "")
      this.sharedService.openAlert(errorMsg, this.enums.DANGER_ALERT);
    else
      this.registerUser();
  }
  registerUser() {
    if (this.initialAccountDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var reduceASecDate = new Date(Number(this.initialAccountDate) - 1);

    var xhr;
    if (this.selectedRoleID != this.enums.ADMIN && this.selectedRoleID != this.enums.BOSS && this.selectedRoleID != this.enums.FINANCE && this.selectedRoleID != this.enums.EXPENSE_ADMIN && this.selectedRoleID != this.enums.AUDITOR) {
      xhr = this._auth.registerUser(
        {
          "username": this.username,
          "password": this.password,
          "companyId": this.selectedCompanyID,
          "roleId": Number(this.selectedRoleID),
          "salary": 0,
          "initialAccountBalance": this.initialCashAmount,
          "initialAccountDate": reduceASecDate
        });
    } else {
      xhr = this._auth.registerUser(
        {
          "username": this.username,
          "password": this.password,
          "roleId": Number(this.selectedRoleID)
        });
    }


    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {

        //if success
        if (xhr.status == 200) {

          this.sharedService.openAlert("Successfully registered your account!", this.enums.SUCCESS_ALERT)
          this.reset();

        }
        //if error
        else {
          var json = JSON.parse((xhr.responseText));

          this.sharedService.openAlert(json.error, this.enums.DANGER_ALERT);
        }

      }

    }

  }

  reset() {
    this.username = "";
    this.password = "";
    this.selectedCompany = "COMPANY";
    this.selectedRole = "ROLE";
    this.selectedCompanyID = 0;
    this.selectedRoleID = 0;
  }

  addCashAccount() {

    var companyId = Number(sessionStorage.getItem('companyId'));
    // var loggedInUsername = sessionStorage.getItem('username');

    var data = {
      "name": this.username + "'s CASH ACCOUNT",
      "holderName": this.username,
      "companyId": companyId,
      "username": this.username
    };

    var xhr = this.restapi.postRequest(this.apiAddress.ADD_ACCOUNT, data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        var json = JSON.parse(xhr.responseText);

        var cashAccount = new Account(json.id, json.accountNumber, json.name, json.holdername, json.balnce, json.companyid, json.username);

        // this.passEntry.emit(customer);
        this.sharedService.openAlert("Successfully added CASH ACCOUNT!", this.enums.SUCCESS_ALERT);

      }
    }
  }

}
