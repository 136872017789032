<div class="row p-3">
    <div class="col-12 col-md-3" [hidden]="isConfirmed==false">
        <div class="row">
            <div class="col-12">
                <p>Start Date</p>
            </div>
            <div class="col-12">
                <mat-form-field class="full-width-formField p-0">
                    <input matInput readonly [matDatepicker]="startDatePicker" name="startDate" [(ngModel)]="startDate" required
                        [max]="sharedService.getTodayDate()">
                    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #startDatePicker name="startDate"></mat-datepicker>
                </mat-form-field>

            </div>

        </div>

    </div>

    <div class="col-12 col-md-3" [hidden]="isConfirmed==false">
        <div class="row">
            <div class="col-12">
                <p>End Date</p>
            </div>
            <div class="col-12">
                <mat-form-field class="full-width-formField p-0">
                    <input matInput readonly [matDatepicker]="endDatePicker" name="endDate" [(ngModel)]="endDate"
                        [min]="startDate" required>
                    <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #endDatePicker name="endDate"></mat-datepicker>
                </mat-form-field>


            </div>

        </div>
    </div>
    <div class="col-12 col-md-3" [hidden]="isConfirmed==false">
        <div class="row">

            <div class="col-12 mb-2">
                <button class="btn btn-dark" (click)="getCompanyCPTransactions()"> Generate </button>
            </div>
            <div class="col-12">
                Shortcut:
                <div class="col-12 m-0 p-0">
                    <h5 class="d-inline"><span class="badge text-bg-primary " style="cursor: pointer;"
                            (click)="setDateIntoThisYear()">This Year</span></h5>
                    <h5 class="d-inline ms-1"><span class="badge text-bg-info " style="cursor: pointer;"
                            (click)="setIntoThisMonth()">This Month</span>
                    </h5>
                    <h5 class="d-inline ms-1"><span class="badge text-bg-secondary " style="cursor: pointer;"
                            (click)="setDateIntoLastYear()">Last Year</span>
                    </h5>
                </div>
            </div>
        </div>

    </div>
    <div class="col-12">
        <hr />
        <div class="row">
            <div class="col-10">
                <h3 class="fw-bold" *ngIf="isConfirmed==false;else confirmed">Unconfirmed Payment Sheet</h3>

                <ng-template #confirmed>
                    <h3 class="fw-bold"
                        *ngIf="startDate!=undefined && endDate!=undefined && isConfirmed==true">
                        Payment
                        Sheet ({{startDate
                        |date:'dd/MM/yyyy'}} -
                        {{endDate | date:'dd/MM/yyyy'}})</h3>
                    <h3 class="fw-bold"
                        [hidden]="startDate!=undefined && endDate!=undefined && isConfirmed==true">
                        Payment
                        Sheet ({{year}})</h3>
                </ng-template>
            </div>
            <div class="col-2">
                <div class="card" style="background-color: lightblue;cursor: pointer;" (click)="goToSimpanBDBalanceReport()">
                    <div class="card-body">
                        <i class="fas fa-file-invoice"></i> Simpan BD: RM {{simpanBDBalance}}
                    </div>
                </div>
            </div>

        </div>
    </div>


    <div class="col-12">

        <ng-container *ngIf="transactionList== undefined || transactionList.length==0;else fullList">
            <p>There's no payment record for this company within the selected date.</p>
        </ng-container>

        <ng-template #fullList>

            <table mat-table [dataSource]="dataSource" matSort>
                <!-- index Column -->
                <ng-container matColumnDef="Index">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                    <td mat-cell *matCellDef="let transaction; let i=index">
                        {{i+1}} </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>

                <!-- customerCode Column -->
                <ng-container matColumnDef="CustomerCode">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Code </th>
                    <td mat-cell *matCellDef="let transaction">
                        {{transaction.CustomerCode}} </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <!-- customerName Column -->
                <ng-container matColumnDef="CustomerName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                    <td mat-cell *matCellDef="let transaction">
                        {{transaction.CustomerName}} </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <!-- customerIC Column -->
                <ng-container matColumnDef="CustomerIC">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> IC </th>
                    <td mat-cell *matCellDef="let transaction">
                        {{transaction.CustomerIC}} </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <!-- custOccupation Column -->
                <ng-container matColumnDef="CustOccupation">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Occupation </th>
                    <td mat-cell *matCellDef="let transaction">
                        {{transaction.CustOccupation}} </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>

                <!-- remarks Column -->
                <ng-container matColumnDef="Remarks">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Remarks </th>
                    <td mat-cell *matCellDef="let transaction">
                        {{transaction.Remarks}} </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>

                <!-- date Column -->
                <ng-container matColumnDef="TransactionDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
                    <td mat-cell *matCellDef="let transaction">
                        {{transaction.TransactionDate | date:'dd-MM-yyy'}} </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>

                <!-- Amount Column -->
                <ng-container matColumnDef="Amount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Amount </th>
                    <td mat-cell *matCellDef="let transaction">
                        {{transaction.Amount}} </td>
                    <td mat-footer-cell *matFooterCellDef><strong>{{calculateTotalAmount()}}</strong> </td>
                </ng-container>

                <!-- Action Column -->
                <ng-container matColumnDef="Action">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
                    <td mat-cell *matCellDef="let transaction">
                        <button class="btn btn-dark" (click)="addIntoSimpanBDModal(transaction)"><i class="fas fa-plus"></i> Add Simpan BD</button>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>




                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
            </table>

        </ng-template>
    </div>
</div>