<div class="container-fluid">
    <div class="row">
        <div class="col-12 col-lg-6">
            <div class="card" style="margin: 2%;">
                <div class="card-body">
                    <h4 class="text-center card-title"><i class="fa fa-file-text-o"></i><strong>&nbsp;EPF Loan
                            Requests ({{loanRequestList.length + sharedLoanRequestList.length}})</strong></h4>
                    <h6 class="text-center text-secondary card-subtitle mb-2"><strong>Please review and
                            reply</strong></h6>
                    <div class="row">
                        <div class="col-3">
                            <mat-form-field>
                                <mat-label>Ticket No</mat-label>
                                <input matInput [(ngModel)]="searchText" (keyup)="applyFilter()"
                                    (keypress)="sharedService.isNumberKeyAllowNegative($event)"
                                    placeholder="Enter searching criteria" style="user-select: all;">
                            </mat-form-field>
                        </div>
                        <!-- <div class="col-4">
                            <button class="btn" (click)="clickPaste()"
                                [ngClass]="{'btn-primary':clipboardCompareMessage==undefined || clipboardCompareMessage=='' ,'btn-warning': clipboardCompareMessage!=undefined && clipboardCompareMessage!='' }"
                                #tooltip="matTooltip" [matTooltip]="clipboardCompareMessage" matTooltipPosition="above"
                                matTooltipHideDelay="100" matTooltipClass="tooltip-black"><i
                                    class="fas fa-paste"></i></button>
                        </div> -->
                        <div class="col-3">
                            <mat-form-field>
                                <mat-label>Ticket Message</mat-label>
                                <input matInput [(ngModel)]="clipboardCompareMessage" placeholder="Paste Ticket Message"
                                    (ngModelChange)="filterAndCompareMessage()" rows="1"
                                    style="user-select: all;white-space: pre;">
                            </mat-form-field>
                        </div>
                    </div>
                    <ng-container *ngIf="loanRequestList!=undefined">
                        <div class="p-1 m-1" *ngFor="let request of loanRequestList">
                            <app-all-loan-request-card [request]="request"></app-all-loan-request-card>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="sharedLoanRequestList!=undefined">
                        <div class="p-1 m-1" *ngFor="let group of sharedLoanRequestList">
                            <app-shared-loan-request-card [loanGroupRequest]="group"></app-shared-loan-request-card>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="card" style="margin: 2%;" *ngIf="wholeApprovedLoanRequestList.length>0">
                <div class="card-body">
                    <h4 class="text-center card-title"><i class="fa fa-file-text-o"></i><strong>&nbsp;Approved Ticket
                            ({{approvedWithoutloanRequestList.length}})</strong></h4>
                    <h6 class="text-center text-secondary card-subtitle mb-2"><strong>These are all approved tickets but did
                            not open loan. Please check on it.</strong></h6>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field>
                                <mat-label>Ticket No</mat-label>
                                <input matInput [(ngModel)]="searchApprovedText" (keyup)="applyApprovedFilter()"
                                    (keypress)="sharedService.isNumberKeyAllowNegative($event)"
                                    placeholder="Enter searching criteria">
                            </mat-form-field>
                        </div>
                    </div>
                    <ng-container *ngIf="approvedWithoutloanRequestList!=undefined">
                        <div class="p-1 m-1" *ngFor="let request of approvedWithoutloanRequestList">
                            <app-all-loan-request-card [request]="request"></app-all-loan-request-card>
                        </div>
                    </ng-container>
                </div>
            </div>

        </div>
        <div class="col-12 col-lg-6 ">
            <div class="card" style="margin: 2%;background-color: lightblue;cursor: pointer;" (click)="goToReceipts()"
                *ngIf="sharedService.isAdmin()">
                <div class="card-body">
                    <h5 class="fw-bold"><i class="fas fa-file-invoice-dollar"> </i> Pending Receipts:
                        {{setPendingCount}}</h5>
                </div>
            </div>

            <div class="card" style="margin: 2%;background-color: lightgray ;cursor: pointer;"
                (click)="goToSettlementInfoCheck()">
                <div class="card-body">
                    <h5 class="fw-bold"><i class="fas fa-not-equal"></i> Check EPF Settlement</h5>
                </div>
            </div>

            <div class="card" style="margin: 2%;">
                <div class="card-body">
                    <h4 class="text-center card-title"><i class="fa fa-file-text-o"></i><strong>&nbsp;Other Loan
                            Requests ({{normalLoanRequestList.length}})</strong></h4>
                    <h6 class="text-center text-secondary card-subtitle mb-2"><strong>Please review and
                            reply</strong></h6>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field>
                                <mat-label>Name / IC Number</mat-label>
                                <input matInput [(ngModel)]="searchNormalText" (keyup)="applySearchNormalFilter()"
                                    placeholder="Enter searching criteria">
                            </mat-form-field>
                        </div>
                    </div>
                    <ng-container *ngIf="normalLoanRequestList!=undefined">
                        <div class="p-1 m-1" *ngFor="let request of normalLoanRequestList">
                            <app-all-loan-request-card [request]="request"></app-all-loan-request-card>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="card" style="margin: 2%;"
                *ngIf="transactionRequestList!=undefined && transactionRequestList.length>0">

                <div class="card-body">
                    <ng-container>

                        <h4 class="text-center card-title"><strong><i class="fas fa-exclamation-triangle"></i> HQ
                                Assigned
                                Transaction</strong></h4>

                        <div class="row">
                            <div class="col-12" *ngFor="let request of transactionRequestList">
                                <app-assigned-transaction-card
                                    [transactionRequest]="request"></app-assigned-transaction-card>
                            </div>
                        </div>
                    </ng-container>
                </div>

            </div>
            <div class="card" style="margin: 2%;">
                <div class="card-body">
                    <h4 class="text-center card-title"><i
                            class="fa fa-file-text-o"></i><strong>&nbsp;UsageReport</strong></h4>
                    <h6 class="text-center text-secondary card-subtitle mb-2"><strong>Monitor usage by showing
                            latest account date</strong></h6>
                    <app-usage-report-card [usageList]="usageList"></app-usage-report-card>
                </div>
            </div>
        </div>
    </div>

    <!-- End of Add Customer Modal -->
    <div class="modal fade" id="addCustomer">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <app-add-customer></app-add-customer>
            </div>
        </div>
        <!-- End of Add Customer Modal -->
    </div>
</div>