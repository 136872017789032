import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';

interface Report {
  lastGeneratedDate: Date,
  customerName: string,
  customerIC: string,
  customerId: number
}

@Component({
  selector: 'app-form-j-report',
  templateUrl: './form-j-report.component.html',
  styleUrls: ['./form-j-report.component.css'],
  providers: [DatePipe]
})


export class FormJReportComponent implements OnInit {
  @Input() selectedCompany: number;
  @Input() reportName: string;

  reportList: Report[] = new Array;
  wholeReportList: Report[] = new Array;

  isCollapsed: boolean = false;

  routeSubscription: any;

  isBranchManager: boolean;

  constructor(private modalService: NgbModal, public enums: Enums, private activatedRoute: ActivatedRoute, private datePipe: DatePipe, private router: Router, private restapi: RestApiService, private constructApi: ConstructAPI, public sharedService: SharedService) { }

  ngOnInit(): void {

    this.routeSubscription = this.activatedRoute.paramMap.subscribe(params => {
      this.selectedCompany = + params.get('companyId');

      this.getCompanyFormJLatestDate();
    });
  }

  goToCustomer(customerID: number) {
    this.router.navigate(['/manageCustomer', customerID]);
  }

  getCompanyFormJLatestDate() {
    this.reportList.splice(0, this.reportList.length);
    var xhttp = this.restapi.getRequest(this.constructApi.getCompanyFormJLatestDate(this.selectedCompany));

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          var report = {
            customerId: json.id, customerName: json.name, customerIC: json.icNumber, lastGeneratedDate: json.lastFormJDate
          };
          this.reportList.push(report);
        }
        );
      }
    };
  }


}
