import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { LoanDetailsComponent } from 'src/app/Loans/loan-details/loan-details.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedAddRemarkComponent } from 'src/app/Shared/shared-add-remark/shared-add-remark.component';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Loan } from 'src/model/loan.model';

interface SettlementInfoCheck {
  companyId: number,
  customerId: number,
  extras: number,
  icNumber: string,
  id: number,
  name: string,
  remark: string,
  serviceCharge: number,
  settlementExtras: number,
  settlementServiceCharge: number,
  settlementStamp: number,
  stamp: number,
  checkedBy: string
}


@Component({
  selector: 'app-compare-settlement-info-list',
  templateUrl: './compare-settlement-info-list.component.html',
  styleUrls: ['./compare-settlement-info-list.component.css']
})
export class CompareSettlementInfoListComponent implements OnInit {
  displayedColumns: string[];
  dataSource = new MatTableDataSource;

  innerWidth: any;
  disabledSubmit = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  settlementInfoCheckList: SettlementInfoCheck[] = new Array;
  constructor(private router: Router, private restApi: RestApiService, private constructApi: ConstructAPI, public sharedService: SharedService, private enums: Enums) { }

  ngOnInit(): void {
    this.getSettlementInfoCheckList();

    this.displayedColumns = ['name', 'icNumber', 'settlementStamp', 'stamp', 'settlementServiceCharge', 'serviceCharge', 'settlementExtras', 'extras', 'action'];
  }


  getSettlementInfoCheckList() {
    var xhttp = this.restApi.getRequest(this.constructApi.getSettledLoansSettlementInfos());



    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // Typical action to be performed when the document is ready:

        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          var check = {
            companyId: json.companyId, customerId: json.customerId, extras: json.extras, icNumber: json.icNumber,
            id: json.id, name: json.name, remark: json.remark, serviceCharge: json.serviceCharge, settlementExtras: json.settlementExtras,
            settlementServiceCharge: json.settlementServiceCharge, settlementStamp: json.settlementStamp, stamp: json.stamp, checkedBy: json.checkedBy
          }

          this.settlementInfoCheckList.push(check);

        }
        );

        this.settlementInfoCheckList.sort((a, b) => (a.checkedBy > b.checkedBy) ? -1 : 1);

        //make customer list as the datasource
        this.dataSource = new MatTableDataSource<SettlementInfoCheck>(this.settlementInfoCheckList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

      }
    };
  }

  goToCustomer(selectedID: number) {
    // console.log("selcted:" + selectedID);
    // this.router.navigateByUrl('/manageCustomer', { state: { id: selectedID } });
    this.router.navigate(['/manageCustomer', selectedID]);
  }


  openRemarkModal(check: boolean, loanId: number) {
    var modalRef = this.sharedService.openModal(SharedAddRemarkComponent, "regularModal");
    modalRef.componentInstance.title = (check == true) ? "Mark loan as CHECKED" : "Mark loan as UNCHECKED";

    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var remark = entry;

      if (check == true) {
        this.checkSettlementInfo(loanId, remark);
      }
      else
        this.uncheckSettlementInfo(loanId, remark);

    });

  }


  checkSettlementInfo(loanId: number, remark: string) {
    this.disabledSubmit = true;
    var data = {
      "remark": remark
    };

    var xhr = this.restApi.putRequest(this.constructApi.getCheckLoanSettlementInfo(loanId), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {

        this.sharedService.openAlert("Successfully checked loan!", this.enums.SUCCESS_ALERT);
        window.location.reload();
      }
    }

  }

  uncheckSettlementInfo(loanId: number, remark: string) {
    this.disabledSubmit = true;

    var data = {
      "remark": remark
    };

    var xhr = this.restApi.putRequest(this.constructApi.getUnheckLoanSettlementInfo(loanId), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {

        this.sharedService.openAlert("Successfully unchecked loan!", this.enums.SUCCESS_ALERT);
        window.location.reload();
      }
    }

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  openLoanDetailsModal(loanId: number) {
    const modalRef = this.sharedService.openModal(LoanDetailsComponent, "largeModal");

    var loan = new Loan(loanId, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    modalRef.componentInstance.selectedLoan = loan;
    modalRef.componentInstance.isDeletable = false;

  }

}
