export class Account {
    constructor(private _id: number, private _accountNumber: string, private _name: string,
        private _holderName: string, private _balance: number, private _companyId: number, private _username: string,
        private _allocation?: number, private _adjustment?: number) {

    }


    set ID(id: number) { this._id = id }
    get ID(): number { return this._id }

    set AccountNumber(accountNumber: string) { this._accountNumber = accountNumber }
    get AccountNumber(): string { return this._accountNumber }

    set Name(name: string) { this._name = name }
    get Name(): string { return this._name }

    set HolderName(holderName: string) { this._holderName = holderName }
    get HolderName(): string { return this._holderName }

    set Username(username: string) { this._username = username }
    get Username(): string { return this._username }

    set CompanyId(companyId: number) { this._companyId = companyId }
    get CompanyId(): number { return this._companyId }


    set Balance(balance: number) { this._balance = balance }
    get Balance(): number { return this._balance }

    set Allocation(allocation: number) { this._allocation = allocation }
    get Allocation(): number { return this._allocation }

    set Adjustment(adjustment: number) { this._adjustment = adjustment }
    get Adjustment(): number { return this._adjustment }


}