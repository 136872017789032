<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Add Company Account</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body">
        <div class="container-fluid p-0">

            <div class="mt-4 d-flex justify-content-center align-items-center" *ngIf="isCashAccount==false">
                <div class="group">
                    <input type="text" required class="textInputLong" name="accountNo" [(ngModel)]="accountNo">
                    <span class="highlight"></span>
                    <span class="bar barLong"></span>
                    <label>Account Number</label>
                </div>
            </div>

            <div class="mt-4 d-flex justify-content-center align-items-center">
                <div class="group">
                    <input type="text" required class="textInputLong" name="accountName" [(ngModel)]="accountName">
                    <span class="highlight"></span>
                    <span class="bar barLong"></span>
                    <label>Account Name</label>
                </div>
            </div>

            <div class="mt-4 d-flex justify-content-center align-items-center" *ngIf="isCashAccount==false">
                <div class="group">
                    <input type="text" required class="textInputLong" name="holderName" [(ngModel)]="holderName">
                    <span class="highlight"></span>
                    <span class="bar barLong"></span>
                    <label>Holder Name</label>
                </div>
            </div>
            <div class="mt-4 d-flex justify-content-center align-items-center" *ngIf="accountId==undefined">
                <div class="group">
                    <input type="text" required class="textInputLong" name="initialAmount" [(ngModel)]="initialAmount"
                        (keypress)="sharedService.isNumberKey($event)" [readonly]="sharedService.isBossAccount()==false">
                    <span class="highlight"></span>
                    <span class="bar barLong"></span>
                    <label>Initial Amount (RM)</label>
                    <p class="text-danger">Please leave it to be 0 unless there's special case</p>
                </div>
            </div>
            <div class="mt-4 d-flex justify-content-center align-items-center" *ngIf="accountId==undefined">
                <mat-form-field class="full-width-formField bar ">
                    <input matInput class="textInputLong initialDatePicker" [matDatepicker]="myDatepicker"
                        name="initialAccountDate" [(ngModel)]="initialAccountDate"
                        placeholder="Initial Cash Account Date">
                    <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                    <mat-datepicker #myDatepicker name="date"></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="row" *ngIf="isBranchManager()==false && accountId==undefined">
                <div class="col-12 ms-3">Company</div>
                <div class="col-12">
                    <div class="p-3 d-flex justify-content-center align-items-center">

                        <select class="browser-default form-select" [disabled]="checkIsStaff()" name="selectedCompany"
                            [(ngModel)]="selectedCompanyID">
                            <option *ngFor="let company of companyList" [value]="company.ID"
                                [selected]="setSelectedCompany(company.ID)">{{company.CompanyCode}}</option>
                        </select>
                    </div>

                </div>
            </div>

            <!-- <div class="row">
                <div class="col-12 ms-3">User</div>
                <div class="col-12">
                    <div class="p-3 d-flex justify-content-center align-items-center">

                        <select class="browser-default form-select" [disabled]="checkIsStaff()"
                            name="selectedUsername" [(ngModel)]="selectedUsername">
                            <option *ngFor="let user of userList" [value]="user.Username"
                                [selected]="setSelectedUser(user.Username)">{{user.Username}}</option>
                        </select>
                    </div>
                </div>
            </div> -->
            
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="submit()" [disabled]="disabledSubmit">
            <span *ngIf="accountId==undefined">Add Account</span>
            <span *ngIf="accountId!=undefined">Edit Account</span>
        </button>
        <button class="btn btn-outline-danger" type="button" (click)="reset()">Cancel</button>
    </div>
</form>