import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Contact } from 'src/model/contact.model';
import { RestApiService } from 'src/app/API/restapi';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { AlertDialogComponent } from 'src/app/Shared/alert-dialog/alert-dialog.component';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Enums } from 'src/app/Shared/enums';


@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.css']
})
export class AddContactComponent implements OnInit {

  name: string;
  contactNum: string;
  address: string;
  icNumber: string;
  relation: string;
  remark: string;

  @Input() viewCustomerId: string;
  @Input() isDialog: boolean = true;

  @Input() contactToUpdate: Contact;

  disableSubmit = false;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal,
    private restapi: RestApiService, private constructApi: ConstructAPI,
    public sharedService: SharedService, private enums: Enums) { }

  ngOnInit(): void {
    if (this.contactToUpdate != undefined) {
      this.name = this.contactToUpdate.Name;
      this.contactNum = this.contactToUpdate.ContactNumber;
      this.address = this.contactToUpdate.Address;
      this.icNumber = this.contactToUpdate.ICNumber;
      this.relation = this.contactToUpdate.Relation;
      this.remark = this.contactToUpdate.Remark;
    }
  }


  checkValidity() {
    var errorStr = "";

    if (this.name == "" || this.name == undefined)
      errorStr += "- Contact Name\n";

    if (this.icNumber == "" || this.icNumber == undefined)
      errorStr += "- IC Number\n";
    else if ((this.sharedService.checkIfICValid(this.icNumber) == false) && this.sharedService.isBossAccount() == false)
      errorStr += "- IC Number format invalid\n";

    if (this.contactNum == "" || this.contactNum == undefined)
      errorStr += "- Contact Number\n";
    if (this.relation == "" || this.relation == undefined)
      errorStr += "- Relation\n";

    if (errorStr != "")
      return "Please key in correct information:\n " + errorStr;
    else
      return errorStr;
  }


  submit() {
    this.disableSubmit = true;
    var message = this.checkValidity();
    if (message == "") {
      this.addContact();
    }
    else {
      this.sharedService.openAlert(message, this.enums.DANGER_ALERT);
      this.disableSubmit = false;
    }
  }

  addContact() {
    const icNumberFormat = this.icNumber.replace(/-/g, "",);
    var data = {
      "name": this.name, "icNumber": icNumberFormat, "number": this.contactNum, "address": this.address, "customerId": this.viewCustomerId, "relation": this.relation, "remark": this.remark
    };
    var xhr = (this.contactToUpdate == undefined) ? this.restapi.postRequest(this.constructApi.getAddContact(), data) : this.restapi.putRequest(this.constructApi.getUpdateContact(this.contactToUpdate.ID), data);

    ;
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        var json = JSON.parse(xhr.responseText);


        var contact = new Contact(json.id, json.name, json.icNumber, json.number, json.address, json.customerId, json.relation, json.remark, true);
        if (this.contactToUpdate == undefined) {
          this.passEntry.emit(contact)
          this.reset();
          this.sharedService.openAlert("Successfully added contact!", this.enums.SUCCESS_ALERT);
        }
        else
          window.location.reload();
      }
    }
  }


  reset() {
    this.activeModal.close();
    this.name = "";
    this.contactNum = "";
    this.address = "";
    this.remark = "";
    this.relation = "";
  };

}
