import { Component, OnInit, ɵConsole, ChangeDetectorRef } from '@angular/core';
import { LoanScheme } from '../../../model/loanScheme.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { RestApiService } from 'src/app/API/restapi';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { AlertDialogComponent } from 'src/app/Shared/alert-dialog/alert-dialog.component';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Enums } from 'src/app/Shared/enums';
@Component({
  selector: 'app-add-plan',
  templateUrl: './add-plan.component.html',
  styleUrls: ['./add-plan.component.css']
})
export class AddPlanComponent implements OnInit {

  constructor(private cdr: ChangeDetectorRef, private activeModal: NgbActiveModal, private modalService: NgbModal,
    private restapi: RestApiService, private constructApi: ConstructAPI, public sharedService: SharedService,private enums:Enums) {

  }

  loanSchemeList: LoanScheme[] = new Array;

  //2 way binding from html view
  loanSchemeName: string;
  loanSchemeInterest: number;
  loanSchmemeTerm: string;


  schemeNameNull: boolean;
  schemeInterestNull: boolean;
  schemeTermNull: boolean;

  disabledSubmit = false;

  ngOnInit() {

    this.getAllLoanSchemes();
  }


  createLoanScheme() {

    this.disabledSubmit = true;
    var data = { "name": this.loanSchemeName, "term": this.loanSchmemeTerm, "interest": this.loanSchemeInterest };
    var xhr = this.restapi.postRequest(this.constructApi.getAddLoanScheme(), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        if (xhr.status == 200) {
          var json = JSON.parse(xhr.responseText);
          var loanScheme = new LoanScheme(json.id, json.name, json.term, json.interest);

          this.loanSchemeList = this.loanSchemeList.concat(loanScheme);

          this.resetValues();

        } else {
          this.sharedService.openErrorMessage(xhr);
        }
      }

    }

    // xhr.send(data);

  }


  openDeleteConfirmationDialog(scheme: LoanScheme) {

    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Are you sure you want to delete " + scheme.Name + " ?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something

      if (confirmation == true)
        this.deleteScheme(scheme);

    });

    // modalRef.result.then(() => {
    //   console.log("added task")
    //   this.cdr.detectChanges();

    // })
  }

  deleteScheme(scheme: LoanScheme) {
    // var xhttp = new XMLHttpRequest();
    // var url = "http://localhost:10080/api/v1/loanScheme/" + scheme.ID;
    // xhttp.open("DELETE", url, true);
    // xhttp.withCredentials = true;
    // xhttp.setRequestHeader("Content-type", "application/json");

    var xhttp = this.restapi.deleteRequest(this.constructApi.getDeleteLoanScheme(scheme.ID));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        for (let i in this.loanSchemeList) {
          if (this.loanSchemeList[i].ID == scheme.ID) {
            this.loanSchemeList.splice(Number(i), 1);
            this.cdr.detectChanges();
          }
        }
      }
    };

    // xhttp.send();

  }

  getAllLoanSchemes() {

    // var xhttp = new XMLHttpRequest();
    // var url = "http://localhost:10080/api/v1/loanSchemes";
    // xhttp.withCredentials = true;

    var xhttp = this.restapi.getRequest(this.constructApi.getAllLoanSchemes())
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // Typical action to be performed when the document is ready:
        //  document.getElementById("demo").innerHTML = xhttp.responseText;
        // console.log(this.responseText);

        var jsonArray = JSON.parse((xhttp.responseText));
        // console.log(jsonArray);

        jsonArray.forEach(json => {

          let loanScheme = new LoanScheme(json.id, json.name, json.term, json.interest);
          this.loanSchemeList.push(loanScheme);
        }
        );
      }
    };
    // xhttp.open("GET", url, true);
    // xhttp.send();

  }



  isInvalid() {
    this.schemeNameNull = (this.loanSchemeName == undefined || this.loanSchemeName === '');
    this.schemeTermNull = (this.loanSchmemeTerm == undefined);
    this.schemeInterestNull = (this.loanSchemeInterest == undefined);
    var termValid = isNaN(+this.loanSchmemeTerm)
    var interestValid = isNaN(+this.loanSchemeInterest)

    return (this.schemeNameNull || this.schemeTermNull || this.schemeInterestNull || termValid || interestValid);
  }


  submit() {
    this.isInvalid() ? this.sharedService.openAlert('Please fill in all the fields!',this.enums.DANGER_ALERT) : this.createLoanScheme();
  }

  reset() {

    this.activeModal.close();
    this.resetValues();
  }

  resetValues() {
    this.loanSchemeName = undefined;
    this.loanSchemeInterest = undefined;
    this.loanSchmemeTerm = undefined;
  }
}
