<form>
    <!-- Modal Header -->
    <div class="modal-header" style="background-color:aquamarine;">
        <h4 class="modal-title" [hidden]="selectedBasicAmount!=undefined">Staff Commission Payout</h4>
        <h4 class="modal-title" [hidden]="selectedBasicAmount==undefined">Staff Salary Payout</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body">

        <div class="card shadow">
            <div class="card-body">
                <table>
                    <tbody>
                        <tr>
                            <td>Staff Name: </td>
                            <td> <strong>{{unpaidCommission.StaffUsername}}</strong></td>
                        </tr>
                        <tr>
                            <td>Commission: </td>
                            <td><strong>RM {{selectedCommissionAmount}}</strong></td>
                        </tr>
                        <tr [hidden]="selectedBasicAmount==undefined">
                            <td>Basic: </td>
                            <td><strong>RM {{selectedBasicAmount}}</strong></td>
                        </tr>
                    </tbody>
                    <tfoot [hidden]="selectedBasicAmount==undefined">
                        <tr style="border-top:black 2px solid;border-bottom:black 2px solid;">
                            <th>Total: </th>
                            <th>RM {{selectedCommissionAmount + selectedBasicAmount}}</th>
                        </tr>
                    </tfoot>
                </table>
                <hr />
                <h5>Summary</h5>
                <table class="table">
                    <thead>
                        <tr>
                            <th>Customer Name</th>
                            <th>Commission Payment</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let commission of unpaidCommission.UnpaidComissionList">
                            <tr [hidden]="commission.PaymentAmount<=0">
                                <td>{{commission.CustomerName}}</td>
                                <td>{{commission.PaymentAmount}}</td>
                            </tr>
                        </ng-container>

                    </tbody>
                </table>
            </div>
        </div>

        <!--Account Number-->
        <div class="row p-3 d-flex justify-content-center align-items-center">

            <div class="col-12">
                <p>From Account</p>
            </div>
            <div class="col-12">
                <select class="browser-default form-select" name="selectedAccount" [(ngModel)]="selectedAccountId">
                    <optgroup label="Company Account">
                        <option *ngFor="let account of companyBankAccountList" [value]="account.ID"
                            [selected]="setSelectedAccount(account.ID)"
                            [disabled]="isBalanceInsufficient(account.Balance)">{{account.Name}} (RM
                            {{account.Balance}})</option>
                    </optgroup>

                    <optgroup label="Cash Account">
                        <option *ngFor="let account of cashAccountList" [value]="account.ID"
                            [selected]="setSelectedAccount(account.ID)"
                            [disabled]="isBalanceInsufficient(account.Balance)">{{account.Name}}(RM {{account.Balance}})
                        </option>
                    </optgroup>

                </select>
            </div>

        </div>

        <!--Date-->
        <mat-form-field class="full-width-formField bar">
            <input matInput class="textInputLong" [matDatepicker]="myDatepicker" name="paymentDate"
                [(ngModel)]="paymentDate" placeholder="Payment Date" [max]="sharedService.getTodayDate()" onkeydown="return false">
            <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #myDatepicker name="paymentDate"></mat-datepicker>
        </mat-form-field>


    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="submit()" [disabled]="disabledSubmit">Pay
            Commission</button>
        <button class="btn btn-outline-danger" type="button" (click)="reset()">Cancel</button>
    </div>

</form>