<div class="container-fluid p-3">
    <div class="d-flex justify-content-end align-items-end">
        <button class="btn btn-info" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseExample">
            <i class="fas fa-chevron-circle-down" *ngIf="isCollapsed"></i>
            <i class="fas fa-chevron-circle-up" *ngIf="isCollapsed==false"></i>
        </button>
    </div>

    <div class="collapse" id="collapseExample" [ngbCollapse]="isCollapsed">
        <app-report-generator></app-report-generator>
    </div>
    <h2> Asset Summary Report ( {{startDate |date:'yyyy-MM-dd'}} - {{endDate |date:'yyyy-MM-dd'}} )
    </h2>

    <p [hidden]="reportList.length>0"> Do not have any record within this date range</p>

    <table class="table table-striped" [hidden]="reportList.length==0">
        <thead>
            <tr>
                <th>Company Name</th>
                <th>Total In</th>
                <th>Total Out</th>
                <th>Balance</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let report of reportList">
                <td>{{report.companyName}}</td>
                <td>{{report.totalIn}}</td>
                <td>{{report.totalOut}}</td>
                <td>{{report.assetBalance || 0}}</td>
            </tr>
        </tbody>
    </table>
</div>