<div class="main-container" *ngIf="selectedTask">

    <div class="header-done ps-3 m-0" style="background-color: teal; color: white;"
        *ngIf="selectedTask.DateDone!=undefined">
        <h3 class="m-0"> <strong>You've done this task.</strong></h3>
    </div>
    <div class="content p-0 m-0">
        <!-- <app-manage-customer [customerInTask]="selectedTask.CustomerId" [allowUpdateTask]="false">
        </app-manage-customer> -->
        <app-manage-customer  [allowUpdateTask]="false">
        </app-manage-customer>
    </div>

    <div class="footer sticky-bottom" *ngIf="selectedTask.DateDone==undefined">
        <div class="row" style="background-color: #ffb904;">
            <div class="col" style="margin: 1%;">
                <p style="margin: 0;">Follow Up Remark</p>
                <textarea name="followUpRemark" [(ngModel)]="followUpRemark" required></textarea>

                <h5 style="display:inline" (click)="setPresetRemark('PAYMENT')">
                    <span class="badge  text-bg-success mx-1  badgeRemark">
                        PAYMENT
                    </span>
                </h5>

                <h5 style=" display:inline" (click)=" setPresetRemark('NEW')">
                    <span class="badge  text-bg-primary mx-1 badgeRemark">
                        NEW
                    </span>
                </h5>

                <h5 style="display:inline" (click)=" setPresetRemark('RENEW')">
                    <span class="badge  text-bg-info mx-1 badgeRemark">
                        RENEW
                    </span>
                </h5>

                <h5 style="display:inline" (click)=" setPresetRemark('EXTRA')">
                    <span class="badge text-bg-danger mx-1 badgeRemark">
                        EXTRA
                    </span>
                </h5>

                <h5 style="display:inline">
                    <span class="badge text-bg-light mx-1 badgeRemark" (click)="setPresetRemark('OVERLAP')">
                        OVERLAP
                    </span>
                </h5>
            </div>
            <div class="col" style="margin: 1%;">
                <!-- <a class="btn btn-primary"  data-bs-toggle="collapse" href="#collapseExample" role="button"
                    aria-expanded="false" aria-controls="collapseExample">
                    Link with href
                </a> -->
                <div class="row">
                    <div class="col-12 col-checkBox">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="checkCreateNextTask"
                                 data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false"
                                aria-controls="collapseExample" [(ngModel)]="createNextTask">
                            <label class="form-check-label" for="checkCreateNextTask">Create Next Task</label>
                        </div>

                    </div>
                    <div class="col-12">
                        <div class="collapse" id="collapseExample">
                            <div class="card card-body card-nextFollowUp">
                                <ng-template #tooltipContent>
                                    <div>
                                        <span>Example: <br>Customer nak recommend kawan minggu depan</span>
                                    </div>

                                </ng-template>
                                <p style="margin: 0;">Next Task Description <i class="far fa-question-circle ms-2"
                                        placement="top" [ngbTooltip]="tooltipContent" style="cursor:pointer;"></i></p>
                                <input type="text" name="nextTaskDesc" [(ngModel)]="nextTaskDesc" required />

                                <!-- <p style="margin: 0;">Next Follow Up Date</p>
                <input type="date" name="taskDate" [min]="minDate" [(ngModel)]="taskDate"> -->

                                <mat-form-field class="full-width-formField">
                                    <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="myDatepicker"
                                        name="taskDate" [(ngModel)]="taskDate" required>
                                    <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                                    <mat-datepicker #myDatepicker name="taskDate"></mat-datepicker>
                                </mat-form-field>

                            </div>
                        </div>
                    </div>
                </div>






                <!-- <p style="margin: 0;">Status</p> -->
                <!-- <select name="status" name="taskStatus" [(ngModel)]="taskStatus" required> -->
                <!-- <option value="INPROGRESS">INPROGRESS</option> -->
                <!-- <option [value]="enums.DONE">DONE</option>
                </select> -->
            </div>

        </div>


        <div class="row" style="background-color: #ffb904;">
            <div class="col d-flex justify-content-end">
                <button class="btn btn-success" type="button" style="margin: 5px;" (click)="submit()">Done</button>
                <button class="btn btn-dark" type="button" style="margin: 5px;"
                    (click)="backToDashboard()">Cancel</button>
            </div>
        </div>
    </div>


</div>