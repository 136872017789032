<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Session Timeout!</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 aria-label="Close" aria-label="Close"
            (click)="closeModal()"></button>
    </div>

    <div class="modal-body">
        <p>{{message}}
        </p>
        <div class="row">
            <div class="col">
                <button class="btn btn-success" (click)=passResultToParent(true)>Stay</button>
                <button class="btn btn-danger" (click)=passResultToParent(false)>Leave</button>
            </div>
        </div>
    </div>


</form>