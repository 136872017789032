<div class="m-3">
    <h3>Check Settled Loan Settlement Info</h3>
    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Enter searching criteria">
            </mat-form-field>
        </div>
    </div>
    <div class="mat-elevation-z8 m-3">


        <table mat-table [dataSource]="dataSource" matSort>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let loan" (click)="goToCustomer(loan.customerId)">
                    {{loan.name}}
                </td>
            </ng-container>

            <!-- ICNumber Column -->
            <ng-container matColumnDef="icNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ICNumber </th>
                <td mat-cell *matCellDef="let loan" (click)="goToCustomer(loan.customerId)">
                    {{sharedService.processICNum(loan.icNumber)}}
                </td>
            </ng-container>

            <!-- SettlementStamp Column -->
            <ng-container matColumnDef="settlementStamp">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Preset Stamp </th>
                <td mat-cell *matCellDef="let loan" class="numberCell">
                    {{loan.settlementStamp||0}}
                </td>
            </ng-container>

            <!-- ActualStamp Column -->
            <ng-container matColumnDef="stamp">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Actual Stamp </th>
                <td mat-cell *matCellDef="let loan"
                    [ngClass]="{'lessThanCell':loan.stamp< loan.settlementStamp,'moreThanCell':loan.stamp> loan.settlementStamp}"
                    class="numberCell">
                    {{loan.stamp||0}}
                </td>
            </ng-container>

            <!-- SettlementSC Column -->
            <ng-container matColumnDef="settlementServiceCharge">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Preset SC </th>
                <td mat-cell *matCellDef="let loan" class="numberCell">
                    {{loan.settlementServiceCharge||0}}
                </td>
            </ng-container>

            <!-- ActualSC Column -->
            <ng-container matColumnDef="serviceCharge">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Actual SC </th>
                <td mat-cell *matCellDef="let loan"
                    [ngClass]="{'lessThanCell':loan.serviceCharge< loan.settlementServiceCharge,'moreThanCell':loan.serviceCharge> loan.settlementServiceCharge}"
                    class="numberCell">
                    {{loan.serviceCharge||0}}
                </td>
            </ng-container>

            <!-- SettlementExtras Column -->
            <ng-container matColumnDef="settlementExtras">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Preset Duit Lebih </th>
                <td mat-cell *matCellDef="let loan" class="numberCell">
                    {{loan.settlementExtras}}
                </td>
            </ng-container>


            <!-- DuitLebih Column -->
            <ng-container matColumnDef="extras">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Duit Lebih </th>
                <td mat-cell *matCellDef="let loan"
                    [ngClass]="{'lessThanCell':loan.extras< loan.settlementExtras,'moreThanCell':loan.extras> loan.settlementExtras}"
                    class="numberCell">
                    {{loan.extras}}
                </td>
            </ng-container>

            <!-- action Column -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
                <td mat-cell *matCellDef="let loan">
                    <div class="ms-2">
                        <button class="btn btn-success" (click)="openRemarkModal(true,loan.id)"
                            [disabled]="disabledSubmit" *ngIf="loan.checkedBy==undefined"><i
                                class="far fa-check-circle"></i></button>

                        <button class="btn btn-danger" (click)="openRemarkModal(false,loan.id)"
                            *ngIf="loan.checkedBy!=undefined"><i class="far fa-times-circle"></i></button>


                        <button class="btn btn-dark mx-1" (click)="openLoanDetailsModal(loan.id)" #tooltip="matTooltip" matTooltip="View Loan Transactions"
                        matTooltipPosition="above" matTooltipHideDelay="100" matTooltipClass="tooltip-black"> <i class="fas fa-list-ol"></i></button>

                        <h2 class="d-inline text-center ms-1 p-1 align-items-center"><span class="text-info"
                                #tooltip="matTooltip" matTooltip="{{loan.remark}} ({{loan.checkedBy}})"
                                matTooltipPosition="above" matTooltipHideDelay="100" matTooltipClass="tooltip-black"
                                *ngIf="loan.remark!=undefined && loan.remark!=''"><i
                                    class=" fas fa-comment-dots"></i></span></h2>
                    </div>

                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[100,200,300]" [pageSize]="100" showFirstLastButtons></mat-paginator>
    </div>
</div>