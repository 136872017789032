export class ReceiptPayment {
    constructor(private _receiptNumber: string, private _monthlyPayment: number, private _balance: number) { }

    set ReceiptNumber(receiptNumber: string) { this._receiptNumber = receiptNumber }
    get ReceiptNumber(): string { return this._receiptNumber }

    set MonthlyPayment(monthlyPayment: number) { this._monthlyPayment = monthlyPayment }
    get MonthlyPayment(): number { return this._monthlyPayment }

    set Balance(balance: number) { this._balance = balance }
    get Balance(): number { return this._balance }

}

export class TaskCollection {
    constructor(private _id, private _name: string, private _icNumber: string, private _loanCollection: ReceiptPayment[]) { }


    set ID(id: number) { this._id = id }
    get ID(): number { return this._id }

    set Name(name: string) { this._name = name }
    get Name(): string { return this._name }

    set ICNumber(icNumber: string) { this._icNumber = icNumber }
    get ICNumber(): string { return this._icNumber }


    set LoanCollection(loanCollection: ReceiptPayment[]) { this._loanCollection = loanCollection }
    get LoanCollection(): ReceiptPayment[] { return this._loanCollection }

}