import { Component, Input, OnInit } from '@angular/core';
import { request } from 'https';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { LoanRequest } from 'src/model/loanRequest.model';


@Component({
  selector: 'app-loan-request-approval',
  templateUrl: './loan-request-approval.component.html',
  styleUrls: ['./loan-request-approval.component.css']
})
export class LoanRequestApprovalComponent implements OnInit {

  @Input() requestList: LoanRequest[] = new Array;
  @Input() loanGroupId: number;

  remark: string = "";


  constructor(public sharedService: SharedService, public enums: Enums, private restApi: RestApiService, private constructApi: ConstructAPI) { }

  ngOnInit(): void {
  }


  checkIfAllowApproval() {
    if (this.sharedService.isBossAccount())
      return true;
    else if (this.sharedService.isAdmin()) {
      this.requestList.forEach(request => {
        if (request.ProcessRequiredRole != this.enums.ADMIN || request.VerifiedOn == undefined)
          return false
      });
      return true;
    }
  }


  confirmBeforeAction(action: string) {

    if (this.remark == "" && action == 'deny')
      this.sharedService.openAlert("Please key in denial reason", this.enums.DANGER_ALERT);
    else {
      const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);

      var requestIds="";
      this.requestList.forEach(request => {
        requestIds += "#" + request.ID + " "
      });
      modalRef.componentInstance.message = "Are you confirm to " + action + " loan TICKET #" + requestIds + "?";

      //get confirmation
      modalRef.componentInstance.passEntry.subscribe((entry) => {
        var confirmation = entry;

        // use the confirmation to do something

        if (confirmation == true) {

          if (action == 'approve')
            this.approve();
          else
            this.deny();
        }
      });
    }

  }


  approve() {
    var data = {
      "remark": this.remark
    };
    var xhr = (this.loanGroupId == undefined)
      ? this.restApi.postRequest(this.constructApi.getApproveLoanApprovalRequest(this.requestList[0].ID), data)
      : this.restApi.postRequest(this.constructApi.getApproveGroupLoanApprovalRequest(this.loanGroupId), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        window.location.reload();
      }
    }
  }

  deny() {
    var data = {
      "remark": this.remark
    };
    var xhr = (this.loanGroupId == undefined)
      ? this.restApi.postRequest(this.constructApi.getDenyLoanApprovalRequest(this.requestList[0].ID), data)
      : this.restApi.postRequest(this.constructApi.getDenyGroupLoanApprovalRequest(this.loanGroupId), data)
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        window.location.reload();
      }
    }

  }

}
