export class LoanScheme {
    constructor(private _id:number,private _name:string, private _term:number, private _interest:number) {
        
    }


    set ID(id: number) { this._id = id }
    get ID(): number { return this._id }

    set Name(name: string) { this._name = name }
    get Name(): string { return this._name }

    set Term(term: number) { this._term = term }
    get Term(): number { return this._term }

    set Interest(interest: number) { this._interest = interest }
    get Interest(): number { return this._interest }

    

}