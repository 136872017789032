<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Add Loan Request Guarantor</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body">
        <p *ngIf="contactList.length==0|| contactList==undefined">You do not have any
            contact can be chosen now, please add contact if necessary
        </p>
        <div class="d-flex justify-content-end">
            <button class="btn btn-info" (click)="addContact()">Add</button>
        </div>

        <hr />
        <ng-container *ngFor="let contact of contactList;let i=index">
            <div class="card mb-1" [ngClass]="getContactClass(contact.IsSelected)" (click)="changeIsContactSelected(i)">
                <div class="card-header">
                    <div class="row">
                        <div class="col-10">
                            <h5>Contact # {{i+1}}</h5>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col col-2">
                            <span style="font-size: 1.5rem;color: purple;" *ngIf="contact.IsSelected==true"><i
                                    class="fas fa-check"></i></span>
                        </div>
                        <div class="col col-10">
                            <div class="row">
                                <div class="col-6">
                                    Name: {{contact.Name}}
                                </div>
                                <div class="col-6">
                                    IC: {{contact.ICNumber}}
                                </div>
                                <div class="col-6">
                                    Contact Number : {{contact.ContactNumber}}
                                </div>
                                <div class="col-6">
                                    Relation : {{contact.Relation}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>



    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="submit()" [disabled]="disabledSubmit">Add
            Guarantor
            Loan</button>
        <button class="btn btn-outline-danger" type="button" (click)="reset()">Cancel</button>
    </div>

</form>