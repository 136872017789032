import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Customer } from 'src/model/customer.model';
import { GlobalCustomer } from 'src/model/globalCustomer.model';
import { Loan } from 'src/model/loan.model';
import { Profile } from 'src/model/profile.model';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-move-loan',
  templateUrl: './move-loan.component.html',
  styleUrls: ['./move-loan.component.css']
})
export class MoveLoanComponent implements OnInit {

  disabledSubmit = false;
  @Input() profileList: Profile[] = new Array;

  @Input() selectedCustomer: Customer;
  searchProfileList: GlobalCustomer[] = new Array;


  searchName: string;
  searchICNum: string;
  searchCustCode: string;

  selectedProfile: Profile;
  selectedLoan: Loan;

  constructor(private activeModal: NgbActiveModal, private restApi: RestApiService, private router: Router,
    private constructApi: ConstructAPI, public enums: Enums, public sharedService: SharedService, private location: Location) { }

  ngOnInit(): void {
    for (let i = 0; i < this.profileList.length; i++) {

      if (Number(this.profileList[i].ID) != Number(this.selectedProfile.ID))
        this.getProfileDetails(this.profileList[i].ID);
    }
  }

  getProfileDetails(id: number) {
    var xhttp = this.restApi.getRequest(this.constructApi.getProfile(id));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var json = JSON.parse((xhttp.responseText));
        var reserveAmount = (json.reserve == undefined) ? 0 : json.reserve;

        var index = this.profileList.findIndex(profile => Number(profile.ID) === Number(json.id));
        this.profileList[index].Reserve = reserveAmount;
        this.profileList[index].BadDebt = json.badDebt;
        this.profileList[index].CustomerID = this.selectedCustomer.ID;
      }
    };
  }



  findCustomer(name: string, ic: string, customerCode: string) {
    this.disabledSubmit = true;
    this.searchProfileList.splice(0, this.searchProfileList.length);
    if (ic != undefined)
      var icNumberFormat = this.sharedService.sanitizeICNumInput(ic)
    var xhr;
    xhr = this.restApi.getRequest(this.constructApi.getCompanyProfileSearch(this.selectedCustomer.CompanyId, name, icNumberFormat, customerCode));

    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;

        if (xhr.status == 200) {

          var jsonArray = JSON.parse((xhr.responseText));

          if (jsonArray.length == 0) {
            this.sharedService.openAlert("Customer not found!", this.enums.DANGER_ALERT)
          }
          else {
            jsonArray.forEach(json => {
              // create customer instance with all response data

              if (Number(json.id) != Number(this.selectedCustomer.ID)) {
                var customer = new GlobalCustomer(json.name, json.icNumber, json.companyCode, json.customerId, json.status, json.occupation, json.id);
                //add customer instance into customer list
                this.searchProfileList.push(customer);
              }

            }
            );

            if (this.searchProfileList.length == 0)
              this.sharedService.openAlert("Do not have any other customer with these search criteria", this.enums.DANGER_ALERT)

          }


        }
        else {
          this.sharedService.openAlert("Error! " + JSON.parse((xhr.responseText)).error, this.enums.DANGER_ALERT)
        }

      }
    }
  }



  confirmBeforeMoveToSameCustomer(profile: Profile) {
    this.disabledSubmit = true;

    var proceed = false;
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Confirm to move profile " +
      this.selectedLoan.ID + "-" + this.selectedLoan.LoanCode + "(RM " + this.selectedLoan.Principal + ")" + " to " + profile.ID + "-" + this.selectedCustomer.Name + "(" + profile.CustomerCode + ")";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something\

      if (confirmation == true)
        this.moveProfile(this.selectedLoan.ID, profile.ID, profile.CustomerID, false);
      else
        this.disabledSubmit = false;
    });

    return proceed;
  }

  confirmBeforeMove(customer: GlobalCustomer, isForceMove: boolean) {
    this.disabledSubmit = true;

    var proceed = false;
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Confirm to move loan " +
      this.selectedLoan.ID + "-" + this.selectedLoan.LoanCode + "(RM " + this.selectedLoan.Principal + ")" + " to " + customer.CustomerID + "-" + customer.Name + "(" + customer.ICNumber + ")";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something\

      if (confirmation == true)
        this.moveProfile(this.selectedLoan.ID, customer.ProfileID, customer.CustomerID, isForceMove);
      else
        this.disabledSubmit = false;
    });

    return proceed;
  }

  moveProfile(loanId: number, targetProfileId: number, customerId: number, isForceMove: boolean) {

    var data = {
      "targetProfileId": targetProfileId
    };


    var xhr;
    if (isForceMove == true)
      xhr = this.restApi.putRequest(this.constructApi.getForceMoveLoan(loanId), data);
    else
      xhr = this.restApi.putRequest(this.constructApi.getMoveLoan(loanId), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        if (xhr.status == 200) {

          // var urls = window.location.pathname.split("/")
          // this.location.replaceState(urls[1] + "/" + customerId);


          // window.location.reload();
          this.reset();
          this.router.navigate(['/manageCustomer', customerId, targetProfileId]);

        } else
          this.sharedService.openErrorMessage(xhr);
      }

    }
  }

  setActive(action: string) {

    if (action == "Merge") {
      if (this.profileList.length > 1) return "active";
    }
    else
      if (this.profileList.length == 1) return "active";
  }


  detectEnableSearch() {
    if (this.searchICNum == "" && this.searchName == "" && this.searchCustCode == "")
      this.disabledSubmit = true;
    else
      this.disabledSubmit = false;

    return this.disabledSubmit;
  }

  reset() {
    this.activeModal.close();
  }
}
