import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { ShowWhatsappMessageClipboardComponent } from 'src/app/Shared/show-whatsapp-message-clipboard/show-whatsapp-message-clipboard.component';
import { LoanRequest } from 'src/model/loanRequest.model';

@Component({
  selector: 'app-review-loan-request',
  templateUrl: './review-loan-request.component.html',
  styleUrls: ['./review-loan-request.component.css'],
})
export class ReviewLoanRequestComponent implements OnInit {

  @Input() requestList: LoanRequest[] = new Array;
  @Input() loanGroupId: number;

  disabledSubmit = false;

  remark = "";


  constructor(private activeModal: NgbActiveModal, public sharedService: SharedService, private restApi: RestApiService, private constructApi: ConstructAPI, private enums: Enums, private datepipe: DatePipe) {

  }

  ngOnInit(): void {



  }

  openShowWhatsappClipboardModal() {
    var modalRef = (this.sharedService.isAdmin() || this.sharedService.isBossAccount())
      ? this.sharedService.openModal(ShowWhatsappMessageClipboardComponent, "largeModal")
      : this.sharedService.openModal(ShowWhatsappMessageClipboardComponent);
    modalRef.componentInstance.requestList = this.requestList;
    modalRef.componentInstance.isEPFRequest = this.requestList[0].LoanInfo.Scheme == 'EPF';
    modalRef.componentInstance.loanGroupId = this.loanGroupId;
    // modalRef.componentInstance.previousEPFLoanList = this.previousEPFLoanList;
  }


  confirmBeforeVerifyOrUnverify(isVerify: boolean, index: number) {

    var errorMessage = "";
    if (this.requestList[index].LoanInfo.Scheme == 'EPF' && isVerify == true) {
      var isDOBValidWithIC = this.sharedService.isDOBValidWithIC(this.requestList[index].CustomerIC, new Date(this.requestList[index].DOB));
      var isEPFDateValid = this.sharedService.isEPFDateValid(new Date(this.requestList[index].DOB), new Date(this.requestList[index].EPFDate));

      if (isDOBValidWithIC == false)
        errorMessage += "Date Of Birth (" + this.datepipe.transform(this.requestList[index].DOB, "dd-MM-yyyy") + ") not same with IC (" + this.sharedService.processICNum(this.requestList[index].CustomerIC) + ") \n\n";

      if (isEPFDateValid == false) {
        var calculatedEPFDate = new Date(this.sharedService.calculateEPFDate(new Date(this.requestList[index].DOB)));
        errorMessage += "Calculated EPF Date (" + this.datepipe.transform(calculatedEPFDate, "dd-MM-yyyy") + ") not same with User defined EPF Date (" + this.datepipe.transform(this.requestList[index].EPFDate, "dd-MM-yyyy") + ")  \n\n";
      }

    }

    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    var verifyMessage = (isVerify == true) ? "VERIFY" : "UNVERIFY";
    modalRef.componentInstance.message = (errorMessage == "") ? "Confirm to " + verifyMessage + " this customer?" : "There are some error(s):\n" + errorMessage + "Confirm to " + verifyMessage + " this customer?";
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something\

      if (confirmation == true) {
        this.verifyCutsomer(this.requestList[index].CustomerId, isVerify, index)
      }
    });
  }


  verifyCutsomer(customerID: number, isVerify: boolean, index: number) {

    var xhr = (isVerify) ? this.restApi.postRequest(this.constructApi.getVerifyCustomer(customerID)) : this.restApi.postRequest(this.constructApi.getUnverifyCustomer(customerID));
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {

        if (xhr.status == 200) {

          // window.location.reload();
          if (isVerify == true) {
            this.requestList[index].VerifiedOn = new Date();
            this.requestList[index].VerifiedBy = sessionStorage.getItem("username");
          } else {
            this.requestList[index].VerifiedOn = undefined;
            this.requestList[index].VerifiedBy = undefined;
          }

        } else
          this.sharedService.openErrorMessage(xhr);

      }
    }
  }



  reset() {
    this.activeModal.close();
  }
}
