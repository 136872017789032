import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RestApiService } from 'src/app/API/restapi';
import { APIAddress } from 'src/app/API/apiAddresses';
import { Company } from 'src/model/company.model';
import { DatePipe } from '@angular/common';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { Enums } from 'src/app/Shared/enums';

@Component({
  selector: 'app-update-company',
  templateUrl: './update-company.component.html',
  styleUrls: ['./update-company.component.css'],
  providers: [DatePipe]
})
export class UpdateCompanyComponent implements OnInit {
  companyId: number;
  companyName: string;
  phoneNumber: string;
  address: string;
  regNum: string;
  companyCode: string;
  oldRequireConsentForm = false;


  dateOperating: Date;

  companyNameNull = false;
  phoneNumberNull = false;
  addressNull = false;
  companyCodeNull = false;
  regNumNull = false;

  requireConsentForm = false;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();


  constructor(private activeModal: NgbActiveModal, private restApi: RestApiService, private constructApi: ConstructAPI, private apiAddress: APIAddress,
    public sharedService: SharedService, private enums: Enums, private datepipe: DatePipe) { }

  ngOnInit(): void {
    this.getCompanyDetails(this.companyId);
  }
  validField(field: string) {
    switch (field) {
      case 'companyName': this.companyNameNull = (this.companyName === ''); break;
      case 'phoneNumber': this.phoneNumberNull = (this.phoneNumber === ''); break;
      case 'address': this.addressNull = (this.address === ''); break;
      case 'regNum': this.regNumNull = (this.regNum === ''); break;
      case 'companyCode': this.companyCodeNull = (this.companyCode === ''); break;
    }
  }

  validFields() {
    this.companyNameNull = (this.companyName === '');
    this.phoneNumberNull = (this.phoneNumber === '');
    this.addressNull = (this.address === '');
    this.regNumNull = (this.regNum === '');
    this.companyCodeNull = (this.companyCode === '');
    var dateOperatingNull = (this.dateOperating == undefined);

    return (this.companyNameNull || this.phoneNumberNull || this.addressNull || this.regNumNull || this.companyCodeNull || dateOperatingNull);
  }


  submit() {


    if (this.oldRequireConsentForm != this.requireConsentForm )
      this.validFields() ? this.sharedService.openAlert('Please fill in all the fields!', this.enums.DANGER_ALERT) : this.toggleConsentForm();
    else
      this.validFields() ? this.sharedService.openAlert('Please fill in all the fields!', this.enums.DANGER_ALERT) : this.updateCompany();
  }

  getCompanyDetails(companyID: number) {
    var xhttp = this.restApi.getRequest(this.constructApi.getCompanyDetails(companyID))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // Typical action to be performed when the document is ready:

        var json = JSON.parse((xhttp.responseText));
        // console.log(jsonArray);
        this.companyCode = json.companyCode;
        this.companyName = json.name;
        this.companyId = json.id;
        this.regNum = json.regNo;
        this.phoneNumber = json.contactNo;
        this.address = json.address;
        this.dateOperating = json.dateOperating;
        this.oldRequireConsentForm = json.requireConsentForm;
        this.requireConsentForm = this.oldRequireConsentForm;
       

      }
    };
  }

  updateCompany() {

    var dateOperatingStr = this.datepipe.transform(this.dateOperating, 'yyyy-MM-dd');

    var data = {
      "name": this.companyName,
      "address": this.address,
      "contactno": this.phoneNumber,
      "companyCode": this.companyCode,
      "regNo": this.regNum,
      "dateOperating": dateOperatingStr
    };

    var xhr = this.restApi.putRequest(this.apiAddress.UPDATE_COMPANY + this.companyId, data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        var json = JSON.parse(xhr.responseText);

        var company = new Company(json.id, json.name, json.address, json.companyCode, json.contactNo, json.regNo, json.group)
        this.passEntry.emit(company);

        this.reset();
        this.sharedService.openAlert("Successfully updated company!", this.enums.SUCCESS_ALERT);
      }
    }

  }



  toggleConsentForm() {

    var xhr = this.restApi.putRequest(this.constructApi.getCompanyToggleConsentFormRequirement(this.companyId));
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        if (xhr.status == 200) {
          this.updateCompany();
        }
        else {
          this.sharedService.openErrorMessage(xhr);
        }
      }

    }

  }
  reset() {
    this.activeModal.close();
    this.companyName = "";
    this.address = "";
    this.phoneNumber = "";
  }



}
