import { DuitLebih } from "./duitLebih.model"

export class Payment {


    constructor(private _stamp: number, private _serviceCharge: number, private _arrear: number, private _discount: number, private _dateCreated: Date, private _remark: string,
        private _extras?: DuitLebih[], private _reserve?: number, private _term?: number, private _principal?: number, private _termsAmount?: number,
        private _payInterestAfterPrincipal?: boolean, private _newInterestAmount?: number, private _actualDiscountAmount?: number, private _handlingCharge?: number) {

    }

    set Stamp(stamp: number) { this._stamp = stamp }
    get Stamp(): number { return this._stamp }

    set ServiceCharge(serviceCharge: number) { this._serviceCharge = serviceCharge }
    get ServiceCharge(): number { return this._serviceCharge }

    set Arrear(arrear: number) { this._arrear = arrear }
    get Arrear(): number { return this._arrear }

    set Discount(discount: number) { this._discount = discount }
    get Discount(): number { return this._discount }

    set DateCreated(dateCreated: Date) { this._dateCreated = dateCreated }
    get DateCreated(): Date { return this._dateCreated }

    set Remark(remark: string) { this._remark = remark }
    get Remark(): string { return this._remark }


    set Term(term: number) { this._term = term }
    get Term(): number { return this._term }

    set Reserve(reserve: number) { this._reserve = reserve }
    get Reserve(): number { return this._reserve }

    set Principal(principal: number) { this._principal = principal }
    get Principal(): number { return this._principal }

    set Extras(extras: DuitLebih[]) { this._extras = extras }
    get Extras(): DuitLebih[] { return this._extras }


    set TermsAmount(termsAmount: number) { this._termsAmount = termsAmount }
    get TermsAmount(): number { return this._termsAmount }

    set PayInterestAfterPrincipal(payInterestAfterPrincipal: boolean) { this._payInterestAfterPrincipal = payInterestAfterPrincipal }
    get PayInterestAfterPrincipal(): boolean { return this._payInterestAfterPrincipal }

    set NewInterestAmount(newInterestAmount: number) { this._newInterestAmount = newInterestAmount }
    get NewInterestAmount(): number { return this._newInterestAmount }

    get ActualDiscountAmount(): number {
        return this._actualDiscountAmount
    }
    set ActualDiscountAmount(value: number) {
        this._actualDiscountAmount = value
    }

    get HandlingCharge(): number {
        return this._handlingCharge
    }
    set HandlingCharge(value: number) {
        this._handlingCharge = value
    }
}