import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { CompanyAccess } from 'src/model/companyAccess.model';
import { User } from 'src/model/user.model';

@Component({
  selector: 'app-manage-company-access',
  templateUrl: './manage-company-access.component.html',
  styleUrls: ['./manage-company-access.component.css']
})
export class ManageCompanyAccessComponent implements OnInit {

  constructor(private activeModal: NgbActiveModal, private restApi: RestApiService, private constructApi: ConstructAPI,
    public sharedService: SharedService, private enums: Enums, private cdr: ChangeDetectorRef) { }

  userList: User[] = new Array;
  selectedUser: string;
  priority: number;

  companyAccessList: CompanyAccess[] = new Array;

  @Input() selectedCompany: number;
  @Input() selectedCompanyCode: number;
  ngOnInit(): void {
    this.getAllUsersByRoles();
    this.getCompanyAccess(this.selectedCompany);
  }



  getAllUsersByRoles() {
    var xhttp = this.restApi.getRequest(this.constructApi.getAllUsersByRoles([this.enums.ADMIN, this.enums.EXPENSE_ADMIN, this.enums.AUDITOR]))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {

          var user = new User(null, json.companyId, json.companyName, json.roleId, json.roleName, json.username, null)


          this.userList.push(user);
        }
        );
      }
    };
  }


  getCompanyAccess(companyId: number) {
    var xhttp = this.restApi.getRequest(this.constructApi.getCompaniesAccess(companyId))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          var access = new CompanyAccess(json.companyCode, json.companyId, json.priority, json.username);

          this.companyAccessList.push(access);
        }
        );
      }
    };
  }


  submit() {
    if (this.selectedUser == undefined || this.priority == undefined || this.priority == 0) {
      this.sharedService.openAlert("Please fill in all necessary info", this.enums.DANGER_ALERT);

    }
    else {
      this.grantUserCompanyAccess();
    }
  }

  grantUserCompanyAccess() {

    var data = {
      "companyIds": [this.selectedCompany]
    }

    var xhr = this.restApi.putRequest(this.constructApi.getGrantUserCompanyAccess(this.selectedUser, this.priority), data)
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {

        if (xhr.status == 200) {
          this.sharedService.openAlert("Successfully granted access to user!", this.enums.SUCCESS_ALERT);
          var access = new CompanyAccess(undefined, this.selectedCompany, this.priority, this.selectedUser);
          this.companyAccessList.push(access);
          this.cdr.detectChanges();
          this.reset();

        }
        else {
          this.sharedService.openErrorMessage(xhr);
        }



      }
    }

  }



  confirmBeforeRevoke(username: string, index: number) {
    var proceed = false;
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Confirm to revoke " + username + "'s access from company?";
    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something\

      if (confirmation == true)
        this.revokeUserCompanyAccess(username, index);
    });

  }


  revokeUserCompanyAccess(username: string, index: number) {
    var data = {
      "companyIds": [this.selectedCompany]
    }

    var xhr = this.restApi.deleteRequest(this.constructApi.getRevokeUserCompanyAccess(username), data)
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {

        if (xhr.status == 200) {

          this.sharedService.openAlert("Successfully revoked access from user!", this.enums.SUCCESS_ALERT);
          this.companyAccessList.splice(index, 1);
          this.cdr.detectChanges();
          this.reset();
        }
        else {
          this.sharedService.openErrorMessage(xhr);
        }



      }
    }
  }

  reset() {
    this.priority = undefined;
    this.selectedUser = undefined;


  }

  close() {
    this.activeModal.close();
  }
}
