<div class="dropdown">
    <button class="btn btn-dark dropdown-toggle" type="button" id="dropdownMenuButton"  data-bs-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        <i class="fas fa-receipt"></i> Add transaction
    </button>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <h5 class="dropdown-header fw-bold">Basic</h5>
        <!-- <a class="dropdown-item income-item" (click)="openIncomeModal(false)"><i class="fas fa-plus"></i>
            Income</a> -->
        <a class="dropdown-item expenses-item" (click)="openExpenseModal()"> <i class="fas fa-minus"></i>
            Expenses</a>
        <!-- <a class="dropdown-item income-byExtra-item" (click)="openIncomeModal(true)">
            <i class="fas fa-money-check-alt"></i>
            Income by Duit Lebih</a> -->
        <a class="dropdown-item expenses-byExtra-item" (click)="openExpenseByExtraModal()">
            <i class="fas fa-money-check-alt"></i>
            Expense by Duit Lebih <strong>(D-)</strong></a>
        <h5 class="dropdown-header fw-bold">Advanced</h5>
        <a class="dropdown-item capital-item" (click)="openCapitalModal()">
            <i class="fas fa-money-bill-wave"></i>
            Add Capital
        </a>

        <a class="dropdown-item toting-item" (click)="openTotingModal()">
            <i class="fas fa-comment-dollar"></i>
            Add Toting
        </a>
        <a class="dropdown-item saving-item" (click)="openSavingModal()">
            <i class="fas fa-wallet"></i>
            Add Saving
        </a>
        <h5 class="dropdown-header fw-bold">Others</h5>
        <a class="dropdown-item add-asset-item" (click)="openAssetModal()">
            <i class="fas fa-plus"></i>
            Apply / Return Cash</a>
        <a class="dropdown-item add-transfer-item" (click)="openTransferModal()">
            <i class="fas fa-exchange-alt"></i>
            Transfer Fund</a>
    </div>
</div>