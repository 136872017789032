import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Customer } from 'src/model/customer.model';
import { FormJ } from 'src/model/formJ.model';
import { PaymentSchedule } from 'src/model/paymentSchedule.model';
import { GenerateFormjComponent } from '../generate-formj/generate-formj.component';

@Component({
  selector: 'app-form-j-history',
  templateUrl: './form-j-history.component.html',
  styleUrls: ['./form-j-history.component.css']
})
export class FormJHistoryComponent implements OnInit {

  @Input() viewCustomer: Customer;
  formJList: FormJ[] = new Array;
  constructor(private router: Router, public sharedService: SharedService, private restapi: RestApiService, private constructAPI: ConstructAPI) { }

  ngOnInit(): void {
    this.getAllFormJsOfCustomer();
  }

  openFormJModal() {
    var modal = this.sharedService.openModal(GenerateFormjComponent, "largeModal");
    modal.componentInstance.viewCustomerId = this.viewCustomer.ID;
  }

  private getAllFormJsOfCustomer() {
    var xhttp = this.restapi.getRequest(this.constructAPI.getCustomerFormJs(this.viewCustomer.ID))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          var lastPayment = Number(json.data.lastPayment);
          var paymentScheduleList = this.processPaymentSchedule(JSON.parse(JSON.stringify(json.data.paymentSchedule)))
          var formJ = new FormJ(json.id, json.principal, json.customerId, json.term, json.dateCreated, lastPayment, paymentScheduleList, json.data.receiptNumber, json.data.stampDate,json.data.version)

          this.formJList.push(formJ);
        }
        );
      }
    };
  }

  private reprintFormJ(formJID: number) {
    this.router.navigateByUrl('/formJ/' + this.viewCustomer.CompanyId + "/" + this.viewCustomer.ID + "/" + formJID)
  }

  processPaymentSchedule(scheduleList: any) {
    var paymentScheduleList: PaymentSchedule[] = new Array;
    scheduleList.forEach(schedule => {
      var payment = { monthsCount: schedule.monthsCount, amount: schedule.amount }
      paymentScheduleList.push(payment);
    }
    );
    return scheduleList;
  }

  openDeleteFormJDialog(formJ: FormJ) {

    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Confirm to delete Form J generated on " + new Date(formJ.DateCreated).toLocaleDateString() + " with last payment of RM " + formJ.LastPayment + "?";


    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;
      if (confirmation == true) {

        this.deleteFormJ(formJ.ID)
      }
    });
  }

  deleteFormJ(id: number) {
    var xhr = this.restapi.deleteRequest(this.constructAPI.getDeleteFormJ(id));

    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {

        if (xhr.status == 200) {
          window.location.reload();
        }
        else {
          this.sharedService.openErrorMessage(xhr);
        }


      }

    }

  }

}
