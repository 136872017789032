import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddPlanComponent } from '../../AddThings/add-plan/add-plan.component';
import { AppComponent } from '../../app.component';
import { AddCustomerComponent } from '../../AddThings/add-customer/add-customer.component';
import { AuthService } from '../../auth/auth.service';
import { AddAccountComponent } from 'src/app/AddThings/add-account/add-account.component';
import { GlobalSearchCustomerComponent } from '../global-search-customer/global-search-customer.component';
import { Enums } from 'src/app/Shared/enums';
import { SearchTaskComponent } from '../search-task/search-task.component';
import { EditProfileComponent } from 'src/app/User/edit-profile/edit-profile.component';
import { FundReallocationComponent } from 'src/app/Account/fund-reallocation/fund-reallocation.component';
import { SearchByReceiptComponent } from '../search-by-receipt/search-by-receipt.component';
import { AddSubtypeComponent } from 'src/app/AddThings/add-subtype/add-subtype.component';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { MaintenanceModeToggleComponent } from '../maintenance-mode-toggle/maintenance-mode-toggle.component';
import { EpfRequestCalculatorComponent } from 'src/app/LoanRequest/epf-request-calculator/epf-request-calculator.component';
import { Router } from '@angular/router';
import { AddTransactionRequestComponent } from 'src/app/AddThings/add-transaction-request/add-transaction-request.component';
import { AddBlacklistCustomerComponent } from 'src/app/Blacklist/add-blacklist-customer/add-blacklist-customer.component';
import { ValidateEpfStatementComponent } from '../validate-epf-statement/validate-epf-statement.component';
import { SearchTicketComponent } from 'src/app/LoanRequest/search-ticket/search-ticket.component';

declare var $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  title = 'theCoolestProject';
  toggled: boolean = false;
  key: any;

  loggedInUser: string;
  loggedInRoleID: number;
  loggedInCompanyUid: string;
  loggedInCompanyName: string;

  constructor(private router: Router, private appComponent: AppComponent, private _auth: AuthService, public enums: Enums, public sharedService: SharedService) { }

  ngOnInit(): void {
    this.loggedInUser = sessionStorage.getItem("username");
    this.loggedInRoleID = Number(sessionStorage.getItem("roleId"));
    this.loggedInCompanyUid = sessionStorage.getItem("companyUid");
    this.loggedInCompanyName = sessionStorage.getItem("companyName")
  }

  openAddPlanModal() {
    const modalRef = this.sharedService.openModal(AddPlanComponent);

  }
  openAddSubTypeModal() {
    const modalRef = this.sharedService.openModal(AddSubtypeComponent);
  }

  openAddCustomerModal() {
    const modalRef = this.sharedService.openModal(AddCustomerComponent, "largeModal");
    //get customer that was created
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      window.location.reload();
    });
  }

  openEPFCalculatorModal() {
    const modalRef = this.sharedService.openModal(EpfRequestCalculatorComponent);
    modalRef.componentInstance.epfLimitRuleSet = this.enums.epfLimitList;
  }


  openEditProfileModal() {
    const modalRef = this.sharedService.openModal(EditProfileComponent);
  }

  openAddAccountModal() {
    const modalRef = this.sharedService.openModal(AddAccountComponent);
  }

  openGlobalSearchModal(isGlobal: boolean) {

    const modalRef = this.sharedService.openModal(GlobalSearchCustomerComponent, "largeModal")
    // const modalRef = this.sharedService.openModal(GlobalSearchCustomerComponent, { windowClass: "largeModal" });
    modalRef.componentInstance.isGlobal = isGlobal;
  }

  openSearchTaskModal() {
    const modalRef = this.sharedService.openModal(SearchTaskComponent, "largeModal");
  }

  openSearchCustomerByReceiptModal() {
    const modalRef = this.sharedService.openModal(SearchByReceiptComponent, "largeModal");
  }

  openFundAllocationModal() {
    const modalRef = this.sharedService.openModal(FundReallocationComponent, "largeModal");
  }
  openMaintenaceModeModal(On: boolean) {
    const modalRef = this.sharedService.openModal(MaintenanceModeToggleComponent, "largeModal");
    modalRef.componentInstance.turn_on = On;
  }

  openAddTransactionRequestModal() {
    const modalRef = this.sharedService.openModal(AddTransactionRequestComponent, "largeModal");

  }

  openAddBlacklistCustomer() {
    const modalRef = this.sharedService.openModal(AddBlacklistCustomerComponent);
  }


  openValidateEPFStatement() {
    const modalRef = this.sharedService.openModal(ValidateEpfStatementComponent);
  }


  openSearchTicketModal() {
    const modalRef = this.sharedService.openModal(SearchTicketComponent, "largeModal")
  }

  goToBookmarks() {
    this.router.navigate(['/bookmarks']);
  }

  goToServerStatusPage() {
    this.router.navigate(['/serverStatus']);
  }


  toggleMenu() {
    $('#sidebar').toggleClass('active');
  }

  toggleSidebar() {
    this.appComponent.toggleSidebar();
  }

  logout() {
    this._auth.logout();
  }


}
