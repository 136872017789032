import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Loan } from 'src/model/loan.model';
import { RestApiService } from 'src/app/API/restapi';
import { Customer } from 'src/model/customer.model';
import { Account } from 'src/model/account.model';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { UploadImageComponent } from 'src/app/Images/upload-image/upload-image.component';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Profile } from 'src/model/profile.model';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Contact } from 'src/model/contact.model';
import { AddContactComponent } from '../add-contact/add-contact.component';
import { DatePipe } from '@angular/common';
import { ShowWhatsappMessageClipboardComponent } from 'src/app/Shared/show-whatsapp-message-clipboard/show-whatsapp-message-clipboard.component';
import { EPF } from 'src/model/epf.model';
import { LoanRequest } from 'src/model/loanRequest.model';
import { ElevateConfirmationComponent } from 'src/app/LoanRequest/elevate-confirmation/elevate-confirmation.component';
import { MatStepper } from '@angular/material/stepper';
import { AddCommissionsComponent } from 'src/app/Comission/add-commissions/add-commissions.component';



@Component({
  selector: 'app-add-loan',
  templateUrl: './add-loan.component.html',
  styleUrls: ['./add-loan.component.css'],
  providers: [DatePipe]
})




export class AddLoanComponent implements OnInit {


  loggedInUser: string;
  loggedInRole: number;
  loggedInCompanyName: string;
  loggedInCompanyId: number;

  companyAccountList: Account[] = new Array;
  selectedAccountId: number;

  withAnotherLoan: boolean = false;
  @Input() oboCompanyId: number;
  @Input() selectedCustomer: Customer;
  @Input() selectedProfile: Profile;
  @Input() epfDetails: EPF;
  @Input() isLead: boolean = false;
  @Input() isEPFExtra: boolean = false;
  @Input() previousEPFLoans: Loan[] = new Array;
  @Input() needRestrictedRequest: boolean;
  @Input() allowEPF: boolean;

  @Input() isOverlap: boolean = false;
  @Input() batchDate: Date;

  @Input() isManualKeyIn: boolean = false;


  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  loanList: Loan[] = new Array;

  nextTaskDate: Date;
  error: boolean = false;


  disabledSubmit = false;

  hasApplicationForm: boolean;

  nonExistedNecessaryFileTypeList: string[] = new Array;

  hasEPFStatement: boolean;
  hasCustomerAcknowledgement: boolean;
  hasEPFProof: boolean;
  isEPF: boolean = false;
  payWithSimpan: boolean = false;


  ratio = 0;

  //test
  loanFormGroup: UntypedFormGroup;
  contactFormGroup: UntypedFormGroup;
  epfFormGroup: UntypedFormGroup;
  isOptional = false;
  contactList: Contact[] = new Array;
  request: LoanRequest;
  restrictedReqeustRemark: string;

  @Input() isManualElevate = false;
  elevateError = "";
  elevateLoanError = "";
  elevateSuggestedSettlement = "";


  requestRemark: string = "";

  @ViewChild('stepper') private epfStepper: MatStepper;

  constructor(private activeModal: NgbActiveModal, private modalService: NgbModal,
    private restApi: RestApiService, private constructApi: ConstructAPI, public enums: Enums, public sharedService: SharedService,
    private _formBuilder: UntypedFormBuilder, private cdr: ChangeDetectorRef, private datepipe: DatePipe) { }

  ngOnInit(): void {
    this.loggedInCompanyName = sessionStorage.getItem('companyName');
    this.loggedInCompanyId = Number(sessionStorage.getItem('companyId'));
    this.loggedInUser = sessionStorage.getItem('username');
    this.loggedInRole = Number(sessionStorage.getItem('roleId'));

    if (this.sharedService.isOperatingUser()) {

      // if dont have epf details initialize epf information
      if (this.epfDetails == undefined) {

        if (this.selectedCustomer.EPFDate != undefined)
          this.getCustomerEPF();
        else {
          // if this customer is actually a lead, get lead info first
          if (this.isLead == true)
            this.getLeadDetails(this.selectedCustomer.ID);


          //if this customer dont have any details
          else {
            //calculate customer EPF if do not have old record
            if (this.selectedCustomer.DOB != undefined)
              var epfDate = this.sharedService.calculateEPFDate(new Date(this.selectedCustomer.DOB));
            this.epfDetails = new EPF(undefined, undefined, new Date(epfDate), undefined, undefined, undefined, undefined)

            this.bindFormGroupInfo();
          }

        }

      }
      // if epf details is found: plug in epf information
      else {
        this.bindFormGroupInfo();

      }
    }

    //if requireConsentForm property is not found in session storage, get this info
    var requireConsentForm = sessionStorage.getItem("requireConsentForm")
    if (requireConsentForm == undefined)
      this.getCompanyInfo(this.loggedInCompanyId);

    // this.getApplicationFormExistence(this.loggedInCompanyId);


    // this.getAllLoanSchemes();
    this.getAllAccountsOfCompany(this.loggedInCompanyId);

    // this.nextTaskDate = this.calculateNextTaskDate();
    this.calculateNextTaskDate();

    if (this.selectedProfile.Reserve > 0 && this.isOverlap == false)
      this.openPayBySimpanConfirmation();


  }


  bindFormGroupInfo() {
    var epfDate;

    if (this.epfDetails.Date == undefined && this.selectedCustomer.DOB != undefined)
      epfDate = this.sharedService.calculateEPFDate(new Date(this.selectedCustomer.DOB));
    else
      epfDate = this.epfDetails.Date;


    this.epfFormGroup = this._formBuilder.group({
      epfDate: [epfDate, Validators.required],
      epfAmount: [this.epfDetails.Amount, Validators.required],
      hasiAccount: [this.epfDetails.HasIAccount],
      iAccountUsername: [this.epfDetails.IAccountUsername],
      iAccountPassword: [this.epfDetails.IAccountPassword],
      remark: [this.epfDetails.Remark],
      otherId: [this.selectedCustomer.OtherID],
      gender: [this.selectedCustomer.Gender],
      race: [this.selectedCustomer.Race]
    });


    this.loanFormGroup = this._formBuilder.group({
      loanList: [this.loanList]
    });

    this.contactFormGroup = this._formBuilder.group({
      contactList: [this.contactList]
    });
  }



  getCustomerEPF() {

    var xhttp = this.restApi.getRequest(this.constructApi.getCustomerEPF(this.selectedCustomer.ID));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var json = JSON.parse((xhttp.responseText));
        this.epfDetails = new EPF(json.amount, json.customerId, json.date, json.hasIAccount, json.iAccountPassword, json.iAccountUsername, json.remark);
        this.bindFormGroupInfo();
      }
    };
  }



  openUploadEPFStatementModal() {
    const modalRef = this.sharedService.openModal(UploadImageComponent);

    modalRef.componentInstance.selectedCustomerId = this.selectedCustomer.ID;
    modalRef.componentInstance.supportingDocType = this.enums.EPF_STATEMENT;
    //get added loan
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      if (entry != undefined)
        this.hasEPFStatement = true;
    });

    modalRef.result.then(() => {
      //detect changes
      this.cdr.detectChanges();
    })
  }

  openUploadCustomerAcknowledgementModal() {
    const modalRef = this.sharedService.openModal(UploadImageComponent);

    modalRef.componentInstance.selectedCustomerId = this.selectedCustomer.ID;
    modalRef.componentInstance.supportingDocType = this.enums.ACKNOWLEDGEMENT;
    //get added loan
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      if (entry != undefined)
        this.hasCustomerAcknowledgement = true;
    });

    modalRef.result.then(() => {
      //detect changes
      this.cdr.detectChanges();
    })
  }

  openUploadEPFProofModal() {
    const modalRef = this.sharedService.openModal(UploadImageComponent);

    modalRef.componentInstance.selectedCustomerId = this.selectedCustomer.ID;
    modalRef.componentInstance.supportingDocType = this.enums.EPF_PROOF;
    //get added loan
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      if (entry != undefined)
        this.hasEPFProof = true;
    });

    modalRef.result.then(() => {
      //detect changes
      this.cdr.detectChanges();
    })
  }


  openPayBySimpanConfirmation() {
    var proceed = false;
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "This customer has RM " + this.selectedProfile.Reserve + " SIMPAN. You want to PAY BY SIMPAN?";
    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something\

      if (confirmation == true)
        this.payWithSimpan = true;
    });

  }






  getLeadDetails(id: number) {
    var xhttp = this.restApi.getRequest(this.constructApi.getLeadDetails(this.selectedCustomer.ID));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var json = JSON.parse((xhttp.responseText));

        //if this lead epf details is found
        if (json.epfDate != undefined) {
          this.selectedCustomer.EPFDate = json.epfDate;
          this.getCustomerEPF();
        }

        //if this lead dont have any epf details
        else {
          if (this.selectedCustomer.DOB != undefined)
            var epfDate = new Date(this.sharedService.calculateEPFDate(new Date(this.selectedCustomer.DOB)));
          this.epfDetails = new EPF(undefined, undefined, epfDate, undefined, undefined, undefined, undefined)
          this.bindFormGroupInfo();
        }
      }
    };
  }
  countSelectedGaurantor() {
    var count = 0;
    if (this.contactList.length == 0)
      return 0;
    else {
      for (let i = 0; i < this.contactList.length; i++) {
        if (this.contactList[i].IsSelected == true)
          count++;
      }

      return count;
    }
  }


  addContact() {
    const modalRef = this.sharedService.openModal(AddContactComponent);
    modalRef.componentInstance.viewCustomerId = this.selectedCustomer.ID;

    //get added loan
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var contact = entry;

      //append added loan to loan list
      this.contactList.push(contact);
    });

    modalRef.result.then(() => {

      //detect changes
      this.cdr.detectChanges();
    })
  }

  deleteContact(i: number) {
    this.contactList.splice(i, 1);
  }


  getFormValue(valueString: string) {

    return this.epfFormGroup.get(valueString).value;
  }


  loadEPFInfos() {
    if (this.isEPF == true)
      this.getContactInfo();
  }





  calculateNextTaskDate() {

    //get next month's date
    var taskDateStr = new Date(new Date().getFullYear(), new Date().getMonth() + 1, this.selectedProfile.SalaryDay);

    try {
      var taskDate = taskDateStr;
    }
    catch {
      // if date string not valid, set last day of the month
      taskDateStr = new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0);
    }
    this.nextTaskDate = taskDate;

  }



  getLoan1Data(loan: Loan) {
    this.loanList[0] = loan;
    this.selectedAccountId = undefined;
  }

  getLoan2Data(loan: Loan) {
    this.loanList[1] = loan;
    this.selectedAccountId = undefined;
  }

  calculateTotalOnHand() {
    var total = 0;
    for (let i = 0; i < this.loanList.length; i++) {
      if (this.loanList[i] != undefined) {
        var duitLebihTotal = 0;
        if (this.loanList[i].Extras.length > 0) {
          for (let j = 0; j < this.loanList[i].Extras.length; j++)
            duitLebihTotal += this.loanList[i].Extras[j].Amount;
        }

        total += this.loanList[i].Principal + this.loanList[i].Reserve - duitLebihTotal - this.loanList[i].InterestCharge - this.loanList[i].StampAmount -
          this.loanList[i].ServiceCharge
      }

    }

    return total;
  }

  calculateTotalProcessing() {
    var total = 0;
    for (let i = 0; i < this.loanList.length; i++) {
      if (this.loanList[i] != undefined)
        total += this.loanList[i].InterestCharge;
    }

    return total;
  }

  calculateTotalStampAndSC() {
    var total = 0;
    for (let i = 0; i < this.loanList.length; i++) {
      if (this.loanList[i] != undefined)
        total += this.loanList[i].StampAmount + this.loanList[i].ServiceCharge;
    }

    return total;
  }

  calculateTotalDuitLebih() {
    var total = 0;
    for (let i = 0; i < this.loanList.length; i++) {
      if (this.loanList[i] != undefined) {
        if (this.loanList[i].Extras.length > 0) {
          for (let j = 0; j < this.loanList[i].Extras.length; j++) {
            total += this.loanList[i].Extras[j].Amount;
          }
        }
      }
    }
    return total;
  }

  openUploadApplicationFormModal() {
    const modalRef = this.sharedService.openModal(UploadImageComponent);

    modalRef.componentInstance.selectedCustomerId = this.selectedCustomer.ID;
    modalRef.componentInstance.isApplicationForm = true;
    modalRef.componentInstance.isSingleType = false

    modalRef.componentInstance.passEntry.subscribe((entry) => {
      this.getAllFileExitence();
    });


    // this.hasApplicationForm = true;
  }

  openUploadSupportingDocModal() {
    const modalRef = this.sharedService.openModal(UploadImageComponent);

    modalRef.componentInstance.selectedCustomerId = this.selectedCustomer.ID;
    modalRef.componentInstance.isSingleType = false;
    //get added loan
    // modalRef.componentInstance.passEntry.subscribe((entry) => {
    //   if (entry != undefined)

    // });

    modalRef.result.then(() => {
      //detect changes
      this.cdr.detectChanges();
    })
  }


  reset() {
    this.activeModal.close();

  }

  dismissModal() {
    this.activeModal.dismiss();
  }




  constructReqeust() {

    var epfDate = this.getFormValue('epfDate');
    var epfAmount = this.getFormValue('epfAmount');
    var hasIAccount = this.getFormValue('hasiAccount');
    var iAccountPassword = this.getFormValue('iAccountPassword');
    var iAccountUsername = this.getFormValue('iAccountUsername');

    var guarantorList: Contact[] = new Array;
    for (let i = 0; i < this.contactList.length; i++) {
      if (this.contactList[i].IsSelected == true) {
        guarantorList.push(this.contactList[i]);
      }

    }

    // When constructing request, need to make reserve amount into negative

    this.loanList[0].Reserve = (this.loanList[0].Reserve != undefined) ? - this.loanList[0].Reserve : 0;

    //check if there is any on behalf of company id, if exist, then use that company id, else use logged in company id
    var companyId = (this.oboCompanyId == undefined) ? this.selectedCustomer.CompanyId : this.oboCompanyId;

    this.request = new LoanRequest(this.selectedCustomer.ID, epfAmount, epfDate, guarantorList, hasIAccount, undefined, this.loanList[0], undefined, this.selectedProfile.ID,
      this.requestRemark, undefined, undefined, undefined, undefined
      , undefined, this.selectedCustomer.CompanyCode, this.selectedCustomer.ICNumber, this.selectedCustomer.Name, companyId)


    //emit this request back to parent component
    this.passEntry.emit(this.request);
    this.activeModal.close();

  }


  checkEPFInfoChanged() {

    if (this.epfDetails.Date != this.getFormValue('epfDate'))
      return true;

    else if (this.epfDetails.Amount != this.getFormValue('epfAmount'))
      return true;

    else if (this.epfDetails.HasIAccount != this.getFormValue('hasiAccount'))
      return true;

    else if (this.epfDetails.IAccountUsername != this.getFormValue('iAccountUsername'))
      return true;

    else if (this.epfDetails.IAccountPassword != this.getFormValue('iAccountPassword'))
      return true;

    else
      return false;
  }




  updateCustomerDetails(requireElevate: boolean) {
    var data;
    var otherId = this.getFormValue('otherId')
    var gender = this.getFormValue('gender')
    var race = this.getFormValue('race')
    data = {
      "name": this.selectedCustomer.Name,
      "icNumber": this.selectedCustomer.ICNumber,
      "contactNo": this.selectedCustomer.ContactNo,
      "ctosNumber": this.selectedCustomer.CtosNumber,
      "ctosStatus": this.selectedCustomer.CtosStatus,
      "status": this.selectedCustomer.Status,
      "dateCreated": this.selectedCustomer.DateCreated,
      "handler": this.selectedCustomer.HandlerId,
      "companyId": this.selectedCustomer.CompanyId,
      "address": this.selectedCustomer.Address,
      "address2": this.selectedCustomer.Address2,
      "otherId": otherId,
      "gender": gender,
      "race": race
    }
    var xhr = this.restApi.putRequest(this.constructApi.getUpdateCustomer(this.selectedCustomer.ID), data);

    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        //if success , then proceed
        if (xhr.status == 200) {

        }
        else {
          this.disabledSubmit = false;

          //show error message
          this.sharedService.openAlert(JSON.parse(xhr.responseText).error, this.enums.DANGER_ALERT)

        }
      }
    }
  }

  updateEPFDetails(requireElevate: boolean) {
    var data;

    if (this.epfDetails.Date != undefined) {
      data = {
        "hasIAccount": this.getFormValue('hasiAccount'),
        "iAccountUsername": this.getFormValue('iAccountUsername'),
        "iAccountPassword": this.getFormValue('iAccountPassword'),
        "remark": ""
      }

    } else {
      var epfStr = this.datepipe.transform(this.getFormValue('epfDate'), 'yyyy-MM-dd');
      data = {
        "date": epfStr,
        "amount": this.getFormValue('epfAmount'),
        "hasIAccount": this.getFormValue('hasiAccount'),
        "iAccountUsername": this.getFormValue('iAccountUsername'),
        "iAccountPassword": this.getFormValue('iAccountPassword'),
        "remark": ""
      }
    }



    var xhr;
    if (this.sharedService.isBranchAccountHandler())
      xhr = (this.epfDetails.Date == undefined) ? this.restApi.postRequest(this.constructApi.getUpdateEPFDetails(this.selectedCustomer.ID), data) : this.restApi.putRequest(this.constructApi.getUpdateEPFDetails(this.selectedCustomer.ID), data)
    else
      xhr = this.restApi.postRequest(this.constructApi.getUpdateEPFDetails(this.selectedCustomer.ID), data);

    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        if (xhr.status == 200) {

          this.epfDetails.Date = new Date(this.datepipe.transform(this.getFormValue('epfDate'), 'yyyy-MM-dd'));
          this.epfDetails.Amount = this.getFormValue('epfAmount');

          this.addEPFRequest(requireElevate);
        }
        else {
          this.disabledSubmit = false;

          //show error message
          this.sharedService.openAlert(JSON.parse(xhr.responseText).error, this.enums.DANGER_ALERT)

        }
      }
    }
  }




  submitEPFLoanRequest(requireElevate: boolean) {
    this.disabledSubmit = true;


    //if there is changes in other id, update first only send request
    var otherId = this.getFormValue('otherId')

    if (this.selectedCustomer.OtherID == undefined || this.selectedCustomer.OtherID != otherId || this.selectedCustomer.Gender == '' || this.selectedCustomer.Race == '')

      this.updateCustomerDetails(requireElevate);

    //DETECT IF EPF INFO IS CHANGED
    if (this.epfDetails.Date == undefined)
      this.updateEPFDetails(requireElevate);
    else {
      if (this.checkEPFInfoChanged() == true && this.sharedService.isBranchAccountHandler())
        this.updateEPFDetails(requireElevate);
      else
        this.addEPFRequest(requireElevate);
    }

  }


  confirmBeforeElevatingCase() {

    const modalRef = this.sharedService.openModal(ElevateConfirmationComponent);

    modalRef.componentInstance.message = this.elevateError + this.elevateLoanError + this.elevateSuggestedSettlement;

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {

      if (entry == true)
        this.submitEPFLoanRequest(true);

    });
  }


  checkEPFLoanBeforeSubmitting() {

    var errorStr = this.validateEPFLoan();
    if (errorStr == '') {

      // var epfDate = this.getFormValue("epfDate");
      // var epfAmount = this.getFormValue("epfAmount");
      // var epfCategory = this.sharedService.checkEPFLimitCategory(epfDate, epfAmount);
      // var currentLoanSettlement = this.sharedService.calculateEPFSettlementAmountWithCharges(this.loanList[0]);


      // // EPF set Limit
      // if (this.isEPF == true && this.enums.epfLimitList[epfCategory].ElevatedSettlementRate != undefined &&
      //   this.sharedService.calculatePreviousEPFLoanSettlement(this.previousEPFLoans) + currentLoanSettlement > this.sharedService.getEPFElevateSettlement(epfDate, epfAmount)) {
      //   this.elevateSuggestedSettlement += "\n\n- The loan requested has the settlement ratio more than " + this.enums.epfLimitList[epfCategory].ElevatedSettlementRate * 100 + "%. This case will be sent to BOSS FOR APPROVAL"
      //   this.confirmBeforeElevatingCase();
      // }
      // else if (this.isManualElevate == true)
      //   this.confirmBeforeElevatingCase();
      // else
      //   this.submitEPFLoanRequest(false)
      this.constructReqeust();


    }
    else
      this.sharedService.openAlert("Please fill in all the necessary fields CORRECTLY" + errorStr, this.enums.DANGER_ALERT);


  }

  //add: add in restriction for senior within certain age must send loan request other than EPF scheme
  addRestrictedLoanRequest() {
    var string = this.checkNull();
    if (string != "") {
      this.sharedService.openAlert(string, this.enums.DANGER_ALERT);
    } else {
      var loanInfoData = {
        "principal": this.loanList[0].Principal,
        "scheme": this.loanList[0].Scheme,
        "term": this.loanList[0].Term,
        "interestRate": this.sharedService.roundToDec(this.loanList[0].InterestRate, 11),
        "stamp": this.loanList[0].StampAmount,
        "serviceCharge": this.loanList[0].ServiceCharge,
        "extras": this.loanList[0].Extras,
        "processingRate": this.loanList[0].ProcessingRate,
        "reserve": -this.loanList[0].Reserve,
      }
      var data = {
        "profileId": this.selectedProfile.ID,
        "loanInfo": loanInfoData,
        "requestOn": new Date().toISOString(),
        "remark": this.restrictedReqeustRemark
      }

      var loanInfo = new Loan(undefined, this.selectedProfile.ID, this.loanList[0].Principal, this.loanList[0].StampAmount, undefined, undefined, new Date(), this.selectedCustomer.CompanyId,
        undefined, this.loanList[0].Scheme, this.loanList[0].InterestRate, this.loanList[0].Term, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined,
        undefined, undefined, undefined, this.loanList[0].ServiceCharge, this.loanList[0].Extras, undefined, undefined, undefined, this.loanList[0].ProcessingRate);
      this.request = new LoanRequest(this.selectedCustomer.ID, undefined, undefined, undefined, undefined, undefined, loanInfo, undefined, this.selectedProfile.ID, this.restrictedReqeustRemark, undefined, new Date(), undefined, undefined, undefined, this.selectedCustomer.CompanyCode, this.selectedCustomer.ICNumber, this.selectedCustomer.Name, this.selectedCustomer.CompanyId);

      var xhr = this.restApi.postRequest(this.constructApi.getAddLoanRequest(), data)
      xhr.onreadystatechange = () => {
        if (xhr.readyState == 4) {
          this.disabledSubmit = false;
          //if success , then proceed
          if (xhr.status == 200) {

            var json = JSON.parse(xhr.responseText);
            this.request.ID = Number(json.id);


            if (this.sharedService.isBranchAccountHandler()) {
              var modalRef = this.sharedService.openModal(ShowWhatsappMessageClipboardComponent);


              modalRef.componentInstance.requestList = [this.request];
              modalRef.componentInstance.isDirty = true;
              modalRef.componentInstance.isEPFRequest = false;
              this.reset();
            }
            else {
              window.location.reload();
            }

          }
          else {
            this.disabledSubmit = false;
            //show error message
            this.sharedService.openAlert(json.error, this.enums.DANGER_ALERT)

          }
        }
      }
    }

  }



  addEPFRequest(requireElevate: boolean) {
    var settlementCharges = {
      "stamp": this.loanList[0].SettlementCharges.Stamp,
      "serviceCharge": this.loanList[0].SettlementCharges.ServiceCharge,
      "extras": this.loanList[0].SettlementCharges.Extras
    }

    var loanInfoData = {
      "principal": this.loanList[0].Principal,
      "scheme": this.loanList[0].Scheme,
      "term": this.loanList[0].Term,
      "interestRate": this.sharedService.roundToDec(this.loanList[0].InterestRate, 11),
      "stamp": this.loanList[0].StampAmount,
      "serviceCharge": this.loanList[0].ServiceCharge,
      "extras": this.loanList[0].Extras,
      "processingRate": this.loanList[0].ProcessingRate,
      "reserve": -this.loanList[0].Reserve,
      "settlementCharges": settlementCharges
    }

    var guarantorIdData: Number[] = new Array;
    var guarantorList: Contact[] = new Array;
    for (let i = 0; i < this.contactList.length; i++) {
      if (this.contactList[i].IsSelected == true) {
        guarantorIdData.push(this.contactList[i].ID);
        guarantorList.push(this.contactList[i]);
      }

    }

    var remarkStr = this.requestRemark;
    var remark = (requireElevate == true) ? remarkStr + "=" + this.elevateError + this.elevateLoanError + this.elevateSuggestedSettlement : remarkStr;
    var data = {
      "profileId": this.selectedProfile.ID,
      "loanInfo": loanInfoData,
      "remark": remark,
      "requestOn": new Date().toISOString(),
      "guarantorIds": guarantorIdData
    }

    var xhr = (requireElevate == true) ? this.restApi.postRequest(this.constructApi.getAddElevatedEPFLoanRequest(), data) : this.restApi.postRequest(this.constructApi.getAddLoanRequest(), data)
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        //if success , then proceed
        if (xhr.status == 200) {

          var json = JSON.parse(xhr.responseText);
          this.request.ID = Number(json.id);


          if (this.sharedService.isBranchAccountHandler()) {
            if (remark != undefined || remark != "")
              this.request.Remark = remark;

            var otherId = this.getFormValue('otherId')
            if (otherId != undefined)
              this.request.OtherId = otherId;

            this.request.DOB = this.selectedCustomer.DOB;

            var modalRef = this.sharedService.openModal(ShowWhatsappMessageClipboardComponent);



            modalRef.componentInstance.request = this.request;
            modalRef.componentInstance.isDirty = true;
            modalRef.componentInstance.isEPFRequest = true;
            this.reset();
          }
          else {
            window.location.reload();
          }




        }
        else {
          this.disabledSubmit = false;
          var json = JSON.parse(xhr.responseText);

          if (json.requireElevation != undefined && json.requireElevation == true) {
            this.elevateError += json.error
            this.submitEPFLoanRequest(true);
          } else
            //show error message
            this.sharedService.openAlert(json.error, this.enums.DANGER_ALERT)

        }
      }
    }
  }





  getFileExistence(type: string, index: number) {

    var xhttp = this.restApi.getRequest(this.constructApi.getCompanyCustomerTypedFileExistence(this.selectedCustomer.ID, type))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {


        var result = JSON.parse(xhttp.responseText);

        if (Boolean(result) == false)
          this.nonExistedNecessaryFileTypeList.push(this.enums.customerNecessaryDocsString[index]);

      }
    };



  }

  getCompanyInfo(companyId: number) {
    var xhttp = this.restApi.getRequest(this.constructApi.getCompanyDetails(companyId))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var json = JSON.parse(xhttp.responseText);

        sessionStorage.setItem("requireConsentForm", json.requireConsentForm);

        this.getAllFileExitence();

      }
    };
  }

  getAllFileExitence() {
    this.nonExistedNecessaryFileTypeList = [];
    var fileList;
    //for this case, CONSENT FORM is not necessary, leave for server to check
    var requireConsentForm = sessionStorage.getItem("requireConsentForm")
    if (requireConsentForm == "true")
      fileList = this.enums.customerNecessaryDocs;
    else
      fileList = this.enums.customerNecessaryDocs.slice(0, 4);

    for (let i = 0; i < fileList.length; i++) {
      this.getFileExistence(fileList[i], i);
    }
  }





  private getAllAccountsOfCompany(companyId: number) {

    var xhttp = this.restApi.getRequest(this.constructApi.getAccountsOfCompany(companyId))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let account = new Account(json.id, json.number, json.name, json.holderName, json.balance, json.companyId, json.username);

          this.companyAccountList.push(account);
        }
        );

      }
    };
  }
  setSelectedAccount(accountId: number) {

    if (accountId === this.selectedAccountId)
      return true
    else
      return false;
  }

  async updateProfile() {
    var data = {
      "occupation": this.selectedProfile.Occupation,
      "salary": this.selectedProfile.Salary,
      "salaryDay": this.selectedProfile.SalaryDay,
      "remark": this.selectedProfile.Remark,
      "dateCreated": this.selectedProfile.DateCreated,
      "customerID": this.selectedCustomer.ID,
      "customerCode": this.selectedProfile.CustomerCode
    }
    var xhr = this.restApi.putRequest(this.constructApi.getUpdateProfile(this.selectedProfile.ID), data);

    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        var json = JSON.parse(xhr.responseText);
        // var customer = new Customer(json.id, json.name, json.occupation, json.salary, json.salaryDay, json.icNumber, json.contactNo, json.remark, json.ctosNumber, json.ctosStatus, json.status, json.dateCreated, json.handler, json.companyId, json.customerCode);

        var profile = new Profile(json.id, json.occupation, json.salary, json.salaryDay, json.remark, undefined, json.dateCreated, json.customerID, json.customerCode)
        this.selectedProfile = profile;

        this.sharedService.openAlert("Successfully updated customer profile salary details", this.enums.SUCCESS_ALERT)
      }
    }
  }


  addNewLoanObj() {
    if (this.withAnotherLoan == true)
      this.loanList[1] = undefined;
    else
      this.loanList.splice(1, 1);

  }

  async updateSalaryDetailsAndTaskDate() {


    if (this.isEPF == true && this.epfDetails.Date == undefined)
      this.sharedService.openAlert("Please enter a valid EPF SETTLEMENT DATE!", this.enums.DANGER_ALERT);
    else {
      if (this.selectedProfile.SalaryDay <= 31 && this.selectedProfile.SalaryDay >= 1) {
        await this.updateProfile();
        this.calculateNextTaskDate();
      }
      else {
        this.sharedService.openAlert("Please enter a valid day of month! (1-31)", this.enums.DANGER_ALERT);
      }
    }

  }

  confirmBeforeAddLoan() {
    var errorMessage = "";

    for (let i = 0; i < this.loanList.length; i++) {
      var stampNull;
      var scNull;
      var extraNull;
      if (this.loanList[i].Scheme != 'HUTANG') {
        stampNull = this.loanList[i].StampAmount == 0;
        scNull = this.loanList[i].ServiceCharge == 0
        extraNull = this.loanList[i].Extras.length == 0;

        if (stampNull == true || scNull == true || extraNull == true) {
          errorMessage += "Your Loan " + Number(i + 1) + "'s STAMP / SERVICE CHARGE / DUIT LEBIH=0\n";
        }
      }

    }
    if (errorMessage != "") {
      errorMessage += "\n Are you sure?"
      const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);

      modalRef.componentInstance.message = errorMessage;

      //get confirmation
      modalRef.componentInstance.passEntry.subscribe((entry) => {
        var confirmation = entry;

        // use the confirmation to do something
        if (confirmation == true)
          this.sendOrAddLoan();
      });
    }
    else
      this.sendOrAddLoan();
  }


  isBalanceInsufficient(balance: number) {
    if (this.calculateTotalOnHand() > balance) {
      return true;
    }
    else
      return false;
  }

  checkLoanNull() {
    for (let i = 0; i < this.loanList.length; i++) {
      if (this.loanList[i] == undefined)
        return true;
    }
    return false;
  }


  roundTo2Dec(num: number) {
    return Math.round(num * 100) / 100
  }


  checkEPFAmountLimit() {
    var epfDate = this.getFormValue('epfDate');
    var epfAmount = this.getFormValue('epfAmount');
    var string = "";

    //only restrict to NEW customer: NEW CUSTOMER that epf amount which is less than MINIMUM EPF AMOUNT of each category should be stopped
    // epf amount limit category can be found in enums
    if (this.isEPFExtra == false) {
      var epfAmountLimitCategory = this.sharedService.checkEPFAmountLimitCategory(new Date(epfDate));
      if (epfAmount < this.enums.epfAmountCategory[epfAmountLimitCategory].MinimumEPFAmount) {
        if (this.isManualElevate == false) {
          string += "\n\n - TERM " + this.enums.epfAmountCategory[epfAmountLimitCategory].LowerLimit + "-" + this.enums.epfAmountCategory[epfAmountLimitCategory].UpperLimit +
            " months AND EPF Amount less than RM " + this.sharedService.roundTo2Dec(this.enums.epfAmountCategory[epfAmountLimitCategory].MinimumEPFAmount) + " is not allowed";
        }
        else
          this.elevateError += "\n\n - TERM " + this.enums.epfAmountCategory[epfAmountLimitCategory].LowerLimit + "-" + this.enums.epfAmountCategory[epfAmountLimitCategory].UpperLimit +
            " months AND EPF Amount less than RM " + this.sharedService.roundTo2Dec(this.enums.epfAmountCategory[epfAmountLimitCategory].MinimumEPFAmount) + " is not allowed";

      }
    }
    return string;
  }



  checkNull() {
    var string = "";
    this.elevateLoanError = "";
    for (let i = 0; i < this.loanList.length; i++) {
      var interestRateNull = (this.loanList[i].Scheme == "HUTANG") ?
        this.loanList[i].InterestRate == undefined : this.loanList[i].InterestRate == undefined || this.loanList[i].InterestRate <= 0;
      if (this.loanList[i].Principal == undefined || this.loanList[i].Principal === 0)
        string += "\n- Principal";
      else if (this.loanList[i].Scheme == undefined)
        string += "\n- Skim";
      else if (this.loanList[i].Term == undefined || this.loanList[i].Term === 0 || this.loanList[i].Term > 48)
        string += "\n- Term";
      else if (interestRateNull)
        string += "\n- Interest Rate";
      else if (this.loanList[i].Scheme == 'EPF' && this.isEPFExtra == false) {

        
        if (this.loanList[i].Term <= 3) {
          if (this.roundTo2Dec(this.loanList[i].InterestRate) < (this.enums.MINIMUM_EXTRA_EPF_RATE * 100)) {
            if (this.isManualElevate == true)
              this.elevateLoanError += "\n- Interest Rate less than" + (this.enums.MINIMUM_EXTRA_EPF_RATE * 100) + "% for loan less than 3 months";
            else
              string += "\n- Interest Rate less than " + (this.enums.MINIMUM_EXTRA_EPF_RATE * 100) + "% for loan less than 3 months";
          }

        }
        else {
          if (this.roundTo2Dec(this.loanList[i].InterestRate) < (this.enums.MINIMUM_NEW_EPF_RATE * 100))
            string += "\n- Interest Rate less than" + (this.enums.MINIMUM_NEW_EPF_RATE * 100) + "%";
        }
      }
      else if (this.loanList[i].Scheme == 'EPF' && this.isEPFExtra == true && this.roundTo2Dec(this.loanList[i].InterestRate) < (this.enums.MINIMUM_EXTRA_EPF_RATE * 100))
        string += "\n- EPF EXTRA - Interest Rate less than " + (this.enums.MINIMUM_EXTRA_EPF_RATE * 100) + "%";


      else if (this.loanList[i].Scheme != "HUTANG" && (this.loanList[i].InterestCharge == undefined || this.loanList[i].InterestCharge === 0))
        string += "\n- Interest Charge";
      else if (this.selectedAccountId == undefined && this.loanList[i].Scheme != 'EPF' && this.isOverlap == false && this.needRestrictedRequest == false)
        string += "\n- Issue Account";
      else if (this.selectedProfile.Salary == undefined || this.selectedProfile.Salary == 0)
        string += "\n- Salary"
      else if (this.selectedProfile.SalaryDay == undefined || this.selectedProfile.SalaryDay == 0)
        string += "\n- Salary Day"
      else if (this.isEPF == true && this.epfDetails.Date == undefined && (this.epfFormGroup.get('epfDate') != undefined && this.getFormValue('epfDate') == undefined))
        string += "\n- EPF Date"



      if (this.isEPF == true && this.loanList[i].Extras.length == 0 || (this.isEPF == true && this.calculateAdsDuitLebihTotal() < this.loanList[i].Principal * this.enums.MINIMUM_ADS_RATE))
        string += "\n- Please at least key in 2% in duit lebih for ADS"


      if (this.isEPF == true) {

        //settlement charges empty check
        if (this.loanList[i].SettlementCharges == undefined) {
          string += "\n - Settlement Charges cannnot leave empty!"
        }
        else {
          if (this.sharedService.checkIsWholeNumber(this.loanList[i].SettlementCharges.Stamp) == false)
            string += "\n - Settlement Stamp cannot have cents!";
          if (this.sharedService.checkIsWholeNumber(this.loanList[i].SettlementCharges.ServiceCharge) == false)
            string += "\n - Settlement SC cannot have cents!";
          if (this.sharedService.checkIsWholeNumber(this.loanList[i].SettlementCharges.Extras) == false)
            string += "\n - Settlement DL cannot have cents!";
          if (this.loanList[i].SettlementCharges.HandlingCharge != undefined && this.sharedService.checkIsWholeNumber(this.loanList[i].SettlementCharges.HandlingCharge) == false)
            string += "\n - Handling Charge cannot have cents!";
        }
      }

      //STAMP LIMIT
      //IF not EPF SCHEME, make sure STAMP is <= than MAXIMUM STAMP LIMIT
      if (this.loanList[i].StampAmount == undefined) {
        string += "\n- STAMP cannnot leave empty!";
      }
      else {
        if (this.isEPF == false && this.loanList[i].StampAmount > this.enums.MAXIMUM_STAMP_NORMAL_SCHEME)
          string += "\n- Please make sure your STAMP not more than RM " + this.enums.MAXIMUM_STAMP_NORMAL_SCHEME;
      }


      //SC LIMIT
      //IF not EPF SCHEME, make sure SC is <= than MAXIMUM SC LIMIT
      if (this.loanList[i].ServiceCharge == undefined) {
        string += "\n- SC cannnot leave empty!";
      }
      else {
        if (this.isEPF == false && this.loanList[i].ServiceCharge > this.enums.MAXIMUM_SC_NORMAL_SCHEME)
          string += "\n- Please make sure your SC not more than RM " + this.enums.MAXIMUM_SC_NORMAL_SCHEME;
      }

      if (string != "")
        return string;
      else
        continue;
    }
    return string;
  }

  calculateAdsDuitLebihTotal() {
    var total = 0;
    for (let i = 0; i < this.loanList.length; i++) {
      if (this.loanList[i] != undefined) {
        if (this.loanList[i].Extras.length > 0) {
          for (let j = 0; j < this.loanList[i].Extras.length; j++) {
            if (this.loanList[i].Extras[j].Subtype == this.enums.ADS_SUBTYPE_ID)
              total += this.loanList[i].Extras[j].Amount;
          }
        }
      }
    }
    return total;
  }


  getContactInfo() {
    var index = 0;
    var xhttp = this.restApi.getRequest(this.constructApi.getCustomerContact(this.selectedCustomer.ID));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));
        // console.log(jsonArray);

        jsonArray.forEach(json => {

          let contact = new Contact(json.id, json.name, json.icNumber, json.number, json.address, json.customerId, json.relation, json.remark, false);
          //add customer instance into customer list
          this.contactList.push(contact);
          this.getCustomerContactPhotoExisted(contact, index)
          index++;

        }
        );
      }
    };

  }



  getCustomerContactPhotoExisted(contact: Contact, index: number) {

    var xhttp = this.restApi.getRequest(this.constructApi.getCustomerContactPhotoExistence(this.selectedCustomer.ID, contact.ID));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var result = Boolean(JSON.parse((xhttp.responseText)));
        this.contactList[index].PhotoExisted = result
      }
    };

  }


  checkGuarantorValidity() {
    var string = "";
    if (this.contactList.length == 0 || this.contactList == undefined)
      string += "Please at least add a guarantor!";
    else {
      var selectedContact = this.contactList.filter((contact: Contact) => (contact.IsSelected == true));
      if (selectedContact.length == 0 || selectedContact == undefined) {
        string += "Please at least add a guarantor!";
      }

      else {
        var selectedContactWithoutPicture = selectedContact.filter((contact: Contact) => (contact.PhotoExisted == false || contact.PhotoExisted == undefined));
        if (selectedContactWithoutPicture.length > 0)
          string += "Please make sure all your guarantors contains picture!";
      }
    }

    return string
  }

  validateCheckGurantorValidity() {
    var string = this.checkGuarantorValidity();
    if (string == "")
      this.constructReqeust();
    else {
      this.sharedService.openAlert(string, this.enums.DANGER_ALERT);
    }
  }

  isEPFGurantorStepCompleted() {
    if (this.checkGuarantorValidity() != "")
      return false;
    else return true;
  }

  isEPFLoanStepCompleted() {
    if (this.loanList.length > 0) {
      // if the loan is filled in with details
      //check again if the epf amount is a valid amount 
      //if yes then only continue to check loan's limit to make sure can find the correct loan limit category
      if (this.checkEPFAmountLimit() != undefined && this.checkEPFAmountLimit() == '') {
        if (this.checkNull() != undefined && this.checkNull() == '')
          return true;
        else
          return false;
      }

    }

    //if there's no loan details, return false= not completed
    else
      return false;
  }

  validateEPFLoan() {
    var string = this.checkNull()

    if (string != '')
      return string;
    else return '';
    // this.sharedService.openAlert("Please fill in all the necessary fields CORRECTLY" + string, this.enums.DANGER_ALERT);
  }



  checkIfManualEPFDateIsValid() {
    if (this.epfDetails.Date != undefined && this.selectedCustomer.DOB != undefined) {
      var calculatedEPFDate = this.sharedService.calculateEPFDate(new Date(this.selectedCustomer.DOB));
      var formattedEPFDate = this.datepipe.transform(new Date(this.epfDetails.Date), "yyyy-MM-dd");
      var formattedCalculateEPFDate = this.datepipe.transform(new Date(calculatedEPFDate), "yyyy-MM-dd");
      if (formattedEPFDate == formattedCalculateEPFDate)
        return true;
      else
        return false;
    }
  }

  sendOrAddLoan() {
    if (this.isOverlap == true) {
      this.passEntry.emit(this.loanList);
      this.reset();
    }
    else
      this.addLoan();
  }


  addLoan() {

    this.disabledSubmit = true;
    var dataArr = new Array;

    for (let i = 0; i < this.loanList.length; i++) {
      var data = {
        "loanCode": this.loanList[i].LoanCode,
        "principal": this.loanList[i].Principal,
        "profileID": this.selectedProfile.ID,
        "dateCreated": this.loanList[i].DateCreated.toISOString(),
        "scheme": this.loanList[i].Scheme,
        "term": this.loanList[i].Term,
        "interestRate": this.sharedService.roundToDec(this.loanList[i].InterestRate, 11),
        "stamp": this.loanList[i].StampAmount,
        "serviceCharge": this.loanList[i].ServiceCharge,
        "extras": this.loanList[i].Extras,
        "processingRate": this.loanList[i].ProcessingRate,
        "accountId": Number(this.selectedAccountId),
        "reserve": -this.loanList[i].Reserve
      }
      dataArr.push(data)
    }

    var xhr = this.restApi.postRequest(this.constructApi.getAddLoans(), dataArr)
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        //if success , then proceed
        if (xhr.status == 200) {
          var jsonArray = JSON.parse(xhr.responseText);

          jsonArray.forEach(json => {
            var loan = new Loan(json.id, json.profileId, json.principal, json.stampAmount, json.receiptNumber,
              json.status, json.dateted, json.companyId, json.companyName,
              json.scheme, json.interestRate, json.term, json.customerName,
              json.icNumber, json.interestCharge, json.issuer, json.loanCode, json.arrear, json.principalPaid,
              json.repayment, json.reserve)

            //if successfully added loan, need to assign commission
            if (this.loggedInRole == this.enums.BRANCH_MANAGER)
              this.openAssignCommissionModal(loan);

            this.passEntry.emit(loan);
          }
          );
          this.reset();
        }
        else {
          //show error message
          this.sharedService.openAlert(JSON.parse(xhr.responseText).error, this.enums.DANGER_ALERT)

        }
      }
    }
  }

  submit() {
    var usedReserve = 0;
    for (let i = 0; i < this.loanList.length; i++) {
      usedReserve += this.loanList[i].Reserve;
    }

    if (this.checkLoanNull()) {
      this.sharedService.openAlert("WARNING! You left a loan empty", this.enums.DANGER_ALERT);
    }
    else if (usedReserve > this.selectedProfile.Reserve) {
      this.sharedService.openAlert("WARNING! You used more than your available simpan", this.enums.DANGER_ALERT);
    }
    else if (this.nonExistedNecessaryFileTypeList.length > 0) {
      var message = "";
      for (let i = 0; i < this.nonExistedNecessaryFileTypeList.length; i++) {
        message += "- " + this.nonExistedNecessaryFileTypeList[i] + "\n";
      }
      this.sharedService.openAlert("WARNING! Please add: \n" + message, this.enums.DANGER_ALERT);
    }
    else {
      var string = this.checkNull()
      if (string == "") {
        this.confirmBeforeAddLoan();
      }
      else
        this.sharedService.openAlert("Please fill in all the necessary fields CORRECTLY" + string, this.enums.DANGER_ALERT);
    }

  }

  openAssignCommissionModal(loan: Loan) {
    const modalRef = this.sharedService.openModal(AddCommissionsComponent);
    modalRef.componentInstance.selectedLoan = loan;
  }

}
