import { ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { AddAdminRemarkComponent } from 'src/app/AddThings/add-admin-remark/add-admin-remark.component';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { AdminRemark } from 'src/model/customerAdminRemark';

@Component({
  selector: 'app-customer-admin-remark',
  templateUrl: './customer-admin-remark.component.html',
  styleUrls: ['./customer-admin-remark.component.css']
})
export class CustomerAdminRemarkComponent implements OnInit, OnChanges {

  @Input() viewCustomerId: number;
  customerAdminRemarkList: AdminRemark[] = new Array;

  constructor(private restapi: RestApiService, private constructAPI: ConstructAPI, private sharedService: SharedService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if (this.customerAdminRemarkList.length > 0)
      this.customerAdminRemarkList.splice(0, this.customerAdminRemarkList.length);
    this.getCustomerAdminRemarks();
  }

  getCustomerAdminRemarks() {
    var xhttp = this.restapi.getRequest(this.constructAPI.getCustomerAdminRemarks(this.viewCustomerId));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          var remark = new AdminRemark(json.id, json.author, json.customerId, new Date(json.date), json.remark, json.needAttention);

          this.customerAdminRemarkList.push(remark);
        }
        );

        this.customerAdminRemarkList.sort((a, b) => (a.ID > b.ID) ? -1 : 1);
      }
    };
  }


  changeBackground(needAttention: boolean) {

    if (needAttention == true)
      return { 'background-color': 'salmon' };
  }

  openAddCustomerRemarkModal() {
    const modalRef = this.sharedService.openModal(AddAdminRemarkComponent);
    modalRef.componentInstance.viewCustomerId = this.viewCustomerId;

    //get added loan
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var remark = entry;

      //append added loan to loan list
      this.customerAdminRemarkList.push(remark);
      this.customerAdminRemarkList.sort((a, b) => (a.ID > b.ID) ? -1 : 1);
    });

    modalRef.result.then(() => {

      //detect changes
      this.cdr.detectChanges();
    })

  }
}
