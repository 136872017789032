import { Component, OnInit, Input, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { Customer } from 'src/model/customer.model';
import { Loan } from 'src/model/loan.model';
import { AddLoanComponent } from '../../AddThings/add-loan/add-loan.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoanDetailsComponent } from 'src/app/Loans/loan-details/loan-details.component';
import { RestApiService } from 'src/app/API/restapi';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { Enums } from 'src/app/Shared/enums';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Commission } from 'src/model/commission.model';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { BatchPaymentComponent } from 'src/app/LoanPayment/batch-payment/batch-payment.component';
import { Profile } from 'src/model/profile.model';
import { EPF } from 'src/model/epf.model';
import { MarkLegacyEpfsComponent } from 'src/app/Loans/mark-legacy-epfs/mark-legacy-epfs.component';
import { LegacyEpfSettlementComponent } from 'src/app/Loans/legacy-epf-settlement/legacy-epf-settlement.component';
import { AddHutangRequestComponent } from 'src/app/AddThings/add-hutang-request/add-hutang-request.component';
import { SettlementCharges } from 'src/model/settlementCharges.model';
import { SharedLoanComponent } from 'src/app/SharedLoan/shared-loan/shared-loan.component';
import { AddReserveGroupComponent } from 'src/app/Reserve/add-reserve-group/add-reserve-group.component';
import { SharedLoanListComponent } from 'src/app/SharedLoan/shared-loan-list/shared-loan-list.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-customer-loan-card',
  templateUrl: './customer-loan-card.component.html',
  styleUrls: ['./customer-loan-card.component.css']
})
export class CustomerLoanCardComponent implements OnInit, OnChanges {

  @Input() viewCustomer: Customer;
  @Input() selectedProfile: Profile;
  @Input() epfDetails: EPF;
  @Input() isModal: boolean = false;

  commissionList: Commission[] = new Array;
  customerLoanList: Loan[] = new Array;
  totalCustomerLoanList: Loan[] = new Array;
  isHidingSettled: boolean = false;

  sharedLoanList: Loan[] = new Array;

  constructor(private activeModal: NgbActiveModal, private cdr: ChangeDetectorRef, private restapi: RestApiService, private constructApi: ConstructAPI,
    private enums: Enums, public sharedService: SharedService, private router: Router) {

  }

  ngOnInit() {
    if (this.isModal == true)
      this.getCustomerLoanInfo();

  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.selectedProfile) {
      this.getCustomerLoanInfo();
    }
  }


  openBatchPaymentModal() {
    var activeLoan = this.totalCustomerLoanList.filter((loan: Loan) => (loan.Status != this.enums.SETTLED_LOAN));
    const modalRef = this.sharedService.openModal(BatchPaymentComponent, "largeModal");
    modalRef.componentInstance.loanList = activeLoan;
    modalRef.componentInstance.selectedCustomer = this.viewCustomer;
    modalRef.componentInstance.selectedProfile = this.selectedProfile;
    modalRef.componentInstance.sharedProfileId = this.selectedProfile.SharedProfileId;
  }


  openAddReserveGroup() {
    const modalRef = this.sharedService.openModal(AddReserveGroupComponent, "largeModal");
    modalRef.componentInstance.sharedProfileId = this.selectedProfile.SharedProfileId;
    modalRef.componentInstance.selectedProfileId = this.selectedProfile.ID;
    var activeLoan = this.totalCustomerLoanList.filter((loan: Loan) => (loan.Status != this.enums.SETTLED_LOAN));
    modalRef.componentInstance.loanList = activeLoan;
    modalRef.componentInstance.selectedCustomer = this.viewCustomer;
    modalRef.componentInstance.selectedProfile = this.selectedProfile;

  }

  // checkIfNeedDoReserveGroup() {
  //   var activeEPFLoans = this.totalCustomerLoanList.filter((loan: Loan) => (loan.Status != this.enums.SETTLED_LOAN && loan.Scheme == "EPF"));
  //   // console.log(activeEPFLoans)

  //   //if this is an EPF shared profile's loan
  //   if (activeEPFLoans.length > 0 && this.selectedProfile.SharedProfileId != undefined)
  //     this.openAddReserveGroup();
  //   else
  //     this.openBatchPaymentModal();

  // }



  hideSettledLoan() {

    this.isHidingSettled = !this.isHidingSettled;
    if (this.isHidingSettled == true) {
      this.customerLoanList = this.totalCustomerLoanList.filter((loan: Loan) => (loan.Status != this.enums.SETTLED_LOAN));
    }
    else
      this.customerLoanList = this.totalCustomerLoanList;

  }


  checkIfLegacySettlementCompleted() {
    var legacyNoSettlementLoanList = this.customerLoanList.filter((loan: Loan) => (loan.IsEPF == true && loan.LegacySettlement == undefined));

    if (legacyNoSettlementLoanList.length > 0) {
      const modalRef = this.sharedService.openModal(LegacyEpfSettlementComponent, "regularModal");
      modalRef.componentInstance.loanList = legacyNoSettlementLoanList;

      //if completed
      modalRef.componentInstance.passEntry.subscribe((entry) => {
        var success = entry;

        //append added loan to loan list
        if (success)
          this.openAddLoanModal();
      });

    }
    else
      this.openAddLoanModal();

  }

  openHutangLoanModal() {
    const modalRef = this.sharedService.openModal(AddHutangRequestComponent, "largeModal");
    modalRef.componentInstance.selectedCustomer = this.viewCustomer;
    modalRef.componentInstance.selectedProfile = this.selectedProfile;
  }

  openAddSharedLoanModal() {
    const modalRef = this.sharedService.openModal(SharedLoanComponent, "regularModal");
    modalRef.componentInstance.selectedCustomer = this.viewCustomer;
    modalRef.componentInstance.selectedProfile = this.selectedProfile;

    if (this.epfDetails != undefined)
      modalRef.componentInstance.epfDetails = this.epfDetails;
    else {
      var epf = new EPF(undefined, undefined, undefined, undefined, undefined, undefined, undefined)
      modalRef.componentInstance.epfDetails = epf;
    }

    modalRef.componentInstance.isEPF = true;
    //get current loans and filter EPF loans to check if it's an extra customer
    var filtered = this.customerLoanList.filter((loan: Loan) => ((loan.Scheme == 'EPF' || loan.IsEPF == true) && (loan.Status != this.enums.SETTLED_LOAN || loan.Status != this.enums.CP_LOAN)));

    //if is main shared company need to add shared loan, need to add the previous loans of others shared companies
    filtered = filtered.concat(this.sharedLoanList);
    modalRef.componentInstance.isEPFExtra = (filtered != undefined && filtered.length > 0) ? true : false;
    modalRef.componentInstance.previousEPFLoans = filtered;
    modalRef.componentInstance.selectedProfile = this.selectedProfile;


    modalRef.componentInstance
    modalRef.componentInstance.needRestrictedRequest = this.sharedService.checkIsSeniorRestrictedCustomer(new Date(this.viewCustomer.DOB));
    modalRef.componentInstance.allowEPF = this.sharedService.checkIsAllowEPF(this.viewCustomer.DOB, this.epfDetails, this.selectedProfile.ID, this.selectedProfile.SharedProfileId);

  }

  openAddLoanModal() {

    const modalRef = this.sharedService.openModal(AddLoanComponent, "regularModal");
    modalRef.componentInstance.selectedCustomer = this.viewCustomer;
    modalRef.componentInstance.selectedProfile = this.selectedProfile;

    if (this.epfDetails != undefined)
      modalRef.componentInstance.epfDetails = this.epfDetails;
    else {
      var epf = new EPF(undefined, undefined, undefined, undefined, undefined, undefined, undefined)
      modalRef.componentInstance.epfDetails = epf;
    }

    //get current loans and filter EPF loans to check if it's an extra customer
    var filtered = this.customerLoanList.filter((loan: Loan) => ((loan.Scheme == 'EPF' || loan.IsEPF == true) && (loan.Status != this.enums.SETTLED_LOAN || loan.Status != this.enums.CP_LOAN)));
    modalRef.componentInstance.isEPFExtra = (filtered != undefined && filtered.length > 0) ? true : false;
    modalRef.componentInstance.previousEPFLoans = filtered;


    modalRef.componentInstance
    modalRef.componentInstance.needRestrictedRequest = this.sharedService.checkIsSeniorRestrictedCustomer(new Date(this.viewCustomer.DOB));
    // modalRef.componentInstance.allowEPF = this.sharedService.checkIsAllowEPF(this.viewCustomer.DOB, this.epfDetails, this.selectedProfile.ID, this.selectedProfile.SharedProfileId);
    //get added loan
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var loan = entry;

      //append added loan to loan list
      this.customerLoanList.push(loan);
    });

    modalRef.result.then(() => {

      //detect changes
      this.cdr.reattach();
    })

  }

  isStaff() {
    var roleId = Number(sessionStorage.getItem('roleId'));

    if (roleId == this.enums.STAFF)
      return true;
    else
      return false;
  }


  checkRestrict() {
    return this.sharedService.checkIsSeniorRestrictedCustomer(new Date(this.viewCustomer.DOB));
  }

  getEPFTotalPrincipal() {
    var total = 0;

    for (let i = 0; i < this.customerLoanList.length; i++) {
      if ((this.customerLoanList[i].IsEPF == true || this.customerLoanList[i].Scheme == 'EPF') && (this.customerLoanList[i].Status != this.enums.SETTLED_LOAN && this.customerLoanList[i].Status != this.enums.CP_LOAN))
        total += this.customerLoanList[i].Principal;
    }
    return total;
  }


  getEPFTotalSettlement() {
    var total = 0;

    for (let i = 0; i < this.customerLoanList.length; i++) {
      if (this.customerLoanList[i].Status != this.enums.SETTLED_LOAN && this.customerLoanList[i].Status != this.enums.CP_LOAN) {
        if (this.customerLoanList[i].IsEPF == true)
          total += this.customerLoanList[i].LegacySettlement;
        else if (this.customerLoanList[i].Scheme == 'EPF')

          total += this.sharedService.calculateEPFSettlementAmount(this.customerLoanList[i]) + this.customerLoanList[i].SettlementExtra + this.customerLoanList[i].SettlementServiceCharge + this.customerLoanList[i].SettlementStamp;
        // total += this.customerLoanList[i].Balance ;
      }
    }
    return total;
  }


  getCustomerLoanInfo() {

    if (this.customerLoanList.length > 0) {
      this.customerLoanList.splice(0, this.customerLoanList.length);
      this.totalCustomerLoanList.splice(0, this.totalCustomerLoanList.length)
    }

    var isManager = this.sharedService.isBranchManager();
    var isMainSharedProfile = this.sharedService.checkIsMainShareProfile(this.selectedProfile);

    var xhttp = (isManager)
      ? this.restapi.getRequest(this.constructApi.getLoanOfCustomerProfile(this.selectedProfile.ID, undefined, undefined, undefined, isMainSharedProfile))
      : this.restapi.getRequest(this.constructApi.getLoanOfCustomerProfile(this.selectedProfile.ID));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));



        jsonArray.forEach(json => {

          var settlementCharges = (json.settlementCharges.serviceCharge == undefined || json.settlementCharges.stamp == undefined) ? undefined : new SettlementCharges(json.settlementCharges.serviceCharge, json.settlementCharges.stamp, json.settlementCharges.extras, json.settlementCharges.handlingCharge)

          var monthlyPayment = this.sharedService.calculateLoanMonthlyPayment(json.scheme, json.principal, json.principalPaid, json.term, json.interestRate);

          // create customer instance with all response data
          let loan = new Loan(json.id, json.profileId, json.principal || 0, json.stampAmount, json.receiptNumber,
            json.status, json.dateCreated, json.companyId, json.companyName,
            json.scheme, json.interestRate, json.term, json.customerName,
            json.icNumber, json.extraInterest, json.issuer, json.loanCode, json.arrearCount,
            json.principalPaid, json.repayment, json.reserve, json.balance || 0, monthlyPayment, json.arrearPaid, undefined, undefined, undefined,
            json.monthlyInterest, json.discount, undefined, json.processingCharge, false, json.epf, json.settlementStamp, json.settlementServiceCharge,
            json.settlementExtras, undefined, undefined, undefined, undefined, json.legacySettlement, false, settlementCharges, json.sharedCompanies,
            json.mainCompanyId, json.mainCustomerId, json.mainCompanyCode, json.companyCode)

          if (isManager) {
            if (loan.CompanyId == this.viewCustomer.CompanyId)
              //add customer instance into customer list
              this.customerLoanList.push(loan);
            else
              this.sharedLoanList.push(loan);
          }
          else
            this.customerLoanList.push(loan);

        }
        );

        // this.customerLoanList.sort((a, b) => (a.ID < b.ID) ? 1 : -1);
        // this.totalCustomerLoanList = this.customerLoanList;

        if (this.sharedService.isStaff() == false && !this.sharedService.isFinance())
          this.getLoansWithFiles(this.viewCustomer.ID);
        else {
          this.customerLoanList.sort((a, b) => (a.ID < b.ID) ? 1 : -1);
          this.totalCustomerLoanList = this.customerLoanList;
        }
      }
    };
  }


  getLoansWithFiles(customerId: number) {

    var xhttp = this.restapi.getRequest(this.constructApi.getCustomerLoansWithFiles(customerId));

    xhttp.onreadystatechange = () => {


      if (xhttp.readyState == 4) {
        if (xhttp.status == 200) {
          var result = JSON.parse((xhttp.responseText));
          var jsonArray = result.ids;
          if (jsonArray != undefined && jsonArray != null && jsonArray.length > 0) {
            jsonArray.forEach(json => {
              for (let i = 0; i < this.customerLoanList.length; i++) {
                if (this.customerLoanList[i].ID == Number(json))
                  this.customerLoanList[i].LoanDocExisted = true;
              }
            }
            );
          }
        }

        this.customerLoanList.sort((a, b) => (a.ID < b.ID) ? 1 : -1);
        this.totalCustomerLoanList = this.customerLoanList;
        //after loading all loans, check for commission
        if (this.isStaff() == false)
          this.getCustomerPendingCommission();
      }
    };
  }




  allowSendCP(status: number) {

    var roleId = Number(sessionStorage.getItem('roleId'));

    var statusCode = this.sharedService.processStatusCode(status)
    if ((statusCode != this.enums.CP_LOAN) && this.isModal == false)
      return true
    else
      return false;


  }

  allowAddHutang() {

    var filtered = this.customerLoanList.filter(loan => { return loan.Scheme == 'EPF' || loan.IsEPF == true });
    if (filtered.length > 0)
      return false
    else
      return true;


  }

  allowAddLoan() {
    var isRetrictedSenior = this.sharedService.checkIsSeniorRestrictedCustomer(new Date(this.viewCustomer.DOB));

    return !isRetrictedSenior;
  }



  sendProfileLoansToCP() {
    var xhr = this.restapi.putRequest(this.constructApi.getSendProfileLoansToCP(this.selectedProfile.ID), {});
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        if (xhr.status == 200)
          this.refresh();
        else
          this.sharedService.openErrorMessage(xhr)
      }
    };
  }



  sendLoansToLBD() {
    var xhr = this.restapi.putRequest(this.constructApi.getSendProfileLoansToLBD(this.selectedProfile.ID));
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        if (xhr.status == 200)
          this.refresh();
        else
          this.sharedService.openErrorMessage(xhr)
      }
    };



  }



  confirmBeforeSendCP() {

    var proceed = false;
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "You sure you want to send this customer to CP?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something

      if (confirmation == true)
        this.sendProfileLoansToCP();
    });

    return proceed;

  }




  confirmBeforeSendLegal() {

    var proceed = false;
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "You sure you want to send this customer to Legal Department?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something

      if (confirmation == true)
        this.sendLoansToLBD();
    });

    return proceed;

  }

  refresh(): void {
    window.location.reload();
  }


  getIfEarning(earning: number) {

    if (earning > 0)
      return "bg-success";
    else
      return "bg-danger";
  }

  calculateTotalEarning() {
    var totalEarning = 0;
    for (let i = 0; i < this.totalCustomerLoanList.length; i++) {

      if (this.totalCustomerLoanList[i].Scheme == "A") {
        totalEarning += this.totalCustomerLoanList[i].PrincipalPaid + this.totalCustomerLoanList[i].InterestCharge + this.totalCustomerLoanList[i].MonthlyInterest +
          this.totalCustomerLoanList[i].ArrearPaid + this.totalCustomerLoanList[i].ProcessingCharge - this.totalCustomerLoanList[i].Principal + this.totalCustomerLoanList[i].Discount;
      }
      else {
        totalEarning += this.totalCustomerLoanList[i].Repayment + this.totalCustomerLoanList[i].InterestCharge + this.totalCustomerLoanList[i].MonthlyInterest +
          this.totalCustomerLoanList[i].ArrearPaid + this.totalCustomerLoanList[i].ProcessingCharge - this.totalCustomerLoanList[i].Principal + this.totalCustomerLoanList[i].Discount;
      }

    }

    return totalEarning;
  }

  // calculateTotalSettlementBalance() {
  //   var totalSettlement = 0;
  //   for (let i = 0; i < this.totalCustomerLoanList.length; i++) {

  //     if (this.totalCustomerLoanList[i].Status != this.enums.SETTLED_LOAN) {
  //       if (this.totalCustomerLoanList[i].Scheme == "A") {
  //         //if is LEGACY A EPF 
  //         if (this.totalCustomerLoanList[i].IsEPF == true)
  //           totalSettlement += (this.totalCustomerLoanList[i].LegacySettlement || 0) - this.totalCustomerLoanList[i].PrincipalPaid;

  //         //if is normal A
  //         else
  //           totalSettlement += this.totalCustomerLoanList[i].Principal - this.totalCustomerLoanList[i].PrincipalPaid;
  //       }
  //       else {
  //         //if settlement charges != undefined, this case only can be true when it's epf loan 
  //         // add principal + interest + all left settlement charges - Repayment made
  //         if (this.totalCustomerLoanList[i].SettlementCharges != undefined && this.totalCustomerLoanList[i].SettlementCharges.Stamp != undefined)
  //           totalSettlement += (this.totalCustomerLoanList[i].Principal + (this.totalCustomerLoanList[i].Principal * (this.totalCustomerLoanList[i].InterestRate / 100) * this.totalCustomerLoanList[i].Term))
  //             + ((this.totalCustomerLoanList[i].SettlementCharges.Extras || 0) + (this.totalCustomerLoanList[i].SettlementCharges.Stamp || 0) + (this.totalCustomerLoanList[i].SettlementCharges.ServiceCharge || 0) + (this.totalCustomerLoanList[i].SettlementCharges.HandlingCharge || 0))
  //             - this.totalCustomerLoanList[i].Repayment

  //         //if there's no settlement charges, just add on all the principal + interest + legacy defined settlement charges values - Repayment made
  //         else
  //           totalSettlement += (this.totalCustomerLoanList[i].Principal + (this.totalCustomerLoanList[i].Principal * (this.totalCustomerLoanList[i].InterestRate / 100) * this.totalCustomerLoanList[i].Term))
  //             + ((this.totalCustomerLoanList[i].SettlementExtra || 0) + (this.totalCustomerLoanList[i].SettlementStamp || 0) + (this.totalCustomerLoanList[i].SettlementServiceCharge || 0))
  //             - this.totalCustomerLoanList[i].Repayment


  //       }
  //     }

  //   }

  //   return totalSettlement;
  // }

  calculateTotalSettlement() {
    var totalSettlement = 0;
    for (let i = 0; i < this.totalCustomerLoanList.length; i++) {


      if (this.totalCustomerLoanList[i].Scheme == "A") {
        //if is LEGACY A EPF 
        if (this.totalCustomerLoanList[i].IsEPF == true)
          totalSettlement += (this.totalCustomerLoanList[i].LegacySettlement || 0);

        //if is normal A
        else
          totalSettlement += this.totalCustomerLoanList[i].Principal;
      }
      else if (this.totalCustomerLoanList[i].Scheme == "HUTANG") {
        totalSettlement += this.totalCustomerLoanList[i].Principal;
      }
      else {
        //if settlement charges != undefined, this case only can be true when it's epf loan 
        // add principal + interest + all settlement charges left- Repayment made
        if (this.totalCustomerLoanList[i].SettlementCharges != undefined && this.totalCustomerLoanList[i].SettlementCharges.Stamp != undefined)
          totalSettlement += (this.totalCustomerLoanList[i].Principal + (this.totalCustomerLoanList[i].Principal * (this.totalCustomerLoanList[i].InterestRate / 100) * this.totalCustomerLoanList[i].Term))
            + ((this.totalCustomerLoanList[i].SettlementCharges.Extras || 0) + (this.totalCustomerLoanList[i].SettlementCharges.Stamp || 0) + (this.totalCustomerLoanList[i].SettlementCharges.ServiceCharge || 0) + (this.totalCustomerLoanList[i].SettlementCharges.HandlingCharge || 0))


        //if there's no settlement charges, just add on all the principal + interest + legacy defined settlement charges values - Repayment made
        else
          totalSettlement += (this.totalCustomerLoanList[i].Principal + (this.totalCustomerLoanList[i].Principal * (this.totalCustomerLoanList[i].InterestRate / 100) * this.totalCustomerLoanList[i].Term))
            + ((this.totalCustomerLoanList[i].SettlementExtra || 0) + (this.totalCustomerLoanList[i].SettlementStamp || 0) + (this.totalCustomerLoanList[i].SettlementServiceCharge || 0))

      }


    }

    return totalSettlement;
  }

  calculateTotalIncludeInterest(loan: Loan) {
    return this.sharedService.roundToDec(((loan.InterestRate / 100 * loan.Principal * loan.Term) + loan.Principal), 2);

  }
  openLoanDetailsModal(loan: Loan) {
    const modalRef = this.sharedService.openModal(LoanDetailsComponent, "largeModal");
    modalRef.componentInstance.selectedLoan = loan;
  }

  openMarkLegacyEPF() {
    var nonEpfLoanList = this.customerLoanList.filter((loan: Loan) => (loan.Scheme != 'EPF' && loan.Scheme != 'HUTANG' && loan.IsEPF != true));

    if (nonEpfLoanList.length == 0) {
      this.sharedService.openAlert("You have no valid loan can be added to EPF", this.enums.DANGER_ALERT)
    }
    else {
      const modalRef = this.sharedService.openModal(MarkLegacyEpfsComponent, "largeModal");
      modalRef.componentInstance.epfDetails = this.epfDetails;


      modalRef.componentInstance.loanList = nonEpfLoanList;

      modalRef.componentInstance.selectedCustomer = this.viewCustomer;
      modalRef.componentInstance.selectedProfileID = this.selectedProfile.ID;
    }

  }

  calculateSettlementRatio() {
    return (this.sharedService.calculateTotalSettlementBalance(this.totalCustomerLoanList) / this.epfDetails.Amount) * 100;
  }



  getCustomerPendingCommission() {
    this.commissionList.splice(0, this.commissionList.length);

    var xhttp = this.restapi.getRequest(this.constructApi.getAllPendingCommissions(this.selectedProfile.ID));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          var index = this.customerLoanList.findIndex(loan => loan.ID === json.loanId);
          if (index != undefined && index > -1)
            this.customerLoanList[index].IsPendingCommission = true;
        }
        );

        //AFTER CHECKING COMMISSION, DEFAULT HIDE SETTLED LOAN
        this.hideSettledLoan();
      }
    };

  }


  reset() {
    this.activeModal.close();
  }

  openSharedLoanListModal() {
    if (this.sharedLoanList.length == 0) {
      this.sharedService.openAlert("No shared company open loan yet", this.enums.DANGER_ALERT);
    } else {
      var modalRef = this.sharedService.openModal(SharedLoanListComponent, "largeModal");
      modalRef.componentInstance.sharedLoanList = this.sharedLoanList;
      modalRef.componentInstance.viewCustomer = this.viewCustomer;
      modalRef.componentInstance.selectedProfile = this.selectedProfile;
    }

  }


  async goToSettlementLetter() {

    // filter own epf loan and + shared loan
    var epfLoan = (this.totalCustomerLoanList.filter((loan: Loan) => ((loan.Scheme == 'EPF' || loan.IsEPF == true) && loan.Status != this.enums.SETTLED_LOAN && loan.Status != this.enums.CP_LOAN))).concat(this.sharedLoanList);

    var settlement = (epfLoan.length == 0) ? 0 : await this.sharedService.calculateTotalSettlementBalance(epfLoan);
    var simpan = (epfLoan.length == 0) ? 0 : this.selectedProfile.Reserve
    var lastExtraDate = (epfLoan.length == 0) ? this.sharedService.getTodayDate() : await new Date(Math.max.apply(null, epfLoan.map(function (e) {
      return new Date(e.DateCreated);
    })));

    this.router.navigate(['/settlementBreakdown', this.viewCustomer.CompanyId, this.viewCustomer.ID], { state: { settlement: settlement, simpan: simpan, latestDate: lastExtraDate } })


  }
}
