import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Loan } from 'src/model/loan.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoanDetailsComponent } from '../loan-details/loan-details.component';
import { RestApiService } from 'src/app/API/restapi';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { SharedService } from 'src/app/Shared/shared-service.service';

@Component({
  selector: 'app-loan-list',
  templateUrl: './loan-list.component.html',
  styleUrls: ['./loan-list.component.css']
})
export class LoanListComponent implements OnInit {

  loanList: Loan[] = new Array;
  displayedColumns: string[] = ['receiptNumber', 'customerName', 'amount', 'term', 'dateCreated', 'companyName', 'icNumber', 'status'];
  dataSource = new MatTableDataSource;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private modalService: NgbModal, private restapi: RestApiService, private constructapi: ConstructAPI, private sharedService: SharedService) { }

  ngOnInit(): void {
    this.getAllLoans();
  }

  getAllLoans() {

    // var xhttp = new XMLHttpRequest();
    // var url = "http://localhost:10080/api/v1/loans";
    // xhttp.withCredentials = true;

    var xhttp = this.restapi.getRequest(this.constructapi.getAllLoans())
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // Typical action to be performed when the document is ready:

        var jsonArray = JSON.parse((xhttp.responseText));
        // console.log(jsonArray);

        jsonArray.forEach(json => {

          // create loan instance with all response data
          let loan = new Loan(json.id, json.customerId, json.principal, json.stampAmount, json.receiptNumber,
            json.status, json.dateCreated, json.companyId, json.companyName,
            json.scheme, json.interestRate, json.term, json.customerName,
            json.icNumber, json.interestCharge, json.issuer, json.loanCode, json.arrear, json.principalPaid,
            json.repayment, json.reserve);

          //add loan instance into loan list
          this.loanList.push(loan);
        }
        );

        //make loan list as the datasource
        this.dataSource = new MatTableDataSource<Loan>(this.loanList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

      }
    };
    // xhttp.open("GET", url, true);
    // xhttp.send();

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openLoanDetailsModal(loan: Loan) {
    const modalRef = this.sharedService.openModal(LoanDetailsComponent, "largeModal");
    modalRef.componentInstance.selectedLoan = loan;
  }


}
