<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Add Task</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 aria-label="Close" (click)="reset()"></button>
    </div>

    <div class="modal-body">
        <mat-form-field class="full-width-formField" *ngIf="!isSpecificCustomer">
            <input matInput placeholder="Customer" aria-label="Customer" [matAutocomplete]="auto"
                [formControl]="customerCtrl">
            <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption>

                <mat-option *ngFor="let profile of filteredProfiles | async" [value]="profile.CustomerName"
                    (onSelectionChange)="setSelectedProfile(profile.ID,profile.CustomerName)">
                    <span>{{profile.CustomerName}}</span> |
                    <small>Occupation: {{profile.Occupation}}</small>
                </mat-option>

            </mat-autocomplete>
        </mat-form-field>

        <div>
            <mat-form-field class="full-width-formField">
                <mat-label>Task Description</mat-label>
                <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" name="taskDescription"
                    [(ngModel)]="taskDescription"></textarea>
            </mat-form-field>
        </div>

        <div class="col-12 mb-2">
            <p>Task Type</p>
            <select class="browser-default form-select" name="selectedTaskType" [(ngModel)]="selectedTaskType">
                <option *ngFor="let type of enums.taskTypeString;let i=index" [value]="i+1"
                    (change)="setSelectedTaskType(i+1)">{{type}}</option>
            </select>
        </div>

        <mat-form-field class="full-width-formField">
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="myDatepicker" name="taskDate"
                [(ngModel)]="taskDate">
            <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #myDatepicker name="taskDate"></mat-datepicker>
            <label for="taskDate"> Task Date</label>
        </mat-form-field>

        <div class="d-flex align-items-right justify-content-end">
            <button class="btn btn-success me-2" (click)="createTask()">Create</button>
            <button class="btn btn-danger">Cancel</button>
        </div>
    </div>
</form>