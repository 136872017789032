import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Customer } from 'src/model/customer.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RestApiService } from 'src/app/API/restapi';
import { APIAddress } from 'src/app/API/apiAddresses';
import { DataService } from 'src/app/Shared/data.service';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Router } from '@angular/router';
import { Enums } from 'src/app/Shared/enums';
import { Company } from 'src/model/company.model';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { User } from 'src/model/user.model';
import { DatePipe } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.css'],
  providers: [DatePipe]
})
export class AddCustomerComponent implements OnInit {
  today: any;
  public imagePath;
  imgURL: any;
  imageUrls: Array<string>;



  customerName: string;
  icNumber: string;
  contactNo: string;
  custOccupation: string;
  salaryAmount: number;
  salaryDay: number;
  remark: string;
  customerCode: string;
  address: string;
  address2: string;
  dob: Date;
  otherId: string;

  gender: string;
  race: string;

  customerNameNull = false;
  icNumberNull = false;
  contactNoNull = false;
  custOccupationNull = false;
  salaryAmountNull = false;
  salaryDayNull = false;
  remarkNull = false;
  addressNull = false;
  genderNull = false;
  raceNull = false;


  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  disabledSubmit = false;


  selectedCompany: number;
  selectedCompanyCode: string;
  isCPCustomer: boolean = false;
  companyList: Company[] = new Array;
  userList: User[] = new Array;
  selectedUsername: string;


  myControl = new UntypedFormControl();
  filteredCompany: Observable<Company[]>;

  constructor(private router: Router, private activeModal: NgbActiveModal, private restapi: RestApiService, private consctructApi: ConstructAPI,
    private dataService: DataService, public sharedService: SharedService, public enums: Enums, private datepipe: DatePipe) {

    //filter company
    this.filteredCompany = this.myControl.valueChanges.pipe(
      startWith(''),
      map(companyCode => this._filterCompanyCodeOptions(companyCode))
    );
  }

  ngOnInit() {

    if (this.sharedService.isBossAccount())
      this.getAllCompanies();
  }


  getToday() {
    this.today = new Date().toISOString().substr(0, 10);
    return this.today;
  }

  private _filterCompanyCodeOptions(value: string): Company[] {
    const filterValue = value.toLowerCase();


    // return this.companyList.filter(company => company.CompanyCode.toLowerCase().includes(filterValue));
    // var filtered = this.companyList.filter(company => { return company.CompanyCode.toLowerCase().indexOf(filterValue) === 0 });
    var filtered = this.companyList.filter(company => { return company.CompanyCode.toLowerCase().includes(filterValue) });

    return filtered;
  }


  getAllCompanies() {
    //clear company list
    this.companyList.splice(0, this.companyList.length);

    var xhttp = this.restapi.getRequest(this.consctructApi.getAllCompanies())
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));


        jsonArray.forEach(json => {
          let company = new Company(json.id, json.name, json.address, json.companyCode, json.contactNo, undefined, json.group, new Date(json.dateOperating));
          this.companyList.push(company);
        }
        );
        // this.outputToParent();

        //set the selected company code
        if (this.selectedCompany != undefined)
          this.setSelectedCompanyCode(this.selectedCompany)
      }
    };
  }

  getAllUsersOfCompany(companyId: number) {
    //clear company list
    this.companyList.splice(0, this.companyList.length);

    var xhttp = this.restapi.getRequest(this.consctructApi.getAllUsers(companyId))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));


        jsonArray.forEach(json => {
          let user = new User(json.id, json.companyId, json.companyName, json.roleId, json.roleName, json.username, undefined, undefined)
          this.userList.push(user);
        }
        );
      }
    };
  }


  setSelectedCompanyCode(companyId: number) {
    var index = this.companyList.findIndex(company => Number(company.ID) == Number(companyId));
    this.selectedCompanyCode = this.companyList[index].CompanyCode;
  }

  setSelectedCompany(code: string) {

    var index = this.companyList.findIndex(company => company.CompanyCode.toLowerCase() == code.toLowerCase());
    this.selectedCompany = this.companyList[index].ID;

    this.getAllUsersOfCompany(this.companyList[index].ID);
  }




  getCheckCustomerExisted(ic: string, code: string) {
    var xhttp = this.restapi.getRequest(this.consctructApi.getCheckCustomerExistence(ic, code))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4) {
        if (xhttp.status == 200) {
          var json = JSON.parse((xhttp.responseText));

          this.confirmBeforeGoToCustomer(json.id, json.customerId, ic, code);
        }
        else {
          this.sharedService.openErrorMessage(xhttp);
        }
      }
    };

  }


  confirmBeforeGoToCustomer(id: string, customerId: string, ic: string, code: string) {
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Customer with IC NUMBER : " + ic + " and CODE: " + code + " already existed in system. You want to go to the profile?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something

      if (confirmation == true) {
        this.router.navigate(['/manageCustomer', customerId, id]);
        this.activeModal.close();
      }
    });

  }

  validField(field: string) {
    switch (field) {
      case 'customerName': this.customerNameNull = (this.customerName === ''); break;
      case 'icNumber': this.icNumberNull = (this.icNumber === ''); break;
      case 'contactNo': this.contactNoNull = (this.contactNo === ''); break;
      case 'custOccupation': this.custOccupationNull = (this.custOccupation === ''); break;
      case 'salaryAmount': this.salaryAmountNull = (this.salaryAmount === 0); break;
      case 'salaryDay': this.salaryDayNull = (this.salaryDay === 0); break;
      case 'remark': this.remarkNull = (this.remark === ''); break;
      case 'address': this.addressNull = (this.address === ''); break;
      case 'dob': this.dob == undefined; break;
    }
  }

  validFields() {
    this.customerNameNull = (this.customerName == undefined || this.customerName === '');
    this.icNumberNull = (this.icNumber == undefined || this.icNumber === '');
    this.remarkNull = (this.remark == undefined || this.remark === '');
    var customerCodeNull = (this.customerCode == undefined || this.customerCode === '');


    this.contactNoNull = (this.contactNo == undefined || this.contactNo === '');
    this.custOccupationNull = (this.custOccupation == undefined || this.custOccupation === '');
    this.salaryAmountNull = (this.salaryAmount == undefined);
    this.salaryDayNull = (this.salaryDay == undefined || this.salaryDay === 0);

    this.addressNull = (this.address == undefined || this.address === '');

    var salaryDayInvalid = (this.salaryDay < 1 || this.salaryDay > 31);

    if (this.icNumberNull == false) {
      var icNumberInvalid = !this.sharedService.checkIfICValid(this.icNumber);
    }


    var cpCompanyNull = (this.isCPCustomer == true && this.selectedCompany == undefined);
    var cpStaffNull = (this.isCPCustomer == true && this.selectedUsername == undefined);

    var dobNull = (this.dob == undefined);


    var genderNull = (this.gender == "" || this.gender == undefined)
    var raceNull = (this.race == "" || this.race == undefined)
    var dobInvalid = this.sharedService.checkInvalidCustomerAge(this.dob);
    if (this.isCPCustomer == true)
      return (this.customerNameNull || this.icNumberNull || icNumberInvalid || customerCodeNull || cpCompanyNull || cpStaffNull || dobNull || genderNull || raceNull);
    else {
      return (this.customerNameNull || this.icNumberNull
        || this.custOccupationNull || this.salaryAmountNull || salaryDayInvalid || icNumberInvalid || this.addressNull || customerCodeNull || dobNull || dobInvalid || genderNull || raceNull);
    }

  }

  handleFileInput(fileList: FileList) {
    this.imageUrls = new Array();

    Array.from(fileList).forEach((file: File) => {
      const reader = new FileReader();
      reader.onload = () => {
        var result = String(reader.result);
        this.imageUrls.push(result);
      }
      reader.readAsDataURL(file);
    });
  }

  reset() {
    this.imageUrls = null;

    this.activeModal.close();
    this.customerName = "";
    this.custOccupation = "";
    this.contactNo = "";
    this.salaryAmount = 0;
    this.salaryDay = 0;
    this.icNumber = "";
    this.remark = "";
    this.address = "";
    this.dob = undefined;

  }



  checkIfSalaryDateInvalid() {
    if (this.salaryDay != undefined) {
      if (this.salaryDay > 31 || this.salaryDay < 0)
        return true;
      else
        return false;
    }

  }
  submit() { this.validFields() ? this.sharedService.openAlert('Please fill in all the fields correctly!', this.enums.DANGER_ALERT) : this.addCustomer(); }

  addCustomer() {

    this.disabledSubmit = true;
    var companyId = Number(sessionStorage.getItem('companyId'));
    var loggedInUsername = sessionStorage.getItem('username');

    const icNumberFormat = this.icNumber.replace(/-/g, "",);

    var data;

    if (this.isCPCustomer == true) {


      data = {
        "name": this.customerName,
        "icNumber": icNumberFormat,
        "remark": "",
        "dateCreated": new Date().toISOString(),
        "handler": this.selectedUsername,
        "companyId": this.selectedCompany,
        "customerCode": this.customerCode,
        "dob": this.datepipe.transform(this.dob, "yyyy-MM-dd"),
        "race": this.race,
        "gender": this.gender
      }
    } else {
      data = {
        "name": this.customerName,
        "occupation": this.custOccupation,
        "salary": this.salaryAmount,
        "salaryDay": this.salaryDay,
        "icNumber": icNumberFormat,
        "remark": this.remark,
        "contactNo": this.contactNo,
        "companyId": companyId,
        "handler": loggedInUsername,
        "dateCreated": new Date().toISOString(),
        "customerCode": this.customerCode,
        "address": this.address,
        "address2": this.address2,
        "dob": this.datepipe.transform(this.dob, "yyyy-MM-dd"),
        "otherId": this.otherId,
        "race": this.race,
        "gender": this.gender
      };
    }


    var xhr = (this.isCPCustomer) ? this.restapi.postRequest(this.consctructApi.getAddCPSettledCustomer(), data) : this.restapi.postRequest(this.consctructApi.getAddCustomer(), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        if (xhr.status == 200) {

          this.passEntry.emit(true);

          // //send new lead info, create new Array, push object inside, pass array into dataService
          // var newLeadArr: Customer[] = new Array;
          // newLeadArr.push(customer);
          // this.dataService.updateLeadList(newLeadArr);

          this.reset();
          this.sharedService.openAlert("Successfully added customer!", this.enums.SUCCESS_ALERT);

        }
        else {
          this.getCheckCustomerExisted(icNumberFormat, this.customerCode);

        }

      }

    }

  }

}