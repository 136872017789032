<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Customer Transaction History</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 aria-label="Close" aria-label="Close"
            (click)="closeModal()"></button>
    </div>

    <div class="modal-body">

        <div class="container-fluid" style="padding: 2%;">
            <div class="row">
                <div class="col-12 col-lg-3">
                    <div class="row">
                        <div class="col-12">
                            <p>Start Date</p>
                        </div>
                        <div class="col-12">
                            <mat-form-field class="full-width-formField p-0">
                                <input matInput readonly [matDatepicker]="startDatePicker" name="startDate"
                                    [(ngModel)]="startDate" required>
                                <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #startDatePicker name="startDate"></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>

                </div>

                <div class="col-12 col-lg-3">
                    <div class="row">
                        <div class="col-12">
                            <p>End Date</p>
                        </div>
                        <div class="col-12">
                            <mat-form-field class="full-width-formField p-0">
                                <input matInput readonly [matDatepicker]="endDatePicker" name="endDate"
                                    [(ngModel)]="endDate" [min]="startDate" required>
                                <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #endDatePicker name="endDate"></mat-datepicker>
                            </mat-form-field>
                        </div>

                    </div>
                </div>
                <div class="col-12 col-md-2 d-flex justify-content-left align-items-center">
                    <button class="btn btn-primary" (click)="getAllTransactionsOfLoan()">Go</button>
                </div>
            </div>
            <hr />
            <ng-container *ngIf="(!receiptList || receiptList.length===0);else alltransactions">
                <p>There's no transaction record for this account.</p>
            </ng-container>

            <ng-template #alltransactions>

                <div class="card mb-2" *ngIf="sharedService.isAdmin()|| sharedService.isBossAccount()">
                    <div class="card-body" style="background-color: #e9d8f2;">
                        <h5 class="fw-bold">Type Total Summary</h5>
                        <table class="table table-bordered" style="border: 1px solid black !important;;">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let typeTotal of typeTotalList">
                                    <td>{{enums.transactionTypeString[typeTotal.type-1]}}</td>
                                    <td>{{typeTotal.total |number:'1.2-2'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <ng-container *ngFor="let receipt of receiptList">
                    <h4>{{receipt.Number}} <span *ngIf="receipt.Scheme=='A'">({{receipt.SchemeInterest}}%)</span></h4>
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th
                                    *ngIf="this.sharedService.isBranchAccountHandler()==true || sharedService.isBossAccount()==true">
                                </th>
                                <th style="width: 10%">Date</th>
                                <th style="width: 40%;text-align: center;">Remark</th>
                                <th style="width: 15%">Amount</th>
                                <th style="width: 20%">Type</th>
                                <th style="width: 15%">HandleBy</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let transaction of receipt.Transactions">
                                <td
                                    *ngIf="this.sharedService.isBranchAccountHandler()==true || sharedService.isBossAccount()==true">
                                    <button class="btn btn-danger" (click)="confirmDeleteTranscation(transaction)" *ngIf="transaction.SetId==undefined"><i
                                            class="fas fa-trash-alt"></i></button>

                                    <!-- <button class="btn btn-danger" (click)="confirmDeleteTranscation(transaction)"><i
                                            class="fas fa-trash"></i></button> -->
                                </td>
                                <td style="cursor: pointer;" [matTooltip]="transaction.Date  |
                                date:'dd-MM-yyyy hh:mm:ss'" matTooltipPosition="above" matTooltipHideDelay="100"
                                    matTooltipClass="tooltip-black">
                                    {{transaction.Date | date:'dd-MM-yyyy'}}
                                </td>
                                <td class="text-center">
                                    {{transaction.Remark}}
                                    <span *ngIf="transaction.SetId!=undefined " class="text-info" style="cursor: pointer;"
                                        (click)="openSetDialog(transaction.SetId)"><i
                                            class="fas fa-file-invoice-dollar"></i></span>
                                </td>

                                <td [ngClass]=" checkAmountInOut(transaction.Amount)">
                                    {{transaction.Amount}}
                                </td>
                                <td>
                                    {{enums.transactionTypeString[transaction.Type-1]}}
                                </td>
                                <!-- <td>
                                {{transaction.ClosingBalance}}
                            </td> -->
                                <td>
                                    {{transaction.HandleBy}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>

                <table class="table  table-hover">
                    <tbody>
                        <tr style="border-top: 2px solid black ; border-bottom: 2px solid black;">
                            <th *ngIf="this.sharedService.isAdmin()==true||sharedService.isBossAccount()==true || sharedService.isFinance()"></th>
                            <th [ngClass]="checkAmountInOut(totalIn)" style="width: 10%">Total In: {{totalIn
                                |number:'1.2-2'}}</th>
                            <th [ngClass]="checkAmountInOut(totalOut)" style="width: 40%;text-align: center;">Total Out:
                                {{totalOut|number:'1.2-2'}}</th>
                            <th style="width: 15%">Total:{{totalAmount |number:'1.2-2'}}</th>
                            <th style="width: 20%"></th>
                            <th style="width: 15%"></th>
                        </tr>
                    </tbody>
                </table>



            </ng-template>
        </div>
    </div>


</form>