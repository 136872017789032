import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { ShowWhatsappMessageClipboardComponent } from 'src/app/Shared/show-whatsapp-message-clipboard/show-whatsapp-message-clipboard.component';
import { LoanRequest } from 'src/model/loanRequest.model';

@Component({
  selector: 'app-edit-loan-request-remark',
  templateUrl: './edit-loan-request-remark.component.html',
  styleUrls: ['./edit-loan-request-remark.component.css']
})
export class EditLoanRequestRemarkComponent implements OnInit {

  @Input() request: LoanRequest;
  remark: string = "";
  disabledSubmit = false;


  constructor(private activeModal: NgbActiveModal, public sharedService: SharedService, public enums: Enums, private restApi: RestApiService, private constructApi: ConstructAPI) { }

  ngOnInit(): void {
    this.remark = (this.request.Remark.split("=").length > 1) ? this.request.Remark.split("=")[0] : this.request.Remark;
  }



  submit() {
    if (this.remark == "")
      this.sharedService.openAlert("Please fill in remark", this.enums.DANGER_ALERT);
    else if (this.remark == this.request.Remark) {
      this.sharedService.openAlert("Nothing updated in the remark", this.enums.DANGER_ALERT);
    }
    else {
      this.updateLoanRequestRemark();
    }
  }


  updateLoanRequestRemark() {

    var remark = (this.request.Remark.split("=").length > 1) ? this.remark + "=" + this.request.Remark.split("=")[1] : this.remark;
    var data = {
      "remark": remark
    }

    var xhr = this.restApi.putRequest(this.constructApi.getUpdateEPFRequestRemark(this.request.ID), data)
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        //if success , then proceed
        if (xhr.status == 200) {

          var json = JSON.parse(xhr.responseText);
          this.request.ID = Number(json.id);

          this.request.Remark = remark;
          if (this.sharedService.isBranchManager()) {
            var modalRef = this.sharedService.openModal(ShowWhatsappMessageClipboardComponent);
            modalRef.componentInstance.request = this.request;
            modalRef.componentInstance.isDirty = true;

            this.reset();
          }
          else
            window.location.reload();

        }
        else {
          //show error message
          this.sharedService.openAlert(JSON.parse(xhr.responseText).error, this.enums.DANGER_ALERT)

        }
      }
    }
  }


  reset() {
    this.activeModal.close();
  }
}
