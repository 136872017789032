import { ValueBrushScaleDescription } from "igniteui-angular-core"
import { DuitLebih } from "./duitLebih.model"
import { SettlementCharges } from "./settlementCharges.model"

export class Loan {




    constructor(private _id: number,
        private _profileId: number,
        private _principal: number,
        private _stampAmount: number,
        private _receiptNumber: string,
        private _status: number,
        private _dateCreated: Date,
        private _companyId: number,
        private _companyName: string,
        private _scheme: string,
        private _interestRate: number,
        private _term: number,
        private _customerName: string,
        private _icNumber: string,
        private _interestCharge: number,
        private _issuer: string,
        private _loanCode: string,
        private _arrear: number,
        private _principalPaid: number,
        private _repayment: number,
        private _reserve: number,
        private _balance?: number,
        private _monthlyPayment?: number,
        private _arrearPaid?: number,
        private _serviceCharge?: number,
        private _extras?: DuitLebih[],
        private _isPendingCommission?: boolean,
        private _monthlyInterest?: number,
        private _discount?: number,
        private _processingRate?: number,
        private _processingCharge?: number,
        private _isSelected?: boolean,
        private _isEPF?: boolean,
        private _settlementStamp?: number,
        private _settlementServiceCharge?: number,
        private _settlementExtras?: number,
        private _repaymentPaid?: number,
        private _pnl?: number,
        private _principalOut?: number,
        private _termLeft?: number,
        private _legacySettlement?: number,
        private _loanDocExisted?: boolean,
        private _settlementCharges?: SettlementCharges,
        private _sharedCompanies?: Number[],
        private _mainCompanyId?: number,
        private _mainCustomerId?: number,
        private _mainCompanyCode?: string,
        private _companyCode?: string

    ) {

    }


    get SharedCompanies(): Number[] {
        return this._sharedCompanies
    }
    set SharedCompanies(value: Number[]) {
        this._sharedCompanies = value
    }
    set ID(id: number) { this._id = id }
    get ID(): number { return this._id }

    set ProfileId(profileId: number) { this._profileId = profileId }
    get ProfileId(): number { return this._profileId }


    set Principal(principal: number) { this._principal = principal }
    get Principal(): number { return this._principal }


    set StampAmount(stampAmount: number) { this._stampAmount = stampAmount }
    get StampAmount(): number { return this._stampAmount }

    set ReceiptNumber(receiptNumber: string) { this._receiptNumber = receiptNumber }
    get ReceiptNumber(): string { return this._receiptNumber }


    set Status(status: number) { this._status = status }
    get Status(): number { return this._status }

    set DateCreated(dateCreated: Date) { this._dateCreated = dateCreated }
    get DateCreated(): Date { return this._dateCreated }

    set CompanyId(companyId: number) { this._companyId = companyId }
    get CompanyId(): number { return this._companyId }

    set CompanyName(companyName: string) { this._companyName = companyName }
    get CompanyName(): string { return this._companyName }


    set CustomerName(customerName: string) { this._customerName = customerName }
    get CustomerName(): string { return this._customerName }


    set ICNumber(icNumber: string) { this._icNumber = icNumber }
    get ICNumber(): string { return this._icNumber }

    set Term(term: number) { this._term = term }
    get Term(): number { return this._term }


    set Scheme(scheme: string) { this._scheme = scheme }
    get Scheme(): string { return this._scheme }

    set InterestRate(interestRate: number) { this._interestRate = interestRate }
    get InterestRate(): number { return this._interestRate }

    set ProcessingRate(processingRate: number) { this._processingRate = processingRate }
    get ProcessingRate(): number { return this._processingRate }

    set ProcessingCharge(processingCharge: number) { this._processingCharge = processingCharge }
    get ProcessingCharge(): number { return this._processingCharge }

    set InterestCharge(interestCharge: number) { this._interestCharge = interestCharge }
    get InterestCharge(): number { return this._interestCharge }

    set Issuer(issuer: string) { this._issuer = issuer }
    get Issuer(): string { return this._issuer }

    set LoanCode(loanCode: string) { this._loanCode = loanCode }
    get LoanCode(): string { return this._loanCode }

    set Arrear(arrear: number) { this._arrear = arrear }
    get Arrear(): number { return this._arrear }

    set PrincipalPaid(principalPaid: number) { this._principalPaid = principalPaid }
    get PrincipalPaid(): number { return this._principalPaid }

    set Repayment(repayment: number) { this._repayment = repayment }
    get Repayment(): number { return this._repayment }

    set Reserve(reserve: number) { this._reserve = reserve }
    get Reserve(): number { return this._reserve }

    set MonthlyPayment(monthlyPayment: number) { this._monthlyPayment = monthlyPayment }
    get MonthlyPayment(): number { return this._monthlyPayment }

    set Balance(balance: number) { this._balance = balance }
    get Balance(): number { return this._balance }

    set ArrearPaid(arrearPaid: number) { this._arrearPaid = arrearPaid }
    get ArrearPaid(): number { return this._arrearPaid }

    set ServiceCharge(sc: number) { this._serviceCharge = sc }
    get ServiceCharge(): number { return this._serviceCharge }

    set Extras(extras: DuitLebih[]) { this._extras = extras }
    get Extras(): DuitLebih[] { return this._extras }

    set IsPendingCommission(isPendingCommission: boolean) { this._isPendingCommission = isPendingCommission }
    get IsPendingCommission(): boolean { return this._isPendingCommission }

    set MonthlyInterest(monthlyInterest: number) { this._monthlyInterest = monthlyInterest }
    get MonthlyInterest(): number { return this._monthlyInterest }

    set Discount(discount: number) { this._discount = discount }
    get Discount(): number { return this._discount }

    set IsSelected(isSelected: boolean) { this._isSelected = isSelected }
    get IsSelected(): boolean { return this._isSelected }

    set IsEPF(isEPF: boolean) { this._isEPF = isEPF }
    get IsEPF(): boolean { return this._isEPF }


    set SettlementStamp(settlementStamp: number) { this._settlementStamp = settlementStamp }
    get SettlementStamp(): number { return this._settlementStamp }


    set SettlementServiceCharge(settlementServiceCharge: number) { this._settlementServiceCharge = settlementServiceCharge }
    get SettlementServiceCharge(): number { return this._settlementServiceCharge }

    set SettlementExtra(settlementExtra: number) { this._settlementExtras = settlementExtra }
    get SettlementExtra(): number { return this._settlementExtras }

    set RepaymentPaid(repaymentPaid: number) { this._repaymentPaid = repaymentPaid }
    get RepaymentPaid(): number { return this._repaymentPaid }

    set PnL(pnl: number) { this._pnl = pnl }
    get PnL(): number { return this._pnl }

    set PrincipalOut(principalOut: number) { this._principalOut = principalOut }
    get PrincipalOut(): number { return this._principalOut }

    set TermLeft(termLeft: number) { this._termLeft = termLeft }
    get TermLeft(): number { return this._termLeft }

    set LegacySettlement(legacySettlement: number) { this._legacySettlement = legacySettlement }
    get LegacySettlement(): number { return this._legacySettlement }

    get LoanDocExisted(): boolean { return this._loanDocExisted }
    set LoanDocExisted(value: boolean) { this._loanDocExisted = value }

    get SettlementCharges(): SettlementCharges { return this._settlementCharges }
    set SettlementCharges(value: SettlementCharges) { this._settlementCharges = value }

    get MainCompanyCode(): string {
        return this._mainCompanyCode
    }
    set MainCompanyCode(value: string) {
        this._mainCompanyCode = value
    }
    get MainCustomerId(): number {
        return this._mainCustomerId
    }
    set MainCustomerId(value: number) {
        this._mainCustomerId = value
    }
    get MainCompanyId(): number {
        return this._mainCompanyId
    }
    set MainCompanyId(value: number) {
        this._mainCompanyId = value
    }

    get CompanyCode(): string {
        return this._companyCode
    }
    set CompanyCode(value: string) {
        this._companyCode = value
    }

}