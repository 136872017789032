<div class="container-fluid p-3">
    <div class="d-flex justify-content-end align-items-end">
        <button class="btn btn-info" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseExample">
            <i class="fas fa-chevron-circle-down" *ngIf="isCollapsed"></i>
            <i class="fas fa-chevron-circle-up" *ngIf="isCollapsed==false"></i>
        </button>
    </div>



    <div class="collapse" id="collapseExample" [ngbCollapse]="isCollapsed">
        <app-report-generator [selectedCompany]="selectedCompany"></app-report-generator>
    </div>
    <h2> Duit Lebih Report ( {{startDate |date:'yyyy-MM-dd'}} - {{endDate |date:'yyyy-MM-dd'}} )
    </h2>

    <p [hidden]="reportList.length>0 || transactionBalance>0"> Do not have any record within this date range</p>

    <div [hidden]="reportList.length==0 && transactionBalance==0">
        <div class="row">
            <div class="col d-flex justify-content-end align-items-end">
                <span style="color: purple;" class="me-2"> Filter by Subtype: </span>
                <select class="browser-default form-select" [(ngModel)]="selectedSubtype" (change)="filterBySubtype()"
                    style="max-width: 50%;">
                    <option [ngValue]="null">All</option>
                    <ng-container *ngFor="let type of transactionSubtypeList;let i=index">
                        <option [value]="type.ID">
                            {{type.Name}}
                        </option>
                    </ng-container>

                </select>
            </div>
        </div>
        <table class="table table-striped mt-2">
            <thead>
                <tr>
                    <th (click)="sortByCriteria('Date')" class="sortHeader">Date </th>
                    <th (click)="sortByCriteria('CustomerName')" class="sortHeader">Customer</th>
                    <th (click)="sortByCriteria('Receipt')" class="sortHeader">Receipt</th>
                    <th (click)="sortByCriteria('Remark')" class="sortHeader">Remark</th>
                    <th (click)="sortByCriteria('Subtype')" class="sortHeader">SubType</th>
                    <th (click)="sortByCriteria('Amount')" class="sortHeader">Amount</th>
                </tr>
            </thead>
            <tbody>
                <tr style="background-color: lightskyblue;">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Carry Forward Balance</td>
                    <td></td>
                    <td>{{transactionBalance}}</td>
                </tr>
                <tr *ngFor="let report of reportList">
                    <td>{{report.date | date:'yyyy-MM-dd'}}</td>
                    <td (click)="goToCustomer(report.customerId,report.profileId)" class="customerCol">
                        {{report.customerName}}</td>
                    <td>{{report.receiptNumber}}</td>
                    <td>{{report.remark}}</td>
                    <td> <span *ngIf="report.subtype!=undefined">{{ getSubTypeName(report.subtype)}}</span> </td>
                    <td [ngClass]="getStyle(report.amount)">RM {{report.amount}}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <th>
                        <div class="row">
                            <div class="col-12"> Sum: RM {{calculateTotal()| number:'1.2-2'}}</div>
                            <div class="col-12 col-md-6 text-success">In: RM {{calculateInTotal()| number:'1.2-2'}}
                            </div>
                            <div class="col-12 col-md-6 text-danger">Out: RM {{calculateOutTotal()| number:'1.2-2'}}
                            </div>
                        </div>

                    </th>
                    <th>

                        <div class="row">
                            <div class="col-12"> Total: RM {{calculateTotal()+transactionBalance| number:'1.2-2'}}</div>
                            <div class="col-12 text-info">Count: {{reportList.length}}</div>
                        </div>

                    </th>
                </tr>
            </tfoot>
        </table>
    </div>
</div>