<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">View Picture</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()" aria-label="Close"></button>
    </div>

    <div class="modal-body">
        <div class="container-fluid p-0">

            <ng-container *ngIf="customerID!=undefined && loanId==undefined && contactID==undefined">
                <app-check-customer-necessary-files [customerId]="customerID"></app-check-customer-necessary-files>
            </ng-container>
            <div class="row" appNoRightClick>
                <div class="col-12 d-flex justify-content-end align-items-right"
                    *ngIf="loanId!=undefined && sharedService.isBranchManager()==true">
                    <button class="btn btn-dark" (click)="addLoanDocModal()">Add more Loan Doc</button>
                </div>
                <div class="col-2 m-1 " *ngFor="let img of imageArr; let i =index">
                    <div class="row">
                        <div class="col-12 imageBox" (click)="openEnlargeImageModal(img.label,i)">
                            <span class="badge text-bg-dark dateTag" #tooltip="matTooltip" [matTooltip]="img.lastModified"
                                matTooltipPosition="above" matTooltipHideDelay="100"
                                matTooltipClass="tooltip-black">{{img.lastModified | date:'dd-MM-yyyy'}}</span>
                            <img [src]="img.url" />
                        </div>
                        <p class="m-1 downloadLink" (click)="downloadFile(img.label)">
                            {{img.label}}
                        </p>
                    </div>
                </div>


            </div>


            <div class="row">
                <div class="ng-container col-2 m-1" *ngFor="let file of fileArr">
                    <div class="col-12 imageBox">
                        <span class="badge text-bg-dark dateTag" #tooltip="matTooltip" [matTooltip]="file.lastModified"
                            matTooltipPosition="above" matTooltipHideDelay="100">{{file.lastModified |
                            date:'dd-MM-yyyy'}}</span>

                        <div class="row">
                            <div class="col-12 d-flex align-items-center justify-content-center">
                                <h1><i class="fas fa-file-pdf" *ngIf="sharedService.checkIsPDF(file.contentType)"></i>
                                    <i class="fas fa-video" *ngIf="sharedService.checkIsVideo(file.contentType,file.label)"></i>
                                    <i class="fas fa-file"
                                        *ngIf="sharedService.checkIsPDF(file.contentType)==false && sharedService.checkIsVideo(file.contentType,file.label)==false"></i>
                                </h1>
                            </div>
                            <div class="col-12" style="width: 250px;">
                                <p class="m-1 downloadLink" (click)="downloadFile(file.label)">
                                    {{file.label}}
                                </p>
                            </div>

                        </div>

                    </div>

                    <div class="row">
                        <div class="col d-inline-block">
                            <button class="btn btn-danger " *ngIf="checkCanDelete()"
                                (click)="confirmDeleteFile(file.label,i)"><i class="fas fa-trash-alt"></i> </button>

                            <button class="btn btn-info" (click)="previewFile(file.label)"
                                *ngIf="sharedService.checkIsPDF(file.contentType)|| sharedService.checkIsVideo(file.contentType,file.label)"><i
                                    class="fas fa-eye"></i>
                            </button>
                        </div>
                    </div>


                </div>
            </div>

        </div>
    </div>
</form>