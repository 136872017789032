import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { AlertDialogComponent } from 'src/app/Shared/alert-dialog/alert-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Account } from 'src/model/account.model';

@Component({
  selector: 'app-add-asset',
  templateUrl: './add-asset.component.html',
  styleUrls: ['./add-asset.component.css'],
  providers: [DatePipe]
})
export class AddAssetComponent implements OnInit {
  @Input() companyBankAccountList: Account[] = new Array;
  @Input() cashAccountList: Account[] = new Array;

  selectedAccountId: number;
  isReturn: boolean;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  //2-WAY BINDING INFO
  date: Date;
  remark: string;
  amount: number = 0;

  disabledSubmit = false;
  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private restapi: RestApiService, private constructApi: ConstructAPI,
    public sharedService: SharedService, private enums: Enums, private datepipe: DatePipe) {
    this.date = new Date();
  }

  ngOnInit(): void {
  }

  setSelectedAccount(accountId: number) {

    if (accountId === this.selectedAccountId)
      return true
    else
      return false;
  }

  isFieldNull() {
    var paymentNull = (this.amount == undefined || this.amount == 0);
    var remarkNull = (this.remark == undefined || this.remark == "");
    var selectedAccountIdNull = (this.selectedAccountId == undefined)
    var paymentValid = (isNaN(+this.amount))

    return paymentNull || remarkNull || selectedAccountIdNull || paymentValid
  }




  getLastestCheckInDateBeforeAssetDate(companyId: number) {

    var assetDateStr = new Date(Number(this.date)).toISOString();
    var xhttp = this.restapi.getRequest(this.constructApi.getCompaniesLatestCheckIns(companyId, assetDateStr));

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          //last check in date
          var lastDate = this.datepipe.transform(new Date(json.date), 'dd-MM-yyyy');

          var dayBeforeAssetDate = new Date(this.date); //current assetDate
          dayBeforeAssetDate.setDate(dayBeforeAssetDate.getDate() - 1) //minimum need verify 24 hours before asset date
          var dayBeforeAssetDateStr = this.datepipe.transform(dayBeforeAssetDate, 'dd-MM-yyyy');


          //check if last verified date = 24 hours before asset date
          if (lastDate == dayBeforeAssetDateStr)
            this.addAndApproveAsset();
          else {
            this.sharedService.openAlert("Please verify your account on  (" + this.date.getFullYear() + "/" + (this.date.getMonth() + 1) + "/" + (this.date.getDate() - 1) + ")", this.enums.DANGER_ALERT);

          }

        }
        );
      }
    };
  }

  addAndApproveAsset() {

    this.disabledSubmit = true;

    var isoTaskDate = (new Date(Number(this.date))).toISOString();
    var companyId = Number(sessionStorage.getItem("companyId"));

    var amount = (this.isReturn) ? -this.amount : this.amount;
    var data = {
      "companyId": companyId,
      "amount": amount,
      "accountId": Number(this.selectedAccountId),
      "dateCreated": isoTaskDate,
      "remark": this.remark
    };


    var xhr = this.restapi.postRequest(this.constructApi.getAddAsset(), data);
    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        if (xhr.readyState == 4) {


          if (xhr.status == 200) {
            var json = JSON.parse(xhr.responseText);
            var assetID = json.id;
            this.approveAsset(assetID);
          }
          else {
            this.disabledSubmit = false;
            this.sharedService.openErrorMessage(xhr);
          }
        }
      }

    }
  }

  approveAsset(assetID: number) {
    var isoTaskDate = (new Date(Number(this.date))).toISOString();

    var data = {
      "accountId": Number(this.selectedAccountId),
      "date": isoTaskDate,
      "remark": this.remark
    };


    var xhr = this.restapi.postRequest(this.constructApi.getApproveAsset(assetID), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        // var json = JSON.parse(xhr.responseText);
        var account;
        if (this.isReturn)
          account = new Account(this.selectedAccountId, null, null, null, null, null, null, -this.amount)
        else
          account = new Account(this.selectedAccountId, null, null, null, null, null, null, this.amount)
        this.passEntry.emit(account)
        this.reset();
        this.sharedService.openAlert("Successfully approved asset!", this.enums.SUCCESS_ALERT);
        window.location.reload();

      }
    }
  }

  submit() {
    if (this.isFieldNull()) {
      this.sharedService.openAlert('Please fill in all the fields', this.enums.DANGER_ALERT)
    }
    else if (this.isReturn && this.checkAccountBalanceSufficient() == false) {
      this.sharedService.openAlert('This account does not have enough money', this.enums.DANGER_ALERT);

    }
    else {
      var companyId = Number(sessionStorage.getItem("companyId"));
      this.getLastestCheckInDateBeforeAssetDate(companyId);
    }
  }

  checkAccountBalanceSufficient() {
    var index = this.cashAccountList.findIndex(account => Number(account.ID) == Number(this.selectedAccountId));

    if (index == -1) {
      index = this.companyBankAccountList.findIndex(account => Number(account.ID) == Number(this.selectedAccountId));

      if (this.companyBankAccountList[index].Balance - this.amount < 0)
        return false;
    }
    else {
      if (this.cashAccountList[index].Balance - this.amount < 0)
        return false;
    }
    return true;
  }


  reset() {
    this.activeModal.close();
  }
}
