<div class="container-fluid  m-2 p-2">
    <div class=" m-2 p-2">
        <div class="card shadow-lg">
            <div class="card-body">
                <div class="row">
                    <div class="col-4 pt-3" [hidden]="sharedService.isBranchAccountHandler()==true">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Company</mat-label>
                            <input class="companyInput" type="text" placeholder="Pick one" aria-label="Company" matInput
                                [formControl]="myControl" [matAutocomplete]="auto" [(ngModel)]="selectedCompanyCode">
                            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                <mat-option *ngFor="let company of filteredCompany |async" [value]="company.CompanyCode"
                                    (onSelectionChange)="setSelectedCompany(company.CompanyCode)"
                                    (click)="setSelectedCompany(company.CompanyCode)">
                                    {{company.CompanyCode}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>

                    </div>
                    <div class="col-12 col-md-3">
                        <div class="row">
                            <div class="col-12">
                                <p>Start Date</p>
                            </div>
                            <div class="col-12">
                                <mat-form-field class="full-width-formField p-0">
                                    <input matInput readonly [matDatepicker]="startDatePicker" name="startDate"
                                        [(ngModel)]="startDate" required [max]="sharedService.getTodayDate()">
                                    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                                    <mat-datepicker #startDatePicker name="startDate"></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>

                    </div>

                    <div class="col-12 col-md-3">
                        <div class="row">
                            <div class="col-12">
                                <p>End Date</p>
                            </div>
                            <div class="col-12">
                                <mat-form-field class="full-width-formField p-0">
                                    <input matInput readonly [matDatepicker]="endDatePicker" name="endDate" [(ngModel)]="endDate"
                                        [min]="startDate" required [max]="sharedService.getTodayDate()">
                                    <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                                    <mat-datepicker #endDatePicker name="endDate"></mat-datepicker>
                                </mat-form-field>
                            </div>

                        </div>
                    </div>
                    <div class="col-12 ">
                        <div class="row">
                            <div class="col-12 mb-1">
                                <div class="row"
                                    *ngIf="sharedService.isAdmin() || sharedService.isBossAccount() || sharedService.isFinance() || sharedService.isExpenseAdmin()">
                                    <div class="col-3">
                                        <p class="d-inline">Type</p>
                                        <select class="browser-default form-select" name="type"
                                            [(ngModel)]="filteredType"
                                            (ngModelChange)="changeFilteredSubtypeList(filteredType)">
                                            <option [value]="0"></option>
                                            <option *ngFor="let tt of filterTransactionTypeList" [value]="tt.ID">
                                                {{tt.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-3" *ngIf="filteredSubtypeList.length>0">
                                        <p class="d-inline">SubType</p>
                                        <select class="browser-default form-select" name="type"
                                            [(ngModel)]="filteredSubtype">
                                            <option [value]="0"></option>
                                            <option *ngFor="let tt of filteredSubtypeList" [value]="tt.ID">
                                                {{tt.Name}}</option>
                                        </select>
                                    </div>
                                </div>

                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-3">
                                        <div class="card p-2 " style="background-color:lightgoldenrodyellow ;">
                                            <mat-slide-toggle class="mat-slide-toggle-lg" [hideIcon]="true" color="warn"
                                                [(ngModel)]="showValid" name="showValid">Show valid and submitted sets
                                            </mat-slide-toggle>
                                        </div>
                                    </div>
                                    <div class="col-3"
                                        *ngIf="sharedService.isAdmin() || sharedService.isBossAccount() || sharedService.isFinance() || sharedService.isExpenseAdmin()">
                                        <div class="card p-2 " style="background-color:lightblue ;">
                                            <mat-slide-toggle class="mat-slide-toggle-lg" [hideIcon]="true" color="warn"
                                                [(ngModel)]="showBookmark" name="showValid">Show BOOKMARK Only
                                            </mat-slide-toggle>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>

                    </div>
                    <div class="col-12 d-flex justify-content-end align-items-end">
                        <button class="btn btn-info me-1" (click)="showUnprocessedTransaction()"
                            *ngIf="sharedService.isBossAccount() || sharedService.isFinance() || sharedService.isAdmin()">Show Unprocessed
                            Transactions</button>
                        <button class="btn btn-dark" (click)="goToCompanySet()" [disabled]="disableSubmit">Apply</button>
                    </div>
                </div>
            </div>
        </div>



        <hr />

        <div class="row">
            <div class="col-6"> <input type="text"  placeholder="Search" aria-label="Search"
                    name="searchText" [(ngModel)]="searchText" (keyup)="filterList()">
            </div>
            <div class="col-6 d-flex justify-content-end align-items-end" *ngIf="sharedService.isBranchAccountHandler()">
                <button class="btn btn-dark" (click)="openAddNewSetModal()">New Receipt</button>
            </div>


            <div class="col-12 d-flex justify-content-end align-items-end"
                *ngIf="sharedService.isAdmin()||sharedService.isBossAccount() || sharedService.isFinance() || sharedService.isExpenseAdmin()">
                <button class="btn" [ngClass]="{'btn-dark':isListView==true, 'btn-light':isListView==false}"
                    (click)="changeListView(true)"><i class="fas fa-list-ul"></i></button>
                <button class="btn" [ngClass]="{'btn-dark':isListView==false, 'btn-light':isListView==true}"
                    (click)="changeListView(false)"><i class="fas fa-th-large"></i></button>
            </div>

            <ng-container class="conatiner-fluid" *ngIf="setList.length>0">
                <div *ngIf="isListView==false;else listView" style="width: 100%;">
                    <div class="row">
                        <div class="col-12">
                            <p class="fw-bold">{{setList.length}} results</p>
                        </div>

                        <div class="col-12 col-lg-4 m-1 m-lg-0 mb-lg-2" *ngFor="let set of setList">
                            <app-receipt-details [receipt]="set" [expenseSubtypeList]="expenseSubtypeList"
                                [dlSubtypeList]="dlSubtypeList"></app-receipt-details>
                        </div>
                    </div>

                </div>
                <ng-template #listView>

                    <div class="col-12">
                        <app-receipt-sets-listview [setList]="setList" [expenseSubtypeList]="expenseSubtypeList"
                            [dlSubtypeList]="dlSubtypeList"></app-receipt-sets-listview>
                    </div>


                </ng-template>
            </ng-container>


        </div>
    </div>

</div>