import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Enums } from '../enums';
import { SharedService } from '../shared-service.service';

@Component({
  selector: 'app-shared-add-remark',
  templateUrl: './shared-add-remark.component.html',
  styleUrls: ['./shared-add-remark.component.css']
})
export class SharedAddRemarkComponent implements OnInit {

  constructor(private activeModal: NgbActiveModal, private sharedService: SharedService, public enums: Enums) { }

  remark: string;
  disabledSubmit: boolean = false;
  title: string;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
  }

  submit() {
    if (this.remark == undefined || this.remark == "")
      this.sharedService.openAlert("Please key in your remark to proceed!", this.enums.DANGER_ALERT);
    else
      this.passEntry.emit(this.remark);

    this.activeModal.close();
  }


  reset() {
    this.remark = "";
    this.activeModal.close();
  }
}
