<div class="modal-header" style="background-color:lightgray;" *ngIf="isModal">
    <h4 class="modal-title">Receipt</h4>
    <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
</div>
<ng-container *ngIf="receipt!=undefined">
    <div class="card shadow-lg m-0" [ngClass]="{'unsubmitCard':receipt.Valid==false}">
        <div class="card-body">
            <div class="row">
                <div class="col-10">
                    <div>
                        <button class="btn btn-dark"
                            *ngIf="(sharedService.isAdmin()|| sharedService.isBossAccount() || sharedService.isFinance() || sharedService.isExpenseAdmin()) && receipt.Saved==true"
                            (click)="checkOpenBookmark(receipt)"> <i class="fas fa-bookmark"></i></button>
                        <button class="btn btn-light"
                            *ngIf="(sharedService.isAdmin()|| sharedService.isBossAccount() || sharedService.isFinance() || sharedService.isExpenseAdmin()) && (receipt.Saved==false || receipt.Saved ==undefined)"
                            (click)="checkOpenBookmark(receipt)"><i class="far fa-bookmark"></i></button>

                        <span class="badge text-bg-dark ms-1"
                            *ngIf="receipt.CompanyCode!=undefined">{{receipt.CompanyCode}}</span>

                    </div>


                    <h3>
                        #{{receipt.ID}} - {{receipt.Name}}

                        @if(this.sharedService.isBossAccount() || this.sharedService.isAdmin()){
                        <span class="badge text-bg-success" *ngIf="receipt.Valid==true">Valid</span>
                        <span class="badge text-bg-danger"
                            *ngIf="receipt.Valid==false && receipt.CheckedBy!=undefined">Invalid</span>
                        <span class="badge text-bg-primary" *ngIf="receipt.Valid==undefined">Pending</span>
                        }

                    </h3>
                </div>
                <div class="col-2 d-flex justify-content-end align-items-end">

                    <button class="btn btn-info" (click)="viewSetFiles()" #tooltip="matTooltip" matTooltip="View Files"
                        matTooltipPosition="above" matTooltipHideDelay="100" matTooltipClass="tooltip-black"><i
                            class="fas fa-image"></i></button>
                    <button class="btn btn-secondary ms-1" (click)="openAddTransactionToSetModal()"
                        *ngIf="receipt.Valid==false && sharedService.isBranchManager() " #tooltip="matTooltip"
                        matTooltip="Add transaction" matTooltipPosition="above" matTooltipHideDelay="100"
                        matTooltipClass="tooltip-black"><i class="fas fa-plus"></i></button>

                    <button class="btn btn-danger ms-1" *ngIf="receipt.Valid==false && sharedService.isBranchManager()"
                        (click)="confirmBeforeDeletingAllTransactions()" #tooltip="matTooltip"
                        matTooltip="Remove all transactions from set" matTooltipPosition="above"
                        matTooltipHideDelay="100" matTooltipClass="tooltip-black"> <i class="fas fa-trash"></i>
                    </button>
                </div>
            </div>


            <div class="text-secondary fw-bold">
                <span [hidden]="isEditing==true">{{receipt.Remark}}</span>
                <div class="form-floating my-2" [hidden]="isEditing == false">
                    <input class="form-control" type="text" name="editedRemark" id="editedRemark" placeholder="Remark"
                        [(ngModel)]="editedRemark">
                    <label for="editedRemark" class="form-label">Remark</label>
                </div>
                <button class="btn btn-warning ms-1 d-none d-md-inline" (click)="editRemark(receipt.Remark)"
                    [hidden]="isEditing == true ||  receipt.Valid!=false|| sharedService.isBranchManager()==false">
                    <i class="fas fa-pencil-alt"></i>
                </button>
                <button class="btn btn-danger ms-1" (click)="isEditing=false"
                    [hidden]="isEditing == false ||  receipt.Valid!=false|| sharedService.isBranchManager()==false">
                    <i class="fas fa-times"></i>
                </button>
                <button class="btn btn-success ms-1"
                    [hidden]="isEditing == false || receipt.Valid!=false|| sharedService.isBranchManager()==false"
                    (click)="updateRemark()">
                    <i class="fas fa-check"></i>
                </button>
            </div>
            <span class="text-info fw-bold m-0 p-0"
                *ngIf="receipt.CheckerRemark!=''&& receipt.CheckerRemark!=undefined">
                <i class="far fa-comment-dots"></i> : {{receipt.CheckerRemark}}</span>

            <ng-container
                *ngIf="sharedService.isAdmin()|| sharedService.isBossAccount() || sharedService.isFinance() ||sharedService.isExpenseAdmin()">
                <span class="text-success fw-bold m-0 p-0"
                    *ngIf="(receipt.SetRemark!=''&& receipt.SetRemark!=undefined) && (sharedService.isAdmin()|| sharedService.isBossAccount() || sharedService.isFinance() ||sharedService.isExpenseAdmin())">
                    <i class="fas fa-comment-dollar"></i>: {{receipt.SetRemark}}
                </span>

                <ng-container *ngFor="let tag of receipt.Tags; let i=index">
                    <span class="badge text-bg-secondary m-1">{{tag}} <i class="fas fa-times" style="cursor: pointer;"
                            (click)="confirmRemoveTag(receipt,tag,i)"></i></span>
                </ng-container>
                <button class="btn  btn-sm  btn-warning" *ngIf="receipt.Tags!=undefined || receipt.SetRemark!=undefined"
                    (click)="openEditSetRemark(receipt)"><i class="fas fa-pencil-alt"></i></button>
            </ng-container>

            <table class="table table-custom">
                <tbody>
                    <tr *ngFor="let transaction of receipt.TransactionList">
                        <td *ngIf="receipt.Valid==false && sharedService.isBranchManager()">
                            <button class="btn btn-danger" (click)="confirmBeforeDeletingTransaction(transaction)"><i
                                    class="fas fa-minus"></i></button>
                        </td>
                        <td style="width: 40%;"> {{transaction.Date | date:'dd-MM-yyy'}}</td>
                        <td style="width: 30%;"> {{transaction.Remark}}
                            <ng-template #popContent>
                                <div *ngIf="transactionCustomerIsReady==false;else customerDetails">
                                    <mat-spinner></mat-spinner>
                                </div>
                                <ng-template #viewcustomerTooltip>
                                    <p>Go To Customer</p>

                                </ng-template>

                                <ng-template #viewtransactionTooltip>
                                    <p>View Transactions</p>
                                </ng-template>
                                <ng-template #customerDetails>
                                    <div class="card mb-2">
                                        <div class="card-body" style="cursor:pointer">
                                            <h3>{{transactionCustomer.Name}}
                                                ({{sharedService.processICNum(transactionCustomer.ICNumber)}})</h3>
                                            <button class="btn btn-info m-1"><i class="fas fa-user"
                                                    (click)="goToCustomer(transactionCustomer.ID)"
                                                    [ngbTooltip]="viewcustomerTooltip"></i></button>
                                            <button class="btn btn-dark m-1"
                                                (click)="viewCustomerTransaction(transactionCustomer.Profiles[0].ID,transactionCustomer.CompanyId,transaction)"
                                                [ngbTooltip]="viewtransactionTooltip">
                                                <i class="fas fa-file-invoice-dollar"></i></button>
                                        </div>
                                    </div>
                                </ng-template>

                            </ng-template>
                            <button class="btn btn-primary btn-sm"
                                *ngIf="(sharedService.isLoanRelatedType(transaction.Type) || sharedService.isLoanRelatedSubype(transaction.Subtype) || transaction.CustomerID!=undefined) && this.sharedService.isExpenseAdmin()==false"
                                [ngbPopover]="popContent" (click)="loadTransactionCustomer(transaction.ID)"><i
                                    class="fas fa-info-circle"></i></button>
                        </td>
                        <td style="width: 30%;">
                            {{enums.transactionTypeString[transaction.Type-1]}}
                            <!-- <span class="badge text-bg-info bagde-sm"
                                *ngIf="(transaction.Type== enums.EXPENSE || transaction.Type== enums.EXTRAS) && expenseSubtypeList!=undefined && dlSubtypeList!=undefined && transaction.Subtype!=undefined">
                                {{findSubtypeName(transaction.Subtype,transaction.Type)}}</span> -->

                            <button class="btn btn-sm btn-info"
                                *ngIf="(transaction.Type== enums.EXPENSE || transaction.Type== enums.EXTRAS) && expenseSubtypeList!=undefined && dlSubtypeList!=undefined && transaction.Subtype!=undefined &&(this.sharedService.isExpenseAdmin()|| this.sharedService.isBossAccount() || this.sharedService.isFinance() || this.sharedService.isAdmin() || this.sharedService.isBranchManager())"
                                (click)="openManageSubtypeModal(transaction)"
                                [disabled]="!this.sharedService.isExpenseAdmin() && !this.sharedService.isBossAccount()">
                                {{findSubtypeName(transaction.Subtype,transaction.Type)}}
                                <i class="fas fa-pencil-alt"
                                    *ngIf="this.sharedService.isExpenseAdmin()|| this.sharedService.isBossAccount()"></i>
                            </button>
                            <button class="btn btn-sm btn-warning"
                                *ngIf="(transaction.Type== enums.EXPENSE || transaction.Type== enums.EXTRAS) && (expenseSubtypeList!=undefined && dlSubtypeList!=undefined && transaction.Subtype==undefined) &&(this.sharedService.isExpenseAdmin()|| this.sharedService.isBossAccount())"
                                (click)="openManageSubtypeModal(transaction)"><i
                                    class="fas fa-folder-plus"></i></button>
                        </td>
                        <td style="width: 40%;">RM {{transaction.Amount|number:'1.0-2'}}</td>
                    </tr>
                </tbody>

                <tfoot>
                    <tr>
                        <th *ngIf="receipt.Valid==false && sharedService.isBranchManager()"></th>
                        <th></th>
                        <th></th>
                        <th>TOTAL:</th>
                        <th>RM {{calculateTransactionTotal(receipt.TransactionList) |number:'1.0-2'}}</th>
                    </tr>
                </tfoot>
            </table>
            <hr />


            <div class="row">

                <div class="col d-flex align-items-end justify-content-end">
                    <button class="btn btn-info" *ngIf="receipt.Valid==false && sharedService.isBranchManager()"
                        (click)="submitSet()">Submit
                    </button>
                    <button class="btn btn-warning ms-1" *ngIf="receipt.Valid==false && sharedService.isBranchManager()"
                        (click)="addEvidenceForSet()">Add
                        Evidence
                    </button>
                    <!-- <button class="btn btn-danger ms-1" *ngIf="receipt.Valid==false && sharedService.isBranchManager()"
                        (click)="confirmBeforeDeletingSet()">Delete
                    </button> -->

                    <div class="row">
                        <div class="col-12 d-flex justify-content-end align-items-end"
                            *ngIf="receipt.Valid==undefined|| receipt.Valid==true">
                            <textarea cols="40" rows="2" required [(ngModel)]="checkerRemark"
                                name="checkerRemark"></textarea>
                        </div>
                        <div class="col-12 d-flex justify-content-end align-items-end">
                            <button class="btn btn-success ms-1" (click)="confirmBeforeValidate()"
                                *ngIf="receipt.Valid==undefined && (sharedService.isAdmin()||sharedService.isBossAccount() || sharedService.isFinance())">Validate</button>

                            <button class="btn btn-danger ms-1" (click)="confirmBeforeInvalidate()"
                                *ngIf="receipt.Valid==undefined || receipt.Valid==true">Invalidate</button>
                        </div>
                    </div>





                </div>
            </div>


        </div>
    </div>
</ng-container>