import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { Company } from 'src/model/company.model';
interface Report {
  companyName: string,
  companyId: number,
  totalIn: number,
  totalOut: number,
  assetBalance: number
}

@Component({
  selector: 'app-asset-summary-report',
  templateUrl: './asset-summary-report.component.html',
  styleUrls: ['./asset-summary-report.component.css'],
  providers: [DatePipe]
})
export class AssetSummaryReportComponent implements OnInit, OnDestroy {
  @Input() selectedGroup: number;
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() reportName: string;

  companyList: Company[] = new Array;
  companyIdList: number[] = new Array;
  reportList: Report[] = new Array;
  isCollapsed: boolean = false;
  routeSubscription: any;
  constructor(public enums: Enums, private activatedRoute: ActivatedRoute, private datePipe: DatePipe, private restapi: RestApiService, private constructApi: ConstructAPI) { }

  ngOnInit(): void {
    this.routeSubscription = this.activatedRoute.paramMap.subscribe(params => {
      this.selectedGroup = + params.get('companyGroupId');
      this.startDate = (params.get('startDate') == undefined) ? undefined : new Date(params.get('startDate'));
      this.endDate = (params.get('endDate') == undefined) ? undefined : new Date(params.get('endDate'));
      this.getCompaniesOfTheGroup(this.selectedGroup);


    });

  }
  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }

  getCompaniesOfTheGroup(groupId: number) {
    this.companyIdList.splice(0, this.companyIdList.length);
    this.companyList.splice(0, this.companyList.length);
    this.reportList.splice(0, this.reportList.length);

    var xhttp = this.restapi.getRequest(this.constructApi.getCompanyGroupCompanies(groupId));

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          var company = new Company(json.id, json.companyCode, null, null, null, null);
          this.companyList.push(company);
          this.companyIdList.push(json.id);

        }
        );
        this.getAssetTotalInOut(this.companyIdList, [this.enums.ASSET]);
        // for (let i = 0; i < this.companyList.length; i++) {
        //   this.getSavingAndAsset(this.companyList[i], [this.enums.ASSET, this.enums.SAVING]);
        // }
      }
    };

  }


  getAssetTotalInOut(companyIds: number[], types: number[]) {
    var startDateStr = (this.startDate == undefined) ? undefined : this.startDate.toISOString();

    if (this.endDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var addADayDate = new Date(Number(this.endDate) + (3600 * 1000 * 24) - 1);
    var endDateStr = (this.endDate == undefined) ? undefined : addADayDate.toISOString();

    var xhttp = this.restapi.getRequest(this.constructApi.getTransactionBalanceDetails(companyIds, types, startDateStr, endDateStr));

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {

          var index = (this.reportList.length > 0) ? this.reportList.findIndex(report => report.companyId == json.companyId) : -1;

          var company = this.companyList.find(company => company.ID === Number(json.companyId));

          if (index == -1) {
            var totalIn = (json.in == undefined) ? 0 : Number(json.in);
            var totalOut = (json.out == undefined) ? 0 : Number(json.out);
            var balance = (json.balance == undefined) ? 0 : Number(json.balance);
            var report = { companyId: json.companyId, companyName: company.Name, totalIn: totalIn, totalOut: totalOut, assetBalance: json.balance }
            this.reportList.push(report);
          }
          else {
            this.reportList[index].totalIn += Number(json.in);
            this.reportList[index].totalOut += Number(json.out);
            this.reportList[index].assetBalance += Number(json.balance);

          }

        }
        );

      }
    };
  }


  // getSavingAndAsset(company: Company, types: number[]) {
  //   var startDateStr = (this.startDate == undefined) ? undefined : this.startDate.toISOString();

  //   if (this.endDate != undefined)
  //     //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
  //     // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
  //     var addADayDate = new Date(Number(this.endDate) + (3600 * 1000 * 24) - 1);
  //   var endDateStr = (this.endDate == undefined) ? undefined : addADayDate.toISOString();

  //   var xhttp = this.restapi.getRequest(this.constructApi.getAllTransactions(company.ID, types, startDateStr, endDateStr));

  //   xhttp.onreadystatechange = () => {
  //     if (xhttp.readyState == 4 && xhttp.status == 200) {

  //       var negative = 0;
  //       var positive = 0;
  //       var jsonArray = JSON.parse((xhttp.responseText));
  //       jsonArray.forEach(json => {

  //         if (Number(json.amount) < 0)
  //           negative += Number(json.amount);
  //         else
  //           positive += Number(json.amount);
  //       }
  //       );
  //       var report = { companyId: company.ID, companyName: company.Name, totalIn: positive, totalOut: negative, savingBalance: 0, assetBalance: 0 }
  //       this.reportList.push(report)


  //       this.getTransactionBalance(company, this.enums.ASSET);
  //       this.getTransactionBalance(company, this.enums.SAVING);
  //     }
  //   };


  // }

  // getTransactionBalance(company: Company, type: number) {
  //   if (this.startDate != undefined)
  //     //in order to get the correct result, start date need to  minus 1 millisecond 
  //     var reduceASecDate = new Date(Number(this.startDate) - 1);
  //   var startDateStr = (this.startDate == undefined) ? undefined : reduceASecDate.toISOString();

  //   var xhttp = this.restapi.getRequest(this.constructApi.getCompanyTransactionBalance(company.ID, type, startDateStr));
  //   xhttp.onreadystatechange = () => {
  //     if (xhttp.readyState == 4 && xhttp.status == 200) {

  //       var json = JSON.parse((xhttp.responseText));
  //       var index = (this.reportList.length > 0) ? this.reportList.findIndex(report => report.companyId == company.ID) : -1;

  //       if (index != -1) {
  //         if (type == this.enums.SAVING)
  //           this.reportList[index].savingBalance += json.balance;
  //         else
  //           this.reportList[index].assetBalance += json.balance;
  //       }
  //     }
  //   };
  // }


}
