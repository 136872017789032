<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Quick Calculate EPF Settlement</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>


    <div class="modal-body">
        <div class="row">
            <div class="col-10 p-2" style="background-color:lavender ;">
                <mat-slide-toggle class="mat-slide-toggle-lg" [hideIcon]="true" color="primary" [(ngModel)]="isExtra"
                    name="isExtra" (ngModelChange)="changeInterestRate(true)">
                    EXTRA
                </mat-slide-toggle>
            </div>
            <div class="col-2">
                <button class="btn btn-dark" (click)="searchCustomer()" *ngIf="isExtra"><i
                        class="fas fa-search"></i></button>
            </div>
        </div>

        @if(isExtra==true)
        {<app-epf-limit-information [previousEPFLoans]="previousEPFLoans" [isEPFExtra]="isExtra" [epfDate]="epfDate"
            [epfAmount]="epfAmount"
            *ngIf="epfDate!=undefined && epfAmount!=undefined && epfAmount >= enums.epfAmountCategory[sharedService.checkEPFAmountLimitCategory(epfDate)].MinimumEPFAmount"
            [epfLimitRuleSet]="epfLimitRuleSet"></app-epf-limit-information>
        }

        <div class="p-1 m-1" style="background-color: red;"
            *ngIf="epfDate!=undefined && epfAmount!=undefined &&  
            enums.epfAmountCategory[sharedService.checkEPFAmountLimitCategory(epfDate)].MinimumEPFAmount!=undefined 
            && epfAmount < enums.epfAmountCategory[sharedService.checkEPFAmountLimitCategory(epfDate)].MinimumEPFAmount">
            <span class="fw-bold" style="color:white">
                TERM {{ enums.epfAmountCategory[sharedService.checkEPFAmountLimitCategory(epfDate)].LowerLimit}} - {{
                enums.epfAmountCategory[sharedService.checkEPFAmountLimitCategory(epfDate)].UpperLimit}} months AND EPF
                Amount less than RM {{
                enums.epfAmountCategory[sharedService.checkEPFAmountLimitCategory(epfDate)].MinimumEPFAmount
                |number:'1.0-2'}} is not
                allowed</span>
        </div>



        <div class="row">

            <div class="col-6">
                <mat-form-field class="halfWidth bar">
                    <input matInput class="textInputLong" [matDatepicker]="myDatepicker" [(ngModel)]="epfDate"
                        placeholder="EPF Settlement Date" [min]="sharedService.getTodayDate()" name="date"
                        [max]="sharedService.getMaxDateForEPF()" onkeydown="false"
                        (ngModelChange)="getLoanCalculatedProperties()" [disabled]="isExtra">
                    <mat-datepicker-toggle matSuffix [for]=" myDatepicker">
                    </mat-datepicker-toggle>
                    <mat-datepicker #myDatepicker name="date"></mat-datepicker>

                </mat-form-field>


            </div>
            <div class="col-6">
                <mat-form-field class="halfWidth bar">
                    <mat-label>EPF Amount (RM)</mat-label>
                    <input matInput type="number" required class="textInputLong" placeholder="epfAmount" name="amount"
                        [(ngModel)]="epfAmount" min=0 (keypress)="sharedService.isNumberKey($event)"
                        [disabled]="isExtra" (ngModelChange)="calculateFirstLoanPlan()">


                </mat-form-field>
            </div>
        </div>



        @if (isExtra==true){
        <div class="row">
            <div class="col-6">
                <mat-form-field class="halfWidth bar">
                    <mat-label>Loan Amount (RM)</mat-label>
                    <input matInput type="number" required class="textInputLong" placeholder="loanAmount"
                        name="loanAmount" [(ngModel)]="loanAmount" min=0 (keypress)="sharedService.isNumberKey($event)">
                </mat-form-field>
                <span class="badge text-bg-danger" style="font-size:0.8rem"
                    *ngIf="loanAmount!=undefined && loanAmount%50!=0">Make sure PRINCIPAL is multiple of 50</span>
                <span class="badge text-bg-danger ms-1" style="font-size:0.8rem" *ngIf="epfDate!=undefined && epfAmount!=undefined && loanAmount > sharedService.getEPFLimitPrincipal(epfDate,
                        epfAmount,epfLimitRuleSet) -
                        sharedService.calculatePreviousEPFLoanPrincipal(previousEPFLoans)">Principal
                    over limit! </span>

            </div>
            <ng-container *ngIf="loanAmount>0 && loanAmount!=undefined">

                <div class="col-6" *ngIf="loanAmount>0 && loanAmount!=undefined">
                    <mat-form-field class="halfWidth bar">
                        <mat-label>Settlement Extra Charges </mat-label>
                        <input matInput type="number" required class="textInputLong" placeholder="Extra Charges"
                            name="extraChargesCollected" [(ngModel)]="extraChargesCollected" min=0
                            (keypress)="sharedService.isNumberKey($event)" (keyup)="getSettlementCharges()">

                    </mat-form-field>
                    <p style="color: crimson;" *ngIf="extraChargesCollected!=0 && extraChargesCollected >= loanAmount">
                        Extra Charges not reasonable! Cannot be more than principal</p>
                    <p style="color: crimson;"
                        *ngIf="extraChargesCollected!=undefined && extraChargesCollected < (this.enums.MIN_SSC_PER_TERM *loanTerm)">
                        Extra Charges not reasonable! Cannot be less than minimum charges of
                        (RM{{this.enums.MIN_SSC_PER_TERM}} x {{loanTerm}} =RM
                        {{this.enums.MIN_SSC_PER_TERM *loanTerm}})</p>

                    <p class=" text-info"><strong>Suggested Charges : RM
                            {{sharedService.calculateMaxTotalExtraCharges(loanAmount,
                            loanTerm) | number:'1.0-2'}}</strong></p>
                </div>

                <div class="col-12 card "
                    *ngIf="settlementCharges!=undefined && extraChargesCollected >= enums.MIN_SSC_PER_TERM*loanTerm">
                    <div class="card-body shadow-lg">
                        <p>Settlement Stamp : RM {{settlementStamp/loanTerm|number:'1.0-2'}} x {{loanTerm}} = RM
                            {{settlementStamp}}
                        </p>
                        <p>Settlement SC : RM {{settlementSC/loanTerm|number:'1.0-2'}} x {{loanTerm}} = RM
                            {{settlementSC}}</p>
                        <p> Settlement Duit Lebih : RM {{settlementExtras/loanTerm |number:'1.0-2'}} x {{loanTerm}} = RM
                            {{settlementExtras}}
                        </p>
                    </div>
                </div>

            </ng-container>
        </div>
        <hr />
        <div class="card">
            <div class="card-body shadow-lg">
                <h6 class="fw-bold">Deductions
                    <span>
                        <button class="btn btn-dark" (click)="isCollapsed=true" *ngIf="isCollapsed==false"><i
                                class="fas fa-caret-up"></i>
                        </button>
                        <button class="btn btn-dark" (click)="isCollapsed=false" *ngIf="isCollapsed==true"><i
                                class="fas fa-caret-down"></i>
                        </button>
                    </span>
                </h6>
                <div id="collapseExample" [ngbCollapse]="isCollapsed">
                    <div class="row">

                        <div class="col-12 m-1">
                            <p>Stamp : {{processStamp}}</p>
                            <p>Service Charge : {{processSc}}</p>
                            <p>Ads : {{loanAmount*enums.MINIMUM_ADS_RATE}}</p>
                            <mat-form-field class="halfWidth bar">
                                <mat-label>Duit Lebih (RM)</mat-label>
                                <input matInput type="number" required class="textInputLong" placeholder="Duit Lebih"
                                    name="processDL" [(ngModel)]="processDL" min=0
                                    (keypress)="sharedService.isNumberKey($event)">

                            </mat-form-field>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="row">
            <hr />
            <div class="col-12  mb-2">
                <h4>Loan Term:</h4>
                <div class="card shadow-sm" style="background-color: #CBC3E3;">
                    <div class="card-body">
                        <h3 class="fw-bold"> {{loanTerm}} month(s) | Int: {{MINIMUM_INTEREST*100}}% </h3>
                    </div>
                </div>
            </div>
            <div class="col-12 mb-2">
                <h4>Minimum Settlement:</h4>
                <div class="card shadow-sm" style="background-color: #CBC3E3;">
                    <div class="card-body">
                        <h3 class="fw-bold">RM {{calculateTotalSettlement(loanAmount) +
                            calculateTotalCharges()||0|number:'1.0-2'}} |
                            <span class="fw-bold"
                                [ngClass]="{'text-danger':checkIfHittingRatioLimit(epfDate,epfAmount)==true}">

                                Ratio: {{((calculateTotalSettlement(loanAmount) +
                                calculateTotalCharges())/this.epfAmount)*100||0|number:'1.0-2'}}%

                            </span>
                        </h3>
                        <p class="m-0 p-0 font-italic">(RM{{calculateTotalSettlement(loanAmount)|number:'1.0-2'}} +

                            <span *ngIf="isExtra==true">RM{{calculateHandlingCharge(isExtra,loanAmount)|number:'1.0-2'}}
                                + </span>
                            RM{{calculateTotalCharges()-calculateHandlingCharge(isExtra,loanAmount) |number:'1.0-2'}})
                        </p>

                    </div>
                </div>
            </div>


            <div class="col-12  mb-2" *ngIf="loanAmount!=undefined && loanAmount>0">
                <h4>On Hand:</h4>
                <div class=" card shadow-sm" style="background-color:  #a7d4f1 ;">
                    <div class="card-body">
                        <h3 class="fw-bold pb-0 mb-0">
                            RM {{loanAmount - processStamp - processDL - processSc - loanAmount*0.10 -
                            (enums.MINIMUM_ADS_RATE*loanAmount)|number:'1.0-2'}}
                        </h3>
                    </div>
                </div>
            </div>
        </div>
        }

        @else{
        @if(this.maximumFirstCashOnHand>0){
        <div class="row"
            *ngIf="epfDate!=undefined && this.epfAmount!=undefined &&  
                enums.epfAmountCategory[sharedService.checkEPFAmountLimitCategory(epfDate)].MinimumEPFAmount!=undefined 
                && epfAmount >= enums.epfAmountCategory[sharedService.checkEPFAmountLimitCategory(epfDate)].MinimumEPFAmount">

            <div class="col-12" *ngIf="isExtra==false">
                <button class=" btn btn-primary mb-2" (click)="useFirstMaxPlan()" *ngIf="maximumFirstCashOnHand>0">Use
                    Maximum Plan</button>
            </div>
            <div class="col-12">
                <!-- <span class="text-danger" *ngIf="cashOnHand==undefined|| cashOnHand==0"><strong>Please key in CASH ON
                        HAND</strong></span> -->
                <mat-form-field class="halfWidth bar" subscriptSizing="dynamic">
                    <mat-label>Cash on hand</mat-label>
                    <input matInput type="number" required class="textInputLong" placeholder="loanAmount"
                        name="cashOnHand" [(ngModel)]="cashOnHand" min=0 (keypress)="sharedService.isNumberKey($event)"
                        (ngModelChange)="calculateLoanAmountByCOH()">
                </mat-form-field>

                <span class="text-success  mt-0 pt-0">Maximum: RM {{this.maximumFirstCashOnHand|number:'1.0-2'}}</span>

                <span class="badge text-bg-danger" style="font-size:0.8rem"
                    *ngIf="cashOnHand!=undefined && cashOnHand%100!=0">Make sure CASH ON
                    HAND is multiple of
                    100</span>
                <span class="badge text-bg-danger ms-1" style="font-size:0.8rem"
                    *ngIf="epfDate!=undefined && epfAmount!=undefined && isCashOnHandOverlimit()">Cash
                    On Hand over limit!</span>
            </div>

            <div class="col-12" *ngIf="isExtra==false && maximumExtraPrincipal>0 ">
                <div class="d-flex my-3">
                    <input class="form-check-input" type="checkbox" value="" id="checkboxPlanExtra"
                        [(ngModel)]="isPlannedExtra" (click)="resetPlannedExtra()" name="isPlannedExtra">
                    <label class="form-check-label px-2" for="checkboxPlanExtra">
                        Next Extra Loan
                    </label>
                </div>
                <div *ngIf="isPlannedExtra==true">
                    <span>Earliest Loan Date : <strong>{{sharedService.getTodayDate()|date:'dd-MM-yyyy'}}</strong>
                    </span>
                    <mat-form-field class="halfWidth bar  mt-2" subscriptSizing="dynamic">
                        <mat-label>Extra Amount (RM)</mat-label>
                        <input matInput type="number" required class="textInputLong" placeholder="plannedExtraPrincipal"
                            name="plannedExtraPrincipal" [(ngModel)]="plannedExtraPrincipal" min=0
                            (keypress)="sharedService.isNumberKey($event)">

                    </mat-form-field>
                    <span class="text-success mt-0 pt-0">Maximum: RM
                        {{this.calculatedBalancePlannedExtra|number:'1.0-2'}}</span>
                    <span class="badge text-bg-danger ms-1" style="font-size:0.8rem"
                        *ngIf="epfDate!=undefined && epfAmount!=undefined &&  isPlannedExtraOverlimit()">Planned
                        Extra over limit!</span>

                    @if(epfDate!=undefined && epfAmount!=undefined && isPlannedExtraOverlimit()==false){
                    <mat-form-field class="halfWidth bar mt-3" subscriptSizing="dynamic">
                        <mat-label>Extra Settlement Charges (RM)</mat-label>
                        <input matInput type="number" required class="textInputLong"
                            placeholder="plannedExtraSettlementCharges" name="plannedExtraSettlementCharges"
                            [(ngModel)]="plannedExtraSettlementCharges" min=0
                            (keypress)="sharedService.isNumberKey($event)"
                            (ngModelChange)="getPlannedSettlementCharges()">
                    </mat-form-field>


                    <p class=" text-info"><strong>Suggested Charges : RM
                            {{enums.MIN_SSC_PER_TERM * loanTerm| number:'1.0'}}</strong></p>
                    <span class="badge text-bg-danger ms-1" style="font-size:0.8rem"
                        *ngIf="epfDate!=undefined && epfAmount!=undefined && isPlannedSettlementChargesOverlimit()">Extra
                        Charges over limit!</span>
                    }
                </div>
            </div>

            <!--FIRST LOAN PLAN SUMMARY-->
            @if(cashOnHand>0 && isCashOnHandOverlimit()==false && isPlannedExtraOverlimit()==false &&
            isPlannedSettlementChargesOverlimit()==false){
            <h5 class=summaryLine><span>Summary</span></h5>
            <div class="col-12">
                <mat-expansion-panel (opened)="summaryOpenState = true" (closed)="summaryOpenState = false"
                    [expanded]="summaryOpenState">
                    <mat-expansion-panel-header>
                        <mat-panel-title><b>Total Loan</b></mat-panel-title>
                        <mat-panel-description class="d-flex justify-content-end">
                            <h6><span class="badge text-bg-danger" *ngIf="checkIsMaxPlan()==false"><i
                                        class="fas fa-exclamation-circle"></i> Not Max </span>
                                <span class="badge text-bg-success" *ngIf="checkIsMaxPlan()==true"><i
                                        class="fas fa-check"></i>
                                    Max Amount</span>
                            </h6>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="card totalLoanDiffCard" *ngIf="checkIsMaxPlan()==false">
                        <div class="card-body pb-0">
                            <table class="table table-borderless table-custom mb-0 totalLoanDiffTable">
                                <thead>
                                    <td></td>
                                    <td class="text-end">Principal</td>
                                    <td class="text-end">Settlement</td>
                                    <td class="text-end">On Hand</td>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Max</td>
                                        <td class="text-end">{{getFirstPlanTMaxTotal() | number:'1.0-2'}}</td>
                                        <td class="text-end">{{getFirstPlanMaxSettlement()| number:'1.0-2'}}
                                        </td>
                                        <td class="text-end">{{getFirstPlanMaxOnHand()| number:'1.0-2'}} </td>
                                    </tr>
                                    <tr>
                                        <td>Total</td>
                                        <td class="text-end">{{getUserFirstPlannedPrincipals() |number:'1.0-2'}}</td>
                                        <td class="text-end"> {{getUserFirstPlannedSettlement() |number:'1.0-2'}}</td>
                                        <td class="text-end">{{getUserFirstPlannedOnHand()|number:'1.0-2'}}</td>
                                    </tr>
                                    <tr>
                                        <td> <strong>Diff (-)</strong></td>
                                        <td class="text-end">
                                            <strong>
                                                {{getFirstPlanTMaxTotal()-getUserFirstPlannedPrincipals()|number:'1.0-2'}}</strong>

                                        </td>
                                        <td class="text-end">
                                            <strong>
                                                {{getFirstPlanMaxSettlement()-getUserFirstPlannedSettlement()|number:'1.0-2'}}</strong>

                                        </td>
                                        <td class="text-end">
                                            <strong>
                                                {{getFirstPlanMaxOnHand()-getUserFirstPlannedOnHand()|number:'1.0-2'}}</strong>
                                        </td>
                                    </tr>
                                </tbody>

                                <tfoot>
                                    <th colspan="2">
                                        <h6 class="mb-0 "> <strong>Total Ratio:
                                                {{(getUserFirstPlannedSettlement()/epfAmount)*100|number:'1.0-2'}}%</strong>
                                        </h6>
                                    </th>
                                    <td></td>
                                    <td></td>
                                    <td></td>

                                </tfoot>
                            </table>
                        </div>
                    </div>

                    <div class="card totalLoanMaxCard" *ngIf="checkIsMaxPlan()==true">
                        <div class="card-body pb-0">
                            <table class="table table-borderless table-custom table-primary totalLoanMaxTable ">
                                <thead>
                                    <td></td>
                                    <td class="text-end">Principal</td>
                                    <td class="text-end">Settlement</td>
                                    <td class="text-end">On Hand</td>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>First</td>
                                        <td class="text-end"> {{this.maximumFirstPrincipalByOnHand|number:'1.0-2'}}</td>
                                        <td class="text-end">
                                            {{calculateSettlementAmountWithMinimumCharges(maximumFirstPrincipalByOnHand,MINIMUM_INTEREST,false)
                                            |number:'1.0-2'}}</td>
                                        <td class="text-end"> {{this.sharedService.getEPFLimitCashOnHand(this.epfDate,
                                            this.epfAmount,maximumFirstPrincipalByOnHand)|number:'1.0-2'}}</td>
                                    </tr>
                                    <tr>
                                        <td>Extra</td>
                                        <td class="text-end">{{this.maximumExtraPrincipal|number:'1.0-2'}}</td>
                                        <td class="text-end">
                                            {{calculateSettlementAmountWithMinimumCharges(maximumExtraPrincipal,enums.MINIMUM_EXTRA_EPF_RATE,true)|number:'1.0-2'}}
                                        </td>
                                        <td class="text-end">
                                            @if(maximumExtraPrincipal>0){
                                            {{maximumExtraPrincipal-this.calculateMinimumDeductionWithoutDL(maximumExtraPrincipal)|number:'1.0-2'}}
                                            }
                                            @else{ 0 }

                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Total</th>
                                        <th class="text-end">{{getFirstPlanTMaxTotal() |number:'1.0-2'}}</th>
                                        <th class="text-end">{{getFirstPlanMaxSettlement() |number:'1.0-2'}}</th>
                                        <th class="text-end"> {{getFirstPlanMaxOnHand() |number:'1.0-2'}}</th>
                                    </tr>

                                </tbody>

                                <tfoot>
                                    <th colspan="2">
                                        <h6 class="pb-0 mb-0"> <strong>Total Ratio:
                                                {{(getFirstPlanMaxSettlement()/epfAmount)*100
                                                |number:'1.0-2'}}%</strong>
                                        </h6>
                                    </th>
                                    <td></td>
                                    <td></td>
                                    <td></td>

                                </tfoot>
                            </table>
                        </div>
                    </div>



                    <div class="col-12  mb-2">
                        <div class="row mt-2">
                            <div class="col-6">
                                <h4 class="mt-2">First Loan</h4>
                            </div>
                            <div class="col-6">
                                <h4 class="d-flex justify-content-end align-items-end"><span
                                        class="badge text-bg-primary">
                                        {{loanTerm}}
                                        month(s) | Int:
                                        {{MINIMUM_INTEREST*100}}%
                                    </span>
                                </h4>
                            </div>
                        </div>


                        <p><strong>Principal: RM {{this.loanAmount||0|number:'1.0-2'}}</strong></p>
                        <p><strong>Deductions : RM {{calculateMinimumDeduction(loanAmount,false) |number:'1.0-2'}} <i
                                    class="fas fa-info-circle text-primary" [ngbTooltip]="tooltipContent"
                                    style="cursor:pointer;"></i></strong>

                            <ng-template #tooltipContent>
                                <p class="text-start">Stamp : {{enums.MINIMUM_STAMP}}</p>
                                <p class="text-start">Service Charge : {{enums.MINIMUM_SC}}</p>
                                <p class="text-start">Ads : {{loanAmount*enums.MINIMUM_ADS_RATE}}</p>
                                <p class="text-start">Duit Lebih :
                                    {{sharedService.calculateRoundedDLByOnHand(cashOnHand)||0}}
                                </p>
                                <p>Processing :
                                    {{loanAmount*(this.enums.FIXED_SCHEME_PROCESSING_RATE / 100)}}</p>
                            </ng-template>
                        </p>
                    </div>
                    <div class="col-12  mb-2" *ngIf="loanAmount!=undefined && loanAmount>0">
                        <h4>On Hand:</h4>
                        <div class=" card shadow-sm" style="background-color:  #a7d4f1 ;">
                            <div class="card-body">
                                <h3 class="fw-bold pb-0 mb-0">
                                    RM {{this.loanAmount - calculateMinimumDeduction(loanAmount,false)|number:'1.0-2'}}
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mb-2" *ngIf="epfDate!=undefined && epfAmount!=undefined">
                        <h4>Minimum Settlement:</h4>
                        <div class="card shadow-sm" style="background-color: #dedbe7;">
                            <div class="card-body">


                                <h3 class="fw-bold">RM {{(calculateTotalSettlement(loanAmount) +
                                    calculateTotalCharges())||0|number:'1.0-2'}} |
                                    <span class="fw-bold"
                                        [ngClass]="{'text-danger':checkIfHittingRatioLimit(epfDate,epfAmount)==true}">
                                        Ratio: {{((calculateTotalSettlement(loanAmount) +
                                        calculateTotalCharges())/this.epfAmount)*100||0|number:'1.0-2'}}%
                                    </span>
                                </h3>
                                <p class="m-0 p-0 font-italic">(RM
                                    {{calculateTotalSettlement(loanAmount)|number:'1.0-2'}} +
                                    RM{{calculateTotalCharges() |number:'1.0-2'}})</p>
                                <hr />
                                <div class="row mt-1" *ngIf="loanAmount>0 && loanAmount!=undefined">
                                    <p class=" text-info"><strong>Settlement Charges : RM
                                            {{extraChargesCollected| number:'1.0-2'}}</strong>
                                        <span class="badge text-bg-warning"
                                            *ngIf="checkIsMinimumCharges(extraChargesCollected)">Minimum</span>
                                    </p>

                                    <div class="col-12"
                                        *ngIf="settlementCharges!=undefined && extraChargesCollected >= enums.MIN_SSC_PER_TERM *loanTerm">
                                        <p>Stamp : RM {{settlementStamp/loanTerm |number:'1.0-2'}} x {{loanTerm}}
                                            months= RM
                                            {{settlementStamp|number:'1.0-2'}}</p>
                                        <p>SC : RM {{settlementSC/loanTerm |number:'1.0-2'}} x {{loanTerm}} months =
                                            RM
                                            {{settlementSC|number:'1.0-2'}}
                                        </p>
                                        <p>Duit Lebih : RM {{settlementExtras/loanTerm |number:'1.0-2'}} x
                                            {{loanTerm}}
                                            months =
                                            RM
                                            {{settlementExtras |number:'1.0-2'}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        @if(isPlannedExtra==true && plannedExtraPrincipal!=undefined && plannedExtraPrincipal>0){
                        <div class="mt-3">
                            <p class="plannedExtraLine text-primary"><span>New Loan</span></p>
                            <div class="row">
                                <div class="col-6">
                                    <h4 class="mt-2">Extra</h4>
                                </div>
                                <div class="col-6">
                                    <h4 class="d-flex justify-content-end align-items-end"><span
                                            class="badge text-bg-primary"> {{loanTerm}} month(s) | Int:
                                            {{enums.MINIMUM_EXTRA_EPF_RATE*100}}%
                                        </span>
                                    </h4>
                                </div>
                            </div>
                            <p><strong>Earliest Loan Date :{{sharedService.getTodayDate()|date:'dd-MM-yyyy'}}</strong>
                            </p>
                            <p><strong>Principal: RM {{this.plannedExtraPrincipal||0|number:'1.0-2'}}</strong></p>
                            <p><strong>Deductions : RM {{calculateMinimumDeductionWithoutDL(plannedExtraPrincipal)
                                    |number:'1.0-2'}} <i class="fas fa-info-circle text-primary"
                                        [ngbTooltip]="tooltipContent" style="cursor:pointer;"></i></strong>

                                <ng-template #tooltipContent>
                                    <p class="text-start">Stamp : {{enums.MINIMUM_STAMP}}</p>
                                    <p class="text-start">Service Charge : {{enums.MINIMUM_SC}}</p>
                                    <p class="text-start">DL : {{plannedExtraPrincipal*(this.enums.MINIMUM_ADS_RATE)}}
                                    </p>
                                    <p class="text-start">Processing :
                                        {{plannedExtraPrincipal*(this.enums.FIXED_SCHEME_PROCESSING_RATE / 100)}}
                                    </p>
                                </ng-template>
                            </p>
                            <div class="col-12  mb-2" *ngIf="loanAmount!=undefined && loanAmount>0">
                                <h4>On Hand:</h4>
                                <div class=" card shadow-sm" style="background-color:  #a7d4f1 ;">
                                    <div class="card-body">
                                        <h3 class="fw-bold pb-0 mb-0">
                                            RM {{this.plannedExtraPrincipal -
                                            calculateMinimumDeductionWithoutDL(plannedExtraPrincipal)|number:'1.0-2'}}
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <h4>Minimum Settlement:</h4>
                            <div class="card shadow-sm" style="background-color: #dedbe7;">
                                <div class="card-body">


                                    <h3 class="fw-bold">RM
                                        {{(calculateTotalSettlement(plannedExtraPrincipal,enums.MINIMUM_EXTRA_EPF_RATE)
                                        +
                                        calculateTotalCharges()+calculateHandlingCharge(true,plannedExtraPrincipal))||0|number:'1.0-2'}}
                                        |
                                        <span class="fw-bold"
                                            [ngClass]="{'text-danger':checkIfHittingRatioLimit(epfDate,epfAmount)==true}">
                                            Ratio: {{((calculateTotalSettlement(plannedExtraPrincipal) +
                                            calculateTotalCharges()+calculateHandlingCharge(true,plannedExtraPrincipal))/this.epfAmount)*100||0|number:'1.0-2'}}%
                                        </span>
                                    </h3>
                                    <p class="m-0 p-0 font-italic">(RM
                                        {{calculateTotalSettlement(plannedExtraPrincipal)|number:'1.0-2'}}
                                        + RM{{calculateHandlingCharge(true,plannedExtraPrincipal) |number:'1.0-2'}}
                                        + RM{{calculateTotalCharges() |number:'1.0-2'}})</p>
                                    <hr />
                                    <div class="row mt-1"
                                        *ngIf="plannedExtraPrincipal>0 && plannedExtraPrincipal!=undefined">
                                        <p class=" text-info"><strong>Settlement Charges : RM
                                                {{plannedExtraSettlementCharges| number:'1.0-2'}}</strong>
                                            <span class="badge text-bg-warning"
                                                *ngIf="checkIsMinimumCharges(plannedExtraSettlementCharges)">Minimum</span>
                                        </p>

                                        <div class="col-12"
                                            *ngIf=" plannedSettlementCharges!=undefined && plannedExtraSettlementCharges >= (enums.MIN_SSC_PER_TERM *loanTerm)">
                                            <p>Stamp : RM {{plannedSettlementCharges.Stamp/loanTerm |number:'1.0-2'}} x
                                                {{loanTerm}}
                                                months= RM
                                                {{plannedSettlementCharges.Stamp|number:'1.0-2'}}</p>
                                            <p>SC : RM {{plannedSettlementCharges.ServiceCharge/loanTerm
                                                |number:'1.0-2'}} x {{loanTerm}} months =
                                                RM
                                                {{plannedSettlementCharges.ServiceCharge|number:'1.0-2'}}
                                            </p>
                                            <p>Duit Lebih : RM {{plannedSettlementCharges.Extras/loanTerm
                                                |number:'1.0-2'}} x
                                                {{loanTerm}}
                                                months =
                                                RM
                                                {{plannedSettlementCharges.Extras |number:'1.0-2'}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        }


                    </div>
                </mat-expansion-panel>
            </div>
            }
        </div>
        }
        }



    </div>

</form>