import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { Customer } from 'src/model/customer.model';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  leadList = new BehaviorSubject<any>([]);

  // private messageSource = new Subject('default message');
  currentLeadList = this.leadList.asObservable();

  constructor() { }

  updateLeadList(lead: Customer[]) {
    this.leadList.next(lead)
  }
}

