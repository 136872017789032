<div class="card mt-3">
    <div class="card-header loan-header  fw-bold">Loan Details</div>
    <div class="card-body">
        <div class="row">
            <div class="col col-12  col-lg-6" *ngIf="batchDate==undefined">
                <div>
                    <span>Loan Date</span><i class="far fa-calendar-alt ms-2"></i>
                </div>
                <!-- <div>
                    <input class="dateInput" type="date" [value]="getToday()" name="loanDate" ([ngModel])="loanDate">
                </div> -->
                <mat-form-field class="full-width-formField p-0">
                    <input matInput readonly [matDatepicker]="loanDatePicker" name="loanDate" [(ngModel)]="loanDate"
                        required (ngModelChange)="outputToParent()" [max]="sharedService.getTodayDate()"
                        onkeydown="return false">
                    <mat-datepicker-toggle matSuffix [for]="loanDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #loanDatePicker name="loanDate"></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col col-12  col-lg-6">
                <div><span>Loan Code</span></div>
                <div><input type="text" name="loanCode" [(ngModel)]="loanCode"></div>
            </div>

        </div>
        <div class="row">

            <div class="col col-12  col-lg-6" *ngIf="checkIfIsEPFFirstLoan()">
                <div><span>Cash On Hand (RM)</span></div>
                <div><input type="number" name="cashOnHand" [(ngModel)]="cashOnHand" min="0"
                        (keypress)="sharedService.isNumberKey($event)" (keyup)="fillInAllDefaultForEPFFirstLoan()">
                    <p style="color: crimson;" *ngIf="cashOnHand%100!=0">
                        Please make sure Cash On Hand is multiple of 100 (Eg: 100,300,800)</p>
                </div>
            </div>
            <div class="col col-12  col-lg-6">
                <div><span>Loan Amount (RM)</span></div>
                <div>
                    <input type="number" name="loanAmount" [(ngModel)]="loanAmount" min="0"
                        (keypress)="sharedService.isNumberKey($event)" (keyup)="getInterestCharge()"
                        [disabled]="checkIfIsEPFFirstLoan()">

                    <p style="color: crimson;" *ngIf="loanAmount%50!=0">
                        Please make sure Principal is multiple of 50 (Eg: 50,250,300)</p>
                </div>

            </div>

        </div>
        <div class="row">
            <div class="col col-12  col-lg-6">

                <mat-form-field>
                    <mat-label>Skim</mat-label>
                    <select matNativeControl required name="selectedOption" [(ngModel)]="selectedOption"
                        (change)="findSchemeInfo(selectedOption)" [disabled]="isEPF==true"> *
                        <option *ngFor="let skim of loanSchemeList" [value]="skim.ID">
                            {{skim.Name}} <span *ngIf="skim.Name!='P'||skim.Name!='EPF'">( {{skim.Interest}}%)</span>
                        </option>
                    </select>
                </mat-form-field>
            </div>
            <div class="col col-12  col-lg-6">
                <ng-template #tooltipContent>
                    <div>
                        <span>Loan Term and interest rate will varies based on the Skim
                            selected. You can also adjust them based on your need.</span>
                    </div>

                </ng-template>
                <div><span>Loan Term</span>
                    <i class="far fa-question-circle ms-2" placement="top" [ngbTooltip]="tooltipContent"
                        style="cursor:pointer;"></i>
                </div>
                <div>
                    <input type="number" name="loanTerm" [(ngModel)]="loanTerm" min="0"
                        (keypress)="sharedService.isNumberKey($event)" [disabled]="isNotSkimP()"
                        (change)="outputToParent()">
                    <p style="color: crimson;" *ngIf="loanTerm==0 || loanTerm==undefined">Please fill in loan term!!
                    </p>
                    <p style="color: crimson;" *ngIf="loanTerm!=undefined && loanTerm > 48">Loan Term not reasonable!
                        (MAXIMUM 48 months)</p>
                </div>
            </div>

        </div>
        <div class="row">

            <!--Normal : key in interest calculate monthly-->
            <div class="col col-12  col-lg-6"
                *ngIf="selectedSkim!=undefined && selectedSkim.Name !='P' && selectedSkim.Name !='EPF';else skimPMonthly">
                <div><span>Interest Rate</span><i class="far fa-question-circle ms-2" placement="top"
                        [ngbTooltip]="tooltipContent" style="cursor:pointer;"></i>
                </div>
                <div>
                    <input type="number" name="interestRate" [(ngModel)]="interestRate" min="0"
                        (keypress)="sharedService.isNumberKey($event)" disabled (change)="outputToParent()">
                    <p style="color: crimson;" *ngIf="selectedSkim">Default:
                        {{selectedSkim.Interest|number:'1.0-2'}}</p>
                </div>
            </div>

            <!--SKIM P : key in monthly calculate interest-->

            <ng-template #skimPMonthly>
                <div class="col col-12  col-lg-6" *ngIf="isEPF==false">
                    <div><span>Monthly Payment</span></div>
                    <div>
                        <input type="number" name="flexiMonthlyAmount" [(ngModel)]="flexiMonthlyAmount" min="0"
                            (keypress)="sharedService.isNumberKey($event)" (keyup)="outputToParent()">
                        <p style="color: crimson;" *ngIf="flexiMonthlyAmount==0 || flexiMonthlyAmount==undefined">Please
                            fill in monthly payment!!

                        <p style="color: crimson;" *ngIf="flexiMonthlyAmount!=0 && flexiMonthlyAmount < 100">
                            Payment Amount not reasonable! (MINIMUM 100 per month)</p>
                    </div>
                </div>

                <ng-container *ngIf="isEPF==true">
                    <div class="col col-12  col-lg-6">
                        <div><span>Settlement Amount (WITHOUT Stamp)</span></div>
                        <div>
                            <input type="number" name="epfSettlementAmount" [(ngModel)]="epfSettlementAmount" min="0"
                                (keypress)="sharedService.isNumberKey($event)" (keyup)="calculateEPFMonthly()"
                                [disabled]="loanAmount==undefined || loanAmount<=0 ||loanTerm==undefined ||loanTerm<=0">
                            <p style="color: crimson;" *ngIf="epfSettlementAmount==0 || epfSettlementAmount==undefined">
                                Please
                                fill in EPF Settlement amount!!

                            <p style="color: crimson;" *ngIf="flexiMonthlyAmount!=0 && flexiMonthlyAmount < 100">
                                Payment Amount not reasonable! (MINIMUM 100 per month)</p>
                        </div>



                    </div>
                    <div class="col col-12  col-lg-6">

                        <div>
                            <div *ngIf="isEPFExtra==true">
                                <ng-template #handlingTooltip>
                                    <div>
                                        <span>Handling Charge is {{enums.MININUM_HANDLING_RATE *100}}% of Principal.
                                            For
                                            non-share account,it will be added in DUIT LEBIH.

                                            For RM {{loanAmount}}
                                            principal,
                                            supposed to have RM {{loanAmount*enums.MININUM_HANDLING_RATE}}</span>
                                    </div>
                                </ng-template>
                                <div><span>Handling Charge <i class="far fa-question-circle ms-2" placement="top"
                                            [ngbTooltip]="handlingTooltip" style="cursor:pointer;"></i></span></div>

                                <input type="number" name="handlingCharge" [(ngModel)]="handlingCharge" min="0"
                                    (keypress)="sharedService.isNumberKey($event)"
                                    [disabled]="loanAmount==undefined || isManualKeyIn==false">
                            </div>

                            <ng-container *ngIf="isManualKeyIn==false">
                                <div><span>Settlement Extra Charges</span></div>
                                <input type="number" name="extraChargesCollected" [(ngModel)]="extraChargesCollected"
                                    min="0" (keypress)="sharedService.isNumberKey($event)"
                                    [disabled]="epfSettlementAmount==undefined || epfSettlementAmount<=0 || checkIfIsEPFFirstLoan()==true"
                                    (keyup)="getSettlementCharges()" *ngIf="isManualKeyIn==false">

                                <p style=" color: crimson;"
                                    *ngIf="extraChargesCollected!=0 && extraChargesCollected/loanTerm >= loanAmount">
                                    Extra Charges not reasonable! Extra Charges per term cannot be more than principal
                                </p>
                                <p style="color: crimson;"
                                    *ngIf="extraChargesCollected!=undefined && extraChargesCollected < (this.enums.MIN_SSC_PER_TERM *loanTerm)">
                                    Extra Charges not reasonable! Cannot be less than minimum charges of
                                    (RM{{this.enums.MIN_SSC_PER_TERM}} x {{loanTerm}} =RM
                                    {{this.enums.MIN_SSC_PER_TERM *loanTerm}})</p>

                                <p style="color: crimson;"
                                    *ngIf="extraChargesCollected!=undefined && extraChargesCollected%1!=0 ">
                                    Extra Charges cannot have cents</p>

                            </ng-container>


                            <ng-container *ngIf="isManualKeyIn==true ">
                                <div><span>Settlement Stamp</span></div>
                                <input type="number" name="settlementStamp" [(ngModel)]="settlementStamp" min="0"
                                    (keypress)="sharedService.isNumberKey($event)"
                                    [disabled]="epfSettlementAmount==undefined || epfSettlementAmount<=0"
                                    (keyup)="manualSetSettlementCharges()">
                                <div><span>Settlement SC</span></div>
                                <input type="number" name="settlementSC" [(ngModel)]="settlementServiceCharge" min="0"
                                    (keypress)="sharedService.isNumberKey($event)"
                                    [disabled]="epfSettlementAmount==undefined || epfSettlementAmount<=0"
                                    (keyup)="manualSetSettlementCharges()">
                                <div><span>Settlement DL</span></div>
                                <input type="number" name="settlementExtra" [(ngModel)]="settlementExtras" min="0"
                                    (keypress)="sharedService.isNumberKey($event)"
                                    [disabled]="epfSettlementAmount==undefined || epfSettlementAmount<=0"
                                    (keyup)="manualSetSettlementCharges()">



                                <p style="color: crimson;"
                                    *ngIf="settlementExtras==undefined ||settlementServiceCharge==undefined || settlementStamp==undefined ">
                                    Please fill in all charges details</p>
                            </ng-container>
                            <p class=" text-primary" *ngIf="checkIfIsEPFFirstLoan()==true "><strong>Suggested Charges :
                                    RM
                                    {{enums.MIN_SSC_PER_TERM * loanTerm| number:'1.2-2'}}</strong></p>
                            <p class=" text-primary" *ngIf="isEPFExtra==true"><strong>Suggested Charges : RM
                                    {{sharedService.calculateMaxTotalExtraCharges(loanAmount,loanTerm)|
                                    number:'1.0'}}</strong></p>


                        </div>
                    </div>

                    <div class="col col-12  col-lg-6">
                        <div><span>Total Settlement Amount</span></div>
                        <div class="card shadow-sm"
                            [ngClass]="{'manualKeyInSettlementCard':isManualKeyIn==true ,'normalSettlementCard':isManualKeyIn==false}">
                            <div class="card-body">
                                <h4 class="fw-bold"> RM {{calculateEPFSettlementIncludeCharges()}}
                                    <span
                                        *ngIf="extraChargesCollected >= this.enums.MIN_SSC_PER_TERM && extraChargesCollected!=undefined && extraChargesCollected < loanAmount && isManualKeyIn==false">
                                        (
                                        {{epfSettlementAmount}}+{{extraChargesCollected}} <span
                                            *ngIf="handlingCharge>0 && isEPFExtra==true">+ {{handlingCharge}}</span>
                                        )
                                    </span>

                                    <span *ngIf="isManualKeyIn==true">
                                        (
                                        {{epfSettlementAmount}}+{{settlementStamp +
                                        settlementServiceCharge+settlementExtras}}
                                        <span *ngIf="handlingCharge>0 && isEPFExtra==true">+ {{handlingCharge}}</span>
                                        )
                                    </span>
                                </h4>
                                <table class="table table-borderless"
                                    *ngIf="settlementCharges!=undefined && extraChargesCollected >= (this.enums.MIN_SSC_PER_TERM*loanTerm) && extraChargesCollected < loanAmount  && extraChargesCollected%1==0">
                                    <tr>
                                        <td>Stamp: RM {{settlementCharges.Stamp/loanTerm |number:'1.2-2'}} x
                                            {{loanTerm}} </td>
                                        <td>=</td>
                                        <td>RM {{settlementCharges.Stamp }}</td>
                                    </tr>
                                    <tr>
                                        <td> SC: RM {{settlementCharges.ServiceCharge/loanTerm|number:'1.2-2'}} x
                                            {{loanTerm}} </td>
                                        <td>=</td>
                                        <td>RM {{settlementCharges.ServiceCharge}}</td>
                                    </tr>
                                    <tr *ngIf="settlementCharges.Extras>0">
                                        <td>DL: RM {{settlementCharges.Extras/loanTerm |number:'1.0-2'}} x
                                            {{loanTerm}} </td>
                                        <td>~ </td>
                                        <td>RM {{settlementCharges.Extras }}</td>
                                    </tr>
                                </table>

                            </div>
                        </div>
                    </div>
                </ng-container>

            </ng-template>

            <div class="col col-12  col-lg-6">

                <!--calculate monthly amount-->
                <div class="mt-3"
                    *ngIf="selectedSkim!=undefined && (selectedSkim.Name !='P' && selectedSkim.Name !='EPF');else skimPInterest">
                    <h3 class="fw-bold" style="color: lightseagreen;">Monthly Payment: RM
                        {{calculateMonthlyAmount(interestRate,loanAmount,loanTerm)| number : '1.2-2' || 0}}
                    </h3>
                </div>

                <!--calculate interest rate (for skim P)-->
                <ng-template #skimPInterest>
                    <div class="mt-3" *ngIf="selectedSkim!=undefined && selectedSkim.Name =='P'">
                        <h3 class="fw-bold" [style.color]="getPositiveNegative()">Interest Rate:
                            {{calculateInterestRate(flexiMonthlyAmount,loanAmount,loanTerm) | number : '1.2-2'}}%
                        </h3>
                    </div>

                    <!--calculate interest rate (for skim EPF)-->

                    <div class="mt-3" *ngIf="selectedSkim!=undefined && selectedSkim.Name =='EPF'">
                        <h3 class="fw-bold" [style.color]="getPositiveNegative()">Interest Rate:
                            {{interestRate | number : '1.2-2'}}%
                            <span *ngIf="isEPF==true"> (Monthly: RM {{flexiMonthlyAmount| number : '1.2-2'}})</span>
                        </h3>
                    </div>
                </ng-template>

            </div>
        </div>


        <div class="card mt-3" [hidden]="selectedSkim!=undefined && selectedSkim.Name=='HUTANG'">
            <div class="card-header deduction-header fw-bold"> Deductions</div>
            <div class="card-body">
                <div class="row">

                    <ng-container>
                        <div class="col col-12  col-lg-6">
                            <div><span>Stamp Amount</span></div>
                            <div><input type="number" name="stampAmount" [(ngModel)]="stampAmount" min="0"
                                    (keyup)="outputToParent()" (keypress)="sharedService.isNumberKey($event)"
                                    [disabled]="checkIfIsEPFDefaultLoan()">
                                <p style="color: crimson;" *ngIf="isEPF==true  && stampAmount < enums.MINIMUM_STAMP">
                                    Minimum Stamp = RM {{enums.MINIMUM_STAMP}}</p>
                            </div>
                        </div>
                        <div class="col col-12  col-lg-6">
                            <div><span>Service Charge</span></div>
                            <div><input type="number" name="serviceCharge" [(ngModel)]="serviceCharge" min="0"
                                    (keyup)="outputToParent()" (keypress)="sharedService.isNumberKey($event)"
                                    [disabled]="checkIfIsEPFDefaultLoan()">
                                <p style="color: crimson;" *ngIf="isEPF==true && serviceCharge < enums.MINIMUM_SC">
                                    Minimum SC = RM {{enums.MINIMUM_SC}}</p>
                            </div>
                        </div>
                    </ng-container>




                    <div class="col col-12  col-lg-6">
                        <ng-template #processsingTooltip>
                            <div>
                                <span>Processing Fee will be different for different scheme</span>
                            </div>
                        </ng-template>
                        <div>
                            <span>Processing Fee</span>
                            <i class="far fa-question-circle ms-2" placement="top" [ngbTooltip]="processsingTooltip"
                                style="cursor:pointer;"></i>
                        </div>
                        <div>
                            <input type="number" name="interestCharge" [(ngModel)]="interestCharge" min="0"
                                (keypress)="sharedService.isNumberKey($event)" disabled (keyup)="outputToParent()">
                            <span class="text-danger"
                                *ngIf="selectedSkim!=undefined &&  selectedSkim.Name=='A' && interestCharge==0">*Please
                                do not leave empty*</span>
                        </div>

                        <ng-container *ngIf="selectedSkim!=undefined && selectedSkim.Name =='A'">

                            <ng-container *ngFor="let pr of enums.SKIM_A_PROCESSING_RATES">
                                <button class="btn btn-dark ms-1" (click)="calculateInterestCharge(pr)">
                                    {{pr}}%</button>
                            </ng-container>

                            <!-- <button class="btn btn-info ms-1" (click)="calculateInterestCharge(9)">
                                9%</button>
                            <button class="btn btn-dark ms-1" (click)="calculateInterestCharge(10)">
                                10%</button>
                            <button class="btn btn-primary ms-1" (click)="calculateInterestCharge(11)">
                                11%</button>
                            <button class="btn btn-info ms-1" (click)="calculateInterestCharge(12)">
                                12%</button> -->
                        </ng-container>

                    </div>
                </div>

            </div>
        </div>

        <div class="card mt-3">
            <div class="card-header deduction-header fw-bold"> Duit Lebih</div>
            <div class="card-body">
                <ng-container *ngIf="isEPF==true && isEPFExtra==false && isManualKeyIn==false ;else manualKeyInDL">
                    <h5>Ads Duit Lebih: {{adsDuitLebih||0}}</h5>
                    <h5>Duit Lebih:
                        <span
                            *ngIf="checkIfIsEPFFirstLoan()==true">{{this.sharedService.calculateRoundedDLByOnHand(this.cashOnHand)||0}}</span>
                        <span *ngIf="isEPFExtra==true">{{this.extras||0}}</span>
                    </h5>
                    <h5><strong>Total Duit Lebih: {{
                            this.sharedService.calculateDuitLebihAmount(duitLebihList)||0}}</strong> </h5>
                </ng-container>


                <ng-template #manualKeyInDL>
                    <app-added-duit-lebih-list (getDuitLebihData)="getDuitLebihData($event)" [isEPF]="isEPF"
                        [loanAmount]="loanAmount"></app-added-duit-lebih-list>
                    <h4 class="text-primary fw-bold mt-1" *ngIf="duitLebihList.length>0"> Duit Lebih: RM
                        {{calculateTotalDuitLebih()||0}}</h4>
                </ng-template>

            </div>
        </div>
        <br />


        <div *ngIf="availableReserve>0 && isPayWithSimpan==true">
            <!-- <h4 class="fw-bold">Available Simpan: RM {{availableReserve}}</h4> -->
            <div class="col col-12  col-lg-6">
                <div><span>Simpan Out</span></div>
                <div><input type="number" name="reserve" [(ngModel)]="reserve" min="0" (keyup)="outputToParent()"
                        (keypress)="sharedService.isNumberKey($event)" [disabled]="isPayWithSimpan">
                </div>
            </div>
        </div>
    </div>
</div>