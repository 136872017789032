import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Company } from 'src/model/company.model';
import { Contact } from 'src/model/contact.model';
import { Customer } from 'src/model/customer.model';

@Component({
  selector: 'app-guarantor-form',
  templateUrl: './guarantor-form.component.html',
  styleUrls: ['./guarantor-form.component.css']
})
export class GuarantorFormComponent implements OnInit {

  selectedCompany: Company;
  selectedCustomer: Customer;
  selectedCustomerID: number;
  selectedCompanyID: number;
  selectedContactID: number;
  contact: Contact;

  date: Date;
  amount: number;
  formJDate: Date;

  epfSettlementAge: number;

  @ViewChild('htmlData') htmlData: ElementRef;

  routeSubscription: any;

  constructor(private activatedRoute: ActivatedRoute, private restapi: RestApiService, private constructApi: ConstructAPI, public sharedService: SharedService) {
    this.routeSubscription = this.activatedRoute.paramMap.subscribe(async params => {
      this.selectedCustomerID = + params.get('customerId');
      this.selectedCompanyID = + params.get('companyId');
      this.selectedContactID = + params.get('contactId');

      this.selectedCompany = await this.getCompanyInfo();
      this.selectedCustomer = await this.getCustomerInfo();
      this.contact = await this.getContactInfo();

      this.epfSettlementAge = this.sharedService.defineEPFTier(new Date(this.selectedCustomer.DOB));


    })
  }

  ngOnInit(): void {
  }



  getContactInfo() {
    return new Promise<Contact>(resolve => {
      var xhttp = this.restapi.getRequest(this.constructApi.getContactDetails(this.selectedContactID))
      xhttp.onreadystatechange = () => {
        if (xhttp.readyState == 4 && xhttp.status == 200) {
          var json = JSON.parse((xhttp.responseText));
          resolve(new Contact(json.id, json.name, json.icNumber, json.number, undefined, undefined, undefined, undefined));

        }
      }
    });

  }

  getCompanyInfo() {
    return new Promise<Company>(resolve => {
      var xhttp = this.restapi.getRequest(this.constructApi.getCompanyDetails(this.selectedCompanyID))
      xhttp.onreadystatechange = () => {
        if (xhttp.readyState == 4 && xhttp.status == 200) {
          var json = JSON.parse((xhttp.responseText));
          resolve(new Company(json.id, json.name, json.address, json.companyCode, json.contactNo, json.regNo))

        }
      }
    });
  }



  getCustomerInfo() {

    return new Promise<Customer>(resolve => {
      var xhttp = this.restapi.getRequest(this.constructApi.getCustomerDetails(this.selectedCustomerID))
      xhttp.onreadystatechange = () => {
        if (xhttp.readyState == 4 && xhttp.status == 200) {
          var json = JSON.parse((xhttp.responseText));
          resolve(new Customer(json.id, json.name, json.occupation,
            json.salary, json.salaryDay, json.icNumber, json.contactNo, json.remark, json.ctosNumber, json.ctosStatus,
            json.status, json.dateCreated, json.handler, json.companyId, json.customerCode, json.companyName, json.reserve, json.badDebt, json.address, json.Profiles, json.lastFormJDate, undefined, json.epfDate, undefined, undefined, json.dob));

        }
      }
    });
  }
}
