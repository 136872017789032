<div class="m-3">
    <h3>Blacklist Records</h3>
    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Enter searching criteria">
            </mat-form-field>
        </div>
    </div>
    <div class="mat-elevation-z8 m-3">


        <table mat-table [dataSource]="dataSource" matSort>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let record">
                    {{record.name}}
                </td>
            </ng-container>

            <!-- ICNumber Column -->
            <ng-container matColumnDef="icNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ICNumber </th>
                <td mat-cell *matCellDef="let record">
                    {{sharedService.processICNum(record.icNumber)}}
                </td>
            </ng-container>

            <!-- Other Id Column -->
            <ng-container matColumnDef="otherId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Other Id </th>
                <td mat-cell *matCellDef="let record">
                    {{record.otherId}}
                </td>
            </ng-container>
            <!-- Status Column -->
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                <td mat-cell *matCellDef="let record">
                    {{record.status}}
                </td>
            </ng-container>

            <!-- CreatedBy Column -->
            <ng-container matColumnDef="createdBy">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> CreatedBy </th>
                <td mat-cell *matCellDef="let record">
                    {{record.createdBy}}
                </td>
            </ng-container>

            <!-- CreatedOn Column -->
            <ng-container matColumnDef="createdOn">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> CreatedOn </th>
                <td mat-cell *matCellDef="let record">
                    {{record.createdOn | date:'dd-MM-yyyy'}}
                </td>
            </ng-container>

            <!-- Remark Column -->
            <ng-container matColumnDef="remark">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Remark </th>
                <td mat-cell *matCellDef="let record">
                    {{record.remark }}
                </td>
            </ng-container>


            <!-- action Column -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
                <td mat-cell *matCellDef="let record">
                    <div class="ms-2">
                        <button class="btn btn-success" [disabled]="disabledSubmit" *ngIf="record.checkedBy==undefined"
                            [hidden]="record.releasedBy!=undefined"><i class="far fa-check-circle" #tooltip="matTooltip"
                                matTooltip="Release Customer" matTooltipPosition="above" matTooltipHideDelay="100"
                                matTooltipClass="tooltip-black"
                                (click)="confirmBeforeReleaseCustomer(record)"></i></button>

                        <h6><span class="badge text-bg-info" *ngIf="record.releasedBy!=undefined"> {{record.releasedBy}} |
                                {{record.releasedOn|date:'dd-MM-yyyy'}}</span></h6>

                    </div>

                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[100,200,300]" [pageSize]="100" showFirstLastButtons></mat-paginator>
    </div>
</div>