<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Change Loan Status = {{selectedLoan.ReceiptNumber }}({{selectedLoan.LoanCode}})</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body">

        <div class="row">
            <div class="col-12">
                <!--Status-->
                <mat-form-field appearance="fill">
                    <mat-label>Status</mat-label>
                    <mat-select [(ngModel)]="selectedStatus" name="selectedStatus">
                        <mat-option *ngFor="let status of enums.loanStatusString;let i=index" [value]="i+1">
                            {{status}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </div>
            <div class="col-12">
                <!--Date-->
                <mat-form-field class="full-width-formField bar">
                    <input matInput class="textInputLong" [matDatepicker]="myDatepicker" name="date" [(ngModel)]="date"
                        placeholder="Date" [max]="sharedService.getTodayDate()" onkeydown="return false">
                    <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                    <mat-datepicker #myDatepicker name="date"></mat-datepicker>
                </mat-form-field>
            </div>
        </div>




    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="submit()"
            [disabled]="disabledSubmit">Change</button>
        <button class="btn btn-outline-danger" type="button" (click)="reset()">Cancel</button>
    </div>

</form>