import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-import-new-company',
  templateUrl: './import-new-company.component.html',
  styleUrls: ['./import-new-company.component.css']
})
export class ImportNewCompanyComponent implements OnInit {

 
  constructor() { }

  ngOnInit(): void {
  }

}
