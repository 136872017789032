import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { DuitLebih } from 'src/model/duitLebih.model';
import { TransactionSubType } from 'src/model/transactionSubType.model';
import { AddDuitLebihComponent } from '../add-duit-lebih/add-duit-lebih.component';



@Component({
  selector: 'app-added-duit-lebih-list',
  templateUrl: './added-duit-lebih-list.component.html',
  styleUrls: ['./added-duit-lebih-list.component.css']
})
export class AddedDuitLebihListComponent implements OnInit, OnChanges {
  displayedColumns: string[] = ['Amount', 'SubType', 'Action'];
  dataSource = new MatTableDataSource;

  @Input() duitLebihList: DuitLebih[] = new Array;
  @Input() isEPF = false;
  @Input() isEPFExtra = false;
  @Input() loanAmount = 0;

  subtypeList: TransactionSubType[] = new Array;

  totalDuitLebihList: DuitLebih[] = new Array;
  @Output() getDuitLebihData: EventEmitter<any> = new EventEmitter();

  adsDuitLebih: number;
  adsDuitLebihObj: DuitLebih;

  constructor(private router: Router, private modalService: NgbModal, private cdr: ChangeDetectorRef, public enums: Enums, private restapi: RestApiService,
    private constructApi: ConstructAPI, public sharedService: SharedService) { }


  ngOnChanges(changes: SimpleChanges) {
  }

  ngOnInit(): void {
    this.getTransactionSubTypes();

    //for edit purposes
    if (this.duitLebihList != undefined && this.duitLebihList.length > 0)
      this.refreshDataSource();
  }

  openAddDuitLebihModal() {
    const modalRef = this.sharedService.openModal(AddDuitLebihComponent);

    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var duitLebih = entry;
      this.duitLebihList.push(duitLebih);
      this.refreshTotalDuitLebihListAndOutput();
    });

    modalRef.result.then(() => {
      this.refreshDataSource();
    })
  }

  findTransactionSubTypeName(id: number) {

    if (this.subtypeList.length > 0) {
      var index = this.subtypeList.findIndex(type => Number(type.ID) == Number(id));
      return this.subtypeList[index].Name;
    }

  }

  setMinimumAds() {
    if (this.adsDuitLebih < this.calculateMinimumAds() || this.adsDuitLebih == undefined) {
      this.adsDuitLebih = this.calculateMinimumAds();


      this.updateAdsDuitLebih()
    }

  }

  removeDuitLebih(i: number) {

    this.duitLebihList.splice(Number(i), 1);
    this.refreshDataSource();
    this.refreshTotalDuitLebihListAndOutput();
  }

  getTransactionSubTypes() {
    var xhttp = this.restapi.getRequest(this.constructApi.getAllTransactionSubTypes());
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let type = new TransactionSubType(json.id, json.name, json.type, json.assignable, json.enabled);

          if (json.assignable == true)
            this.subtypeList.push(type);
        });
      }
    };
  }

  updateAdsDuitLebih() {

    var adsTypeObj = this.subtypeList.find(type => type.Name == "ADS")
    this.adsDuitLebihObj = new DuitLebih(this.adsDuitLebih, adsTypeObj.ID);

    this.refreshTotalDuitLebihListAndOutput();

  }

  // assignMinimumAdsDuitLebih() {
  //   //ASSIGN MINIMUM ADS DUIT LEBIH
  //   if (this.isEPF == true) {
  //     var adsTypeObj = this.subtypeList.find(type => type.Name == "ADS")
  //     var minimumAdsDuitLebih = new DuitLebih(this.loanAmount * this.enums.MINIMUM_ADS_RATE, adsTypeObj.ID);
  //     this.duitLebihList.push(minimumAdsDuitLebih);
  //     this.refreshDataSource();
  //     this.getDuitLebihData.emit(this.duitLebihList);
  //   }
  // }


  calculateMinimumAds() {
    return this.loanAmount * this.enums.MINIMUM_ADS_RATE;
  }


  refreshTotalDuitLebihListAndOutput() {
    // this.totalDuitLebihList = [];

    this.totalDuitLebihList = [];
    this.totalDuitLebihList = Object.assign([], this.duitLebihList);

    if (this.adsDuitLebihObj != undefined && this.adsDuitLebih > 0)
      this.totalDuitLebihList.push(this.adsDuitLebihObj);

    this.getDuitLebihData.emit(this.totalDuitLebihList);
  }

  refreshDataSource() {
    this.dataSource.data = this.duitLebihList;
  }
}
