<div class="modal-header">
    <h4 class="modal-title">Share Profile - {{currentProfile.ID}} </h4>
    <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 aria-label="Close" aria-label="Close"
        (click)="closeModal()"></button>
</div>

<div class="modal-body">
    <div class="table table-bordered" *ngIf="possibleSharedProfileList!=undefined && manualKeyInSharedId==false">
        <thead>
            <tr>
                <th>Company Code</th>
                <th>Customer Name</th>
                <th class="d-none d-md-block">Customer IC</th>
                <th>Profile ID</th>
                <th>Request Interval</th>
                <th>Share?</th>
            </tr>
        </thead>


        <tbody>
            <tr *ngFor="let profile of possibleSharedProfileList">
                <td>{{profile.companyCode}}</td>
                <td>{{profile.customerName}}</td>
                <td class="d-none d-md-block">{{sharedService.processICNum(profile.icNumber) }}</td>
                <td>{{profile.profileId}}</td>
                <td>{{profile.requestApprovalInterval}}</td>
                <td><button class="btn btn-primary" (click)="sharePossibleProfile(profile.profileId)">Share!</button>
                </td>
            </tr>
        </tbody>

    </div>


    <mat-slide-toggle class="mat-slide-toggle-lg" [hideIcon]="true" color="primary" [(ngModel)]="manualKeyInSharedId"
        name="isEPF" (ngModelChange)="changeManualKeyInBoolean()">
        <span style="color:purple">Not in the list? Manual key in profile ID</span>
    </mat-slide-toggle>

    <div class="mt-4 d-flex justify-content-center align-items-center" *ngIf="manualKeyInSharedId==true">
        <div class="group">
            <input type="number" required class="textInputLong" name="profileIdToShare" [(ngModel)]="profileIdToShare"
                (keypress)="sharedService.isNumberKey($event)">
            <span class="highlight"></span>
            <span class="bar barLong"></span>
            <label>To Profile ID</label>
        </div>
    </div>



</div>
<div class="modal-footer d-flex justify-content-end align-items-right" *ngIf="manualKeyInSharedId==true">
    <button class="btn btn-outline-success" (click)="validateInput()">Share</button>
</div>