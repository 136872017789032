export class Company {


    constructor(private _id: number, private _name: string,
        private _address: string, private _companyCode: string, private _contactNo: string, private _regNo?: string, private _group?: string, private _dateOperating?: Date,
        private _unverifedCustomerCount?: number, private _requireConsentForm?: boolean, private _uid?: string) {

    }


    set ID(id: number) { this._id = id }
    get ID(): number { return this._id }

    set Name(name: string) { this._name = name }
    get Name(): string { return this._name }


    set CompanyCode(companyCode: string) { this._companyCode = companyCode }
    get CompanyCode(): string { return this._companyCode }


    set Address(address: string) { this._address = address }
    get Address(): string { return this._address }

    set ContactNo(contactNo: string) { this._contactNo = contactNo }
    get ContactNo(): string { return this._contactNo }

    set RegNum(regNo: string) { this._regNo = regNo }
    get RegNum(): string { return this._regNo }

    set Group(group: string) { this._group = group }
    get Group(): string { return this._group }

    set DateOperating(dateOperating: Date) { this._dateOperating = dateOperating }
    get DateOperating(): Date { return this._dateOperating }

    set UnverifiedCutsomerCount(unverifedCustomerCount: number) { this._unverifedCustomerCount = unverifedCustomerCount }
    get UnverifiedCutsomerCount(): number { return this._unverifedCustomerCount }

    get RequireConsentForm(): boolean { return this._requireConsentForm }
    set RequireConsentForm(value: boolean) { this._requireConsentForm = value }

    get UID(): string {
        return this._uid
    }
    set UID(value: string) {
        this._uid = value
    }
}