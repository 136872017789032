import { Component, OnInit, Input, ChangeDetectorRef, OnChanges } from '@angular/core';
import { Customer } from 'src/model/customer.model';
import { Contact } from 'src/model/contact.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddContactComponent } from 'src/app/AddThings/add-contact/add-contact.component';
import { RestApiService } from 'src/app/API/restapi';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Router } from '@angular/router';
import { UploadImageComponent } from 'src/app/Images/upload-image/upload-image.component';
import { ViewThumbnailsComponent } from 'src/app/Images/view-thumbnails/view-thumbnails.component';
import { EPF } from 'src/model/epf.model';
import { Profile } from 'src/model/profile.model';

@Component({
  selector: 'app-customer-contact-list',
  templateUrl: './customer-contact-list.component.html',
  styleUrls: ['./customer-contact-list.component.css']
})
export class CustomerContactListComponent implements OnInit, OnChanges {

  @Input() viewCustomer: Customer;
  @Input() selectedProfile: Profile;
  @Input() epfDetails: EPF;

  customerContactList: Contact[] = new Array;

  constructor(private router: Router, private modalService: NgbModal, private cdr: ChangeDetectorRef, private restapi: RestApiService, private constructApi: ConstructAPI,
    private enums: Enums, public sharedService: SharedService) { }

  ngOnInit() {
    // this.getContactInfo();
  }

  ngOnChanges() {
    if (this.customerContactList.length > 0)
      this.customerContactList.splice(0, this.customerContactList.length);
    this.getContactInfo();
  }



  getContactInfo() {

    // var xhttp = new XMLHttpRequest();
    // var url = "http://localhost:10080/api/v1/contacts?customerId=" + this.viewCustomerId;
    // xhttp.withCredentials = true;
    var index = 0;
    var xhttp = this.restapi.getRequest(this.constructApi.getCustomerContact(this.viewCustomer.ID));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {

          // create customer instance with all response data
          // let payment = new Payment(json.id, json.loanId, json.amount, json.date, json.accountId);

          let contact = new Contact(json.id, json.name, json.icNumber, json.number, json.address, json.customerId, json.relation, json.remark);

          //add customer instance into customer list
          this.customerContactList.push(contact);
          this.getGuarantorFormExistence(contact, index);
          index++;
        }
        );
      }
    };

  }

  downloadFile(filename: string, customerId: number) {
    var encoded = encodeURIComponent(filename);
    var xhr = this.restapi.getRequest(this.constructApi.getFullFile(customerId, encoded));
    xhr.responseType = "blob";
    xhr.onreadystatechange = () => {

      if (xhr.readyState === 4) {
        if (xhr.status == 200) {
          this.sharedService.generateDownloadLink(xhr, filename);
        }
        else {
          this.sharedService.openAlert("Some error occurred. Please try again later", this.enums.DANGER_ALERT);
        }
      }

    };
  }


  getGuarantorFormExistence(contact: Contact, index: number) {

    var xhttp = this.restapi.getRequest(this.constructApi.getCustomerContactGuarantorFormExistence(this.viewCustomer.ID, contact.ID));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var result = Boolean(JSON.parse((xhttp.responseText)));
        if (result == true)
          this.getContactGuarantorForm(contact, index)
      }
    };

  }



  private getContactGuarantorForm(contact: Contact, index: number) {

    var xhttp = this.restapi.getRequest(this.constructApi.getCustomerContactGuarantorForms(this.viewCustomer.ID, contact.ID));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {

          // let file = { label: json.Name, url: json.Data, contentType: json["Content-Type"] }

          this.customerContactList[index].GuarantorFilename = json.Name;
        }
        );

      }
    };

  }


  openEditContactModal(contact: Contact) {
    const modalRef = this.sharedService.openModal(AddContactComponent);
    modalRef.componentInstance.viewCustomerId = this.viewCustomer.ID;
    modalRef.componentInstance.contactToUpdate = contact;
  }

  confirmBeforeDelete(contact: Contact) {
    var proceed = false;
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Confirm to delete contact " + contact.Name + "?";
    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something\

      if (confirmation == true)
        this.deleteContact(contact.ID);
    });

    return proceed;
  }



  deleteContact(contactId: number) {

    var xhr = this.restapi.deleteRequest(this.constructApi.getDeleteContact(contactId));

    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        if (xhr.readyState == 4 && xhr.status == 200) {

          if (xhr.status == 200) {
            window.location.reload();
          }
          else {
            this.sharedService.openAlert("Opps, some errors occured. Please try again later", this.enums.DANGER_ALERT);
          }

        }
      }

    }
  }

  downloadGuarantorForm(contact: Contact) {
    var companyId = this.viewCustomer.CompanyId;
    this.router.navigateByUrl('/guarantorForm/' + companyId + "/" + this.viewCustomer.ID + "/" + contact.ID);
  }

  openAddContactModal() {
    const modalRef = this.sharedService.openModal(AddContactComponent);
    modalRef.componentInstance.viewCustomerId = this.viewCustomer.ID;

    //get added loan
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var contact = entry;

      //append added loan to loan list
      this.customerContactList.push(contact);
    });

    modalRef.result.then(() => {

      //detect changes
      this.cdr.detectChanges();
    })

  }

  openViewContactDocsModal(contactID: number) {
    const modalRef = this.sharedService.openModal(ViewThumbnailsComponent);
    modalRef.componentInstance.customerID = this.viewCustomer.ID;
    modalRef.componentInstance.contactID = contactID;
  }


  openUploadImageModal(contact: Contact, fileType: string) {
    const modalRef = this.sharedService.openModal(UploadImageComponent);

    modalRef.componentInstance.selectedCustomerId = this.viewCustomer.ID;
    modalRef.componentInstance.contactId = contact.ID;
    modalRef.componentInstance.contactDocType = fileType;

    modalRef.componentInstance.passEntry.subscribe((entry) => {

      window.location.reload();
    });

    modalRef.result.then(() => {
      //detect changes
      this.cdr.detectChanges();
    })

  }
}