<div class="row">
    <div class="col-12 ">
        <div class="row m-3">
            <div class="col-12 pt-3" [hidden]="sharedService.isBranchAccountHandler()==true">
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Company</mat-label>
                    <input class="companyInput" type="text" placeholder="Pick one" aria-label="Company" matInput
                        [formControl]="myControl" [matAutocomplete]="auto" [(ngModel)]="selectedCompanyCode">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                        <mat-option *ngFor="let company of filteredCompany |async" [value]="company.CompanyCode"
                            (onSelectionChange)="setSelectedCompany(company.CompanyCode)"
                            (click)="setSelectedCompany(company.CompanyCode)">
                            {{company.CompanyCode}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

            </div>
            <ng-container *ngIf="selectedCompany!=undefined">
                <div class="col-12">
                    <ul class="nav nav-tabs">
                        <li class="nav-item ">
                            <a class="nav-link active" role="tab"  data-bs-toggle="tab" href="#tabPaymentSheet">
                                <i class="fas fa-file-invoice-dollar"></i> Payment Sheet
                            </a>
                        </li>

                        <li class="nav-item ">
                            <a class="nav-link " role="tab"  data-bs-toggle="tab" href="#tabCustomerList">
                                <i class="fas fa-users"></i> Customer List
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a class="nav-link " role="tab"  data-bs-toggle="tab" href="#tabUnconfirmedPayments">
                                <i class="fas fa-tasks"></i> Unconfirmed Payments
                            </a>
                        </li>



                    </ul>
                </div>

                <div class="tab-content">
                    <!--tabPaymentSheet-->
                    <div class="tab-pane active" role="tabpanel" id="tabPaymentSheet">
                        <app-cp-payment-sheet [companyId]="selectedCompany" [isConfirmed]="true"></app-cp-payment-sheet>

                    </div>

                    <!--tabCustomerList-->
                    <div class="tab-pane" role="tabpanel" id="tabCustomerList">
                        <app-cp-customer-list [companyId]="selectedCompany"></app-cp-customer-list>

                    </div>

                    <!--tabUnconfirmedPayments-->
                    <div class="tab-pane" role="tabpanel" id="tabUnconfirmedPayments">
                        <app-cp-payment-sheet [companyId]="selectedCompany"
                            [isConfirmed]="false"></app-cp-payment-sheet>
                    </div>
                </div>
            </ng-container>




        </div>
    </div>
</div>

<!-- <app-cp-customer-list [companyId]="1"></app-cp-customer-list> -->