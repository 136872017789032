import { Injectable, NgModule } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DuitLebih } from 'src/model/duitLebih.model';
import { Loan } from 'src/model/loan.model';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { Enums } from './enums';
import { EPF } from 'src/model/epf.model';
import { SettlementCharges } from 'src/model/settlementCharges.model';
import { DatePipe } from '@angular/common';
import { LoanRequest } from 'src/model/loanRequest.model';
import { Contact } from 'src/model/contact.model';
import { Profile } from 'src/model/profile.model';
import { EPFLimit } from 'src/model/epfLimit.model';

@Injectable({
  providedIn: 'root',
})


export class SharedService {
  // Add 1 day and minus 1 millisecond to get last millisecond of the day
  readonly oneDayMinusOneMillisecond = (3600 * 1000 * 24) - 1;

  constructor(private modalService: NgbModal, private enums: Enums, private datepipe: DatePipe) { }

  getLastMsOfDate(aDate: Date): Date {
    aDate.setHours(0, 0, 0, 0);
    return new Date(aDate.getTime() + this.oneDayMinusOneMillisecond);
  }

  createLocalISODateEncodedString(date: Date) {
    let t = date;
    var tz = t.toString().split("+").pop()
    tz = (tz === undefined ? "Z" : tz.split(" ", 1)[0])
    return encodeURIComponent((new Date(t.getTime() - (t.getTimezoneOffset() * 60000)).toISOString().replace("Z", (tz === "" ? "Z" : "+" + tz.slice(0, 2).concat(":", tz.slice(-2))))));
  }
  getTodayDate() {
    return new Date();
  }

  getMaxDateForEPF() {
    //CURRENTLY MAX DATE OF EPF SETTLEMENT IS 3 YEARS FROM TODAY
    var date;

    var today = new Date();


    //MAX DATE COVERED WHOLE MONTH, EG: TODAY 25/7, MAX DATE = WHOLE JULY OF NEXT 2 YEARS
    date = new Date(today.getFullYear() + 2, today.getMonth() + 1, 0);
    return date;
  }

  isNumberKey(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46)
      return false;
    return true;
  }


  isNumberKeyAllowNegative(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 45 && charCode != 46)
      return false;
    return true;
  }

  openModal(content: any, modalClass?: string) {

    var modalOptions: NgbModalOptions = { backdrop: 'static', keyboard: false, animation: false }
    if (modalClass != undefined)
      modalOptions.windowClass = modalClass;
    const modalRef = this.modalService.open(content, modalOptions);

    return modalRef;
  }


  isBranchAccountHandler() {
    return this.isBookkeepingStaff() || this.isBranchManager()
  }

  isOperatingUser() {
    return this.isBookkeepingStaff() || this.isBranchManager() || this.isStaff()
  }

  isMonitoringUser() {
    return this.isBossAccount() || this.isAdmin() || this.isAuditor() || this.isFinance()
  }

  isBossAccount() {
    var roleId = Number(sessionStorage.getItem('roleId'));
    if (roleId == this.enums.BOSS) {
      return true;
    }

    else
      return false;
  }

  isFinance() {
    return Number(sessionStorage.getItem('roleId')) == this.enums.FINANCE;
  }

  isAdmin() {
    var roleId = Number(sessionStorage.getItem('roleId'));
    if (roleId == this.enums.ADMIN)
      return true;
    else
      return false;
  }

  isBranchManager() {
    var roleId = Number(sessionStorage.getItem('roleId'));

    if (roleId == this.enums.BRANCH_MANAGER)
      return true;
    else
      return false;
  }

  isExpenseAdmin() {
    var roleId = Number(sessionStorage.getItem('roleId'));
    if (roleId == this.enums.EXPENSE_ADMIN)
      return true;
    else
      return false;
  }

  isAuditor() {
    var roleId = Number(sessionStorage.getItem('roleId'));
    if (roleId == this.enums.AUDITOR)
      return true;
    else
      return false;
  }

  isBookkeepingStaff() {
    var roleId = Number(sessionStorage.getItem('roleId'));
    if (roleId == this.enums.BOOKKEEPING_STAFF)
      return true;
    else
      return false;
  }

  isStaff() {
    var roleId = Number(sessionStorage.getItem('roleId'));

    if (roleId == this.enums.STAFF)
      return true;
    else
      return false;
  }

  openAlert(message: string, type: number) {
    const modalRef = this.openModal(AlertDialogComponent);
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.alertType = type;
  }

  openErrorMessage(xhr: XMLHttpRequest) {
    const modalRef = this.openModal(AlertDialogComponent);
    modalRef.componentInstance.alertType = this.enums.DANGER_ALERT;
    var errorMessage = "";

    if (xhr.readyState == 4 && xhr.responseText != undefined) {
      errorMessage = xhr.responseText;


    }

    modalRef.componentInstance.message = "Opps, some error occurred. Please try again later = " + errorMessage;
  }




  processStatusCode(status: any) {
    var statusCode;
    if (status != null || status != undefined) {
      if (status.some(s => s === this.enums.ACTIVE_LOAN))
        statusCode = this.enums.ACTIVE_LOAN;
      else if (status.some(s => s === this.enums.WARNING_LOAN))
        statusCode = this.enums.WARNING_LOAN;
      else if (status.some(s => s === this.enums.BAD_DEBT_LOAN))
        statusCode = this.enums.BAD_DEBT_LOAN;
      else if (status.some(s => s === this.enums.CP_LOAN))
        statusCode = this.enums.CP_LOAN;
      else if (status.some(s => s === this.enums.LBD_LOAN))
        statusCode = this.enums.LBD_LOAN;
      else if (status.some(s => s === this.enums.SETTLED_LOAN))
        statusCode = this.enums.SETTLED_LOAN;

      return statusCode;
    }
    else
      return null;

  }


  processICNum(icNumber: string) {
    var icNumberFormat = icNumber.replace(/-/g, "",);
    var output = "";
    for (let i = 0; i < icNumberFormat.length; i++) {
      output += icNumberFormat[i];
      if (i == 5 || i == 7)
        output += "-"
    }
    return output;
  }

  sanitizeICNumInput(icNumber: string) {
    return icNumber.trim().replace(/-/g, "",);
  }


  processStringStripInvalidSymbol(str: string) {
    //strip invalid symbol : ';','&'
    return str.replace(/[&;]/g, "");
  }

  checkIfICValid(icNumber: string) {
    if (icNumber != undefined && icNumber != "") {
      const icNumberFormat = icNumber.replace(/-/g, "",);
      let regexp: RegExp = /^(?:[0-2]\d(?:0\d|1[0-2])(?:[0-2]\d|3[0-1])\d{2}[0-4][0-9]{3})|(?:[3-9]\d(?:0\d|1[0-2])(?:[0-2]\d|3[0-1])\d{2}[5-9]\d{3})$/

      return regexp.test(icNumberFormat);
    }
    else
      return false;
  }

  checkFormJExpired(lastFormJDate: Date) {
    if (lastFormJDate != undefined && lastFormJDate) {

      var monthDiff = new Date().getMonth() - new Date(lastFormJDate).getMonth() +
        (12 * (new Date().getFullYear() - new Date(lastFormJDate).getFullYear()))

      // var difference_time = new Date().getTime() - new Date(lastFormJDate).getTime();
      // var difference_day = difference_time / (1000 * 3600 * 24);

      if (monthDiff >= 9)
        return true
    }
    return false
  }


  getEPFTerm(epfDate: Date) {

    if (epfDate != undefined) {
      epfDate = new Date(epfDate);
      var monthDiff = 0;
      if (epfDate.getFullYear() == new Date().getFullYear()) {
        monthDiff = epfDate.getMonth() - new Date().getMonth();
      }
      else {
        var yearDiff = epfDate.getFullYear() - new Date().getFullYear();
        var epfMonth = epfDate.getMonth() + (yearDiff * 12);
        monthDiff = epfMonth - new Date().getMonth();
      }

      monthDiff = (monthDiff == 0) ? 1 : monthDiff;
      return monthDiff;
    }
  }


  getChargeableTerm(loanDate: Date) {

    if (loanDate != undefined) {
      loanDate = new Date(loanDate);
      var monthDiff = 0;
      if (loanDate.getFullYear() == new Date().getFullYear()) {
        monthDiff = new Date().getMonth() - loanDate.getMonth();
      }
      else {
        var yearDiff = new Date().getFullYear() - loanDate.getFullYear();
        var months = new Date().getMonth() + (yearDiff * 12);
        monthDiff = months - loanDate.getMonth();
      }

      monthDiff = (monthDiff == 0) ? 1 : monthDiff;
      return monthDiff;
    }
  }


  checkIsSeniorRestrictedCustomer(dob: Date) {
    var minimumRestrictedDate = new Date(dob.getFullYear() + this.enums.MINIMUM_SENIOR_RESTRICTED_AGE, dob.getMonth(), dob.getDate());
    var maximumRestrictedDate = new Date(dob.getFullYear() + this.enums.MAXIMUM_SENIOR_RESTRICTED_AGE, dob.getMonth(), dob.getDate() + this.enums.RESTRICTED_AGE_BUFFER_DAY);

    if (new Date() >= minimumRestrictedDate && new Date() <= maximumRestrictedDate)
      return true;

    else return false;
  }


  checkInvalidCustomerAge(dob: Date) {
    var minimumDOB = new Date(new Date().getFullYear() - this.enums.VALID_CUSTOMER_AGE, new Date().getMonth(), new Date().getDate());

    // make sure customer born before minimum customer dob
    if (dob != undefined && new Date(dob) > minimumDOB)
      return true;
    else return false;
  }

  calculateTotalOnHand(loan: Loan) {
    var duitLebihAmount = this.calculateDuitLebihAmount(loan.Extras);
    return loan.Principal - loan.Reserve - (loan.Principal * (loan.ProcessingRate / 100)) - loan.StampAmount - loan.ServiceCharge - duitLebihAmount

  }

  roundTo2Dec(num: number) {
    return this.roundToDec(num, 2)
  }

  roundToDec(oriNum: number, decimalPlace: number) {
    var roundNum = Math.pow(10, decimalPlace);
    return Math.round(oriNum * roundNum) / roundNum
  }


  // get the minimum epf amount of each term category
  checkEPFAmountLimitCategory(epfDate: Date) {
    var term = this.getEPFTerm(new Date(epfDate));

    for (let i = 0; i < this.enums.epfAmountCategory.length; i++) {
      if (term <= this.enums.epfAmountCategory[i].UpperLimit && term >= this.enums.epfAmountCategory[i].LowerLimit) {
        return i;

      }
    }
  }

  //get the epf limit category based on their epf date and epf amount
  //must do minimum epf amount check to check the input before proceed to this 
  checkEPFLimitCategory(epfDate: Date, epfAmount: number, epfLimitRuleSet?: EPFLimit[]) {

    var term = this.getEPFTerm(new Date(epfDate));
    var epfLimitRule = (epfLimitRuleSet == undefined) ? this.enums.epfLimitList : epfLimitRuleSet;

    for (let i = 0; i < epfLimitRule.length; i++) {

      //if maximum epf amount  & minimum epf amount == undefined, ignore  epf amount check
      var checkMaximumEPFAmount = (epfLimitRule[i].MaximumEPFAmount == undefined) ? true : epfAmount < epfLimitRule[i].MaximumEPFAmount;
      var checkMinimumEPFAmount = (epfLimitRule[i].MinimumEPFAmount == undefined) ? true : epfAmount >= epfLimitRule[i].MinimumEPFAmount;

      if (term <= epfLimitRule[i].UpperLimit && term >= epfLimitRule[i].LowerLimit) {
        if (checkMinimumEPFAmount && checkMaximumEPFAmount)
          return i;
        else if (epfLimitRule[i].IsMinimumnTier)
          return i;
      }
    }
  }

  getEPFLimitPrincipal(epfDate: Date, epfAmount: number, epfLimitRuleSet?: EPFLimit[]) {
    var limitCategory = this.checkEPFLimitCategory(epfDate, epfAmount, epfLimitRuleSet);
    var epfLimitRule = (epfLimitRuleSet == undefined) ? this.enums.epfLimitList : epfLimitRuleSet;

    return this.roundTo2Dec(epfAmount * epfLimitRule[limitCategory].OverallLoanRate);
  }

  getEPFLimitFirstLoan(epfDate: Date, epfAmount: number, epfLimitRuleSet?: EPFLimit[]) {
    var limitCategory = this.checkEPFLimitCategory(epfDate, epfAmount, epfLimitRuleSet);
    var epfLimitRule = (epfLimitRuleSet == undefined) ? this.enums.epfLimitList : epfLimitRuleSet;

    var settlement = this.getEPFLimitSettlement(epfDate, epfAmount, epfLimitRule);
    var maximumPrincipalBySettlementLimit = this.calculatePrincipalBySettlementLeft(settlement, this.getEPFTerm(epfDate), false);

    var firstLoanPrincipalByLimit = this.roundTo2Dec(epfAmount * epfLimitRule[limitCategory].FirstLoanRate);

    return (firstLoanPrincipalByLimit < maximumPrincipalBySettlementLimit) ? firstLoanPrincipalByLimit : maximumPrincipalBySettlementLimit;

  }


  calculateRoundedPrincipal(originalPrincipal: number) {
    var max = 0;
    if (originalPrincipal != undefined) {
      max = originalPrincipal;
    }
    return Math.floor(max / 50) * 50;
  }

  calculateRoundedDuitLebih(principal: number, epfDate: Date, epfAmount: number, originalPrincipal: number) {

    var principal = this.calculateRoundedPrincipal(originalPrincipal);
    var processingCharge = principal * 0.1;
    var ads = principal * 0.02;
    return 100 - ((principal + processingCharge + ads + this.enums.MINIMUM_STAMP + this.enums.MINIMUM_SC) % 100);
  }

  getEPFLimitCashOnHand(epfDate: Date, epfAmount: number, originalPrincipal: number) {
    var principal = this.calculateRoundedPrincipal(originalPrincipal);
    var processingCharge = principal * 0.1;
    var ads = principal * 0.02;
    var duitLebih = this.calculateRoundedDuitLebih(principal, epfDate, epfAmount, originalPrincipal);

    return principal - processingCharge - ads - duitLebih - this.enums.MINIMUM_STAMP - this.enums.MINIMUM_SC;
  }




  getEPFLimitSettlement(epfDate: Date, epfAmount: number, epfLimitRuleSet?: EPFLimit[]) {
    var limitCategory = this.checkEPFLimitCategory(epfDate, epfAmount, epfLimitRuleSet);
    var epfLimitRule = (epfLimitRuleSet == undefined) ? this.enums.epfLimitList : epfLimitRuleSet;

    return this.roundTo2Dec(epfAmount * epfLimitRule[limitCategory].MaximumSettlementRate);
  }

  getEPFElevateSettlement(epfDate: Date, epfAmount: number, epfLimitRuleSet?: EPFLimit[]) {
    var limitCategory = this.checkEPFLimitCategory(epfDate, epfAmount, epfLimitRuleSet);
    var epfLimitRule = (epfLimitRuleSet == undefined) ? this.enums.epfLimitList : epfLimitRuleSet;

    return this.roundTo2Dec(epfAmount * epfLimitRule[limitCategory].ElevatedSettlementRate);
  }


  calculatePreviousEPFLoanPrincipal(loanList: Loan[]) {

    var principalSum = 0;

    if (loanList != undefined) {
      for (let i = 0; i < loanList.length; i++) {
        principalSum += loanList[i].Principal;
      }

    }
    return principalSum;
  }


  calculateSettlementRatio(settlement: number, epfAmount: number) {
    return this.roundTo2Dec((settlement / epfAmount) * 100)

  }


  calculateEPFSettlementAmount(principal: number, interestRate: number, term: number) {

    return principal + (term * principal * (interestRate / 100))
  }


  calculateEPFSettlementAmountWithCharges(loan: Loan) {

    var settlement = loan.Principal + (loan.Term * loan.Principal * (loan.InterestRate / 100));
    // var termLeft = (loan.Balance == undefined) ? loan.Term : this.roundTo2Dec(loan.Balance / loan.MonthlyPayment);

    if (loan.SettlementCharges != undefined)
      settlement += loan.SettlementCharges.Stamp + loan.SettlementCharges.ServiceCharge + (loan.SettlementCharges.Extras || 0) + (loan.SettlementCharges.HandlingCharge || 0);
    else if (loan.SettlementExtra != undefined || loan.SettlementServiceCharge != undefined || loan.SettlementStamp != undefined)
      settlement += loan.SettlementExtra + loan.SettlementServiceCharge + loan.SettlementStamp;

    return settlement;
  }
  calculatePreviousEPFLoanSettlement(loanList: Loan[]) {
    var sum = 0;


    if (loanList != undefined) {

      for (let i = 0; i < loanList.length; i++) {


        var termLeft = (loanList[i].Balance == undefined) ? loanList[i].Term : this.roundTo2Dec(loanList[i].Balance / loanList[i].MonthlyPayment);


        if (loanList[i].LegacySettlement != undefined)
          sum += loanList[i].LegacySettlement;

        else if (loanList[i].Scheme == 'EPF') {
          var previousSettlementWithoutCharge = this.calculateEPFSettlementAmount(loanList[i].Principal, loanList[i].InterestRate, loanList[i].Term)

          if (loanList[i].SettlementCharges == undefined)
            sum += previousSettlementWithoutCharge + loanList[i].SettlementExtra + loanList[i].SettlementServiceCharge + loanList[i].SettlementStamp
          else
            sum += previousSettlementWithoutCharge + loanList[i].SettlementCharges.Extras + loanList[i].SettlementCharges.ServiceCharge + loanList[i].SettlementCharges.Stamp + (loanList[i].SettlementCharges.HandlingCharge || 0)

        }
      }
    }

    return sum;
  }


  calculateRoundedPrincipalByOnHand(cashOnHand: number) {
    var roundedPrincipal = Math.ceil(((cashOnHand + this.enums.MINIMUM_STAMP + this.enums.MINIMUM_SC) / 0.88) / 50) * 50;


    return roundedPrincipal;
  }



  calculateRoundedDLByOnHand(cashOnHand: number) {

    var oriPrincipal = (cashOnHand + this.enums.MINIMUM_STAMP + this.enums.MINIMUM_SC) / 0.88;
    var roundedPrincipal = Math.ceil(oriPrincipal / 50) * 50;

    return Math.round((roundedPrincipal - oriPrincipal) * 0.88);
  }



  calculatePrincipalBySettlementLeft(settlementLeft: number, term: number, isExtra: Boolean) {
    var principal
    if (isExtra == true)
      principal = (settlementLeft - (this.enums.MIN_SSC_PER_TERM * term)) / (1 + (term * this.enums.MINIMUM_EXTRA_EPF_RATE) + (this.enums.MININUM_HANDLING_RATE * term))
    else
      principal = (settlementLeft - (this.enums.MIN_SSC_PER_TERM * term)) / (1 + (term * this.enums.MINIMUM_NEW_EPF_RATE));

    return (principal < 0) ? 0 : principal;
  }

  calculateEPFDate(dob: Date) {
    // var epfTier1Date = new Date(dob.getFullYear() + this.enums.EPF_TIER_1, dob.getMonth(), dob.getDate());
    var epfTier2Date = new Date(dob.getFullYear() + this.enums.EPF_TIER_2, dob.getMonth(), dob.getDate());
    var epfTier3Date = new Date(dob.getFullYear() + this.enums.EPF_TIER_3, dob.getMonth(), dob.getDate());

    if (epfTier2Date > new Date())
      return epfTier2Date;
    else
      return epfTier3Date;
  }


  defineEPFTier(dob: Date) {
    // var epfTier1Date = new Date(dob.getFullYear() + this.enums.EPF_TIER_1, dob.getMonth(), dob.getDate());
    var epfTier2Date = new Date(dob.getFullYear() + this.enums.EPF_TIER_2, dob.getMonth(), dob.getDate());

    if (epfTier2Date > new Date())
      return this.enums.EPF_TIER_2;
    else
      return this.enums.EPF_TIER_3;
  }



  checkIsAllowEPF(dob: Date, epf: EPF, profileId: number, sharedProfileId: number) {

    //if is non shared epf / is main profile only can open epf request 
    //share epf request, main must have loan so need to open request from main's end
    if (sharedProfileId == undefined || sharedProfileId == profileId) {
      if (epf != undefined && epf.Date != undefined) {

        //default date might contain time section
        var epfDate = new Date(epf.Date);
        var formattedDate = new Date(epfDate.getFullYear(), epfDate.getMonth(), epfDate.getDate())

        //format date and compare
        return formattedDate <= this.getMaxDateForEPF() && formattedDate >= new Date();
      }

      else {
        var calculatedEPFDate = this.calculateEPFDate(new Date(dob));

        return calculatedEPFDate <= this.getMaxDateForEPF() && calculatedEPFDate >= new Date();
      }
    }
    else
      return false;



  }



  calculateDuitLebihAmount(extras: DuitLebih[]) {
    var duitLebihAmount = 0;
    if (extras != undefined && extras.length > 0) {
      for (let i = 0; i < extras.length; i++) {
        duitLebihAmount += extras[i].Amount;
      }
    }

    return duitLebihAmount;
  }

  checkIsPDF(contentType: string) {
    if (contentType == "application/pdf")
      return true;
    else
      return false;
  }

  checkIsVideo(contentType: string, filename: string) {
    if (contentType.includes("video/") == true)
      return true;
    else if (contentType.includes("application/octet-stream") == true) {
      let regexp: RegExp = /\.(mov)$/g;
      var isMovVideoExt = regexp.test(filename);
      return isMovVideoExt;
    }
    else
      return false;
  }

  checkIfDateIsToday(date: Date) {
    if (date.getFullYear() == new Date().getFullYear() && date.getMonth() == new Date().getMonth() && date.getDate() == new Date().getDate())
      return true;
    else
      return false;
  }


  getMaxCommissionAmount(loan: Loan) {
    var maxCommissionAmount = 0
    if (loan.Scheme == 'EPF') {
      maxCommissionAmount = this.roundTo2Dec(loan.Principal * this.enums.EPF_COMMISSION_MAX_RATE);
    }
    else {
      maxCommissionAmount = this.roundTo2Dec(loan.Principal * this.enums.OTHER_COMMISSION_MAX_RATE);
    }

    return maxCommissionAmount;
  }

  checkCommissionOverpay(loan: Loan, commission: number) {

    var maxCommissionAmount = this.getMaxCommissionAmount(loan);

    if (commission > maxCommissionAmount)
      return true;
    else return false;
  }

  //EXTRA CHARGERS DISTRIBUTION + CALCULATION
  calculateAndReturnSettlementCharges(principal: number, chargeCollected: number, loanTerm: number) {

    var maxTotalCharge = this.calculateMaxTotalExtraCharges(principal, loanTerm);

    var extraExtras = 0;
    if (chargeCollected > maxTotalCharge) {
      extraExtras = chargeCollected - maxTotalCharge;
      chargeCollected = maxTotalCharge;
    }


    var sscAmount = chargeCollected * this.enums.MAX_SSC_OVERCOLLETED;
    if (sscAmount < this.enums.MIN_SSC_PER_TERM * loanTerm)
      sscAmount = this.enums.MIN_SSC_PER_TERM * loanTerm;


    var serviceCharge = Math.ceil(sscAmount * this.enums.SC_PER_SSC);
    var stamp = Math.ceil(sscAmount - serviceCharge);
    var extras = chargeCollected - serviceCharge - stamp + extraExtras;

    return new SettlementCharges(serviceCharge, stamp, extras)
  }

  calculateEPFFirstLoanSettlementCharges(chargeCollected: number, loanTerm: number) {
    var minimumCollectCharge = this.enums.MIN_SSC_PER_TERM * loanTerm;

    var extras = chargeCollected - minimumCollectCharge;
    var serviceCharge = this.enums.MINIMUM_SC * loanTerm;
    var stamp = this.enums.MINIMUM_STAMP * loanTerm;

    console.log(new SettlementCharges(serviceCharge, stamp, extras))
    return new SettlementCharges(serviceCharge, stamp, extras)
  }


  calculateMaxTotalExtraCharges(principal: number, term: number) {
    return (this.enums.MIN_SSC_PER_TERM + this.enums.SSC_PER_TERM_PER_STEP * (principal - this.enums.MIN_SSC_STARTING_PRINCIPAL) / this.enums.PRINCIPAL_STEP) * term;
  }


  isLoanRelatedType(typeId: number) {
    return typeId == this.enums.PRINCIPAL || typeId == this.enums.REPAYMENT || typeId == this.enums.EXTRA_INTEREST || typeId == this.enums.MONTHLY_INTEREST
      || typeId == this.enums.ARREAR_CHARGES || typeId == this.enums.DISCOUNT
  }

  isLoanRelatedSubype(subTypeId: number) {
    return subTypeId == this.enums.LOAN_EXPENSE_SUBTYPE_ID
  }

  calculateLoanMonthlyPayment(scheme: string, principal: number, principalPaid: number, term: number, interestRate: number) {

    var interestRate = interestRate / 100;
    if (scheme == 'A')
      return (principal - principalPaid) * (interestRate)
    else
      return (principal * interestRate) + (principal / term);
  }


  isEPFDateValid(dob: Date, epfDate: Date) {
    var calculatedEPFDate = this.calculateEPFDate(new Date(dob));
    if (this.datepipe.transform(calculatedEPFDate, 'dd-MM-yyyy') == this.datepipe.transform(new Date(epfDate), 'dd-MM-yyyy'))
      return true;
    else
      return false;

  }

  isDOBValidWithIC(icNumber: string, dob: Date) {
    if (this.checkIfICValid(icNumber) == true) {
      var yearPartFromIC = icNumber.substring(0, 2);
      var monthPartFromIC = Number(icNumber.substring(2, 4));
      var dayPartFromIC = Number(icNumber.substring(4, 6));

      var currentCentury = new Date().getFullYear().toString().substring(0, 2);
      var constructedYear = Number(currentCentury + yearPartFromIC);
      var constructedDOB = new Date(constructedYear, monthPartFromIC - 1, dayPartFromIC)

      while (constructedDOB > new Date()) {
        constructedYear = constructedYear - 100;
        constructedDOB = new Date(constructedYear, monthPartFromIC - 1, dayPartFromIC)
      }

      if (this.datepipe.transform(constructedDOB, 'dd-MM-yyyy') == this.datepipe.transform(new Date(dob), 'dd-MM-yyyy'))
        return true;
      else
        return false;

    }
    else
      return false;



  }

  calculateInterestRate(monthly: number, principal: number, term: number) {

    var total = monthly * term;
    var interestRate;
    interestRate = (((total - principal) / principal) / term) * 100;

    return interestRate;


  }

  calculateMonthlyPayment(interestRate: number, principal: number, term: number) {
    return (((interestRate / 100) * term * principal) + principal) / term

  }


  processLoanRequest(json: any) {
    var loanInfoJson = json.loanInfo

    var stampAmount = (loanInfoJson.stamp == undefined) ? 0 : loanInfoJson.stamp;
    var serviceChargeAmount = (loanInfoJson.serviceCharge == undefined) ? 0 : loanInfoJson.serviceCharge;
    var reserve = (loanInfoJson.reserve == undefined) ? 0 : loanInfoJson.reserve;

    var extras = (loanInfoJson.extras == undefined) ? undefined : this.processDuitLebihJsonArr(loanInfoJson.extras);

    var settleStamp = (loanInfoJson.settlementInfo == undefined || loanInfoJson.settlementInfo.stamp == undefined) ? 0 : loanInfoJson.settlementInfo.stamp;
    var settleSc = (loanInfoJson.settlementInfo == undefined || loanInfoJson.settlementInfo.serviceCharge == undefined) ? 0 : loanInfoJson.settlementInfo.serviceCharge;
    var settleExtra = (loanInfoJson.settlementInfo == undefined || loanInfoJson.settlementInfo.extras == undefined) ? 0 : loanInfoJson.settlementInfo.extras;

    var settlementCharges = (loanInfoJson.settlementCharges == undefined) ? undefined : new SettlementCharges(loanInfoJson.settlementCharges.serviceCharge, loanInfoJson.settlementCharges.stamp, loanInfoJson.settlementCharges.extras, loanInfoJson.settlementCharges.handlingCharge);

    var loanInfo = new Loan(undefined, loanInfoJson.profileId, loanInfoJson.principal, stampAmount,
      undefined, undefined, undefined, undefined, undefined, loanInfoJson.scheme, loanInfoJson.interestRate, loanInfoJson.term,
      undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined,
      reserve, undefined, undefined, undefined, serviceChargeAmount, extras, undefined, undefined,
      undefined, loanInfoJson.processingRate, undefined, undefined, undefined,
      settleStamp, settleSc, settleExtra, undefined, undefined, undefined, undefined, undefined, undefined, settlementCharges);



    if (json.guarantors != undefined && Array(json.guarantors).length > 0)
      var guarantorsInfo = this.processGuarantorJsonArr(json.guarantors);
    var loanRequest = new LoanRequest(json.customerId, json.epfAmount, json.epfDate,
      guarantorsInfo, json.hasIAccount, json.id, loanInfo, json.processedRemark,
      json.profileId, json.remark, json.requestBy, json.requestOn, json.updatedOn,
      json.approved, json.loanId, json.companyCode, json.icNumber, json.name, json.companyId, undefined, undefined, json.authorizedBy,
      undefined, undefined, undefined, undefined, json.dob, json.otherId, undefined, undefined, undefined, json.sharedProfileId, json.loanGroupId)

    return loanRequest;
  }

  processDuitLebihJsonArr(jsonArray: any) {
    var duitLebihList: DuitLebih[] = new Array;
    jsonArray.forEach(json => {
      var extra = new DuitLebih(json.amount, json.subtype)
      duitLebihList.push(extra);
    });

    return duitLebihList;
  }

  processGuarantorJsonArr(jsonArray: any) {
    var guarantorsInfo: Contact[] = new Array;
    jsonArray.forEach(json => {
      var guarantors = new Contact(json.id, json.name, json.icNumber, json.number, json.address, json.customerId, json.relation, json.remark)
      guarantorsInfo.push(guarantors);



    });

    guarantorsInfo.sort((a, b) => (a.Name > b.Name) ? -1 : 1);
    return guarantorsInfo;
  }

  checkIsWholeNumber(num: number) {
    if (num - Math.floor(num) != 0)
      return false;
    else
      return true;
  }

  checkIsMainShareProfile(profile: Profile) {
    if (profile.ID == profile.SharedProfileId)
      return true;
    else
      return false;
  }

  getLoggedInUsername() {
    return sessionStorage.getItem("username");
  }

  generateDownloadLink(xhr: XMLHttpRequest, filename: string) {
    var contentType = xhr.getResponseHeader("content-type");

    var data = xhr.response;
    var file = new Blob([data], { type: contentType });

    var downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);
    var csvUrl = URL.createObjectURL(file);
    downloadLink.href = csvUrl;
    downloadLink.download = filename;
    downloadLink.click();
    URL.revokeObjectURL(downloadLink.href)
    downloadLink.remove();

    // For Firefox
    setTimeout(function () {
      document.body.removeChild(downloadLink);
      // Release resource on disk after triggering the download
      window.URL.revokeObjectURL(data);
    }, 30000);
  }


  calculateTotalSettlementBalance(loanList: Loan[]) {
    var totalSettlement = 0;
    for (let i = 0; i < loanList.length; i++) {

      if (loanList[i].Status != this.enums.SETTLED_LOAN) {
        if (loanList[i].Scheme == "A") {
          //if is LEGACY A EPF 
          if (loanList[i].IsEPF == true)
            totalSettlement += (loanList[i].LegacySettlement || 0) - loanList[i].PrincipalPaid;

          //if is normal A
          else
            totalSettlement += loanList[i].Principal - loanList[i].PrincipalPaid;
        }
        else {
          //if settlement charges != undefined, this case only can be true when it's epf loan 
          // add principal + interest + all left settlement charges - Repayment made
          if (loanList[i].SettlementCharges != undefined && loanList[i].SettlementCharges.Stamp != undefined)
            totalSettlement += (loanList[i].Principal + (loanList[i].Principal * (loanList[i].InterestRate / 100) * loanList[i].Term))
              + ((loanList[i].SettlementCharges.Extras || 0) + (loanList[i].SettlementCharges.Stamp || 0) + (loanList[i].SettlementCharges.ServiceCharge || 0) + (loanList[i].SettlementCharges.HandlingCharge || 0))
              - loanList[i].Repayment

          //if there's no settlement charges, just add on all the principal + interest + legacy defined settlement charges values - Repayment made
          else
            totalSettlement += (loanList[i].Principal + (loanList[i].Principal * (loanList[i].InterestRate / 100) * loanList[i].Term))
              + ((loanList[i].SettlementExtra || 0) + (loanList[i].SettlementStamp || 0) + (loanList[i].SettlementServiceCharge || 0))
              - loanList[i].Repayment


        }
      }

    }

    return totalSettlement;
  }


  calculateSettlementToDate(loan: Loan) {
    var interestTerm = this.getChargeableTerm(new Date(loan.DateCreated));
    var chargeableInterest = (loan.Principal * (loan.InterestRate / 100)) * interestTerm;
    var totalCharges;

    if (loan.SettlementCharges != undefined)
      totalCharges = (loan.SettlementCharges.Extras || 0) + (loan.SettlementCharges.HandlingCharge || 0) + loan.SettlementCharges.Stamp + loan.SettlementCharges.ServiceCharge;
    else
      totalCharges = (loan.SettlementExtra || 0) + (loan.ServiceCharge || 0) + (loan.SettlementStamp || 0);

    var chargeableCharges = (totalCharges / loan.Term) * interestTerm;

    return chargeableInterest + chargeableCharges + loan.Principal;
  }

  isAcctCheckedInBeforeAction(actionDate: Date, latestCheckInDate: Date) {
    //make sure account is correctly checked in before performing any action 
    //make sure last checked in date = 1 day before action date (cannot be more than that)


    var actionCheckInDate = this.datepipe.transform(new Date(actionDate.getFullYear(), actionDate.getMonth(), actionDate.getDate() - 1), 'dd-MM-yyyy');
    var formattedLatestCheckInDate = this.datepipe.transform(latestCheckInDate, 'dd-MM-yyyy');

    if (actionCheckInDate == formattedLatestCheckInDate)
      return true
    else
      return false;
  }

}
