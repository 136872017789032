import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { CPTransaction } from 'src/app/cp-payment-sheet/cp-payment-sheet.component';

@Component({
  selector: 'app-compare-cp-payments',
  templateUrl: './compare-cp-payments.component.html',
  styleUrls: ['./compare-cp-payments.component.css'],
  providers: [DatePipe]
})
export class CompareCpPaymentsComponent implements OnInit {

  @Input() selectedCustomerName: string;
  @Input() selectedCustomerId: number;
  @Input() selectedProfileId: number;
  @Input() transaction: CPTransaction;

  insertAmount: number;
  constructor(private activeModal: NgbActiveModal, private datepipe: DatePipe, public sharedService: SharedService) { }

  ngOnInit(): void {
  }


  reset() {
    this.activeModal.close();
  }
}
