<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Edit Loan Request Info</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-6">
                <div class="group mt-3">
                    <input type="number" required class="textInputLong" name="amount"
                        [(ngModel)]="request.LoanInfo.Principal" (keypress)="sharedService.isNumberKey($event)">
                    <span class="highlight"></span>
                    <span class="bar barLong"></span>
                    <label>Loan Amount</label>
                </div>
            </div>
            <div class="col-6">
                <div class="card">
                    <div class="card-body">
                        <span>Term : {{request.LoanInfo.Term}} month(s)</span>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="group mt-3">
                    <input type="number" required class="textInputLong" name="settlementAmount" min=0
                        [(ngModel)]="settlementAmount" (keypress)="sharedService.isNumberKey($event)"
                        (ngModelChange)="calculateInterestRate()">
                    <span class="highlight"></span>
                    <span class="bar barLong"></span>
                    <label>Settlement Amount (Without Stamp)</label>
                </div>
            </div>
            <div class="col-6" *ngIf="request!=undefined">
                <div class="group mt-3">
                    <input type="number" required class="textInputLong" name="settleStamp"
                        [(ngModel)]="request.LoanInfo.SettlementStamp" min=0
                        (keypress)="sharedService.isNumberKey($event)">
                    <span class="highlight"></span>
                    <span class="bar barLong"></span>
                    <label>Settlement Stamp</label>
                </div>
            </div>
            <div class="col-6">
                <div class="group mt-3">
                    <input type="number" required class="textInputLong" name="settleSc"
                        [(ngModel)]="request.LoanInfo.SettlementServiceCharge" min=0
                        (keypress)="sharedService.isNumberKey($event)">
                    <span class="highlight"></span>
                    <span class="bar barLong"></span>
                    <label>Settlement SC</label>
                </div>
            </div>

            <div class="col-6">
                <div class="group mt-3">
                    <input type="number" required class="textInputLong" name="settleDL"
                        [(ngModel)]="request.LoanInfo.SettlementExtra" min=0
                        (keypress)="sharedService.isNumberKey($event)">
                    <span class="highlight"></span>
                    <span class="bar barLong"></span>
                    <label>Settlement DL</label>
                </div>
            </div>
            <div class="col-12">
                <div class="card" style="background-color: #CBC3E3;">
                    <div class="card-body">
                        <span class="fw-bold">Total Settlment: RM {{calculateEPFSettlementIncludeCharges()}} |
                            Interest Rate:
                            {{request.LoanInfo.InterestRate |number:'1.2-2'}}% |
                            Ratio:{{calculateTotalSettlementRatio()|number:'1.2-2'}}%</span>
                    </div>

                </div>
            </div>
        </div>
        <hr />
        <div class="card" style="border: 1px solid black;">
            <div class="card-header" style="font-weight: bold;background-color: salmon;">Deduction</div>

            <div class="card-body">
                <div class="row">
                    <div class="col-6">
                        <div class="group mt-3">
                            <input type="number" required class="textInputLong" name="stamp"
                                [(ngModel)]="request.LoanInfo.StampAmount" min=0
                                (keypress)="sharedService.isNumberKey($event)">
                            <span class="highlight"></span>
                            <span class="bar barLong"></span>
                            <label>Stamp</label>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="group mt-3">
                            <input type="number" required class="textInputLong" name="sc"
                                [(ngModel)]="request.LoanInfo.ServiceCharge" min=0
                                (keypress)="sharedService.isNumberKey($event)">
                            <span class="highlight"></span>
                            <span class="bar barLong"></span>
                            <label>Service Charge</label>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="card mt-3">
                            <div class="card-header deduction-header fw-bold"> Duit Lebih</div>
                            <div class="card-body">
                                <app-added-duit-lebih-list [duitLebihList]="request.LoanInfo.Extras"
                                    (getDuitLebihData)="getDuitLebihData($event)">
                                </app-added-duit-lebih-list>
                                <h4 class="text-primary fw-bold mt-1" *ngIf="request.LoanInfo.Extras.length>0">
                                    Duit
                                    Lebih: RM
                                    {{calculateTotalDuitLebih()}}</h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <h3 class="mt-2" style="font-weight: bold;color: brown;background-color: yellow;"> Total On
                            Hand: RM
                            {{calculateTotalOnHand() ||0}} </h3>
                    </div>
                </div>
            </div>
        </div>


    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="submit()" [disabled]="disabledSubmit">Edit
            Loan</button>
        <button class="btn btn-outline-danger" type="button" (click)="reset()">Cancel</button>
    </div>

</form>