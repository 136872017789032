import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Commission } from 'src/model/commission.model';
import { EditCommissionComponent } from '../edit-commission/edit-commission.component';
import { AddCommissionPaymentComponent } from '../add-commission-payment/add-commission-payment.component';


interface UnpaidStaffCommissionInterface {
  StaffUsername: string;
  UnpaidComissionList: Commission[];
}

@Component({
  selector: 'app-comission-list',
  templateUrl: './comission-list.component.html',
  styleUrls: ['./comission-list.component.css']
})
export class ComissionListComponent implements OnInit {
  commissionList: Commission[] = new Array;

  unpaidStaffCommissionList: UnpaidStaffCommissionInterface[] = new Array;
  // unpaidCommissionList: Commission[] = new Array;

  displayedColumns: string[];
  dataSource = new MatTableDataSource;

  innerWidth: any;
  showRemark: string;
  isCollapsed: boolean = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  loggedInRole: number;
  loggedInUsername: string;

  constructor(private router: Router, private modalService: NgbModal,
    private restapi: RestApiService, private constructApi: ConstructAPI, public enums: Enums, private cdr: ChangeDetectorRef, private sharedService: SharedService) { }

  ngOnInit(): void {
    this.loggedInRole = Number(sessionStorage.getItem('roleId'));
    this.loggedInUsername = sessionStorage.getItem('username');

    this.loadUnpaidCommission();
  }


  confirmBeforeAssignAll() {

    var confirmation = false;
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "You are going to clear all the commissions, are you sure about that?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      confirmation = entry;
      if (confirmation) {

        // confirm to proceed
        this.assignAllCommissions();
      }
    });

  }
  assignAllCommissions() {

    var editIds: Number[] = new Array;
    for (let i = 0; i < this.commissionList.length; i++) {
      editIds.push(Number(this.commissionList[i].LoanId))
    }

    this.batchAssignAmountAndAssignee(this.loggedInUsername, editIds, 0)
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  assignCommission(commission: Commission) {


    // alert(commission.Rate + "|" + commission.Assignee)
    if (commission.Assignee == undefined || commission.Amount == undefined)
      this.sharedService.openAlert("Please key in commission amount and assignee!", this.enums.DANGER_ALERT);
    else
      this.assignRateAndAssignee(commission, true);
  }

  assignRateAndAssignee(commission: Commission, showAlert: boolean) {

    var data = {
      "amount": Number(commission.Amount),
      "loanId": commission.LoanId,
      "assignee": commission.Assignee,
      "dateCreated": new Date()
    }

    var xhr = this.restapi.postRequest(this.constructApi.getAssignCommission(), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        // var jsonArray = JSON.parse((xhr.responseText));

        if (showAlert == true)
          this.sharedService.openAlert("Successfully assign commission!", this.enums.SUCCESS_ALERT);
        commission.Assigned = true;
      }
    };
  }

  fillInAllPayment(unpaidCommissionStaff: string) {
    var index = this.unpaidStaffCommissionList.findIndex(commission => commission.StaffUsername == unpaidCommissionStaff);

    for (let i = 0; i < this.unpaidStaffCommissionList[index].UnpaidComissionList.length; i++) {
      this.unpaidStaffCommissionList[index].UnpaidComissionList[i].PaymentAmount = this.unpaidStaffCommissionList[index].UnpaidComissionList[i].Amount - this.unpaidStaffCommissionList[index].UnpaidComissionList[i].Paid;
    }

    this.submitPayment(this.unpaidStaffCommissionList[index]);

  }

  batchAssignAmountAndAssignee(assignee: string, loanIds: Number[], amount: number) {

    var data = {
      "amount": amount,
      "loanIds": loanIds,
      "assignee": assignee,
      "dateCreated": new Date()
    }

    var xhr = this.restapi.postRequest(this.constructApi.getAssignCommission(), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        // var jsonArray = JSON.parse((xhr.responseText));

        this.sharedService.openAlert("Successfully batch assign commission!", this.enums.SUCCESS_ALERT);
        window.location.reload();
      }
    };
  }

  goToCustomer(selectedID: number) {
    // console.log("selcted:" + selectedID);
    // this.router.navigateByUrl('/manageCustomer', { state: { id: selectedID } });
    this.router.navigate(['/manageCustomer', selectedID]);
  }

  getAllCommission() {

    var xhttp = this.restapi.getRequest(this.constructApi.getAllPendingCommissions(undefined, true))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // Typical action to be performed when the document is ready:

        var jsonArray = JSON.parse((xhttp.responseText));
        // console.log(jsonArray);

        jsonArray.forEach(json => {

          let commission = new Commission(json.id, json.loanId, json.rate, json.assignee, json.assignor, json.transactionId, json.dateCreated,
            json.customerId, json.customerName, json.principal, false, undefined, undefined, json.customerCode, json.companyId, json.companyCode, json.scheme)


          this.commissionList.push(commission);
        }
        );
      }
    };
    // xhttp.open("GET", url, true);
    // xhttp.send();
  }

  loadUnpaidCommission() {
    if (this.unpaidStaffCommissionList.length > 0)
      this.unpaidStaffCommissionList.splice(0, this.unpaidStaffCommissionList.length);

    var companyId = Number(sessionStorage.getItem('companyId'));
    this.getCompanyUnpaidCommission(companyId);
  }

  calculateUnpaidAmount(commissionList: Commission[]) {
    var total = 0;

    for (let i = 0; i < commissionList.length; i++) {
      total += commissionList[i].Amount;
    }
    return Math.round(total);
  }

  calculateBalanceAmount(commissionList: Commission[]) {
    var total = 0;

    for (let i = 0; i < commissionList.length; i++) {
      var paid = (commissionList[i].Paid == undefined) ? 0 : commissionList[i].Paid;
      total += commissionList[i].Amount - paid;
    }
    return Math.round(total);
  }

  calculatePaymentAmount(commissionList: Commission[]) {
    var total = 0;

    for (let i = 0; i < commissionList.length; i++) {
      total += commissionList[i].PaymentAmount;
    }
    return Math.round(total);
  }

  roundTo2Dec(num: number) {
    return Math.round(num * 100) / 100
  }

  getCompanyUnpaidCommission(companyId: number) {

    var xhttp = this.restapi.getRequest(this.constructApi.getCompanyUnpaidCommission(companyId))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {

          // alert(JSON.stringify(json));
          //get assignee username
          var username = json.assignee;

          //get index in the unpaid list if exist
          var index = this.unpaidStaffCommissionList.findIndex(unpaidStaff => unpaidStaff.StaffUsername === username);
          var commissionList: Commission[] = new Array;
          // alert(index);
          //get comission
          var paid = (json.paid == undefined) ? 0 : json.paid;
          let commission = new Commission(json.id, json.loanId, json.amount, json.assignee, json.assignor, json.transactionId, json.dateCreated, json.customerId, json.customerName, json.principal,
            undefined, 0, paid, json.customerCode);

          //if assignee not existed before
          if (index == -1) {
            commissionList.push(commission);
            //add into the list
            let staffUnpaidCommission = { StaffUsername: username, UnpaidComissionList: commissionList }
            this.unpaidStaffCommissionList.push(staffUnpaidCommission);
          }
          else {
            //if assignee existed before, append the new commission into the commission list
            this.unpaidStaffCommissionList[index].UnpaidComissionList.push(commission);
          }
        }
        );
      }
    };

  }




  openEditCommissionModal(commission: Commission) {
    var modalRef = this.sharedService.openModal(EditCommissionComponent);
    modalRef.componentInstance.amount = commission.Amount;
    modalRef.componentInstance.assignee = commission.Assignee;
    modalRef.componentInstance.loanId = commission.LoanId;
    modalRef.componentInstance.commissionId = commission.ID;

    modalRef.componentInstance.passEntry.subscribe((entry) => {
      window.location.reload();
    });
  }

  checkError(unpaidCommission: UnpaidStaffCommissionInterface) {
    for (let i = 0; i < unpaidCommission.UnpaidComissionList.length; i++) {
      var paid = (unpaidCommission.UnpaidComissionList[i].Paid == undefined) ? 0 : unpaidCommission.UnpaidComissionList[i].Paid;
      if (unpaidCommission.UnpaidComissionList[i].Amount - paid < unpaidCommission.UnpaidComissionList[i].PaymentAmount)
        return true
    }
    return false;
  }

  submitPayment(unpaidCommission: UnpaidStaffCommissionInterface) {
    if (this.checkError(unpaidCommission) == false) {
      this.openAddCommissionPaymentModal(unpaidCommission);
    }
    else
      this.sharedService.openAlert("Please make sure payment amount is correct! \nYou can only pay maximum of the balance commission amount!", this.enums.DANGER_ALERT)
  }

  openAddCommissionPaymentModal(unpaidCommission: UnpaidStaffCommissionInterface) {
    var modalRef = this.sharedService.openModal(AddCommissionPaymentComponent);
    modalRef.componentInstance.unpaidCommission = unpaidCommission;

    // var total = 0;
    // for (let i = 0; i < unpaidCommission.UnpaidComissionList.length; i++)
    //   total += unpaidCommission.UnpaidComissionList[i].Amount;

    // modalRef.componentInstance.selectedCommissionAmount = Math.round(total);

    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var commission = entry;

      this.unpaidStaffCommissionList.splice(0, this.unpaidStaffCommissionList.length);
      var companyId = Number(sessionStorage.getItem('companyId'));
      this.getCompanyUnpaidCommission(companyId);
    });

    modalRef.result.then(() => {
      this.cdr.detectChanges();
    })

  }


  confirmBeforeDeletingCommission(commission: Commission) {
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Are you sure you want to delete the commission of " + commission.Assignee + "- RM " + commission.Amount + "?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something

      if (confirmation == true) {
        this.deleteCommission(commission.LoanId, commission.ID);
      }
    });
  }

  deleteCommission(loanId: number, commissionId: number) {
    var xhttp = this.restapi.deleteRequest(this.constructApi.getDeleteLoanCommission(loanId, commissionId));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        window.location.reload();
      }
    };
  }


}
