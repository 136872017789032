import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RestApiService } from 'src/app/API/restapi';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { Account } from 'src/model/account.model';
import { AlertDialogComponent } from 'src/app/Shared/alert-dialog/alert-dialog.component';
import { TransactionSubType } from 'src/model/transactionSubType.model';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Enums } from 'src/app/Shared/enums';

@Component({
  selector: 'app-add-income',
  templateUrl: './add-income.component.html',
  styleUrls: ['./add-income.component.css']
})
export class AddIncomeComponent implements OnInit {


  @Input() companyBankAccountList: Account[] = new Array;
  @Input() cashAccountList: Account[] = new Array;

  selectedAccountId: number;
  selectedSubTypeId: number;

  @Input() paidByDuitLebih: boolean;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  //2-WAY BINDING INFO
  incomeDate: Date;
  remark: string;
  amount: number = 0;
  subtypeList: TransactionSubType[] = new Array;

  disabledSubmit = false;
  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private restapi: RestApiService, private constructApi: ConstructAPI, 
    public sharedService: SharedService,private enums:Enums) {

    this.incomeDate = new Date();
  }


  setSelectedSubType(subTypeID: number) {

    if (subTypeID === this.selectedSubTypeId)
      return true
    else
      return false;

  }
  ngOnInit(): void {
    if (this.paidByDuitLebih)
      this.getTransactionSubTypes();
  }


  setSelectedAccount(accountId: number) {

    if (accountId === this.selectedAccountId)
      return true
    else
      return false;
  }

  isFieldNull() {
    var paymentNull = (this.amount == undefined || this.amount == 0);
    var remarkNull = (this.remark == undefined || this.remark == "");
    var selectedAccountIdNull = (this.selectedAccountId == undefined);
    var paymentValid = isNaN(this.amount);

    return paymentNull || remarkNull || selectedAccountIdNull || paymentValid
  }

  getTransactionSubTypes() {
    var xhttp = this.restapi.getRequest(this.constructApi.getAllTransactionSubTypes());
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let type = new TransactionSubType(json.id, json.name, json.type, json.assignable, json.enabled);

          this.subtypeList.push(type);
        });
      }
    };

  }


  addIncome() {

    this.disabledSubmit = true;
    var isoTaskDate = (new Date(Number(this.incomeDate))).toISOString();

    var data = {
      "amount": this.amount,
      "accountId": Number(this.selectedAccountId),
      "date": isoTaskDate,
      "remark": this.remark
    };


    var xhr = this.restapi.postRequest(this.constructApi.getAddIncome(), data);
    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        if (xhr.status == 200) {
          let account = new Account(this.selectedAccountId, null, null, null, null, null, null, this.amount)
          this.passEntry.emit(account)
          this.reset();
          this.sharedService.openAlert("Successfully added income!",this.enums.SUCCESS_ALERT);
          window.location.reload();


        } else
          this.sharedService.openErrorMessage(xhr);
      }

    }
  }

  addIncomeOfExtras() {

    this.disabledSubmit = true;

    var isoTaskDate = (new Date(Number(this.incomeDate))).toISOString();
    var data;

    if (this.selectedSubTypeId == undefined) {
      data = {
        "amount": this.amount,
        "accountId": Number(this.selectedAccountId),
        "date": isoTaskDate,
        "remark": this.remark
      };
    } else {
      data = {
        "amount": this.amount,
        "accountId": Number(this.selectedAccountId),
        "date": isoTaskDate,
        "remark": this.remark,
        "subtype": Number(this.selectedSubTypeId)
      };
    }



    var xhr = this.restapi.postRequest(this.constructApi.getAddIncomeOfExtras(), data);
    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        if (xhr.status == 200) {
          let account = new Account(this.selectedAccountId, null, null, null, null, null, null, this.amount)
          this.passEntry.emit(account)
          this.reset();
          this.sharedService.openAlert("Successfully added income!",this.enums.SUCCESS_ALERT);

        }
        else
          this.sharedService.openErrorMessage(xhr);
      }

    }
  }

  submit() {
    if (this.isFieldNull()) {
      this.sharedService.openAlert('Please fill in all the fields',this.enums.DANGER_ALERT)
    }
    else {
      if (this.paidByDuitLebih)
        this.addIncomeOfExtras();
      else
        this.addIncome();
    }
  }
  reset() {
    this.activeModal.close();
  }

}
