<div class="m-2 schedule"
    style="font-family: 'Times New Roman', Times, serif;line-height: 150%!important;font-size: 11pt;">
    <h6 class="text-center fw-bold">THE FIRST SCHEDULE</h6>
    <p class="text-center m-0">(which is to be taken read and construed</p>
    <p class="text-center m-0"> as an integral part of this Agreement)</p>

    <br />
    <div class="p-2">
        <table class="table" style="border: black 1px solid !important;">
            <thead>
                <tr>
                    <th class="text-center">Section No.</th>
                    <th class="text-center">Item</th>
                    <th class="text-center">Particulars</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1.</td>
                    <td>The day and year of this Agreement</td>
                    <td class=" text-center fw-bold">{{selectedFormJ.StampDate | date:'dd-MM-yyyy'}}</td>
                </tr>
                <tr>
                    <td>2.</td>
                    <td>Name, I.C. No., Company Registration No., Licence No. and address of the Lender</td>
                    <td>
                        <div class="row">
                            <div class="col-12 text-center fw-bold"> {{selectedCompany.Name |uppercase}}</div>
                            <div class="col-12 text-center fw-bold"> {{selectedCompany.RegNum |uppercase}}
                            </div>
                            <div class="col-12 text-center fw-bold"> {{selectedCompany.Address |uppercase}}
                            </div>
                            <div class="col-12 text-center fw-bold">TEL: {{selectedCompany.ContactNo }}
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>3.</td>
                    <td>Name, I.C. No., Company Registration No. and address of the Borrower</td>
                    <td>
                        <div class="row">
                            <div class="col-12  text-center fw-bold"> {{selectedCustomer.Name |uppercase}}
                            </div>
                            <div class="col-12 text-center fw-bold">
                                {{sharedService.processICNum(selectedCustomer.ICNumber)}}
                            </div>
                            <div class="col-12 text-center fw-bold"> {{selectedCustomer.Address |uppercase}}
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>4.</td>
                    <td>Principal Sum</td>
                    <td class=" text-center fw-bold"><span>{{selectedFormJ.Principal |number:'1.2-2'}}</span>
                        ringgit (RM )
                    </td>
                </tr>
                <tr>
                    <td>5.</td>
                    <td>Interest rate</td>
                    <td class=" text-center">The rate of interest is at eighteen per centum <strong>( 18 %) </strong>per
                        annum.</td>
                </tr>

                <tr>
                    <td>6.</td>
                    <td>Amount of each instalment repayment</td>
                    <td class="text-center fw-bold">
                        <div class="row d-flex justify-content-center align-items-center"
                            *ngFor="let schedule of processedScheduleList; let i=index">

                            <div class="col-3" *ngIf="schedule.months!=''"> {{schedule.months}} </div>

                            <div class=" col-3">
                                {{schedule.amount | number:'1.2-2'}}
                            </div>
                        </div>
                        <div class="row d-flex justify-content-center align-items-center"
                            *ngIf="this.selectedFormJ.PaymentSchedule.length > 1 || this.selectedFormJ.PaymentSchedule[this.selectedFormJ.PaymentSchedule.length - 1].monthsCount > 1">
                            <div class="col-3">
                                {{selectedFormJ.Term}}
                            </div>
                            <div class="col-3">
                                <span>
                                    {{selectedFormJ.LastPayment|number:'1.2-2'}}
                                </span>
                            </div>
                        </div>

                    </td>
                </tr>
                <tr>
                    <td>7.</td>
                    <td>Total repayment</td>
                    <td class=" text-center fw-bold">
                        {{repaymentAmount|number:'1.2-2'}}
                    </td>

                </tr>
            </tbody>
        </table>
    </div>

</div>