import { Profile } from "./profile.model"
import { Status } from "./status.model"


export class Customer {


    constructor(private _id: number, private _name: string, private _occupation: string, private _salary: number,
        private _salaryDay: number, private _icNumber: string, private _contactNo: string, private _remark: string,
        private _ctosNumber: string, private _ctosStatus: string, private _status: Status[], private _dateCreated: Date,
        private _handlerId: number, private _companyId: number, private _customerCode: string,
        private _companyCode?: string, private _reserve?: number, private _badDebt?: number, private _address?: string,
        private _profiles?: Profile[], private _lastformJDate?: Date, private _needAttention?: boolean, private _epfDate?: Date, private _address2?: string,
        private _verified?: boolean, private _dob?: Date, private _verifiedBy?: string, private _otherId?: string, private _race?: string, private _gender?: string, private _verifiedOn?: Date) {


    }


    set ID(id: number) { this._id = id }
    get ID(): number { return this._id }

    set Name(name: string) { this._name = name }
    get Name(): string { return this._name }

    set Occupation(occupation: string) { this._occupation = occupation }
    get Occupation(): string { return this._occupation }

    set ICNumber(icNumber: string) { this._icNumber = icNumber }
    get ICNumber(): string { return this._icNumber }

    set ContactNo(contactNo: string) { this._contactNo = contactNo }
    get ContactNo(): string { return this._contactNo }


    set Salary(salary: number) { this._salary = salary }
    get Salary(): number { return this._salary }

    set SalaryDay(salaryDay: number) { this._salaryDay = salaryDay }
    get SalaryDay(): number { return this._salaryDay }

    set Remark(remark: string) { this._remark = remark }
    get Remark(): string { return this._remark }


    set CtosNumber(ctosNumber: string) { this._ctosNumber = ctosNumber }
    get CtosNumber(): string { return this._ctosNumber }

    set CtosStatus(ctosStatus: string) { this._ctosStatus = ctosStatus }
    get CtosStatus(): string { return this._ctosStatus }

    set Status(status: Status[]) { this._status = status }
    get Status(): Status[] { return this._status }

    set DateCreated(dateCreated: Date) { this._dateCreated = dateCreated }
    get DateCreated(): Date { return this._dateCreated }


    set HandlerId(handlerId: number) { this._handlerId = handlerId }
    get HandlerId(): number { return this._handlerId }

    set CompanyId(companyId: number) { this._companyId = companyId }
    get CompanyId(): number { return this._companyId }

    set CustomerCode(customerCode: string) { this._customerCode = customerCode }
    get CustomerCode(): string { return this._customerCode }

    set Reserve(reserve: number) { this._reserve = reserve }
    get Reserve(): number { return this._reserve }

    set BadDebt(badDebt: number) { this._badDebt = badDebt }
    get BadDebt(): number { return this._badDebt }

    set CompanyCode(companyCode: string) { this._companyCode = companyCode }
    get CompanyCode(): string { return this._companyCode }

    set Address(address: string) { this._address = address }
    get Address(): string { return this._address }

    set Profiles(_profiles: Profile[]) { this._profiles = _profiles }
    get Profiles(): Profile[] { return this._profiles }

    set LastFormJDate(lastformJDate: Date) { this._lastformJDate = lastformJDate }
    get LastFormJDate(): Date { return this._lastformJDate }

    set NeedAttention(needAttention: boolean) { this._needAttention = needAttention }
    get NeedAttention(): boolean { return this._needAttention }

    set EPFDate(epfDate: Date) { this._epfDate = epfDate }
    get EPFDate(): Date { return this._epfDate }

    set Address2(address2: string) { this._address2 = address2 }
    get Address2(): string { return this._address2 }

    set Verified(verified: boolean) { this._verified = verified }
    get Verified(): boolean { return this._verified }


    set DOB(dob: Date) { this._dob = dob }
    get DOB(): Date { return this._dob }

    set VerifiedBy(verifiedBy: string) { this._verifiedBy = verifiedBy }
    get VerifiedBy(): string { return this._verifiedBy }

    get OtherID(): string { return this._otherId }
    set OtherID(value: string) { this._otherId = value }

    get Gender(): string { return this._gender }
    set Gender(value: string) { this._gender = value }

    get Race(): string { return this._race }
    set Race(value: string) { this._race = value }


    get VerifiedOn(): Date { return this._verifiedOn }
    set VerifiedOn(value: Date) { this._verifiedOn = value }

}