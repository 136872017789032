<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Deletion Reason</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()" aria-label="Close"></button>
    </div>

    <div class="modal-body">

        <div class="card shadow-lg" *ngIf="groupId==undefined">
            <div class="card-body">
                <div class="row">
                    <div class="col-6">
                        <strong>Date:</strong>
                    </div>
                    <div class="col-6">
                        {{transaction.Date| date:'dd-MM-yyyy'}}
                    </div>
                    <div class="col-6">
                        <strong>Remark:</strong>
                    </div>
                    <div class="col-6">
                        {{transaction.Remark}}
                    </div>
                    <div class="col-6">
                        <strong>Type:</strong>
                    </div>
                    <div class="col-6">
                        {{enums.transactionTypeString[transaction.Type-1]}}
                    </div>
                    <div class="col-6">
                        <strong>Amount:</strong>
                    </div>
                    <div class="col-6">
                        RM {{transaction.Amount}}
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="groupId!=undefined">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th style="width: 10%">Date</th>
                        <th style="width: 30%;text-align: center;">Remark</th>
                        <th style="width: 15%">Amount</th>
                        <th style="width: 20%">Type</th>
                        <th style="width: 15%">HandleBy</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let transaction of groupTransactions">
                        <td>
                            {{transaction.Date | date:'dd-MM-yyyy'}}
                        </td>
                        <td class="text-center">
                            {{transaction.Remark}}
                        </td>

                        <td>
                            {{transaction.Amount}}
                        </td>
                        <td>
                            {{enums.transactionTypeString[transaction.Type-1]}}
                        </td>
                        <td>
                            {{transaction.HandleBy}}
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <th style="width: 10%"></th>
                        <th style="width: 30%">Total:RM </th>
                        <th style="width: 15%">{{calculateTotalPayment()}}</th>
                        <th style="width: 20%"></th>
                        <th style="width: 15%"></th>
                    </tr>
                </tfoot>

            </table>
            <hr />
        </div>


        <div class="container-fluid p-0">
            <div class="mt-2">
                <div class="col">
                    <div>
                        <h4 class="fw-bold">Reason</h4>
                    </div>
                    <div class="row">
                        <div class="col-12 ms-3">General</div>
                        <div class="col-12">
                            <div class="p-3 d-flex justify-content-center align-items-center">

                                <select class="browser-default form-select" name="generalReason"
                                    [(ngModel)]="generalReason">
                                    <option *ngFor="let reason of enums.generalDeleteReasonString" [value]="reason">
                                        {{reason}}</option>
                                </select>
                            </div>

                        </div>
                    </div>

                    <div>
                        <p>Description</p>
                        <textarea class="form-control" rows="10" required [(ngModel)]="deleteRemark"
                            name="deleteRemark"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="submit()"
            [disabled]="disabledSubmit">Delete</button>
        <button class="btn btn-outline-danger" type="button" data-dismiss="modal" (click)="reset()">Cancel</button>
    </div>
</form>