import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { EPF } from 'src/model/epf.model';


interface EPFChangeHistory {
  customerId: number,
  id: number,
  modified_by: string,
  modified_on: Date,
  new_value: EPF,
  old_value: EPF
}
@Component({
  selector: 'app-view-epf-change-history',
  templateUrl: './view-epf-change-history.component.html',
  styleUrls: ['./view-epf-change-history.component.css']
})
export class ViewEpfChangeHistoryComponent implements OnInit {


  @Input() customerId: number;
  historyList: EPFChangeHistory[] = new Array;
  constructor(private activeModal: NgbActiveModal, private restApi: RestApiService, private constructApi: ConstructAPI) { }

  ngOnInit(): void {


    this.getCustomerEpfChangeHistory(this.customerId);
  }


  getCustomerEpfChangeHistory(customerId: number) {
    var xhttp = this.restApi.getRequest(this.constructApi.getCustomerEPFChangeHistory(this.customerId))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse(xhttp.responseText);
        jsonArray.forEach(json => {

          var old_value = this.processValue(json.old_value);
          var new_value = this.processValue(json.new_value);
          let history = { id: json.id, customerId: json.customerId, modified_by: json.modified_by, modified_on: json.modified_on, new_value: new_value, old_value: old_value };

          this.historyList.push(history);
        }
        );
      }
    };
  }

  processValue(jsonValue: any) {
    var json = JSON.parse(jsonValue);
    return new EPF(json.amount, json.customerId, json.date, json.hasIAccount, json.iAccountPassword, json.iAccountUsername, json.remark);
  }

  reset() {
    this.activeModal.close();
  }
}
