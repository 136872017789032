<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Update Profile</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()" aria-label="Close"></button>
    </div>

    <div class="modal-body">
        <div class="container-fluid p-0">

            <div class="mt-2">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col col-12">
                                <div><span>Customer Code</span></div>
                                <div><input type="text" [(ngModel)]="customerCode" name="customerCode" required
                                        (focusout)="validField('customerCode')"></div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div><span>Company Name</span></div>
                                <div><input type="text" required [(ngModel)]="custOccupation" name="custOccupation"
                                        (focusout)="validField('custOccupation')"></div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-12 col-md-6">
                                <div><span>Salary</span></div>
                                <div><input type="number" required [(ngModel)]="salaryAmount" name="salaryAmount"
                                        (focusout)="validField('salaryAmount')"></div>
                            </div>
                            <div class="col col-12 col-md-6">
                                <div><span>Salary Day</span></div>
                                <div><input type="number" required [(ngModel)]="salaryDay" name="salaryDay"></div>
                                <span class="text-danger">Between (1-31). If more than 1, fill another at
                                    Description</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div><span>Description</span></div>
                                <div><textarea class="form-control-lg cols='30'" required [(ngModel)]="remark"
                                        name="remark" (focusout)="validField('remark')"></textarea></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="submit()" [disabled]="disableSubmit">Update Profile</button>
        <button class="btn btn-outline-danger" type="button" data-dismiss="modal"
            onclick="form.reset()">Cancel</button>
    </div>
</form>