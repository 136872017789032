<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Fund Reallocation</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body">

        <div class="container-fluid" style="padding: 2%;">

            <div *ngIf="accountList.length==0;else allocationList">
                <p>You do not have any accounts</p>
            </div>
            <ng-template #allocationList>
                <div class="row">
                    <div class="col-12">
                        <p>Reallocation Date</p>
                    </div>
                    <div class="col-12">
                        <mat-form-field class="full-width-formField p-0">
                            <input matInput readonly [matDatepicker]="endDatePicker" name="date" [(ngModel)]="date" required>
                            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #endDatePicker name="date"></mat-datepicker>
                        </mat-form-field>
                    </div>

                </div>
                <div>
                    <div class="mat-elevation-z8">
                        <table mat-table [dataSource]="dataSource" matSort>

                            <!-- Name Column -->
                            <ng-container matColumnDef="Name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                                <td mat-cell *matCellDef="let account">
                                    {{account.Name}} </td>

                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>

                            <!-- Current Column -->
                            <ng-container matColumnDef="Current">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Current </th>
                                <td mat-cell *matCellDef="let account">
                                    RM {{account.Balance ||0 }} </td>

                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>


                            <!-- Allocation Column -->
                            <ng-container matColumnDef="Allocation">
                                <th mat-header-cell *matHeaderCellDef> Allocation </th>
                                <td mat-cell *matCellDef="let account">
                                    <mat-form-field floatLabel="never">
                                        <input matInput placeholder="Enter Allocation" [value]="account.Allocation"
                                            name="allocation" [(ngModel)]="account.Allocation"
                                            (keypress)="sharedService.isNumberKey($event)" maxlength="6">
                                    </mat-form-field>
                                </td>

                                <th mat-footer-cell *matFooterCellDef>Total Adjustment:</th>
                            </ng-container>
                            <!-- Adjustment Column -->
                            <ng-container matColumnDef="Adjustment">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Adjustment </th>
                                <td mat-cell *matCellDef="let account">
                                    RM {{calculateAdjustment(account)}} </td>
                                <th mat-footer-cell *matFooterCellDef>
                                    RM{{ getTotalAdjustment()}} </th>
                            </ng-container>


                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
                        </table>
                    </div>
                    <div class="mt-2">
                        <table style="background-color: pink;">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Current</th>
                                    <th>Allocation</th>
                                    <th>Adjustment</th>
                                </tr>
                            </thead>
                            <tr>
                                <td>{{managerAccount.Name}}</td>
                                <td>RM {{managerAccount.Balance }}</td>
                                <td>RM {{managerAccount.Balance -totalAdjustment}}</td>
                                <td>RM {{calculateManagerAdjustment(managerAccount) }}</td>
                            </tr>
                        </table>
                    </div>
                    <div class="row mt-2 ">
                        <div class="col d-flex justify-content-end">
                            <button class="btn btn-success" (click)="postFundAllocation()">Done</button>
                            <button class="btn btn-danger ms-2">Cancel</button>
                        </div>
                    </div>
                </div>

            </ng-template>
        </div>
    </div>


</form>