import { Component, Input, OnInit } from '@angular/core';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { AlertDialogComponent } from 'src/app/Shared/alert-dialog/alert-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Account } from 'src/model/account.model';

@Component({
  selector: 'app-withdraw-simpan',
  templateUrl: './withdraw-simpan.component.html',
  styleUrls: ['./withdraw-simpan.component.css']
})
export class WithdrawSimpanComponent implements OnInit {

  @Input() totalReserveAmount: number;
  @Input() selectedProfileID: number;
  withdrawAmount: number;
  selectedAccountId: number;
  companyBankAccountList: Account[] = new Array;
  cashAccountList: Account[] = new Array;

  paymentDate: Date = new Date();
  disabledSubmit = false;

  constructor(private restapi: RestApiService, private constructApi: ConstructAPI, public sharedService: SharedService,private enums: Enums) { }

  ngOnInit(): void {
    var companyId = Number(sessionStorage.getItem('companyId'));
    this.getAllAccountsOfCompany(companyId);
  }

  checkSimpanLeftEnough() {
    if (this.calculateReserveLeft() < 0)
      return false;
    else
      return true;
  }
  private getAllAccountsOfCompany(companyId: number) {
    var loggedInUsername = sessionStorage.getItem("username");
    var xhttp = this.restapi.getRequest(this.constructApi.getAccountsOfCompany(companyId));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let account = new Account(json.id, json.number, json.name, json.holderName, json.balance, json.companyId, json.username);

          if (account.Username == undefined)
            this.companyBankAccountList.push(account);
          else if (account.Username == loggedInUsername)
            this.cashAccountList.push(account);

        }
        );

      }
    };

  }

  submit() {
    if (this.withdrawAmount == undefined)
      this.sharedService.openAlert("Please fill in the withdrawal amount",this.enums.DANGER_ALERT);
    else
      this.withdrawWithReserve();
  }

  withdrawWithReserve() {
    this.disabledSubmit = true;

    var extras = [{ "amount": this.withdrawAmount }]
    var data = {
      "extras": extras,
      "accountId": Number(this.selectedAccountId),
      "dateCreated": this.paymentDate,
      "reserve": -this.withdrawAmount,
      "remark": "Simpan withdraw Duit Lebih"
    };

    var xhr = this.restapi.postRequest(this.constructApi.getWithdrawReserve(this.selectedProfileID), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;

        if (xhr.status == 200) {
          this.sharedService.openAlert("Successfully withdraw reserve!",this.enums.SUCCESS_ALERT);
          window.location.reload();
        }
        else
          this.sharedService.openErrorMessage(xhr);
      }

    }
  }


  calculateReserveLeft() {
    return this.totalReserveAmount - this.withdrawAmount;
  }

}
