<ng-container class="m-2">
    <div class="alert alert-info" role="alert" [hidden]="selectedCompanyID!=undefined && selectedUsername!=undefined">
        You will need to add the company and register a manager user before you can import company's data.
        You can click the button to add company / register user if needed.
        <button class="btn btn-info" (click)="viewCompanyList()"> Add Company </button>
        <button class="btn btn-warning ms-2" (click)="goToRegister()"> Register New User</button>
    </div>

    <div class="row">
        <div class="col-12 ms-3">Company</div>
        <div class="col-12">
            <div class="p-3 d-flex justify-content-center align-items-center">
                <select class="browser-default form-select" name="selectedCompany" [(ngModel)]="selectedCompanyID"
                    (change)="getAllManagerUsers(selectedCompanyID)">
                    <option *ngFor="let company of companyList" [value]="company.ID">{{company.CompanyCode}}</option>
                </select>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 ms-3">Import Manager Username</div>
        <div class="col-12">
            <div class="p-3 d-flex justify-content-center align-items-center">
                <select class="browser-default form-select" name="selectedUsername" [(ngModel)]="selectedUsername">
                    <option *ngFor="let user of userList" [value]="user.Username">{{user.Username}}</option>
                </select>
            </div>
        </div>
    </div>

    <div [hidden]="selectedCompanyID==undefined || selectedUsername==undefined">
        <div class="card">
            <div class="card-header fw-bold bg-success">Import Excel</div>
            <div class="card-body">
                <ng-container>
                    <div class="row mb-3">
                        <!-- <div class="col-6">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="checkboxReturn" name="isDry" [value]="isDry"
                                [(ngModel)]="isDry" style="top:10px">
                            <label class="form-check-label" for="checkboxReturn"
                                style="top: 0px;font-weight:bold;font-size:1.2rem">Dry</label>
                        </div>
                    </div> -->
                        <div class="col-6">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="checkboxReturn"
                                    name="isIncludedSettle" [value]="isIncludedSettle" [(ngModel)]="isIncludedSettle"
                                    style="top:10px">
                                <label class="form-check-label text-success" for="checkboxReturn"
                                    style="top: 0px;font-weight:bold;font-size:1.2rem">Included Settle</label>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <div class="table-responsive  m-1 mt-5">
                    <table class="table table-bordered">
                        <tbody>
                            <tr>
                                <th class="text-center">Import Type</th>
                                <th>Import File</th>
                                <th>Progress</th>
                            </tr>
                            <tr *ngFor="let import of importFileList;let i=index">
                                <td class="text-center">
                                    <span>{{import.type}}</span>
                                </td>
                                <td>
                                    <span>{{import.file.name}}</span>
                                    <div class="d-flex justify-content-end align-items-right"
                                        style="cursor: pointer;display: inline;" (click)="removeExcel(i)"
                                        [hidden]="import.done==true">
                                        <h3><i class="fas fa-times text-danger"></i></h3>
                                    </div>
                                </td>
                                <td>
                                    <span class="text-primary" *ngIf="import.done==undefined">Pending</span>
                                    <span class="text-danger" *ngIf="import.done==false">Error</span>
                                    <span class="text-success" *ngIf="import.done==true">Done</span>

                                </td>
                            </tr>
                            <tr>
                                <td><select class="browser-default form-select" name="selectedImportType"
                                        [(ngModel)]="selectedImportType">
                                        <option *ngFor="let type of importFileTypes" [value]="type">{{type}}</option>
                                    </select>
                                </td>
                                <td> <input #excelFile id="file-input" type="file"
                                        (change)="handleFileInput($event.target.files)" />
                                    <div class="d-flex justify-content-end align-items-right">
                                        <button class="btn btn-success" (click)="addSelectedFile()">Add</button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
        <hr />
        <div class="card">
            <div class="card-header fw-bold bg-info">Import Balances</div>
            <div class="card-body">

                <div class="table-responsive  m-1">
                    <table class="table table-bordered">
                        <tbody>
                            <tr>
                                <th class="text-center"> Type</th>
                                <th>Import Balance</th>
                                <th> Date</th>
                                <th>Progress</th>
                            </tr>
                            <tr *ngFor="let import of importBalanceList">
                                <td class="text-center">
                                    <span>{{enums.transactionTypeString[import.type-1]}}</span>
                                </td>
                                <td>
                                    <span> RM {{import.balance}}</span>
                                </td>

                                <td>
                                    <span> {{import.date | date:'dd-MM-yyyy'}}</span>
                                    <div class="d-flex justify-content-end align-items-right"
                                        style="cursor: pointer;display: inline;" (click)="removeBalance(i)"
                                        [hidden]="import.done==true">
                                        <h3><i class="fas fa-times text-danger"></i></h3>
                                    </div>
                                </td>
                                <td>
                                    <span class="text-primary" *ngIf="import.done==undefined">Pending</span>
                                    <span class="text-danger" *ngIf="import.done==false">Error</span>
                                    <span class="text-success" *ngIf="import.done==true">Done</span>

                                </td>
                            </tr>
                            <tr>
                                <td><select class="browser-default form-select" name="selectedBalanceType"
                                        [(ngModel)]="selectedBalanceType">
                                        <option *ngFor="let type of transactionTypeList" [value]="type.ID">{{type.Name}}
                                        </option>
                                    </select>
                                </td>
                                <td>
                                    <input type="text" (keypress)="sharedService.isNumberKey($event)"
                                        [(ngModel)]="balanceAmount">
                                </td>
                                <td>
                                    <mat-form-field class="full-width-formField p-0">
                                        <input matInput readonly [matDatepicker]="balanceDatePicker" name="balanceDate"
                                            [(ngModel)]="balanceDate" required>
                                        <mat-datepicker-toggle matSuffix [for]="balanceDatePicker">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #balanceDatePicker name="endDate"></mat-datepicker>
                                    </mat-form-field>

                                    <div class="d-flex justify-content-end align-items-right">
                                        <button class="btn btn-success" (click)="addSelectedBalance()">Add</button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col d-flex justify-content-end align-items-right">
                <button class="btn btn-success" (click)="submit()" [disabled]="disabledSubmit">Import</button>
                <button class="btn btn-danger ms-2">Cancel</button>
            </div>
        </div>
    </div>
</ng-container>