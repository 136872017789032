<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Add Pull Customer</h4>
    </div>

    <div class="modal-body">

        <div class="col-12 pt-3">
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Company</mat-label>
                <input class="companyInput" type="text" placeholder="Pick one" aria-label="Company" matInput
                    [formControl]="myControl" [matAutocomplete]="auto" [(ngModel)]="selectedCompanyCode">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option *ngFor="let company of filteredCompany |async" [value]="company.CompanyCode"
                        (onSelectionChange)="setSelectedCompany(company.CompanyCode)">
                        {{company.CompanyCode}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

        </div>

        <!--Date-->
        <div class="col-12">
            <mat-form-field class="full-width-formField bar">
                <input matInput class="textInputLong" [matDatepicker]="myDatepicker" name="date" [(ngModel)]="date"
                    placeholder="Date">
                <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #myDatepicker name="date"></mat-datepicker>
            </mat-form-field>
        </div>



        <!--Remark-->
        <div class="col-12">
            <div class="group">
                <input type="text" required class="textInputLong" name="remark" [(ngModel)]="remark">
                <span class="highlight"></span>
                <span class="bar barLong"></span>
                <label> Remark</label>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="submit()" [disabled]="disabledSubmit">Add Pull
            Record</button>
        <button class="btn btn-outline-danger" type="button" (click)="reset()">Cancel</button>
    </div>

</form>