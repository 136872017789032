<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Please update legacy settlement</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 aria-label="Close" aria-label="Close"
            (click)="closeModal()"></button>
    </div>

    <div class="modal-body">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>Receipt Number</th>
                    <th>Principal</th>
                    <th>Settlement</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let loan of loanList">
                    <td> {{loan.ReceiptNumber}} ({{loan.LoanCode}})</td>
                    <td>{{loan.Principal}}</td>
                    <td>
                        <div class="group mt-3">
                            <input type="number" required class="textInputShort" name="amount"
                                [(ngModel)]="loan.LegacySettlement" min=0 (keypress)="sharedService.isNumberKey($event)">
                            <span class="highlight"></span>
                            <span class="bar barShort"></span>
                            <label>Amount</label>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="submit()" [disabled]="disabledSubmit">Add
            Loan</button>
        <button class="btn btn-outline-danger" type="button" (click)="closeModal()">Cancel</button>
    </div>

</form>