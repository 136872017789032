import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ConstructAPI } from "src/app/API/constructAPI";
import { RestApiService } from "src/app/API/restapi";
import { Enums } from "src/app/Shared/enums";
import { SharedService } from "src/app/Shared/shared-service.service";
import { Loan } from "src/model/loan.model";

@Component({
  selector: "app-change-loan-status",
  templateUrl: "./change-loan-status.component.html",
  styleUrls: ["./change-loan-status.component.css"],
})
export class ChangeLoanStatusComponent implements OnInit {
  constructor(
    private activeModal: NgbActiveModal, public sharedService: SharedService, public enums: Enums,
    private restApi: RestApiService, private constructApi: ConstructAPI
  ) { }

  @Input() selectedLoan: Loan;
  selectedStatus: number;
  date: Date;
  disabledSubmit: boolean = false;

  ngOnInit(): void {
    this.selectedStatus = this.selectedLoan.Status;
  }

  reset() {
    this.activeModal.close();
  }

  submit() {

    if (this.date != undefined && this.selectedStatus != undefined) {
      this.disabledSubmit = true;
      this.changeStatus();
    }
    else
      this.sharedService.openAlert("Please fill in status and date!",this.enums.DANGER_ALERT);
  }


  changeStatus() {
    var data = {
      "status": this.selectedStatus,
      "date": this.date
    }
    var xhr = this.restApi.putRequest(this.constructApi.getChangeStatus(this.selectedLoan.ID), data);

    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        if (xhr.status == 200) {
          // var json = JSON.parse(xhr.responseText);
          this.sharedService.openAlert("Successfully changed status",this.enums.SUCCESS_ALERT)
          this.reset();
          window.location.reload();
        } else {

          this.sharedService.openErrorMessage(xhr);

        }

      }
    }
  }
}
