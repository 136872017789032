<form>
    <!-- Modal Header -->
    <div class="modal-header bg-info">
        <h4 class="modal-title">Withdraw Simpan BD to Simpan</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body">

        <!--Account Number-->
        <div class="row p-3 d-flex justify-content-center align-items-center">

            <div class="col-12">
                <p>From Account</p>
            </div>
            <div class="col-12">
                <select class="browser-default form-select" name="selectedAccount" [(ngModel)]="selectedAccountId">
                    <optgroup label="Company Account">
                        <option *ngFor="let account of companyBankAccountList" [value]="account.ID"
                            [selected]="setSelectedAccount(account.ID)"
                            [disabled]="isBalanceInsufficient(account.Balance)">
                            {{account.Name}} (RM {{account.Balance ||0}})
                        </option>
                    </optgroup>

                    <optgroup label="Cash Account">
                        <option *ngFor="let account of cashAccountList" [value]="account.ID"
                            [selected]="setSelectedAccount(account.ID)"
                            [disabled]="isBalanceInsufficient(account.Balance)">
                            {{account.Name}} (RM {{account.Balance ||0}})
                        </option>
                    </optgroup>

                </select>
            </div>

        </div>


        <!--Amount-->

        <div class="card">
            <div class="card-header">Withdrawal Details</div>
            <div class="card-body">
                <div class="group">
                    <input type="number" required class="textInputLong" name="amount" [(ngModel)]="amount" min=0
                        (keypress)="sharedService.isNumberKey($event)">
                    <span class="highlight"></span>
                    <span class="bar barLong"></span>
                    <label>Amount</label>
                </div>
            </div>
        </div>


        <!--Date-->
        <mat-form-field class="full-width-formField bar">
            <input matInput class="textInputLong" [matDatepicker]="myDatepicker" name="withdrawalDate"
                [(ngModel)]="withdrawalDate" placeholder="Withdrawal Date" [max]="sharedService.getTodayDate()" onkeydown="return false">
            <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #myDatepicker name="withdrawalDate"></mat-datepicker>
        </mat-form-field>

        <!--Remark-->
        <div class="group">
            <input type="text" required class="textInputLong" name="remark" [(ngModel)]="remark">
            <span class="highlight"></span>
            <span class="bar barLong"></span>
            <label> Remark</label>
        </div>

    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="submit()" [disabled]="disabledSubmit">Withdraw to
            Simpan</button>
        <button class="btn btn-outline-danger" type="button" (click)="reset()">Cancel</button>
    </div>

</form>