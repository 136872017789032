<div class="card mt-2" *ngIf="sharedLoanRequest!=undefined">
    <div class="card-body pt-0">
        <div class="row mb-1 p-0">
            <ng-container *ngFor="let company of sharedLoanRequest.Companies">
                <ng-template #tooltipContent>
                    <span>{{company.CompanyCode}} (RM{{findCompanyPrincipal(company.CompanyId)}})</span>
                </ng-template>

                <div class="col mx-1 my-0" style="height: 10px;"
                    [ngClass]="{'agreed':company.Agreed==true,'denied':company.Agreed==false, 'pending':company.Agreed ==null}"
                    placement="top" [ngbTooltip]="tooltipContent">
                </div>
            </ng-container>
        </div>
        <div class="row">
            <div class="col-12">
                <h3>{{sharedLoanRequest.CustomerName}}</h3>
                <hr>
            </div>
            <div class="col-12">
                <p>Principal: RM {{loggedInCompanyLoanRequest.LoanInfo.Principal| number:'1.0-2'}} /
                    <span>RM{{calculateSharedLoanTotal() | number:'1.0-2'}}</span>
                </p>
                <p>Settlement: RM {{sharedService.calculateEPFSettlementAmount(loggedInCompanyLoanRequest.LoanInfo)|
                    number:'1.0-2'}}
                    /<span>RM{{calculateSharedLoanTotalSettlement()| number:'1.0-2'}}</span> </p>
                <p>Charges: RM {{loggedInCompanyLoanRequest.LoanInfo.SettlementCharges.Stamp +
                    loggedInCompanyLoanRequest.LoanInfo.SettlementCharges.ServiceCharge +
                    loggedInCompanyLoanRequest.LoanInfo.SettlementCharges.Extras | number:'1.0-2'}}

                    <ng-template #chargesTooltip>
                        <p>Settlement Stamp: RM {{ loggedInCompanyLoanRequest.LoanInfo.SettlementCharges.Stamp|
                            number:'1.0-2' }} </p>
                        <p>Settlement SC: RM {{ loggedInCompanyLoanRequest.LoanInfo.SettlementCharges.ServiceCharge|
                            number:'1.0-2' }}
                        </p>
                        <p>Settlement DL: RM {{ loggedInCompanyLoanRequest.LoanInfo.SettlementCharges.Extras|
                            number:'1.0-2'}}</p>
                    </ng-template>
                    <i class="far fa-question-circle ms-2" placement="top" [ngbTooltip]="chargesTooltip"
                        style="cursor:pointer;"></i>

                </p>
                <p>Deductions:RM

                    {{loggedInCompanyLoanRequest.LoanInfo.ServiceCharge +
                    loggedInCompanyLoanRequest.LoanInfo.StampAmount +
                    sharedService.calculateDuitLebihAmount(loggedInCompanyLoanRequest.LoanInfo.Extras) |
                    number:'1.0-2'}}
                    <ng-template #deductionTooltip>
                        <p>Stamp: RM {{ loggedInCompanyLoanRequest.LoanInfo.StampAmount| number:'1.0-2' }} </p>
                        <p>SC: RM {{ loggedInCompanyLoanRequest.LoanInfo.ServiceCharge | number:'1.0-2'}}</p>
                        <p>DL: RM {{
                            sharedService.calculateDuitLebihAmount(loggedInCompanyLoanRequest.LoanInfo.Extras)|
                            number:'1.0-2'}}</p>
                    </ng-template>

                    <i class="far fa-question-circle ms-2" placement="top" [ngbTooltip]="deductionTooltip"
                        style="cursor:pointer;"></i>
                </p>
            </div>
            <hr>

            <div class="col-12 d-flex justify-content-end align-items-right">
                <button class="btn btn-success" (click)="processShareLoan(sharedLoanRequest.LoanGroupId, 'agree')"
                    *ngIf="isAgreed==false || isAgreed==null">
                    Agree
                </button>
                <button class="btn btn-danger mx-1" (click)="processShareLoan(sharedLoanRequest.LoanGroupId,'deny')"
                    *ngIf="isAgreed==null">
                    Deny
                </button>
                <button class="btn btn-primary me-1" (click)="processShareLoan(sharedLoanRequest.LoanGroupId,'unagree')"
                    *ngIf="isAgreed==true">
                    Unagree
                </button>
                <button class="btn btn-info " (click)="openAuthorizeLoanRequestModal()">
                    View Details
                </button>
            </div>
        </div>

    </div>
</div>