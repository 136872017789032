import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { APIAddress } from 'src/app/API/apiAddresses';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { DataService } from 'src/app/Shared/data.service';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Profile } from 'src/model/profile.model';

@Component({
  selector: 'app-add-profile',
  templateUrl: './add-profile.component.html',
  styleUrls: ['./add-profile.component.css']
})
export class AddProfileComponent implements OnInit {

  @Input() addProfileCustomerId: number;
  custOccupation: string;
  salaryAmount: number;
  salaryDay: number;
  remark: string;
  customerCode: string;
  dateCreated: Date = new Date();

  custOccupationNull = false;
  salaryAmountNull = false;
  salaryDayNull = false;
  remarkNull = false;
  customerCodeNull = false;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  disabledSubmit = false;
  constructor(private activeModal: NgbActiveModal, private restapi: RestApiService, private constructApi: ConstructAPI, private dataService: DataService, 
    public sharedService: SharedService,private enums:Enums) { }

  validField(field: string) {
    switch (field) {
      case 'custOccupation': this.custOccupationNull = (this.custOccupation === ''); break;
      case 'salaryAmount': this.salaryAmountNull = (this.salaryAmount === 0); break;
      case 'salaryDay': this.salaryDayNull = (this.salaryDay === 0); break;
      case 'customerCode': this.customerCodeNull = (this.customerCode === ''); break;
    }
  }

  validFields() {
    this.custOccupationNull = (this.custOccupation == undefined || this.custOccupation === '');
    this.salaryAmountNull = (this.salaryAmount == undefined);
    this.salaryDayNull = (this.salaryDay == undefined || this.salaryDay === 0);
    this.customerCodeNull = (this.customerCode == undefined || this.customerCode === '');

    var salaryDayInvalid = (this.salaryDay < 1 || this.salaryDay > 31);


    return (this.custOccupationNull || this.salaryAmountNull || this.customerCodeNull || salaryDayInvalid);
  }
  ngOnInit(): void {
  }


  submit() {
    if (this.validFields() == true)
      this.sharedService.openAlert("Please key in all the fields correctly",this.enums.DANGER_ALERT);
    else
      this.addProfile();
  }

  addProfile() {

    this.disabledSubmit = true;
    var data = {
      "occupation": this.custOccupation,
      "salary": this.salaryAmount,
      "salaryDay": this.salaryDay,
      "remark": this.remark,
      "dateCreated": this.dateCreated.toISOString(),
      "customerCode": this.customerCode,
      "customerId": Number(this.addProfileCustomerId)
    };

    var xhr = this.restapi.postRequest(this.constructApi.getAddProfile(), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        if (xhr.status == 200) {
          var json = JSON.parse(xhr.responseText);
          var profile = new Profile(json.id, json.occupation, json.salary, json.salaryDay, json.remark, undefined, json.dateCreated, json.customerId, json.customerCode, 0, 0);
          this.passEntry.emit(profile);

          this.reset();
          this.sharedService.openAlert("Successfully added profile!",this.enums.SUCCESS_ALERT);

        }
        else
          this.sharedService.openErrorMessage(xhr);
      }

    }

  }

  reset() {
    this.activeModal.close();
  }
}
