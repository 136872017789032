<!-- 
        <h6 class="fw-bold">
            <button class="btn btn-warning" (click)="payment.isOverlap=false"><i class="fas fa-undo"></i></button>
            Overlap {{payment.loan.ReceiptNumber}}
            (Amount: RM {{payment.loan.Balance}})
        </h6> -->

<mat-expansion-panel (opened)="payment.panelOpenState = true" (closed)="payment.panelOpenState = false"
    [expanded]="payment.panelOpenState">
    <mat-expansion-panel-header style="background-color: lightyellow;">
        <mat-panel-title>
            <button class="btn btn-warning me-2" (click)="resetOverlap(payment)"><i class="fas fa-undo"></i></button>
            <b>Overlap {{payment.loan.ReceiptNumber}}
                (Amount: RM {{payment.loan.Balance}})</b>
        </mat-panel-title>
        <mat-panel-description>
            <!-- Currently I am {{panelOpenState ? 'open' : 'closed'}} -->
            <h6 class="fw-bold" [ngClass]="getPaymentColor(calculateTotalPayment())"> Paid :
                RM {{calculateTotalPayment()||0 |number:'1.0-2'}}
                <span class="badge rounded-pill text-bg-success ms-1">
                    + RM {{this.amount + calculateIntAfterPart()+ this.stamp + this.serviceCharge}}
                </span>
                <span class="badge rounded-pill text-bg-danger ">
                    - RM {{calculateTotalLoanOnHand()|number:'1.0-2'}}
                </span>

                <span class="badge rounded-pill text-bg-danger ms-1" *ngIf="payWithSimpan==true">
                    <i class="fas fa-piggy-bank"></i> - RM {{this.calculateSimpanOut()|number:'1.0-2'}}
                </span>
            </h6>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <hr />
    <div class="row">
        <div class="col-6">
            <!--Amount-->
            <div class="group mt-3">
                <input type="number" required class="textInputLong" name="amount" [(ngModel)]="amount" min=0
                    (keypress)="sharedService.isNumberKey($event)" (ngModelChange)="resetIntAndCharges()">
                <span class="highlight"></span>
                <span class="bar barLong"></span>
                <label>Overlap Amount</label>
            </div>
            <h6 class="fw-bold">Balance: RM {{payment.loan.Balance-amount}} </h6>
            <div class="payInterestCard py-2" *ngIf="payment.loan.Balance-amount>0 && amount>0">
                <div class="col-12 py-2 ">
                    <mat-slide-toggle class="mat-slide-toggle-lg" [hideIcon]="true" color="primary" [(ngModel)]="payInterest" name="payInterest">
                        <span style="color:orangered">Pay Interest <span *ngIf="payInterest==true"> (RM{{
                                calculateIntAfterPart() |number:'1.0-2'}} -
                                {{this.payment.loan.InterestRate |number:'1.0-2'}}%)</span></span>
                    </mat-slide-toggle>

                    <div class="row" [hidden]="payInterest==false">
                        <div class="col-12">
                            <!--Stamp-->
                            <div class="group mt-3">
                                <input type="number" required class="textInputShort" name="stamp" [(ngModel)]="stamp"
                                    min=0 (keypress)="sharedService.isNumberKey($event)">
                                <span class="highlight"></span>
                                <span class="bar barShort"></span>
                                <label>Stamp</label>
                            </div>
                        </div>
                        <div class="col-12">
                            <!--SC-->
                            <div class="group mt-3">
                                <input type="number" required class="textInputShort" name="sc"
                                    [(ngModel)]="serviceCharge" min=0 (keypress)="sharedService.isNumberKey($event)">
                                <span class="highlight"></span>
                                <span class="bar barShort"></span>
                                <label>Service Charge</label>
                            </div>
                        </div>
                    </div>



                    <!--consider need add 2 fields for stamp and sc / straight use another component-->
                </div>
            </div>

        </div>
        <div class="col-1">
            <i class="fas fa-arrow-right"></i>
        </div>
        <div class="col-5">
            <button class="btn btn-warning" (click)="openAddLoanDialog()"> <i class="fas fa-plus"></i> Add
                Loan</button>
            <ng-container *ngFor="let addedLoan of addedLoanList;let i=index">
                <div class="card addedLoanCard my-2">
                    <div class="card-body">
                        <div class="row p-0">
                            <div class="col-11">
                                <div class="d-block">
                                    <span class="fw-bold">Added <span
                                            class="badge text-bg-success">{{addedLoan.Scheme}}</span> : RM
                                        {{addedLoan.Principal}}
                                    </span>
                                </div>
                                <div class="d-block">
                                    <span class="fw-bold">On Hand :RM
                                        {{sharedService.calculateTotalOnHand(addedLoan)}}
                                    </span>
                                </div>

                            </div>
                            <div class="col-1 p-0">
                                <span class="d-flex justify-content-end align-items-right"
                                    style=" cursor: pointer;color:gray" (click)="deleteNewLoan(i)"> <i
                                        class="fas fa-times"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

        </div>
        <div class="col-12 mt-2">
            <!--Date-->
            <mat-form-field class="full-width-formField bar">
                <input matInput class="textInputLong" [max]="sharedService.getTodayDate()"
                    [matDatepicker]="myDatepicker" name="paymentDate" [(ngModel)]="paymentDate"
                    placeholder="Payment Date" onkeydown="return false" [disabled]="batchDate!=undefined">
                <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #myDatepicker name="paymentDate"></mat-datepicker>
            </mat-form-field>
        </div>
    </div>

    <div class="card-footer d-flex align-items-right justify-content-end">
        <button class=" btn btn-success" (click)="submit()">Confirm</button>
    </div>

</mat-expansion-panel>