import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Account } from 'src/model/account.model';

@Component({
  selector: 'app-add-toting',
  templateUrl: './add-toting.component.html',
  styleUrls: ['./add-toting.component.css']
})
export class AddTotingComponent implements OnInit {
  @Input() companyBankAccountList: Account[] = new Array;
  @Input() cashAccountList: Account[] = new Array;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  selectedAccountId: number;
  selectedAccountBalance: number;

  @Input() paidByDuitLebih: boolean;

  //2-WAY BINDING INFO
  totingDate: Date;
  remark: string;
  amount: number = 0;

  disabledSubmit = false;

  isReturn = false;
  constructor(private activeModal: NgbActiveModal, private restapi: RestApiService, private constructApi: ConstructAPI, public sharedService: SharedService, private enums: Enums) { }

  ngOnInit(): void {
    this.totingDate = new Date();
  }

  setSelectedAccount(accountId: number) {

    if (accountId === this.selectedAccountId)
      return true
    else
      return false;

  }
  isFieldNull() {
    var paymentNull = (this.amount == undefined || this.amount == 0);
    var remarkNull = (this.remark == undefined || this.remark == "");
    var selectedAccountIdNull = (this.selectedAccountId == undefined);
    var paymentValid = isNaN(+this.amount)

    return paymentNull || remarkNull || selectedAccountIdNull || paymentValid
  }

  checkAccountBalanceSufficient() {
    var index = this.cashAccountList.findIndex(account => Number(account.ID) == Number(this.selectedAccountId));

    if (index == -1) {
      index = this.companyBankAccountList.findIndex(account => Number(account.ID) == Number(this.selectedAccountId));

      if (this.companyBankAccountList[index].Balance - this.amount < 0)
        return false;
    }
    else {
      if (this.cashAccountList[index].Balance - this.amount < 0)
        return false;
    }
    return true;
  }

  addToting() {

    this.disabledSubmit = true;
    var isoTaskDate = (new Date(Number(this.totingDate))).toISOString();
    var remark = (this.isReturn == true) ? "TOTING RETURN - " : "TOTING -";
    var data = {
      "amount": this.amount,
      "accountId": Number(this.selectedAccountId),
      "date": isoTaskDate,
      "remark": remark + this.amount + "(" + this.remark + ")"
    };


    var xhr = (this.isReturn == true) ? this.restapi.postRequest(this.constructApi.getReturnToting(), data) : this.restapi.postRequest(this.constructApi.getAddToting(), data);
    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        this.disabledSubmit = false;

        if (xhr.status == 200) {
          // var json = JSON.parse(xhr.responseText);

          let account = new Account(this.selectedAccountId, null, null, null, null, null, null, this.amount)
          this.passEntry.emit(account)
          this.reset();
          this.sharedService.openAlert("Toting operation successful!", this.enums.SUCCESS_ALERT);
          window.location.reload();


        } else {
          this.sharedService.openErrorMessage(xhr);
        }
      }

    }
  }

  getAccountBalance() {

    var account = this.companyBankAccountList.find(account => account.ID === Number(this.selectedAccountId));
    if (account == undefined)
      account = this.cashAccountList.find(account => account.ID === Number(this.selectedAccountId));

    return account.Balance;
  }



  submit() {
    if (this.isFieldNull()) {
      this.sharedService.openAlert('Please fill in all the fields', this.enums.DANGER_ALERT)
    }
    else if (this.isReturn && this.checkAccountBalanceSufficient() == false) {
      this.sharedService.openAlert('This account does not have enough money', this.enums.DANGER_ALERT);
    }
    else {
      this.addToting();
    }
  }

  reset() {
    this.activeModal.close();
  }

}
