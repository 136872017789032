<ng-container *ngIf="!customerContactList || customerContactList.length===0;else contactList">
    <p>There's no contact record in this customer.</p>
    <p>Add a contact?</p>
    <button class="btn btn-dark" (click)="openAddContactModal()"
        *ngIf="sharedService.isAdmin()==false && sharedService.isBossAccount()==false && !sharedService.isFinance()">Add
        Contact</button>
</ng-container>


<ng-template #contactList>
    <div class="d-flex align-items-end justify-content-end p-1">
        <button class="btn btn-dark" (click)="openAddContactModal()"
            *ngIf="sharedService.isAdmin()==false && sharedService.isBossAccount()==false && !sharedService.isFinance() && sharedService.isAuditor()==false">Add
            Contact</button>
    </div>

    <ng-container *ngFor="let contact of customerContactList;let i=index">
        <div class="card shadow-lg m-2">
            <div class="row">
                <div class="col-1 d-flex justify-content-center align-items-center">
                    <img src="../../../assets/image/contactAvatar.png" class="rounded-circle"
                        style="max-width: 50px;max-height:50px;">
                </div>
                <div class="col-11">
                    <div class="row p-2">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-7">
                                    <h4 class="fw-bold text-primary">{{ contact.Name }}
                                        <span *ngIf="contact.ICNumber!=undefined"> |
                                            {{sharedService.processICNum(contact.ICNumber)}} </span>
                                    </h4>
                                </div>
                                <div class="col-5">
                                    <button class="btn btn-warning" (click)="openEditContactModal(contact)"
                                        data-bs-toggle="tooltip" data-placement="top" title="Edit"
                                        *ngIf="sharedService.isBranchAccountHandler()|| sharedService.isBossAccount()"><i
                                            class="fas fa-pencil-alt"></i></button>
                                    <button class="btn btn-danger" (click)="confirmBeforeDelete(contact)"
                                        *ngIf="this.sharedService.isBranchAccountHandler()" data-bs-toggle="tooltip"
                                        data-placement="top" title="Delete">
                                        <i class="fas fa-trash-alt"></i></button>
                                    <button class="btn btn-info ms-1" (click)="downloadGuarantorForm(contact)"
                                        data-bs-toggle="tooltip" data-placement="top" title="Print Guarantor Form">
                                        <i class="fas fa-print"></i>
                                    </button>


                                    <div class="dropdown d-inline ms-1" data-bs-toggle="tooltip" data-placement="top"
                                        title="Upload Contact files">
                                        <button class="btn btn-dark dropdown-toggle" type="button"
                                            id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false"
                                            *ngIf="sharedService.isAdmin()==false && sharedService.isBossAccount()==false && !sharedService.isFinance() && sharedService.isAuditor()==false">
                                            <i class="fas fa-upload"></i> </button>
                                        <div class="dropdown-menu dropdown-menu-end"
                                            aria-labelledby="dropdownMenuButton">
                                            <a class="dropdown-item"
                                                (click)="openUploadImageModal(contact,this.enums.CONTACT_GUARANTOR_FORM)">
                                                <i class="fas fa-signature"></i> Guarantor Form</a>
                                            <a class="dropdown-item"
                                                (click)="openUploadImageModal(contact,this.enums.CONTACT_PROOF_OF_RELATION)">
                                                <i class="far fa-heart"></i> Proof Of Relation</a>
                                            <a class="dropdown-item"
                                                (click)="openUploadImageModal(contact,this.enums.CONTACT_ACKNOWLEDGEMENT)">
                                                <i class="fas fa-clipboard-check"></i> Acknowledgement</a>
                                            <a class="dropdown-item"
                                                (click)="openUploadImageModal(contact,this.enums.CONTACT_PHOTO)">
                                                <i class="fas fa-photo-video"></i> Other Docs</a>

                                        </div>
                                    </div>
                                    <!-- <button class="btn btn-dark ms-1"  data-bs-toggle="tooltip" data-placement="top"
                                        title="Upload Images" (click)="openUploadImages(contact.ID)"
                                        *ngIf="sharedService.isBranchAccountHandler()|| sharedService.isBossAccount()">
                                        <i class="far fa-file-image"></i>
                                    </button>
                                    <button class="btn btn-success ms-1"  data-bs-toggle="tooltip" data-placement="top"
                                        title="Upload Guarantor Form" (click)="openUploadForm(contact.ID)"
                                        *ngIf="sharedService.isBranchAccountHandler()|| sharedService.isBossAccount()">
                                        <i class="fas fa-file-upload"></i>
                                    </button> -->
                                    <button class="btn btn-primary ms-1" data-bs-toggle="tooltip" data-placement="top"
                                        title="Download Guarantor Form" *ngIf="contact.GuarantorFilename!=undefined"
                                        (click)="downloadFile(contact.GuarantorFilename,viewCustomer.ID)">
                                        <i class="fas fa-download"></i>
                                    </button>

                                    <button class="btn btn-primary ms-1" data-bs-toggle="tooltip" data-placement="top"
                                        title="View All Contact Docs" (click)="openViewContactDocsModal(contact.ID)">
                                        <i class="far fa-folder-open"></i>
                                    </button>

                                </div>
                            </div>

                        </div>
                        <div class="col-12"> <i class="fas fa-phone"></i> {{ contact.ContactNumber }}</div>
                        <div class="col-12"><i class="fas fa-users"></i> {{ contact.Relation }}</div>
                        <div class="col-12"><i class="fas fa-address-book"></i> {{ contact.Address }}</div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

</ng-template>