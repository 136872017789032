<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Company Access - {{selectedCompanyCode}}</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="close()" aria-label="Close"></button>
    </div>

    <div class="modal-body">
        <div class="container-fluid p-0">

            <table class="table table-bordered">
                <tbody>
                    <tr>
                        <td><strong>User</strong></td>
                        <td> <select class="browser-default form-select" name="userList" [(ngModel)]="selectedUser">
                                <option *ngFor="let user of userList" [value]="user.Username">
                                    {{user.Username}}</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td><strong>Priority</strong></td>
                        <td><input type="number" [(ngModel)]="priority" name="priority"
                                (keypress)="sharedService.isNumberKey($event)"></td>
                    </tr>

                </tbody>
            </table>
            <div class="row">
                <div class="col d-flex justify-content-end" style="padding: 0;margin-bottom: 2%;" (click)="submit()">
                    <button class="btn btn-success" type="button">Add</button>
                </div>
            </div>


            <div class="row" style="margin: 0;">
                <div class="col p-0">
                    <div class="card shadow-lg">
                        <div class="card-body">
                            <h4 class="card-title">Current Access<br></h4>
                        </div>
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="text-center">Username</th>
                                        <th class="text-center">Priority</th>
                                        <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let access of companyAccessList;let i =index">
                                        <td class="text-center">{{access.Username}}</td>
                                        <td class="text-center">{{access.Priority}}</td>
                                        <td class="text-center">
                                            <i class="fas fa-times" style="cursor: pointer;"
                                                (click)="confirmBeforeRevoke(access.Username,i)"></i>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>