<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Shared Loan List </h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body">
        <ng-container *ngFor="let loan of sharedLoanList">
            <app-loan-card [loan]="loan" [selectedCustomer]="viewCustomer" [selectedProfile]="selectedProfile"
                [needRestrictionCustomer]="true">
            </app-loan-card>
        </ng-container>
    </div>
</form>