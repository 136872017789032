import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Customer } from 'src/model/customer.model';
import { Task } from 'src/model/task.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateNewTaskComponent } from 'src/app/AddThings/create-new-task/create-new-task.component';
import { RestApiService } from 'src/app/API/restapi';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { NextTaskComponent } from 'src/app/Dashboards/follow-next-task/follow-next-task.component';

@Component({
  selector: 'app-customer-task-list',
  templateUrl: './customer-task-list.component.html',
  styleUrls: ['./customer-task-list.component.css']
})
export class CustomerTaskListComponent implements OnInit, OnChanges {

  @Input() viewCustomer: Customer;
  @Input() allowUpdateTask: boolean = true;
  @Input() selectedProfileId: number;

  customerTaskList: Task[] = new Array;


  constructor(private modalService: NgbModal, private restapi: RestApiService, private constructAPI: ConstructAPI, public enums: Enums, public sharedService: SharedService) { }

  ngOnInit() {

    // this.getCustomerTaskInfo();
  }
  ngOnChanges() {
    if (this.customerTaskList.length > 0)
      this.customerTaskList.splice(0, this.customerTaskList.length);
    this.getCustomerTaskInfo();
  }

  getCustomerTaskInfo() {

    // var xhttp = new XMLHttpRequest();
    // var url = "http://localhost:10080/api/v1/tasks?customerId=" + this.viewCustomer.ID;
    // xhttp.withCredentials = true;

    var xhttp = this.restapi.getRequest(this.constructAPI.getTaskOfProfile(this.selectedProfileId));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));
        // console.log(jsonArray);

        jsonArray.forEach(json => {

          // create customer instance with all response data
          let task = new Task(json.id, json.customerId, json.description, json.followUpRemark, json.date, json.customerName, json.assignee,
            json.dateDone, undefined, json.profileId, json.type);
          //add task instance into task list

          this.customerTaskList.push(task);

          this.customerTaskList.sort((a, b) => (b.Date > a.Date) ? 1 : -1);

          // console.log(this.customerPaymentList);
        }
        );
      }
    };
    // xhttp.open("GET", url, true);
    // xhttp.send();

  }


  getTaskStatusString(dateDone: Date) {
    if (dateDone == undefined)
      return "PENDING";
    else
      return "DONE";
  }
  changeStatusColor(dateDone: Date) {

    if (dateDone != undefined)
      return 'green'
    else
      return 'red'
  }
  openAddTaskModal() {
    const modalRef = this.sharedService.openModal(CreateNewTaskComponent);

    modalRef.componentInstance.selectedCustomerID = this.viewCustomer.ID;
    modalRef.componentInstance.selectedCustomerName = this.viewCustomer.Name;
    modalRef.componentInstance.isSpecificCustomer = true;

    //get task that was created
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var task = entry;

      //append added task to task list
      // this.taskList.push(task);
      // console.log(JSON.stringify(this.taskList));

      //REFRESH TASK LIST
      this.customerTaskList.splice(0, this.customerTaskList.length);
      this.getCustomerTaskInfo();

    });


    modalRef.result.then((result) => {
      if (result) {

      }
    });

  }

  openNextTaskModal(task: Task) {
    var modalRef = this.sharedService.openModal(NextTaskComponent);
    modalRef.componentInstance.task = task;

    if (task.Type == this.enums.EPF_TASK)
      modalRef.componentInstance.isEPF = true;

  }

}
