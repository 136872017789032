<div class="navbar sticky-top doNotPrint">
    <div class="row" style="width: 100vw">
        <div class="col-2 col-md-1 d-flex justify-content-center align-items-center">
            <button class="btn  menuButton" type="button" (click)="toggleSidebar()">
                <i class="fa fa-bars rotate navBarIcon"></i>
            </button>

        </div>
        <div class="col d-flex   align-items-center">
            <span class="badge text-bg-warning" *ngIf="sharedService.isOperatingUser()"> {{loggedInCompanyName}} |
                {{loggedInCompanyUid}}</span>

        </div>
        <div class="col d-flex justify-content-end">
            <ng-container *ngIf="sharedService.isExpenseAdmin()==false">
                <!-- should be finance account -->
                <button class="btn navBarButton" type="button" (click)="goToBookmarks()" ngbTooltip="Quick Access Ticket" *ngIf="sharedService.isBossAccount() || sharedService.isFinance()">
                    <i class="fas fa-bookmark navBarIcon"></i>
                </button>

                <button class="btn navBarButton" type="button" (click)="openSearchTicketModal()" ngbTooltip="Search Ticket" *ngIf="sharedService.isOperatingUser()==false">
                    <i class="fas fa-ticket-alt navBarIcon"></i>
                </button>

                <ng-template #tooltipValidate>
                    <div><span>Check EPF STATEMENT</span></div>
                </ng-template>

                <button *ngIf="!sharedService.isFinance()" class="btn navBarButton" type="button" (click)="openValidateEPFStatement()"
                    [ngbTooltip]="tooltipValidate" placement="bottom">
                    <i class="fas fa-clipboard-check navBarIcon"></i>
                </button>

                <ng-template #tooltipCalculator>
                    <div><span>Quick Calculate EPF</span></div>
                </ng-template>

                <button class="btn navBarButton" type="button" (click)="openEPFCalculatorModal()"
                    [ngbTooltip]="tooltipCalculator" placement="bottom">
                    <i class="fas fa-calculator navBarIcon" style="color: #ffbd33 "></i>
                </button>

                <ng-template #tooltipReallocate>
                    <div><span>Reallocate Fund</span></div>
                </ng-template>

                <button class="btn navBarButton" type="button" (click)="openFundAllocationModal()"
                    *ngIf="sharedService.isBranchAccountHandler()" [ngbTooltip]="tooltipReallocate" placement="bottom">
                    <i class="fas fa-search-dollar navBarIcon"></i>
                </button>

                <div class="dropdown d-flex">
                    <button class="btn dropdown-toggle navBarButton"  data-bs-toggle="dropdown" aria-expanded="false"
                        type="button">
                        <i class="fa fa-search navBarIcon"></i>
                    </button>

                    <div class="dropdown-menu dropdown-menu-end" role="menu">
                        <a class="dropdown-item" role="presentation" (click)="openGlobalSearchModal(true)"
                            *ngIf="sharedService.isAdmin() || sharedService.isBossAccount() || sharedService.isFinance();else companyCustomerSearch">Global
                            Search
                            Customer</a>
                        <ng-template #companyCustomerSearch>
                            <a class="dropdown-item" role="presentation" (click)="openGlobalSearchModal(false)">Search
                                Customer Profile</a>

                        </ng-template>
                        <a class="dropdown-item" role="presentation" (click)="openSearchCustomerByReceiptModal()"
                            [hidden]="sharedService.isAdmin()==true|| sharedService.isBossAccount() || sharedService.isFinance()">Search Customer by
                            Receipt</a>
                        <a class="dropdown-item" role="presentation" (click)="openSearchTaskModal()"
                            [hidden]="sharedService.isAdmin()==true|| sharedService.isBossAccount() || sharedService.isFinance()">Search Task</a>

                        <a class="dropdown-item" role="presentation" (click)="openGlobalSearchModal(true)"
                            style="background-color: lightblue;"
                            [hidden]="sharedService.isAdmin()==true|| sharedService.isBossAccount() || sharedService.isFinance()">Global
                            Search
                            Customer</a>



                    </div>
                </div>

                <div class=" dropdown d-flex" *ngIf="!sharedService.isFinance()">
                    <button class="btn dropdown-toggle navBarButton"  data-bs-toggle="dropdown" aria-expanded="false"
                        type="button">
                        <i class="fa fa-plus navBarIcon"></i>
                    </button>

                    <div class="dropdown-menu  dropdown-menu-end" role="menu">
                        <a class="dropdown-item" role="presentation" (click)="openAddCustomerModal()">Add
                            Customer</a>
                        <a class="dropdown-item" role="presentation" (click)="openAddAccountModal()"
                            *ngIf="sharedService.isStaff()==false && sharedService.isBookkeepingStaff()==false">Add
                            Account</a>
                        <!-- <a class="dropdown-item" role="presentation"  data-bs-toggle="modal" data-target="#addPlan">Add Plan</a> -->

                        <ng-container *ngIf="sharedService.isAdmin()|| sharedService.isBossAccount()">
                            <a class="dropdown-item" role="presentation" (click)="openAddPlanModal()">Add Plan</a>
                            <a class="dropdown-item" role="presentation" (click)="openAddSubTypeModal()">Add SubType</a>
                            <a class="dropdown-item" role="presentation" (click)="openAddBlacklistCustomer()">Add
                                Blacklist Record</a>
                        </ng-container>
                        <ng-container *ngIf=" sharedService.isBossAccount()">
                            <a class="dropdown-item" role="presentation" (click)="openAddTransactionRequestModal()">Add
                                Transaction
                                Request</a>
                        </ng-container>



                    </div>
                </div>
            </ng-container>
            <div class="dropdown">
                <button class="btn  dropdown-toggle navBarButton"  data-bs-toggle="dropdown" aria-expanded="false"
                    type="button">
                    <img class="avatar" src="../assets/image/man-avatar.png" style="width: 50px;">
                </button>
                <div class="dropdown-menu dropdown-menu-end" role="menu">
                    <a class="dropdown-item" role="presentation"><strong>{{loggedInUser |uppercase}}</strong></a>
                    <a class="dropdown-item" role="presentation" (click)="openEditProfileModal()">Edit
                        Profile</a>
                    <a class="dropdown-item" role="presentation" (click)="goToServerStatusPage()"
                        *ngIf="sharedService.isBossAccount()">Server Status</a>
                    <a class=" dropdown-item" role="presentation" style="color: red;" (click)="logout()">
                        <strong>LOGOUT</strong></a>
                </div>
            </div>
        </div>
    </div>
</div>