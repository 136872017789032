<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Conflicted List</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 aria-label="Close" (click)="reset()"></button>
    </div>


    <div class="modal-body">
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col-12" [hidden]="customerList ==undefined || customerList.length<=0">
                    <h4>Customer List</h4>
                    <table mat-table [dataSource]="dataSource" matSort>

                        <!-- Name Column -->
                        <ng-container matColumnDef="Name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                            <td mat-cell *matCellDef="let customer"
                                (click)="goToCustomer(customer.CustomerID,customer.ProfileID)"> {{customer.Name}} </td>
                        </ng-container>

                        <!-- ICNumber Column -->
                        <ng-container matColumnDef="ICNumber">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> IC Number </th>
                            <td mat-cell *matCellDef="let customer"
                                (click)="goToCustomer(customer.CustomerID,customer.ProfileID)">
                                {{sharedService.processICNum(customer.ICNumber)}}
                            </td>
                        </ng-container>


                        <!-- Status Column -->
                        <ng-container matColumnDef="Status">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                            <td mat-cell *matCellDef="let customer"
                                (click)="goToCustomer(customer.CustomerID,customer.ProfileID)">
                                <h6>
                                    <span class="badge" [ngClass]="getStatusColor(customer.Status)">
                                        {{enums.customerStatusString[customer.Status-1]}}
                                    </span>

                                </h6>
                            </td>
                        </ng-container>

                        <!-- CompanyName Column -->
                        <ng-container matColumnDef="CompanyName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> CompanyName </th>
                            <td mat-cell *matCellDef="let customer"
                                (click)="goToCustomer(customer.CustomerID,customer.ProfileID)">
                                {{customer.CompanyName}}
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <!-- <mat-paginator [pageSizeOptions]="[25,50, 100]" showFirstLastButtons></mat-paginator> -->
                </div>

                <div class="col-12" [hidden]="guarantorList==undefined || guarantorList.length<=0">
                    <hr />
                    <h4>Guarantor List</h4>
                    <table mat-table [dataSource]="guarantorDataSource" matSort>

                        <!-- Name Column -->
                        <ng-container matColumnDef="Name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                            <td mat-cell *matCellDef="let guarantor"
                                (click)="goToCustomer(guarantor.CustomerID,guarantor.ProfileID)">
                                {{guarantor.GuarantorName}} </td>
                        </ng-container>

                        <!-- ICNumber Column -->
                        <ng-container matColumnDef="ICNumber">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> IC Number </th>
                            <td mat-cell *matCellDef="let guarantor"
                                (click)="goToCustomer(guarantor.CustomerID,guarantor.ProfileID)">
                                {{sharedService.processICNum(guarantor.GuarantorIC)}}
                            </td>
                        </ng-container>


                        <!-- CompanyName Column -->
                        <ng-container matColumnDef="CompanyName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> CompanyName </th>
                            <td mat-cell *matCellDef="let guarantor"
                                (click)="goToCustomer(guarantor.CustomerID,guarantor.ProfileID)">
                                {{guarantor.CompanyName}}
                            </td>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="CustomerName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Name </th>
                            <td mat-cell *matCellDef="let guarantor"
                                (click)="goToCustomer(guarantor.CustomerID,guarantor.ProfileID)"> {{guarantor.Name}}
                            </td>
                        </ng-container>

                        <!-- ICNumber Column -->
                        <ng-container matColumnDef="CustomerIC">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer IC </th>
                            <td mat-cell *matCellDef="let guarantor"
                                (click)="goToCustomer(guarantor.CustomerID,guarantor.ProfileID)">
                                {{sharedService.processICNum(guarantor.ICNumber)}}
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedGuarantorColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedGuarantorColumns;"></tr>
                    </table>
                    <!-- <mat-paginator [pageSizeOptions]="[25,50, 100]" showFirstLastButtons></mat-paginator> -->
                </div>


                <div class="col-12" [hidden]="ticketList==undefined || ticketList.length<=0">
                    <hr />
                    <h4>Ticket List</h4>
                    <table mat-table [dataSource]="ticketDataSource" matSort>

                        <!-- TicketNo Column -->
                        <ng-container matColumnDef="TicketNo">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Ticket No </th>
                            <td mat-cell *matCellDef="let ticket"
                                (click)="goToCustomer(ticket.CustomerId,ticket.ProfileID)">
                                #{{ticket.ID}} </td>
                        </ng-container>

                        <!-- CompanyName Column -->
                        <ng-container matColumnDef="CompanyName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Company Name </th>
                            <td mat-cell *matCellDef="let ticket"
                                (click)="goToCustomer(ticket.CustomerId,ticket.ProfileID)">
                                {{ticket.CompanyCode}}
                            </td>
                        </ng-container>


                        <!-- CustomerName Column -->
                        <ng-container matColumnDef="CustomerName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Name </th>
                            <td mat-cell *matCellDef="let ticket"
                                (click)="goToCustomer(ticket.CustomerId,ticket.ProfileID)">
                                {{ticket.CustomerName}}
                            </td>
                        </ng-container>


                        <!-- ICNumber Column -->
                        <ng-container matColumnDef="CustomerIC">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer IC </th>
                            <td mat-cell *matCellDef="let ticket"
                                (click)="goToCustomer(ticket.CustomerId,ticket.ProfileID)">
                                {{sharedService.processICNum(ticket.CustomerIC)}}
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedTicketColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedTicketColumns;"></tr>
                    </table>
                    <!-- <mat-paginator [pageSizeOptions]="[25,50, 100]" showFirstLastButtons></mat-paginator> -->
                </div>

            </div>
        </div>
    </div>
</form>