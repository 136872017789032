import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { PanZoomConfig, PanZoomAPI, PanZoomModel, PanZoomConfigOptions } from 'ngx-panzoom';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { Customer } from 'src/model/customer.model';

@Component({
  selector: 'app-enlarge-image',
  templateUrl: './enlarge-image.component.html',
  styleUrls: ['./enlarge-image.component.css'],
})
export class EnlargeImageComponent implements OnInit {

  constructor(private activeModal: NgbActiveModal, private restApi: RestApiService, private constructApi: ConstructAPI, public sharedService: SharedService, private enums: Enums) { }

  @Input() customerId: number;
  @Input() customer: Customer;

  @Input() fileName: string;
  @Input() fileList = new Array;

  @Input() currentFileIndex: number;

  @Input() isSetFile: boolean = false;
  @Input() setId: number;
  @Input() isValidFile: boolean;

  @Input() reserveGroupID: number;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  rotateDegree: number = 0;

  image: string;

  panZoomConfigOptions: PanZoomConfigOptions = { "zoomLevels": 8, "freeMouseWheelFactor": 0.005 }
  panZoomConfig: PanZoomConfig = new PanZoomConfig(this.panZoomConfigOptions);

  ngOnInit(): void {

    console.log(this.reserveGroupID)
    this.createImageLink();
  }


  createImageLink() {

    if (this.setId != undefined)
      this.image = this.restApi.apiUrl + this.constructApi.getSetFileFull(this.setId, this.fileName);
    else if (this.reserveGroupID != undefined)
      this.image = this.restApi.apiUrl + this.constructApi.getReserveGroupFileFull(this.reserveGroupID, this.fileName);
    else
      this.image = this.restApi.apiUrl + this.constructApi.getFullFile(this.customerId, this.fileName);

  }

  changePreviousImage() {
    if (this.currentFileIndex - 1 >= 0) {

      this.fileName = this.fileList[--this.currentFileIndex].label;

      this.createImageLink();
    }

    else
      this.sharedService.openAlert("This is the first image of the list", this.enums.DANGER_ALERT);
  }

  changeNextImage() {
    if (this.currentFileIndex + 1 <= this.fileList.length - 1) {

      this.fileName = this.fileList[++this.currentFileIndex].label;

      this.createImageLink();
    }
    else
      this.sharedService.openAlert("This is the last image of the list", this.enums.DANGER_ALERT);
  }


  checkCanDelete() {

    //branch manager only can delete set documents
    if (this.sharedService.isBranchManager() == true) {

      if (this.setId != undefined) {
        if (this.isValidFile == false)
          return true;
        else
          return false;
      }
      else
        return false;
    }

    // boss account can delete all kinds of documents
    else if (this.sharedService.isBossAccount())
      return true;

    //admin only can delete customer documents 
    else if (this.sharedService.isAdmin()) {
      if (this.customerId != undefined)
        return true;
      else
        return false
    }

    //other role not allowed to delete document
    else
      return false;
  }


  confirmDeletePicture() {

    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Are you sure you want to delete this picture (" + this.fileName + ") ?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something

      if (confirmation == true) {
        if (this.isSetFile == false)
          this.deleteImage(this.customerId, this.fileName, undefined);
        else
          this.deleteImage(undefined, this.fileName, this.setId);

      }

    });

  }


  deleteImage(customerId: number, filename: string, setId: number) {
    var encoded = encodeURIComponent(filename);
    var xhttp;
    if (this.isSetFile == false)
      xhttp = this.restApi.deleteRequest(this.constructApi.getDeleteFile(customerId, encoded))
    else
      xhttp = this.restApi.deleteRequest(this.constructApi.getSetFileFull(setId, encoded))

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4) {

        if (xhttp.status == 200) {
          // Typical action to be performed when the document is ready:
          this.sharedService.openAlert("Successfully deleted picture!", this.enums.SUCCESS_ALERT);
          // window.location.reload();
          this.passEntry.emit(true);
          this.reset();
        }
        else {
          this.sharedService.openErrorMessage(xhttp);
        }


      }


    };
  }





  reset() {
    this.activeModal.close();
  }

  rotate() {
    this.rotateDegree = (this.rotateDegree == 270) ? 0 : this.rotateDegree + 90;
    var rotateStr = "rotate(" + this.rotateDegree + "deg)";
    var img = document.getElementById('enlargedImage');
    img.style.transform = rotateStr

  }


}
