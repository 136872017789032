<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Add Blacklist Record</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()" aria-label="Close"></button>
    </div>

    <div class="modal-body">
        <div class="container-fluid p-0">
            <div class="mt-2">
                <div class="col-12 col-md-6">
                    <div class="group">
                        <input type="text" required class="textInputLong" name="name" [(ngModel)]="name">
                        <span class="highlight"></span>
                        <span class="bar barLong"></span>
                        <label> Name*</label>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="group">
                        <input type="text" required class="textInputLong" name="icNumber" [(ngModel)]="icNumber"
                            (keypress)="sharedService.isNumberKey($event)">
                        <span class="highlight"></span>
                        <span class="bar barLong"></span>
                        <label>IC Number*</label>
                        <p class="text-danger my-1 mx-0 p-0"
                            *ngIf="this.sharedService.checkIfICValid(this.icNumber) == false"> IC Number format
                            invalid </p>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="group">
                        <input type="text" class="textInputLong" name="otherId" [(ngModel)]="otherId">
                        <span class="highlight"></span>
                        <span class="bar barLong"></span>
                        <label> Other ID*</label>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="group">
                        <input type="text" required class="textInputLong" name="status" [(ngModel)]="status">
                        <span class="highlight"></span>
                        <span class="bar barLong"></span>
                        <label> Status*</label>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div><span>Remark*</span></div>
                    <div><textarea class="form-control" rows="10" required [(ngModel)]="remark"
                            name="remark"></textarea></div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="submit()" [disabled]="disabledSubmit">Add
            Record</button>
        <button class="btn btn-outline-danger" type="button" data-dismiss="modal" (click)="reset()">Cancel</button>
    </div>
</form>