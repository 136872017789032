import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Transaction } from 'src/model/transaction.model';
import { RestApiService } from 'src/app/API/restapi';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { Router } from '@angular/router';

@Component({
  selector: 'app-show-unprocessed-set-transaction',
  templateUrl: './show-unprocessed-set-transaction.component.html',
  styleUrls: ['./show-unprocessed-set-transaction.component.css']
})
export class ShowUnprocessedSetTransactionComponent implements OnInit {

  @Input() startDate: Date = new Date();
  endDate: Date = new Date();
  transactionList: Transaction[] = new Array;
  @Input() selectedCompany;

  constructor(public router: Router, public activeModal: NgbActiveModal, public sharedService: SharedService, public enums: Enums,
    private restapi: RestApiService, private constructApi: ConstructAPI) { }

  ngOnInit(): void {
    this.getUnprocessedTransactions();
  }



  getUnprocessedTransactions() {
    this.transactionList.splice(0, this.transactionList.length);
    var startDateStr = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate()).toISOString();

    if (this.startDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var addADayDate = new Date(Number(this.endDate) + (3600 * 1000 * 24) - 1);
    var endDateStr = (this.endDate == undefined) ? undefined : addADayDate.toISOString();

    var types = [this.enums.EXTRAS, this.enums.ADVANCE, this.enums.EXPENSE]
    var xhttp = this.restapi.getRequest(this.constructApi.getAllTransactions(this.selectedCompany, types, startDateStr, endDateStr, 0,
      undefined, undefined, undefined, false))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          // if (json.setId == undefined) {

          var transaction = new Transaction(json.id, json.remark, json.amount, undefined, undefined, json.date, json.type, json.handleBy,
            json.receiptId, json.customerId, json.customerName, json.icNumber, undefined, json.subtype, json.groupId, json.setId);

          if (json.loanId == undefined) {
            if (json.type == this.enums.EXTRAS) {

              if (Number(json.amount) < 0) {
                this.transactionList.push(transaction);
              }
            }
            else if (json.type == this.enums.ADVANCE) {
              if (json.subtype == this.enums.BORROW_ADV_SUBTYPE_ID)
                this.transactionList.push(transaction);
            }
            else {
              this.transactionList.push(transaction);
            }
          }

          // }

        }
        );
        this.getUnprocessedLoanTransactions();
      }
    };
  }


  getUnprocessedLoanTransactions() {
    var startDateStr = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate()).toISOString();

    if (this.startDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var addADayDate = new Date(Number(this.endDate) + (3600 * 1000 * 24) - 1);
    var endDateStr = (this.endDate == undefined) ? undefined : addADayDate.toISOString();

    var types = [this.enums.EXTRAS, this.enums.DISCOUNT]
    var xhttp = this.restapi.getRequest(this.constructApi.getCompanyAllLoansTransactions(this.selectedCompany, startDateStr, endDateStr, undefined, types,
      undefined, undefined, undefined, undefined, 0, false))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          // if (json.setId == undefined) {

          var transaction = new Transaction(json.id, json.remark, json.amount, undefined, undefined, json.date, json.type, json.handleBy,
            json.receiptId, json.customerId, json.customerName, json.icNumber, undefined, json.subtype, json.groupId, json.setId);

          this.transactionList.push(transaction);

          // }

        }
        );
        this.transactionList.sort((a, b) => (b.Type > a.Type) ? 1 : -1);
      }
    };
  }

  goToCustomer(id: number) {
    this.activeModal.close();
    this.router.navigate(['/manageCustomer', id]);
  }


}
