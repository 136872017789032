import { Component, OnInit } from '@angular/core';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { DeployWebappComponent } from '../deploy-webapp/deploy-webapp.component';
import { JsonPipe } from '@angular/common';
import { VersionStatus } from 'src/app/Shared/versionStatus';
import { ThemeService } from 'igniteui-angular/lib/services/theme/theme.service';
import { MaintenanceModeToggleComponent } from '../maintenance-mode-toggle/maintenance-mode-toggle.component';



interface ServerStatus {
  databaseConnected: boolean,
  databasePing: string,
  redisConnected: boolean,
  redisPing: string,
  storageConnected: boolean,
  storagePing: string,
  maintenanceMode: boolean,
  version: string,
  websiteVersion: string,
  cpdbConnected: boolean,
  cpdbPing: string
}


@Component({
  selector: 'app-server-status-page',
  templateUrl: './server-status-page.component.html',
  styleUrls: ['./server-status-page.component.css']
})
export class ServerStatusPageComponent implements OnInit {

  serverStatus: ServerStatus;
  signedInUserList: string[] = new Array;

  errorCode: string;
  errorLogList: string[] = new Array;

  time: number = 10;
  constructor(private restApi: RestApiService, private constructApi: ConstructAPI, public sharedService: SharedService,public versionStatus:VersionStatus) { }

  ngOnInit(): void {
    this.getServerStatus();
    this.getSignedInUsers();
    this.getErrorLog();
  }



  refreshEverything() {
    this.serverStatus = undefined;
    this.errorLogList = undefined;
    this.signedInUserList.splice(0);


    this.getServerStatus();
    this.getSignedInUsers();
    this.getErrorLog();
  }

  getServerStatus() {

    var xhttp = this.restApi.getRequest(this.constructApi.getServerStatus());
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var json = JSON.parse(xhttp.responseText);
        this.serverStatus = {
          databaseConnected: json.databaseConnected, databasePing: json.databasePing, redisConnected: json.redisConnected, redisPing: json.redisPing, storageConnected: json.storageConnected,
          storagePing: json.storagePing, maintenanceMode: json.maintenanceMode, version: json.version, websiteVersion: json.websiteVersion, cpdbConnected: json.cpDatabaseConnected, cpdbPing: json.cpDatabasePing
        }
      }
    };

  }
  
  openMaintenaceModeModal(value: boolean) {
    const modalRef = this.sharedService.openModal(MaintenanceModeToggleComponent);
    modalRef.componentInstance.turn_on = value;
    modalRef.result.then(r => this.getServerStatus());
  }


  getErrorLog(errorCode?: string) {

    var xhttp = this.restApi.getRequest(this.constructApi.getErrorLog(errorCode));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse(xhttp.responseText);

        this.errorLogList = jsonArray;
        // console.log(this.errorLogList[0])
        // jsonArray.forEach(json => {

        //   // console.log(json)
        //   this.errorLogList.push(json);


        // }
        // );

      }
    };

  }


  getSignedInUsers() {

    var xhttp = this.restApi.getRequest(this.constructApi.getSignedInUserList("30s"));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse(xhttp.responseText);
        jsonArray.forEach(json => {

          let username = json.username;
          this.signedInUserList.push(username)

        }
        );
      }
    };

  }

  openDeployWebAppModal() {
    var modalRef = this.sharedService.openModal(DeployWebappComponent, "regularModal");

  }


}
