<ng-container>
    <div class="row ">
        <div class="col d-flex justify-content-end align-items-right">
            <button class="btn btn-primary" (click)="openAddCustomerRemarkModal()">
                <i class="fas fa-plus"></i> Add Remark</button>
        </div>
    </div>

    <div *ngIf="customerAdminRemarkList==undefined || customerAdminRemarkList.length==0;else adminTable">
        This customer do not have any remark
    </div>
    <ng-template #adminTable>
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Remark</th>
                    <th>Author</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let remark of customerAdminRemarkList" [ngStyle]="changeBackground(remark.NeedAttention)">
                    <td>{{remark.Date | date:'dd-MM-yyyy'}}</td>
                    <td>{{remark.Remark}}</td>
                    <td>{{remark.Author}}</td>
                </tr>
            </tbody>

        </table>
    </ng-template>

</ng-container>