<ng-container *ngFor="let company of companies">
    <div class="card">
        <div class="card-body companyCard">
            <h6>
                <strong>{{company.companyCode}} - {{company.companyName}}</strong>
            </h6>
            <p>
                <i class="far fa-building"></i>
                Address: {{company.address}}
            </p>
            <p>
                <i class="fas fa-phone"></i>
                Phone: {{company.phone}}
            </p>

        </div>
    </div>
</ng-container>