<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Loan Request List</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 aria-label="Close" aria-label="Close"
            (click)="closeModal()"></button>
    </div>

    <div class="modal-body">

        <div class="row">
            <div class="col d-flex justify-content-end align-items-end m-1">
                <button class="btn btn-info" (click)="isExpanded = !isExpanded" [attr.aria-expanded]="!isExpanded"
                    aria-controls="collapseExample">Show Details</button>
            </div>
        </div>

        <ul class="nav nav-tabs">
            <li class="nav-item" *ngIf="containsPending()==true">
                <a class="nav-link" [ngClass]="checkActive('Pending')" role="tab"  data-bs-toggle="tab" href="#tabPending"
                    (click)="setSelectedMenu('Pending')">
                    <p> <i class="fab fa-readme"></i> Pending</p>
                </a>
            </li>
            <li class="nav-item" *ngIf="containsApproved()==true">
                <a class="nav-link" [ngClass]="checkActive('Approved')" role="tab"  data-bs-toggle="tab"
                    href="#tabApproved" (click)="setSelectedMenu('Approved')">
                    <p> <i class="fas fa-thumbs-up"></i> Approved</p>
                </a>
            </li>
        </ul>



        <div class="tab-content">

            <!--tabPending-->
            <div class="tab-pane " role="tabpanel" [ngClass]="checkActive('Pending')" id="tabPending" *ngIf="checkMenuSelected('Pending')">
                <ng-container *ngFor="let request of loanRequestList">
                    <ng-container *ngIf="request.Approved==undefined">
                        <!-- <ng-container *ngTemplateOutlet="requestView;context:{request:request}"></ng-container> -->
                        <app-loan-request-details [request]="request" [isExpanded]="isExpanded">
                        </app-loan-request-details>
                    </ng-container>
                </ng-container>

            </div>

            <!--tabApproved-->
            <div class="tab-pane" role="tabpanel" [ngClass]="checkActive('Approved')" id="tabApproved"
                *ngIf="checkMenuSelected('Approved')">
                <ng-container *ngFor="let request of loanRequestList">
                    <ng-container *ngIf="request.Approved==true && request.LoanID == undefined">
                        <!-- <ng-container *ngTemplateOutlet="requestView;context:{request:request}"></ng-container> -->
                        <app-loan-request-details [request]="request" [isExpanded]="isExpanded">
                        </app-loan-request-details>
                    </ng-container>
                </ng-container>
            </div>

        </div>


        <!-- 
        <ng-template #requestView let-request="request">
        
        </ng-template> -->
    </div>


</form>