<app-navbar *ngIf="isLoggedIn"></app-navbar>

<div class="overlay" (click)="toggleSidebar()"> </div>
<div>
  <app-sidebar></app-sidebar>
</div>
<div class="container-fluid">
  <router-outlet></router-outlet>
</div>

<!-- Add Asset Modal -->
<div class="modal fade" id="applyAssets">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <app-apply-asset></app-apply-asset>
    </div>
  </div>
</div>
<!-- End of Add Asset Modal -->

<!-- Add Transaction Modal -->
<div class="modal fade" id="addTransaction">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <app-add-transaction></app-add-transaction>
    </div>
  </div>
</div>
<!-- End of Add Transaction Modal -->

<!-- End of EditProfile Modal -->
<div class="modal fade" id="editProfile">
  <div class="modal-dialog modal-dialog-centered modal-sm">
    <div class="modal-content">
      <app-edit-profile></app-edit-profile>
    </div>
  </div>
  <!-- End of EditProfile Modal -->
</div>

<app-scroll-to-top class="doNotPrint"></app-scroll-to-top>

<!-- End of Add Customer Modal -->
<!-- <div class="modal fade" id="addCustomer">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <app-add-customer></app-add-customer>
    </div>
  </div> -->
<!-- End of Add Customer Modal -->
<!-- </div> -->

<!-- <div class="modal fade" id="addPlan">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <app-add-plan></app-add-plan>
    </div>
  </div> -->
<!-- End of Add Plan Modal -->
<!-- </div> -->