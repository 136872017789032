import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteReasonRemarkComponent } from 'src/app/Account/delete-reason-remark/delete-reason-remark.component';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Transaction } from 'src/model/transaction.model';

@Component({
  selector: 'app-customer-bd-history',
  templateUrl: './customer-bd-history.component.html',
  styleUrls: ['./customer-bd-history.component.css']
})
export class CustomerBdHistoryComponent implements OnInit {

  constructor(private restApi: RestApiService, private constructApi: ConstructAPI, private activeModal: NgbActiveModal, public sharedService: SharedService,
    private cdr: ChangeDetectorRef, private enums: Enums) { }

  bdTransactionList: Transaction[] = new Array;

  @Input() selectedProfileID: number;
  @Input() selectedCustomerName: string;
  @Input() isModal: boolean = true;
  isDirty: boolean = false;

  ngOnInit(): void {
    this.getAllTransactionsOfBD();
  }

  getAllTransactionsOfBD() {
    var xhttp = this.restApi.getRequest(this.constructApi.getProfileBDReceipts(this.selectedProfileID));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var receiptArray = JSON.parse((xhttp.responseText));

        receiptArray.forEach(receipt => {

          var transactionArray = JSON.parse(JSON.stringify(receipt.transactions));

          transactionArray.forEach(transaction => {
            var record = new Transaction(transaction.id, transaction.remark, transaction.amount,
              transaction.closingBalance, transaction.accountId, transaction.date, transaction.type, transaction.handleBy)

            this.bdTransactionList.push(record);
          });

          // var receiptObj = new Receipt(receipt.id, receipt.number, loanTransactionList);
          // this.receiptList.push(receiptObj);
        }
        );
      }
    };
  }

  confirmDeleteTranscation(badDebt: Transaction) {

    var timeDiff = new Date().getTime() - new Date(badDebt.Date).getTime();
    var dateDiff = timeDiff / (1000 * 3600 * 24);
    if (dateDiff < 90 || this.sharedService.isBossAccount() == true) {
      const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
      modalRef.componentInstance.message = "Are you sure you want to delete SIMPAN BAD DEBT RM " + badDebt.Amount + "?";

      //get confirmation
      modalRef.componentInstance.passEntry.subscribe((entry) => {
        var confirmation = entry;
        if (confirmation == true) {


          const modalRef = this.sharedService.openModal(DeleteReasonRemarkComponent);
          modalRef.componentInstance.transaction = badDebt;
          modalRef.componentInstance.passEntry.subscribe((entry) => {
            var remark = entry;

            //append added account to account list
            this.deleteTransaction(remark, badDebt.ID);
          });

        }

      });
    }
    else
      this.sharedService.openAlert("This transaction is too old, please contact your admin to delete", this.enums.DANGER_ALERT)


  }

  deleteTransaction(remark: string, transactionID: number) {
    var data =
    {
      "reason": remark
    };


    var xhr = this.restApi.deleteRequest(this.constructApi.getDeleteBadDebtTransaction(transactionID), data);
    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        if (xhr.readyState == 4 && xhr.status == 200) {

          if (xhr.status == 200) {
            this.removeTransctionFromList(transactionID);
            this.isDirty = true;
          }
          else {
            this.sharedService.openAlert("Opps, some errors occured. Please try again later", this.enums.DANGER_ALERT);
          }

        }
      }

    }

  }


  removeTransctionFromList(transactionId: number) {

    for (let i = 0; i < this.bdTransactionList.length; i++) {
      if (Number(this.bdTransactionList[i].ID) == transactionId) {
        this.bdTransactionList.splice(i, 1);
        this.cdr.detectChanges();

        if (this.bdTransactionList.length == 0)
          window.location.reload();
      }
    }
    return -1
  }


  calculateTotalBD(bdTransactionList: Transaction[]) {
    var sumOfReserve: number = 0;
    for (let i = 0; i < bdTransactionList.length; i++) {
      sumOfReserve += bdTransactionList[i].Amount;
    }
    return sumOfReserve;
  }

  checkAmountInOut(amount: number) {
    if (amount < 0)
      return 'negative';
    else
      return 'positive';
  }

  reset() {

    if (this.isDirty == true)
      window.location.reload();
    else
      this.activeModal.close();

  }
}
