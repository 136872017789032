<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Unprocessed Transactions {{selectedCompany.Name}}</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="activeModal.close()"></button>
    </div>

    <div class="modal-body">
        <div class="col-12 m-1">
            <table class="table ">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Remark</th>
                        <th>Customer</th>
                        <th>Type</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let st of transactionList">

                        <td>{{st.Date | date:'dd-MM-yyyy'}}</td>
                        <td>{{st.Remark}}</td>
                        <td> <span class="customerLink" *ngIf="st.CustomerName!=undefined"
                                (click)="goToCustomer(st.tCustomerID)">{{st.CustomerName}}
                                ({{sharedService.processICNum(st.CustomerIC)}})</span>
                        </td>
                        <td>{{enums.transactionTypeString[st.Type-1]}}</td>
                        <td>{{st.Amount}}</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>

        </div>
    </div>



</form>