import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Account } from 'src/model/account.model';

@Component({
  selector: 'app-add-cp-payment',
  templateUrl: './add-cp-payment.component.html',
  styleUrls: ['./add-cp-payment.component.css']
})
export class AddCpPaymentComponent implements OnInit {
  companyBankAccountList: Account[] = new Array;
  cashAccountList: Account[] = new Array;

  selectedAccountId: number;

  @Input() selectedProfileId: number;
  @Input() isModal = true;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  //2-WAY BINDING INFO
  paymentDate: Date = new Date();
  remark: string;
  amount: number = 0;

  disabledSubmit = false;

  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private restapi: RestApiService, private constructApi: ConstructAPI,
    public sharedService: SharedService, private enums: Enums) { }

  ngOnInit(): void {

    var loggedInCompanyId = Number(sessionStorage.getItem('companyId'));
    this.getAllAccountsOfCompany(loggedInCompanyId);
  }

  private getAllAccountsOfCompany(companyId: number) {

    // var xhttp = new XMLHttpRequest();
    // var url = "http://localhost:10080/api/v1/company/"+companyId+"/accounts";
    // xhttp.withCredentials = true;
    var loggedInUsername = sessionStorage.getItem("username");
    var xhttp = this.restapi.getRequest(this.constructApi.getAccountsOfCompany(companyId));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let account = new Account(json.id, json.number, json.name, json.holderName, json.balance, json.companyId, json.username);

          if (account.Username == undefined)
            this.companyBankAccountList.push(account);
          else if (account.Username == loggedInUsername)
            this.cashAccountList.push(account);

        }
        );

      }
    };

  }

  setSelectedAccount(accountId: number) {

    if (accountId === this.selectedAccountId)
      return true
    else
      return false;
  }

  isFieldNull() {
    var paymentNull = (this.amount == undefined || this.amount == 0);
    var remarkNull = (this.remark == undefined || this.remark == "");
    var selectedAccountIdNull = (this.selectedAccountId == undefined);
    var paymentValid = isNaN(+this.amount);

    return paymentNull || remarkNull || selectedAccountIdNull || paymentValid
  }

  addPaymentForBadDebt() {
    this.disabledSubmit = true;

    var isoTaskDate = (new Date(Number(this.paymentDate))).toISOString();

    var data = {
      "amount": this.amount,
      "accountId": Number(this.selectedAccountId),
      "date": isoTaskDate,
      "remark": this.remark
    };


    var xhr = this.restapi.postRequest(this.constructApi.getPayProfileBadDebt(this.selectedProfileId), data);
    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        if (xhr.status == 200) {
          var json = JSON.parse(xhr.responseText);

          this.passEntry.emit(this.selectedProfileId)
          this.reset();
          this.sharedService.openAlert("Successfully added payment for bad debt!", this.enums.SUCCESS_ALERT);

        } else
          this.sharedService.openErrorMessage(xhr);
      }

    }
  }

  submit() {

    (this.isFieldNull()) ? this.sharedService.openAlert('Please fill in all the fields', this.enums.DANGER_ALERT) : this.addPaymentForBadDebt();
  }
  reset() {
    this.activeModal.close();
  }


}
