<div class="toggled doNotPrint " id="wrapper">
    <div class="border rounded-0" id="sidebar-wrapper">
        <div class="d-md-none">
            <div class="d-flex justify-content-center align-items-center">
                <img class="img-thumbnail img-fluid border rounded-circle border-primary shadow m-3 thumbnail"
                    src="../assets/image/man-avatar.png" data-bs-toggle="modal" data-target="#editProfile"
                    (click)="toggleSidebar()">
                <div class="text-center">
                    <span class="userName"><strong>{{sharedService.getLoggedInUsername() | uppercase}}</strong></span>
                    <div class="text-center d-md-flex justify-content-md-center userType">
                        <span>{{getLoggedInRolename() | uppercase}}</span>
                    </div>
                </div>
            </div>
            <hr>
        </div>
        <ul class="sidebar-nav">
            <li [hidden]="sharedService.isExpenseAdmin()==true||sharedService.isAuditor()==true">
                <a [routerLink]="checkDashboard(getLoggedInRoleID())"
                    [id]="getStyle(checkDashboard(getLoggedInRoleID()))" (click)="toggleSidebar()">Dashboard </a>
            </li>
            <!-- <li [hidden]="getLoggedInRoleID()==enums.STAFF">
                <a routerLink="/manageAsset" [id]="getStyle('/manageAsset')" (click)="toggleSidebar()">Assets </a>
            </li> -->
            <!-- <li *ngIf="getLoggedInRoleID()!=enums.STAFF">
                <a routerLink="/dashboard" [id]="getStyle('/dashboard')" (click)="toggleSidebar()">Transactions </a>
            </li> -->
            <li
                [hidden]="(sharedService.isAdmin()==false && sharedService.isBossAccount()==false && !sharedService.isFinance() && sharedService.isAuditor()==false) ">
                <a routerLink="/companyList" [id]="getStyle('/companyList')" (click)="toggleSidebar()">Companies </a>
            </li>
            <li [hidden]="sharedService.isBranchAccountHandler()==false ">
                <a [routerLink]="getCustomerListLink()" [id]="getStyle('/customerList')"
                    (click)="toggleSidebar()">Customers </a>
            </li>
            <li [hidden]="sharedService.isBranchAccountHandler()==false||sharedService.isAuditor()==true">
                <a routerLink="/accountList" [id]="getStyle('/accountList')" (click)="toggleSidebar()">Accounts</a>
            </li>
            <li [hidden]="sharedService.isBranchAccountHandler()==false">
                <a [routerLink]="getAdvanceLink()" [id]="getStyle('/advanceList')" (click)="toggleSidebar()">Advance</a>
            </li>
            <li [hidden]="sharedService.isBranchAccountHandler()==false">
                <a routerLink="/commissionList" [id]="getStyle('/commissionList')"
                    (click)="toggleSidebar()">Commission</a>
            </li>

            <li [hidden]="sharedService.isStaff()==true||sharedService.isAuditor()==true">
                <a (click)="getReceiptMenu()" [id]="getStyle('/receiptList')" style="cursor:pointer">Receipts</a>
            </li>
            <li
                [hidden]="sharedService.isStaff()==true || sharedService.isExpenseAdmin()==true||sharedService.isAuditor()==true|| sharedService.isBookkeepingStaff() || sharedService.isFinance()">
                <a routerLink="/blacklistRecords" [id]="getStyle('/blacklistRecords')"
                    (click)="toggleSidebar()">Blacklist </a>
            </li>
            <li
                [hidden]="sharedService.isStaff()==true || sharedService.isExpenseAdmin()==true||sharedService.isAuditor()==true|| sharedService.isBookkeepingStaff()">
                <a routerLink="/userList" [id]="getStyle('/userList')" (click)="toggleSidebar()">Users </a>
            </li>



            <li
                [hidden]="sharedService.isStaff()==true || sharedService.isExpenseAdmin()==true||sharedService.isAuditor()==true || sharedService.isBookkeepingStaff()">
                <a routerLink="/cpReports" [id]="getStyle('/cpReports')" (click)="toggleSidebar()">CP</a>
            </li>
            <li [hidden]="sharedService.isStaff()==true || sharedService.isExpenseAdmin()==true">
                <a routerLink="/reportMenu" [id]="getStyle('/reportMenu')" (click)="toggleSidebar()">Report</a>
            </li>

            <!-- <li> <a routerLink="/contact" [id]="getStyle('/contact')" (click)="toggleSidebar()">Contact</a></li> -->
            <li
                [hidden]="sharedService.isAdmin()==false && sharedService.isBossAccount()==false||sharedService.isAuditor()==true">
                <a routerLink="/register" [id]="getStyle('/register')" (click)="toggleSidebar()">Register</a>
            </li>
            <li
                [hidden]="sharedService.isAdmin()==false && sharedService.isBossAccount()==false||sharedService.isAuditor()==true">
                <a routerLink="/import" [id]="getStyle('/import')" (click)="toggleSidebar()">Import</a>
            </li>

            <!--Only show this option when there's latest draft rule-->
            <li class="d-flex align-items-center" *ngIf="enums.draftEPFLimitList!=undefined">
                <a routerLink="/draft-calculator" [id]="getStyle('/draft-calculator')" (click)="toggleSidebar()">
                    Calculator</a>
                <span class="badge text-bg-danger" style="text-indent: 0; font-size: 10pt;">New!</span>
            </li>
            <li class="d-md-none" (click)="logout()"> <a class="logoutLink">Logout</a></li>
        </ul>
    </div>


</div>