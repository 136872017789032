<div class="card">
    <div class="card-body">
        <div class="mb-2"*ngFor="let request of loanGroupRequest.requestList" >
            <app-all-loan-request-card [request]="request" ></app-all-loan-request-card>
        </div>

        <div class="col d-flex align-items-end justify-content-end">
            <button class="btn btn-info " (click)="openReviewLoanRequestModal()">Review Requests</button>
        </div>
        
    </div>
</div>