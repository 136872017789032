<div class="card shadow-lg"
    [ngClass]="{'approvedCard':request.Approved==true,'elevatedCard':request.ProcessRequiredRole==enums.BOSS,
    'unauthorizedCard':request.AuthorizedBy==undefined && sharedService.isAdmin()==false && sharedService.isBossAccount() ==false ,
    'normalRequestCard':request.LoanInfo.Scheme !='EPF' && request.LoanInfo.Scheme !='HUTANG' ,'hutangRequestCard':request.LoanInfo.Scheme =='HUTANG'}">
    <div class="card-body">
        <div class="row">
            <div class="col-10">
                <h3 *ngIf="request.LoanInfo.Scheme=='EPF'"><strong> Ticket #{{request.ID}} -
                        ({{request.CompanyCode}}) </strong>
                </h3>
                <h3 *ngIf="request.LoanInfo.Scheme!='EPF'"><strong>({{request.LoanInfo.Scheme}}) Request -
                        #{{request.ID}} -
                        ({{request.CompanyCode}})</strong>
                </h3>
            </div>
            <div class="col-2 d-flex justify-content-end ">
                <h5 class="me-1">
                    <span class="badge text-bg-warning " *ngIf="request.Priority==enums.FIRST_PRIORITY">
                        <i class="fas fa-star"></i> </span>

                    <span class="badge text-bg-primary" *ngIf="request.LoanGroupId!=undefined"> <i
                            class="far fa-handshake"></i></span>
                </h5>
                <h5 class="me-1">
                    <i class="fas fa-check-square text-success" *ngIf="request.VerifiedOn!=undefined"
                        #tooltip="matTooltip" [matTooltip]="constructVerifiedMessage(request)"
                        matTooltipPosition="above" matTooltipHideDelay="100" matTooltipClass="tooltip-black"></i>
                </h5>
                <h5> <span class="badge text-bg-secondary " #tooltip="matTooltip" [matTooltip]="request.UpdatedOn |
                    date:'dd-MM-yyyy hh:mm:ss'" matTooltipPosition="above" matTooltipHideDelay="100"
                        matTooltipClass="tooltip-black">Date:
                        {{request.UpdatedOn |
                        date:'dd-MM-yyyy'}}</span></h5>
            </div>
        </div>


        <hr />
        <div class="row">
            <div class="col-12">
                <h5 class="p-0 m-0 viewCustomerLink" style="color: darkslategray;" (click)=" goToCustomer()">
                    <b>{{request.CustomerName}}
                        | {{sharedService.processICNum(request.CustomerIC)}}
                    </b>
                </h5>
            </div>
            <div class="col-12" *ngIf="request.LoanInfo.Scheme=='EPF'">
                <span style="color: slategray;font-weight: bold;"><i class="fas fa-piggy-bank"></i> EPF:
                    {{request.EPFDate |date:'dd-MM-yyyy'}} |
                    RM{{(request.EpfAmount/1000)|number:'1.2-2'}}K</span>
            </div>

            <div class="col-12" *ngIf="request.LoanInfo.Scheme=='HUTANG'">
                <span style="color: rgb(190, 129, 79);font-weight: bold;"> <i class="fas fa-receipt"></i> HUTANG:
                    {{request.LoanInfo.Principal |number:'1.2-2'}}
                </span>
            </div>


            <div class="col-12 col-lg-5" *ngIf="request.LoanInfo.Scheme=='EPF'">
                <span style="color: slateblue;font-weight: bold;"><i class="fas fa-money-bill"></i> Loan:
                    {{request.LoanInfo.Principal/1000 |number:'1.2-2'}}K
                    | Settlement: RM{{(calculateSettlement(request)/1000)
                    |number:'1.2-2'}}K
                </span>

            </div>

            <div class="col-12 col-lg-5" *ngIf="request.LoanInfo.Scheme!='EPF'&& request.LoanInfo.Scheme!='HUTANG'">
                <span style="color:  rgb(190, 129, 79);;font-weight: bold;"><i class="fas fa-money-bill"></i> Loan: RM
                    {{request.LoanInfo.Principal |number:'1.2-2'}}
                    | {{request.LoanInfo.Scheme }}({{request.LoanInfo.InterestRate|number:'1.2-2'}}%)
                </span>

            </div>
            <div class="col-12" *ngIf="request.LoanInfo.Scheme!='EPF'">
                <span style="color: gray;font-weight: bold;"> <i class="fas fa-comments-dollar"></i>
                    Remark:
                    {{request.Remark}}
                </span>
            </div>
            <div class="col-12 col-lg-7"
                *ngIf="request.LoanInfo.Scheme=='EPF' && (sharedService.isBranchManager()==false && sharedService.isStaff()==false)">
                <span class="badge" [ngClass]="{'bg-danger': calculateRatio(request)>25, 'bg-success' : calculateRatio(request)
                    <=25 }">+ Ratio : {{calculateRatio(request)|number:'1.3-3'}}%</span>

                <span class="badge text-bg-danger ms-1" *ngIf="request.Guarantors.length==0"><i class="fas fa-times"></i>
                    Guarantor</span>
                <span class="badge text-bg-success ms-1" *ngIf="request.Guarantors.length>0"><i class="fas fa-check"></i>
                    Guarantor</span>

                <span class="badge text-bg-danger ms-1" *ngIf="request.HasIAccount==false"><i class="fas fa-times"></i>
                    I-Account</span>
                <span class="badge text-bg-success ms-1" *ngIf="request.HasIAccount==true"><i class="fas fa-check"></i>
                    I-Account</span>


            </div>
        </div>
        <div class="row">
            <div class="col d-flex justify-content-end align-items-right">
                <button class="btn btn-info" (click)="openReviewLoanRequestModal()"
                    *ngIf="(sharedService.isAdmin()==true || sharedService.isBossAccount()==true) && request.Approved==undefined && request.LoanGroupId==undefined"><i
                        class="fas fa-file-alt"></i> 
                    Review </button>

                <button class="btn btn-info" (click)="openAuthorizeLoanRequestModal()"
                    *ngIf="(sharedService.isBranchManager()==true || sharedService.isStaff()==true || sharedService.isFinance()) && request.Approved==undefined">
                    <i class="fas fa-info"></i>
                    View Details
                </button>

                <button class="btn btn-success ms-2" (click)="checkOtherLoanRequests(request)"
                    *ngIf="(sharedService.isBranchManager()==true || sharedService.isStaff()==true)&& request.Approved==undefined && request.AuthorizedBy!=undefined && request.LoanInfo.Scheme=='EPF'"><i
                        class="fab fa-whatsapp"></i>
                    Send Whatsapp</button>
            </div>
        </div>

    </div>