export class Task {
    constructor(private _id: number, private _customerId: number,
        private _description: string, private _followUpRemrk: string, private _date: Date,
        private _customerName: string, private _assignee: string, private _dateDone: Date, private _isDone?: boolean, private _profileId?: number, private _type?: number) {

    }


    set ID(id: number) { this._id = id }
    get ID(): number { return this._id }

    set CustomerId(customerId: number) { this._customerId = customerId }
    get CustomerId(): number { return this._customerId }

    set Description(description: string) { this._description = description }
    get Description(): string { return this._description }

    set FollowUpRemark(followUpRemrk: string) { this._followUpRemrk = followUpRemrk }
    get FollowUpRemark(): string { return this._followUpRemrk }

    set Date(date: Date) { this._date = date }
    get Date(): Date { return this._date }

    set CustomerName(customerName: string) { this._customerName = customerName }
    get CustomerName(): string { return this._customerName }

    set Assignee(assignee: string) { this._assignee = assignee }
    get Assignee(): string { return this._assignee }

    set DateDone(dateDone: Date) { this._dateDone = dateDone }
    get DateDone(): Date { return this._dateDone }

    set IsDone(isDone: boolean) { this._isDone = isDone }
    get IsDone(): boolean { return this._isDone }

    set ProfileId(profileId: number) { this._profileId = profileId }
    get ProfileId(): number { return this._profileId }

    set Type(type: number) { this._type = type }
    get Type(): number { return this._type }

}