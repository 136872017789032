import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Transaction } from 'src/model/transaction.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RestApiService } from 'src/app/API/restapi';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { TransactionType } from 'src/model/transactionType.model';
import { Enums } from 'src/app/Shared/enums';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/model/user.model';
import { EditTransactionComponent } from '../edit-transaction/edit-transaction.component';
import { TransactionSubType } from 'src/model/transactionSubType.model';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { DeleteReasonRemarkComponent } from '../delete-reason-remark/delete-reason-remark.component';

@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.css']
})
export class TransactionHistoryComponent implements OnInit {

  @Input() public accountId;
  transactionList: Transaction[] = new Array;
  transactionSubtypeList: TransactionSubType[] = new Array;
  startDate: Date = new Date();
  endDate: Date = new Date();
  userList: User[] = new Array;
  selectedUsername: string;
  loggedInRole: number;
  carryForwardAmount: number;
  @Input() selectedCompany: number;

  isDirty: boolean = false;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private activeModal: NgbActiveModal, private restApi: RestApiService,
    private constructApi: ConstructAPI, public enums: Enums, private sharedService: SharedService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.loggedInRole = Number(sessionStorage.getItem('roleId'));

    if (this.sharedService.isAdmin() == false && this.sharedService.isBossAccount() == false && !this.sharedService.isFinance())
      this.selectedCompany = Number(sessionStorage.getItem("companyId"));

    this.getAllTransactionSubtype();

    //always show today's transaction
    this.startDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    // this.getAccountCarryForwardAmount();

    // if (this.transactionList.length == 0) {
    this.getAllTransactions();
    this.getAllUsersInCompany();
    // }

  }

  setSelectedUser(username: string) {

    if (username === this.selectedUsername)
      return true
    else
      return false;
  }

  private getAllTransactionSubtype() {

    var xhttp = this.restApi.getRequest(this.constructApi.getAllTransactionSubTypes());

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          let type = new TransactionSubType(json.id, json.name, json.type, json.assignable, json.enabled)
          this.transactionSubtypeList.push(type);
        }
        );

      }
    };
  }

  getSubTypeName(subTypeId: number) {
    var index = this.transactionSubtypeList.findIndex(type => type.ID === subTypeId);

    return this.transactionSubtypeList[index].Name;
  }

  openEditTransactionDialog(transactionId: number, amount: number, remark: string) {
    const modalRef = this.sharedService.openModal(EditTransactionComponent);
    modalRef.componentInstance.transactionId = transactionId;
    modalRef.componentInstance.amount = amount;
    modalRef.componentInstance.remark = remark;
    //get account that was created
    modalRef.componentInstance.passEntry.subscribe((entry) => {

      window.location.reload();
    });


  }
  openDeleteTransactionDialog(transaction: Transaction) {

    var timeDiff = new Date().getTime() - new Date(transaction.Date).getTime();
    var dateDiff = timeDiff / (1000 * 3600 * 24);
    if (dateDiff < 90 && this.sharedService.isBossAccount() == false && !this.sharedService.isFinance()) {

      const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
      modalRef.componentInstance.message = "Confirm to delete transaction amount = RM " + transaction.Amount + " and remark=" + transaction.Remark + "?";


      //get confirmation
      modalRef.componentInstance.passEntry.subscribe((entry) => {
        var confirmation = entry;
        if (confirmation == true) {
          const modalRef = this.sharedService.openModal(DeleteReasonRemarkComponent);
          modalRef.componentInstance.transaction = transaction;
          modalRef.componentInstance.passEntry.subscribe((entry) => {
            var remark = entry;

            //append added account to account list
            this.deleteTransaction(remark, transaction.ID, transaction.Type);
          });
        }
      });
    }
    else
      this.sharedService.openAlert("This transaction is too old, please contact your admin to delete", this.enums.DANGER_ALERT);
  }


  deleteTransaction(remark: string, transactionID: number, transactionType: number) {
    var data =
    {
      "reason": remark
    };

    var xhr;
    switch (transactionType) {
      case this.enums.RESERVE:
        xhr = this.restApi.deleteRequest(this.constructApi.getDeleteReserveTransaction(transactionID), data);
        break;
      case this.enums.BAD_DEBT:
        xhr = this.restApi.deleteRequest(this.constructApi.getDeleteBadDebtTransaction(transactionID), data);
        break;
      case this.enums.PRINCIPAL:
      case this.enums.MONTHLY_INTEREST:
      case this.enums.REPAYMENT:
      case this.enums.PROCESSING_CHARGE:
      case this.enums.ARREAR_CHARGES:
      case this.enums.EXTRA_INTEREST:
      case this.enums.BOUNTY:
        xhr = this.restApi.deleteRequest(this.constructApi.getDeleteLoanTransaction(transactionID), data);
        break;

      case this.enums.ADVANCE:
        xhr = this.restApi.deleteRequest(this.constructApi.getDeleteAdvanceTransaction(transactionID), data);
        break;
      case this.enums.ASSET:
        xhr = this.restApi.deleteRequest(this.constructApi.getDeleteAssetTransaction(transactionID), data);
        break;
      default:
        xhr = this.restApi.deleteRequest(this.constructApi.getDeleteTransaction(transactionID), data);
        break;
    }

    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {

        if (xhr.status == 200) {
          this.removeTransctionFromList(transactionID);
          this.isDirty = true;
        }
        else {
          // this.sharedService.openAlert("Opps, some errors occured. Please try again later");
          this.sharedService.openErrorMessage(xhr);
        }


      }

    }

  }

  removeTransctionFromList(transactionId: number) {

    for (let i = 0; i < this.transactionList.length; i++) {
      if (Number(this.transactionList[i].ID) == transactionId) {
        this.transactionList.splice(i, 1);
        this.cdr.detectChanges();

        if (this.transactionList.length == 0)
          window.location.reload();
      }
    }
    return -1
  }

  getAccountCarryForwardAmount() {
    this.carryForwardAmount = 0;
    if (this.startDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var reduceASecDate = new Date(Number(this.startDate) - 1);
    var endDateStr = reduceASecDate.toISOString();

    var xhttp = this.restApi.getRequest(this.constructApi.getAccount(this.accountId, endDateStr));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var json = JSON.parse((xhttp.responseText));
        this.carryForwardAmount = json.balance;
      }
    };
  }

  getAllTransactions() {
    this.getAccountCarryForwardAmount();
    this.transactionList.splice(0, this.transactionList.length);
    var startDateStr = (this.startDate == undefined) ? undefined : this.startDate.toISOString();

    if (this.endDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var addADayDate = new Date(Number(this.endDate) + (3600 * 1000 * 24) - 1);


    var endDateStr = (this.endDate == undefined) ? undefined : addADayDate.toISOString();
    var types = [this.enums.STAMP, this.enums.SERVICE_CHARGE, this.enums.EXTRAS, this.enums.ASSET, this.enums.BAD_DEBT, this.enums.RESERVE, this.enums.ADVANCE, this.enums.TRANSFER, this.enums.REALLOCATION, this.enums.INCOME, this.enums.EXPENSE,
    this.enums.CAPITAL, this.enums.SAVING, this.enums.TOTING]

    var xhttp = this.restApi.getRequest(this.constructApi.getAllTransactions(this.selectedCompany, types, startDateStr, endDateStr, undefined, undefined, this.accountId, this.selectedUsername));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        // console.log(jsonArray);

        jsonArray.forEach(json => {
          let transaction = new Transaction(json.id, json.remark, json.amount, json.closingBalance, json.accountId, json.date, json.type, json.handleBy, undefined, undefined, undefined, undefined, undefined, json.subtype);


          if (json.type == this.enums.EXTRAS || json.type == this.enums.STAMP || json.type == this.enums.SERVICE_CHARGE) {
            if (json.loanId == undefined) {
              this.transactionList.push(transaction);
            }

          }
          else
            this.transactionList.push(transaction);
          this.transactionList.sort((a, b) => (a.ID > b.ID) ? 1 : -1);
        }
        );

        this.getAllAccountLoanTransaction();
      }
    };

  }

  getAllAccountLoanTransaction() {

    var startDateStr = (this.startDate == undefined) ? undefined : this.startDate.toISOString();

    if (this.endDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var addADayDate = new Date(Number(this.endDate) + (3600 * 1000 * 24) - 1);
    var endDateStr = (this.endDate == undefined) ? undefined : addADayDate.toISOString();



    var xhttp = this.restApi.getRequest(this.constructApi.getCompanyAllLoansTransactions(this.selectedCompany, startDateStr, endDateStr, undefined, undefined, this.accountId, this.selectedUsername));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        // console.log(jsonArray);

        jsonArray.forEach(json => {
          let transaction = new Transaction(json.id, json.remark, json.amount, json.closingBalance, json.accountId, json.date, json.type, json.handleBy, json.receiptId, json.customerId, json.customerName, json.icNumber, json.occupation, json.subtype);

          this.transactionList.push(transaction);

          this.transactionList.sort((a, b) => (a.ID > b.ID) ? 1 : -1);

        }
        );
      }
    };

  }

  private getAllUsersInCompany() {

    // var xhttp = new XMLHttpRequest();
    // var url = "http://localhost:10080/api/v1/users?company=" + companyId;
    // xhttp.withCredentials = true;

    var xhttp = this.restApi.getRequest(this.constructApi.getAllUsers());

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let user = new User(json.id, json.companyid, json.companyname, json.roleid, json.rolename, json.username, undefined);
          this.userList.push(user);
        }
        );

      }
    };
    // xhttp.open("GET", url, true);
    // xhttp.send();

  }

  goToCustomer(customerID: number) {
    this.activeModal.close();
    this.router.navigate(['/manageCustomer', customerID]);
  }

  closeModal() {
    if (this.isDirty == true)
      window.location.reload();
    else
      this.activeModal.close();

  }

  calculateTotalTransaction(transaction: Transaction[]) {
    var sum: number = this.carryForwardAmount;
    for (let i = 0; i < transaction.length; i++) {
      sum += transaction[i].Amount;
    }
    return sum;

  }

  calculateTotalIn(transaction: Transaction[]) {
    var sum: number = 0;
    for (let i = 0; i < transaction.length; i++) {

      if (transaction[i].Amount >= 0)
        sum += transaction[i].Amount;
    }
    return sum;
  }

  calculateTotalOut(transaction: Transaction[]) {
    var sum: number = 0;
    for (let i = 0; i < transaction.length; i++) {

      if (transaction[i].Amount < 0)
        sum += transaction[i].Amount;
    }
    return sum;
  }

  checkAmountInOut(amount: number) {
    if (amount < 0)
      return 'negative';
    else
      return 'positive';
  }

  getTransactionColor(transactionAmount: number) {

    if (transactionAmount >= 0)
      return 'positive';
    else
      return 'negative';

  }
}
