import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { EPFLimit } from 'src/model/epfLimit.model';
import { Loan } from 'src/model/loan.model';

@Component({
  selector: 'app-epf-limit-information',
  templateUrl: './epf-limit-information.component.html',
  styleUrls: ['./epf-limit-information.component.css']
})
export class EpfLimitInformationComponent implements OnInit {

  @Input() isEPFExtra = false;
  @Input() previousEPFLoans: Loan[] = new Array;
  @Input() epfDate: Date;
  @Input() epfAmount: number;
  @Input() epfLimitRuleSet: EPFLimit[];

  message: string[] = new Array;


  constructor(public sharedService: SharedService, public enums: Enums) {

  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.epfDate || changes.epfAmount)
      this.getAllEPFCategoryLimitRules()

  }
  ngOnInit(): void {

  }


  includeSharedLoans() {
    var hasShare = false;
    this.previousEPFLoans.forEach(loan => {
        if (loan.SharedCompanies != undefined && loan.SharedCompanies.length > 1) {
          hasShare = true;

        }
  
    });

    return hasShare;
  }

  getAllEPFCategoryLimitRules() {
    this.message = [];
    var epfCategory = this.sharedService.checkEPFLimitCategory(new Date(this.epfDate), this.epfAmount,this.epfLimitRuleSet);

    var epfLimit = this.epfLimitRuleSet[epfCategory];

    this.message.push("Term " + epfLimit.LowerLimit + "-" + epfLimit.UpperLimit + " rules :");

    if (this.isEPFExtra == false) {
      if (epfLimit.FirstLoanRate != undefined)
        this.message.push("- First loan MAXIMUM can only be " + epfLimit.FirstLoanRate * 100 + "% of epf amount");

    }



    if (epfLimit.MaximumSettlementRate != undefined)
      this.message.push("- MAXIMUM Settlement Ratio can only be " + epfLimit.MaximumSettlementRate * 100 + "%");

    if (epfLimit.OverallLoanRate != undefined)
      this.message.push("- TOTAL LOAN PRINCIPAL can only be " + epfLimit.OverallLoanRate * 100 + "% of epf amount");

    if (epfLimit.ElevatedSettlementRate != undefined)
      this.message.push("- Total Settlement Ratio MORE THAN " + epfLimit.ElevatedSettlementRate * 100 + "% will need BOSS APPROVAL");

    if (epfLimit.MinimumEPFAmount != undefined)
      this.message.push("- MINIMUM EPF AMOUNT = RM " + this.sharedService.roundTo2Dec(epfLimit.MinimumEPFAmount));


    this.message.push("- Principal must be multiple of 50 (Eg: 100,150,350)")
    this.message.push("- Cash On Hand must be multiple of 100 (Eg: 100,200,900)")
  }

}
