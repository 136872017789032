export class rgDistribution {


    constructor(private _id: number, private _companyId: Number, private _amount: Number, private _companyCode: string, private _acceptedOn: Date, private _transactionDate: Date) { }

    get TransactionDate(): Date {
        return this._transactionDate;
    }
    set TransactionDate(value: Date) {
        this._transactionDate = value;
    }
    get AcceptedOn(): Date {
        return this._acceptedOn;
    }
    set AcceptedOn(value: Date) {
        this._acceptedOn = value;
    }

    get CompanyCode(): string {
        return this._companyCode;
    }
    set CompanyCode(value: string) {
        this._companyCode = value;
    }
    get Amount(): Number {
        return this._amount;
    }
    set Amount(value: Number) {
        this._amount = value;
    }
    get CompanyId(): Number {
        return this._companyId;
    }
    set CompanyId(value: Number) {
        this._companyId = value;
    }
    get Id(): number {
        return this._id;
    }
    set Id(value: number) {
        this._id = value;
    }
}

export class ReserveGroup {



    constructor(private _distribution: rgDistribution[], private _id: number, private _requestedBy: string, private _requestedOn: Date, private _sharedProfileId: number, private _accepted?: number, private _total?: number, private _valid?: boolean) { }

    get SharedProfileId(): number {
        return this._sharedProfileId;
    }
    set SharedProfileId(value: number) {
        this._sharedProfileId = value;
    }
    get RequestedOn(): Date {
        return this._requestedOn;
    }
    set RequestedOn(value: Date) {
        this._requestedOn = value;
    }
    get RequestedBy(): string {
        return this._requestedBy;
    }
    set RequestedBy(value: string) {
        this._requestedBy = value;
    }
    get ID(): number {
        return this._id;
    }
    set ID(value: number) {
        this._id = value;
    }
    get Distribution(): rgDistribution[] {
        return this._distribution;
    }
    set Distribution(value: rgDistribution[]) {
        this._distribution = value;
    }

    get Total(): number {
        return this._total;
    }
    set Total(value: number) {
        this._total = value;
    }
    get Accepted(): number {
        return this._accepted;
    }
    set Accepted(value: number) {
        this._accepted = value;
    }
    get Valid(): boolean {
        return this._valid;
    }
    set Valid(value: boolean) {
        this._valid = value;
    }
}