<div class="container-fluid g-0">

        <div class="row">
                <div class="col d-flex justify-content-end align-items-end">
                        <button class="btn btn-warning" (click)="openImageModal(this.enums.APPLICATION_FORM)"
                                *ngIf="sharedService.isAdmin()==false && sharedService.isBossAccount()==false && sharedService.isAuditor()==false && !sharedService.isFinance()"><i
                                        class="far fa-file-alt"></i>
                                Add
                                Application Form</button>

                        <div class="dropdown me-3">
                                <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton"
                                         data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                        *ngIf="sharedService.isAdmin()==false && sharedService.isBossAccount()==false && !sharedService.isFinance() && sharedService.isAuditor()==false">
                                        Add File
                                </button>
                                <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                                        <a class="dropdown-item" (click)="openImageModal(this.enums.REFERENCE_FORM)">
                                                <i class="fas fa-asterisk"></i> Reference Form</a>
                                        <a class="dropdown-item" (click)="openImageModal(this.enums.IC_FRONT)">
                                                <i class="far fa-id-card"></i> IC Front</a>
                                        <a class="dropdown-item" (click)="openImageModal(this.enums.IC_BACK)">
                                                <i class="fas fa-address-card"></i> IC Back</a>
                                        <a class="dropdown-item" (click)="openImageModal(this.enums.CONSENT_FORM)">
                                                <i class="fas fa-check-square"></i> Consent Form</a>
                                        <a class="dropdown-item" (click)="openImageModal(undefined)">
                                                <i class="fas fa-file-alt"></i> Other Docs/Images</a>
                                        <a class="dropdown-item" (click)="openImageModal(this.enums.FORM_J)"><i
                                                        class="fas fa-file-signature"></i> Signed
                                                FormJ</a>
                                        <a class="dropdown-item" (click)="openImageModal(this.enums.EPF_STATEMENT)"><i
                                                        class="fas fa-chart-pie"></i> EPF
                                                Statement</a>
                                        <a class="dropdown-item" (click)="openImageModal(this.enums.PAYSLIP)"><i
                                                        class="fas fa-file-invoice"></i> Payslip</a>
                                        <a class="dropdown-item" (click)="openImageModal(this.enums.BANK_STATEMENT)"><i
                                                        class="fas fa-file-invoice-dollar"></i> Bank Statement</a>
                                        <a class="dropdown-item" (click)="openImageModal(this.enums.ATM_CARD)"><i
                                                        class="far fa-credit-card"></i> ATM</a>
                                        <a class="dropdown-item" (click)="openImageModal(this.enums.EPF_PROOF)"><i
                                                        class="fas fa-video"></i> EPF Proof</a>
                                        <a class="dropdown-item" (click)="openImageModal(this.enums.ACKNOWLEDGEMENT)"><i class="fas fa-clipboard-check"></i> Acknowledgement Video</a>


                                </div>
                        </div>

                        <!-- <button class="btn btn-primary" (click)="openImageModal(false)"
                                *ngIf="sharedService.isAdmin()==false && sharedService.isBossAccount()==false"><i
                                        class="fas fa-images"></i>
                                Add
                                File</button> -->
                </div>
        </div>
        <h3>
                Supporting Documents
                <i class="far fa-question-circle ms-2" [ngbPopover]="popContent" style="cursor:pointer;"></i>

                <ng-template #popContent>
                        <!-- <div *ngIf="isLoanListReady==false;else loansList">
                                <mat-spinner></mat-spinner>
                        </div> -->
                        <app-check-customer-necessary-files [customerId]="viewCustomer.ID"
                                [companyId]="viewCustomer.CompanyId"></app-check-customer-necessary-files>
                </ng-template>
        </h3>

        <div *ngIf="supportingDocArr.length==0;else applicationTemplate">
                <p class="fw-bold text-danger bg-warning">This customer has no supporting documents in album
                </p>
        </div>

        <ng-template #applicationTemplate>
                <div>
                        <app-album-component [docArr]="supportingDocArr" [viewCustomer]="viewCustomer">
                        </app-album-component>

                </div>
        </ng-template>



        <hr>

        <div class="row">
                <div class="col-10">
                        <h3>Contact Docs ({{contactDocsArr.length}})</h3>
                </div>
                <div class="col-2" *ngIf="contactDocsArr.length>0">
                        <div class="d-flex justify-content-end align-items-end">
                                <button class="btn btn-light" (click)="isContactCollapsed = !isContactCollapsed"
                                        [attr.aria-expanded]="!isContactCollapsed" aria-controls="contactCollapse">
                                        <i class="fas fa-chevron-circle-down" *ngIf="isContactCollapsed"></i>
                                        <i class="fas fa-chevron-circle-up" *ngIf="isContactCollapsed==false"></i>
                                </button>
                        </div>
                </div>

        </div>

        <div class="collapse" id="contactCollapse" [ngbCollapse]="isContactCollapsed">
                <div *ngIf="contactDocsArr.length==0;else contactTemplate">
                        <p class="fw-bold text-danger bg-warning">This customer has no contact pictures in
                                album</p>
                </div>

                <ng-template #contactTemplate>
                        <div>
                                <app-album-component [docArr]="contactDocsArr" [viewCustomer]="viewCustomer">
                                </app-album-component>

                        </div>
                </ng-template>
        </div>


        <hr>



        <div class="row">
                <div class="col-10">
                        <h3>Loan Docs ({{loanDocsArr.length}})</h3>
                </div>
                <div class="col-2" *ngIf="loanDocsArr.length>0">
                        <div class="d-flex justify-content-end align-items-end">
                                <button class="btn btn-light" (click)="isLoanCollapsed = !isLoanCollapsed"
                                        [attr.aria-expanded]="!isLoanCollapsed" aria-controls="loanCollapse">
                                        <i class="fas fa-chevron-circle-down" *ngIf="isLoanCollapsed"></i>
                                        <i class="fas fa-chevron-circle-up" *ngIf="isLoanCollapsed==false"></i>
                                </button>
                        </div>
                </div>

        </div>

        <div class="collapse" id="loanCollapse" [ngbCollapse]="isLoanCollapsed">
                <div *ngIf="loanDocsArr.length==0;else loanTemplate">
                        <p class="fw-bold text-danger bg-warning">This customer has no loan pictures in album
                        </p>
                </div>

                <ng-template #loanTemplate>
                        <div>
                                <app-album-component [docArr]="loanDocsArr" [viewCustomer]="viewCustomer">
                                </app-album-component>

                        </div>
                </ng-template>
        </div>
        <hr>
        <h3>Other Images</h3>
        <div *ngIf="normalFileArr.length==0;else normalTemplate">
                <p class="fw-bold text-danger bg-warning">This customer has no other pictures in album</p>
        </div>

        <ng-template #normalTemplate>
                <div>
                        <app-album-component [docArr]="normalFileArr" [viewCustomer]="viewCustomer">
                        </app-album-component>

                </div>
        </ng-template>


</div>