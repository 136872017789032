import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { report } from 'process';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { UpdateProfileInfoComponent } from 'src/app/Customer/update-profile-info/update-profile-info.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Customer } from 'src/model/customer.model';
import { EPF } from 'src/model/epf.model';
import { Loan } from 'src/model/loan.model';


interface Report {
  customer: Customer,
  customerCode: string,
  customerName: string,
  epfAmount: number,
  epfDate: Date,
  lastPaymentDate: Date,
  loans: Loan[],
  supposed?: number,
  actual?: number,
  processing?: number,
  principalIn?: number,
  principalOut?: number,
  salary?: number,
  principalBalance?: number,
  ratio?: number,
  salaryUsage?: number,
  nextMonthSalaryUsage?: number,
  epfBalance?: number,
  epfLoanRatio?: number,
  includeLegacyEPF?: boolean,
  pnl?: number
}

@Component({
  selector: 'app-customer-record-report',
  templateUrl: './customer-record-report.component.html',
  styleUrls: ['./customer-record-report.component.css']
})


export class CustomerRecordReportComponent implements OnInit {
  displayedColumns: string[];
  dataSource = new MatTableDataSource;


  reportList: Report[] = new Array;
  wholeReportList: Report[] = new Array;
  @Input() selectedCompany: number;

  hideSettled: boolean = false;
  routeSubscription: any;
  isCollapsed: boolean = true;


  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private router: Router, private restapi: RestApiService, private activatedRoute: ActivatedRoute,
    private constructApi: ConstructAPI, private enums: Enums, private sharedService: SharedService) {

  }

  ngOnInit(): void {
    this.routeSubscription = this.activatedRoute.paramMap.subscribe(params => {
      this.selectedCompany = + params.get('companyId');
      this.getReport();

      this.displayedColumns = ['customerCode', 'customerName', 'supposed', 'actual', 'processing',
        'principalIn', 'principalOut', 'salary', 'principalBalance', 'ratio', 'salaryUsage', 'nextSalaryUsage',
        'epfAmount', 'epfBalance', 'epfLoanRatio', 'epfDate', 'lastPaymentDate', 'pnl'];
    });
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }

  resetList() {
    this.reportList.splice(0, this.reportList.length);
  }


  getReport() {
    this.resetList();

    var xhttp = this.restapi.getRequest(this.constructApi.getCompanyCustomerReport(this.selectedCompany));

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          var customer = new Customer(json.customerId, json.customerName, json.occupation, json.salary, undefined, json.icNumber,
            undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, json.customerCode, undefined)

          var loanList = this.processLoansArr(json.loans);

          var report = this.processAllAmounts(customer, loanList, json);
          this.reportList.push(report);
        }
        );
        this.wholeReportList = this.reportList;
        this.dataSource = new MatTableDataSource<Report>(this.reportList);
        this.dataSource.sort = this.sort;
      }
    };
  }


  hideSettledLoan() {
    this.reportList = [];
    if (this.hideSettled == true)
      this.reportList = this.wholeReportList.filter(report => { return report.supposed > 0 || report.epfBalance > 0 });
    else
      this.reportList = this.wholeReportList;

    this.dataSource = new MatTableDataSource<Report>(this.reportList);
    this.dataSource.sort = this.sort;
  }

  calculateTotalPnl() {
    var sum = 0;
    for (let i = 0; i < this.reportList.length; i++) {
      sum += this.reportList[i].pnl;
    }

    return sum
  }




  calculateTotalEPFBalance() {
    var sum = 0;
    for (let i = 0; i < this.reportList.length; i++) {
      sum += this.reportList[i].epfBalance;
    }

    return sum
  }


  // calculateTotalEPFAmount() {
  //   var sum = 0;
  //   for (let i = 0; i < this.reportList.length; i++) {
  //     if (this.reportList[i].epfDetails.Amount != undefined && this.reportList[i].epfDetails.Amount > 0)
  //       sum += this.reportList[i].epfDetails.Amount;
  //   }

  //   return sum
  // }


  containsEPFLoan(report: Report) {

    for (let i = 0; i < report.loans.length; i++) {
      if (report.loans[i].IsEPF == true || report.loans[i].Scheme == 'EPF')
        return true;
    }
    return false;
  }


  calculateTotalSupposed() {
    var sum = 0;
    for (let i = 0; i < this.reportList.length; i++) {

      sum += this.reportList[i].supposed;
    }

    return sum
  }

  calculateTotalActual() {
    var sum = 0;
    for (let i = 0; i < this.reportList.length; i++) {
      sum += this.reportList[i].actual;
    }

    return sum
  }


  calculateTotalProcessing() {
    var sum = 0;
    for (let i = 0; i < this.reportList.length; i++) {
      sum += this.reportList[i].processing;
    }

    return sum
  }

  calculateTotalPrincipalIn() {
    var sum = 0;
    for (let i = 0; i < this.reportList.length; i++) {
      sum += this.reportList[i].principalIn;
    }

    return sum
  }

  calculateTotalPrincipalOut() {
    var sum = 0;
    for (let i = 0; i < this.reportList.length; i++) {
      sum += this.reportList[i].principalOut;
    }

    return sum
  }

  calculateTotalPrincipalBalance() {
    var sum = 0;
    for (let i = 0; i < this.reportList.length; i++) {
      sum += this.reportList[i].principalBalance;
    }

    return sum
  }


  processAllAmounts(customer: Customer, loans: Loan[], json: any) {

    var supposed = 0;
    var actual = 0;
    var processing = 0;
    var ratio = 0;
    var salaryUsage = 0;
    var principalBalance = 0;
    var nextSalaryUsage = 0;
    var epfBalance = 0;
    var epfLoanRatio = 0;
    var principalIn = 0;
    var principalOut = 0;

    var pnl = 0;

    var includeLegacyEPF: boolean = false;

    for (let i = 0; i < loans.length; i++) {
      var monthly = 0;
      var termLeft = 0;

      if (loans[i].Scheme != 'EPF' && loans[i].IsEPF == false) {
        principalIn += loans[i].PrincipalPaid;
        principalOut += loans[i].PrincipalOut;
      }


      if (loans[i].Principal == 0)
        monthly = 0;
      else {
        if (loans[i].Scheme == 'A') {
          //PI
          monthly = loans[i].Principal * (loans[i].InterestRate / 100)
        }
        else {
          // PI+P/t
          monthly = (loans[i].Principal * (loans[i].InterestRate / 100)) + (loans[i].Principal / loans[i].Term)
        }
      }

      //assign MONTHLY PAYMENT
      loans[i].MonthlyPayment = monthly;

      if (loans[i].Principal == 0)
        termLeft = 0;
      else {
        if (loans[i].Scheme == 'A') {
          termLeft = 1;
        }
        else {
          //t- (Paid/Monthly)
          termLeft = loans[i].Term - (loans[i].RepaymentPaid / monthly)
        }
      }
      //assign term left
      loans[i].TermLeft = termLeft;

      if (loans[i].Scheme == 'A')
        principalBalance += loans[i].Principal;
      else {
        if ((loans[i].IsEPF == false && loans[i].Scheme != 'EPF')) {
          principalBalance += monthly * termLeft / (loans[i].Term * (loans[i].InterestRate / 100) + 1);
        }
      }


      if ((loans[i].IsEPF == false && loans[i].Scheme != 'EPF') && termLeft > 0) {
        supposed += monthly;
        actual += loans[i].Repayment + loans[i].ArrearPaid + loans[i].InterestCharge;
        processing += loans[i].ProcessingCharge;


      }

      ratio = principalBalance / customer.Salary;
      salaryUsage = supposed / customer.Salary;

      var nextMonthSupposed = 0;
      //(if A then (prin-prinin+prinout)*interest
      // if termleft=1 and repayment = monthly then 0
      // else monthly)/salary
      if (loans[i].Scheme == 'A')
        //if A = next month will need to pay new principle's interest
        nextMonthSupposed = (loans[i].Principal - loans[i].PrincipalPaid - loans[i].PrincipalOut) * (loans[i].InterestRate / 100);
      else {
        //if this is the last month and the repayment is made, next month no need pay this anymore
        if (termLeft == 1 && loans[i].Repayment == monthly)
          nextMonthSupposed = 0;
        else
          //next month will need pay same amount;
          nextMonthSupposed = supposed;
      }
      nextSalaryUsage = nextMonthSupposed / customer.Salary;

      //if is legacy epf
      if (loans[i].IsEPF == true) {
        includeLegacyEPF = true;
        if (loans[i].Scheme == 'A') {
          epfBalance = loans[i].Principal;
        }
        else {
          epfBalance = monthly * termLeft / (termLeft * (loans[i].InterestRate / 100) + 1);
        }
      }

      //if is epf scheme
      else if (loans[i].Scheme == 'EPF') {
        epfBalance = monthly * termLeft / (termLeft * (loans[i].InterestRate / 100) + 1);
      }
      //if not epf
      else
        epfBalance = 0;

      if (epfBalance != 0)
        epfLoanRatio = (epfBalance / Number(json.epfAmount)) * 100
      else
        epfLoanRatio = 0
        
      //add up all P&L
      pnl += loans[i].PnL;

    }


    var report = {
      customer: customer, customerCode: customer.CustomerCode, customerName: customer.Name, epfAmount: json.epfAmount,
      epfDate: json.epfDate, lastPaymentDate: json.lastPaymentDate, loans: loans,
      supposed: supposed, actual: actual, processing: processing, principalIn: principalIn,
      principalOut: principalOut, salary: customer.Salary, principalBalance: principalBalance,
      ratio: ratio, salaryUsage: salaryUsage, nextSalaryUsage: nextSalaryUsage,
      epfBalance: epfBalance, includeLegacyEPF: includeLegacyEPF, pnl: pnl, epfLoanRatio: epfLoanRatio
    }

    return report
  }


  goToCustomer(customerId: number) {
    this.router.navigate(['/manageCustomer', customerId]);
  }

  processLoansArr(jsonArr: any) {

    var loanList: Loan[] = new Array;

    jsonArr.forEach(json => {


      var loan = new Loan(undefined, undefined, json.principal || 0, undefined,
        undefined, undefined, undefined, undefined, undefined, json.scheme, json.interestRate,
        json.term, undefined, undefined, json.extraInterest || 0, undefined, json.loanCode,
        undefined, json.principalIn || 0, json.repayment || 0, undefined, undefined, undefined, json.arrear || 0, undefined,
        undefined, undefined, undefined, undefined, undefined, json.processingCharges || 0, undefined, json.epf || false,
        undefined, undefined, undefined, json.repaymentPaid || 0, json.pl, json.principalOut || 0)
      loanList.push(loan);

    }
    );

    return loanList;
  }



}
