import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Company } from 'src/model/company.model';
import { map, startWith } from 'rxjs/operators';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Transaction } from 'src/model/transaction.model';
import { Enums } from 'src/app/Shared/enums';
import { Set } from 'src/model/set.model';
import { AddReceiptSetComponent } from '../add-receipt-set/add-receipt-set.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ShowUnprocessedSetTransactionComponent } from '../show-unprocessed-set-transaction/show-unprocessed-set-transaction.component';
import { TransactionType } from 'src/model/transactionType.model';
import { TransactionHistoryComponent } from 'src/app/Account/transaction-history/transaction-history.component';
import { TransactionSubType } from 'src/model/transactionSubType.model';

@Component({
  selector: 'app-receipt-sets-list',
  templateUrl: './receipt-sets-list.component.html',
  styleUrls: ['./receipt-sets-list.component.css']
})
export class ReceiptSetsListComponent implements OnInit {

  selectedCompanyCode: string;
  myControl = new UntypedFormControl();
  filteredCompany: Observable<Company[]>;
  companyList: Company[] = new Array;
  startDate: Date;
  endDate: Date;
  selectedCompany: number;
  transactionList: Transaction[] = new Array;

  setList: Set[] = new Array;
  wholeSetList: Set[] = new Array;

  routeSubscription: any;
  showValid: boolean = false;
  filterTransactionTypeList: TransactionType[] = new Array;
  filteredType: number;


  isListView: boolean;
  showBookmark: boolean;
  expenseSubtypeList: TransactionSubType[] = new Array;
  dlSubtypeList: TransactionSubType[] = new Array;
  filteredSubtypeList: TransactionSubType[] = new Array;
  filteredSubtype: number;

  searchText: string;
  disableSubmit: boolean = false;



  constructor(public sharedService: SharedService, private restapi: RestApiService, private constructApi: ConstructAPI, public enums: Enums,
    private router: Router, private activatedRoute: ActivatedRoute) {

    //filter company
    this.filteredCompany = this.myControl.valueChanges.pipe(
      startWith(''),
      map(companyCode => this._filterCompanyCodeOptions(companyCode))
    );

    this.filterTransactionTypeList.push(new TransactionType(this.enums.EXPENSE, "EXPENSE"));
    this.filterTransactionTypeList.push(new TransactionType(this.enums.EXTRAS, "DUIT LEBIH"));
    this.filterTransactionTypeList.push(new TransactionType(this.enums.ADVANCE, "ADVANCE"));

  }


  ngOnInit(): void {

    this.routeSubscription = this.activatedRoute.paramMap.subscribe(params => {
      if (this.sharedService.isBranchAccountHandler() == false)
        this.selectedCompany = (params.get('companyId') == undefined) ? undefined : +params.get('companyId');
      else
        this.selectedCompany = Number(sessionStorage.getItem("companyId"));

      this.startDate = (params.get('startDate') == undefined) ? undefined : new Date(params.get('startDate'));
      this.endDate = (params.get('endDate') == undefined) ? undefined : new Date(params.get('endDate'));

      this.filteredType = (params.get('type') == undefined) ? 0 : +params.get('type');
      this.filteredSubtype = (params.get('subtype') == undefined) ? 0 : +params.get('subtype');


      var showValid = (params.get('showValid') == "true");
      this.showValid = (params.get('showValid') == undefined) ? false : showValid;


      var showBookmark = (params.get('saved') == "true");
      this.showBookmark = (params.get('saved') == undefined) ? false : showBookmark;



      if (this.selectedCompany != undefined || (this.sharedService.isBossAccount() == false && !this.sharedService.isFinance()))
        this.getAllSets();
    });


    if (this.sharedService.isAdmin() || this.sharedService.isBossAccount() || this.sharedService.isFinance() || this.sharedService.isExpenseAdmin()) {
      this.getAllCompanies(0);

    }



    this.getTransactionSubTypes(this.enums.EXPENSE);
    this.getTransactionSubTypes(this.enums.EXTRAS);

    // if (this.sharedService.isBranchManager())
    // this.getAllSets();
  }


  filterList() {
    if (this.searchText != "") {
      // console.log("search:" + this.searchText);
      this.setList = this.wholeSetList.filter((set: Set) => (set.Name.toUpperCase().includes(this.searchText.toUpperCase())) || Number(set.ID) == Number(this.searchText) || set.Remark.toString().toUpperCase().includes(this.searchText.toUpperCase()));
    }

    else
      this.setList = this.wholeSetList;
  }

  changeListView(isListView: boolean) {
    this.isListView = isListView;
    sessionStorage.setItem("setListView", this.isListView.toString());
  }


  openAddNewSetModal() {
    var modalRef = this.sharedService.openModal(AddReceiptSetComponent, "largeModal");

  }

  getAllSets() {


    this.setList.splice(0, this.setList.length);
    this.wholeSetList.splice(0, this.wholeSetList.length)

    var xhttp;

    // if no company is SELECTED (boss & admin)
    if (this.selectedCompany == undefined) {
      xhttp = this.restapi.getRequest(this.constructApi.getAllSets(undefined, undefined, undefined, undefined, true, undefined, undefined, this.enums.FIRST_PRIORITY));
    }

    else {
      // if company is SELECTED
      if (this.sharedService.isBranchManager()) {
        if (this.startDate != undefined && this.endDate != undefined) {
          var endDateStr = (this.endDate == undefined) ? undefined : this.endDate.toISOString();

          xhttp = this.restapi.getRequest(this.constructApi.getCompanyAllSets(this.selectedCompany, this.startDate.toISOString(), endDateStr,
            this.showValid, undefined, undefined, true, this.filteredType, this.filteredSubtype, this.showBookmark));
        }

        //if START DATE and ENDDATE undefined
        else {
          xhttp = this.restapi.getRequest(this.constructApi.getCompanyAllSets(this.selectedCompany, undefined, undefined, this.showValid, undefined, undefined, true, this.filteredType, this.filteredSubtype, this.showBookmark));
        }
      }

      //if is BOSS / ADMIN and company is selected
      else {
        if (this.startDate != undefined && this.endDate != undefined) {
          xhttp = this.restapi.getRequest(this.constructApi.getCompanyAllSets(this.selectedCompany, this.startDate.toISOString(), this.endDate.toISOString(), this.showValid, undefined,
            undefined, true, this.filteredType, this.filteredSubtype, this.showBookmark))
        }
        else
          xhttp = this.restapi.getRequest(this.constructApi.getCompanyAllSets(this.selectedCompany, undefined, undefined, this.showValid, undefined,
            undefined, true, this.filteredType, this.filteredSubtype, this.showBookmark))

      }

    }


    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {

          var transactions = this.processSetTransactions(json.transactions);

          var set = new Set(json.id, json.name, json.remark, json.companyId, transactions, json.created_by, json.checked_on,
            json.checker_remark, json.valid, json.checked_by, undefined, json.companyCode, json.saved, json.setRemark, json.tags)

          this.setList.push(set);
          this.wholeSetList.push(set);
        }
        );

        this.disableSubmit = false;
        this.setList.sort((a, b) => (a.Valid == undefined) ? -1 : 1);
        this.wholeSetList.sort((a, b) => (a.Valid == undefined) ? -1 : 1);
        this.isListView = (sessionStorage.getItem("setListView") == undefined) ? false : (sessionStorage.getItem("setListView") == "true");
      }
    };
  }

  processSetTransactions(json: any) {
    var setTransactions: Transaction[] = new Array;

    json.forEach(json => {
      var transcation = new Transaction(json.id, json.remark, json.amount, undefined, undefined, json.date, json.type, json.handleBy,
        json.receiptId, json.customerId, json.customerName, json.customerIC, undefined, json.subtype, json.groupId, json.setId);

      setTransactions.push(transcation);
    }
    );
    return setTransactions;
  }


  getTransactionSubTypes(type: number) {

    var xhttp = this.restapi.getRequest(this.constructApi.getTransactionSubTypes(type));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let subtype = new TransactionSubType(json.id, json.name, json.type, json.assignable, json.enabled);

          // if (json.assignable == true) {
          if (type == this.enums.EXPENSE)
            this.expenseSubtypeList.push(subtype)
          else if (type == this.enums.EXTRAS)
            this.dlSubtypeList.push(subtype)

          // }

        });

      }
    };



  }

  changeFilteredSubtypeList(type: number) {
    if (type == this.enums.EXPENSE)
      this.filteredSubtypeList = this.expenseSubtypeList;
    else if (type == this.enums.EXTRAS)
      this.filteredSubtypeList = this.dlSubtypeList;
  }

  getAllCompanies(companyGroup: number) {
    //clear company list
    this.companyList.splice(0, this.companyList.length);

    var xhttp;
    if (companyGroup == 0 || companyGroup == undefined)
      xhttp = this.restapi.getRequest(this.constructApi.getAllCompanies())
    else
      xhttp = this.restapi.getRequest(this.constructApi.getCompanyGroupCompanies(companyGroup))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));


        jsonArray.forEach(json => {
          let company = new Company(json.id, json.name, json.address, json.companyCode, json.contactNo, undefined, json.group, new Date(json.dateOperating));
          this.companyList.push(company);
        }
        );
        // this.outputToParent();

        //set the selected company code
        if (this.selectedCompany != undefined)
          this.setSelectedCompanyCode(this.selectedCompany)
      }
    };
  }

  setSelectedCompanyCode(companyId: number) {
    var index = this.companyList.findIndex(company => Number(company.ID) == Number(companyId));
    this.selectedCompanyCode = this.companyList[index].CompanyCode;
  }

  setSelectedCompany(code: string) {

    var index = this.companyList.findIndex(company => company.CompanyCode.toLowerCase() == code.toLowerCase());
    this.selectedCompany = this.companyList[index].ID;
  }


  private _filterCompanyCodeOptions(value: string): Company[] {
    const filterValue = value.toLowerCase();
    var filtered = this.companyList.filter(company => { return company.CompanyCode.toLowerCase().includes(filterValue) });

    return filtered;
  }


  goToCompanySet() {
    this.disableSubmit = true;
    // this.getAllSets();
    if (this.sharedService.isBranchManager() == false) {
      this.disableSubmit = false;
      if (this.startDate != undefined && this.endDate != undefined) {
        this.router.navigate(['/receiptList', this.selectedCompany, this.startDate.toISOString(), this.endDate.toISOString(), this.showValid, this.filteredType, this.filteredSubtype, this.showBookmark]);

      } else
        if (this.filteredType == undefined)
          this.router.navigate(['/receiptList', this.selectedCompany, this.showValid, this.filteredType, this.filteredSubtype, this.showBookmark]);
        else
          this.router.navigate(['/receiptList', this.selectedCompany, this.showValid, this.filteredType, this.filteredSubtype, this.showBookmark]);

    }
    else {

      this.getAllSets();
    }

  }


  showUnprocessedTransaction() {
    if (this.startDate == undefined || this.selectedCompany == undefined)
      this.sharedService.openAlert("Please select company and start date", this.enums.DANGER_ALERT)
    else {
      var modalRef = this.sharedService.openModal(ShowUnprocessedSetTransactionComponent, "largeModal");
      modalRef.componentInstance.selectedCompany = this.selectedCompany;
      modalRef.componentInstance.startDate = this.startDate;
    }


  }

}
