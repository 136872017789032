<form>
    <!-- Modal Header -->
    <div class="modal-header" *ngIf="isModal==true">
        <h4 class="modal-title">Bad Debt Simpan History = {{selectedCustomerName}}</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 aria-label="Close" aria-label="Close"
            (click)="reset()"></button>
    </div>

    <div class="modal-body">
        <div *ngIf="bdTransactionList.length==0 ||bdTransactionList==undefined ; else fullRecord">
            This customer does not have any SIMPAN BD RECORD
        </div>
        <ng-template #fullRecord>
            <div class="container-fluid" style="padding: 2%;">

                <div>
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th
                                    *ngIf="this.sharedService.isAdmin()==true || this.sharedService.isBossAccount()==true">
                                </th>
                                <th>Date</th>
                                <th>Remark</th>
                                <th>Amount</th>
                                <th>HandleBy</th>
                            </tr>

                        </thead>
                        <tr *ngFor="let bd of bdTransactionList">
                            <td *ngIf="this.sharedService.isAdmin()==true|| this.sharedService.isBossAccount()==true">
                                <button class="btn btn-danger" (click)="confirmDeleteTranscation(bd)"><i
                                        class="fas fa-trash-alt"></i></button>
                            </td>
                            <td>
                                {{bd.Date | date:'dd-MM-yyyy'}}
                            </td>
                            <td>
                                {{bd.Remark}}
                            </td>

                            <td [ngClass]=" checkAmountInOut(bd.Amount)">
                                {{bd.Amount}}
                            </td>
                            <td>
                                {{bd.HandleBy}}
                            </td>
                        </tr>
                        <tfoot>
                            <tr>
                                <td></td>
                                <td><strong>Total:</strong></td>
                                <td><strong>{{calculateTotalBD(bdTransactionList)}}</strong></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>

        </ng-template>

    </div>


</form>