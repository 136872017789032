import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { CustomerBdHistoryComponent } from 'src/app/Customer/customer-bd-history/customer-bd-history.component';
import { AlertDialogComponent } from 'src/app/Shared/alert-dialog/alert-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Account } from 'src/model/account.model';


interface BadDebt {
  checked: boolean,
  customerId: number,
  icNumber: string,
  name: string,
  total: number,
  amount: number,
  profileId: number
}

interface PaymentCust {
  id: number,
  amount: number
}

@Component({
  selector: 'app-bad-debt-simpan-expense',
  templateUrl: './bad-debt-simpan-expense.component.html',
  styleUrls: ['./bad-debt-simpan-expense.component.css']
})
export class BadDebtSimpanExpenseComponent implements OnInit {
  badDebtList: BadDebt[] = new Array;
  displayedColumns: string[];
  dataSource = new MatTableDataSource;

  companyBankAccountList: Account[] = new Array;
  cashAccountList: Account[] = new Array;
  paymentDate: Date = new Date();
  selectedAccountId: number;

  totalAmount = 0;

  disabledSubmit = false;

  filterEmpty = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  isChecked = false;

  constructor(private activeModal: NgbActiveModal, private router: Router, private modalService: NgbModal,
    private restapi: RestApiService, private constructApi: ConstructAPI, public enums: Enums, public sharedService: SharedService) { }

  ngOnInit(): void {
    this.displayedColumns = ['Check', 'Name', 'ICNumber', 'Total', 'Amount'];
    var companyId = Number(sessionStorage.getItem('companyId'));
    this.getAllBadDebts(companyId);
    this.getAllAccountsOfCompany(companyId);
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;

    if (filterValue == undefined || filterValue == "")
      this.filterEmpty = true;
    else
      this.filterEmpty = false;

    this.dataSource.filter = filterValue;
  }

  isCheckedAll() {
    for (let i = 0; i < this.badDebtList.length; i++) {
      if (this.badDebtList[i].checked == false)
        return false;
    }
    return true;
  }

  checkAll() {

    if (this.isChecked == true) {
      for (let i = 0; i < this.badDebtList.length; i++) {
        this.badDebtList[i].checked = true;
      }
    }
    else {
      for (let i = 0; i < this.badDebtList.length; i++) {
        this.badDebtList[i].checked = false;
      }
    }

    this.calculateTotal();
  }

  getAllBadDebts(companyId: number) {

    var xhttp = this.restapi.getRequest(this.constructApi.getCompanyUnpaidBadDebts(companyId))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // Typical action to be performed when the document is ready
        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {

          if (json.total > 0) {
            let bd = { customerId: json.customerId, icNumber: json.icNumber, name: json.name, total: json.total, checked: false, amount: undefined, profileId: json.profileId }
            this.badDebtList.push(bd);
          }

        }
        );

        //make bad debt list as the datasource
        this.dataSource = new MatTableDataSource<BadDebt>(this.badDebtList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    };
  }

  showCustomerSimpanBD(profileId: number, name: string) {
    const modalRef = this.sharedService.openModal(CustomerBdHistoryComponent, "largeModal");
    modalRef.componentInstance.selectedProfileID = profileId;
    modalRef.componentInstance.selectedCustomerName = name;
  }

  reset() {
    this.activeModal.close();
  }


  invalidFields() {
    var filtered = this.badDebtList.filter((bd: BadDebt) => (bd.checked == true));
    if (filtered.length == 0) {
      return true;
    }
    else {
      var accountNull = this.selectedAccountId == undefined;
      var paymentDateNull = this.paymentDate == undefined;

      return accountNull || paymentDateNull
    }
  }

  submit() {
    (this.invalidFields() == true) ? this.sharedService.openAlert("Please fill in all fields! Select at least 1 Bad Debt to pay!",this.enums.DANGER_ALERT) : this.confirmBeforePayBD();
  }

  confirmBeforePayBD() {
    var proceed = false;
    var idToPay: Number[] = new Array;
    var profileList: JSON[] = new Array;
    var filtered = this.badDebtList.filter((bd: BadDebt) => (bd.checked == true));

    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "You are making payments of " + filtered.length + " customer(s) to bad debt, are you sure?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something

      if (confirmation == true) {
        for (let i = 0; i < filtered.length; i++) {

          if (filtered[i].amount == undefined)
            var profile = { "id": filtered[i].profileId, "amount": filtered[i].total }
          else
            var profile = { "id": Number(filtered[i].profileId), "amount": Number(filtered[i].amount) }
          profileList.push(JSON.parse(JSON.stringify(profile)));
        }

        this.addBatchPayBD(profileList);
      }
    });

    return proceed;

  }
  private getAllAccountsOfCompany(companyId: number) {
    var loggedInUsername = sessionStorage.getItem("username");
    var xhttp = this.restapi.getRequest(this.constructApi.getAccountsOfCompany(companyId));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let account = new Account(json.id, json.number, json.name, json.holderName, json.balance, json.companyId, json.username);

          if (account.Username == undefined)
            this.companyBankAccountList.push(account);
          else if (account.Username == loggedInUsername)
            this.cashAccountList.push(account);

        }
        );

      }
    };

  }

  setSelectedAccount(accountId: number) {

    if (accountId === this.selectedAccountId)
      return true
    else
      return false;
  }

  calculateTotal() {
    this.totalAmount = 0;
    for (let i = 0; i < this.badDebtList.length; i++) {
      if (this.badDebtList[i].checked == true) {
        if (this.badDebtList[i].amount == undefined)
          this.totalAmount += this.badDebtList[i].total;
        else {
          this.totalAmount += this.badDebtList[i].amount
        }

      }

    }
  }


  isBalanceInsufficient(balance: number) {


    if (balance >= this.totalAmount)
      return false;
    else
      return true;
  }

  addBatchPayBD(ids: JSON[]) {
    this.disabledSubmit = true;
    var isoTaskDate = (new Date(Number(this.paymentDate))).toISOString();

    var data = {
      "accountId": Number(this.selectedAccountId),
      "date": isoTaskDate,
      "profiles": ids
    };

    var companyId = Number(sessionStorage.getItem('companyId'));
    var xhr = this.restapi.postRequest(this.constructApi.getPayCompanyUnpaidBadDebts(companyId), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        if (xhr.status == 200) {
          this.passEntry.emit("Done")
          this.reset();
          this.sharedService.openAlert("Successfully made bad debt payment!",this.enums.SUCCESS_ALERT);
        } else
          this.sharedService.openErrorMessage(xhr);
      }

    }
  }

}
