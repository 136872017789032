export class TransactionSubType {
    constructor(
        private _id: number,
        private _name: string,
        private _type: number,
        private _assignable?: boolean,
        private _enabled?: boolean

    ) { }


    set ID(id: number) { this._id = id }
    get ID(): number { return this._id }

    set Name(name: string) { this._name = name }
    get Name(): string { return this._name }

    set Type(type: number) { this._type = type }
    get Type(): number { return this._type }

    set Assignable(assignable: boolean) { this._assignable = assignable }
    get Assignable(): boolean { return this._assignable }

    set Enabled(enabled: boolean) { this._enabled = enabled }
    get Enabled(): boolean { return this._enabled }

}