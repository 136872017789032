import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { ShowWhatsappMessageClipboardComponent } from 'src/app/Shared/show-whatsapp-message-clipboard/show-whatsapp-message-clipboard.component';
import { Contact } from 'src/model/contact.model';
import { DuitLebih } from 'src/model/duitLebih.model';
import { Loan } from 'src/model/loan.model';
import { LoanRequest } from 'src/model/loanRequest.model';
import { SettlementCharges } from 'src/model/settlementCharges.model';
import { LoanGroupRequest } from 'src/model/sharedLoan.model';
import { TransactionRequest } from 'src/model/transactionRequest.model';


interface Usage {
  CompanyCode: string,
  CompanyId: number,
  LastInsertedDate: Date,
  LatestDate: Date,
  Group: number
}




@Component({
  selector: 'app-dashboard-admin',
  templateUrl: './dashboard-admin.component.html',
  styleUrls: ['./dashboard-admin.component.css']
})
export class DashboardAdminComponent implements OnInit {

  loanRequestList: LoanRequest[] = new Array;
  approvedWithoutloanRequestList: LoanRequest[] = new Array;
  wholeApprovedLoanRequestList: LoanRequest[] = new Array;
  wholeLoanRequestList: LoanRequest[] = new Array;
  normalLoanRequestList: LoanRequest[] = new Array;
  wholeNormalLoanRequestList: LoanRequest[] = new Array;
  sharedLoanRequestList: LoanGroupRequest[] = new Array;
  wholeSharedLoanRequestList: LoanGroupRequest[] = new Array;

  transactionRequestList: TransactionRequest[] = new Array;


  usageList: Usage[] = new Array;

  searchText: string = "";
  searchApprovedText: string = "";
  searchNormalText: string = "";

  setPendingCount = 0;


  clipboardCompareMessage = "";
  compareRequest: LoanRequest;

  constructor(private router: Router, private restApi: RestApiService, private constructApi: ConstructAPI, private cdr: ChangeDetectorRef, public sharedService: SharedService, private enums: Enums) { }


  ngOnInit() {

    this.getAllLoanRequests();
    this.getAllUsageStats();
    this.getPendingReceiptCount();
    this.getAllAssignedTransactionList();
  }



  getAllUsageStats() {
    var xhttp = this.restApi.getRequest(this.constructApi.getAllUsageStats(false))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          let usage = { CompanyCode: json.companyCode, CompanyId: json.companyId, LastInsertedDate: json.lastInserted, LatestDate: json.latestDate, Group: json.group };

          var timeDiff = new Date().getTime() - new Date(usage.LatestDate).getTime();
          var dateDiff = timeDiff / (1000 * 3600 * 24);

          // if this branch never do account for more than 5 days then only show on dashboard
          if (dateDiff > 5 || usage.LatestDate == undefined)
            this.usageList.push(usage);

        }
        );

        this.usageList.sort((a, b) => (a.LatestDate > b.LatestDate) ? 1 : -1);
      }
    };

  }
  getAllLoanRequests() {

    var xhttp = this.restApi.getRequest(this.constructApi.getAllLoanApprovalRequest(undefined, false, true, true, true))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));


        jsonArray.forEach(json => {

          // if (json.approved == undefined) {
          var loanInfoJson = json.loanInfo

          var stampAmount = (loanInfoJson.stamp == undefined) ? 0 : loanInfoJson.stamp;
          var serviceChargeAmount = (loanInfoJson.serviceCharge == undefined) ? 0 : loanInfoJson.serviceCharge;
          var reserve = (loanInfoJson.reserve == undefined) ? 0 : loanInfoJson.reserve;

          var extras = (loanInfoJson.extras == undefined) ? undefined : this.processDuitLebihJsonArr(loanInfoJson.extras);

          var settleStamp = (loanInfoJson.settlementInfo == undefined || loanInfoJson.settlementInfo.stamp == undefined) ? 0 : loanInfoJson.settlementInfo.stamp;
          var settleSc = (loanInfoJson.settlementInfo == undefined || loanInfoJson.settlementInfo.serviceCharge == undefined) ? 0 : loanInfoJson.settlementInfo.serviceCharge;
          var settleExtra = (loanInfoJson.settlementInfo == undefined || loanInfoJson.settlementInfo.extras == undefined) ? 0 : loanInfoJson.settlementInfo.extras;

          var settlementCharges = (loanInfoJson.settlementCharges == undefined) ? undefined : new SettlementCharges(loanInfoJson.settlementCharges.serviceCharge, loanInfoJson.settlementCharges.stamp, loanInfoJson.settlementCharges.extras, loanInfoJson.settlementCharges.handlingCharge);


          var loanInfo = new Loan(undefined, loanInfoJson.profileId, loanInfoJson.principal, stampAmount,
            undefined, undefined, undefined, undefined, undefined, loanInfoJson.scheme, loanInfoJson.interestRate, loanInfoJson.term,
            undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined,
            reserve, undefined, undefined, undefined, serviceChargeAmount, extras, undefined, undefined,
            undefined, loanInfoJson.processingRate, undefined, undefined, undefined,
            settleStamp, settleSc, settleExtra, undefined, undefined, undefined, undefined, undefined, undefined, settlementCharges);



          if (Array(json.guarantors).length > 0)
            var guarantorsInfo = this.processGuarantorJsonArr(json.guarantors);
          var loanRequest = new LoanRequest(json.customerId, json.epfAmount, json.epfDate,
            guarantorsInfo, json.hasIAccount, json.id, loanInfo, json.processedRemark,
            json.profileId, json.remark, json.requestBy, json.requestOn, json.updatedOn,
            json.approved, json.loanId, json.companyCode, json.icNumber, json.name, json.companyId, undefined, undefined, undefined,
            json.processorRoleRequired, json.priority, json.verifiedBy, json.verifiedOn, json.dob, json.otherId, undefined, undefined, undefined,json.sharedProfileId,json.loanGroupId)

          if (loanRequest.LoanInfo.Scheme == 'EPF') {
            //if not shared loan just add into loan request list
            if (loanRequest.Approved == undefined) {

              if (loanRequest.LoanGroupId == undefined) {
                this.loanRequestList.push(loanRequest);
                this.wholeLoanRequestList.push(loanRequest);
              }
              else   //if its shared loan,add into loan group request list
              {
                //check if same loan group existed, if existed add the loan request into loan group
                var index = this.sharedLoanRequestList.findIndex(request => request.loanGroup == loanRequest.LoanGroupId);
                if (index != -1) {
                  this.sharedLoanRequestList[index].requestList.push(loanRequest);
                }

                //if its is a newly added loan group, create it 
                else {
                  var loanGroupRequest = { loanGroup: loanRequest.LoanGroupId, requestList: [loanRequest] }
                  this.sharedLoanRequestList.push(loanGroupRequest);
                  this.wholeSharedLoanRequestList.push(loanGroupRequest);
                }


              }

            }
            else {
              this.approvedWithoutloanRequestList.push(loanRequest);
              this.wholeApprovedLoanRequestList.push(loanRequest)
            }
          }
          else {
            if (loanRequest.Approved == undefined) {
              this.normalLoanRequestList.push(loanRequest);
              this.wholeNormalLoanRequestList.push(loanRequest);
            }
          }


          this.cdr.detectChanges();

          this.loanRequestList.sort((a, b) => (a.Priority > b.Priority || a.UpdatedOn > b.UpdatedOn) ? 1 : -1);
          this.wholeLoanRequestList.sort((a, b) => (a.Priority > b.Priority || a.UpdatedOn > b.UpdatedOn) ? 1 : -1);

          this.approvedWithoutloanRequestList.sort((a, b) => (a.Priority > b.Priority || a.UpdatedOn > b.UpdatedOn) ? 1 : -1);
          this.wholeApprovedLoanRequestList.sort((a, b) => (a.Priority > b.Priority || a.UpdatedOn > b.UpdatedOn) ? 1 : -1);

          this.normalLoanRequestList.sort((a, b) => (a.Priority > b.Priority || a.UpdatedOn > b.UpdatedOn || a.LoanInfo.Scheme != "HUTANG") ? 1 : -1);
          this.wholeNormalLoanRequestList.sort((a, b) => (a.Priority > b.Priority || a.UpdatedOn > b.UpdatedOn || a.LoanInfo.Scheme != "HUTANG") ? 1 : -1);

          // this.approvedWithoutloanRequestList.sort((a, b) => (a.Priority > b.Priority || a.UpdatedOn > b.UpdatedOn) ? 1 : -1);
          // this.wholeApprovedLoanRequestList.sort((a, b) => (a.Priority > b.Priority || a.UpdatedOn > b.UpdatedOn) ? 1 : -1);
        }
        );

      }
    };

  }

  processGuarantorJsonArr(jsonArray: any) {
    var guarantorsInfo: Contact[] = new Array;
    jsonArray.forEach(json => {
      var guarantors = new Contact(json.id, json.name, json.icNumber, json.number, json.address, json.customerId, json.relation, json.remark)
      guarantorsInfo.push(guarantors);

    });

    guarantorsInfo.sort((a, b) => (a.Name > b.Name) ? 1 : -1);
    return guarantorsInfo;
  }

  processDuitLebihJsonArr(jsonArray: any) {
    var duitLebihList: DuitLebih[] = new Array;
    jsonArray.forEach(json => {
      var extra = new DuitLebih(json.amount, json.subtype)
      duitLebihList.push(extra);
    });

    return duitLebihList;
  }

  applyFilter() {
    if (this.searchText == "") {
      this.loanRequestList = this.wholeLoanRequestList;
      this.sharedLoanRequestList = this.wholeSharedLoanRequestList;
    }

    else {
      this.loanRequestList = this.wholeLoanRequestList.filter(request => request.ID == Number(this.searchText));
      this.sharedLoanRequestList = this.wholeSharedLoanRequestList.filter(group => group.requestList.find(request => request.ID == Number(this.searchText)));
    }

  }

  applySearchNormalFilter() {
    if (this.searchNormalText == "")
      this.normalLoanRequestList = this.wholeNormalLoanRequestList;
    else
      this.normalLoanRequestList = this.wholeNormalLoanRequestList.filter(request => request.CustomerIC.toUpperCase().includes(this.searchNormalText.toUpperCase()) || request.CustomerName.toUpperCase().includes(this.searchNormalText.toUpperCase()));
  }

  applyApprovedFilter() {
    if (this.searchApprovedText == "")
      this.approvedWithoutloanRequestList = this.wholeApprovedLoanRequestList;
    else
      this.approvedWithoutloanRequestList = this.wholeApprovedLoanRequestList.filter(request => request.ID == Number(this.searchApprovedText));
  }


  getPendingReceiptCount() {

    var xhttp = this.restApi.getRequest(this.constructApi.getSetsCount(true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.enums.FIRST_PRIORITY))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));


        jsonArray.forEach(json => {
          if (json.priority == 100)
            this.setPendingCount += json.count;
        });


      }
    };

  }

  goToReceipts() {
    this.router.navigate(['/receiptList']);
  }

  goToSettlementInfoCheck() {
    this.router.navigate(['/settlementInfoCheck']);
  }

  getAllAssignedTransactionList() {
    //only get trasactionRequest without transaction
    var xhttp = this.restApi.getRequest(this.constructApi.getAllTransactionRequests(false))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {

          var itemsArray = json.items;
          var itemList = new Array;
          itemsArray.forEach(itemJson => {
            var item = { "amount": itemJson.amount, "type": itemJson.type, "subtype": itemJson.subtype }

            itemList.push(item);
          }
          );

          var transactionRequest = new TransactionRequest(json.id, json.companyCode, json.companyid, json.remark, json.requestedBy, json.requestedOn, itemList);
          this.transactionRequestList.push(transactionRequest);
        }
        );
      }
    };
  }


  clickPaste() {
    navigator.clipboard.readText().then(
      text => {
        this.clipboardCompareMessage = text;

      }
    )
      .catch(error => {
        console.error('Cannot read clipboard text: ', error);
      }
      );
  }


  filterAndCompareMessage() {

    if (this.clipboardCompareMessage != '' && this.clipboardCompareMessage != undefined) {
      let regexp: RegExp = /\#(\d+)/g;

      //will get ticket number with leading #
      var ticketNo = this.clipboardCompareMessage.match(regexp)

      if (ticketNo != undefined && ticketNo != null) {
        //remove # to get ticket number and do filter
        this.searchText = ticketNo[0].replace('#', "");
        this.applyFilter();


        //search the request in request list
        //if found, add the request into whatsapp clipboard component
        //if not found, search in shared loan request list
        var index = this.wholeLoanRequestList.findIndex(loanRequest => Number(loanRequest.ID) == Number(this.searchText));
        if (index != -1) {
          this.compareRequest = this.wholeLoanRequestList[index];


          var modalRef = this.sharedService.openModal(ShowWhatsappMessageClipboardComponent, "regularModal");
          modalRef.componentInstance.isEPFRequest = true;
          modalRef.componentInstance.requestList = [this.compareRequest];
          modalRef.componentInstance.compareString = this.clipboardCompareMessage;
        }

        else {

          //if the ticket number is not found in loan request list, find in shared loan request list
          var groupIndex = this.wholeSharedLoanRequestList.findIndex(group => group.requestList.find(request => Number(request.ID) == Number(this.searchText)));

          if (groupIndex != -1) {

            var modalRef = this.sharedService.openModal(ShowWhatsappMessageClipboardComponent, "regularModal");
            modalRef.componentInstance.isEPFRequest = true;

            var list = this.wholeSharedLoanRequestList[groupIndex].requestList.sort((a, b) => ((a.ID > b.ID)) ? -1 : 1);
            modalRef.componentInstance.requestList = list;
            modalRef.componentInstance.compareString = this.clipboardCompareMessage;
            modalRef.componentInstance.loanGroupId = this.wholeSharedLoanRequestList[groupIndex].loanGroup;



          }
          else {
            this.sharedService.openAlert("Ticket with this ticket number not existed!", this.enums.DANGER_ALERT);
          }



        }



      }
      else
        this.sharedService.openAlert("No record found! Please check pasted messsage!", this.enums.DANGER_ALERT);

    }
    else {
      this.searchText = "";
      this.applyFilter();
    }



  }



}
