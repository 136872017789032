export class GlobalCustomer {





    constructor(private _name: string, private _icNumber: string, private _companyName: string, private _customerId?: number,
        private _status?: number, private _occupation?: string, private _profileId?: number, private _custCode?: string, private _isLead?: boolean,
        private _guarantorName?: string, private _guarantorIC?: string, private _handlerName?: string, private _remark?: string,
        private _hasLoan?: boolean, private _lastApprovedTicketDate?: Date, private _dob?: Date, private _companyId?: number, private _sharedProfileId?: number) { }

    get SharedProfileId(): number {
        return this._sharedProfileId
    }
    set SharedProfileId(value: number) {
        this._sharedProfileId = value
    }

    get CompanyId(): number {
        return this._companyId
    }
    set CompanyId(value: number) {
        this._companyId = value
    }


    set Name(name: string) { this._name = name }
    get Name(): string { return this._name }


    set ICNumber(icNumber: string) { this._icNumber = icNumber }
    get ICNumber(): string { return this._icNumber }

    set CompanyName(companyName: string) { this._companyName = companyName }
    get CompanyName(): string { return this._companyName }

    set CustomerID(id: number) { this._customerId = id }
    get CustomerID(): number { return this._customerId }


    set Status(status: number) { this._status = status }
    get Status(): number { return this._status }

    set Occupation(occupation: string) { this._occupation = occupation }
    get Occupation(): string { return this._occupation }

    set ProfileID(profileID: number) { this._profileId = profileID }
    get ProfileID(): number { return this._profileId }

    set CustomerCode(customerCode: string) { this._custCode = customerCode }
    get CustomerCode(): string { return this._custCode }

    set IsLead(isLead: boolean) { this._isLead = isLead }
    get IsLead(): boolean { return this._isLead }


    get GuarantorIC(): string {
        return this._guarantorIC
    }
    set GuarantorIC(value: string) {
        this._guarantorIC = value
    }
    get GuarantorName(): string {
        return this._guarantorName
    }
    set GuarantorName(value: string) {
        this._guarantorName = value
    }

    get HandlerName(): string {
        return this._handlerName
    }
    set HandlerName(value: string) {
        this._handlerName = value
    }

    get Remark(): string {
        return this._remark
    }
    set Remark(value: string) {
        this._remark = value
    }
    get DOB(): Date {
        return this._dob
    }
    set DOB(value: Date) {
        this._dob = value
    }
    get LastApprovedTicketDate(): Date {
        return this._lastApprovedTicketDate
    }
    set LastApprovedTicketDate(value: Date) {
        this._lastApprovedTicketDate = value
    }
    get HasLoan(): boolean {
        return this._hasLoan
    }
    set HasLoan(value: boolean) {
        this._hasLoan = value
    }
}